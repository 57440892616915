import React, { FC, useEffect, useMemo, useState } from 'react';
import PageLayout from '../../components/PageLayout';
import {
  ComponentsLayout,
  ModalDeleteReminder,
  ModalMobile,
  ReminderData,
} from '@hdcorner/ui-library';
import ReminderInfoPanel from './components/ReminderInfoPanel';
import {
  useDeleteReminderMutation,
  useEditReminderMutation,
  useGetReminderByIdQuery,
} from './queries/remindersQueries';
import ModalSetReminderComponent from '../../components/ModalSetReminderComponent';
import moment from 'moment';
import useAlert from '../../hooks/useAlert';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';

interface Props
  extends RouteComponentProps<{
    reminderId: string;
  }> {}
const ReminderInfo: FC<Props> = ({
  match: {
    params: { reminderId },
  },
}) => {
  const { t } = useTranslation();
  const { presentError, presentSuccess } = useAlert();

  const [editReminderModal, setEditReminderModal] = useState<boolean>(false);
  const [deleteReminderModal, setDeleteReminderModal] = useState<boolean>(false);

  const [reminderData, setReminderData] = useState<ReminderData>({
    _id: '',
    info: '',
    title: '',
    category: '',
    allDay: false,
    reminderDateTime: '',
  });

  const { error, data: reminderRes } = useGetReminderByIdQuery(
    { id: reminderId },
    { skip: !reminderId },
  );

  useEffect(() => {
    if (error) presentError(t('errors.reminders.errorFetchSingle'));
  }, [error]);

  const [editReminder] = useEditReminderMutation();
  const [deleteReminder] = useDeleteReminderMutation();

  useEffect(() => {
    if (reminderRes && reminderRes.length > 0) {
      const reminder = reminderRes[0];
      const category = reminder?.category.replace(/_/g, ' ');

      setReminderData({
        _id: reminder._id,
        info: reminder.info,
        title: reminder.title,
        allDay: reminder.allDay,
        reminderDateTime: moment(reminder.reminderDateTime).toISOString(),
        category:
          category === 'cvd risk' ? 'CVD Risk' : category ? category : reminder.category,
      });
    }
  }, [reminderRes]);

  const formattedDate = useMemo(() => {
    if (!reminderData.reminderDateTime) return '';
    if (reminderData.allDay) {
      return moment(reminderData.reminderDateTime).format('MMM D YYYY') + ', All day';
    }
    return moment(reminderData.reminderDateTime).format('MMM D YYYY, h:mm A');
  }, [reminderData]);

  const handleEdit = () => {
    setEditReminderModal(true);
  };

  const handleDelete = () => {
    setDeleteReminderModal(true);
  };

  const handleCancel = () => {
    setDeleteReminderModal(false);
  };

  const handleEditReminder = (data: any) => {
    saveReminder(data);
    setEditReminderModal(false);
  };

  const handleDeleteReminder = () => {
    try {
      deleteReminder({ id: reminderData._id as string })
        .unwrap()
        .then(() => {
          presentSuccess(t('errors.reminders.successDelete'));
          setDeleteReminderModal(false);
        });
    } catch (e) {
      console.log(e);
      presentError(t('errors.reminders.errorDelete'));
    }
  };

  const saveReminder = (data: any) => {
    const newDate = moment(data.date);
    const splitTime = data.time.split(':');
    newDate.hour(parseInt(splitTime[0]));
    newDate.minutes(parseInt(splitTime[1]));
    newDate.seconds(0);
    newDate.milliseconds(0);

    try {
      editReminder({
        info: data.info,
        title: data.title,
        allDay: data.allDay,
        _id: reminderData._id,
        category: data.category,
        reminderDateTime: newDate.toISOString(),
      })
        .unwrap()
        .then(() => {
          presentSuccess(t('errors.reminders.successEdit'));
        });
    } catch (e) {
      console.log(e);
      presentError(t('errors.reminders.errorEdit'));
    }
  };

  return (
    <PageLayout headerTitle={formattedDate} defaultHref={'/dashboard/reminders'}>
      <ComponentsLayout>
        <ReminderInfoPanel
          handleEdit={handleEdit}
          reminderData={reminderData}
          handleDelete={handleDelete}
        />
      </ComponentsLayout>
      <ModalMobile open={editReminderModal} setOpen={setEditReminderModal}>
        <ModalSetReminderComponent
          selectedReminder={reminderData}
          handleSaveModal={handleEditReminder}
          modalTitle={`${t('buttons.editReminder')}`}
        />
      </ModalMobile>

      <ModalDeleteReminder
        open={deleteReminderModal}
        handleCancel={handleCancel}
        setOpen={setDeleteReminderModal}
        handleDelete={handleDeleteReminder}
      />
    </PageLayout>
  );
};

export default ReminderInfo;
