import * as React from 'react';
import { SVGProps } from 'react';
import { theme } from '@hdcorner/ui-library';

const CloseIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.707 2.70757L7.41402 6.00057L10.707 9.29357C10.8025 9.38582 10.8787 9.49616 10.9311 9.61817C10.9835 9.74017 11.0111 9.87139 11.0123 10.0042C11.0134 10.1369 10.9881 10.2686 10.9378 10.3915C10.8876 10.5144 10.8133 10.6261 10.7194 10.72C10.6255 10.8139 10.5139 10.8881 10.391 10.9384C10.2681 10.9887 10.1364 11.014 10.0036 11.0128C9.87084 11.0117 9.73962 10.9841 9.61762 10.9317C9.49561 10.8793 9.38527 10.8031 9.29302 10.7076L6.00002 7.41457L2.70702 10.7076C2.61477 10.8031 2.50443 10.8793 2.38242 10.9317C2.26042 10.9841 2.1292 11.0117 1.99642 11.0128C1.86364 11.014 1.73196 10.9887 1.60907 10.9384C1.48617 10.8881 1.37452 10.8139 1.28062 10.72C1.18673 10.6261 1.11248 10.5144 1.0622 10.3915C1.01192 10.2686 0.986616 10.1369 0.98777 10.0042C0.988924 9.87139 1.01651 9.74017 1.06892 9.61817C1.12133 9.49616 1.19751 9.38582 1.29302 9.29357L4.58602 6.00057L1.29302 2.70757C1.19751 2.61532 1.12133 2.50498 1.06892 2.38297C1.01651 2.26097 0.988924 2.12975 0.98777 1.99697C0.986616 1.86419 1.01192 1.73251 1.0622 1.60962C1.11248 1.48672 1.18673 1.37507 1.28062 1.28117C1.37452 1.18728 1.48617 1.11303 1.60907 1.06275C1.73196 1.01247 1.86364 0.987165 1.99642 0.988319C2.1292 0.989473 2.26042 1.01706 2.38242 1.06947C2.50443 1.12188 2.61477 1.19806 2.70702 1.29357L6.00002 4.58657L9.29302 1.29357C9.38527 1.19806 9.49561 1.12188 9.61762 1.06947C9.73962 1.01706 9.87084 0.989473 10.0036 0.988319C10.1364 0.987165 10.2681 1.01247 10.391 1.06275C10.5139 1.11303 10.6255 1.18728 10.7194 1.28117C10.8133 1.37507 10.8876 1.48672 10.9378 1.60962C10.9881 1.73251 11.0134 1.86419 11.0123 1.99697C11.0111 2.12975 10.9835 2.26097 10.9311 2.38297C10.8787 2.50498 10.8025 2.61532 10.707 2.70757Z"
        fill={props?.color ?? theme.palette.primary.main}
      />
    </svg>
  );
};

export default CloseIcon;
