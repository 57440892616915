import React, { FC, useMemo } from 'react';
import { Box, BoxProps, IconButton } from '@mui/material';
import FacebookIcon from '../../assets/FacebookIcon';
import GoogleIcon from '../../assets/GoogleIcon';
import AppleIcon from '../../assets/AppleIcon';

type SocialButtonsType = {
  isIphone: boolean;
  loginWithProvider: (provided: 'facebook' | 'google' | 'apple') => void;
};
const SocialButtons: FC<SocialButtonsType & BoxProps> = ({
  isIphone,
  loginWithProvider,
}) => {
  const isSafari = useMemo(() => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1;
  }, []);

  return (
    <Box display={'flex'} flex={1} justifyContent={'space-evenly'}>
      <IconButton onClick={() => loginWithProvider('facebook')}>
        <FacebookIcon />
      </IconButton>
      {/*<IconButton onClick={() => loginWithProvider('google')}>*/}
      {/*  <GoogleIcon />*/}
      {/*</IconButton>*/}
      {(isSafari || isIphone) && (
        <IconButton onClick={() => loginWithProvider('apple')}>
          <AppleIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default SocialButtons;
