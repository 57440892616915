import * as React from 'react';
import { SVGProps } from 'react';

const EditPencil = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props && props.width ? props.width : '16px'}
    height={props && props.height ? props.height : '15px'}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 20.9998V2.99979C0 2.73457 0.105357 2.48022 0.292893 2.29268C0.48043 2.10514 0.734784 1.99979 1 1.99979H11C11.2652 1.99979 11.5196 2.10514 11.7071 2.29268C11.8946 2.48022 12 2.73457 12 2.99979C12 3.265 11.8946 3.51936 11.7071 3.70689C11.5196 3.89443 11.2652 3.99979 11 3.99979H2V19.9998H18V10.9998C18 10.7346 18.1054 10.4802 18.2929 10.2927C18.4804 10.1051 18.7348 9.99979 19 9.99979C19.2652 9.99979 19.5196 10.1051 19.7071 10.2927C19.8946 10.4802 20 10.7346 20 10.9998V20.9998C20 21.265 19.8946 21.5194 19.7071 21.7069C19.5196 21.8944 19.2652 21.9998 19 21.9998H1C0.734784 21.9998 0.48043 21.8944 0.292893 21.7069C0.105357 21.5194 0 21.265 0 20.9998ZM10.293 7.62579L17.626 0.292786C17.8135 0.105315 18.0678 0 18.333 0C18.5982 0 18.8525 0.105315 19.04 0.292786L21.707 2.95879C21.8 3.05166 21.8737 3.16195 21.9241 3.28335C21.9744 3.40474 22.0003 3.53487 22.0003 3.66629C22.0003 3.7977 21.9744 3.92783 21.9241 4.04923C21.8737 4.17062 21.8 4.28091 21.707 4.37379L14.373 11.7068C14.1855 11.8943 13.9312 11.9997 13.666 11.9998H11C10.7348 11.9998 10.4804 11.8944 10.2929 11.7069C10.1054 11.5194 10 11.265 10 10.9998V8.33279C10.0001 8.06759 10.1055 7.81328 10.293 7.62579ZM12 9.99979H13.252L19.586 3.66679L18.333 2.41379L12 8.74779V9.99979Z"
      fill={props.color ?? '#222222'}
    />
  </svg>
);

export default EditPencil;
