import { Box } from '@mui/material';
import CustomInput from '../controls/CustomInput';
import React, { FC, useState } from 'react';
import ModalHeading from './ModalHeading';
import SwitchCard from '../cards/SwitchCard';
import DropdownMenu from './DropdownMenu';
import moment from 'moment';
import CustomDateInput from '../controls/CustomDateInput';
import CalendarModal from './CalendarModal';
import { useTranslation } from 'react-i18next';

type ModalSetReminderProps = {
  info: string;
  date: string;
  value: string;
  title: string;
  modalTitle?: string;
  allDayChecked: boolean;
  setInfo: (str: string) => void;
  setDate: (str: string) => void;
  setValue: (str: string) => void;
  setTitle: (str: string) => void;
  setAllDayChecked: (bool: boolean) => void;
  options: { label: string; value: string }[];
};
const ModalSetReminder: FC<ModalSetReminderProps> = ({
  date,
  info,
  value,
  title,
  options,
  setInfo,
  setDate,
  setValue,
  setTitle,
  modalTitle,
  allDayChecked,
  setAllDayChecked,
}) => {
  const { t } = useTranslation();

  const [openPicker, setOpenPicker] = useState<boolean>(false);

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      <ModalHeading
        align={'center'}
        showCalendar={'none'}
        title={modalTitle ? modalTitle : t('reminders.labels.set')}
      />
      <Box>
        <DropdownMenu
          value={value}
          menuItems={options}
          setValue={setValue}
          label={t('general.labels.type')}
          placeholder={t('general.labels.selectType')}
        />
      </Box>
      <Box>
        <Box>
          <CustomInput
            fullWidth
            value={title}
            handleChange={setTitle}
            label={t('general.labels.title')}
            placeholder={t('reminders.text.title')}
          />
        </Box>
      </Box>
      <Box>
        <Box>
          <CustomInput
            fullWidth
            value={info}
            handleChange={setInfo}
            label={t('general.labels.info')}
            placeholder={t('reminders.text.enterInfo')}
          />
        </Box>
      </Box>
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Box>
          <CustomDateInput
            selectedDate={date}
            label={t('subscription.labels.date')}
            handleCalendarOpen={() => setOpenPicker(true)}
          />
        </Box>
        <Box alignSelf={'end'}>
          <SwitchCard
            checked={allDayChecked}
            setChecked={setAllDayChecked}
            label={t('general.labels.allDay')}
          />
        </Box>
      </Box>
      <CalendarModal
        open={openPicker}
        selectFutureDates
        setOpen={setOpenPicker}
        selectedDate={moment(date).toISOString()}
        saveDate={date => setDate(moment(date).toISOString())}
      />
    </Box>
  );
};

export default ModalSetReminder;
