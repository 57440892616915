import { useMemo } from 'react';
import { Food, Meal, MealItem } from '../types/Food';
import { MacrosMetrics } from '../types/MacrosMetrics';
import { dietTypes } from '../constants/constantsDietType';
import { findMeal, foodTitlesString, totalValue } from '../utils/getMealObject';

export type MealList = {
  title: string;
  mealId: string;
  logDate: string;
  category: string;
  description: string;
  mealItems: MealItem[];
  totalCalories: number;
  metrics: MacrosMetrics[];
};

const usePrepareMealList = (
  mealCategory: string,
  mealLogRes?: Meal[],
  dietType?: string,
  calculatedCalories?: number,
) => {
  return useMemo<MealList>(() => {
    const selectedMeal = findMeal(mealLogRes || [], mealCategory);

    const dietPlan = dietTypes[dietType || 'standard'];

    const mealName = () => {
      switch (mealCategory) {
        case 'breakfast':
          return 'fitness.nutrition.labels.breakfast';
        case 'lunch':
          return 'fitness.nutrition.labels.lunch';
        case 'dinner':
          return 'fitness.nutrition.labels.dinner';
        case 'snacks':
          return 'fitness.nutrition.labels.snack';
        default:
          return '';
      }
    };

    if (!selectedMeal) {
      return {
        mealId: '',
        metrics: [],
        logDate: '',
        mealItems: [],
        description: '',
        totalCalories: 0,
        category: mealCategory,
        title: mealName(),
      };
    }

    const cal = totalValue(selectedMeal.foods, 'calories');

    const mealItems = findMealItems(selectedMeal);

    const metrics: MacrosMetrics[] = [
      {
        measure: 'gr',
        category: 'Calories',
        eaten: Math.round(cal),
        max: Math.round(calculatedCalories || 0),
      },
      {
        measure: 'gr',
        category: 'Carbs',
        eaten: Math.round(totalValue(selectedMeal.foods, 'carbs')),
        max: Math.round(((calculatedCalories || 0) * dietPlan.carbs) / 4),
      },
      {
        measure: 'gr',
        category: 'Protein',
        eaten: Math.round(totalValue(selectedMeal.foods, 'protein')),
        max: Math.round(((calculatedCalories || 0) * dietPlan.protein) / 4),
      },
      {
        measure: 'gr',
        category: 'Fat',
        eaten: totalValue(selectedMeal.foods, 'fat'),
        max: Math.round(((calculatedCalories || 0) * dietPlan.fat) / 4),
      },
    ];

    return {
      metrics,
      mealItems,
      totalCalories: cal,
      category: mealCategory,
      mealId: selectedMeal._id,
      logDate: selectedMeal.logDate,
      title: mealName(),
      description: foodTitlesString(selectedMeal.foods) || '',
    };
  }, [calculatedCalories, dietType, mealCategory, mealLogRes]);
};

const findMealItems = (mealObj: Meal) => {
  return mealObj.foods.map(item => {
    const food = item.food as Food;
    return {
      food: {
        ...food,
        calculatedCalories: Math.round((food.calories / 100) * item.quantity),
      },
      quantity: item.quantity,
    };
  });
};

export default usePrepareMealList;

export { findMealItems };
