import * as React from 'react';
import { SVGProps } from 'react';
import { Box, BoxProps, styled, useTheme } from '@mui/material';

const IconBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: '20px',
  height: '20px',
  padding: '4px',
  display: 'flex',
  borderRadius: '4px',
  alignItems: 'center',
  boxSizing: 'border-box',
  justifyContent: 'center',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.secondary.main
      : theme.palette.primary.main,
}));

const CopySQIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();

  return (
    <IconBox>
      <svg
        width="10"
        height="10"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M19 6H7C6.73478 6 6.48043 6.10536 6.29289 6.29289C6.10536 6.48043 6 6.73478 6 7V19C6 19.2652 6.10536 19.5196 6.29289 19.7071C6.48043 19.8946 6.73478 20 7 20H19C19.2652 20 19.5196 19.8946 19.7071 19.7071C19.8946 19.5196 20 19.2652 20 19V7C20 6.73478 19.8946 6.48043 19.7071 6.29289C19.5196 6.10536 19.2652 6 19 6ZM18 18H8V8H18V18ZM4 13C4 13.2652 3.89464 13.5196 3.70711 13.7071C3.51957 13.8946 3.26522 14 3 14H1C0.734784 14 0.48043 13.8946 0.292893 13.7071C0.105357 13.5196 0 13.2652 0 13V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1V3C14 3.26522 13.8946 3.51957 13.7071 3.70711C13.5196 3.89464 13.2652 4 13 4C12.7348 4 12.4804 3.89464 12.2929 3.70711C12.1054 3.51957 12 3.26522 12 3V2H2V12H3C3.26522 12 3.51957 12.1054 3.70711 12.2929C3.89464 12.4804 4 12.7348 4 13Z"
          fill={
            theme.palette.mode === 'dark'
              ? theme.palette.kmColorsTurquoise.main
              : theme.palette.warning.main
          }
        />
      </svg>
    </IconBox>
  );
};

export default CopySQIcon;
