import React, { FC, useEffect, useMemo } from 'react';
import { useIonRouter } from '@ionic/react';
import { Box, useMediaQuery } from '@mui/material';
import SectionHeading from '../../components/SectionHeading';
import { useGetSuggestedVideoQuery } from '../../nutrition/queries/videoQueries';
import {
  CardWithVideo,
  ClickNavCardNutrition,
  ConduitFile,
  theme,
} from '@hdcorner/ui-library';
import useAlert from '../../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

type Props = {
  webHandleAvatar?: () => void;
  webHandleWorkout?: () => void;
};
const SectionWorkouts: FC<Props> = ({ webHandleWorkout, webHandleAvatar }) => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { presentError } = useAlert();
  const web = useMediaQuery(theme.breakpoints.up('md'));
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const { data: videoRes, error } = useGetSuggestedVideoQuery({
    skip: 0,
    limit: 1,
    version: lang,
    suggested: true,
    sort: ['-createdAt'],
  });

  useEffect(() => {
    if (error) {
      presentError(t('errors.fitness.workouts.errorSugVideoFetch'));
    }
  }, [error]);

  const video = useMemo(() => {
    if (!videoRes || videoRes.suggestedVideos.length === 0) return;
    return videoRes.suggestedVideos[0];
  }, [videoRes]);

  const handleViewMore = () => {
    router.push('/dashboard/fitness/workouts');
  };

  const handleAvatar = () => {
    if (web && webHandleAvatar) {
      webHandleAvatar();
      return;
    }
    router.push('/dashboard/fitness/workouts/avatar');
  };

  const handleWorkout = () => {
    if (web && webHandleWorkout) {
      webHandleWorkout();
      return;
    }
    router.push('/dashboard/fitness/workouts/workout-type');
  };

  return (
    <Box gap={1} display={'flex'} flexDirection={'column'}>
      <SectionHeading
        hasGoal={false}
        viewMore={handleViewMore}
        heading={t('dashboard.dashcards.fitness.workouts')}
      />
      <Box mb={web ? theme.spacing(3) : theme.spacing(1)}>
        <ClickNavCardNutrition
          onClick={handleAvatar}
          body={`${t('buttons.selectAv')}`}
          headings={t('fitness.workouts.labels.avatar')}
        />
        <ClickNavCardNutrition
          onClick={handleWorkout}
          body={`${t('buttons.startWorkout')}`}
          headings={t('fitness.workouts.labels.workout')}
        />
      </Box>

      {video && (
        <>
          <SectionHeading
            hasGoal={false}
            heading={t('fitness.workouts.text.suggestedVid')}
          />
          <CardWithVideo
            title={video.title}
            url={(video.attachedVideo as ConduitFile).url}
            sx={{
              backgroundSize: 'cover',
              backgroundImage: `url(${(video.thumbnail as ConduitFile).url})`,
            }}
          />
        </>
      )}
    </Box>
  );
};

export default SectionWorkouts;
