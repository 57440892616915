import React, { FC, useEffect, useMemo, useState } from 'react';
import SectionHeading from '../../components/SectionHeading';
import {
  CalendarModal,
  CircularChart,
  dayWeekMonthYearCustom,
  SmallMacros,
  theme,
  ToggleButtons,
} from '@hdcorner/ui-library';
import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
  useMediaQuery,
} from '@mui/material';
import { useIonRouter } from '@ionic/react';
import useTimeframe from '../../../../hooks/useTimeframe';
import {
  useGetCaloriesInDepthQuery,
  usePostCaloriesInDepthMutation,
} from '../queries/caloriesQueries';
import useAlert from '../../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../../../../utils/useConvertJSON';

const BoxCard = styled(Box, { shouldForwardProp: propName => propName !== 'web' })<
  BoxProps & { web: boolean }
>(({ web, theme }) => ({
  display: 'flex',
  borderRadius: '8px',
  position: 'relative',
  boxSizing: 'border-box',
  flexDirection: 'column',
  minWidth: web ? '517px' : 'none',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.secondary.light,
}));

const BoxSecondary = styled(Box)<BoxProps>(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(3),
}));

const BoxGraph = styled(Box, { shouldForwardProp: propName => propName !== 'web' })<
  BoxProps & { web: boolean }
>(({ web }) => ({
  order: web ? 2 : 1,
}));

const BoxColumn = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
}));

const TypographySubText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.light,
  fontSize: theme.typography.subtitle2.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
}));

type SectionGraphProps = {
  toggle: boolean;
  heading?: boolean;
};
const SectionGraph: FC<SectionGraphProps> = ({ toggle, heading }) => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { presentError } = useAlert();
  const web = useMediaQuery(theme.breakpoints.up('md'));
  const xs = useMediaQuery(theme.breakpoints.down(395));

  // Used for updating the params for the query
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [selectedTimeframe, setSelectedTimeframe] = useState<string>('day');
  const [calendarModalOpen, setCalendarModalOpen] = useState<boolean>(false);

  const timeframe = useTimeframe(selectedTimeframe, selectedDate);

  const [postCalories, { isUninitialized }] = usePostCaloriesInDepthMutation();

  const {
    error,
    status,
    data: calorieRes,
  } = useGetCaloriesInDepthQuery({
    params: { skip: 0, limit: 1 },
    ...timeframe,
  });

  useEffect(() => {
    if (error) {
      presentError(t('errors.fitness.calories.error01'));
    }
  }, [error]);

  useEffect(() => {
    if (
      status === 'fulfilled' &&
      isUninitialized &&
      (!calorieRes || calorieRes.documents.length === 0)
    ) {
      postCalories()
        .unwrap()
        .catch(err => {
          console.log(err);
          presentError(t('errors.generic.error01'));
        });
    }
  }, [calorieRes, isUninitialized, postCalories, status]);

  const graphData = useMemo(() => {
    const data = calorieRes?.documents[0];

    return {
      caloriesLeft: data?.left ? Math.round(data.left) : 0,
      caloriesConsumed: data?.eaten ? Math.round(data.eaten) : 0,
      caloriesBurned: data?.burned ? Math.round(data.burned) : 0,
      caloriesTotal: data?.calculated ? Math.round(data.calculated + data.burned) : 0,
    };
  }, [calorieRes]);

  const prepareMacros = useMemo(() => {
    const data = calorieRes?.documents[0];

    return [
      {
        measure: t('measurements.gr'),
        category: t('fitness.calories.titles.carbs'),
        total: data ? Math.round(data.carbs.total) : 0,
        consumed: data ? Math.round(data.carbs.eaten) : 0,
      },
      {
        measure: t('measurements.gr'),
        category: t('fitness.calories.titles.protein'),
        total: data ? Math.round(data.protein.total) : 0,
        consumed: data ? Math.round(data.protein.eaten) : 0,
      },
      {
        measure: t('measurements.gr'),
        category: t('fitness.calories.titles.fat'),
        total: data ? Math.round(data.fat.total) : 0,
        consumed: data ? Math.round(data.fat.eaten) : 0,
      },
    ];
  }, [calorieRes]);

  const handleViewMore = () => {
    router.push('/dashboard/fitness/calories');
  };

  const handleChange = (newValue: string) => {
    if (newValue === 'custom') {
      setCalendarModalOpen(true);
    }
    setSelectedTimeframe(newValue);
  };

  const handleDateChange = (newDate: string) => {
    setSelectedDate(newDate);
  };

  const filteredToggle = dayWeekMonthYearCustom.filter(
    item => item.value === 'custom' || item.value === 'day',
  );

  return (
    <Box width={'100%'} display={'flex'} flexDirection={'column'}>
      <Box mb={1} display={heading ? 'block' : 'none'}>
        <SectionHeading
          hasGoal={false}
          viewMore={handleViewMore}
          heading={t('fitness.calories.titles.dailyCalIntake')}
        />
      </Box>
      <BoxCard web={web}>
        <Box mb={2} display={!toggle ? 'none' : 'block'}>
          <ToggleButtons
            value={selectedTimeframe}
            handleChange={handleChange}
            color={theme.palette.exercise.main}
            headings={useConvertJSON(filteredToggle)}
          />
        </Box>
        <Box display={'flex'} flexDirection={web ? 'row' : 'column'} gap={2}>
          <BoxSecondary flexDirection={xs ? 'column' : 'row'}>
            <BoxGraph web={web} minWidth={'230px'}>
              <CircularChart
                left={graphData.caloriesLeft}
                total={graphData.caloriesTotal}
              />
            </BoxGraph>
            <BoxColumn order={web ? 1 : 2} flexDirection={xs ? 'row' : 'column'}>
              <Box>
                <Typography variant={'h1'}>
                  {graphData.caloriesConsumed} {t('measurements.kcal')}
                </Typography>
                <TypographySubText>
                  {t('fitness.calories.titles.consumed')}
                </TypographySubText>
              </Box>
              <Box>
                <Typography variant={'h1'}>
                  {graphData.caloriesBurned} {t('measurements.kcal')}
                </Typography>
                <TypographySubText>
                  {t('dashboard.dashcards.fitness.burned')}
                </TypographySubText>
              </Box>
            </BoxColumn>
          </BoxSecondary>
          <BoxSecondary
            width={'100%'}
            gap={web ? 1 : 3}
            maxWidth={web ? '33%' : 'none'}
            flexDirection={web ? 'column' : 'row'}
          >
            {prepareMacros.map(it => (
              <Box flex={1} key={it.category} display={'flex'} width={'100%'}>
                <SmallMacros
                  max={it.total}
                  value={it.consumed}
                  title={it.category}
                  isProgressBarWide={web}
                  measure={`${it.consumed} / ${it.total} ${it.measure}`}
                />
              </Box>
            ))}
          </BoxSecondary>
        </Box>
      </BoxCard>
      <CalendarModal
        open={calendarModalOpen}
        saveDate={handleDateChange}
        selectedDate={selectedDate}
        setOpen={setCalendarModalOpen}
      />
    </Box>
  );
};

export default SectionGraph;
