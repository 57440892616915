import * as React from 'react';
import { SVGProps } from 'react';

const TikTokIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="24"
    height="28"
    fill="none"
    viewBox="0 0 24 28"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.7868 10.2951C19.5379 11.5253 21.6831 12.2491 24 12.2491V7.86751C23.5615 7.8676 23.1242 7.82267 22.6952 7.73334V11.1823C20.3784 11.1823 18.2335 10.4585 16.482 9.22839V18.17C16.482 22.6431 12.7923 26.269 8.24112 26.269C6.54297 26.269 4.9646 25.7644 3.65346 24.8991C5.14991 26.4028 7.23684 27.3357 9.54565 27.3357C14.0971 27.3357 17.787 23.7098 17.787 19.2366L17.7868 10.2951ZM19.3964 5.8746C18.5015 4.91375 17.9139 3.67203 17.7868 2.29925V1.73568H16.5503C16.8615 3.48048 17.9231 4.97113 19.3964 5.8746ZM6.53208 21.4666C6.03208 20.8223 5.76188 20.0342 5.76309 19.2238C5.76309 17.178 7.45072 15.5192 9.53281 15.5192C9.92084 15.5191 10.3065 15.5775 10.6763 15.6928V11.2132C10.2442 11.155 9.80803 11.1303 9.37207 11.1394V14.6261C9.002 14.5107 8.61611 14.4522 8.22799 14.4525C6.14591 14.4525 4.45837 16.1111 4.45837 18.1572C4.45837 19.6039 5.30191 20.8565 6.53208 21.4666Z"
      fill="#FF004F"
    />
    <path
      d="M16.482 9.22839C18.2335 10.4585 20.3784 11.1823 22.6952 11.1823V7.73334C21.402 7.46263 20.2572 6.79839 19.3964 5.8746C17.9231 4.97113 16.8615 3.48048 16.5503 1.73568H13.3023V19.2364C13.295 21.2766 11.6102 22.9286 9.5326 22.9286C8.3083 22.9286 7.22092 22.3551 6.53208 21.4666C5.30191 20.8565 4.45837 19.6039 4.45837 18.1572C4.45837 16.1111 6.14591 14.4525 8.22799 14.4525C8.62691 14.4525 9.0114 14.5136 9.37207 14.6261V11.1394C4.90085 11.2302 1.30469 14.8207 1.30469 19.2365C1.30469 21.4408 2.20009 23.439 3.65346 24.8991C4.9646 25.7644 6.54297 26.269 8.24112 26.269C12.7923 26.269 16.482 22.6431 16.482 18.17V9.22839Z"
      fill="black"
    />
    <path
      d="M22.6952 7.73334L22.6952 6.79962C21.5291 6.80136 20.3858 6.48155 19.3964 5.8746C20.2723 6.81697 21.4255 7.46675 22.6952 7.73334ZM16.5503 1.73568C16.5206 1.56893 16.4978 1.40003 16.482 1.23154V0.667969H11.9974V18.1689C11.9903 20.2088 10.3056 21.8608 8.22782 21.8608C7.6178 21.8608 7.04213 21.7195 6.53208 21.4666C7.22092 22.3551 8.3083 22.9286 9.5326 22.9286C11.6102 22.9286 13.295 21.2766 13.3023 19.2364V1.73568H16.5503ZM9.37207 11.1394L9.37208 10.1456C8.99736 10.0953 8.61957 10.07 8.24131 10.0702C3.68967 10.0701 0 13.6962 0 18.1689C0 20.973 1.44991 23.4454 3.65346 24.8991C2.20009 23.439 1.30469 21.4408 1.30469 19.2365C1.30469 14.8207 4.90085 11.2302 9.37207 11.1394Z"
      fill="#00F2EA"
    />
  </svg>
);

export default TikTokIcon;
