import React, { FC, useCallback } from 'react';
import {
  Box,
  BoxProps,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  styled,
  FormControlLabelProps,
  useTheme,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const CustomBoxOne = styled(Box)<BoxProps>(({ theme }) => ({
  padding: theme.spacing(1, 2),
  marginBottom: '1px',
  backgroundColor: theme.palette.secondary.light,
  cursor: 'pointer',
  '&:first-of-type': {
    borderTopRightRadius: '8px',
    borderTopLeftRadius: '8px',
  },
  '&:last-of-type': {
    marginBottom: theme.spacing(0),
    borderBottomRightRadius: '8px',
    borderBottomLeftRadius: '8px',
  },
}));

const CustomCheckbox = styled(Checkbox)<CheckboxProps>(({ theme }) => ({
  padding: theme.spacing(0),
  margin: theme.spacing(0),
  height: '20px',
  color: theme.palette.secondary.light,
}));

const CustomFormControlLabel = styled(FormControlLabel)<FormControlLabelProps>(
  ({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(0),
    margin: theme.spacing(0),
    '.MuiFormControlLabel-label': {
      color: theme.palette.primary.main,
      fontSize: theme.typography.subtitle2.fontSize,
      fontWeight: theme.typography.fontWeightRegular,
    },
  }),
);

type MultiSelectCardProps = {
  selected: string[];
  options: { label: string; value: string }[];
  handleChange: (selected: string[]) => void;
};
const MultiSelectCard: FC<MultiSelectCardProps> = ({
  options,
  selected,
  handleChange,
}) => {
  const theme = useTheme();

  const checkValue = useCallback(
    (value: string) => {
      return selected.includes(value);
    },
    [selected],
  );

  const handleCheck = useCallback(
    (selectedValue: string) => {
      if (checkValue(selectedValue)) {
        const newSelected = selected.filter(value => value !== selectedValue);
        handleChange(newSelected);
      } else {
        handleChange([...selected, selectedValue]);
      }
    },
    [options, checkValue, handleChange],
  );

  return (
    <div>
      {options.map((option, index) => (
        <CustomBoxOne key={index}>
          <CustomFormControlLabel
            label={option.label}
            labelPlacement={'start'}
            control={
              <CustomCheckbox
                onChange={() => handleCheck(option.value)}
                checked={checkValue(option.value)}
                checkedIcon={
                  <CheckIcon
                    sx={{ color: theme.palette.kmColorsRed.main }}
                    fontSize="small"
                  />
                }
              />
            }
          />
        </CustomBoxOne>
      ))}
    </div>
  );
};

export default MultiSelectCard;
