import { FC, useCallback, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import ChatMessageSenders from './ChatMessageSenders';
import moment from 'moment';
import FeelsEmpty from '../FeelsEmpty';

export type ChatRoom = {
  id: string;
  title: string;
  avatar?: string;
  group?: boolean;
  lastMessage: string;
  lastMessageDate: string;
  unreadMessages?: number;
};

type ChatPanelProps = {
  web: boolean;
  collapse?: boolean;
  chatRooms: ChatRoom[];
  selectedChatRoom?: string;
  handleOpenMessage: (chatId: string) => void;
  handleLoadMoreChatRooms?: () => void;
};

const ChatPanel: FC<ChatPanelProps> = ({
  web,
  chatRooms,
  selectedChatRoom,
  collapse = false,
  handleOpenMessage,
  handleLoadMoreChatRooms,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    const container = containerRef.current;
    if (!container) return;
    const isNear =
      container.scrollTop >= (container.scrollHeight - container.clientHeight) * 0.8;
    if (isNear) {
      handleLoadMoreChatRooms?.();
    }
  }, [handleLoadMoreChatRooms, containerRef]);

  useEffect(() => {
    const container = containerRef.current;
    container?.addEventListener('scroll', handleScroll);
    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, containerRef]);

  return (
    <>
      {!web ? (
        <Box
          flexGrow={1}
          display={'flex'}
          ref={containerRef}
          flexDirection={'column'}
          sx={{ overflowY: 'auto', overflowX: 'hidden' }}
        >
          {chatRooms && chatRooms.length ? (
            chatRooms.map((it, index) => (
              <Box key={index} onClick={() => handleOpenMessage(it.id)}>
                <ChatMessageSenders
                  web={false}
                  title={it.title}
                  group={it.group}
                  avatar={it.avatar}
                  message={it.lastMessage}
                  unreadMessages={it.unreadMessages}
                  selected={selectedChatRoom === it.id}
                  date={moment(it.lastMessageDate).format('HH:mm')}
                />
              </Box>
            ))
          ) : (
            <FeelsEmpty />
          )}
        </Box>
      ) : (
        <Box
          ref={containerRef}
          flexGrow={1}
          display={'flex'}
          flexDirection={'column'}
          sx={{ overflowY: 'auto', overflowX: 'hidden' }}
        >
          {chatRooms.map((it, index) => (
            <Box key={index} onClick={() => handleOpenMessage(it.id)}>
              <ChatMessageSenders
                web
                group={it.group}
                title={it.title}
                avatar={it.avatar}
                collapsed={collapse}
                message={it.lastMessage}
                unreadMessages={it.unreadMessages}
                selected={selectedChatRoom === it.id}
                date={moment(it.lastMessageDate).format('HH:mm')}
              />
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export default ChatPanel;
