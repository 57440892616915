import moment from 'moment/moment';
import { Box, useMediaQuery } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import {
  CustomButton,
  ModalDeleteReminder,
  ModalMobile,
  ReminderData,
  RemindersBox,
  theme,
} from '@hdcorner/ui-library';
import ModalSetReminderComponent from '../../../components/ModalSetReminderComponent';
import {
  useAddNewReminderMutation,
  useDeleteReminderMutation,
  useEditReminderMutation,
  useGetRemindersQuery,
} from '../queries/remindersQueries';
import useAlert from '../../../hooks/useAlert';
import ReminderInfoPanel from './ReminderInfoPanel';
import { useTranslation } from 'react-i18next';

type Props = {
  category: 'all' | 'medication';
};
const RemindersDashboard: FC<Props> = ({ category }) => {
  const { t } = useTranslation();
  const { presentError, presentSuccess } = useAlert();
  const web = useMediaQuery(theme.breakpoints.up('md'));

  const [viewReminderModal, setViewReminderModal] = useState<string>('');
  const [editReminderModal, setEditReminderModal] = useState<boolean>(false);
  const [deleteReminderModal, setDeleteReminderModal] = useState<boolean>(false);
  const [monthlyCalendarOpen, setMonthlyCalendarOpen] = useState<boolean>(false);
  const [reminderData, setReminderData] = useState<ReminderData | undefined>(undefined);

  const [params, setParams] = useState<{
    skip: number;
    limit: number;
    endDate: string;
    startDate: string;
    category?: string;
  }>({
    skip: 0,
    limit: 1000,
    endDate: moment().endOf('day').toISOString(),
    startDate: moment().startOf('day').toISOString(),
    category: category === 'medication' ? 'medication' : undefined,
  });

  const { data: remindersRes, error: fetchError } = useGetRemindersQuery({
    params: params,
  });

  const [editReminder] = useEditReminderMutation();
  const [addReminder] = useAddNewReminderMutation();
  const [deleteReminder] = useDeleteReminderMutation();

  useEffect(() => {
    if (fetchError) {
      presentError(t('errors.reminders.errorFetch'));
    }
  }, [fetchError]);

  const handleReminderClick = (reminderId: string) => {
    setViewReminderModal(reminderId);

    if (reminderId && remindersRes) {
      const data = remindersRes.documents.find(reminder => reminder._id === reminderId);
      const category = data?.category.replace(/_/g, ' ');
      if (data) {
        setReminderData({
          _id: data._id,
          info: data.info,
          title: data.title,
          allDay: data.allDay,
          reminderDateTime: data.reminderDateTime,
          category:
            category === 'cvd risk' ? 'CVD Risk' : category ? category : data.category,
        });
      }
    }
  };

  const handleEdit = () => {
    setViewReminderModal('');
    setEditReminderModal(true);
  };

  const handleDelete = () => {
    setViewReminderModal('');
    setDeleteReminderModal(true);
  };

  const handleSaveReminder = (data: any) => {
    const newDate = moment(data.date);
    const splitTime = data.time.split(':');
    newDate.hour(parseInt(splitTime[0]));
    newDate.minutes(parseInt(splitTime[1]));
    newDate.seconds(0);
    newDate.milliseconds(0);

    const reminder = {
      info: data.info,
      title: data.title,
      allDay: data.allDay,
      category: data.category,
      reminderDateTime: newDate.toISOString(),
    };
    if (!reminderData?._id) {
      try {
        addReminder(reminder)
          .unwrap()
          .then(() => presentSuccess(t('errors.reminders.successAdd')));
      } catch (e) {
        console.log(e);
        presentError(t('errors.reminders.errorAdd'));
      }
      setEditReminderModal(false);
      return;
    }

    try {
      editReminder({
        info: data.info,
        title: data.title,
        allDay: data.allDay,
        category: data.category,
        _id: reminderData._id as string,
        reminderDateTime: newDate.toISOString(),
      })
        .unwrap()
        .then(() => presentSuccess(t('errors.reminders.successEdit')));
    } catch (e) {
      console.log(e);
      presentError(t('errors.reminders.errorEdit'));
    }

    setEditReminderModal(false);
  };

  const handleDeleteReminder = () => {
    if (!reminderData) {
      presentError(t('errors.reminders.errorSelectReminder'));
      return;
    }
    try {
      deleteReminder({ id: reminderData._id })
        .unwrap()
        .then(() => presentSuccess(t('errors.reminders.successDelete')));
      setDeleteReminderModal(false);
    } catch (e) {
      console.log(e);
      presentError(t('errors.reminders.errorDelete'));
    }
  };

  const handleCancel = () => {
    setDeleteReminderModal(false);
  };

  const handleChangeDate = (data: any) => {
    const endDate = data.endDate;
    const startDate = data.startDate;

    setParams((prevState: any) => ({
      ...prevState,
      endDate,
      startDate,
    }));
  };

  const addReminderHandle = () => {
    setEditReminderModal(true);
  };

  return (
    <Box height={'322px'} display={'flex'} flexDirection={'column'} gap={2}>
      <RemindersBox
        web={web}
        handleSendData={handleChangeDate}
        reminders={remindersRes?.documents || []}
        monthlyCalendarOpen={monthlyCalendarOpen}
        handleClickReminder={handleReminderClick}
        setMonthlyCalendarOpen={setMonthlyCalendarOpen}
        heading={
          category === 'medication'
            ? `${t('reminders.titles.medReminders')}`
            : `${t('reminders.titles.reminders')}`
        }
      />
      {category === 'medication' && (
        <Box>
          <CustomButton variant={'contained'} onClick={addReminderHandle}>
            {t('reminders.text.addNew')}
          </CustomButton>
        </Box>
      )}
      <ModalMobile
        open={viewReminderModal !== ''}
        setOpen={() => setViewReminderModal('')}
      >
        <Box padding={2} boxSizing={'border-box'} width={'400px'} bgcolor={'#F2F1F3'}>
          <ReminderInfoPanel
            web
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            reminderData={reminderData}
          />
        </Box>
      </ModalMobile>
      <ModalMobile open={editReminderModal} setOpen={setEditReminderModal}>
        <ModalSetReminderComponent
          selectedReminder={reminderData}
          handleSaveModal={handleSaveReminder}
          preset={category === 'medication' ? 'medication' : undefined}
          modalTitle={
            reminderData ? `${t('buttons.editReminder')}` : `${t('buttons.addReminder')}`
          }
        />
      </ModalMobile>
      <ModalDeleteReminder
        open={deleteReminderModal}
        handleCancel={handleCancel}
        setOpen={setDeleteReminderModal}
        handleDelete={handleDeleteReminder}
      />
    </Box>
  );
};

export default RemindersDashboard;
