import React, { FC, useState } from 'react';
import { Box } from '@mui/material';
import ComponentsLayout from './ComponentsLayout';
import CustomInput from './controls/CustomInput';
import CustomButton from './controls/CustomButton';
import { useTranslation } from 'react-i18next';

type SettingsProps = {
  handleChangeEmail: (newEmail: string, password: string) => void;
};
const SettingsEmailPanelMobile: FC<SettingsProps> = ({ handleChangeEmail }) => {
  const { t } = useTranslation();

  const [count, setCount] = useState(0);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const clickFinal = () => {
    if (count === 0) {
      setCount(1);
      return;
    }
    handleChangeEmail(email, password);
  };

  return (
    <ComponentsLayout>
      <Box display={'flex'} flexDirection={'column'} gap={2}>
        {count === 0 ? (
          <CustomInput
            fullWidth
            value={email}
            handleChange={setEmail}
            label={t('profile.text.enterNewEmail')}
          />
        ) : null}
        {count === 1 ? (
          <CustomInput
            fullWidth
            value={password}
            type={'password'}
            handleChange={setPassword}
            label={t('profile.text.enterPass')}
            helperText={t('profile.text.mustEnterPass')}
          />
        ) : null}
        <Box width={'288px'} margin={'0 auto'}>
          <CustomButton
            fullWidth
            onClick={clickFinal}
            variant={'contained'}
            disabled={count === 0 && !email}
          >
            {count === 0 ? t('buttons.next') : t('buttons.changeEmail')}
          </CustomButton>
        </Box>
      </Box>
    </ComponentsLayout>
  );
};

export default SettingsEmailPanelMobile;
