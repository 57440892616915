import api from '../../../redux/api';
import { ApiResult, AuthUser } from '@hdcorner/ui-library';

const signUpQueries = api.injectEndpoints({
  endpoints: build => ({
    signUp: build.mutation<ApiResult<string>, { email: string; password: string }>({
      query: ({ email, password }) => ({
        method: 'POST',
        url: '/authentication/local/new',
        body: {
          email,
          password,
          redirectUri: process.env.REACT_APP_VERIFICATION_REDIRECT_URI,
        },
      }),
    }),

    setAuthUserRole: build.mutation<AuthUser, void>({
      query: () => ({
        method: 'PUT',
        body: { role: 'user' },
        url: '/database/function/setAuthUser',
      }),
      invalidatesTags: ['AuthUser'],
    }),

    authorizeUser: build.mutation<ApiResult<string>, void>({
      query: () => ({
        method: 'POST',
        url: '/hdCornerService/user/authorization',
      }),
    }),

    changeSubscription: build.mutation<ApiResult<string>, { premium: boolean }>({
      query: ({ premium }) => ({
        method: 'PATCH',
        body: { toPremium: premium },
        url: '/hdCornerService/user/change-subscription',
      }),
      invalidatesTags: ['AuthUser', 'UserData'],
    }),
  }),
});

export const { useSetAuthUserRoleMutation, useSignUpMutation, useAuthorizeUserMutation } =
  signUpQueries;

export default signUpQueries;
