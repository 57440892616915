import api from '../../../../redux/api';
import { ApiPagination, TipsTricks } from '@hdcorner/ui-library';

const tipsTricksQueries = api.injectEndpoints({
  endpoints: build => ({
    getTipsAndTricks: build.query<
      { trafficLights: TipsTricks[] },
      ApiPagination & { level?: string; lang: string }
    >({
      query: ({ level, lang, skip, limit, sort }) => ({
        method: 'GET',
        params: { level, lang, skip, limit, sort },
        url: '/hdCornerService/user/traffic-lights',
      }),
      providesTags: ['TipsTricks'],
    }),
  }),
});

export const { useGetTipsAndTricksQuery } = tipsTricksQueries;

export default tipsTricksQueries;
