import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: {
  toggle: string;
  measurement: string;
} = {
  measurement: 'all',
  toggle: 'in-depth info',
};

const hypertensionSlice = createSlice({
  name: 'hypertension',
  initialState,
  reducers: {
    updateMeasurementCategory: (state, action: PayloadAction<string>) => {
      state.measurement = action.payload;
    },
    updateToggle: (state, action: PayloadAction<string>) => {
      state.toggle = action.payload;
    },
  },
});

export const { updateToggle, updateMeasurementCategory } = hypertensionSlice.actions;

export default hypertensionSlice.reducer;
