import moment from 'moment';
import { useMemo } from 'react';
import { ProgressEnum } from '../enums/ProgressEnum';
import { PaginationResult } from '../types/ApiPagination';
import { CvdRiskLog } from '../types/CvdRiskLog';
import { calculateCvdRange } from '../utils/calculateRange';
import { useTranslation } from 'react-i18next';

const usePrepareInDepthData = (cvdRiskLogs?: PaginationResult<CvdRiskLog>) => {
  const { t } = useTranslation();
  const getProgress = (log: CvdRiskLog) => {
    const cvdState = calculateCvdRange(log.score);
    switch (cvdState) {
      case 'low':
        return ProgressEnum.success;
      case 'warning':
        return ProgressEnum.warning;
      case 'high_risk':
        return ProgressEnum.risk;
      case 'very_high_risk':
        return ProgressEnum.error;
    }
  };

  return useMemo(() => {
    if (!cvdRiskLogs) return [];

    return cvdRiskLogs.logs.map(log => {
      return {
        _id: log._id,
        progress: getProgress(log),
        values: {
          'Overall Risk': { value: log.score + '%' },
          [t('lipid.labels.totalChol')]: {
            value: `${log.totalChol || '-'} ${t('measurements.mgdl')}`,
          },
          [t('dashboard.dashcards.hypertension.systolic')]: {
            value: `${log.sysBloodPressure || '-'} ${t('measurements.mmGH')}`,
          },
          [t('inDepth.timeOfCalc')]: { value: moment(log.logDate).format('h:mm A') },
          [t('inDepth.dateOfCalc')]: {
            value: moment(log.logDate).format('MMM Do, YYYY'),
          },
          // 'Diastolic Pressure': { value: `${log.diaBloodPressure || '-'} mmHG` },
          // 'Treated for high blood pressure': {
          //   value: `${log.treatedForHighBloodPressure ? 'Yes' : 'No'}`,
          // },
          // HDL: { value: `${log.hdl || '-'} mg/dl` },
        },
      };
    });
  }, [cvdRiskLogs]);
};

export default usePrepareInDepthData;
