import * as React from 'react';
import { SVGProps } from 'react';

const NotGoodEmojiOutlined = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={30}
    height={30}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.2455 23.6997C16.897 21.3683 13.0992 21.3765 10.7584 23.7258C10.3686 24.117 9.73547 24.1182 9.34424 23.7284C8.953 23.3386 8.95184 22.7054 9.34165 22.3142C12.4609 19.1835 17.5231 19.1716 20.6546 22.2803C21.0465 22.6694 21.0488 23.3025 20.6597 23.6945C20.2706 24.0865 19.6375 24.0888 19.2455 23.6997Z"
      fill="#212121"
    />
    <path
      d="M10 16C11.3807 16 12.5 14.8807 12.5 13.5C12.5 12.1193 11.3807 11 10 11C8.61929 11 7.5 12.1193 7.5 13.5C7.5 14.8807 8.61929 16 10 16Z"
      fill="#212121"
    />
    <path
      d="M20 16C21.3807 16 22.5 14.8807 22.5 13.5C22.5 12.1193 21.3807 11 20 11C18.6193 11 17.5 12.1193 17.5 13.5C17.5 14.8807 18.6193 16 20 16Z"
      fill="#212121"
    />
    <path
      d="M14.9989 0C10.0952 0 6.313 1.65419 3.76342 4.42276C1.22626 7.17785 0 10.9378 0 14.9989C0 19.06 1.22626 22.8199 3.76342 25.575C6.313 28.3436 10.0952 29.9978 14.9989 29.9978C19.9026 29.9978 23.6848 28.3436 26.2343 25.575C28.7715 22.8199 29.9978 19.06 29.9978 14.9989C29.9978 10.9378 28.7715 7.17785 26.2343 4.42276C23.6848 1.65419 19.9026 0 14.9989 0ZM2 14.9989C2 11.3286 3.10595 8.08911 5.23462 5.77759C7.35088 3.47956 10.5681 2 14.9989 2C19.4296 2 22.6469 3.47956 24.7631 5.77759C26.8918 8.08911 27.9978 11.3286 27.9978 14.9989C27.9978 18.6691 26.8918 21.9087 24.7631 24.2202C22.6469 26.5182 19.4296 27.9978 14.9989 27.9978C10.5681 27.9978 7.35088 26.5182 5.23462 24.2202C3.10595 21.9087 2 18.6691 2 14.9989Z"
      fill="#212121"
    />
  </svg>
);

export default NotGoodEmojiOutlined;
