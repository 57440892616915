import React, { FC } from 'react';
import { CustomButton, theme } from '@hdcorner/ui-library';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Questions0of9Props = {
  web: boolean;
  incrementCount: (data: any) => void;
};
const Questions0of4: FC<Questions0of9Props> = ({ web, incrementCount }) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography
        variant={'subtitle2'}
        align={web ? 'center' : 'left'}
        sx={{ fontWeight: theme.typography.fontWeightRegular }}
      >
        {t('authentication.onboarding.improveCadio')}
      </Typography>
      <Box margin={theme.spacing(3, 0)}>
        <CustomButton fullWidth variant={'contained'} onClick={incrementCount}>
          {t('buttons.letsStart')}
        </CustomButton>
      </Box>
    </>
  );
};

export default Questions0of4;
