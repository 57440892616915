import React, { FC } from 'react';
import { Box, BoxProps, styled, Typography, TypographyProps } from '@mui/material';

const Circle = styled(Box)<BoxProps>(({ theme }) => ({
  width: '24px',
  height: '24px',
  display: 'flex',
  borderRadius: '50px',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.primary.main,
}));

const CircleText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.secondary.light,
  fontSize: theme.typography.subtitle1.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
}));

type Props = {
  count: string | number;
};
const CircleCounter: FC<Props> = ({ count }) => {
  return (
    <Circle>
      <CircleText>{count}</CircleText>
    </Circle>
  );
};

export default CircleCounter;
