import React, { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import WorkoutsPanel from './WorkoutsPanel';
import AvatarSelectorComponent from './AvatarSelectorComponent';
import ProgramVideos from './ProgramVideos';

type Props = {
  goToAvatar: boolean;
};
const WebWorkouts: FC<Props> = ({ goToAvatar }) => {
  const [display, setDisplay] = useState<string>('default');

  useEffect(() => {
    if (goToAvatar) {
      setDisplay('avatar');
    }
  }, [goToAvatar]);

  return (
    <Box>
      <Box display={display === 'default' ? 'block' : 'none'}>
        <WorkoutsPanel
          handleChangeAvatar={() => setDisplay('avatar')}
          onViewProgramClick={() => setDisplay('programDetails')}
        />
      </Box>
      <Box display={display === 'avatar' ? 'block' : 'none'}>
        <AvatarSelectorComponent onClick={() => setDisplay('default')} />
      </Box>
      <Box display={display === 'programDetails' ? 'block' : 'none'}>
        <ProgramVideos onWebClick={() => setDisplay('default')} />
      </Box>
    </Box>
  );
};

export default WebWorkouts;
