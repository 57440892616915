import React, { FC, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { AuthUser, CustomButton, CustomInput, DropdownMenu } from '@hdcorner/ui-library';
import { useTranslation } from 'react-i18next';
import { useGetAuthUserQuery } from '../../queries/signInQueries';

type UserDetailsWebOneProps = {
  loading: boolean;
  handleNextPage: (data: Partial<AuthUser>) => void;
};
const UserDetailsWebOne: FC<UserDetailsWebOneProps> = ({ loading, handleNextPage }) => {
  const { t } = useTranslation();

  const [gender, setGender] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [birthDate, setBirthDate] = useState<string>('');

  const { data: authUserResponse } = useGetAuthUserQuery();

  useEffect(() => {
    if (authUserResponse && authUserResponse.length > 0) {
      const { firstName, lastName, apple, gender, birthDate } = authUserResponse[0];
      setGender(gender || '');
      setBirthDate(birthDate || '');
      if (apple && !firstName && !lastName) {
        const { data } = apple;
        const nameObj = data?.name;
        const name = nameObj || { firstName: '', lastName: '' };
        const { firstName: appleFirstName, lastName: appleLastName } = name;
        setLastName(appleLastName || '');
        setFirstName(appleFirstName || '');
      } else {
        setLastName(lastName);
        setFirstName(firstName);
      }
    }
  }, [authUserResponse]);

  const handleSaveData = () => {
    const data = { firstName, lastName, birthDate, gender };
    handleNextPage(data);
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={6}>
      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <CustomInput
          fullWidth
          value={firstName}
          handleChange={setFirstName}
          label={`${t('userDetails.fName')}`}
        />

        <CustomInput
          fullWidth
          value={lastName}
          handleChange={setLastName}
          label={`${t('userDetails.lName')}`}
        />

        <CustomInput
          fullWidth
          type={'date'}
          value={birthDate}
          handleChange={setBirthDate}
          label={`${t('userDetails.dob')}`}
          placeholder={`${t('userDetails.chooseDate')}`}
          sx={{
            'input[type="date"]::-webkit-calendar-picker-indicator': {
              display: 'none',
              webkitAppearance: 'none',
            },
          }}
        />

        <DropdownMenu
          value={gender}
          placeholder={'-'}
          setValue={setGender}
          label={`${t('userDetails.sex')}`}
          menuItems={[
            { label: `${t('userDetails.male')}`, value: 'male' },
            { label: `${t('userDetails.female')}`, value: 'female' },
          ]}
        />
      </Box>

      <CustomButton
        fullWidth
        variant={'contained'}
        onClick={handleSaveData}
        disabled={!firstName || !lastName || birthDate === '' || gender === '' || loading}
      >
        Continue
      </CustomButton>
    </Box>
  );
};

export default UserDetailsWebOne;
