import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
  useMediaQuery,
} from '@mui/material';
import moment from 'moment';
import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  ArrowLeft,
  ArrowRight,
  ConduitFile,
  CustomButton,
  theme,
} from '@hdcorner/ui-library';
import useAlert from '../../../../hooks/useAlert';
import { useAppSelector } from '../../../../redux/hooks';
import { useGetArticleByIdQuery } from '../queries/articlesQueries';
import { convertArticleCategory, findArticleIndex } from '../utilities/articleUtils';
import { useTranslation } from 'react-i18next';

const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

const ImageFrame = styled(Box, { shouldForwardProp: propName => propName !== 'web' })<
  BoxProps & { web: boolean }
>(({ web, theme }) => ({
  width: '100%',
  display: 'flex',
  borderRadius: '8px',
  backgroundSize: 'cover',
  boxShadow: theme.shadows[1],
  height: web ? '308px' : '160px',
}));

const DetailsText = styled(Typography, {
  shouldForwardProp: propName => propName !== 'bold',
})<
  TypographyProps & {
    bold: boolean;
    dangerouslySetInnerHTML?: {
      __html: string;
    };
  }
>(({ bold, theme }) => ({
  fontSize: theme.typography.subtitle2.fontSize,
  fontWeight: bold
    ? theme.typography.fontWeightRegular
    : theme.typography.subtitle2.fontWeight,
}));

const ButtonText = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: theme.typography.subtitle1.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
  textTransform: lang === 'el' ? 'capitalize' : 'uppercase',
}));

type Props = {
  handleGoBack?: () => void;
};
const ArticleDetailsInner: FC<Props> = ({ handleGoBack }) => {
  const { t } = useTranslation();
  const { presentError } = useAlert();
  const web = useMediaQuery(theme.breakpoints.up('md'));

  const { article, idArray } = useAppSelector(state => state.articles);

  const [articleID, setArticleID] = useState<string>(article.id);

  const { data: articleRes, error } = useGetArticleByIdQuery({ id: articleID });

  useEffect(() => {
    if (error) {
      presentError(t('errors.fitness.nutrition.errorArticleFetch'));
    }
  }, [error]);

  useEffect(() => {
    setArticleID(article.id);
  }, [article]);

  const data = useMemo(() => {
    if (!articleRes) return;

    return {
      author: articleRes.author,
      title: articleRes.title || '',
      details: articleRes.description,
      image: (articleRes.image as ConduitFile)?.url || '',
      category: convertArticleCategory(articleRes.category) || '',
      createdAt: moment(articleRes.createdAt).format('DD/MM/YYYY'),
    };
  }, [articleRes]);

  const currentIndex = useMemo(() => {
    return findArticleIndex(idArray, articleID);
  }, [idArray, articleID]);

  const handleChangeArticle = (next: boolean) => {
    if (next) {
      const nextArticle = findArticleIndex(idArray, articleID) + 1;
      setArticleID(idArray[nextArticle]);
    } else {
      const nextArticle = findArticleIndex(idArray, articleID) - 1;
      setArticleID(idArray[nextArticle]);
    }
  };

  return (
    <Box>
      {data && (
        <Box mb={3} gap={3} display={web ? 'flex' : 'none'} flexDirection={'column'}>
          <Box
            alignItems={'center'}
            justifyContent={'flex-start'}
            display={!idArray || idArray.length === 0 ? 'none' : 'flex'}
          >
            <CustomButton variant={'text'} sx={{ padding: 0 }} onClick={handleGoBack}>
              <DetailsText bold={false}>{`${data.category} /`}</DetailsText>
            </CustomButton>
            <DetailsText bold>{data.title}</DetailsText>
          </Box>
          <Box mb={3} flex={1} position={'relative'}>
            <Box
              left={0}
              position={'absolute'}
              display={currentIndex === 0 ? 'none' : 'flex'}
            >
              <CustomButton
                variant={'text'}
                onClick={() => handleChangeArticle(false)}
                startIcon={<ArrowLeft width={'10px'} height={'16px'} />}
              >
                <ButtonText>{t('buttons.prevArticle')}</ButtonText>
              </CustomButton>
            </Box>
            <Box
              left={0}
              position={'absolute'}
              display={web && currentIndex === 0 ? 'flex' : 'none'}
            >
              <CustomButton
                variant={'text'}
                onClick={handleGoBack}
                startIcon={<ArrowLeft width={'10px'} height={'16px'} />}
              >
                <ButtonText>{t('buttons.back')}</ButtonText>
              </CustomButton>
            </Box>
            <Box
              right={0}
              position={'absolute'}
              display={currentIndex + 1 >= idArray.length ? 'none' : 'flex'}
            >
              <CustomButton
                variant={'text'}
                onClick={() => handleChangeArticle(true)}
                endIcon={<ArrowRight width={'10px'} height={'16px'} />}
              >
                <ButtonText>{t('buttons.nextArticle')}</ButtonText>
              </CustomButton>
            </Box>
          </Box>
        </Box>
      )}
      <Box
        gap={3}
        display={'flex'}
        margin={'0 auto'}
        flexDirection={'column'}
        maxWidth={web ? '800px' : 'none'}
      >
        {data?.image !== '' && (
          <ImageFrame web={web} sx={{ backgroundImage: `url(${data?.image})` }} />
        )}
        {data && (
          <>
            <DetailsText bold={false}>{`${data.author} • ${data.createdAt}`}</DetailsText>
            <Typography variant={'h1'}>{data.title}</Typography>
            <DetailsText bold={!web} dangerouslySetInnerHTML={{ __html: data.details }} />
          </>
        )}
      </Box>
    </Box>
  );
};

export default ArticleDetailsInner;
