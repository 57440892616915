import * as React from 'react';
import { CSSProperties, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import {
  AllMeasurements,
  BarChart,
  CalendarModal,
  ChartTypeButtons,
  ColumnsIcon,
  ComponentsLayout,
  CustomSlider,
  DayScatterChart,
  dayWeekMonthYearCustom,
  FeelsEmpty,
  LineChart,
  MeasurementsContainer,
  ModalFilters,
  theme,
  ToggleButtons,
} from '@hdcorner/ui-library';
import { Button, styled, Typography, TypographyProps } from '@mui/material';
import InDepthDatePicker from '../../components/InDepthDatePicker';
import PageLayout from '../../components/PageLayout';
import useTimeframe from '../../hooks/useTimeframe';
import LinearGraphIcon from '../../assets/icons/LinearGraphIcon';
import { useParams } from 'react-router';
import usePrepareGraph from './hooks/usePrepareGraph';
import {
  useGetBloodGlucoseGraphDataQuery,
  useGetHcac1GraphDataQuery,
} from './queries/diabetesQueries';
import { FilterOptions } from './constants';
import useFilterLabel from './hooks/useFilterLabel';
import UpdateIcon from '@mui/icons-material/Update';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import useAlert from '../../hooks/useAlert';
import { useConvertJSON } from '../../utils/useConvertJSON';

const icons = [
  { name: 'lineChart', icon: LinearGraphIcon },
  { name: 'barChart', icon: ColumnsIcon },
  { name: '24hours', icon: UpdateIcon },
];

const GraphContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderRadius: '8px',
  flexDirection: 'column',
  backgroundColor: 'white',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
}));

const GraphTypography = styled(Typography, {
  shouldForwardProp: propName => propName !== 'dotColor',
})<TypographyProps & { dotColor?: CSSProperties['color'] }>(({ dotColor, theme }) => ({
  position: 'relative',
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightRegular,
  '&::before': {
    top: '50%',
    width: '8px',
    height: '8px',
    left: '-16px',
    content: '" "',
    borderRadius: '50%',
    position: 'absolute',
    transform: 'translateY(-50%)',
    backgroundColor: dotColor ? dotColor : '#222222',
  },
}));

const DiabetesGraphDataPage = () => {
  const { t } = useTranslation();
  const { type } = useParams<{ type: string }>();
  const { presentError } = useAlert();

  const [limits, setLimits] = useState<number[]>([100, 300]);
  const [calendarModalOpen, setCalendarModalOpen] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<string>('default');
  const [categorySelectModal, setCategorySelectModal] = useState<boolean>(false);
  //for pressure
  const [iconTogglePressure, setIconTogglePressure] = useState<string>(icons[0].name);
  const [dayTogglePressure, setDayTogglePressure] = useState<string>('all');
  // Used for updating the params for the query
  const [endParam, setEndParam] = useState<string>('');
  const [startParam, setStartParam] = useState<string>('');
  const [availableIcons, setAvailableIcons] = useState<any[]>([]);

  const timeframe = useTimeframe(dayTogglePressure, startParam, endParam);

  useEffect(() => {
    if (type === 'blood-glucose') {
      setAvailableIcons(icons);
    } else {
      setAvailableIcons([icons[0]]);
    }
  }, [type]);

  const { data: graphDataRes, error: bloodGlucoseError } =
    useGetBloodGlucoseGraphDataQuery({
      category: selectedCategory === 'default' ? undefined : selectedCategory,
      ...timeframe,
    });

  const { data: hbacGraphData, error: hbacError } = useGetHcac1GraphDataQuery({
    ...timeframe,
  });

  useEffect(() => {
    if (bloodGlucoseError) {
      presentError(t('errors.diabetes.errorGlucose.fetchGraph'));
    }
    if (hbacError) {
      presentError(t('errors.diabetes.errorsHba1c.fetchGraph'));
    }
  }, [bloodGlucoseError, hbacError]);

  const graphData = usePrepareGraph(
    type === 'blood-glucose' ? graphDataRes : hbacGraphData,
  );

  const dayScatterChartData = useMemo(() => {
    const data = graphData.map(item => {
      const today = moment().utc(true);
      const hour = moment(item.x).format('HH');
      const minute = moment(item.x).format('mm');
      const currentDate = today
        .startOf('day')
        .add(hour, 'hours')
        .add(minute, 'minutes')
        .toDate()
        .getTime();
      const value = item.y;
      return { x: currentDate, y: value };
    });
    return [{ name: 'BloodGlucose', data }];
  }, [graphData]);

  const barChartData = useMemo(() => {
    const lowerLimit = limits[0];
    const upperLimit = limits[1];

    // extract the percentages of below limit, within limit and above limit
    const belowLimit = graphData.filter(item => item.y < lowerLimit).length;
    const withinLimit = graphData.filter(
      item => item.y >= lowerLimit && item.y <= upperLimit,
    ).length;
    const aboveLimit = graphData.filter(item => item.y > upperLimit).length;

    // calculate the percentages
    const total = belowLimit + withinLimit + aboveLimit;
    const belowLimitPercent = (belowLimit / total) * 100;
    const withinLimitPercent = (withinLimit / total) * 100;
    const aboveLimitPercent = (aboveLimit / total) * 100;

    return [
      {
        x: t('general.labels.belowLim'),
        fillColor: '#979FA580',
        y: Math.round(belowLimitPercent),
      },
      {
        x: t('general.labels.withinLim'),
        fillColor: '#4780EF80',
        y: Math.round(withinLimitPercent),
      },
      {
        x: t('general.labels.aboveLim'),
        fillColor: '#E1205A80',
        y: Math.round(aboveLimitPercent),
      },
    ];
  }, [graphData, limits]);

  const filterLabel = useFilterLabel(selectedCategory);

  const handleDateChange = (dateRange: string[]) => {
    setDayTogglePressure('custom');
    setStartParam(dateRange[0]);
    setEndParam(dateRange[1]);
  };

  const filteredToggle = dayWeekMonthYearCustom.filter(item => item.value !== 'custom');

  return (
    <PageLayout
      defaultHref={'/dashboard/diabetes'}
      headerTitle={t('buttons.navigation.graphData')}
    >
      <ComponentsLayout display={'flex'} flexDirection={'column'} gap={theme.spacing(1)}>
        <MeasurementsContainer>
          {type === 'blood-glucose' && (
            <AllMeasurements
              flex={2}
              text={filterLabel}
              openModal={() => setCategorySelectModal(true)}
            />
          )}
          <InDepthDatePicker flex={1} onClick={() => setCalendarModalOpen(true)} />
        </MeasurementsContainer>
        <GraphContainer>
          <Box display={'flex'} justifyContent={'space-between'}>
            <ToggleButtons
              value={dayTogglePressure}
              handleChange={setDayTogglePressure}
              headings={useConvertJSON(filteredToggle)}
            />
            <ChartTypeButtons
              icons={availableIcons}
              iconValue={iconTogglePressure}
              setIconValue={setIconTogglePressure}
            />
          </Box>
          {graphData.length > 0 && iconTogglePressure === 'lineChart' && (
            <LineChart
              height={'250px'}
              yAnnotations={
                type === 'blood-glucose'
                  ? [
                      {
                        from: 0,
                        to: limits[0],
                        fillColor: '#979FA580',
                      },
                      {
                        to: limits[1],
                        from: limits[0],
                        fillColor: '#4780EF80',
                      },
                      {
                        to: 600,
                        from: limits[1],
                        fillColor: '#E1205A80',
                      },
                    ]
                  : []
              }
              chartData={[
                {
                  name:
                    type === 'blood-glucose'
                      ? t('headingsTitles.bloodGluc')
                      : t('headingsTitles.hba1c'),
                  data: graphData,
                },
              ]}
              colors={[theme.palette.error.main, theme.palette.warning.main]}
            />
          )}
          {graphData.length > 0 && iconTogglePressure === 'barChart' && (
            <BarChart chartData={barChartData} />
          )}
          {graphData.length > 0 && iconTogglePressure === '24hours' && (
            <DayScatterChart
              height={'250px'}
              xAxisAnnotations={[
                {
                  borderWidth: 2,
                  strokeDashArray: 4,
                  borderColor: 'red',
                  x: moment().utc(true).startOf('day').add(8, 'hours').toDate().getTime(),
                },
                {
                  borderWidth: 2,
                  strokeDashArray: 4,
                  borderColor: 'red',
                  x: moment()
                    .utc(true)
                    .startOf('day')
                    .add(16, 'hours')
                    .toDate()
                    .getTime(),
                },
              ]}
              yAxisAnnotations={[
                {
                  y: 0,
                  y2: limits[0],
                  fillColor: '#979FA580',
                },
                {
                  y: limits[0],
                  y2: limits[1],
                  fillColor: '#4780EF80',
                },
                {
                  y2: 600,
                  y: limits[1],
                  fillColor: '#E1205A80',
                },
              ]}
              chartData={dayScatterChartData}
            />
          )}
          {graphData.length === 0 && <FeelsEmpty />}
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            gap={theme.spacing(3)}
            paddingLeft={theme.spacing(2)}
          >
            <GraphTypography dotColor={theme.palette.warning.main}>
              {type === 'blood-glucose'
                ? t('headingsTitles.bloodGluc')
                : t('headingsTitles.hba1c')}
            </GraphTypography>
          </Box>
        </GraphContainer>
        {type === 'blood-glucose' && (
          <GraphContainer>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography
                fontSize={14}
                variant={'subtitle1'}
                color={theme.palette.primary.main}
                marginBottom={theme.spacing(1)}
                fontWeight={theme.typography.fontWeightMedium}
              >
                {t('diabetes.graph.text.change')}
              </Typography>
              <Button
                onClick={() => setLimits([100, 300])}
                sx={{
                  paddingY: '5px',
                  display: 'flex',
                  paddingX: '20px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: theme.palette.medication.main,
                }}
              >
                <Typography
                  fontSize={12}
                  variant={'subtitle1'}
                  color={theme.palette.secondary.main}
                  fontWeight={theme.typography.fontWeightMedium}
                >
                  {t('buttons.resetLimits')}
                </Typography>
              </Button>
            </Box>
            <CustomSlider
              min={80}
              max={600}
              customThumbIcon
              selected={limits}
              valueLabelDisplay={'on'}
              sx={{ color: '#4780EF80' }}
              setSliderValue={value => setLimits(value as number[])}
            />
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography
                fontSize={12}
                variant={'subtitle1'}
                color={theme.palette.primary.main}
                marginBottom={theme.spacing(1)}
                fontWeight={theme.typography.fontWeightMedium}
              >
                {`80 ${t('measurements.mgdl')}`}
              </Typography>
              <Typography
                fontSize={12}
                variant={'subtitle1'}
                color={theme.palette.primary.main}
                marginBottom={theme.spacing(1)}
                fontWeight={theme.typography.fontWeightMedium}
              >
                {`600 ${t('measurements.mgdl')}`}
              </Typography>
            </Box>
          </GraphContainer>
        )}
      </ComponentsLayout>
      <ModalFilters
        open={categorySelectModal}
        selected={selectedCategory}
        options={useConvertJSON(FilterOptions)}
        dismiss={(newValue: string) => {
          setSelectedCategory(newValue);
          setCategorySelectModal(false);
        }}
      />
      <CalendarModal
        open={calendarModalOpen}
        saveDate={handleDateChange}
        setOpen={setCalendarModalOpen}
        selectedDate={[startParam, endParam]}
      />
    </PageLayout>
  );
};

export default DiabetesGraphDataPage;
