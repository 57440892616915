import React from 'react';
import { ComponentsLayout } from '@hdcorner/ui-library';
import PageLayout from '../../../components/PageLayout';
import SectionGoalActivities from './components/SectionGoalActivities';
import { useTranslation } from 'react-i18next';

const FitnessGoalActivity = () => {
  const { t } = useTranslation();

  return (
    <PageLayout
      defaultHref={'/dashboard/fitness/exercise'}
      headerTitle={t('headingsTitles.activityGoal')}
    >
      <ComponentsLayout>
        <SectionGoalActivities />
      </ComponentsLayout>
    </PageLayout>
  );
};
export default FitnessGoalActivity;
