import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import DeleteIcon from '../../assets/DeleteIcon';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SelectCropIcon from '../../assets/SelectCropIcon';
import FilePreviewIcon from '../../assets/FilePreviewIcon';
import ImagePreviewIcon from '../../assets/ImagePreviewIcon';
import {
  Box,
  BoxProps,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const FileContainer = styled(Box, {
  shouldForwardProp: propName => propName !== 'selected' && propName !== 'image',
})<BoxProps & { image: boolean; selected: boolean }>(({ image, theme, selected }) => ({
  width: '100%',
  display: 'flex',
  maxWidth: '222px',
  borderRadius: '8px',
  alignItems: 'center',
  boxSizing: 'border-box',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: theme.spacing(3),
  backgroundColor: theme.palette.secondary.light,
  padding: theme.spacing(1, 0, 0, 0),
  border: selected
    ? `2px solid ${image ? theme.palette.medication.main : theme.palette.diabetes.main}`
    : 'none',
}));

type FileType = {
  name: string;
  fileSize: string;
  category: string;
  selected?: boolean;
  type: 'file' | 'image';
  handleEdit: () => void;
  handleSelect?: () => void;
  handleDelete?: () => void;
};
const FileRecordCard: FC<FileType> = ({
  type,
  name,
  fileSize,
  category,
  selected = false,
  handleEdit,
  handleSelect,
  handleDelete,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    handleEdit();
    setAnchorEl(null);
  };

  const handleDeleteClick = () => {
    if (!handleDelete) return;
    handleDelete();
    setAnchorEl(null);
  };

  const handleSelectClick = () => {
    if (!handleSelect) return;
    handleSelect();
    setAnchorEl(null);
  };

  return (
    <FileContainer selected={selected} image={type === 'image'}>
      <Box
        width={'100%'}
        paddingLeft={1}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Typography
          variant={'subtitle2'}
          fontWeight={'fontWeightMedium'}
          sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        >
          {name}
        </Typography>
        <IconButton onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
      </Box>
      <Box>
        {type === 'file' && <FilePreviewIcon />}
        {type === 'image' && <ImagePreviewIcon />}
      </Box>
      <Box
        padding={1}
        width={'100%'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          background: theme.palette.highlight.main,
        }}
      >
        <Typography variant="body1" fontWeight={'fontWeightRegular'}>
          {category}
        </Typography>
        <Typography variant="caption" fontWeight={'fontWeightBold'}>
          {fileSize}
        </Typography>
      </Box>
      <Menu open={open} id="basic-menu" anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem onClick={handleEditClick}>
          <ListItemIcon>
            <ImagePreviewIcon
              width={'14px'}
              height={'12px'}
              color={theme.palette.primary.main}
            />
          </ListItemIcon>
          <Typography variant={'subtitle2'} fontWeight={'fontWeightBold'}>
            {t('general.labels.view')}
          </Typography>
        </MenuItem>
        {handleSelect && (
          <MenuItem onClick={handleSelectClick}>
            <ListItemIcon>
              <SelectCropIcon />
            </ListItemIcon>
            <Typography variant={'subtitle2'} fontWeight={'fontWeightBold'}>
              {selected ? t('general.labels.unselect') : t('general.labels.select')}
            </Typography>
          </MenuItem>
        )}
        {handleDelete && (
          <MenuItem onClick={handleDeleteClick}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <Typography variant={'subtitle2'} fontWeight={'fontWeightBold'}>
              {t('buttons.delete')}
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </FileContainer>
  );
};

export default FileRecordCard;
