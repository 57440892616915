import * as React from 'react';
import { SVGProps } from 'react';
import { lighten, useTheme } from '@mui/material';

const CardBackgroundSmall = ({
  fill = useTheme().palette.primary.main,
  ...rest
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={350}
      height={68}
      fill="none"
      viewBox={'0 0 350 68'}
      preserveAspectRatio={'none'}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect width={350} height={68} fill={fill} />
      <path
        d="M342 68a8 8 0 0 0 8-8V8a8 8 0 0 0-8-8H242c13.881 20.226 28.245 43.112 41.925 68H342Z"
        fill={lighten(fill, 0.1)}
      />
    </svg>
  );
};

export default CardBackgroundSmall;
