import { Box, Typography } from '@mui/material';

const TermsAndConditionsEN = () => {
  return (
    <Box>
      <Box>
        <Typography variant={'h1'}>TERMS OF USE</Typography>
        <Typography variant={'body1'} paragraph>
          These terms of use govern the relationship between you, the users of the
          Application, and the company " KARABINIS MEDICAL SA-Manufacture –Sale of
          Technological Products and Providing Services ", based in Peania Attica, on
          Lavriou Avenue, no. 151, with A.F.M.: 099052507 – D.O.Y.: FAE Athens- NO. GEMI
          87183102000, tel. 2106645751. ("we", "us", "our" or "Company") regarding your
          use of the services of the HD Corner Application -web or mobile app (the
          "Application" or "Service" or " HD Corner "), and all information, text,
          graphics, software and services available for the use of the Application (the "
          Content").
        </Typography>
        <Typography variant={'body1'} paragraph>
          These Terms constitute the legally binding agreement between you and the Company
          for the use of the Application. For this reason, PLEASE READ THE TERMS CAREFULLY
          BEFORE USING THE SERVICE.
        </Typography>
        <Typography variant={'body1'} paragraph>
          The Company reserves the right to change or modify the applicable terms and
          conditions for the use of the Website and the Application at its own discretion
          and at any time, always taking into account the relevant conditions set forth by
          the current legislation
        </Typography>
        <Typography variant={'subtitle1'} paragraph>
          DISCLAIMER
        </Typography>
        <Typography variant={'body1'} paragraph>
          THE SERVICES AND THE CONTENT ARE PROVIDED "AS IS", WITHOUT WARRANTIES OF ANY
          KIND.
        </Typography>
        <Typography variant={'body1'} paragraph>
          THE COMPANY DOES NOT PROVIDE MEDICAL ADVICE OF ANY KIND, REGARDING THE
          PREVENTION, DIAGNOSIS OR TREATMENT OF ANY DISEASE OR HEALTH CONDITION
        </Typography>
        <Typography variant={'body1'} paragraph>
          THE SERVICE IS NOT A REPLACEMENT FOR HEALTHCARE PROFESSIONALS BUT IS INTENDED
          ONLY AS A TOOL, WHICH MAY BE USEFUL IN ACHIEVING YOUR GOALS AND OVERALL HEALTH
          AND WELLNESS.
        </Typography>
        <Typography variant={'body1'} paragraph>
          YOU ARE NOT ALLOWED TO USE THE SERVICE AS A SUBSTITUTE FOR MEDICAL ADVICE; IF
          YOU DO SO, IT MAY PUT YOUR HEALTH AT RISK. YOU ACKNOWLEDGE THAT YOU ASSUME FULL
          RESPONSIBILITY FOR YOUR HEALTH, LIFE AND WELL-BEING.
        </Typography>
        <Typography variant={'body1'} paragraph>
          THE CONTENT PROVIDED THROUGH THE SERVICE, WHETHER PROVIDED BY US OR THIRD
          PARTIES (EVEN IF THEY ARE HEALTHCARE PROFESSIONALS), IS NOT INTENDED AND SHOULD
          NOT BE USED AS A SUBSTITUTE FOR VISIT, CALL OR CONSULT YOUR PHYSICIAN OR OTHER
          HEALTH PROFESSIONALS.
        </Typography>
        <Typography variant={'body1'} paragraph>
          IF YOU HAVE ANY QUESTIONS REGARDING YOUR HEALTH, CALL OR VISIT YOUR PHYSICIAN OR
          OTHER HEALTHCARE PROFESSIONAL IMMEDIATELY. IF THERE IS AN EMERGENCY, CALL YOUR
          DOCTOR OR LOCAL EMERGENCY SERVICES IMMEDIATELY.
        </Typography>
        <Typography variant={'body1'} paragraph>
          YOU ACKNOWLEDGE THAT YOUR NUTRITION AND EXERCISE ACTIVITIES INCLUDE A RISK OF
          PERSONAL INJURY OR DEATH AND THAT YOU ASSUME SUCH RISKS BEFORE USING THE SERVICE
          AND AGREE TO RELEASE AND RELEASE THE COMPANY FROM ANY CONSEQUENCES, KNOWN OR
          UNKNOWN, THAT MAY ARISE FROM THE USE OF THE SERVICE .
        </Typography>
        <Typography variant={'body1'} paragraph>
          THE COMPANY ASSUMES NO RESPONSIBILITY FOR ANY INACCURACIES REGARDING FOOD
          RECIPES, EXERCISE ADVICE OR OTHER CONTENT ON THE SERVICE. YOU SHOULD CAREFULLY
          READ ALL INFORMATION PROVIDED BY FOOD PRODUCT MANUFACTURERS, WHETHER ON THE
          INTERNET OR ON THE ACTUAL PRODUCT PACKAGING AND LABELS, INCLUDING NUTRIENT
          CONTENT INGREDIENTS BEFORE USING OR CONSUMING A PRODUCT. FOR ADDITIONAL
          INFORMATION ABOUT A FOOD PRODUCT, CONTACT THE MANUFACTURER DIRECTLY.
        </Typography>
        <Typography variant={'body1'} paragraph>
          THE COMPANY ASSUMES NO RESPONSIBILITY FOR THE SUITABILITY OF THE SERVICE TO YOU
          AND THE LEVEL OF SUCCESS. RESULTS MAY VARY PER INDIVIDUAL. In particular, the
          Company does not provide and expressly disclaims any warranty that: (i) the
          Service will meet your requirements, (ii) the Service will be uninterrupted,
          timely, secure or error-free, (iii) the results that may arise from your use of
          the Service, including data, will be accurate or reliable, (iv) the quality of
          any data or services available on the Service will meet your expectations, and
          (v) any errors in the Service will be corrected. Any material obtained through
          the use of the Service is at your own discretion and responsibility, and you
          shall be solely responsible for any damage to a computer system or mobile device
          or loss of data resulting from the use of any such material. We cannot guarantee
          and do not promise specific results from the use of the Application and/or
          Service. You also agree to bear the risks of interruption of the Service for any
          technical reasons.
        </Typography>
        <Typography variant={'body1'} paragraph>
          TESTIMONIALS AND EXAMPLES THAT MAY BE PROVIDED ON THE SERVICE MAY NOT APPLY TO
          ALL INDIVIDUALS AND RESULTS ARE NOT GUARANTEED. THERE IS NO GUARANTEE THAT
          EXAMPLES OF PAST EXERCISE AND NUTRITION RESULTS MAY HAVE FUTURE RESULTS. WE
          CANNOT GUARANTEE YOUR FUTURE RESULTS AND/OR SUCCESS NOR CAN WE GUARANTEE THAT
          YOU WILL MAINTAIN RESULTS IF YOU DO NOT CONTINUE TO FOLLOW OUR PROGRAMS.
        </Typography>
        <Typography variant={'body1'} paragraph>
          THE SERVICE MAY NOT BE SUITABLE FOR ALL INDIVIDUALS. EACH PERSON'S HEALTH,
          EXERCISE AND NUTRITION RESULTS DEPEND ON THEIR HISTORY, DEDICATION, DESIRE AND
          MOTIVATION. AS WITH ANY HEALTH-RELATED PROGRAM OR SERVICE, YOUR RESULTS MAY VARY
          AND WILL BE BASED ON MANY VARIABLES, INCLUDING BUT NOT LIMITED TO INDIVIDUAL
          ABILITY, AND LEVEL OF CONSISTENCE. USE OF THE SERVICE SHOULD BE BASED ON YOUR
          OWN DUE DILIGENCE AND YOU AGREE THAT THE COMPANY IS NOT RESPONSIBLE FOR ANY
          SUCCESS OR FAILURE OF YOUR HEALTH AND PHYSICAL CONDITION.
        </Typography>
        <Typography variant={'body1'} paragraph>
          IN ADDITION TO ALL OTHER LIMITATIONS AND DISCLAIMER IN THESE TERMS, THE COMPANY
          DISCLAIMS ANY LIABILITY FOR DAMAGES IN CONNECTION WITH THE CONTENT PROVIDED ON
          THE SERVICE. WE ENCOURAGE YOU TO CONSULT YOUR PHYSICIAN AND OTHER APPROPRIATE
          PROFESSIONALS REGARDING THE INFORMATION CONTAINED ON OR ACCESSED THROUGH THE
          SERVICE.
        </Typography>
        <ol>
          <li>
            <Typography variant={'subtitle1'}>Registration to the Service</Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            Registration for HD Corner service is done by providing the following personal
            information: name, date of birth, gender and email address. In addition, you
            will be asked to create a password, which you will use together with your
            email from now on to connect to the service. You can change the password in
            the "Profile" Tab.
          </Typography>
          <Typography variant={'body1'} paragraph>
            It is your sole responsibility to control the disclosure and use of your
            password and to allow, control and manage access to and use of your HD Corner
            account and password By using the Application Services you warrant that:
          </Typography>
          <ul>
            <li>
              <Typography variant={'body1'}>
                You will not provide false information
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                No more than one account will be created.
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                If an account is suspended or deleted by the administrator, another one
                will not be created.
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                You will not share the password with others and you will not give the
                access information to others.
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                Your use of the Service will not violate any applicable law or regulation
                or these Terms.
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>You are not under 18 years of age</Typography>
            </li>
          </ul>
          <Typography variant={'body1'} paragraph>
            The Company reserves the right to suspend or terminate your Profile or your
            access to the Service, with or without notice to you, if you violate these
            Terms. You are responsible for maintaining the confidentiality of your Profile
            login information and are fully responsible for all activities that occur
            under your Profile. You agree to notify the Company immediately of any
            unauthorized use or suspected unauthorized use of your Profile or any other
            breach of security. The Company cannot and will not be liable for any loss or
            damage arising from your failure to comply with the above requirements
          </Typography>
          <Typography variant={'body1'} paragraph>
            Upon logging into the service, you are prompted to choose between the basic
            profile and the premium profile. By choosing the basic profile you will be
            able to:
          </Typography>
          <ul>
            <li>
              <Typography variant={'body1'}>
                Upload sugar readings either manually for the Microlife GlucoTeq and
                BIONIME GM550 & GM700s blood glucose meters
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                Upload blood pressure readings either manually or via USB connection for
                Microlife blood pressure devices
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                Upload HbA1c, Cholesterol, Triglyceride, HDL, Lpa & LDL readings manually
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                Access to automated reports of your measurements
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                You will also be able to set reminders (e.g. for taking medicine,
                measuring sugar, etc.)
              </Typography>
            </li>
          </ul>
          <Typography variant={'body1'} paragraph>
            By choosing the premium profile, in addition to the aforementioned, you will
            have access to all HD Corner modules, which are distinguished as follows:
          </Typography>
          <ul>
            <li>
              <Typography variant={'body1'}>
                - Upload blood sugar readings from any blood glucose meter via your mobile
                camera.
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                Upload blood pressure readings from any blood pressure measuring device
                via your mobile camera.
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                Ability to download the automated report of your measurements in excel or
                pdf.
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                Access to the Hellenic Score II Fatal Cardiovascular Risk Calculation.
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                Access to medication reminder service.
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                Access to calorie calculator based on your profile, diet plans, food
                equivalents and the calorie intake and burn service
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                Access to exercise videos and tips for running exercise programs based on
                your profile
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                Ability to store medical exam files (cloud).
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                Ability to add a health professional (doctor or pharmacist), who will be
                able to review the content you upload and give you instructions and advice
                via chat. The premium option comes with a subscription (monthly or yearly)
                which you can pay via credit/debit card from the HD Corner web application
              </Typography>
            </li>
          </ul>
          <Typography variant={'body1'}>
            In each profile option (basic or premium) you can optionally create your
            profile by entering your eating habits, information about your physical
            condition and health, so that we can offer you the most personalized content
            possible
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>Unsubscribe from the Service</Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            The user can unsubscribe from the service by selecting the corresponding
            option on the profile tab. By choosing to unsubscribe, you agree that your
            profile will be deleted and all data you have entered will be destroyed in
            accordance with data protection law and legislation.
          </Typography>
          <Typography variant={'body1'} paragraph>
            We reserve the right to restrict access to the Website or the Application
            and/or to delete an account, if we deem it necessary, such as:
          </Typography>
          <ul>
            <li>
              <Typography variant={'body1'}>
                Someone other than the User uses their account, or
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                The User has engaged in any activity or behavior that violates the law,
                these Terms, and our policies, or has engaged in activity or behavior that
                we consider in our sole discretion to be a misuse of our services.
              </Typography>
            </li>
          </ul>
          <li>
            <Typography variant={'subtitle1'}>Use of Content</Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            HD Corner Content includes photographs, images, graphics, video, audio, data,
            text, music, fitness, comments, software, author work of any kind and other
            information, content and other material published, produced, made available on
            or through Services (collectively "Content"). The Content, Services are
            protected by copyrights, trademarks and patents, . We reserve all rights not
            expressly mentioned in these Terms. You agree not to delete, alter or obscure
            any copyright, trademark, service mark or other proprietary notices
            incorporated or accompanying the Services. Our logos and any other HD Corner
            trademarks that may appear on the Services, and App Content, such as the
            general appearance of the Services, including page headings, graphics, images
            and text, may not be copied, imitated or used in any way, in whole or in part,
            without prior written authorization. Other trademarks, product and service
            names, and company names or logos mentioned on the Services consist property
            of their respective owners and may not be copied, imitated or used, in whole
            or in part, without the permission of the trademark owner that is respectively
            protected.
          </Typography>
          <Typography variant={'body1'} paragraph>
            Please note that the use of the application and its content is solely at your
            own risk. The Company bears no responsibility for any delays, failures,
            interruptions, or damage to any data or information related to the
            application. Also, under no circumstances does the Company guarantee the
            absence of "viruses" or other harmful code, whether it is the application, or
            any other site or server through which you receive its content.
          </Typography>
          <Typography variant={'body1'} paragraph>
            Without prejudice to these Terms, the Company grants you a non-transferable,
            non-exclusive license (with no right to sublicense) to (i) use the Service
            solely for your personal, noncommercial purposes, and (b) install and use the
            Application, solely in your portable handheld device (e.g. iPhone, Android,
            etc. as applicable) or personal computer and solely for personal,
            non-commercial purposes.
          </Typography>
          <Typography variant={'body1'} paragraph>
            You agree, state and warrant that your use of the Service, or any part
            thereof, will be in accordance with the foregoing license, agreements and
            restrictions and will not infringe or violate the rights of any other party or
            violate any contract or legal obligation in any other parts. In addition, you
            agree that you will comply with all applicable laws and regulations relating
            to the Service or its use and will be solely responsible for your own
            individual violations of any such laws.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>
              Change of Information and Services
            </Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            We may change all information provided on the Service (whether in free or paid
            features) at our sole discretion without notice. We may at any time modify or
            discontinue, temporarily or permanently, the Service (or any part thereof) in
            our sole discretion with or without notice. You agree that we will not be
            liable to you or any third party for any modification, suspension or
            discontinuance of the Service.
          </Typography>

          <li>
            <Typography variant={'subtitle1'} paragraph>
              Statements and Limitations of Use
            </Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            By using the Service, you state and warrant that: (a) you have legal capacity
            and agree to comply with these Terms; (b) you are not under the age of 18; (c)
            you will not access the Service through automated or non-human means , whether
            by robot or script or otherwise. (d) you will not use the Service for any
            illegal or unauthorized purpose; (e) your use of the Service will not violate
            any applicable law or regulation;
          </Typography>

          <Typography variant={'body1'} paragraph>
            If you provide any information that is untrue, inaccurate, not current or
            incomplete, we have the right to refuse any and all current or future use of
            the Service (or any part thereof).
          </Typography>
          <Typography variant={'body1'} paragraph>
            You may not access or use the Service for any purpose other than that for
            which we provide the Service. The Service may not be used for commercial
            purposes unless specifically authorized by us
          </Typography>
          <Typography variant={'body1'} paragraph>
            The user of the Service agrees NOT to (a) systematically retrieve data or
            other content from the Service to create or compile, directly or indirectly, a
            collection, compilation, database or directory without our written permission;
            (b) do any unauthorized use of the Service. (c) make any modification,
            adaptation, enhancement, improvement, translation or derivative work from the
            Service; (d) use the Service for any monetization effort, commercial
            enterprise or other purpose for which it was not designed or intended; (e)
            make the Service available over a network or other environment that allows
            access or use by multiple devices or users; at the 6 same time; (f) use the
            Service to create a product, service or software that is, directly or
            indirectly, competitive with or in any way a substitute for the Service; (g)
            use any of our proprietary information or other intellectual property to
            design, develop, manufacture, license or distribute any applications,
            accessories or devices for use with the Service; (h) bypass, disable or
            otherwise interfere with security-related features of the Service; (i) engages
            in unauthorized framing or connection to the Service; (j) interferes with,
            disrupts, or creates an unreasonable burden on the Service or networks or
            services connected to the Service. (k) decrypt, decompile, disassemble or
            reverse engineer any software that includes or in any way forms part of the
            Service; (l) attempt to circumvent any measures of the Service intended to
            prevent or limit access to the Service or any part of the Service; (m) upload
            or distribute in any way files that contain viruses, worms, trojans, corrupted
            files or any other similar software or programs that may harm the operation of
            another's computer; (n) use, launch, develop or distribute any automated
            system, including without limitation, any spider, robot, cheat utility,
            scraper or offline reader that accesses the Service or uses or launches any
            unauthorized script or other software. (o) use the Service to send automated
            queries to any website or to send unsolicited commercial e-mail; (p)
            disparage, tarnish or otherwise harm, in our opinion, us and/or the Service.
            (q) use the Service in a manner inconsistent with any applicable laws or
            regulations; or (r) violates these Terms in any way.
          </Typography>
          <Typography variant={'body1'} paragraph>
            You are solely responsible for obtaining the equipment and telecommunications
            services necessary to access the Service, and for all charges associated
            therewith (such as computing devices and Internet Service Provider and airtime
            charges).
          </Typography>
          <Typography variant={'body1'} paragraph>
            Your access to and use of the Service is at your own risk. The Company shall
            have no liability for any damage to your computer system, loss of data or
            other harm to you or any third party, including without limitation any
            personal injury, that may arise from your access to or use of the Service or
            rely on any information or advice.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>Subscription and Payment</Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            Certain features of the Service are offered by subscription for a fee. You can
            purchase a subscription directly from the Company through the WEB platform of
            the Application. The purchase of the subscription through the WEB platform of
            the Application is made by electronic payment with payment services from
            third-party providers and payments by debit or credit card, according to the
            services available in the special field of the Application. Settlement of card
            payments is carried out according to the user's choice through the authorized
            services.
          </Typography>
          <Typography variant={'body1'} paragraph>
            To the maximum extent permitted by applicable law, we may change our purchase
            fees at any time. We will give you reasonable notice of any such price changes
            by posting the new prices through the Application and/or sending you a notice
            by email or other conspicuous means. If you do not wish to pay the new fees,
            you may cancel the applicable subscription before the change takes effect
            and/or refrain from pre-paying for access to the Service.
          </Typography>
          <Typography variant={'body1'} paragraph>
            You authorize us to charge the applicable fees to the payment card you submit.
            By signing up for certain subscriptions, you agree that your subscription may
            automatically renew. If you do not cancel your subscription, you authorize us
            to bill you for the duration of the renewal. The auto-renewal period will be
            the same as your original subscription period, unless otherwise notified in
            the Service
          </Typography>
          <Typography variant={'body1'} paragraph>
            We may offer a trial subscription period for the Service. The trial version
            gives you access to the Service for a period of time, with details specified
            when you sign up for the offer. Unless you cancel before the end of the free
            trial or unless otherwise specified, your access to the Service will continue
            and you will be charged the applicable fees for the Service. It is ultimately
            your responsibility to know when the free trial will end. We reserve the
            right, in our sole discretion, to modify or terminate any free trial offer,
            your access to the Service during the free trial, or any of these terms
            without notice and without liability. We reserve the right to limit your
            ability to take advantage of multiple free trials. The Service and your rights
            to use it expire at the end of your subscription payment period. We reserve
            the right to disable or terminate your access to the Service (and may do so
            without notice) if the applicable subscription fee has not been paid.
          </Typography>
          <Typography variant={'body1'} paragraph>
            You agree that payment of the subscription is final, that the Company will not
            refund any transaction after it has taken place, and that the subscription
            cannot be cancelled. When you make payment, you acknowledge and agree that all
            subscriptions are non-refundable or exchangeable. Notwithstanding anything to
            the contrary above, the Company will provide refunds and/or cancellations of
            subscriptions in cases and to the extent required by the mandatory provisions
            of applicable law. The Company may also provide refunds at its discretion and
            subject to our policies as may be published from time to time.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>
              Withdrawal/Cancellation of Activated Subscriptions:
            </Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            The user of the Services acknowledges, consents and confirms that he will lose
            the right of withdrawal/cancellation, once the contract for the purchase of
            the services of the Application is fully executed and he is informed that the
            subscription service of HD CORNER has been activated.
          </Typography>
          <Typography variant={'body1'} paragraph>
            With the activation of the provision of subscription services, the right of
            withdrawal does not apply to the provision of services in accordance with what
            is provided for in articles 3e to 3a of Law 2251/1994 - Official Gazette
            191/A/16-11-1994 on Consumer Protection, as amended with K.Y.A. Z1-891/2013 –
            Official Gazette 2144/B/30-8-2013 for distance and non-store contracts.
            Subscription services are subject to the exceptions of article 3l of the above
            law.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>Personal Data..</Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            HD Corner respects your privacy. To obtain information about our data
            protection policy, please consult the Privacy Policy listed below. By
            accessing or using the Services, you agree that HD Corner may collect and use
            the information you provide to us in accordance with the Privacy Policy.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>Limitation of Liability</Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            THE COMPANY AND THE ENTITIES INVOLVED IN CREATING, PRODUCING OR PROVIDING THE
            SERVICES SHALL NOT BE LIABLE FOR ANY INCIDENTAL, SPECIAL OR CONSEQUENTIAL
            DAMAGES, INCLUDING LOST PROFITS, LOSS OF DATA, SERVICE INTERRUPTION, DAMAGE TO
            ELECTRONIC EQUIPMENT, OR SYSTEM FAILURE OR COSTS OF REPLACEMENT OF THE
            SERVICES ARISING OUT OF OR RELATED TO THE TERMS OR THE USE OR INABILITY TO USE
            THE SERVICES, BASED ON ANY WARRANTY, CONTRACT, TORT (INCLUDING THAT 8
            NEGLIGENCE), PRODUCT LIABILITY PROVISIONS OR THIRD PARTY ADVERTISING, OR ANY
            OTHER LEGAL PROVISION, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </Typography>
          <Typography variant={'body1'} paragraph>
            TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT SHALL THE APP'S AGGREGATE
            LIABILITY ARISING OUT OF OR RELATED TO THESE TERMS OR THE USE OR INABILITY TO
            USE THE SERVICES EXCEED THE VALUE OF THE SUBSCRIPTION PAID FOR. YOU HAVE PAID
            THE LAST TWELVE MONTHS.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>News letters</Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            By accepting the terms of use of the Application and registering to the HD
            CORNER Services, newsletters can be sent to the user’s e-mail address they
            have indicated during their registration. In the event that the user does not
            wish the sending of newsletters - news of HD CORNER in the future, they can
            immediately proceed to delete their e-mail address from the list of addresses
            receiving such messages, using the special deletion link (unsubscribe) that
            exists in each such newsletter (Law 3741/2006 no. 11 par. 3 and 4).
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>Jurisdiction</Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            These Terms of Use are governed by Greek law. The user expressly accepts that
            the Courts of Athens have the exclusive jurisdiction and competence for any
            dispute arising from the use of the Application. If any of the above
            conditions prove to be invalid, this does not affect the validity and force of
            the remaining conditions which remain in full force.
          </Typography>
          <Typography variant={'body1'} paragraph>
            USE OF THE APPLICATION, SERVICES AND ITS CONTENT IS SUBJECT TO THE ABOVE TERMS
            OF USE. IF THE USER - VISITOR CONSIDERS THESE TERMS UNACCEPTABLE, PLEASE DO
            NOT DOWNLOAD THE APPLICATION OR USE ITS SERVICES.
          </Typography>
          <Typography variant={'subtitle1'} paragraph>
            Contact
          </Typography>
          <Typography variant={'body1'} paragraph>
            If you wish to contact us regarding these Terms of Use or have any other
            question regarding the provision of the Services you may send an email to
            info@hdcorner.com .
          </Typography>
          <Typography variant={'body1'} paragraph>
            Last update 08/01/2024
          </Typography>
        </ol>
      </Box>
      <Box>
        <Typography variant={'h1'} paragraph>
          PRIVACY POLICY
        </Typography>
        <Typography variant={'body1'} paragraph>
          This Privacy Policy concerns the processing of your personal data when using the
          HDCORNER Application (hereinafter the "Application")
        </Typography>
        <ol>
          <li>
            <Typography variant={'subtitle1'}>Controller – Contact details</Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            " KARABINIS MEDICAL SA-Manufacture –Sale of Technological Products and
            Providing Services ", based in Peania Attica, on Lavriou Avenue, no. 151, with
            A.F.M.: 099052507 – D.O.Y.: FAE Athens- LOT NO. 87183102000, tel. 2106645751
            (hereinafter the "Company")
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>
              What personal data of mine is collected and how?
            </Typography>
          </li>
          <Typography variant={'body1'}>
            When you register in the Application (sign up) as a user, you provide the
            following personal data:
          </Typography>
          <ul>
            <li>
              <Typography variant={'body1'}>Name</Typography>
            </li>
            <li>
              <Typography variant={'body1'}>Date of birth</Typography>
            </li>
            <li>
              <Typography variant={'body1'}>Gender</Typography>
            </li>
            <li>
              <Typography variant={'body1'}>Contact info</Typography>
            </li>
          </ul>
          <Typography variant={'body1'}>
            When you register in the Application (sign up) as a health professional, you
            provide the following personal data:
          </Typography>
          <ul>
            <li>
              <Typography variant={'body1'}>first and last name,</Typography>
            </li>
            <li>
              <Typography variant={'body1'}>specialty,</Typography>
            </li>
            <li>
              <Typography variant={'body1'}>VAT NUMBER,</Typography>
            </li>
            <li>
              <Typography variant={'body1'}>address, number, zip code, area,</Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                contact phone number and email address
              </Typography>
            </li>
          </ul>
          <Typography variant={'body1'}>
            When using the services of the Application as a user you have the possibility
            to enter the following data:
          </Typography>
          <ul>
            <li>
              <Typography variant={'body1'}>
                Health data (e.g. diabetes readings, blood pressure, diagnostic tests,
                etc.) that you enter into the platform if you wish
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                Information regarding sports activities, eating habits
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                The details of your credit card (number, name of the holder, security
                code) that you provide to us when paying for the service are not collected
                by the Application, but are provided directly to the authorized provider
                to which you are redirected during the payment process.
              </Typography>
            </li>
          </ul>
          <li>
            <Typography variant={'subtitle1'}>
              For what purposes is the processing carried out and what is its legal basis?
            </Typography>
          </li>
          <Typography variant={'body1'}>
            Your data is processed to provide you with access to HDCORNER services. The
            legal basis for this processing is the performance of a contract (a. 6 par. 2
            b' GDPR)
          </Typography>
          <Typography variant={'body1'}>
            For the health data you provide us, the legal basis is the performance of a
            contract and a. 9 par.1 para. h'
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>
              Who are the recipients of my personal data?
            </Typography>
          </li>
          <Typography variant={'body1'}>
            Access to your personal data is given to the platform provider acting as data
            processor as well as to the doctor or pharmacist or other healthcare
            professional to whom you choose to give access to your data. The healthcare
            professional will be able to see the data you enter on the platform, only if
            you yourself give him access.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>How long is my data kept?</Typography>
          </li>
          <Typography variant={'body1'}>
            Your data will be strictly maintained for as long as you are registered with
            the service. If you are deleted from the service, your data will be deleted
            immediately. They will also be deleted if you submit a deletion request to us.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>Cookies</Typography>
          </li>
          <Typography variant={'body1'}>
            The application does not use cookies when the user enters it, either through
            the mobile phone or through the web platform.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>How is my data protected?</Typography>
          </li>
          <Typography variant={'body1'}>
            Your data is kept on an external server with all the necessary security
            measures. Especially for the health data you enter on the platform, these are
            subject to encryption and pseudonymization, therefore no recipient can
            associate them with your person, except for the health professional to whom
            you give access.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>What are my rights?</Typography>
          </li>
          <li>
            <Typography variant={'body1'}>
              As a data subject you have the following rights:
            </Typography>
          </li>
          <table>
            <tbody style={{ verticalAlign: 'top' }}>
              <tr>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>Right to information</Typography>
                </td>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>
                    You have the right to be informed about the collection, processing and
                    storage of your personal data.
                  </Typography>
                </td>
              </tr>
              <tr>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>Right of access</Typography>
                </td>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>
                    You have the right to receive confirmation as to whether or not your
                    data is being processed and, if this is the case, you have the right
                    to access your data in a short, understandable, transparent and easily
                    accessible form.
                  </Typography>
                </td>
              </tr>
              <tr>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>Right to rectification</Typography>
                </td>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>
                    You may request the rectification of inaccurate or incomplete personal
                    data concerning you.
                  </Typography>
                </td>
              </tr>
              <tr>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>Right to erasure</Typography>
                </td>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>
                    You have the right to request the erasure of the data concerning you,
                    if you consider that it is being held in an 11 unlawful manner and the
                    Company is obliged to proceed with the erasure unless there is a legal
                    reason for its retention
                  </Typography>
                </td>
              </tr>
              <tr>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>Right to data portability</Typography>
                </td>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>
                    You have the right to receive the personal data concerning you and
                    which you have provided to the Company in a commonly used and
                    machine-readable format, as well as the right to request the
                    transmission of said data to another data controller, under the
                    conditions set by law
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
          <Typography variant={'body1'} paragraph>
            We will respond to your request without delay and in any case within one month
            of receiving it. This deadline is extended for another two months, if
            necessary, taking into account the complexity of the request and the number of
            requests. We will inform you of said extension within one month of receiving
            the request, as well as the reasons for the delay.
          </Typography>
          <Typography variant={'body1'} paragraph>
            The company has the right in any case to refuse to comply with your request to
            limit the processing or delete your personal data, if this processing is
            necessary for the establishment, exercise or support of its legal rights or
            the fulfillment of its obligations.
          </Typography>
          <li>
            <Typography variant={'subtitle1'} paragraph>
              How you can exercise your rights:
            </Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            In order to exercise your above rights, you can contact the Data Protection
            Officer at the email address dataprotection@karabinismedical.gr or by phone at
            210-6645751 or by post at the company address KARABINIS MEDICAL SA, Lavriou
            Ave. 151, 19005 Peania – Att: Data Protection Officer .
          </Typography>
          <li>
            <Typography variant={'subtitle1'} paragraph>
              Personal Data Protection Authority
            </Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            You have the right to file a complaint with the Personal Data Protection
            Authority (www.dpa.gr), which is the competent supervisory authority for the
            protection of the fundamental rights and freedoms of natural persons against
            the processing of their personal data, if you consider that they are being
            violated your rights in any way.
          </Typography>
          <Typography variant={'body1'} paragraph>
            Last update 08/01/2024
          </Typography>
        </ol>
      </Box>
    </Box>
  );
};

export default TermsAndConditionsEN;
