import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment/moment';
import { Box } from '@mui/material';
import InDepthHeading from '../../components/InDepthHeading';
import {
  calculateBMI,
  CalendarModal,
  categoryBMI,
  Collapsible,
  FeelsEmpty,
  theme,
} from '@hdcorner/ui-library';
import { useGetWeightLogsQuery } from '../queries/weightQueries';
import { useGetUserDataQuery } from '../../../profile/queries/personalInfoQueries';
import useAlert from '../../../../hooks/useAlert';
import InfiniteScrollList from '../../../../components/InfiniteScrollList';
import { useTranslation } from 'react-i18next';

const SectionWeightData = () => {
  const { t } = useTranslation();
  const { presentError } = useAlert();

  const [open, setOpen] = useState<boolean>(false);
  // Used for updating the params for the query
  const [endParam, setEndParam] = useState<string>('');
  const [startParam, setStartParam] = useState<string>('');

  const [params, setParams] = useState({
    skip: 0,
    limit: 10,
  });

  const { data: userData, error: userError } = useGetUserDataQuery();
  const { data: weightLogs, error: logsError } = useGetWeightLogsQuery({
    params: params,
    end: endParam || undefined,
    start: startParam || undefined,
  });

  useEffect(() => {
    if (userError) {
      presentError(t('errors.generic.error01'));
    }

    if (logsError) {
      presentError(t('errors.fitness.wellness.errorWeightLogFetch'));
    }
  }, [userError, logsError]);

  const userHeight = useMemo(() => {
    if (!userData) return 0;
    return userData[0].height;
  }, [userData]);

  const inDepthData = useMemo(() => {
    if (!weightLogs || weightLogs.documents.length === 0) return [];

    if (!userHeight) return [];

    return weightLogs.documents.map(it => {
      const BMI = calculateBMI(userHeight, !it.weight ? 0 : it.weight);
      const BMILabel = categoryBMI(Number(BMI));

      return {
        _id: it._id,
        measure: t('measurements.kg'),
        count: !it.weight ? '0' : `${it.weight.toString()}`,
        data: {
          [t('userDetails.weight')]: {
            value: !it.weight ? '0' : `${it.weight.toString()}${t('measurements.kg')}`,
          },
          [t('fitness.wellness.labels.bmi')]: {
            value: BMI ? BMI.toString() : '-',
          },
          [t('fitness.wellness.labels.bmiDesc')]: {
            value: t(BMILabel.label),
          },
          [t('inDepth.timeOfCalc')]: { value: moment(it.logDate).format('h:mm A') },
          [t('inDepth.dateOfCalc')]: {
            value: moment(it.logDate).format('MMMM Do, YYYY'),
          },
        },
      };
    });
  }, [weightLogs, userHeight]);

  const handleDateChange = (dateRange: string[]) => {
    setEndParam(dateRange[1]);
    setStartParam(dateRange[0]);
  };

  return (
    <Box>
      <InDepthHeading
        module={'weight'}
        color={theme.palette.exercise.main}
        handleDate={() => setOpen(true)}
      />
      <Box mt={1} gap={1} display={'flex'} flexDirection={'column'}>
        <InfiniteScrollList
          skip={params.skip}
          limit={params.limit}
          count={inDepthData.length}
          totalCount={weightLogs?.count}
          onLoadMore={(skip, limit) => setParams({ skip, limit })}
        >
          {inDepthData.map(data => (
            <Box key={data._id}>
              <Collapsible data={data.data} titleSub={data.measure} title={data.count} />
            </Box>
          ))}
        </InfiniteScrollList>
        {inDepthData.length === 0 && (
          <Box sx={{ marginBottom: theme.spacing(1), width: '100%' }}>
            <FeelsEmpty />
          </Box>
        )}
      </Box>
      <CalendarModal
        open={open}
        setOpen={setOpen}
        saveDate={handleDateChange}
        selectedDate={[startParam, endParam]}
      />
    </Box>
  );
};

export default SectionWeightData;
