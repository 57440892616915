import Box from '@mui/material/Box';
import { styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import * as React from 'react';
import { FC } from 'react';
import ChartNumbersInfo from './tables/ChartNumbersInfo';
import { useTranslation } from 'react-i18next';

const GraphDotContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginTop: theme.spacing(1),
}));

const GraphDot = styled('div')(() => ({
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  boxSizing: 'border-box',
}));

type HypertensionGraphContainerProps = {
  gap?: number;
  headers?: string[];
  horizontal?: boolean;
  systolicPressure: { value: string; min: string; max: string; avg: string };
  diastolicPressure: { value: string; min: string; max: string; avg: string };
};
export const HypertensionGraphContainer: FC<HypertensionGraphContainerProps> = ({
  systolicPressure,
  diastolicPressure,
  horizontal,
  headers,
  gap,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const web = useMediaQuery(theme.breakpoints.up('sm'));
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(1) }}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={gap ? theme.spacing(3) : theme.spacing(1)}
        >
          {web && (
            <Box>
              <ChartNumbersInfo
                isHorizontal
                numberOne={systolicPressure.max}
                numberTwo={systolicPressure.min}
                numberThree={systolicPressure.avg}
                header={headers !== undefined ? headers[0] : undefined}
              />
              <ChartNumbersInfo
                isHorizontal
                numberOne={diastolicPressure.max}
                numberTwo={diastolicPressure.min}
                numberThree={diastolicPressure.avg}
                header={headers !== undefined ? headers[1] : undefined}
              />
            </Box>
          )}
          <Box
            gap={theme.spacing(1)}
            display={horizontal ? 'flex' : 'unset'}
            marginBottom={gap ? theme.spacing(3) : theme.spacing(0)}
          >
            <Box display={'flex'} alignItems={'center'} gap={theme.spacing(1)}>
              <Typography
                variant={'h1'}
                color={theme.palette.primary.main}
                textTransform={lang === 'el' ? 'capitalize' : 'uppercase'}
              >
                {t('hypertension.labels.latestSys')}
              </Typography>
              <Typography
                variant={'subtitle1'}
                color={theme.palette.primary.light}
                fontWeight={theme.typography.fontWeightMedium}
              >
                {systolicPressure.value} {t('measurements.mmGH')}
              </Typography>
            </Box>
            <Box display={'flex'} alignItems={'center'} gap={theme.spacing(1)}>
              <Typography
                variant={'h1'}
                color={theme.palette.primary.main}
                textTransform={lang === 'el' ? 'capitalize' : 'uppercase'}
              >
                {t('hypertension.labels.latestDia')}
              </Typography>
              <Typography
                variant={'subtitle1'}
                color={theme.palette.primary.light}
                fontWeight={theme.typography.fontWeightMedium}
              >
                {diastolicPressure.value} {t('measurements.mmGH')}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <GraphDotContainer>
        <GraphDot sx={{ backgroundColor: theme.palette.nutrition.main }} />
        <Typography
          variant={'body1'}
          color={theme.palette.primary.main}
          fontWeight={theme.typography.subtitle1.fontWeight}
        >
          {t('dashboard.dashcards.hypertension.systolic')}
        </Typography>
        <GraphDot sx={{ backgroundColor: theme.palette.medication.main }} />
        <Typography
          variant={'body1'}
          color={theme.palette.primary.main}
          fontWeight={theme.typography.subtitle1.fontWeight}
        >
          {t('dashboard.dashcards.hypertension.diastolic')}
        </Typography>
      </GraphDotContainer>
    </>
  );
};

export default HypertensionGraphContainer;
