import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import {
  CalendarGoalsModal,
  CalendarIcon,
  ComponentsLayout,
  CustomTabs,
} from '@hdcorner/ui-library';
import PageLayout from '../../../components/PageLayout';
import ChartSteps from './components/ChartSteps';
import SectionActivitiesCal from './components/SectionActivitiesCal';
import { useIonRouter } from '@ionic/react';
import ModalStepsGoal from './components/ModalStepsGoal';
import moment from 'moment';
import { useGetCaloriesInDepthQuery } from '../calories/queries/caloriesQueries';
import useAlert from '../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

const ExerciseFitness = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { presentError } = useAlert();

  const [monthCalendarGoals, setMonthCalendarGoals] = useState<string>();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [openStepsModal, setOpenStepsModal] = useState<boolean>(false);
  const [openCalendarGoals, setOpenCalendarGoals] = useState<boolean>(false);

  const { data: getCaloriesInDepth, error } = useGetCaloriesInDepthQuery(
    {
      params: { skip: 0, limit: 40 },
      end: moment(monthCalendarGoals).endOf('month').toISOString(),
      start: moment(monthCalendarGoals).startOf('month').toISOString(),
    },
    { skip: !monthCalendarGoals },
  );

  useEffect(() => {
    if (error) {
      presentError(t('errors.fitness.calories.error01'));
    }
  }, [error]);

  const prepareCompletedDays = useMemo(() => {
    if (!getCaloriesInDepth) return [];
    return getCaloriesInDepth.documents
      .filter(cal => cal.goalAchieved !== null)
      .map(cal => ({
        success: cal.goalAchieved,
        date: new Date(cal.createdAt),
      }));
  }, [getCaloriesInDepth]);

  const handleOpenStepsModal = () => {
    setOpenStepsModal(true);
  };

  const handleAddActivity = () => {
    router.push('/dashboard/fitness/activities');
  };

  const handleOpenStepsCalendar = () => {
    setOpenCalendarGoals(true);
  };

  return (
    <PageLayout
      defaultHref={'/dashboard/fitness'}
      headerTitle={t('headingsTitles.exercise')}
      fabClick={selectedTab === 0 ? handleOpenStepsModal : handleAddActivity}
      endButtons={
        selectedTab === 0
          ? []
          : [
              {
                icon: <CalendarIcon width={'20px'} height={'20px'} />,
                onClick: handleOpenStepsCalendar,
              },
            ]
      }
    >
      <ComponentsLayout>
        <Box display={'flex'} flexDirection={'column'} flex={1}>
          <CustomTabs
            value={selectedTab}
            setValue={setSelectedTab}
            tabs={[
              {
                label: t('headingsTitles.steps'),
                children: <ChartSteps />,
              },
              {
                label: t('headingsTitles.activities'),
                children: <SectionActivitiesCal />,
              },
            ]}
            sx={{ display: 'flex', flex: 1, width: '100%' }}
          />
        </Box>
      </ComponentsLayout>
      <ModalStepsGoal log open={openStepsModal} setOpen={setOpenStepsModal} />
      <CalendarGoalsModal
        open={openCalendarGoals}
        setOpen={setOpenCalendarGoals}
        goalDates={prepareCompletedDays?.map(cal => ({
          date: cal.date,
          success: cal.success,
        }))}
        onMonthChange={setMonthCalendarGoals}
      />
    </PageLayout>
  );
};
export default ExerciseFitness;
