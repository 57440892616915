import React, { useEffect, useMemo, useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useIonRouter } from '@ionic/react';
import {
  BarChart,
  CalendarModal,
  ChartCard,
  ClickNavCardNutrition,
  dayWeekMonthYearCustom,
  FeelsEmpty,
  prepareGraphData,
} from '@hdcorner/ui-library';
import SectionHeading from '../../components/SectionHeading';
import useTimeframe from '../../../../hooks/useTimeframe';
import { useGetSleepGraphDataQuery } from '../queries/sleepQueries';
import useAlert from '../../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../../../../utils/useConvertJSON';

const ChartSleep = () => {
  const theme = useTheme();
  const router = useIonRouter();
  const { t } = useTranslation();
  const { presentError } = useAlert();

  const web = useMediaQuery(theme.breakpoints.up('md'));

  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
  const [selectedTimeframe, setSelectedTimeframe] = useState<
    'day' | 'week' | 'month' | 'year'
  >('day');

  // Used for updating the params for the query
  const [endParam, setEndParam] = useState<string>('');
  const [startParam, setStartParam] = useState<string>('');

  const timeframe = useTimeframe(selectedTimeframe, startParam, endParam);
  const decideTimeframe = useMemo(() => {
    if (selectedTimeframe === 'day') {
      return { timeframe: 'day' };
    } else if (selectedTimeframe === 'week') {
      return { timeframe: 'week' };
    } else if (selectedTimeframe === 'month') {
      return { timeframe: 'month' };
    } else if (selectedTimeframe === 'year') {
      return { timeframe: 'year' };
    } else {
      return { ...timeframe };
    }
  }, [selectedTimeframe, timeframe]);

  const { data: graphRes, error } = useGetSleepGraphDataQuery({
    ...decideTimeframe,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  useEffect(() => {
    if (error) {
      presentError(t('errors.fitness.wellness.errorSleepGraphFetch'));
    }
  }, [error]);

  const sleepData = prepareGraphData(graphRes?.graphData, 'quantity');

  const handleDateChange = (dateRange: string[]) => {
    setStartParam(dateRange[0]);
    setEndParam(dateRange[1]);
  };

  return (
    <Box display={'flex'} flexDirection={'column'} mt={theme.spacing(2)}>
      <Box mb={web ? 1 : 0} display={web ? 'block' : 'none'}>
        <SectionHeading hasGoal={false} heading={t('fitness.wellness.labels.sleepTim')} />
      </Box>
      <Box>
        <ChartCard
          setOpen={setCalendarOpen}
          value={selectedTimeframe}
          unit={t('measurements.hours')}
          setValue={setSelectedTimeframe}
          headings={useConvertJSON(dayWeekMonthYearCustom).slice(1)}
          amount={
            selectedTimeframe === 'day'
              ? graphRes?.total?.toFixed(1) || 0
              : graphRes?.avg?.toFixed(1) || 0
          }
        >
          {!sleepData || sleepData.length === 0 ? (
            <FeelsEmpty />
          ) : (
            <BarChart chartData={sleepData} unitOfTime={selectedTimeframe} />
          )}
        </ChartCard>
      </Box>
      <Box mt={2} display={web ? 'none' : 'block'}>
        <ClickNavCardNutrition
          headings={t('buttons.navigation.inDepth')}
          onClick={() => router.push('/dashboard/fitness/wellness/in-depth/sleep')}
        />
      </Box>
      <CalendarModal
        open={calendarOpen}
        setOpen={setCalendarOpen}
        saveDate={handleDateChange}
        selectedDate={[startParam, endParam]}
      />
    </Box>
  );
};

export default ChartSleep;
