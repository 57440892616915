import * as React from 'react';
import { SVGProps } from 'react';

const GreenDownArrow = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.707 3.293C20.8944 3.48053 20.9998 3.73484 20.9998 4C20.9998 4.26516 20.8944 4.51947 20.707 4.707L6.41397 19H12C12.2652 19 12.5195 19.1054 12.7071 19.2929C12.8946 19.4804 13 19.7348 13 20C13 20.2652 12.8946 20.5196 12.7071 20.7071C12.5195 20.8946 12.2652 21 12 21L3.99997 21C3.73475 21 3.4804 20.8946 3.29286 20.7071C3.10533 20.5196 2.99997 20.2652 2.99997 20V12C2.99997 11.7348 3.10533 11.4804 3.29286 11.2929C3.4804 11.1054 3.73475 11 3.99997 11C4.26519 11 4.51954 11.1054 4.70708 11.2929C4.89461 11.4804 4.99997 11.7348 4.99997 12L4.99997 17.586L19.293 3.293C19.4805 3.10553 19.7348 3.00021 20 3.00021C20.2651 3.00021 20.5194 3.10553 20.707 3.293Z"
        fill="#5FD071"
      />
    </svg>
  );
};

export default GreenDownArrow;
