import * as React from 'react';
import { SVGProps } from 'react';
const PlayButtonOutlined = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.99984 0.666504C6.54944 0.666504 5.13162 1.0966 3.92566 1.90239C2.7197 2.70819 1.77977 3.8535 1.22472 5.19349C0.669681 6.53348 0.524457 8.00797 0.807415 9.4305C1.09037 10.853 1.78881 12.1597 2.81439 13.1853C3.83998 14.2109 5.14665 14.9093 6.56918 15.1923C7.99171 15.4752 9.4662 15.33 10.8062 14.775C12.1462 14.2199 13.2915 13.28 14.0973 12.074C14.9031 10.8681 15.3332 9.45023 15.3332 7.99984C15.3309 6.05562 14.5575 4.19169 13.1828 2.81692C11.808 1.44215 9.94406 0.668798 7.99984 0.666504ZM7.99984 13.9998C6.81315 13.9998 5.65311 13.6479 4.66642 12.9887C3.67972 12.3294 2.91069 11.3923 2.45656 10.2959C2.00244 9.19958 1.88362 7.99318 2.11513 6.8293C2.34664 5.66541 2.91808 4.59631 3.7572 3.7572C4.59631 2.91808 5.66541 2.34664 6.8293 2.11513C7.99319 1.88361 9.19958 2.00243 10.2959 2.45656C11.3923 2.91069 12.3294 3.67972 12.9887 4.66642C13.6479 5.65311 13.9998 6.81315 13.9998 7.99984C13.9979 9.59054 13.3651 11.1155 12.2403 12.2403C11.1155 13.3651 9.59054 13.9979 7.99984 13.9998ZM6.66651 5.33317L10.6665 7.99984L6.66651 10.6665V5.33317Z"
      fill="#222222"
    />
  </svg>
);
export default PlayButtonOutlined;
