import * as React from 'react';
import { SVGProps } from 'react';

const AppleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={36}
    height={36}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={36} height={36} rx={8} fill="black" />
    <path
      d="M15.0989 27C14.4705 26.9658 13.8792 26.6918 13.447 26.2343C12.9299 25.7367 12.4685 25.1843 12.0708 24.5869C11.4497 23.6972 10.9588 22.7234 10.6128 21.695C10.2209 20.5886 10.0146 19.4249 10.0022 18.2512C9.97204 17.0995 10.2575 15.9615 10.8276 14.9604C11.2449 14.2388 11.8402 13.6362 12.5565 13.2101C13.265 12.7858 14.0734 12.5574 14.899 12.5483C15.5215 12.5852 16.1331 12.7275 16.7079 12.9692C17.1662 13.1709 17.6485 13.3126 18.143 13.391C18.6887 13.2767 19.2219 13.1093 19.735 12.8912C20.3423 12.654 20.9852 12.5212 21.6368 12.4983C21.7237 12.4983 21.8097 12.4983 21.8926 12.5083C23.3212 12.5498 24.65 13.2512 25.4903 14.4076C24.1541 15.1222 23.3337 16.5281 23.3687 18.0433C23.3555 19.1925 23.8345 20.2923 24.6848 21.0652C25.0675 21.4299 25.5131 21.7222 26 21.9279C25.9001 22.2278 25.7851 22.5177 25.6632 22.8095C25.3861 23.4546 25.0442 24.0699 24.6429 24.6459C24.264 25.2228 23.8229 25.7565 23.3277 26.2373C22.8756 26.686 22.273 26.9507 21.6368 26.98C21.0975 26.9561 20.5677 26.8295 20.0758 26.6071C19.5453 26.376 18.9752 26.2494 18.3968 26.2343C17.8021 26.246 17.2151 26.3719 16.6679 26.6051C16.1953 26.8185 15.6903 26.9512 15.1739 26.998L15.0989 27ZM18.2469 12.4983C18.172 12.4983 18.097 12.4983 18.0221 12.4893C18.0063 12.3704 17.998 12.2505 17.9971 12.1305C18.0298 11.132 18.4199 10.1784 19.0964 9.44347C19.4745 9.0212 19.9328 8.67835 20.4445 8.43484C20.9221 8.18893 21.444 8.04108 21.9796 8C21.9945 8.13095 21.9945 8.2589 21.9945 8.38086C21.9804 9.36365 21.6112 10.3081 20.9552 11.0399C20.3122 11.9023 19.3207 12.4362 18.2469 12.4983Z"
      fill="#FAFAFA"
    />
  </svg>
);

export default AppleIcon;
