import { FC } from 'react';
import ArrowForward from '../../assets/ArrowForward';
import ArrowBackward from '../../assets/ArrowBackward';
import ArrowForwardDouble from '../../assets/ArrowForwardDouble';
import ArrowBackwardDouble from '../../assets/ArrowBackwardDouble';
import {
  Box,
  CircularProgress,
  IconButton,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const CustomIconButton = styled(IconButton)(() => ({
  '&:disabled svg': {
    opacity: '0.24',
  },
}));

type AdminTablePaginationProps = {
  loading?: boolean;
  skipAmount: number;
  currentPage: number;
  numberOfPages: number;
  //how many pages are skipped by multiple skip buttons
  onSkipClick: (amount: number) => void;
};
const AdminTablePagination: FC<AdminTablePaginationProps> = ({
  loading = false,
  skipAmount,
  onSkipClick,
  currentPage,
  numberOfPages,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const disablePrev = currentPage <= 1;
  const disableNext = currentPage >= numberOfPages;
  const disablePrevMultiple = currentPage - skipAmount <= 0;
  const disableNextMultiple = currentPage + skipAmount >= numberOfPages;

  return (
    <Box
      maxWidth={500}
      display={'flex'}
      margin={'0 auto'}
      alignItems={'center'}
      gap={theme.spacing(1)}
      marginTop={theme.spacing(-1)}
    >
      <CustomIconButton
        disableRipple
        sx={{ padding: '0px' }}
        onClick={() => onSkipClick(-skipAmount)}
        disabled={disablePrevMultiple || loading}
      >
        <ArrowBackwardDouble color={theme.palette.kmColorsBgText.main} />
      </CustomIconButton>
      <CustomIconButton
        disableRipple
        sx={{ padding: '0px' }}
        disabled={disablePrev || loading}
        onClick={() => onSkipClick(-1)}
      >
        <ArrowBackward color={theme.palette.kmColorsBgText.main} />
      </CustomIconButton>
      <Box display={'flex'} alignItems={'center'} gap={theme.spacing(0.5)}>
        <Box
          sx={{
            backgroundColor:
              theme.palette.mode === 'dark'
                ? theme.palette.secondary.main
                : theme.palette.secondary.light,
            padding: '2px 8px',
          }}
        >
          <Typography
            fontSize={'body1'}
            color={theme.palette.kmColorsBgText.main}
            fontWeight={theme.typography.caption.fontWeight}
          >
            {currentPage}
          </Typography>
        </Box>
        {!loading && (
          <Typography
            fontSize={'body1'}
            color={theme.palette.kmColorsBgText.main}
            fontWeight={theme.typography.caption.fontWeight}
          >
            {t('general.labels.of')}{' '}
          </Typography>
        )}
        {loading && (
          <Box padding={theme.spacing(0, 0.5)}>
            <CircularProgress size={10} color={'primary'} />
          </Box>
        )}
        <Typography
          fontSize={'body1'}
          color={theme.palette.kmColorsBgText.main}
          fontWeight={theme.typography.caption.fontWeight}
        >
          {numberOfPages}
        </Typography>
      </Box>
      <CustomIconButton
        disableRipple
        sx={{ padding: '0px' }}
        disabled={disableNext || loading}
        onClick={() => onSkipClick(1)}
      >
        <ArrowForward color={theme.palette.kmColorsBgText.main} />
      </CustomIconButton>
      <CustomIconButton
        disableRipple
        sx={{ padding: '0px' }}
        onClick={() => onSkipClick(skipAmount)}
        disabled={disableNextMultiple || loading}
      >
        <ArrowForwardDouble color={theme.palette.kmColorsBgText.main} />
      </CustomIconButton>
    </Box>
  );
};

export default AdminTablePagination;
