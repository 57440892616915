import React, { FC } from 'react';
import { Box, Typography, useTheme } from '@mui/material';

type ChatDateInfoCardProps = {
  date: string;
};
const ChatDateInfoCard: FC<ChatDateInfoCardProps> = ({ date }) => {
  const theme = useTheme();

  return (
    <Box
      height={'32px'}
      display={'flex'}
      borderRadius={'50px'}
      alignItems={'center'}
      boxSizing={'border-box'}
      justifyContent={'center'}
      padding={theme.spacing(1)}
      bgcolor={theme.palette.secondary.light}
      marginBottom={theme.spacing(1.25)}
    >
      <Typography
        variant={'body1'}
        sx={{
          fontWeight: theme.typography.fontWeightRegular,
          color: theme.palette.kmColorsDarkGrey.main,
        }}
      >
        {date}
      </Typography>
    </Box>
  );
};

export default ChatDateInfoCard;
