import * as React from 'react';
import { SVGProps } from 'react';

const CameraIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.667 1.333h-2.438l-.257-.858A.667.667 0 0 0 11.333 0H5.067a.667.667 0 0 0-.639.475l-.257.858H1.333A.667.667 0 0 0 .667 2v9.333a.667.667 0 0 0 .666.667h13.334a.667.667 0 0 0 .666-.667V2a.667.667 0 0 0-.666-.667ZM14 10.667H2v-8h2.667a.667.667 0 0 0 .638-.476l.258-.858h5.274l.258.858a.667.667 0 0 0 .638.476H14v8Zm-6-8a3.333 3.333 0 1 0 0 6.666 3.333 3.333 0 0 0 0-6.666ZM8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z"
      fill="#4A4F55"
    />
  </svg>
);

export default CameraIcon;
