import * as React from 'react';
import { SVGProps } from 'react';
import { theme } from '@hdcorner/ui-library';

const LunchIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 15}
      height={props.height ? props.height : 15}
      viewBox={props.viewBox ? props.viewBox : '0 0 15 15'}
      {...props}
    >
      <path
        d="M8.22367 9.79737C7.33721 10.2195 6.2345 10.0473 5.48671 9.28798C4.8731 8.66478 4.06273 8.01878 3.24852 7.72586C2.79712 7.56347 2.37168 7.29758 2.0085 6.92886L1.9872 6.90708L1.96583 6.88561C0.694896 5.595 0.675806 3.52178 1.9232 2.25507C2.25477 1.91842 2.64246 1.67263 3.0569 1.51741C3.29804 1.4271 3.51241 1.70482 3.51481 1.96582C3.52147 2.68908 4.10426 3.28097 4.81658 3.28774C4.98293 3.28932 5.14161 3.25877 5.2871 3.20193C5.29303 3.19962 5.29944 3.20402 5.29946 3.21047C5.30737 4.06947 5.99949 4.77222 6.84531 4.78025C7.03499 4.78205 7.21629 4.74878 7.38333 4.68636C7.59181 4.60848 7.84407 4.65343 7.97379 4.83655C8.24178 5.21489 8.54796 5.56834 8.8488 5.87384C9.79684 6.83656 9.81553 8.37857 8.89043 9.3179L8.88435 9.32408L8.87835 9.33026C8.68294 9.52867 8.46106 9.68433 8.22367 9.79737ZM8.22367 9.79737L9.20977 10.7987C9.29857 10.8889 9.2844 11.0385 9.19561 11.1287C8.66506 11.6674 8.66926 12.5452 9.20498 13.0892C9.7407 13.6332 10.6051 13.6375 11.1356 13.0987C11.3142 12.9174 11.4322 12.6976 11.4897 12.4652C11.548 12.2296 11.749 12.0255 11.9809 11.9663C12.2099 11.9079 12.4263 11.7881 12.6048 11.6068C13.1354 11.068 13.1312 10.1902 12.5954 9.64623C12.0597 9.10221 11.1954 9.09795 10.6648 9.63671C10.5759 9.72697 10.4286 9.7416 10.3394 9.6516L9.35045 8.65315"
        stroke={props.stroke ? props.stroke : theme.palette.medication.main}
        strokeWidth="1.4"
      />
    </svg>
  );
};

export default LunchIcon;
