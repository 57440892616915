import React, { FC, useEffect } from 'react';
import { ClickNavCardNutrition, theme } from '@hdcorner/ui-library';
import { Box, styled, Typography, TypographyProps, useMediaQuery } from '@mui/material';
import { useIonRouter } from '@ionic/react';
import { useAppDispatch } from '../../../redux/hooks';
import { updateArticleCategory } from '../nutrition/slices/articlesSlice';
import SectionSuggestedArticle from '../nutrition/components/SectionSuggestedArticle';
import SectionHeading from './SectionHeading';
import useAlert from '../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';
import { useGetArticleCategoriesQuery } from '../nutrition/queries/articlesQueries';

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.light,
  fontWeight: theme.typography.fontWeightMedium,
}));

type Props = {
  handleGoToArticles?: () => void;
};
const SectionArticles: FC<Props> = ({ handleGoToArticles }) => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { presentError } = useAlert();
  const web = useMediaQuery(theme.breakpoints.up('md'));
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const { error, data: categoriesRes } = useGetArticleCategoriesQuery({
    lang: lang,
  });

  useEffect(() => {
    if (error) presentError(t('errors.fitness.nutrition.errorFetchArticleCategories'));
  }, [error]);

  const handleNavigation = (value: string) => {
    dispatch(updateArticleCategory({ title: value, value: value }));
    if (!web) {
      router.push('/dashboard/fitness/nutrition/articles');
    }
    if (handleGoToArticles) {
      handleGoToArticles();
    }
  };

  return (
    <Box mt={web ? 0 : 2}>
      <Box gap={1} display={'flex'} flexDirection={'column'}>
        {!web && <SectionSuggestedArticle />}
        {web && (
          <SectionHeading hasGoal={false} heading={t('headingsTitles.articleCat')} />
        )}
        <Box
          gap={1}
          display={'flex'}
          flexWrap={web ? 'wrap' : 'nowrap'}
          flexDirection={web ? 'row' : 'column'}
        >
          {!web && <Title variant={'subtitle2'}>{t('headingsTitles.categories')}</Title>}

          {categoriesRes?.categories.map((it, index) => (
            <Box key={`${it}-${index}`} flex={1} minWidth={web ? '252px' : 'none'}>
              <ClickNavCardNutrition
                noArrow
                key={it}
                headings={it}
                onClick={() => handleNavigation(it)}
                body={`${t('buttons.navigation.showArticles')}`}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default SectionArticles;
