import {
  ChatSendMessageIcon,
  ComponentsLayout,
  GroupParticipantCard,
  theme,
} from '@hdcorner/ui-library';
import { Box, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import PageLayout from '../../components/PageLayout';
import { useTranslation } from 'react-i18next';

type GroupParticipantsDataType = Record<string, { image: string; name: string }>;

const data: GroupParticipantsDataType = {
  232: {
    image:
      'https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
    name: 'Curtis Jackson',
  },
  145: {
    image:
      'https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
    name: 'Jason Statham',
  },
  34524: {
    image:
      'https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
    name: 'John Wick',
  },
  3992: {
    image:
      'https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
    name: 'Scarlett Johansson',
  },
};

const RecordsSend = () => {
  const { t } = useTranslation();

  const preparedData = Object.entries(data);
  const [selected, setSelected] = useState<string[]>(['']);

  const handleSelected = useCallback(
    (healthProfID: string) => {
      const selectedArray = [...selected];
      if (!selected.includes(healthProfID)) {
        selectedArray.push(healthProfID);
      } else {
        selectedArray.splice(selectedArray.indexOf(healthProfID), 1);
      }
      setSelected(selectedArray);
    },
    [selected],
  );

  const handleSendRecord = () => {
    console.log('Sending records');
  };

  return (
    <PageLayout
      headerTitle={t('records.titles.sendFiles')}
      endButtons={[
        {
          icon: <ChatSendMessageIcon />,
          onClick: handleSendRecord,
        },
      ]}
    >
      <ComponentsLayout>
        <Typography
          align={'center'}
          variant={'subtitle2'}
          sx={{
            marginBottom: theme.spacing(3),
            color: theme.palette.kmColorsCoolGrey.main,
            fontWeight: theme.typography.fontWeightRegular,
          }}
        >
          {t('records.text.chooseSend')}
        </Typography>

        {preparedData?.map(([key, val], index) => (
          <Box key={`keyHealthProf${index}`} marginTop={index === 0 ? 0 : 1}>
            <GroupParticipantCard
              healthProfKey={key}
              healthProfName={val.name}
              healthProfImage={val.image}
              handleSelected={handleSelected}
              checked={selected.includes(key)}
            />
          </Box>
        ))}
      </ComponentsLayout>
    </PageLayout>
  );
};

export default RecordsSend;
