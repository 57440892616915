import React, { FC, useMemo, useState } from 'react';
import { Box, Container, IconButton, Modal, Typography, useTheme } from '@mui/material';
import { ArrowLeft } from '@hdcorner/ui-library';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { VideoType } from '../types/VideoType';
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  singleVideo: boolean;
  handleClose: () => void;
  videosData: VideoType[];
  handleLastVideo?: () => void;
};

const VideoModal: FC<Props> = ({
  open,
  videosData,
  handleClose,
  singleVideo,
  handleLastVideo,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [playing, setPlaying] = useState<boolean>(false);
  const [videoNumber, setVideoNumber] = useState<number>(0);

  const videoPlaying = useMemo<VideoType>(() => {
    if (!videosData) return videosData[videoNumber];
    return {
      creator: '',
      title: videosData[videoNumber].title,
      watched: videosData[videoNumber].watched,
      section: videosData[videoNumber].bodyPart,
      time: videosData[videoNumber].duration || 0,
      videoUrl: videosData[videoNumber].videoUrl || '',
      thumbnailUrl: videosData[videoNumber].thumbnailUrl || '',
    };
  }, [videoNumber, videosData]);

  const handlePressPrevNext = (direction: 'prev' | 'next') => {
    setVideoNumber(direction === 'prev' ? videoNumber - 1 : videoNumber + 1);
  };

  const handleVideoEnded = () => {
    setTimeout(() => {
      if (videoNumber < videosData.length - 1) {
        setVideoNumber(videoNumber + 1);
      } else {
        console.log('Done');
      }
    }, 500);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Container
        disableGutters
        sx={{
          outline: 'none',
          position: 'relative',
        }}
      >
        <Box
          flex={1}
          top={16}
          left={0}
          right={0}
          zIndex={10}
          display={'flex'}
          position={'absolute'}
          justifyContent={'space-between'}
        >
          <Box display={'flex'} alignItems={'center'} paddingLeft={theme.spacing(2)}>
            <IconButton onClick={handleClose} sx={{ marginRight: theme.spacing(2) }}>
              <ArrowLeft color={theme.palette.secondary.light} />
            </IconButton>
            <Typography variant={'h1'} sx={{ color: theme.palette.secondary.light }}>
              {videoPlaying?.section && (
                <Typography
                  component={'span'}
                  variant={'h1'}
                  sx={{
                    color: theme.palette.secondary.light,
                  }}
                >
                  {videoPlaying.section} - {'  '}
                </Typography>
              )}
              {videoPlaying.title}
            </Typography>
          </Box>
          {!singleVideo && (
            <Box display={'flex'} alignItems={'center'} paddingRight={theme.spacing(2)}>
              <IconButton
                disabled={videoNumber <= 0}
                onClick={() => handlePressPrevNext('prev')}
                sx={{
                  cursor: 'pointer',
                  flexDirection: 'column',
                  opacity: videoNumber <= 0 ? 0.4 : 1,
                }}
              >
                <SkipPreviousIcon color={'secondary'} />
                <Typography variant={'body1'} color={'secondary'}>
                  {t('buttons.prev')}
                </Typography>
              </IconButton>

              <IconButton
                onClick={
                  videoNumber >= videosData?.length - 1 && !singleVideo
                    ? handleLastVideo
                    : () => handlePressPrevNext('next')
                }
                disabled={videoNumber >= videosData?.length - 1 && singleVideo}
                sx={{
                  cursor: 'pointer',
                  flexDirection: 'column',
                  marginLeft: theme.spacing(2),
                  opacity: videoNumber >= videosData?.length - 1 && singleVideo ? 0.4 : 1,
                }}
              >
                <SkipNextIcon color={'secondary'} />
                <Typography variant={'body1'} color={'secondary'}>
                  {videoNumber >= videosData?.length - 1 && !singleVideo
                    ? t('general.labels.completed')
                    : t('buttons.next')}
                </Typography>
              </IconButton>
            </Box>
          )}
        </Box>
        <video
          autoPlay
          controls
          width={'100%'}
          onEnded={handleVideoEnded}
          controlsList={'nodownload'}
          src={videoPlaying.videoUrl}
          onPlaying={() => setPlaying(true)}
          poster={playing ? '' : videoPlaying.thumbnailUrl}
          style={{
            objectFit: 'contain',
          }}
        />
      </Container>
    </Modal>
  );
};

export default VideoModal;
