import React, { FC, useMemo } from 'react';
import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  TypographyProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import TableHead from '@mui/material/TableHead';
import { TableCellProps } from '@mui/material/TableCell';
import { FEData } from './FEData';
import { FEDataEL } from './FEDataEL';

const TableCellData = styled(TableCell, {
  shouldForwardProp: prop =>
    prop !== 'web' && prop !== 'heading' && prop !== 'protein' && prop !== 'admin',
})<
  TableCellProps & { web: boolean; heading: boolean; protein?: boolean; admin: boolean }
>(({ web, theme, heading, protein, admin }) => ({
  flexGrow: '1',
  minWidth: '80px',
  textAlign: 'center',
  boxSizing: 'border-box',
  height: heading ? '40px' : 'auto',
  fontWeight: heading
    ? theme.typography.body2.fontWeight
    : theme.typography.fontWeightRegular,
  borderBottom: `1px solid ${theme.palette.secondary.dark}`,
  padding: web ? theme.spacing(1, 2) : theme.spacing(1),
  color:
    heading && admin
      ? theme.palette.kmColorsTurquoise.main
      : heading && !admin
      ? theme.palette.kmColorsRed.main
      : admin
      ? theme.palette.kmColorsCoolGrey.main
      : theme.palette.primary.main,
  backgroundColor: web ? theme.palette.secondary.main : theme.palette.secondary.light,
  fontSize: heading ? theme.typography.body2.fontSize : theme.typography.body1.fontSize,
  '&:first-of-type': {
    textAlign: heading || web ? 'center' : 'left',
  },
  '&:last-of-type': {
    textAlign: heading || web || protein ? 'center' : 'right',
  },
}));

const ProteinText = styled(Typography)<TypographyProps>(({ theme }) => ({
  '&:first-of-type': {
    marginBottom: theme.spacing(1),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

type Props = {
  header: string;
  admin?: boolean;
  lang?: 'en' | 'el';
};
const FETableInner: FC<Props> = ({ admin, header, lang = 'en' }) => {
  const theme = useTheme();
  const web = useMediaQuery(theme.breakpoints.up('sm'));

  const feEN = FEData();
  const feEL = FEDataEL();

  const data = useMemo(() => {
    if (lang === 'el') return feEL.find((it: any) => it.title === header);
    return feEN.find((it: any) => it.title === header);
  }, [lang, header]);

  const categoryA =
    data?.type === 'dairy' ||
    data?.type === 'fruit' ||
    data?.type === 'carbs' ||
    data?.type === 'fat';

  const categoryB =
    data?.type === 'vegetable' ||
    data?.type === 'carbs_other' ||
    data?.type === 'alcohol' ||
    data?.type === 'other';

  const categoryC = data?.type === 'protein';

  return (
    <Box
      gap={3}
      display={'flex'}
      padding={web ? 3 : 1.5}
      flexDirection={'column'}
      bgcolor={
        admin
          ? theme.palette.background.default
          : web
          ? theme.palette.secondary.light
          : 'transparent'
      }
    >
      <Box display={web ? 'block' : 'none'}>
        <Typography
          variant={'subtitle2'}
          textTransform={lang === 'el' ? 'capitalize' : 'uppercase'}
          sx={{
            fontWeight: theme.typography.fontWeightMedium,
            color:
              theme.palette.mode === 'dark'
                ? theme.palette.kmColorsCoolGrey.main
                : theme.palette.primary.main,
          }}
        >
          {header}
        </Typography>
      </Box>

      <Typography
        variant={web ? 'body1' : 'body2'}
        sx={{
          color:
            theme.palette.mode === 'dark'
              ? theme.palette.kmColorsCoolGrey.main
              : theme.palette.primary.main,
        }}
      >
        {data?.description}
      </Typography>

      <TableContainer
        sx={{
          borderRadius: '8px',
          backgroundColor:
            theme.palette.mode === 'dark'
              ? theme.palette.secondary.main
              : theme.palette.secondary.light,
        }}
      >
        <Table>
          {data &&
            data.data.map((value: any) => (
              <>
                {value.heading && (
                  <TableHead>
                    <TableRow>
                      <TableCellData
                        heading
                        web={web}
                        colSpan={2}
                        admin={admin ? admin : false}
                      >
                        {value.heading}
                      </TableCellData>
                    </TableRow>
                  </TableHead>
                )}

                {/*TABLE : DAIRY | FRUIT | CARBS | FAT */}
                {categoryA && (
                  <TableBody>
                    {value.table.map((it: any) => (
                      <TableRow>
                        {Object.values(it).map((value: any) => (
                          <TableCellData
                            web={web}
                            heading={false}
                            admin={admin ? admin : false}
                            sx={{ width: web ? '50%' : 'none' }}
                          >
                            {value}
                          </TableCellData>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                )}

                {/*TABLE : VEG | CARB_OTHER | ALCOHOL |OTHER*/}
                {categoryB && (
                  <TableBody>
                    <TableRow>
                      {Object.values(value).map((it: any) => (
                        <TableCellData
                          web={web}
                          heading={false}
                          admin={admin ? admin : false}
                        >
                          {it}
                        </TableCellData>
                      ))}
                    </TableRow>
                  </TableBody>
                )}

                {/*TABLE : PROTEIN*/}
                {categoryC && (
                  <TableBody>
                    <TableRow>
                      <TableCellData
                        protein
                        web={web}
                        heading={false}
                        admin={admin ? admin : false}
                      >
                        {Object.values(value).map((it: any) => (
                          <>
                            <ProteinText variant={'body1'}>{it}</ProteinText>
                          </>
                        ))}
                      </TableCellData>
                    </TableRow>
                  </TableBody>
                )}
              </>
            ))}
          {data && data?.note && (
            <caption
              style={{
                textAlign: 'center',
                color:
                  theme.palette.mode === 'dark'
                    ? theme.palette.kmColorsTurquoise.main
                    : theme.palette.kmColorsRed.main,
                backgroundColor: admin
                  ? theme.palette.secondary.main
                  : web
                  ? theme.palette.secondary.main
                  : theme.palette.secondary.light,
              }}
            >
              {data?.note}
            </caption>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};

export default FETableInner;
