import {
  Box,
  Card,
  CardActionArea,
  CardActionAreaProps,
  CardContent,
  CardContentProps,
  CardMedia,
  CardMediaProps,
  CardProps,
  Typography,
  useTheme,
} from '@mui/material';
import React, { FC } from 'react';
import ChatIcon from '../../assets/ChatIcon';
import { styled } from '@mui/material/styles';
import CardBackgroundSmall from '../../assets/CardBackgroundSmall';
import NotificationAddIcon from '../../assets/NotificationAddIcon';
import { TypographyProps } from '@mui/material/Typography/Typography';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTranslation } from 'react-i18next';

const CardMain = styled(Card)<CardProps>(({ theme }) => ({
  display: 'flex',
  borderRadius: 8,
  boxShadow: 'none',
  cursor: 'pointer',
  overflow: 'hidden',
  alignItems: 'center',
  background: theme.palette.primary.dark,
}));

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.highlight.main,
  fontWeight: theme.typography.fontWeightMedium,
}));

const Subtitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.highlight.main,
  fontWeight: theme.typography.fontWeightMedium,
}));

const SubtitleText = styled(Typography)<TypographyProps>(({ theme }) => ({
  textTransform: 'lowercase',
  color: theme.palette.highlight.main,
  marginLeft: theme.spacing(0.5),
}));

type DashboardCardSmallType = {
  color?: string;
  reminder?: boolean;
  onClick: () => void;
  unreadMessages: number | string;
  cardMediaProps?: CardMediaProps;
  cardContentProps?: CardContentProps;
  cardActionAreaProps?: CardActionAreaProps;
};
const DashboardCardSmall: FC<DashboardCardSmallType & CardProps> = ({
  color,
  onClick,
  reminder = false,
  unreadMessages,
  cardMediaProps,
  cardContentProps,
  cardActionAreaProps,
  ...cardProps
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const Background = () => {
    return (
      <Box
        width={'100%'}
        height={'100%'}
        sx={{ position: 'absolute', top: theme.spacing(0), right: theme.spacing(0) }}
      >
        <CardBackgroundSmall fill={color} width={'100%'} height={'100%'} />
      </Box>
    );
  };

  return (
    <CardMain raised={false} {...cardProps}>
      <CardActionArea onClick={onClick} {...cardActionAreaProps}>
        <CardMedia component={Background} {...cardMediaProps} />
        <CardContent sx={{ position: 'relative' }} {...cardContentProps}>
          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Box gap={1} display={'flex'} alignItems={'baseline'}>
              {!reminder && <ChatIcon />}
              {reminder && (
                <NotificationAddIcon
                  width={'13px'}
                  height={'15px'}
                  fill={theme.palette.secondary.light}
                />
              )}
              <Title variant={'subtitle1'}>
                {reminder ? t('reminders.titles.reminders') : t('chat.titles.chat')}
              </Title>
            </Box>
            <KeyboardArrowRightIcon sx={{ color: theme.palette.highlight.main }} />
          </Box>
          <Subtitle variant={'subtitle1'}>
            {unreadMessages}
            <SubtitleText variant={'body1'}>
              {reminder
                ? `${
                    unreadMessages === 1
                      ? t('reminders.titles.reminder')
                      : t('reminders.titles.reminders')
                  }`
                : t('reminders.labels.unread')}
            </SubtitleText>
          </Subtitle>
        </CardContent>
      </CardActionArea>
    </CardMain>
  );
};

export default DashboardCardSmall;
