import * as React from 'react';
import { SVGProps } from 'react';

const TooltipHealthProfessionalsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={22} height={23} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7 10.5v-2a1 1 0 0 1 2 0v2a1 1 0 1 1-2 0Zm7 1a1 1 0 0 0 1-1v-2a1 1 0 0 0-2 0v2a1 1 0 0 0 1 1Zm8 0a11 11 0 1 1-11-11 11.038 11.038 0 0 1 11 11Zm-19.972-.549 4.485 2.691 2.236-1.127a5.018 5.018 0 0 1 4.5 0l2.236 1.127 4.485-2.691a8.988 8.988 0 0 0-17.944 0h.002ZM19.8 13.388l-2.2 1.319 1.475.743c.326-.655.57-1.347.725-2.062ZM18 17.147 12.351 14.3a3.01 3.01 0 0 0-2.7 0L4 17.147a8.977 8.977 0 0 0 13.994 0H18ZM2.926 15.45l1.474-.743-2.2-1.319c.156.715.4 1.407.726 2.062Z"
      fill="#EE1D23"
    />
  </svg>
);

export default TooltipHealthProfessionalsIcon;
