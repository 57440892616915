import { FC, useEffect } from 'react';
import { LOG_LEVEL, Purchases } from '@revenuecat/purchases-capacitor';
import { Capacitor } from '@capacitor/core';

const InAppPayments: FC<{ userId: string }> = ({ userId }) => {
  useEffect(() => {
    if (Capacitor.getPlatform() === 'web') {
      console.log('InAppPayments is not available on web');
      return;
    }
    (async () => {
      try {
        if (Capacitor.getPlatform() === 'ios') {
          // remove line for production
          await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG });
          await Purchases.configure({
            appUserID: userId,
            apiKey: 'appl_KuNTDjQlOvKffsrFybZzNUcJLjD',
          });
        } else if (Capacitor.getPlatform() === 'android') {
          // remove line for production
          await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG });
          await Purchases.configure({
            appUserID: userId,
            apiKey: 'goog_BvQUDaMPVeopTFZGBfqOzVFXVda',
          });
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [userId]);

  return null;
};

export default InAppPayments;
