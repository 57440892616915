import { Box, Typography } from '@mui/material';
import { theme, ToggleCard, useDebounce } from '@hdcorner/ui-library';
import React, { useEffect, useRef, useState } from 'react';
import {
  useGetUserSettingsQuery,
  useUpdateLanguageSettingsMutation,
} from '../queries/userSettingsQueries';
import { useTranslation } from 'react-i18next';
import useAlert from '../../../hooks/useAlert';
import ProfileTypographyHeading from './ProfileTypographyHeading';

const languages = [
  { label: 'constants.languages.en', value: 'en' },
  { label: 'constants.languages.gr', value: 'el' },
];

const WebLanguage = () => {
  const { presentError } = useAlert();
  const { t, i18n } = useTranslation();

  const isFirstRef = useRef<boolean>(true);
  const [language, setLanguage] = useState<string>('en');

  const debounceLanguage = useDebounce(language, 200);

  const { error, data: userSettings } = useGetUserSettingsQuery();
  const [updateUserSettings] = useUpdateLanguageSettingsMutation();

  useEffect(() => {
    if (error) presentError(t('errors.profile.errorUserData'));
  }, [error]);

  const handleLanguageChange = (newValue: string) => {
    if (newValue === 'en') {
      i18n.changeLanguage('en');
    }
    if (newValue === 'el') {
      i18n.changeLanguage('el');
    }
    setLanguage(newValue);
  };

  useEffect(() => {
    if (userSettings && userSettings.length > 0) {
      const data = userSettings[0];
      if (data) {
        setLanguage(data.selectedLang || 'en');
      }
      const timeout = setTimeout(() => {
        isFirstRef.current = false;
        clearTimeout(timeout);
      }, 600);
    }
  }, [userSettings]);

  useEffect(() => {
    if (isFirstRef.current) {
      return;
    }
    updateUserSettings({
      selectedLang: language,
    })
      .unwrap()
      .catch(() => presentError(t('errors.profile.errorLangRegion')));
  }, [debounceLanguage]);

  return (
    <>
      <ProfileTypographyHeading text={'profile.titles.language'} />
      <Box
        padding={2}
        borderRadius={'8px'}
        boxSizing={'border-box'}
        bgcolor={theme.palette.highlight.main}
      >
        <Box flexGrow={'1'}>
          <Typography
            variant={'subtitle2'}
            sx={{ fontWeight: theme.typography.fontWeightRegular }}
          >
            {t('profile.titles.language')}
          </Typography>
          <Box rowGap={1} columnGap={3} flexGrow={'1'} display={'flex'}>
            {languages.map((lang, index) => (
              <ToggleCard
                value={lang.value}
                label={t(lang.label)}
                textTransform={'none'}
                key={`${index}-${lang.value}`}
                selected={language === lang.value}
                setSelected={handleLanguageChange}
                backgroundColor={theme.palette.secondary.main}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default WebLanguage;
