import ChartSleep from './ChartSleep';
import ChartWeight from './ChartWeight';
import SectionSleepData from './SectionSleepData';
import SectionWeightData from './SectionWeightData';
import { Box, BoxProps, styled } from '@mui/material';
import SectionGoalWeight from './SectionGoalWeight';

const BoxMain = styled(Box)<BoxProps>(({ theme }) => ({
  flex: 1,
  height: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  overflow: 'scroll',
  scrollbarWidth: 'none',
  gap: theme.spacing(3),
  msOverflowStyle: 'none',
  '::-webkit-scrollbar': {
    display: 'none',
  },
}));

const BoxColumn = styled(Box)<BoxProps>(({ theme }) => ({
  flex: '1 0 250px',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const WebWellness = () => {
  return (
    <BoxMain>
      <BoxColumn>
        <ChartWeight />
        <SectionWeightData />
        <SectionGoalWeight />
      </BoxColumn>
      <BoxColumn>
        <ChartSleep />
        <SectionSleepData />
      </BoxColumn>
    </BoxMain>
  );
};

export default WebWellness;
