import React from 'react';
import { useIonRouter } from '@ionic/react';
import { pageTitles } from '../../../utils/pageTitles';
import PageLayout from '../../../components/PageLayout';
import { capitalizeFirstLetter, ComponentsLayout } from '@hdcorner/ui-library';
import AddActivityPanel from './components/AddActivityPanel';
import { useTranslation } from 'react-i18next';

const AddActivity = () => {
  const router = useIonRouter();
  const { t } = useTranslation();

  const path = router?.routeInfo?.pathname;
  const pageTitle = pageTitles(path);

  return (
    <PageLayout
      defaultHref={'/dashboard/fitness/exercise'}
      headerTitle={
        pageTitle.toLowerCase() === 'custom activity'
          ? capitalizeFirstLetter(t('fitness.exercise.labels.customActivity'))
          : pageTitle
      }
    >
      <ComponentsLayout>
        <AddActivityPanel title={pageTitle} />
      </ComponentsLayout>
    </PageLayout>
  );
};

export default AddActivity;
