import React, { FC, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import {
  Avatar,
  AvatarProps,
  Box,
  Card,
  CardActionAreaProps,
  CardContent,
  CardContentProps,
  CardMedia,
  CardProps,
  Typography,
  useTheme,
} from '@mui/material';
import ArticleIcon from '../../assets/ArticleIcon';
import ArrowRight from '../../assets/ArrowRight';

const CardMain = styled(Card)<CardProps>(({ theme }) => ({
  height: 166,
  width: '100%',
  minWidth: 350,
  display: 'flex',
  overflow: 'hidden',
  borderRadius: '8px',
  position: 'relative',
  flexDirection: 'column',
  boxShadow: theme.shadows[4],
}));

const CardActionAreaMain = styled(Box)(() => ({
  flex: 1,
  display: 'flex',
  position: 'unset',
  justifyContent: 'space-between',
}));

const CardContentMain = styled(CardContent)<CardContentProps>(({ theme }) => ({
  height: '100%',
  position: 'relative',
  padding: theme.spacing(0),
  '&.MuiCardContent-root:last-child': {
    paddingBottom: theme.spacing(0),
  },
}));

const AvatarMain = styled(Avatar)<AvatarProps>(({ theme }) => ({
  width: 32,
  height: 32,
  cursor: 'pointer',
  borderRadius: 100,
  border: `1px solid ${theme.palette.primary.light}`,
}));

type Props = {
  title: string;
  author: string;
  category: string;
  imageSrc: string;
  createdOn?: string;
  avatarSrc?: string;
  altIcon?: ReactNode;
  cardContentProps?: CardContentProps;
  cardActionAreaProps?: CardActionAreaProps;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};
const ArticleCard: FC<Props & CardProps> = ({
  title,
  author,
  altIcon,
  onClick,
  category,
  imageSrc,
  avatarSrc,
  createdOn,
  cardContentProps,
  cardActionAreaProps,
  ...cardProps
}) => {
  const theme = useTheme();

  return (
    <CardMain {...cardProps}>
      <CardContentMain {...cardContentProps}>
        <CardActionAreaMain onClick={onClick}>
          <Box
            position={'absolute'}
            sx={{
              flex: 1,
              zIndex: 30,
              height: '100%',
              maxHeight: 166,
              display: 'flex',
              flexDirection: 'column',
              top: theme.spacing(0),
              left: theme.spacing(0),
              right: theme.spacing(0),
              justifyContent: 'space-between',
            }}
          >
            <Box
              flex={1}
              height={115}
              maxHeight={115}
              display={'flex'}
              alignItems={'flex-start'}
              padding={theme.spacing(1.5, 1.5, 0, 1.5)}
              sx={{
                background:
                  'linear-gradient(180deg, #232323 2.82%, rgba(48, 48, 48, 0) 100%)',
              }}
            >
              <Box
                flex={1}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Box display={'flex'} alignItems={'center'}>
                  <ArticleIcon />
                  <Typography
                    variant={'subtitle2'}
                    sx={{
                      textTransform: 'capitalize',
                      marginLeft: theme.spacing(1),
                      color:
                        theme.palette.mode === 'dark'
                          ? theme.palette.primary.main
                          : theme.palette.secondary.main,
                    }}
                  >
                    {category}
                  </Typography>
                </Box>
                {altIcon ? altIcon : <ArrowRight fill={theme.palette.secondary.main} />}
              </Box>
            </Box>
            <Box
              gap={1}
              flex={1}
              height={51}
              maxHeight={51}
              display={'flex'}
              alignItems={'center'}
              paddingX={theme.spacing(1)}
              bgcolor={
                theme.palette.mode === 'dark'
                  ? theme.palette.secondary.main
                  : theme.palette.secondary.light
              }
            >
              <Box
                flex={1}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Box gap={1} flex={1} display={'flex'}>
                  {avatarSrc && <AvatarMain src={avatarSrc} />}
                  <Box>
                    <Typography
                      variant={'subtitle2'}
                      sx={{
                        color:
                          theme.palette.mode === 'dark'
                            ? theme.palette.kmColorsCoolGrey.main
                            : theme.palette.primary.main,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        maxWidth: createdOn ? '157px' : 'none',
                        fontWeight: theme.typography.fontWeightMedium,
                      }}
                    >
                      {title}
                    </Typography>
                    <Typography
                      variant={'body1'}
                      sx={{
                        color:
                          theme.palette.mode === 'dark'
                            ? theme.palette.kmColorsCoolGrey.main
                            : theme.palette.primary.main,
                      }}
                    >
                      {author}
                    </Typography>
                  </Box>
                </Box>
                {createdOn && (
                  <Typography
                    variant={'caption'}
                    sx={{
                      color:
                        theme.palette.mode === 'dark'
                          ? theme.palette.kmColorsCoolGrey.main
                          : theme.palette.primary.main,
                      fontWeight: theme.typography.fontWeightBold,
                    }}
                  >
                    {createdOn}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
          <CardMedia
            src={imageSrc}
            component={'img'}
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </CardActionAreaMain>
      </CardContentMain>
    </CardMain>
  );
};

export default ArticleCard;
