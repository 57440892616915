import React, { FC } from 'react';
import { Box, BoxProps, styled, Typography, TypographyProps } from '@mui/material';

const CustomBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: '100%',
  display: 'flex',
  borderRadius: '8px',
  alignItems: 'flex-end',
  boxSizing: 'border-box',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.secondary.light,
  padding: theme.spacing(1, 2),
  margin: theme.spacing(0, 0, 1, 0),
  '&:last-of-type': {
    margin: theme.spacing(0),
  },
}));

const CustomInnerBox = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
}));

const CustomTypographyAmount = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightRegular,
  padding: theme.spacing(0, 1, 0, 0),
}));

const CustomTypographyData = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.kmColorsCoolGrey.main,
}));

type InfoCardDosageProps = {
  date: string;
  amount: number;
  measurement: string;
};
const InfoCardDosage: FC<InfoCardDosageProps> = ({ date, amount, measurement }) => {
  return (
    <CustomBox>
      <CustomInnerBox>
        <CustomTypographyAmount variant={'subtitle2'}>{amount}</CustomTypographyAmount>
        <CustomTypographyData variant={'body1'}>{measurement}</CustomTypographyData>
      </CustomInnerBox>
      <CustomInnerBox>
        <CustomTypographyData variant={'body1'}>{date}</CustomTypographyData>
      </CustomInnerBox>
    </CustomBox>
  );
};

export default InfoCardDosage;
