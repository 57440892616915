import * as React from 'react';
import { useMemo, useState } from 'react';
import {
  ComponentsLayout,
  CustomTabs,
  HypertensionLog,
  HypertensionSQ,
  ModalHypertension,
  ModalMobile,
  theme,
} from '@hdcorner/ui-library';
import PageLayout from '../../components/PageLayout';
import {
  useAddHypertensionLogMutation,
  useGetFirstLogOfGoalQuery,
  useGetPressureGoalQuery,
} from './queries/hypertensionQueries';
import HypertensionPressure from './HypertensionPressure';
import HypertensionPulses from './HypertensionPulses';
import useAlert from '../../hooks/useAlert';
import { Camera, CameraResultType } from '@capacitor/camera';
import { useTranslation } from 'react-i18next';
import { goalProgress } from '../../utils/goalProgress';
import { addGoalDetails } from '../dashboard/slices/congratulationsSlice';
import { useAppDispatch } from '../../redux/hooks';
import { cameraPermissions } from '../../utils/cameraPermissions';

const Hypertension = () => {
  const dispatch = useAppDispatch();
  const { presentSuccess, presentError } = useAlert();
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [hypertensionModal, setHypertensionModal] = useState(false);

  const [addHypertensionLog] = useAddHypertensionLogMutation();

  /**
   * Checks needed for Congrats modal
   */
  const { data: pressureGoal } = useGetPressureGoalQuery();
  const goalData = useMemo(() => {
    if (!pressureGoal || pressureGoal.length === 0) return { sys: 0, dia: 0, date: '' };
    return {
      sys: pressureGoal[0].goal.systolic,
      dia: pressureGoal[0].goal.diastolic,
      date: pressureGoal[0].createdAt,
    };
  }, [pressureGoal]);

  const { data: starting } = useGetFirstLogOfGoalQuery(
    {
      start: goalData.date,
    },
    { skip: !goalData.date },
  );

  const startData = useMemo(() => {
    if (!starting || starting.documents.length === 0)
      return { systolic: 0, diastolic: 0 };
    return {
      systolic: starting.documents[0].systolic,
      diastolic: starting.documents[0].diastolic,
    };
  }, [starting]);

  const handleOpenHypertensionModal = () => setHypertensionModal(true);

  const handleAddHypertension = (data: Partial<HypertensionLog>) => {
    let { afib, diastolic, systolic, pulses, category, logDate } = data;
    addHypertensionLog({ afib, systolic, diastolic, pulses, category, logDate })
      .unwrap()
      .then(() => {
        presentSuccess(t('errors.hypertension.successAddLog'));
      })
      .catch(e => {
        presentError(t('errors.hypertension.errorAddLog'));
      })
      .finally(() => {
        setHypertensionModal(false);
        if (
          goalData.date !== '' &&
          goalProgress(data.systolic || 0, goalData.sys, startData.systolic) === 100 &&
          goalProgress(data.diastolic || 0, goalData.dia, startData.diastolic) === 100
        ) {
          setTimeout(() => {
            dispatch(
              addGoalDetails({
                category: 'hypertension',
                goalHyp: {
                  systolic: goalData.sys,
                  diastolic: goalData.dia,
                  measure: 'mm HG',
                },
                startHyp: {
                  systolic: startData.systolic,
                  diastolic: startData.diastolic,
                  measure: 'mm HG',
                  time: goalData.date,
                },
              }),
            );
          }, 1500);
        }
      });
  };

  const handleHypertensionOcr = async (
    cb: (newMeasurement: { systolic: string; diastolic: string; pulses: string }) => void,
  ) => {
    const cameraSource = await cameraPermissions();
    if (!cameraSource) {
      presentError(t('errors.devicePermissions.camera'));
      return;
    }
    const image = await Camera.getPhoto({
      quality: 90,
      source: cameraSource,
      resultType: CameraResultType.Base64,
    });

    setLoading(true);
    await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer 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',
      },
      body: JSON.stringify({
        max_tokens: 300,
        model: 'gpt-4-vision-preview',
        messages: [
          {
            role: 'user',
            content: [
              {
                type: 'text',
                text: 'Read the text on the device screen resembling the following: 140 mmHg 104 mmHg 87 pul/min and return just the number measurements',
              },
              {
                type: 'image_url',
                image_url: {
                  url: `data:image/jpeg;base64,${image.base64String}`,
                },
              },
            ],
          },
        ],
      }),
    })
      .then(response => response.json())
      .then(data => {
        setLoading(false);
        if (data.choices.length === 0) {
          presentError(t('errors.generic.errorMeasurement'));
          return;
        }
        if (data.choices[0].message.content === 'No measurement detected') {
          presentError(t('errors.generic.errorMeasurement'));
          return;
        }

        const measurements = data.choices[0].message.content;
        const splitMeasurements = measurements.split(', ');
        const systolic = splitMeasurements[0];
        const diastolic = splitMeasurements[1];
        const pulses = splitMeasurements[2];
        cb({ systolic, diastolic, pulses });
        presentSuccess(t('errors.generic.measurementDetected'));
      })
      .catch(error => {
        setLoading(false);
        presentError(t('errors.generic.errorImage'));
      });
  };

  return (
    <PageLayout
      defaultHref={'/dashboard/home'}
      fabClick={handleOpenHypertensionModal}
      headerColor={theme.palette.hypertension.main}
      headerTitle={t('dashboard.dashcards.hypertension.title')}
      headerIcon={<HypertensionSQ color={theme.palette.hypertension.main} />}
    >
      <ComponentsLayout disablePaddingBottom marginBottom={theme.spacing(1)}>
        <CustomTabs
          value={selectedTab}
          setValue={setSelectedTab}
          tabs={[
            {
              children: <HypertensionPressure />,
              label: t('hypertension.labels.pressure'),
            },
            {
              children: <HypertensionPulses />,
              label: t('dashboard.dashcards.hypertension.pulses'),
            },
          ]}
        />
      </ComponentsLayout>
      <ModalMobile open={hypertensionModal} setOpen={setHypertensionModal}>
        <ModalHypertension
          isLoading={isLoading}
          triggerOCR={cb => handleHypertensionOcr(cb)}
          handleModalSaveClick={handleAddHypertension}
        />
      </ModalMobile>
    </PageLayout>
  );
};

export default Hypertension;
