import {
  Box,
  BoxProps,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import React, { FC, ReactNode } from 'react';
import FacebookRoundIcon from '../assets/FacebookRoundIcon';
import InstagramIcon from '../assets/InstagramIcon';
import TikTokIcon from '../assets/TikTokIcon';
import YouTubeIcon from '../assets/YouTubeIcon';

const MainBox = styled(Box)<BoxProps & { web: boolean }>(({ web, theme }) => ({
  flex: 1,
  display: 'flex',
  borderRadius: '8px',
  alignItems: 'center',
  flexDirection: 'column',
  boxSizing: 'border-box',
  gap: theme.spacing(6),
  height: web ? '95%' : '100%',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  margin: theme.spacing(web ? 3 : 0),
  backgroundColor: theme.palette.secondary.light,
}));

const SecondaryBox = styled(Box)<BoxProps>(({ theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: theme.spacing(6),
}));

type Props = {
  logo: ReactNode;
};

const AboutUsInner: FC<Props> = ({ logo }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const web = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <MainBox web={web}>
      <SecondaryBox>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: web ? '48px' : '36px',
            lineHeight: web ? '48px' : '36px',
            fontWeight: theme.typography.fontWeightMedium,
          }}
        >
          {t('aboutUs.title')}
        </Typography>
        <Typography
          sx={{
            fontWeight: theme.typography.subtitle1.fontWeight,
            fontSize: web ? '24px' : theme.typography.h1.fontSize,
          }}
        >
          {`${t('aboutUs.motto')}`}
        </Typography>
        <Typography
          align={'center'}
          variant={'subtitle1'}
          sx={{ maxWidth: '576px', color: theme.palette.kmColorsCoolGrey.main }}
        >
          {t('aboutUs.description')}
        </Typography>

        {logo}

        <Box gap={2} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <a
            target={'_blank'}
            rel={'noreferrer'}
            aria-label={'Facebook link'}
            href={'https://www.facebook.com/hdcornerofficial/'}
          >
            <FacebookRoundIcon />
          </a>

          <a
            target={'_blank'}
            rel={'noreferrer'}
            aria-label={'Instagram link'}
            href={'https://www.instagram.com/hd.corner_way_of_life/'}
          >
            <InstagramIcon />
          </a>

          <a
            target={'_blank'}
            rel={'noreferrer'}
            aria-label={'TikTok link'}
            href={'https://www.tiktok.com/@karabinis.medical'}
          >
            <TikTokIcon />
          </a>

          <a
            target={'_blank'}
            rel={'noreferrer'}
            aria-label={'Youtube link'}
            href={'https://www.youtube.com/@KarabinisMedicalSA'}
          >
            <YouTubeIcon />
          </a>
        </Box>
      </SecondaryBox>

      <Typography
        variant={'body1'}
        sx={{
          color: theme.palette.kmColorsCoolGrey.main,
          fontWeight: theme.typography.fontWeightRegular,
        }}
      >
        {t('aboutUs.footer')}
      </Typography>
    </MainBox>
  );
};

export default AboutUsInner;
