import { FC } from 'react';
import Box from '@mui/material/Box';
import { CustomButton, ModalMobile } from '@hdcorner/ui-library';
import { BoxProps, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

const MainBox = styled(Box, {
  shouldForwardProp: propName => propName !== 'web',
})<BoxProps & { web: boolean }>(({ web, theme }) => ({
  display: 'flex',
  borderRadius: '8px',
  gap: theme.spacing(3),
  boxSizing: 'border-box',
  flexDirection: 'column',
  padding: theme.spacing(3),
  width: web ? '351px' : 'none',
}));

type Props = {
  open: boolean;
  handleFindOne: () => void;
  handleEnterCode: () => void;
  setOpen: (open: boolean) => void;
};

const ModalCCPrompt: FC<Props> = ({ open, setOpen, handleEnterCode, handleFindOne }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const web = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <ModalMobile open={open} setOpen={setOpen}>
      <MainBox web={web}>
        <Typography
          variant={'subtitle2'}
          sx={{ fontWeight: theme.typography.fontWeightBold }}
        >
          {t('healthProf.clinicalCoach.text.maximizeYour')}
        </Typography>

        <Box gap={1} display={'flex'} flexDirection={'column'}>
          <Typography
            variant={'subtitle2'}
            sx={{ color: theme.palette.kmColorsCoolGrey.main }}
          >
            {t('healthProf.clinicalCoach.text.congratsAddingHcp')}
          </Typography>
          <Typography
            variant={'subtitle2'}
            sx={{ color: theme.palette.kmColorsCoolGrey.main }}
          >
            {t('healthProf.clinicalCoach.text.toFurther')}
          </Typography>
          <Typography
            variant={'subtitle2'}
            sx={{ color: theme.palette.kmColorsCoolGrey.main }}
          >
            {t('healthProf.clinicalCoach.text.youCanEnter')}
          </Typography>
        </Box>

        <Box gap={2} display={'flex'} flexDirection={'column'}>
          <CustomButton fullWidth variant={'contained'} onClick={handleEnterCode}>
            {t('buttons.alreadyHaveCC')}
          </CustomButton>

          <CustomButton
            fullWidth
            variant={'outlined'}
            style={{ height: 48 }}
            onClick={handleFindOne}
          >
            {t('buttons.findCC')}
          </CustomButton>
        </Box>
      </MainBox>
    </ModalMobile>
  );
};

export default ModalCCPrompt;
