import { FC, ReactNode } from 'react';
import {
  Box,
  BoxProps,
  IconButton,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import DeleteCircleIcon from '../../assets/DeleteCircleIcon';

const BoxMain = styled(Box)<BoxProps>(() => ({
  flexGrow: 1,
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const TextMain = styled(Typography)<TypographyProps>(({ theme }) => ({
  textAlign: 'left',
  textTransform: 'capitalize',
  color: theme.palette.primary.main,
  fontSize: theme.typography.subtitle2.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
}));

const TextSecondary = styled(Typography)<TypographyProps>(({ theme }) => ({
  textAlign: 'left',
  marginRight: theme.spacing(1),
  fontSize: theme.typography.body1.fontSize,
  color: theme.palette.kmColorsCoolGrey.main,
  fontWeight: theme.typography.body1.fontWeight,
}));

type FoodItemsProps = {
  name: string;
  admin?: boolean;
  calories?: number;
  quantity?: number;
  icon?: ReactNode;
  handleAddItem?: () => void;
};
const FoodItems: FC<FoodItemsProps> = ({
  icon,
  name,
  admin = false,
  calories,
  quantity,
  handleAddItem,
}) => {
  return (
    <BoxMain>
      <Box>
        <TextMain>{name}</TextMain>
        <TextSecondary>{quantity ? `${quantity}gr` : '100g'}</TextSecondary>
      </Box>

      <Box
        display={'flex'}
        alignItems={'center'}
        onClick={admin ? undefined : handleAddItem}
      >
        <TextSecondary>{`${calories ? calories : '-'} kcal`}</TextSecondary>
        {!admin && (
          <IconButton sx={{ padding: 0 }}>
            {icon ? icon : <DeleteCircleIcon />}
          </IconButton>
        )}
      </Box>
    </BoxMain>
  );
};

export default FoodItems;
