import { useMemo } from 'react';
import { HypertensionLog } from '../types/HypertensionLog';
import { useTranslation } from 'react-i18next';

const usePreparePressureGraph = (graphData?: { logs: HypertensionLog[] }) => {
  const { t } = useTranslation();

  return useMemo<{ name: string; data: { x: string; y: number }[] }[]>(() => {
    if (!graphData || graphData.logs.length === 0) return [];

    const data: { name: string; data: { x: string; y: number }[] }[] = [
      { name: t('hypertension.labels.diastolic'), data: [] },
      { name: t('hypertension.labels.systolic'), data: [] },
    ];

    graphData.logs.forEach(item => {
      data[0].data.push({
        x: item.logDate,
        y: item.diastolic,
      });
      data[1].data.push({
        x: item.logDate,
        y: item.systolic,
      });
    });

    return data;
  }, [graphData]);
};

export default usePreparePressureGraph;
