import RadioSections from '../components/RadioSections';
import React, { FC } from 'react';
import SliderSection from '../components/SliderSection';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

type CalcOptInnerProps = {
  changePerWeek: number;
  activityLevel: 'light' | 'moderate' | 'heavy';
  goal: 'lose_weight' | 'muscle_gain' | 'maintain_weight';
  dietType: 'standard' | 'low_carb' | 'low_fat' | 'high_protein';
  setChangePerWeek: (value: number) => void;
  setActivityLevel: (value: 'light' | 'moderate' | 'heavy') => void;
  setGoal: (value: 'lose_weight' | 'muscle_gain' | 'maintain_weight') => void;
  setDietType: (value: 'standard' | 'low_carb' | 'low_fat' | 'high_protein') => void;
};
const CalcOptInner: FC<CalcOptInnerProps> = ({
  goal,
  setGoal,
  dietType,
  setDietType,
  changePerWeek,
  activityLevel,
  setActivityLevel,
  setChangePerWeek,
}) => {
  const { t } = useTranslation();

  return (
    <Box display={'flex'} flexDirection={'column'} gap={3}>
      <RadioSections
        value={goal}
        data={[
          { value: 'muscle_gain', choice: t('fitness.dietPlan.text.gainMuscle') },
          { value: 'lose_weight', choice: t('fitness.dietPlan.text.loseFat') },
          { value: 'maintain_weight', choice: t('fitness.dietPlan.text.keepWeight') },
        ]}
        radioGroup={'goal'}
        title={t('fitness.dietPlan.text.iWant')}
        onChange={event => setGoal(event.target.value as any)}
      />
      {goal !== 'maintain_weight' && (
        <SliderSection
          grams={changePerWeek}
          title={
            goal === 'muscle_gain'
              ? t('fitness.dietPlan.text.gain')
              : t('fitness.dietPlan.text.lose')
          }
          onChange={value => setChangePerWeek(value as any)}
        />
      )}
      <RadioSections
        data={[
          { value: 'light', choice: t('fitness.dietPlan.text.light') },
          { value: 'moderate', choice: t('fitness.dietPlan.text.moderate') },
          { value: 'heavy', choice: t('fitness.dietPlan.text.intense') },
        ]}
        value={activityLevel}
        title={t('fitness.dietPlan.text.activityLevel')}
        radioGroup={'activityLevel'}
        onChange={event => setActivityLevel(event.target.value as any)}
      />
      <RadioSections
        value={dietType}
        data={[
          { value: 'low_fat', choice: t('fitness.dietPlan.text.lowFat') },
          { value: 'standard', choice: t('fitness.dietPlan.text.standard') },
          { value: 'low_carb', choice: t('fitness.dietPlan.text.lowCarb') },
          { value: 'high_protein', choice: t('fitness.dietPlan.text.highProtein') },
        ]}
        radioGroup={'dietType'}
        title={t('fitness.dietPlan.text.dietType')}
        onChange={event => setDietType(event.target.value as any)}
      />
    </Box>
  );
};

export default CalcOptInner;
