import React, { useMemo, useState } from 'react';
import GoalCard from './GoalCard';
import Carousel from './Carousel';
import useGetBloodGlucoseGoalData from '../hooks/useGetBloodGlucoseGoalData';
import useGetTotalCholGoalData from '../hooks/useGetCholGoalData';
import useGetCvdGoalData from '../hooks/useGetCvdGoalData';
import useGetDiastolicGoalData from '../hooks/useGetDiastolicGoalData';
import useGetHbacGoalData from '../hooks/useGetHbac1GoalData';
import useGetHdlGoalData from '../hooks/useGetHdlGoalData';
import useGetLdlGoalData from '../hooks/useGetLdlGoalData';
import useGetLpaGoalData from '../hooks/useGetLpaGoalData';
import useGetSystolicGoalData from '../hooks/useGetSystolicGoalData';
import useGetTrgGoalData from '../hooks/useGetTrgGoalData';
import { Box, Typography } from '@mui/material';
import useGetWeightGoalData from '../hooks/useGetWeightGoalData';
import useGetExerciseGoalData from '../hooks/useGetExerciseGoalData';
import { useTranslation } from 'react-i18next';

const GoalsSection = () => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const cvdGoalData = useGetCvdGoalData();
  const hdlGoalData = useGetHdlGoalData();
  const ldlGoalData = useGetLdlGoalData();
  const lpaGoalData = useGetLpaGoalData();
  const trgGoalData = useGetTrgGoalData();
  const hbacGoalData = useGetHbacGoalData();
  const weightGoalData = useGetWeightGoalData();
  const systolicGoalData = useGetSystolicGoalData();
  const exerciseGoalData = useGetExerciseGoalData();
  const diastolicGoalData = useGetDiastolicGoalData();
  const totalCholGoalData = useGetTotalCholGoalData();
  const bloodGlucoseGoal = useGetBloodGlucoseGoalData();

  const constructPyramids = useMemo(() => {
    const pyramids = [];

    let index = 0;

    if (bloodGlucoseGoal) {
      pyramids.push(
        <GoalCard
          key={'bloodGlucoseGoal'}
          hidden={activeIndex !== index}
          progress={bloodGlucoseGoal.progress}
          goalValue={bloodGlucoseGoal.goalValue}
          startValue={bloodGlucoseGoal.startValue}
          title={t('dashboard.goalCards.glucose')}
          currentValue={bloodGlucoseGoal.currentValue}
        />,
      );
      index++;
    }
    if (totalCholGoalData) {
      pyramids.push(
        <GoalCard
          key={'totalCholGoalData'}
          hidden={activeIndex !== index}
          title={t('dashboard.goalCards.chol')}
          progress={totalCholGoalData.progress}
          goalValue={totalCholGoalData.goalValue}
          startValue={totalCholGoalData.startValue}
          currentValue={totalCholGoalData.currentValue}
        />,
      );
      index++;
    }

    if (hdlGoalData) {
      pyramids.push(
        <GoalCard
          key={'hdlGoalData'}
          hidden={activeIndex !== index}
          progress={hdlGoalData.progress}
          goalValue={hdlGoalData.goalValue}
          startValue={hdlGoalData.startValue}
          title={t('dashboard.goalCards.hdl')}
          currentValue={hdlGoalData.currentValue}
        />,
      );
      index++;
    }

    if (ldlGoalData) {
      pyramids.push(
        <GoalCard
          key={'ldlGoalData'}
          hidden={activeIndex !== index}
          progress={ldlGoalData.progress}
          goalValue={ldlGoalData.goalValue}
          startValue={ldlGoalData.startValue}
          title={t('dashboard.goalCards.ldl')}
          currentValue={ldlGoalData.currentValue}
        />,
      );
      index++;
    }

    if (lpaGoalData) {
      pyramids.push(
        <GoalCard
          key={'lpaGoalData'}
          hidden={activeIndex !== index}
          progress={lpaGoalData.progress}
          goalValue={lpaGoalData.goalValue}
          startValue={lpaGoalData.startValue}
          title={t('dashboard.goalCards.lpa')}
          currentValue={lpaGoalData.currentValue}
        />,
      );
      index++;
    }

    if (trgGoalData) {
      pyramids.push(
        <GoalCard
          key={'trgGoalData'}
          hidden={activeIndex !== index}
          progress={trgGoalData.progress}
          goalValue={trgGoalData.goalValue}
          startValue={trgGoalData.startValue}
          title={t('dashboard.goalCards.trg')}
          currentValue={trgGoalData.currentValue}
        />,
      );
      index++;
    }

    if (hbacGoalData) {
      pyramids.push(
        <GoalCard
          key={'hbacGoalData'}
          hidden={activeIndex !== index}
          progress={hbacGoalData.progress}
          goalValue={hbacGoalData.goalValue}
          startValue={hbacGoalData.startValue}
          title={t('dashboard.goalCards.hba1c')}
          currentValue={hbacGoalData.currentValue}
        />,
      );
      index++;
    }

    if (systolicGoalData) {
      pyramids.push(
        <GoalCard
          key={'systolicGoalData'}
          hidden={activeIndex !== index}
          title={t('dashboard.goalCards.systolic')}
          goalValue={systolicGoalData.goalSystolic}
          startValue={systolicGoalData.startSystolic}
          progress={systolicGoalData.progressSystolic}
          currentValue={systolicGoalData.currentSystolic}
        />,
      );
      index++;
    }

    if (diastolicGoalData) {
      pyramids.push(
        <GoalCard
          key={'diastolicGoalData'}
          hidden={activeIndex !== index}
          title={t('dashboard.goalCards.diastolic')}
          goalValue={diastolicGoalData.goalDiastolic}
          startValue={diastolicGoalData.startDiastolic}
          progress={diastolicGoalData.progressDiastolic}
          currentValue={diastolicGoalData.currentDiastolic}
        />,
      );
      index++;
    }

    if (cvdGoalData) {
      pyramids.push(
        <GoalCard
          key={'cvdGoalData'}
          hidden={activeIndex !== index}
          progress={cvdGoalData.progress}
          goalValue={cvdGoalData.goalValue}
          startValue={cvdGoalData.startValue}
          title={t('dashboard.goalCards.cvd')}
          currentValue={cvdGoalData.currentValue}
        />,
      );
      index++;
    }

    if (weightGoalData) {
      pyramids.push(
        <GoalCard
          key={'weightGoalData'}
          hidden={activeIndex !== index}
          progress={weightGoalData.progress}
          goalValue={weightGoalData.goalValue}
          startValue={weightGoalData.startValue}
          title={t('dashboard.goalCards.weight')}
          currentValue={weightGoalData.currentValue}
        />,
      );
      index++;
    }

    if (exerciseGoalData) {
      pyramids.push(
        <GoalCard
          key={'exerciseGoalData'}
          hidden={activeIndex !== index}
          progress={exerciseGoalData.progress}
          goalValue={exerciseGoalData.goalValue}
          startValue={exerciseGoalData.startValue}
          title={t('dashboard.goalCards.exercise')}
          currentValue={exerciseGoalData.currentValue}
        />,
      );
      index++;
    }

    return pyramids;
  }, [
    t,
    hdlGoalData,
    ldlGoalData,
    lpaGoalData,
    trgGoalData,
    activeIndex,
    cvdGoalData,
    hbacGoalData,
    weightGoalData,
    bloodGlucoseGoal,
    exerciseGoalData,
    systolicGoalData,
    diastolicGoalData,
    totalCholGoalData,
  ]);

  const renderCarousel = () => {
    if (constructPyramids.length > 0) {
      return (
        <Carousel
          activeIndex={activeIndex}
          handleChangeElement={index => {
            setActiveIndex(index);
          }}
        >
          {constructPyramids.map(constructPyramid => constructPyramid)}
        </Carousel>
      );
    } else {
      return (
        <Box>
          <Typography>{t('dashboard.goalCards.noGoals')}</Typography>
        </Box>
      );
    }
  };

  return renderCarousel();
};

export default GoalsSection;
