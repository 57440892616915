import { FC } from 'react';
import { CustomButton, ModalMobile, theme } from '@hdcorner/ui-library';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type ModalCancelSubProps = {
  web: boolean;
  open: boolean;
  handleStayPremium: () => void;
  setOpen: (open: boolean) => void;
  handleCancelSubscription: () => void;
};
const ModalCancelSub: FC<ModalCancelSubProps> = ({
  web,
  open,
  setOpen,
  handleStayPremium,
  handleCancelSubscription,
}) => {
  const { t } = useTranslation();

  return (
    <ModalMobile
      open={open}
      height={'auto'}
      setOpen={setOpen}
      width={web ? '512px' : '80%'}
    >
      <Box
        gap={3}
        padding={2}
        display={'flex'}
        boxSizing={'border-box'}
        flexDirection={'column'}
      >
        <Typography
          variant={'subtitle1'}
          sx={{
            fontWeight: theme.typography.fontWeightMedium,
          }}
        >
          {t('subscription.text.sure')}
        </Typography>
        <Typography
          variant={'subtitle2'}
          sx={{ color: theme.palette.kmColorsCoolGrey.main }}
        >
          {t('subscription.text.warning')}
        </Typography>
        <Box gap={3} display={'flex'}>
          <CustomButton
            fullWidth
            variant={'contained'}
            onClick={handleCancelSubscription}
            style={{
              backgroundColor: theme.palette.primary.light,
            }}
          >
            {t('buttons.yesCancel')}
          </CustomButton>
          <CustomButton
            fullWidth
            variant={'contained'}
            onClick={handleStayPremium}
            sx={{
              backgroundColor: theme.palette.primary.main,
            }}
          >
            {t('buttons.stayPremium')}
          </CustomButton>
        </Box>
      </Box>
    </ModalMobile>
  );
};

export default ModalCancelSub;
