import { FC } from 'react';
import Box from '@mui/material/Box';
import { CustomButton, ModalMobile } from '@hdcorner/ui-library';
import { storeModalCCReminder } from '../utils/clinicalCoachPromptStorage';
import { BoxProps, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

const MainBox = styled(Box, {
  shouldForwardProp: propName => propName !== 'web',
})<BoxProps & { web: boolean }>(({ web, theme }) => ({
  display: 'flex',
  borderRadius: '8px',
  boxSizing: 'border-box',
  flexDirection: 'column',
  gap: theme.spacing(3),
  width: web ? '351px' : 'none',
  padding: theme.spacing(3),
}));

type Props = {
  open: boolean;
  handleFindOne: () => void;
  handleEnterCode: () => void;
  setOpen: (open: boolean) => void;
};

const ModalFindCC: FC<Props> = ({ open, setOpen, handleFindOne, handleEnterCode }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const web = useMediaQuery(theme.breakpoints.up('md'));

  const handleRemindMe = () => {
    storeModalCCReminder();
    setOpen(false);
  };

  return (
    <ModalMobile open={open} setOpen={setOpen}>
      <MainBox web={web}>
        <Typography
          variant={'subtitle2'}
          sx={{ fontWeight: theme.typography.fontWeightBold }}
        >
          {t('healthProf.clinicalCoach.text.findRightCC')}
        </Typography>

        <Typography
          variant={'subtitle2'}
          sx={{ color: theme.palette.kmColorsCoolGrey.main }}
        >
          {t('healthProf.clinicalCoach.text.connectWithCC')}
        </Typography>

        <Box gap={2} display={'flex'} flexDirection={'column'}>
          <CustomButton fullWidth variant={'contained'} onClick={handleEnterCode}>
            {t('buttons.enterCode')}
          </CustomButton>

          <Box gap={2} display={'flex'}>
            <CustomButton
              fullWidth
              variant={'outlined'}
              style={{ height: 48 }}
              onClick={handleFindOne}
            >
              {t('buttons.findOne')}
            </CustomButton>
            <CustomButton
              fullWidth
              variant={'contained'}
              onClick={handleRemindMe}
              style={{
                backgroundColor: theme.palette.kmColorsCoolGrey.main,
              }}
            >
              {t('buttons.remindLater')}
            </CustomButton>
          </Box>
        </Box>
      </MainBox>
    </ModalMobile>
  );
};

export default ModalFindCC;
