import { FC } from 'react';
import CopySQIcon from '../assets/CopySQIcon';
import { Box, IconButton, Typography, useTheme } from '@mui/material';

type IDBadgeProps = {
  id?: string;
};
const IDBadge: FC<IDBadgeProps> = ({ id }) => {
  const theme = useTheme();

  const handleCopyID = () => {
    typeof id === 'string' ? navigator.clipboard.writeText(id) : undefined;
  };

  return (
    <Box
      height={'28px'}
      display={'flex'}
      borderRadius={'4px'}
      alignItems={'center'}
      boxSizing={'border-box'}
      justifyContent={'space-between'}
      padding={theme.spacing(0.5)}
      bgcolor={
        theme.palette.mode === 'dark'
          ? theme.palette.kmColorsTurquoise.main
          : theme.palette.warning.main
      }
    >
      <Typography
        variant={'body1'}
        color={theme.palette.mode === 'dark' ? 'secondary' : 'primary'}
        sx={{
          marginRight: theme.spacing(1),
          fontWeight: theme.typography.fontWeightBold,
        }}
      >
        {id}
      </Typography>

      <IconButton onClick={handleCopyID} sx={{ padding: 0 }}>
        <CopySQIcon />
      </IconButton>
    </Box>
  );
};

export default IDBadge;
