import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Food, MealItem } from '@hdcorner/ui-library';
import { ExistingCustomMeal } from '../types/ExistingCustomMeal';

type FoodAndCategory = Partial<Food> & { category?: string };

// foods is an array of new Foods to be adding to a meal
// mealItems is an array of MealItems already existing in a selected meal
const initialState: {
  mealId: string;
  logDate: string;
  mealCategory: string;
  addFoodDetails: Food;
  mealItems: MealItem[];
  foods: FoodAndCategory[];
  existingCustomMeal: ExistingCustomMeal;
} = {
  foods: [],
  mealId: '',
  logDate: '',
  mealItems: [],
  mealCategory: '',
  addFoodDetails: {
    _id: '',
    name: {
      en: '',
      el: '',
    },
    fat: 0,
    carbs: 0,
    protein: 0,
    calories: 0,
    quantity: 0,
  },
  existingCustomMeal: {
    _id: '',
    foods: [],
    mealName: '',
  },
};

const mealFoodsSlice = createSlice({
  name: 'mealFoodsList',
  initialState,
  reducers: {
    addFoodItem: (state, action: PayloadAction<Partial<FoodAndCategory>>) => {
      state.foods = [...state.foods, action.payload];
    },
    removeFoodItem: (state, action: PayloadAction<string>) => {
      const index = state.foods.findIndex(it => it._id === action.payload);
      if (index !== -1) {
        state.foods.splice(index, 1);
      }
    },
    deleteFoodArray: state => {
      state.foods = [];
    },
    storeExistingMeal: (state, action: PayloadAction<ExistingCustomMeal>) => {
      state.existingCustomMeal = action.payload;
    },
    removeExistingMeal: state => {
      state.existingCustomMeal = {
        _id: '',
        foods: [],
        mealName: '',
      };
    },
    updateCategory: (state, action: PayloadAction<string>) => {
      state.mealCategory = action.payload;
    },
    updateMealId: (state, action: PayloadAction<string>) => {
      state.mealId = action.payload;
    },
    updateMealLogDate: (state, action: PayloadAction<string>) => {
      state.logDate = action.payload;
    },
    deleteMealLogDate: state => {
      state.logDate = '';
    },
    updateAddFoodDetails: (state, action: PayloadAction<Food>) => {
      state.addFoodDetails = action.payload;
    },
    createAPIFoodArray: (state, action: PayloadAction<MealItem[]>) => {
      state.mealItems = action.payload;
    },
    cleanSlate: state => {
      state.foods = [];
      state.mealId = '';
      state.logDate = '';
      state.mealItems = [];
      state.addFoodDetails = {
        _id: '',
        name: {
          en: '',
          el: '',
        },
        fat: 0,
        carbs: 0,
        protein: 0,
        calories: 0,
        quantity: 0,
      };
    },
  },
});

export const {
  cleanSlate,
  addFoodItem,
  updateMealId,
  removeFoodItem,
  updateCategory,
  deleteFoodArray,
  deleteMealLogDate,
  updateMealLogDate,
  storeExistingMeal,
  removeExistingMeal,
  createAPIFoodArray,
  updateAddFoodDetails,
} = mealFoodsSlice.actions;

export default mealFoodsSlice.reducer;
