import {
  Collapsible,
  CvdRiskChartWithRisk,
  CvdRiskRanges,
  GoalActionButtons,
  GoalDetailsCard,
  ModalMobile,
  usePrepareInDepthData,
  useTimeframe,
} from '@hdcorner/ui-library';
import {
  Box,
  ListItem,
  styled,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import ModalRemoveGoal from '../../components/ModalRemoveGoal';
import ModalAddEditGoal from '../../components/ModalAddEditGoal';
import { useAppSelector } from '../../redux/hooks';
import {
  useAddCvdRiskGoalMutation,
  useDeleteCvdRiskGoalMutation,
  useEditCvdRiskGoalMutation,
  useGetCvdCurrentMeasurementQuery,
  useGetCvdGraphDataQuery,
  useGetCvdRiskGoalQuery,
  useGetCvdRiskLogsQuery,
  useGetCvdStartingMeasurementQuery,
} from './queries/cvdRiskQueries';
import useIdealData from './hooks/useIdealData';
import usePrepareGraphData from './hooks/usePrepareGraphData';
import useAlert from '../../hooks/useAlert';
import InDepthHeading from '../fitness/components/InDepthHeading';
import usePrepareGoalData from './hooks/usePrepareGoalData';
import InfiniteScrollList from '../../components/InfiniteScrollList';
import { useTranslation } from 'react-i18next';

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightMedium,
}));

const UserDataCvd = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { presentSuccess, presentError } = useAlert();

  const { dateFilter, customDateFilter } = useAppSelector(state => state.layout);

  const [modalGoalOpen, setModalGoalOpen] = useState<boolean>(false);
  const [modalRemoveGoalOpen, setModalRemoveGoalOpen] = useState(false);
  const [cvdRiskParams, setCvdRiskParams] = useState({
    skip: 0,
    limit: 20,
  });

  const [addCvdRiskGoal] = useAddCvdRiskGoalMutation();
  const [editCvdRiskGoal] = useEditCvdRiskGoalMutation();
  const [deleteCvdRiskGoalMutation] = useDeleteCvdRiskGoalMutation();

  const timeframe = useTimeframe(
    dateFilter,
    customDateFilter?.start,
    customDateFilter?.end,
  );

  const { data: graphData, error: errorGraphData } = useGetCvdGraphDataQuery(timeframe);

  const { data: currentMeasurement } = useGetCvdCurrentMeasurementQuery();
  const { data: cvdRiskGoal, error: cvdRiskGoalError } = useGetCvdRiskGoalQuery();
  const {
    data: cvdRiskLogs,
    error: cvdRiskError,
    isFetching: cvdRiskFetching,
  } = useGetCvdRiskLogsQuery({
    ...timeframe,
    ...cvdRiskParams,
  });

  const { data: cvdRiskFirstLog, error: cvdRiskFirstError } =
    useGetCvdStartingMeasurementQuery(
      {
        startingDate: cvdRiskGoal?.[0]?.startDate,
      },
      { skip: Boolean(!cvdRiskGoal?.[0]) },
    );
  const { data: cvdRiskLastLog, error: cvdRiskLastError } =
    useGetCvdCurrentMeasurementQuery();

  const getGoalValue = useMemo(() => {
    if (!cvdRiskGoal || cvdRiskGoal.length === 0) return '';
    return cvdRiskGoal[0].goal || '';
  }, [cvdRiskGoal]);

  const idealData = useIdealData(currentMeasurement);
  const prepareGraphData = usePrepareGraphData(graphData);
  const prepareInDepthData = usePrepareInDepthData(cvdRiskLogs);
  const prepareGoalData = usePrepareGoalData(
    cvdRiskGoal,
    cvdRiskLastLog,
    cvdRiskFirstLog,
  );

  useEffect(() => {
    if (errorGraphData) {
      const { status, data } = errorGraphData as any;
      if (status === 500) {
        presentError(data.message);
      } else {
        presentError(t('errors.cvd.fetchGraph'));
      }
    }
  }, [errorGraphData]);

  useEffect(() => {
    if (cvdRiskError) {
      presentError(t('errors.cvd.fetchLogs'));
    }
    if (cvdRiskFirstError || cvdRiskLastError || cvdRiskGoalError) {
      presentError(t('errors.cvd.fetchGoal'));
    }
  }, [cvdRiskError, cvdRiskFirstError, cvdRiskGoalError, cvdRiskLastError]);

  const getCurrentScore = useMemo(() => {
    if (!cvdRiskLastLog || (cvdRiskLastLog as any).logs.length === 0) return null;

    return (cvdRiskLastLog as any).logs[0].score.toString();
  }, [cvdRiskLastLog]);

  const handleClickRemove = () => {
    if (cvdRiskGoal?.[0]) {
      deleteCvdRiskGoalMutation({ _id: cvdRiskGoal[0]._id })
        .unwrap()
        .then(() => {
          setModalRemoveGoalOpen(false);
          presentSuccess(t('errors.cvd.goalRemoved'));
        })
        .catch(e => {
          console.error(e);
          setModalRemoveGoalOpen(false);
          presentError(t('errors.cvd.errorRemoveGoal'));
        });
    }
  };

  const handleSaveClick = (newGoal: string) => {
    setModalGoalOpen(false);
    if (newGoal) {
      try {
        if (!cvdRiskGoal?.[0]) {
          addCvdRiskGoal({
            goalType: 'cvd_risk',
            goal: parseFloat(newGoal),
            startDate: moment().startOf('day').toISOString(),
          }).unwrap();
          presentSuccess(t('errors.cvd.goalAdded'));
        } else {
          editCvdRiskGoal({
            goalType: 'cvd_risk',
            _id: cvdRiskGoal[0]._id,
            goal: parseFloat(newGoal),
            startDate: cvdRiskGoal[0].createdAt,
          }).unwrap();
          presentSuccess(t('errors.cvd.goalUpdated'));
        }
      } catch (e) {
        console.error(e);
        presentError(t('errors.cvd.errorAddGoal'));
      }
    }
  };

  const handleAddCvdGoal = () => {
    setModalGoalOpen(true);
  };

  const handleEditCvdGoal = () => {
    setModalGoalOpen(true);
  };

  const handleRemoveCvdGoal = () => {
    setModalRemoveGoalOpen(true);
  };

  return (
    <>
      <Box display={'flex'} flexDirection={'column'} gap={theme.spacing(3)}>
        <Box display={'flex'} flex={1} gap={theme.spacing(3)}>
          <Box display={'flex'} flex={1} flexDirection={'column'}>
            <Title variant={'subtitle1'}>RISK CALCULATOR</Title>
            <CvdRiskChartWithRisk data={idealData} chartData={prepareGraphData} />
          </Box>
          <Box display={'flex'} flex={1} flexDirection={'column'}>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <Title variant={'subtitle1'}>GOAL</Title>
              <GoalActionButtons
                handleAdd={handleAddCvdGoal}
                handleEdit={handleEditCvdGoal}
                handleRemove={handleRemoveCvdGoal}
                type={getGoalValue ? 'edit' : 'add'}
              />
            </Box>
            <Box
              flex={1}
              display={'flex'}
              borderRadius={'8px'}
              flexDirection={'column'}
              padding={theme.spacing(1)}
              marginTop={theme.spacing(1)}
              bgcolor={theme.palette.secondary.light}
            >
              {prepareGoalData?.map((item, index) => (
                <Box key={`${item}${index}`} mt={theme.spacing(2)}>
                  <GoalDetailsCard goal={item} />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        <Box display={'flex'} flex={1} gap={theme.spacing(3)}>
          <Box display={'flex'} flexDirection={'column'} flex={1} gap={1}>
            <InDepthHeading module={'cvd'} />
            <InfiniteScrollList
              loading={cvdRiskFetching}
              skip={cvdRiskParams.skip}
              limit={cvdRiskParams.limit}
              totalCount={cvdRiskLogs?.count}
              count={cvdRiskLogs?.logs.length}
              onLoadMore={(skip, limit) => {
                setCvdRiskParams({ skip, limit });
              }}
            >
              {prepareInDepthData.map((item: any, index: number) => {
                const title = parseInt(item.values['Overall Risk'].value);
                return (
                  <ListItem key={index} disablePadding disableGutters>
                    <Collapsible
                      icon
                      titleSub={'%'}
                      data={item.values}
                      title={title.toString()}
                      progress={item.progress}
                    />
                  </ListItem>
                );
              })}
            </InfiniteScrollList>
          </Box>
          <Box flex={1}>
            <Box
              flex={1}
              padding={2}
              display={'flex'}
              borderRadius={'8px'}
              flexDirection={'column'}
              bgcolor={theme.palette.secondary.light}
            >
              <CvdRiskRanges />
            </Box>
          </Box>
        </Box>
      </Box>
      <ModalMobile open={modalRemoveGoalOpen} setOpen={setModalRemoveGoalOpen}>
        <ModalRemoveGoal
          handleRemoveClick={handleClickRemove}
          handleCancelClick={() => setModalRemoveGoalOpen(false)}
        />
      </ModalMobile>
      <ModalMobile width={'350px'} open={modalGoalOpen} setOpen={setModalGoalOpen}>
        <ModalAddEditGoal
          goalType={'cvd'}
          goal={getGoalValue}
          desiredPlaceholder={`1%`}
          handleSaveClick={handleSaveClick}
          currentMeasurement={getCurrentScore}
        />
      </ModalMobile>
    </>
  );
};

export default UserDataCvd;
