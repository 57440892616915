const dietTypes: any = {
  standard: {
    name: 'Standard',
    description: 'Standard diet',
    carbs: 0.5,
    protein: 0.2,
    fat: 0.3,
  },
  low_carb: {
    name: 'Low carb',
    description: 'Low carb diet',
    carbs: 0.3,
    protein: 0.25,
    fat: 0.45,
  },
  low_fat: {
    name: 'Low fat',
    description: 'Low fat diet',
    carbs: 0.55,
    protein: 0.2,
    fat: 0.25,
  },
  high_protein: {
    name: 'High protein',
    description: 'High protein diet',
    carbs: 0.35,
    protein: 0.4,
    fat: 0.25,
  },
};

const activityLevels = {
  light: {
    name: 'Light',
    description: 'Light activity level',
    value: 1.3,
  },
  moderate: {
    name: 'Moderate',
    description: 'Moderate activity level',
    value: 1.55,
  },
  heavy: {
    name: 'Heavy',
    description: 'Heavy activity level',
    value: 1.725,
  },
};

export { dietTypes, activityLevels };
