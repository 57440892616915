import * as React from 'react';
import { SVGProps } from 'react';
import { useTheme } from '@mui/material';

const NavHomeIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();

  return (
    <svg
      {...props}
      fill="none"
      viewBox="0 0 19 20"
      xmlns="http://www.w3.org/2000/svg"
      width={props && props.width ? props.width : 19}
      height={props && props.height ? props.height : 20}
    >
      <path
        d="M18.5 19V6.40002C18.5001 6.23638 18.46 6.07523 18.3833 5.93069C18.3066 5.78616 18.1956 5.66265 18.06 5.57102L10.06 0.171019C9.89463 0.0592431 9.6996 -0.000488281 9.5 -0.000488281C9.3004 -0.000488281 9.10537 0.0592431 8.94 0.171019L0.94 5.57102C0.804431 5.66265 0.693419 5.78616 0.616705 5.93069C0.539991 6.07523 0.499917 6.23638 0.5 6.40002V19C0.5 19.2652 0.605357 19.5196 0.792893 19.7071C0.98043 19.8947 1.23478 20 1.5 20H17.5C17.7652 20 18.0196 19.8947 18.2071 19.7071C18.3946 19.5196 18.5 19.2652 18.5 19ZM16.5 18H2.5V6.93202L9.5 2.20702L16.5 6.93202V18Z"
        fill={props && props.color ? props.color : theme.palette.primary.main}
      />
    </svg>
  );
};

export default NavHomeIcon;
