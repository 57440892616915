import PageLayout from '../../../components/PageLayout';
import { ComponentsLayout } from '@hdcorner/ui-library';
import { useIonRouter } from '@ionic/react';
import React from 'react';
import { pageTitles } from '../../../utils/pageTitles';
import { useAppSelector } from '../../../redux/hooks';
import AddFoodInner from './components/AddFoodInner';
import useQuery from '../../../hooks/useQuery';

const AddFood = () => {
  const router = useIonRouter();
  const searchParams = useQuery();
  const customMeal = searchParams.get('customMeal');
  const customFood = searchParams.get('customFood');

  const path = router?.routeInfo?.pathname;
  const pageTitle = pageTitles(path);

  const { mealCategory } = useAppSelector(state => state.mealFoodsList);

  return (
    <PageLayout
      headerTitle={pageTitle}
      defaultHref={`/dashboard/fitness/food-meals/${mealCategory}`}
    >
      <ComponentsLayout>
        <AddFoodInner customFood={!!customFood} customMeal={!!customMeal} />
      </ComponentsLayout>
    </PageLayout>
  );
};

export default AddFood;
