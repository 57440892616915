import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Box,
  BoxProps,
  IconButton,
  Input,
  InputProps,
  styled,
  useTheme,
} from '@mui/material';
import ChatSendMessageIcon from '../../assets/ChatSendMessageIcon';

const MessageBox = styled(Box)<BoxProps>(({ theme }) => ({
  height: '32px',
  display: 'flex',
  minWidth: '359px',
  alignItems: 'center',
  borderRadius: '50px',
  backgroundColor: theme.palette.secondary.light,
}));

const CustomInput = styled(Input)<InputProps>(({ theme }) => ({
  textAlign: 'left',
  color: theme.palette.primary.main,
  fontSize: theme.typography.subtitle2.fontSize,
  backgroundColor: theme.palette.secondary.light,
  fontWeight: theme.typography.subtitle2.fontWeight,
}));

type ChatMessageMobileProps = {
  disabled?: boolean;
  placeholder: string;
  handleAddContentClick: () => void;
  handleSendMessage: (message: string) => void;
};

const ChatMessageMobile: FC<ChatMessageMobileProps> = ({
  placeholder,
  disabled = false,
  handleSendMessage,
  handleAddContentClick,
}) => {
  const theme = useTheme();

  const [message, setMessage] = useState('');

  const handleInput = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setMessage(event.target.value);
  };

  const handleSubmit = useCallback(() => {
    handleSendMessage(message);
    setMessage('');
  }, [handleSendMessage, message]);

  return (
    <MessageBox>
      {/*<IconButton*/}
      {/*  onClick={handleAddContentClick}*/}
      {/*  sx={{*/}
      {/*    padding: theme.spacing(0.5, 2, 0.5, 0.5),*/}
      {/*  }}*/}
      {/*  disabled={disabled}*/}
      {/*>*/}
      {/*  <ChatAddContentIcon />*/}
      {/*</IconButton>*/}
      <Box
        sx={{
          flexGrow: '1',
        }}
      >
        <Box
          pl={3}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <CustomInput
            fullWidth
            type={message}
            value={message}
            disableUnderline
            disabled={disabled}
            onChange={handleInput}
            placeholder={placeholder}
          />
          <IconButton
            type={'submit'}
            disabled={disabled}
            onClick={handleSubmit}
            sx={{
              padding: theme.spacing(1, 1.5),
            }}
          >
            <ChatSendMessageIcon />
          </IconButton>
        </Box>
      </Box>
    </MessageBox>
  );
};

export default ChatMessageMobile;
