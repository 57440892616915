import * as React from 'react';
import { SVGProps } from 'react';

const ActiveIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.63672 1.68452C8.76491 1.87638 8.83333 2.10194 8.83333 2.33268C8.83333 2.48589 8.80316 2.6376 8.74453 2.77915C8.6859 2.92069 8.59996 3.04931 8.49163 3.15764C8.38329 3.26598 8.25468 3.35191 8.11313 3.41054C7.97159 3.46917 7.81988 3.49935 7.66667 3.49935C7.43592 3.49935 7.21036 3.43093 7.0185 3.30273C6.82664 3.17454 6.67711 2.99233 6.58881 2.77915C6.50051 2.56597 6.4774 2.33139 6.52242 2.10508C6.56743 1.87877 6.67855 1.67089 6.84171 1.50773C7.00487 1.34456 7.21275 1.23345 7.43906 1.18843C7.66537 1.14342 7.89995 1.16652 8.11313 1.25482C8.32631 1.34313 8.50852 1.49266 8.63672 1.68452Z"
        fill="#222222"
        stroke="#222222"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.79953 4.93268C4.92543 4.76481 5.12302 4.66602 5.33286 4.66602H9.33286C9.63877 4.66602 9.90543 4.87421 9.97962 5.17099L10.5493 7.4496L12.8279 8.01925C13.1851 8.10855 13.4023 8.47051 13.313 8.82771C13.2237 9.1849 12.8617 9.40208 12.5045 9.31278L9.83784 8.64611C9.59898 8.5864 9.41248 8.3999 9.35277 8.16104L9.03478 6.8891L8.65325 8.79676C8.58104 9.1578 8.22983 9.39194 7.86878 9.31974C7.50775 9.24753 7.2736 8.89631 7.34581 8.53527L7.85299 5.99935H6.56428L6.06508 8.99455L9.00919 10.761C9.13413 10.836 9.23107 10.9498 9.28518 11.0851L10.6185 14.4184C10.7553 14.7603 10.589 15.1483 10.2471 15.285C9.90527 15.4217 9.51729 15.2555 9.38054 14.9136L8.13062 11.7888L4.98987 9.90434C4.75502 9.76344 4.63024 9.49323 4.67527 9.22308L5.08294 6.77702L3.8662 8.39935C3.78483 8.50783 3.67233 8.58892 3.54368 8.6318L1.54368 9.29847C1.19439 9.4149 0.816839 9.22613 0.700408 8.87683C0.583976 8.52754 0.772749 8.14999 1.12204 8.03356L2.92448 7.43275L4.79953 4.93268ZM5.16122 10.6859C5.51842 10.7752 5.73559 11.1372 5.64629 11.4944L5.31296 12.8277C5.28365 12.9449 5.22304 13.052 5.1376 13.1374L3.1376 15.1374C2.87725 15.3978 2.45514 15.3978 2.19479 15.1374C1.93444 14.8771 1.93444 14.455 2.19479 14.1946L4.06422 12.3252L4.35277 11.171C4.44207 10.8138 4.80402 10.5966 5.16122 10.6859Z"
        fill="#222222"
      />
    </svg>
  );
};

export default ActiveIcon;
