import { useMemo } from 'react';
import { AuthUser, ChatMessage, ChatMessageType } from '@hdcorner/ui-library';

export const usePrepareChatMessageListData = (
  userId?: string,
  chatMessages?: ChatMessageType[],
  senders?: AuthUser[],
): ChatMessage[] => {
  return useMemo(
    () =>
      chatMessages?.map(chatMessage => {
        const sender = senders?.find(sender => sender._id === chatMessage.senderUser);
        return {
          id: chatMessage._id,
          date: chatMessage.createdAt,
          message: chatMessage.message,
          lastName: sender?.lastName ?? '',
          firstName: sender?.firstName ?? '',
          avatar: sender?.profilePicture?.url,
          currentUser: userId === chatMessage.senderUser,
          read: chatMessage.readBy?.includes(userId ?? '') ?? false,
        };
      }) ?? [],
    [chatMessages, senders, userId],
  );
};
