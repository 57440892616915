import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import {
  Collapsible,
  ComponentsLayout,
  DownloadIcon,
  InDepthDataCard,
  theme,
} from '@hdcorner/ui-library';
import PageLayout from '../../components/PageLayout';
import moment from 'moment';
import {
  useGetHbac1LogsQuery,
  useGetHbac1OverallDataQuery,
} from './queries/diabetesQueries';
import ModalInDepthDataDL from '../../components/ModalInDepthDataDL';
import useAlert from '../../hooks/useAlert';
import InfiniteScrollList from '../../components/InfiniteScrollList';
import { useTranslation } from 'react-i18next';

const Hbac1InDepthData = () => {
  const { t } = useTranslation();
  const { presentError } = useAlert();

  const [downloadDataModalOpen, setDownloadDataModalOpen] = useState(false);
  const [hba1cParams, setHba1cParams] = useState({
    skip: 0,
    limit: 20,
  });

  const { data: overallData, error: overallError } = useGetHbac1OverallDataQuery();
  const {
    isLoading,
    data: hba1cLogs,
    error: hba1cError,
  } = useGetHbac1LogsQuery({
    params: hba1cParams,
  });

  useEffect(() => {
    if (hba1cError) {
      presentError(t('errors.diabetes.errorsHba1c.fetchLogs'));
    }

    if (overallError) {
      presentError(t('errors.diabetes.errorsHba1c.fetchOverall'));
    }
  }, [hba1cError, overallError]);

  const prepareData = useMemo(() => {
    if (!hba1cLogs) return [];

    return hba1cLogs.documents.map(item => {
      return {
        title: item.measurement.toString(),
        titleSub: '%',
        data: {
          HbA1c: { value: `${item.measurement.toString()}%` },
          [t('inDepth.timeOfCalc')]: { value: moment(item.logDate).format('h:mm A') },
          [t('inDepth.dateOfCalc')]: {
            value: moment(item.logDate).format('MMM Do, YYYY'),
          },
        },
      };
    });
  }, [hba1cLogs]);

  const prepareOverallData = useMemo(() => {
    if (!overallData)
      return [
        {
          name: t('inDepth.all'),
          min: t('general.labels.nA'),
          max: t('general.labels.nA'),
          avg: t('general.labels.nA'),
        },
      ];
    const data = overallData;
    return [
      {
        name: t('inDepth.all'),
        min: data.min ? data.min.toFixed() : t('general.labels.nA'),
        max: data.max ? data.max.toFixed() : t('general.labels.nA'),
        avg: data.avg ? data.avg.toFixed() : t('general.labels.nA'),
      },
    ];
  }, [overallData]);

  return (
    <PageLayout
      defaultHref={'/dashboard/diabetes'}
      headerTitle={t('headingsTitles.inDepth')}
      headerColor={theme.palette.primary.main}
      endButtons={[
        {
          icon: <DownloadIcon />,
          onClick: () => setDownloadDataModalOpen(true),
        },
      ]}
    >
      <ComponentsLayout display={'flex'} flexDirection={'column'} gap={theme.spacing(2)}>
        <InDepthDataCard
          measurement={'%'}
          rows={prepareOverallData}
          headings={[
            `${t('measurements.min')}`,
            `${t('measurements.max')}`,
            `${t('measurements.avg')}`,
          ]}
        />
        <Box>
          <Typography
            variant="h1"
            marginBottom={theme.spacing(2)}
            color={theme.palette.primary.main}
            fontWeight={theme.typography.fontWeightRegular}
          >
            {t('headingsTitles.latestUpdates')}
          </Typography>
          <InfiniteScrollList
            loading={isLoading}
            skip={hba1cParams.skip}
            limit={hba1cParams.limit}
            totalCount={hba1cLogs?.count || 0}
            count={hba1cLogs?.documents.length}
            onLoadMore={(skip, limit) => {
              setHba1cParams({ skip, limit });
            }}
          >
            {prepareData.map((data, index) => (
              <Box>
                <Collapsible
                  key={index}
                  data={data.data}
                  title={data.title}
                  titleSub={data.titleSub}
                />
              </Box>
            ))}
          </InfiniteScrollList>
        </Box>
      </ComponentsLayout>
      <ModalInDepthDataDL
        module={'hbac1'}
        open={downloadDataModalOpen}
        dismiss={() => setDownloadDataModalOpen(false)}
      />
    </PageLayout>
  );
};

export default Hbac1InDepthData;
