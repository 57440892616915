import React, { FC } from 'react';
import { IonDatetime } from '@ionic/react';
import { styled } from '@mui/material/styles';
import { Box, BoxProps, Modal } from '@mui/material';
import { CustomButton, theme } from '@hdcorner/ui-library';

const CustomBox = styled(Box)<BoxProps>(({ theme }) => ({
  top: '50%',
  left: '50%',
  borderRadius: '8px',
  position: 'absolute',
  boxSizing: 'border-box',
  transform: 'translate(-50%, -50%)',
  paddingTop: theme.spacing(1),
  backgroundColor: theme.palette.secondary.light,
}));

const styles = {
  width: '100%',
  color: theme.palette.kmColorsCoolGrey.main,
};

export type DatePickerProps = {
  open: boolean;
  handleSaveDate?: () => void;
  selectedDate?: string | string[];
  setOpen: (open: boolean) => void;
  setSelectedDate: (selectedDate: string | string[]) => void;
};

const DatePicker: FC<DatePickerProps> = ({
  open,
  setOpen,
  handleSaveDate,
  selectedDate,
  setSelectedDate,
}) => {
  const handleClose = () => setOpen(false);

  return (
    <Modal open={open} onClose={handleClose}>
      <CustomBox>
        <IonDatetime
          style={styles}
          presentation="date"
          preferWheel={true}
          value={selectedDate}
          onIonChange={event => setSelectedDate(event.detail.value!)}
        ></IonDatetime>

        <Box padding={theme.spacing(1.5)} boxSizing={'border-box'}>
          <CustomButton
            fullWidth
            color={'primary'}
            variant={'contained'}
            children={'Continue'}
            onClick={handleSaveDate}
          />
        </Box>
      </CustomBox>
    </Modal>
  );
};

export default DatePicker;
