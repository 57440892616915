import moment from 'moment';
import InDepthHeading from './InDepthHeading';
import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { CalendarModal, Collapsible, FeelsEmpty, theme } from '@hdcorner/ui-library';
import { useGetCaloriesInDepthQuery } from '../calories/queries/caloriesQueries';
import useTimeframe from '../../../hooks/useTimeframe';
import useAlert from '../../../hooks/useAlert';
import InfiniteScrollList from '../../../components/InfiniteScrollList';
import { useTranslation } from 'react-i18next';

const CalorieDataInner = () => {
  const { t } = useTranslation();
  const { presentError } = useAlert();

  const [calendarModalOpen, setCalendarModalOpen] = useState<boolean>(false);

  // Used for updating the params for the query
  const [selectedDate, setSelectedDate] = useState<string>(
    moment().startOf('day').utc(true).toISOString(),
  );

  const [params, setParams] = useState({
    skip: 0,
    limit: 20,
    sort: ['-createdAt'],
  });

  const timeframe = useTimeframe('custom', selectedDate);

  const {
    error,
    isFetching,
    data: calorieRes,
  } = useGetCaloriesInDepthQuery({
    params: params,
    ...timeframe,
  });

  useEffect(() => {
    if (error) {
      presentError(t('errors.fitness.calories.error01'));
    }
  }, [error]);

  const inDepthData = useMemo(() => {
    if (!calorieRes || calorieRes.documents.length === 0) return [];

    return calorieRes.documents.map(it => {
      return {
        _id: it._id,
        titleSub: t('measurements.kcal'),
        title: Math.round(it.eaten),
        data: {
          [t('fitness.calories.titles.KcalConsumed')]: { value: Math.round(it.eaten) },
          [t('fitness.calories.titles.kcalBurned')]: { value: Math.round(it.burned) },
          [t('fitness.calories.titles.kcalLeft')]: {
            value: Math.round(it.left + it.burned),
          },
          [t('fitness.calories.titles.carbs')]: {
            value: `${Math.round(it.carbs.eaten)}/${Math.round(it.carbs.total)} ${t(
              'measurements.gr',
            )}`,
          },
          [t('fitness.calories.titles.protein')]: {
            value: `${Math.round(it.protein.eaten)}/${Math.round(it.protein.total)} ${t(
              'measurements.gr',
            )}`,
          },
          [t('fitness.calories.titles.fat')]: {
            value: `${Math.round(it.fat.eaten)}/${Math.round(it.fat.total)} ${t(
              'measurements.gr',
            )}`,
          },
          [t('inDepth.timeOfCalc')]: { value: moment(it.updatedAt).format('h:mm A') },
          [t('inDepth.dateOfCalc')]: {
            value: moment(it.updatedAt).format('MMMM Do, YYYY'),
          },
        },
      };
    });
  }, [calorieRes]);

  const handleDateChange = (newDate: string) => {
    setSelectedDate(newDate);
  };

  return (
    <Box flex={1} display={'flex'} flexDirection={'column'}>
      <InDepthHeading
        module={'calories'}
        color={theme.palette.nutrition.main}
        handleDate={() => setCalendarModalOpen(true)}
      />
      <Box
        sx={{
          mt: 1,
          gap: 1,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <InfiniteScrollList
          skip={params.skip}
          maxHeight={'600px'}
          limit={params.limit}
          loading={isFetching}
          count={inDepthData.length}
          totalCount={calorieRes?.count}
          onLoadMore={(skip, limit) => {
            setParams({ ...params, skip, limit });
          }}
        >
          {inDepthData.map((it: any) => (
            <Box key={it._id}>
              <Collapsible
                data={it.data}
                title={it.title}
                titleSub={it.titleSub}
                progress={it?.progress}
              />
            </Box>
          ))}
        </InfiniteScrollList>
        {inDepthData.length === 0 && (
          <Box>
            <FeelsEmpty />
          </Box>
        )}
      </Box>
      <CalendarModal
        open={calendarModalOpen}
        saveDate={handleDateChange}
        selectedDate={selectedDate}
        setOpen={setCalendarModalOpen}
      />
    </Box>
  );
};

export default CalorieDataInner;
