import { useMemo } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const useGetUserData = () => {
  const { t } = useTranslation();
  const { data } = useAppSelector(state => state.auth);

  return useMemo(() => {
    if (!data || !data.user) {
      return { age: 0, smoker: false, gender: '', diabetic: false };
    }

    const userData = data.user;
    const authUser = data.user.authUser;
    if (!userData || !authUser || typeof authUser === 'string') {
      return { age: 0, smoker: false, gender: '', diabetic: false };
    }

    const birthDate = authUser.birthDate;

    return {
      smoker: userData.smoker,
      diabetic: !!userData.diabetes,
      age: moment().diff(birthDate, 'years'),
      gender: authUser.gender ? authUser.gender : t('userDetails.male'),
    };
  }, [data]);
};

export default useGetUserData;
