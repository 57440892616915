import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import {
  ComponentsLayout,
  CustomButton,
  CustomInput,
  MedicineStock,
  theme,
  ToggleCard,
} from '@hdcorner/ui-library';
import { Box, Typography } from '@mui/material';
import PageLayout from '../../components/PageLayout';
import {
  useCreateMedicineStockMutation,
  useEditMedicineStockMutation,
  useGetMedicationByIdQuery,
  useGetMedicineStockQuery,
} from './queries/medicationQueries';
import { useParams } from 'react-router';
import { instructionCategories } from './constants';
import useAlert from '../../hooks/useAlert';
import { useTranslation } from 'react-i18next';
import { useIonRouter } from '@ionic/react';

const MedicationInstructions = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { presentError, presentSuccess } = useAlert();
  const { medicationId } = useParams<{ medicationId: string }>();

  const [instructions, setInstructions] = useState<string>('');
  const [instructionsCategory, setInstructionsCategory] = useState<
    'before_meal' | 'after_meal' | 'with_meal' | 'no_instructions'
  >('no_instructions');

  const [editMedicineStock] = useEditMedicineStockMutation();
  const [createMedicineStock] = useCreateMedicineStockMutation();

  const { data: medicationRes, error: errorMedication } = useGetMedicationByIdQuery(
    { medicationId },
    {
      skip: !medicationId,
    },
  );

  const medication = useMemo(() => {
    if (!medicationRes || medicationRes.length === 0) return null;
    return medicationRes[0];
  }, [medicationRes]);

  const { data: medicineStockRes, error: errorMedStock } = useGetMedicineStockQuery(
    { medicineId: medication?.medicine._id || '' },
    {
      skip: !medication || !medication.medicine._id,
    },
  );

  useEffect(() => {
    if (errorMedication) presentError(t(''));
    if (errorMedStock) presentError(t(''));
  }, [errorMedStock]);

  const medicineStock = useMemo<MedicineStock | null>(() => {
    if (!medicineStockRes || medicineStockRes.length === 0) return null;
    return medicineStockRes[0];
  }, [medicineStockRes]);

  useEffect(() => {
    if (medicineStock) {
      setInstructionsCategory(medicineStock.instructionsCategory || 'no_instructions');
      setInstructions(medicineStock.instructions || '');
    }
  }, [medicineStock]);

  const updateInstructions = () => {
    if (medicineStock) {
      editMedicineStock({
        ...medicineStock,
        instructions,
        instructionsCategory,
      })
        .unwrap()
        .then(() => {
          presentSuccess('Medicine successfully edited');
          const medicationId = medication?._id;
          router.push(`/dashboard/medication/${medicationId}`, 'back', 'pop');
        })
        .catch(() => presentError('An unexpected error occurred'));
    } else {
      const medicineId = medication?.medicine._id;
      if (!medicineId) {
        presentError('No medicine!');
        return;
      }
      createMedicineStock({
        instructions,
        medicine: medicineId,
        instructionsCategory,
      })
        .unwrap()
        .then(() => {
          presentSuccess('Medicine successfully created');
          const medicationId = medication?._id;
          router.push(`/dashboard/medication/${medicationId}`, 'back', 'pop');
        })
        .catch(() => presentError('An unexpected error occurred'));
    }
  };

  const handleChangeCategory = (value: string) => {
    const castedValue = value as
      | 'before_meal'
      | 'after_meal'
      | 'with_meal'
      | 'no_instructions';
    setInstructionsCategory(castedValue);
  };

  return (
    <PageLayout
      defaultHref={'/dashboard/medication'}
      headerTitle={'Medication Instructions'}
      headerColor={theme.palette.primary.main}
    >
      <ComponentsLayout>
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <Box>
            {instructionCategories.map((category, index) => (
              <ToggleCard
                key={index}
                value={category.value}
                textTransform={'none'}
                label={t(category.label)}
                setSelected={handleChangeCategory}
                backgroundColor={theme.palette.secondary.light}
                selected={instructionsCategory === category.value}
              />
            ))}
          </Box>
          <Box>
            <Typography
              variant={'subtitle2'}
              color={theme.palette.primary.main}
              marginBottom={theme.spacing(1)}
              fontWeight={theme.typography.fontWeightRegular}
            >
              Custom Instructions
            </Typography>
            <CustomInput
              fullWidth
              value={instructions}
              alternativeBackground
              handleChange={setInstructions}
              placeholder={'Enter your custom instructions'}
            />
          </Box>
          <CustomButton
            fullWidth
            disableElevation
            color={'primary'}
            variant={'contained'}
            onClick={updateInstructions}
          >
            Update
          </CustomButton>
        </Box>
      </ComponentsLayout>
    </PageLayout>
  );
};

export default MedicationInstructions;
