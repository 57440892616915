import {
  BubbleIcon,
  Collapsible,
  CustomTabs,
  FeelsEmpty,
  TipsTricks,
} from '@hdcorner/ui-library';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  BoxProps,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useGetTipsAndTricksQuery } from '../queries/tipsTricksQueries';
import { getTipTitle } from '../utilities/trafficLights';
import useAlert from '../../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

const ConsumptionBox = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  scrollbarWidth: 'none',
  flexDirection: 'column',
  msOverflowStyle: 'none',
  gap: theme.spacing(1),
}));

const Circle = styled(Box, {
  shouldForwardProp: prop => prop !== 'usage',
})<BoxProps & { usage: 'frequent' | 'moderate' | 'avoid' }>(({ theme, usage }) => ({
  width: '16px',
  height: '16px',
  borderRadius: '50%',
  backgroundColor:
    usage === 'frequent'
      ? theme.palette.success.main
      : usage === 'moderate'
      ? theme.palette.warning.main
      : theme.palette.error.main,
}));

type TabType = {
  label: string;
  children: React.ReactNode;
  icon?: string | React.ReactElement;
  iconPosition?: 'top' | 'bottom' | 'start' | 'end';
};

const TrafficLightTabsList = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { presentError } = useAlert();
  const web = useMediaQuery(theme.breakpoints.up('md'));
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const [selectedTab, setSelectedTab] = useState<number>(0);

  const { data: tipsTricksRes, error } = useGetTipsAndTricksQuery({
    skip: 0,
    lang: lang,
    limit: 1000,
  });

  useEffect(() => {
    if (error) {
      presentError(t('errors.fitness.nutrition.errorTipTrickFetch'));
    }
  }, [error]);

  const tabs: TabType[] = useMemo(() => {
    return [
      {
        children: <></>,
        iconPosition: 'start',
        label: t('fitness.nutrition.labels.frequent'),
        icon: <BubbleIcon fill={theme.palette.success.main} />,
      },
      {
        children: <></>,
        iconPosition: 'start',
        label: t('fitness.nutrition.labels.moderate'),
        icon: <BubbleIcon fill={theme.palette.warning.main} />,
      },
      {
        children: <></>,
        iconPosition: 'start',
        label: t('fitness.nutrition.labels.avoid'),
        icon: <BubbleIcon fill={theme.palette.error.main} />,
      },
    ];
  }, []);

  /** RETURN: results for a single level */
  const filterLevels = useCallback(
    (level: 'frequent' | 'moderate' | 'avoid') => {
      if (!tipsTricksRes) return [];
      const { trafficLights } = tipsTricksRes;
      const foundItem = trafficLights.filter(it => it.level === level);
      if (foundItem) {
        return foundItem;
      } else {
        return [];
      }
    },
    [tipsTricksRes],
  );

  /** RETURN: single level - mobile */
  const levelData = useMemo(() => {
    if (!filterLevels) return [];

    const levelTranslated = tabs[selectedTab].label;
    let level: 'frequent' | 'moderate' | 'avoid';
    if (levelTranslated === t('fitness.nutrition.labels.frequent')) {
      level = 'frequent';
    } else if (levelTranslated === t('fitness.nutrition.labels.moderate')) {
      level = 'moderate';
    } else {
      level = 'avoid';
    }

    return filterLevels(level);
  }, [filterLevels, selectedTab, tabs]);

  /** RETURN: all levels - web */
  const allLevels = useMemo<
    {
      id: string;
      tips: TipsTricks[];
      value: 'frequent' | 'moderate' | 'avoid';
      level: 'frequent' | 'moderate' | 'avoid';
    }[]
  >(() => {
    return [
      {
        id: '1',
        value: 'frequent',
        tips: filterLevels('frequent'),
        level: t('fitness.nutrition.labels.frequent'),
      },
      {
        id: '2',
        value: 'moderate',
        tips: filterLevels('moderate'),
        level: t('fitness.nutrition.labels.moderate'),
      },
      {
        id: '3',
        value: 'avoid',
        tips: filterLevels('avoid'),
        level: t('fitness.nutrition.labels.avoid'),
      },
    ];
  }, [filterLevels]);

  return (
    <Box flex={1} width={'100%'} display={'flex'} flexDirection={'column'}>
      {!web && <CustomTabs tabs={tabs} value={selectedTab} setValue={setSelectedTab} />}
      {!web &&
        levelData.map(it => (
          <Box key={it._id} mt={theme.spacing(1)}>
            <Collapsible
              textOnly
              text={it.description}
              title={t(getTipTitle(it.title))}
            />
          </Box>
        ))}

      {!web && levelData.length === 0 && <FeelsEmpty />}

      <Box mt={3} gap={3} flex={1} display={'flex'}>
        {web &&
          allLevels.map(value => (
            <ConsumptionBox key={value.id}>
              <Box gap={1} display={'flex'} alignItems={'center'}>
                <Circle usage={value.value} />
                <Typography
                  variant={'subtitle1'}
                  sx={{ fontWeight: theme.typography.fontWeightMedium }}
                  textTransform={lang === 'el' ? 'capitalize' : 'uppercase'}
                >
                  {`${value.level} ${t('fitness.nutrition.labels.consumption')}`}
                </Typography>
              </Box>
              {value.tips.map(tip => {
                return (
                  <Box key={tip._id}>
                    <Collapsible
                      textOnly
                      text={tip.description}
                      title={t(getTipTitle(tip.title))}
                    />
                  </Box>
                );
              })}
              {value.tips.length === 0 && (
                <Box>
                  <FeelsEmpty />
                </Box>
              )}
            </ConsumptionBox>
          ))}
      </Box>
    </Box>
  );
};

export default TrafficLightTabsList;
