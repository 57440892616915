import React, { FC } from 'react';
import { CustomButton, FoodItems, theme } from '@hdcorner/ui-library';
import { Box, BoxProps, styled, Typography, useMediaQuery } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  deleteFoodArray,
  deleteMealLogDate,
  removeFoodItem,
} from '../slices/mealFoodsSlice';
import {
  usePatchMealsLogsMutation,
  usePostMealsLogsMutation,
} from '../queries/foodsQueries';
import moment from 'moment/moment';
import { useIonRouter } from '@ionic/react';
import useAlert from '../../../../hooks/useAlert';
import { usePostCaloriesInDepthMutation } from '../../calories/queries/caloriesQueries';
import { useTranslation } from 'react-i18next';

const BoxMain = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  borderRadius: '8px',
  flexDirection: 'column',
  gap: theme.spacing(3),
  marginBottom: theme.spacing(3),
  backgroundColor: theme.palette.highlight.main,
  padding: theme.spacing(1, 2),
}));

type Props = {
  customMeal?: boolean;
  handleCloseModal?: () => void;
};
const ModalAddFood: FC<Props> = ({ customMeal = false, handleCloseModal }) => {
  const router = useIonRouter();
  const dispatch = useAppDispatch();
  const { presentSuccess, presentError } = useAlert();
  const { t } = useTranslation();
  const web = useMediaQuery(theme.breakpoints.up('md'));
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const { foods, mealCategory, mealItems, mealId, logDate } = useAppSelector(
    state => state.mealFoodsList,
  );

  const [postMealLog] = usePostMealsLogsMutation();
  const [patchMealLog] = usePatchMealsLogsMutation();
  const [postCalories] = usePostCaloriesInDepthMutation();

  const handleRemoveItem = (_id?: string) => {
    if (!_id) return;
    dispatch(removeFoodItem(_id));
  };

  const handleAddItemsToMeal = () => {
    if (foods) {
      const foodsList = foods.map(food => ({
        food: food._id as string,
        quantity: food.quantity as number,
      }));
      postMealLog({
        foods: foodsList,
        category: mealCategory,
        logDate:
          logDate === '' || logDate === undefined
            ? moment().utc(true).toDate().toISOString()
            : logDate,
      })
        .unwrap()
        .then(() => {
          dispatch(deleteFoodArray());
          dispatch(deleteMealLogDate());
          presentSuccess(t('errors.fitness.nutrition.successFoodAddLog'));
        })
        .catch(() => presentError(t('errors.fitness.nutrition.errorFoodAddLog')))
        .finally(() => {
          handlePostCalories();
        });
    }
  };

  const handlePatchItemsToMeal = () => {
    const foodsList = foods.map(food => ({
      food: food._id as string,
      quantity: food.quantity as number,
    }));
    const existingFoods = mealItems.map(item => ({
      food: item.food as string,
      quantity: item.quantity,
    }));
    const patchArray = [...foodsList, ...existingFoods];
    if (foods) {
      patchMealLog({
        mealId: mealId,
        foods: patchArray,
      })
        .unwrap()
        .then(() => {
          dispatch(deleteFoodArray());
          dispatch(deleteMealLogDate());
          presentSuccess(t('errors.fitness.nutrition.successFoodAddLog'));
        })
        .catch(() => presentError(t('errors.fitness.nutrition.errorFoodAddLog')))
        .finally(() => {
          handlePostCalories();
        });
    }
  };

  const handlePostCalories = () => {
    postCalories()
      .unwrap()
      .catch(() => presentError(t('errors.fitness.nutrition.errorFoodAddLog')))
      .finally(() => {
        if (handleCloseModal) {
          handleCloseModal();
        }
        if (!web) {
          router.push('/dashboard/fitness', 'back', 'pop');
        }
      });
  };

  return (
    <>
      <BoxMain>
        {foods.map(food => (
          <FoodItems
            key={food._id}
            quantity={food.quantity}
            handleAddItem={() => handleRemoveItem(food._id)}
            name={
              !food.name
                ? ''
                : typeof food.name === 'string'
                ? food.name
                : food.name[lang]
            }
            calories={Math.round(((food.calories || 0) / 100) * (food.quantity || 0))}
          />
        ))}
        {foods.length === 0 && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant={'subtitle1'}>
              {t('fitness.nutrition.labels.noFoodAdd')}
            </Typography>
          </Box>
        )}
      </BoxMain>
      {!customMeal && (
        <CustomButton
          fullWidth
          color={'primary'}
          variant={'contained'}
          disabled={foods.length === 0}
          onClick={
            mealId === '' || mealId === undefined
              ? handleAddItemsToMeal
              : handlePatchItemsToMeal
          }
        >
          {t('buttons.save')}
        </CustomButton>
      )}
    </>
  );
};

export default ModalAddFood;
