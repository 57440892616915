import * as React from 'react';
import { SVGProps } from 'react';

const CheckSuccessBackgroundIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width={20} height={20} rx={10} fill="#5FD071" />
    <path
      d="M14.667 6.5 8.25 12.917 5.333 10"
      stroke="#F2F1F3"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckSuccessBackgroundIcon;
