import React, { FC } from 'react';
import { Box, useTheme } from '@mui/material';
import ModalHeading from './ModalHeading';
import CustomInput from '../controls/CustomInput';
import CheckBoxesSingle from '../controls/CheckBoxesSingle';
import { useTranslation } from 'react-i18next';

type ModalHypertensionFieldsProps = {
  afib: boolean;
  pulses: string;
  column: boolean;
  systolic: string;
  diastolic: string;
  handleCalendarClick?: () => void;
  setAfib: (afib: boolean) => void;
  setPulses: (pulses: string) => void;
  setSystolic: (systolic: string) => void;
  setDiastolic: (diastolic: string) => void;
  errorPulses: { value: boolean; message: string };
  errorSystolic: { value: boolean; message: string };
  errorDiastolic: { value: boolean; message: string };
};
const ModalHypertensionFields: FC<ModalHypertensionFieldsProps> = ({
  afib,
  column,
  pulses,
  systolic,
  diastolic,
  setAfib,
  setPulses,
  setSystolic,
  setDiastolic,
  errorPulses,
  errorSystolic,
  errorDiastolic,
  handleCalendarClick,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <>
      <Box display={column ? 'block' : 'none'}>
        <ModalHeading
          align={'left'}
          title={t('general.labels.dataInput')}
          showCalendar={column ? 'flex' : 'none'}
          color={column ? theme.palette.hypertension.main : undefined}
          handleCalendarClick={column ? handleCalendarClick : undefined}
          headingButton={
            column ? t('constants.toggleValues.dates.customDate') : undefined
          }
        />
      </Box>

      <Box
        gap={1}
        flex={1}
        display={'flex'}
        flexWrap={column ? 'nowrap' : 'wrap'}
        flexDirection={column ? 'column' : 'row'}
        margin={column ? theme.spacing(5.25, 0, 0, 0) : theme.spacing(6.5, 0, 3, 0)}
      >
        <Box flex={1} display={'flex'} minWidth={'125px'}>
          <CustomInput
            fullWidth
            type={'number'}
            value={systolic}
            placeholder={'80-250'}
            handleChange={setSystolic}
            error={errorSystolic.value}
            suffix={t('measurements.mmGH')}
            helperText={errorSystolic.message}
            label={t('hypertension.labels.systolic')}
            inputProps={{
              min: 80,
              max: 250,
            }}
          />
        </Box>

        <Box flex={1} display={'flex'} minWidth={'125px'}>
          <CustomInput
            fullWidth
            type={'number'}
            value={diastolic}
            placeholder={'50-150'}
            handleChange={setDiastolic}
            error={errorDiastolic.value}
            suffix={t('measurements.mmGH')}
            helperText={errorDiastolic.message}
            label={t('hypertension.labels.diastolic')}
            inputProps={{
              min: 50,
              max: 150,
            }}
          />
        </Box>
        <Box flex={1} display={'flex'} minWidth={'125px'}>
          <CustomInput
            fullWidth
            value={pulses}
            type={'number'}
            placeholder={'30-220'}
            handleChange={setPulses}
            error={errorPulses.value}
            suffix={t('measurements.bpm')}
            helperText={errorPulses.message}
            label={t('dashboard.dashcards.hypertension.pulses')}
            inputProps={{
              min: 30,
              max: 220,
            }}
          />
        </Box>
      </Box>
      <Box mt={1} mb={3} width={'65px'}>
        <CheckBoxesSingle
          checked={afib}
          setChecked={setAfib}
          label={t('measurements.afib')}
        />
      </Box>
    </>
  );
};

export default ModalHypertensionFields;
