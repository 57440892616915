import HorizontalCalendar, {
  HorizontalCalendarErrorIndicator,
  HorizontalCalendarSuccessIndicator,
} from '../controls/HorizontalCalendar';
import ModalMobile from './ModalMobile';
import React, { FC, ReactNode, useMemo } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import ActiveIcon from '../../assets/ActiveIcon';
import { useTranslation } from 'react-i18next';

type CalendarGoalsModalType = {
  open: boolean;
  altIcon?: ReactNode;
  setOpen: (bool: boolean) => void;
  onMonthChange?: (date: string) => void;
  goalDates?: { date: Date; success: boolean }[];
};
const CalendarGoalsModal: FC<CalendarGoalsModalType> = ({
  open,
  setOpen,
  altIcon,
  goalDates,
  onMonthChange,
}) => {
  const theme = useTheme();

  const daysAchieved = useMemo(() => {
    if (!goalDates) return 0;
    return goalDates.filter(date => date.success).length;
  }, [goalDates]);

  const daysMissed = useMemo(() => {
    if (!goalDates) return 0;
    return goalDates.filter(date => !date.success).length;
  }, [goalDates]);

  const daysActive = useMemo(() => {
    if (!goalDates) return 0;
    return goalDates.length;
  }, [goalDates]);

  return (
    <ModalMobile open={open} setOpen={setOpen} transparentBackground>
      <Box display={'flex'} flexDirection={'column'} gap={2} p={2}>
        <HorizontalCalendar
          openMonthly={true}
          goalDates={goalDates}
          onMonthChange={onMonthChange}
          sx={{ background: theme.palette.secondary.light }}
        />
        <CalendarGoalsLegend
          altIcon={altIcon}
          daysMissed={daysMissed}
          daysActive={daysActive}
          daysAchieved={daysAchieved}
        />
      </Box>
    </ModalMobile>
  );
};

type CalendarGoalsLegendType = {
  altIcon?: ReactNode;
  daysMissed: number;
  daysActive: number;
  daysAchieved: number;
};
const CalendarGoalsLegend: FC<CalendarGoalsLegendType> = ({
  altIcon,
  daysMissed,
  daysActive,
  daysAchieved,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      gap={2}
      paddingX={2}
      paddingY={1}
      display={'flex'}
      borderRadius={1}
      alignItems={'stretch'}
      flexDirection={'column'}
      sx={{ background: theme.palette.secondary.light }}
    >
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Box display={'flex'} alignItems={'center'}>
          <Box
            mr={1}
            width={16}
            height={16}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <HorizontalCalendarSuccessIndicator />
          </Box>
          <Typography
            variant={'body1'}
            color={theme.palette.primary.main}
            fontWeight={theme.typography.fontWeightMedium}
          >
            {t('goals.daysAchieved')}
          </Typography>
        </Box>
        <Typography
          variant={'body1'}
          color={theme.palette.primary.main}
          fontWeight={theme.typography.fontWeightLight}
        >
          {daysAchieved}{' '}
          {daysAchieved === 1
            ? t('constants.goals.achievedIn.day')
            : t('constants.goals.achievedIn.days')}
        </Typography>
      </Box>

      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Box display={'flex'} alignItems={'center'}>
          <Box
            mr={1}
            width={16}
            height={16}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <HorizontalCalendarErrorIndicator />
          </Box>
          <Typography
            variant={'body1'}
            color={theme.palette.primary.main}
            fontWeight={theme.typography.fontWeightMedium}
          >
            {t('goals.daysMissed')}
          </Typography>
        </Box>
        <Typography
          variant={'body1'}
          color={theme.palette.primary.main}
          fontWeight={theme.typography.fontWeightLight}
        >
          {daysMissed}{' '}
          {daysMissed === 1
            ? t('constants.goals.achievedIn.day')
            : t('constants.goals.achievedIn.days')}
        </Typography>
      </Box>

      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Box display={'flex'} alignItems={'center'}>
          <Box
            mr={1}
            width={16}
            height={16}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            {altIcon ? altIcon : <ActiveIcon />}
          </Box>
          <Typography
            variant={'body1'}
            color={theme.palette.primary.main}
            fontWeight={theme.typography.fontWeightMedium}
          >
            {t('goals.daysActive')}
          </Typography>
        </Box>
        <Typography
          variant={'body1'}
          color={theme.palette.primary.main}
          fontWeight={theme.typography.fontWeightLight}
        >
          {daysActive}{' '}
          {daysActive === 1
            ? t('constants.goals.achievedIn.day')
            : t('constants.goals.achievedIn.days')}
        </Typography>
      </Box>
    </Box>
  );
};

export default CalendarGoalsModal;
