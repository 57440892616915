import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

const Dot = styled(Box)<BoxProps>(() => ({
  width: 8,
  height: 8,
  borderRadius: '99px',
}));

const RiskText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.light,
  fontSize: theme.typography.body1.fontSize,
  textTransform: lang === 'el' ? 'capitalize' : 'uppercase',
}));

const CvdRiskRanges = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box display={'flex'} flexDirection={'column'} gap={1}>
      <Box gap={1} display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
        <Dot sx={{ background: theme.palette.success.main }} />
        <RiskText>{`${t('cvd.labels.low')}: < 1%`}</RiskText>
      </Box>
      <Box gap={1} display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
        <Dot sx={{ background: theme.palette.warning.main }} />

        <RiskText>{`${t('cvd.labels.med')}: < 5%`}</RiskText>
      </Box>
      <Box gap={1} display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
        <Dot sx={{ background: theme.palette.hypertension.main }} />
        <RiskText>{`${t('cvd.labels.high')}: ≥ 5 % - < 10%`}</RiskText>
      </Box>
      <Box gap={1} display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
        <Dot sx={{ background: theme.palette.error.main }} />
        <RiskText>{`${t('cvd.labels.vHigh')}: ≥ 10%`}</RiskText>
      </Box>
    </Box>
  );
};

export default CvdRiskRanges;
