import React, { FC } from 'react';
import { alpha, Box, BoxProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import CustomButton from '../controls/CustomButton';
import { ButtonProps } from '@mui/material/Button';
import { TypographyProps } from '@mui/material/Typography/Typography';
import { useTranslation } from 'react-i18next';

const Container = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  borderRadius: 8,
  boxSizing: 'border-box',
  flexDirection: 'column',
  padding: theme.spacing(1),
  background: theme.palette.secondary.light,
  [theme.breakpoints.up('md')]: {
    width: 400,
    padding: theme.spacing(3),
  },
}));

const Text = styled(Typography)<TypographyProps>(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.primary.main,
}));

const TextName = styled(Typography)<TypographyProps>(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightMedium,
}));

const CancelButton = styled(CustomButton)<ButtonProps>(({ theme }) => ({
  flex: 1,
  display: 'flex',
  boxShadow: theme.shadows[0],
  ...theme.typography.subtitle2,
  fontWeight: theme.typography.fontWeightRegular,
  padding: theme.spacing(1.5, 0),
  color: alpha(theme.palette.primary.main, 0.24),
  background: alpha(theme.palette.secondary.dark, 0.24),
  height: 48,
  ':hover': {
    background: theme.palette.secondary.dark,
  },
}));

const RemoveButton = styled(CustomButton)<ButtonProps>(({ theme }) => ({
  ...theme.typography.subtitle2,
  flex: 1,
  height: 48,
  display: 'flex',
  boxShadow: theme.shadows[0],
  color: theme.palette.secondary.main,
  background: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightRegular,
  padding: theme.spacing(1.5, 0),
}));

type RemoveHPCodeModalType = {
  handleRemove: () => void;
  handleCancel: () => void;
  healthProfessionalName: string;
};
const RemoveHPCodeModal: FC<RemoveHPCodeModalType> = ({
  handleCancel,
  handleRemove,
  healthProfessionalName,
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Typography
        variant={'subtitle2'}
        dangerouslySetInnerHTML={{
          __html: t('healthProf.hcp.text.removeHPCheck', {
            name: healthProfessionalName,
          }),
        }}
      />

      <Box display={'flex'} flex={1} gap={3} marginTop={3}>
        <CancelButton variant={'contained'} onClick={handleCancel}>
          {t('buttons.cancel')}
        </CancelButton>
        <RemoveButton variant={'contained'} onClick={handleRemove}>
          {t('buttons.remove')}
        </RemoveButton>
      </Box>
    </Container>
  );
};

export default RemoveHPCodeModal;
