import * as React from 'react';
import { SVGProps } from 'react';

const CvdRiskIcon = ({ fill = '#222222', ...rest }: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={16}
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M10.8618 10.2132V6.20688C10.8618 5.97076 10.7709 5.7443 10.6089 5.57734C10.447 5.41038 10.2274 5.31658 9.99837 5.31658C9.76936 5.31658 9.54974 5.41038 9.3878 5.57734C9.22587 5.7443 9.1349 5.97076 9.1349 6.20688V10.2132C9.1349 10.4493 9.22587 10.6758 9.3878 10.8428C9.54974 11.0097 9.76936 11.1035 9.99837 11.1035C10.2274 11.1035 10.447 11.0097 10.6089 10.8428C10.7709 10.6758 10.8618 10.4493 10.8618 10.2132Z"
      fill={fill}
    />
    <path
      d="M9.99837 11.9938C10.1691 11.9938 10.3361 12.046 10.4781 12.1439C10.6201 12.2417 10.7308 12.3807 10.7961 12.5434C10.8615 12.7061 10.8786 12.8851 10.8452 13.0578C10.8119 13.2305 10.7297 13.3891 10.6089 13.5137C10.4882 13.6382 10.3343 13.723 10.1668 13.7573C9.99933 13.7917 9.82571 13.774 9.66793 13.7066C9.51015 13.6393 9.3753 13.5252 9.28042 13.3787C9.18554 13.2323 9.1349 13.0602 9.1349 12.8841C9.1349 12.648 9.22587 12.4215 9.3878 12.2546C9.54974 12.0876 9.76936 11.9938 9.99837 11.9938Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.500305 15.0864C0.496206 15.2453 0.533446 15.4024 0.608157 15.5415C0.682927 15.6805 0.79243 15.7963 0.925291 15.877C1.05815 15.9576 1.20953 16.0001 1.36369 16H18.6331C18.7875 16.0006 18.9393 15.9586 19.0726 15.8782C19.2058 15.7977 19.3158 15.682 19.3909 15.5428C19.4661 15.4037 19.5036 15.2464 19.4997 15.0872C19.4958 14.928 19.4506 14.7729 19.3687 14.6378L10.7341 0.393056C10.6513 0.271635 10.5414 0.172536 10.4136 0.104125C10.2859 0.0357135 10.1441 0 10.0001 0C9.85616 0 9.71433 0.0357135 9.58659 0.104125C9.45884 0.172536 9.34894 0.271635 9.26616 0.393056L0.631471 14.6378C0.549695 14.7726 0.504405 14.9275 0.500305 15.0864ZM9.99838 2.54491L17.0788 14.2194H2.91794L9.99838 2.54491Z"
      fill={fill}
    />
  </svg>
);

export default CvdRiskIcon;
