import { Box, BoxProps, IconButton, styled, useMediaQuery } from '@mui/material';
import SectionHeading from '../../components/SectionHeading';
import {
  CalendarGoalsModal,
  CalendarIcon,
  capitalizeFirstLetter,
  findFoodById,
  Food,
  HorizontalCalendar,
  KnifeForkIcon,
  MealCollapseCard,
  MealList,
  theme,
  usePrepareMealList,
} from '@hdcorner/ui-library';
import {
  useGetMealsOfDayQuery,
  usePatchMealsLogsMutation,
} from '../queries/foodsQueries';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useIonRouter } from '@ionic/react';
import {
  cleanSlate,
  createAPIFoodArray,
  deleteFoodArray,
  updateCategory,
  updateMealId,
  updateMealLogDate,
} from '../slices/mealFoodsSlice';
import ModalFoodMealsWeb from './ModalFoodMealsWeb';
import useAlert from '../../../../hooks/useAlert';
import {
  useGetCaloriesInDepthQuery,
  usePostCaloriesInDepthMutation,
} from '../../calories/queries/caloriesQueries';
import { useGetDietPlanQuery } from '../../diet-plan/queries/dietPlanQueries';
import { useTranslation } from 'react-i18next';

const BoxMain = styled(Box, { shouldForwardProp: propName => propName !== 'web' })<
  BoxProps & { web: boolean }
>(({ web, theme }) => ({
  display: 'flex',
  overflow: 'scroll',
  scrollbarWidth: 'none',
  flexDirection: 'column',
  '::-webkit-scrollbar': {
    display: 'none',
  },
  msOverflowStyle: 'none',
  borderBottomLeftRadius: '8px',
  borderBottomRightRadius: '8px',
  maxHeight: web ? '535px' : '80%',
  marginBottom: web ? 0 : theme.spacing(3),
  backgroundColor: theme.palette.secondary.light,
}));

const BoxSecondary = styled(Box)<BoxProps>(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  gap: theme.spacing(1),
  borderTop: `1px solid ${theme.palette.secondary.dark}`,
  padding: theme.spacing(1, 1, 2, 1),
}));

const SectionNutritionCal = () => {
  const dispatch = useAppDispatch();
  const router = useIonRouter();
  const { presentSuccess, presentError } = useAlert();
  const { t } = useTranslation();
  const web = useMediaQuery(theme.breakpoints.up('md'));

  const { mealCategory } = useAppSelector(state => state.mealFoodsList);

  const [open, setOpen] = useState<boolean>(false);
  const [monthCalendarGoals, setMonthCalendarGoals] = useState<string>();
  const [openCalendarGoals, setOpenCalendarGoals] = useState<boolean>(false);
  const [monthlyCalendarOpen, setMonthlyCalendarOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date>(moment().utc(true).toDate());
  const [params, setParams] = useState({
    end: moment().utc(true).endOf('day').toISOString(),
    start: moment().utc(true).startOf('day').toISOString(),
  });

  const [patchMealLog] = usePatchMealsLogsMutation();
  const [postCalories] = usePostCaloriesInDepthMutation();

  const { data: dietPlanRes, error: errorDietPlan } = useGetDietPlanQuery();
  const { data: mealLogRes, error: errorMeals } = useGetMealsOfDayQuery({
    end: params.end,
    start: params.start,
  });
  const { data: getCaloriesInDepth, error: errorCalories } = useGetCaloriesInDepthQuery(
    {
      params: { skip: 0, limit: 40 },
      end: moment(monthCalendarGoals).endOf('month').toISOString(),
      start: moment(monthCalendarGoals).startOf('month').toISOString(),
    },
    { skip: !monthCalendarGoals },
  );

  useEffect(() => {
    if (errorDietPlan || errorCalories) {
      presentError(t('errors.fitness.calories.error01'));
    }
    if (errorMeals) {
      presentError(t('errors.fitness.nutrition.errorMealFetch'));
    }
  }, [errorCalories, errorDietPlan, errorMeals]);

  const getDietDetails = useMemo(() => {
    if (dietPlanRes && dietPlanRes.length > 0) {
      const dietPlan = dietPlanRes[0];
      return {
        dietType: dietPlan.dietType,
        calories: dietPlan.calculatedCalories,
      };
    }
    return {
      dietType: '',
      calories: 0,
    };
  }, [dietPlanRes]);

  const lunchList = usePrepareMealList(
    'lunch',
    mealLogRes,
    getDietDetails.dietType,
    getDietDetails.calories,
  );

  const dinnerList = usePrepareMealList(
    'dinner',
    mealLogRes,
    getDietDetails.dietType,
    getDietDetails.calories,
  );

  const snacksList = usePrepareMealList(
    'snacks',
    mealLogRes,
    getDietDetails.dietType,
    getDietDetails.calories,
  );

  const breakfastList = usePrepareMealList(
    'breakfast',
    mealLogRes,
    getDietDetails.dietType,
    getDietDetails.calories,
  );

  const meals = useMemo(() => {
    return [breakfastList, lunchList, dinnerList, snacksList];
  }, [breakfastList, dinnerList, lunchList, snacksList]);

  const handleChangeDate = (date: Date) => {
    setSelectedDate(date);
    dispatch(updateMealLogDate(moment(date).utc(true).toISOString()));

    const newDate = moment(date).utc(true);
    const endDate = newDate.endOf('day').toISOString();
    const startDate = newDate.startOf('day').toISOString();
    const data = { endDate, startDate };
    handleCalClick(data);
  };

  const handleCalClick = (data: any) => {
    const endDate = data.endDate;
    const startDate = data.startDate;
    setParams((prevState: any) => ({
      ...prevState,
      end: endDate,
      start: startDate,
    }));
  };

  const patchArray = (meals: MealList[], foodId: string) => {
    if (meals) {
      const mealList = findFoodById(meals, foodId);
      if (mealList && mealList.mealItems && Array.isArray(mealList.mealItems)) {
        const newMealListItems = [...mealList.mealItems];
        return newMealListItems.filter(item => (item.food as Food)._id !== foodId);
      }
    }
    return [];
  };

  const handlePostCalories = () => {
    postCalories()
      .unwrap()
      .catch(() => presentError(t('errors.fitness.nutrition.errorMealFetch')));
  };

  const handleRemoveFoodItem = (mealId: string, foodId: string) => {
    patchMealLog({
      mealId,
      foods: patchArray(meals, foodId),
    })
      .unwrap()
      .then(() => presentSuccess(t('errors.fitness.nutrition.successFoodDeleteLog')))
      .catch(() => presentError(t('errors.fitness.nutrition.errorFoodDeleteLog')))
      .finally(() => {
        handlePostCalories();
      });
  };

  const handleCheckData = (meal: MealList) => {
    if (!meal.mealId) {
      handleCreateNewMeal(meal.category);
    } else {
      handleUpdateExistingMeal(meal);
    }
  };

  const handleCreateNewMeal = (category: string) => {
    dispatch(deleteFoodArray());
    dispatch(updateCategory(category));
    if (web) {
      setOpen(true);
    } else {
      router.push(`/dashboard/fitness/food-meals/${category}`);
    }
  };

  const handleUpdateExistingMeal = (meal: MealList) => {
    dispatch(updateMealId(meal.mealId));
    dispatch(createAPIFoodArray(meal.mealItems));
    dispatch(updateMealLogDate(meal.logDate));
    dispatch(updateCategory(meal.category));
    if (web) {
      setOpen(true);
    } else {
      router.push(`/dashboard/fitness/food-meals/${meal.title}`);
    }
  };

  const handleFoodsModal = (value: boolean) => {
    setOpen(value);
    if (!value) dispatch(cleanSlate());
  };

  const prepareCompletedDays = useMemo(() => {
    if (!getCaloriesInDepth) return [];
    return getCaloriesInDepth.documents?.map(cal => ({
      date: new Date(cal.createdAt),
      success: Math.abs((cal.calculated ?? 0) - (cal.eaten ?? 0)) <= 50,
    }));
  }, [getCaloriesInDepth]);

  return (
    <Box gap={1} display={'flex'} flexDirection={'column'}>
      <Box display={web ? 'flex' : 'none'}>
        <SectionHeading hasGoal={false} heading={t('fitness.nutrition.labels.nutCal')} />
        <IconButton onClick={() => setOpenCalendarGoals(true)} sx={{ padding: 0 }}>
          <CalendarIcon width={'20px'} height={'20px'} />
        </IconButton>
      </Box>
      <BoxMain web={web}>
        <HorizontalCalendar
          sx={{ flex: 1 }}
          selectedDate={selectedDate}
          openMonthly={monthlyCalendarOpen}
          setOpenMonthly={setMonthlyCalendarOpen}
          handleChange={(date: any) => handleChangeDate(date)}
        />
        <BoxSecondary>
          {meals.map((meal, index) => (
            <MealCollapseCard
              metrics={meal.metrics}
              mealItems={meal.mealItems}
              totalCal={meal.totalCalories}
              key={`${meal.title}-${index}`}
              description={meal.description || ''}
              addMore={() => handleCheckData(meal)}
              title={capitalizeFirstLetter(t(meal.title))}
              handleRemoveItem={foodId => handleRemoveFoodItem(meal.mealId, foodId)}
            />
          ))}
        </BoxSecondary>
      </BoxMain>
      <ModalFoodMealsWeb open={open} category={mealCategory} setOpen={handleFoodsModal} />
      <CalendarGoalsModal
        open={openCalendarGoals}
        altIcon={<KnifeForkIcon />}
        setOpen={setOpenCalendarGoals}
        goalDates={prepareCompletedDays}
        onMonthChange={setMonthCalendarGoals}
      />
    </Box>
  );
};

export default SectionNutritionCal;
