import api from '../../../../redux/api';
import { WorkoutSetting } from '../types/WorkoutSetting';

const workoutSettingsQueries = api.injectEndpoints({
  endpoints: build => ({
    getWorkoutSettings: build.query<WorkoutSetting[], void>({
      query: () => ({
        url: '/database/function/getWorkoutSettings',
        method: 'GET',
      }),
      providesTags: ['WorkoutSetting'],
    }),

    createWorkoutSettings: build.mutation<WorkoutSetting, { avatar: string }>({
      query: ({ avatar }) => ({
        url: '/database/function/createWorkoutSettings',
        method: 'POST',
        body: { avatar },
      }),
      invalidatesTags: ['WorkoutSetting'],
    }),

    updateWorkoutSettings: build.mutation<void, WorkoutSetting>({
      query: ({ _id, avatar }) => ({
        url: `/database/function/updateWorkoutSettings/${_id}`,
        method: 'PATCH',
        body: { avatar },
      }),
      invalidatesTags: ['WorkoutSetting'],
    }),
  }),
});

export const {
  useGetWorkoutSettingsQuery,
  useCreateWorkoutSettingsMutation,
  useUpdateWorkoutSettingsMutation,
} = workoutSettingsQueries;

export default workoutSettingsQueries;
