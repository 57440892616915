import React, { FC, ReactNode } from 'react';
import { Typography } from '@mui/material';
import { theme } from '@hdcorner/ui-library';
import { IonFabButton } from '@ionic/react';

type IonicFabButtonProps = {
  title: string;
  color?: string;
  icon: ReactNode;
  onClick: () => void;
};
const IonicFabButton: FC<IonicFabButtonProps> = ({ icon, color, title, onClick }) => {
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  return (
    <IonFabButton
      style={{
        height: '40px',
        '--border-radius': '50px',
        '--background': '#FFFFFF',
        width: lang === 'en' ? '157px' : '245px',
      }}
      onClick={onClick}
    >
      <Typography
        variant={'subtitle2'}
        sx={{
          marginRight: theme.spacing(1.25),
          fontWeight: theme.typography.fontWeightRegular,
          color: color ? color : theme.palette.primary.main,
        }}
      >
        {title}
      </Typography>
      {icon}
    </IonFabButton>
  );
};

export default IonicFabButton;
