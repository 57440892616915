import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import { Box, BoxProps, Typography, TypographyProps, useTheme } from '@mui/material';
import CalendarIcon from '../../assets/CalendarIcon';

const InnerBox = styled(Box, {
  shouldForwardProp: prop => prop !== 'align',
})<BoxProps & { align: 'left' | 'center' }>(props => ({
  justifyContent: props.align === 'left' ? 'space-between' : 'center',
}));

const HeadingTypography = styled(Typography, {
  shouldForwardProp: prop => prop !== 'align',
})<TypographyProps & { align: 'left' | 'center' }>(({ align, theme }) => ({
  textTransform: 'capitalize',
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.kmColorsCoolGrey.main
      : theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightMedium,
  textAlign: align === 'left' ? 'left' : 'center',
  fontSize:
    align === 'left' ? theme.typography.subtitle1.fontSize : theme.typography.h1.fontSize,
}));

type ModalHeadingProps = {
  title: string;
  color?: string;
  subtitle?: string;
  showCalendar: string;
  headingButton?: string;
  align: 'left' | 'center';
  alternateIcon?: React.ReactNode;
  handleCalendarClick?: () => void;
};
const ModalHeading: FC<ModalHeadingProps & BoxProps> = ({
  color,
  align,
  title,
  subtitle,
  showCalendar,
  alternateIcon,
  headingButton,
  handleCalendarClick,
  ...rest
}) => {
  const theme = useTheme();
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  return (
    <Box flexGrow={1} marginBottom={theme.spacing(3)} {...rest}>
      <InnerBox display={'flex'} align={align} alignItems={'center'}>
        <Box>
          <HeadingTypography align={align}>{title}</HeadingTypography>
          <Typography
            variant={'caption'}
            align={align}
            sx={{
              color: theme.palette.warning.main,
              marginTop: theme.spacing(1),
              fontWeight: theme.typography.fontWeightMedium,
            }}
          >
            {subtitle}
          </Typography>
        </Box>
        <Box
          alignItems={'center'}
          display={showCalendar}
          justifyContent={'center'}
          sx={{ cursor: 'pointer' }}
          onClick={handleCalendarClick}
        >
          {alternateIcon ? alternateIcon : <CalendarIcon color={color} />}
          <Typography
            variant={'caption'}
            sx={{
              color: { color },
              marginLeft: theme.spacing(0.5),
              fontWeight: theme.typography.fontWeightBold,
              textTransform: lang === 'el' ? 'capitalize' : 'uppercase',
            }}
          >
            {headingButton}
          </Typography>
        </Box>
      </InnerBox>
    </Box>
  );
};

export default ModalHeading;
