import React, { FC, useEffect, useState } from 'react';
import { AuthUser, CustomButton, CustomInput } from '@hdcorner/ui-library';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetAuthUserQuery } from '../../queries/signInQueries';

type UserDetailsOneProps = {
  loading: boolean;
  handleNextPage: (data: Pick<AuthUser, 'firstName' | 'lastName'>) => void;
};
const UserDetailsOne: FC<UserDetailsOneProps> = ({ loading, handleNextPage }) => {
  const { t } = useTranslation();

  const [lastName, setLastName] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');

  const { data: authUserResponse } = useGetAuthUserQuery();

  useEffect(() => {
    if (authUserResponse && authUserResponse.length > 0) {
      const { firstName, lastName, apple } = authUserResponse[0];
      if (apple && !firstName && !lastName) {
        const { data } = apple;
        const nameObj = data?.name;
        const name = nameObj || { firstName: '', lastName: '' };
        const { firstName: appleFirstName, lastName: appleLastName } = name;
        setLastName(appleLastName || '');
        setFirstName(appleFirstName || '');
      } else {
        setLastName(lastName);
        setFirstName(firstName);
      }
    }
  }, [authUserResponse]);

  const handleSaveData = () => {
    const data = { firstName, lastName };
    handleNextPage(data);
  };

  return (
    <Box gap={6} display={'flex'} flexDirection={'column'}>
      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <CustomInput
          fullWidth
          value={firstName}
          handleChange={setFirstName}
          label={`${t('userDetails.fName')}`}
        />

        <CustomInput
          fullWidth
          value={lastName}
          handleChange={setLastName}
          label={`${t('userDetails.lName')}`}
        />
      </Box>

      <CustomButton
        fullWidth
        variant={'contained'}
        onClick={handleSaveData}
        disabled={!firstName || !lastName || loading}
      >
        {t('buttons.continue')}
      </CustomButton>
    </Box>
  );
};

export default UserDetailsOne;
