export enum ExerciseWorkoutsType {
  MAIN = 'main',
  WARMUP = 'warm_up',
  RECOVERY = 'recovery',
}

export const exerciseWorkoutsLabel = (exerciseWorkout: ExerciseWorkoutsType) => {
  switch (exerciseWorkout) {
    case ExerciseWorkoutsType.WARMUP:
      return 'constants.exercise.warmUp';
    case ExerciseWorkoutsType.MAIN:
      return 'constants.exercise.main';
    case ExerciseWorkoutsType.RECOVERY:
      return 'constants.exercise.recovery';
  }
};
