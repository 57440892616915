import {
  Badge,
  BadgeProps,
  Box,
  styled,
  Typography,
  TypographyProps,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import {
  ComponentsLayout,
  DashboardCardSmall,
  ModalMobile,
  theme,
  TooltipOverlay,
} from '@hdcorner/ui-library';
import moment from 'moment/moment';
import { useIonRouter } from '@ionic/react';
import PageLayout from '../../components/PageLayout';
import RemindersDashboard from '../reminders/components/RemindersDashboard';
import { useGetRemindersQuery } from '../reminders/queries/remindersQueries';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import DashDataCards from './components/DashDataCards';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useSetTutorialCompletedMutation } from '../authentication/queries/onBoardingQueries';
import { useGetNotificationsQuery } from '../notifications/queries/notificationQueries';
import GoalsSection from './components/GoalsSection';
import UserMood from './components/UserMood';
import { setPromotion } from '../../redux/slices/appSlice';
import AppleHealth from '../appleHealth/AppleHealth';
import FcmIntegration from '../pushNotifications/PushNotifications';
import { useGetChatRoomsQuery } from '../chat/queries/chatQueries';
import ModalPopUp from '../../components/ModalPopUp';
import {
  getDidYouKnow,
  getOtherMessages,
  storeDidYouKnow,
  storeOtherMessages,
} from './utils/popupStorage';
import { useLazyGetPopupQuery } from './queries/popupQueries';
import { useTranslation } from 'react-i18next';
import { useGetUserSettingsQuery } from '../profile/queries/userSettingsQueries';

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(2),
  fontWeight: theme.typography.fontWeightMedium,
}));

const BadgeMain = styled(Badge)<BadgeProps>(({ theme }) => ({
  '.MuiBadge-badge': {
    border: '1px solid white',
    margin: theme.spacing(1, 0.7, 0, 0),
  },
}));

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const router = useIonRouter();
  const { t } = useTranslation();
  const web = useMediaQuery(theme.breakpoints.up('md'));
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const {
    data: { user },
  } = useAppSelector(state => state.auth);

  const [popUpModal, setPopUpModal] = useState<boolean>(false);
  const [welcomeTooltipStep, setWelcomeTooltipStep] = useState<number>(0);
  const [welcomeTooltipOpen, setWelcomeTooltipOpen] = useState<boolean>(false);
  const [popUpMessage, setPopUpMessage] = useState({
    text: '',
    category: '',
  });

  const { data: userSettings } = useGetUserSettingsQuery();
  const { data: chatRoomListData } = useGetChatRoomsQuery({
    skip: 0,
    limit: 10,
  });

  const [popupRes] = useLazyGetPopupQuery();
  const [setTutorialCompleted] = useSetTutorialCompletedMutation();

  const { data: remindersRes } = useGetRemindersQuery({
    params: {
      skip: 0,
      limit: 100,
      endDate: moment().endOf('day').toISOString(),
      startDate: moment().startOf('day').toISOString(),
    },
  });

  const { data: notificationRes } = useGetNotificationsQuery({
    skip: 0,
    limit: 50,
    read: false,
  });

  /** Check if D.Y.K. & O.M. notifications are on/off */
  const settings = useMemo(() => {
    if (!userSettings || userSettings.length === 0) return { dyk: false, om: false };
    return {
      dyk: userSettings[0].knowledgeNotifications,
      om: userSettings[0].otherMessagesNotifications,
    };
  }, [userSettings]);

  const reminders = useMemo(() => {
    if (!remindersRes) return 0;
    if (remindersRes.count > 99) return '99+';
    return remindersRes.count;
  }, [remindersRes]);

  const notifications = useMemo(() => {
    if (!notificationRes) return 0;
    return notificationRes.count;
  }, [notificationRes]);

  const unreadMessagesChat = useMemo<number>(() => {
    if (!chatRoomListData) return 0;
    if (chatRoomListData && chatRoomListData.rooms) {
      let unreadMsg = 0;
      chatRoomListData.rooms.forEach(room => {
        if (room.unreadMessageCount) {
          unreadMsg = room.unreadMessageCount + unreadMsg;
        }
      });
      return unreadMsg;
    }
    return 0;
  }, [chatRoomListData]);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setEmojiModalOpen(true);
  //     clearTimeout(timeout);
  //   }, 1500);
  // }, []);

  useEffect(() => {
    if (user) {
      const authUser = user.authUser;
      if (authUser && typeof authUser === 'object') {
        if (!authUser.tutorialCompleted) {
          const timeout = setTimeout(() => {
            setWelcomeTooltipOpen(true);
            clearTimeout(timeout);
          }, 500);
        }
      }
    }
  }, [user]);

  /**
   * DID YOU KNOW? - 3DAYS
   * checks users noti settings first
   * check date posted against time elapsed
   */
  useEffect(() => {
    if (!settings.dyk) return;
    getDidYouKnow().then(time => {
      const currentTime = new Date().getTime();
      const threeDays = 3 * 24 * 60 * 60 * 1000;
      if (!time || currentTime - time >= threeDays) {
        dataRetrieval('did_you_know');
      }
    });
  }, []);

  /**
   * OTHER MESSAGES - 5 DAYS
   * checks users noti settings first
   * check date posted against time elapsed
   */
  useEffect(() => {
    if (!settings.om) return;
    getOtherMessages().then(time => {
      const currentTime = new Date().getTime();
      const fiveDays = 5 * 24 * 60 * 60 * 1000;
      if (!time || currentTime - time >= fiveDays) {
        dataRetrieval('other_message');
      }
    });
  }, []);

  /**
   * retrieve popup message
   * for D.Y.K. & O.M.
   * reset counter in local storage
   */
  const dataRetrieval = async (category: string) => {
    try {
      const data = await popupRes({
        lang,
        category: category,
        personality: user?.questionnaireOutcome
          ? user.questionnaireOutcome.toLowerCase()
          : undefined,
      }).unwrap();
      if (data && data.messages.length > 0) {
        // pick a random message
        const randomIndex = Math.floor(Math.random() * data.messages.length);
        setPopUpMessage({ text: data.messages[randomIndex].message, category: category });
        setPopUpModal(true);
        if (category === 'did_you_know') {
          storeDidYouKnow();
        } else {
          storeOtherMessages();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onChatClick = () => {
    if (!user?.premiumUser) {
      dispatch(
        setPromotion({
          open: true,
          heading: t('premium.title'),
          body: t('premium.message'),
        }),
      );
      return;
    }
    router.push('/dashboard/chat');
  };

  const handleClickNotifications = () => {
    router.push('/dashboard/notification-alerts');
  };

  const handleClickReminders = () => {
    router.push('/dashboard/reminders');
  };

  const handleClickCard = (value: string) => {
    if (!user?.premiumUser) {
      if (
        value === 'fitness' ||
        value === 'programs' ||
        value === 'medication' ||
        value === 'cvd'
      ) {
        dispatch(
          setPromotion({
            open: true,
            heading: t('premium.title'),
            body: t('premium.message'),
          }),
        );
        return;
      }
    }
    if (value === 'programs') {
      router.push(`/dashboard/fitness/workouts`);
      return;
    }

    if (value === 'healthdata') {
      router.push(`/dashboard/records`);
      return;
    }

    router.push(`/dashboard/${value}`);
  };

  const closeTutorialModal = () => {
    setWelcomeTooltipOpen(false);
    setTutorialCompleted();
  };

  return (
    <PageLayout
      headerTitle={t('headingsTitles.home')}
      endButtons={[
        {
          icon: (
            <BadgeMain
              color="error"
              variant="dot"
              invisible={notifications === 0}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <NotificationsNoneOutlinedIcon htmlColor={theme.palette.primary.main} />
            </BadgeMain>
          ),
          onClick: handleClickNotifications,
        },
      ]}
      unreadMessages={unreadMessagesChat}
    >
      <ComponentsLayout>
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <Box display={'flex'} flex={1} gap={1}>
            <Box flex={1}>
              <Box flexGrow={1} display={'flex'} justifyContent={'space-between'}>
                <Title
                  variant={'subtitle1'}
                  sx={{ marginBottom: 1 }}
                  textTransform={web && lang === 'en' ? 'uppercase' : 'capitalize'}
                >
                  {t('homePage.subtitleGoal')}
                </Title>
              </Box>
              <Box>
                <GoalsSection />
              </Box>
            </Box>

            <Box display={web ? 'flex' : 'none'} flexDirection={'column'} flex={1}>
              <RemindersDashboard category={'all'} />
            </Box>
          </Box>

          {!web && (
            <DashboardCardSmall
              reminder
              color={'#DC6826'}
              unreadMessages={reminders}
              onClick={handleClickReminders}
            />
          )}

          <Title
            variant={'subtitle1'}
            textTransform={web && lang === 'en' ? 'uppercase' : 'capitalize'}
          >
            {t('dashboard.headings.myData')}
          </Title>

          <DashDataCards handleClickCard={handleClickCard} />

          {!web && (
            <DashboardCardSmall
              onClick={onChatClick}
              sx={{ marginTop: 2 }}
              unreadMessages={unreadMessagesChat}
            />
          )}
        </Box>
      </ComponentsLayout>
      <ModalMobile width={'335px'} open={welcomeTooltipOpen} setOpen={closeTutorialModal}>
        <TooltipOverlay
          activeStep={welcomeTooltipStep}
          onContinue={closeTutorialModal}
          setActiveStep={setWelcomeTooltipStep}
          onSkip={() => setWelcomeTooltipOpen(false)}
        />
      </ModalMobile>
      <UserMood welcomeOpen={welcomeTooltipOpen} />
      <AppleHealth />
      <FcmIntegration />
      <ModalPopUp
        open={popUpModal}
        setOpen={setPopUpModal}
        text={popUpMessage.text}
        category={popUpMessage.category}
      />
    </PageLayout>
  );
};

export default Dashboard;
