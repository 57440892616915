import * as React from 'react';
import { styled, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { FC } from 'react';
import DeleteBucketIcon from '../../assets/DeleteBucketIcon';
import CustomButton from '../../components/controls/CustomButton';
import { useTranslation } from 'react-i18next';

const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(3),
  marginTop: theme.spacing(3),
}));

type RemoveEditButtonsProps = {
  onEdit: () => void;
  onRemove: () => void;
};
const RemoveEditButtonsContainer: FC<RemoveEditButtonsProps> = ({ onRemove, onEdit }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <ButtonsContainer>
      <CustomButton fullWidth onClick={onRemove} color={'secondary'} variant={'outlined'}>
        <DeleteBucketIcon color={theme.palette.primary.main} />
        <Typography
          variant={'subtitle2'}
          color={theme.palette.primary.main}
          marginLeft={theme.spacing(1)}
          fontWeight={theme.typography.fontWeightRegular}
        >
          {t('buttons.remove')}
        </Typography>
      </CustomButton>
      <CustomButton
        fullWidth
        onClick={onEdit}
        disableElevation
        color={'primary'}
        variant={'contained'}
      >
        {t('buttons.editGoal')}
      </CustomButton>
    </ButtonsContainer>
  );
};

export default RemoveEditButtonsContainer;
