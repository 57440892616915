import { SettingsPanelMobile } from '@hdcorner/ui-library';
import { useIonRouter } from '@ionic/react';
import PageLayout from '../../components/PageLayout';
import { useGetAuthUserQuery } from '../authentication/queries/signInQueries';
import { useGetUserDataQuery } from './queries/personalInfoQueries';
import useAlert from '../../hooks/useAlert';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const AccountSettings = () => {
  const { presentError } = useAlert();
  const { t } = useTranslation();

  const router = useIonRouter();

  const { data: authUserRes, error: errorAuthUser } = useGetAuthUserQuery();
  const { data: userDataRes, error: errorUserData } = useGetUserDataQuery();

  useEffect(() => {
    if (errorAuthUser || errorUserData) presentError(t('errors.profile.errorUserData'));
  }, [errorAuthUser, errorUserData]);

  const handleRouting = (route: string) => {
    router.push(`/dashboard/${route}`);
  };

  return (
    <PageLayout
      backgroundWhite
      defaultHref={'/dashboard/profile'}
      headerTitle={t('headingsTitles.accSettings')}
    >
      <SettingsPanelMobile
        handleRouting={handleRouting}
        phone={userDataRes ? userDataRes[0].phone || '' : ''}
        email={authUserRes && authUserRes[0].email ? authUserRes[0].email : ''}
      />
    </PageLayout>
  );
};

export default AccountSettings;
