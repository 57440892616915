import { alpha, Box, BoxProps, useTheme } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { PasswordStrengthEnum } from '../../enums/PasswordStrengthEnum';

const Container = styled(Box)<BoxProps>(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: theme.spacing(1),
}));

const PasswordSlider = styled(Box)<BoxProps>(({ theme }) => ({
  height: 4,
  width: '32%',
  borderRadius: 8,
  background: alpha(theme.palette.primary.main, 0.12),
}));

type PasswordStrengthType = {
  passwordStrength?: PasswordStrengthEnum;
};
const PasswordStrength: FC<PasswordStrengthType> = ({ passwordStrength }) => {
  const theme = useTheme();

  const handleBarsColor = useMemo(() => {
    const red = theme.palette.error.main;
    const green = theme.palette.success.main;
    const yellow = theme.palette.warning.main;
    const grey = alpha(theme.palette.primary.main, 0.12);

    switch (passwordStrength) {
      case PasswordStrengthEnum.empty:
        return [grey, grey, grey];
      case PasswordStrengthEnum.error:
        return [red, grey, grey];
      case PasswordStrengthEnum.weak:
        return [yellow, yellow, grey];
      case PasswordStrengthEnum.strong:
        return [green, green, green];
      default:
        return [grey, grey, grey];
    }
  }, [passwordStrength]);

  return (
    <Container>
      {[0, 1, 2].map(item => (
        <PasswordSlider
          key={item}
          sx={{
            background: handleBarsColor[item],
          }}
        />
      ))}
    </Container>
  );
};

export default PasswordStrength;
