import React, { useEffect, useMemo, useState } from 'react';
import { useIonRouter } from '@ionic/react';
import ModalStepsGoal from './ModalStepsGoal';
import useTimeframe from '../../../../hooks/useTimeframe';
import SectionHeading from '../../components/SectionHeading';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import {
  BarChart,
  CalendarModal,
  ChartCard,
  ClickNavCardNutrition,
  dayWeekMonthYearCustom,
  FeelsEmpty,
  prepareGraphData,
} from '@hdcorner/ui-library';
import {
  useGetStepsGraphDataQuery,
  useGetUserGoalStepsQuery,
} from '../queries/stepsQueries';
import useAlert from '../../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../../../../utils/useConvertJSON';

const ChartSteps = () => {
  const theme = useTheme();
  const router = useIonRouter();
  const { t } = useTranslation();
  const { presentError } = useAlert();

  const web = useMediaQuery(theme.breakpoints.up('md'));

  const [open, setOpen] = useState<boolean>(false);
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
  const [selectedTimeframe, setSelectedTimeframe] = useState<
    'day' | 'week' | 'month' | 'year'
  >('day');

  // Used for updating the params for the query
  const [endParam, setEndParam] = useState<string>('');
  const [startParam, setStartParam] = useState<string>('');

  const timeframe = useTimeframe(selectedTimeframe, startParam, endParam);
  const decideTimeframe = useMemo(() => {
    if (selectedTimeframe === 'day') {
      return { timeframe: 'day' };
    } else if (selectedTimeframe === 'week') {
      return { timeframe: 'week' };
    } else if (selectedTimeframe === 'month') {
      return { timeframe: 'month' };
    } else if (selectedTimeframe === 'year') {
      return { timeframe: 'year' };
    } else {
      return { ...timeframe };
    }
  }, [selectedTimeframe, timeframe]);

  const { data: getGoalRes, error: goalError } = useGetUserGoalStepsQuery();
  const { data: graphRes, error: graphError } = useGetStepsGraphDataQuery({
    ...decideTimeframe,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  const stepsData = prepareGraphData(graphRes?.graphData, 'steps');

  useEffect(() => {
    if (goalError) {
      presentError(t('errors.exercise.errorStepsFetchGoal'));
    }

    if (graphError) {
      presentError(t('errors.exercise.errorStepsFetchGraph'));
    }
  }, [goalError, graphError]);

  const goalSteps = useMemo(() => {
    if (!getGoalRes || getGoalRes.length === 0) return `- ${t('headingsTitles.steps')}`;

    return `${getGoalRes[0].goal.steps} ${t('headingsTitles.steps')}`;
  }, [getGoalRes]);

  const handleClickGoal = () => {
    if (!getGoalRes || getGoalRes.length === 0) {
      setOpen(true);
    } else {
      router.push('/dashboard/fitness/exercise/steps/goal');
    }
  };

  const handleDateChange = (dateRange: string[]) => {
    setEndParam(dateRange[1]);
    setStartParam(dateRange[0]);
  };

  return (
    <Box display={'flex'} flex={1} flexDirection={'column'}>
      <Box mb={web ? 1 : 0} display={web ? 'block' : 'none'}>
        <SectionHeading hasGoal={false} heading={t('headingsTitles.steps')} />
      </Box>
      <Box>
        <ChartCard
          setOpen={setCalendarOpen}
          value={selectedTimeframe}
          setValue={setSelectedTimeframe}
          unit={t('headingsTitles.steps')}
          headings={useConvertJSON(dayWeekMonthYearCustom).slice(1)}
          amount={
            selectedTimeframe === 'day'
              ? graphRes?.total?.toFixed() || 0
              : graphRes?.avg?.toFixed() || 0
          }
        >
          {stepsData.length === 0 || !stepsData ? (
            <FeelsEmpty />
          ) : (
            <BarChart chartData={stepsData} unitOfTime={selectedTimeframe} />
          )}
        </ChartCard>
      </Box>
      <Box mt={2} display={web ? 'none' : 'block'}>
        <ClickNavCardNutrition
          headings={t('buttons.navigation.inDepth')}
          onClick={() => router.push('/dashboard/fitness/exercise/in-depth/steps')}
        />
        <ClickNavCardNutrition
          body={goalSteps}
          onClick={handleClickGoal}
          headings={t('buttons.navigation.goal')}
        />
        <ModalStepsGoal log={false} open={open} setOpen={setOpen} />
        <CalendarModal
          open={calendarOpen}
          setOpen={setCalendarOpen}
          saveDate={handleDateChange}
          selectedDate={[startParam, endParam]}
        />
      </Box>
    </Box>
  );
};

export default ChartSteps;
