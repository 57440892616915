import * as React from 'react';
import { SVGProps } from 'react';
import { Box, BoxProps, styled } from '@mui/material';

const IconBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: '40px',
  height: '40px',
  borderRadius: '8px',
  padding: '8px',
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#EB4DBF',
}));
const GoalsCompletedSQ = (props: SVGProps<SVGSVGElement>) => {
  return (
    <IconBox>
      <svg
        width="23"
        height="26"
        viewBox="0 0 23 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M4.5 21L1 19V15M4.5 21V16.9998M4.5 21L8 23M1 15L4.5 13M1 15L4.5 16.9998M8 15L4.5 13M8 15L4.5 16.9998M8 15L11.5 17M8 15V10.9998M8 18.9998L4.5 16.9998M8 18.9998L11.5 17M8 18.9998V23M8 18.9998L11.5 20.9998M4.5 13V9M8 23L11.5 25M11.5 17L15 15M11.5 17L15 19M11.5 17V13M11.5 20.9998L15 19M11.5 20.9998V25M15 15L18.5 12.9998M15 15V10.9998M15 15L18.5 16.9998M11.5 13L8 10.9998M11.5 13L15 10.9998M11.5 9L15 7M11.5 9L15 10.9998M11.5 9L8 7M11.5 9L8 10.9998M11.5 9V4.9998M18.5 9V12.9998M18.5 9L15 7M18.5 9L15 10.9998M18.5 12.9998L22 15M15 7V3M4.5 9L8 7M4.5 9L8 10.9998M8 7V3M18.5 21L22 18.9998V15M18.5 21V16.9998M18.5 21L15 23M15 19L18.5 16.9998M15 19V23M22 15L18.5 16.9998M15 23L11.5 25M8 3L11.5 1L15 3M8 3L11.5 4.9998M15 3L11.5 4.9998"
          stroke="#FBFBFB"
        />
      </svg>
    </IconBox>
  );
};

export default GoalsCompletedSQ;
