import React, { FC } from 'react';
import { Box, BoxProps, List, ListProps, styled } from '@mui/material';

const ListMain = styled(List)<ListProps>(({ theme }) => ({
  padding: '8px',
  overflow: 'auto',
  borderRadius: '8px',
  background: theme.palette.secondary.light,
  '::-webkit-scrollbar': {
    width: 4,
  },
  '::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: 8,
    backgroundColor: theme.palette.secondary.dark,
  },
}));

type GeneralListType = {
  listProps?: ListProps;
  listMaxHeight?: number;
  children: React.ReactNode;
};
const GeneralList: FC<GeneralListType & BoxProps> = ({
  children,
  listProps,
  listMaxHeight = 400,
  ...containerProps
}) => {
  return (
    <Box overflow={'hidden'} borderRadius={'8px'} {...containerProps}>
      <ListMain {...listProps} sx={{ maxHeight: listMaxHeight }}>
        {children}
      </ListMain>
    </Box>
  );
};

export default GeneralList;
