import { useEffect, useMemo, useState } from 'react';
import {
  useGetLipidFirstLogQuery,
  useGetLipidGoalQuery,
  useGetLipidLastLogQuery,
} from '../../lipid/queries/lipidQueries';
import { goalProgress } from '../../../utils/goalProgress';

const useGetLpaGoalData = () => {
  const [startingDate, setStartingDate] = useState<string>();

  const { data: lpaGoal } = useGetLipidGoalQuery({ type: 'lpa' });

  const { data: lipidCurrent } = useGetLipidLastLogQuery();
  const { data: lipidStarting } = useGetLipidFirstLogQuery(
    {
      startingDate: startingDate || '',
    },
    { skip: !startingDate },
  );

  useEffect(() => {
    if (lpaGoal && lpaGoal.length > 0) {
      setStartingDate(lpaGoal[0].startDate);
    }
  }, [lpaGoal]);

  return useMemo(() => {
    if (!lpaGoal || lpaGoal.length === 0) return null;

    let goalValue;
    let startValue;
    let currentValue;
    let progress = 0;

    if (lpaGoal && lpaGoal.length > 0) {
      if (lpaGoal[0].goal) {
        goalValue = parseInt(lpaGoal[0].goal?.measurement);
      }
      goalValue = 0;
    }

    if (lipidStarting && lipidStarting.documents.length > 0) {
      startValue = lipidStarting.documents[0].lpa;
    }

    if (lipidCurrent && lipidCurrent.documents.length > 0) {
      currentValue = lipidCurrent.documents[0].lpa;
      startValue = startValue || currentValue;
    }

    if (goalValue && startValue && currentValue) {
      progress = goalProgress(currentValue, goalValue, startValue);
    }

    return { progress, goalValue, startValue, currentValue };
  }, [lpaGoal, lipidCurrent, lipidStarting]);
};

export default useGetLpaGoalData;
