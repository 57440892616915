import React, { FC } from 'react';
import {
  Box,
  FormControlLabel,
  FormControlLabelProps,
  Radio,
  RadioProps,
  styled,
} from '@mui/material';

const BpIcon = styled('span')(({ theme }) => ({
  width: 16,
  height: 16,
  borderRadius: '50%',
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  border: theme.palette.mode === 'dark' ? '1px solid #A5A9B1' : 'inherit',
}));

const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  border: 'none',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.kmColorsTurquoise.main
      : theme.palette.kmColorsRed.main,
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    width: 16,
    height: 16,
    content: '""',
    display: 'block',
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
  },
}));

const RadioLabel = styled(FormControlLabel, {
  shouldForwardProp: prop => prop !== 'bold' && prop !== 'reverse',
})<FormControlLabelProps & { bold: boolean; reverse: boolean }>(
  ({ bold, theme, reverse }) => ({
    textAlign: 'left',
    '.MuiFormControlLabel-label': {
      color: theme.palette.primary.main,
      fontSize: bold
        ? theme.typography.body1.fontSize
        : theme.typography.subtitle2.fontSize,
      fontWeight: bold
        ? theme.typography.fontWeightBold
        : theme.typography.subtitle2.fontWeight,
    },
    flex: 1,
    marginLeft: 0,
    marginRight: 0,
    display: 'flex',
    gap: theme.spacing(1),
    flexDirection: reverse ? 'row-reverse' : 'row',
    justifyContent: reverse ? 'space-between' : 'flex-start',
    '.MuiButtonBase-root.MuiRadio-root': {
      padding: 0,
    },
  }),
);

type RadiosSingleProps = {
  bold?: boolean;
  label?: string;
  value?: string;
  reverse?: boolean;
  setValue?: (value: string) => void;
};
const RadioSingle: FC<RadiosSingleProps & RadioProps> = ({
  bold,
  label,
  value,
  reverse,
  setValue,
  disabled,
}) => {
  const onChangeHandler = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const { target } = event;
    setValue?.(target?.value);
  };

  const BpRadio = (props: RadioProps) => {
    return (
      <Radio
        {...props}
        disableRipple
        color="default"
        icon={<BpIcon />}
        disabled={disabled}
        onChange={onChangeHandler}
        checkedIcon={<BpCheckedIcon />}
      />
    );
  };

  return (
    <Box flex={1} display={'flex'} justifyContent={'space-between'}>
      <RadioLabel
        value={value}
        label={label}
        disabled={disabled}
        control={<BpRadio />}
        reverse={reverse || false}
        bold={bold ? bold : false}
      />
    </Box>
  );
};

export default RadioSingle;
