import React, { FC, useState } from 'react';
import {
  Card,
  CardActionAreaProps,
  CardContent,
  CardContentProps,
  CardProps,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ReactPlayer from 'react-player';
import PlayButton from '../../assets/PlayButton';
import CustomButton from '../controls/CustomButton';
import { useTranslation } from 'react-i18next';

const CardMain = styled(Card)<CardProps>(() => ({
  flex: 1,
  height: 'auto',
  minHeight: 200,
  display: 'flex',
  boxShadow: 'none',
  overflow: 'hidden',
  borderRadius: '8px',
  position: 'relative',
  flexDirection: 'column',
}));

const CardContentMain = styled(CardContent)<CardContentProps>(({ theme }) => ({
  height: '100%',
  minHeight: '200px',
  position: 'relative',
  padding: theme.spacing(0),
  '&.MuiCardContent-root:last-child': {
    paddingBottom: theme.spacing(0),
  },
}));

type CardWithVideoType = {
  url: string;
  title?: string;
  titleOnTop?: boolean;
  handleShowMoreClick?: () => void;
  cardContentProps?: CardContentProps;
  cardActionAreaProps?: CardActionAreaProps;
};
const CardWithVideo: FC<CardWithVideoType & CardProps> = ({
  url,
  title,
  titleOnTop = true,
  handleShowMoreClick,
  cardActionAreaProps,
  cardContentProps,
  ...cardProps
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [playing, setPlaying] = useState<boolean>(false);
  const [started, setStarted] = useState<boolean>(false);

  return (
    <CardMain raised={false} {...cardProps}>
      <CardContentMain>
        <ReactPlayer
          url={url}
          light={!started}
          style={{
            ...(!playing ? { filter: 'blur(2px)' } : undefined),
          }}
          width={'100%'}
          controls={true}
          height={'200px'}
          playIcon={<></>}
          playing={playing}
          onPlay={() => setPlaying(true)}
          onPause={() => setPlaying(false)}
          onClickPreview={() => setPlaying(true)}
        />
        {playing ? null : (
          <>
            <IconButton
              onClick={() => {
                setPlaying(true);
                setStarted(true);
              }}
              sx={{
                top: '50%',
                left: '50%',
                position: 'absolute',
                transform: 'translateY(-50%) translateX(-50%)',
              }}
            >
              <PlayButton />
            </IconButton>
            <CustomButton
              variant={'text'}
              onClick={handleShowMoreClick}
              sx={{
                '&.MuiButton-text': {
                  width: titleOnTop ? '90%' : undefined,
                },
                cursor: 'pointer',
                position: 'absolute',
                justifyContent: 'flex-start',
                padding: theme.spacing(0),
                color: theme.palette.secondary.main,
                fontWeight: theme.typography.fontWeightMedium,
                ...(!titleOnTop
                  ? {
                      left: '50%',
                      transform: 'translateX(-50%)',
                      bottom: theme.spacing(2),
                    }
                  : {
                      top: theme.spacing(1),
                      left: theme.spacing(1),
                    }),
              }}
            >
              {!titleOnTop ? (
                <>{t('general.text.tapMore')}</>
              ) : (
                <Typography
                  align={'left'}
                  variant={'subtitle2'}
                  sx={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    color: theme.palette.secondary.light,
                    fontWeight: theme.typography.fontWeightMedium,
                  }}
                >
                  {title}
                </Typography>
              )}
            </CustomButton>
          </>
        )}
      </CardContentMain>
    </CardMain>
  );
};

export default CardWithVideo;
