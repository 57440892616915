import * as React from 'react';
import { SVGProps } from 'react';
import { useTheme } from '@mui/material';

const LockPremium = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.5 21H17.5C18.2956 21 19.0587 20.6839 19.6213 20.1213C20.1839 19.5587 20.5 18.7956 20.5 18V9C20.5 8.20435 20.1839 7.44129 19.6213 6.87868C19.0587 6.31607 18.2956 6 17.5 6H15.5V5C15.5 3.67392 14.9732 2.40215 14.0355 1.46447C13.0979 0.526784 11.8261 0 10.5 0C9.17392 0 7.90215 0.526784 6.96447 1.46447C6.02678 2.40215 5.5 3.67392 5.5 5V6H3.5C2.70435 6 1.94129 6.31607 1.37868 6.87868C0.816071 7.44129 0.5 8.20435 0.5 9V18C0.5 18.7956 0.816071 19.5587 1.37868 20.1213C1.94129 20.6839 2.70435 21 3.5 21ZM7.5 5C7.5 4.20435 7.81607 3.44129 8.37868 2.87868C8.94129 2.31607 9.70435 2 10.5 2C11.2956 2 12.0587 2.31607 12.6213 2.87868C13.1839 3.44129 13.5 4.20435 13.5 5V6H7.5V5ZM2.5 9C2.5 8.73478 2.60536 8.48043 2.79289 8.29289C2.98043 8.10536 3.23478 8 3.5 8H17.5C17.7652 8 18.0196 8.10536 18.2071 8.29289C18.3946 8.48043 18.5 8.73478 18.5 9V18C18.5 18.2652 18.3946 18.5196 18.2071 18.7071C18.0196 18.8946 17.7652 19 17.5 19H3.5C3.23478 19 2.98043 18.8946 2.79289 18.7071C2.60536 18.5196 2.5 18.2652 2.5 18V9ZM8.5 13.5C8.5 13.1044 8.6173 12.7178 8.83706 12.3889C9.05682 12.06 9.36918 11.8036 9.73463 11.6522C10.1001 11.5009 10.5022 11.4613 10.8902 11.5384C11.2781 11.6156 11.6345 11.8061 11.9142 12.0858C12.1939 12.3655 12.3844 12.7219 12.4616 13.1098C12.5387 13.4978 12.4991 13.8999 12.3478 14.2654C12.1964 14.6308 11.94 14.9432 11.6111 15.1629C11.2822 15.3827 10.8956 15.5 10.5 15.5C9.96957 15.5 9.46086 15.2893 9.08579 14.9142C8.71071 14.5391 8.5 14.0304 8.5 13.5Z"
        fill={props.fill ? props.fill : theme.palette.primary.main}
      />
    </svg>
  );
};

export default LockPremium;
