import { capitalizeFirstLetter } from './capitalizeFirstLetter';

export const pageTitles = (value: string) => {
  const path = value.split('/').slice(-1);
  const newPath = path[0].split('-');

  const newArr = newPath.map(it => {
    return capitalizeFirstLetter(it);
  });

  return newArr.join(' ');
};
