import IonicFabButton from './IonicFabButton';
import { ExerciseIcon, findMealItems, ModalMobile, theme } from '@hdcorner/ui-library';
import BreakfastIcon from '../../../assets/icons/BreakfastIcon';
import LunchIcon from '../../../assets/icons/LunchIcon';
import DinnerIcon from '../../../assets/icons/DinnerIcon';
import SnackIcon from '../../../assets/icons/SnackIcon';
import WeightIcon from '../../../assets/icons/WeightIcon';
import ActivitiesIcon from '../../../assets/icons/ActivitiesIcon';
import IonicFabMain from './IonicFabMain';
import React, { useMemo, useState } from 'react';
import { useIonRouter } from '@ionic/react';
import ModalActivityContent from '../exercise/components/ModalActivityContent';
import ModalSliderWeight from '../wellness/components/ModalSliderWeight';
import ModalStepsGoal from '../exercise/components/ModalStepsGoal';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  cleanSlate,
  createAPIFoodArray,
  deleteFoodArray,
  updateCategory,
  updateMealId,
} from '../nutrition/slices/mealFoodsSlice';
import { useMediaQuery } from '@mui/material';
import ModalFoodMealsWeb from '../nutrition/components/ModalFoodMealsWeb';
import { Camera, CameraResultType } from '@capacitor/camera';
import useAlert from '../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';
import { useGetMealsOfDayQuery } from '../nutrition/queries/foodsQueries';
import moment from 'moment/moment';
import { cameraPermissions } from '../../../utils/cameraPermissions';

const FABFitness = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { presentSuccess, presentError } = useAlert();

  const web = useMediaQuery(theme.breakpoints.up('md'));

  const { logDate, mealCategory } = useAppSelector(state => state.mealFoodsList);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [openMeal, setOpenMeal] = useState<boolean>(false);
  const [openWeight, setOpenWeight] = useState<boolean>(false);
  const [openActivities, setOpenActivities] = useState<boolean>(false);
  const [openStepsModal, setOpenStepsModal] = useState<boolean>(false);
  const [openDialogFab, setOpenDialogFab] = useState<boolean>(false);

  /**
   * Retrieve meals of the day
   */
  const start = logDate
    ? moment(logDate).utc(true).startOf('day').toISOString()
    : moment().utc(true).startOf('day').toISOString();
  const end = logDate
    ? moment(logDate).utc(true).endOf('day').toISOString()
    : moment().utc(true).endOf('day').toISOString();
  const { data: mealLogRes } = useGetMealsOfDayQuery({
    start,
    end,
  });

  /**
   * Removes multiple meals of the same category
   * Multiples created due to bug in initial setup
   */
  const revisedMeals = useMemo(() => {
    if (!mealLogRes || mealLogRes.length === 0) return;
    return mealLogRes.filter((item, ind) => {
      return ind === mealLogRes.findIndex((it: any) => item.category === it.category);
    });
  }, [mealLogRes]);

  const handleAddFood = (value: string) => {
    setOpenDialogFab(false);
    dispatch(deleteFoodArray());
    dispatch(updateCategory(value));
    sendMealId(value);
    if (web) {
      setOpenMeal(true);
    } else {
      router.push(`/dashboard/fitness/food-meals/${value}`);
    }
  };

  /**
   * Func check if log exists for selected date
   * if log exists, redux is updated
   */
  const sendMealId = (meal: string) => {
    if (!revisedMeals || revisedMeals.length === 0) return;
    const found = revisedMeals.find(it => it.category === meal);
    if (found) {
      dispatch(updateMealId(found._id));
      dispatch(createAPIFoodArray(findMealItems(found)));
    }
  };

  const handleAddWeight = () => {
    setOpenDialogFab(false);
    setOpenWeight(true);
  };

  const handleAddActivity = () => {
    setOpenDialogFab(false);
    setOpenActivities(true);
  };

  const handleFoodsModal = (value: boolean) => {
    setOpenMeal(value);
    if (!value) dispatch(cleanSlate());
  };
  const handleSteps = () => {
    setOpenDialogFab(false);
    setOpenStepsModal(true);
  };

  const handleWeightOcr = async (cb: (newMeasurement: { weight: string }) => void) => {
    const cameraSource = await cameraPermissions();
    if (!cameraSource) {
      presentError(t('errors.devicePermissions.camera'));
      return;
    }
    const image = await Camera.getPhoto({
      quality: 90,
      source: cameraSource,
      resultType: CameraResultType.Base64,
    });

    setLoading(true);
    await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer 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',
      },
      body: JSON.stringify({
        max_tokens: 300,
        model: 'gpt-4-vision-preview',
        messages: [
          {
            role: 'user',
            content: [
              {
                type: 'text',
                text: 'Read the text on the device screen resembling the following: 70 kg and return just the number measurements',
              },
              {
                type: 'image_url',
                image_url: {
                  url: `data:image/jpeg;base64,${image.base64String}`,
                },
              },
            ],
          },
        ],
      }),
    })
      .then(response => response.json())
      .then(data => {
        setLoading(false);
        if (data.choices.length === 0) {
          presentError('No measurement detected');
          return;
        }
        if (data.choices[0].message.content === 'No measurement detected') {
          presentError('No measurement detected');
          return;
        }

        const weight = data.choices[0].message.content;
        cb({ weight });
        presentSuccess('Measurement detected!');
      })
      .catch(error => {
        setLoading(false);
        presentError('Error reading image');
      });
  };

  return (
    <>
      <ModalSliderWeight
        log
        open={openWeight}
        isLoading={isLoading}
        setOpen={setOpenWeight}
        triggerOCR={cb => handleWeightOcr(cb)}
      />
      <ModalStepsGoal log open={openStepsModal} setOpen={setOpenStepsModal} />
      <ModalMobile open={openActivities} setOpen={setOpenActivities} width={'398px'}>
        <ModalActivityContent dashboardModal setOpen={setOpenActivities} />
      </ModalMobile>
      <ModalFoodMealsWeb
        open={openMeal}
        category={mealCategory}
        setOpen={handleFoodsModal}
      />
      <IonicFabMain openDialogFab={openDialogFab} setOpenDialogFab={setOpenDialogFab}>
        <IonicFabButton
          color={theme.palette.hypertension.main}
          onClick={() => handleAddFood('breakfast')}
          title={t('buttons.navigation.addBreakfast')}
          icon={<BreakfastIcon width={'16px'} height={'16px'} />}
        />
        <IonicFabButton
          color={theme.palette.medication.main}
          onClick={() => handleAddFood('lunch')}
          title={t('buttons.navigation.addLunch')}
          icon={<LunchIcon width={'18px'} height={'18px'} />}
        />
        <IonicFabButton
          color={theme.palette.nutrition.main}
          onClick={() => handleAddFood('dinner')}
          title={t('buttons.navigation.addDinner')}
          icon={<DinnerIcon width={'18px'} height={'20px'} />}
        />
        <IonicFabButton
          color={theme.palette.diabetes.main}
          onClick={() => handleAddFood('snacks')}
          title={t('buttons.navigation.addSnack')}
          icon={<SnackIcon width={'18px'} height={'22px'} />}
        />
        <IonicFabButton
          color={'#E93C16'}
          icon={<WeightIcon />}
          onClick={() => handleAddWeight()}
          title={t('buttons.navigation.addWeight')}
        />
        <IonicFabButton
          icon={<ActivitiesIcon />}
          color={theme.palette.warning.main}
          onClick={() => handleAddActivity()}
          title={t('buttons.navigation.addActivity')}
        />
        <IonicFabButton
          onClick={() => handleSteps()}
          color={theme.palette.exercise.main}
          title={t('buttons.navigation.addSteps')}
          icon={<ExerciseIcon width={'22px'} height={'20px'} />}
        />
      </IonicFabMain>
    </>
  );
};

export default FABFitness;
