import { FC } from 'react';
import { Box, BoxProps, styled, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

const BoxMain = styled(Box)<BoxProps & { change: string }>(({ theme, change }) => ({
  borderRadius: 4,
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  flexDirection: 'column',
  backgroundColor: change,
  justifyContent: 'center',
  padding: theme.spacing(0.25),
}));

type BMIIndicatorProps = {
  bmi: string;
  weight: { value: string; label: string };
};
const BMIIndicator: FC<BMIIndicatorProps> = ({ bmi, weight }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const handleBackground = () => {
    switch (weight.value) {
      case 'over':
        return theme.palette.warning.main;
      case 'healthy':
        return theme.palette.success.main;
      case 'under':
        return '#EA728F';
      default:
        return theme.palette.primary.main;
    }
  };

  const handleTextColor = () => {
    switch (weight.value) {
      case 'over':
        return theme.palette.primary.main;
      case 'healthy':
      case 'under':
        return theme.palette.secondary.light;
      default:
        return theme.palette.primary.main;
    }
  };
  return (
    <BoxMain change={handleBackground()}>
      <Typography
        variant={'body1'}
        sx={{ color: handleTextColor(), fontWeight: theme.typography.fontWeightBold }}
      >
        {`${t('fitness.wellness.labels.bmi')}: ${bmi}`}
      </Typography>
      <Typography
        variant={'caption'}
        sx={{
          color: handleTextColor(),
          fontWeight: theme.typography.fontWeightBold,
        }}
        textTransform={lang === 'el' ? 'capitalize' : 'uppercase'}
      >
        {t(weight.label)}
      </Typography>
    </BoxMain>
  );
};

export default BMIIndicator;
