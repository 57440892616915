import * as React from 'react';
import { SVGProps } from 'react';

const DashboardSidebarIcon = ({ fill = '#222222', ...rest }: SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={20}
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M18 19V6.40002C18.0001 6.23638 17.96 6.07523 17.8833 5.93069C17.8066 5.78616 17.6956 5.66265 17.56 5.57102L9.56 0.171019C9.39463 0.0592431 9.1996 -0.000488281 9 -0.000488281C8.8004 -0.000488281 8.60537 0.0592431 8.44 0.171019L0.44 5.57102C0.304431 5.66265 0.193419 5.78616 0.116705 5.93069C0.0399906 6.07523 -8.28837e-05 6.23638 1.28708e-07 6.40002V19C1.28708e-07 19.2652 0.105357 19.5196 0.292893 19.7071C0.48043 19.8947 0.734784 20 1 20H17C17.2652 20 17.5196 19.8947 17.7071 19.7071C17.8946 19.5196 18 19.2652 18 19ZM16 18H2V6.93202L9 2.20702L16 6.93202V18Z"
      fill={fill}
    />
  </svg>
);

export default DashboardSidebarIcon;
