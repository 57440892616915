import React, { FC, useMemo } from 'react';
import ThumbsUp from '../../assets/ThumbsUp';
import ThumbsDown from '../../assets/ThumbsDown';
import { Box, styled, Typography, TypographyProps, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

const TypographyText = styled(Typography)<TypographyProps>(({ theme }) => ({
  boxSizing: 'border-box',
  padding: theme.spacing(0),
  color: theme.palette.secondary.main,
  fontWeight: theme.typography.fontWeightBold,
  textTransform: lang === 'el' ? 'capitalize' : 'uppercase',
}));

type RiskIdealPercentCardProps = {
  percent: string;
  fullwidth?: boolean;
  riskIdealState: 'danger' | 'ideal' | 'warning';
};
const RiskIdealPercentCard: FC<RiskIdealPercentCardProps> = ({
  percent,
  fullwidth,
  riskIdealState,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const pickColor = useMemo(() => {
    if (riskIdealState === 'ideal') {
      return theme.palette.success.main;
    } else if (riskIdealState === 'danger') {
      return theme.palette.error.main;
    } else {
      return theme.palette.warning.main;
    }
  }, [riskIdealState]);

  const pickText = useMemo(() => {
    if (riskIdealState === 'ideal') {
      return 'cvd.labels.ideal';
    } else if (riskIdealState === 'danger') {
      return 'cvd.labels.risk';
    } else {
      return 'cvd.labels.warning';
    }
  }, [riskIdealState]);

  return (
    <Box
      sx={{
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(0),
        padding: theme.spacing(0),
      }}
      width={fullwidth ? '100%' : 'unset'}
    >
      <Box
        sx={{
          display: 'flex',
          minWidth: '140px',
          borderRadius: '8px',
          alignItems: 'center',
          boxSizing: 'border-box',
          justifyContent: 'center',
          padding: theme.spacing(1),
        }}
        bgcolor={pickColor}
        width={fullwidth ? '100%' : 'unset'}
        maxWidth={fullwidth ? 'unset' : '270px'}
      >
        {riskIdealState ? <ThumbsUp /> : <ThumbsDown />}
        <TypographyText variant={'subtitle2'} sx={{ padding: theme.spacing(0, 1.5) }}>
          {t(pickText)}:
        </TypographyText>
        <TypographyText variant={'subtitle2'}>{percent}</TypographyText>
      </Box>
    </Box>
  );
};

export default RiskIdealPercentCard;
