import * as React from 'react';
import { SVGProps } from 'react';

const ArrowRight = ({ fill = '#222222', ...rest }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="8"
      height="11"
      viewBox="0 0 8 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M0.966614 9.26165C0.613052 9.57592 0.565281 10.1364 0.859917 10.5136C1.15455 10.8907 1.68002 10.9416 2.03358 10.6274L7.03357 6.18294C7.22356 6.01406 7.33342 5.76389 7.33342 5.50008C7.33342 5.23628 7.22356 4.9861 7.03357 4.81722L2.03359 0.372795C1.68002 0.0585173 1.15455 0.109471 0.859918 0.486605C0.565282 0.863739 0.613052 1.42424 0.966615 1.73852L5.19838 5.50008L0.966614 9.26165Z"
        fill={fill}
      />
    </svg>
  );
};

export default ArrowRight;
