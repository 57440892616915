import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import {
  ClickNavCardNutrition,
  ComponentsLayout,
  CustomButton,
  CustomInput,
  MedicineStock,
  ModalMobile,
  SwitchButton,
  theme,
} from '@hdcorner/ui-library';
import { Box, Typography } from '@mui/material';
import { useIonRouter } from '@ionic/react';
import { useParams } from 'react-router';
import PageLayout from '../../components/PageLayout';
import RescheduleMedicationModal from './components/RescheduleMedicationModal';
import moment from 'moment';
import MedicationDeleteModal from './components/MedicationDeleteModal';
import ActionButtonsSmall from './components/ActionButtonsSmall';
import BannerReschedule from './components/BannerReschedule';
import {
  useCreateMedicineStockMutation,
  useDeleteAllUnTakenMedicationMutation,
  useDeleteSingleMedicationMutation,
  useEditMedicineStockMutation,
  useGetMedicationByIdQuery,
  useGetMedicineStockQuery,
  useRescheduleMedicationMutation,
  useTakeMedicationMutation,
} from './queries/medicationQueries';
import MedicationHeaderIcon from '../../assets/icons/MedicationHeaderIcon';
import useAlert from '../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

const MedicationDrug = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { presentError, presentSuccess } = useAlert();
  const { medicationId } = useParams<{ medicationId: string }>();

  const [stockAmount, setStockAmount] = useState('');
  const [stockThreshold, setStockThreshold] = useState('');

  const [refill, setRefill] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [rescheduleModalOpen, setRescheduleModalOpen] = useState(false);

  const [takeMedication] = useTakeMedicationMutation();
  const [editMedicineStock] = useEditMedicineStockMutation();
  const [createMedicineStock] = useCreateMedicineStockMutation();
  const [rescheduleMedication] = useRescheduleMedicationMutation();
  const [deleteSingleMedication] = useDeleteSingleMedicationMutation();
  const [deleteAllUnTakenMeds] = useDeleteAllUnTakenMedicationMutation();

  const { data: medicationRes, error: errorMedication } = useGetMedicationByIdQuery(
    { medicationId },
    {
      skip: !medicationId,
    },
  );

  const medication = useMemo(() => {
    if (!medicationRes || medicationRes.length === 0) return null;
    return medicationRes[0];
  }, [medicationRes]);

  const { data: medicineStockRes, error: errorMedStock } = useGetMedicineStockQuery(
    { medicineId: medication?.medicine._id || '' },
    {
      skip: !medication || !medication.medicine._id,
    },
  );

  /** Return content packaging */
  const medContent = useMemo(() => {
    if (!medication) return;
    return medication.medicine;
  }, [medication]);

  useEffect(() => {
    if (errorMedication) presentError(t('errors.medication.errorFetchMeds'));
  }, [errorMedication, errorMedStock]);

  const medicineStock = useMemo<MedicineStock | null>(() => {
    if (!medicineStockRes || medicineStockRes.length === 0) return null;
    return medicineStockRes[0];
  }, [medicineStockRes]);

  useEffect(() => {
    if (medicineStock) {
      setStockAmount(medicineStock.amount ? medicineStock.amount.toString() : '');
      setStockThreshold(
        medicineStock.threshold ? medicineStock.threshold.toString() : '',
      );

      if (medicineStock.threshold) {
        setRefill(true);
      }
    }
  }, [medicineStock]);

  const handleShowMedicationInstructions = () => {
    router.push(`/dashboard/medication-instructions/${medicationId}`);
  };

  const updateStock = () => {
    if (medicineStock) {
      editMedicineStock({
        ...medicineStock,
        amount: parseInt(stockAmount),
        threshold: refill ? parseInt(stockThreshold) : undefined,
      })
        .unwrap()
        .then(() => {
          presentSuccess(t('errors.medication.successEditMedStock'));
          router.push('/dashboard/medication', 'back', 'pop');
        })
        .catch(() => presentError(t('errors.medication.errorEditMedStock')));
    } else {
      if (!medication) return;
      createMedicineStock({
        amount: parseInt(stockAmount),
        medicine: medication.medicine._id,
        threshold: refill ? parseInt(stockThreshold) : undefined,
      })
        .unwrap()
        .then(() => {
          router.push('/dashboard/medication', 'back', 'pop');
          presentSuccess(t('errors.medication.successAddMedStock'));
        })
        .catch(() => presentError(t('errors.medication.errorAddStock')));
    }
  };

  const handleTakenClick = () => {
    if (!medication) return;
    takeMedication(medication._id);
  };

  const handleDeleteAll = () => {
    if (!medication) return;
    const id = medication.medicine._id;
    deleteAllUnTakenMeds({ id: id ? id : '' })
      .unwrap()
      .then(() => {
        router.push('/dashboard/medication');
      });
  };

  const handleDeleteEntry = () => {
    if (!medication) return;
    const medicationId = medication._id;
    deleteSingleMedication({ medicationId })
      .unwrap()
      .then(() => {
        router.push('/dashboard/medication');
      });
  };

  const saveReschedule = (rescheduleTo: string) => {
    if (!medication) return;
    rescheduleMedication({ medicationId: medication._id, rescheduleTo })
      .unwrap()
      .then(() => {
        setRescheduleModalOpen(false);
      });
  };

  const rescheduleBanner = useMemo(() => {
    if (!medication) return null;

    if (medication.rescheduledTo) {
      return (
        <BannerReschedule
          time={t('general.labels.to')}
          date={moment(medication.rescheduledTo).format('DD/MM/YYYY')}
        />
      );
    }

    if (medication.rescheduledFrom) {
      return (
        <BannerReschedule
          time={t('general.labels.from')}
          date={moment(medication.rescheduledFrom).format('DD/MM/YYYY')}
        />
      );
    }
  }, [medication]);

  const renderActionButtons = useMemo(() => {
    if (!medication) return null;
    return (
      <ActionButtonsSmall
        taken={medication.taken}
        onClickTake={handleTakenClick}
        rescheduledTo={!!medication.rescheduledTo}
        rescheduledFrom={!!medication.rescheduledFrom}
        onClickReschedule={() => setRescheduleModalOpen(true)}
      />
    );
  }, [medication]);

  return (
    <PageLayout
      defaultHref={'/dashboard/medication'}
      headerColor={theme.palette.primary.main}
      headerTitle={medication ? medication.medicine.medicineName : '-'}
    >
      <ComponentsLayout display={'flex'} flexDirection={'column'} gap={theme.spacing(2)}>
        <Box display={'flex'} flexDirection={'column'} gap={theme.spacing(3)}>
          {rescheduleBanner}
          {renderActionButtons}
          {medication?.medicine.substanceUrl && (
            <Box
              px={2}
              py={1}
              borderRadius={1}
              sx={{
                backgroundColor: 'white',
                paddingInline: theme.spacing(2),
              }}
            >
              <MedicationHeaderIcon
                width={'16px'}
                height={'16px'}
                color={theme.palette.medication.main}
              />
              <Typography
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
                variant={'subtitle2'}
                color={theme.palette.medication.main}
                fontWeight={theme.typography.fontWeightRegular}
              >
                {medication.medicine.substanceUrl}
              </Typography>
            </Box>
          )}

          <Box>
            <Typography
              variant={'subtitle2'}
              color={theme.palette.primary.main}
              marginBottom={theme.spacing(1)}
              fontWeight={theme.typography.fontWeightRegular}
            >
              {t('general.labels.info')}
            </Typography>
            <ClickNavCardNutrition
              headings={t('general.labels.content')}
              body={medContent ? medContent.content : `${t('general.labels.nA')}`}
            />
            <ClickNavCardNutrition
              headings={t('general.labels.type')}
              body={medContent ? medContent.medicineType : `${t('general.labels.nA')}`}
            />
            <ClickNavCardNutrition
              headings={t('buttons.navigation.package')}
              body={medContent ? medContent.package : `${t('general.labels.nA')}`}
            />
            <ClickNavCardNutrition
              onClick={handleShowMedicationInstructions}
              headings={t('buttons.navigation.medInstructions')}
            />
          </Box>
          <Box>
            <Typography
              variant={'subtitle2'}
              color={theme.palette.primary.main}
              marginBottom={theme.spacing(1)}
              fontWeight={theme.typography.fontWeightRegular}
            >
              {t('medication.labels.medicineStock')}
            </Typography>
            <Box display={'flex'} flexDirection={'column'} gap={theme.spacing(1)}>
              <CustomInput
                fullWidth
                type={'number'}
                value={stockAmount}
                alternativeBackground
                placeholder={'Enter Amount'}
                readOnly={medication?.taken}
                handleChange={setStockAmount}
              />
              <Box
                sx={{
                  height: '35px',
                  display: 'flex',
                  borderRadius: '8px',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingInline: theme.spacing(2),
                  backgroundColor: theme.palette.secondary.light,
                }}
              >
                <Typography
                  variant={'subtitle2'}
                  color={theme.palette.primary.main}
                  fontWeight={theme.typography.fontWeightRegular}
                >
                  {t('medication.labels.refillAmount')}
                </Typography>
                <SwitchButton
                  checked={refill}
                  setChecked={setRefill}
                  disabled={medication?.taken}
                />
              </Box>
              {refill && (
                <Box>
                  <Typography
                    variant={'subtitle2'}
                    color={theme.palette.primary.main}
                    marginBottom={theme.spacing(1)}
                    fontWeight={theme.typography.fontWeightRegular}
                  >
                    {t('medication.labels.reminderThreshold')}
                  </Typography>
                  <CustomInput
                    fullWidth
                    type={'number'}
                    alternativeBackground
                    value={stockThreshold}
                    readOnly={medication?.taken}
                    handleChange={setStockThreshold}
                    placeholder={`${t('medication.labels.lowerThresh')}`}
                  />
                </Box>
              )}
            </Box>
          </Box>
          <Box display={'flex'} gap={theme.spacing(3)}>
            <CustomButton
              fullWidth
              disableElevation
              color={'primary'}
              variant={'contained'}
              onClick={updateStock}
              disabled={medication?.taken}
            >
              {t('buttons.update')}
            </CustomButton>
            <CustomButton
              fullWidth
              color={'error'}
              disableElevation
              variant={'contained'}
              disabled={medication?.taken}
              onClick={() => setDeleteModalOpen(true)}
            >
              {t('buttons.delete')}
            </CustomButton>
          </Box>
        </Box>
      </ComponentsLayout>
      {/*Reschedule Modal*/}
      <ModalMobile
        open={rescheduleModalOpen}
        setOpen={() => setRescheduleModalOpen(false)}
      >
        <RescheduleMedicationModal
          saveChanges={saveReschedule}
          initialDate={medication?.time}
        />
      </ModalMobile>
      {/*Delete Modal*/}
      <ModalMobile open={deleteModalOpen} setOpen={setDeleteModalOpen}>
        <MedicationDeleteModal
          deleteAll={handleDeleteAll}
          deleteEntry={handleDeleteEntry}
          setModalOpen={setDeleteModalOpen}
        />
      </ModalMobile>
    </PageLayout>
  );
};

export default MedicationDrug;
