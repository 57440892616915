import {
  AddIconRounded,
  BloodGlucoseLog,
  CvdRiskLog,
  CVDRiskSQ,
  DiabetesSQ,
  ExerciseSQ,
  Filters,
  HypertensionLog,
  HypertensionSQ,
  LipidLog,
  LipidSQ,
  MedicationSQ,
  ModalCholesterolData,
  ModalDiabetes,
  ModalHeartRiskEU,
  ModalHypertension,
  ModalMobile,
  NutritionSQ,
  OptionType,
} from '@hdcorner/ui-library';
import { IonFab, IonFabButton, IonFabList, useIonRouter } from '@ionic/react';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { useMemo, useState } from 'react';
import AddMedicationModalWeb from '../features/Medication/components/AddMedicationModalWeb';
import DiabetesModalWeb from '../features/diabetes/DiabetesModalWeb';
import HeartRiskModalWeb from '../features/cvdRisk/components/HeartRiskModalWeb';
import HypertensionModalWeb from '../features/hypertension/components/HypertensionModalWeb';
import LipidModalWeb from '../features/lipid/components/LipidModalWeb';
import useGetUserData from '../features/cvdRisk/hooks/useGetUserData';
import { useAddBloodGlucoseLogMutation } from '../features/diabetes/queries/diabetesQueries';
import { useAddHypertensionLogMutation } from '../features/hypertension/queries/hypertensionQueries';
import { useAddCvdRiskLogMutation } from '../features/cvdRisk/queries/cvdRiskQueries';
import { useAddLipidLogMutation } from '../features/lipid/queries/lipidQueries';
import useAlert from '../hooks/useAlert';
import NotificationAddIcon from '../assets/icons/NotificationAddIcon';
import ModalSetReminderComponent from './ModalSetReminderComponent';
import moment from 'moment/moment';
import { useAddNewReminderMutation } from '../features/reminders/queries/remindersQueries';
import ModalActivityContent from '../features/fitness/exercise/components/ModalActivityContent';
import SelectMealModal from '../features/fitness/nutrition/SelectMealModal';
import ModalFoodMealsWeb from '../features/fitness/nutrition/components/ModalFoodMealsWeb';
import {
  cleanSlate,
  deleteFoodArray,
  updateCategory,
} from '../features/fitness/nutrition/slices/mealFoodsSlice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { Camera, CameraResultType } from '@capacitor/camera';
import { useTranslation } from 'react-i18next';
import { cameraPermissions } from '../utils/cameraPermissions';

const options: OptionType[] = [
  { name: 'headingsTitles.nutrition', icon: NutritionSQ, value: 'nutrition' },
  {
    name: 'dashboard.dashcards.medication.title',
    icon: MedicationSQ,
    value: 'medication',
  },
  { name: 'headingsTitles.lipid', icon: LipidSQ, value: 'lipid' },
  { name: 'userDetails.hypertension', icon: HypertensionSQ, value: 'hypertension' },
  { name: 'userDetails.diabetes', icon: DiabetesSQ, value: 'diabetes' },
  { name: 'fitness.exercise.labels.activity', icon: ExerciseSQ, value: 'activity' },
  { name: 'dashboard.dashcards.cvd.title', icon: CVDRiskSQ, value: 'cvd' },
  { name: 'buttons.addReminder', icon: NotificationAddIcon, value: 'reminder' },
];

const MainFabButton = styled(IonFabButton, {
  shouldForwardProp: prop => prop !== 'smallScreen',
})<{ smallScreen: boolean }>(({ theme, smallScreen }) => ({
  marginBottom: '18px',
  '--box-shadow': 'none',
  '--border-style': 'solid',
  '--border-width': smallScreen ? '2px' : '0',
  '--border-color': theme.palette.common.white,
}));

const FabList = styled(IonFabList, {
  shouldForwardProp: prop => prop !== 'smallScreen' && prop !== 'lang',
})<{ lang: string; smallScreen: boolean }>(({ lang, smallScreen }) => ({
  top: smallScreen ? '-115%' : 'unset',
  margin: smallScreen ? '62px 0' : '80px 0',
  flexDirection: smallScreen ? 'row' : 'column-reverse',
  transform: smallScreen ? 'translate(-50%, -105%)' : 'none',
  left: lang === 'el' && !smallScreen ? '-150px' : smallScreen ? '50%' : '-130px',
}));

const FabListButton = styled(IonFabButton, {
  shouldForwardProp: prop =>
    prop !== 'smallScreen' && prop !== 'freeUser' && prop !== 'lang',
})<{ lang: string; smallScreen: boolean; freeUser: boolean }>(
  ({ theme, lang, freeUser, smallScreen }) => ({
    margin: smallScreen ? undefined : 0,
    filter: freeUser ? 'blur(3px)' : 'none',
    marginRight: smallScreen ? -1 : undefined,
    width: lang === 'el' && !smallScreen ? '205px' : smallScreen ? undefined : '156px',
    '--box-shadow': 'none',
    '--border-radius': '0',
    '--background': theme.palette.common.white,
    '&:first-of-type': {
      '--border-radius': smallScreen ? '50% 0 0 50%' : '0 0 8px 8px',
    },
    '&:last-child': {
      marginRight: smallScreen ? 0 : undefined,
      '--border-radius': smallScreen ? '0 50% 50% 0' : '8px 8px 0 0',
    },
  }),
);

const FabBottomTabs = () => {
  const theme = useTheme();
  const router = useIonRouter();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const web = useMediaQuery(theme.breakpoints.up('sm'));
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { presentSuccess, presentError } = useAlert();

  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const {
    data: { user },
  } = useAppSelector(state => state.auth);

  const [isLoadingDiabetesOcr, setLoadingDiabetesOcr] = useState<boolean>(false);
  const [isLoadingHypertensionOcr, setLoadingHypertensionOcr] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [openMeal, setOpenMeal] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<OptionType['value']>();
  const [mealCategory, setMealCategory] = useState<string>('');
  const [inputError, setInputError] = useState({ value: false, message: '' });

  const useDetails = useGetUserData();

  const [addLipidLog] = useAddLipidLogMutation();
  const [addCvdRiskLog] = useAddCvdRiskLogMutation();
  const [addNewReminder] = useAddNewReminderMutation();
  const [addBloodGlucoseLog] = useAddBloodGlucoseLogMutation();
  const [addHypertensionLog] = useAddHypertensionLogMutation();

  const premiumUser = useMemo(() => {
    if (!user || !user.premiumUser) return false;
    return user.premiumUser;
  }, [user]);

  const handleClickTab = (option: string) => {
    switch (option) {
      case 'Medication':
        if (smallScreen) {
          router.push('/dashboard/medication-add');
          return;
        } else {
          setSelectedType(option);
          setModalOpen(true);
          return;
        }
      default:
        setSelectedType(option);
        setModalOpen(true);
        return;
    }
  };

  const handleDismissModal = () => {
    setSelectedType(undefined);
    setModalOpen(false);
  };

  const handleSaveDiabetes = (data: Partial<BloodGlucoseLog>) => {
    const log = {
      logDate: data.logDate,
      measurement: data.measurement,
      category: data.category === Filters.DEFAULT ? undefined : data.category,
    };

    if (!log.measurement) {
      presentError(t('diabetes.inputValidation.glucose'));
      return;
    }

    if (log.measurement < 30 || log.measurement > 600) {
      setInputError({
        value: true,
        message: t('diabetes.inputValidation.glucose'),
      });
    } else {
      addBloodGlucoseLog(log)
        .unwrap()
        .then(() => presentSuccess(t('errors.diabetes.errorsGlucose.successAddLog')))
        .catch(() => presentError(t('errors.diabetes.errorsGlucose.addLog')))
        .finally(() => {
          setSelectedType(undefined);
          setModalOpen(false);
          setInputError({
            value: false,
            message: '',
          });
        });
    }
  };

  const handleSaveHypertension = (data: Partial<HypertensionLog>) => {
    let { afib, diastolic, systolic, pulses, category, logDate } = data;

    addHypertensionLog({ afib, systolic, diastolic, pulses, category, logDate })
      .unwrap()
      .then(() => presentSuccess(t('errors.hypertension.successAddLog')))
      .catch(() => presentError(t('errors.subscription.errorAddLog')))
      .finally(() => {
        setSelectedType(undefined);
        setModalOpen(false);
      });
  };

  const handleSaveCvd = (data: Partial<CvdRiskLog>) => {
    const dataForRequest = {
      logDate: data.logDate,
      totalChol: data.totalChol,
      sysBloodPressure: data.sysBloodPressure,
    };

    addCvdRiskLog({
      ...dataForRequest,
    })
      .unwrap()
      .then(() => presentSuccess(t('errors.cvd.logAdded')))
      .catch(() => presentError(t('errors.cvd.errorAddLog')))
      .finally(() => {
        setSelectedType(undefined);
        setModalOpen(false);
      });
  };

  const handleSaveLipid = (data: Omit<LipidLog, '_id'>) => {
    let { totalChol, triglycerides, hdl, ldl, lpa, logDate } = data;

    addLipidLog({ totalChol, triglycerides, hdl, ldl, lpa, logDate })
      .unwrap()
      .then(() => presentSuccess(t('errors.lipid.successLogAdd')))
      .catch(() => presentError(t('errors.lipid.errorLogAdd')))
      .finally(() => {
        setSelectedType(undefined);
        setModalOpen(false);
      });
  };

  const handleCreateReminder = (data: any) => {
    const newDate = moment(data.date);
    const splitTime = data.time.split(':');
    newDate.hour(parseInt(splitTime[0]));
    newDate.minutes(parseInt(splitTime[1]));
    newDate.seconds(0);
    newDate.milliseconds(0);

    addNewReminder({
      info: data.info,
      title: data.title,
      allDay: data.allDay,
      category: data.category,
      reminderDateTime: newDate.toISOString(),
    })
      .unwrap()
      .then(() => presentSuccess(t('errors.reminders.successAdd')))
      .catch(() => {
        presentError(t('errors.reminders.errorAdd'));
      })
      .finally(() => {
        handleDismissModal();
      });
  };

  const handleFoodsModal = (value: string) => {
    handleDismissModal();
    dispatch(cleanSlate());
    setMealCategory(value);
    dispatch(deleteFoodArray());
    dispatch(updateCategory(value));
    if (web) {
      setOpenMeal(true);
    } else {
      router.push(`/dashboard/fitness/food-meals/${value}`);
    }
  };

  const handleHypertensionOcr = async (
    cb: (newMeasurement: { systolic: string; diastolic: string; pulses: string }) => void,
  ) => {
    const cameraSource = await cameraPermissions();
    if (!cameraSource) {
      presentError(t('errors.devicePermissions.camera'));
      return;
    }
    const image = await Camera.getPhoto({
      quality: 90,
      source: cameraSource,
      resultType: CameraResultType.Base64,
    });

    setLoadingHypertensionOcr(true);
    await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer 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',
      },
      body: JSON.stringify({
        max_tokens: 300,
        model: 'gpt-4-vision-preview',
        messages: [
          {
            role: 'user',
            content: [
              {
                type: 'text',
                text: 'Read the text on the device screen resembling the following: 140 mmHg 104 mmHg 87 pul/min and return just the number measurements',
              },
              {
                type: 'image_url',
                image_url: {
                  url: `data:image/jpeg;base64,${image.base64String}`,
                },
              },
            ],
          },
        ],
      }),
    })
      .then(response => response.json())
      .then(data => {
        setLoadingHypertensionOcr(false);
        if (data.choices.length === 0) {
          presentError(t('errors.generic.errorMeasurement'));
          return;
        }
        if (data.choices[0].message.content === 'No measurement detected') {
          presentError(t('errors.generic.errorMeasurement'));
          return;
        }

        const measurements = data.choices[0].message.content;
        const splitMeasurements = measurements.split(', ');
        const systolic = splitMeasurements[0];
        const diastolic = splitMeasurements[1];
        const pulses = splitMeasurements[2];
        cb({ systolic, diastolic, pulses });
        presentSuccess(t('errors.generic.measurementDetected'));
      })
      .catch(error => {
        setLoadingHypertensionOcr(false);
        presentError(t('errors.generic.errorImage'));
      });
  };

  const handleDiabetesOcr = async (cb: (newMeasurement: string) => void) => {
    const cameraSource = await cameraPermissions();
    if (!cameraSource) {
      presentError(t('errors.devicePermissions.camera'));
      return;
    }
    const image = await Camera.getPhoto({
      quality: 90,
      source: cameraSource,
      resultType: CameraResultType.Base64,
    });

    setLoadingDiabetesOcr(true);
    await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer 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',
      },
      body: JSON.stringify({
        max_tokens: 300,
        model: 'gpt-4-vision-preview',
        messages: [
          {
            role: 'user',
            content: [
              {
                type: 'text',
                text: 'Read the text on the device screen resembling the following: 120 mg/dl and return just the number measurement',
              },
              {
                type: 'image_url',
                image_url: {
                  url: `data:image/jpeg;base64,${image.base64String}`,
                },
              },
            ],
          },
        ],
      }),
    })
      .then(response => response.json())
      .then(data => {
        setLoadingDiabetesOcr(false);
        if (data.choices.length === 0) {
          presentError(t('errors.generic.errorMeasurement'));
          return;
        }
        if (data.choices[0].message.content === 'No measurement detected') {
          presentError(t('errors.generic.errorMeasurement'));
          return;
        }

        const measurement = data.choices[0].message.content;
        cb(measurement);
        presentSuccess(t('errors.generic.measurementDetected'));
      })
      .catch(error => {
        setLoadingDiabetesOcr(false);
        presentError(t('errors.generic.errorImage'));
      });
  };

  const handleWhichModalToShow = () => {
    if (!modalOpen) return null;
    if (!selectedType) {
      return null;
    }

    switch (selectedType) {
      case 'medication':
        return <AddMedicationModalWeb onSaveSuccess={() => handleDismissModal()} />;
      case 'diabetes':
        if (smallScreen) {
          return (
            <ModalDiabetes
              tabView
              error={inputError}
              isBloodGlucose={true}
              isLoading={isLoadingDiabetesOcr}
              triggerOCR={cb => handleDiabetesOcr(cb)}
              handleModalSaveClick={data => handleSaveDiabetes(data)}
            />
          );
        } else {
          return <DiabetesModalWeb onSaveSuccess={() => handleDismissModal()} />;
        }
      case 'hypertension':
        if (smallScreen) {
          return (
            <ModalHypertension
              isLoading={isLoadingHypertensionOcr}
              triggerOCR={cb => handleHypertensionOcr(cb)}
              handleModalSaveClick={data => handleSaveHypertension(data)}
            />
          );
        } else {
          return (
            <HypertensionModalWeb onSaveSuccess={() => setSelectedType(undefined)} />
          );
        }
      case 'cvd':
        if (!smallScreen) {
          return (
            <ModalHeartRiskEU
              userAge={useDetails.age}
              userGender={useDetails.gender}
              handleModalSaveClick={handleSaveCvd}
              userSmoker={useDetails.smoker || false}
            />
          );
        } else {
          return <HeartRiskModalWeb dismiss={() => handleDismissModal()} />;
        }
      case 'lipid':
        if (!smallScreen) {
          return (
            <ModalCholesterolData handleModalSaveClick={data => handleSaveLipid(data)} />
          );
        } else {
          return <LipidModalWeb setUsersDataModalOpen={() => handleDismissModal()} />;
        }
      case 'nutrition':
        return <SelectMealModal handleLogMeal={handleFoodsModal} />;
      case 'activity':
        return (
          <ModalActivityContent
            dashboardModal
            setOpen={value => {
              setModalOpen(value);
              setSelectedType(undefined);
            }}
          />
        );
      case 'reminder':
        return <ModalSetReminderComponent handleSaveModal={handleCreateReminder} />;
    }
  };

  return (
    <>
      <IonFab slot="fixed" vertical="bottom" horizontal={smallScreen ? 'center' : 'end'}>
        <MainFabButton
          color="dark"
          smallScreen={smallScreen}
          size={smallScreen ? 'small' : undefined}
        >
          <AddIconRounded />
        </MainFabButton>
        <FabList lang={lang} smallScreen={smallScreen} side="top">
          {options.map((option, index) => (
            <FabListButton
              key={index}
              lang={lang}
              freeUser={
                (!premiumUser && option.value === 'cvd') ||
                (!premiumUser && option.value === 'medication') ||
                (!premiumUser && option.value === 'activity') ||
                (!premiumUser && option.value === 'nutrition')
              }
              disabled={
                (!premiumUser && option.value === 'cvd') ||
                (!premiumUser && option.value === 'medication') ||
                (!premiumUser && option.value === 'activity') ||
                (!premiumUser && option.value === 'nutrition')
              }
              smallScreen={smallScreen}
              onClick={() => handleClickTab(option.value ? option.value : '')}
            >
              <option.icon
                width={'16px'}
                height={'16px'}
                color={theme.palette.primary.main}
                style={{ marginLeft: theme.spacing(1.25) }}
              />
              {!smallScreen && (
                <Typography
                  variant={'subtitle2'}
                  sx={{
                    flex: 1,
                    textAlign: 'left',
                    color: theme.palette.primary.main,
                    marginLeft: theme.spacing(1.25),
                    fontWeight: theme.typography.fontWeightRegular,
                  }}
                >
                  {t(option.name)}
                </Typography>
              )}
            </FabListButton>
          ))}
        </FabList>
      </IonFab>
      <ModalMobile width={'auto'} open={modalOpen} setOpen={() => handleDismissModal()}>
        {handleWhichModalToShow()}
      </ModalMobile>
      <ModalFoodMealsWeb open={openMeal} category={mealCategory} setOpen={setOpenMeal} />
    </>
  );
};

export default FabBottomTabs;
