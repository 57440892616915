import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
// import supported locales from moment
import 'moment/locale/el';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    supportedLngs: ['en', 'el'],
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  })
  .then(() => {
    moment.locale(i18n.language);
  });

i18n.on('languageChanged', lng => {
  moment.locale(lng, {
    week: {
      dow: 0, // Sunday is the first day of the week.
    },
  });
});

export default i18n;
