import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = ({ fill = '#5FD071', ...rest }: SVGProps<SVGSVGElement>) => (
  <svg
    width={17}
    height={17}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <rect x={0.5} y={0.5} width={16} height={16} rx={8} fill={fill} />
  </svg>
);

export default SvgComponent;
