import { useMemo } from 'react';
import { HypertensionOverall, ToggleDataType } from '@hdcorner/ui-library';
import { useTranslation } from 'react-i18next';

const usePrepareOverallData = (
  overallData: HypertensionOverall | undefined,
): ToggleDataType[] => {
  const { t } = useTranslation();

  return useMemo<ToggleDataType[]>(() => {
    return [
      {
        name: t('hypertension.labels.avgSys'),
        value:
          overallData && overallData.systolic
            ? overallData.systolic.avg.toFixed().toString()
            : '-',
      },
      {
        name: t('hypertension.labels.avgDia'),
        value:
          overallData && overallData.diastolic
            ? overallData.diastolic.avg.toFixed().toString()
            : '-',
      },
      {
        name: t('hypertension.labels.avgPul'),
        value:
          overallData && overallData.pulses
            ? overallData.pulses.avg.toFixed().toString()
            : '-',
      },
    ];
  }, [overallData]);
};

export default usePrepareOverallData;
