import React, { FC } from 'react';
import { CustomButton, ModalHeading, ModalMobile, theme } from '@hdcorner/ui-library';
import { Box, Typography } from '@mui/material';
import CloseIcon from '../assets/icons/CloseIcon';
import Lottie from 'lottie-react';
import animationData from '../assets/lotties/subspromotion.json';
import { useTranslation } from 'react-i18next';

export type ModalSubsPromotionProps = {
  body: string;
  open: boolean;
  heading: string;
  onCtaClick: () => void;
  setOpen: (open: boolean) => void;
};

const ModalSubsPromotion: FC<ModalSubsPromotionProps> = ({
  open,
  body,
  heading,
  setOpen,
  onCtaClick,
}) => {
  const { t } = useTranslation();

  return (
    <ModalMobile open={open} setOpen={setOpen} width={'348px'}>
      <Box padding={2} boxSizing={'border-box'}>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <ModalHeading
            align={'left'}
            title={heading}
            showCalendar={'flex'}
            alternateIcon={<CloseIcon />}
            handleCalendarClick={() => setOpen(false)}
          />
        </Box>

        <Box margin={theme.spacing(0, 'auto', 1, 'auto')}>
          <Lottie animationData={animationData} width={'128px'} height={'128px'} />
        </Box>

        <Typography variant={'body1'} sx={{ color: theme.palette.kmColorsCoolGrey.main }}>
          {body}
        </Typography>

        <Box marginY={1.5} />

        <CustomButton
          fullWidth
          color={'primary'}
          onClick={onCtaClick}
          variant={'contained'}
          children={t('buttons.navigation.goToSub')}
        />
      </Box>
    </ModalMobile>
  );
};

export default ModalSubsPromotion;
