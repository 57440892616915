import { Box, styled, Typography, TypographyProps } from '@mui/material';
import * as React from 'react';
import { CSSProperties, useEffect, useMemo, useState } from 'react';
import useTimeframe from '../../hooks/useTimeframe';
import PageLayout from '../../components/PageLayout';
import {
  CalendarModal,
  ChartTypeButtons,
  ComponentsLayout,
  dayWeekMonthYearCustom,
  FeelsEmpty,
  LineChart,
  LipidLog,
  MeasurementsContainer,
  theme,
  ToggleButtons,
} from '@hdcorner/ui-library';
import InDepthDatePicker from '../../components/InDepthDatePicker';
import LinearGraphIcon from '../../assets/icons/LinearGraphIcon';
import moment from 'moment';
import { useGetLipidGraphDataQuery } from './queries/lipidQueries';
import { useParams } from 'react-router';
import useTitle from './hooks/useTitle';
import useAlert from '../../hooks/useAlert';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../../utils/useConvertJSON';

const icons = [{ name: 'lineChart', icon: LinearGraphIcon }];

const GraphContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderRadius: '8px',
  flexDirection: 'column',
  backgroundColor: 'white',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
}));

const GraphTypography = styled(Typography, {
  shouldForwardProp: propName => propName !== 'dotColor',
})<TypographyProps & { dotColor?: CSSProperties['color'] }>(({ dotColor, theme }) => ({
  position: 'relative',
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightRegular,
  '&::before': {
    top: '50%',
    width: '8px',
    height: '8px',
    left: '-16px',
    content: '" "',
    borderRadius: '50%',
    position: 'absolute',
    transform: 'translateY(-50%)',
    backgroundColor: dotColor ? dotColor : '#222222',
  },
}));

const LipidGraphDataPage = () => {
  const { t } = useTranslation();
  const { presentError } = useAlert();

  const { type: paramType } = useParams<{ type: string }>();

  const title = useTitle(paramType);

  const [calendarModalOpen, setCalendarModalOpen] = useState<boolean>(false);

  //for pressure
  const [iconTogglePressure, setIconTogglePressure] = useState<string>(icons[0].name);
  const [dayTogglePressure, setDayTogglePressure] = useState<string>('all');

  // Used for updating the params for the query
  const [endParam, setEndParam] = useState<string>('');
  const [startParam, setStartParam] = useState<string>('');

  const timeframe = useTimeframe(dayTogglePressure, startParam, endParam);

  const { error, data: lipidGraphData } = useGetLipidGraphDataQuery({
    params: { metric: paramType === 'trg' ? 'triglycerides' : paramType, ...timeframe },
  });

  useEffect(() => {
    if (error) presentError(t('errors.lipid.errorFetchGraph'));
  }, [error]);

  const chartData = useMemo(() => {
    if (!lipidGraphData) return [];

    return lipidGraphData.logs.map((item: LipidLog) => ({
      x: moment(item.logDate).toISOString(),
      y: (item as any)[paramType === 'trg' ? 'triglycerides' : paramType],
    }));
  }, [lipidGraphData, paramType]);

  const handleDateChange = (dateRange: string[]) => {
    setDayTogglePressure('custom');
    setStartParam(dateRange[0]);
    setEndParam(dateRange[1]);
  };

  const filteredToggle = useConvertJSON(
    dayWeekMonthYearCustom.filter(item => item.value !== 'custom'),
  );

  return (
    <PageLayout
      defaultHref={'/dashboard/lipid'}
      headerTitle={t('buttons.navigation.graphData')}
    >
      <ComponentsLayout display={'flex'} flexDirection={'column'} gap={theme.spacing(1)}>
        <MeasurementsContainer>
          <InDepthDatePicker flex={1} onClick={() => setCalendarModalOpen(true)} />
        </MeasurementsContainer>
        <GraphContainer>
          <Box display={'flex'} justifyContent={'space-between'}>
            <ToggleButtons
              value={dayTogglePressure}
              headings={filteredToggle}
              handleChange={setDayTogglePressure}
            />
            <ChartTypeButtons
              icons={icons}
              iconValue={iconTogglePressure}
              setIconValue={setIconTogglePressure}
            />
          </Box>
          {chartData.length > 0 && iconTogglePressure === 'lineChart' && (
            <LineChart
              chartData={[{ name: title, data: chartData }]}
              colors={[theme.palette.error.main, theme.palette.warning.main]}
            />
          )}
          {chartData.length === 0 && <FeelsEmpty />}
          <Box
            display={'flex'}
            alignItems={'center'}
            gap={theme.spacing(3)}
            justifyContent={'center'}
            paddingLeft={theme.spacing(2)}
          >
            <GraphTypography dotColor={theme.palette.warning.main}>
              {title}
            </GraphTypography>
          </Box>
        </GraphContainer>
      </ComponentsLayout>
      <CalendarModal
        open={calendarModalOpen}
        saveDate={handleDateChange}
        setOpen={setCalendarModalOpen}
        selectedDate={[startParam, endParam]}
      />
    </PageLayout>
  );
};

export default LipidGraphDataPage;
