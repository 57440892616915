import React, { FC } from 'react';
import {
  Avatar,
  Box,
  BoxProps,
  IconButton,
  styled,
  Typography,
  TypographyProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CCNameIcon from '../../../assets/icons/CCNameIcon';
import CCPhoneIcon from '../../../assets/icons/CCPhoneIcon';
import CCEmailIcon from '../../../assets/icons/CCEmailIcon';
import CCAddressIcon from '../../../assets/icons/CCAddressIcon';
import CCSpecialityIcon from '../../../assets/icons/CCSpecialityIcon';
import { ArrowLeft, CustomButton, ModalMobile } from '@hdcorner/ui-library';
import { useTranslation } from 'react-i18next';

const MainBox = styled(Box, {
  shouldForwardProp: propName => propName !== 'web',
})<BoxProps & { web: boolean }>(({ web, theme }) => ({
  display: 'flex',
  borderRadius: '8px',
  boxSizing: 'border-box',
  flexDirection: 'column',
  gap: theme.spacing(3),
  width: web ? '480px' : 'none',
  padding: theme.spacing(3),
}));

const DetailsBox = styled(Box, {
  shouldForwardProp: propName => propName !== 'web',
})<BoxProps & { web: boolean }>(({ web, theme }) => ({
  flex: 1,
  display: 'flex',
  borderRadius: '8px',
  boxSizing: 'border-box',
  flexDirection: 'column',
  gap: theme.spacing(1),
  padding: theme.spacing(1),
  border: `1px solid ${theme.palette.secondary.main}`,
}));

const InnerBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const Text = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: 'rgba(34, 34, 34, 0.8)',
  fontSize: theme.typography.subtitle2.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
}));

type Props = {
  open: boolean;
  handleBack: () => void;
  setOpen: (open: boolean) => void;
  handleAddClinicalCoach: () => void;
  data: {
    avatar: string;
    fullName: string;
    speciality: string;
    address: string;
    postCode: string;
    phone: string;
    email: string;
  };
};

const ModalCCDetails: FC<Props> = ({
  open,
  data,
  setOpen,
  handleBack,
  handleAddClinicalCoach,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const web = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <ModalMobile open={open} setOpen={setOpen}>
      <MainBox web={web}>
        <Box flex={1} display={'flex'} position={'relative'} justifyContent={'center'}>
          <Typography
            align={'center'}
            variant={'subtitle2'}
            sx={{ fontWeight: theme.typography.fontWeightBold }}
          >
            {t('healthProf.clinicalCoach.text.ccSelected')}
          </Typography>
          <IconButton
            onClick={handleBack}
            sx={{ top: 0, left: 0, padding: 0, position: 'absolute' }}
          >
            <ArrowLeft />
          </IconButton>
        </Box>

        <Avatar
          src={data.avatar}
          variant={'rounded'}
          alt={`${t('healthProf.clinicalCoach.text.userAvatar', {
            name: data.fullName,
          })}`}
          sx={{
            width: '128px',
            height: '128px',
            margin: '0 auto',
            padding: theme.spacing(0.25),
            border: `1px solid ${theme.palette.secondary.main}`,
          }}
        />

        <DetailsBox web={web}>
          <InnerBox>
            <CCNameIcon />
            <Text>{data.fullName}</Text>
          </InnerBox>

          <InnerBox>
            <CCSpecialityIcon />
            <Text>{data.speciality}</Text>
          </InnerBox>

          <InnerBox>
            <CCAddressIcon />
            <Text>
              {data.address}, {data.postCode}
            </Text>
          </InnerBox>

          <InnerBox>
            <CCPhoneIcon />
            <Text>{data.phone}</Text>
          </InnerBox>

          <InnerBox>
            <CCEmailIcon />
            <Text>{data.email}</Text>
          </InnerBox>
        </DetailsBox>

        <CustomButton
          fullWidth
          variant={'contained'}
          onClick={handleAddClinicalCoach}
          sx={{
            '&:disabled': {
              background: theme.palette.primary.light,
            },
          }}
        >
          {t('buttons.addYourCC')}
        </CustomButton>
      </MainBox>
    </ModalMobile>
  );
};

export default ModalCCDetails;
