import { CalendarIcon, DownloadIcon, theme } from '@hdcorner/ui-library';
import { Box, Typography, useMediaQuery } from '@mui/material';
import React, { FC, useState } from 'react';
import SectionHeading from './SectionHeading';
import DownloadButton from '../../../components/DownloadButton';
import ModalInDepthDataDL from '../../../components/ModalInDepthDataDL';
import ButtonCustomDate from '../../../components/ButtonCustomDate';
import { useTranslation } from 'react-i18next';

type Props = {
  color?: string;
  handleDate?: () => void;
  module?:
    | 'cvd'
    | 'hbac1'
    | 'sleep'
    | 'steps'
    | 'lipid'
    | 'weight'
    | 'calories'
    | 'activities'
    | 'hypertension'
    | 'blood_glucose';
};

const InDepthHeading: FC<Props> = ({ color, handleDate, module }) => {
  const web = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useTranslation();

  const [open, setOpen] = useState<boolean>(false);

  return (
    <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
      {web ? (
        <SectionHeading hasGoal={false} heading={t('headingsTitles.inDepth')} />
      ) : (
        <Typography variant={'h1'}>{t('headingsTitles.latestUpdates')}</Typography>
      )}
      <Box gap={1} display={'flex'}>
        <ButtonCustomDate
          web={web}
          disableRipple
          textColor={color}
          onClick={handleDate}
          sx={{ display: color ? 'flex' : 'none' }}
          startIcon={<CalendarIcon color={color} />}
        >
          {web ? t('buttons.custom') : t('buttons.customDate')}
        </ButtonCustomDate>
        <Box display={web ? 'flex' : 'none'}>
          <DownloadButton
            web={true}
            variant={'contained'}
            onClick={() => setOpen(true)}
            startIcon={
              <DownloadIcon
                width={'14px'}
                height={'15px'}
                color={theme.palette.secondary.main}
              />
            }
          >
            {t('buttons.dlData')}
          </DownloadButton>
        </Box>
      </Box>
      <ModalInDepthDataDL
        open={open}
        module={module || 'steps'}
        dismiss={() => setOpen(false)}
      />
    </Box>
  );
};

export default InDepthHeading;
