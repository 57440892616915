import * as React from 'react';
import { SVGProps } from 'react';
import { theme } from '@hdcorner/ui-library';

const BreakfastIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 14}
      height={props.height ? props.height : 14}
      viewBox={props.viewBox ? props.viewBox : '0 0 14 14'}
      {...props}
    >
      <path
        d="M6.0233 11.4146C6.80116 11.6143 8.3996 11.9349 9.19922 11.8009C9.36886 11.7724 9.47206 11.6307 9.49922 11.4607L9.51003 11.393M6.0233 11.4146L7.69323 8.01721M6.0233 11.4146C5.94411 11.5757 5.77529 11.6717 5.58418 11.6285C4.65777 11.4194 3.67806 10.628 3.27201 10.222C2.86596 9.81594 2.07998 8.84114 1.87083 7.91479C1.82768 7.72368 1.92369 7.55487 2.0848 7.47569M7.69323 8.01721L7.78395 7.83265C7.9375 7.52027 7.74561 7.09644 7.41688 6.87649C7.26504 6.7749 7.10703 6.65087 6.97517 6.51902C6.84331 6.38716 6.72473 6.23401 6.62314 6.08219C6.40318 5.75348 5.97932 5.56159 5.66692 5.71514L5.48235 5.80585M7.69323 8.01721C8.05282 8.37678 8.71591 8.6684 9.26117 8.85534C9.59771 8.97073 9.84183 9.31661 9.78946 9.64433L9.78414 9.67764M9.51003 11.393L9.78414 9.67764M9.51003 11.393C10.1599 11.37 11.3692 11.1524 12.1868 10.5154C12.543 10.238 12.1966 9.73524 11.7177 9.75663C10.9675 9.79014 10.1545 9.78927 9.78414 9.67764M9.78414 9.67764C9.76185 9.67093 9.74116 9.66381 9.72219 9.65627M2.0848 7.47569C1.88506 6.69787 1.56441 5.09952 1.69847 4.29995C1.72691 4.13032 1.86868 4.02713 2.03871 3.99996L2.10633 3.98916M2.0848 7.47569L5.48235 5.80585M5.48235 5.80585C5.12276 5.44628 4.83113 4.78323 4.64418 4.238C4.52879 3.90148 4.18288 3.65738 3.85514 3.70974L3.82182 3.71506M2.10633 3.98916L3.82182 3.71506M2.10633 3.98916C2.1294 3.33932 2.34702 2.13013 2.98398 1.31251C3.2614 0.956402 3.76423 1.30278 3.74283 1.78161C3.70932 2.53176 3.7102 3.34474 3.82182 3.71506M3.82182 3.71506C3.82854 3.73735 3.83566 3.75804 3.8432 3.77701"
        strokeWidth="1.4"
        stroke={props.stroke ? props.stroke : theme.palette.hypertension.main}
      />
    </svg>
  );
};

export default BreakfastIcon;
