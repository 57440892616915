import { Box } from '@mui/material';
import React, { FC } from 'react';
import CustomButton from './controls/CustomButton';
import { useTranslation } from 'react-i18next';

type Props = {
  type?: 'add' | 'edit';
  handleAdd?: () => void;
  handleEdit?: () => void;
  handleRemove?: () => void;
};
const GoalActionButtons: FC<Props> = ({
  type = 'edit',
  handleAdd,
  handleEdit,
  handleRemove,
}) => {
  const { t } = useTranslation();

  return (
    <Box display={'flex'} gap={1}>
      {type === 'add' ? (
        <CustomButton variant={'contained'} onClick={handleAdd} sx={{ padding: '8px' }}>
          {t('buttons.add')}
        </CustomButton>
      ) : (
        <>
          <CustomButton
            variant={'contained'}
            onClick={handleEdit}
            sx={{ padding: '8px' }}
          >
            {t('buttons.edit')}
          </CustomButton>
          <CustomButton
            variant={'outlined'}
            onClick={handleRemove}
            sx={{ padding: '8px' }}
          >
            {t('buttons.delete')}
          </CustomButton>
        </>
      )}
    </Box>
  );
};

export default GoalActionButtons;
