import {
  useGetHbac1CurrentMeasurementQuery,
  useGetHbac1StartingMeasurementQuery,
  useGetHbacGoalQuery,
} from '../../diabetes/queries/diabetesQueries';
import { useEffect, useMemo, useState } from 'react';
import { goalProgress } from '../../../utils/goalProgress';

const useGetHbacGoalData = () => {
  const [startingDate, setStartingDate] = useState<string>();

  const { data: hbacGoal } = useGetHbacGoalQuery();
  const { data: hbacCurrent } = useGetHbac1CurrentMeasurementQuery();
  const { data: hbacStarting } = useGetHbac1StartingMeasurementQuery(
    {
      startDate: startingDate || '',
    },
    { skip: !startingDate },
  );

  useEffect(() => {
    if (hbacGoal && hbacGoal.length > 0) {
      setStartingDate(hbacGoal[0].startDate);
    }
  }, [hbacGoal]);

  return useMemo(() => {
    if (!hbacGoal || hbacGoal.length === 0) return null;

    let goalValue;
    let startValue;
    let currentValue;
    let progress = 0;

    if (hbacGoal && hbacGoal.length > 0) {
      goalValue = hbacGoal[0].goal;
    }

    if (hbacStarting && hbacStarting.documents.length > 0) {
      startValue = hbacStarting.documents[0].measurement;
    }

    if (hbacCurrent && hbacCurrent.documents.length > 0) {
      currentValue = hbacCurrent.documents[0].measurement;
      startValue = startValue || currentValue;
    }

    if (goalValue && startValue && currentValue) {
      progress = goalProgress(currentValue, goalValue, startValue);
    }

    return { progress, goalValue, startValue, currentValue };
  }, [hbacGoal, hbacStarting, hbacCurrent]);
};

export default useGetHbacGoalData;
