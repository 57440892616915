import {
  CalendarModal,
  ComponentsLayout,
  CVDRiskSQ,
  dataGraphs,
  DiabetesSQ,
  fitnessHeadings,
  FitnessHeartIcon,
  HypertensionSQ,
  LipidSQ,
  MedicationSQ,
  ModalMobile,
  OptionType,
  weekMonthYearCustom,
} from '@hdcorner/ui-library';
import { useIonRouter } from '@ionic/react';
import { Box, useTheme } from '@mui/material';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PageLayout from '../../components/PageLayout';
import GraphView from './components/GraphView';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  setCustomDateFilter,
  setDateFilter,
  setPromotion,
} from '../../redux/slices/appSlice';
import UserDataMedication from '../Medication/UserDataMedication';
import UserDataToolbar from './components/UserDataToolbar';
import AddMedicationModalWeb from '../Medication/components/AddMedicationModalWeb';
import UserDataCvd from '../cvdRisk/CvdRiskWeb';
import DiabetesWeb from '../diabetes/DiabetesWeb';
import WebFitnessOverview from '../fitness/WebFitnessOverview';
import WebCalories from '../fitness/calories/components/WebCalories';
import WebExercise from '../fitness/exercise/components/WebExercise';
import WebWorkouts from '../fitness/workouts/components/WebWorkouts';
import WebNutrition from '../fitness/nutrition/WebNutrition';
import WebWellness from '../fitness/wellness/components/WebWellness';
import UserDataLipid from '../lipid/UserDataLipid';
import DiabetesModalWeb from '../diabetes/DiabetesModalWeb';
import HeartRiskModalWeb from '../cvdRisk/components/HeartRiskModalWeb';
import HypertensionModalWeb from '../hypertension/components/HypertensionModalWeb';
import LipidModalWeb from '../lipid/components/LipidModalWeb';
import FABFitness from '../fitness/components/FABFitness';
import UserDataHypertension from '../hypertension/UserDataHypertension';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../../utils/useConvertJSON';

const options: OptionType[] = [
  { name: 'dashboard.dashcards.fitness.title', value: 'Fitness', icon: FitnessHeartIcon },
  {
    name: 'dashboard.dashcards.medication.title',
    value: 'Medication',
    icon: MedicationSQ,
  },
  { name: 'headingsTitles.lipid', value: 'Lipid', icon: LipidSQ },
  { name: 'userDetails.hypertension', value: 'Hypertension', icon: HypertensionSQ },
  { name: 'userDetails.diabetes', value: 'Diabetes', icon: DiabetesSQ },
  { name: 'dashboard.dashcards.cvd.title', value: 'CVD', icon: CVDRiskSQ },
];

const screensWithToggle: string[] = ['Diabetes', 'Hypertension', 'Lipid', 'Fitness'];

const UsersData = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const router = useIonRouter();
  const { t } = useTranslation();

  const { dateFilter, customDateFilter } = useAppSelector(state => state.layout);

  const {
    data: { user },
  } = useAppSelector(state => state.auth);

  const [customEndDate, setCustomEndDate] = useState<string>('');
  const [customStartDate, setCustomStartDate] = useState<string>('');
  const [calendarModal, setCalendarModal] = useState<boolean>(false);
  const [usersDataModalOpen, setUsersDataModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (customDateFilter) {
      setCustomEndDate(customDateFilter.end);
      setCustomStartDate(customDateFilter.start);
    }
  }, [customDateFilter]);

  const onDateFilterChange = (newFilter: string) => {
    if (newFilter === 'custom date' || newFilter === 'custom') {
      setCalendarModal(true);
    }
    dispatch(setDateFilter(newFilter));
  };

  const selectedType = useMemo(() => {
    return (
      options.find(
        item => item.value?.toLowerCase() === router.routeInfo.pathname.split('/')?.[2],
      ) ?? options[0]
    );
  }, [router.routeInfo.pathname]);

  const goToAvatarRef = useRef<boolean>(false);
  const [toggleDataGraphs, setToggleDataGraphs] = useState(
    selectedType.value?.toLowerCase() === 'fitness' ? 'overview' : 'data',
  );

  const SwitchPage = useMemo(() => {
    switch (selectedType.value?.toLowerCase()) {
      case 'medication':
        return <UserDataMedication />;
      case 'diabetes':
        return <DiabetesWeb />;
      case 'hypertension':
        return <UserDataHypertension />;
      case 'fitness':
        return (
          <WebFitnessOverview
            webHandleAvatar={() => {}}
            webHandleWorkout={() => {
              setToggleDataGraphs('workouts');
            }}
          />
        );
      case 'cvd':
        return <UserDataCvd />;
      case 'lipid':
        return <UserDataLipid />;
      default:
        return;
    }
  }, [selectedType.value]);

  const handleWhichModalToShow = useMemo(() => {
    switch (selectedType.value?.toLowerCase()) {
      case 'medication':
        return (
          <AddMedicationModalWeb onSaveSuccess={() => setUsersDataModalOpen(false)} />
        );
      case 'diabetes':
        return <DiabetesModalWeb onSaveSuccess={() => setUsersDataModalOpen(false)} />;
      case 'hypertension':
        return (
          <HypertensionModalWeb onSaveSuccess={() => setUsersDataModalOpen(false)} />
        );
      case 'cvd':
        return <HeartRiskModalWeb dismiss={() => setUsersDataModalOpen(false)} />;
      case 'lipid':
        return (
          <LipidModalWeb setUsersDataModalOpen={() => setUsersDataModalOpen(false)} />
        );
    }
  }, [selectedType.value]);

  const handleClickFabButton = () => {
    setUsersDataModalOpen(true);
  };

  const handleGraphView = useMemo(() => {
    return (
      <GraphView
        selectedType={selectedType.value ? selectedType.value?.toLowerCase() : ''}
      />
    );
  }, [selectedType]);

  /** variables defined for toggles to resolve TS errors */
  const convertedDataToggle = useConvertJSON(dataGraphs);
  const convertedDateToggle = useConvertJSON(weekMonthYearCustom);
  const convertedFitnessToggle = useConvertJSON(fitnessHeadings);

  const prepareSegmentOptions = useMemo<{ label: string; value: string }[] | []>(() => {
    switch (selectedType.value?.toLowerCase()) {
      case 'hypertension':
      case 'diabetes':
      case 'lipid':
        return convertedDataToggle;
      case 'fitness':
        return convertedFitnessToggle;
      default:
        return [];
    }
  }, [selectedType]);

  const prepareFilterOptions = useMemo<{ label: string; value: string }[] | []>(() => {
    switch (selectedType.value?.toLowerCase()) {
      case 'medication':
        return [];
      case 'fitness':
        return [];
      default:
        return convertedDateToggle;
    }
  }, [selectedType]);

  const handleShowContent = useCallback(() => {
    switch (toggleDataGraphs) {
      case 'data':
        return SwitchPage;
      case 'graphs':
        return handleGraphView;
      case 'overview':
        return (
          <WebFitnessOverview
            webHandleAvatar={() => {
              goToAvatarRef.current = true;
              setToggleDataGraphs('workouts');
            }}
            webHandleWorkout={() => {
              setToggleDataGraphs('workouts');
            }}
          />
        );
      case 'calories':
        return <WebCalories />;
      case 'nutrition':
        return <WebNutrition />;
      case 'exercise':
        return <WebExercise />;
      case 'wellness':
        return <WebWellness />;
      case 'workouts':
        return <WebWorkouts goToAvatar={goToAvatarRef.current} />;
    }
  }, [SwitchPage, handleGraphView, toggleDataGraphs]);

  const handleSetDropdown = (val: OptionType) => {
    const option = val.value?.toLowerCase();
    if (!user?.premiumUser) {
      if (option === 'fitness' || option === 'medication' || option === 'cvd') {
        dispatch(
          setPromotion({
            open: true,
            heading: t('premium.title'),
            body: t('premium.message'),
          }),
        );
        return;
      }
      router.push(`/dashboard/${option}`, 'forward', 'push');
    } else {
      router.push(`/dashboard/${option}`, 'forward', 'push');
    }
  };

  const handleToggleDataGraphs = useMemo(() => {
    return screensWithToggle.includes(selectedType.value ? selectedType.value : '');
  }, [selectedType.value]);

  const onCustomDatesChange = (dates: string[]) => {
    let start, end;
    start = dates[0];
    end = dates[1];
    setCustomStartDate(start);
    setCustomEndDate(end);

    dispatch(setCustomDateFilter({ start: start, end: end }));
  };

  return (
    <PageLayout
      fabClick={
        selectedType.value?.toLowerCase() === 'fitness' ? undefined : handleClickFabButton
      }
    >
      <ComponentsLayout sx={{ height: '100%' }}>
        <Box
          flex={1}
          height={'100%'}
          display={'flex'}
          flexDirection={'column'}
          gap={theme.spacing(3)}
        >
          <UserDataToolbar
            options={options}
            activeFilter={dateFilter}
            activeOption={selectedType}
            changeOption={handleSetDropdown}
            changeFilter={onDateFilterChange}
            filterOptions={prepareFilterOptions}
            segmentOptions={prepareSegmentOptions}
            activeSegment={handleToggleDataGraphs ? toggleDataGraphs : undefined}
            changeSegment={handleToggleDataGraphs ? setToggleDataGraphs : undefined}
          />
          {handleShowContent()}
        </Box>
      </ComponentsLayout>
      <ModalMobile open={usersDataModalOpen} setOpen={setUsersDataModalOpen}>
        {handleWhichModalToShow}
      </ModalMobile>
      <CalendarModal
        open={calendarModal}
        setOpen={setCalendarModal}
        saveDate={onCustomDatesChange}
        selectedDate={[customStartDate, customEndDate]}
      />
      {selectedType.value?.toLowerCase() === 'fitness' && <FABFitness />}
    </PageLayout>
  );
};

export default UsersData;
