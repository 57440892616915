import React, { FC, useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { useMediaQuery, useTheme } from '@mui/material';
import moment from 'moment';

type ChartBarProps = {
  chartData?: any[];
  colorList?: string[];
  width?: string | number;
  height?: string | number;
  type?: 'single' | 'multi';
  unitOfTime?: 'day' | 'week' | 'month' | 'year';
};
const BarChart: FC<ChartBarProps> = ({
  colorList,
  chartData = [],
  width = '100%',
  type = 'single',
  height = '170px',
  unitOfTime = 'day',
}) => {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));

  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const colors = useMemo(
    () =>
      chartData.map((rec, index) => {
        if (index === chartData.length - 1) {
          return '#222222';
        } else if (
          unitOfTime === 'day' ||
          moment(rec.x).isSame(chartData[chartData.length - 1].x, 'day')
        ) {
          return '#A5A9B1';
        } else {
          return '#E4E7ED';
        }
      }),
    [chartData, unitOfTime],
  );

  const options = useMemo<ApexOptions>(
    () => ({
      legend: {
        show: type !== 'multi',
      },
      chart: {
        type: 'bar',
        toolbar: { show: false },
        zoom: { enabled: false },
        fontFamily: theme.typography.fontFamily,
        defaultLocale: lang,
        locales: [
          {
            name: 'en',
            options: {
              months: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
              ],
              shortMonths: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
              ],
              days: [
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
              ],
              shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            },
          },
          {
            name: 'el',
            options: {
              months: [
                'Ιανουάριος',
                'Φεβρουάριος',
                'Μάρτιος',
                'Απρίλιος',
                'Μάιος',
                'Ιούνιος',
                'Ιούλιος',
                'Αύγουστος',
                'Σεπτέμβριος',
                'Οκτώβριος',
                'Νοέμβριος',
                'Δεκέμβριος',
              ],
              shortMonths: [
                'Ιαν',
                'Φεβ',
                'Μάρ',
                'Απρ',
                'Μάι',
                'Ιούν',
                'Ιούλ',
                'Αύγ',
                'Σεπ',
                'Οκτ',
                'Νοέ',
                'Δεκ',
              ],
              days: [
                'Κυριακή',
                'Δευτέρα',
                'Τρίτη',
                'Τετάρτη',
                'Πέμπτη',
                'Παρασκευή',
                'Σάββατο',
              ],
              shortDays: ['Κυρ', 'Δευ', 'Τρί', 'Τετ', 'Πέμ', 'Παρ', 'Σάβ'],
            },
          },
        ],
      },
      colors:
        type !== 'multi'
          ? [({ dataPointIndex }: { dataPointIndex: number }) => colors[dataPointIndex]]
          : colorList,
      plotOptions: {
        bar: {
          borderRadius: 2,
          dataLabels: { position: 'top' },
        },
      },
      dataLabels: {
        offsetY: -20,
        enabled: type !== 'multi',
        style: {
          colors: [
            ({ dataPointIndex }: { dataPointIndex: number }) => colors[dataPointIndex],
          ],
          fontSize: '12px',
          fontWeight: 400,
        },
      },
      xaxis: {
        tickAmount: 10,
        type: 'category',
        tickPlacement: 'on',
        axisBorder: {
          offsetY: 2,
          color: '#A5A9B1',
        },
        labels: {
          formatter: (value: string) => {
            if (unitOfTime === 'day') {
              return moment(value).format('HH:mm');
            }
            if (unitOfTime === 'week') {
              return moment(value).format('ddd');
            }
            if (unitOfTime === 'month') {
              return moment(value).format('dd D');
            }
            if (unitOfTime === 'year') {
              return moment(value).format('MMM');
            }
            return moment(value).format('D MMM');
          },
          style: {
            fontWeight: 600,
            fontSize: '12px',
            colors: type === 'multi' ? '#A5A9B1' : colors,
          },
        },
      },
      yaxis: {
        axisBorder: {
          show: true,
          offsetY: 1,
          offsetX: 3,
          color: '#A5A9B1',
        },
        tickAmount: 6,
        labels: {
          style: {
            colors: ['#A5A9B1'],
            fontSize: '12px',
            fontWeight: 600,
          },
        },
      },
      grid: {
        xaxis: { lines: { show: false } },
        yaxis: { lines: { show: false } },
      },
      tooltip: {
        enabled: false,
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          filter: {
            type: 'none',
          },
        },
      },
    }),
    [colors, desktop, theme, unitOfTime],
  );

  const series = useMemo<ApexAxisChartSeries>(() => [{ data: chartData }], [chartData]);

  return (
    <ReactApexChart
      type={'bar'}
      width={width}
      height={height}
      options={options}
      series={type === 'single' ? series : chartData}
    />
  );
};

export default BarChart;
