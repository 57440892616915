import React, { useState } from 'react';
import { Box } from '@mui/material';
import { ComponentsLayout, CustomTabs } from '@hdcorner/ui-library';
import PageLayout from '../../../components/PageLayout';
import ChartWeight from './components/ChartWeight';
import ChartSleep from './components/ChartSleep';
import ModalSliderWeight from './components/ModalSliderWeight';
import { Camera, CameraResultType } from '@capacitor/camera';
import useAlert from '../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';
import { cameraPermissions } from '../../../utils/cameraPermissions';

const Wellness = () => {
  const { t } = useTranslation();
  const { presentSuccess, presentError } = useAlert();

  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const handleOpenWeightModal = () => {
    setOpen(true);
  };

  const handleWeightOcr = async (cb: (newMeasurement: { weight: string }) => void) => {
    const cameraSource = await cameraPermissions();
    if (!cameraSource) {
      presentError(t('errors.devicePermissions.camera'));
      return;
    }
    const image = await Camera.getPhoto({
      quality: 90,
      source: cameraSource,
      resultType: CameraResultType.Base64,
    });

    setLoading(true);
    await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer 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',
      },
      body: JSON.stringify({
        max_tokens: 300,
        model: 'gpt-4-vision-preview',
        messages: [
          {
            role: 'user',
            content: [
              {
                type: 'text',
                text: 'Read the text on the device screen resembling the following: 70 kg and return just the number measurements',
              },
              {
                type: 'image_url',
                image_url: {
                  url: `data:image/jpeg;base64,${image.base64String}`,
                },
              },
            ],
          },
        ],
      }),
    })
      .then(response => response.json())
      .then(data => {
        setLoading(false);
        if (data.choices.length === 0) {
          presentError('No measurement detected');
          return;
        }
        if (data.choices[0].message.content === 'No measurement detected') {
          presentError('No measurement detected');
          return;
        }

        const weight = data.choices[0].message.content;
        cb({ weight });
        presentSuccess('Measurement detected!');
      })
      .catch(error => {
        setLoading(false);
        presentError('Error reading image');
      });
  };

  return (
    <PageLayout
      defaultHref={'/dashboard/fitness'}
      headerTitle={t('headingsTitles.wellness')}
      fabClick={selectedTab === 0 ? handleOpenWeightModal : undefined}
    >
      <ComponentsLayout>
        <Box display={'flex'} flexDirection={'column'} flex={1}>
          <CustomTabs
            value={selectedTab}
            setValue={setSelectedTab}
            tabs={[
              {
                children: <ChartWeight />,
                label: t('userDetails.weight'),
              },
              {
                children: <ChartSleep />,
                label: t('fitness.wellness.labels.sleepTim'),
              },
            ]}
            sx={{ display: 'flex', flex: 1, width: '100%' }}
          />
        </Box>
      </ComponentsLayout>
      <ModalSliderWeight
        log
        open={open}
        setOpen={setOpen}
        isLoading={isLoading}
        triggerOCR={cb => handleWeightOcr(cb)}
      />
    </PageLayout>
  );
};
export default Wellness;
