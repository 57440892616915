import * as React from 'react';
import { SVGProps } from 'react';

const YouTubeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="28"
    height="20"
    fill="none"
    viewBox="0 0 28 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.3861 1.22761C25.5349 1.53534 26.431 2.43632 26.7416 3.58692C27.5541 6.86943 27.5012 13.0754 26.7587 16.4093C26.4515 17.5598 25.5519 18.4574 24.4032 18.7686C21.1601 19.5721 6.63464 19.4729 3.57934 18.7686C2.43061 18.4608 1.5345 17.5598 1.22385 16.4093C0.457464 13.2806 0.510377 6.66428 1.20678 3.60401C1.51402 2.45342 2.41354 1.55586 3.56227 1.2447C7.89773 0.338591 22.8431 0.63094 24.3861 1.22761ZM11.4139 6.0013L18.378 10.0019L11.4139 14.0024V6.0013Z"
      fill="#FF0000"
    />
  </svg>
);

export default YouTubeIcon;
