import React, { FC } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import ProgressBar from '../navigation/ProgressBar';

type SmallMacrosType = {
  max?: number;
  title: string;
  value: number;
  measure: string;
  isProgressBarWide?: boolean;
};
const SmallMacros: FC<SmallMacrosType> = ({
  max,
  title,
  value,
  measure,
  isProgressBarWide,
}) => {
  const theme = useTheme();

  return (
    <Box display={'flex'} flex={1} justifyContent={'space-between'}>
      <Box display={'flex'} flex={1} flexDirection={'column'}>
        <Typography
          variant={'body1'}
          sx={{
            color:
              theme.palette.mode === 'dark'
                ? theme.palette.kmColorsCoolGrey.main
                : theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightRegular,
          }}
        >
          {title}
        </Typography>
        {max && (
          <Box marginY={1}>
            <ProgressBar progress={value / max} isProgressBarWide={isProgressBarWide} />
          </Box>
        )}
        <Typography
          variant={'body1'}
          sx={{
            color:
              theme.palette.mode === 'dark'
                ? theme.palette.kmColorsCoolGrey.main
                : theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightRegular,
          }}
        >
          {measure}
        </Typography>
      </Box>
    </Box>
  );
};

export default SmallMacros;
