import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import LipidGroupDetails from './LipidGroupDetails';
import {
  useGetLipidFirstLogQuery,
  useGetLipidGoalQuery,
  useGetLipidGraphDataQuery,
  useGetLipidLastLogQuery,
  useGetLipidLogsQuery,
  useGetOverallLipidQuery,
} from './queries/lipidQueries';
import moment from 'moment/moment';
import { useAppSelector } from '../../redux/hooks';
import useTimeframe from '../../hooks/useTimeframe';
import { LipidGoal, LipidLog, minMaxAvg, minMaxObj } from '@hdcorner/ui-library';
import useAlert from '../../hooks/useAlert';
import { useTranslation } from 'react-i18next';
import { goalProgress } from '../../utils/goalProgress';

type Props = {
  onAddGoalClick: (type: string) => void;
  onEditGoalClick: (goal: LipidGoal) => void;
  onRemoveGoalClick: (goal: LipidGoal) => void;
};
const TotalCholesterolSection: FC<Props> = ({
  onAddGoalClick,
  onEditGoalClick,
  onRemoveGoalClick,
}) => {
  const { t } = useTranslation();
  const { presentError } = useAlert();

  const [cholLogsParams, setCholLogsParams] = useState({
    skip: 0,
    limit: 8,
    sort: ['-logDate'],
  });

  const { dateFilter, customDateFilter } = useAppSelector(state => state.layout);

  const timeframe = useTimeframe(
    dateFilter,
    customDateFilter?.start,
    customDateFilter?.end,
  );

  const { data: lastLog, error: errorLipidLastLog } = useGetLipidLastLogQuery();
  const { data: cholGoalRes, error: errorCholGoal } = useGetLipidGoalQuery({
    type: 'chol',
  });
  const { data: lipidOverallData, error: errorLipidOverall } =
    useGetOverallLipidQuery(timeframe);
  const {
    data: lipidLogs,
    error: errorLipidLogs,
    isFetching: cholFetching,
  } = useGetLipidLogsQuery({
    params: { ...cholLogsParams },
    ...timeframe,
  });
  const { data: chartDataRes, error: errorGraphData } = useGetLipidGraphDataQuery({
    params: { metric: 'totalChol', ...timeframe },
  });

  const { data: cholFirstLog, error: errorCholFirstLog } = useGetLipidFirstLogQuery(
    {
      startingDate:
        cholGoalRes && cholGoalRes.length > 0
          ? (cholGoalRes as LipidGoal[])[0].startDate
          : '',
    },
    { skip: cholGoalRes ? cholGoalRes.length === 0 : true },
  );

  useEffect(() => {
    if (errorLipidLogs || errorLipidLastLog || errorCholFirstLog)
      presentError(t('errors.lipid.errorFetchLog'));
    if (errorCholGoal) presentError(t('errors.lipid.errorFetchGoal'));
    if (errorGraphData) presentError(t('errors.lipid.errorFetchGraph'));
    if (errorLipidOverall) presentError(t('errors.lipid.errorFetchOverall'));
  }, [
    errorCholGoal,
    errorLipidLogs,
    errorGraphData,
    errorLipidLastLog,
    errorLipidOverall,
    errorCholFirstLog,
  ]);

  const getOverallData = useCallback(() => {
    return minMaxAvg(lipidOverallData, 'totalChol');
  }, [lipidOverallData]);

  const goalData = useMemo(() => {
    const cholGoal = cholGoalRes ? cholGoalRes[0] : null;
    const lastLogItem = lastLog ? lastLog.documents[0] : null;
    let cholFirst = cholFirstLog ? cholFirstLog.documents[0] : null;

    let progress = 0;

    if (cholGoal && cholFirst && lastLogItem) {
      const startingMeasurement = cholFirst.totalChol || 0;
      const currentMeasurement = lastLogItem.totalChol || 0;
      const goalMeasurement = parseFloat(cholGoal.goal.measurement) || 0;
      progress = goalProgress(currentMeasurement, goalMeasurement, startingMeasurement);
    }

    if (cholGoal && !cholFirst) {
      cholFirst = lastLogItem;
    }

    return {
      progress: progress ? progress + '%' : '-',
      startingMeasurement: cholFirst ? cholFirst.totalChol : undefined,
      goal: cholGoal ? parseFloat(cholGoal.goal.measurement) : undefined,
      latestMeasurement: lastLogItem ? lastLogItem.totalChol : undefined,
      startingDate: cholGoal?.startDate
        ? moment(cholGoal.startDate).format('MMMM Do, YYYY')
        : undefined,
    };
  }, [cholGoalRes, cholFirstLog, lastLog]);

  const chartData = useMemo(() => {
    if (chartDataRes) {
      const logs = chartDataRes.logs;
      if (!logs) return [];

      return logs.map((log: LipidLog) => {
        return {
          date: log.logDate,
          value: log.totalChol,
        };
      });
    }
    return [];
  }, [chartDataRes]);

  const overallData = useMemo(() => {
    return minMaxObj('Total Cholesterol', getOverallData);
  }, [getOverallData]);

  const inDepthData = useMemo(() => {
    if (lipidLogs && lipidLogs.documents.length > 0) {
      return lipidLogs.documents
        .filter(log => log.totalChol)
        .map((log: LipidLog) => {
          return {
            title: log.totalChol,
            titleSub: t('measurements.mgdl'),
            data: {
              [t('lipid.labels.totalChol')]: {
                value: `${log.totalChol} ${t('measurements.mgdl')}`,
              },
              [t('inDepth.timeOfCalc')]: { value: moment(log.logDate).format('h:mm A') },
              [t('inDepth.dateOfCalc')]: {
                value: moment(log.logDate).format('MMM Do, YYYY'),
              },
            },
          };
        });
    }
    return [];
  }, [lipidLogs]);

  const handleAddGoal = () => {
    onAddGoalClick('chol');
  };

  const handleEditGoal = () => {
    if (cholGoalRes) {
      onEditGoalClick(cholGoalRes[0]);
    }
  };

  const handleRemoveGoal = () => {
    if (cholGoalRes) {
      onRemoveGoalClick(cholGoalRes[0]);
    }
  };

  return (
    <LipidGroupDetails
      goalData={goalData}
      chartData={chartData}
      inDepthData={inDepthData}
      overallData={overallData}
      isFetching={cholFetching}
      listParams={cholLogsParams}
      handleAddGoal={handleAddGoal}
      totalCount={lipidLogs?.count}
      handleEditGoal={handleEditGoal}
      count={lipidLogs?.documents.length}
      handleRemoveGoal={handleRemoveGoal}
      sectionName={t('lipid.labels.totalChol')}
      setListParams={params => setCholLogsParams({ ...cholLogsParams, ...params })}
    />
  );
};

export default TotalCholesterolSection;
