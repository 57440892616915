import React, { SVGProps } from 'react';

const CCAddressIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="12"
      height="15"
      fill="none"
      viewBox="0 0 12 15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9941 6.15455C11.9962 5.36741 11.8433 4.58756 11.544 3.85953C11.2447 3.1315 10.8049 2.46955 10.2498 1.91147C9.12875 0.784375 7.60584 0.148796 6.01613 0.144553C4.42642 0.140309 2.90014 0.767748 1.77305 1.88884C0.645952 3.00993 0.0103731 4.53285 0.00612968 6.12255C-0.00794836 7.96937 0.528168 9.77856 1.54615 11.3195C2.56414 12.8605 4.01789 14.0635 5.72213 14.7752C5.80925 14.8147 5.90383 14.8349 5.99946 14.8346C6.09579 14.8347 6.19104 14.8143 6.2788 14.7746C7.97785 14.065 9.42801 12.8671 10.4455 11.3325C11.463 9.79789 12.0019 7.99579 11.9941 6.15455ZM5.99946 13.4346C4.59409 12.8158 3.40233 11.7965 2.57324 10.504C1.74415 9.2115 1.31454 7.70325 1.33813 6.16789C1.33813 5.55575 1.4587 4.94961 1.69295 4.38407C1.92721 3.81853 2.27056 3.30467 2.7034 2.87183C3.13625 2.43898 3.65011 2.09563 4.21565 1.86138C4.78119 1.62712 5.38733 1.50655 5.99946 1.50655C6.6116 1.50655 7.21774 1.62712 7.78328 1.86138C8.34882 2.09563 8.86268 2.43898 9.29552 2.87183C9.72837 3.30467 10.0717 3.81853 10.306 4.38407C10.5402 4.94961 10.6608 5.55575 10.6608 6.16789C10.6608 6.18189 10.6608 6.19789 10.6608 6.21122C10.677 7.73949 10.2438 9.23884 9.41508 10.523C8.58638 11.8072 7.39866 12.8196 5.99946 13.4346ZM7.3328 6.16789C7.3328 6.43159 7.2546 6.68938 7.10809 6.90865C6.96158 7.12791 6.75334 7.29881 6.50971 7.39973C6.26607 7.50064 5.99798 7.52705 5.73934 7.4756C5.4807 7.42415 5.24312 7.29716 5.05665 7.11069C4.87018 6.92422 4.7432 6.68665 4.69175 6.42801C4.6403 6.16937 4.66671 5.90128 4.76762 5.65764C4.86854 5.41401 5.03944 5.20577 5.2587 5.05926C5.47797 4.91275 5.73575 4.83455 5.99946 4.83455C6.35308 4.83455 6.69222 4.97503 6.94227 5.22508C7.19232 5.47513 7.3328 5.81426 7.3328 6.16789Z"
        fill="#222222"
      />
    </svg>
  );
};

export default CCAddressIcon;
