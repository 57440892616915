import React, { FC, useCallback } from 'react';
import { Box, Menu, MenuItem, styled, useTheme } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import CustomButton from './CustomButton';

const MenuButton = styled(CustomButton)<ButtonProps>(({ theme }) => ({
  ...theme.typography.body1,
  fontWeight: theme.typography.fontWeightRegular,
  border: `2px solid ${theme.palette.secondary.dark}`,
  borderRadius: '8px',
  height: 32,
  width: 56,
}));

const CustomToggleButton = styled(CustomButton, {
  shouldForwardProp: prop => prop !== 'selected',
})<ButtonProps & { selected?: boolean }>(({ theme, selected }) => ({
  display: 'flex',
  flex: 1,
  height: 28,
  borderRadius: '7px',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.body1.fontSize,
  color: theme.palette.primary.main,
  backgroundColor: selected ? `${theme.palette.secondary.main} !important` : undefined,
  ':hover': {
    backgroundColor: selected ? theme.palette.secondary.light : undefined,
  },
}));

type ChatTabsType = {
  value: string;
  shrink?: boolean;
  tabs: { label: string; value: string }[];
  setValue: (val: string) => void;
};
const ChatTabs: FC<ChatTabsType> = ({ tabs, value, setValue, shrink }) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnChange = (newValue: string) => {
    if (newValue !== value) {
      setValue(newValue);
    }
  };

  const handleClose = (newValue: string) => {
    setAnchorEl(null);
    handleOnChange(newValue);
  };

  const showLine = useCallback(
    (index: number) => {
      return !(
        index === tabs.length - 1 ||
        value === tabs[index]?.value ||
        value === tabs[index + 1]?.value
      );
    },
    [value, tabs],
  );

  return !shrink ? (
    <Box
      display={'flex'}
      flex={1}
      bgcolor={theme.palette.secondary.dark}
      alignItems={'center'}
      borderRadius={'8px'}
      height={32}
    >
      <Box display={'flex'} flex={1} paddingX={theme.spacing(0.25)}>
        {tabs.map((tab, index) => (
          <React.Fragment key={index}>
            <CustomToggleButton
              key={tab.value}
              selected={value === tab.value}
              onClick={() => handleOnChange(tab.value)}
            >
              {tab.label}
            </CustomToggleButton>
            <Box
              sx={{
                height: 16,
                width: '1px',
                background: showLine(index)
                  ? theme.palette.secondary.light
                  : 'transparent',
                alignSelf: 'center',
                borderRadius: '0.5px',
              }}
            />
          </React.Fragment>
        ))}
      </Box>
    </Box>
  ) : (
    <>
      <MenuButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {value.toString()}
      </MenuButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose(value)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {tabs.map((tab, index) => (
          <MenuItem key={index} onClick={() => handleClose(tab.value)}>
            {tab.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ChatTabs;
