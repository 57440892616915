import React, { FC, useCallback, useEffect, useState } from 'react';
import { IonDatetime } from '@ionic/react';
import { CustomButton, HorizontalCalendar, theme } from '@hdcorner/ui-library';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';

const styles = {
  left: '50%',
  width: '100%',
  fontSize: '23px',
  position: 'relative',
  transform: 'translate(-50%, 0)',
  color: theme.palette.kmColorsCoolGrey.main,
  fontWeight: theme.typography.subtitle1.fontWeight,
};

type RescheduleMedicationModalProps = {
  initialDate?: string;
  saveChanges: (newDate: string) => void;
};

const RescheduleMedicationModal: FC<RescheduleMedicationModalProps> = ({
  initialDate,
  saveChanges,
}) => {
  const { t } = useTranslation();

  const [time, setTime] = useState<string>(moment().format('HH:mm'));
  const [selectedDate, setSelectedDate] = useState<Date>(moment().toDate());

  useEffect(() => {
    if (initialDate) {
      setSelectedDate(moment(initialDate).toDate());
      setTime(moment(initialDate).utc(false).format('HH:mm'));
    }
  }, [initialDate]);

  const handleChange = useCallback((newDate: Date) => {
    setSelectedDate(newDate);
  }, []);

  const onIonChange = (event: any) => {
    setTime(event.detail.value);
  };

  const handleReschedule = () => {
    const newDate = moment(selectedDate).format('YYYY-MM-DD') + 'T' + time + ':00.000Z';
    saveChanges(newDate);
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2} p={3}>
      <Typography
        variant={'subtitle1'}
        color={theme.palette.primary.main}
        marginBottom={theme.spacing(2)}
        fontWeight={theme.typography.fontWeightRegular}
      >
        {t('medication.text.rescheduleMeds')}
      </Typography>
      <Box display={'flex'} flexDirection={'column'} gap={1}>
        <Typography variant={'body1'} color={theme.palette.primary.main}>
          {t('medication.text.chooseDate')}
        </Typography>
        <HorizontalCalendar
          openMonthly={true}
          selectFutureDates
          selectedDate={selectedDate}
          handleChange={date => handleChange(date as Date)}
          sx={{ background: theme.palette.secondary.light }}
        />
      </Box>
      <Box display={'flex'} flexDirection={'column'} gap={1}>
        <Typography variant={'body1'} color={theme.palette.primary.main}>
          {t('medication.text.chooseTime')}
        </Typography>
        <IonDatetime
          style={styles}
          value={time}
          hourCycle={'h23'}
          preferWheel={true}
          presentation="time"
          onIonChange={onIonChange}
        ></IonDatetime>
        <CustomButton
          fullWidth
          disableElevation
          variant={'contained'}
          onClick={handleReschedule}
        >
          {t('buttons.reschedule')}
        </CustomButton>
      </Box>
    </Box>
  );
};

export default RescheduleMedicationModal;
