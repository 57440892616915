import { useIonRouter } from '@ionic/react';
import { Box, useMediaQuery } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { addFoodItem } from '../slices/mealFoodsSlice';
import { CustomButton, CustomInput, theme } from '@hdcorner/ui-library';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { usePostCreateCustomFoodMutation } from '../queries/foodsQueries';
import useAlert from '../../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

type Props = {
  customFood?: boolean;
  customMeal?: boolean;
  handleSwitchPanel?: () => void;
};

const AddFoodInner: FC<Props> = ({
  customFood = false,
  customMeal = false,
  handleSwitchPanel,
}) => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { presentSuccess, presentError } = useAlert();
  const web = useMediaQuery(theme.breakpoints.up('md'));

  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const { mealCategory, addFoodDetails } = useAppSelector(state => state.mealFoodsList);

  const [food, setFood] = useState({
    fat: '',
    name: '',
    carbs: '',
    protein: '',
    calories: '',
  });
  const [quantity, setQuantity] = useState<string>('');
  const [totalCalories, setTotalCalories] = useState<number>(0);

  const [postCustomFood] = usePostCreateCustomFoodMutation();

  useEffect(() => {
    if (customFood) {
      return;
    }

    setTotalCalories(addFoodDetails.calories);
    setFood(prevState => ({
      ...prevState,
      calories: addFoodDetails.calories.toString(),
      name:
        typeof addFoodDetails.name === 'object'
          ? addFoodDetails.name[lang]
          : addFoodDetails.name,
    }));
  }, [addFoodDetails, customFood]);

  useEffect(() => {
    if (quantity !== '' && parseInt(quantity) !== 0) {
      const calculatedCalories = Math.round(
        (parseInt(food.calories) / 100) * parseInt(quantity),
      );
      setTotalCalories(calculatedCalories);
    } else {
      setTotalCalories(addFoodDetails.calories);
    }
  }, [addFoodDetails, food.calories, quantity]);

  const handleChange = (fieldName: string, value: any) => {
    setFood(oldValue => ({
      ...oldValue,
      [fieldName]: value,
    }));
  };

  const handleAddFood = () => {
    dispatch(
      addFoodItem({
        _id: addFoodDetails._id,
        fat: addFoodDetails.fat,
        name: addFoodDetails.name,
        carbs: addFoodDetails.carbs,
        quantity: parseInt(quantity),
        protein: addFoodDetails.protein,
        calories: addFoodDetails.calories,
        calculatedCalories: totalCalories,
      }),
    );
    if (customMeal) {
      router.goBack();
      return;
    }
    if (web && handleSwitchPanel) {
      handleSwitchPanel();
    } else {
      router.push(`/dashboard/fitness/food-meals/${mealCategory}`, 'back', 'pop');
    }
  };

  const handleAddCustomFood = () => {
    if (!food) {
      presentError(t('errors.fitness.nutrition.errorFoodCreate'));
      return;
    }

    postCustomFood({
      fat: parseInt(food.fat),
      carbs: parseInt(food.carbs),
      protein: parseInt(food.protein),
      calories: parseInt(food.calories),
      name: { en: food.name, el: food.name },
    })
      .unwrap()
      .then((res: any) => {
        if (res && res.length > 0) {
          const customFoodData = {
            ...res[0],
          };
          dispatch(
            addFoodItem({
              ...customFoodData,
              quantity: parseInt(quantity),
              calculatedCalories: totalCalories,
            }),
          );
        }
        if (web && handleSwitchPanel) {
          handleSwitchPanel();
        } else {
          router.push(`/dashboard/fitness/food-meals/${mealCategory}`);
        }
        presentSuccess(t('errors.fitness.nutrition.successFoodCreate'));
      })
      .catch(() => presentError(t('errors.fitness.nutrition.errorFoodCreate')));
  };

  const quantityInput = (
    <CustomInput
      fullWidth
      type={'number'}
      value={quantity}
      alternativeBackground
      placeholder={`${t('measurements.grEg')}`}
      label={`${t('fitness.nutrition.labels.grams')}`}
      handleChange={value => setQuantity(value)}
    />
  );

  const totalCalInput = (
    <CustomInput
      readOnly
      fullWidth
      type={'number'}
      alternativeBackground
      placeholder={'e.g. 589'}
      value={Math.round(totalCalories)}
      label={
        customFood
          ? `${t('fitness.exercise.labels.calories')}`
          : `${t('fitness.calories.titles.calories')}`
      }
    />
  );

  const isDisabled = () => {
    if (customFood) {
      return (
        !food?.name || !food?.calories || !food?.protein || !food?.carbs || !food?.fat
      );
    }
    return !quantity;
  };

  return (
    <>
      <Box
        gap={1}
        width={'100%'}
        display={'flex'}
        flexDirection={'column'}
        margin={theme.spacing(3, 0)}
      >
        <Box display={customFood ? 'block' : 'none'}>
          <CustomInput
            fullWidth
            label={'Name'}
            value={food?.name}
            alternativeBackground
            placeholder={'e.g. Chicken breast'}
            handleChange={value => handleChange('name', value)}
          />
        </Box>

        {customFood ? null : quantityInput}

        <CustomInput
          readOnly
          fullWidth
          alternativeBackground
          value={`100${t('measurements.gr')}`}
          label={`${t('fitness.nutrition.labels.portion')}`}
        />

        {!customFood ? totalCalInput : null}

        <Box
          textAlign={'left'}
          flexDirection={'column'}
          display={customFood ? 'flex' : 'none'}
        >
          <Box gap={1} display={'flex'} flex={1}>
            <CustomInput
              fullWidth
              type={'number'}
              value={food?.calories}
              alternativeBackground
              placeholder={`${t('measurements.calEg')}`}
              label={`${t('fitness.exercise.labels.calories')}`}
              handleChange={value => handleChange('calories', value)}
            />

            <CustomInput
              fullWidth
              type={'number'}
              value={food?.protein}
              alternativeBackground
              placeholder={`${t('measurements.grEg')}`}
              label={`${t('fitness.calories.titles.protein')}`}
              handleChange={value => handleChange('protein', value)}
            />
          </Box>

          <Box gap={1} display={'flex'} flex={1}>
            <CustomInput
              fullWidth
              type={'number'}
              value={food?.carbs}
              alternativeBackground
              placeholder={`${t('measurements.grEg')}`}
              label={`${t('fitness.calories.titles.carbs')}`}
              handleChange={value => handleChange('carbs', value)}
            />

            <CustomInput
              fullWidth
              type={'number'}
              value={food?.fat}
              alternativeBackground
              placeholder={`${t('measurements.grEg')}`}
              label={`${t('fitness.calories.titles.fat')}`}
              handleChange={value => handleChange('fat', value)}
            />
          </Box>
        </Box>
      </Box>

      <Box
        mb={3}
        textAlign={'center'}
        flexDirection={'column'}
        display={customFood ? 'flex' : 'none'}
      >
        <Box
          height={'1px'}
          width={'228px'}
          bgcolor={theme.palette.secondary.dark}
          margin={theme.spacing(0, 'auto', 3, 'auto')}
        />
        <Box gap={1} display={'flex'} flex={1}>
          {quantityInput}
          {totalCalInput}
        </Box>
      </Box>

      <CustomButton
        fullWidth
        color={'primary'}
        variant={'contained'}
        disabled={isDisabled()}
        onClick={customFood ? handleAddCustomFood : handleAddFood}
        children={customFood ? t('buttons.create') : t('buttons.add')}
      />
    </>
  );
};

export default AddFoodInner;
