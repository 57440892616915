import * as React from 'react';
import { SVGProps } from 'react';
import { Box, BoxProps, styled } from '@mui/material';

const IconBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: '40px',
  height: '40px',
  borderRadius: '8px',
  padding: '8px',
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#4E80E1',
}));
const GoalsProgressSQ = (props: SVGProps<SVGSVGElement>) => {
  return (
    <IconBox>
      <svg
        width="16"
        height="23"
        viewBox="0 0 16 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M15 7H10.724L13.868 1.5C13.9558 1.34784 14.002 1.17521 14.002 0.999508C14.0019 0.823803 13.9555 0.651223 13.8675 0.499146C13.7795 0.347068 13.653 0.220862 13.5007 0.133235C13.3484 0.0456082 13.1757 -0.000345191 13 1.9523e-06H5.99998C5.81415 -8.46432e-05 5.63197 0.0516118 5.47388 0.149291C5.31579 0.246971 5.18804 0.386769 5.10498 0.553002L0.104978 10.553C0.0287753 10.7055 -0.00717704 10.8749 0.000535877 11.0452C0.00824879 11.2155 0.0593708 11.381 0.149046 11.526C0.238722 11.671 0.363974 11.7907 0.512905 11.8736C0.661836 11.9566 0.829502 12.0001 0.999978 12H5.65598L3.04198 20.713C2.97565 20.9333 2.98729 21.1697 3.07493 21.3824C3.16258 21.595 3.32087 21.771 3.52312 21.8806C3.72538 21.9902 3.95923 22.0267 4.18526 21.9839C4.4113 21.9412 4.61568 21.8219 4.76398 21.646L15.764 8.646C15.8872 8.50038 15.9662 8.32255 15.9917 8.13349C16.0172 7.94443 15.988 7.75202 15.9077 7.57899C15.8274 7.40595 15.6992 7.2595 15.5384 7.15691C15.3776 7.05432 15.1907 6.99988 15 7ZM6.33698 16.689L7.95798 11.289C8.00309 11.1396 8.01259 10.9816 7.98573 10.8279C7.95886 10.6741 7.89637 10.5288 7.80326 10.4035C7.71015 10.2782 7.589 10.1765 7.44951 10.1064C7.31002 10.0363 7.15607 9.9999 6.99998 10H2.61798L6.61798 2H11.276L8.13198 7.5C8.04413 7.65217 7.99792 7.82479 7.998 8.0005C7.99809 8.1762 8.04447 8.34878 8.13247 8.50086C8.22047 8.65294 8.34699 8.77914 8.49929 8.86677C8.65158 8.9544 8.82427 9.00035 8.99998 9H12.844L6.33698 16.689Z"
          fill="#FBFBFB"
        />
      </svg>
    </IconBox>
  );
};

export default GoalsProgressSQ;
