import { FilePicker, PickFilesResult } from '@capawesome/capacitor-file-picker';

const pickFiles = async (): Promise<PickFilesResult> => {
  return await FilePicker.pickFiles({
    types: ['application/pdf'],
    multiple: true,
    readData: true,
  });
};

const createFiles = (result: PickFilesResult) => {
  let pickedFiles = result.files;
  const files: File[] = [];

  pickedFiles.forEach(file => {
    if (!file.data) return;

    const byteString = window.atob(file.data);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const fileBlob = new Blob([int8Array], { type: file.mimeType });
    const rawFile = new File([fileBlob], file.name, {
      type: file.mimeType,
    });
    files.push(rawFile);
  });

  return files;
};

export { pickFiles, createFiles };
