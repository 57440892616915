import React, { FC } from 'react';
import { Box, BoxProps, IconButton, Input, InputProps, styled } from '@mui/material';
import ChatSendMessageIcon from '../../assets/ChatSendMessageIcon';

const MessageBox = styled(Box)<BoxProps>(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  maxWidth: 'unset',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.background.default
      : theme.palette.secondary.light,
}));

const CustomInput = styled(Input)<InputProps>(({ theme }) => ({
  height: '42px',
  textAlign: 'left',
  borderRadius: '8px',
  boxSizing: 'border-box',
  color: theme.palette.primary.main,
  fontSize: theme.typography.subtitle2.fontSize,
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.background.default
      : theme.palette.secondary.light,
  fontWeight: theme.typography.subtitle2.fontWeight,
  padding: theme.spacing(1.25, 1.5),
  margin: theme.spacing(0, 2.5, 0, 2),
  border:
    theme.palette.mode === 'dark'
      ? `2px solid ${theme.palette.kmColorsDarkGrey.main}`
      : `2px solid ${theme.palette.secondary.main}`,
}));

type ChatMessageWebProps = {
  message: string;
  disabled?: boolean;
  placeholder: string;
  handleSubmit: () => void;
  handleAddContentClick: () => void;
  setMessage: (value: string) => void;
};
const ChatMessageWeb: FC<ChatMessageWebProps> = ({
  message,
  setMessage,
  placeholder,
  handleSubmit,
  handleAddContentClick,
  disabled = false,
}) => {
  const handleInput = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setMessage(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      // Check if Enter key is pressed without the Shift key
      handleSubmit();
    }
  };

  return (
    <MessageBox>
      {/*<IconButton*/}
      {/*  onClick={handleAddContentClick}*/}
      {/*  sx={{*/}
      {/*    '&:hover': {*/}
      {/*      background: 'none',*/}
      {/*    },*/}
      {/*  }}*/}
      {/*  disabled={disabled}*/}
      {/*>*/}
      {/*  <ChatAddContentIcon />*/}
      {/*</IconButton>*/}
      <Box display={'flex'} flexGrow={1}>
        <CustomInput
          fullWidth
          type={message}
          value={message}
          disableUnderline
          onChange={handleInput}
          onKeyDown={handleKeyPress}
          placeholder={placeholder}
          disabled={disabled}
        />
        <IconButton
          onClick={handleSubmit}
          type={'submit'}
          sx={{
            '&:hover': {
              background: 'none',
            },
          }}
          disabled={disabled}
        >
          <ChatSendMessageIcon />
        </IconButton>
      </Box>
    </MessageBox>
  );
};

export default ChatMessageWeb;
