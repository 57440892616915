import React from 'react';
import { ComponentsLayout } from '@hdcorner/ui-library';
import PageLayout from '../../../components/PageLayout';
import SectionGoalWeight from './components/SectionGoalWeight';
import { useTranslation } from 'react-i18next';

const FitnessGoalWeight = () => {
  const { t } = useTranslation();

  return (
    <PageLayout
      defaultHref={'/dashboard/fitness/wellness'}
      headerTitle={t('headingsTitles.weightGoal')}
    >
      <ComponentsLayout>
        <SectionGoalWeight />
      </ComponentsLayout>
    </PageLayout>
  );
};
export default FitnessGoalWeight;
