const FilePreviewIcon = () => {
  return (
    <svg
      width="36"
      height="45"
      viewBox="0 0 36 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 44.5H34C34.5304 44.5 35.0391 44.2893 35.4142 43.9142C35.7893 43.5391 36 43.0304 36 42.5V10.5C35.9999 9.96961 35.7891 9.46099 35.414 9.086L27.414 1.086C27.039 0.710901 26.5304 0.500113 26 0.5H2C1.46957 0.5 0.960859 0.710714 0.585786 1.08579C0.210714 1.46086 0 1.96957 0 2.5V42.5C0 43.0304 0.210714 43.5391 0.585786 43.9142C0.960859 44.2893 1.46957 44.5 2 44.5ZM4 4.5H25.172L32 11.328V40.5H4V4.5Z"
        fill="#E1205A"
      />
    </svg>
  );
};

export default FilePreviewIcon;
