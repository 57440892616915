import { useMemo } from 'react';
import { DietPlan } from '../types/DietPlan';
import moment from 'moment';
import { ConduitFile } from '../types/ConduitFile';
import { useTranslation } from 'react-i18next';

const usePrepareDietPlan = (dietPlanRes?: DietPlan[]) => {
  const { t } = useTranslation();

  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const fileName = useMemo(() => {
    if (!dietPlanRes || dietPlanRes.length === 0) return t('general.labels.nA');

    const dietPlan = dietPlanRes[0].dietPlan;
    if (dietPlan?.name) {
      return dietPlan.name[lang]
        ? dietPlan.name[lang]
        : dietPlan.name['en']
        ? dietPlan.name['en']
        : t('general.labels.nA');
    }

    return (
      t('fitness.dietPlan.text.dp') +
      ' ' +
      (dietPlanRes[0].dietType
        ? "'" + dietPlanRes[0].dietType + "'"
        : t('general.labels.nA'))
    );
  }, [dietPlanRes]);

  const calories = useMemo(() => {
    if (!dietPlanRes || dietPlanRes.length === 0) return '-';

    return dietPlanRes[0].calculatedCalories ? dietPlanRes[0].calculatedCalories : '-';
  }, [dietPlanRes]);

  const file = useMemo(() => {
    if (!dietPlanRes || dietPlanRes.length === 0) return '';

    const langFiles = dietPlanRes[0].dietPlan?.attachedFile;
    if (!langFiles) return '';

    const attachedFile = langFiles[lang]
      ? (langFiles[lang] as ConduitFile)
      : (langFiles['en'] as ConduitFile);

    return attachedFile?.url ? attachedFile.url : '';
  }, [dietPlanRes]);

  return useMemo(() => {
    if (!dietPlanRes || dietPlanRes.length === 0) return null;

    return {
      file: file,
      title: fileName,
      subtitle: calories + ' ' + t('fitness.calories.titles.calories'),
      date: moment(dietPlanRes[0].createdAt).format('DD MMMM YYYY'),
    };
  }, [dietPlanRes]);
};

export default usePrepareDietPlan;
