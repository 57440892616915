import { FC, useMemo } from 'react';
import { Box, styled, Typography, TypographyProps } from '@mui/material';
import {
  CrownSQIcon,
  CustomButton,
  SubscriptionPlanBenefits,
  theme,
} from '@hdcorner/ui-library';
import { useTranslation } from 'react-i18next';
import {
  useGetProductsQuery,
  useGetSubscriptionsQuery,
} from '../queries/subscriptionsQueries';

const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

const TypographyCost = styled(Typography, {
  shouldForwardProp: propName => {
    return propName !== 'premium';
  },
})<TypographyProps & { premium: boolean }>(({ theme, premium }) => ({
  fontSize: '28px',
  boxSizing: 'border-box',
  fontWeight: premium
    ? theme.typography.fontWeightMedium
    : theme.typography.fontWeightBold,
  paddingTop: premium ? theme.spacing(1) : 0,
  marginBottom: premium ? theme.spacing(1) : 0,
  textTransform: lang === 'el' ? 'capitalize' : 'uppercase',
}));

type SubsPlanCardProps = {
  plan: string;
  premium: boolean;
  tryPremium?: () => void;
  handlePlanUpgrade?: () => void;
  handlePlanDowngrade?: () => void;
  benefits: { benefit: string; plan: string[] }[];
};
const SubsPlanCard: FC<SubsPlanCardProps> = ({
  plan,
  premium,
  benefits,
  tryPremium,
  handlePlanUpgrade,
  handlePlanDowngrade,
}) => {
  const { t } = useTranslation();

  const { data: productsRes } = useGetProductsQuery();
  const { data: subscriptionsRes } = useGetSubscriptionsQuery();

  const activeSubscriptionPrice = useMemo(() => {
    if (!productsRes || !subscriptionsRes) return 0;
    const activeSub = subscriptionsRes.find(sub => sub.status === 'active');
    const product = productsRes.find(product => product._id === activeSub?.product._id);

    if (!product) return null;

    const vatRate = product.vat;
    const priceInCents = product.value;

    const finalPriceInCents = priceInCents + Math.round(priceInCents * (vatRate / 100));
    const finalPriceInDollars = finalPriceInCents / 100;

    return finalPriceInDollars.toFixed();
  }, [productsRes, subscriptionsRes]);

  const biAnnualSubscriptionPrice = useMemo(() => {
    if (!productsRes) return 0;
    const product = productsRes.find(product => product.name === 'APP-HD-024');

    if (!product) return null;

    const vatRate = product.vat;
    const priceInCents = product.value;

    const finalPriceInCents = priceInCents + Math.round(priceInCents * (vatRate / 100));
    const finalPriceInDollars = finalPriceInCents / 100;

    return finalPriceInDollars.toFixed();
  }, [productsRes]);

  const findActivePlanName = useMemo(() => {
    const activeSub = subscriptionsRes?.find(sub => sub.status === 'active');
    if (!activeSub) return t('constants.subscription.plans.biAnnual');
    switch (activeSub.product.name) {
      case 'APP-HD-001':
        return t('constants.subscription.plans.mon');
      case 'APP-HD-012':
        return t('constants.subscription.plans.annua');
      case 'APP-HD-024':
        return t('constants.subscription.plans.biAnnual');
      default:
        return 'No plan found';
    }
  }, [subscriptionsRes]);

  const decidePrice = useMemo(() => {
    if (activeSubscriptionPrice) return activeSubscriptionPrice;
    return biAnnualSubscriptionPrice;
  }, [premium, activeSubscriptionPrice, biAnnualSubscriptionPrice]);

  return (
    <Box
      width={'328px'}
      display={'flex'}
      minWidth={'312px'}
      borderRadius={'20px'}
      flexDirection={'column'}
      boxSizing={'border-box'}
      padding={theme.spacing(3)}
      bgcolor={theme.palette.secondary.light}
      boxShadow={'0px 1px 24px rgba(0, 0, 0, 0.08)'}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        marginBottom={theme.spacing(4)}
      >
        <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
          <CrownSQIcon
            color={premium ? theme.palette.warning.main : theme.palette.primary.main}
          />
          <Typography variant={'h1'} sx={{ marginLeft: theme.spacing(1) }}>
            {premium ? 'Premium' : 'Basic'}
          </Typography>
        </Box>
        <Box textAlign={'right'}>
          <TypographyCost premium={premium}>
            {premium ? decidePrice + '€' : t('subscription.labels.free')}
          </TypographyCost>
          <Typography variant={'caption'} sx={{ display: premium ? 'block' : 'none' }}>
            / {findActivePlanName}
          </Typography>
        </Box>
      </Box>

      <Box
        height={'2px'}
        width={'140px'}
        bgcolor={theme.palette.secondary.main}
        margin={theme.spacing(0, 'auto', 4.25, 'auto')}
      ></Box>

      <SubscriptionPlanBenefits
        benefits={benefits}
        selectedPlan={premium ? 'premium' : 'basic'}
      />

      <Box
        height={'2px'}
        width={'140px'}
        bgcolor={theme.palette.secondary.main}
        margin={theme.spacing(4.25, 'auto', 4, 'auto')}
      ></Box>

      <Box display={'flex'} gap={1} flexDirection={'column'}>
        <CustomButton
          fullWidth
          variant={'contained'}
          onClick={
            (plan === 'basic' && !premium) || (plan === 'premium' && premium)
              ? undefined
              : plan === 'premium' && !premium
              ? handlePlanDowngrade
              : handlePlanUpgrade
          }
          disabled={(plan === 'basic' && !premium) || (plan === 'premium' && premium)}
          sx={{
            height: '48px',
            margin: '0 auto',
            fontWeight: theme.typography.fontWeightBold,
            backgroundColor: theme.palette.primary.main,
            fontSize: theme.typography.subtitle2.fontSize,
          }}
        >
          {(plan === 'basic' && !premium) || (plan === 'premium' && premium)
            ? t('subscription.labels.currentPlan')
            : t('subscription.labels.choosePlan')}
        </CustomButton>

        {premium && (
          <CustomButton
            variant={'outlined'}
            onClick={tryPremium}
            disabled={(plan === 'basic' && !premium) || (plan === 'premium' && premium)}
          >
            <Typography variant={'body1'}>{t('subscription.text.tryFree')}</Typography>
          </CustomButton>
        )}
      </Box>
    </Box>
  );
};

export default SubsPlanCard;
