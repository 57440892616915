const PhotosAndVideos = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.99967 14.6654H13.9997C14.1765 14.6654 14.3461 14.5951 14.4711 14.4701C14.5961 14.3451 14.6663 14.1755 14.6663 13.9987V1.9987C14.6663 1.82189 14.5961 1.65232 14.4711 1.52729C14.3461 1.40227 14.1765 1.33203 13.9997 1.33203H1.99967C1.82286 1.33203 1.65329 1.40227 1.52827 1.52729C1.40325 1.65232 1.33301 1.82189 1.33301 1.9987V13.9987C1.33301 14.1755 1.40325 14.3451 1.52827 14.4701C1.65329 14.5951 1.82286 14.6654 1.99967 14.6654ZM3.60901 13.332L6.66634 10.2747L7.52834 11.1367C7.65336 11.2617 7.8229 11.3319 7.99967 11.3319C8.17645 11.3319 8.34599 11.2617 8.47101 11.1367L11.333 8.2747L13.333 10.2747V13.332H3.60901ZM13.333 2.66536V8.38937L11.8043 6.8607C11.6793 6.73572 11.5098 6.66551 11.333 6.66551C11.1562 6.66551 10.9867 6.73572 10.8617 6.8607L7.99967 9.7227L7.13767 8.8607C7.01266 8.73572 6.84312 8.66551 6.66634 8.66551C6.48957 8.66551 6.32003 8.73572 6.19501 8.8607L2.66634 12.3894V2.66536H13.333ZM3.99967 5.66536C3.99967 5.33573 4.09742 5.0135 4.28056 4.73941C4.46369 4.46533 4.72399 4.25171 5.02854 4.12557C5.33308 3.99942 5.66819 3.96641 5.99149 4.03072C6.31479 4.09503 6.61176 4.25377 6.84485 4.48685C7.07794 4.71994 7.23667 5.01691 7.30098 5.34021C7.36529 5.66352 7.33229 5.99863 7.20614 6.30317C7.07999 6.60771 6.86637 6.86801 6.59229 7.05115C6.31821 7.23428 5.99598 7.33203 5.66634 7.33203C5.22431 7.33203 4.80039 7.15644 4.48783 6.84388C4.17527 6.53132 3.99967 6.10739 3.99967 5.66536Z"
        fill="#4A4F55"
      />
    </svg>
  );
};

export default PhotosAndVideos;
