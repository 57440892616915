import React, { SVGProps } from 'react';
import { useTheme } from '@mui/material';

const PlusCircleIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  return (
    <svg
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.99935 1.16602C6.54896 1.16602 5.13113 1.59611 3.92517 2.4019C2.71921 3.2077 1.77928 4.35301 1.22424 5.693C0.669193 7.03299 0.523969 8.50748 0.806927 9.93001C1.08989 11.3525 1.78832 12.6592 2.8139 13.6848C3.83949 14.7104 5.14616 15.4088 6.56869 15.6918C7.99122 15.9747 9.46571 15.8295 10.8057 15.2745C12.1457 14.7194 13.291 13.7795 14.0968 12.5735C14.9026 11.3676 15.3327 9.94975 15.3327 8.49935C15.3304 6.55513 14.557 4.6912 13.1823 3.31643C11.8075 1.94166 9.94357 1.16831 7.99935 1.16602ZM7.99935 14.4993C6.81266 14.4993 5.65263 14.1475 4.66593 13.4882C3.67924 12.8289 2.9102 11.8918 2.45608 10.7954C2.00195 9.69909 1.88313 8.49269 2.11464 7.32881C2.34615 6.16492 2.9176 5.09582 3.75671 4.25671C4.59583 3.41759 5.66492 2.84615 6.82881 2.61464C7.9927 2.38313 9.1991 2.50195 10.2955 2.95607C11.3918 3.4102 12.3289 4.17923 12.9882 5.16593C13.6475 6.15262 13.9994 7.31266 13.9994 8.49935C13.9976 10.0901 13.3649 11.6152 12.24 12.74C11.1152 13.8649 9.59011 14.4976 7.99935 14.4993ZM11.3327 8.49935C11.3327 8.67616 11.2624 8.84573 11.1374 8.97075C11.0124 9.09578 10.8428 9.16602 10.666 9.16602H8.66602V11.166C8.66602 11.3428 8.59578 11.5124 8.47076 11.6374C8.34573 11.7624 8.17616 11.8327 7.99935 11.8327C7.82254 11.8327 7.65297 11.7624 7.52795 11.6374C7.40292 11.5124 7.33269 11.3428 7.33269 11.166V9.16602H5.33269C5.15587 9.16602 4.98631 9.09578 4.86128 8.97075C4.73626 8.84573 4.66602 8.67616 4.66602 8.49935C4.66602 8.32254 4.73626 8.15297 4.86128 8.02794C4.98631 7.90292 5.15587 7.83268 5.33269 7.83268H7.33269V5.83268C7.33269 5.65587 7.40292 5.4863 7.52795 5.36128C7.65297 5.23625 7.82254 5.16602 7.99935 5.16602C8.17616 5.16602 8.34573 5.23625 8.47076 5.36128C8.59578 5.4863 8.66602 5.65587 8.66602 5.83268V7.83268H10.666C10.8428 7.83268 11.0124 7.90292 11.1374 8.02794C11.2624 8.15297 11.3327 8.32254 11.3327 8.49935Z"
        fill={props.color ? props.color : theme.palette.primary.main}
      />
    </svg>
  );
};

export default PlusCircleIcon;
