import React, { FC } from 'react';
import { Box, BoxProps, useTheme } from '@mui/material';

type ComponentsLayoutType = {
  disablePaddingTop?: boolean;
  disablePaddingLeft?: boolean;
  disablePaddingRight?: boolean;
  disablePaddingBottom?: boolean;
  children: React.ReactNode;
};

const ComponentsLayout: FC<ComponentsLayoutType & BoxProps> = ({
  disablePaddingTop = false,
  disablePaddingLeft = false,
  disablePaddingBottom = false,
  disablePaddingRight = false,
  children,
  ...boxProps
}) => {
  const theme = useTheme();

  return (
    <Box
      {...boxProps}
      sx={{
        boxSizing: 'border-box',
        paddingTop: disablePaddingTop ? 0 : theme.spacing(3),
        paddingLeft: disablePaddingLeft ? 0 : theme.spacing(1.5),
        paddingBottom: disablePaddingBottom ? 0 : theme.spacing(3),
        paddingRight: disablePaddingRight ? 0 : theme.spacing(1.5),
      }}
    >
      {children}
    </Box>
  );
};

export default ComponentsLayout;
