import FEDairyIcon from '../../assets/FEDairyIcon';
import FEVegetableIcon from '../../assets/FEVegetableIcon';
import FEFruitIcon from '../../assets/FEFruitIcon';
import FEBreadIcon from '../../assets/FEBreadIcon';
import FEOtherCarbIcon from '../../assets/FEOtherCarbIcon';
import FEMeatIcon from '../../assets/FEMeatIcon';
import FEAlcoholIcon from '../../assets/FEAlcoholIcon';
import FEFatsIcon from '../../assets/FEFatsIcon';
import FEOtherIcon from '../../assets/FEOtherIcon';
import { useMediaQuery } from '@mui/material';
import theme from '../../theme/theme';

const dataMob = [
  {
    'column 1': 'Λεμόνι',
    'column 2': 'Πάπρικα',
  },
  { 'column 1': 'Σκόρδο', 'column 2': 'Σκόνη κακάο' },
  {
    'column 1': 'Ξύδι',
    'column 2': 'Κανέλλα',
  },
  { 'column 1': 'Κάπαρη', 'column 2': 'Ζελέ χωρίς ζάχαρη' },
  {
    'column 1': 'Κέτσαπ',
    'column 2': 'Ταμπάσκο',
  },
  { 'column 1': 'Κρασί (για μαγείρεμα)', 'column 2': 'Σόδα' },
  {
    'column 1': 'Μουστάρδα',
    'column 2': 'Βασιλικός',
  },
  { 'column 1': 'Καφές', 'column 2': 'Αναψυκτικά χωρίς ζάχαρη' },
  {
    'column 1': 'Πιπέρι',
    'column 2': 'Άνηθος/μαϊντανός',
  },
  { 'column 1': 'Τσάι', 'column 2': 'Υποκατάστατα ζάχαρης' },
  {
    'column 1': 'Τσίλι',
    'column 2': 'Ρίγανη/θυμάρι/θρούμπι',
  },
  { 'column 1': 'Αφεψήματα', 'column 2': '' },
];

const dataWeb = [
  {
    'column 1': 'Λεμόνι',
    'column 2': 'Πάπρικα',
    'column 3': 'Σκόρδο',
    'column 4': 'Σκόνη κακάο',
  },
  {
    'column 1': 'Ξύδι',
    'column 2': 'Κανέλλα',
    'column 3': 'Κάπαρη',
    'column 4': 'Ζελέ χωρίς ζάχαρη',
  },
  {
    'column 1': 'Κέτσαπ',
    'column 2': 'Ταμπάσκο',
    'column 3': 'Κρασί (για μαγείρεμα)',
    'column 4': 'Σόδα',
  },
  {
    'column 1': 'Μουστάρδα',
    'column 2': 'Βασιλικός',
    'column 3': 'Καφές',
    'column 4': 'Αναψυκτικά χωρίς ζάχαρη',
  },
  {
    'column 1': 'Πιπέρι',
    'column 2': 'Άνηθος/μαϊντανός',
    'column 3': 'Τσάι',
    'column 4': 'Υποκατάστατα ζάχαρης',
  },
  {
    'column 1': 'Τσίλι',
    'column 2': 'Ρίγανη/θυμάρι/θρούμπι',
    'column 3': 'Αφεψήματα',
    'column 4': '',
  },
];

export const FEDataEL = () => {
  const web = useMediaQuery(theme.breakpoints.up('sm'));

  return [
    {
      type: 'dairy',
      title: 'Γάλα και γαλακτοκομικά προϊόντα',
      icon: <FEDairyIcon />,
      description:
        'Ένα ισοδύναμο περιέχει 12 g υδατανθράκων, 8 g πρωτεϊνών και ποσότητα λιπιδίων που ποικίλλει ανά κατηγορία.',
      data: [
        {
          heading: 'Γάλα αποβουτυρωμένο (0–3 g λιπιδίων ανά ισοδύναμο)',
          table: [
            {
              item: 'Αποβουτυρωμένο γάλα',
              quantity: '1 φλιτζάνι',
            },
            {
              item: 'Γάλα με 1 ή 1,5% λίπος',
              quantity: '1 φλιτζάνι',
            },
            {
              item: 'Γάλα σκόνη αποβουτυρωμένο',
              quantity: '⅓ φλιτζανιού',
            },
            {
              item: 'Γάλα εβαπορέ (χωρίς λίπος)',
              quantity: '½ φλιτζανιού',
            },
            {
              item: 'Γάλα σόγιας αποβουτυρωμένο',
              quantity: '1 φλιτζάνι',
            },
            {
              item: 'Γιαούρτι από αποβουτυρωμένο γάλα',
              quantity: '180-200 g',
            },
          ],
        },
        {
          heading: 'Γάλα χαμηλής περιεκτικότητας σε λίπος (5 g λιπιδίων ανά ισοδύναμο)',
          table: [
            {
              item: 'Γάλα με 2% λίπος',
              quantity: '1 φλιτζάνι',
            },
            {
              item: 'Γιαούρτι από γάλα με 2% λίπος',
              quantity: '180 g',
            },
            {
              item: 'Κεφίρ, αριάνι, ξινόγαλο (μέσης περιεκτικότητας σε λίπος)',
              quantity: '1 φλιτζάνι',
            },
          ],
        },
        {
          heading: 'Γάλα πλήρες (8 g λιπιδίων ανά ισοδύναμο)',
          table: [
            {
              item: 'Γάλα πλήρες',
              quantity: '180 g',
            },
            {
              item: 'Γάλα εβαπορέ πλήρες (κουτί)',
              quantity: '½ φλιτζανιού',
            },
            {
              item: 'Γάλα κατσίκας',
              quantity: '180 g',
            },
            {
              item: 'Γιαούρτι από πλήρες γάλα',
              quantity: '180 g',
            },
          ],
        },
      ],
    },
    {
      type: 'vegetable',
      title: 'Λαχανικά',
      icon: <FEVegetableIcon />,
      description:
        'Ένα ισοδύναμο αντιστοιχεί σε ½ φλιτζάνι του τσαγιού βρασμένα λαχανικά ή 1 φλιτζάνι ωμά λαχανικά και περιέχει 5 g υδατανθράκων, 2 g πρωτεϊνών, 25 Kcal και 1–4 g διαιτητικών ινών.',
      note: 'Σημ.: τα αμυλούχα λαχανικά (πατάτα, αρακάς, φασολάκια, καλαμπόκι) αναφέρονται στην ομάδα αμύλου.',
      data: [
        {
          item: 'Αγγούρι',
          equivalent: 'Μανιτάρια',
        },
        {
          item: 'Αγκινάρα',
          equivalent: 'Μαρούλι',
        },
        {
          item: 'Αντίδια',
          equivalent: 'Μελιτζάνες',
        },
        {
          item: 'Βρούβες',
          equivalent: 'Μπρόκολο',
        },
        {
          item: 'Βλίτα',
          equivalent: 'Μπάμιες',
        },
        {
          item: 'Γογγύλια',
          equivalent: 'Παντζάρια',
        },
        {
          item: 'Καρότα',
          equivalent: 'Πιπεριές',
        },
        {
          item: 'Κολοκυθάκια',
          equivalent: 'Πράσα',
        },
        {
          item: 'Κρεμμύδια',
          equivalent: 'Ραπανάκια',
        },
        {
          item: 'Κουνουπίδι',
          equivalent: 'Ραδίκια',
        },
        {
          item: 'Λάχανο',
          equivalent: 'Σέλινο',
        },
        {
          item: 'Λάχανο κατσαρό',
          equivalent: 'Σπανάκι',
        },
        {
          item: 'Λαχανάκια Βρυξελλών',
          equivalent: 'Σπαράγγια',
        },
        {
          item: 'Λάχανο τουρσί',
          equivalent: 'Τομάτες',
        },
        {
          item: 'Λαχανίδες',
          equivalent: 'Χυμός τομάτας ή λαχανικών',
        },
      ],
    },
    {
      type: 'fruit',
      title: 'Φρούτα και χυμοί',
      icon: <FEFruitIcon />,
      description:
        'Ένα ισοδύναμο περιέχει 15 g υδατάνθρακες και 60 Kcal. Τα γραμμάρια που αναφέρονται περιλαμβάνουν τη φλούδα και τα κουκούτσια.',
      data: [
        {
          heading: 'Fruit',
          table: [
            {
              item: 'Ακτινίδιο',
              quantity: '1 μεγάλο',
            },
            {
              item: 'Ανανάς',
              quantity: '¾ φλιτζανιού',
            },
            {
              item: 'Ανανάς κονσέρβα',
              quantity: '⅓ φλιτζανιού',
            },
            {
              item: 'Αχλάδι',
              quantity: '1 μικρό (~ 110 g)',
            },
            {
              item: 'Βερίκοκα',
              quantity: '3 μεγάλα (~ 200 g)',
            },
            {
              item: 'Γκρέιπφρουτ',
              quantity: '½ μεγάλο (~ 330 g)',
            },
            {
              item: 'Δαμάσκηνα',
              quantity: '2 μέτριο (~ 140 g)',
            },
            {
              item: 'Καρπούζι',
              quantity: '1 και ¼ φλιτζανιού (~ 380 g)',
            },
            {
              item: 'Κεράσια',
              quantity: '12 μεγάλα (~ 85 g)',
            },
            {
              item: 'Μάνγκο',
              quantity: '½ μικρό (~ 160 g)',
            },
            {
              item: 'Μανταρίνι',
              quantity: '2 μικρά (~ 220 g)',
            },
            {
              item: 'Νεκταρίνι',
              quantity: '1 μέτριο (~ 140 g)',
            },
            {
              item: 'Μήλο',
              quantity: '1 μικρό (~ 120 g)',
            },
            {
              item: 'Μούρα',
              quantity: '¾ φλιτζανιού',
            },
            {
              item: 'Μούσμουλα',
              quantity: '3 μέτριο (~ 170 g)',
            },
            {
              item: 'Μπανάνα',
              quantity: '1 μικρό (~ 120 g)',
            },
            {
              item: 'Πεπόνι',
              quantity: '1 φλιτζανιού (~ 280 g)',
            },
            {
              item: 'Πορτοκάλι',
              quantity: '1 μικρό (~ 180 g)',
            },
            {
              item: 'Ροδάκινο',
              quantity: '1 μέτριο (~ 110 g)',
            },
            {
              item: 'Ρόδι',
              quantity: '½ μέτριο',
            },
            {
              item: 'Σταφίδες',
              quantity: '2 κουταλιές σούπας',
            },
            {
              item: 'Σταφύλια',
              quantity: '15 μικρό (~ 85 g)',
            },
            {
              item: 'Σύκα',
              quantity: '2 μεγάλα',
            },
            {
              item: 'Φράουλες',
              quantity: '1 και ¼ φλιτζανιού',
            },
          ],
        },
        {
          heading: 'Χυμοί: (1 φλιτζάνι = 240 mL)',
          table: [
            {
              item: 'Ανανά',
              quantity: '½ φλιτζανιού',
            },
            {
              item: 'Γκρέϊπφρουτ',
              quantity: '½ φλιτζανιού',
            },
            {
              item: 'Δαμάσκηνου',
              quantity: '⅓ φλιτζανιού',
            },
            {
              item: 'Μήλου',
              quantity: '½ φλιτζανιού',
            },
            {
              item: 'Πορτοκαλιού',
              quantity: '½ φλιτζανιού',
            },
            {
              item: 'Σταφυλιού',
              quantity: '⅓ φλιτζανιού',
            },
            {
              item: 'Ανάμεικτος χυμός',
              quantity: '½ φλιτζανιού',
            },
          ],
        },
        {
          heading: 'Άλλα:',
          table: [
            {
              item: 'Μέλι/μαρμελάδα/ζάχαρη',
              quantity: '1 κουταλιά της σούπας',
            },
            {
              item: 'Αναψυκτικά με ζάχαρη / φρουτοποτά',
              quantity: '½ φλιτζανιού',
            },
          ],
        },
      ],
    },
    {
      type: 'carbs',
      title: 'Ψωμί - Δημητριακά - Όσπρια - Αμυλούχα Λαχανικά',
      icon: <FEBreadIcon />,
      description:
        'Ένα ισοδύναμο περιέχει 15 g υδατανθράκων, 3 g πρωτεϊνών, 0-1 g λιπιδίων και 80 Kcal.',
      data: [
        {
          heading: 'Ψωμί',
          table: [
            {
              item: 'Λευκό (όλων των τύπων)',
              quantity: '1 φέτα (30 g)',
            },
            {
              item: 'Ολικής άλεσης',
              quantity: '1 φέτα (30 g)',
            },
            {
              item: 'Ψωμάκια ατομικά',
              quantity: '1 φέτα (~30 g)',
            },
            {
              item: 'Ψωμί hot dog',
              quantity: '½ τεμάχιο',
            },
            {
              item: 'Σταφιδόψωμο',
              quantity: '1 φέτα (30 g)',
            },
            {
              item: 'Πίτα (π.χ. για σουβλάκι, τύπου αραβική)',
              quantity: '½ τεμάχιο',
            },
            {
              item: 'Φρυγανιές (μικρές)',
              quantity: '2 τεμάχια',
            },
            {
              item: 'Κουλούρι Θεσσαλονίκης (μέτριο)',
              quantity: '½ τεμάχιο',
            },
            {
              item: 'Κριτσίνια',
              quantity: '2 μεγάλα (~20g)',
            },
            {
              item: 'Κράκερ',
              quantity: '4 τεμάχια (~20g)',
            },
            {
              item: 'Κρουτόν (χωρίς λίπος)',
              quantity: '1 φλιτζάνι',
            },
          ],
        },
        {
          heading: 'Δημητριακά προϊόντα',
          table: [
            {
              item: 'Αλεύρι',
              quantity: '3 κουταλιές σούπας',
            },
            {
              item: 'Νιφάδες βρώμης, σιταριού, καλαμποκιού',
              quantity: '½ φλιτζανιού',
            },
            {
              item: 'Μούσλι',
              quantity: '¼ φλιτζανιού',
            },
            {
              item: 'Πίτουρο σιταριού, πίτουρο βρώμης',
              quantity: '⅓ φλιτζανιού',
            },
            {
              item: 'Δημητριακά πρωινού χωρίς ζάχαρη',
              quantity: '¾ φλιτζανιού',
            },
            {
              item: 'Ρύζι (μαγειρεμένο) άσπρο ή καστανό',
              quantity: '⅓ φλιτζανιού',
            },
            {
              item: 'Μακαρόνια-κριθαράκι-χυλοπίτες (μαγειρ.)',
              quantity: '⅓ φλιτζανιού',
            },
            {
              item: 'Noodles',
              quantity: '½ φλιτζανιού',
            },
            {
              item: 'Πλιγούρι',
              quantity: '½ φλιτζανιού',
            },
          ],
        },
        {
          heading:
            'Όσπρια (περιέχουν και πρωτεΐνες όσες ένα ισοδύναμο πολύ άπαχου κρέατος/ισοδύναμο)',
          table: [
            {
              item: '"Φασόλια (όλα τα είδη) (μαγειρεμένα στραγγισμένα)"',
              quantity: '½ φλιτζανιού',
            },
            {
              item: '"Φάβα, ρεβίθια, φακές (μαγειρεμένα στραγγισμένα)"',
              quantity: '½ φλιτζανιού',
            },
          ],
        },
        {
          heading: 'Αμυλούχα λαχανικά',
          table: [
            {
              item: 'Αρακάς (μαγειρεμένος)',
              quantity: '½ φλιτζανιού',
            },
            {
              item: 'Φασολάκια πράσινα με καρπό (μαγειρεμένα)',
              quantity: '½ φλιτζανιού',
            },
            {
              item: 'Πατάτες ψητές ή βραστές',
              quantity: '1 μικρό egg-size (85 g)',
            },
            {
              item: 'Πατάτα πουρέ',
              quantity: '½ φλιτζανιού',
            },
            {
              item: 'Γλυκοπατάτα',
              quantity: '⅓ φλιτζανιού',
            },
            {
              item: 'Καλαμπόκι βραστό',
              quantity: '½ φλιτζανιού',
            },
            {
              item: 'Καλαμπόκι ολόκληρο',
              quantity: '1 μέτριο (140 g)',
            },
            {
              item: 'Ποπ κορν (χωρίς προσθήκη λιπαρής ύλης)',
              quantity: '3 φλιτζάνια',
            },
          ],
        },
      ],
    },
    {
      type: 'carbs_other',
      title: 'Άλλα ισοδύναμα υδατανθράκων',
      icon: <FEOtherCarbIcon />,
      description:
        'Σ’ αυτήν την κατηγορία περιλαμβάνονται τρόφιμα που περιέχουν κατά κύριο λόγο υδατάνθρακες (κυρίως απλούς) και ίσως και λιπίδια (1 ισοδύναμο αμύλου ή φρούτου ισοδυναμεί με 15 g υδατανθράκων και 1 ισοδύναμο λίπους ισοδυναμεί με 5 g λιπιδίων). Ωστόσο, από συνταγή σε συνταγή και από παρασκευαστή σε παρασκευαστή μπορεί να υπάρχει πολύ μεγάλη απόκλιση στα περιεχόμενα μακροθρεπτικά συστατικά, γι’ αυτό και η ανάγνωση των ετικετών των τροφίμων κρίνεται απαραίτητη.',
      data: [
        {
          item: 'Βάφλα',
          quantity: '1 μικρό',
          equivalent: '1 ισοδ. αμύλου ή φρούτου, 1 ισοδ. λίπους',
        },
        {
          item: 'Κέικ απλό',
          quantity: '1 λεπτή φέτα',
          equivalent: '1 ισοδ. αμύλου ή φρούτου, 1 ισοδ. λίπους',
        },
        {
          item: 'Ντόνατ απλό',
          quantity: '1 μέτριο',
          equivalent: '2 ισοδ. αμύλου ή φρούτου, 1 ισοδ. λίπους',
        },
        {
          item: 'Ντόνατ γεμιστό',
          quantity: '1 μικρό',
          equivalent: '2 ισοδ. αμύλου ή φρούτου, 1 ισοδ. λίπους',
        },
        {
          item: 'Μπισκότα γεμιστά',
          quantity: '2 μικρά',
          equivalent: '1 ισοδ. αμύλου ή φρούτου, 1 ισοδ. λίπους',
        },
        {
          item: 'Κρέμα άνθος αραβοσίτου',
          quantity: '½ φλιτζανιού',
          equivalent: '1 ισοδ. αμύλου, ½ ισοδ. λίπους',
        },
        {
          item: 'Κρέμα καραμελέ',
          quantity: '½ φλιτζανιού',
          equivalent: '1 ισοδ. αμύλου, ½ ισοδ. λίπους',
        },
        {
          item: 'Παγωτό απλό (βανίλια, σοκολάτα, καϊμάκι)',
          quantity: '65 g',
          equivalent: '1 ισοδ. αμύλου, 1 ισοδ. λίπους',
        },
        {
          item: 'Σοκολάτα απλή',
          quantity: '25 g',
          equivalent: '1 ισοδ. αμύλου, 1,5 ισοδ. λίπους',
        },
        {
          item: 'Σορμπέ',
          quantity: '½ φλιτζανιού',
          equivalent: '2 ισοδ. αμύλου ή φρούτου',
        },
        {
          item: 'Πατάτες τηγανιτές',
          quantity: '8 τεμάχια',
          equivalent: '1 ισοδ. αμύλου, 1 ισοδ. λίπους',
        },
        {
          item: 'Πατατάκια',
          quantity: '15 τεμάχια',
          equivalent: '1 ισοδ. αμύλου, 2 ισοδ. λίπους',
        },
      ],
    },
    {
      type: 'protein',
      title: 'Κρέας και υποκατάστατα',
      icon: <FEMeatIcon />,
      description:
        'Ένα ισοδύναμο περιέχει 7 g πρωτεϊνών και ποσότητα λιπιδίων που ποικίλλει ανά κατηγορία.',
      note: '*Σημ: Κάθε ισοδύναμο οσπρίου περιέχει και 15 g υδατανθράκων.',
      data: [
        {
          title: 'Πολύ άπαχο κρέας και υποκατάστατα (0-1 g λιπιδίων)',
          description:
            '30 g λευκού κρέατος από κοτόπουλο και γαλοπούλα (χωρίς το δέρμα), μπακαλιάρου, γαλέου, γλώσσας, τόνου (φρέσκου ή κονσέρβας σε νερό), μυδιών, καβουριού, αστακού, γαρίδων, πίνων, πάπιας, φασιανού (χωρίς δέρμα), ελαφιού, στρουθοκαμήλου, τυριού cottage άπαχου, ζαμπόν γαλοπούλας άπαχου. ½ φλιτζανιού οσπρίων (φασόλια, φακές, φάβα, ρεβίθια, μαγειρεμένα και στραγγισμένα)*',
        },
        {
          title: 'Άπαχο κρέας και υποκατάστατα (3 g λιπιδίων)',
          description:
            '30 g γαλοπούλας (σκούρο κρέας, χωρίς δέρμα), κοτόπουλου (λευκό κρέας με δέρμα), πάπιας (χωρίς δέρμα), κουνελιού, μπον φιλέ, κόντρα, μπριζόλας χοιρινής με κόκαλο (αφού αφαιρεθεί και το ελάχιστο ορατό λίπος), ψαρονεφριού, χοιρομεριού άπαχου (πόδι, πλευρά, ώμος), ρέγκας, σαρδέλας (κονσέρβα), σολομού (φρέσκου ή κονσερβοποιημένου), τόνου (σε λάδι, αποστραγγισμένου), συκωτιού, καρδιάς. 6 μέτρια στρείδια, ¼ φλιτζανιού τυρί cottage 4,5% λίπος.',
        },
        {
          title: 'Μέσης περιεκτικότητας σε λίπος κρέας και υποκατάστατα (5 g λιπιδίων)',
          description:
            '30 g κοτόπουλου και γαλοπούλας (σκούρο κρέας με δέρμα), μοσχαρίσιου κιμά με λίπος 15%, μπριζόλας, βρασμένου χοιρομεριού, κιμά χοιρινού, παϊδάκια ψητά, λιπαρών ψαριών που δεν αναφέρονται στις προηγούμενες ομάδες, ανθότυρου, φέτας, μοτσαρέλας. 1 αυγό (πλούσιο σε χοληστερόλη).',
        },
        {
          title: 'Πλούσια σε λίπος κρέας και υποκατάστατα (8 g λιπιδίων)',
          description:
            '30 g χοιρινά παϊδάκια, λουκάνικων, σαλαμιού, τηγανιτών ψαριών, τυριών όπως γραβιέρα, κασέρι, κεφαλογραβιέρα, κεφαλοτύρι, μετσοβόνε, κοπανιστή, παρμεζάνα, cheddar, edam.',
        },
      ],
    },
    {
      type: 'alcohol',
      title: 'Αλκοολούχων ποτών',
      icon: <FEAlcoholIcon />,
      description:
        'Σ’ αυτήν την κατηγορία περιλαμβάνονται οι διάφορες επιλογές αλκοολούχων ποτών τα οποία, στις ποσότητες που αναφέρονται, θεωρούνται ότι ισοδυναμούν περίπου με 100 Kcal. Σε κάποια ποτά με περισσότερη περιεκτικότητα σε υδατάνθρακες ή με προσθήκη ζάχαρης θα πρέπει να υπολογίζεται και 1 ή ½ ισοδύναμο φρούτου.',
      data: [
        {
          item: 'Κόκκινο κρασί',
          quantity: '120 mL',
          equivalent: '1 ισοδύναμο αλκοόλ',
        },
        {
          item: 'Λευκό κρασί',
          quantity: '120 mL',
          equivalent: '1 ισοδύναμο αλκοόλ',
        },
        {
          item: 'Γλυκό κρασί',
          quantity: '100 mL',
          equivalent: '1 ισοδύναμο αλκοόλ, 1 ισοδύναμο φρούτου',
        },
        {
          item: 'Μπύρα (4,5% αλκοόλ)',
          quantity: '330 mL',
          equivalent: '1 ισοδύναμο αλκοόλ, 1 ισοδύναμο φρούτου',
        },
        {
          item: 'Λικέρ',
          quantity: '30 mL',
          equivalent: '1 ισοδύναμο αλκοόλ, 1 ισοδύναμο φρούτου',
        },
        {
          item: 'Ρούμι, ουίσκι, βότκα, τζιν',
          quantity: '30 mL',
          equivalent: '1 ισοδύναμο αλκοόλ',
        },
      ],
    },
    {
      type: 'fat',
      title: 'Λίπη και έλαια',
      icon: <FEFatsIcon />,
      description: 'Ένα ισοδύναμο περιέχει 5 g λιπιδίων και 45 Kcal.',
      data: [
        {
          heading: 'Ομάδα μονοακόρεστων λιπιδίων',
          table: [
            {
              item: 'Αβοκάντο',
              quantity: '30 g',
            },
            {
              item: 'Ελαιόλαδο',
              quantity: '1 κουταλιά του γλυκού',
            },
            {
              item: 'Ελιές',
              quantity: '10 μικρά or 5 μεγάλα',
            },
            {
              item: 'Σησαμόσποροι',
              quantity: '1 κουταλιά της σούπας',
            },
            {
              item: 'Ταχίνι',
              quantity: '2 κουταλιές του γλυκού',
            },
            {
              item: 'Αμύγδαλα',
              quantity: '6 τεμάχια',
            },
            {
              item: 'Καρύδια',
              quantity: '2 ολόκληρα',
            },
            {
              item: 'Κάσιους',
              quantity: '6 τεμάχια',
            },
            {
              item: 'Κουκουνάρι',
              quantity: '1 κουταλιά της σούπας',
            },
            {
              item: 'Πασατέμπο/ηλιόσποροι',
              quantity: '1 κουταλιά της σούπας',
            },
            {
              item: 'Φιστίκια',
              quantity: '10 τεμάχια',
            },
            {
              item: 'Φιστικοβούτυρο',
              quantity: '½ κουταλιά της σούπας',
            },
          ],
        },
        {
          heading: 'Ομάδα πολυακόρεστων λιπιδίων',
          table: [
            {
              item: 'Μαργαρίνη',
              quantity: '1 κουταλιά του γλυκού',
            },
            {
              item: 'Μαγιονέζα',
              quantity: '2 κουταλιές του γλυκού',
            },
            {
              item: 'Μαγιονέζα light',
              quantity: '1 κουταλιά της σούπας',
            },
            {
              item: 'Σάλτσα για σαλάτα',
              quantity: '1 κουταλιά της σούπας',
            },
            {
              item: 'Καλαμποκέλαιο',
              quantity: '1 κουταλιά του γλυκού',
            },
            {
              item: 'Ηλιέλαιο',
              quantity: '1 κουταλιά του γλυκού',
            },
            {
              item: 'Σογιέλαιο',
              quantity: '1 κουταλιά του γλυκού',
            },
          ],
        },
        {
          heading: 'Ομάδα κορεσμένων λιπιδίων',
          table: [
            {
              item: 'Βούτυρο',
              quantity: '1 κουταλιά του γλυκού',
            },
            {
              item: 'Κρέμα γάλακτος πλήρης',
              quantity: '1 κουταλιά της σούπας',
            },
            {
              item: 'Κρέμα γάλακτος light',
              quantity: '2 κουταλιές σούπας',
            },
            {
              item: 'Μπέικον',
              quantity: '1 φέτα',
            },
          ],
        },
      ],
    },
    {
      type: 'other',
      title: 'Ομάδα τροφίμων ελεύθερης κατανάλωσης',
      icon: <FEOtherIcon />,
      description:
        'Σ’ αυτήν την ομάδα ανήκουν τρόφιμα ή ποτά που περιέχουν λιγότερες από 20 Kcal ή λιγότερο από 5 g υδατανθράκων ανά ισοδύναμο. Για τα τρόφιμα αυτής της ομάδας δεν υπολογίζονται ενέργεια και μακροθρεπτικά συστατικά.',
      data: web ? dataWeb : dataMob,
    },
  ];
};
