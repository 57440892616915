import { useEffect, useMemo, useState } from 'react';
import {
  useGetFirstLogOfGoalQuery,
  useGetLatestLogQuery,
  useGetPressureGoalQuery,
} from '../../hypertension/queries/hypertensionQueries';
import { goalProgress } from '../../../utils/goalProgress';

const useGetDiastolicGoalData = () => {
  const [startingDate, setStartingDate] = useState<string>();

  const { data: pressureGoal } = useGetPressureGoalQuery();

  const { data: current } = useGetLatestLogQuery();
  const { data: starting } = useGetFirstLogOfGoalQuery(
    {
      start: startingDate || '',
    },
    { skip: !startingDate },
  );

  useEffect(() => {
    if (pressureGoal && pressureGoal.length > 0) {
      setStartingDate(pressureGoal[0].startDate);
    }
  }, [pressureGoal]);

  return useMemo(() => {
    if (!pressureGoal || pressureGoal.length === 0) return null;

    let goalDiastolic;
    let startDiastolic;
    let currentDiastolic;
    let progressDiastolic = 0;

    if (pressureGoal && pressureGoal.length > 0) {
      goalDiastolic = pressureGoal[0].goal.diastolic;
    }

    if (starting && starting.documents.length > 0) {
      startDiastolic = starting.documents[0].diastolic;
    }

    if (current && current.documents.length > 0) {
      currentDiastolic = current.documents[0].diastolic;
      startDiastolic = startDiastolic || currentDiastolic;
    }

    if (goalDiastolic && startDiastolic && currentDiastolic) {
      progressDiastolic = goalProgress(currentDiastolic, goalDiastolic, startDiastolic);
    }

    return { progressDiastolic, goalDiastolic, startDiastolic, currentDiastolic };
  }, [pressureGoal, starting, current]);
};

export default useGetDiastolicGoalData;
