import { FC } from 'react';
import { Box } from '@mui/material';
import arms from '../assets/MAArms.png';
import legs from '../assets/MALegs.png';
import torso from '../assets/MATorso.png';
import start from '../assets/MaleAvatar.png';
import wholeBody from '../assets/MAWhole.png';
import { useTranslation } from 'react-i18next';

type Props = {
  width: string;
  selected: any;
  height: string;
};
const AvatarMale: FC<Props> = ({ width, height, selected }) => {
  const { t } = useTranslation();

  const selection = (value: string) => {
    switch (value) {
      case 'whole_body':
        return wholeBody;
      case 'torso':
        return torso;
      case 'arms':
        return arms;
      case 'legs':
        return legs;
      case 'default':
        return start;
      default:
        return start;
    }
  };

  return (
    <Box>
      <img
        width={width}
        height={height}
        src={selection(selected)}
        alt={`${t('fitness.workouts.labels.bodyPartMa')}`}
      />
    </Box>
  );
};

export default AvatarMale;
