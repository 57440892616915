import * as React from 'react';
import { SVGProps } from 'react';

const AppBarExpandedDarkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={16}
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_15_1541)">
      <rect width={22} height={16} rx={4} fill="#38D8E2" />
      <rect
        x={15}
        y={15}
        width={14}
        height={14}
        rx={3}
        transform="rotate(-180 15 15)"
        fill="#222222"
      />
      <path
        d="M6.00012 11.0002L10.0001 8.00024L6.00012 5.00024"
        stroke="#F2F1F3"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x={15}
        y={15}
        width={14}
        height={14}
        rx={3}
        transform="rotate(-180 15 15)"
        stroke="#F2F1F3"
        strokeWidth={2}
      />
    </g>
    <rect x={1} y={1} width={20} height={14} rx={3} stroke="#F2F1F3" strokeWidth={2} />
    <defs>
      <clipPath id="clip0_15_1541">
        <rect width={22} height={16} rx={4} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AppBarExpandedDarkIcon;
