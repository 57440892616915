import { Box, useTheme } from '@mui/material';
import React, { FC } from 'react';
import ToggleCard from '../cards/ToggleCard';
import ModalHeading from './ModalHeading';
import ModalMobile from './ModalMobile';

type ModalFiltersProps = {
  open: boolean;
  selected: string;
  dismiss: (newValue: string) => void;
  options: { label: string; value: string }[] | string[];
};
const ModalFilters: FC<ModalFiltersProps> = ({ open, selected, options, dismiss }) => {
  const theme = useTheme();

  const handelDismiss = (value: string) => {
    if (!value) {
      dismiss(selected);
      return;
    }
    dismiss(value);
  };

  const handleSetOpen = () => {
    handelDismiss(selected);
  };

  return (
    <ModalMobile open={open} setOpen={handleSetOpen}>
      <Box p={3}>
        <ModalHeading title={'Filters'} showCalendar={'none'} align={'left'} />
        {options.map((heading, index) => (
          <ToggleCard
            key={index}
            textTransform={'none'}
            setSelected={handelDismiss}
            backgroundColor={theme.palette.secondary.main}
            value={typeof heading !== 'string' ? heading.value : heading}
            label={typeof heading !== 'string' ? heading.label : heading}
            selected={
              selected === (typeof heading !== 'string' ? heading.value : heading)
            }
          />
        ))}
      </Box>
    </ModalMobile>
  );
};

export default ModalFilters;
