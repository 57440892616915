import api from '../../../redux/api';
import { ApiResult, AuthResponse, AuthUser } from '@hdcorner/ui-library';

const signInQueries = api.injectEndpoints({
  endpoints: build => ({
    getAuthUser: build.query<AuthUser[], void>({
      query: () => ({
        method: 'GET',
        url: '/database/function/getAuthUser',
        params: { populate: 'profilePicture' },
      }),
      providesTags: ['AuthUser'],
    }),
    signIn: build.mutation<AuthResponse, { email: string; password: string }>({
      query: ({ email, password }) => ({
        method: 'POST',
        body: { email, password },
        url: '/authentication/local',
      }),
      invalidatesTags: ['AuthUser'],
    }),
    signOut: build.mutation<ApiResult<string>, void>({
      query: () => ({
        method: 'POST',
        url: '/authentication/logout',
      }),
    }),
    signInWithFacebook: build.mutation<AuthResponse, { accessToken: string }>({
      query: ({ accessToken }) => ({
        method: 'POST',
        url: '/authentication/facebook',
        body: { access_token: accessToken },
      }),
      invalidatesTags: ['AuthUser'],
    }),
    signInWithFacebookInit: build.query<{ result: string }, void>({
      query: () => ({
        method: 'GET',
        url: '/authentication/init/facebook',
      }),
      providesTags: ['AuthUser'],
    }),
    signInWithGoogle: build.mutation<AuthResponse, { accessToken: string }>({
      query: ({ accessToken }) => ({
        method: 'POST',
        url: '/authentication/google',
        body: { access_token: accessToken },
      }),
      invalidatesTags: ['AuthUser'],
    }),
    signInWithGoogleInit: build.query<{ result: string }, void>({
      query: () => ({
        method: 'GET',
        url: '/authentication/init/google',
      }),
      providesTags: ['AuthUser'],
    }),
    signInWithApple: build.mutation<AuthResponse, { accessToken: string }>({
      query: ({ accessToken }) => ({
        method: 'POST',
        url: '/authentication/apple',
        body: { access_token: accessToken },
      }),
      invalidatesTags: ['AuthUser'],
    }),
    signInWithAppleInit: build.query<{ result: string }, void>({
      query: () => ({
        method: 'GET',
        url: '/authentication/init/apple',
      }),
      providesTags: ['AuthUser'],
    }),
  }),
});

export const {
  useSignInMutation,
  useSignOutMutation,
  useGetAuthUserQuery,
  useLazyGetAuthUserQuery,
  useSignInWithAppleMutation,
  useSignInWithGoogleMutation,
  useSignInWithFacebookMutation,
  useLazySignInWithAppleInitQuery,
  useLazySignInWithGoogleInitQuery,
  useLazySignInWithFacebookInitQuery,
} = signInQueries;

export default signInQueries;
