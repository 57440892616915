export const goalProgress = (current: number, goal: number, start: number) => {
  const progress: number = ((current - start) / (goal - start)) * 100;
  if (progress > 100) return 100;
  if (progress < 0) return 0;
  return Math.round(progress);
};

export const goalProgressHypertension = (
  current: { systolic: number; diastolic: number },
  goal: { systolic: number; diastolic: number },
  start: { systolic: number; diastolic: number },
) => {
  const systolicProgress: number =
    ((current.systolic - start.systolic) / (goal.systolic - start.systolic)) * 100;
  const diastolicProgress: number =
    ((current.diastolic - start.diastolic) / (goal.diastolic - start.diastolic)) * 100;

  const combinedProgress = (systolicProgress + diastolicProgress) / 2;
  if (combinedProgress > 100) return 100;
  if (combinedProgress < 0) return 0;
  return Math.round(combinedProgress);
};

/**
 * Needed for incremental progress that needs to be tallied
 * Steps | Activities
 */
export const incrementalProgress = (total: number, goal: number) => {
  const progress = Math.round((total / goal) * 100);
  if (progress > 100) return 100;
  if (progress < 0) return 0;
  return progress;
};
