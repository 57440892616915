import { Box, BoxProps, styled, useMediaQuery, useTheme } from '@mui/material';
import { FC, useMemo } from 'react';
import HealthDataSQ from '../assets/HealthDataSQ';
import ProgramsSQ from '../assets/ProgramsSQ';
import ExerciseSQ from '../assets/ExerciseSQ';
import MedicationSQ from '../assets/MedicationSQ';
import DiabetesSQ from '../assets/DiabetesSQ';
import HypertensionSQ from '../assets/HypertensionSQ';
import CVDRiskSQ from '../assets/CVDRiskSQ';
import LipidSQ from '../assets/LipidSQ';
import MyDataCard from './cards/MyDataCard';
import DashboardCardBig from './cards/DashboardCardBig';
import NutritionIconAdmin from '../assets/NutritionIconAdmin';
import { useTranslation } from 'react-i18next';
import BlurredOverlayLocked from './BlurredOverlayLocked';

const IconBox = styled(Box, {
  shouldForwardProp: prop => prop !== 'backColor',
})<BoxProps & { backColor: string }>(({ backColor }) => ({
  width: '24px',
  height: '24px',
  display: 'flex',
  padding: '4px',
  borderRadius: '4px',
  alignItems: 'center',
  boxSizing: 'border-box',
  justifyContent: 'center',
  backgroundColor: backColor,
}));

type ModuleTypes =
  | 'healthdata'
  | 'fitness'
  | 'programs'
  | 'medication'
  | 'diabetes'
  | 'hypertension'
  | 'cvd'
  | 'lipid'
  | 'nutrition';

type MyDataType = {
  data: Record<
    ModuleTypes,
    {
      premium?: boolean;
      title: string;
      date: string;
      users?: string;
      twoColumn?: boolean;
      data: {
        label: string;
        info: string;
        trend?: 'ascending' | 'descending' | 'stable';
      }[];
    }
  >[];
  minWidth?: number | string;
  handleClickCard?: (value: string) => void;
};

const MyDataComponent: FC<MyDataType & BoxProps> = ({
  data,
  minWidth,
  handleClickCard,
  ...boxProps
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const web = useMediaQuery(theme.breakpoints.up('md'));

  const dataArr = useMemo(() => {
    return data.map(item => {
      const fieldName = Object.keys(item)[0];
      const value = Object.values(item)[0];

      return { fieldName, value };
    });
  }, [data]);

  const handleColor = (color: string) => {
    switch (color) {
      case 'healthdata':
        return theme.palette.nutrition.main;
      case 'fitness':
        return theme.palette.exercise.main;
      case 'programs':
        return theme.palette.program.main;
      case 'medication':
        return theme.palette.medication.main;
      case 'diabetes':
        return theme.palette.diabetes.main;
      case 'hypertension':
        return theme.palette.hypertension.main;
      case 'cvd':
        return theme.palette.mode === 'dark'
          ? theme.palette.warning.main
          : theme.palette.cvdRisk.main;
      case 'lipid':
        return theme.palette.lipid.main;
      case 'nutrition':
        return theme.palette.nutrition.main;
      default:
        return theme.palette.kmColorsCoolGrey.main;
    }
  };

  const handleIcon = (type: string) => {
    switch (type) {
      case 'healthdata':
        return <HealthDataSQ />;
      case 'fitness':
        return <ExerciseSQ />;
      case 'programs':
        return <ProgramsSQ />;
      case 'medication':
        return <MedicationSQ />;
      case 'diabetes':
        return <DiabetesSQ />;
      case 'hypertension':
        return <HypertensionSQ />;
      case 'cvd':
        return (
          <CVDRiskSQ
            color={
              theme.palette.mode === 'dark'
                ? theme.palette.kmColorsButtonText.main
                : undefined
            }
          />
        );
      case 'lipid':
        return (
          <LipidSQ
            color={
              theme.palette.mode === 'dark'
                ? theme.palette.kmColorsButtonText.main
                : undefined
            }
          />
        );
      case 'nutrition':
        return <NutritionIconAdmin />;
    }
  };

  const renderHeading = (item: any) => {
    if (item.value.title && item.value.users) {
      return `${item.value.title} ${item.value.users}`;
    }

    if (item.value.title && !item.value.users) {
      return `${item.value.title}`;
    }

    return t('general.labels.nA');
  };

  return (
    <Box display={'flex'} flexWrap={'wrap'} gap={2} {...boxProps}>
      {dataArr.map((item, index) => {
        const type = item.fieldName.toLowerCase();
        const premium = item.value.premium === undefined ? true : item.value.premium;

        return (
          <Box flex={1} display={'flex'} position={'relative'} key={`${type}${index}`}>
            <BlurredOverlayLocked module={item.value.title} premium={premium} />
            <Box
              flexGrow={1}
              display={'flex'}
              sx={{
                position: 'relative',
                borderRadius: theme.spacing(web ? 1 : 2.5),
                '::before': premium
                  ? {}
                  : {
                      top: 0,
                      left: 0,
                      zIndex: 1,
                      opacity: 0.8,
                      width: '100%',
                      height: '100%',
                      content: '""',
                      position: 'absolute',
                      background: handleColor(type),
                      borderRadius: theme.spacing(web ? 1 : 2.5),
                    },
              }}
            >
              {web ? (
                <MyDataCard
                  boxSize={'tallNarrow'}
                  sx={{ minWidth: minWidth }}
                  heading={renderHeading(item)}
                  time={item.value.date || t('general.labels.nA')}
                  icon={
                    <IconBox backColor={handleColor(type)}>{handleIcon(type)}</IconBox>
                  }
                  cards={item.value.data.map(val => ({
                    direction: val.trend,
                    value: val.info || t('general.labels.nA'),
                    label: val.label || t('general.labels.nA'),
                  }))}
                />
              ) : (
                <DashboardCardBig
                  icon={handleIcon(type)}
                  color={handleColor(type)}
                  twoColumns={item.value.twoColumn}
                  onClick={() => handleClickCard?.(type)}
                  time={item.value.date || t('general.labels.nA')}
                  data={item.value.data || t('general.labels.nA')}
                  title={item.value.title || t('general.labels.nA')}
                />
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default MyDataComponent;
