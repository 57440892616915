import PageLayout from '../../components/PageLayout';
import { ComponentsLayout } from '@hdcorner/ui-library';
import FoodEquivalentsInner from './components/FoodEquivalentsInner';
import { useTranslation } from 'react-i18next';

const FoodEquivalents = () => {
  const { t } = useTranslation();

  return (
    <PageLayout
      defaultHref={'/dashboard/fitness/nutrition'}
      headerTitle={t('fitness.calories.titles.foodEquiv')}
    >
      <ComponentsLayout>
        <FoodEquivalentsInner />
      </ComponentsLayout>
    </PageLayout>
  );
};

export default FoodEquivalents;
