import api from '../../../redux/api';
import { PaginationResult, ReminderData, ReminderParams } from '@hdcorner/ui-library';

const remindersQueries = api.injectEndpoints({
  endpoints: build => ({
    getReminders: build.query<PaginationResult<ReminderData>, { params: ReminderParams }>(
      {
        query: ({ params }) => ({
          method: 'GET',
          params: { ...params },
          url: '/database/function/getReminders',
        }),
        providesTags: ['ReminderData'],
      },
    ),

    getReminderById: build.query<ReminderData[], { id: string | undefined }>({
      query: ({ id }) => ({
        method: 'GET',
        url: `/database/function/getReminderById/${id}`,
      }),
      providesTags: ['ReminderData'],
    }),

    getMedicationReminders: build.query<ReminderData, void>({
      query: () => ({
        method: 'GET',
        url: '/database/function/getMedicationReminders',
      }),
      providesTags: ['ReminderData'],
    }),

    addNewReminder: build.mutation<void, Omit<ReminderData, '_id'>>({
      query: ({ ...rest }) => ({
        body: rest,
        method: 'POST',
        url: '/hdCornerService/user/reminder',
      }),
      invalidatesTags: ['ReminderData'],
    }),

    editReminder: build.mutation<void, ReminderData>({
      query: ({ _id, ...rest }) => ({
        body: rest,
        method: 'PATCH',
        url: `/hdCornerService/user/reminder/${_id}`,
      }),
      invalidatesTags: ['ReminderData'],
    }),

    deleteReminder: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        method: 'DELETE',
        url: `/hdCornerService/user/reminder/${id}`,
      }),
      invalidatesTags: ['ReminderData'],
    }),
  }),
});

export const {
  useGetRemindersQuery,
  useGetReminderByIdQuery,
  useAddNewReminderMutation,
  useDeleteReminderMutation,
  useEditReminderMutation,
} = remindersQueries;

export default remindersQueries;
