import React, { Dispatch, FC, SetStateAction } from 'react';
import TakeIcon from '../../../assets/icons/TakeIcon';
import { Button, useTheme } from '@mui/material';
import RescheduleIcon from '../../../assets/icons/RescheduleIcon';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

type ActionButtonSmallTakeProps = {
  taken: boolean;
  disabled?: boolean;
  setTaken: Dispatch<SetStateAction<boolean>>;
};
const ActionButtonSmallTake: FC<ActionButtonSmallTakeProps> = ({
  taken,
  disabled,
  setTaken,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const handleClickTake = () => {
    setTaken(taken => !taken);
  };

  return (
    <Button
      fullWidth
      disableRipple
      disableElevation
      disabled={disabled}
      variant={'contained'}
      onClick={handleClickTake}
      startIcon={
        <TakeIcon
          color={taken ? theme.palette.secondary.light : theme.palette.success.main}
        />
      }
      sx={{
        height: '40px',
        backgroundColor: taken
          ? theme.palette.success.main
          : theme.palette.secondary.light,
        color: taken ? theme.palette.secondary.main : theme.palette.success.main,
        boxShadow: 'none',
        minWidth: 'max-content',
        paddingInline: theme.spacing(2),
        '&:disabled': {
          opacity: 0.7,
          color: taken ? theme.palette.secondary.main : theme.palette.success.main,
          backgroundColor: taken
            ? theme.palette.success.main
            : theme.palette.secondary.light,
        },
      }}
    >
      {taken ? t('buttons.taken') : t('buttons.take')}
    </Button>
  );
};

type ActionButtonSmallRescheduleProps = {
  disabled?: boolean;
  rescheduled: boolean;
  onClick: () => void;
};

const ActionButtonSmallReschedule: FC<ActionButtonSmallRescheduleProps> = ({
  disabled,
  rescheduled,
  onClick,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const handleClickReschedule = () => {
    onClick();
  };

  return (
    <Button
      fullWidth
      disableRipple
      disableElevation
      disabled={disabled}
      variant={'contained'}
      onClick={handleClickReschedule}
      startIcon={
        <RescheduleIcon
          color={rescheduled ? theme.palette.secondary.light : theme.palette.error.main}
        />
      }
      sx={{
        height: '40px',
        boxShadow: 'none',
        minWidth: 'max-content',
        paddingInline: theme.spacing(2),
        color: rescheduled ? theme.palette.secondary.light : theme.palette.error.main,
        backgroundColor: rescheduled
          ? theme.palette.warning.main
          : theme.palette.secondary.light,
        '&:disabled': {
          opacity: 0.7,
          color: rescheduled ? '' : theme.palette.error.main,
          backgroundColor: rescheduled
            ? theme.palette.warning.main
            : theme.palette.secondary.light,
        },
      }}
    >
      {t('buttons.reschedule')}
    </Button>
  );
};

type ActionButtonsSmallProps = {
  taken: boolean;
  rescheduledTo: boolean;
  rescheduledFrom: boolean;
  onClickReschedule: () => void;
  onClickTake: () => void;
};

const ActionButtonsSmall: FC<ActionButtonsSmallProps> = ({
  taken,
  rescheduledFrom,
  rescheduledTo,
  onClickTake,
  onClickReschedule,
}) => {
  const theme = useTheme();

  const disableTake = taken || rescheduledTo;
  const disableReschedule = rescheduledTo || taken;
  const isRescheduled = rescheduledFrom || rescheduledTo;

  return (
    <Box display={'flex'} gap={theme.spacing(1)}>
      <ActionButtonSmallTake
        taken={taken}
        disabled={disableTake}
        setTaken={onClickTake}
      />
      <ActionButtonSmallReschedule
        rescheduled={isRescheduled}
        onClick={onClickReschedule}
        disabled={disableReschedule}
      />
    </Box>
  );
};

export default ActionButtonsSmall;
