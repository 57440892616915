import React, { FC } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import CustomInput from './controls/CustomInput';
import CustomButton from './controls/CustomButton';
import { useTranslation } from 'react-i18next';

type SignInInputFieldsProps = {
  email: string;
  password: string;
  handleSignIn: () => void;
  onInputClick?: () => void;
  handleForgotPassword?: () => void;
  setEmail: (email: string) => void;
  setPassword: (password: string) => void;
};
const SignInInputFields: FC<SignInInputFieldsProps> = ({
  email,
  setEmail,
  password,
  setPassword,
  onInputClick,
  handleSignIn,
  handleForgotPassword,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box gap={2} display={'flex'} flexDirection={'column'}>
      <Box>
        <CustomInput
          fullWidth
          value={email}
          type={'email'}
          onClick={onInputClick}
          handleChange={setEmail}
          label={t('personalDetails.email')}
          alternativeBackground={theme.palette.mode === 'dark'}
        />
      </Box>
      <Box>
        <CustomInput
          fullWidth
          value={password}
          type={'password'}
          handleChange={setPassword}
          label={t('profile.titles.pass')}
          alternativeBackground={theme.palette.mode === 'dark'}
        />
      </Box>

      <CustomButton
        fullWidth
        variant={'contained'}
        onClick={handleSignIn}
        disabled={!email || !password}
      >
        {t('buttons.signIn')}
      </CustomButton>

      <Box
        display={'flex'}
        textAlign={'center'}
        justifyContent={'center'}
        marginTop={theme.spacing(3)}
      >
        <CustomButton
          variant={'text'}
          onClick={handleForgotPassword}
          sx={{
            marginBottom: theme.spacing(3),
          }}
        >
          <Typography
            sx={{
              color:
                theme.palette.mode === 'dark'
                  ? theme.palette.kmColorsTurquoise.main
                  : theme.palette.kmColorsRed.main,
              fontWeight: theme.typography.fontWeightMedium,
            }}
          >
            {t('authentication.forgotReset.labels.forgotPass')}
          </Typography>
        </CustomButton>
      </Box>
    </Box>
  );
};

export default SignInInputFields;
