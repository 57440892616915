import React, { FC, useMemo, useState } from 'react';
import {
  Box,
  BoxProps,
  ButtonProps,
  Divider,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import {
  BloodGlucoseLog,
  CalendarIcon,
  CalendarModal,
  CustomButton,
  CustomInput,
  Filters,
  theme,
  ToggleCard,
  whenTakenDiabetes,
} from '@hdcorner/ui-library';
import {
  useAddBloodGlucoseLogMutation,
  useAddHbac1LogMutation,
  useGetBloodGlucoseGoalQuery,
  useGetBloodGlucoseStartingMeasurementQuery,
  useGetHbac1StartingMeasurementQuery,
  useGetHbacGoalQuery,
} from './queries/diabetesQueries';
import useAlert from '../../hooks/useAlert';
import moment from 'moment/moment';
import { Device, MicrolifeBPM } from '@quintessential/health-hid';
import { useUploadDeviceDataMutation } from '../../redux/queries/queries';
import { useAppDispatch } from '../../redux/hooks';
import { addGoalDetails } from '../dashboard/slices/congratulationsSlice';
import { goalProgress } from '../../utils/goalProgress';
import { useTranslation } from 'react-i18next';

const RightContainer = styled(Box)<BoxProps>(({ theme }) => ({
  width: 398,
  maxWidth: 398,
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),
  background: theme.palette.secondary.light,
}));

const LeftContainer = styled(Box)<BoxProps>(({ theme }) => ({
  width: 260,
  maxWidth: 260,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  padding: theme.spacing(3),
  background: theme.palette.secondary.main,
}));

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const CustomDateButton = styled(CustomButton)<ButtonProps>(({ theme }) => ({
  ...theme.typography.caption,
  textTransform: 'capitalize',
  color: theme.palette.diabetes.main,
  fontWeight: theme.typography.fontWeightBold,
  ':hover': {
    background: theme.palette.secondary.light,
  },
}));

const types = [
  { label: 'headingsTitles.bloodGluc', value: 'Blood Glucose' },
  { label: 'headingsTitles.hba1c', value: 'HbA1c' },
];

type Props = {
  onSaveSuccess: () => void;
};
const DiabetesModalWeb: FC<Props> = ({ onSaveSuccess }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { presentError, presentSuccess } = useAlert();

  const [date, setDate] = useState<string>(moment().toISOString());
  const [measurement, setMeasurement] = useState<string>('');
  const [openPicker, setOpenPicker] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<string>(Filters.DEFAULT);
  const [selectedType, setSelectedType] = useState<string>('Blood Glucose');
  const [inputError, setInputError] = useState({ value: false, message: '' });

  const [addHbac1Log] = useAddHbac1LogMutation();
  const [uploadData] = useUploadDeviceDataMutation();
  const [addBloodGlucoseLog] = useAddBloodGlucoseLogMutation();

  /**
   * Check if Glucose goal has been reached
   */
  const { data: glucoseGoal } = useGetBloodGlucoseGoalQuery();

  const glucGoalData = useMemo(() => {
    if (!glucoseGoal || glucoseGoal.length === 0) return { goal: 0, date: '' };
    return { goal: glucoseGoal[0].goal, date: glucoseGoal[0].startDate };
  }, [glucoseGoal]);

  const { data: glucStart } = useGetBloodGlucoseStartingMeasurementQuery({
    startDate: glucGoalData.date,
  });

  const glucStartData = useMemo(() => {
    if (!glucStart || glucStart.documents.length === 0) return 0;
    return glucStart.documents[0].measurement;
  }, [glucStart]);

  /**
   * Check if HbA1c goal has been reached
   */
  const { data: hbacGoal } = useGetHbacGoalQuery();

  const hbacGoalData = useMemo(() => {
    if (!hbacGoal || hbacGoal.length === 0) return { goal: 0, date: '' };
    return { goal: hbacGoal[0].goal, date: hbacGoal[0].startDate };
  }, [hbacGoal]);

  const { data: hbacStart } = useGetHbac1StartingMeasurementQuery({
    startDate: hbacGoalData.date,
  });

  const hbacStartData = useMemo(() => {
    if (!hbacStart || hbacStart.documents.length === 0) return 0;
    return hbacStart.documents[0].measurement;
  }, [hbacStart]);

  const handleUSBInput = () => {
    Device.requestDevice(['Microlife BPM']).then(async device => {
      if (!device) return;
      // await MicrolifeBPM.setDateTime(device, new Date());
      MicrolifeBPM.getData(device).then(data => {
        const prepareData: Partial<BloodGlucoseLog>[] = data.map((item: any) => {
          return {
            category: 'before_meal',
            measurement: item.blood_glucose,
            date: moment(item.date).toISOString(),
          };
        });
        uploadData({ logType: 'hypertension', uploadData: prepareData }).then(() => {
          presentSuccess(t('errors.generic.success01'));
        });
      });
    });
  };

  const handleSaveData = () => {
    if (selectedType === 'Blood Glucose') {
      const prepareData = {
        logDate: date,
        measurement: parseFloat(measurement),
        category: selectedCategory === Filters.DEFAULT ? undefined : selectedCategory,
      };
      if (parseFloat(measurement) < 30 || parseFloat(measurement) > 600) {
        setInputError({
          value: true,
          message: t('errors.diabetes.inputErrors.validRangeGlucose'),
        });
      } else {
        addBloodGlucoseLog(prepareData)
          .unwrap()
          .then(() => presentSuccess(t('errors.diabetes.successGlucose.logAdded')))
          .catch(() => presentError(t('errors.diabetes.errorGlucose.addLog')))
          .finally(() => {
            onSaveSuccess();
            setInputError({
              value: false,
              message: '',
            });
            if (
              goalProgress(prepareData.measurement, glucGoalData.goal, glucStartData) ===
                100 &&
              glucGoalData.date !== ''
            ) {
              setTimeout(() => {
                dispatch(
                  addGoalDetails({
                    category: 'blood glucose',
                    goal: { value: glucGoalData.goal, measure: 'mg/dL' },
                    start: {
                      value: glucStartData,
                      measure: 'mg/dL',
                      time: glucGoalData.date,
                    },
                  }),
                );
              }, 1500);
            }
          });
      }
    } else {
      if (parseFloat(measurement) < 4 || parseFloat(measurement) > 20) {
        setInputError({
          value: true,
          message: t('errors.diabetes.inputErrors.validRangeHba1c'),
        });
      } else {
        addHbac1Log({ logDate: date, measurement: parseFloat(measurement) })
          .unwrap()
          .then(() => presentSuccess(t('errors.diabetes.successHba1c.logAdded')))
          .catch(() => presentError(t('errors.diabetes.errorsHba1c.addLog')))
          .finally(() => {
            onSaveSuccess();
            setInputError({
              value: false,
              message: '',
            });
            if (
              goalProgress(parseFloat(measurement), hbacGoalData.goal, hbacStartData) ===
                100 &&
              hbacGoalData.date !== ''
            ) {
              setTimeout(() => {
                dispatch(
                  addGoalDetails({
                    category: 'HbA1c',
                    goal: { value: hbacGoalData.goal, measure: '%' },
                    start: {
                      value: hbacStartData,
                      measure: '%',
                      time: hbacGoalData.date,
                    },
                  }),
                );
              }, 1500);
            }
          });
      }
    }
  };

  return (
    <Box sx={{ flex: 1, height: '100%', display: 'flex' }}>
      <LeftContainer>
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <Title variant={'h1'}>{t('headingsTitles.diabetes')}</Title>
          <Box>
            {types.map(item => (
              <ToggleCard
                key={item.value}
                value={item.value}
                label={t(item.label)}
                textTransform={'none'}
                setSelected={setSelectedType}
                selected={selectedType === item.value}
                backgroundColor={theme.palette.secondary.light}
              />
            ))}
          </Box>
          <Box my={1}>
            <Divider />
          </Box>
          <Box>
            {selectedType === 'Blood Glucose' &&
              whenTakenDiabetes.map(item => (
                <ToggleCard
                  key={item.value}
                  value={item.value}
                  textTransform={'none'}
                  label={t(item.label)}
                  setSelected={setSelectedCategory}
                  selected={selectedCategory === item.value}
                  backgroundColor={theme.palette.secondary.light}
                />
              ))}
          </Box>
          <Box display={'flex'} flexDirection={'column'} gap={1}>
            <CustomButton
              fullWidth
              color={'primary'}
              variant={'contained'}
              onClick={handleSaveData}
              children={`${t('buttons.saveData')}`}
              disabled={!measurement || !selectedType}
            />
            <CustomButton
              fullWidth
              variant={'outlined'}
              onClick={handleUSBInput}
              children={`${t('buttons.usbInput')}`}
              style={{ color: '#4780EF', borderColor: '#4780EF' }}
            />
          </Box>
        </Box>
      </LeftContainer>
      <RightContainer>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          marginBottom={theme.spacing(5)}
        >
          <Title variant={'h1'}>{t('general.labels.dataInput')}</Title>
          <CustomDateButton
            disableRipple
            variant={'text'}
            onClick={() => setOpenPicker(true)}
            startIcon={<CalendarIcon color={theme.palette.diabetes.main} />}
          >
            {t('buttons.customDate')}
          </CustomDateButton>
        </Box>
        <CustomInput
          fullWidth
          type={'number'}
          value={measurement}
          error={inputError.value}
          handleChange={setMeasurement}
          helperText={inputError.message}
          label={t('general.labels.addMeasurement') as string}
          suffix={selectedType === 'Blood Glucose' ? `${t('measurements.mgdl')}` : '%'}
          placeholder={
            selectedType === 'Blood Glucose'
              ? `30-600 ${t('measurements.mgdl')}`
              : '4-20%'
          }
        />
      </RightContainer>
      <CalendarModal
        open={openPicker}
        selectedDate={date}
        setOpen={setOpenPicker}
        saveDate={date => setDate(date)}
      />
    </Box>
  );
};

export default DiabetesModalWeb;
