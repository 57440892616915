import React, { FC, ReactNode } from 'react';
import { IonFab, IonFabButton } from '@ionic/react';
import { AddIconRounded } from '@hdcorner/ui-library';
import { Box, BoxProps, Modal } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomBox = styled(Box)<BoxProps>(() => ({
  gap: 8,
  right: 15,
  bottom: 80,
  width: 'auto',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  scrollbarWidth: 'none',
  boxSizing: 'border-box',
  flexDirection: 'column',
  backgroundColor: 'none',
  msOverflowStyle: 'none',
  '::-webkit-scrollbar': {
    display: 'none',
  },
}));

type IonicFabMainProps = {
  children: ReactNode;
  openDialogFab: boolean;
  setOpenDialogFab: (value: boolean) => void;
};
const IonicFabMain: FC<IonicFabMainProps> = ({
  children,
  openDialogFab,
  setOpenDialogFab,
}) => {
  return (
    <>
      <IonFab slot="fixed" vertical="bottom" horizontal="end">
        <IonFabButton
          style={{ '--background': '#222222' }}
          onClick={() => setOpenDialogFab(true)}
        >
          <AddIconRounded />
        </IonFabButton>
      </IonFab>
      <Modal onClose={() => setOpenDialogFab(false)} open={openDialogFab}>
        <CustomBox>{children}</CustomBox>
      </Modal>
    </>
  );
};

export default IonicFabMain;
