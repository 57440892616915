import * as React from 'react';
import { ComponentsLayout, CustomButton, theme } from '@hdcorner/ui-library';
import { useEffect, useState } from 'react';
import { useIonRouter } from '@ionic/react';
import PageLayout from '../../components/PageLayout';
import MedicationRepeat from './components/MedicationRepeat';
import { useAppDispatch } from '../../redux/hooks';
import { setRepeat } from './medicationSlice';
import { dates, datesWeek } from './constants';
import { useTranslation } from 'react-i18next';

const MedicationRepeatPage = () => {
  const { t } = useTranslation();
  const router = useIonRouter();

  const dispatch = useAppDispatch();

  const [selectedRepeat, setSelectedRepeat] = useState<string>('Everyday');
  const [selectedDays, setSelectedDays] = useState<string[]>([]);

  useEffect(() => {
    setSelectedDays(datesWeek.map(it => it.value));
  }, [datesWeek]);

  const handleShowRepeatSelect = () => {
    dispatch(setRepeat(selectedDays));
    router.push('/dashboard/medication-add', 'back', 'pop');
  };

  return (
    <PageLayout
      headerTitle={'Repeat'}
      defaultHref={'/dashboard/medication'}
      headerColor={theme.palette.primary.main}
    >
      <ComponentsLayout display={'flex'} flexDirection={'column'} gap={theme.spacing(2)}>
        <MedicationRepeat
          dates={dates}
          datesWeek={datesWeek}
          selected={selectedRepeat}
          selectedDays={selectedDays}
          setSelected={setSelectedRepeat}
          setSelectedDays={setSelectedDays}
        />
        <CustomButton
          fullWidth
          disableElevation
          color={'primary'}
          variant={'contained'}
          onClick={handleShowRepeatSelect}
        >
          {t('buttons.save')}
        </CustomButton>
      </ComponentsLayout>
    </PageLayout>
  );
};

export default MedicationRepeatPage;
