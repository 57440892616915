import React, { FC, useMemo, useState } from 'react';
import { Box, BoxProps, styled, Typography } from '@mui/material';
import {
  CalendarModal,
  CustomButton,
  HypertensionLog,
  ModalHypertensionFields,
  theme,
  ToggleCard,
} from '@hdcorner/ui-library';
import {
  useAddHypertensionLogMutation,
  useGetFirstLogOfGoalQuery,
  useGetPressureGoalQuery,
} from '../queries/hypertensionQueries';
import useAlert from '../../../hooks/useAlert';
import moment from 'moment/moment';
import { Device, MicrolifeBPM } from '@quintessential/health-hid';
import { useUploadDeviceDataMutation } from '../../../redux/queries/queries';
import { goalProgress } from '../../../utils/goalProgress';
import { useAppDispatch } from '../../../redux/hooks';
import { addGoalDetails } from '../../dashboard/slices/congratulationsSlice';
import { useTranslation } from 'react-i18next';

const Container = styled(Box)<BoxProps>(() => ({
  flex: 1,
  height: '100%',
  display: 'flex',
}));

const RightContainer = styled(Box)<BoxProps>(({ theme }) => ({
  width: 398,
  maxWidth: 398,
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),
  background: theme.palette.secondary.light,
}));

const LeftContainer = styled(Box)<BoxProps>(({ theme }) => ({
  flex: 1,
  width: 260,
  maxWidth: 260,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  justifyContent: 'space-between',
  padding: theme.spacing(3),
  background: theme.palette.secondary.main,
}));

const measurementCategories = [
  {
    label: 'constants.hypertension.measurements.default',
    value: '',
  },
  {
    label: 'constants.hypertension.measurements.morning',
    value: 'morning',
  },
  {
    label: 'constants.hypertension.measurements.afternoon',
    value: 'afternoon',
  },
];

type Props = {
  onSaveSuccess: () => void;
};
const HypertensionModalWeb: FC<Props> = ({ onSaveSuccess }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { presentSuccess, presentError } = useAlert();

  const [afib, setAfib] = useState<boolean>(false);
  const [pulses, setPulses] = useState<string>('');
  const [systolic, setSystolic] = useState<string>('');
  const [selected, setSelected] = useState<string>('');
  const [diastolic, setDiastolic] = useState<string>('');
  const [errorPulses, setErrorPulses] = useState({ value: false, message: '' });
  const [errorSystolic, setErrorSystolic] = useState({ value: false, message: '' });
  const [errorDiastolic, setErrorDiastolic] = useState({ value: false, message: '' });

  const [openPicker, setOpenPicker] = useState<boolean>(false);
  const [date, setDate] = useState<string>(moment().toISOString());

  const [uploadData] = useUploadDeviceDataMutation();
  const [addHypertensionLog] = useAddHypertensionLogMutation();

  /**
   * Checks needed for Congrats modal
   */
  const { data: pressureGoal } = useGetPressureGoalQuery();
  const goalData = useMemo(() => {
    if (!pressureGoal || pressureGoal.length === 0) return { sys: 0, dia: 0, date: '' };
    return {
      sys: pressureGoal[0].goal.systolic,
      dia: pressureGoal[0].goal.diastolic,
      date: pressureGoal[0].createdAt,
    };
  }, [pressureGoal]);

  const { data: starting } = useGetFirstLogOfGoalQuery(
    {
      start: goalData.date,
    },
    { skip: !goalData.date },
  );

  const startData = useMemo(() => {
    if (!starting || starting.documents.length === 0)
      return { systolic: 0, diastolic: 0 };
    return {
      systolic: starting.documents[0].systolic,
      diastolic: starting.documents[0].diastolic,
    };
  }, [starting]);

  const handleUSBInput = () => {
    Device.requestDevice(['Microlife BPM']).then(async device => {
      if (!device) return;
      // await MicrolifeBPM.setDateTime(device, new Date());
      MicrolifeBPM.getData(device).then(data => {
        const prepareData: Partial<HypertensionLog>[] = data.map((item: any) => {
          return {
            pulses: item.pulse,
            systolic: item.systolicPressure,
            diastolic: item.diastolicPressure,
            date: moment(item.date).toISOString(),
            category: moment(item.date).hour() < 12 ? 'morning' : 'afternoon',
          };
        });
        uploadData({ logType: 'hypertension', uploadData: prepareData }).then(() => {
          presentSuccess(t('errors.generic.success01'));
        });
      });
    });
  };

  const handleSaveData = () => {
    const data = {
      afib: afib,
      logDate: date,
      pulses: parseInt(pulses),
      systolic: parseInt(systolic),
      diastolic: parseInt(diastolic),
      category: selected === '' ? undefined : selected,
    };

    const pulseValue = !(parseInt(pulses) > 220 || parseInt(pulses) < 30);
    const systolicValue = !(parseInt(systolic) > 250 || parseInt(systolic) < 80);
    const diastolicValue = !(parseInt(diastolic) > 150 || parseInt(diastolic) < 50);

    if (!pulseValue) {
      setErrorPulses({
        value: true,
        message: t('hypertension.inputValidation.pulses'),
      });
    } else {
      setErrorPulses({ value: false, message: '' });
    }

    if (!systolicValue) {
      setErrorSystolic({
        value: true,
        message: t('hypertension.inputValidation.systolic'),
      });
    } else {
      setErrorSystolic({ value: false, message: '' });
    }

    if (!diastolicValue) {
      setErrorDiastolic({
        value: true,
        message: t('hypertension.inputValidation.diastolic'),
      });
    } else {
      setErrorDiastolic({ value: false, message: '' });
    }
    if (pulseValue && systolicValue && diastolicValue) {
      addHypertensionLog({ ...data })
        .unwrap()
        .then(() => {
          presentSuccess(t('errors.hypertension.successAddLog'));
        })
        .catch(() => {
          presentError(t('errors.hypertension.errorAddLog'));
        })
        .finally(() => {
          onSaveSuccess();
          if (
            goalData.date !== '' &&
            goalProgress(data.systolic, goalData.sys, startData.systolic) === 100 &&
            goalProgress(data.diastolic, goalData.dia, startData.diastolic) === 100
          ) {
            setTimeout(() => {
              dispatch(
                addGoalDetails({
                  category: 'hypertension',
                  goalHyp: {
                    systolic: goalData.sys,
                    diastolic: goalData.dia,
                    measure: 'mm HG',
                  },
                  startHyp: {
                    systolic: startData.systolic,
                    diastolic: startData.diastolic,
                    measure: 'mm HG',
                    time: goalData.date,
                  },
                }),
              );
            }, 1500);
          }
        });
    }
  };

  const handleChange = (newValue: string) => {
    setSelected(newValue);
  };

  return (
    <Container>
      <LeftContainer>
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <Typography variant={'h1'} sx={{ color: theme.palette.primary.main }}>
            {t('dashboard.dashcards.hypertension.title')}
          </Typography>
          <Box>
            {measurementCategories.map(category => (
              <ToggleCard
                value={category.value}
                textTransform={'none'}
                label={t(category.label)}
                setSelected={handleChange}
                selected={selected === category.value}
                backgroundColor={theme.palette.secondary.light}
              />
            ))}
          </Box>
        </Box>
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <CustomButton
            fullWidth
            color={'primary'}
            variant={'contained'}
            onClick={handleSaveData}
            children={t('buttons.saveData')}
            disabled={!Boolean(systolic && diastolic && pulses)}
          />
          <CustomButton
            fullWidth
            variant={'outlined'}
            onClick={handleUSBInput}
            children={t('buttons.usbInput')}
            style={{ color: '#4780EF', borderColor: '#4780EF' }}
          />
        </Box>
      </LeftContainer>
      <RightContainer>
        <ModalHypertensionFields
          afib={afib}
          column={true}
          pulses={pulses}
          setAfib={setAfib}
          systolic={systolic}
          diastolic={diastolic}
          setPulses={setPulses}
          setSystolic={setSystolic}
          setDiastolic={setDiastolic}
          errorPulses={errorPulses}
          errorSystolic={errorSystolic}
          errorDiastolic={errorDiastolic}
          handleCalendarClick={() => setOpenPicker(true)}
        />
      </RightContainer>
      <CalendarModal
        open={openPicker}
        selectedDate={date}
        setOpen={setOpenPicker}
        saveDate={date => setDate(date)}
      />
    </Container>
  );
};
export default HypertensionModalWeb;
