import { SettingsPassPanelMobile } from '@hdcorner/ui-library';
import { useIonRouter } from '@ionic/react';
import useAlert from '../../hooks/useAlert';
import PageLayout from '../../components/PageLayout';
import { useUpdateChangePasswordMutation } from './queries/accountSettingsQueries';
import { useTranslation } from 'react-i18next';
import { useSignInMutation } from '../authentication/queries/signInQueries';
import { useAppSelector } from '../../redux/hooks';

const AccountSettingsPassword = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { presentError } = useAlert();

  const {
    data: { user },
  } = useAppSelector(state => state.auth);

  const [login] = useSignInMutation();
  const [updatePassword] = useUpdateChangePasswordMutation();

  const changePassword = async (currentPassword: string, newPassword: string) => {
    if (!user || !user.authUser || !user.authUser.email) {
      presentError(t('errors.profile.errorUpdateEmail'));
      return;
    }
    try {
      await login({ email: user.authUser.email, password: currentPassword }).unwrap();
      await updatePassword({
        oldPassword: currentPassword,
        newPassword: newPassword,
      }).unwrap();
      router.push('/dashboard/profile-settings');
    } catch (e) {
      console.log(e);
      presentError(t('errors.profile.errorUpdatePass'));
    }
  };

  return (
    <PageLayout
      backgroundWhite
      headerTitle={t('profile.titles.pass')}
      defaultHref={'/dashboard/profile-settings'}
    >
      <SettingsPassPanelMobile handleNext={changePassword} />
    </PageLayout>
  );
};

export default AccountSettingsPassword;
