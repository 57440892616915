import React, { FC } from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardActionAreaProps,
  CardContent,
  CardContentProps,
  CardProps,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import FileIcon from '../../assets/FileIcon';
import { useTranslation } from 'react-i18next';

const CardMain = styled(Card)<CardProps>(({ theme }) => ({
  flex: 1,
  minHeight: 80,
  display: 'flex',
  boxShadow: 'none',
  overflow: 'hidden',
  borderRadius: '8px',
  position: 'relative',
  flexDirection: 'column',
  background: theme.palette.secondary.main,
}));

const CardActionAreaMain = styled(CardActionArea)<CardActionAreaProps>(() => ({
  flex: 1,
  display: 'flex',
  position: 'unset',
}));

const CardContentMain = styled(CardContent)<CardContentProps>(() => ({
  flex: 1,
  height: '100%',
  display: 'flex',
  position: 'relative',
}));

type Props = {
  date: string;
  title: string;
  subtitle: string;
  hasFile?: boolean;
  onClick: () => void;
  icon?: React.ReactNode;
  cardContentProps?: CardContentProps;
  cardActionAreaProps?: CardActionAreaProps;
};
const FileCard: FC<Props & CardProps> = ({
  date,
  icon,
  title,
  hasFile,
  onClick,
  subtitle,
  cardContentProps,
  cardActionAreaProps,
  ...cardProps
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const mainColor =
    theme.palette.mode === 'dark'
      ? theme.palette.kmColorsCoolGrey.main
      : theme.palette.primary.main;
  const secondaryColor =
    theme.palette.mode === 'dark'
      ? theme.palette.kmColorsDarkGrey.main
      : theme.palette.primary.light;

  return (
    <CardMain {...cardProps}>
      <CardActionAreaMain disabled={!hasFile} onClick={onClick} {...cardActionAreaProps}>
        <CardContentMain {...cardContentProps}>
          <Box
            flex={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box display={'flex'} flex={1}>
              <Box
                width={48}
                height={48}
                display={'flex'}
                borderRadius={'8px'}
                alignItems={'center'}
                justifyContent={'center'}
                bgcolor={theme.palette.secondary.dark}
              >
                {icon ? icon : <FileIcon />}
              </Box>
              <Box
                gap={0.5}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'flex-start'}
                justifyContent={'center'}
                marginLeft={theme.spacing(1)}
              >
                <Typography
                  variant={'subtitle2'}
                  sx={{
                    color: mainColor,
                    fontWeight: theme.typography.fontWeightMedium,
                  }}
                >
                  {title}
                </Typography>
                <Typography
                  variant={'body1'}
                  sx={{
                    color: secondaryColor,
                    fontWeight: theme.typography.fontWeightRegular,
                  }}
                >
                  {subtitle}
                </Typography>
              </Box>
            </Box>
            <Box
              flex={1}
              gap={0.5}
              display={'flex'}
              alignItems={'flex-end'}
              flexDirection={'column'}
              justifyContent={'center'}
            >
              {hasFile && (
                <Box display={'flex'} alignItems={'center'} gap={1}>
                  <OpenInNewIcon fontSize={'small'} htmlColor={mainColor} />
                  <Typography
                    variant={'body1'}
                    sx={{
                      color: mainColor,
                      fontWeight: theme.typography.fontWeightRegular,
                    }}
                  >
                    {t('general.labels.openFile')}
                  </Typography>
                </Box>
              )}
              <Typography variant={'body2'} sx={{ color: secondaryColor }}>
                {date}
              </Typography>
            </Box>
          </Box>
        </CardContentMain>
      </CardActionAreaMain>
    </CardMain>
  );
};
export default FileCard;
