import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import {
  ClickNavCardNutrition,
  ComponentsLayout,
  extractMedicationState,
  FeelsEmpty,
  HorizontalCalendar,
  theme,
} from '@hdcorner/ui-library';
import Box from '@mui/material/Box';
import { useIonRouter } from '@ionic/react';
import moment from 'moment/moment';
import PageLayout from '../../components/PageLayout';
import MedicationHeaderIcon from '../../assets/icons/MedicationHeaderIcon';
import NavCardMedication from './components/NavCardMedication';
import {
  useGetMedicationsQuery,
  useTakeMedicationMutation,
} from './queries/medicationQueries';
import useAlert from '../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

const Medications = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { presentError, presentSuccess } = useAlert();

  const [selectedDate, setSelectedDate] = useState<Date>(moment().toDate());
  const [openMonthly, setOpenMonthly] = useState<boolean>(false);

  const [takeMedication] = useTakeMedicationMutation();

  const { error, data: medicationRes } = useGetMedicationsQuery({
    skip: 0,
    limit: 1000,
    endDate: moment(selectedDate).utc(true).endOf('day').toISOString(),
    startDate: moment(selectedDate).utc(true).startOf('day').toISOString(),
  });

  useEffect(() => {
    if (error) presentError(t('errors.medication.errorFetchMeds'));
  }, [error]);

  const medications = useMemo(() => {
    if (!medicationRes) return [];
    return medicationRes.documents;
  }, [medicationRes]);

  const handleShowAllMedicines = () => {
    router.push('/dashboard/medicines');
  };

  const handleShowReminders = () => {
    router.push('/dashboard/reminders?medication=true');
  };

  const handleAddMedication = () => {
    router.push('/dashboard/medicines?add=true');
  };

  const handleShowMedicationSingle = (medicationId: string) => {
    router.push(`/dashboard/medication/${medicationId}`);
  };

  const onTakeHandler = (medicationId: string) => {
    takeMedication(medicationId)
      .unwrap()
      .then(() => presentSuccess(t('errors.medication.successTakeMed')))
      .catch(() => presentError(t('errors.medication.errorTakeMed')));
  };

  const handleChange = (newDate: Date) => {
    setSelectedDate(newDate);
  };

  const renderMedications = useMemo(() => {
    if (medications.length === 0) return null;

    return (
      <Box
        display={'flex'}
        gap={theme.spacing(1)}
        flexDirection={'column'}
        paddingBottom={theme.spacing(2)}
      >
        {medications.map((medication, index) => (
          <NavCardMedication
            key={index}
            time={medication.time}
            medicineName={medication.medicine.medicineName}
            medicationState={extractMedicationState(medication)}
            onChangeHandler={() => onTakeHandler(medication._id)}
            onClickHandler={() => handleShowMedicationSingle(medication._id)}
            amount={`${medication.amount} (${medication.medicine.medicineType})`}
            checkLabel={medication.medicine.label ? medication.medicine.label : ''}
          />
        ))}
      </Box>
    );
  }, [medications, openMonthly, selectedDate]);

  return (
    <PageLayout
      fabClick={handleAddMedication}
      defaultHref={'/dashboard/home'}
      headerColor={theme.palette.medication.main}
      headerTitle={t('dashboard.dashcards.medication.medications')}
      headerIcon={
        <MedicationHeaderIcon
          width={'20px'}
          height={'20px'}
          color={theme.palette.medication.main}
        />
      }
    >
      <ComponentsLayout display={'flex'} flexDirection={'column'} gap={theme.spacing(2)}>
        <Box
          display={'flex'}
          borderRadius={'8px'}
          flexDirection={'column'}
          sx={{ backgroundColor: theme.palette.secondary.light }}
        >
          <Box padding={theme.spacing(2, 2, 3, 2)}>
            <HorizontalCalendar
              selectFutureDates
              openMonthly={openMonthly}
              selectedDate={selectedDate}
              setOpenMonthly={setOpenMonthly}
              handleChange={date => handleChange(date as Date)}
            />
          </Box>
          {renderMedications}
        </Box>
        {medications.length === 0 && <FeelsEmpty />}
        <Box marginTop={theme.spacing(1)}>
          <ClickNavCardNutrition
            headings={t('buttons.navigation.showAllMed')}
            onClick={handleShowAllMedicines}
          />
          <ClickNavCardNutrition
            onClick={handleShowReminders}
            headings={t('buttons.navigation.medReminders')}
          />
        </Box>
      </ComponentsLayout>
    </PageLayout>
  );
};

export default Medications;
