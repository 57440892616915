import React, { FC } from 'react';
import ArrowRight from '../../assets/ArrowRight';
import {
  Box,
  BoxProps,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';

const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

const CustomBox = styled(Box)<BoxProps>(({ theme }) => ({
  padding: theme.spacing(1, 2),
  borderRadius: '8px',
  boxSizing: 'border-box',
  height: '35px',
  backgroundColor: theme.palette.secondary.light,
  display: 'flex',
  justifyContent: 'space-between',
  cursor: 'pointer',
}));

const CustomBoxLeft = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

const CustomBoxRight = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  flexGrow: '1',
}));

const CustomFormControlLabel = styled(FormControlLabel)<FormControlLabelProps>(
  ({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    padding: theme.spacing(0),
    margin: theme.spacing(0),
    '.MuiFormControlLabel-label': {
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.subtitle2.fontSize,
      color: theme.palette.primary.main,
    },
  }),
);

const CustomCheckbox = styled(Checkbox)<CheckboxProps>(({ theme }) => ({
  borderRadius: '4px',
  padding: theme.spacing(0, 0.5, 0, 0),
  margin: theme.spacing(0),
  color: theme.palette.kmColorsCoolGrey.main,
  '&.Mui-checked': {
    color: theme.palette.kmColorsRed.main,
  },
  '.MuiSvgIcon-root': {
    fontSize: '13.33px',
  },
}));

const CustomTypographyAmount = styled(Typography)<TypographyProps>(({ theme }) => ({
  padding: theme.spacing(0, 1),
  margin: theme.spacing(0),
  fontSize: theme.typography.body1.fontSize,
  fontWeight: theme.typography.body1.fontWeight,
  color: theme.palette.kmColorsCoolGrey.main,
}));

const CustomTypographyTime = styled(Typography)<TypographyProps>(({ theme }) => ({
  margin: theme.spacing(0),
  fontSize: theme.typography.body1.fontSize,
  color: theme.palette.kmColorsCoolGrey.main,
  fontWeight: theme.typography.body1.fontWeight,
  padding: theme.spacing(0, 1),
  textTransform: lang === 'el' ? 'capitalize' : 'uppercase',
}));

type CheckboxNavCardProps = {
  checked: boolean;
  setChecked: (checked: boolean) => void;
  checkLabel: string;
  amount: string;
  time: string;
  onClickHandler: () => void;
};
const CheckboxNavCard: FC<CheckboxNavCardProps> = ({
  checked,
  setChecked,
  checkLabel,
  amount,
  time,
  onClickHandler,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <CustomBox>
      <CustomBoxLeft>
        <CustomFormControlLabel
          label={checkLabel}
          labelPlacement={'end'}
          control={<CustomCheckbox checked={checked} onChange={handleChange} />}
        />
        <CustomTypographyAmount>{amount}</CustomTypographyAmount>
      </CustomBoxLeft>
      <CustomBoxRight onClick={onClickHandler}>
        <CustomTypographyTime>{time}</CustomTypographyTime>
        <ArrowRight height={'10.67px'} />
      </CustomBoxRight>
    </CustomBox>
  );
};

export default CheckboxNavCard;
