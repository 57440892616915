import * as React from 'react';
import { SVGProps } from 'react';

const PersonIcon = ({ fill = '#222222', ...rest }: SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={22}
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M9 10C9.98891 10 10.9556 9.70676 11.7779 9.15735C12.6001 8.60794 13.241 7.82705 13.6194 6.91342C13.9978 5.99979 14.0969 4.99446 13.9039 4.02455C13.711 3.05465 13.2348 2.16373 12.5355 1.46447C11.8363 0.765206 10.9454 0.289002 9.97545 0.0960759C9.00555 -0.0968503 8.00021 0.00216642 7.08658 0.380605C6.17295 0.759043 5.39206 1.39991 4.84265 2.22215C4.29324 3.0444 4 4.0111 4 5C4.00159 6.3256 4.52888 7.59644 5.46622 8.53378C6.40356 9.47112 7.67441 9.99841 9 10ZM9 2C9.59334 2 10.1734 2.17595 10.6667 2.50559C11.1601 2.83524 11.5446 3.30377 11.7716 3.85195C11.9987 4.40013 12.0581 5.00333 11.9424 5.58527C11.8266 6.16722 11.5409 6.70177 11.1213 7.12132C10.7018 7.54088 10.1672 7.8266 9.58527 7.94236C9.00333 8.05811 8.40013 7.9987 7.85195 7.77164C7.30377 7.54458 6.83524 7.16006 6.50559 6.66671C6.17595 6.17337 6 5.59335 6 5C6 4.20435 6.31607 3.44129 6.87868 2.87868C7.44129 2.31607 8.20435 2 9 2ZM0 21V17C0.00158786 15.6744 0.528882 14.4036 1.46622 13.4662C2.40356 12.5289 3.67441 12.0016 5 12H13C14.3256 12.0016 15.5964 12.5289 16.5338 13.4662C17.4711 14.4036 17.9984 15.6744 18 17V21C18 21.2652 17.8946 21.5196 17.7071 21.7071C17.5196 21.8946 17.2652 22 17 22C16.7348 22 16.4804 21.8946 16.2929 21.7071C16.1054 21.5196 16 21.2652 16 21V17C16 16.2044 15.6839 15.4413 15.1213 14.8787C14.5587 14.3161 13.7956 14 13 14H5C4.20435 14 3.44129 14.3161 2.87868 14.8787C2.31607 15.4413 2 16.2044 2 17V21C2 21.2652 1.89464 21.5196 1.70711 21.7071C1.51957 21.8946 1.26522 22 1 22C0.734784 22 0.48043 21.8946 0.292893 21.7071C0.105357 21.5196 0 21.2652 0 21Z"
      fill={fill}
    />
  </svg>
);

export default PersonIcon;
