import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  BoxProps,
  IconButton,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import CompassIcon from '../../../assets/icons/CompassIcon';
import {
  ArrowLeft,
  ClinicalCoachesFound,
  CustomButton,
  CustomInput,
  ModalMobile,
  useDebounce,
} from '@hdcorner/ui-library';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { useSearchClinicalCoachQuery } from '../queries/healthProfessionalsQueries';
import ClinicalCoachResultsCard from './ClinicalCoachResultsCard';
import useAlert from '../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

const MainBox = styled(Box, {
  shouldForwardProp: propName => propName !== 'web',
})<BoxProps & { web: boolean }>(({ web, theme }) => ({
  display: 'flex',
  borderRadius: '8px',
  gap: theme.spacing(3),
  boxSizing: 'border-box',
  flexDirection: 'column',
  padding: theme.spacing(3),
  width: web ? '480px' : 'none',
}));

type Props = {
  open: boolean;
  handleBack: () => void;
  setOpen: (open: boolean) => void;
  handleSelectCc: (cc: ClinicalCoachesFound) => void;
};

const ModalSearchForCC: FC<Props> = ({ open, setOpen, handleBack, handleSelectCc }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { presentError } = useAlert();

  const web = useMediaQuery(theme.breakpoints.up('md'));

  const [search, setSearch] = useState<string>('');
  const [selectedCardId, setSelectedCardId] = useState<ClinicalCoachesFound | null>(null);

  const debouncedSearch = useDebounce(search, 500);

  const { error, data: clinicalCoachesRes } = useSearchClinicalCoachQuery(
    { postalCode: debouncedSearch },
    { skip: !debouncedSearch },
  );

  useEffect(() => {
    if (error) presentError(t('errors.healthProf.clinicalCoach.errorCCsFetch'));
  }, [error]);

  const center = useMemo(() => {
    if (!clinicalCoachesRes) return { lat: 37.965521, lng: 23.705292 };
    if (clinicalCoachesRes.point) {
      return { lat: clinicalCoachesRes.point.lat, lng: clinicalCoachesRes.point.lng };
    }
    return { lat: 37.965521, lng: 23.705292 };
  }, [clinicalCoachesRes]);

  const zoom = useMemo(() => {
    if (!clinicalCoachesRes) return 12;
    if (clinicalCoachesRes.point) {
      return 14;
    }
    return 12;
  }, [clinicalCoachesRes]);

  const clinicalCoaches = useMemo(() => {
    if (!clinicalCoachesRes) return [];
    return clinicalCoachesRes.results;
  }, [clinicalCoachesRes]);

  const handleContinue = () => {
    if (!selectedCardId) return;
    handleSelectCc(selectedCardId);
  };

  const handleCardClick = (cc: ClinicalCoachesFound) => {
    setSelectedCardId(cc);
  };

  return (
    <ModalMobile open={open} setOpen={setOpen}>
      <MainBox web={web}>
        <Box flex={1} display={'flex'} position={'relative'} justifyContent={'center'}>
          <Typography
            align={'center'}
            variant={'subtitle2'}
            sx={{ fontWeight: theme.typography.fontWeightBold }}
          >
            {t('buttons.findCC')}
          </Typography>
          <IconButton
            onClick={handleBack}
            sx={{ top: 0, left: 0, padding: 0, position: 'absolute' }}
          >
            <ArrowLeft />
          </IconButton>
        </Box>

        <CustomInput
          fullWidth
          value={search}
          placeholder={''}
          handleChange={setSearch}
          endAdornment={<CompassIcon />}
          label={`${t('healthProf.clinicalCoach.labels.enterPostCode')}`}
        />

        <GoogleMap
          zoom={zoom}
          center={center}
          mapContainerStyle={{ width: '100%', height: '228px' }}
        >
          {clinicalCoaches.map(cc => (
            <Marker
              key={cc._id}
              position={{ lat: cc.location[1], lng: cc.location[0] }}
              onClick={() => {
                handleCardClick(cc);
              }}
            />
          ))}
        </GoogleMap>

        <Box
          gap={1}
          display={'flex'}
          overflow={'auto'}
          maxHeight={'250px'}
          flexDirection={'column'}
        >
          {clinicalCoaches.map(cc => (
            <ClinicalCoachResultsCard
              data={cc}
              key={cc._id}
              checked={cc._id === selectedCardId?._id}
              handleCardClick={() => handleCardClick(cc)}
            />
          ))}
        </Box>

        <CustomButton
          fullWidth
          variant={'contained'}
          onClick={handleContinue}
          disabled={!selectedCardId}
        >
          {t('buttons.continue')}
        </CustomButton>
      </MainBox>
    </ModalMobile>
  );
};

export default ModalSearchForCC;
