import { createTheme, Shadows, Theme } from '@mui/material';

const theme: Theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#F2F1F3',
      light: '#FFFFFF',
      dark: '#DCDEE0',
    },
    secondary: {
      main: '#222222',
      dark: '#000000',
      light: '#A5A9B1',
    },
    success: {
      main: '#5FD071',
      contrastText: '#F2F1F3',
    },
    warning: {
      main: '#F2C72E',
      contrastText: '#F2F1F3',
    },
    error: {
      main: '#E84044',
      contrastText: '#F2F1F3',
    },
    info: {
      main: '#FFF8F8',
    },
    background: {
      default: '#303030',
      paper: '#222222',
    },
    highlight: {
      main: '#303030',
    },
    /*Data colors*/
    nutrition: {
      main: '#609B33',
    },
    medication: {
      main: '#4780EF',
    },
    diabetes: {
      main: '#E1205A',
    },
    hypertension: {
      main: '#CD2BD7',
    },
    exercise: {
      main: '#C41168',
    },
    cvdRisk: {
      main: '#24839C',
    },
    lipid: {
      main: '#1CD7E8',
    },
    program: {
      main: '#F2C72E',
    },
    /*Km colors*/
    kmColorsBg: {
      main: '#303030',
    },
    kmColorsButtonText: {
      main: '#332F36',
    },
    kmColorsBgText: {
      main: '#B2B8BD',
    },
    kmColorsTurquoise: {
      main: '#38D8E2',
    },
    kmColorsDarkGrey: {
      main: '#4A4F55',
    },
    kmColorsRed: {
      main: '#EE1D23',
    },
    kmColorsCoolGrey: {
      main: '#979FA5',
    },
    kmColorsYellow: {
      main: '#FFE792',
    },
    kmColorsDarkYellow: {
      main: '#BE9811',
    },
    kmColorsOrange: {
      main: '#EA9C27',
    },
    kmColorsPurple: {
      main: '#8C55E7',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: { textTransform: 'none' },
      },
    },
  },
  typography: {
    fontWeightLight: 500,
    fontWeightRegular: 600,
    fontWeightMedium: 700,
    fontWeightBold: 800,
    h1: {
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '27px',
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '22px',
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '19px',
    },
    body1: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '16px',
    },
    body2: {
      fontSize: 10,
      fontWeight: 500,
      lineHeight: '14px',
    },
    caption: {
      fontSize: 8,
      fontWeight: 600,
      lineHeight: '11px',
    },
  },
  shadows: [
    'none',
    '0px 1px 24px rgba(0,0,0,0.08)',
    '0px 1px 16px rgba(0,0,0,0.16)',
    '0px 4px 6px rgba(0,0,0,0.24)',
    ...Array(21).fill('none'),
  ] as Shadows,
});

export default theme;
