import React, { useEffect, useMemo, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { findMeal, Food, Meal, theme, totalValue } from '@hdcorner/ui-library';
import SectionHeading from '../../components/SectionHeading';
import LunchIcon from '../../../../assets/icons/LunchIcon';
import SnackIcon from '../../../../assets/icons/SnackIcon';
import DinnerIcon from '../../../../assets/icons/DinnerIcon';
import BreakfastIcon from '../../../../assets/icons/BreakfastIcon';
import { useIonRouter } from '@ionic/react';
import MealCard from './MealCard';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  cleanSlate,
  createAPIFoodArray,
  deleteFoodArray,
  updateCategory,
  updateMealId,
} from '../slices/mealFoodsSlice';
import ModalFoodMealsWeb from './ModalFoodMealsWeb';
import { useGetMealsOfDayQuery } from '../queries/foodsQueries';
import moment from 'moment/moment';
import useAlert from '../../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

const SectionNutrition = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { presentError } = useAlert();
  const web = useMediaQuery(theme.breakpoints.up('md'));

  const { mealCategory } = useAppSelector(state => state.mealFoodsList);

  const [open, setOpen] = useState<boolean>(false);

  const { data: mealLogRes, error } = useGetMealsOfDayQuery({
    end: moment().utc(true).endOf('day').toDate().toISOString(),
    start: moment().utc(true).startOf('day').toDate().toISOString(),
  });

  useEffect(() => {
    if (error) {
      presentError(t('errors.fitness.nutrition.errorMealFetch'));
    }
  }, [error]);

  const breakfastList = useMemo(() => {
    const breakfast = findMeal(mealLogRes, 'breakfast');
    return {
      category: 'breakfast',
      icon: <BreakfastIcon />,
      _id: breakfast ? breakfast._id : '1',
      foods: breakfast?.foods.length || 0,
      color: theme.palette.hypertension.main,
      title: t('fitness.nutrition.labels.breakfast'),
      calories: !mealLogRes ? '' : totalValue(breakfast?.foods, 'calories').toString(),
    };
  }, [mealLogRes]);

  const lunchList = useMemo(() => {
    const lunch = findMeal(mealLogRes, 'lunch');
    return {
      category: 'lunch',
      icon: <LunchIcon />,
      _id: lunch ? lunch._id : '2',
      foods: lunch?.foods.length || 0,
      color: theme.palette.medication.main,
      title: t('fitness.nutrition.labels.lunch'),
      calories: !mealLogRes ? '' : totalValue(lunch?.foods, 'calories').toString(),
    };
  }, [mealLogRes]);

  const dinnerList = useMemo(() => {
    const dinner = findMeal(mealLogRes, 'dinner');
    return {
      category: 'dinner',
      icon: <DinnerIcon />,
      _id: dinner ? dinner._id : '3',
      foods: dinner?.foods.length || 0,
      color: theme.palette.nutrition.main,
      title: t('fitness.nutrition.labels.dinner'),
      calories: !mealLogRes ? '' : totalValue(dinner?.foods, 'calories').toString(),
    };
  }, [mealLogRes]);

  const snacksList = useMemo(() => {
    const snacks = findMeal(mealLogRes, 'snacks');
    return {
      category: 'snacks',
      icon: <SnackIcon />,
      _id: snacks ? snacks._id : '4',
      foods: snacks?.foods.length || 0,
      color: theme.palette.diabetes.main,
      title: t('fitness.nutrition.labels.snack'),
      calories: !mealLogRes ? '' : totalValue(snacks?.foods, 'calories').toString(),
    };
  }, [mealLogRes]);

  const mealCardsList = useMemo(() => {
    return [breakfastList, lunchList, dinnerList, snacksList];
  }, [breakfastList, dinnerList, lunchList, snacksList]);

  const mealList = (value: Meal) => {
    if (!mealLogRes) return [];
    return value.foods.map(it => {
      return {
        quantity: it.quantity,
        food: (it.food as Food)._id as string,
      };
    });
  };

  const handleViewMore = () => {
    router.push('/dashboard/fitness/nutrition');
  };

  const handleAddMeal = (category: string) => {
    dispatch(deleteFoodArray());
    if (web) {
      setOpen(true);
      handleUpdateSlice(category);
    } else {
      router.push(`/dashboard/fitness/food-meals/${category}`);
      handleUpdateSlice(category);
    }
  };

  const handleUpdateSlice = (category: string) => {
    dispatch(updateCategory(category));
    if (mealLogRes) {
      const currentMeal = findMeal(mealLogRes, category);
      if (currentMeal) {
        dispatch(updateMealId(currentMeal._id));
        dispatch(createAPIFoodArray(currentMeal ? mealList(currentMeal) : []));
      }
    }
  };

  const handleFoodsModal = (value: boolean) => {
    setOpen(value);
    if (!value) dispatch(cleanSlate());
  };

  return (
    <Box gap={1} flexGrow={1} display={'flex'} flexDirection={'column'}>
      <SectionHeading
        hasGoal={false}
        viewMore={handleViewMore}
        heading={t('headingsTitles.nutrition')}
      />
      <Box display={'flex'} flexGrow={1} gap={1} flexWrap={'wrap'}>
        {mealCardsList.map(meal => (
          <MealCard
            key={meal._id}
            icon={meal.icon}
            color={meal.color}
            foods={meal.foods}
            title={meal.title}
            calories={meal.calories}
            handleAddClick={() => handleAddMeal(meal.category)}
          />
        ))}
      </Box>
      <ModalFoodMealsWeb open={open} category={mealCategory} setOpen={handleFoodsModal} />
    </Box>
  );
};

export default SectionNutrition;
