import * as React from 'react';
import { SVGProps } from 'react';

const DiabetesSQ = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.80529 15.3333C9.76342 15.3331 10.7028 15.0679 11.5196 14.567C12.3364 14.0662 12.9988 13.3492 13.4335 12.4954C13.8681 11.6415 14.0582 10.6841 13.9826 9.72894C13.9071 8.7738 13.5688 7.85816 13.0053 7.08329L8.53863 0.941291C8.47378 0.860623 8.39166 0.795529 8.29832 0.750804C8.20498 0.706079 8.1028 0.682861 7.99929 0.682861C7.89579 0.682861 7.79361 0.706079 7.70027 0.750804C7.60693 0.795529 7.52481 0.860623 7.45996 0.941291L2.99329 7.08329C2.42969 7.85826 2.09142 8.77401 2.01591 9.72927C1.9404 10.6845 2.13059 11.6421 2.56543 12.496C3.00028 13.3498 3.66284 14.0668 4.47983 14.5676C5.29681 15.0683 6.23639 15.3333 7.19463 15.3333H8.80529ZM3.33329 10.1386C3.33134 9.32207 3.58987 8.52617 4.07129 7.86662L7.99996 2.46662L11.9286 7.86662C12.3478 8.4427 12.5995 9.12353 12.6557 9.83377C12.712 10.544 12.5706 11.256 12.2473 11.8909C11.924 12.5257 11.4314 13.0588 10.8239 13.431C10.2164 13.8033 9.51776 14.0002 8.80529 14H7.19463C6.17097 13.9985 5.18965 13.5913 4.46581 12.8674C3.74198 12.1436 3.3347 11.1623 3.33329 10.1386ZM7.49663 12.8333C6.86239 12.8339 6.23996 12.6618 5.69615 12.3354C5.15235 12.009 4.70771 11.5406 4.40996 10.9806C4.36712 10.9033 4.3401 10.8182 4.33046 10.7303C4.32081 10.6424 4.32875 10.5535 4.35379 10.4687C4.37884 10.3839 4.4205 10.305 4.47635 10.2365C4.5322 10.1679 4.60112 10.1112 4.67911 10.0695C4.75709 10.0279 4.84258 10.0022 4.9306 9.99388C5.01862 9.98559 5.10741 9.99488 5.1918 10.0212C5.27619 10.0476 5.3545 10.0904 5.42217 10.1473C5.48984 10.2042 5.54552 10.274 5.58596 10.3526C5.77018 10.6994 6.04538 10.9894 6.38201 11.1916C6.71863 11.3937 7.10397 11.5003 7.49663 11.5C7.67344 11.5 7.84301 11.5702 7.96803 11.6952C8.09306 11.8202 8.16329 11.9898 8.16329 12.1666C8.16329 12.3434 8.09306 12.513 7.96803 12.638C7.84301 12.7631 7.67344 12.8333 7.49663 12.8333Z"
      fill={props?.color ?? '#F2F1F3'}
    />
  </svg>
);

export default DiabetesSQ;
