import { IonDatetime } from '@ionic/react';
import React, { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import {
  AlarmIcon,
  CustomButton,
  ModalSetReminder,
  ReminderData,
  theme,
} from '@hdcorner/ui-library';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../utils/useConvertJSON';

const types = [
  {
    label: 'headingsTitles.nutrition',
    value: 'nutrition',
  },
  {
    label: 'fitness.workouts.labels.workout',
    value: 'workout',
  },
  {
    label: 'headingsTitles.activities',
    value: 'activities',
  },
  {
    label: 'headingsTitles.steps',
    value: 'steps',
  },
  {
    label: 'userDetails.weight',
    value: 'weight',
  },
  {
    label: 'fitness.wellness.labels.sleep',
    value: 'sleep',
  },
  {
    label: 'dashboard.dashcards.medication.title',
    value: 'medication',
  },
  {
    label: 'dashboard.dashcards.lipid.title',
    value: 'lipid_profile',
  },
  {
    label: 'dashboard.dashcards.hypertension.title',
    value: 'hypertension',
  },
  {
    label: 'dashboard.dashcards.diabetes.title',
    value: 'diabetes',
  },
  {
    label: 'cvd.titles.cvdRisk',
    value: 'cvd_risk',
  },
];

type Props = {
  preset?: string;
  modalTitle?: string;
  selectedReminder?: ReminderData;
  handleSaveModal: (data: {
    info: string;
    date: string;
    title: string;
    allDay: boolean;
    category: string;
  }) => void;
};

const ModalSetReminderComponent: FC<Props> = ({
  preset,
  modalTitle,
  handleSaveModal,
  selectedReminder,
}) => {
  const { t } = useTranslation();

  const [info, setInfo] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [date, setDate] = useState<string>(moment().toISOString());
  const [allDay, setAllDay] = useState<boolean>(false);
  const [time, setTime] = useState<string | string[]>('12:00:00.000Z');
  const [category, setCategory] = useState<string>(preset ? 'medication' : '');

  useEffect(() => {
    if (selectedReminder) {
      const category = types.find(type => type.value === selectedReminder.category);
      setInfo(selectedReminder.info);
      setTitle(selectedReminder.title);
      setAllDay(selectedReminder.allDay);
      if (category) {
        setCategory(category.value);
      }

      if (!selectedReminder.reminderDateTime) return;

      const momentDate = moment(selectedReminder.reminderDateTime);
      const date = momentDate.toISOString();
      const time = momentDate.format('HH:mm:ss.SSS[Z]');
      setTime(time);
      setDate(date);
    }
  }, [selectedReminder]);

  const handleSaveData = (category: string) => {
    let correctTime;
    if (allDay) {
      correctTime = moment().add(1, 'hour').format('HH:mm:ss.SSS[Z]');
    } else {
      correctTime = time;
    }
    const data = {
      info,
      title,
      allDay,
      category,
      time: correctTime,
      date: moment(date).toISOString(),
    };
    handleSaveModal(data);
  };

  const handleButtonClick = () => {
    if (category) {
      const find = types.find(type => type.value === category);
      if (find) {
        const found = find.value;
        handleSaveData(found);
      }
    }
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2} p={3}>
      <ModalSetReminder
        info={info}
        date={date}
        title={title}
        value={category}
        setInfo={setInfo}
        setDate={setDate}
        setTitle={setTitle}
        setValue={setCategory}
        allDayChecked={allDay}
        modalTitle={modalTitle}
        setAllDayChecked={setAllDay}
        options={useConvertJSON(types)}
      />
      <Box display={'flex'} flexDirection={'column'} gap={4}>
        {!allDay && (
          <Box>
            <IonDatetime
              value={time}
              hourCycle={'h12'}
              preferWheel={true}
              presentation="time"
              onIonChange={event => setTime(event.detail.value!)}
              style={{
                '--background': 'none',
                color: theme.palette.kmColorsCoolGrey.main,
              }}
            ></IonDatetime>
          </Box>
        )}

        <Box display={'flex'} justifyContent={'center'}>
          <CustomButton
            fullWidth
            color={'primary'}
            variant={'contained'}
            startIcon={<AlarmIcon />}
            onClick={handleButtonClick}
            disabled={!category || !title || (!allDay && !date) || !info || !date}
          >
            {modalTitle ? modalTitle : t('reminders.labels.set')}
          </CustomButton>
        </Box>
      </Box>
    </Box>
  );
};

export default ModalSetReminderComponent;
