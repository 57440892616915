import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Divider, useTheme } from '@mui/material';
import ChatHeaderWeb from './ChatHeaderWeb';
import ChatMessageWeb from './ChatMessageWeb';
import ChatMediaPanelWeb from './ChatMediaPanelWeb';
import ChatMessageCardWeb from './ChatMessageCardWeb';
import ChatNewMessageDivider from './NewMessageDivider';
import moment from 'moment';
import CustomButton from '../controls/CustomButton';
import SupportChatMessageCard from './SupportChatMessageCard';
import { useTranslation } from 'react-i18next';

export type ChatMessage = {
  id: string;
  date: string;
  read: boolean;
  avatar?: string;
  message: string;
  lastName: string;
  firstName: string;
  currentUser?: boolean;
};

type ChatMessageWebProps = {
  title: string;
  avatar?: string;
  group?: boolean;
  admin?: boolean;
  support?: boolean;
  pending?: boolean;
  supportMessage?: string;
  messages?: ChatMessage[];
  children?: React.ReactNode;
  handleLeaveChat?: () => void;
  handleAcceptPending?: () => void;
  handleLoadMoreMessages?: () => void;
  handleSendMessage: (message: string) => void;
};
const ChatMessagePanelWeb: FC<ChatMessageWebProps> = ({
  title,
  avatar,
  children,
  messages,
  group = false,
  admin = false,
  supportMessage,
  pending = false,
  support = false,
  handleLeaveChat,
  handleSendMessage,
  handleAcceptPending,
  handleLoadMoreMessages,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const containerRef = useRef<HTMLDivElement>(null);

  const [message, setMessage] = useState('');
  const [mediaPanelOpen, setMediaPanelOpen] = useState(false);
  const [autoResponse, setAutoResponse] = useState<boolean>(false);

  const handleSubmit = useCallback(() => {
    handleSendMessage(message);
    setMessage('');
  }, [handleSendMessage, message]);

  const handleOpenMediaPanel = useCallback(() => {
    setMediaPanelOpen(!mediaPanelOpen);
  }, [mediaPanelOpen]);

  const filteredMessages = useMemo(() => {
    const firstRead = messages?.findIndex(message => message.read);
    return { unread: messages?.slice(0, firstRead), read: messages?.slice(firstRead) };
  }, [messages]);

  const handleScroll = useCallback(() => {
    const container = containerRef.current;
    if (!container) return;
    const isNear =
      container.scrollTop <= (container.clientHeight - container.scrollHeight) * 0.8;
    if (isNear) {
      handleLoadMoreMessages?.();
    }
  }, [handleLoadMoreMessages, containerRef]);

  useEffect(() => {
    const container = containerRef.current;
    container?.addEventListener('scroll', handleScroll);
    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, containerRef]);

  useEffect(() => {
    if (!admin && support && supportMessage && (!messages || messages.length === 0)) {
      const timer = setTimeout(() => {
        setAutoResponse(true);
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }
    setAutoResponse(false);
  }, [admin, support, messages, supportMessage]);

  return (
    <>
      <Box
        flex={1}
        display={'flex'}
        overflow={'hidden'}
        alignItems={'stretch'}
        flexDirection={'column'}
        boxSizing={'border-box'}
      >
        <ChatHeaderWeb
          admin={admin}
          title={title}
          group={group}
          avatar={avatar}
          support={support}
          handleLeaveChat={handleLeaveChat}
          handleOpenMediaPanel={handleOpenMediaPanel}
        />

        <Box
          flexGrow={1}
          display={'flex'}
          overflow={'hidden'}
          alignItems={'center'}
          flexDirection={'column'}
          justifyContent={'flex-end'}
        >
          <Box
            flexGrow={1}
            maxWidth={600}
            width={'100%'}
            display={'flex'}
            ref={containerRef}
            flexDirection={'column-reverse'}
            borderBottom={
              theme.palette.mode === 'dark'
                ? `1px solid ${theme.palette.kmColorsDarkGrey.main}`
                : `1px solid ${theme.palette.secondary.main}`
            }
            sx={{
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            {filteredMessages.unread?.map(message => (
              <ChatMessageCardWeb
                key={message.id}
                avatar={message.avatar}
                message={message.message}
                date={moment(message.date).format('HH:mm')}
                name={`${message.firstName} ${message.lastName}`}
              />
            ))}
            {filteredMessages.unread && filteredMessages.unread.length ? (
              <ChatNewMessageDivider />
            ) : null}
            {filteredMessages.read?.map(message => (
              <ChatMessageCardWeb
                key={message.id}
                avatar={message.avatar}
                message={message.message}
                date={moment(message.date).format('HH:mm')}
                name={`${message.firstName} ${message.lastName}`}
              />
            ))}

            {autoResponse && (
              <ChatMessageCardWeb
                support
                key={'support-1'}
                name={t('chat.titles.support')}
                message={t('chat.autoResponse')}
                date={moment().format('HH:mm')}
              />
            )}
            {support && supportMessage ? (
              <>
                {!messages || messages.length === 0 ? null : (
                  <Divider
                    sx={{
                      borderColor:
                        theme.palette.mode === 'dark'
                          ? theme.palette.kmColorsDarkGrey.main
                          : theme.palette.secondary.main,
                    }}
                  />
                )}
                <SupportChatMessageCard message={supportMessage} />
              </>
            ) : null}
            {!admin && support && (!messages || messages.length === 0) ? (
              <>
                <ChatMessageCardWeb
                  support
                  key={'support-1'}
                  message={t('chat.automated')}
                  name={t('chat.titles.support')}
                  date={moment().format('HH:mm')}
                />
                {!supportMessage ? <ChatNewMessageDivider /> : null}
              </>
            ) : null}
          </Box>
          <Box
            maxWidth={600}
            width={'100%'}
            boxSizing={'border-box'}
            padding={theme.spacing(2)}
            bgcolor={
              theme.palette.mode === 'dark'
                ? theme.palette.background.default
                : theme.palette.secondary.light
            }
          >
            {!pending ? (
              <ChatMessageWeb
                message={message}
                setMessage={setMessage}
                handleSubmit={handleSubmit}
                placeholder={'Enter your message here'}
                handleAddContentClick={handleOpenMediaPanel}
                disabled={
                  !admin &&
                  support &&
                  Boolean(supportMessage) &&
                  (!messages || messages.length === 0)
                }
              />
            ) : (
              <CustomButton onClick={handleAcceptPending} fullWidth variant={'contained'}>
                {t('chat.text.accept')}
              </CustomButton>
            )}
          </Box>
        </Box>
      </Box>
      <ChatMediaPanelWeb open={mediaPanelOpen}>{children}</ChatMediaPanelWeb>
    </>
  );
};

export default ChatMessagePanelWeb;
