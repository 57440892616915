import DeviceApps from './components/DeviceApps';
import PageLayout from '../../components/PageLayout';
import FitnessDevicesIcon from '../../assets/icons/FitnessDevicesIcon';
import { ComponentsLayout, ExerciseIcon, theme } from '@hdcorner/ui-library';
import { useTranslation } from 'react-i18next';

const DevicesFitness = () => {
  const { t } = useTranslation();

  const devices = [
    {
      id: '9182374',
      icon: <ExerciseIcon />,
      title: t('devices.labels.fitbit'),
    },
  ];

  const handleAddDevice = () => {
    console.log('add device');
  };

  const handleAppleHealth = () => {
    console.log('adding apple health');
  };

  const handleRemoveDevice = (id: string) => {
    console.log(`Removing ${id}`);
  };

  return (
    <PageLayout
      headerIcon={<FitnessDevicesIcon />}
      headerColor={theme.palette.primary.main}
      headerTitle={t('headingsTitles.fitnessDevices')}
    >
      <ComponentsLayout>
        <DeviceApps
          devices={devices}
          handleAddDevice={handleAddDevice}
          handleAppleHealth={handleAppleHealth}
          handleRemoveDevice={handleRemoveDevice}
        />
      </ComponentsLayout>
    </PageLayout>
  );
};

export default DevicesFitness;
