import { ComponentsLayout, Goal, ModalMobile } from '@hdcorner/ui-library';
import { useIonRouter } from '@ionic/react';
import { useEffect, useState } from 'react';
import ModalAddEditGoal from '../../components/ModalAddEditGoal';
import ModalRemoveGoal from '../../components/ModalRemoveGoal';
import PageLayout from '../../components/PageLayout';
import usePrepareGoalData from './hooks/usePrepareGoalData';
import {
  useDeleteCvdRiskGoalMutation,
  useEditCvdRiskGoalMutation,
  useGetCvdCurrentMeasurementQuery,
  useGetCvdRiskGoalQuery,
  useGetCvdStartingMeasurementQuery,
} from './queries/cvdRiskQueries';
import useAlert from '../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

const CvdRiskGoalScreen = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { presentSuccess, presentError } = useAlert();

  const [openEditGoal, setOpenEditGoal] = useState<boolean>(false);
  const [openRemoveGoal, setOpenRemoveGoal] = useState<boolean>(false);

  const [editCvdRiskGoal] = useEditCvdRiskGoalMutation();
  const [deleteCvdRiskGoalMutation] = useDeleteCvdRiskGoalMutation();

  const { data: cvdRiskGoal, error: cvdRiskGoalError } = useGetCvdRiskGoalQuery();
  const { data: cvdRiskFirstLog, error: cvdRiskFirstError } =
    useGetCvdStartingMeasurementQuery(
      {
        startingDate: cvdRiskGoal?.[0]?.startDate,
      },
      { skip: !cvdRiskGoal?.[0]?.startDate },
    );
  const { data: cvdRiskLastLog, error: cvdRiskLastError } =
    useGetCvdCurrentMeasurementQuery();

  const prepareGoalData = usePrepareGoalData(
    cvdRiskGoal,
    cvdRiskLastLog,
    cvdRiskFirstLog,
  );

  useEffect(() => {
    if (cvdRiskFirstError || cvdRiskLastError || cvdRiskGoalError) {
      presentError(t('errors.cvd.fetchGoal'));
    }
  }, [cvdRiskFirstError, cvdRiskGoalError, cvdRiskLastError]);

  const handleClickRemove = () => {
    if (cvdRiskGoal?.[0]?._id) {
      deleteCvdRiskGoalMutation({ _id: cvdRiskGoal[0]._id })
        .unwrap()
        .then(() => {
          setOpenRemoveGoal(false);
          router.push('/dashboard/cvd');
          presentSuccess(t('errors.cvd.goalRemoved'));
        })
        .catch(e => {
          console.error(e);
          setOpenRemoveGoal(false);
          presentError(t('errors.cvd.errorRemoveGoal'));
        });
    }
  };

  const handleRemove = () => {
    setOpenRemoveGoal(true);
  };

  const handleEdit = () => {
    setOpenEditGoal(true);
  };

  const handleSaveClick = (newGoal: string) => {
    if (cvdRiskGoal?.[0]) {
      editCvdRiskGoal({
        goalType: 'cvd_risk',
        _id: cvdRiskGoal[0]._id,
        goal: parseFloat(newGoal),
        startDate: cvdRiskGoal?.[0].createdAt,
      })
        .unwrap()
        .then(() => {
          setOpenEditGoal(false);
          presentSuccess(t('errors.cvd.goalUpdated'));
        })
        .catch(e => {
          console.error(e);
          setOpenEditGoal(false);
          presentError(t('errors.cvd.errorUpdateGoal'));
        });
    } else {
      setOpenEditGoal(false);
      presentError(t('errors.cvd.errorUpdateGoal'));
    }
  };

  return (
    <PageLayout
      defaultHref={'/dashboard/cvd'}
      headerTitle={`${t('buttons.navigation.goal')}`}
    >
      <ComponentsLayout>
        <Goal
          data={prepareGoalData}
          handleEdit={handleEdit}
          handleRemove={handleRemove}
        />
      </ComponentsLayout>
      <ModalMobile open={openRemoveGoal} setOpen={setOpenRemoveGoal}>
        <ModalRemoveGoal
          handleRemoveClick={handleClickRemove}
          handleCancelClick={() => setOpenRemoveGoal(false)}
        />
      </ModalMobile>
      <ModalMobile open={openEditGoal} setOpen={setOpenEditGoal}>
        <ModalAddEditGoal
          goalType={'cvd'}
          desiredPlaceholder={`1%`}
          handleSaveClick={handleSaveClick}
          goal={cvdRiskGoal?.[0]?.goal.toString()}
          currentMeasurement={cvdRiskLastLog?.logs?.[0]?.score.toString()}
        />
      </ModalMobile>
    </PageLayout>
  );
};

export default CvdRiskGoalScreen;
