import { Camera, CameraResultType } from '@capacitor/camera';
import moment from 'moment';
import { cameraPermissions } from './cameraPermissions';

const takePhoto = async (): Promise<File | null> => {
  const cameraSource = await cameraPermissions();
  if (!cameraSource) {
    return null;
  }
  const image = await Camera.getPhoto({
    quality: 90,
    source: cameraSource,
    resultType: CameraResultType.DataUrl,
  });

  if (!image.dataUrl) return null;

  const path = image.path;
  const dataUrl = image.dataUrl;

  const byteString = atob(dataUrl.split(',')[1]);
  const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([ab], { type: mimeString });
  return new File(
    [blob],
    path || `captured_image_${moment().format('DD-MM-YYYY HH:mm:ss')}`,
    {
      type: blob.type,
    },
  );
};

export { takePhoto };
