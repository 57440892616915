import {
  BaseQueryApi,
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { RootState } from './store';
import {
  logout,
  setAccessToken,
  setRefreshToken,
} from '../features/authentication/authSlice';

const BASE_URL = process.env.REACT_APP_API_URL;

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  cache: 'no-cache',
  prepareHeaders: async (headers, { getState, endpoint }) => {
    const authState = (getState() as RootState).auth;
    const {
      data: { token, refreshToken },
    } = authState;

    const authToken = endpoint === '/authentication/renew' ? refreshToken : token;
    if (token) {
      headers.set('authorization', `Bearer ${authToken}`);
    }

    // Disables caching for all requests
    headers.set('Cache-Control', 'no-cache');
    headers.set('Pragma', 'no-cache');
    headers.set('expires', '0');
    return headers;
  },
});

const mutex = new Mutex();
const baseQueryWithReAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  abortNonPremium(args, api.getState() as RootState, api);
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  args = injectScopes(args as FetchArgs, api.getState() as RootState);
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await baseQuery(
          {
            method: 'POST',
            url: '/authentication/renew',
          },
          { ...api, endpoint: '/authentication/renew' },
          extraOptions,
        );
        const refreshRes = refreshResult as any;
        if (refreshRes.data) {
          // update the tokens in redux store
          api.dispatch(setAccessToken(refreshRes.data.accessToken));
          api.dispatch(setRefreshToken(refreshRes.data.refreshToken));

          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(logout());
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

const abortNonPremium = (
  args: string | FetchArgs,
  state: RootState,
  api: BaseQueryApi,
) => {
  const url = (args as FetchArgs).url || (args as string);
  const premiumEndpoints = [
    '/database/function/getDietPlan',
    '/database/function/getStepLogs',
    '/database/function/getMealsOfDay',
    '/database/function/getUserActivities',
    '/database/function/getCaloriesInDepth',
    '/hdCornerService/user/programs/trends',
    '/hdCornerService/user/cvd-risk/in-depth',
  ];
  if (premiumEndpoints.includes(url) && !state.auth.data.user?.premiumUser) {
    console.log('Query aborted due to user not being premium.');
    api.abort('User not premium');
  }
};

const injectScopes = (args: FetchArgs, state: RootState): FetchArgs => {
  if (!state.auth.data.user) return args;

  const userId = state.auth.data.user.authUser._id;
  switch (args.url) {
    //CVD Risk
    case '/database/function/addCvdRiskLog':
      args.params = { ...args.params, scope: `PremiumHealth:${userId}` };
      break;
    // Goals
    case '/hdCornerService/user/goal':
    case '/database/function/getUserGoal':
      args.params = { ...args.params, scope: `Goal:${userId}` };
      break;
    // Diabetes
    case '/database/function/addBloodGlucoseLog':
      args.params = { ...args.params, scope: `BasicHealth:${userId}` };
      break;
    case '/database/function/getBloodGlucoseLogs':
      args.params = { ...args.params, scope: `BasicHealth:${userId}` };
      break;
    case '/database/function/addHbacLog':
      args.params = { ...args.params, scope: `BasicHealth:${userId}` };
      break;
    case '/database/function/getHbacLogs':
      args.params = { ...args.params, scope: `BasicHealth:${userId}` };
      break;
    // Hypertension
    case '/database/function/addHypertensionLog':
      args.params = { ...args.params, scope: `BasicHealth:${userId}` };
      break;
    case '/database/function/getHypertensionLogs':
      args.params = { ...args.params, scope: `BasicHealth:${userId}` };
      break;
    // Lipid
    case '/database/function/addLipidLog':
      args.params = { ...args.params, scope: `BasicHealth:${userId}` };
      break;
    case '/database/function/getLipidLogs':
      args.params = { ...args.params, scope: `BasicHealth:${userId}` };
      break;
    // Medication
    case '/database/function/getMedications':
      args.params = { ...args.params, scope: `PremiumHealth:${userId}` };
      break;
    case '/database/function/deleteSingleMedication':
      args.params = { ...args.params, scope: `PremiumHealth:${userId}` };
      break;
    //Calories
    case '/database/function/getCaloriesInDepth':
      args.params = { ...args.params, scope: `Nutrition:${userId}` };
      break;
    //Diet plan
    case '/database/function/getDietPlan':
      args.params = { ...args.params, scope: `Nutrition:${userId}` };
      break;
    case '/database/function/setCalculatedCalories':
      args.params = { ...args.params, scope: `Nutrition:${userId}` };
      break;
    case '/database/function/changeCalculatedCalories':
      args.params = { ...args.params, scope: `Nutrition:${userId}` };
      break;
    //Activities
    case '/database/function/getUserActivities':
      args.params = { ...args.params, scope: `Exercise:${userId}` };
      break;
    case '/database/function/createActivityLog':
      args.params = { ...args.params, scope: `Exercise:${userId}` };
      break;
    // Steps
    case '/database/function/getStepLogs':
    case '/database/function/createStepsLog':
    case '/database/function/getStepsGraphData':
      args.params = { ...args.params, scope: `Exercise:${userId}` };
      break;
    // Weight
    case '/database/function/getWeightLogs':
      args.params = { ...args.params, scope: `Weight:${userId}` };
      break;
    case '/database/function/createWeightLog':
      args.params = { ...args.params, scope: `Weight:${userId}` };
      break;
    case '/database/function/getWeightGraphData':
      args.params = { ...args.params, scope: `Weight:${userId}` };
      break;
    // Sleep
    case '/database/function/getSleepLogs':
    case '/database/function/createSleepLog':
    case '/database/function/getSleepGraphData':
      args.params = { ...args.params, scope: `Sleep:${userId}` };
      break;
    // Meals
    case '/database/function/getMealsOfDay':
      args.params = { ...args.params, scope: `Nutrition:${userId}` };
      break;
    // User Records
    case '/database/function/getUserRecords':
      args.params = { ...args.params, scope: `UserRecord:${userId}` };
      break;
    case '/hdCornerService/user/record':
      const folder = args.body.folder;
      if (folder === 'hdcorner') {
        args.body.folder = userId + '-hdcorner';
      }
      break;
  }

  // case 'database/UserStepsLog':
  const regex = /database\/UserStepsLog\/(.*)/;
  if (args.url) {
    const match = args.url.match(regex);
    if (match) {
      args.params = { ...args.params, scope: `Exercise:${userId}` };
    }

    // case 'database/UserSleepLog':
    const regex2 = /database\/UserSleepLog\/(.*)/;
    const match2 = args.url.match(regex2);
    if (match2) {
      args.params = { ...args.params, scope: `Sleep:${userId}` };
    }

    // case 'hdCornerService/user/goal':
    const regex3 = /hdCornerService\/user\/goal\/(.*)/;
    const match3 = args.url.match(regex3);
    if (match3) {
      args.params = { ...args.params, scope: `Goal:${userId}` };
    }

    // case 'database/UserGoal':
    const regex4 = /database\/UserGoal\/(.*)/;
    const match4 = args.url.match(regex4);
    if (match4) {
      args.params = { ...args.params, scope: `Goal:${userId}` };
    }

    // case database/function/getMedicationById
    const regex5 = /database\/function\/getMedicationById\/(.*)/;
    const match5 = args.url.match(regex5);
    if (match5) {
      args.params = { ...args.params, scope: `PremiumHealth:${userId}` };
    }
  }

  return args;
};

const api = createApi({
  reducerPath: 'api',
  endpoints: () => ({}),
  baseQuery: baseQueryWithReAuth,
  tagTypes: [
    'AuthUser',
    'UserData',
    'UserSettings',
    'ChangePassword',
    'ChangeEmail',
    'BloodGlucoseLog',
    'Hbac1Log',
    'HCPUser',
    'HypertensionLog',
    'DiabeticLogs',
    'PressureGoal',
    'ReminderData',
    'FoodData',
    'MealsData',
    'Activity',
    'ActivityGoal',
    'ActivityLog',
    'Calories',
    'GetStepsLog',
    'StepsGoal',
    'WeightGoal',
    'WeightLog',
    'GetWeightLog',
    'SleepLog',
    'DietPlan',
    'TipsTricks',
    'Workout',
    'WorkoutSetting',
    'Article',
    'Notification',
    'MedicationDashboard',
    'Dashboard',
  ],
});

export default api;
