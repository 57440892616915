import { FC, useState } from 'react';
import {
  CustomButton,
  GroupChatIcon,
  ModalMobile,
  RadioGroup,
  RadioSingle,
} from '@hdcorner/ui-library';
import {
  Avatar,
  AvatarProps,
  Box,
  BoxProps,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  useGetGroupChatsQuery,
  useGetHealthProfessionalsQuery,
} from '../queries/recordsQueries';
import { useTranslation } from 'react-i18next';

const MainBox = styled(Box, {
  shouldForwardProp: propName => propName !== 'web',
})<BoxProps & { web: boolean }>(({ web, theme }) => ({
  display: 'flex',
  borderRadius: '8px',
  boxSizing: 'border-box',
  flexDirection: 'column',
  gap: theme.spacing(3),
  width: web ? '400px' : 'none',
  padding: theme.spacing(3),
}));

const SecondaryBox = styled(Box)<BoxProps>(({ theme }) => ({
  flex: 1,
  display: 'flex',
  borderRadius: '8px',
  alignItems: 'center',
  boxSizing: 'border-box',
  backgroundColor: '#F2F1F3',
  gap: theme.spacing(1),
  padding: theme.spacing(1),
  justifyContent: 'space-between',
}));

const SQAvatar = styled(Avatar)<AvatarProps>(() => ({
  width: '36px',
  height: '36px',
}));

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleSend: (data: { hcpId?: string; groupChatRoom?: string }) => void;
};
const ModalSelectRecordRecipient: FC<Props> = ({ open, setOpen, handleSend }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const web = useMediaQuery(theme.breakpoints.up('md'));

  const [receiver, setReceiver] = useState<string>('');

  const { data: groupChats } = useGetGroupChatsQuery(undefined, { skip: !open });
  const { data: healthProfessionals } = useGetHealthProfessionalsQuery(undefined, {
    skip: !open,
  });

  return (
    <ModalMobile open={open} setOpen={setOpen}>
      <MainBox web={web}>
        <Typography
          align={'center'}
          variant={'subtitle2'}
          sx={{
            color: theme.palette.kmColorsCoolGrey.main,
            fontWeight: theme.typography.fontWeightRegular,
          }}
        >
          {t('records.text.chooseSend')}
        </Typography>

        <RadioGroup
          row={false}
          name={'chats'}
          value={receiver}
          sx={{ gap: theme.spacing(1) }}
          onChange={event => setReceiver(event.target.value)}
        >
          {groupChats?.map(groupChat => (
            <SecondaryBox key={groupChat._id}>
              <Box gap={1} display={'flex'} alignItems={'center'}>
                <Box
                  sx={{
                    padding: '4px',
                    borderRadius: '4px',
                    backgroundColor: 'red',
                  }}
                >
                  <GroupChatIcon />
                </Box>
                <Typography
                  variant={'subtitle2'}
                  sx={{
                    fontWeight: theme.typography.fontWeightRegular,
                  }}
                >
                  {groupChat.participants}
                </Typography>
              </Box>
              <RadioSingle reverse value={'groupChat_' + groupChat._id} />
            </SecondaryBox>
          ))}
          {healthProfessionals?.map(hcp => (
            <SecondaryBox key={hcp._id}>
              <Box gap={1} display={'flex'} alignItems={'center'}>
                <SQAvatar src={hcp.profilePicture} variant={'rounded'} />
                <Typography
                  variant={'subtitle2'}
                  sx={{
                    fontWeight: theme.typography.fontWeightRegular,
                  }}
                >
                  {hcp.name}
                </Typography>
              </Box>
              <RadioSingle reverse value={'hcp_' + hcp._id} />
            </SecondaryBox>
          ))}
        </RadioGroup>
        <CustomButton
          variant={'contained'}
          disabled={receiver === ''}
          sx={{ width: '212px', margin: '0 auto' }}
          onClick={() => {
            const [type, id] = receiver.split('_');
            handleSend({ [type === 'hcp' ? 'hcpId' : 'groupChatRoom']: id });
          }}
        >
          {t('buttons.send')}
        </CustomButton>
      </MainBox>
    </ModalMobile>
  );
};

export default ModalSelectRecordRecipient;
