import PageLayout from '../../../components/PageLayout';
import {
  CalendarGoalsModal,
  CalendarIcon,
  ClickNavCardNutrition,
  ComponentsLayout,
  findMealItems,
  KnifeForkIcon,
  theme,
} from '@hdcorner/ui-library';
import { useIonRouter } from '@ionic/react';
import SectionNutritionCal from './components/SectionNutritionCal';
import IonicFabMain from '../components/IonicFabMain';
import IonicFabButton from '../components/IonicFabButton';
import BreakfastIcon from '../../../assets/icons/BreakfastIcon';
import LunchIcon from '../../../assets/icons/LunchIcon';
import DinnerIcon from '../../../assets/icons/DinnerIcon';
import SnackIcon from '../../../assets/icons/SnackIcon';
import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment/moment';
import { Box } from '@mui/material';
import { useGetCaloriesInDepthQuery } from '../calories/queries/caloriesQueries';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  createAPIFoodArray,
  updateCategory,
  updateMealId,
} from './slices/mealFoodsSlice';
import useAlert from '../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';
import { useGetMealsOfDayQuery } from './queries/foodsQueries';

const NutritionFitness = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { presentError } = useAlert();

  const { logDate } = useAppSelector(state => state.mealFoodsList);

  const [monthCalendarGoals, setMonthCalendarGoals] = useState<string>();
  const [openDialogFab, setOpenDialogFab] = useState<boolean>(false);
  const [openCalendarGoals, setOpenCalendarGoals] = useState<boolean>(false);

  const { data: getCaloriesInDepth, error } = useGetCaloriesInDepthQuery(
    {
      params: { skip: 0, limit: 40 },
      end: moment(monthCalendarGoals).endOf('month').toISOString(),
      start: moment(monthCalendarGoals).startOf('month').toISOString(),
    },
    { skip: !monthCalendarGoals },
  );

  /**
   * Retrieve meals of the day
   */
  const { data: mealLogRes } = useGetMealsOfDayQuery({
    end: moment(logDate).utc(true).endOf('day').toISOString(),
    start: moment(logDate).utc(true).startOf('day').toISOString(),
  });

  useEffect(() => {
    if (error) {
      presentError('An error occurred while fetching nutrition data');
    }
  }, [error]);

  /**
   * Removes multiple meals of the same category
   * Multiples created due to bug in initial setup
   */
  const revisedMeals = useMemo(() => {
    if (!mealLogRes || mealLogRes.length === 0) return;
    return mealLogRes.filter((item, ind) => {
      return ind === mealLogRes.findIndex((it: any) => item.category === it.category);
    });
  }, [mealLogRes]);

  const nextPage = [
    {
      title: t('fitness.calories.titles.foodEquiv'),
      value: 'food-equivalents',
    },
    {
      title: t('headingsTitles.tipsTricks'),
      value: 'tips-tricks',
    },
  ];

  const handleOpenGoalsCalendar = () => {
    setOpenCalendarGoals(true);
  };

  const handleNextPage = (value: string) => {
    router.push(`/dashboard/fitness/nutrition/${value}`);
  };

  const handleAddFood = (value: string) => {
    setOpenDialogFab(false);
    dispatch(updateCategory(value));
    sendMealId(value);
    router.push(`/dashboard/fitness/food-meals/${value}`);
  };

  /**
   * update redux slice with mealId if meal exists
   */
  const sendMealId = (meal: string) => {
    if (!revisedMeals || revisedMeals.length === 0) return;
    const found = revisedMeals.find(it => it.category === meal);
    if (found) {
      dispatch(updateMealId(found._id));
      dispatch(createAPIFoodArray(findMealItems(found)));
    }
  };

  return (
    <PageLayout
      defaultHref={'/dashboard/fitness'}
      headerTitle={t('headingsTitles.nutrition')}
      endButtons={[
        {
          icon: <CalendarIcon width={'20px'} height={'20px'} />,
          onClick: handleOpenGoalsCalendar,
        },
      ]}
    >
      <ComponentsLayout>
        <SectionNutritionCal />
        <Box>
          {nextPage.map(it => (
            <ClickNavCardNutrition
              key={it.value}
              headings={it.title}
              onClick={() => handleNextPage(it.value)}
            />
          ))}
        </Box>
      </ComponentsLayout>
      <IonicFabMain openDialogFab={openDialogFab} setOpenDialogFab={setOpenDialogFab}>
        <IonicFabButton
          color={theme.palette.hypertension.main}
          title={t('buttons.navigation.addBreakfast')}
          onClick={() => handleAddFood('breakfast')}
          icon={<BreakfastIcon width={'16px'} height={'16px'} />}
        />
        <IonicFabButton
          color={theme.palette.medication.main}
          title={t('buttons.navigation.addLunch')}
          onClick={() => handleAddFood('lunch')}
          icon={<LunchIcon width={'18px'} height={'18px'} />}
        />
        <IonicFabButton
          color={theme.palette.nutrition.main}
          title={t('buttons.navigation.addDinner')}
          onClick={() => handleAddFood('dinner')}
          icon={<DinnerIcon width={'18px'} height={'20px'} />}
        />
        <IonicFabButton
          color={theme.palette.diabetes.main}
          title={t('buttons.navigation.addSnack')}
          onClick={() => handleAddFood('snacks')}
          icon={<SnackIcon width={'18px'} height={'22px'} />}
        />
      </IonicFabMain>
      <CalendarGoalsModal
        open={openCalendarGoals}
        altIcon={<KnifeForkIcon />}
        setOpen={setOpenCalendarGoals}
        goalDates={getCaloriesInDepth?.documents?.map(cal => ({
          date: new Date(cal.createdAt),
          success: Math.abs((cal.calculated ?? 0) - (cal.eaten ?? 0)) <= 50,
        }))}
        onMonthChange={setMonthCalendarGoals}
      />
    </PageLayout>
  );
};

export default NutritionFitness;
