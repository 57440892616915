import api from '../../../../redux/api';
import { User24HourHypertension } from '@hdcorner/ui-library';

const twentyFourHoursQueries = api.injectEndpoints({
  endpoints: build => ({
    getTwentyFourHours: build.query<
      { documents: User24HourHypertension[]; count: number },
      { skip: number; limit: number; start?: string; end?: string }
    >({
      query: ({ skip, limit, start, end }) => ({
        method: 'GET',
        params: { skip, limit, start, end },
        url: '/database/function/getUserTwentyHourHypertension',
      }),
      providesTags: ['HypertensionLog'],
    }),
    downloadPdfReport: build.query<string, string>({
      query: id => ({
        method: 'POST',
        params: { hypertension24HourId: id },
        url: '/hdCornerService/user/hypertension/24-hour-pdf',
      }),
    }),
  }),
});

export const { useGetTwentyFourHoursQuery, useLazyDownloadPdfReportQuery } =
  twentyFourHoursQueries;

export default twentyFourHoursQueries;
