import React, { FC } from 'react';
import ResetPassTwo from './ResetPassTwo';
import CustomButton from '../controls/CustomButton';
import { Box, styled, Typography, TypographyProps, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const TypographyHeading = styled(Typography)<TypographyProps>(({ theme }) => ({
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.kmColorsBgText.main
      : theme.palette.primary.main,
  marginBottom: theme.spacing(3),
}));

const TypographyText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.kmColorsBgText.main
      : theme.palette.primary.light,
  fontWeight: theme.typography.fontWeightRegular,
}));

const TypographyCopyright = styled(Typography)<TypographyProps>(({ theme }) => ({
  marginTop: theme.spacing(3),
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.kmColorsBgText.main
      : theme.palette.primary.light,
  fontWeight: theme.typography.fontWeightRegular,
}));

type ResetPasswordPanelGlobalProps = {
  web: boolean;
  count: number;
  password1: string;
  password2: string;
  handleContinue: () => void;
  handleGoToSignIn: () => void;
  handleCancelButton: () => void;
  logoContainer: React.ReactNode;
  handleResetPassword: () => void;
  setPassword1: (password1: string) => void;
  setPassword2: (password2: string) => void;
};
const ResetPasswordPanelGlobal: FC<ResetPasswordPanelGlobalProps> = ({
  web,
  count,
  password1,
  password2,
  setPassword1,
  setPassword2,
  handleContinue,
  handleGoToSignIn,
  handleCancelButton,
  handleResetPassword,
  logoContainer,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const neverMindButton = (
    <>
      <Box>
        <Box marginTop={theme.spacing(3)} display={count > 0 ? 'block' : 'none'}>
          <CustomButton
            onClick={handleCancelButton}
            variant={web ? 'contained' : 'text'}
            sx={{
              margin: '0 auto',
              boxShadow: 'none',
              width: web ? '100px' : 'auto',
              height: web ? '32px' : 'auto',
              color:
                theme.palette.mode === 'dark'
                  ? theme.palette.kmColorsBgText.main
                  : theme.palette.primary.main,
              fontSize: theme.typography.body1.fontSize,
              backgroundColor: !web ? null : theme.palette.secondary.main,
            }}
          >
            {t('authentication.forgotReset.labels.nvm')}
          </CustomButton>
        </Box>
        {web && (
          <TypographyCopyright variant={'body1'}>
            {t('karabinis.footer', { year: moment().year() })}
          </TypographyCopyright>
        )}
      </Box>
    </>
  );

  return (
    <Box
      height={'100%'}
      display={'flex'}
      margin={'0 auto'}
      maxWidth={'350px'}
      overflow={'scroll'}
      textAlign={'center'}
      flexDirection={'column'}
      width={web ? '350px' : 'auto'}
      justifyContent={'space-between'}
      sx={{
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <Box display={'flex'} flexDirection={'column'} flexGrow={1}>
        <Box
          textAlign={'center'}
          marginBottom={web ? theme.spacing(0) : theme.spacing(5)}
        >
          {logoContainer}
        </Box>

        <Box
          flexGrow={1}
          display={'flex'}
          maxWidth={'350px'}
          textAlign={'center'}
          flexDirection={'column'}
          justifyContent={web ? 'center' : 'flex-start'}
        >
          <TypographyHeading variant={'h1'}>
            {count === 0
              ? t('healthProf.clinicalCoach.text.helloThere')
              : count === 1
              ? t('authentication.forgotReset.labels.resetPass')
              : t('authentication.forgotReset.text.resetSuccess')}
          </TypographyHeading>

          <Box
            margin={'0 auto'}
            maxWidth={'264px'}
            display={count === 0 ? 'block' : 'none'}
          >
            <TypographyText variant={'body1'}>
              {t('authentication.forgotReset.text.resetRequest')}
            </TypographyText>
          </Box>

          <Box
            margin={theme.spacing(6.5, 0, 3, 0)}
            display={count === 1 ? 'block' : 'none'}
          >
            <ResetPassTwo
              password1={password1}
              password2={password2}
              setPassword1={setPassword1}
              setPassword2={setPassword2}
            />
          </Box>

          <CustomButton
            variant={'contained'}
            disabled={
              (count === 1 && password1 !== password2) ||
              (count === 1 && password1 === '')
            }
            onClick={
              count === 0
                ? handleContinue
                : count === 1
                ? handleResetPassword
                : handleGoToSignIn
            }
            fullWidth
            sx={{
              opacity: '1',
              height: '48px',
              marginTop: theme.spacing(3),
              '&:hover': {
                opacity: '0.24',
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? theme.palette.kmColorsTurquoise.main
                    : theme.palette.primary.main,
              },
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? theme.palette.kmColorsTurquoise.main
                  : theme.palette.primary.main,
            }}
          >
            {count === 0
              ? t('buttons.continue')
              : count === 1
              ? t('authentication.forgotReset.labels.reset')
              : t('buttons.signIn')}
          </CustomButton>
        </Box>
      </Box>
      {neverMindButton}
    </Box>
  );
};

export default ResetPasswordPanelGlobal;
