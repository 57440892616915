import React, { SVGProps } from 'react';
import { useTheme } from '@mui/material';

const FitnessHeartIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  return (
    <svg
      width="21"
      height="20"
      fill="none"
      viewBox="0 0 21 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.5 3.10968C18.9525 2.44728 18.265 1.91454 17.4868 1.54986C16.7087 1.18517 15.8594 0.997607 15 1.00068C13.5296 0.956163 12.0981 1.47687 11 2.45568C9.90188 1.47687 8.47037 0.956163 7.00001 1.00068C6.14065 0.997607 5.29132 1.18517 4.51318 1.54986C3.73504 1.91454 3.04747 2.44728 2.50001 3.10968C1.54701 4.26568 0.550007 6.35868 1.21101 9.76968C2.26601 15.2167 10.177 19.6867 10.511 19.8697C10.6599 19.9527 10.8275 19.9963 10.998 19.9963C11.1685 19.9963 11.3361 19.9527 11.485 19.8697C11.821 19.6827 19.732 15.2127 20.785 9.76968C21.45 6.35868 20.453 4.26568 19.5 3.10968ZM18.826 9.38968C18.08 13.2407 12.658 16.8227 11 17.8407C8.66401 16.4307 3.85801 12.8907 3.17901 9.38968C2.66601 6.74368 3.36801 5.20668 4.04801 4.38268C4.40761 3.94879 4.85879 3.59987 5.36916 3.36093C5.87954 3.12199 6.43647 2.99897 7.00001 3.00068C7.6019 2.95554 8.20522 3.06714 8.75114 3.32459C9.29706 3.58204 9.76696 3.97656 10.115 4.46968C10.2008 4.62762 10.3274 4.75964 10.4817 4.852C10.6359 4.94436 10.812 4.99367 10.9918 4.99479C11.1715 4.99592 11.3483 4.94881 11.5036 4.85839C11.659 4.76797 11.7872 4.63753 11.875 4.48068C12.2224 3.98372 12.6933 3.58586 13.2413 3.32633C13.7893 3.06681 14.3954 2.95457 15 3.00068C15.5647 2.99793 16.123 3.12046 16.6347 3.35943C17.1464 3.59841 17.5986 3.94788 17.959 4.38268C18.637 5.20668 19.339 6.74368 18.826 9.38968Z"
        fill={props.fill ? props.fill : theme.palette.primary.main}
      />
    </svg>
  );
};

export default FitnessHeartIcon;
