import Box from '@mui/material/Box';
import { styled, Typography, TypographyProps, useTheme } from '@mui/material';
import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const GraphTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
  position: 'relative',
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightRegular,
  '&::before': {
    top: '50%',
    width: '8px',
    height: '8px',
    left: '-16px',
    content: '" "',
    borderRadius: '50%',
    position: 'absolute',
    transform: 'translateY(-50%)',
  },
}));

type DotType = {
  text: string;
  dotColor: string;
};
type GraphMeasurementsProps = {
  dots?: DotType[];
  measurement: string;
  value?: string | number;
};
const GraphMeasurements: FC<GraphMeasurementsProps> = ({
  dots = [],
  value,
  measurement,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      gap={theme.spacing(1)}
      padding={theme.spacing(0, 2, 2, 2)}
    >
      {value && (
        <Box display={'flex'} alignItems={'center'} gap={theme.spacing(1)}>
          <Typography variant={'h1'} fontWeight={theme.typography.fontWeightMedium}>
            {t('goals.latest')} {value}
          </Typography>
          <Typography
            variant={'subtitle1'}
            color={theme.palette.primary.light}
            fontWeight={theme.typography.fontWeightRegular}
          >
            {measurement}
          </Typography>
        </Box>
      )}
      <Box
        display={'flex'}
        alignItems={'center'}
        gap={theme.spacing(3)}
        paddingLeft={theme.spacing(2)}
      >
        {dots.map(dot => (
          <GraphTypography
            variant={'body1'}
            sx={{ background: dot.dotColor ? dot.dotColor : '#222222' }}
          >
            {dot.text}
          </GraphTypography>
        ))}
      </Box>
    </Box>
  );
};

export default GraphMeasurements;
