import { FC } from 'react';
import Box from '@mui/material/Box';
import { CustomButton, ModalMobile } from '@hdcorner/ui-library';
import { BoxProps, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

const MainBox = styled(Box, {
  shouldForwardProp: propName => propName !== 'web',
})<BoxProps & { web: boolean }>(({ web, theme }) => ({
  display: 'flex',
  borderRadius: '8px',
  gap: theme.spacing(3),
  boxSizing: 'border-box',
  flexDirection: 'column',
  padding: theme.spacing(3),
  width: web ? '351px' : 'none',
}));

type Props = {
  open: boolean;
  handleNo: () => void;
  handleYes: () => void;
  setOpen: (open: boolean) => void;
};

const ModalCCDetected: FC<Props> = ({ open, setOpen, handleNo, handleYes }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const web = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <ModalMobile open={open} setOpen={setOpen}>
      <MainBox web={web}>
        <Typography
          variant={'subtitle2'}
          sx={{ fontWeight: theme.typography.fontWeightBold }}
        >
          {t('healthProf.clinicalCoach.text.ccDetected')}
        </Typography>

        <Typography
          variant={'subtitle2'}
          sx={{ color: theme.palette.kmColorsCoolGrey.main }}
        >
          {t('healthProf.clinicalCoach.text.elevateYour')}
        </Typography>

        <Box gap={2} display={'flex'}>
          <CustomButton fullWidth onClick={handleYes} variant={'contained'}>
            {t('userDetails.yes')}
          </CustomButton>
          <CustomButton
            fullWidth
            onClick={handleNo}
            variant={'contained'}
            style={{
              backgroundColor: theme.palette.kmColorsCoolGrey.main,
            }}
          >
            {t('userDetails.no')}
          </CustomButton>
        </Box>
      </MainBox>
    </ModalMobile>
  );
};

export default ModalCCDetected;
