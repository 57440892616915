import { useIonToast } from '@ionic/react';
import { useAppDispatch } from '../redux/hooks';
import { useMediaQuery } from '@mui/material';
import { theme } from '@hdcorner/ui-library';
import { showAlert } from '../redux/slices/appSlice';

const useAlert = () => {
  const dispatch = useAppDispatch();

  const web = useMediaQuery(theme.breakpoints.up('sm'));

  const [present] = useIonToast();

  const presentSuccess = (message: string) => {
    if (web) {
      dispatch(showAlert({ message, severity: 'success' }));
      return;
    }

    present({
      message,
      duration: 2000,
      position: 'top',
      color: 'success',
    });
  };

  const presentError = (message: string) => {
    if (web) {
      dispatch(showAlert({ message, severity: 'error' }));
      return;
    }

    present({
      message,
      duration: 2000,
      color: 'danger',
      position: 'top',
    });
  };

  const presentWarning = (message: string) => {
    if (web) {
      dispatch(showAlert({ message, severity: 'warning' }));
      return;
    }

    present({
      message,
      duration: 2000,
      color: 'warning',
      position: 'top',
    });
  };

  const presentInfo = (message: string) => {
    if (web) {
      dispatch(showAlert({ message, severity: 'info' }));
      return;
    }

    present({
      message,
      duration: 2000,
      color: 'primary',
      position: 'top',
    });
  };

  return { presentSuccess, presentError, presentWarning, presentInfo };
};

export default useAlert;
