import { Box, Typography, TypographyProps, useTheme } from '@mui/material';
import moment from 'moment';
import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import { Notification } from '../types/Notification';

const Date = styled(Typography, {
  shouldForwardProp: prop =>
    prop !== 'isUnread' &&
    prop !== 'disablePaddingTop' &&
    prop !== 'isNotificationDialog',
})<
  TypographyProps & {
    isUnread?: boolean;
    disablePaddingTop?: boolean;
    isNotificationDialog?: boolean;
  }
>(({ theme, isUnread, disablePaddingTop, isNotificationDialog }) => ({
  ...theme.typography.body2,
  fontWeight: theme.typography.fontWeightRegular,
  display: 'flex',
  flexShrink: 1,
  padding: theme.spacing(0),
  color: isNotificationDialog
    ? isUnread
      ? theme.palette.primary.main
      : theme.palette.kmColorsCoolGrey.main
    : theme.palette.kmColorsDarkGrey.main,
  paddingTop: disablePaddingTop ? theme.spacing(0) : theme.spacing(0.1),
}));

type NotificationComponentType = {
  data: Notification;
  centerElements?: boolean;
  isNotificationDialog?: boolean;
  onNotificationClick: (id: string) => void;
};
const NotificationComponent: FC<NotificationComponentType> = ({
  data,
  centerElements,
  onNotificationClick,
  isNotificationDialog = true,
}) => {
  const theme = useTheme();

  return (
    <Box
      flex={1}
      padding={2}
      display={'flex'}
      alignItems={centerElements ? 'center' : 'flex-start'}
      onClick={data.read ? undefined : () => onNotificationClick(data._id)}
    >
      <Box display={'flex'} flexGrow={1}>
        <Typography
          variant={'body1'}
          fontWeight={'fontWeightBold'}
          sx={{
            color: isNotificationDialog
              ? !data.read
                ? theme.palette.primary.main
                : theme.palette.kmColorsCoolGrey.main
              : theme.palette.kmColorsDarkGrey.main,
          }}
        >
          {data.title}
          <br />
          <span
            style={{
              color: isNotificationDialog
                ? !data.read
                  ? theme.palette.primary.main
                  : theme.palette.kmColorsCoolGrey.main
                : theme.palette.kmColorsDarkGrey.main,
            }}
          >
            {data.body}
          </span>
        </Typography>
      </Box>
      <Box display={'flex'} flexShrink={1}>
        <Date
          align="right"
          isUnread={!data.read}
          disablePaddingTop={centerElements}
          isNotificationDialog={isNotificationDialog}
        >
          {moment(data.createdAt).format('DD/MM/YYYY')}
        </Date>
      </Box>
    </Box>
  );
};

export default NotificationComponent;
