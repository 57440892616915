const hcpSpecialties = [
  { label: 'General Doctor', value: 'general_doctor' },
  { label: 'General Practitioner', value: 'general_practitioner' },
  { label: 'Diabetologist', value: 'diabetologist' },
  { label: 'Doctor', value: 'doctor' },
  { label: 'Pharmacist', value: 'pharmacist' },
  { label: 'Dietician', value: 'dietician' },
  { label: 'Nurse', value: 'nurse' },
  { label: 'Psychologist', value: 'psychologist' },
  { label: 'Personal Trainer', value: 'personal_trainer' },
  {
    label: 'HD Corner Supportive Clinical Coach',
    value: 'hd_corner_supportive_clinical_coach',
  },
];

export { hcpSpecialties };
