import { Box, Typography } from '@mui/material';
import { CustomButton, theme } from '@hdcorner/ui-library';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';

type QuestionnaireEndButtonsProps = {
  web: boolean;
  count: number;
  decrementCount: () => void;
};
const QuestionnaireEndButtons: FC<QuestionnaireEndButtonsProps> = ({
  web,
  count,
  decrementCount,
}) => {
  const { t } = useTranslation();

  return (
    <Box gap={2} display={'flex'} textAlign={'center'} flexDirection={'column'}>
      <Box display={count === 0 ? 'none' : 'block'}>
        <CustomButton onClick={decrementCount}>{t('buttons.goBack')}</CustomButton>
      </Box>
      <Box textAlign={'center'}>
        <Typography
          variant={'body1'}
          sx={{
            display: web ? 'inherit' : 'none',
            color: theme.palette.primary.light,
            fontWeight: theme.typography.fontWeightRegular,
          }}
        >
          {t('karabinis.footer', { year: moment().year() })}
        </Typography>
      </Box>
    </Box>
  );
};

export default QuestionnaireEndButtons;
