import React, { FC, useCallback } from 'react';
import Box from '@mui/material/Box';
import { styled, useMediaQuery, useTheme } from '@mui/material';
import { ToggleDataType } from '../types/ToggleDataType';
import GraphMeasurements from './GraphMeasurements';
import ChartNumbersInfo from './tables/ChartNumbersInfo';
import ToggleButtonsComp from './ToggleButtonsComp';
import LineChart from './charts/LineChart';
import FeelsEmpty from './FeelsEmpty';

const GraphContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderRadius: '8px',
  flexDirection: 'column',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.secondary.main
      : theme.palette.secondary.light,
  paddingBottom: theme.spacing(2),
}));

type HBAC1ChartProps = {
  latestMeasurement?: number;
  selectedTimeframe?: string;
  overallData: ToggleDataType[];
  handleOpenCalendar?: () => void;
  chartData: { x: string; y: number }[];
  selectTimeframe?: (value: string) => void;
  headings: { label: string; value: string }[];
};
const HBAC1Chart: FC<HBAC1ChartProps> = ({
  chartData,
  headings,
  overallData,
  selectTimeframe,
  selectedTimeframe,
  latestMeasurement,
  handleOpenCalendar,
}) => {
  const theme = useTheme();
  const web = useMediaQuery(theme.breakpoints.up('sm'));

  const getValue = useCallback(
    (type: string) => {
      const value = overallData.find(
        item => item.name.toLowerCase() === type.toLowerCase(),
      )?.value;
      return value ? value : '-';
    },
    [overallData],
  );

  const handleSetOpen = useCallback(() => {
    if (handleOpenCalendar) {
      handleOpenCalendar();
    }
  }, [handleOpenCalendar]);

  return (
    <GraphContainer>
      {web ? (
        <Box padding={2}>
          <ChartNumbersInfo
            isHorizontal
            numberOne={getValue('max')}
            numberTwo={getValue('min')}
            numberThree={getValue('avg')}
          />
        </Box>
      ) : (
        <ToggleButtonsComp
          data={overallData}
          headings={headings}
          setOpen={handleSetOpen}
          textColor={theme.palette.diabetes.main}
          setToggleButtonsValue={selectTimeframe}
          customColor={theme.palette.diabetes.main}
          toggleButtonsValue={selectedTimeframe ? selectedTimeframe : ''}
        />
      )}
      {latestMeasurement && (
        <GraphMeasurements value={latestMeasurement} measurement={'%'} />
      )}
      {chartData.length > 0 && <LineChart chartData={chartData} />}
      {chartData.length === 0 && <FeelsEmpty />}
    </GraphContainer>
  );
};

export default HBAC1Chart;
