import React, { SVGProps } from 'react';

const CCEmailIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="16"
      height="13"
      fill="none"
      viewBox="0 0 16 13"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.666 0.5H1.33268C1.15587 0.5 0.986302 0.570238 0.861278 0.695262C0.736253 0.820286 0.666016 0.989856 0.666016 1.16667V11.8333C0.666016 12.0101 0.736253 12.1797 0.861278 12.3047C0.986302 12.4298 1.15587 12.5 1.33268 12.5H14.666C14.8428 12.5 15.0124 12.4298 15.1374 12.3047C15.2624 12.1797 15.3327 12.0101 15.3327 11.8333V1.16667C15.3327 0.989856 15.2624 0.820286 15.1374 0.695262C15.0124 0.570238 14.8428 0.5 14.666 0.5ZM13.9993 11.1667H1.99935V4.818L7.75135 7.11933C7.91066 7.18245 8.08804 7.18245 8.24735 7.11933L13.9993 4.818V11.1667ZM13.9993 3.382L7.99935 5.782L1.99935 3.382V1.83333H13.9993V3.382Z"
        fill="#222222"
      />
    </svg>
  );
};

export default CCEmailIcon;
