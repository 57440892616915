import React, { FC } from 'react';
import { Box } from '@mui/material';
import { ModalMobile, SettingsPassPanelMobile } from '@hdcorner/ui-library';
import { useUpdateChangePasswordMutation } from '../queries/accountSettingsQueries';
import { useAppSelector } from '../../../redux/hooks';
import { useSignInMutation } from '../../authentication/queries/signInQueries';
import { useTranslation } from 'react-i18next';
import useAlert from '../../../hooks/useAlert';

type ModalChangePasswordProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};
const ModalChangePassword: FC<ModalChangePasswordProps> = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const { presentError } = useAlert();

  const {
    data: { user },
  } = useAppSelector(state => state.auth);

  const [login] = useSignInMutation();
  const [updatePassword] = useUpdateChangePasswordMutation();

  const changePassword = async (currentPassword: string, newPassword: string) => {
    if (!user || !user.authUser || !user.authUser.email) {
      presentError(t('errors.profile.errorUpdateEmail'));
      return;
    }
    try {
      await login({ email: user.authUser.email, password: currentPassword }).unwrap();
      await updatePassword({
        oldPassword: currentPassword,
        newPassword: newPassword,
      }).unwrap();
    } catch (e) {
      console.log(e);
      presentError(t('errors.profile.errorUpdatePass'));
    }
  };

  return (
    <ModalMobile open={open} height={'auto'} setOpen={setOpen} width={'512px'}>
      <Box padding={2} boxSizing={'border-box'}>
        <SettingsPassPanelMobile handleNext={changePassword} />
      </Box>
    </ModalMobile>
  );
};

export default ModalChangePassword;
