import * as React from 'react';
import { SVGProps } from 'react';
import { useTheme } from '@mui/material';

const GiftIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  return (
    <svg
      {...props}
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.9048 9.04824H25.5881C25.6778 8.65791 25.7202 8.25821 25.7143 7.85776C25.7649 7.21956 25.6764 6.57799 25.455 5.97731C25.2335 5.37662 24.8844 4.83112 24.4317 4.37843C23.979 3.92575 23.4335 3.57665 22.8329 3.35522C22.2322 3.13379 21.5906 3.0453 20.9524 3.09585C18.3762 3.09585 16.2274 5.79943 15 7.77205C13.7726 5.79943 11.6238 3.09585 9.04764 3.09585C8.40944 3.0453 7.76787 3.13379 7.16719 3.35522C6.56651 3.57665 6.021 3.92575 5.56832 4.37843C5.11563 4.83112 4.76653 5.37662 4.5451 5.97731C4.32367 6.57799 4.23518 7.21956 4.28574 7.85776C4.27988 8.25821 4.32225 8.65791 4.41193 9.04824H3.09526C2.77953 9.04824 2.47673 9.17366 2.25347 9.39692C2.03021 9.62018 1.90479 9.92298 1.90479 10.2387V16.1911C1.90479 16.5068 2.03021 16.8096 2.25347 17.0329C2.47673 17.2561 2.77953 17.3816 3.09526 17.3816H4.28574V25.7149C4.28574 26.0306 4.41116 26.3334 4.63442 26.5567C4.85768 26.78 5.16048 26.9054 5.47621 26.9054H24.5238C24.8396 26.9054 25.1424 26.78 25.3656 26.5567C25.5889 26.3334 25.7143 26.0306 25.7143 25.7149V17.3816H26.9048C27.2205 17.3816 27.5233 17.2561 27.7466 17.0329C27.9698 16.8096 28.0953 16.5068 28.0953 16.1911V10.2387C28.0953 9.92298 27.9698 9.62018 27.7466 9.39692C27.5233 9.17366 27.2205 9.04824 26.9048 9.04824ZM9.04764 5.47681C10.3726 5.47681 11.9274 7.36133 12.9857 9.04824H9.70955C6.66669 9.04824 6.66669 8.57205 6.66669 7.85776C6.66669 5.72443 8.02621 5.47681 9.04764 5.47681ZM13.8095 24.5244H6.66669V17.3816H13.8095V24.5244ZM13.8095 15.0006H4.28574V11.4292H13.8095V15.0006ZM20.9524 5.47681C21.9738 5.47681 23.3334 5.72443 23.3334 7.85776C23.3334 8.57205 23.3334 9.04824 20.2905 9.04824H17.0143C18.0726 7.36133 19.6274 5.47681 20.9524 5.47681ZM23.3334 24.5244H16.1905V17.3816H23.3334V24.5244ZM25.7143 15.0006H16.1905V11.4292H25.7143V15.0006Z"
        fill={props.color ? props.color : theme.palette.secondary.light}
      />
    </svg>
  );
};

export default GiftIcon;
