import { MobileProfile, theme } from '@hdcorner/ui-library';
import { useIonRouter } from '@ionic/react';
import { Box, useMediaQuery } from '@mui/material';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import PageLayout from '../../components/PageLayout';
import {
  useGetAuthUserQuery,
  useSignOutMutation,
} from '../authentication/queries/signInQueries';
import WebAccountSettingsContainer from './components/WebAccountSettingsContainer';
import { useGetUserDataQuery } from './queries/personalInfoQueries';
import { useAppDispatch } from '../../redux/hooks';
import { logout } from '../authentication/authSlice';
import useAlert from '../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

const Profile = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { presentError } = useAlert();

  const web = useMediaQuery(theme.breakpoints.up('sm'));
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [authUserLogout] = useSignOutMutation();

  const { data: authUserResponse, error: errorAuthUser } = useGetAuthUserQuery();
  const { data: userDataResponse, error: errorUserData } = useGetUserDataQuery();

  useEffect(() => {
    if (errorAuthUser || errorUserData) presentError(t('errors.profile.errorUserData'));
  }, [errorAuthUser, errorUserData]);

  const prepareData = useMemo(() => {
    const authUserData = authUserResponse && authUserResponse[0];
    const userData = userDataResponse && userDataResponse[0];

    return {
      image: authUserData
        ? authUserData.profilePicture
          ? authUserData.profilePicture.url
          : ''
        : '',
      plan: userData
        ? userData.premiumUser
          ? t('userDetails.premium')
          : t('userDetails.basic')
        : '-',
      name: authUserData ? `${authUserData.firstName} ${authUserData.lastName}` : '-',
      joined: authUserData ? moment(authUserData.createdAt).format('DD/MM/YYYY') : '-',
    };
  }, [authUserResponse, userDataResponse]);

  const handlePageRouting = (path: string) => {
    if (path === 'sign-in') {
      authUserLogout()
        .unwrap()
        .finally(() => {
          dispatch(logout());
          router.push('/', 'root', 'replace');
        });
    } else {
      router.push(`/dashboard/${path}`);
    }
  };

  return (
    <PageLayout backgroundWhite={!web} headerTitle={t('headingsTitles.myHd')}>
      <Box hidden={mobile} padding={3} boxSizing={'border-box'}>
        <WebAccountSettingsContainer />
      </Box>

      <Box hidden={web}>
        <MobileProfile
          data={prepareData}
          healthProf={false}
          handlePageRouting={handlePageRouting}
        />
      </Box>
    </PageLayout>
  );
};

export default Profile;
