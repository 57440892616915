import FantasticEmoji from '../assets/FantasticEmoji';
import NotGoodEmoji from '../assets/NotGoodEmoji';
import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { Box, styled, Typography } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography/Typography';
import OkEmoji from '../assets/OkEmoji';
import FantasticEmojiOutlined from '../assets/FantasticEmojiOutlined';
import OkEmojiOutlined from '../assets/OkEmojiOutlined';
import NotGoodEmojiOutlined from '../assets/NotGoodEmojiOutlined';
import { useTranslation } from 'react-i18next';

const ButtonMain = styled(Button, {
  shouldForwardProp: prop => prop !== 'isSelected',
})<ButtonProps & { isSelected: boolean }>(({ theme, isSelected }) => ({
  width: 74,
  height: 88,
  display: 'flex',
  flexDirection: 'column',
  background: isSelected ? theme.palette.secondary.main : undefined,
  border: isSelected ? `1px solid ${theme.palette.primary.light}` : undefined,
}));

const Text = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  marginTop: theme.spacing(1),
  fontWeight: theme.typography.fontWeightRegular,
}));

export type IconButtonsType = {
  label: string;
  value: string;
  iconSelected: React.ReactNode;
  iconUnselected: React.ReactNode;
};

const iconButtons: IconButtonsType[] = [
  {
    label: 'general.labels.fantastic',
    value: 'fantastic',
    iconSelected: <FantasticEmoji width={48} height={48} />,
    iconUnselected: <FantasticEmojiOutlined width={48} height={48} />,
  },
  {
    label: 'general.labels.ok',
    value: 'ok',
    iconSelected: <OkEmoji width={48} height={48} />,
    iconUnselected: <OkEmojiOutlined width={48} height={48} />,
  },
  {
    label: 'general.labels.notGood',
    value: 'not_good',
    iconSelected: <NotGoodEmoji width={48} height={48} />,
    iconUnselected: <NotGoodEmojiOutlined width={48} height={48} />,
  },
];

type EmojisSelectType = {
  selected?: string;
  handleClickIcon: (item: IconButtonsType) => void;
};

const EmojisSelect = ({ selected, handleClickIcon }: EmojisSelectType) => {
  const { t } = useTranslation();
  const handleShowIcon = (item: IconButtonsType) => {
    if (!selected) return item.iconUnselected;

    if (selected === item.value) {
      return item.iconSelected;
    }
    return item.iconUnselected;
  };

  return (
    <Box
      width={'100%'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      {iconButtons.map((item, index) => (
        <ButtonMain
          fullWidth
          key={index}
          isSelected={selected === item.value}
          onClick={() => handleClickIcon(item)}
        >
          {handleShowIcon(item)}
          <Text variant={'body1'} textTransform={'capitalize'}>
            {t(item.label)}
          </Text>
        </ButtonMain>
      ))}
    </Box>
  );
};

export default EmojisSelect;
