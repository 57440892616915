import React from 'react';
import { theme, ComponentsLayout } from '@hdcorner/ui-library';
import PageLayout from '../../../../components/PageLayout';
import CongratulationsScreen from './CongratulationsScreen';
import { useIonRouter } from '@ionic/react';

const WorkoutCongratulations = () => {
  const router = useIonRouter();

  const handleOnClick = () => {
    router.push('/dashboard/fitness');
  };

  return (
    <PageLayout backgroundWhite defaultHref={'/dashboard/fitness'}>
      <ComponentsLayout sx={{ paddingX: theme.spacing(3.75) }}>
        <CongratulationsScreen onClick={handleOnClick} />
      </ComponentsLayout>
    </PageLayout>
  );
};

export default WorkoutCongratulations;
