import * as React from 'react';
import { SVGProps } from 'react';

const SearchIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props && props.width ? props.width : '21px'}
      height={props && props.height ? props.height : '21px'}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.62398 13.9999C4.00872 15.3866 5.85275 16.2191 7.80868 16.3405C9.76462 16.4618 11.6974 15.8638 13.243 14.6589L18.561 19.9769C18.7496 20.1591 19.0022 20.2599 19.2644 20.2576C19.5266 20.2553 19.7774 20.1502 19.9628 19.9648C20.1482 19.7793 20.2534 19.5285 20.2557 19.2663C20.2579 19.0041 20.1571 18.7515 19.975 18.5629L14.657 13.2449C15.9153 11.6301 16.5102 9.59608 16.3204 7.5577C16.1305 5.51933 15.1703 3.63013 13.6355 2.2754C12.1006 0.920666 10.1068 0.202446 8.06065 0.267212C6.01447 0.331978 4.07007 1.17485 2.62398 2.62393C1.87678 3.37076 1.28405 4.25748 0.879642 5.23345C0.475238 6.20942 0.26709 7.2555 0.26709 8.31193C0.26709 9.36837 0.475238 10.4145 0.879642 11.3904C1.28405 12.3664 1.87678 13.2531 2.62398 13.9999ZM4.03798 4.03993C5.02656 3.05138 6.32731 2.43618 7.71861 2.29913C9.10992 2.16209 10.5057 2.51168 11.6681 3.28836C12.8306 4.06503 13.6878 5.22073 14.0937 6.55855C14.4996 7.89637 14.4291 9.33354 13.8941 10.6252C13.3592 11.9169 12.393 12.9831 11.1601 13.6422C9.92721 14.3014 8.50391 14.5126 7.13271 14.24C5.7615 13.9674 4.52723 13.2279 3.64018 12.1473C2.75313 11.0667 2.26819 9.71197 2.26798 8.31393C2.26524 7.51951 2.42029 6.73245 2.72413 5.99842C3.02798 5.26439 3.47456 4.598 4.03798 4.03793V4.03993Z"
        fill={props && props.color ? props.color : '#979FA5'}
      />
    </svg>
  );
};

export default SearchIcon;
