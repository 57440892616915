import React from 'react';
import PageLayout from '../../components/PageLayout';
import { ComponentsLayout } from '@hdcorner/ui-library';
import TrafficLightTabsList from './nutrition/components/TrafficLightTabsList';
import { useTranslation } from 'react-i18next';

const TrafficLight = () => {
  const { t } = useTranslation();

  return (
    <PageLayout
      headerTitle={t('headingsTitles.tipsTricks')}
      defaultHref={'/dashboard/fitness/nutrition/tips-tricks'}
    >
      <ComponentsLayout>
        <TrafficLightTabsList />
      </ComponentsLayout>
    </PageLayout>
  );
};

export default TrafficLight;
