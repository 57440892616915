import React, { FC } from 'react';
import {
  Box,
  BoxProps,
  IconButton,
  styled,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import EditPencil from '../../assets/EditPencil';

const BoxHeader = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  boxSizing: 'border-box',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.background.default
      : theme.palette.secondary.light,
  borderBottom:
    theme.palette.mode === 'dark'
      ? `1px solid ${theme.palette.kmColorsDarkGrey.main}`
      : `1px solid ${theme.palette.secondary.main}`,
  padding: theme.spacing(1, 1, 1, 2),
}));

const TypographyHeader = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.subtitle1.fontSize,
}));

type ChatSendersHeaderWebProps = {
  title: string;
  collapsed?: boolean;
  handleNewMessage: () => void;
};
const ChatSendersHeaderWeb: FC<ChatSendersHeaderWebProps> = ({
  title,
  collapsed,
  handleNewMessage,
}) => {
  const theme = useTheme();
  return (
    <BoxHeader>
      {!collapsed ? <TypographyHeader>{title}</TypographyHeader> : null}
      <IconButton
        size="small"
        onClick={handleNewMessage}
        disableRipple
        sx={{ height: '40px', width: '40px' }}
      >
        <EditPencil width={'22px'} height={'22px'} color={theme.palette.primary.main} />
      </IconButton>
    </BoxHeader>
  );
};

export default ChatSendersHeaderWeb;
