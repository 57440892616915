import { useIonRouter } from '@ionic/react';
import OnBoardingHeading from './OnBoardingHeading';
import UserDetailsThree from './UserDetailsThree';
import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import UserDetailsWebOne from './UserDetailsWebOne';
import LogoHeartPNG from '../../../../assets/illustrations/LogoHeartPNG.png';
import { AuthUser, theme, UserData } from '@hdcorner/ui-library';
import {
  useSetOnboardingOneMutation,
  useSetOnboardingTwoMutation,
} from '../../queries/onBoardingQueries';
import useQuery from '../../../../hooks/useQuery';
import { useAppDispatch } from '../../../../redux/hooks';
import { setAuthUser } from '../../authSlice';
import useAlert from '../../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  useGetUserDataQuery,
  useUpdateUserDataMutation,
} from '../../../profile/queries/personalInfoQueries';

const OnBoardingPanelWeb = () => {
  const dispatch = useAppDispatch();
  const { presentError } = useAlert();
  const router = useIonRouter();
  const searchParams = useQuery();
  const { t } = useTranslation();

  const [count, setCount] = useState<number>(1);

  const [onboardingPtOne, { isLoading: onBoardingOneLoading }] =
    useSetOnboardingOneMutation();
  const [onboardingPtTwo, { isLoading: onBoardingTwoLoading }] =
    useSetOnboardingTwoMutation();
  const [updateUserData, { isLoading: updateUserDataLoading }] =
    useUpdateUserDataMutation();

  const { data: userDataRes } = useGetUserDataQuery();

  useEffect(() => {
    if (searchParams.get('incomplete')) {
      setCount(2);
    }
  }, [searchParams]);

  const handleNextPage = (data: Partial<AuthUser & UserData>) => {
    if (count === 2) {
      sendUserDataTwo(data);
    } else {
      sendUserDataOne(data);
    }
  };

  const sendUserDataOne = async (data: Partial<AuthUser>) => {
    try {
      await onboardingPtOne({
        gender: data.gender,
        lastName: data.lastName,
        firstName: data.firstName,
        birthDate: data.birthDate,
      }).unwrap();

      dispatch(
        setAuthUser({
          gender: data.gender,
          lastName: data.lastName,
          firstName: data.firstName,
          birthDate: data.birthDate,
        }),
      );

      setCount(count + 1);
    } catch (e) {
      console.log(e);
      presentError(t('authentication.onboarding.error1'));
    }
  };

  const sendUserDataTwo = async (data: Partial<UserData>) => {
    try {
      const body: Partial<UserData> = {
        phone: data.phone,
        smoker: data.smoker,
        height: data.height,
        weight: data.weight,
        diabetes: data.diabetes,
        hypertension: data.hypertension,
        dyslipidemia: data.dyslipidemia,
        underMedication: data.underMedication,
        socialSecurityNumber: data.socialSecurityNumber,
      };
      if (userDataRes?.[0]) {
        await updateUserData({
          ...userDataRes[0],
          ...body,
        }).unwrap();
      } else {
        await onboardingPtTwo(body).unwrap();
      }
      router.push('/dashboard/questionnaire');
      setCount(1);
    } catch (e) {
      console.log(e);
      presentError(t('authentication.onboarding.error1'));
    }
  };

  return (
    <Box
      height={'100%'}
      display={'flex'}
      maxWidth={'560px'}
      overflow={'scroll'}
      flexDirection={'column'}
      alignContent={'space-between'}
      margin={theme.spacing(0, 'auto')}
      padding={theme.spacing(10, 13, 3, 13)}
      sx={{
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <Box marginBottom={3}>
        <Box textAlign={'center'}>
          <img src={LogoHeartPNG} alt={'company logo'} width={'78px'} height={'80px'} />
        </Box>
        <OnBoardingHeading web />

        {count === 1 && (
          <UserDetailsWebOne
            loading={onBoardingOneLoading}
            handleNextPage={handleNextPage}
          />
        )}

        {count === 2 && (
          <UserDetailsThree
            web
            user={userDataRes?.[0]}
            handleNextPage={handleNextPage}
            loading={onBoardingTwoLoading || updateUserDataLoading}
          />
        )}
      </Box>

      <Box textAlign={'center'}>
        <Typography
          variant={'body1'}
          sx={{
            color: theme.palette.primary.light,
            fontWeight: theme.typography.fontWeightRegular,
          }}
        >
          {t('karabinis.footer', { year: moment().year() })}
        </Typography>
      </Box>
    </Box>
  );
};

export default OnBoardingPanelWeb;
