import React, { FC, useCallback } from 'react';
import {
  Avatar,
  AvatarProps,
  Badge,
  BadgeProps,
  Box,
  BoxProps,
  IconButton,
  styled,
} from '@mui/material';
import CameraIcon from '../../assets/CameraIcon';
import { Accept, FileRejection, useDropzone } from 'react-dropzone';

const Container = styled(Box)<BoxProps>(() => ({
  position: 'relative',
  width: 70,
  height: 70,
}));

const BadgeMain = styled(Badge)<BadgeProps>(({ theme }) => ({
  '.MuiBadge-badge': {
    right: theme.spacing(0.75),
    bottom: theme.spacing(0.75),
  },
}));

const AvatarMain = styled(Avatar)<AvatarProps>(() => ({
  borderRadius: 8,
  width: 64,
  height: 64,
  '.MuiAvatar-img': { objectFit: 'cover' },
}));

const CameraButton = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.palette.secondary.main,
  width: 24,
  height: 24,
  borderRadius: '4px',
  overflow: 'hidden',
}));

type UserParticipantImageType = {
  photo: string;
  maxFiles?: number;
  maxFileSize?: number;
  acceptedFiles?: Accept;
  onFilesSelected: (files: File[], base64Files: string[]) => void;
};
const UserParticipantImage: FC<UserParticipantImageType> = ({
  photo,
  maxFiles,
  maxFileSize,
  acceptedFiles,
  onFilesSelected,
}) => {
  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (acceptedFiles.length > 0) {
        const promises: Promise<string>[] = acceptedFiles.map(file => {
          return new Promise<string>(resolve => {
            const reader = new FileReader();
            reader.onload = () => {
              const base64String = reader.result as string;
              resolve(base64String);
            };
            reader.readAsDataURL(file);
          });
        });

        Promise.all(promises).then(base64Results => {
          onFilesSelected(acceptedFiles, base64Results);
        });
      } else {
        console.error('Invalid file(s) rejected:', rejectedFiles);
      }
    },
    [onFilesSelected],
  );

  const { getInputProps } = useDropzone({
    onDrop,
    maxFiles,
    maxSize: maxFileSize,
    accept: acceptedFiles,
  });

  return (
    <Container>
      <BadgeMain
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
          <CameraButton>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
              size={'large'}
            >
              <input {...getInputProps()} />
              <CameraIcon />
            </IconButton>
          </CameraButton>
        }
      >
        <AvatarMain src={photo} />
      </BadgeMain>
    </Container>
  );
};

export default UserParticipantImage;
