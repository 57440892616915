import { FC } from 'react';
import {
  Avatar,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import { ClinicalCoachesFound } from '@hdcorner/ui-library';
import { useTranslation } from 'react-i18next';
import { findSpecialityLabel } from '../utils/findSpecialityLabel';

const MainBox = styled(Box, {
  shouldForwardProp: propName => propName !== 'checked',
})<BoxProps & { checked: boolean }>(({ theme, checked }) => ({
  flex: 1,
  display: 'flex',
  borderRadius: '8px',
  boxSizing: 'border-box',
  gap: theme.spacing(1),
  justifyContent: 'flex-start',
  padding: theme.spacing(1),
  backgroundColor: theme.palette.secondary.light,
  border: `1px solid ${
    checked ? theme.palette.primary.main : theme.palette.secondary.main
  }`,
}));

const TextDetails = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: theme.typography.body1.fontSize,
  fontWeight: theme.typography.fontWeightBold,
}));

type Props = {
  checked: boolean;
  data: ClinicalCoachesFound;
  handleCardClick: () => void;
};

const ClinicalCoachResultsCard: FC<Props> = ({ data, checked, handleCardClick }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <MainBox checked={checked} onClick={handleCardClick}>
      <Avatar
        variant={'rounded'}
        src={data.authUser.profilePicture?.url || ''}
        sx={{
          width: '60px',
          height: '60px',
          border: `1px solid ${theme.palette.secondary.main}`,
        }}
      />
      <Box gap={0.5} display={'flex'} flexDirection={'column'}>
        <Typography
          variant={'subtitle2'}
          sx={{
            color: theme.palette.kmColorsDarkGrey.main,
            fontWeight: theme.typography.fontWeightMedium,
          }}
        >
          {data.authUser.firstName} {data.authUser.lastName}
        </Typography>
        <TextDetails>{t(findSpecialityLabel(data.speciality))}</TextDetails>
        <TextDetails>{data.address}</TextDetails>
      </Box>
    </MainBox>
  );
};

export default ClinicalCoachResultsCard;
