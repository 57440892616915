import { FC } from 'react';
import CustomInput from '../controls/CustomInput';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

type ResetPassTwoProps = {
  password1: string;
  password2: string;
  setPassword1: (password1: string) => void;
  setPassword2: (password2: string) => void;
};
const ResetPassTwo: FC<ResetPassTwoProps> = ({
  password1,
  password2,
  setPassword1,
  setPassword2,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <>
      <CustomInput
        fullWidth
        value={password1}
        type={'password'}
        handleChange={setPassword1}
        sx={{ marginBottom: theme.spacing(7) }}
        label={t('authentication.forgotReset.labels.newPass')}
      />

      <CustomInput
        fullWidth
        type={'password'}
        value={password2}
        handleChange={setPassword2}
        label={t('authentication.forgotReset.labels.reenterPass')}
      />
    </>
  );
};

export default ResetPassTwo;
