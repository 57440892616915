import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import { Slider, SliderProps, SliderThumb, Typography } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography/Typography';
import SliderThumbIcon from '../../assets/SliderThumbIcon';

const CustomSliderMain = styled(Slider)<SliderProps>(({ theme }) => ({
  flex: 1,
  height: '8px',
  display: 'flex',
  color: theme.palette.error.main,
  '.MuiSlider-rail': {
    color: theme.palette.secondary.main,
  },
  '& .MuiSlider-track': {
    border: 'none',
  },
  '.MuiSlider-valueLabelOpen': {
    background: 'unset',
    color: theme.palette.primary.main,
  },
  '.MuiSlider-valueLabel.MuiSlider-valueLabelOpen': {
    transform: 'translateY(-14px) scale(1)',
  },
  '.MuiSlider-mark': {
    background: 'unset',
  },
  '& .MuiSlider-thumb': {
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.24)',
  },
  '.MuiSlider-markLabel[data-index="0"]': {
    fontSize: 12,
    transform: 'translateX(0%)',
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightRegular,
  },
  '.MuiSlider-markLabel[data-index="1"]': {
    fontSize: 12,
    transform: 'translateX(-100%)',
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const ValueLabel = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightRegular,
}));

interface SliderThumbComponentProps extends React.HTMLAttributes<unknown> {}

const SliderThumbComponent = (props: SliderThumbComponentProps) => {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      {<SliderThumbIcon />}
    </SliderThumb>
  );
};

type CustomSliderType = {
  valueLabelText?: string;
  customThumbIcon?: boolean;
  selected?: number | number[];
  setSliderValue: (value: number | number[]) => void;
};
const CustomSlider: FC<CustomSliderType & SliderProps> = ({
  selected,
  setSliderValue,
  customThumbIcon,
  valueLabelText,
  ...sliderProps
}) => {
  const ValueLabelComponent = (value: number) => {
    return (
      <ValueLabel>
        {value}
        {valueLabelText}
      </ValueLabel>
    );
  };

  return (
    <CustomSliderMain
      {...sliderProps}
      value={selected}
      valueLabelFormat={x => ValueLabelComponent(x)}
      onChange={(e, newValue) => setSliderValue(newValue)}
      components={customThumbIcon ? { Thumb: SliderThumbComponent } : undefined}
    />
  );
};

export default CustomSlider;
