export const minMaxAvg = (object: any, value: any) => {
  if (object) {
    const overallData = object[value];
    if (!overallData)
      return {
        min: 'N/A',
        max: 'N/A',
        avg: 'N/A',
      };
    const min = Math.round(overallData.min);
    const max = Math.round(overallData.max);
    const avg = Math.round(overallData.avg);

    return {
      min,
      max,
      avg,
    };
  }

  return {
    min: 'N/A',
    max: 'N/A',
    avg: 'N/A',
  };
};

export const minMaxObj = (title: string, why: () => any) => {
  const min = why().min;
  const max = why().max;
  const avg = why().avg;

  return {
    min,
    max,
    avg,
    title: title,
  };
};
