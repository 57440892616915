import React, { FC, useState } from 'react';
import { ArrowLeft, CustomTabs, Food, ModalMobile, theme } from '@hdcorner/ui-library';
import { Box, IconButton, Typography } from '@mui/material';
import CircleCounter from './CircleCounter';
import FoodsTab from './FoodsTab';
import MealsTab from './MealsTab';
import {
  deleteFoodArray,
  removeExistingMeal,
  updateAddFoodDetails,
} from '../slices/mealFoodsSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import AddFoodInner from './AddFoodInner';
import ModalAddFood from './ModalAddFood';
import CustomMealInner from './CustomMealInner';
import useAlert from '../../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';
import { mealCategories } from '../constants';

type Props = {
  open: boolean;
  category: string;
  setOpen: (value: boolean) => void;
};
const ModalFoodMealsWeb: FC<Props> = ({ open, setOpen, category }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { presentWarning } = useAlert();
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const { foods, addFoodDetails } = useAppSelector(state => state.mealFoodsList);

  /**
   * SCREENS:
   * 1: Foods / Meals
   * 2: Set item quantity
   * 3: Staging Foods
   * 4: Create Custom Meal
   */
  const [screenState, setScreenState] = useState<number>(1);
  const [hideSave, setHideSave] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [customFood, setCustomFood] = useState<boolean>(false);

  const handleAddFood = (data: Food) => {
    setScreenState(2);
    dispatch(updateAddFoodDetails({ ...data }));
  };

  const handleAddCustomFood = () => {
    dispatch(
      updateAddFoodDetails({
        fat: 0,
        _id: '',
        name: {
          en: '',
          el: '',
        },
        carbs: 0,
        protein: 0,
        calories: 0,
      }),
    );
    setScreenState(2);
    setCustomFood(true);
  };

  const handleSeeStagingFoods = () => {
    if (foods.length !== 0) {
      setScreenState(3);
    } else {
      presentWarning(t('errors.fitness.nutrition.warningFoodAdd'));
    }
  };

  const handleSaveFoods = () => {
    handleModalClose();
  };

  const handleCreateNewMeal = () => {
    setHideSave(true);
    setScreenState(4);
  };

  const handleModalClose = () => {
    setOpen(false);
    setSelectedTab(0);
    setScreenState(1);
    setCustomFood(false);
  };

  const handleBackClick = () => {
    if (screenState === 1) {
      handleModalClose();
    } else if (screenState === 2 && selectedTab === 1) {
      setScreenState(4);
    } else if (screenState === 2) {
      setScreenState(1);
      setCustomFood(false);
    } else if (screenState === 3 && selectedTab === 1) {
      setScreenState(4);
    } else if (screenState === 3) {
      setScreenState(1);
    } else if (screenState === 4) {
      setHideSave(false);
      setScreenState(1);
      dispatch(removeExistingMeal());
      dispatch(deleteFoodArray());
    } else {
      setScreenState(3);
    }
  };

  const translatedCategory = () => {
    const found = mealCategories.find(it => it.value === category);
    return found ? found.label : '';
  };

  const renderTitle = () => {
    switch (screenState) {
      case 1:
        return t(translatedCategory());
      case 2:
        return addFoodDetails?.name[lang] || t('fitness.nutrition.labels.creactCustom');
      case 3:
        return t('fitness.nutrition.labels.foods');
      case 4:
        return t('fitness.nutrition.labels.customMeal');
      default:
        return '';
    }
  };

  const renderStagingFoods = () => {
    if (screenState === 3 || screenState === 2) return null;
    return (
      <IconButton
        sx={{
          top: 0,
          right: 0,
          padding: 0,
          display: 'flex',
          position: 'absolute',
        }}
        onClick={handleSeeStagingFoods}
      >
        <CircleCounter count={foods.length} />
      </IconButton>
    );
  };

  return (
    <ModalMobile open={open} setOpen={handleModalClose}>
      <Box
        width={'375px'}
        display={'flex'}
        boxSizing={'border-box'}
        flexDirection={'column'}
        padding={theme.spacing(2)}
        bgcolor={theme.palette.highlight.main}
      >
        <Box
          mb={3}
          flexGrow={1}
          display={'flex'}
          textAlign={'center'}
          position={'relative'}
        >
          <IconButton
            onClick={handleBackClick}
            sx={{
              left: 0,
              top: '5px',
              padding: 0,
              position: 'absolute',
            }}
          >
            <ArrowLeft />
          </IconButton>
          <Typography variant={'h1'} textTransform={'capitalize'} flex={1}>
            {renderTitle()}
          </Typography>
          {renderStagingFoods()}
        </Box>

        {/* 1ST PANEL : FOODS / MEALS */}
        {screenState === 1 && (
          <Box flex={1} display={'flex'} flexDirection={'column'}>
            <CustomTabs
              value={selectedTab}
              setValue={setSelectedTab}
              tabs={[
                {
                  label: t('fitness.nutrition.labels.foods'),
                  children: (
                    <FoodsTab
                      handleAddFood={handleAddFood}
                      handleAddCustomFood={handleAddCustomFood}
                    />
                  ),
                },
                {
                  label: t('fitness.nutrition.labels.meals'),
                  children: (
                    <MealsTab
                      handleUpdateMeal={() => {
                        setHideSave(true);
                        setScreenState(4);
                      }}
                      handleCreateNewMeal={handleCreateNewMeal}
                    />
                  ),
                },
              ]}
            />
          </Box>
        )}

        {/* 2ND PANEL : ENTER QUANTITY SELECTED FOOD */}
        {screenState === 2 && (
          <Box display={'flex'} flexDirection={'column'}>
            <AddFoodInner
              customFood={customFood}
              handleSwitchPanel={() => {
                setCustomFood(false);
                setScreenState(selectedTab === 0 ? 1 : 4);
              }}
            />
          </Box>
        )}

        {/* 3RD PANEL : VIEW FOODS SELECTED (circular icon) */}
        {screenState === 3 && (
          <Box display={'flex'} flexDirection={'column'}>
            <ModalAddFood customMeal={hideSave} handleCloseModal={handleSaveFoods} />
          </Box>
        )}

        {/* 4TH PANEL : CREATE CUSTOM MEAL*/}
        {screenState === 4 && (
          <Box display={'flex'} flexDirection={'column'}>
            <CustomMealInner
              web
              handleAddItemWeb={handleAddFood}
              handleModalPanelWeb={() => {
                setScreenState(1);
                setHideSave(false);
              }}
            />
          </Box>
        )}
      </Box>
    </ModalMobile>
  );
};

export default ModalFoodMealsWeb;
