import * as React from 'react';
import { SVGProps } from 'react';
import { useTheme } from '@mui/material';

const FEOtherIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.3334 0.333984H1.00004C0.82323 0.333984 0.65366 0.404222 0.528636 0.529246C0.403612 0.654271 0.333374 0.82384 0.333374 1.00065V3.66732C0.333374 5.61224 1.10599 7.4775 2.48126 8.85277C3.85652 10.228 5.72179 11.0007 7.66671 11.0007C9.61163 11.0007 11.4769 10.228 12.8522 8.85277C14.2274 7.4775 15 5.61224 15 3.66732V1.00065C15 0.82384 14.9298 0.654271 14.8048 0.529246C14.6798 0.404222 14.5102 0.333984 14.3334 0.333984ZM7.66671 9.66732C6.1913 9.66553 4.76812 9.12098 3.66837 8.13741C2.56863 7.15385 1.8692 5.80005 1.70337 4.33398H13.63C13.4642 5.80005 12.7648 7.15385 11.665 8.13741C10.5653 9.12098 9.14212 9.66553 7.66671 9.66732ZM13.6667 3.00065H1.66671V1.66732H13.6667V3.00065ZM5.00004 13.6673C4.82323 13.6673 4.65366 13.5971 4.52864 13.4721C4.40361 13.347 4.33337 13.1775 4.33337 13.0007C4.33337 12.8238 4.40361 12.6543 4.52864 12.5292C4.65366 12.4042 4.82323 12.334 5.00004 12.334H10.3334C10.5102 12.334 10.6798 12.4042 10.8048 12.5292C10.9298 12.6543 11 12.8238 11 13.0007C11 13.1775 10.9298 13.347 10.8048 13.4721C10.6798 13.5971 10.5102 13.6673 10.3334 13.6673H5.00004Z"
        fill={
          theme.palette.mode === 'dark'
            ? theme.palette.kmColorsCoolGrey.main
            : theme.palette.primary.main
        }
      />
    </svg>
  );
};

export default FEOtherIcon;
