import * as React from 'react';
import { SVGProps } from 'react';

const HealthProfSidebarIcon = ({
  fill = '#222222',
  ...rest
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M7 10V8C7 7.73478 7.10535 7.48043 7.29289 7.29289C7.48043 7.10536 7.73478 7 8 7C8.26521 7 8.51957 7.10536 8.7071 7.29289C8.89464 7.48043 9 7.73478 9 8V10C9 10.2652 8.89464 10.5196 8.7071 10.7071C8.51957 10.8946 8.26521 11 8 11C7.73478 11 7.48043 10.8946 7.29289 10.7071C7.10535 10.5196 7 10.2652 7 10ZM14 11C14.2652 11 14.5196 10.8946 14.7071 10.7071C14.8946 10.5196 15 10.2652 15 10V8C15 7.73478 14.8946 7.48043 14.7071 7.29289C14.5196 7.10536 14.2652 7 14 7C13.7348 7 13.4804 7.10536 13.2929 7.29289C13.1054 7.48043 13 7.73478 13 8V10C13 10.2652 13.1054 10.5196 13.2929 10.7071C13.4804 10.8946 13.7348 11 14 11ZM22 11C22 13.1756 21.3549 15.3023 20.1462 17.1113C18.9375 18.9202 17.2195 20.3301 15.2095 21.1627C13.1995 21.9952 10.9878 22.2131 8.854 21.7886C6.72021 21.3642 4.7602 20.3166 3.22182 18.7782C1.68345 17.2398 0.635797 15.2798 0.211359 13.146C-0.213078 11.0122 0.00475854 8.80047 0.837322 6.79048C1.66989 4.78049 3.07978 3.06253 4.88872 1.85383C6.69767 0.645139 8.8244 0 11 0C13.9143 0.0100158 16.7064 1.17216 18.7671 3.23289C20.8278 5.29362 21.99 8.0857 22 11ZM2.028 10.451L6.513 13.142L8.749 12.015C9.4473 11.6647 10.2178 11.4823 10.999 11.4823C11.7802 11.4823 12.5507 11.6647 13.249 12.015L15.485 13.142L19.97 10.451C19.8334 8.16411 18.8286 6.01571 17.161 4.4448C15.4935 2.87389 13.289 1.99906 10.998 1.99906C8.70703 1.99906 6.50253 2.87389 4.83496 4.4448C3.16739 6.01571 2.16263 8.16411 2.026 10.451H2.028ZM19.8 12.888L17.6 14.207L19.075 14.95C19.4007 14.2951 19.6442 13.6026 19.8 12.888ZM18 16.647L12.351 13.8C11.932 13.5898 11.4698 13.4803 11.001 13.4803C10.5322 13.4803 10.07 13.5898 9.651 13.8L4 16.647C4.84125 17.6936 5.90658 18.5382 7.11753 19.1185C8.32848 19.6988 9.65418 20.0001 10.997 20.0001C12.3398 20.0001 13.6655 19.6988 14.8765 19.1185C16.0874 18.5382 17.1528 17.6936 17.994 16.647H18ZM2.926 14.95L4.4 14.207L2.2 12.888C2.35615 13.6026 2.59999 14.2952 2.926 14.95Z"
      fill={fill}
    />
  </svg>
);

export default HealthProfSidebarIcon;
