import { CustomButton } from '@hdcorner/ui-library';
import { ButtonProps, styled } from '@mui/material';

const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

const ButtonCustomDate = styled(CustomButton, {
  shouldForwardProp: prop => prop !== 'web' && prop !== 'textColor',
})<ButtonProps & { web?: boolean; textColor?: string }>(({ web, textColor, theme }) => ({
  backgroundColor: 'none',
  fontWeight: theme.typography.fontWeightBold,
  textTransform: lang === 'el' ? 'capitalize' : 'uppercase',
  color: textColor ? textColor : theme.palette.secondary.main,
  fontSize: web ? theme.typography.body1.fontSize : theme.typography.caption.fontSize,
}));

export default ButtonCustomDate;
