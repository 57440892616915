import React, { FC } from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardActionAreaProps,
  CardContent,
  CardContentProps,
  CardMedia,
  CardMediaProps,
  CardProps,
  Typography,
  useTheme,
} from '@mui/material';
import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

interface CardMoreProps extends CardProps {
  width?: string | number;
  height?: string | number;
}
const CardMain = styled((props: CardMoreProps) => {
  const { width, height, ...other } = props;
  return <Card {...other} raised={false} />;
})(({ width, height }) => ({
  flex: 1,
  width: width,
  height: height,
  display: 'flex',
  boxShadow: 'none',
  overflow: 'hidden',
  position: 'relative',
  borderRadius: '20px',
  flexDirection: 'column',
}));

const CardActionAreaMain = styled(CardActionArea)<CardActionAreaProps>(() => ({
  flex: 1,
  display: 'flex',
  position: 'unset',
  justifyContent: 'space-between',
}));

const CardContentMain = styled(CardContent)<CardContentProps>(({ theme }) => ({
  position: 'relative',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: theme.spacing(0),
  '&.MuiCardContent-root:last-child': {
    paddingBottom: theme.spacing(0),
  },
}));

type Props = {
  author: string;
  title: string;
  imageSrc: string;
  subtitle: string;
  width?: string | number;
  height?: string | number;
  cardMediaProps?: CardMediaProps;
  cardContentProps?: CardContentProps;
  cardActionAreaProps?: CardActionAreaProps;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};
const NavigationCardBackground: FC<Props & CardProps> = ({
  imageSrc,
  title,
  subtitle,
  height = 164,
  width = '100%',
  author,
  onClick,
  cardActionAreaProps,
  cardMediaProps,
  cardContentProps,
  ...cardProps
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <CardMain raised={false} width={width} height={height} {...cardProps}>
      <CardContentMain {...cardContentProps}>
        <CardActionAreaMain onClick={onClick} {...cardActionAreaProps}>
          <Box
            position={'absolute'}
            sx={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              justifyContent: 'space-between',
              top: theme.spacing(2),
              left: theme.spacing(2),
              right: theme.spacing(2),
              bottom: theme.spacing(1),
              zIndex: 30,
            }}
          >
            <Box display={'flex'} flex={1} justifyContent={'space-between'}>
              <Box>
                <Typography
                  variant={'h1'}
                  sx={{
                    color: theme.palette.secondary.main,
                  }}
                >
                  {title}
                </Typography>
                <Typography
                  variant={'subtitle2'}
                  sx={{
                    fontWeight: theme.typography.fontWeightLight,
                    color: theme.palette.secondary.main,
                  }}
                >
                  {subtitle}
                </Typography>
              </Box>
              <KeyboardArrowRightOutlined
                sx={{
                  width: '30px',
                  height: '30px',
                  color: 'white',
                }}
              />
            </Box>
            <Typography
              variant={'body1'}
              sx={{
                alignSelf: 'center',
                color: theme.palette.secondary.main,
              }}
            >
              {author ? `${t('general.labels.author')}: ${author}` : ''}
            </Typography>
          </Box>
          <CardMedia
            src={imageSrc}
            sx={{
              filter: 'brightness(70%)',
              height: height,
              width: '100%',
              objectFit: 'cover',
            }}
            component={'img'}
          />
        </CardActionAreaMain>
      </CardContentMain>
    </CardMain>
  );
};

export default NavigationCardBackground;
