import React, { FC } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

type ChatMediaPanelWebProps = {
  open: boolean;
  children: React.ReactNode;
};
const ChatMediaPanelWeb: FC<ChatMediaPanelWebProps> = ({ open, children }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      height={'100%'}
      minWidth={'300px'}
      maxWidth={'300px'}
      boxSizing={'border-box'}
      padding={theme.spacing(2)}
      display={open ? 'initial' : 'none'}
      sx={{ overflowY: 'auto', overflowX: 'hidden' }}
      borderLeft={`1px solid ${theme.palette.secondary.main}`}
    >
      <Typography
        variant={'subtitle1'}
        sx={{ fontWeight: theme.typography.fontWeightMedium }}
      >
        {t('records.titles.media')}
      </Typography>
      <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'} gap={0.5}>
        {children}
      </Box>
    </Box>
  );
};

export default ChatMediaPanelWeb;
