import * as React from 'react';
import { SVGProps } from 'react';
import { Box, BoxProps, styled } from '@mui/material';

const IconBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: '32px',
  height: '32px',
  borderRadius: '4px',
  padding: '4px',
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.secondary.main,
}));

const RecordsSQIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <IconBox>
      <svg
        width="16"
        height="12"
        viewBox="0 0 16 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M14.6667 0H1.33333C1.15652 0 0.98695 0.0702379 0.861926 0.195262C0.736902 0.320286 0.666664 0.489856 0.666664 0.666667V4C0.666664 4.17681 0.736902 4.34638 0.861926 4.4714C0.98695 4.59643 1.15652 4.66667 1.33333 4.66667H2V11.3333C2 11.5101 2.07024 11.6797 2.19526 11.8047C2.32028 11.9298 2.48985 12 2.66666 12H13.3333C13.5101 12 13.6797 11.9298 13.8047 11.8047C13.9298 11.6797 14 11.5101 14 11.3333V4.66667H14.6667C14.8435 4.66667 15.013 4.59643 15.1381 4.4714C15.2631 4.34638 15.3333 4.17681 15.3333 4V0.666667C15.3333 0.489856 15.2631 0.320286 15.1381 0.195262C15.013 0.0702379 14.8435 0 14.6667 0ZM12.6667 10.6667H3.33333V4.66667H12.6667V10.6667ZM14 3.33333H2V1.33333H14V3.33333ZM4.66666 6.66667C4.66666 6.48986 4.7369 6.32029 4.86193 6.19526C4.98695 6.07024 5.15652 6 5.33333 6H10.6667C10.8435 6 11.013 6.07024 11.1381 6.19526C11.2631 6.32029 11.3333 6.48986 11.3333 6.66667C11.3333 6.84348 11.2631 7.01305 11.1381 7.13807C11.013 7.2631 10.8435 7.33333 10.6667 7.33333H5.33333C5.15652 7.33333 4.98695 7.2631 4.86193 7.13807C4.7369 7.01305 4.66666 6.84348 4.66666 6.66667Z"
          fill="#4A4F55"
        />
      </svg>
    </IconBox>
  );
};

export default RecordsSQIcon;
