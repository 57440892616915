import React, { useEffect, useMemo, useState } from 'react';
import SectionHeading from '../../components/SectionHeading';
import { Box, BoxProps, styled, useMediaQuery } from '@mui/material';
import { GoalDetailsCard, ModalMobile, theme } from '@hdcorner/ui-library';
import GoalModalButtons from '../../../../components/GoalModalButtons';
import {
  useDeleteUserGoalStepsMutation,
  useGetLatestStepsLogsQuery,
  useGetTodayStepsLogsQuery,
  useGetUserGoalStepsQuery,
} from '../queries/stepsQueries';
import moment from 'moment';
import ModalStepsGoal from './ModalStepsGoal';
import ModalRemoveGoal from '../../../../components/ModalRemoveGoal';
import { useIonRouter } from '@ionic/react';
import { calculateLogs } from '../utils/calculateLogs';
import useAlert from '../../../../hooks/useAlert';
import { incrementalProgress } from '../../../../utils/goalProgress';
import { useTranslation } from 'react-i18next';

const BoxInner = styled(Box, { shouldForwardProp: propName => propName !== 'web' })<
  BoxProps & { web: boolean }
>(({ web, theme }) => ({
  display: 'flex',
  borderRadius: '8px',
  flexDirection: 'column',
  boxSizing: 'border-box',
  gap: web ? 0 : theme.spacing(1),
  padding: theme.spacing(web ? 1 : 0),
  backgroundColor: web ? theme.palette.secondary.light : 'transparent',
}));

const SectionGoalSteps = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { presentSuccess, presentError } = useAlert();
  const web = useMediaQuery(theme.breakpoints.up('md'));

  const [open, setOpen] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);

  const [deleteStepsGoal] = useDeleteUserGoalStepsMutation();

  const { data: logsData, error: errorLogs } = useGetTodayStepsLogsQuery();
  const { data: getGoalRes, error: errorGoal } = useGetUserGoalStepsQuery();
  const { data: lastLogData, error: errorLatest } = useGetLatestStepsLogsQuery();

  useEffect(() => {
    if (errorGoal) {
      presentError(t('errors.fitness.exercise.errorStepsFetchGoal'));
    }
    if (errorLogs || errorLatest) {
      presentError(t('errors.fitness.exercise.errorStepsFetchLogs'));
    }
  }, [errorGoal, errorLatest, errorLogs]);

  const lastLog = useMemo(() => {
    if (!lastLogData || lastLogData.documents.length === 0) return '-';
    return lastLogData.documents[0].steps;
  }, [lastLogData]);

  const allLogs = useMemo(() => {
    if (!logsData) return 0;
    return calculateLogs(logsData, 'steps');
  }, [logsData]);

  const goalData = useMemo(() => {
    if (!getGoalRes || getGoalRes.length === 0) return [];
    const progress = incrementalProgress(allLogs, getGoalRes[0].goal.steps);

    return [
      {
        label: t('headingsTitles.goal'),
        value: `${getGoalRes[0].goal.steps} ${t('headingsTitles.steps')}`,
      },
      {
        label: t('goals.start'),
        value: moment(getGoalRes[0].startDate).format('MMMM Do, YYYY'),
      },
      {
        label: t('goals.latestMeasurement'),
        value: `${lastLog} ${t('headingsTitles.steps')}`,
      },
      {
        label: t('goals.progress'),
        value: `${progress}%`,
      },
    ];
  }, [allLogs, getGoalRes, lastLog]);

  const handleRemoveGoal = () => {
    setOpenDelete(true);
  };

  const handleAddEditGoal = () => {
    setOpen(true);
  };

  const handleDeleteGoal = () => {
    if (!getGoalRes || getGoalRes.length === 0) return;
    const goal = getGoalRes[0];
    if (getGoalRes) {
      deleteStepsGoal({ _id: goal._id ? goal._id : '' })
        .unwrap()
        .then(() => {
          setOpenDelete(false);
          router.push('/dashboard/fitness/exercise');
          presentSuccess('Steps goal removed successfully');
        })
        .catch(e => {
          console.log(e);
          setOpenDelete(false);
          presentError('An error occurred while removing your steps goal');
        });
    }
  };

  return (
    <Box gap={1} display={'flex'} width={'100%'} flexDirection={'column'}>
      <Box display={web ? 'block' : 'none'}>
        <SectionHeading
          displayGoalButtons
          handleAdd={handleAddEditGoal}
          handleEdit={handleAddEditGoal}
          handleRemove={handleRemoveGoal}
          heading={t('headingsTitles.goal')}
          hasGoal={!!(getGoalRes && getGoalRes.length > 0)}
        />
      </Box>
      <BoxInner web={web}>
        {goalData.map((it, index) => (
          <Box key={`${it}${index}`}>
            <GoalDetailsCard goal={it} />
          </Box>
        ))}
        <Box
          mt={2}
          flex={1}
          width={'100%'}
          display={web ? 'none' : 'flex'}
          justifyContent={'space-between'}
        >
          <GoalModalButtons
            handleFirst={handleRemoveGoal}
            handleSecond={handleAddEditGoal}
          />
        </Box>
      </BoxInner>
      <ModalStepsGoal open={open} setOpen={setOpen} log={false} />
      <ModalMobile open={openDelete} setOpen={setOpenDelete}>
        <ModalRemoveGoal
          heading={'Steps'}
          handleRemoveClick={handleDeleteGoal}
          handleCancelClick={() => setOpenDelete(false)}
        />
      </ModalMobile>
    </Box>
  );
};

export default SectionGoalSteps;
