import * as React from 'react';
import ToggleButtonGroup, {
  ToggleButtonGroupProps,
} from '@mui/material/ToggleButtonGroup';
import { CSSProperties, FC } from 'react';
import { Box, styled, useTheme } from '@mui/material';
import CalendarIcon from '../../assets/CalendarIcon';
import ToggleButton, { ToggleButtonProps } from '@mui/material/ToggleButton';

const CustomToggleButton = styled(ToggleButton, {
  shouldForwardProp: prop =>
    prop !== 'buttonBorder' &&
    prop !== 'isSelected' &&
    prop !== 'customColor' &&
    prop !== 'size' &&
    prop !== 'lang' &&
    prop !== 'disabled',
})<
  ToggleButtonProps & {
    lang: string;
    disabled: boolean;
    isSelected: boolean;
    buttonBorder: boolean;
    size: 'small' | 'large' | 'medium';
    customColor?: CSSProperties['color'];
  }
>(({ lang, theme, customColor, buttonBorder, isSelected, size, disabled }) => ({
  boxSizing: 'border-box',
  fontWeight: theme.typography.fontWeightBold,
  padding: theme.spacing(0.5, 1),
  textTransform:
    (size === 'small' && lang === 'en') || (size === 'medium' && lang === 'en')
      ? 'uppercase'
      : 'capitalize',
  fontSize:
    size === 'small' || size === 'medium'
      ? theme.typography.caption.fontSize
      : theme.typography.subtitle2.fontSize,
  color: customColor
    ? customColor
    : theme.palette.mode === 'dark'
    ? theme.palette.kmColorsCoolGrey.main
    : theme.palette.primary.main,
  '&.Mui-selected, &.Mui-selected:hover': {
    opacity: disabled ? 0.24 : '1',
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.kmColorsCoolGrey.main
        : theme.palette.primary.main,
    color: customColor
      ? customColor
      : theme.palette.mode === 'dark'
      ? theme.palette.secondary.main
      : theme.palette.secondary.light,
  },
  '&.MuiToggleButton-root': {
    border: 'none',
    '&:last-of-type': {
      border: disabled
        ? '1px solid rgba(34, 34, 34, .24)'
        : buttonBorder && customColor && !isSelected
        ? `1px solid ${customColor}`
        : buttonBorder
        ? `1px solid ${
            theme.palette.mode === 'dark'
              ? theme.palette.kmColorsCoolGrey.main
              : theme.palette.primary.main
          }`
        : 'none',
    },
  },
}));

const CustomToggleButtonGroup = styled(ToggleButtonGroup, {
  shouldForwardProp: prop =>
    prop !== 'buttonBorder' && prop !== 'size' && prop !== 'disabled',
})<
  ToggleButtonGroupProps & {
    disabled: boolean;
    buttonBorder: boolean;
    size: 'small' | 'large' | 'medium';
  }
>(({ theme, ...props }) => ({
  '.MuiToggleButtonGroup-grouped': {
    border: 'none',
    boxSizing: 'border-box',
    margin: theme.spacing(0, 0.25, 0, 0),
    // height: props.size === 'small' || props.size === 'medium' ? '20px' : '40px',
    padding: props.size === 'small' ? theme.spacing(0.5, 1) : theme.spacing(1.25),
    '&:last-of-type': {
      margin: theme.spacing(0),
      borderLeft: props.disabled
        ? '1px solid rgba(34, 34, 34, .24)'
        : props.buttonBorder
        ? `1px solid ${theme.palette.primary.main}`
        : 'none',
    },
  },
  '.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
    borderRadius: '4px',
  },
  '.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
    borderRadius: '4px',
  },
}));

type ToggleButtonsProps = {
  value: string;
  color?: string;
  disabled?: boolean;
  buttonBorder?: boolean;
  size?: 'small' | 'large' | 'medium';
  handleChange: (newValue: string) => void;
  headings: { label: string; value: string }[];
};
const ToggleButtons: FC<ToggleButtonsProps & Omit<ToggleButtonProps, 'color'>> = ({
  size = 'small',
  color,
  value,
  headings,
  disabled = false,
  buttonBorder = false,
  handleChange,
  ...rest
}) => {
  const theme = useTheme();
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const handleOnChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string | null,
  ) => {
    if (newValue === null) {
      handleChange(value);
    } else {
      handleChange(newValue);
    }
  };

  return (
    <CustomToggleButtonGroup
      exclusive
      size={size}
      disabled={disabled}
      onChange={handleOnChange}
      buttonBorder={buttonBorder}
      value={value?.toLowerCase()}
    >
      {headings.map((heading, index) => (
        <CustomToggleButton
          lang={lang}
          size={size}
          key={index}
          disabled={disabled}
          value={heading.value}
          buttonBorder={buttonBorder}
          isSelected={value === heading.value}
          customColor={heading.value === 'custom' ? color : undefined}
          sx={{
            // width:
            //   size === 'medium'
            //     ? '74px'
            //     : size === 'small'
            //     ? heading.toLowerCase() === 'custom'
            //       ? '71px'
            //       : '36px'
            //     : heading === 'custom' || heading.toLowerCase() === 'custom date'
            //     ? '140px'
            //     : '60px',
            padding:
              heading.value === 'custom' && size === 'large'
                ? theme.spacing(1.25, 1.75)
                : theme.spacing(1.25),
            ...rest.sx,
          }}
        >
          {heading.value === 'custom' && (
            <Box
              sx={{ opacity: disabled ? 0.24 : 1 }}
              paddingTop={theme.spacing(0.25)}
              marginRight={theme.spacing(0.75)}
            >
              <CalendarIcon
                width={'13px'}
                height={'13px'}
                color={
                  value === 'custom' && !color
                    ? theme.palette.mode === 'dark'
                      ? theme.palette.secondary.main
                      : theme.palette.secondary.light
                    : color
                    ? color
                    : theme.palette.mode === 'dark'
                    ? theme.palette.kmColorsCoolGrey.main
                    : theme.palette.primary.main
                }
              />
            </Box>
          )}
          {heading.label}
        </CustomToggleButton>
      ))}
    </CustomToggleButtonGroup>
  );
};

export default ToggleButtons;
