import * as React from 'react';
import { FC, SVGProps } from 'react';
import { Box, BoxProps, styled } from '@mui/material';

const IconBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: '32px',
  height: '32px',
  padding: '4px',
  display: 'flex',
  borderRadius: '4px',
  alignItems: 'center',
  boxSizing: 'border-box',
  justifyContent: 'center',
  backgroundColor: theme.palette.secondary.main,
}));

type SvgProps = SVGProps<SVGSVGElement>;

const StarFavouriteIcon: FC<SvgProps> = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0246637 5.32489C-0.0101943 5.44919 -0.00821285 5.58094 0.0303673 5.70413C0.0689474 5.82733 0.142467 5.93668 0.241997 6.01889L3.44533 8.66089L2.43533 13.1876C2.40582 13.3195 2.4171 13.4572 2.46769 13.5826C2.51828 13.708 2.60578 13.815 2.71859 13.8895C2.83139 13.9639 2.96418 14.0024 3.09933 13.9996C3.23448 13.9969 3.36561 13.9532 3.47533 13.8742L7 11.3389L10.5247 13.8722C10.6344 13.9512 10.7655 13.9949 10.9007 13.9976C11.0358 14.0004 11.1686 13.9619 11.2814 13.8875C11.3942 13.813 11.4817 13.706 11.5323 13.5806C11.5829 13.4552 11.5942 13.3175 11.5647 13.1856L10.5547 8.65889L13.758 6.01689C13.8576 5.93477 13.9312 5.8255 13.9698 5.70236C14.0085 5.57922 14.0106 5.4475 13.9759 5.32319C13.9412 5.19888 13.8711 5.08733 13.7742 5.00207C13.6773 4.91681 13.5577 4.86151 13.43 4.84289L9.552 4.27689L7.6 0.368224C7.5384 0.264906 7.45103 0.179354 7.34644 0.119943C7.24184 0.0605314 7.12362 0.0292969 7.00333 0.0292969C6.88304 0.0292969 6.76482 0.0605314 6.66023 0.119943C6.55563 0.179354 6.46826 0.264906 6.40666 0.368224L4.448 4.27889L0.569997 4.84489C0.442416 4.86359 0.322995 4.91889 0.226204 5.00408C0.129412 5.08928 0.0594051 5.20071 0.0246637 5.32489ZM4.98533 5.54822C5.09162 5.53273 5.19258 5.49178 5.27964 5.42887C5.36669 5.36596 5.43726 5.28294 5.48533 5.18689L7 2.15689L8.51466 5.18689C8.56273 5.28294 8.6333 5.36596 8.72036 5.42887C8.80741 5.49178 8.90838 5.53273 9.01466 5.54822L11.748 5.94822L9.39066 7.89289C9.29583 7.97124 9.22452 8.0743 9.18464 8.19067C9.14475 8.30704 9.13784 8.43217 9.16466 8.55223L9.882 11.7696L7.38933 9.97622C7.27591 9.89463 7.13972 9.85073 7 9.85073C6.86027 9.85073 6.72408 9.89463 6.61066 9.97622L4.118 11.7696L4.83533 8.55223C4.86216 8.43217 4.85524 8.30704 4.81536 8.19067C4.77547 8.0743 4.70416 7.97124 4.60933 7.89289L2.25066 5.94689L4.98533 5.54822Z"
        fill={props.color ? props.color : '#4A4F55'}
      />
    </svg>
  );
};

const SubscriptionSQIcon: FC<SvgProps> = ({ ...props }) => {
  return (
    <IconBox>
      <StarFavouriteIcon {...props} />
    </IconBox>
  );
};

export { SubscriptionSQIcon, StarFavouriteIcon };
