import {
  Box,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputBase,
  InputBaseProps,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import React, { FC, useMemo, useRef, useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { PasswordStrengthEnum } from '../../enums/PasswordStrengthEnum';
import PasswordStrength from './PasswordStrength';

const CustomInputBase = styled(InputBase, {
  shouldForwardProp: propName =>
    propName !== 'alternativeBackground' && propName !== 'dark',
})<InputBaseProps & { dark: boolean; alternativeBackground?: boolean }>(
  ({ dark, theme, alternativeBackground }) => ({
    '&.MuiInputBase-root': {
      border: `1px ${theme.palette.secondary.main} solid`,
      // background: alternativeBackground
      //   ? theme.palette.secondary.light
      //   : theme.palette.secondary.main,

      background:
        dark && alternativeBackground
          ? theme.palette.background.default
          : dark
          ? '#282828'
          : !dark && alternativeBackground
          ? theme.palette.secondary.light
          : theme.palette.secondary.main,

      webkitAutoFill: 'none',

      margin: 0,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1, 2),
    },
    '&.MuiInputBase-root.Mui-error': {
      border: alternativeBackground ? 'none' : `1px ${theme.palette.error.main} solid`,
      '.MuiSvgIcon-root': {
        color: theme.palette.error.main,
      },
    },
  }),
);

type Props = {
  label?: string;
  error?: boolean;
  suffix?: string;
  helperText?: string;
  passwordStrength?: boolean;
  alternativeBackground?: boolean;
  handleChange?: (value: string) => void;
};
const CustomInput: FC<Props & InputBaseProps> = ({
  label,
  value,
  type,
  error,
  suffix,
  helperText,
  handleChange,
  endAdornment,
  passwordStrength,
  alternativeBackground,
  ...rest
}) => {
  const theme = useTheme();

  const inputBaseRef = useRef<HTMLInputElement>(null);
  const helperTextRef = useRef<HTMLInputElement>(null);

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!handleChange) return;

    const value = event.target.value;
    handleChange(value);
  };

  const chooseType = useMemo(() => {
    if (type === 'password') {
      return showPassword ? 'text' : 'password';
    }
    return type;
  }, [showPassword, type]);

  const renderEndAdornment = useMemo(() => {
    if (type === 'password') {
      return (
        <InputAdornment position={'end'} onClick={() => setShowPassword(!showPassword)}>
          <IconButton>{showPassword ? <VisibilityOff /> : <Visibility />}</IconButton>
        </InputAdornment>
      );
    }

    if (suffix) {
      return (
        <InputAdornment position={'end'}>
          <Typography variant={'subtitle2'} color={theme.palette.primary.light}>
            {suffix}
          </Typography>
        </InputAdornment>
      );
    }
    return null;
  }, [showPassword, type, suffix]);

  const calculatePasswordStrength = useMemo<PasswordStrengthEnum | undefined>(() => {
    if (!passwordStrength || type !== 'password') return undefined;

    if (!value) {
      return PasswordStrengthEnum.empty;
    }

    const strongRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    const valueAsString = value as string;
    const trimmedValue = valueAsString.trim();

    if (trimmedValue.length === 0) {
      return PasswordStrengthEnum.empty;
    }
    if (trimmedValue.length < 6) {
      if (inputBaseRef.current) {
        inputBaseRef.current.classList.add('Mui-error');
      }
      if (helperTextRef.current) {
        helperTextRef.current.textContent = 'Password must be at least 6 characters';
        helperTextRef.current.classList.add('Mui-error');
      }
      return PasswordStrengthEnum.error;
    } else {
      if (inputBaseRef.current) {
        inputBaseRef.current.classList.remove('Mui-error');
      }
      if (helperTextRef.current) {
        helperTextRef.current.textContent = '';
        helperTextRef.current.classList.remove('Mui-error');
      }
    }

    if (strongRegex.test(trimmedValue)) {
      return PasswordStrengthEnum.strong;
    }

    return PasswordStrengthEnum.weak;
  }, [value]);

  return (
    <Box display={'flex'} flexDirection={'column'} gap={1} flex={1}>
      <Typography
        variant={'subtitle2'}
        fontWeight={'fontWeightBold'}
        sx={{
          color:
            theme.palette.mode === 'dark'
              ? theme.palette.kmColorsCoolGrey.main
              : theme.palette.primary.main,
        }}
      >
        {label}
      </Typography>
      <Box>
        <CustomInputBase
          {...rest}
          value={value}
          error={error}
          type={chooseType}
          ref={inputBaseRef}
          onChange={onChange}
          dark={theme.palette.mode === 'dark'}
          alternativeBackground={alternativeBackground}
          endAdornment={endAdornment ? endAdornment : renderEndAdornment}
        />
        <FormHelperText error={error} ref={helperTextRef}>
          {helperText}
        </FormHelperText>
      </Box>
      {passwordStrength && type === 'password' && (
        <Box>
          <PasswordStrength passwordStrength={calculatePasswordStrength} />
        </Box>
      )}
    </Box>
  );
};

export default CustomInput;
