import React, { FC } from 'react';
import {
  Box,
  BoxProps,
  IconButton,
  styled,
  Typography,
  TypographyProps,
  useMediaQuery,
} from '@mui/material';
import { PlusIconCircular, theme } from '@hdcorner/ui-library';
import { useTranslation } from 'react-i18next';

const BoxMain = styled(Box, {
  shouldForwardProp: propName => propName !== 'web',
})<BoxProps & { web: boolean }>(({ web, theme }) => ({
  flex: 1,
  display: 'flex',
  cursor: 'pointer',
  borderRadius: '8px',
  boxSizing: 'border-box',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: theme.spacing(1),
  minWidth: web ? '212px' : '170px',
  backgroundColor: theme.palette.secondary.light,
}));

const TypographyTitle = styled(Typography, {
  shouldForwardProp: propName => propName !== 'color' && propName !== 'title',
})<TypographyProps & { title: string; color: string }>(({ theme, color }) => ({
  textTransform: 'capitalize',
  fontWeight: theme.typography.fontWeightMedium,
  color: color ? color : theme.palette.primary.main,
}));

const TypographyData = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
}));

type MealCardProps = {
  color: string;
  title: string;
  foods: number;
  calories: string;
  icon: React.ReactNode;
  handleAddClick: () => void;
};

const MealCard: FC<MealCardProps> = ({
  icon,
  color,
  foods,
  title,
  calories,
  handleAddClick,
}) => {
  const { t } = useTranslation();
  const web = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <BoxMain web={web} onClick={handleAddClick}>
      <Box
        flex={'1 0 25%'}
        display={'flex'}
        alignItems={'flex-start'}
        justifyContent={'space-between'}
        marginBottom={theme.spacing(2)}
      >
        <Box gap={1} display={'flex'} alignItems={'center'}>
          {icon}
          <TypographyTitle
            color={color}
            variant={'subtitle2'}
            title={title.toLowerCase()}
          >
            {title}
          </TypographyTitle>
        </Box>
        <IconButton sx={{ padding: 0 }}>
          <PlusIconCircular width={'18px'} height={'18px'} />
        </IconButton>
      </Box>
      <Box>
        <TypographyData>
          {calories ? calories : '0'}
          {t('measurements.kcal')}
        </TypographyData>
        <TypographyData>
          {foods > 1
            ? `${foods} ${t('fitness.nutrition.labels.foods')}`
            : foods === 1
            ? `${foods} ${t('fitness.nutrition.labels.food')}`
            : t('fitness.nutrition.labels.noFood')}
        </TypographyData>
      </Box>
    </BoxMain>
  );
};

export default MealCard;
