import React, { FC } from 'react';
import {
  Box,
  BoxProps,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
  styled,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import { ArrowRight, MedicationStateEnum, RescheduleIcon } from '@hdcorner/ui-library';
import moment from 'moment';

const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

const CustomBox = styled(Box)<BoxProps>(({ theme }) => ({
  height: '35px',
  display: 'flex',
  cursor: 'pointer',
  borderRadius: '8px',
  boxSizing: 'border-box',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.secondary.light,
  padding: theme.spacing(1, 2),
}));

const CustomFormControlLabel = styled(FormControlLabel)<
  FormControlLabelProps & { type: MedicationStateEnum }
>(({ theme, type }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  margin: theme.spacing(0),
  padding: theme.spacing(0),
  textDecorationColor: theme.palette.primary.light,
  textDecoration: type === 'rescheduled-to' ? 'line-through' : 'unset',
  '.MuiFormControlLabel-label': {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.subtitle2.fontSize,
    color:
      type === 'rescheduled-to'
        ? theme.palette.primary.light
        : theme.palette.primary.main,
    opacity: type === 'rescheduled-to' ? '0.5' : '1',
    marginLeft: type === 'rescheduled-from' ? theme.spacing(3) : 'unset',
  },
}));

const CustomCheckbox = styled(Checkbox)<CheckboxProps>(({ theme }) => ({
  borderRadius: '4px',
  margin: theme.spacing(0),
  color: theme.palette.kmColorsCoolGrey.main,
  padding: theme.spacing(0, 1, 0, 0),
  '&.Mui-checked': {
    color: theme.palette.success.main,
  },
  '.MuiSvgIcon-root': {
    fontSize: '20px',
  },
}));

const CustomTypographyAmount = styled(Typography)<
  TypographyProps & { type: MedicationStateEnum }
>(({ theme, type }) => ({
  margin: theme.spacing(0),
  fontSize: theme.typography.body1.fontSize,
  fontWeight: theme.typography.body1.fontWeight,
  padding: theme.spacing(0, 1),
  color:
    type === 'rescheduled-from'
      ? theme.palette.primary.light
      : theme.palette.kmColorsCoolGrey.main,
  opacity: type === 'rescheduled-from' ? '0.5' : 1,
}));

const CustomTypographyTime = styled(Typography)<TypographyProps>(({ theme }) => ({
  margin: theme.spacing(0),
  fontSize: theme.typography.body1.fontSize,
  color: theme.palette.kmColorsCoolGrey.main,
  fontWeight: theme.typography.body1.fontWeight,
  padding: theme.spacing(0, 1),
  textTransform: lang === 'el' ? 'capitalize' : 'uppercase',
}));

type CheckboxNavCardProps = {
  time: string;
  amount: string;
  checkLabel: string;
  medicineName: string;
  medicationState: MedicationStateEnum;
  onClickHandler: () => void;
  onChangeHandler: () => void;
};

const NavCardMedication: FC<CheckboxNavCardProps> = ({
  time,
  amount,
  checkLabel,
  medicineName,
  onClickHandler,
  medicationState,
  onChangeHandler,
}) => {
  const theme = useTheme();

  const formatTime = (time: string) => {
    return moment(time).format('HH:mm');
  };

  const checkBoxChangeHandler = (event: any, checked: any) => {
    if (checked) {
      onChangeHandler();
    }
  };

  return (
    <CustomBox>
      <Box
        display={'flex'}
        alignItems={'center'}
        position={'relative'}
        justifyContent={'flex-start'}
      >
        <CustomFormControlLabel
          label={checkLabel}
          type={medicationState}
          labelPlacement={'end'}
          control={
            <CustomCheckbox
              checked={medicationState === 'taken'}
              disabled={medicationState === 'rescheduled-to'}
              onChange={(event, checked) => checkBoxChangeHandler(event, checked)}
            />
          }
        />
        <CustomTypographyAmount type={medicationState}>
          {medicineName}: {amount}
        </CustomTypographyAmount>
        {medicationState === 'rescheduled-from' && (
          <Box top={0} position={'absolute'} left={theme.spacing(3)}>
            <RescheduleIcon color={theme.palette.error.main} />
          </Box>
        )}
      </Box>
      <Box
        flexGrow={1}
        display={'flex'}
        alignItems={'center'}
        onClick={onClickHandler}
        justifyContent={'flex-end'}
      >
        <CustomTypographyTime>{formatTime(time)}</CustomTypographyTime>
        <ArrowRight />
      </Box>
    </CustomBox>
  );
};

export default NavCardMedication;
