import api from '../../../../redux/api';
import {
  ApiPagination,
  GetWeightLog,
  PaginationResult,
  WeightGoal,
  WeightLog,
} from '@hdcorner/ui-library';

const weightQueries = api.injectEndpoints({
  endpoints: build => ({
    getWeightLogs: build.query<
      PaginationResult<GetWeightLog>,
      { params: ApiPagination; start?: string; end?: string }
    >({
      query: ({ params, start, end }) => ({
        method: 'GET',
        url: '/database/function/getWeightLogs',
        params: { ...params, start, end, sort: '-logDate' },
      }),
      providesTags: ['GetWeightLog'],
      serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, otherArgs) => {
        if (otherArgs.arg.params.skip === 0) {
          currentCache.documents = newItems?.documents;
        } else {
          currentCache?.documents.push(...newItems?.documents);
        }
        currentCache.count = newItems?.count;
      },
      forceRefetch: ({ previousArg, currentArg }) => {
        return (
          previousArg?.end !== currentArg?.end ||
          previousArg?.start !== currentArg?.start ||
          previousArg?.params.sort !== currentArg?.params.sort ||
          previousArg?.params.skip !== currentArg?.params.skip ||
          previousArg?.params.limit !== currentArg?.params.limit
        );
      },
    }),

    getLatestWeightLogs: build.query<PaginationResult<GetWeightLog>, void>({
      query: () => ({
        method: 'GET',
        url: '/database/function/getWeightLogs',
        params: { skip: 0, limit: 1, sort: '-logDate' },
      }),
      providesTags: ['GetWeightLog'],
    }),

    getFirstWeightLog: build.query<PaginationResult<GetWeightLog>, { start?: string }>({
      query: ({ start }) => ({
        method: 'GET',
        url: '/database/function/getWeightLogs',
        params: {
          start,
          skip: 0,
          limit: 1,
          sort: 'logDate',
        },
      }),
      providesTags: ['GetWeightLog'],
    }),

    getLastWeightLog: build.query<PaginationResult<GetWeightLog>, void>({
      query: () => ({
        method: 'GET',
        url: '/database/function/getWeightLogs',
        params: {
          skip: 0,
          limit: 1,
          sort: '-logDate',
        },
      }),
      providesTags: ['GetWeightLog'],
    }),

    createWeightLog: build.mutation<void, WeightLog>({
      query: ({ ...rest }) => ({
        body: rest,
        method: 'POST',
        url: '/database/function/createWeightLog',
      }),
      invalidatesTags: ['GetWeightLog'],
    }),

    getUserGoalWeight: build.query<WeightGoal[], void>({
      query: () => ({
        method: 'GET',
        params: { goalType: 'weight' },
        url: '/database/function/getUserGoal',
      }),
      providesTags: ['WeightGoal'],
    }),

    postUserGoalWeight: build.mutation<void, WeightGoal>({
      query: ({ ...rest }) => ({
        body: rest,
        method: 'POST',
        url: '/hdCornerService/user/goal',
      }),
      invalidatesTags: ['WeightGoal'],
    }),

    patchUserGoalWeight: build.mutation<WeightGoal[], WeightGoal>({
      query: ({ _id, ...rest }) => ({
        body: rest,
        method: 'PATCH',
        url: `/hdCornerService/user/goal/${_id}`,
      }),
      invalidatesTags: ['WeightGoal'],
    }),

    deleteUserGoalWeight: build.mutation<void, { _id: string }>({
      query: ({ _id }) => ({
        method: 'DELETE',
        url: `/database/UserGoal/${_id}`,
      }),
      invalidatesTags: ['WeightGoal'],
    }),

    getWeightGraphData: build.query<GetWeightLog[], { start?: string; end?: string }>({
      query: ({ start, end }) => ({
        method: 'GET',
        params: { start, end, sort: '-logDate' },
        url: '/database/function/getWeightGraphData',
      }),
      providesTags: ['GetWeightLog'],
    }),
  }),
});

export const {
  useGetWeightLogsQuery,
  useGetLastWeightLogQuery,
  useGetFirstWeightLogQuery,
  useGetUserGoalWeightQuery,
  useGetWeightGraphDataQuery,
  useCreateWeightLogMutation,
  useGetLatestWeightLogsQuery,
  usePostUserGoalWeightMutation,
  usePatchUserGoalWeightMutation,
  useDeleteUserGoalWeightMutation,
} = weightQueries;

export default weightQueries;
