import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Box,
  styled,
  Typography,
  TypographyProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  CustomButton,
  ModalMobile,
  WorkoutCard,
  WorkoutCardRadio,
} from '@hdcorner/ui-library';
import {
  exerciseWorkoutsLabel,
  ExerciseWorkoutsType,
} from '../types/ExerciseWorkoutsType';
import VideoModal from '../../../../components/VideoModal';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useGetWorkoutSettingsQuery } from '../queries/workoutSettingsQueries';
import {
  useGetMainWorkoutsQuery,
  useGetRecoveryWorkoutsQuery,
  useGetWarmUpWorkoutsQuery,
} from '../queries/workoutQueries';
import { useIonRouter } from '@ionic/react';
import { VideoType } from '../../../../types/VideoType';
import { findProgramByName, workoutExercises } from '../utils/formatWorkouts';
import {
  updateProgram,
  updateStartTime,
  addCompletedProgram,
} from '../slices/workoutSlice';
import moment from 'moment';
import { Exercise } from '../types/Workout';
import { calculateTotal } from '../utils/calculateTotals';
import CongratulationsScreen from './CongratulationsScreen';
import ModalRemoveGoal from '../../../../components/ModalRemoveGoal';
import useAlert from '../../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.light,
  marginBottom: theme.spacing(1),
  fontWeight: theme.typography.fontWeightMedium,
}));

type Props = {
  onWebClick?: () => void;
};
const ViewChooseProgram: FC<Props> = ({ onWebClick }) => {
  const theme = useTheme();
  const router = useIonRouter();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { presentError } = useAlert();
  const web: boolean = useMediaQuery(theme.breakpoints.up('md'));
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const { targetedBodyPart } = useAppSelector(state => state.workouts);

  const [sex, setSex] = useState<string>('');
  const [videosForModal, setVideosForModal] = useState<VideoType[]>();
  const [openWarning, setOpenWarning] = useState<boolean>(false);
  const [openCongrats, setOpenCongrats] = useState<boolean>(false);
  const [selectedMainProgram, setSelectedMainProgram] = useState({ _id: '', name: '' });

  const { data: avatarRes, error } = useGetWorkoutSettingsQuery();

  const { data: warmUpRes } = useGetWarmUpWorkoutsQuery({
    avatar: sex,
    bodyPart: targetedBodyPart,
  });

  const { data: mainRes } = useGetMainWorkoutsQuery({
    avatar: sex,
    bodyPart: targetedBodyPart,
  });

  const { data: recoveryRes } = useGetRecoveryWorkoutsQuery({
    avatar: sex,
    bodyPart: targetedBodyPart,
  });

  useEffect(() => {
    if (error) {
      presentError(t('errors.fitness.workouts.errorWorkoutSettingsFetch'));
    }
  }, [error]);

  useEffect(() => {
    setSex(avatarRes?.[0]?.avatar || '');
  }, [avatarRes]);

  const handleViewProgram = (program: string) => {
    if (!web) router.push(`/dashboard/fitness/workouts/workout-programs/${program}`);
    if (onWebClick && web) {
      onWebClick();
    }
  };

  const warmUpWorkouts = useMemo(() => {
    if (!warmUpRes) return [];
    return workoutExercises(warmUpRes);
  }, [warmUpRes]);

  // RETURNS : An object of key/value pairs for each of the programs & associated exercises
  const mainWorkouts = useMemo(() => {
    if (!mainRes) return [];

    const allPrograms: { [workoutName: string]: Exercise[] } = {};
    mainRes.forEach(workout => {
      allPrograms[workout.name] = workoutExercises(
        findProgramByName(mainRes, workout.name),
      );
    });
    return allPrograms;
  }, [mainRes]);

  const recoveryWorkouts = useMemo(() => {
    if (!recoveryRes) return [];
    return workoutExercises(recoveryRes);
  }, [recoveryRes]);

  const warmUpID = useMemo(() => {
    if (!warmUpRes || warmUpRes.length === 0) return '';

    return warmUpRes[0]._id;
  }, [warmUpRes]);

  const recoveryID = useMemo(() => {
    if (!recoveryRes || recoveryRes.length === 0) return '';

    return recoveryRes[0]._id;
  }, [recoveryRes]);

  const handleClickStartWorkout = (program: string) => {
    if (!mainWorkouts) return [];

    const mainWorkoutPrograms = mainWorkouts as any;

    const selectedProgram = mainWorkoutPrograms[program];
    let videosToReturn = [...warmUpWorkouts, ...selectedProgram, ...recoveryWorkouts];

    videosToReturn = videosToReturn.map(video => {
      return {
        ...video,
        title: lang === 'en' ? video.title.en : video.title.el,
        thumbnailUrl: lang === 'en' ? video.thumbnail.en.url : video.thumbnail.el.url,
        videoUrl: lang === 'en' ? video.attachedVideo.en.url : video.attachedVideo.el.url,
      };
    });

    dispatch(updateStartTime(moment().utc(true).toDate().toISOString()));
    setVideosForModal(videosToReturn);
  };

  const handleLogActivity = () => {
    dispatch(
      addCompletedProgram({
        name: selectedMainProgram.name,
        workout: selectedMainProgram._id,
        calories: calculateTotal(videosForModal, 'kcalAvg'),
        duration: calculateTotal(videosForModal, 'duration'),
        logDate: moment().utc(true).toDate().toISOString(),
      }),
    );

    setVideosForModal(undefined);

    if (web) {
      setOpenCongrats(true);
    } else {
      router.push('/dashboard/fitness/workouts/congratulations');
    }
  };

  const startWorkout = (
    <CustomButton
      fullWidth
      variant={'contained'}
      sx={{ maxWidth: web ? '240px' : 'none' }}
      disabled={selectedMainProgram.name === ''}
      onClick={() => handleClickStartWorkout(selectedMainProgram.name)}
    >
      {t('buttons.startWorkout')}
    </CustomButton>
  );

  return (
    <Box display={'flex'} flexDirection={'column'} gap={3}>
      <Box flex={1} display={'flex'} justifyContent={'flex-end'}>
        {web && startWorkout}
      </Box>
      <Box>
        <Title variant={'subtitle1'}>{t('goals.start')}</Title>
        <WorkoutCard
          title={t(exerciseWorkoutsLabel(ExerciseWorkoutsType.WARMUP))}
          onClick={() => {
            dispatch(updateProgram({ _id: warmUpID, name: 'Warm Up' }));
            handleViewProgram(ExerciseWorkoutsType.WARMUP);
          }}
        />
      </Box>
      <Box>
        <Title variant={'subtitle1'}>{t('fitness.workouts.text.chooseMain')}</Title>
        {mainRes &&
          mainRes.map(workout => (
            <WorkoutCardRadio
              key={workout._id}
              title={workout.name}
              selected={selectedMainProgram.name === workout.name}
              onClickCard={() =>
                setSelectedMainProgram({ _id: workout._id, name: workout.name })
              }
              onClickViewProgram={() => {
                handleViewProgram(workout.name);
                dispatch(updateProgram({ _id: workout._id, name: workout.name }));
              }}
              sx={{
                marginBottom: theme.spacing(1),
              }}
            />
          ))}
      </Box>
      <Box>
        <Title variant={'subtitle1'}>{t('fitness.workouts.labels.end')}</Title>
        <WorkoutCard
          title={t(exerciseWorkoutsLabel(ExerciseWorkoutsType.RECOVERY))}
          onClick={() => {
            dispatch(updateProgram({ _id: recoveryID, name: 'Recovery' }));
            handleViewProgram(ExerciseWorkoutsType.RECOVERY);
          }}
        />
      </Box>
      {!web && startWorkout}

      {videosForModal && (
        <VideoModal
          open={true}
          singleVideo={false}
          videosData={videosForModal}
          handleLastVideo={handleLogActivity}
          handleClose={() => setOpenWarning(true)}
        />
      )}

      <ModalMobile open={openCongrats} setOpen={setOpenCongrats}>
        <CongratulationsScreen onClick={() => setOpenCongrats(false)} />
      </ModalMobile>

      <ModalMobile open={openWarning} setOpen={setOpenWarning}>
        <ModalRemoveGoal
          text={`${t('fitness.workouts.text.closingVideo')}`}
          handleCancelClick={() => setOpenWarning(false)}
          altHeading={`${t('fitness.workouts.labels.stopWatching')}`}
          handleRemoveClick={() => {
            setOpenWarning(false);
            setVideosForModal(undefined);
          }}
        />
      </ModalMobile>
    </Box>
  );
};

export default ViewChooseProgram;
