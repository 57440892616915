import React, { FC, useState } from 'react';
import { styled } from '@mui/material/styles';
import CustomInput from '../controls/CustomInput';
import CustomButton from '../controls/CustomButton';
import { Box, BoxProps, Typography } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography/Typography';
import { useTranslation } from 'react-i18next';

const Container = styled(Box)<BoxProps>(({ theme }) => ({
  flex: 1,
  display: 'flex',
  borderRadius: 8,
  flexDirection: 'column',
  gap: theme.spacing(1),
  padding: theme.spacing(2),
  background: theme.palette.secondary.light,
}));

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightRegular,
}));

type AddHPCodeModalType = {
  clinicalCoach?: boolean;
  handleEnterCode: (code: string) => void;
};

const AddHPCodeModal: FC<AddHPCodeModalType> = ({ clinicalCoach, handleEnterCode }) => {
  const { t } = useTranslation();

  const [value, setValue] = useState<string>('');

  const enterCode = () => {
    handleEnterCode(value);
  };

  return (
    <Container>
      <Title variant={'subtitle2'}>
        {t('healthProf.hcp.text.addCode', {
          type: clinicalCoach
            ? t('healthProf.hcp.details.cc')
            : t('healthProf.hcp.labels.hp'),
        })}
      </Title>
      <CustomInput
        fullWidth
        value={value}
        handleChange={setValue}
        placeholder={t('buttons.enterCode')}
      />
      <CustomButton fullWidth disabled={!value} onClick={enterCode} variant={'contained'}>
        {t('buttons.enterCode')}
      </CustomButton>
    </Container>
  );
};

export default AddHPCodeModal;
