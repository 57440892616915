import React, { FC } from 'react';
import {
  Card,
  CardActionArea,
  CardActionAreaProps,
  CardContent,
  CardContentProps,
  CardProps,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: '13.33px',
  height: '13.33px',
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
}));

const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  backgroundColor: theme.palette.kmColorsRed.main,
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: '13.33px',
    height: '13.33px',
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.kmColorsRed.main,
  },
}));

const CardMain = styled(Card)<CardProps>(({ theme }) => ({
  flexGrow: 1,
  height: '35px',
  borderRadius: 8,
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  backgroundColor: theme.palette.secondary.light,
}));

const CardActionAreaMain = styled(CardActionArea)<CardActionAreaProps>(() => ({
  height: '100%',
}));

const CardContentMain = styled(CardContent)<CardContentProps>(({ theme }) => ({
  flex: 1,
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0),
  margin: theme.spacing(0, 2),
}));

const CustomTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightMedium,
}));

type RadioCardProps = {
  label: string;
  checked: boolean;
  handleChecked: () => void;
  cardContentProps?: CardContentProps;
  cardActionAreaProps?: CardActionAreaProps;
};
const RadioCard: FC<RadioCardProps & CardProps> = ({
  label,
  checked,
  cardContentProps,
  handleChecked,
  cardActionAreaProps,
  ...cardProps
}) => {
  return (
    <CardMain raised={false} {...cardProps}>
      <CardActionAreaMain onClick={handleChecked} {...cardActionAreaProps}>
        <CardContentMain {...cardContentProps}>
          <CustomTypography variant={'subtitle2'}>{label}</CustomTypography>
          {checked ? <BpCheckedIcon /> : <BpIcon />}
        </CardContentMain>
      </CardActionAreaMain>
    </CardMain>
  );
};

export default RadioCard;
