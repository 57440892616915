const cvdCalculatorOptions = [
  { label: 'constants.units.countries.us', value: 'us' },
  { label: 'constants.units.countries.eu', value: 'eu' },
];

const unitsOptions = [
  { label: 'constants.units.measurement.us', value: 'imperial' },
  { label: 'constants.units.measurement.us', value: 'metric' },
];

const diabeticOptions: any[] = [
  {
    label: 'constants.diabetes.types.no',
    value: 'no',
  },
  {
    label: 'constants.diabetes.types.type1',
    value: 'type_1',
  },
  {
    label: 'constants.diabetes.types.type2',
    value: 'type_2',
  },
  {
    label: 'constants.diabetes.types.gestational',
    value: 'gestational_diabetic',
  },
  {
    label: 'constants.diabetes.types.pre',
    value: 'pre_diabetic',
  },
];

export { cvdCalculatorOptions, unitsOptions, diabeticOptions };
