import React, { FC, useMemo } from 'react';
import { CustomButton } from '@hdcorner/ui-library';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

type ModalRemoveGoalProps = {
  text?: string;
  heading?: string;
  altHeading?: string;
  handleRemoveClick: () => void;
  handleCancelClick: () => void;
};

const ModalRemoveGoal: FC<ModalRemoveGoalProps> = ({
  text,
  heading,
  altHeading,
  handleRemoveClick,
  handleCancelClick,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const renderHeading = useMemo(() => {
    if (altHeading) {
      return altHeading;
    } else if (heading) {
      return t('goals.removeGoal', { goalType: heading });
    } else {
      return t('goals.removeGoal');
    }
  }, [heading, altHeading]);

  return (
    <Box display={'flex'} flexDirection={'column'} gap={theme.spacing(3)} padding={3}>
      <Typography
        variant={'subtitle1'}
        color={theme.palette.primary.main}
        fontWeight={theme.typography.fontWeightMedium}
      >
        {renderHeading}
      </Typography>
      <Typography variant={'subtitle1'} color={theme.palette.primary.main}>
        {text ? text : t('goals.areYouSure')}
      </Typography>
      <Box display={'flex'} gap={theme.spacing(3)} alignItems={'center'}>
        <CustomButton
          fullWidth
          disableElevation
          variant={'contained'}
          onClick={handleCancelClick}
        >
          <Typography
            variant={'subtitle2'}
            color={theme.palette.secondary.main}
            fontWeight={theme.typography.fontWeightRegular}
          >
            {t('buttons.cancel')}
          </Typography>
        </CustomButton>

        <Button
          fullWidth
          disableElevation
          color={'secondary'}
          variant={'outlined'}
          onClick={handleRemoveClick}
          sx={{
            border: 'none',
            padding: theme.spacing(1.5),
            backgroundColor: theme.palette.error.main,
          }}
        >
          <Typography
            variant={'subtitle2'}
            color={theme.palette.secondary.main}
            fontWeight={theme.typography.fontWeightRegular}
          >
            {t('userDetails.yes')}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};
export default ModalRemoveGoal;
