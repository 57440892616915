import * as React from 'react';
import { styled, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import FeelsEmptyIcon from '../assets/FeelsEmptyIcon';
import { useTranslation } from 'react-i18next';

const FeelsEmptyContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: theme.spacing(2),
  borderRadius: theme.spacing(2),
  padding: theme.spacing(6, 8),
  backgroundColor: theme.palette.mode === 'dark' ? 'transparent' : 'white',
}));
const FeelsEmptyTypography = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.kmColorsCoolGrey.main
      : theme.palette.primary.light,
  fontWeight: theme.typography.h1.fontWeight,
}));

export const FeelsEmpty = () => {
  const { t } = useTranslation();

  return (
    <FeelsEmptyContainer>
      <FeelsEmptyIcon />
      <FeelsEmptyTypography variant="subtitle2">
        {t('general.text.feelsEmpty')}
      </FeelsEmptyTypography>
    </FeelsEmptyContainer>
  );
};

export default FeelsEmpty;
