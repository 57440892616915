import * as React from 'react';
import { SVGProps } from 'react';

const FileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={22}
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.708 0.294C13.6155 0.201574 13.5058 0.128188 13.385 0.078C13.2632 0.0264927 13.1323 -3.14402e-05 13 2.79675e-08H1C0.734784 2.79675e-08 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V21C0 21.2652 0.105357 21.5196 0.292893 21.7071C0.48043 21.8946 0.734784 22 1 22H17C17.2652 22 17.5196 21.8946 17.7071 21.7071C17.8946 21.5196 18 21.2652 18 21V5C17.999 4.73451 17.8933 4.48013 17.706 4.292L13.708 0.294ZM16 20H2V2H12V5C12 5.26522 12.1054 5.51957 12.2929 5.70711C12.4804 5.89464 12.7348 6 13 6H16V20Z"
      fill="#222222"
    />
  </svg>
);

export default FileIcon;
