import * as React from 'react';
import { SVGProps } from 'react';
import { Box, BoxProps, styled } from '@mui/material';

const IconBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: '32px',
  height: '32px',
  borderRadius: '4px',
  padding: '4px',
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.secondary.main,
}));

const LogoutSQIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <IconBox>
      <svg
        width="14"
        height="12"
        viewBox="0 0 14 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M0.528664 5.52867L3.19533 2.862C3.25683 2.79833 3.33039 2.74754 3.41173 2.7126C3.49306 2.67766 3.58054 2.65927 3.66906 2.6585C3.75758 2.65773 3.84537 2.6746 3.9273 2.70812C4.00923 2.74164 4.08367 2.79114 4.14626 2.85374C4.20886 2.91633 4.25836 2.99077 4.29188 3.0727C4.3254 3.15463 4.34227 3.24241 4.3415 3.33093C4.34073 3.41945 4.32234 3.50693 4.2874 3.58827C4.25246 3.66961 4.20167 3.74317 4.138 3.80467L2.60933 5.33333H10.3333C10.5101 5.33333 10.6797 5.40357 10.8047 5.5286C10.9298 5.65362 11 5.82319 11 6C11 6.17681 10.9298 6.34638 10.8047 6.4714C10.6797 6.59643 10.5101 6.66667 10.3333 6.66667H2.60933L4.138 8.19533C4.20167 8.25683 4.25246 8.33039 4.2874 8.41173C4.32234 8.49307 4.34073 8.58055 4.3415 8.66907C4.34227 8.75759 4.3254 8.84537 4.29188 8.9273C4.25836 9.00923 4.20886 9.08367 4.14626 9.14626C4.08367 9.20886 4.00923 9.25836 3.9273 9.29188C3.84537 9.3254 3.75758 9.34227 3.66906 9.3415C3.58054 9.34073 3.49306 9.32234 3.41173 9.2874C3.33039 9.25246 3.25683 9.20167 3.19533 9.138L0.528664 6.47133C0.403683 6.34631 0.333473 6.17678 0.333473 6C0.333473 5.82322 0.403683 5.65369 0.528664 5.52867ZM12.3333 0.666667V11.3333C12.3333 11.5101 12.4036 11.6797 12.5286 11.8047C12.6536 11.9298 12.8232 12 13 12C13.1768 12 13.3464 11.9298 13.4714 11.8047C13.5964 11.6797 13.6667 11.5101 13.6667 11.3333V0.666667C13.6667 0.489856 13.5964 0.320286 13.4714 0.195262C13.3464 0.0702379 13.1768 0 13 0C12.8232 0 12.6536 0.0702379 12.5286 0.195262C12.4036 0.320286 12.3333 0.489856 12.3333 0.666667Z"
          fill="#4A4F55"
        />
      </svg>
    </IconBox>
  );
};

export default LogoutSQIcon;
