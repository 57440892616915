import * as React from 'react';
import { SVGProps } from 'react';

const ArrowUp = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="9"
      height="6"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.76161 6.53339C10.0759 6.88695 10.6364 6.93472 11.0135 6.64008C11.3907 6.34545 11.4416 5.81998 11.1273 5.46641L6.6829 0.46643C6.51402 0.276437 6.26384 0.166585 6.00004 0.166585C5.73624 0.166584 5.48606 0.276437 5.31718 0.46643L0.872755 5.46641C0.558477 5.81998 0.60943 6.34545 0.986565 6.64008C1.3637 6.93472 1.9242 6.88695 2.23847 6.53339L6.00004 2.30162L9.76161 6.53339Z"
        fill={props?.fill ?? '"#979FA5"'}
      />
    </svg>
  );
};

export default ArrowUp;
