import moment from 'moment';
import ModalHeading from './ModalHeading';
import CalendarModal from './CalendarModal';
import React, { FC, useState } from 'react';
import ToggleCard from '../cards/ToggleCard';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import CustomButton from '../controls/CustomButton';
import ModalHypertensionFields from './ModalHypertensionFields';
import OcrIcon from '../../assets/OcrIcon';
import { HypertensionLog } from '../../types/HypertensionLog';
import { useTranslation } from 'react-i18next';

const measurementCategories = [
  {
    value: '',
    label: 'Default',
  },
  {
    value: 'morning',
    label: 'constants.hypertension.measurements.morning',
  },
  {
    value: 'afternoon',
    label: 'constants.hypertension.measurements.afternoon',
  },
];

type ModalHypertensionProps = {
  isLoading: boolean;
  triggerOCR: (
    cb: (newMeasurements: {
      systolic: string;
      diastolic: string;
      pulses: string;
    }) => void,
  ) => void;
  handleModalSaveClick: (data: Partial<HypertensionLog>) => void;
};

const ModalHypertension: FC<ModalHypertensionProps> = ({
  isLoading,
  triggerOCR,
  handleModalSaveClick,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [afib, setAfib] = useState<boolean>(false);
  const [pulses, setPulses] = useState<string>('');
  const [date, setDate] = useState<string>(moment().toISOString());
  const [systolic, setSystolic] = useState<string>('');
  const [diastolic, setDiastolic] = useState<string>('');
  const [openPicker, setOpenPicker] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [errorPulses, setErrorPulses] = useState({ value: false, message: '' });
  const [errorSystolic, setErrorSystolic] = useState({ value: false, message: '' });
  const [errorDiastolic, setErrorDiastolic] = useState({ value: false, message: '' });

  const handleSaveData = () => {
    const pulseValue = !(parseInt(pulses) > 220 || parseInt(pulses) < 30);
    const systolicValue = !(parseInt(systolic) > 250 || parseInt(systolic) < 80);
    const diastolicValue = !(parseInt(diastolic) > 150 || parseInt(diastolic) < 50);

    if (!pulseValue) {
      setErrorPulses({
        value: true,
        message: t('general.text.validInput', { value: '30-220' }),
      });
    } else {
      setErrorPulses({ value: false, message: '' });
    }

    if (!systolicValue) {
      setErrorSystolic({
        value: true,
        message: t('general.text.validInput', { value: '80-250' }),
      });
    } else {
      setErrorSystolic({ value: false, message: '' });
    }

    if (!diastolicValue) {
      setErrorDiastolic({
        value: true,
        message: t('general.text.validInput', { value: '50-150' }),
      });
    } else {
      setErrorDiastolic({ value: false, message: '' });
    }

    if (pulseValue && systolicValue && diastolicValue) {
      const data = {
        afib: afib,
        logDate: date,
        pulses: parseInt(pulses),
        systolic: parseInt(systolic),
        diastolic: parseInt(diastolic),
        category: selectedCategory === '' ? undefined : selectedCategory,
      };
      handleModalSaveClick(data);
    }
  };

  const handleCalendarClick = () => {
    setOpenPicker(true);
  };

  const handleClickOCR = () => {
    triggerOCR(newMeasurements => {
      setPulses(newMeasurements.pulses);
      setSystolic(newMeasurements.systolic);
      setDiastolic(newMeasurements.diastolic);
    });
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2} p={3}>
      <Box
        sx={{
          filter: isLoading ? 'blur(2px)' : 'none',
          pointerEvents: isLoading ? 'none' : 'auto',
          WebkitFilter: isLoading ? 'blur(2px)' : 'none',
        }}
      >
        <ModalHeading
          align={'left'}
          showCalendar={'flex'}
          color={theme.palette.hypertension.main}
          handleCalendarClick={handleCalendarClick}
          title={t('dashboard.dashcards.hypertension.title')}
          headingButton={t('constants.toggleValues.dates.customDate')}
        />

        <Box display={'block'}>
          <ModalHypertensionFields
            afib={afib}
            column={false}
            pulses={pulses}
            setAfib={setAfib}
            systolic={systolic}
            diastolic={diastolic}
            setPulses={setPulses}
            errorPulses={errorPulses}
            setSystolic={setSystolic}
            setDiastolic={setDiastolic}
            errorSystolic={errorSystolic}
            errorDiastolic={errorDiastolic}
          />
        </Box>

        {measurementCategories.map(category => (
          <ToggleCard
            value={category.value}
            textTransform={'none'}
            label={t(category.label)}
            selected={category.value === selectedCategory}
            backgroundColor={theme.palette.secondary.main}
            setSelected={() => setSelectedCategory(category.value)}
          />
        ))}
        <Box
          gap={1}
          display={'flex'}
          justifyContent={'center'}
          marginTop={theme.spacing(3)}
        >
          <Box flex={1}>
            <CustomButton
              fullWidth
              color={'primary'}
              variant={'contained'}
              onClick={handleSaveData}
              children={t('buttons.saveData')}
              disabled={!systolic || !diastolic || !pulses}
            />
          </Box>
          <Box>
            <CustomButton variant={'outlined'} onClick={handleClickOCR}>
              <OcrIcon />
            </CustomButton>
          </Box>
        </Box>
        <CalendarModal
          open={openPicker}
          selectedDate={date}
          setOpen={setOpenPicker}
          saveDate={date => setDate(date)}
        />
      </Box>
      {isLoading && (
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{
            gap: 4,
            filter: 'none',
            pointerEvents: 'auto',
            WebkitFilter: 'none',
          }}
        >
          <Typography
            align={'left'}
            variant={'subtitle2'}
            sx={{
              color: theme.palette.text.primary,
            }}
          >
            {t('general.text.preparingData')}
          </Typography>
          <CircularProgress size={20} />
        </Box>
      )}
    </Box>
  );
};

export default ModalHypertension;
