export const calculateBMI = (height: number, weight: number) => {
  if (!height || !weight) return undefined;

  const meters = height / 100;
  return (weight / (meters * meters)).toFixed(2);
};

export const categoryBMI = (value: number): { label: string; value: string } => {
  if (value > 30) {
    return { value: 'over', label: 'fitness.wellness.labels.overweight' };
  } else if (value < 18.5) {
    return { value: 'under', label: 'fitness.wellness.labels.underweight' };
  } else {
    return { value: 'healthy', label: 'fitness.wellness.labels.healthy' };
  }
};
