import React, { FC } from 'react';
import { Box, BoxProps, styled, Typography, TypographyProps } from '@mui/material';

const BoxMain = styled(Box)<BoxProps>(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  minHeight: '35px',
  borderRadius: '8px',
  alignItems: 'center',
  boxSizing: 'border-box',
  justifyContent: 'space-between',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.background.default
      : theme.palette.secondary.light,
  padding: theme.spacing(1, 2),
}));

const TextMain = styled(Typography)<TypographyProps>(({ theme }) => ({
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.kmColorsCoolGrey.main
      : theme.palette.primary.main,
  marginRight: theme.spacing(1),
  fontSize: theme.typography.subtitle2.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
}));

const TextSecondary = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.kmColorsCoolGrey.main
      : theme.palette.kmColorsDarkGrey.main,
  fontWeight: theme.typography.body1.fontWeight,
}));

type SleepDataCardProps = {
  unit: string;
  date: string;
  title: string;
};
const SleepDataCard: FC<SleepDataCardProps> = ({ unit, date, title }) => {
  return (
    <BoxMain>
      <Box display={'flex'}>
        <TextMain>{title}</TextMain>
        <TextSecondary>{unit}</TextSecondary>
      </Box>
      <TextSecondary>{date}</TextSecondary>
    </BoxMain>
  );
};

export default SleepDataCard;
