import * as React from 'react';
import { SVGProps } from 'react';

const ActivityIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 11.5H21V8.5C21 7.70435 20.6839 6.94129 20.1213 6.37868C19.5587 5.81607 18.7956 5.5 18 5.5C17.2044 5.5 16.4413 5.81607 15.8787 6.37868C15.3161 6.94129 15 7.70435 15 8.5V11.5H9V8.5C9 7.70435 8.68393 6.94129 8.12132 6.37868C7.55871 5.81607 6.79565 5.5 6 5.5C5.20435 5.5 4.44129 5.81607 3.87868 6.37868C3.31607 6.94129 3 7.70435 3 8.5V11.5H2C1.73478 11.5 1.48043 11.6054 1.29289 11.7929C1.10536 11.9804 1 12.2348 1 12.5C1 12.7652 1.10536 13.0196 1.29289 13.2071C1.48043 13.3946 1.73478 13.5 2 13.5H3V16.5C3 17.2956 3.31607 18.0587 3.87868 18.6213C4.44129 19.1839 5.20435 19.5 6 19.5C6.79565 19.5 7.55871 19.1839 8.12132 18.6213C8.68393 18.0587 9 17.2956 9 16.5V13.5H15V16.5C15 17.2956 15.3161 18.0587 15.8787 18.6213C16.4413 19.1839 17.2044 19.5 18 19.5C18.7956 19.5 19.5587 19.1839 20.1213 18.6213C20.6839 18.0587 21 17.2956 21 16.5V13.5H22C22.2652 13.5 22.5196 13.3946 22.7071 13.2071C22.8946 13.0196 23 12.7652 23 12.5C23 12.2348 22.8946 11.9804 22.7071 11.7929C22.5196 11.6054 22.2652 11.5 22 11.5ZM7 16.5C7 16.7652 6.89464 17.0196 6.70711 17.2071C6.51957 17.3946 6.26522 17.5 6 17.5C5.73478 17.5 5.48043 17.3946 5.29289 17.2071C5.10536 17.0196 5 16.7652 5 16.5V8.5C5 8.23478 5.10536 7.98043 5.29289 7.79289C5.48043 7.60536 5.73478 7.5 6 7.5C6.26522 7.5 6.51957 7.60536 6.70711 7.79289C6.89464 7.98043 7 8.23478 7 8.5V16.5ZM19 16.5C19 16.7652 18.8946 17.0196 18.7071 17.2071C18.5196 17.3946 18.2652 17.5 18 17.5C17.7348 17.5 17.4804 17.3946 17.2929 17.2071C17.1054 17.0196 17 16.7652 17 16.5V8.5C17 8.23478 17.1054 7.98043 17.2929 7.79289C17.4804 7.60536 17.7348 7.5 18 7.5C18.2652 7.5 18.5196 7.60536 18.7071 7.79289C18.8946 7.98043 19 8.23478 19 8.5V16.5Z"
        fill={props?.color ?? '#F2C72E'}
      />
    </svg>
  );
};

export default ActivityIcon;
