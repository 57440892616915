import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Activity } from '@hdcorner/ui-library';

const initialState: {
  addActivity: Activity;
} = {
  addActivity: {
    _id: '',
    name: {
      en: '',
      el: '',
    },
    met: 0,
  },
};

const activitySlice = createSlice({
  name: 'createActivity',
  initialState,
  reducers: {
    addActivityDetails: (state, action: PayloadAction<Activity>) => {
      state.addActivity = action.payload;
    },
    deleteActivityDetails: state => {
      state.addActivity = {
        _id: '',
        name: {
          en: '',
          el: '',
        },
        met: 0,
      };
    },
  },
});

export const { addActivityDetails, deleteActivityDetails } = activitySlice.actions;

export default activitySlice.reducer;
