import React, { FC } from 'react';
import { Box, styled, Typography, TypographyProps, useTheme } from '@mui/material';
import CustomInput from '../controls/CustomInput';
import CustomButton from '../controls/CustomButton';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const TypographyHeading = styled(Typography)<TypographyProps>(({ theme }) => ({
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.kmColorsBgText.main
      : theme.palette.primary.main,
  textAlign: 'center',
}));

const TypographyText = styled(Typography)<TypographyProps>(({ theme }) => ({
  textAlign: 'center',
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.kmColorsBgText.main
      : theme.palette.primary.light,
  fontWeight: theme.typography.fontWeightRegular,
}));

const TypographyCopyright = styled(Typography)<TypographyProps>(({ theme }) => ({
  textAlign: 'center',
  marginTop: theme.spacing(3),
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.kmColorsBgText.main
      : theme.palette.primary.light,
  fontWeight: theme.typography.fontWeightRegular,
}));

export type ForgotPasswordPanelGlobalProps = {
  web: boolean;
  email: string;
  emailSentBtn: boolean;
  handleGoBack: () => void;
  handleSendEmail: () => void;
  logoContainer: React.ReactNode;
  handleCancelButton: () => void;
  setEmail: (email: string) => void;
};
const ForgotPasswordPanelGlobal: FC<ForgotPasswordPanelGlobalProps> = ({
  web,
  email,
  setEmail,
  emailSentBtn,
  handleGoBack,
  logoContainer,
  handleSendEmail,
  handleCancelButton,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      height={'100%'}
      display={'flex'}
      margin={'0 auto'}
      maxWidth={'350px'}
      overflow={'scroll'}
      alignItems={'center'}
      flexDirection={'column'}
      width={web ? '350px' : 'auto'}
      justifyContent={'space-between'}
      sx={{
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <Box width={'100%'}>
        <Box textAlign={'center'} mb={theme.spacing(web ? 16 : 6)}>
          {logoContainer}
        </Box>
        <Box
          margin={
            emailSentBtn
              ? theme.spacing(0, 'auto', 21, 'auto')
              : theme.spacing(0, 0, 3, 0)
          }
        >
          <TypographyHeading variant={'h1'}>
            {emailSentBtn
              ? t('authentication.forgotReset.labels.emailSent')
              : t('authentication.forgotReset.labels.forgotPass')}
          </TypographyHeading>
        </Box>

        {!emailSentBtn && (
          <TypographyText
            align={'center'}
            variant={'body1'}
            sx={{ maxWidth: '264px', margin: theme.spacing(0, 'auto', 3, 'auto') }}
          >
            {t('authentication.forgotReset.text.enterEmail')}
          </TypographyText>
        )}

        <Box display={emailSentBtn ? 'none' : 'block'}>
          <CustomInput
            fullWidth
            value={email}
            handleChange={setEmail}
            label={t('personalDetails.email')}
            sx={{ margin: theme.spacing(6.5, 0, 3, 0) }}
          />
        </Box>

        <CustomButton
          fullWidth
          disabled={!email}
          variant={'contained'}
          sx={{ marginTop: theme.spacing(3) }}
          onClick={emailSentBtn ? handleGoBack : handleSendEmail}
        >
          {emailSentBtn
            ? t('buttons.goBack')
            : t('authentication.forgotReset.text.sendLink')}
        </CustomButton>
      </Box>
      <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
        <CustomButton
          onClick={handleCancelButton}
          variant={web ? 'contained' : 'text'}
          sx={{
            height: web ? '32px' : 'none',
            display: emailSentBtn ? 'none' : 'block',
            fontSize: theme.typography.body1.fontSize,
            padding: theme.spacing(web ? 1 : 2),
          }}
        >
          {t('authentication.forgotReset.labels.nvm')}
        </CustomButton>

        {web && (
          <TypographyCopyright variant={'body1'}>
            {t('karabinis.footer', { year: moment().year() })}
          </TypographyCopyright>
        )}
      </Box>
    </Box>
  );
};

export default ForgotPasswordPanelGlobal;
