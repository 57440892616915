import { IonApp, isPlatform, setupIonicReact } from '@ionic/react';
import { ThemeProvider } from '@mui/material';
import { theme } from '@hdcorner/ui-library';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import AppStack from './AppStack';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';

/* Theme variables */
import './theme/variables.css';
import { useLoadScript } from '@react-google-maps/api';

import { FacebookLogin } from '@capacitor-community/facebook-login';
import { ScreenOrientation } from '@capacitor/screen-orientation';

setupIonicReact({
  mode: 'ios',
  scrollPadding: false,
  scrollAssist: false,
});

const App: React.FC = () => {
  const capacitor = isPlatform('capacitor');

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: capacitor
      ? (process.env.REACT_APP_MAPS_MOBILE_API_KEY as string)
      : (process.env.REACT_APP_MAPS_WEB_API_KEY as string),
  });

  useEffect(() => {
    if (capacitor) {
      ScreenOrientation.lock({ orientation: 'portrait' });
      FacebookLogin.initialize({ appId: process.env.REACT_APP_FB_APP_ID || '' });
    }
  }, []);

  useEffect(() => {
    if (isLoaded) {
      console.log('Google Maps API loaded');
    } else {
      console.log('Google Maps API not loaded');
    }

    if (loadError) {
      console.log('Google Maps API loading error');
    }
  }, [isLoaded, loadError]);

  return (
    <IonApp>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <IonReactRouter>
            <AppStack />
          </IonReactRouter>
        </ThemeProvider>
      </Provider>
    </IonApp>
  );
};

export default App;
