import React, { FC, useEffect, useState } from 'react';
import {
  AuthUser,
  CustomButton,
  CustomDateInput,
  DropdownMenu,
} from '@hdcorner/ui-library';
import Box from '@mui/material/Box';
import DatePicker from '../../../../components/DatePicker';
import { useTranslation } from 'react-i18next';
import { useGetAuthUserQuery } from '../../queries/signInQueries';

type UserDetailsTwoProps = {
  loading: boolean;
  handleNextPage: (data: Pick<AuthUser, 'gender' | 'birthDate'>) => void;
};
const UserDetailsTwo: FC<UserDetailsTwoProps> = ({ loading, handleNextPage }) => {
  const { t } = useTranslation();

  const { data: authUserResponse } = useGetAuthUserQuery();

  const [open, setOpen] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<
    Required<Pick<AuthUser, 'gender' | 'birthDate'>>
  >({
    gender: '',
    birthDate: '',
  });

  useEffect(() => {
    if (authUserResponse && authUserResponse.length > 0) {
      const { birthDate, gender } = authUserResponse[0];
      setUserDetails({
        gender: gender || '',
        birthDate: birthDate || '',
      });
    }
  }, [authUserResponse]);

  const handleChange = (fieldName: string, value: any) => {
    setUserDetails({
      ...userDetails,
      [fieldName]: value,
    });
  };

  const handleSaveData = () => {
    handleNextPage(userDetails);
  };

  const onClickIconButton = () => {
    setOpen(true);
  };

  const handleSaveDate = () => {
    setOpen(false);
    const data = userDetails.birthDate;
    setUserDetails({ ...userDetails, birthDate: data });
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={6}>
      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <CustomDateInput
          selectedDate={userDetails.birthDate}
          handleCalendarOpen={onClickIconButton}
        />
        <DropdownMenu
          value={userDetails.gender}
          label={`${t('userDetails.sex')}`}
          placeholder={`${t('userDetails.chooseSex')}`}
          menuItems={[
            { label: t('userDetails.male'), value: 'male' },
            { label: t('userDetails.female'), value: 'female' },
          ]}
          setValue={value => handleChange('gender', value)}
        />
      </Box>
      <CustomButton
        fullWidth
        variant={'contained'}
        onClick={handleSaveData}
        disabled={!userDetails.gender || !userDetails.birthDate || loading}
      >
        {t('buttons.continue')}
      </CustomButton>
      <DatePicker
        open={open}
        setOpen={setOpen}
        handleSaveDate={handleSaveDate}
        selectedDate={userDetails.birthDate}
        setSelectedDate={value => handleChange('birthDate', value)}
      />
    </Box>
  );
};

export default UserDetailsTwo;
