import { Box, Typography, useTheme } from '@mui/material';
import CustomButton from '../controls/CustomButton';
import ModalMobile from './ModalMobile';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

type ModalDeactivateAccountProps = {
  open: boolean;
  handleCancel: () => void;
  handleDeactivate: () => void;
  setOpen: (open: boolean) => void;
};
const ModalDeactivateAccount: FC<ModalDeactivateAccountProps> = ({
  open,
  setOpen,
  handleCancel,
  handleDeactivate,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <ModalMobile open={open} setOpen={setOpen}>
      <Box padding={theme.spacing(3)} boxSizing={'border-box'}>
        <Typography
          align={'center'}
          variant={'subtitle2'}
          dangerouslySetInnerHTML={{ __html: t('profile.text.deactivateCheck') }}
        />

        <Box
          display={'flex'}
          justifyContent={'space-between'}
          marginTop={theme.spacing(3)}
        >
          <CustomButton
            fullWidth
            color={'primary'}
            variant={'contained'}
            onClick={handleCancel}
            children={t('buttons.cancel')}
            sx={{
              height: '48px',
              marginRight: theme.spacing(3),
            }}
          />
          <CustomButton
            fullWidth
            variant={'contained'}
            onClick={handleDeactivate}
            children={t('buttons.deactivate')}
            style={{
              height: '48px',
              textTransform: 'capitalize',
              backgroundColor: theme.palette.error.main,
            }}
          />
        </Box>
      </Box>
    </ModalMobile>
  );
};

export default ModalDeactivateAccount;
