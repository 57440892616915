import { Box, useTheme } from '@mui/material';
import CustomInput from '../controls/CustomInput';
import CustomButton from '../controls/CustomButton';
import React, { FC, useState } from 'react';
import ModalHeading from './ModalHeading';
import CalendarModal from './CalendarModal';
import moment from 'moment';
import { LipidLog } from '../../types/LipidLog';
import { useTranslation } from 'react-i18next';

type ModalCholesterolDataProps = {
  web?: boolean;
  handleModalSaveClick: (data: Omit<LipidLog, '_id'>) => void;
};
const ModalCholesterolData: FC<ModalCholesterolDataProps> = ({
  web,
  handleModalSaveClick,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [hdl, setHdl] = useState<number>();
  const [ldl, setLdl] = useState<number>();
  const [lpa, setLpa] = useState<number>();
  const [totalChol, setTotalChol] = useState<number>();
  const [triglycerides, setTriglycerides] = useState<number>();
  const [date, setDate] = useState<string>(moment().toISOString());
  const [openPicker, setOpenPicker] = useState<boolean>(false);

  const handleSaveData = () => {
    const data = {
      hdl: hdl,
      ldl: ldl,
      lpa: lpa,
      logDate: date,
      totalChol: totalChol,
      triglycerides: triglycerides,
    };

    handleModalSaveClick(data);
  };

  return (
    <Box p={3}>
      <ModalHeading
        align={'left'}
        showCalendar={'flex'}
        color={theme.palette.lipid.main}
        title={t('lipid.labels.cholData')}
        handleCalendarClick={() => setOpenPicker(true)}
        headingButton={t('constants.toggleValues.dates.customDate')}
      />
      <Box display={'flex'} flexDirection={'column'} marginTop={theme.spacing(6)}>
        <Box display={'flex'} marginBottom={theme.spacing(5)}>
          <CustomInput
            value={lpa}
            type={'number'}
            fullWidth={true}
            suffix={t('measurements.mgdl')}
            label={t('dashboard.dashcards.lipid.lpa')}
            placeholder={`14 ${t('measurements.mgdl')}`}
            handleChange={(val: string) => setLpa(val !== undefined ? parseInt(val) : 0)}
          />
        </Box>
        <Box
          display={'flex'}
          gap={theme.spacing(3)}
          justifyContent={'space-between'}
          marginBottom={theme.spacing(5)}
        >
          <CustomInput
            type={'number'}
            fullWidth={web}
            value={totalChol}
            suffix={t('measurements.mgdl')}
            label={t('lipid.labels.totChol')}
            placeholder={`200 ${t('measurements.mgdl')}`}
            handleChange={(val: string) =>
              setTotalChol(val !== undefined ? parseInt(val) : 0)
            }
          />
          <CustomInput
            value={hdl}
            type={'number'}
            fullWidth={web}
            suffix={t('measurements.mgdl')}
            label={t('dashboard.dashcards.lipid.hdl')}
            placeholder={`50 ${t('measurements.mgdl')}`}
            handleChange={(val: string) => setHdl(val !== undefined ? parseInt(val) : 0)}
          />
        </Box>
        <Box
          display={'flex'}
          gap={theme.spacing(3)}
          justifyContent={'space-between'}
          marginBottom={theme.spacing(5)}
        >
          <CustomInput
            value={ldl}
            type={'number'}
            fullWidth={web}
            suffix={t('measurements.mgdl')}
            label={t('dashboard.dashcards.lipid.ldl')}
            placeholder={`110 ${t('measurements.mgdl')}`}
            handleChange={(val: string) => setLdl(val !== undefined ? parseInt(val) : 0)}
          />
          <CustomInput
            type={'number'}
            fullWidth={web}
            value={triglycerides}
            label={t('lipid.labels.tryg')}
            suffix={t('measurements.mgdl')}
            placeholder={`150 ${t('measurements.mgdl')}`}
            handleChange={(val: string) =>
              setTriglycerides(val !== undefined ? parseInt(val) : 0)
            }
          />
        </Box>
      </Box>
      <Box display={'flex'} justifyContent={'center'}>
        <CustomButton
          fullWidth
          color={'primary'}
          variant={'contained'}
          onClick={handleSaveData}
          children={t('buttons.saveData')}
          disabled={!(totalChol || triglycerides || hdl || ldl)}
        />
      </Box>
      <CalendarModal
        open={openPicker}
        selectedDate={date}
        setOpen={setOpenPicker}
        saveDate={date => setDate(date)}
      />
    </Box>
  );
};

export default ModalCholesterolData;
