import { Camera, CameraSource } from '@capacitor/camera';

const cameraPermissions = async (): Promise<CameraSource | undefined> => {
  const { camera, photos } = await Camera.checkPermissions();
  if (camera !== 'denied' && photos !== 'denied') {
    return CameraSource.Prompt;
  }

  if (camera === 'denied' && photos === 'denied') {
    const { camera, photos } = await Camera.requestPermissions();
    if (camera !== 'denied' && photos !== 'denied') {
      return CameraSource.Prompt;
    }
    if (camera === 'denied' && photos === 'denied') {
      return undefined;
    }

    if (photos === 'denied') {
      return CameraSource.Camera;
    }
    if (camera === 'denied') {
      return CameraSource.Photos;
    }
  } else {
    if (photos === 'denied') {
      return CameraSource.Camera;
    }
    if (camera === 'denied') {
      return CameraSource.Photos;
    }
  }

  return undefined;
};

export { cameraPermissions };
