import React from 'react';
import { Box } from '@mui/material';
import SignInDivider from '../../../../assets/illustrations/SignInDividerPNG.png';
import { SocialButtons, theme } from '@hdcorner/ui-library';
import {
  useLazySignInWithAppleInitQuery,
  useLazySignInWithFacebookInitQuery,
  useLazySignInWithGoogleInitQuery,
  useSignInWithFacebookMutation,
} from '../../queries/signInQueries';
import useAlert from '../../../../hooks/useAlert';
import { isPlatform, useIonRouter } from '@ionic/react';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { InAppBrowser } from '@capgo/inappbrowser';

const SignInSocial = () => {
  const router = useIonRouter();
  const { presentError } = useAlert();

  const isIOS = isPlatform('ios');
  const isMobile = isPlatform('mobile');
  const isCapacitor = isPlatform('capacitor');

  const [facebookSignIn] = useSignInWithFacebookMutation();

  const [appleSignInInit] = useLazySignInWithAppleInitQuery();
  const [googleSignInInit] = useLazySignInWithGoogleInitQuery();
  const [facebookSignInInit] = useLazySignInWithFacebookInitQuery();

  const handleRedirect = (state: any) => {
    if (!state.url.includes('accessToken')) {
      throw new Error('Access Token not found in the URL');
    }

    const url = state.url;
    let accessToken: string, refreshToken: string;
    const accessTokenRegex = /accessToken=([^&]+)/;
    const refreshTokenRegex = /&refreshToken=([^&]+)$/;
    const accessTokenMatch = url.match(accessTokenRegex);
    const refreshTokenMatch = url.match(refreshTokenRegex);
    if (accessTokenMatch) {
      accessToken = accessTokenMatch[1];
    } else {
      throw new Error('Access Token not found in the URL');
    }
    if (refreshTokenMatch) {
      refreshToken = refreshTokenMatch[1];
    } else {
      throw new Error('Refresh Token not found in the URL');
    }
    InAppBrowser.close().then(() => {
      router.push(
        `/sign-in?accessToken=${accessToken}&refreshToken=${refreshToken}`,
        'root',
        'replace',
      );
    });
  };

  const handleOpenInAppBrowser = (res: any) => {
    if (isCapacitor && isMobile) {
      InAppBrowser.open({ url: res.result })
        .then(() => {
          InAppBrowser.addListener('urlChangeEvent', state => {
            handleRedirect(state);
          });
        })
        .catch(e => console.error('Error opening InAppBrowser', e));
    } else {
      window.location.href = res.result;
    }
  };

  const handleSocialLogin = (provider: 'facebook' | 'google' | 'apple') => {
    switch (provider) {
      case 'facebook':
        signInWithFacebook();
        break;
      case 'google':
        signInWithGoogle();
        break;
      case 'apple':
        signInWithApple();
        break;
      default:
        break;
    }
  };

  const signInWithApple = () => {
    appleSignInInit()
      .unwrap()
      .then(res => {
        handleOpenInAppBrowser(res);
      })
      .catch(e => {
        console.log(e);
        presentError('Error while signing in with Apple');
      });
  };

  const signInWithGoogle = () => {
    googleSignInInit()
      .unwrap()
      .then(res => {
        handleOpenInAppBrowser(res);
      })
      .catch(e => {
        console.log(e);
        presentError('Error while signing in with Google');
      });
  };

  const signInWithFacebook = async () => {
    if (isCapacitor && isMobile) {
      try {
        const result = await FacebookLogin.login({
          permissions: ['email', 'public_profile'],
        });
        if (result.accessToken) {
          const res = await facebookSignIn({
            accessToken: result.accessToken.token,
          }).unwrap();
          router.push(
            `/sign-in?accessToken=${res.accessToken}&refreshToken=${res.refreshToken}`,
          );
        }
      } catch (e) {
        console.log(e);
        presentError('Error while signing in with Facebook');
      }
    } else {
      facebookSignInInit()
        .unwrap()
        .then(res => {
          window.location.href = res.result;
        })
        .catch(e => {
          console.log(e);
          presentError('Error while signing in with Facebook');
        });
    }
  };

  return (
    <Box>
      <Box maxWidth={'204px'} margin={theme.spacing(0, 'auto', 3, 'auto')}>
        <SocialButtons
          isIphone={isMobile && isCapacitor && isIOS}
          loginWithProvider={provider => handleSocialLogin(provider)}
        />
      </Box>
      <img src={SignInDivider} alt={'divider icon'} width={'auto'} />
    </Box>
  );
};

export default SignInSocial;
