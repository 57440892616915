import GiftIcon from '../../../assets/icons/GiftIcon';
import { SQIconContainer } from '@hdcorner/ui-library';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PlanGift = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const web = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      gap={1}
      display={'flex'}
      alignItems={'center'}
      boxSizing={'border-box'}
      height={web ? '68px' : 'none'}
      justifyContent={'space-between'}
      borderRadius={web ? '8px' : 'none'}
      bgcolor={web ? theme.palette.secondary.light : 'transparent'}
      padding={web ? theme.spacing(1.25, 3) : 0}
    >
      <Box gap={1} display={'flex'} alignItems={'center'}>
        <SQIconContainer color={theme.palette.kmColorsRed.main}>
          <GiftIcon />
        </SQIconContainer>
        <Typography
          variant={web ? 'h1' : 'subtitle1'}
          sx={{
            whiteSpace: 'nowrap',
            fontWeight: web
              ? theme.typography.fontWeightMedium
              : theme.typography.fontWeightRegular,
          }}
        >
          {t('subscription.text.gift')}
        </Typography>
      </Box>
      <Typography
        textAlign={'right'}
        variant={web ? 'subtitle1' : 'body1'}
        sx={{
          fontWeight: web
            ? theme.typography.fontWeightMedium
            : theme.typography.fontWeightRegular,
        }}
      >
        {t('subscription.text.promo')}
      </Typography>
    </Box>
  );
};

export default PlanGift;
