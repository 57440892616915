import { useIonRouter } from '@ionic/react';
import useAlert from '../../hooks/useAlert';
import PageLayout from '../../components/PageLayout';
import { SettingsPhonePanelWeb } from '@hdcorner/ui-library';
import { useUpdatePhoneNumberMutation } from './queries/accountSettingsQueries';
import { useTranslation } from 'react-i18next';

const AccountSettingsPhone = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { presentSuccess, presentError } = useAlert();

  const [updatePhoneNumber] = useUpdatePhoneNumberMutation();

  const handleNext = (newPhone: string) => {
    updatePhoneNumber({
      phoneNumber: newPhone,
    })
      .then(() => {
        presentSuccess(t('errors.profile.successUpdatePhone'));
        router.push('/dashboard/profile-settings', 'back', 'pop');
      })
      .catch(() => presentError(t('errors.profile.errorUpdatePhone')));
  };

  return (
    <PageLayout headerTitle={t('profile.titles.phone')} backgroundWhite>
      <SettingsPhonePanelWeb handleNext={handleNext} />
    </PageLayout>
  );
};

export default AccountSettingsPhone;
