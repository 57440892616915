import moment from 'moment';
import { useMemo } from 'react';
import { isNil } from 'lodash';
import { CvdRiskGoal, CvdRiskLog, PaginationResult } from '@hdcorner/ui-library';
import { useTranslation } from 'react-i18next';
import { goalProgress } from '../../../utils/goalProgress';

const usePrepareGoalData = (
  goal?: CvdRiskGoal[],
  currentLog?: PaginationResult<CvdRiskLog>,
  cvdStartingLog?: PaginationResult<CvdRiskLog>,
) => {
  const { t } = useTranslation();

  return useMemo(() => {
    const goalValue = goal?.[0];

    const currentValue = currentLog?.logs?.[0];
    let firstValue = cvdStartingLog?.logs?.[0];

    let progress = 0;
    if (
      !isNil(currentValue?.score) &&
      !isNil(goalValue?.goal) &&
      !isNil(firstValue?.score)
    ) {
      const goalScore = goalValue?.goal || 0;
      const firstScore = firstValue?.score || 0;
      const currentScore = currentValue?.score || 0;
      progress = goalProgress(currentScore, goalScore, firstScore);
    }

    if (goalValue && !firstValue) {
      firstValue = currentValue;
    }

    return [
      {
        label: t('headingsTitles.goal'),
        value: goalValue
          ? `${goalValue.goal} % ${t('dashboard.dashcards.cvd.overall')}`
          : '-',
      },
      {
        marginBottom: true,
        label: t('goals.startingDate'),
        value: goalValue?.startDate
          ? moment(goalValue.startDate).format('MMM Do, YYYY')
          : '-',
      },
      {
        label: t('goals.startingMeasurement'),
        value: firstValue && !isNil(firstValue.score) ? `${firstValue.score} %` : '-',
      },
      {
        label: t('goals.latestMeasurement'),
        value:
          currentValue && !isNil(currentValue.score) ? `${currentValue.score} %` : '-',
      },
      {
        label: t('goals.progress'),
        value: !isNil(progress) ? `${progress} %` : '-',
      },
    ];
  }, [currentLog?.logs, cvdStartingLog?.logs, goal, t]);
};

export default usePrepareGoalData;
