import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import LockPremium from '../assets/LockPremium';
import { Box, Typography, useTheme } from '@mui/material';

type Props = {
  module: string;
  premium: boolean;
};

const BlurredOverlayLocked: FC<Props> = ({ module, premium = true }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      gap={1}
      zIndex={3}
      top={'50%'}
      left={'50%'}
      width={'153px'}
      textAlign={'center'}
      position={'absolute'}
      alignItems={'center'}
      flexDirection={'column'}
      display={premium ? 'none' : 'flex'}
      sx={{ transform: 'translate(-50%, -50%)' }}
    >
      <LockPremium />
      <Typography
        textAlign={'center'}
        variant={'subtitle2'}
        sx={{ fontWeight: theme.typography.fontWeightBold }}
      >
        {t('subscription.text.premiumFeature', { module: module })}
      </Typography>
    </Box>
  );
};

export default BlurredOverlayLocked;
