import React, { SVGProps } from 'react';
import { useTheme } from '@mui/material';

const HistoryHourGlassIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  return (
    <svg
      {...props}
      width="13"
      height="15"
      fill="none"
      viewBox="0 0 13 15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8333 1.5013C12.0101 1.5013 12.1797 1.43106 12.3047 1.30604C12.4298 1.18102 12.5 1.01145 12.5 0.834635C12.5 0.657824 12.4298 0.488255 12.3047 0.363231C12.1797 0.238207 12.0101 0.167969 11.8333 0.167969H1.16667C0.989856 0.167969 0.820286 0.238207 0.695262 0.363231C0.570238 0.488255 0.5 0.657824 0.5 0.834635C0.5 1.01145 0.570238 1.18102 0.695262 1.30604C0.820286 1.43106 0.989856 1.5013 1.16667 1.5013H1.866C1.96333 2.7253 2.36133 5.33464 3.99533 6.83463C2.272 8.50863 1.92133 11.9906 1.85133 13.5013H1.16667C0.989856 13.5013 0.820286 13.5715 0.695262 13.6966C0.570238 13.8216 0.5 13.9912 0.5 14.168C0.5 14.3448 0.570238 14.5143 0.695262 14.6394C0.820286 14.7644 0.989856 14.8346 1.16667 14.8346H11.8333C12.0101 14.8346 12.1797 14.7644 12.3047 14.6394C12.4298 14.5143 12.5 14.3448 12.5 14.168C12.5 13.9912 12.4298 13.8216 12.3047 13.6966C12.1797 13.5715 12.0101 13.5013 11.8333 13.5013H11.1487C11.0787 11.992 10.728 8.50997 9.00467 6.83463C10.6387 5.3333 11.0367 2.72397 11.134 1.5013H11.8333ZM7.55333 6.25197C7.44742 6.31079 7.35954 6.39735 7.29913 6.50237C7.23872 6.60738 7.20807 6.72687 7.21047 6.84799C7.21287 6.96911 7.24823 7.0873 7.31275 7.18983C7.37727 7.29237 7.46851 7.37539 7.57667 7.42997C9.20133 8.24863 9.71 11.6373 9.81333 13.5013H3.18667C3.28733 11.6346 3.79867 8.24863 5.42333 7.42997C5.53149 7.37539 5.62273 7.29237 5.68725 7.18983C5.75177 7.0873 5.78713 6.96911 5.78953 6.84799C5.79193 6.72687 5.76128 6.60738 5.70087 6.50237C5.64046 6.39735 5.55258 6.31079 5.44667 6.25197C3.754 5.31264 3.318 2.73064 3.20533 1.5013H9.79467C9.682 2.73064 9.246 5.31264 7.55333 6.25197ZM6.5 8.16797C6.5 8.16797 8.5 9.76797 8.5 10.568V12.8346H4.5V10.568C4.5 9.76797 6.5 8.16797 6.5 8.16797Z"
        fill={props.color ? props.color : theme.palette.primary.main}
      />
    </svg>
  );
};

export default HistoryHourGlassIcon;
