import React, { useEffect, useMemo, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import {
  CalendarModal,
  ClickNavCardNutrition,
  ComponentsLayout,
  CustomTabs,
  LipidChartCard,
  LipidLog,
  ModalCholesterolData,
  ModalMobile,
  weekMonthYearCustom,
} from '@hdcorner/ui-library';
import LipidIcon from '../../assets/icons/LipidIcon';
import { useIonRouter } from '@ionic/react';
import PageLayout from '../../components/PageLayout';
import moment from 'moment/moment';
import {
  useAddLipidGoalMutation,
  useAddLipidLogMutation,
  useGetLipidFirstLogQuery,
  useGetLipidGoalQuery,
  useGetLipidGraphDataQuery,
  useGetLipidLastLogQuery,
  useGetOverallLipidQuery,
} from './queries/lipidQueries';
import { tabs } from './constants';
import ModalAddEditGoal from '../../components/ModalAddEditGoal';
import useTimeframe from '../../hooks/useTimeframe';
import useMetric from './hooks/useMetric';
import useAlert from '../../hooks/useAlert';
import useGoalMetric from './hooks/useGoalMetric';
import { goalProgress } from '../../utils/goalProgress';
import { addGoalDetails } from '../dashboard/slices/congratulationsSlice';
import { useAppDispatch } from '../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../../utils/useConvertJSON';

const Lipid = () => {
  const theme = useTheme();
  const router = useIonRouter();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { presentError, presentSuccess } = useAlert();

  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [openEditGoal, setOpenEditGoal] = useState<boolean>(false);
  const [calendarModal, setCalendarModal] = useState<boolean>(false);
  const [timeframeValue, setTimeframeValue] = useState<string>('all');
  const [lipidModalOpen, setLipidModalOpen] = useState<boolean>(false);

  const metric = useMetric(tabs[selectedTab]);
  const goalMetric = useGoalMetric(tabs[selectedTab]);

  // Used for updating the params for the query
  const [startParam, setStartParam] = useState<string>('');
  const [endParam, setEndParam] = useState<string>('');

  const [addLipidLog] = useAddLipidLogMutation();
  const [addLipidGoal] = useAddLipidGoalMutation();

  const timeframe = useTimeframe(timeframeValue, startParam, endParam);

  const { data: overallData } = useGetOverallLipidQuery(timeframe);
  const { data: lastLog, error: lastLogError } = useGetLipidLastLogQuery();
  const { data: lipidGoal, error: lipidGoalError } = useGetLipidGoalQuery(
    { type: goalMetric },
    {
      skip: metric === 'all',
    },
  );
  const { data: lipidGraphData, error: lipidGraphError } = useGetLipidGraphDataQuery({
    params: { metric: metric === 'all' ? undefined : metric, ...timeframe },
  });
  const { data: firstLog, error: firstLogError } = useGetLipidFirstLogQuery(
    { startingDate: lipidGoal && lipidGoal.length > 0 ? lipidGoal[0].startDate : '' },
    { skip: !lipidGoal || lipidGoal.length === 0 },
  );

  const { data: lpaGoal } = useGetLipidGoalQuery({ type: 'lpa' });
  const { data: hdlGoal } = useGetLipidGoalQuery({ type: 'hdl' });
  const { data: ldlGoal } = useGetLipidGoalQuery({ type: 'ldl' });
  const { data: trgGoal } = useGetLipidGoalQuery({ type: 'trg' });
  const { data: cholGoal } = useGetLipidGoalQuery({ type: 'chol' });

  useEffect(() => {
    if (lipidGoalError) {
      presentError(t('errors.lipid.errorFetchGoal'));
    }
    if (firstLogError || lastLogError) {
      presentError(t('errors.lipid.errorFetchLog'));
    }
    if (lipidGraphError) {
      presentError(t('errors.lipid.errorFetchGraph'));
    }
  }, [lipidGoalError, firstLogError, lastLogError, lipidGraphError]);

  const getFirstLog = useMemo(() => {
    if (!firstLog || firstLog.documents.length === 0) return;
    const latestLogData: any = firstLog.documents[0];
    return latestLogData[tabs[selectedTab].toLowerCase()];
  }, [firstLog, selectedTab]);

  const getDesiredGoal = useMemo(() => {
    if (!lipidGoal || lipidGoal.length === 0) return;
    const lipidGoalData: any = lipidGoal[0];
    return lipidGoalData.goal.measurement;
  }, [lipidGoal]);

  const getLatestMeasurement = useMemo(() => {
    if (!lastLog || lastLog.documents.length === 0) return;
    const startingLogData: any = lastLog.documents[0];
    return startingLogData[metric];
  }, [lastLog, metric]);

  const prepareGoalData = useMemo(() => {
    if (metric === 'all') return undefined;
    if (!getDesiredGoal) return undefined;

    const goalData: any = getDesiredGoal;
    const lastLogValue: any = getLatestMeasurement;
    const fistLogValue: any = getFirstLog;

    return {
      measure: t('measurements.mgdl'),
      goal: goalData ? goalData : '-',
      latestMeasurement: lastLogValue ? lastLogValue : undefined,
      startingMeasurement: fistLogValue ? fistLogValue : undefined,
    };
  }, [getDesiredGoal, getFirstLog, getLatestMeasurement, metric]);

  const startData = useMemo(() => {
    const data =
      !firstLog || firstLog.documents.length === 0 ? undefined : firstLog.documents[0];
    return {
      lpa: data ? Number(data.lpa) : 0,
      hdl: data ? Number(data.hdl) : 0,
      ldl: data ? Number(data.ldl) : 0,
      chol: data ? Number(data.totalChol) : 0,
      trg: data ? Number(data.triglycerides) : 0,
    };
  }, [firstLog]);

  const currentData = useMemo(() => {
    const data =
      !lastLog || lastLog.documents.length === 0 ? undefined : lastLog.documents[0];
    return {
      lpa: data ? Number(data.lpa) : 0,
      hdl: data ? Number(data.hdl) : 0,
      ldl: data ? Number(data.ldl) : 0,
      chol: data ? Number(data.totalChol) : 0,
      trg: data ? Number(data.triglycerides) : 0,
    };
  }, [lastLog]);

  const lpaGoalData = useMemo(() => {
    if (!lpaGoal || lpaGoal.length === 0) return { log: 0, date: '' };
    return { log: Number(lpaGoal[0].goal.measurement), date: lpaGoal[0].startDate };
  }, [lpaGoal]);

  const hdlGoalData = useMemo(() => {
    if (!hdlGoal || hdlGoal.length === 0) return { log: 0, date: '' };
    return { log: Number(hdlGoal[0].goal.measurement), date: hdlGoal[0].startDate };
  }, [hdlGoal]);

  const ldlGoalData = useMemo(() => {
    if (!ldlGoal || ldlGoal.length === 0) return { log: 0, date: '' };
    return { log: Number(ldlGoal[0].goal.measurement), date: ldlGoal[0].startDate };
  }, [ldlGoal]);

  const trgGoalData = useMemo(() => {
    if (!trgGoal || trgGoal.length === 0) return { log: 0, date: '' };
    return { log: Number(trgGoal[0].goal.measurement), date: trgGoal[0].startDate };
  }, [trgGoal]);

  const cholGoalData = useMemo(() => {
    if (!cholGoal || cholGoal.length === 0) return { log: 0, date: '' };
    return { log: Number(cholGoal[0].goal.measurement), date: cholGoal[0].startDate };
  }, [cholGoal]);

  const handleAddLipid = async (data: Omit<LipidLog, '_id'>) => {
    let { totalChol, triglycerides, hdl, ldl, lpa, logDate } = data;

    try {
      await addLipidLog({ totalChol, triglycerides, hdl, ldl, lpa, logDate })
        .unwrap()
        .then(() => {
          presentSuccess(t('errors.lipid.successLogAdd'));
          const goalDetailsArray: Array<{
            type: keyof typeof currentData;
            data: number;
            goalData: {
              log: number;
              date: string;
            };
            startData: {
              chol: number;
              trg: number;
              hdl: number;
              ldl: number;
              lpa: number;
            };
          }> = [
            { type: 'chol', data: currentData.chol, goalData: cholGoalData, startData },
            { type: 'trg', data: currentData.trg, goalData: trgGoalData, startData },
            { type: 'hdl', data: currentData.hdl, goalData: hdlGoalData, startData },
            { type: 'ldl', data: currentData.ldl, goalData: ldlGoalData, startData },
            { type: 'lpa', data: currentData.lpa, goalData: lpaGoalData, startData },
          ];

          goalDetailsArray.forEach(({ type, data, goalData, startData }) => {
            if (
              goalProgress(data, goalData.log, startData[type]) === 100 &&
              goalData.date !== ''
            ) {
              setTimeout(() => {
                dispatch(
                  addGoalDetails({
                    category: 'lipid_profile',
                    goal: {
                      value: goalData.log,
                      measure: `mg/dL - ${type.toUpperCase()}`,
                    },
                    start: {
                      value: startData[type],
                      measure: `mg/dL - ${type.toUpperCase()}`,
                      time: goalData.date,
                    },
                  }),
                );
              }, 1500);
            }
          });
        });
    } catch (e) {
      presentError(t('errors.lipid.errorLogAdd'));
    }
  };

  const chartData = useMemo(() => {
    if (!lipidGraphData) return;

    if (tabs[selectedTab].toLowerCase() === 'all') {
      let totalChol: any[] = [],
        triglycerides: any[] = [],
        hdl: any[] = [],
        ldl: any[] = [],
        lpa: any[] = [];
      lipidGraphData.logs.forEach(item => {
        if (item.totalChol) {
          totalChol.push({
            y: item.totalChol,
            x: moment(item.logDate).toISOString(),
          });
        }
        if (item.triglycerides) {
          triglycerides.push({
            y: item.triglycerides,
            x: moment(item.logDate).toISOString(),
          });
        }
        if (item.hdl) {
          hdl.push({
            y: item.hdl,
            x: moment(item.logDate).toISOString(),
          });
        }
        if (item.ldl) {
          ldl.push({
            y: item.ldl,
            x: moment(item.logDate).toISOString(),
          });
        }
        if (item.lpa) {
          lpa.push({
            y: item.lpa,
            x: moment(item.logDate).toISOString(),
          });
        }
      });

      return [
        {
          name: t('lipid.labels.totChol'),
          data: totalChol,
        },
        {
          name: t('lipid.labels.tryg'),
          data: triglycerides,
        },
        {
          name: t('dashboard.dashcards.lipid.hdl'),
          data: hdl,
        },
        {
          name: t('dashboard.dashcards.lipid.ldl'),
          data: ldl,
        },
        {
          name: t('dashboard.dashcards.lipid.lpa'),
          data: lpa,
        },
      ];
    }

    return lipidGraphData.logs.map((item: LipidLog) => ({
      x: moment(item.logDate).toISOString(),
      y: (item as any)[metric],
    }));
  }, [lipidGraphData, metric, selectedTab]);

  const pageTabs = () => {
    return tabs.map(item => {
      return {
        label: item,
        children: <></>,
      };
    });
  };

  const handleChange = (newValue: string) => {
    if (newValue === 'custom') {
      setCalendarModal(true);
    }
    setTimeframeValue(newValue);
  };

  const renderPlaceholder = useMemo(() => {
    if (metric === 'lpa') return `14 ${t('measurements.mgdl')}`;
    if (metric === 'hdl') return `40 ${t('measurements.mgdl')}`;
    if (metric === 'ldl') return `100 ${t('measurements.mgdl')}`;
    if (metric === 'totalChol') return `200 ${t('measurements.mgdl')}`;
    if (metric === 'triglycerides') return `150 ${t('measurements.mgdl')}`;
  }, [metric]);

  const chartOverallValues = useMemo(() => {
    if (!overallData) return;

    const selectedType = tabs[selectedTab].toLowerCase();
    switch (selectedType) {
      case 'all':
        return [
          {
            label: t('lipid.labels.avgChol'),
            value: Math.round(overallData.totalChol?.avg) || '-',
          },
          {
            label: t('lipid.labels.avgHdl'),
            value: Math.round(overallData.hdl?.avg) || '-',
          },
          {
            label: t('lipid.labels.avgLdl'),
            value: Math.round(overallData.ldl?.avg) || '-',
          },
          {
            label: t('lipid.labels.avgTrg'),
            value: Math.round(overallData.triglycerides?.avg) || '-',
          },
          {
            label: t('lipid.labels.avgLpa'),
            value: Math.round(overallData.lpa?.avg) || '-',
          },
        ];
      case 'lpa':
        return [
          {
            label: t('measurements.max'),
            value: Math.round(overallData.lpa?.max) || '-',
          },
          {
            label: t('measurements.min'),
            value: Math.round(overallData.lpa?.min) || '-',
          },
          {
            label: t('measurements.avg'),
            value: Math.round(overallData.lpa?.avg) || '-',
          },
        ];
      case 'chol':
        return [
          {
            label: t('measurements.max'),
            value: Math.round(overallData.totalChol?.max) || '-',
          },
          {
            label: t('measurements.min'),
            value: Math.round(overallData.totalChol?.min) || '-',
          },
          {
            label: t('measurements.avg'),
            value: Math.round(overallData.totalChol?.avg) || '-',
          },
        ];
      case 'hdl':
        return [
          {
            label: t('measurements.max'),
            value: Math.round(overallData.hdl?.max) || '-',
          },
          {
            label: t('measurements.min'),
            value: Math.round(overallData.hdl?.min) || '-',
          },
          {
            label: t('measurements.avg'),
            value: Math.round(overallData.hdl?.avg) || '-',
          },
        ];
      case 'ldl':
        return [
          {
            label: t('measurements.max'),
            value: Math.round(overallData.ldl?.max) || '-',
          },
          {
            label: t('measurements.min'),
            value: Math.round(overallData.ldl?.min) || '-',
          },
          {
            label: t('measurements.avg'),
            value: Math.round(overallData.ldl?.avg) || '-',
          },
        ];
      case 'trg':
        return [
          {
            label: t('measurements.max'),
            value: Math.round(overallData.triglycerides?.max) || '-',
          },
          {
            label: t('measurements.min'),
            value: Math.round(overallData.triglycerides?.min) || '-',
          },
          {
            label: t('measurements.avg'),
            value: Math.round(overallData.triglycerides?.avg) || '-',
          },
        ];
    }
  }, [overallData, selectedTab]);

  const handleSaveLipidModal = async (data: Omit<LipidLog, '_id'>) => {
    await handleAddLipid(data);
    setLipidModalOpen(false);
  };

  const handleClickGoal = () => {
    if (lipidGoal && lipidGoal.length > 0) {
      router.push(`/dashboard/lipid-goal/${tabs[selectedTab].toLowerCase()}`);
      return;
    }
    setOpenEditGoal(true);
  };

  const handleSaveClick = async (desiredGoal: string) => {
    await addLipidGoal({
      goal: { measurement: desiredGoal },
      goalType: tabs[selectedTab].toLowerCase(),
      startDate: moment().startOf('day').toISOString(),
    })
      .unwrap()
      .then(() => presentSuccess(t('errors.lipid.successGoalAdd')))
      .catch(() => presentError(t('errors.lipid.errorGoalAdd')));
    setOpenEditGoal(false);
  };

  const handleClickInDepthData = () => {
    router.push(`/dashboard/lipid-in-depth-data/${tabs[selectedTab].toLowerCase()}`);
  };

  const handleClickGraphData = () => {
    router.push(`/dashboard/lipid/graph-data/${tabs[selectedTab].toLowerCase()}`);
  };

  const handleDateChange = (dateRange: string[]) => {
    setStartParam(dateRange[0]);
    setEndParam(dateRange[1]);
  };

  return (
    <PageLayout
      defaultHref={'/dashboard/home'}
      headerColor={theme.palette.lipid.main}
      headerTitle={t('headingsTitles.lipid')}
      fabClick={() => setLipidModalOpen(true)}
      headerIcon={<LipidIcon fill={theme.palette.lipid.main} />}
    >
      <ComponentsLayout>
        <Box display={'flex'} flexDirection={'column'} flex={1}>
          <CustomTabs value={selectedTab} setValue={setSelectedTab} tabs={pageTabs()} />
        </Box>
        <LipidChartCard
          chartData={chartData}
          goalData={prepareGoalData}
          values={chartOverallValues}
          title={getLatestMeasurement}
          handleChangeOption={handleChange}
          toggleButtonsValue={timeframeValue}
          headings={useConvertJSON(weekMonthYearCustom)}
        />
        <Box mt={2}>
          {selectedTab !== 0 && (
            <ClickNavCardNutrition
              onClick={handleClickInDepthData}
              headings={t('buttons.navigation.inDepthSpecifics', {
                section: tabs[selectedTab].toUpperCase(),
              })}
            />
          )}
          {selectedTab !== 0 && (
            <ClickNavCardNutrition
              onClick={handleClickGraphData}
              headings={t('buttons.navigation.graphData')}
            />
          )}
          {selectedTab !== 0 && (
            <ClickNavCardNutrition
              onClick={handleClickGoal}
              headings={t('buttons.navigation.goal')}
              body={
                getDesiredGoal
                  ? getDesiredGoal + t('measurements.mgdl')
                  : `${t('buttons.navigation.addGoal')}`
              }
            />
          )}
        </Box>
      </ComponentsLayout>
      <ModalMobile open={lipidModalOpen} setOpen={setLipidModalOpen}>
        <ModalCholesterolData handleModalSaveClick={handleSaveLipidModal} />
      </ModalMobile>
      <ModalMobile open={openEditGoal} setOpen={setOpenEditGoal}>
        <ModalAddEditGoal
          goalType={'lipid'}
          handleSaveClick={handleSaveClick}
          desiredPlaceholder={renderPlaceholder}
          currentMeasurement={getLatestMeasurement}
        />
      </ModalMobile>
      <CalendarModal
        open={calendarModal}
        setOpen={setCalendarModal}
        selectedDate={[startParam, endParam]}
        saveDate={date => handleDateChange(date)}
      />
    </PageLayout>
  );
};

export default Lipid;
