import React, { FC } from 'react';
import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ArrowRight from '../../assets/ArrowRight';
import CustomButton from '../controls/CustomButton';
import { ButtonProps } from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  color: theme.palette.primary.main,
  marginLeft: theme.spacing(1),
}));

const CustomButtonMain = styled(CustomButton)<ButtonProps>(({ theme }) => ({
  ...theme.typography.body1,
  fontWeight: theme.typography.fontWeightRegular,
  color: theme.palette.primary.main,
  '.MuiButton-endIcon': {
    marginLeft: theme.spacing(1.5),
  },
}));

const Container = styled(Box, {
  shouldForwardProp: prop => prop !== 'isWeb',
})<BoxProps & { isWeb: boolean }>(({ theme, isWeb }) => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'space-between',
  height: 56,
  borderRadius: '8px',
  padding: theme.spacing(0, 2),
  background: isWeb ? theme.palette.secondary.main : theme.palette.secondary.light,
  cursor: 'pointer',
}));

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 20,
  height: 20,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
}));

const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  backgroundColor: theme.palette.exercise.main,
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  boxShadow: 'none',
  '&:before': {
    display: 'block',
    width: 20,
    height: 20,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.kmColorsRed.main,
  },
}));

type Props = {
  title: string;
  selected: boolean;
  onClickCard: () => void;
  onClickViewProgram: () => void;
};
const WorkoutCards: FC<Props & BoxProps> = ({
  title,
  onClickCard,
  onClickViewProgram,
  selected,
  ...boxProps
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const web = useMediaQuery(theme.breakpoints.up('sm'));

  const handleClickViewProgram = (event: React.MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation();
    onClickViewProgram();
  };

  return (
    <Container {...boxProps} isWeb={web} onClick={onClickCard}>
      <Box display={'flex'} alignItems={'center'}>
        {selected ? <BpCheckedIcon /> : <BpIcon />}
        <Title variant={'subtitle2'}>{title}</Title>
      </Box>
      <Box display={'flex'} alignItems={'center'}>
        <CustomButtonMain
          disableRipple
          variant={'text'}
          endIcon={<ArrowRight />}
          onClick={handleClickViewProgram}
        >
          {t('fitness.workouts.labels.viewProgram')}
        </CustomButtonMain>
      </Box>
    </Container>
  );
};

export default WorkoutCards;
