import { Box, styled, Typography, TypographyProps, useTheme } from '@mui/material';
import Lottie from 'lottie-react';
import moment from 'moment/moment';
import React, { FC, useEffect, useMemo } from 'react';
import congratulations from '../assets/lotties/bullseye-target.json';
import { CongratulationsDataType } from '../types/CongratulationsDataType';
import {
  CVDRiskSQ,
  DiabetesSQ,
  ExclamationIcon,
  ExerciseIcon,
  HypertensionSQ,
  LipidSQ,
  NutritionBowl,
  SMDetailCard,
  SMDetailCardChild,
} from '@hdcorner/ui-library';
import WeightIcon from '../assets/icons/WeightIcon';
import { timeElapsed } from '../utils/dateTimeUtils';
import { useGetQuotesQuery } from '../features/dashboard/queries/quotesQueries';
import useAlert from '../hooks/useAlert';
import ActivitiesIcon from '../assets/icons/ActivitiesIcon';
import { useTranslation } from 'react-i18next';

const Text = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightBold,
  padding: theme.spacing(1, 8),
}));

const Author = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 400,
  fontStyle: ' italic',
  color: theme.palette.primary.main,
}));

type Props = {
  handleNotify?: () => void;
  data: CongratulationsDataType;
};
const CongratulationsComponent: FC<Props> = ({ data }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { presentError } = useAlert();
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const { error, data: quotesRes } = useGetQuotesQuery({
    lang,
    associatedGoal:
      data.category.toLowerCase() === 'blood glucose' ||
      data.category.toLowerCase() === 'hba1c'
        ? 'diabetes'
        : data.category.toLowerCase(),
  });

  useEffect(() => {
    if (error) presentError('An unexpected error occurred fetching inspirational Quotes');
  }, [error]);

  const quoteData = useMemo(() => {
    const data =
      quotesRes && quotesRes.documents.length > 0 ? quotesRes.documents[0] : undefined;
    return {
      author: data ? data.author : '',
      quote: data ? data.quote : '',
    };
  }, [quotesRes]);

  const iconToShow = (category: string) => {
    const black = theme.palette.primary.main;
    switch (category.toLowerCase()) {
      case 'nutrition':
        return <NutritionBowl />;
      case 'weight':
        return <WeightIcon fill={black} height={'14px'} width={'14px'} />;
      case 'steps':
        return <ExerciseIcon color={black} width={'14px'} height={'14px'} />;
      case 'activities':
        return <ActivitiesIcon fill={black} width={'14px'} height={'14px'} />;
      case 'cvd':
        return <CVDRiskSQ color={black} width={'14px'} height={'14px'} />;
      case 'blood glucose':
        return <DiabetesSQ color={black} width={'14px'} height={'14px'} />;
      case 'hba1c':
        return <DiabetesSQ color={black} width={'14px'} height={'14px'} />;
      case 'hypertension':
        return <HypertensionSQ color={black} width={'14px'} height={'14px'} />;
      case 'lipid_profile':
        return <LipidSQ color={black} width={'14px'} height={'14px'} />;
      default:
        return <ExclamationIcon color={black} height={'14px'} width={'14px'} />;
    }
  };

  const categoryHeading = (category: string) => {
    switch (category.toLowerCase()) {
      case 'nutrition':
        return t('headingsTitles.nutrition');
      case 'weight':
        return t('userDetails.weight');
      case 'steps':
        return t('headingsTitles.steps');
      case 'activities':
        return t('headingsTitles.activities');
      case 'cvd':
        return t('dashboard.dashcards.cvd.title');
      case 'blood glucose':
        return t('headingsTitles.bloodGluc');
      case 'hba1c':
        return t('headingsTitles.hba1c');
      case 'hypertension':
        return t('dashboard.dashcards.hypertension.title');
      case 'lipid_profile':
        return t('dashboard.dashcards.lipid.title');
      default:
        return '';
    }
  };

  return (
    <Box
      gap={3}
      flex={1}
      display={'flex'}
      flexDirection={'column'}
      padding={theme.spacing(3)}
      bgcolor={theme.palette.secondary.light}
    >
      <Box
        height={'160px'}
        display={'flex'}
        alignItems={'center'}
        flexDirection={'column'}
      >
        <Lottie
          loop={true}
          animationData={congratulations}
          style={{ position: 'absolute', height: 160, width: 160 }}
        />
      </Box>
      <Text
        variant={'h1'}
        align={'center'}
        display={'flex'}
        alignSelf={'center'}
        alignItems={'center'}
        textTransform={lang === 'el' ? 'capitalize' : 'uppercase'}
      >
        {t('components.models.congratulations.congrats')}
      </Text>
      {quoteData.quote !== '' && (
        <Author variant={'h1'} align={'center'}>
          {`"${quoteData.quote}"`}
        </Author>
      )}
      {quoteData.author !== '' && (
        <Author variant={'h1'} align={'center'}>
          {`- ${quoteData.author}`}
        </Author>
      )}
      <SMDetailCard
        altIcon={iconToShow(data.category)}
        heading={categoryHeading(data.category)}
        sx={{
          background: theme.palette.secondary.main,
          border: `1px solid ${theme.palette.secondary.dark}`,
        }}
      >
        {data.goal && data.start && (
          <>
            <SMDetailCardChild
              heading={t('goals.start')}
              weight={`${data.start.value} ${data.start.measure}`}
              time={moment(data.start.time).format('D MMM, h:mm A')}
            />
            <SMDetailCardChild
              heading={t('headingsTitles.goal')}
              time={moment().format('D MMM, h:mm A')}
              weight={`${data.goal.value} ${data.goal.measure}`}
            />
            <SMDetailCardChild
              heading={t('goals.achievedIn')}
              time={`${timeElapsed(data.start.time).amount}  ${t(
                timeElapsed(data.start.time).unit,
              )}`}
            />
          </>
        )}

        {data.startHyp && data.goalHyp && (
          <>
            <SMDetailCardChild
              weight={''}
              heading={t('goals.start')}
              time={moment(data.startHyp.time).format('D MMM, h:mm A')}
            />
            <SMDetailCardChild
              time={''}
              heading={''}
              weight={`systolic: ${data.startHyp.systolic} - diastolic: ${data.startHyp.diastolic} ${data.startHyp.measure}`}
            />
            <SMDetailCardChild
              weight={''}
              heading={t('headingsTitles.goal')}
              time={moment().format('D MMM, h:mm A')}
            />
            <SMDetailCardChild
              time={''}
              heading={''}
              weight={`systolic: ${data.goalHyp.systolic} - diastolic: ${data.goalHyp.diastolic} ${data.goalHyp.measure}`}
            />

            <SMDetailCardChild
              heading={t('goals.achievedIn')}
              time={`${timeElapsed(data.startHyp.time).amount}${t(
                timeElapsed(data.startHyp.time).unit,
              )}`}
            />
          </>
        )}
      </SMDetailCard>
      {/*<CustomButton*/}
      {/*  fullWidth*/}
      {/*  variant={'contained'}*/}
      {/*  onClick={handleNotify}*/}
      {/*  style={{*/}
      {/*    width: '300px',*/}
      {/*    margin: '0 auto',*/}
      {/*    color: theme.palette.primary.main,*/}
      {/*    backgroundColor: theme.palette.warning.main,*/}
      {/*  }}*/}
      {/*>*/}
      {/*  {t('goals.notifyHcp')}*/}
      {/*</CustomButton>*/}
    </Box>
  );
};

export default CongratulationsComponent;
