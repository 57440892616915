import { styled } from '@mui/material/styles';
import { List, ListProps } from '@mui/material';

const ListMain = styled(List)<ListProps>(({ theme }) => ({
  overflow: 'auto',
  borderRadius: '8px',
  padding: theme.spacing(1),
  background:
    theme.palette.mode === 'dark'
      ? theme.palette.secondary.main
      : theme.palette.secondary.light,
  '::-webkit-scrollbar': {
    width: 4,
  },
  '::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: 8,
    backgroundColor: theme.palette.secondary.dark,
  },
}));

export default ListMain;
