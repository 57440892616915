import React, { FC } from 'react';
import {
  Box,
  BoxProps,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
  styled,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import RescheduleIcon from '../../assets/RescheduleIcon';
import ArrowRight from '../../assets/ArrowRight';

type medicineState = 'not-taken' | 'taken' | 'rescheduled-from' | 'rescheduled-to';

const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

const CustomBox = styled(Box)<BoxProps>(({ theme }) => ({
  padding: theme.spacing(1, 2),
  borderRadius: '8px',
  boxSizing: 'border-box',
  height: '35px',
  backgroundColor: theme.palette.secondary.light,
  display: 'flex',
  justifyContent: 'space-between',
  cursor: 'pointer',
}));

const CustomBoxLeft = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
}));

const CustomBoxRight = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  flexGrow: '1',
}));

const CustomFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: propName => {
    return propName !== 'type';
  },
})<FormControlLabelProps & { type: medicineState }>(({ theme, type }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  padding: theme.spacing(0),
  margin: theme.spacing(0),
  '.MuiFormControlLabel-label': {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.subtitle2.fontSize,
    color:
      type === 'rescheduled-from'
        ? theme.palette.primary.light
        : theme.palette.primary.main,
    opacity: type === 'rescheduled-from' ? '0.5' : '1',
    marginLeft: type === 'rescheduled-to' ? theme.spacing(3) : 'unset',
  },
}));

const CustomCheckbox = styled(Checkbox)<CheckboxProps>(({ theme }) => ({
  borderRadius: '4px',
  padding: theme.spacing(0, 0.5, 0, 0),
  margin: theme.spacing(0),
  color: theme.palette.kmColorsCoolGrey.main,
  '&.Mui-checked': {
    color: theme.palette.success.main,
  },
  '.MuiSvgIcon-root': {
    fontSize: '13.33px',
  },
}));

const CustomTypographyAmount = styled(Typography, {
  shouldForwardProp: propName => {
    return propName !== 'type';
  },
})<TypographyProps & { type: medicineState }>(({ theme, type }) => ({
  padding: theme.spacing(0, 1),
  margin: theme.spacing(0),
  fontSize: theme.typography.body1.fontSize,
  fontWeight: theme.typography.body1.fontWeight,
  color:
    type === 'rescheduled-from'
      ? theme.palette.primary.light
      : theme.palette.kmColorsCoolGrey.main,
  opacity: type === 'rescheduled-from' ? '0.5' : 1,
}));

const CustomTypographyTime = styled(Typography)<TypographyProps>(({ theme }) => ({
  margin: theme.spacing(0),
  fontSize: theme.typography.body1.fontSize,
  color: theme.palette.kmColorsCoolGrey.main,
  fontWeight: theme.typography.body1.fontWeight,
  padding: theme.spacing(0, 1),
  textTransform: lang === 'el' ? 'capitalize' : 'uppercase',
}));

const AbsoluteBox = styled(Box)<BoxProps>(({ theme }) => ({
  top: 0,
  position: 'absolute',
  left: theme.spacing(2.25),
}));

type CheckboxNavCardProps = {
  time: string;
  amount: string;
  checkLabel: string;
  type: medicineState;
  onClickHandler: () => void;
};
const NavCardMedication: FC<CheckboxNavCardProps> = ({
  type,
  checkLabel,
  amount,
  time,
  onClickHandler,
}) => {
  const theme = useTheme();

  return (
    <CustomBox>
      <CustomBoxLeft>
        <CustomFormControlLabel
          type={type}
          label={checkLabel}
          labelPlacement={'end'}
          control={<CustomCheckbox checked={type === 'taken'} />}
        />
        <CustomTypographyAmount type={type}>{amount}</CustomTypographyAmount>
        {type === 'rescheduled-to' && (
          <AbsoluteBox>
            <RescheduleIcon color={theme.palette.error.main} />
          </AbsoluteBox>
        )}
      </CustomBoxLeft>
      <CustomBoxRight onClick={onClickHandler}>
        <CustomTypographyTime>{time}</CustomTypographyTime>
        <ArrowRight height={'10.67px'} />
      </CustomBoxRight>
    </CustomBox>
  );
};

export default NavCardMedication;
