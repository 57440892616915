import React, { FC, useEffect, useMemo, useState } from 'react';
import { updateProgram } from '../slices/workoutSlice';
import { useGetWorkoutByIdQuery } from '../queries/workoutQueries';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  Box,
  BoxProps,
  IconButton,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  ArrowLeft,
  capitalizeFirstLetter,
  CustomButton,
  ExerciseIcon,
  ExerciseVideoCard,
} from '@hdcorner/ui-library';
import { useIonRouter } from '@ionic/react';
import { Exercise } from '../types/Workout';
import { VideoType } from '../../../../types/VideoType';
import VideoModal from '../../../../components/VideoModal';
import { secondsToMinutes } from '../../../../utils/dateTimeUtils';
import { getWatchedExercises, setWatchedExercises } from '../utils/watchedExercises';
import useAlert from '../../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

const MainBox = styled(Box, { shouldForwardProp: propName => propName !== 'web' })<
  BoxProps & { web: boolean }
>(({ web, theme }) => ({
  flexShrink: 1,
  display: 'flex',
  gap: web ? 0 : 3,
  borderRadius: '8px',
  flexDirection: 'column',
  padding: web ? theme.spacing(3) : 0,
  backgroundColor: web ? theme.palette.secondary.light : 'transparent',
}));

type Props = {
  onWebClick?: () => void;
};
const ProgramVideos: FC<Props> = ({ onWebClick }) => {
  const theme = useTheme();
  const router = useIonRouter();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { presentError } = useAlert();
  const web = useMediaQuery(theme.breakpoints.up('md'));
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const [storedExercises, setStoredExercises] = useState<string[]>([]);
  const [selectedExercise, setSelectedExercise] = useState<Exercise | undefined>(
    undefined,
  );

  const { program } = useAppSelector(state => state.workouts);

  const { data: programRes, error } = useGetWorkoutByIdQuery(
    { workoutId: program._id },
    { skip: !program._id },
  );

  useEffect(() => {
    if (error) {
      presentError(t('errors.fitness.workouts.errorProgramFetch'));
    }
  }, [error]);

  useEffect(() => {
    if (!program || !program._id) return;
    getWatchedExercises(program._id).then(watchedWorkouts => {
      setStoredExercises(watchedWorkouts);
    });
  }, [program]);

  const programVideoList = useMemo<Exercise[]>(() => {
    if (!programRes || programRes.length === 0) return [];

    return programRes[0].exercises.map(exercise => {
      const foundExercise = storedExercises.find(it => it === exercise._id);
      return {
        ...exercise,
        watched: !!foundExercise,
      };
    });
  }, [programRes, storedExercises]);

  const handleBackButton = () => {
    dispatch(updateProgram({ name: '', _id: '' }));
    if (onWebClick) {
      onWebClick();
    } else {
      router.goBack();
    }
  };

  const handleClickCard = (exerciseId: string) => {
    const foundExercise = programVideoList.find(it => it._id === exerciseId);
    if (foundExercise) {
      setSelectedExercise(foundExercise);
      if (!foundExercise.watched) {
        setStoredExercises([...storedExercises, foundExercise._id]);
        setWatchedExercises(program._id, [...storedExercises, foundExercise._id]);
      }
    }
  };

  const videoToPlay = useMemo<VideoType[]>(() => {
    if (!selectedExercise) return [];
    return [
      {
        time: selectedExercise.duration,
        title:
          typeof selectedExercise.title === 'string'
            ? selectedExercise.title
            : selectedExercise.title[lang],
        thumbnailUrl: selectedExercise.thumbnail[lang]
          ? selectedExercise.thumbnail[lang].url
          : (selectedExercise.thumbnail as any).url,
        videoUrl: selectedExercise.attachedVideo[lang]
          ? selectedExercise.attachedVideo[lang].url
          : (selectedExercise.attachedVideo as any).url,
        watched: selectedExercise.watched ? selectedExercise.watched : false,
        section: capitalizeFirstLetter(selectedExercise.bodyPart.replace('_', ' ')),
      },
    ];
  }, [lang, selectedExercise]);

  return (
    <MainBox web={web}>
      {web && (
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          marginBottom={theme.spacing(4)}
        >
          <Box display={'flex'} alignItems={'center'}>
            {program && (
              <IconButton
                onClick={handleBackButton}
                sx={{ marginRight: theme.spacing(2) }}
              >
                <ArrowLeft />
              </IconButton>
            )}
            <Typography
              variant={'h1'}
              sx={{
                color: theme.palette.primary.main,
                textTransform: lang === 'el' ? 'capitalize' : 'uppercase',
              }}
            >
              {program.name}
            </Typography>
          </Box>
        </Box>
      )}
      <Box>
        {programVideoList.map((exercise, index) => (
          <ExerciseVideoCard
            key={exercise._id}
            watched={exercise.watched || false}
            time={secondsToMinutes(exercise.duration)}
            thumbnailUrl={
              exercise.thumbnail[lang]
                ? exercise.thumbnail[lang].url
                : (exercise.thumbnail as any).url
            }
            onClick={() => handleClickCard(exercise._id)}
            sx={{ marginTop: index === 0 ? 0 : theme.spacing(1) }}
            title={
              typeof exercise.title === 'string' ? exercise.title : exercise.title[lang]
            }
          />
        ))}
      </Box>
      {!web && (
        <Box>
          <CustomButton
            fullWidth
            variant={'contained'}
            onClick={handleBackButton}
            startIcon={<ExerciseIcon color={theme.palette.secondary.light} />}
          >
            {t('buttons.backToPrograms')}
          </CustomButton>
          <Box mb={1}></Box>
        </Box>
      )}
      {selectedExercise && (
        <VideoModal
          singleVideo
          open={true}
          videosData={videoToPlay}
          handleClose={() => setSelectedExercise(undefined)}
        />
      )}
    </MainBox>
  );
};

export default ProgramVideos;
