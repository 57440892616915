import * as React from 'react';
import { SVGProps } from 'react';
import { theme } from '@hdcorner/ui-library';

const ActivitiesIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 22}
      height={props.height ? props.height : 15}
      viewBox={props.viewBox ? props.viewBox : '0 0 22 15'}
      {...props}
    >
      <path
        d="M21 6.5H20V3.5C20 2.70435 19.6839 1.94129 19.1213 1.37868C18.5587 0.816071 17.7956 0.5 17 0.5C16.2044 0.5 15.4413 0.816071 14.8787 1.37868C14.3161 1.94129 14 2.70435 14 3.5V6.5H8V3.5C8 2.70435 7.68393 1.94129 7.12132 1.37868C6.55871 0.816071 5.79565 0.5 5 0.5C4.20435 0.5 3.44129 0.816071 2.87868 1.37868C2.31607 1.94129 2 2.70435 2 3.5V6.5H1C0.734784 6.5 0.48043 6.60536 0.292893 6.79289C0.105357 6.98043 0 7.23478 0 7.5C0 7.76522 0.105357 8.01957 0.292893 8.20711C0.48043 8.39464 0.734784 8.5 1 8.5H2V11.5C2 12.2956 2.31607 13.0587 2.87868 13.6213C3.44129 14.1839 4.20435 14.5 5 14.5C5.79565 14.5 6.55871 14.1839 7.12132 13.6213C7.68393 13.0587 8 12.2956 8 11.5V8.5H14V11.5C14 12.2956 14.3161 13.0587 14.8787 13.6213C15.4413 14.1839 16.2044 14.5 17 14.5C17.7956 14.5 18.5587 14.1839 19.1213 13.6213C19.6839 13.0587 20 12.2956 20 11.5V8.5H21C21.2652 8.5 21.5196 8.39464 21.7071 8.20711C21.8946 8.01957 22 7.76522 22 7.5C22 7.23478 21.8946 6.98043 21.7071 6.79289C21.5196 6.60536 21.2652 6.5 21 6.5ZM6 11.5C6 11.7652 5.89464 12.0196 5.70711 12.2071C5.51957 12.3946 5.26522 12.5 5 12.5C4.73478 12.5 4.48043 12.3946 4.29289 12.2071C4.10536 12.0196 4 11.7652 4 11.5V3.5C4 3.23478 4.10536 2.98043 4.29289 2.79289C4.48043 2.60536 4.73478 2.5 5 2.5C5.26522 2.5 5.51957 2.60536 5.70711 2.79289C5.89464 2.98043 6 3.23478 6 3.5V11.5ZM18 11.5C18 11.7652 17.8946 12.0196 17.7071 12.2071C17.5196 12.3946 17.2652 12.5 17 12.5C16.7348 12.5 16.4804 12.3946 16.2929 12.2071C16.1054 12.0196 16 11.7652 16 11.5V3.5C16 3.23478 16.1054 2.98043 16.2929 2.79289C16.4804 2.60536 16.7348 2.5 17 2.5C17.2652 2.5 17.5196 2.60536 17.7071 2.79289C17.8946 2.98043 18 3.23478 18 3.5V11.5Z"
        fill={props.fill ? props.fill : theme.palette.warning.main}
      />
    </svg>
  );
};

export default ActivitiesIcon;
