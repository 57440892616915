import { ArrowDown, CalendarIcon } from '@hdcorner/ui-library';
import * as React from 'react';
import { BoxProps, styled, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const InDepthDatePickerContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '8px',
  backgroundColor: 'white',
  gap: theme.spacing(1),
  padding: theme.spacing(1, 2, 1, 2),
}));
const DatePickerTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightRegular,
}));
const CenterBox = styled(Box)(() => ({
  display: 'flex',
  marginLeft: 'auto',
  alignItems: 'center',
  justifyContent: 'center',
}));

const InDepthDatePicker: FC<BoxProps> = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <InDepthDatePickerContainer {...props}>
      <Box>
        <CalendarIcon />
      </Box>
      <DatePickerTypography variant="subtitle2">
        {t('subscription.labels.date')}
      </DatePickerTypography>
      <CenterBox>
        <ArrowDown />
      </CenterBox>
    </InDepthDatePickerContainer>
  );
};

export default InDepthDatePicker;
