import * as React from 'react';
import { SVGProps } from 'react';
import { Box, BoxProps, styled } from '@mui/material';

const IconBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: '32px',
  height: '32px',
  borderRadius: '4px',
  padding: '4px',
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.secondary.main,
}));

const WalletSQIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <IconBox>
      <svg
        width="16"
        height="12"
        viewBox="0 0 16 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M2.66666 12H13.3333C13.8638 12 14.3725 11.7893 14.7475 11.4142C15.1226 11.0391 15.3333 10.5304 15.3333 10V2C15.3333 1.46957 15.1226 0.960859 14.7475 0.585787C14.3725 0.210714 13.8638 0 13.3333 0H2.66666C2.13623 0 1.62752 0.210714 1.25245 0.585787C0.877378 0.960859 0.666664 1.46957 0.666664 2V10C0.666664 10.5304 0.877378 11.0391 1.25245 11.4142C1.62752 11.7893 2.13623 12 2.66666 12ZM2 2C2 1.82319 2.07024 1.65362 2.19526 1.5286C2.32028 1.40357 2.48985 1.33333 2.66666 1.33333H13.3333C13.5101 1.33333 13.6797 1.40357 13.8047 1.5286C13.9298 1.65362 14 1.82319 14 2V10C14 10.1768 13.9298 10.3464 13.8047 10.4714C13.6797 10.5964 13.5101 10.6667 13.3333 10.6667H2.66666C2.48985 10.6667 2.32028 10.5964 2.19526 10.4714C2.07024 10.3464 2 10.1768 2 10V2ZM3.33333 8.66667C3.33333 8.48986 3.40357 8.32029 3.52859 8.19526C3.65362 8.07024 3.82319 8 4 8H6C6.17681 8 6.34638 8.07024 6.4714 8.19526C6.59643 8.32029 6.66666 8.48986 6.66666 8.66667C6.66666 8.84348 6.59643 9.01305 6.4714 9.13807C6.34638 9.2631 6.17681 9.33333 6 9.33333H4C3.82319 9.33333 3.65362 9.2631 3.52859 9.13807C3.40357 9.01305 3.33333 8.84348 3.33333 8.66667ZM3.33333 6.66667C3.33333 6.48986 3.40357 6.32029 3.52859 6.19526C3.65362 6.07024 3.82319 6 4 6H8C8.17681 6 8.34638 6.07024 8.4714 6.19526C8.59643 6.32029 8.66666 6.48986 8.66666 6.66667C8.66666 6.84348 8.59643 7.01305 8.4714 7.13807C8.34638 7.2631 8.17681 7.33333 8 7.33333H4C3.82319 7.33333 3.65362 7.2631 3.52859 7.13807C3.40357 7.01305 3.33333 6.84348 3.33333 6.66667Z"
          fill="#4A4F55"
        />
      </svg>
    </IconBox>
  );
};

export default WalletSQIcon;
