import React, { useEffect, useState } from 'react';
import { theme } from '@hdcorner/ui-library';
import ModalChangeEmail from './ModalChangeEmail';
import AccountInfoFields from './AccountInfoFields';
import { Box } from '@mui/material';
import ModalChangePassword from './ModalChangePassword';
import ModalChangePhone from './ModalChangePhone';
import { useGetAuthUserQuery } from '../../authentication/queries/signInQueries';
import { useGetUserDataQuery } from '../queries/personalInfoQueries';
import { useTranslation } from 'react-i18next';
import ProfileTypographyHeading from './ProfileTypographyHeading';

const WebAccountInfo = () => {
  const { t } = useTranslation();

  const [modalEmail, setModalEmail] = useState<boolean>(false);
  const [modalPhone, setModalPhone] = useState<boolean>(false);
  const [modalPassword, setModalPassword] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState({
    email: '',
    phone: '',
  });

  const { data: userData } = useGetUserDataQuery();
  const { data: authUserData } = useGetAuthUserQuery();

  useEffect(() => {
    if (authUserData && userData) {
      const authUser = authUserData[0];
      const user = userData[0];

      setUserDetails(oldData => ({
        ...oldData,
        phone: user.phone || '',
        email: authUser.email || '',
      }));
    }
  }, [authUserData, userData]);

  const openModalEmail = () => {
    setModalEmail(true);
  };

  const openModalPassword = () => {
    setModalPassword(true);
  };

  const openModalPhone = () => {
    setModalPhone(true);
  };

  return (
    <Box flexDirection={'column'} flexGrow={'1'}>
      <ProfileTypographyHeading text={'profile.text.accountInfo'} />
      <Box
        gap={4}
        padding={3}
        display={'flex'}
        borderRadius={'8px'}
        flexDirection={'column'}
        boxSizing={'border-box'}
        bgcolor={theme.palette.highlight.main}
      >
        <AccountInfoFields
          type={'text'}
          onClick={openModalEmail}
          value={userDetails.email}
          button={t('buttons.changeEmail')}
          heading={t('personalDetails.email')}
        />
        <AccountInfoFields
          type={'text'}
          value={'••••••••••'}
          onClick={openModalPassword}
          button={t('buttons.changePass')}
          heading={t('profile.titles.pass')}
        />
        <AccountInfoFields
          type={'text'}
          onClick={openModalPhone}
          value={userDetails.phone}
          button={t('buttons.changePhone')}
          heading={t('profile.titles.phone')}
        />
      </Box>

      <ModalChangeEmail open={modalEmail} setOpen={setModalEmail} />
      <ModalChangePhone open={modalPhone} setOpen={setModalPhone} />
      <ModalChangePassword open={modalPassword} setOpen={setModalPassword} />
    </Box>
  );
};

export default WebAccountInfo;
