import { useMemo } from 'react';

const useGoalMetric = (value: string): 'lpa' | 'hdl' | 'ldl' | 'trg' | 'chol' => {
  return useMemo(() => {
    switch (value) {
      case 'LPA':
        return 'lpa';
      case 'CHOL':
        return 'chol';
      case 'HDL':
        return 'hdl';
      case 'LDL':
        return 'ldl';
      case 'TRG':
        return 'trg';
      default:
        return 'lpa';
    }
  }, [value]);
};

export default useGoalMetric;
