import React, { FC, useState } from 'react';
import {
  Box,
  BoxProps,
  Divider,
  DividerProps,
  IconButton,
  Menu,
  MenuItem,
  MenuItemProps,
  MenuProps,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import Button, { ButtonProps } from '@mui/material/Button';

const MenuMain = styled(Menu)<MenuProps>(({ theme }) => ({
  marginTop: theme.spacing(5),
  '.MuiPopover-paper': {
    borderRadius: 8,
    boxShadow: theme.shadows[1],
  },
  '.MuiMenu-list': {
    padding: 0,
  },
}));

const MoreVertIconMain = styled(MoreVertIcon)<SvgIconProps>(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const DividerMain = styled(Divider)<DividerProps>(({ theme }) => ({
  borderColor: theme.palette.highlight.main,
}));

type Options = {
  text: string;
  isDelete?: boolean;
  onClick: () => void;
  icon: React.ReactNode;
};

type DropdownTriggerType = {
  admin?: boolean;
  options: Options[];
  icon?: React.ReactNode;
  buttonProps?: ButtonProps;
  buttonOnClick?: () => void;
  startIcon?: React.ReactNode;
  menuItemProps?: MenuItemProps;
  optionTextProps?: TypographyProps;
  menuProps?: Omit<MenuProps, 'open'>;
};

const DropdownTrigger: FC<DropdownTriggerType & BoxProps> = ({
  icon,
  admin,
  options,
  startIcon,
  buttonOnClick,
  buttonProps,
  menuProps,
  menuItemProps,
  optionTextProps,
  ...containerProps
}) => {
  const theme = useTheme();

  const [anchorEL, setAnchorEL] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEL(event.currentTarget);
  };

  const handleButtonOnClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEL(event.currentTarget);
    buttonOnClick?.();
  };

  const handleCloseUserMenu = () => {
    setAnchorEL(null);
  };

  return (
    <Box {...containerProps}>
      {startIcon ? (
        <Button
          {...buttonProps}
          startIcon={startIcon}
          endIcon={icon ? icon : <MoreVertIconMain />}
          onClick={buttonOnClick ? handleButtonOnClick : handleOpenUserMenu}
        />
      ) : (
        <IconButton onClick={buttonOnClick ? handleButtonOnClick : handleOpenUserMenu}>
          {icon ? icon : <MoreVertIconMain />}
        </IconButton>
      )}
      <MenuMain
        keepMounted
        anchorEl={anchorEL}
        open={Boolean(anchorEL)}
        onClose={handleCloseUserMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        {...menuProps}
      >
        {options?.map((option, index) => (
          <Box key={`key${option}${index}`}>
            {!admin && index !== 0 ? <DividerMain variant={'middle'} /> : null}
            <MenuItem
              {...menuItemProps}
              onClick={option.onClick}
              sx={{ padding: theme.spacing(admin ? 1 : 2) }}
            >
              {option.icon}
              <Typography
                {...optionTextProps}
                textAlign="center"
                variant={'subtitle2'}
                sx={{
                  marginLeft: theme.spacing(1),
                  fontWeight: theme.typography.fontWeightRegular,
                  color: option.isDelete
                    ? theme.palette.kmColorsRed.main
                    : theme.palette.mode === 'dark'
                    ? theme.palette.kmColorsCoolGrey.main
                    : theme.palette.primary.main,
                }}
              >
                {option.text}
              </Typography>
            </MenuItem>
          </Box>
        ))}
      </MenuMain>
    </Box>
  );
};

export default DropdownTrigger;
