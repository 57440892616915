import React, { FC } from 'react';
import { Box, useTheme } from '@mui/material';
import ViewChooseProgram from './ViewChooseProgram';
import TargetWorkout from './TargetWorkout';

type Props = {
  handleChangeAvatar: () => void;
  onViewProgramClick: () => void;
};
const WorkoutsPanel: FC<Props> = ({ handleChangeAvatar, onViewProgramClick }) => {
  const theme = useTheme();

  return (
    <Box
      flexShrink={1}
      display={'flex'}
      borderRadius={'8px'}
      flexDirection={'column'}
      padding={theme.spacing(3)}
      bgcolor={theme.palette.secondary.light}
    >
      <Box display={'flex'} gap={3}>
        <Box flex={1}>
          {/*BODY PART SELECTOR*/}
          <TargetWorkout onWebClick={handleChangeAvatar} />
        </Box>
        <Box flex={2}>
          <ViewChooseProgram onWebClick={onViewProgramClick} />
        </Box>
      </Box>
    </Box>
  );
};

export default WorkoutsPanel;
