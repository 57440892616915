import { Box, Typography, useTheme } from '@mui/material';
import {
  Collapsible,
  DiabetesGoal,
  FeelsEmpty,
  GoalActionButtons,
  GoalDetailsCard,
  HBAC1Chart,
  InDepthDataCard,
  weekMonthYearCustom,
} from '@hdcorner/ui-library';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import moment from 'moment';
import useTimeframe from '../../hooks/useTimeframe';
import {
  useGetHbac1CurrentMeasurementQuery,
  useGetHbac1LogsQuery,
  useGetHbac1OverallDataQuery,
  useGetHbac1StartingMeasurementQuery,
  useGetHbacGoalQuery,
  useGetHcac1GraphDataQuery,
} from './queries/diabetesQueries';
import useGoalValue from './hooks/useGoalValue';
import useOverallData from './hooks/useOverallData';
import useCurrentMeasurement from './hooks/useCurrentMeasurement';
import useHbac1GoalData from './hooks/useHbac1GoalData';
import usePrepareGraph from './hooks/usePrepareGraph';
import useAlert from '../../hooks/useAlert';
import InDepthHeading from '../fitness/components/InDepthHeading';
import InfiniteScrollList from '../../components/InfiniteScrollList';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../../utils/useConvertJSON';

type Props = {
  handleAddHbac1Goal: (current: number | undefined) => void;
  handleRemoveHbac1Goal: (goal: DiabetesGoal | undefined) => void;
  handleEditHbac1Goal: (
    goal: DiabetesGoal | undefined,
    current: number | undefined,
  ) => void;
};
const Hbac1WebSection: FC<Props> = ({
  handleAddHbac1Goal,
  handleEditHbac1Goal,
  handleRemoveHbac1Goal,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { presentError } = useAlert();

  const { dateFilter, customDateFilter } = useAppSelector(state => state.layout);

  const [startingDate, setStartingDate] = useState<string>('');
  const [hba1cParams, setHba1cParams] = useState({
    skip: 0,
    limit: 8,
  });

  const timeframe = useTimeframe(
    dateFilter,
    customDateFilter?.start,
    customDateFilter?.end,
  );

  const { data: hbac1Goal, error: goalError } = useGetHbacGoalQuery();
  const { data: overallData, error: overallError } = useGetHbac1OverallDataQuery({
    ...timeframe,
  });
  const { data: hbacGraphData, error: graphError } = useGetHcac1GraphDataQuery({
    ...timeframe,
  });
  const { data: hbac1CurrentMeasurement, error: currentError } =
    useGetHbac1CurrentMeasurementQuery();
  const { data: hbac1Logs, isFetching: hbac1LogsFetching } = useGetHbac1LogsQuery({
    params: { ...hba1cParams, ...timeframe },
  });
  const { data: startingMeasurements, error: startingError } =
    useGetHbac1StartingMeasurementQuery(
      { startDate: startingDate },
      { skip: !startingDate },
    );

  const prepareGoalValue = useGoalValue(hbac1Goal);
  const prepareOverallData = useOverallData(overallData);
  const prepareGraphData = usePrepareGraph(hbacGraphData);
  const prepareCurrentMeasurement = useCurrentMeasurement(hbac1CurrentMeasurement);
  const prepareStartingMeasurement = useCurrentMeasurement(startingMeasurements);

  const prepareGoalDataValues = useHbac1GoalData(
    prepareGoalValue,
    hbac1Goal,
    prepareStartingMeasurement,
    prepareCurrentMeasurement,
  );

  useEffect(() => {
    if (hbac1Goal && hbac1Goal.length > 0) {
      const goal = hbac1Goal[0];
      const start = goal.startDate;
      setStartingDate(start);
    }
  }, [hbac1Goal]);

  useEffect(() => {
    if (goalError || currentError || startingError) {
      presentError(t('errors.diabetes.errorsHba1c.fetchGoal'));
    }
    if (overallError) {
      presentError(t('errors.diabetes.errorsHba1c.fetchOverall'));
    }
    if (graphError) {
      presentError(t('errors.diabetes.errorsHba1c.fetchGraph'));
    }
  }, [goalError, currentError, startingError, overallError, graphError]);

  const prepareData = useMemo(() => {
    if (!hbac1Logs || hbac1Logs.documents.length === 0) return [];

    return hbac1Logs.documents.map(item => {
      return {
        title: item.measurement.toString(),
        titleSub: t('measurements.mgdl'),
        data: {
          HbA1C: { value: `${item.measurement.toString()}%` },
          [t('inDepth.timeOfCalc')]: { value: moment(item.logDate).format('h:mm A') },
          [t('inDepth.dateOfCalc')]: {
            value: moment(item.logDate).format('MMM Do, YYYY'),
          },
        },
      };
    });
  }, [hbac1Logs]);

  const prepareOverallDataValues = useMemo(() => {
    if (!overallData)
      return [
        {
          name: t('inDepth.all'),
          min: t('general.labels.nA'),
          max: t('general.labels.nA'),
          avg: t('general.labels.nA'),
        },
      ];
    const data = overallData;
    return [
      {
        name: t('inDepth.all'),
        min: data.min ? data.min.toFixed() : t('general.labels.nA'),
        max: data.max ? data.max.toFixed() : t('general.labels.nA'),
        avg: data.avg ? data.avg.toFixed() : t('general.labels.nA'),
      },
    ];
  }, [overallData]);

  return (
    <Box display={'flex'} flexDirection={'column'} flex={1} gap={theme.spacing(3)}>
      <Box display={'flex'} flexDirection={'column'} flex={1}>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightMedium,
          }}
          variant={'subtitle1'}
        >
          {t('headingsTitles.hba1c')}
        </Typography>
        <HBAC1Chart
          chartData={prepareGraphData}
          overallData={prepareOverallData}
          latestMeasurement={prepareCurrentMeasurement}
          headings={useConvertJSON(weekMonthYearCustom)}
        />
      </Box>
      <Box display={'flex'} flexDirection={'column'} gap={1}>
        <InDepthHeading module={'hbac1'} />
        <InDepthDataCard
          measurement={'%'}
          rows={prepareOverallDataValues}
          headings={[
            `${t('measurements.min')}`,
            `${t('measurements.max')}`,
            `${t('measurements.avg')}`,
          ]}
        />
        <InfiniteScrollList
          skip={hba1cParams.skip}
          limit={hba1cParams.limit}
          loading={hbac1LogsFetching}
          totalCount={hbac1Logs?.count}
          count={hbac1Logs?.documents.length}
          onLoadMore={(skip, limit) => {
            setHba1cParams({ skip, limit });
          }}
        >
          {prepareData.map((data, index) => (
            <Box key={index}>
              <Collapsible
                key={index}
                title={data.title}
                data={data.data}
                titleSub={data.titleSub}
              />
            </Box>
          ))}
        </InfiniteScrollList>
        {prepareData.length === 0 && <FeelsEmpty />}
        <Box
          flex={1}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          marginTop={theme.spacing(3)}
          marginBottom={theme.spacing(1)}
        >
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontWeight: theme.typography.fontWeightMedium,
            }}
            variant={'subtitle1'}
          >
            {t('headingsTitles.goal')}
          </Typography>
          <GoalActionButtons
            handleAdd={() =>
              handleAddHbac1Goal(
                prepareCurrentMeasurement ? prepareCurrentMeasurement : undefined,
              )
            }
            handleEdit={() =>
              handleEditHbac1Goal(
                hbac1Goal ? hbac1Goal[0] : undefined,
                prepareCurrentMeasurement ? prepareCurrentMeasurement : undefined,
              )
            }
            handleRemove={() =>
              handleRemoveHbac1Goal(hbac1Goal ? hbac1Goal[0] : undefined)
            }
            type={hbac1Goal && hbac1Goal.length > 0 ? 'edit' : 'add'}
          />
        </Box>
        <Box
          borderRadius={'8px'}
          padding={theme.spacing(1)}
          bgcolor={theme.palette.secondary.light}
        >
          {prepareGoalDataValues?.map((item, index) => (
            <Box key={`${item}${index}`} mt={index === 1 ? 2 : 0}>
              <GoalDetailsCard goal={item} />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Hbac1WebSection;
