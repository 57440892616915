import * as React from 'react';
import { SVGProps } from 'react';

const WeightIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ? props.width : 21}
      height={props.height ? props.height : 21}
      viewBox={props.viewBox ? props.viewBox : '0 0 21 21'}
      {...props}
    >
      <path
        d="M10.5022 6.19226C10.6871 5.7624 10.9039 5.33254 10.9931 5.2314C11.1206 5.07968 11.1907 5.04808 11.4393 5.04808C12.0258 5.04808 12.1979 5.53483 11.8601 6.24916C11.7708 6.4388 11.6433 6.72327 11.5796 6.8813C11.5318 6.99981 11.4302 7.23563 11.3286 7.47147L11.229 7.7031C10.6737 8.97321 10.6743 8.97383 10.7733 9.08404L10.7764 9.0875C10.9485 9.27714 11.0823 9.68172 11.0887 10.042C11.0951 11.0092 10.1771 11.7678 9.24002 11.5718C9.04241 11.5339 8.76829 11.4201 8.63442 11.3316C8.32843 11.1167 8.03519 10.6805 7.97781 10.3518C7.92681 10.0484 7.98419 9.57425 8.10531 9.34668C8.3093 8.95475 8.90853 8.52489 9.2464 8.52489C9.47589 8.52489 9.54602 8.42374 9.87113 7.66517C9.97296 7.43214 10.1184 7.09107 10.2581 6.7635L10.2585 6.76244C10.3459 6.55745 10.4311 6.35756 10.5022 6.19226Z"
        fill="#E93C16"
      />
      <path
        d="M5.5 5.5C5.77614 5.5 6 5.27614 6 5C6 4.72386 5.77614 4.5 5.5 4.5C5.22386 4.5 5 4.72386 5 5C5 5.27614 5.22386 5.5 5.5 5.5Z"
        fill={props.fill ? props.fill : '#E93C16'}
      />
      <path
        d="M8 3C8 3.27614 7.77614 3.5 7.5 3.5C7.22386 3.5 7 3.27614 7 3C7 2.72386 7.22386 2.5 7.5 2.5C7.77614 2.5 8 2.72386 8 3Z"
        fill={props.fill ? props.fill : '#E93C16'}
      />
      <path
        d="M14.5 5.5C14.7761 5.5 15 5.27614 15 5C15 4.72386 14.7761 4.5 14.5 4.5C14.2239 4.5 14 4.72386 14 5C14 5.27614 14.2239 5.5 14.5 5.5Z"
        fill={props.fill ? props.fill : '#E93C16'}
      />
      <path
        d="M13 3C13 3.27614 12.7761 3.5 12.5 3.5C12.2239 3.5 12 3.27614 12 3C12 2.72386 12.2239 2.5 12.5 2.5C12.7761 2.5 13 2.72386 13 3Z"
        fill={props.fill ? props.fill : '#E93C16'}
      />
      <path
        d="M10 2.8999C10.2761 2.8999 10.5 2.67604 10.5 2.3999C10.5 2.12376 10.2761 1.8999 10 1.8999C9.72386 1.8999 9.5 2.12376 9.5 2.3999C9.5 2.67604 9.72386 2.8999 10 2.8999Z"
        fill={props.fill ? props.fill : '#E93C16'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0C12.5154 0 14.7272 1.2865 15.9998 3.23077H17.1429C18.7208 3.23077 20 4.49643 20 6.05769V18.1731C20 19.7343 18.7208 21 17.1429 21H2.85714C1.27919 21 0 19.7343 0 18.1731V6.05769C0 4.49643 1.27919 3.23077 2.85714 3.23077H4.00024C5.27283 1.2865 7.48457 0 10 0ZM10 12.5192C13.0432 12.5192 15.5102 10.0783 15.5102 7.06731C15.5102 4.05629 13.0432 1.61538 10 1.61538C6.9568 1.61538 4.4898 4.05629 4.4898 7.06731C4.4898 10.0783 6.9568 12.5192 10 12.5192ZM10 14.1346C13.9449 14.1346 17.1429 10.9705 17.1429 7.06731C17.1429 6.29137 17.0165 5.54463 16.783 4.84615H17.1429C17.8191 4.84615 18.3673 5.38858 18.3673 6.05769V18.1731C18.3673 18.8422 17.8191 19.3846 17.1429 19.3846H2.85714C2.18088 19.3846 1.63265 18.8422 1.63265 18.1731V6.05769C1.63265 5.38858 2.18088 4.84615 2.85714 4.84615H3.21704C2.98353 5.54463 2.85714 6.29137 2.85714 7.06731C2.85714 10.9705 6.05511 14.1346 10 14.1346Z"
        fill={props.fill ? props.fill : '#E93C16'}
      />
    </svg>
  );
};

export default WeightIcon;
