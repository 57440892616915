const fileCategories = [
  { value: 'diagnostic_tests', label: 'constants.fileTypes.diagnostic' },
  { value: 'biochemical_tests', label: 'constants.fileTypes.bioChem' },
  { value: 'microbiological_tests', label: 'constants.fileTypes.microBio' },
  { value: 'hormonal_tests', label: 'constants.fileTypes.hormone' },
  { value: 'immunological_tests', label: 'constants.fileTypes.immuno' },
  { value: 'gastroenterological_tests', label: 'constants.fileTypes.gastro' },
  { value: 'gynecological_tests', label: 'constants.fileTypes.gyno' },
  { value: 'urine_tests', label: 'constants.fileTypes.urine' },
  { value: 'cultures', label: 'constants.fileTypes.cultures' },
  { value: 'cardiological_tests', label: 'constants.fileTypes.cardio' },
  { value: 'radiographs_xray', label: 'constants.fileTypes.xray' },
  { value: 'ct_scans', label: 'constants.fileTypes.ct' },
  { value: 'mri_scans', label: 'constants.fileTypes.mri' },
  { value: 'mammograms', label: 'constants.fileTypes.mammo' },
  { value: 'other', label: 'constants.fileTypes.other' },
];

export { fileCategories };
