import React, { FC } from 'react';
import {
  Avatar,
  AvatarProps,
  Box,
  BoxProps,
  Card,
  CardActionArea,
  CardActionAreaProps,
  CardContent,
  CardContentProps,
  CardProps,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';

const CardMain = styled(Card)<CardProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.secondary.light,
  borderRadius: 8,
  height: '40px',
  width: '100%',
}));

const CardActionAreaMain = styled(CardActionArea)<CardActionAreaProps>(() => ({
  height: '100%',
}));

const CardContentMain = styled(CardContent)<CardContentProps>(({ theme }) => ({
  flex: 1,
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 0),
}));

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightRegular,
}));

const AvatarTitleContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: theme.spacing(1),
}));

const BpIcon = styled('span')(({ theme }) => ({
  width: '16px',
  height: '16px',
  borderRadius: '50%',
  border: `2px solid ${theme.palette.kmColorsCoolGrey.main}`,
}));

const BpCheckedIcon = styled(Box)<BoxProps>(({ theme }) => ({
  width: '20px',
  height: '20px',
  borderRadius: '50%',
  boxShadow: theme.shadows[0],
  backgroundColor: theme.palette.kmColorsRed.main,
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: '20px',
    height: '20px',
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
}));

const AvatarMain = styled(Avatar)<AvatarProps>(({ theme }) => ({
  border: `1px solid ${theme.palette.secondary.main}`,
  borderRadius: 8,
  width: 24,
  height: 24,
  marginRight: theme.spacing(1),
}));

type GroupParticipantCardType = {
  checked: boolean;
  healthProfKey: string;
  healthProfName: string;
  healthProfImage?: string;
  cardContentProps?: CardContentProps;
  cardActionAreaProps?: CardActionAreaProps;
  handleSelected: (healthProfID: string) => void;
};
const GroupParticipantCard: FC<GroupParticipantCardType & CardProps> = ({
  healthProfName,
  healthProfImage,
  healthProfKey,
  checked = false,
  handleSelected,
  cardActionAreaProps,
  cardContentProps,
  ...cardProps
}) => {
  return (
    <CardMain raised={false} {...cardProps}>
      <CardActionAreaMain
        onClick={() => handleSelected(healthProfKey)}
        {...cardActionAreaProps}
      >
        <CardContentMain {...cardContentProps}>
          <AvatarTitleContainer>
            <AvatarMain src={healthProfImage} />
            <Title variant={'subtitle2'}>{healthProfName}</Title>
          </AvatarTitleContainer>
          {checked ? <BpCheckedIcon /> : <BpIcon />}
        </CardContentMain>
      </CardActionAreaMain>
    </CardMain>
  );
};

export default GroupParticipantCard;
