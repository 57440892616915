import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useIonRouter } from '@ionic/react';
import {
  ClickNavCardReminders,
  ComponentsLayout,
  HorizontalCalendar,
  ModalMobile,
  NoContentCloudIcon,
  theme,
} from '@hdcorner/ui-library';
import moment from 'moment';
import NotificationAddIcon from '../../assets/icons/NotificationAddIcon';
import PageLayout from '../../components/PageLayout';
import ModalSetReminderComponent from '../../components/ModalSetReminderComponent';
import {
  useAddNewReminderMutation,
  useGetRemindersQuery,
} from './queries/remindersQueries';
import useQuery from '../../hooks/useQuery';
import useAlert from '../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

const Reminders = () => {
  const query = useQuery();
  const router = useIonRouter();
  const { t } = useTranslation();

  const { presentError, presentSuccess } = useAlert();

  const [reminderModalOpen, setReminderModalOpen] = useState<boolean>(false);
  const [monthlyCalendarOpen, setMonthlyCalendarOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date>(moment().utc(true).toDate());

  const medication = query.get('medication');

  const [params, setParams] = useState<{
    skip: number;
    limit: number;
    endDate: string;
    category?: string;
    startDate: string;
  }>({
    skip: 0,
    limit: 1000,
    category: medication ? 'medication' : undefined,
    endDate: moment().utc(true).endOf('day').toDate().toISOString(),
    startDate: moment().utc(true).startOf('day').toDate().toISOString(),
  });

  const { error, data: remindersRes } = useGetRemindersQuery({
    params: params,
  });

  useEffect(() => {
    if (error) presentError(t('errors.reminders.errorFetch'));
  }, [error]);

  const [addNewReminder] = useAddNewReminderMutation();

  const handleChangeDate = (date: Date) => {
    setSelectedDate(date);

    const newDate = moment(date).utc(true);

    const endDate = newDate.endOf('day').toISOString();
    const startDate = newDate.startOf('day').toISOString();

    setParams((prevState: any) => ({
      ...prevState,
      endDate,
      startDate,
    }));
  };

  const handleReminderClick = (reminder: string) => {
    router.push(`/dashboard/reminderInfo/${reminder}`);
  };

  const handleCreateReminder = (data: any) => {
    const newDate = moment(data.date);
    const splitTime = data.time.split(':');
    newDate.hour(parseInt(splitTime[0]));
    newDate.minutes(parseInt(splitTime[1]));
    newDate.seconds(0);
    newDate.milliseconds(0);

    addNewReminder({
      info: data.info,
      title: data.title,
      allDay: data.allDay,
      category: data.category,
      reminderDateTime: newDate.toISOString(),
    })
      .unwrap()
      .then(() => presentSuccess(t('errors.reminders.successAdd')))
      .catch(() => {
        presentError(t('errors.reminders.errorAdd'));
      })
      .finally(() => {
        setReminderModalOpen(false);
      });
  };

  return (
    <PageLayout
      fabClick={() => setReminderModalOpen(true)}
      defaultHref={medication ? '/dashboard/medication' : '/dashboard/home'}
      headerTitle={
        medication ? t('reminders.titles.medReminders') : t('reminders.titles.reminders')
      }
      fabIcon={
        <NotificationAddIcon width={14} height={14} fill={theme.palette.secondary.main} />
      }
    >
      <ComponentsLayout>
        <Box mb={1}>
          <HorizontalCalendar
            selectFutureDates
            selectedDate={selectedDate}
            openMonthly={monthlyCalendarOpen}
            setOpenMonthly={setMonthlyCalendarOpen}
            handleChange={(date: any) => handleChangeDate(date)}
          />
        </Box>
        {remindersRes && remindersRes.documents.length > 0 ? (
          <>
            {remindersRes.documents.map(reminder => (
              <ClickNavCardReminders
                key={reminder._id}
                body={reminder.info}
                title={reminder.title}
                category={reminder.category}
                onClickHandler={() => handleReminderClick(reminder._id)}
                time={
                  reminder.allDay
                    ? 'All Day'
                    : moment(reminder.reminderDateTime).utc(true).format('h:mm A')
                }
              />
            ))}
          </>
        ) : (
          <Box textAlign={'center'}>
            <NoContentCloudIcon />
          </Box>
        )}
      </ComponentsLayout>
      <ModalMobile open={reminderModalOpen} setOpen={setReminderModalOpen}>
        <ModalSetReminderComponent
          handleSaveModal={handleCreateReminder}
          preset={medication ? 'medication' : undefined}
        />
      </ModalMobile>
    </PageLayout>
  );
};

export default Reminders;
