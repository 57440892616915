import * as React from 'react';
import { SVGProps } from 'react';

const TooltipHomeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={19} height={21} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.5 19.5V6.9a1 1 0 0 0-.44-.829l-8-5.4a1 1 0 0 0-1.12 0l-8 5.4A1 1 0 0 0 .5 6.9v12.6a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1Zm-2-1h-14V7.432l7-4.725 7 4.725V18.5Z"
      fill="#EE1D23"
    />
  </svg>
);

export default TooltipHomeIcon;
