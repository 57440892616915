import React from 'react';
import HeroImage from '../../../assets/illustrations/HeroImagePNG.png';
import { Box, useMediaQuery } from '@mui/material';
import ResetPasswordPanel from './components/ResetPasswordPanel';
import { ComponentsLayout, theme } from '@hdcorner/ui-library';
import PageLayout from '../../../components/PageLayout';
import useQuery from '../../../hooks/useQuery';

const ResetPassword = () => {
  const searchParams = useQuery();
  const web = useMediaQuery(theme.breakpoints.up('md'));
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const resetToken = searchParams.get('reset_token') || '';

  return (
    <PageLayout backgroundWhite>
      <Box hidden={mobile} height={'100%'} display={'flex'}>
        <Box
          height={'100%'}
          width={'560px'}
          textAlign={'center'}
          boxSizing={'border-box'}
          bgcolor={theme.palette.secondary.light}
          margin={theme.spacing(0, 'auto')}
          padding={theme.spacing(7.25, 13, 3.5, 13)}
        >
          <ResetPasswordPanel web resetToken={resetToken} />
        </Box>
        <Box
          flexGrow={'1'}
          sx={{
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${HeroImage})`,
          }}
        ></Box>
      </Box>

      <Box hidden={web} height={'100%'} textAlign={'center'}>
        <ComponentsLayout height={'100%'}>
          <ResetPasswordPanel web={false} resetToken={resetToken} />
        </ComponentsLayout>
      </Box>
    </PageLayout>
  );
};

export default ResetPassword;
