import api from '../../redux/api';

const pushNotificationsQueries = api.injectEndpoints({
  endpoints: build => ({
    setPushNotificationsToken: build.mutation<void, { token: string; platform: string }>({
      query: ({ token, platform }) => ({
        method: 'POST',
        body: { token, platform },
        url: '/pushNotifications/token',
      }),
    }),
  }),
});

export const { useSetPushNotificationsTokenMutation } = pushNotificationsQueries;
export default pushNotificationsQueries;
