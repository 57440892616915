import * as React from 'react';
import { SVGProps } from 'react';

const DeleteUser = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.666545 12.9999H5.99988C6.17669 12.9999 6.34626 12.9297 6.47128 12.8047C6.59631 12.6797 6.66654 12.5101 6.66654 12.3333C6.66654 12.1565 6.59631 11.9869 6.47128 11.8619C6.34626 11.7369 6.17669 11.6666 5.99988 11.6666H1.38054C1.54232 10.5565 2.09797 9.54159 2.94604 8.80722C3.79412 8.07285 4.87804 7.66801 5.99988 7.66661C6.78486 7.67342 7.54749 7.40538 8.15559 6.90893C8.7637 6.41249 9.17895 5.71894 9.32939 4.94847C9.47982 4.17801 9.35596 3.37919 8.97926 2.69046C8.60256 2.00173 7.99677 1.4665 7.26688 1.17753C6.53699 0.888556 5.729 0.864052 4.98294 1.10826C4.23688 1.35248 3.59977 1.85001 3.18203 2.51465C2.76429 3.17928 2.59224 3.96913 2.69571 4.74729C2.79918 5.52546 3.17164 6.2429 3.74854 6.77528C2.64202 7.22416 1.69439 7.99267 1.02668 8.98265C0.358974 9.97264 0.00148883 11.1392 -0.00012207 12.3333C-0.00012207 12.5101 0.0701158 12.6797 0.19514 12.8047C0.320164 12.9297 0.489734 12.9999 0.666545 12.9999ZM5.99988 2.33328C6.39544 2.33328 6.78212 2.45058 7.11102 2.67034C7.43992 2.8901 7.69626 3.20246 7.84764 3.56791C7.99901 3.93337 8.03862 4.3355 7.96145 4.72346C7.88428 5.11142 7.6938 5.46779 7.41409 5.74749C7.13439 6.0272 6.77802 6.21768 6.39006 6.29485C6.0021 6.37202 5.59996 6.33241 5.23451 6.18104C4.86906 6.02966 4.5567 5.77332 4.33694 5.44442C4.11718 5.11552 3.99988 4.72884 3.99988 4.33328C3.99988 3.80285 4.21059 3.29414 4.58566 2.91907C4.96074 2.54399 5.46944 2.33328 5.99988 2.33328V2.33328ZM14.6665 9.66661C14.6665 9.84342 14.5963 10.013 14.4713 10.138C14.3463 10.263 14.1767 10.3333 13.9999 10.3333H9.33321C9.1564 10.3333 8.98683 10.263 8.86181 10.138C8.73678 10.013 8.66654 9.84342 8.66654 9.66661C8.66654 9.4898 8.73678 9.32023 8.86181 9.19521C8.98683 9.07018 9.1564 8.99995 9.33321 8.99995H13.9999C14.1767 8.99995 14.3463 9.07018 14.4713 9.19521C14.5963 9.32023 14.6665 9.4898 14.6665 9.66661Z"
        fill="#222222"
      />
    </svg>
  );
};

export default DeleteUser;
