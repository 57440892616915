import React, { SVGProps } from 'react';

const CompassIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.586 6.09L9.08601 8.59C8.86353 8.68951 8.68552 8.86752 8.58601 9.09L6.086 14.59C6.00241 14.7755 5.97742 14.9822 6.01437 15.1823C6.05132 15.3824 6.14845 15.5665 6.29279 15.71C6.43712 15.8535 6.62179 15.9495 6.82214 15.9852C7.02248 16.0209 7.22896 15.9947 7.414 15.91L12.914 13.41C13.1365 13.3105 13.3145 13.1325 13.414 12.91L15.914 7.41C15.9976 7.22445 16.0226 7.01782 15.9856 6.81769C15.9487 6.61757 15.8516 6.43348 15.7072 6.29001C15.5629 6.14654 15.3782 6.05053 15.1779 6.01479C14.9775 5.97905 14.771 6.00529 14.586 6.09ZM11.745 11.745L9.014 12.986L10.255 10.255L12.986 9.014L11.745 11.745ZM11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.78049 0.83733 6.79048C0.00476617 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3166 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C21.9966 8.08367 20.8365 5.28778 18.7744 3.22563C16.7122 1.16347 13.9163 0.00344047 11 0ZM11 20C9.21997 20 7.47992 19.4722 5.99987 18.4832C4.51983 17.4943 3.36628 16.0887 2.68509 14.4442C2.0039 12.7996 1.82567 10.99 2.17294 9.24419C2.5202 7.49836 3.37737 5.89471 4.63604 4.63604C5.89472 3.37737 7.49836 2.5202 9.24419 2.17293C10.99 1.82567 12.7996 2.0039 14.4442 2.68508C16.0887 3.36627 17.4943 4.51983 18.4832 5.99987C19.4722 7.47991 20 9.21997 20 11C19.9974 13.3861 19.0483 15.6738 17.361 17.361C15.6738 19.0483 13.3861 19.9974 11 20Z"
        fill="#EE1D23"
      />
    </svg>
  );
};

export default CompassIcon;
