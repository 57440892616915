import React, { FC } from 'react';
import {
  Box,
  BoxProps,
  styled,
  Theme,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import ExerciseIcon from '../../assets/ExerciseIcon';
import ArrowRight from '../../assets/ArrowRight';
import { useTranslation } from 'react-i18next';

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  color: theme.palette.primary.main,
  marginLeft: theme.spacing(1),
}));

const Text = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  color: theme.palette.primary.main,
  marginRight: theme.spacing(1.5),
}));

const Container = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'space-between',
  height: 56,
  borderRadius: '8px',
  padding: theme.spacing(0, 2),
  background: theme.palette.highlight.main,
  cursor: 'pointer',
}));

type Props = {
  title: string;
  onClick: () => void;
};
const WorkoutCard: FC<Props & BoxProps> = ({ title, onClick, ...boxProps }) => {
  const theme: Theme = useTheme<Theme>();
  const { t } = useTranslation();

  return (
    <Container {...boxProps} onClick={onClick}>
      <Box display={'flex'} alignItems={'center'}>
        <ExerciseIcon color={theme.palette.primary.main} />
        <Title variant={'subtitle2'}>{title}</Title>
      </Box>
      <Box display={'flex'} alignItems={'center'}>
        <Text variant={'body1'}>{t('fitness.workouts.labels.viewProgram')}</Text>
        <ArrowRight />
      </Box>
    </Container>
  );
};

export default WorkoutCard;
