import * as React from 'react';
import { SVGProps } from 'react';

const AppBarExpandMoreDarkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={16}
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_15_1410)">
      <rect width={22} height={16} rx={4} fill="#4A4F55" />
      <rect x={7} y={1} width={14} height={14} rx={3} fill="#222222" />
      <path
        d="M16 5L12 8L16 11"
        stroke="#F2F1F3"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x={7} y={1} width={14} height={14} rx={3} stroke="#F2F1F3" strokeWidth={2} />
    </g>
    <rect x={1} y={1} width={20} height={14} rx={3} stroke="#F2F1F3" strokeWidth={2} />
    <defs>
      <clipPath id="clip0_15_1410">
        <rect width={22} height={16} rx={4} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AppBarExpandMoreDarkIcon;
