import api from '../../../../redux/api';
import { DietPlan } from '@hdcorner/ui-library';

const dietPlanQueries = api.enhanceEndpoints({ addTagTypes: [] }).injectEndpoints({
  endpoints: build => ({
    getDietPlan: build.query<DietPlan[], void>({
      query: () => ({
        method: 'GET',
        url: '/database/function/getDietPlan',
        params: {
          populate: [
            'dietPlan',
            'dietPlan.attachedFile',
            'dietPlan.attachedFile.en',
            'dietPlan.attachedFile.el',
          ],
        },
      }),
      providesTags: ['DietPlan'],
    }),
    createDietPlan: build.mutation<
      void,
      { calculatedCalories: number; dietType: string }
    >({
      query: ({ calculatedCalories, dietType }) => ({
        method: 'POST',
        url: '/database/function/setCalculatedCalories',
        body: { calories: calculatedCalories, dietType },
      }),
      invalidatesTags: ['DietPlan', 'Calories'],
    }),
    editDietPlan: build.mutation<void, { calculatedCalories: number; dietType: string }>({
      query: ({ calculatedCalories, dietType }) => ({
        method: 'PATCH',
        body: { calories: calculatedCalories, dietType },
        url: `/database/function/changeCalculatedCalories`,
      }),
      invalidatesTags: ['DietPlan', 'Calories'],
    }),
    assignDietPlan: build.mutation<void, void>({
      query: () => ({
        method: 'PATCH',
        url: `/hdCornerService/user/diet-plan`,
      }),
      invalidatesTags: ['DietPlan', 'Calories'],
    }),
  }),
});

export const {
  useGetDietPlanQuery,
  useEditDietPlanMutation,
  useAssignDietPlanMutation,
  useCreateDietPlanMutation,
} = dietPlanQueries;

export default dietPlanQueries;
