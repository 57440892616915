import ReactApexChart from 'react-apexcharts';
import React, { useMemo } from 'react';
import { ApexOptions } from 'apexcharts';
import { useTheme } from '@mui/material';

type ScatterChartProps = {
  colorList?: string[];
  width?: string | number;
  height?: string | number;
  chartData?: { name: string; data: any[] }[];
  axisTitles?: { xAxis: string; yAxis: string };
};
const ScatterChart = ({
  colorList,
  chartData = [],
  width = '100%',
  height = '170px',
  axisTitles,
}: ScatterChartProps) => {
  const theme = useTheme();

  const options = useMemo<ApexOptions>(
    () => ({
      legend: {
        show: false,
      },
      chart: {
        type: 'scatter',
        toolbar: { show: false },
        zoom: { enabled: false },
        fontFamily: theme.typography.fontFamily,
      },
      annotations: {
        xaxis: [
          {
            x: 125,
            borderWidth: 2,
            strokeDashArray: 0,
            borderColor: 'black',
          },
        ],
        yaxis: [
          {
            y: 100,
            borderWidth: 2,
            borderColor: 'black',
            strokeDashArray: 0,
          },
        ],
      },
      colors: colorList,
      plotOptions: {
        bar: {
          borderRadius: 2,
          dataLabels: { position: 'top' },
        },
      },
      xaxis: {
        min: 0,
        max: 250,
        tickAmount: 5,
        axisTicks: { show: false },
        title: {
          text: axisTitles?.xAxis,
          style: {
            fontWeight: 700,
          },
        },
      },
      yaxis: {
        min: 0,
        max: 200,
        tickAmount: 5,
        title: {
          text: axisTitles?.yAxis,
          style: {
            fontWeight: 700,
          },
        },
      },
      tooltip: {
        enabled: false,
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          filter: {
            type: 'none',
          },
        },
      },
    }),
    [theme],
  );

  return (
    <ReactApexChart
      width={width}
      height={height}
      type={'scatter'}
      options={options}
      series={chartData}
    />
  );
};

export default ScatterChart;
