import * as React from 'react';
import { SVGProps } from 'react';

const SliderThumbIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="path-1-inside-1_1286_127494" fill="white">
      <path d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10Z" />
      <path d="M12 10C12 11.1046 11.1046 12 10 12C8.89543 12 8 11.1046 8 10C8 8.89543 8.89543 8 10 8C11.1046 8 12 8.89543 12 10Z" />
    </mask>
    <path
      d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10Z"
      fill="white"
    />
    <path
      d="M12 10C12 11.1046 11.1046 12 10 12C8.89543 12 8 11.1046 8 10C8 8.89543 8.89543 8 10 8C11.1046 8 12 8.89543 12 10Z"
      fill="#DCDEE0"
    />
    <path
      d="M17 10C17 13.866 13.866 17 10 17V23C17.1797 23 23 17.1797 23 10H17ZM10 17C6.13401 17 3 13.866 3 10H-3C-3 17.1797 2.8203 23 10 23V17ZM3 10C3 6.13401 6.13401 3 10 3V-3C2.8203 -3 -3 2.8203 -3 10H3ZM10 3C13.866 3 17 6.13401 17 10H23C23 2.8203 17.1797 -3 10 -3V3ZM9 10C9 9.44772 9.44772 9 10 9V15C12.7614 15 15 12.7614 15 10H9ZM10 9C10.5523 9 11 9.44772 11 10H5C5 12.7614 7.23858 15 10 15V9ZM11 10C11 10.5523 10.5523 11 10 11V5C7.23858 5 5 7.23858 5 10H11ZM10 11C9.44772 11 9 10.5523 9 10H15C15 7.23858 12.7614 5 10 5V11Z"
      fill="#DCDEE0"
      mask="url(#path-1-inside-1_1286_127494)"
    />
  </svg>
);

export default SliderThumbIcon;
