import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { ArrowLeft, CustomButton, theme } from '@hdcorner/ui-library';
import { useTranslation } from 'react-i18next';

type QuestionnaireHeadingProps = {
  web: boolean;
  count: number;
  decrementCount?: () => void;
};
const QuestionnaireHeading: FC<QuestionnaireHeadingProps> = ({
  web,
  count,
  decrementCount,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {count > 0 && (
        <Box
          left={'0'}
          top={'75px'}
          position={'absolute'}
          display={web ? 'none' : 'flex'}
        >
          <CustomButton variant={'text'} onClick={decrementCount}>
            <ArrowLeft />
          </CustomButton>
        </Box>
      )}
      <Typography variant={'h1'} sx={{ marginBottom: theme.spacing(3) }}>
        {t('authentication.onboarding.greeting')}
      </Typography>
    </>
  );
};

export default QuestionnaireHeading;
