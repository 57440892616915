import { Box, Typography, useTheme } from '@mui/material';
import React, { FC } from 'react';
import ComponentsLayout from './ComponentsLayout';
import ToggleCard from './cards/ToggleCard';
import { useTranslation } from 'react-i18next';

type LanguageProps = {
  selectedLanguage: string;
  languages: { label: string; value: string }[];
  setLanguage: (newValue: string) => void;
};
const LanguagePanelMobile: FC<LanguageProps> = ({
  languages,
  setLanguage,
  selectedLanguage,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <ComponentsLayout>
      <Typography
        align={'left'}
        variant={'subtitle1'}
        sx={{
          marginTop: theme.spacing(3),
          fontWeight: theme.typography.fontWeightMedium,
        }}
      >
        {t('profile.titles.language')}
      </Typography>
      <Box margin={theme.spacing(3, 'auto', 3, 'auto')}>
        {languages.map((lang, index) => (
          <ToggleCard
            value={lang.value}
            label={lang.label}
            textTransform={'none'}
            setSelected={setLanguage}
            key={`${index}-${lang.value}`}
            selected={selectedLanguage === lang.value}
            backgroundColor={theme.palette.secondary.main}
          />
        ))}
      </Box>
    </ComponentsLayout>
  );
};

export default LanguagePanelMobile;
