import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Program {
  _id: string;
  name: string;
}

interface Activity {
  name: string;
  logDate: string;
  duration: number;
  calories: number;
  workout: string;
}

const initialState: {
  program: Program;
  endTime: string;
  startTime: string;
  activity: Activity;
  targetedBodyPart: string;
} = {
  endTime: '',
  startTime: '',
  program: { _id: '', name: '' },
  targetedBodyPart: 'whole_body',
  activity: { name: '', duration: 0, calories: 0, logDate: '', workout: '' },
};

const workoutSlice = createSlice({
  name: 'workouts',
  initialState,
  reducers: {
    updateTargetBodyPart: (state, action: PayloadAction<string>) => {
      state.targetedBodyPart = action.payload;
    },
    updateProgram: (state, action: PayloadAction<Program>) => {
      state.program = action.payload;
    },
    updateStartTime: (state, action: PayloadAction<string>) => {
      state.startTime = action.payload;
    },
    addCompletedProgram: (state, action: PayloadAction<Activity>) => {
      state.activity = action.payload;
    },
  },
});

export const {
  updateProgram,
  updateStartTime,
  addCompletedProgram,
  updateTargetBodyPart,
} = workoutSlice.actions;

export default workoutSlice.reducer;
