import React, { FC, useEffect, useMemo, useState } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import {
  Activity,
  ClickNavCardNutrition,
  CustomButton,
  CustomInput,
  theme,
  useDebounce,
} from '@hdcorner/ui-library';
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter';
import { useIonRouter } from '@ionic/react';
import { useLazyGetActivityListQuery } from '../queries/activitiesQueries';
import { addActivityDetails } from '../slices/activitySlice';
import useAlert from '../../../../hooks/useAlert';
import { useAppDispatch } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import InfiniteScrollList from '../../../../components/InfiniteScrollList';

type Props = {
  dashboardModal?: boolean;
  addCustomHandler?: () => void;
  onActivityClick?: (data: Activity) => void;
};
const SearchActivityPanel: FC<Props> = ({
  dashboardModal,
  onActivityClick,
  addCustomHandler,
}) => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { presentError } = useAlert();
  const web = useMediaQuery(theme.breakpoints.up('md'));
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const [search, setSearch] = useState<string>('');
  const searchText = useDebounce(search, 600);
  const [params, setParams] = useState({
    skip: 0,
    limit: 20,
  });

  const [searchActivity, { error, isFetching, data: activityRes }] =
    useLazyGetActivityListQuery();

  useEffect(() => {
    setParams({ skip: 0, limit: 20 });
    searchActivity({ searchText: searchText || undefined, skip: 0, limit: 20 });
  }, [searchText]);

  useEffect(() => {
    searchActivity({
      skip: params.skip,
      limit: params.limit,
      searchText: searchText || undefined,
    });
  }, [params.limit, params.skip, searchActivity]);

  useEffect(() => {
    if (error) {
      presentError(t('errors.fitness.exercise.errorActivitiesFetch'));
    }
  }, [error]);

  const activityData = useMemo(() => {
    if (!activityRes) return [];

    return activityRes.documents.map(it => {
      return {
        _id: it._id,
        met: it.met,
        name: it.name,
      };
    });
  }, [activityRes]);

  const handleAddCustomClick = () => {
    if (addCustomHandler) {
      addCustomHandler();
      return;
    }
    if (dashboardModal) {
      return;
    }
    if (!web) {
      router.push('/dashboard/fitness/activities/custom-activity');
    }
  };

  const handleAddActivity = (data: Activity) => {
    const name = typeof data.name === 'string' ? data.name : data.name[lang];
    const path = name.toLowerCase().split(' ').join('-');

    dispatch(
      addActivityDetails({
        _id: data._id,
        met: data.met,
        name: data.name,
      }),
    );

    if (onActivityClick) {
      onActivityClick(data);
    }

    if (dashboardModal) {
      return;
    }

    if (!web) {
      router.push(`/dashboard/fitness/activities/${path}`);
    }
  };

  return (
    <>
      <Box
        gap={3}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        margin={theme.spacing(3, 0)}
      >
        <Box
          gap={2}
          display={'flex'}
          alignItems={'flex-end'}
          justifyContent={'space-between'}
        >
          <CustomInput
            fullWidth
            value={search}
            alternativeBackground
            handleChange={setSearch}
            label={`${t('fitness.exercise.labels.activityName')}`}
            placeholder={`${t('fitness.exercise.placeholders.egActivity')}`}
          />
          {!web && (
            <CustomButton
              color={'primary'}
              variant={'contained'}
              onClick={handleAddCustomClick}
              children={t('buttons.addCustom')}
            />
          )}
        </Box>

        <Box gap={1} display={'flex'} flexDirection={'column'}>
          <Typography
            variant={'subtitle2'}
            sx={{ fontWeight: theme.typography.fontWeightRegular }}
          >
            {searchText
              ? t('general.labels.searchRes')
              : t('fitness.exercise.labels.allAct')}
          </Typography>
          <InfiniteScrollList
            skip={params.skip}
            limit={params.limit}
            loading={isFetching}
            isWeb={dashboardModal}
            count={activityData.length}
            totalCount={activityRes?.count}
            onLoadMore={(skip, limit) => setParams({ ...params, skip, limit })}
          >
            {activityData.map(it => (
              <Box key={it._id}>
                <ClickNavCardNutrition
                  onClick={() => handleAddActivity(it)}
                  headings={capitalizeFirstLetter(
                    typeof it.name === 'string' ? it.name : it.name[lang],
                  )}
                />
              </Box>
            ))}
          </InfiniteScrollList>
        </Box>

        {/*NEXT RELEASE :*/}
        {/*<Box gap={1} display={'flex'} flexDirection={'column'}>*/}
        {/*  <Typography*/}
        {/*    variant={'subtitle2'}*/}
        {/*    sx={{ fontWeight: theme.typography.fontWeightRegular }}*/}
        {/*  >*/}
        {/*    Popular*/}
        {/*  </Typography>*/}
        {/*  <BoxMain web={web}>*/}
        {/*    {popularList.map(it => (*/}
        {/*      <ClickNavCardNutrition*/}
        {/*        key={it.id}*/}
        {/*        headings={capitalizeFirstLetter(it.activity)}*/}
        {/*        onClick={() => handleAddActivity(it.activity)}*/}
        {/*      />*/}
        {/*    ))}*/}
        {/*  </BoxMain>*/}
        {/*</Box>*/}

        {/*<Box gap={1} display={'flex'} flexDirection={'column'}>*/}
        {/*  <Typography*/}
        {/*    variant={'subtitle2'}*/}
        {/*    sx={{ fontWeight: theme.typography.fontWeightRegular }}*/}
        {/*  >*/}
        {/*    Recent*/}
        {/*  </Typography>*/}
        {/*  <BoxMain web={web}>*/}
        {/*    {recentList.map(it => (*/}
        {/*      <ClickNavCardNutrition*/}
        {/*        key={it.id}*/}
        {/*        headings={capitalizeFirstLetter(it.activity)}*/}
        {/*        onClick={() => handleAddActivity(it.activity)}*/}
        {/*      />*/}
        {/*    ))}*/}
        {/*  </BoxMain>*/}
        {/*</Box>*/}
      </Box>

      {web && (
        <CustomButton
          fullWidth
          color={'primary'}
          variant={'contained'}
          onClick={handleAddCustomClick}
          children={t('buttons.addCustom')}
        />
      )}
    </>
  );
};

export default SearchActivityPanel;
