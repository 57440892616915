import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, useTheme } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography/Typography';
import { ButtonProps } from '@mui/material/Button';
import CustomButton from '../controls/CustomButton';
import { useTranslation } from 'react-i18next';

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  color: theme.palette.primary.main,
}));

const Text = styled(Typography, {
  shouldForwardProp: prop => prop !== 'isBold',
})<TypographyProps<'span', { component?: 'span'; isBold?: boolean }>>(
  ({ theme, isBold }) => ({
    fontWeight: isBold ? theme.typography.fontWeightBold : undefined,
    color: theme.palette.kmColorsDarkGrey.main,
  }),
);

const AcceptButton = styled(CustomButton)<ButtonProps>(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.secondary.main,
  background: theme.palette.primary.main,
  display: 'flex',
  flex: 1,
  height: 48,
  ':hover': {
    opacity: 0.7,
    background: theme.palette.primary.main,
  },
}));

const DeclineButton = styled(CustomButton)<ButtonProps>(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.secondary.main,
  background: theme.palette.error.main,
  display: 'flex',
  flex: 1,
  height: 48,
  ':hover': {
    opacity: 0.7,
    background: theme.palette.error.main,
  },
}));

type AcceptDeclineModalType = {
  username: string;
  target: string;
  handleAccept: () => void;
  handleDecline: () => void;
};
const RequestInfoModal: FC<AcceptDeclineModalType> = ({
  username,
  target,
  handleAccept,
  handleDecline,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      display={'flex'}
      borderRadius={'8px'}
      flexDirection={'column'}
      bgcolor={theme.palette.secondary.light}
    >
      <Box display={'flex'} flexDirection={'column'} gap={theme.spacing(2)}>
        <Title variant={'subtitle1'}>{t('general.labels.requestInfo')}</Title>
        <Text
          variant={'subtitle2'}
          dangerouslySetInnerHTML={{
            __html: t('chat.text.requestInfo', { name: username, subject: target }),
          }}
        />
        <Box display={'flex'} justifyContent={'space-between'} gap={theme.spacing(3)}>
          <AcceptButton onClick={handleAccept}>{t('buttons.accept')}</AcceptButton>
          <DeclineButton onClick={handleDecline}>{t('buttons.decline')}</DeclineButton>
        </Box>
      </Box>
    </Box>
  );
};

export default RequestInfoModal;
