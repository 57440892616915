import * as React from 'react';
import { SVGProps } from 'react';
import { useTheme } from '@mui/material';

const FEDairyIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  return (
    <svg
      width="9"
      height="16"
      viewBox="0 0 9 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.333352 7.21802V13.9993C0.333352 14.353 0.473827 14.6921 0.723876 14.9422C0.973924 15.1922 1.31306 15.3327 1.66668 15.3327H7.00002C7.35364 15.3327 7.69278 15.1922 7.94283 14.9422C8.19288 14.6921 8.33335 14.353 8.33335 13.9993V7.21802C8.33395 6.9553 8.28247 6.69506 8.18189 6.45236C8.08131 6.20965 7.93362 5.98929 7.74735 5.80402C6.70335 4.68535 6.33335 4.57802 6.33335 4.11402V3.33268C6.33335 3.15587 6.26311 2.9863 6.13809 2.86128C6.01307 2.73625 5.8435 2.66602 5.66669 2.66602H3.00002C2.82321 2.66602 2.65364 2.73625 2.52861 2.86128C2.40359 2.9863 2.33335 3.15587 2.33335 3.33268V4.11402C2.33335 4.58068 1.96735 4.68135 0.919352 5.80402C0.732969 5.9892 0.585198 6.20955 0.484607 6.45227C0.384015 6.695 0.332603 6.95527 0.333352 7.21802ZM7.00002 13.9993H4.33335V8.66602H7.00002V13.9993ZM1.66668 7.21802C1.66672 7.04122 1.73699 6.87168 1.86202 6.74668L3.08069 5.52802C3.27964 5.32822 3.43457 5.08899 3.53549 4.82571C3.63642 4.56243 3.68111 4.28094 3.66668 3.99935H5.00002C4.98811 4.28067 5.03397 4.56144 5.13475 4.82435C5.23554 5.08727 5.38912 5.32674 5.58602 5.52802L6.80469 6.74668C6.87791 6.82427 6.93332 6.9169 6.96706 7.01811C7.0008 7.11932 7.01205 7.22668 7.00002 7.33268H3.66668C3.48987 7.33268 3.3203 7.40292 3.19528 7.52794C3.07026 7.65297 3.00002 7.82254 3.00002 7.99935V13.9993H1.66668V7.21802ZM2.33335 1.99935C2.15654 1.99935 1.98697 1.92911 1.86195 1.80409C1.73692 1.67906 1.66668 1.50949 1.66668 1.33268C1.66668 1.15587 1.73692 0.986302 1.86195 0.861278C1.98697 0.736253 2.15654 0.666016 2.33335 0.666016H6.33335C6.51016 0.666016 6.67973 0.736253 6.80476 0.861278C6.92978 0.986302 7.00002 1.15587 7.00002 1.33268C7.00002 1.50949 6.92978 1.67906 6.80476 1.80409C6.67973 1.92911 6.51016 1.99935 6.33335 1.99935H2.33335Z"
        fill={
          theme.palette.mode === 'dark'
            ? theme.palette.kmColorsCoolGrey.main
            : theme.palette.primary.main
        }
      />
    </svg>
  );
};

export default FEDairyIcon;
