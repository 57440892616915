import FEDairyIcon from '../../assets/FEDairyIcon';
import FEVegetableIcon from '../../assets/FEVegetableIcon';
import FEFruitIcon from '../../assets/FEFruitIcon';
import FEBreadIcon from '../../assets/FEBreadIcon';
import FEOtherCarbIcon from '../../assets/FEOtherCarbIcon';
import FEMeatIcon from '../../assets/FEMeatIcon';
import FEAlcoholIcon from '../../assets/FEAlcoholIcon';
import FEFatsIcon from '../../assets/FEFatsIcon';
import FEOtherIcon from '../../assets/FEOtherIcon';
import { useMediaQuery } from '@mui/material';
import theme from '../../theme/theme';

const dataMob = [
  {
    'column 1': 'Lemon',
    'column 2': 'Paprika',
  },
  { 'column 1': 'Garlic', 'column 2': 'Cocoa powder' },
  {
    'column 1': 'Vinegar',
    'column 2': 'Cinnamon',
  },
  { 'column 1': 'Caper', 'column 2': 'Sugar-free jelly' },
  {
    'column 1': 'Tomato Ketchup',
    'column 2': 'Tabasco',
  },
  { 'column 1': 'Wine (for cooking)', 'column 2': 'Soda drink' },
  {
    'column 1': 'Mustard',
    'column 2': 'Basil',
  },
  { 'column 1': 'Coffee', 'column 2': 'Sugar-free refreshments' },
  {
    'column 1': 'Pepper',
    'column 2': 'Mint/parsley',
  },
  { 'column 1': 'Infusions of tea', 'column 2': 'Sugar substitutes' },
  {
    'column 1': 'Chili',
    'column 2': 'Oregano/thyme/savory',
  },
  { 'column 1': 'Infusions', 'column 2': '' },
];

const dataWeb = [
  {
    'column 1': 'Lemon',
    'column 2': 'Paprika',
    'column 3': 'Garlic',
    'column 4': 'Cocoa powder',
  },
  {
    'column 1': 'Vinegar',
    'column 2': 'Cinnamon',
    'column 3': 'Caper',
    'column 4': 'Sugar-free jelly',
  },
  {
    'column 1': 'Tomato Ketchup',
    'column 2': 'Tabasco',
    'column 3': 'Wine (for cooking)',
    'column 4': 'Soda drink',
  },
  {
    'column 1': 'Mustard',
    'column 2': 'Basil',
    'column 3': 'Coffee',
    'column 4': 'Sugar-free refreshments',
  },
  {
    'column 1': 'Pepper',
    'column 2': 'Mint/parsley',
    'column 3': 'Infusions of tea',
    'column 4': 'Sugar substitutes',
  },
  {
    'column 1': 'Chili',
    'column 2': 'Oregano/thyme/savory',
    'column 3': 'Infusions',
    'column 4': '',
  },
];

export const FEData = () => {
  const web = useMediaQuery(theme.breakpoints.up('sm'));

  return [
    {
      type: 'dairy',
      title: 'Milk and dairy products',
      icon: <FEDairyIcon />,
      description:
        'An equivalent contains 12 g of carbohydrates, 8 g of protein and a quantity of lipids that varies by category.',
      data: [
        {
          heading: 'Skimmed milk (0-3 g of lipids per equivalent)',
          table: [
            {
              item: 'Skimmed milk',
              quantity: '1 cup',
            },
            {
              item: 'Milk with 1 or 1.5% fat',
              quantity: '1 cup',
            },
            {
              item: 'Skimmed milk powder',
              quantity: '⅓ cup',
            },
            {
              item: 'Evaporated milk (without fat)',
              quantity: '½ cup',
            },
            {
              item: 'Skimmed soy milk',
              quantity: '1 cup',
            },
            {
              item: 'Yoghurt made from skimmed milk',
              quantity: '180-200 g',
            },
          ],
        },
        {
          heading: 'Low fat milk (5 g of lipids per equivalent)',
          table: [
            {
              item: 'Milk with 2% fat',
              quantity: '1 cup',
            },
            {
              item: 'Yogurt made from milk with 2% fat',
              quantity: '180 g',
            },
            {
              item: 'Kefir, ayran, sour milk (medium fat)',
              quantity: '1 cup',
            },
          ],
        },
        {
          heading: 'Full-fat milk (8 g of lipids per equivalent)',
          table: [
            {
              item: 'Full-fat milk',
              quantity: '180 g',
            },
            {
              item: 'Full-fat evaporated milk (carton)',
              quantity: '½ cup',
            },
            {
              item: 'Goat milk',
              quantity: '180 g',
            },
            {
              item: 'Yogurt made from full-fat milk',
              quantity: '180 g',
            },
          ],
        },
      ],
    },
    {
      type: 'vegetable',
      title: 'Vegetables',
      icon: <FEVegetableIcon />,
      description:
        'An equivalent corresponds to ½ cup of boiled vegetables or 1 cup of raw vegetables and contains 5 g of carbohydrates, 2 g of protein, 25 Kcal and 1-4 g of dietary fiber.',
      note: 'Note: Starchy vegetables (potato, peas, green beans, corn) are mentioned in the starch group.',
      data: [
        {
          item: 'Cucumber',
          equivalent: 'Mushrooms',
        },
        {
          item: 'Artichoke',
          equivalent: 'Lettuce',
        },
        {
          item: 'Endives',
          equivalent: 'Aubergines',
        },
        {
          item: 'Vrouves (a type of mustard leaves)',
          equivalent: 'Broccoli',
        },
        {
          item: 'Blites',
          equivalent: 'Okra',
        },
        {
          item: 'Turnips',
          equivalent: 'Beetroots',
        },
        {
          item: 'Carrots',
          equivalent: 'Peppers',
        },
        {
          item: 'Courgettes',
          equivalent: 'Leeks',
        },
        {
          item: 'Onions',
          equivalent: 'Radishes',
        },
        {
          item: 'Cauliflower',
          equivalent: 'Chicory',
        },
        {
          item: 'Cabbage',
          equivalent: 'Celery',
        },
        {
          item: 'Curly-leaf Cabbage',
          equivalent: 'Spinach',
        },
        {
          item: 'Brussels Sprouts',
          equivalent: 'Asparagus',
        },
        {
          item: 'Pickled cabbage',
          equivalent: 'Tomatoes',
        },
        {
          item: 'Collards',
          equivalent: 'Tomato or vegetable juice',
        },
      ],
    },
    {
      type: 'fruit',
      title: 'Fruits and juices',
      icon: <FEFruitIcon />,
      description:
        'One equivalent contains 15 g of carbohydrates and 60 Kcal. The grams reported include peel and seeds.',
      data: [
        {
          heading: 'Fruit',
          table: [
            {
              item: 'Kiwi',
              quantity: '1 large',
            },
            {
              item: 'Pineapple',
              quantity: '¾ cup',
            },
            {
              item: 'Canned pineapple',
              quantity: '⅓ cup',
            },
            {
              item: 'Pear',
              quantity: '1 small (~ 110 g)',
            },
            {
              item: 'Apricots',
              quantity: '3 large (~ 200 g)',
            },
            {
              item: 'Grapefruit',
              quantity: '½ large (~ 330 g)',
            },
            {
              item: 'Plums',
              quantity: '2 medium (~ 140 g)',
            },
            {
              item: 'Watermelon',
              quantity: '1 and ¼ cup (~ 380 g)',
            },
            {
              item: 'Cherries',
              quantity: '12 large (~ 85 g)',
            },
            {
              item: 'Mango',
              quantity: '½ small (~ 160 g)',
            },
            {
              item: 'Tangerine',
              quantity: '2 small (~ 220 g)',
            },
            {
              item: 'Nectarine',
              quantity: '1 medium (~ 140 g)',
            },
            {
              item: 'Apple',
              quantity: '1 small (~ 120 g)',
            },
            {
              item: 'Mulberries',
              quantity: '¾ cup',
            },
            {
              item: 'Loquat fruits',
              quantity: '3 medium (~ 170 g)',
            },
            {
              item: 'Banana',
              quantity: '1 small (~ 120 g)',
            },
            {
              item: 'Melon',
              quantity: '1 cup (~ 280 g)',
            },
            {
              item: 'Orange',
              quantity: '1 small (~ 180 g)',
            },
            {
              item: 'Peach',
              quantity: '1 medium (~ 110 g)',
            },
            {
              item: 'Pomegranate',
              quantity: '½ medium',
            },
            {
              item: 'Raisins',
              quantity: '2 tablespoons',
            },
            {
              item: 'Grapes',
              quantity: '15 small (~ 85 g)',
            },
            {
              item: 'Figs',
              quantity: '2 large',
            },
            {
              item: 'Strawberries',
              quantity: '1 and ¼ cup',
            },
          ],
        },
        {
          heading: 'Juices: (1 cup = 240 mL)',
          table: [
            {
              item: 'Pineapple',
              quantity: '½ cup',
            },
            {
              item: 'Grapefruit',
              quantity: '½ cup',
            },
            {
              item: 'Prune',
              quantity: '⅓ cup',
            },
            {
              item: 'Apple',
              quantity: '½ cup',
            },
            {
              item: 'Orange',
              quantity: '½ cup',
            },
            {
              item: 'Grape',
              quantity: '⅓ cup',
            },
            {
              item: 'Mixed fruits Juice',
              quantity: '½ cup',
            },
          ],
        },
        {
          heading: 'Others:',
          table: [
            {
              item: 'Honey/jam/sugar',
              quantity: '1 tablespoon',
            },
            {
              item: 'Refreshments with sugar/fruit drinks',
              quantity: '½ cup',
            },
          ],
        },
      ],
    },
    {
      type: 'carbs',
      title: 'Bread - cereals - lentils - starchy vegetables',
      icon: <FEBreadIcon />,
      description:
        'One equivalent contains 15 g of carbohydrates, 3 g of proteins, 0-1 g of lipids and 80 Kcal.',
      data: [
        {
          heading: 'Bread',
          table: [
            {
              item: 'White (all types)',
              quantity: '1 slice (30 g)',
            },
            {
              item: 'Whole grain',
              quantity: '1 slice (30 g)',
            },
            {
              item: 'Bread-individual portions',
              quantity: '1 slice (~30 g)',
            },
            {
              item: 'Hot dog bread',
              quantity: '½ piece',
            },
            {
              item: 'Raisin bread',
              quantity: '1 slice (30 g)',
            },
            {
              item: 'Pita bread (e.g. for souvlaki, Arabic type)',
              quantity: '½ piece',
            },
            {
              item: 'Toasts (small)',
              quantity: '2 servings',
            },
            {
              item: 'Thessaloniki sesame bagel (medium)',
              quantity: '½ piece',
            },
            {
              item: 'Bread sticks',
              quantity: '2 large (~20g)',
            },
            {
              item: 'Crackers',
              quantity: '4 servings (~20g)',
            },
            {
              item: 'Crouton (without fat)',
              quantity: '1 cup',
            },
          ],
        },
        {
          heading: 'Cereal products',
          table: [
            {
              item: 'Flour',
              quantity: '3 tablespoons',
            },
            {
              item: 'Oat, wheat, corn flakes',
              quantity: '½ cup',
            },
            {
              item: 'Muesli',
              quantity: '¼ cup',
            },
            {
              item: 'Wheat bran, oat bran',
              quantity: '⅓ cup',
            },
            {
              item: 'Sugar-free breakfast cereals',
              quantity: '¾ cup',
            },
            {
              item: 'Rice (cooked) white or brown',
              quantity: '⅓ cup',
            },
            {
              item: 'Pasta-orzo-chylopíta (small squares of pasta) (cooked)',
              quantity: '⅓ cup',
            },
            {
              item: 'Noodles',
              quantity: '½ cup',
            },
            {
              item: 'Groats',
              quantity: '½ cup',
            },
          ],
        },
        {
          heading:
            'Legumes (also containing proteins as a very lean meat equivalent/equivalent)',
          table: [
            {
              item: 'Beans (all kinds) (cooked and drained)',
              quantity: '½ cup',
            },
            {
              item: 'Fava, chickpeas, lentils (cooked and drained)',
              quantity: '½ cup',
            },
          ],
        },
        {
          heading: 'Starchy vegetables',
          table: [
            {
              item: 'Peas (cooked)',
              quantity: '½ cup',
            },
            {
              item: 'Green beans with seed (cooked)',
              quantity: '½ cup',
            },
            {
              item: 'Roasted or boiled potatoes',
              quantity: '1 small egg-size (85 g)',
            },
            {
              item: 'Mashed potatoes',
              quantity: '½ cup',
            },
            {
              item: 'Sweet potato',
              quantity: '⅓ cup',
            },
            {
              item: 'Boiled corn',
              quantity: '½ cup',
            },
            {
              item: 'Whole corn',
              quantity: '1 medium (140 g)',
            },
            {
              item: 'Popcorn (without added fat)',
              quantity: '3 cups',
            },
          ],
        },
      ],
    },
    {
      type: 'carbs_other',
      title: 'Other carbohydrate equivalents',
      icon: <FEOtherCarbIcon />,
      description:
        'This category includes foods containing mainly carbohydrates (mostly simple ones) and perhaps lipids (1 equivalent of starch or fruit is equivalent to 15 g of carbohydrates and 1 equivalent of fat is equivalent to 5 g of lipids). However, there may be a very big deviation of the macronutrients from a recipe to another and from a manufacturer to another, so it is necessary to consult the food labels.',
      data: [
        {
          item: 'Waffle',
          quantity: '1 small',
          equivalent: '1 equiv. starch or fruit, 1 equiv. fat',
        },
        {
          item: 'Simple cake',
          quantity: '1 thin slice',
          equivalent: '1 equiv. starch or fruit, 1 equiv. fat',
        },
        {
          item: 'Simple donut',
          quantity: '1 medium',
          equivalent: '2 equiv. starch or fruit, 1 equiv. fat',
        },
        {
          item: 'Stuffed donut',
          quantity: '1 small',
          equivalent: '2 equiv. starch or fruit, 1 equiv. fat',
        },
        {
          item: 'Stuffed cookies',
          quantity: '2 small',
          equivalent: '1 equiv. starch or fruit, 1 equiv. fat',
        },
        {
          item: 'Corn blossom cream',
          quantity: '½ cup',
          equivalent: '1 equiv. starch, ½ equiv. fat',
        },
        {
          item: 'Caramel cream',
          quantity: '½ cup',
          equivalent: '1 equiv. starch, ½ equiv. fat',
        },
        {
          item: 'Simple ice cream (vanilla, chocolate)',
          quantity: '65 g',
          equivalent: '1 equiv. starch, 1 equiv. fat',
        },
        {
          item: 'Simple chocolate',
          quantity: '25 g',
          equivalent: '1 equiv. starch, 1.5 equiv. fat',
        },
        {
          item: 'Sorbet',
          quantity: '½ cup',
          equivalent: '2 equiv. starch or fruit',
        },
        {
          item: 'French fries',
          quantity: '8 pieces',
          equivalent: '1 equiv. starch, 1 equiv. fat',
        },
        {
          item: 'Potato chips',
          quantity: '15 pieces',
          equivalent: '1 equiv. starch, 2 equiv. fat',
        },
      ],
    },
    {
      type: 'protein',
      title: 'Meat and substitutes',
      icon: <FEMeatIcon />,
      description:
        'An equivalent contains 7 g of carbohydrates and a quantity of lipids that varies by category.',
      note: '*Note: Each legume equivalent also contains 15 g of carbohydrates.',
      data: [
        {
          title: 'Very lean meat and substitutes (0-1 g of lipids)',
          description:
            '30 g of white meat chicken and turkey (no skin), cod, tope, tuna (fresh or canned in water), mussels, crab, lobster, shrimps, pinnas, duck, pheasant (no skin), deer, ostrich, no fat cottage cheese, lean turkey ham. ½ cup of legumes (beans, lentils, fava beans, chickpeas, cooked and drained)*',
        },
        {
          title: 'Very lean meat and substitutes (0-1 g of lipids)',
          description:
            '30 g of turkey (dark meat, without skin), chicken (white meat with skin), duck (without skin), rabbit, bon fillet, boneless strip loin, T-bone pork steak (after removing the minimum visible fat), tenderloin, lean ham (foot, side, shoulder), herring, sardine, salmon (fresh or canned), tuna (in oil, drained), liver, heart. 6 medium oysters, ¼ cup cottage cheese 4.5% fat.',
        },
        {
          title: 'Medium-fat meat and substitutes (5 g of lipids)',
          description:
            '30 g of chicken and turkey (dark meat with skin), beef 15% fat, steak, boiled ham, ground pork, roasted chops, fatty fish not mentioned in the previous groups, anthotyro cheese, feta cheese, mozzarella. 1 egg (rich in cholesterol).',
        },
        {
          title: 'Fat-rich meat and substitutes (8 g of lipid)',
          description:
            '30 g pork chops, sausages, salami, fried fish, cheeses such as gruyere, kasseri cheese, kefalograviera cheese, kefalotyri cheese, metsovone cheese, kopanisti cheese, parmesan, cheddar, edam.',
        },
      ],
    },
    {
      type: 'alcohol',
      title: 'Alcoholic beverages',
      icon: <FEAlcoholIcon />,
      description:
        'This category includes the various options of alcoholic beverages which, in the quantities mentioned, are considered to be equal to approximately 100 Kcal. In some drinks with more carbohydrates or added sugar, 1 or ½ fruit equivalent should also be calculated.',
      data: [
        {
          item: 'Red wine',
          quantity: '120 mL',
          equivalent: '1 alcohol equivalent',
        },
        {
          item: 'White wine',
          quantity: '120 mL',
          equivalent: '1 alcohol equivalent',
        },
        {
          item: 'Sweet wine',
          quantity: '100 mL',
          equivalent: '1 alcohol equivalent, 1 fruit equivalent',
        },
        {
          item: 'Beer (4.5% alcohol)',
          quantity: '330 mL',
          equivalent: '1 alcohol equivalent, 1 fruit equivalent',
        },
        {
          item: 'Liqueur',
          quantity: '30 mL',
          equivalent: '1 alcohol equivalent, 1 fruit equivalent',
        },
        {
          item: 'Rum, whiskey, vodka, gin',
          quantity: '30 mL',
          equivalent: '1 alcohol equivalent',
        },
      ],
    },
    {
      type: 'fat',
      title: 'Fats and oils',
      icon: <FEFatsIcon />,
      description: 'One equivalent contains 5 g of lipids and 45 kcal.',
      data: [
        {
          heading: 'Group of monounsaturated lipids',
          table: [
            {
              item: 'Avocado',
              quantity: '30 g',
            },
            {
              item: 'Olive oil',
              quantity: '1 teaspoon',
            },
            {
              item: 'Olives',
              quantity: '10 small or 5 large ones',
            },
            {
              item: 'Sesame seed',
              quantity: '1 tablespoon',
            },
            {
              item: 'Tahini',
              quantity: '2 teaspoons',
            },
            {
              item: 'Almonds',
              quantity: '6 pieces',
            },
            {
              item: 'Chestnuts',
              quantity: '2 whole',
            },
            {
              item: 'Cashew nuts',
              quantity: '6 pieces',
            },
            {
              item: 'Pine nuts',
              quantity: '1 tablespoon',
            },
            {
              item: 'Pasatempo nuts/sunflower seeds',
              quantity: '1 tablespoon',
            },
            {
              item: 'Peanuts',
              quantity: '10 pieces',
            },
            {
              item: 'Peanut butter',
              quantity: '½ tablespoon',
            },
          ],
        },
        {
          heading: 'Group of polyunsaturated lipids',
          table: [
            {
              item: 'Margarine',
              quantity: '1 teaspoon',
            },
            {
              item: 'Mayonnaise',
              quantity: '2 teaspoons',
            },
            {
              item: 'Light mayonnaise',
              quantity: '1 tablespoon',
            },
            {
              item: 'Salad dressing',
              quantity: '1 tablespoon',
            },
            {
              item: 'Corn oil',
              quantity: '1 teaspoon',
            },
            {
              item: 'Sunflower oil',
              quantity: '1 teaspoon',
            },
            {
              item: 'Soybean oil',
              quantity: '1 teaspoon',
            },
          ],
        },
        {
          heading: 'Group of saturated lipids',
          table: [
            {
              item: 'Butter',
              quantity: '1 teaspoon',
            },
            {
              item: 'Heavy (full-fat) whipping cream',
              quantity: '1 tablespoon',
            },
            {
              item: 'Light whipping cream',
              quantity: '2 tablespoons',
            },
            {
              item: 'Bacon',
              quantity: '1 slice',
            },
          ],
        },
      ],
    },
    {
      type: 'other',
      title: 'Freely consumable foods',
      icon: <FEOtherIcon />,
      description:
        'This group includes foods or beverages containing less than 20 Kcal or less than 5 g of carbohydrates per equivalent. For foods in this group, no energy and macronutrients are calculated.',
      data: web ? dataWeb : dataMob,
    },
  ];
};
