import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Lottie from 'lottie-react';
import moment from 'moment/moment';
import React, { FC, useMemo } from 'react';
import TrophyIcon from '../../../../assets/icons/TrophyIcon';
import {
  CustomButton,
  ExerciseIcon,
  SMDetailCard,
  SMDetailCardChild,
} from '@hdcorner/ui-library';
import useAlert from '../../../../hooks/useAlert';
import { useAppSelector } from '../../../../redux/hooks';
import { hoursAndMinutes } from '../../../../utils/dateTimeUtils';
import congratulations from '../../../../assets/lotties/bullseye-target.json';
import { usePostCreateActivityLogMutation } from '../../exercise/queries/activitiesQueries';
import { usePostCaloriesInDepthMutation } from '../../calories/queries/caloriesQueries';
import { useTranslation } from 'react-i18next';

const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

const Text = styled(Typography)<TypographyProps>(({ theme }) => ({
  align: 'center',
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightBold,
  textTransform: lang === 'el' ? 'capitalize' : 'uppercase',
}));

const KeepPushing = styled(Box)<BoxProps>(({ theme }) => ({
  border: 'none',
  display: 'flex',
  borderRadius: '8px',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(1.5),
  color: theme.palette.primary.main,
  background: theme.palette.warning.main,
}));

type Props = {
  onClick: () => void;
};
const CongratulationsScreen: FC<Props> = ({ onClick }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { presentSuccess, presentError } = useAlert();
  const web: boolean = useMediaQuery(theme.breakpoints.up('md'));

  const { startTime, activity } = useAppSelector(state => state.workouts);

  const [postWorkout] = usePostCreateActivityLogMutation();
  const [postCalories] = usePostCaloriesInDepthMutation();

  const workoutData = useMemo(() => {
    if (!startTime || !activity) return [];

    return [
      {
        title: t('fitness.workouts.labels.started'),
        value: moment(startTime).format('h:mm A'),
      },
      {
        title: t('fitness.workouts.labels.ended'),
        value: moment(activity.logDate).format('h:mm A'),
      },
      {
        title: t('fitness.workouts.labels.duration'),
        value: hoursAndMinutes(activity.duration),
      },
      {
        title: t('fitness.calories.titles.kcalBurned'),
        value: `${activity.calories} ${t('measurements.kcal')}`,
      },
    ];
  }, [activity, startTime]);

  const handleClickShare = () => {
    postWorkout({
      custom: true,
      name: activity.name,
      logDate: activity.logDate,
      workout: activity.workout,
      duration: activity.duration,
      calories: activity.calories,
    })
      .unwrap()
      .then(() => presentSuccess(t('errors.fitness.workouts.successWorkoutLogAdd')))
      .catch(() => presentError(t('errors.fitness.workouts.errorWorkoutLogAdd')))
      .finally(() => {
        handlePostCalories();
      });
  };

  const handlePostCalories = () => {
    postCalories()
      .unwrap()
      .catch(() => presentError(t('errors.fitness.calories.errorUpdate')))
      .finally(() => {
        onClick();
      });
  };

  return (
    <Box
      gap={3}
      flex={1}
      display={'flex'}
      textAlign={'center'}
      flexDirection={'column'}
      justifyContent={'center'}
      bgcolor={theme.palette.secondary.light}
      padding={theme.spacing(web ? 3 : 1)}
    >
      <Box
        height={'160px'}
        display={'flex'}
        alignItems={'center'}
        flexDirection={'column'}
      >
        <Lottie
          loop={true}
          animationData={congratulations}
          style={{ position: 'absolute', height: 160, width: 160 }}
        />
      </Box>
      <Text variant={'h1'}>{t('components.models.congratulations.congrats')}</Text>
      <Box>
        <SMDetailCard
          altIcon={
            <ExerciseIcon
              width={'16px'}
              height={'16px'}
              color={theme.palette.primary.main}
            />
          }
          heading={`${activity.name} ${t('fitness.workouts.labels.workout')}`}
          sx={{
            background: theme.palette.secondary.main,
            border: `1px solid ${theme.palette.secondary.dark} `,
          }}
        >
          {workoutData.map((it: any) => (
            <SMDetailCardChild heading={it.title} time={it.value} />
          ))}
        </SMDetailCard>
      </Box>
      <Box display={'flex'} flexDirection={'column'} gap={theme.spacing(1)}>
        <KeepPushing>
          <TrophyIcon height={14} width={14} />{' '}
          <Typography
            variant={'subtitle2'}
            sx={{ fontWeight: theme.typography.fontWeightRegular }}
          >
            {t('components.models.congratulations.keepPushing')}
          </Typography>
        </KeepPushing>
        <CustomButton fullWidth variant={'contained'} onClick={handleClickShare}>
          {t('buttons.logToActivity')}
        </CustomButton>
      </Box>
    </Box>
  );
};

export default CongratulationsScreen;
