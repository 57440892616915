import { useEffect, useMemo, useState } from 'react';
import {
  useGetCvdCurrentMeasurementQuery,
  useGetCvdRiskGoalQuery,
  useGetCvdStartingMeasurementQuery,
} from '../../cvdRisk/queries/cvdRiskQueries';
import { goalProgress } from '../../../utils/goalProgress';

const useGetCvdGoalData = () => {
  const [startingDate, setStartingDate] = useState<string>();

  const { data: cvdRiskGoal } = useGetCvdRiskGoalQuery();
  const { data: cvdCurrent } = useGetCvdCurrentMeasurementQuery();
  const { data: cvdStarting } = useGetCvdStartingMeasurementQuery(
    {
      startingDate: startingDate || '',
    },
    { skip: !startingDate },
  );

  useEffect(() => {
    if (cvdRiskGoal && cvdRiskGoal.length > 0) {
      setStartingDate(cvdRiskGoal[0].startDate);
    }
  }, [cvdRiskGoal]);

  return useMemo(() => {
    if (!cvdRiskGoal || cvdRiskGoal.length === 0) return null;

    let goalValue;
    let startValue;
    let currentValue;
    let progress = 0;

    if (cvdRiskGoal && cvdRiskGoal.length > 0) {
      goalValue = cvdRiskGoal[0].goal;
    }

    if (cvdStarting && cvdStarting.logs.length > 0) {
      startValue = cvdStarting.logs[0].score;
    }

    if (cvdCurrent && cvdCurrent.logs.length > 0) {
      currentValue = cvdCurrent.logs[0].score;
      startValue = startValue || currentValue;
    }

    if (goalValue && startValue && currentValue) {
      progress = goalProgress(currentValue, goalValue, startValue);
    }

    return { progress, goalValue, startValue, currentValue };
  }, [cvdRiskGoal, cvdCurrent, cvdStarting]);
};

export default useGetCvdGoalData;
