import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react';
import {
  Box,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  skip: number;
  limit: number;
  count?: number;
  isWeb?: boolean;
  loading?: boolean;
  maxHeight?: string;
  totalCount?: number;
  children: React.ReactNode;
  onLoadMore: (skip: number, limit: number) => void;
};
const InfiniteScrollList: React.FC<Props> = ({
  isWeb,
  children,
  skip = 0,
  count = 0,
  limit = 25,
  onLoadMore,
  totalCount = 0,
  loading = false,
  maxHeight = '350px',
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const containerRef = useRef<any>(null);
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const infiniteScrollRef = useRef<HTMLIonInfiniteScrollElement>(null);

  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);

  const handleScroll = () => {
    if (!isWeb) {
      if (mobile) return;
    }
    const container = containerRef.current;
    if (container) {
      const isAtBottom =
        container.scrollTop + container.clientHeight >= container.scrollHeight - 30;
      if (isAtBottom && !disableInfiniteScroll) {
        loadMoreData();
      }
    }
  };

  const loadMoreData = () => {
    // Disable infinite scroll when no more data is available
    if (count >= totalCount) {
      setDisableInfiniteScroll(true);
      return;
    }
    onLoadMore(skip + limit, limit);
  };

  useEffect(() => {
    if (!isWeb) {
      if (mobile) return;
    }
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scrollend', handleScroll);
      return () => {
        container.removeEventListener('scrollend', handleScroll);
      };
    }
  }, [handleScroll, mobile]);

  useEffect(() => {
    if (!loading) {
      infiniteScrollRef.current?.complete();
    }
  }, [loading]);

  const handleLoadMore = useCallback(() => {
    if (isWeb) {
      if (!mobile) return;
    }
    onLoadMore(skip + limit, limit);
  }, [mobile, onLoadMore, skip, limit]);

  return (
    <Box gap={1} display={'flex'} flexDirection={'column'}>
      <Box
        gap={1}
        display={'flex'}
        ref={containerRef}
        flexDirection={'column'}
        sx={{ maxHeight: isWeb || !mobile ? maxHeight : 'unset', overflowY: 'auto' }}
      >
        {React.Children.map(children, (child, index) => (
          <React.Fragment key={index}>{child}</React.Fragment>
        ))}
      </Box>
      {/*Mobile*/}
      {!isWeb && mobile && (
        <IonInfiniteScroll
          threshold="100px"
          ref={infiniteScrollRef}
          onIonInfinite={handleLoadMore}
          disabled={loading || totalCount <= skip + limit}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText={`${t('general.labels.loading')}`}
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      )}
      {(isWeb || !mobile) && (
        <Box display={'flex'} justifyContent={'center'} mt={1}>
          {loading && (
            <Box display={'flex'} flexDirection={'column'} gap={2} alignItems={'center'}>
              <CircularProgress />
              <Typography variant={'body1'}>{`${t(
                'general.labels.loading',
              )}`}</Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default InfiniteScrollList;
