import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Box } from '@mui/material';
import InDepthHeading from '../../components/InDepthHeading';
import { useGetSleepLogsQuery } from '../queries/sleepQueries';
import { CalendarModal, FeelsEmpty, SleepDataCard, theme } from '@hdcorner/ui-library';
import useAlert from '../../../../hooks/useAlert';
import InfiniteScrollList from '../../../../components/InfiniteScrollList';
import { useTranslation } from 'react-i18next';

const SectionSleepData = () => {
  const { presentError } = useAlert();
  const { t } = useTranslation();

  const [open, setOpen] = useState<boolean>(false);
  // Used for updating the params for the query
  const [endParam, setEndParam] = useState<string>('');
  const [startParam, setStartParam] = useState<string>('');
  const [params, setParams] = useState({
    skip: 0,
    limit: 10,
  });

  const {
    error,
    isFetching,
    data: sleepLogs,
  } = useGetSleepLogsQuery({
    end: endParam ? endParam : undefined,
    start: startParam ? startParam : undefined,
    params: { sort: ['-createdAt'], ...params },
  });

  useEffect(() => {
    if (error) {
      presentError('An error occurred while fetching sleep logs');
    }
  }, [error]);

  const inDepthData = useMemo(() => {
    if (!sleepLogs || sleepLogs.documents.length === 0) return [];

    return sleepLogs.documents.map(it => {
      return {
        _id: it._id,
        hours: it.quantity.toFixed(1).toString(),
        date: moment(it.createdAt).format('MMMM Do, YYYY'),
      };
    });
  }, [sleepLogs]);

  const handleDateChange = (dateRange: string[]) => {
    setEndParam(dateRange[1]);
    setStartParam(dateRange[0]);
  };

  return (
    <Box>
      <InDepthHeading
        module={'sleep'}
        color={theme.palette.exercise.main}
        handleDate={() => setOpen(true)}
      />
      <Box mt={1} gap={1} display={'flex'} flexDirection={'column'}>
        <InfiniteScrollList
          skip={params.skip}
          limit={params.limit}
          loading={isFetching}
          count={inDepthData.length}
          totalCount={sleepLogs?.count}
          onLoadMore={(skip, limit) => setParams({ skip, limit })}
        >
          {inDepthData.map(it => (
            <SleepDataCard
              key={it._id}
              date={it.date}
              title={it.hours}
              unit={t('measurements.hours')}
            />
          ))}
        </InfiniteScrollList>
      </Box>
      {inDepthData.length === 0 && (
        <Box>
          <FeelsEmpty />
        </Box>
      )}
      <CalendarModal
        open={open}
        setOpen={setOpen}
        saveDate={handleDateChange}
        selectedDate={[startParam, endParam]}
      />
    </Box>
  );
};

export default SectionSleepData;
