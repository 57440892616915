import React, { FC } from 'react';
import moment from 'moment/moment';
import { Notification } from '../../types/Notification';
import { Box, BoxProps, styled, Typography, useTheme } from '@mui/material';

const MainBox = styled(Box, {
  shouldForwardProp: prop => prop !== 'read',
})<BoxProps & { read: boolean }>(({ read, theme }) => ({
  flex: 1,
  display: 'flex',
  borderRadius: '8px',
  boxSizing: 'border-box',
  justifyContent: 'flex-end',
  paddingLeft: theme.spacing(0.25),
  backgroundColor: read ? theme.palette.secondary.light : theme.palette.error.main,
}));

const InnerBox = styled(Box)<BoxProps>(({ theme }) => ({
  flex: 1,
  display: 'flex',
  borderRadius: '8px',
  boxSizing: 'border-box',
  gap: theme.spacing(1),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.secondary.light,
}));

type Props = {
  data: Notification;
  onNotificationClick: (id: string) => void;
};
const NotificationCard: FC<Props> = ({ data, onNotificationClick }) => {
  const theme = useTheme();

  return (
    <MainBox read={data.read} onClick={() => onNotificationClick(data._id)}>
      <InnerBox>
        <Box display={'flex'} flexDirection={'column'} gap={1} flex={1}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Box>
              <Typography
                variant={'body1'}
                fontWeight={'fontWeightBold'}
                sx={{
                  color: data.read
                    ? theme.palette.kmColorsCoolGrey.main
                    : theme.palette.primary.main,
                }}
              >
                {data.title}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant={'caption'}
                sx={{
                  color: data.read
                    ? theme.palette.kmColorsCoolGrey.main
                    : theme.palette.primary.main,
                }}
              >
                {moment(data.createdAt).format('MM/DD/YYYY')}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography
              variant={'body1'}
              sx={{
                color: data.read
                  ? theme.palette.kmColorsCoolGrey.main
                  : theme.palette.primary.main,
              }}
            >
              {data.body}
            </Typography>
          </Box>
        </Box>
      </InnerBox>
    </MainBox>
  );
};

export default NotificationCard;
