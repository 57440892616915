import * as React from 'react';
import { SVGProps } from 'react';

const TooltipReminderIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={18} height={23} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7 20.5h4a2 2 0 0 1-4 0ZM.076 17.883a1 1 0 0 1 .217-1.09L2 15.086V9.5a7.006 7.006 0 0 1 6-6.92V1.5a1 1 0 1 1 2 0v1.08a7.006 7.006 0 0 1 6 6.92v5.586l1.707 1.707A1 1 0 0 1 17 18.5H1a1 1 0 0 1-.924-.617ZM3.414 16.5h11.172l-.293-.293A1 1 0 0 1 14 15.5v-6a5 5 0 0 0-10 0v6a1 1 0 0 1-.293.707l-.293.293Z"
      fill="#EE1D23"
    />
  </svg>
);

export default TooltipReminderIcon;
