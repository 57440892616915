import { useEffect, useMemo, useState } from 'react';
import {
  useGetLipidFirstLogQuery,
  useGetLipidGoalQuery,
  useGetLipidLastLogQuery,
} from '../../lipid/queries/lipidQueries';
import { goalProgress } from '../../../utils/goalProgress';

const useGetTotalCholGoalData = () => {
  const [startingDate, setStartingDate] = useState<string>();

  const { data: cholGoal } = useGetLipidGoalQuery({ type: 'chol' });

  const { data: lipidCurrent } = useGetLipidLastLogQuery();
  const { data: lipidStarting } = useGetLipidFirstLogQuery(
    {
      startingDate: startingDate || '',
    },
    { skip: !startingDate },
  );

  useEffect(() => {
    if (cholGoal && cholGoal.length > 0) {
      setStartingDate(cholGoal[0].startDate);
    }
  }, [cholGoal]);

  return useMemo(() => {
    if (!cholGoal || cholGoal.length === 0) return null;

    let goalValue;
    let startValue;
    let currentValue;
    let progress = 0;

    if (cholGoal && cholGoal.length > 0) {
      goalValue = parseInt(cholGoal[0].goal.measurement);
    }

    if (lipidStarting && lipidStarting.documents.length > 0) {
      startValue = lipidStarting.documents[0].totalChol;
    }

    if (lipidCurrent && lipidCurrent.documents.length > 0) {
      currentValue = lipidCurrent.documents[0].totalChol;
      startValue = startValue || currentValue;
    }

    if (goalValue && startValue && currentValue) {
      progress = goalProgress(currentValue, goalValue, startValue);
    }

    return { progress, goalValue, startValue, currentValue };
  }, [lipidCurrent, lipidStarting, cholGoal]);
};

export default useGetTotalCholGoalData;
