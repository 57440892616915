import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={46}
    height={46}
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle opacity={0.5} cx={23} cy={23} r={23} fill="black" />
    <path opacity={0.4} d="M31 23L19 30V16L31 23Z" fill="white" />
  </svg>
);

export default SvgComponent;
