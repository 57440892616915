import * as React from 'react';
import { SVGProps } from 'react';
import { Box, BoxProps, styled, useTheme } from '@mui/material';

const IconBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  padding: theme.spacing(0.5),
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.kmColorsBgText.main
      : theme.palette.primary.main,
}));

const ChatAddContentIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();

  return (
    <IconBox>
      <svg
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M3.33333 7.33333V4.66667H0.666667C0.489856 4.66667 0.320287 4.59643 0.195262 4.4714C0.070238 4.34638 0 4.17681 0 4C0 3.82319 0.070238 3.65362 0.195262 3.5286C0.320287 3.40357 0.489856 3.33333 0.666667 3.33333H3.33333V0.666667C3.33333 0.489856 3.40357 0.320287 3.5286 0.195262C3.65362 0.070238 3.82319 0 4 0C4.17681 0 4.34638 0.070238 4.4714 0.195262C4.59643 0.320287 4.66667 0.489856 4.66667 0.666667V3.33333H7.33333C7.51014 3.33333 7.67971 3.40357 7.80474 3.5286C7.92976 3.65362 8 3.82319 8 4C8 4.17681 7.92976 4.34638 7.80474 4.4714C7.67971 4.59643 7.51014 4.66667 7.33333 4.66667H4.66667V7.33333C4.66667 7.51014 4.59643 7.67971 4.4714 7.80474C4.34638 7.92976 4.17681 8 4 8C3.82319 8 3.65362 7.92976 3.5286 7.80474C3.40357 7.67971 3.33333 7.51014 3.33333 7.33333Z"
          fill={
            theme.palette.mode === 'dark'
              ? theme.palette.kmColorsButtonText.main
              : theme.palette.secondary.light
          }
        />
      </svg>
    </IconBox>
  );
};

export default ChatAddContentIcon;
