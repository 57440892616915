import {
  BloodGlucoseLog,
  ComponentsLayout,
  CustomTabs,
  Filters,
  Hbac1Log,
  ModalDiabetes,
  ModalMobile,
  theme,
} from '@hdcorner/ui-library';
import * as React from 'react';
import { useMemo, useState } from 'react';
import DiabetesTabHBAC1 from './DiabetesTabHBAC1';
import DiabetesHeaderIcon from '../../assets/icons/DiabetesHeaderIcon';
import PageLayout from '../../components/PageLayout';
import {
  useAddBloodGlucoseLogMutation,
  useAddHbac1LogMutation,
  useGetBloodGlucoseGoalQuery,
  useGetBloodGlucoseStartingMeasurementQuery,
  useGetHbac1StartingMeasurementQuery,
  useGetHbacGoalQuery,
} from './queries/diabetesQueries';
import DiabetesBloodGlucose from './DiabetesBloodGlucose';
import useAlert from '../../hooks/useAlert';
import { Camera, CameraResultType } from '@capacitor/camera';
import { useAppDispatch } from '../../redux/hooks';
import { goalProgress } from '../../utils/goalProgress';
import { addGoalDetails } from '../dashboard/slices/congratulationsSlice';
import { useTranslation } from 'react-i18next';
import { cameraPermissions } from '../../utils/cameraPermissions';

const Diabetes = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { presentSuccess, presentError } = useAlert();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [modalDiabetesOpen, setModalDiabetesOpen] = useState(false);
  const [inputError, setInputError] = useState({ value: false, message: '' });

  const [addHbac1Log] = useAddHbac1LogMutation();
  const [addBloodGlucoseLog] = useAddBloodGlucoseLogMutation();

  /**
   * Check if Glucose goal has been reached
   */
  const { data: glucoseGoal } = useGetBloodGlucoseGoalQuery();

  const glucoseGoalData = useMemo(() => {
    if (!glucoseGoal || glucoseGoal.length === 0) return { goal: 0, date: '' };
    return { goal: glucoseGoal[0].goal, date: glucoseGoal[0].startDate };
  }, [glucoseGoal]);

  const { data: glucoseStart } = useGetBloodGlucoseStartingMeasurementQuery({
    startDate: glucoseGoalData.date,
  });

  const glucoseStartData = useMemo(() => {
    if (!glucoseStart || glucoseStart.documents.length === 0) return 0;
    return glucoseStart.documents[0].measurement;
  }, [glucoseStart]);

  /**
   * Check if HbA1c goal has been reached
   */
  const { data: hbacGoal } = useGetHbacGoalQuery();

  const hbacGoalData = useMemo(() => {
    if (!hbacGoal || hbacGoal.length === 0) return { goal: 0, date: '' };
    return { goal: hbacGoal[0].goal, date: hbacGoal[0].startDate };
  }, [hbacGoal]);

  const { data: hbacStart } = useGetHbac1StartingMeasurementQuery({
    startDate: hbacGoalData.date,
  });

  const hbacStartData = useMemo(() => {
    if (!hbacStart || hbacStart.documents.length === 0) return 0;
    return hbacStart.documents[0].measurement;
  }, [hbacStart]);

  const handleOcr = async (cb: (newMeasurement: string) => void) => {
    const cameraSource = await cameraPermissions();
    if (!cameraSource) {
      presentError(t('errors.devicePermissions.camera'));
      return;
    }
    const image = await Camera.getPhoto({
      quality: 90,
      source: cameraSource,
      resultType: CameraResultType.Base64,
    });

    setLoading(true);
    await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer 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',
      },
      body: JSON.stringify({
        max_tokens: 300,
        model: 'gpt-4-vision-preview',
        messages: [
          {
            role: 'user',
            content: [
              {
                type: 'text',
                text: 'Read the text on the device screen resembling the following: 120 mg/dl and return just the number measurement',
              },
              {
                type: 'image_url',
                image_url: {
                  url: `data:image/jpeg;base64,${image.base64String}`,
                },
              },
            ],
          },
        ],
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.choices.length === 0) {
          presentError(t('errors.generic.errorMeasurement'));
          return;
        }
        if (data.choices[0].message.content === 'No measurement detected') {
          presentError(t('errors.generic.errorMeasurement'));
          return;
        }
        const measurement = data.choices[0].message.content;
        cb(measurement);
        setLoading(false);
        presentSuccess(t('errors.generic.measurementDetected'));
      })
      .catch(error => {
        setLoading(false);
        console.error('Error:', error);
        presentError(t('errors.generic.errorImage'));
      });
  };

  const handleAddBloodGlucoseLog = async (data: Partial<BloodGlucoseLog>) => {
    const log = {
      logDate: data.logDate,
      category: data.category,
      measurement: data.measurement,
    };

    if (!data.measurement) {
      presentError(t('errors.diabetes.inputErrors.validRangeGlucose'));
      return;
    }

    if (data.measurement < 30 || data.measurement > 600) {
      setInputError({
        value: true,
        message: t('errors.diabetes.inputErrors.validRangeGlucose'),
      });
    } else {
      try {
        await addBloodGlucoseLog(log)
          .unwrap()
          .then(() => {
            presentSuccess(t('errors.diabetes.successGlucose.logAdded'));
          })
          .finally(() => {
            if (
              goalProgress(
                data.measurement || 0,
                glucoseGoalData.goal,
                glucoseStartData,
              ) === 100 &&
              glucoseGoalData.date !== ''
            ) {
              setTimeout(() => {
                dispatch(
                  addGoalDetails({
                    category: 'blood glucose',
                    goal: { value: glucoseGoalData.goal, measure: 'mg/dL' },
                    start: {
                      measure: 'mg/dL',
                      value: glucoseStartData,
                      time: glucoseGoalData.date,
                    },
                  }),
                );
              }, 1500);
            }
          });
      } catch (e) {
        presentError(t('errors.diabetes.errorsGlucose.addLog'));
      }
      handleClose();
    }
  };

  const handleAddHbac1Log = async (data: Partial<Hbac1Log>) => {
    if (!data.measurement) {
      presentError(t('errors.diabetes.inputErrors.validRangeGlucose'));
      return;
    }

    if (data.measurement < 4 || data.measurement > 20) {
      setInputError({
        value: true,
        message: t('errors.diabetes.inputErrors.validRangeGlucose'),
      });
    } else {
      try {
        await addHbac1Log({
          logDate: data.logDate,
          measurement: data.measurement,
        })
          .unwrap()
          .then(() => {
            presentSuccess(t('errors.diabetes.successHba1c.logAdded'));
          })
          .finally(() => {
            if (
              goalProgress(Number(data.measurement), hbacGoalData.goal, hbacStartData) ===
                100 &&
              hbacGoalData.date !== ''
            ) {
              setTimeout(() => {
                dispatch(
                  addGoalDetails({
                    category: 'HbA1c',
                    goal: { value: hbacGoalData.goal, measure: '%' },
                    start: {
                      value: hbacStartData,
                      measure: '%',
                      time: hbacGoalData.date,
                    },
                  }),
                );
              }, 1500);
            }
          });
      } catch (e) {
        presentError(t('errors.diabetes.errorsHba1c.addLog'));
      }
      handleClose();
    }
  };

  const handleClose = () => {
    setModalDiabetesOpen(false);
    setInputError({
      value: false,
      message: '',
    });
  };

  const handleOpenDiabetesModal = () => {
    setModalDiabetesOpen(true);
  };

  const handleModalSaveClick = (data: Partial<BloodGlucoseLog & Hbac1Log>) => {
    if (selectedTab === 0) {
      handleAddBloodGlucoseLog({
        logDate: data.logDate,
        measurement: data.measurement,
        category: data.category === Filters.DEFAULT ? undefined : data.category,
      });
    } else {
      handleAddHbac1Log({
        logDate: data.logDate,
        measurement: data.measurement,
      });
    }
  };

  return (
    <PageLayout
      defaultHref={'/dashboard/home'}
      fabClick={handleOpenDiabetesModal}
      headerColor={theme.palette.diabetes.main}
      headerTitle={t('headingsTitles.diabetes')}
      headerIcon={<DiabetesHeaderIcon color={theme.palette.diabetes.main} />}
    >
      <ComponentsLayout>
        <CustomTabs
          value={selectedTab}
          setValue={setSelectedTab}
          tabs={[
            {
              label: t('headingsTitles.bloodGluc'),
              children: <DiabetesBloodGlucose />,
            },
            {
              label: t('headingsTitles.hba1c'),
              children: <DiabetesTabHBAC1 />,
            },
          ]}
        />
      </ComponentsLayout>
      <ModalMobile open={modalDiabetesOpen} setOpen={() => setModalDiabetesOpen(false)}>
        <ModalDiabetes
          error={inputError}
          isLoading={isLoading}
          triggerOCR={cb => handleOcr(cb)}
          isBloodGlucose={selectedTab === 0}
          handleModalSaveClick={handleModalSaveClick}
        />
      </ModalMobile>
    </PageLayout>
  );
};

export default Diabetes;
