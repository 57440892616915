import React, { useEffect, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import {
  useAddDiabetesGoalMutation,
  useGetBloodGlucoseCurrentMeasurementQuery,
  useGetBloodGlucoseGoalQuery,
  useGetBloodGlucoseGraphDataQuery,
  useGetBloodGlucoseOverallQuery,
} from './queries/diabetesQueries';
import {
  AllMeasurements,
  CalendarModal,
  ClickNavCardNutrition,
  DiabetesBloodGlucoseChart,
  ModalFilters,
  ModalMobile,
  weekMonthYearCustom,
} from '@hdcorner/ui-library';
import { useIonRouter } from '@ionic/react';
import { FilterOptions } from './constants';
import useOverallData from './hooks/useOverallData';
import useFilterLabel from './hooks/useFilterLabel';
import ModalAddEditGoal from '../../components/ModalAddEditGoal';
import moment from 'moment';
import useCurrentMeasurement from './hooks/useCurrentMeasurement';
import useGoalValue from './hooks/useGoalValue';
import useTimeframe from '../../hooks/useTimeframe';
import usePrepareGraph from './hooks/usePrepareGraph';
import useAlert from '../../hooks/useAlert';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../../utils/useConvertJSON';

const DiabetesBloodGlucose = () => {
  const theme = useTheme();
  const router = useIonRouter();
  const { t } = useTranslation();

  const { presentSuccess, presentError } = useAlert();

  const [filter, setFilter] = useState<string>('default');
  const [goalModalOpen, setGoalModalOpen] = useState<boolean>(false);
  const [filtersModalOpen, setFiltersModalOpen] = useState<boolean>(false);
  const [selectedTimeframe, setSelectedTimeframe] = useState<string>('all');
  const [calendarModalOpen, setCalendarModalOpen] = useState<boolean>(false);

  // Used for updating the params for the query
  const [endParam, setEndParam] = useState<string>('');
  const [startParam, setStartParam] = useState<string>('');

  const timeframe = useTimeframe(selectedTimeframe, startParam, endParam);

  const filterLabel = useFilterLabel(filter);

  const [addBloodGlucoseGoal] = useAddDiabetesGoalMutation();

  const { data: overallData, error: overallError } = useGetBloodGlucoseOverallQuery({
    category: filter === 'default' ? undefined : filter,
    ...timeframe,
  });
  const { data: bloodGlucoseGoal, error: bloodGlucoseGoalError } =
    useGetBloodGlucoseGoalQuery();
  const { data: currentMeasurements, error: currentError } =
    useGetBloodGlucoseCurrentMeasurementQuery();
  const { data: graphDataRes, error: graphDataError } = useGetBloodGlucoseGraphDataQuery({
    category: filter === 'default' ? undefined : filter,
    ...timeframe,
  });

  const graphData = usePrepareGraph(graphDataRes);
  const prepareOverallData = useOverallData(overallData);
  const prepareGoalValue = useGoalValue(bloodGlucoseGoal);
  const prepareCurrentMeasurement = useCurrentMeasurement(currentMeasurements);

  useEffect(() => {
    if (bloodGlucoseGoalError || currentError) {
      presentError(t('errors.diabetes.errorsGlucose.fetchGoal'));
    }

    if (graphDataError) {
      presentError(t('errors.diabetes.errorsGlucose.fetchGraph'));
    }

    if (overallError) {
      presentError(t('errors.diabetes.errorsGlucose.fetchOverall'));
    }
  }, [bloodGlucoseGoalError]);

  const handleClickGoal = () => {
    if (bloodGlucoseGoal && bloodGlucoseGoal.length > 0) {
      router.push('/dashboard/diabetes-blood-glucose-goal');
      return;
    }
    setGoalModalOpen(true);
  };

  const handleShowGraphData = () => {
    router.push('/dashboard/diabetes/graph-data/blood-glucose');
  };

  const handleShowInDepthData = () => {
    router.push('/dashboard/diabetes/blood-glucose-in-depth-data');
  };

  const onOpenCalendar = () => {
    if (selectedTimeframe === 'custom') {
      setCalendarModalOpen(true);
    }
  };

  const handleSaveClick = async (newData: any) => {
    setGoalModalOpen(false);
    await addBloodGlucoseGoal({
      goal: newData,
      goalType: 'blood_glucose',
      startDate: moment().startOf('day').toISOString(),
    })
      .unwrap()
      .then(() => presentSuccess(t('errors.diabetes.successGlucose.goalAdded')))
      .catch(() => presentError(t('errors.diabetes.errorsGlucose.goalAdded')));
  };

  const handleDateChange = (dateRange: string[]) => {
    setStartParam(dateRange[0]);
    setEndParam(dateRange[1]);
  };

  return (
    <>
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={theme.spacing(1)}
        marginTop={theme.spacing(3)}
      >
        <AllMeasurements text={filterLabel} openModal={() => setFiltersModalOpen(true)} />
        <DiabetesBloodGlucoseChart
          chartData={graphData}
          overallData={prepareOverallData}
          handleOpenCalendar={onOpenCalendar}
          selectedTimeframe={selectedTimeframe}
          selectTimeframe={setSelectedTimeframe}
          latestMeasurement={prepareCurrentMeasurement}
          headings={useConvertJSON(weekMonthYearCustom)}
        />
        <Box marginTop={theme.spacing(2)}>
          <ClickNavCardNutrition
            onClick={handleShowInDepthData}
            headings={t('buttons.navigation.inDepth')}
          />
          <ClickNavCardNutrition
            headings={t('buttons.navigation.graphData')}
            onClick={handleShowGraphData}
          />
          <ClickNavCardNutrition
            onClick={handleClickGoal}
            headings={t('headingsTitles.goal')}
            body={
              prepareGoalValue
                ? `${prepareGoalValue} ${t('measurements.mgdl')}`
                : 'Add Goal'
            }
          />
        </Box>
        {/*TODO: use this with bluetooth devices*/}
        {/*<CustomButton*/}
        {/*  fullWidth*/}
        {/*  disableElevation*/}
        {/*  color={'warning'}*/}
        {/*  variant={'contained'}*/}
        {/*  onClick={onClickConnectedDevice}*/}
        {/*  sx={{*/}
        {/*    justifyContent: 'flex-start',*/}
        {/*    marginTop: theme.spacing(2),*/}
        {/*  }}*/}
        {/*>*/}
        {/*  Connect Device*/}
        {/*</CustomButton>*/}
      </Box>
      <ModalFilters
        selected={filter}
        open={filtersModalOpen}
        dismiss={newValue => {
          setFilter(newValue);
          setFiltersModalOpen(false);
        }}
        options={useConvertJSON(FilterOptions)}
      />
      <ModalMobile open={goalModalOpen} setOpen={setGoalModalOpen}>
        <ModalAddEditGoal
          handleSaveClick={handleSaveClick}
          currentMeasurement={prepareCurrentMeasurement}
          desiredPlaceholder={`126 ${t('measurements.mgdl')}`}
        />
      </ModalMobile>
      <CalendarModal
        open={calendarModalOpen}
        saveDate={handleDateChange}
        setOpen={setCalendarModalOpen}
        selectedDate={[startParam, endParam]}
      />
    </>
  );
};

export default DiabetesBloodGlucose;
