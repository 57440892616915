import api from '../../../redux/api';
import {
  ApiPagination,
  BloodGlucoseLog,
  DiabetesGoal,
  Hbac1Log,
  PaginationResult,
} from '@hdcorner/ui-library';

const diabetesQueries = api
  .enhanceEndpoints({ addTagTypes: ['DiabetesGoal'] })
  .injectEndpoints({
    endpoints: build => ({
      addBloodGlucoseLog: build.mutation<BloodGlucoseLog[], Partial<BloodGlucoseLog>>({
        query: params => ({
          method: 'POST',
          body: { ...params },
          url: '/database/function/addBloodGlucoseLog',
        }),
        invalidatesTags: ['BloodGlucoseLog', 'Hbac1Log', 'Dashboard'],
      }),
      getBloodGlucoseLogs: build.query<
        PaginationResult<BloodGlucoseLog>,
        {
          params: ApiPagination & {
            category?: string;
            start?: string;
            end?: string;
          };
        }
      >({
        query: ({ params }) => ({
          method: 'GET',
          params: {
            ...params,
            sort: ['-logDate'],
            endDate: params.end,
            startingDate: params.start,
          },
          url: `/database/function/getBloodGlucoseLogs`,
        }),
        serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
          return endpointName;
        },
        merge: (currentCache, newItems, otherArgs) => {
          if (otherArgs.arg.params.skip === 0) {
            currentCache.documents = newItems?.documents;
          } else {
            currentCache?.documents.push(...newItems?.documents);
          }
        },
        forceRefetch: ({ previousArg, currentArg }) => {
          return currentArg !== previousArg;
        },
        providesTags: ['BloodGlucoseLog', 'Hbac1Log'],
      }),
      addHbac1Log: build.mutation<Hbac1Log[], Partial<BloodGlucoseLog>>({
        query: params => ({
          method: 'POST',
          body: { ...params },
          url: '/database/function/addHbacLog',
        }),
        invalidatesTags: ['BloodGlucoseLog', 'Hbac1Log', 'Dashboard'],
      }),
      getHbac1Logs: build.query<
        PaginationResult<Hbac1Log>,
        { params: ApiPagination & { start?: string; end?: string } }
      >({
        query: ({ params }) => ({
          method: 'GET',
          url: `/database/function/getHbacLogs`,
          params: {
            ...params,
            sort: ['-logDate'],
            endDate: params.end,
            startingDate: params.start,
          },
        }),
        serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
          return endpointName;
        },
        merge: (currentCache, newItems, otherArgs) => {
          if (otherArgs.arg.params.skip === 0) {
            currentCache.documents = newItems?.documents;
          } else {
            currentCache?.documents.push(...newItems?.documents);
          }
        },
        forceRefetch: ({ previousArg, currentArg }) => {
          return currentArg !== previousArg;
        },
        providesTags: ['BloodGlucoseLog', 'Hbac1Log'],
      }),
      getBloodGlucoseGoal: build.query<DiabetesGoal[], void>({
        query: () => ({
          method: 'GET',
          url: `/database/function/getUserGoal`,
          params: { goalType: 'blood_glucose' },
        }),
        providesTags: ['DiabetesGoal'],
      }),
      getHbacGoal: build.query<DiabetesGoal[], void>({
        query: () => ({
          method: 'GET',
          params: { goalType: 'hbac1' },
          url: `/database/function/getUserGoal`,
        }),
        providesTags: ['DiabetesGoal'],
      }),
      addDiabetesGoal: build.mutation<DiabetesGoal[], Partial<DiabetesGoal>>({
        query: ({ goal, goalType, startDate }) => ({
          method: 'POST',
          url: `/hdCornerService/user/goal`,
          body: { goal, goalType, startDate },
        }),
        invalidatesTags: ['DiabetesGoal'],
      }),
      editDiabetesGoal: build.mutation<DiabetesGoal[], Partial<DiabetesGoal>>({
        query: ({ _id, goal, goalType, startDate }) => ({
          method: 'PATCH',
          body: { goal, goalType, startDate },
          url: `/hdCornerService/user/goal/${_id}`,
        }),
        invalidatesTags: ['DiabetesGoal'],
      }),
      deleteDiabetesGoal: build.mutation<void, { id: string }>({
        query: ({ id }) => ({
          method: 'DELETE',
          url: `/database/UserGoal/${id}`,
        }),
        invalidatesTags: ['DiabetesGoal'],
      }),
      getBloodGlucoseOverall: build.query<
        { min: number; max: number; avg: number },
        { category?: string; start?: string; end?: string }
      >({
        query: ({ category, start, end }) => ({
          method: 'GET',
          params: { category, start, end },
          url: `/hdCornerService/user/blood-glucose/overall`,
        }),
        providesTags: ['Hbac1Log', 'BloodGlucoseLog'],
      }),
      getBloodGlucoseCurrentMeasurement: build.query<
        PaginationResult<BloodGlucoseLog>,
        void
      >({
        query: () => ({
          method: 'GET',
          url: `/database/function/getBloodGlucoseLogs`,
          params: {
            skip: 0,
            limit: 1,
            sort: ['-logDate'],
          },
        }),
        providesTags: ['Hbac1Log', 'BloodGlucoseLog'],
      }),
      getHbac1CurrentMeasurement: build.query<PaginationResult<Hbac1Log>, void>({
        query: () => ({
          method: 'GET',
          url: `/database/function/getHbacLogs`,
          params: {
            skip: 0,
            limit: 1,
            sort: '-logDate',
          },
        }),
        providesTags: ['Hbac1Log', 'BloodGlucoseLog'],
      }),
      getBloodGlucoseStartingMeasurement: build.query<
        PaginationResult<BloodGlucoseLog>,
        { startDate: string }
      >({
        query: ({ startDate }) => ({
          method: 'GET',
          params: {
            skip: 0,
            limit: 1,
            sort: 'logDate',
            startingDate: startDate,
          },
          url: `/database/function/getBloodGlucoseLogs`,
        }),
        providesTags: ['Hbac1Log', 'BloodGlucoseLog'],
      }),
      getHbac1StartingMeasurement: build.query<
        PaginationResult<Hbac1Log>,
        { startDate: string }
      >({
        query: ({ startDate }) => ({
          method: 'GET',
          url: `/database/function/getHbacLogs`,
          params: {
            skip: 0,
            limit: 1,
            sort: 'logDate',
            startingDate: startDate,
          },
        }),
        providesTags: ['Hbac1Log', 'BloodGlucoseLog'],
      }),
      getHbac1OverallData: build.query<
        { min: number; max: number; avg: number },
        { start?: string; end?: string } | void
      >({
        query: params => ({
          method: 'GET',
          params: { ...params },
          url: `/hdCornerService/user/hbac/overall`,
        }),
        providesTags: ['Hbac1Log', 'BloodGlucoseLog'],
      }),
      getBloodGlucoseGraphData: build.query<
        { logs: BloodGlucoseLog[] },
        { category?: string; start?: string; end?: string } | void
      >({
        query: params => ({
          method: 'GET',
          params: { ...params },
          url: `/hdCornerService/user/blood-glucose/graph`,
        }),
        providesTags: ['Hbac1Log', 'BloodGlucoseLog'],
      }),
      getHcac1GraphData: build.query<
        { logs: Hbac1Log[] },
        { start?: string; end?: string } | void
      >({
        query: params => ({
          method: 'GET',
          params: { ...params },
          url: `/hdCornerService/user/hbac/graph`,
        }),
        providesTags: ['Hbac1Log', 'BloodGlucoseLog'],
      }),
    }),
  });

export const {
  useGetHbacGoalQuery,
  useGetHbac1LogsQuery,
  useAddHbac1LogMutation,
  useGetHcac1GraphDataQuery,
  useAddDiabetesGoalMutation,
  useGetHbac1OverallDataQuery,
  useGetBloodGlucoseLogsQuery,
  useGetBloodGlucoseGoalQuery,
  useEditDiabetesGoalMutation,
  useAddBloodGlucoseLogMutation,
  useDeleteDiabetesGoalMutation,
  useGetBloodGlucoseOverallQuery,
  useGetBloodGlucoseGraphDataQuery,
  useGetHbac1CurrentMeasurementQuery,
  useGetHbac1StartingMeasurementQuery,
  useGetBloodGlucoseCurrentMeasurementQuery,
  useGetBloodGlucoseStartingMeasurementQuery,
} = diabetesQueries;

export default diabetesQueries;
