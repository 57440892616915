import { Box, Button, ButtonProps, styled, Typography } from '@mui/material';
import React, { FC } from 'react';
import { CustomButton, DeleteBucket, theme } from '@hdcorner/ui-library';
import { useTranslation } from 'react-i18next';

const RemoveButton = styled(Button)<ButtonProps>(({ theme }) => ({
  ...theme.typography.body2,
  border: 'none',
  height: '48px',
  borderRadius: '8px',
  boxShadow: theme.shadows[0],
  color: theme.palette.primary.main,
  background: theme.palette.secondary.light,
  fontsize: theme.typography.subtitle2.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
}));

type Props = {
  handleFirst: () => void;
  handleSecond: () => void;
  type?: 'edit' | 'cancel';
};

const GoalModalButtons: FC<Props> = ({ type = 'edit', handleFirst, handleSecond }) => {
  const { t } = useTranslation();

  return (
    <Box gap={3} flex={1} display={'flex'} justifyContent={'space-between'}>
      <RemoveButton fullWidth disableRipple onClick={handleFirst} variant={'outlined'}>
        <Box gap={1} display={'flex'} alignItems={'center'}>
          <DeleteBucket color={theme.palette.primary.main} />
          <Typography
            variant={'subtitle2'}
            sx={{ fontWeight: theme.typography.fontWeightRegular }}
          >
            {type === 'edit' ? t('buttons.remove') : t('buttons.cancel')}
          </Typography>
        </Box>
      </RemoveButton>
      <CustomButton fullWidth disableRipple variant={'contained'} onClick={handleSecond}>
        {type === 'edit' ? t('buttons.editGoal') : t('userDetails.yes')}
      </CustomButton>
    </Box>
  );
};

export default GoalModalButtons;
