import React, { FC } from 'react';
import ComponentsLayout from './ComponentsLayout';
import CustomButton from './controls/CustomButton';
import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

type DeactivateProps = {
  handleContinue: () => void;
};
const SettingsDeactivatePanelMobile: FC<DeactivateProps> = ({ handleContinue }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <ComponentsLayout height={'100%'}>
      <Box
        gap={2}
        height={'100%'}
        display={'flex'}
        textAlign={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        justifyContent={'space-between'}
      >
        <Typography variant={'subtitle1'} sx={{ color: theme.palette.primary.light }}>
          {t('profile.text.deactivate')}
        </Typography>
        <Box width={'280px'}>
          <CustomButton fullWidth variant={'contained'} onClick={handleContinue}>
            {t('buttons.continue')}
          </CustomButton>
        </Box>
      </Box>
    </ComponentsLayout>
  );
};

export default SettingsDeactivatePanelMobile;
