import { BloodGlucoseLog, Hbac1Log } from '@hdcorner/ui-library';
import { useMemo } from 'react';

const usePrepareGraph = (data?: { logs: BloodGlucoseLog[] | Hbac1Log[] }) => {
  return useMemo<{ x: string; y: number }[]>(() => {
    if (!data) return [];

    return data.logs.map(item => ({
      x: item.logDate,
      y: item.measurement,
    }));
  }, [data]);
};

export default usePrepareGraph;
