import { Box, IconButton } from '@mui/material';
import {
  AuthUser,
  capitalizeFirstLetter,
  CheckBoxes,
  CustomDateInput,
  CustomInput,
  DropdownMenu,
  SaveIcon,
  theme,
  UserData,
} from '@hdcorner/ui-library';
import React, { useEffect, useMemo, useState } from 'react';
import {
  useGetUserDataQuery,
  useUpdateAuthUserMutation,
  useUpdateUserDataMutation,
} from '../queries/personalInfoQueries';
import { useGetAuthUserQuery } from '../../authentication/queries/signInQueries';
import useAlert from '../../../hooks/useAlert';
import DatePicker from '../../../components/DatePicker';
import _ from 'lodash';
import { diabeticOptions } from '../constants';
import moment from 'moment';
import { useCreateWeightLogMutation } from '../../fitness/wellness/queries/weightQueries';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../../../utils/useConvertJSON';
import ProfileTypographyHeading from './ProfileTypographyHeading';

const WebGeneralInfo = () => {
  const { t } = useTranslation();
  const { presentSuccess, presentError } = useAlert();

  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<Partial<AuthUser & UserData>>({
    height: 0,
    weight: 0,
    gender: '',
    diabetes: 'no',
    smoker: false,
    lastName: '',
    firstName: '',
    birthDate: '',
    hypertension: false,
    dyslipidemia: false,
    underMedication: false,
    socialSecurityNumber: '',
  });

  const [logWeight] = useCreateWeightLogMutation();
  const [updateAuthUser, { error: updateAuthUserError }] = useUpdateAuthUserMutation();
  const [updateUserData, { error: updateUserDataError }] = useUpdateUserDataMutation();

  const { data: userData } = useGetUserDataQuery();
  const { data: authUserData } = useGetAuthUserQuery();

  useEffect(() => {
    let newUser: Partial<UserData> | undefined = undefined;
    let newAuthUser: Partial<AuthUser> | undefined = undefined;

    if (authUserData) {
      const authUser = authUserData[0];
      const date = moment(authUser.birthDate).format('YYYY-MM-DD');

      newAuthUser = {
        birthDate: date,
        gender: authUser.gender,
        lastName: authUser.lastName,
        firstName: authUser.firstName,
      };
    }

    if (userData) {
      const user = userData[0];
      newUser = {
        smoker: user.smoker,
        height: user.height,
        weight: user.weight,
        hypertension: user.hypertension,
        diabetes: user.diabetes || 'no',
        dyslipidemia: user.dyslipidemia,
        underMedication: user.underMedication,
        socialSecurityNumber: user.socialSecurityNumber,
      };
    }

    setUserDetails({ ...userDetails, ...newAuthUser, ...newUser });
  }, [authUserData, userData]);

  useEffect(() => {
    if (updateAuthUserError || updateUserDataError) {
      presentError(t('errors.profile.errorUpdateDetails'));
    }
  }, [updateAuthUserError, updateUserDataError]);

  const stateHasChanged = useMemo(() => {
    if (userData && authUserData) {
      const allUserDataInitial: Partial<AuthUser | UserData> = {
        smoker: userData[0].smoker,
        height: userData[0].height,
        weight: userData[0].weight,
        diabetes: userData[0].diabetes || 'no',
        hypertension: userData[0].hypertension,
        dyslipidemia: userData[0].dyslipidemia,
        underMedication: userData[0].underMedication,
        socialSecurityNumber: userData[0].socialSecurityNumber,

        gender: authUserData[0].gender,
        lastName: authUserData[0].lastName,
        firstName: authUserData[0].firstName,
        birthDate: moment(authUserData[0].birthDate).format('DD-MM-YYYY'),
      };

      return !_.isEqual(allUserDataInitial, userDetails);
    }

    return false;
  }, [userDetails, userData, authUserData]);

  const handleSave = async () => {
    try {
      await updateAuthUser({
        gender: userDetails.gender,
        lastName: userDetails.lastName,
        firstName: userDetails.firstName,
        birthDate: userDetails.birthDate,
      }).unwrap();

      await updateUserData({
        height: userDetails.height,
        weight: userDetails.weight,
        smoker: userDetails.smoker,
        diabetes: userDetails.diabetes,
        dyslipidemia: userDetails.dyslipidemia,
        hypertension: userDetails.hypertension,
        underMedication: userDetails.underMedication,
        socialSecurityNumber: userDetails.socialSecurityNumber,
      }).unwrap();

      const initialUserWeight = userData && userData[0].weight;
      const currentUserWeight = userDetails.weight;

      if (currentUserWeight && initialUserWeight !== currentUserWeight) {
        const logDate = moment().toISOString();
        await logWeight({ measurement: currentUserWeight, logDate }).unwrap();
      }

      presentSuccess(t('errors.profile.successUserDateUpdate'));
    } catch (e) {
      presentError(t('errors.profile.errorUserDataUpdate'));
    }
  };

  const handleChange = (fieldName: keyof Partial<AuthUser & UserData>, value: any) => {
    setUserDetails(oldValue => ({
      ...oldValue,
      [fieldName]: value,
    }));
  };

  return (
    <>
      <ProfileTypographyHeading text={'profile.titles.genInfo'} />
      <Box
        gap={3}
        padding={3}
        display={'flex'}
        borderRadius={'8px'}
        flexDirection={'column'}
        bgcolor={theme.palette.highlight.main}
      >
        <Box display={'flex'} justifyContent={'end'}>
          <IconButton
            onClick={handleSave}
            disabled={!stateHasChanged}
            sx={[
              {
                '&:disabled': {
                  opacity: 0.5,
                },
              },
            ]}
          >
            <SaveIcon color={theme.palette.kmColorsRed.main} />
          </IconButton>
        </Box>
        <Box display={'flex'} gap={2} justifyContent={'start'}>
          <Box
            gap={2}
            width={'33%'}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'space-between'}
          >
            <CustomInput
              fullWidth
              value={userDetails.firstName}
              label={`${t('userDetails.fName')}`}
              sx={{ marginBottom: theme.spacing(4.25) }}
              handleChange={value => handleChange('firstName', value)}
            />
            <CustomDateInput
              label={`${t('userDetails.dob')}`}
              selectedDate={userDetails.birthDate}
              handleCalendarOpen={() => setDatePickerOpen(true)}
            />
            <CustomInput
              fullWidth
              label={`${t('userDetails.amka')}`}
              value={userDetails.socialSecurityNumber}
              handleChange={value => handleChange('socialSecurityNumber', value)}
            />
          </Box>

          <Box
            gap={2}
            width={'33%'}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'space-between'}
          >
            <CustomInput
              fullWidth
              value={userDetails.lastName}
              label={`${t('userDetails.lName')}`}
              handleChange={value => handleChange('lastName', value)}
            />
            <CustomInput
              fullWidth
              type={'number'}
              value={userDetails.height}
              suffix={`${t('measurements.cm')}`}
              label={`${t('measurements.heightIn')}`}
              placeholder={`${t('measurements.heightEg')}`}
              handleChange={value => handleChange('height', value)}
            />

            <Box flex={1}>
              <DropdownMenu
                placeholder={'-'}
                menuItems={[
                  { label: t('userDetails.yes'), value: 'Yes' },
                  { label: t('userDetails.no'), value: 'No' },
                ]}
                value={userDetails.smoker ? 'Yes' : 'No'}
                label={`${capitalizeFirstLetter(t('userDetails.smoker'))}`}
                setValue={value => handleChange('smoker', value === 'Yes')}
              />
            </Box>
          </Box>

          <Box
            gap={2}
            width={'33%'}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'space-between'}
          >
            <Box flex={1}>
              <DropdownMenu
                placeholder={'-'}
                label={`${t('userDetails.sex')}`}
                value={userDetails.gender || '-'}
                menuItems={[
                  { label: t('userDetails.male'), value: 'male' },
                  { label: t('userDetails.female'), value: 'female' },
                ]}
                setValue={(value: string) => handleChange('gender', value)}
              />
            </Box>

            <CustomInput
              fullWidth
              type={'number'}
              value={userDetails.weight}
              suffix={`${t('measurements.kg')}`}
              label={`${t('measurements.weightIn')}`}
              placeholder={`${t('measurements.weightEg')}`}
              handleChange={value => handleChange('weight', value)}
            />

            <Box flex={1}>
              <DropdownMenu
                placeholder={'-'}
                value={userDetails.diabetes || 'no'}
                menuItems={useConvertJSON(diabeticOptions)}
                label={`${t('dashboard.dashcards.diabetes.title')}`}
                setValue={(value: string) => handleChange('diabetes', value)}
              />
            </Box>
          </Box>
        </Box>
        <Box gap={3} flexGrow={'1'} display={'flex'} flexWrap={'wrap'}>
          <CheckBoxes
            size={'web'}
            label={`${t('userDetails.hypertension')}`}
            checked={userDetails.hypertension || false}
            setChecked={value => handleChange('hypertension', value)}
          />
          <CheckBoxes
            size={'web'}
            label={`${t('userDetails.dyslipidemia')}`}
            checked={userDetails.dyslipidemia || false}
            setChecked={value => handleChange('dyslipidemia', value)}
          />

          <CheckBoxes
            size={'web'}
            label={`${t('userDetails.medicated')}`}
            checked={userDetails.underMedication || false}
            setChecked={value => handleChange('underMedication', value)}
          />
        </Box>
        <DatePicker
          open={datePickerOpen}
          setOpen={setDatePickerOpen}
          selectedDate={userDetails.birthDate || ''}
          handleSaveDate={() => setDatePickerOpen(false)}
          setSelectedDate={value => handleChange('birthDate', value)}
        />
      </Box>
    </>
  );
};

export default WebGeneralInfo;
