import api from '../../../redux/api';
import { PaginationResult, Quote } from '@hdcorner/ui-library';

const quotesQueries = api.injectEndpoints({
  endpoints: build => ({
    getQuotes: build.query<
      PaginationResult<Quote>,
      { associatedGoal: string; lang: string }
    >({
      query: ({ associatedGoal, lang }) => ({
        method: 'GET',
        url: '/database/function/getQuotes',
        params: { skip: 0, limit: 1, sort: ['-createdAt'], associatedGoal, lang },
      }),
    }),
  }),
});

export const { useGetQuotesQuery } = quotesQueries;

export default quotesQueries;
