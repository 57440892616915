import moment from 'moment/moment';
import { useIonRouter } from '@ionic/react';
import React, { useEffect, useMemo, useState } from 'react';
import ModalSliderWeight from './ModalSliderWeight';
import SectionHeading from '../../components/SectionHeading';
import { Box, BoxProps, styled, useMediaQuery } from '@mui/material';
import ModalRemoveGoal from '../../../../components/ModalRemoveGoal';
import GoalModalButtons from '../../../../components/GoalModalButtons';
import { GoalDetailsCard, ModalMobile, theme } from '@hdcorner/ui-library';
import {
  useDeleteUserGoalWeightMutation,
  useGetFirstWeightLogQuery,
  useGetLatestWeightLogsQuery,
  useGetUserGoalWeightQuery,
} from '../queries/weightQueries';
import useAlert from '../../../../hooks/useAlert';
import { goalProgress } from '../../../../utils/goalProgress';
import { useTranslation } from 'react-i18next';

const BoxInner = styled(Box, { shouldForwardProp: propName => propName !== 'web' })<
  BoxProps & { web: boolean }
>(({ web, theme }) => ({
  display: 'flex',
  borderRadius: '8px',
  flexDirection: 'column',
  boxSizing: 'border-box',
  gap: web ? 0 : theme.spacing(1),
  padding: theme.spacing(web ? 1 : 0),
  backgroundColor: web ? theme.palette.secondary.light : 'transparent',
}));

const SectionGoalWeight = () => {
  const router = useIonRouter();
  const { presentSuccess, presentError } = useAlert();
  const { t } = useTranslation();
  const web = useMediaQuery(theme.breakpoints.up('md'));

  const [open, setOpen] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);

  const [deleteWeightGoal] = useDeleteUserGoalWeightMutation();

  const { data: getGoalRes, error: errorGoal } = useGetUserGoalWeightQuery();
  const { data: lastLogData, error: errorLatest } = useGetLatestWeightLogsQuery();
  const { data: firstLog, error: errorFirst } = useGetFirstWeightLogQuery(
    {
      start: getGoalRes && getGoalRes.length > 0 ? getGoalRes[0].startDate : undefined,
    },
    { skip: !getGoalRes || getGoalRes.length === 0 },
  );

  useEffect(() => {
    if (errorLatest || errorGoal || errorFirst) {
      presentError(t('errors.fitness.wellness.errorWeightGoalFetch'));
    }
  }, [errorLatest, errorGoal, errorFirst, t]);

  const startWeight = useMemo(() => {
    if (!firstLog || firstLog.documents.length === 0) {
      if (!lastLogData || lastLogData.documents.length === 0) return 0;
      return lastLogData.documents[0].weight;
    }
    return firstLog.documents[0].weight;
  }, [firstLog, lastLogData]);

  const lastLog = useMemo(() => {
    if (!lastLogData || lastLogData.documents.length === 0) return 0;
    return lastLogData.documents[0].weight;
  }, [lastLogData]);

  const goalData = useMemo(() => {
    if (!getGoalRes || getGoalRes.length === 0)
      return [
        {
          label: t('headingsTitles.goal'),
          value: `-`,
        },
        {
          label: t('goals.start'),
          value: `-`,
        },
        {
          label: t('goals.startingMeasurement'),
          value: `-`,
        },
        {
          label: t('goals.latestMeasurement'),
          value: `${lastLog} ${t('measurements.kg')}`,
        },
        {
          label: t('goals.progress'),
          value: `-`,
        },
      ];

    const goalWeight = getGoalRes[0].goal.measurement;
    const progress = goalProgress(lastLog, goalWeight, startWeight);

    return [
      {
        label: t('headingsTitles.goal'),
        value: `${getGoalRes[0].goal.measurement} ${t('measurements.kg')}`,
      },
      {
        label: t('goals.start'),
        value: moment(getGoalRes[0].startDate).format('MMMM Do, YYYY'),
      },
      {
        label: t('goals.startingMeasurement'),
        value: `${startWeight} ${t('measurements.kg')}`,
      },
      {
        label: t('goals.latestMeasurement'),
        value: `${lastLog} ${t('measurements.kg')}`,
      },
      {
        label: t('goals.progress'),
        value: `${progress}%`,
      },
    ];
  }, [getGoalRes, lastLog, startWeight, t]);

  const handleRemoveGoal = () => {
    setOpenDelete(true);
  };

  const handleAddEditGoal = () => {
    setOpen(true);
  };

  const handleDeleteGoal = () => {
    if (!getGoalRes || getGoalRes.length === 0) return;
    const goal = getGoalRes[0];
    if (getGoalRes) {
      deleteWeightGoal({ _id: goal._id ? goal._id : '' })
        .unwrap()
        .then(() => {
          setOpenDelete(false);
          router.push('/dashboard/fitness/wellness');
          presentSuccess(t('errors.fitness.wellness.successWeightGoalDelete'));
        })
        .catch(() => {
          setOpenDelete(false);
          presentError(t('errors.fitness.wellness.errorWeightGoalDelete'));
        });
    }
  };

  return (
    <Box gap={1} display={'flex'} width={'100%'} flexDirection={'column'}>
      <Box display={web ? 'block' : 'none'}>
        <SectionHeading
          displayGoalButtons
          handleAdd={handleAddEditGoal}
          handleEdit={handleAddEditGoal}
          handleRemove={handleRemoveGoal}
          heading={t('headingsTitles.goal')}
          hasGoal={!!(getGoalRes && getGoalRes.length > 0)}
        />
      </Box>
      <BoxInner web={web}>
        {goalData.map((it, index) => (
          <Box key={`${it}${index}`}>
            <GoalDetailsCard goal={it} />
          </Box>
        ))}
        <Box
          mt={2}
          flex={1}
          width={'100%'}
          display={web ? 'none' : 'flex'}
          justifyContent={'space-between'}
        >
          <GoalModalButtons
            handleFirst={handleRemoveGoal}
            handleSecond={handleAddEditGoal}
          />
        </Box>
      </BoxInner>
      <ModalSliderWeight log={false} open={open} setOpen={setOpen} />
      <ModalMobile open={openDelete} setOpen={setOpenDelete}>
        <ModalRemoveGoal
          handleRemoveClick={handleDeleteGoal}
          heading={`${t('userDetails.weight')}`}
          handleCancelClick={() => setOpenDelete(false)}
        />
      </ModalMobile>
    </Box>
  );
};

export default SectionGoalWeight;
