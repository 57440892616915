import { Box, Typography } from '@mui/material';
import { CustomButton, ModalDeactivateAccount, theme } from '@hdcorner/ui-library';
import React, { useState } from 'react';
import {
  useDeleteAuthUserMutation,
  useDeleteUserDataMutation,
  useUpgradeTokenMutation,
} from '../queries/accountSettingsQueries';
import { useIonRouter } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import ProfileTypographyHeading from './ProfileTypographyHeading';
import { logout } from '../../authentication/authSlice';
import { useAppDispatch } from '../../../redux/hooks';

const WebDeactivatePanel = () => {
  const dispatch = useAppDispatch();
  const router = useIonRouter();
  const { t } = useTranslation();

  const [open, setOpen] = useState<boolean>(false);

  const [deleteUser] = useDeleteAuthUserMutation();
  const [upgradeToken] = useUpgradeTokenMutation();
  const [deleteUserData] = useDeleteUserDataMutation();

  const handleContinue = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleDeactivate = async () => {
    try {
      await upgradeToken();
      await deleteUserData();
      await deleteUser();
      setOpen(false);
      dispatch(logout());
      router.push('/', 'root', 'replace');
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <ProfileTypographyHeading text={'profile.titles.deactivate'} />
      <Box
        gap={3}
        flexGrow={1}
        display={'flex'}
        borderRadius={'8px'}
        flexDirection={'column'}
        boxSizing={'border-box'}
        padding={theme.spacing(3)}
        bgcolor={theme.palette.highlight.main}
      >
        <Typography variant={'body1'} sx={{ color: theme.palette.primary.light }}>
          {t('profile.text.deactivate')}
        </Typography>
        <CustomButton
          fullWidth
          variant={'contained'}
          onClick={handleContinue}
          sx={{
            maxWidth: '223px',
          }}
        >
          {t('profile.titles.deactivate')}
        </CustomButton>
      </Box>
      <ModalDeactivateAccount
        open={open}
        setOpen={setOpen}
        handleCancel={handleCancel}
        handleDeactivate={handleDeactivate}
      />
    </>
  );
};

export default WebDeactivatePanel;
