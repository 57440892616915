import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

const MetricsTable = ({
  title,
  headings,
  data,
}: {
  title?: string;
  headings?: string[];
  data: any[];
}) => (
  <Box display={'flex'} flexDirection={'column'} gap={2}>
    <Box>
      <Typography variant={'body1'} fontWeight={'bold'}>
        {title}
      </Typography>
    </Box>
    <Box>
      <Table>
        <TableHead>
          <TableRow>
            {headings?.map((heading, index) => (
              <TableCell sx={{ bgcolor: '#FBFBFB' }} key={index}>
                {heading}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              {row.map((cell: any, index: number) => (
                <TableCell sx={{ bgcolor: '#FFFFFF' }} key={index}>
                  {cell}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  </Box>
);

export default MetricsTable;
