import PageLayout from '../../../components/PageLayout';
import { ComponentsLayout } from '@hdcorner/ui-library';
import { Box } from '@mui/material';
import TargetWorkout from './components/TargetWorkout';
import { useTranslation } from 'react-i18next';

const FitnessWorkoutType = () => {
  const { t } = useTranslation();

  return (
    <PageLayout
      defaultHref={'/dashboard/fitness/workouts'}
      headerTitle={t('headingsTitles.workoutType')}
    >
      <ComponentsLayout>
        <Box display={'flex'} flexDirection={'column'} gap={3}>
          <TargetWorkout />
        </Box>
      </ComponentsLayout>
    </PageLayout>
  );
};

export default FitnessWorkoutType;
