export const getScreenName = (path: string) => {
  switch (path) {
    case '/home':
      return '';
    case '/user-data':
      return 'Data';
    case '/healthProfessionals':
      return 'Health Care Professionals';
    case '/subscription':
      return 'Subscription';
    case '/wallet':
      return 'Wallet';
    case '/records':
      return 'My records';
    case '/devices':
      return 'My devices';
    case '/chat':
      return 'Chat';
    case '/profile-settings':
      return 'Profile Settings';
    default:
      return '';
  }
};
