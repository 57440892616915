import React, { FC } from 'react';
import {
  Box,
  BoxProps,
  Dialog,
  DialogProps,
  Slide,
  Typography,
  TypographyProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';
import { Notification } from '../types/Notification';
import NotificationsList from './notifications/NotificationsList';
import { useTranslation } from 'react-i18next';

const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  padding: theme.spacing(1, 2, 1, 2),
  textTransform: lang === 'el' ? 'capitalize' : 'uppercase',
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.kmColorsCoolGrey.main
      : theme.palette.primary.main,
}));

const DialogMain = styled(Dialog)<DialogProps>(() => ({
  '.MuiDialog-paper': {
    boxShadow: '0px 1px 24px 0px rgba(0, 0, 0, 0.08)',
    top: 56,
    right: 0,
    borderRadius: 8,
    position: 'absolute',
  },
}));

const Container = styled(Box)<BoxProps>(({ theme }) => ({
  width: 320,
  background:
    theme.palette.mode === 'dark'
      ? theme.palette.secondary.main
      : theme.palette.secondary.light,
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

type NotificationsModalType = {
  open: boolean;
  notifications: Notification[];
  setOpen: (bool: boolean) => void;
  onNotificationClick: (id: string) => void;
};
const NotificationsDialog: FC<NotificationsModalType> = ({
  open,
  setOpen,
  notifications,
  onNotificationClick,
}) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <DialogMain
      open={open}
      hideBackdrop
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <Container>
        <Title variant={'body1'}>{t('notifications.titles.notifications')}</Title>
        <NotificationsList
          data={notifications}
          onNotificationClick={onNotificationClick}
        />
      </Container>
    </DialogMain>
  );
};

export default NotificationsDialog;
