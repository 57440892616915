import * as React from 'react';
import { SVGProps } from 'react';
import { useTheme } from '@mui/material';

const FEAlcoholIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.6667 0.333984C9.89853 0.334954 9.15421 0.600711 8.55917 1.08646C7.96412 1.57222 7.55473 2.24826 7.4 3.00065H1.33333C1.2015 3.00068 1.07263 3.03979 0.963028 3.11305C0.853421 3.18631 0.767995 3.29041 0.717548 3.41221C0.667102 3.53401 0.6539 3.66804 0.679611 3.79734C0.705323 3.92664 0.768794 4.04542 0.862 4.13865L5.33333 8.60998V12.334H3.33333C3.15652 12.334 2.98695 12.4042 2.86193 12.5292C2.73691 12.6543 2.66667 12.8238 2.66667 13.0007C2.66667 13.1775 2.73691 13.347 2.86193 13.4721C2.98695 13.5971 3.15652 13.6673 3.33333 13.6673H8.66667C8.84348 13.6673 9.01305 13.5971 9.13807 13.4721C9.2631 13.347 9.33333 13.1775 9.33333 13.0007C9.33333 12.8238 9.2631 12.6543 9.13807 12.5292C9.01305 12.4042 8.84348 12.334 8.66667 12.334H6.66667V8.60998L8.82867 6.44798C9.2623 6.73464 9.75697 6.91592 10.2731 6.97732C10.7893 7.03872 11.3127 6.97854 11.8015 6.8016C12.2903 6.62467 12.7309 6.33587 13.0882 5.95828C13.4454 5.58069 13.7094 5.12478 13.8591 4.62696C14.0087 4.12915 14.0399 3.60324 13.9501 3.09124C13.8602 2.57925 13.6519 2.09535 13.3417 1.67823C13.0315 1.2611 12.6281 0.922304 12.1636 0.688899C11.6991 0.455494 11.1865 0.333952 10.6667 0.333984ZM8 4.33398H9.05733L7.35667 6.03465C6.02 5.75332 5.16467 5.27998 3.82733 5.21865L2.94267 4.33398H8ZM10.6667 5.66732C10.3682 5.66784 10.0734 5.60131 9.804 5.47265L11.1373 4.13932C11.2307 4.04616 11.2944 3.92739 11.3203 3.79803C11.3461 3.66868 11.333 3.53456 11.2826 3.41265C11.2322 3.29074 11.1468 3.18652 11.0372 3.11318C10.9275 3.03984 10.7986 3.00068 10.6667 3.00065H8.78067C8.90288 2.65497 9.11777 2.34954 9.40185 2.11774C9.68592 1.88594 10.0283 1.73669 10.3914 1.6863C10.7546 1.63591 11.1246 1.68632 11.4611 1.83202C11.7975 1.97773 12.0875 2.21311 12.2992 2.51246C12.5109 2.81181 12.6362 3.16359 12.6615 3.52937C12.6868 3.89514 12.6111 4.26083 12.4426 4.58647C12.2741 4.9121 12.0193 5.18515 11.7061 5.37577C11.3929 5.56639 11.0333 5.66725 10.6667 5.66732Z"
        fill={
          theme.palette.mode === 'dark'
            ? theme.palette.kmColorsCoolGrey.main
            : theme.palette.primary.main
        }
      />
    </svg>
  );
};

export default FEAlcoholIcon;
