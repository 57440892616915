import React, { CSSProperties, FC, useState } from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Collapse,
  Typography,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SuccessIcon from '../../assets/SuccessIcon';
import ErrorIcon from '../../assets/ErrorIcon';
import WarningIcon from '../../assets/WarningIcon';
import { ProgressEnum } from '../../enums/ProgressEnum';
import { ItemsType } from '../../types/ItemType';
import CustomButton from '../controls/CustomButton';
import DeleteIcon from '../../assets/DeleteIcon';
import { useTranslation } from 'react-i18next';

const DataItem: FC<{
  title: string;
  value: string;
  details?: string;
}> = ({ title, value, details }) => {
  const theme = useTheme();

  return (
    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
      <Box display={'flex'} alignItems={'center'}>
        <Typography
          variant={'subtitle2'}
          sx={{ color: theme.palette.kmColorsDarkGrey.main }}
        >
          {title}
        </Typography>
        <Typography
          variant={'body1'}
          sx={{
            marginLeft: theme.spacing(1),
            color: theme.palette.kmColorsCoolGrey.main,
          }}
        >
          {details}
        </Typography>
      </Box>
      <Typography variant={'body1'} sx={{ color: theme.palette.primary.light }}>
        {value}
      </Typography>
    </Box>
  );
};

const SectionDataItem: FC<{
  title: string;
  values: ItemsType;
  details?: string;
  color?: CSSProperties['color'];
}> = ({ title, values, details, color }) => {
  const theme = useTheme();

  const dataToArray = Object.entries(values);

  return (
    <Box>
      <Box display={'flex'} marginBottom={theme.spacing(1)}>
        <Typography
          variant={'subtitle2'}
          sx={{ fontWeight: theme.typography.fontWeightRegular, color: color }}
        >
          {title}
        </Typography>
        <Typography
          variant={'subtitle2'}
          sx={{
            marginLeft: theme.spacing(1),
            fontWeight: theme.typography.fontWeightRegular,
          }}
        >
          {details}
        </Typography>
      </Box>
      {dataToArray?.map(([key, val], index) => (
        <Box key={index} marginTop={index !== 0 ? theme.spacing(1) : theme.spacing(0)}>
          <DataItem value={val.value} title={key} details={val.details} />
        </Box>
      ))}
    </Box>
  );
};

type SectionsItemsType = Record<
  string,
  { values: ItemsType; details?: string; color?: CSSProperties['color'] }
>;

type CollapsibleType = {
  title: string;
  text?: string;
  icon?: boolean;
  data?: ItemsType;
  titleSub?: string;
  textOnly?: boolean;
  progress?: ProgressEnum;
  sectionData?: SectionsItemsType;
  children?: React.ReactNode;
  handleRemove?: () => void;
};
const Collapsible: FC<CollapsibleType> = ({
  text,
  icon,
  data,
  textOnly,
  progress,
  title = '',
  sectionData,
  titleSub = '',
  handleRemove,
  children,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const [expanded, setExpanded] = useState<boolean>(false);

  const handleColor = (progress: CollapsibleType['progress']) => {
    switch (progress) {
      case ProgressEnum.error:
        return theme.palette.error.main;
      case ProgressEnum.success:
        return theme.palette.success.main;
      case ProgressEnum.warning:
        return theme.palette.warning.main;
      case ProgressEnum.risk:
        return theme.palette.hypertension.main;
      default:
        return theme.palette.primary.main;
    }
  };

  const handleIcon = (progress: CollapsibleType['progress']) => {
    const color = handleColor(progress);
    switch (progress) {
      case 'success':
        //CVD low risk
        return <SuccessIcon fill={color} />;
      case 'warning':
        //CVD warning risk
        return <WarningIcon fill={color} />;
      case 'risk':
        //CVD high risk
        return <WarningIcon fill={color} />;
      case 'error':
        //CVD very high risk
        return <ErrorIcon fill={color} />;
      default:
        return null;
    }
  };

  return (
    <Card
      raised={false}
      sx={{
        flex: 1,
        display: 'flex',
        boxShadow: 'none',
        borderRadius: '8px',
        flexDirection: 'column',
        padding: theme.spacing(0),
      }}
    >
      <CardActionArea
        onClick={() => setExpanded(!expanded)}
        sx={{ padding: theme.spacing(1, 2) }}
      >
        <CardHeader
          sx={{ padding: theme.spacing(0) }}
          title={
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
              <Box gap={1} display={'flex'} alignItems={'center'}>
                {icon && handleIcon(progress)}
                <Typography
                  variant={'subtitle2'}
                  sx={{
                    flex: 1,
                    display: 'flex',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    alignItems: 'center',
                    textOverflow: 'ellipsis',
                    maxWidth: lang === 'el' ? '150px' : 'none',
                    fontWeight: theme.typography.fontWeightRegular,
                    color: icon ? theme.palette.primary.main : handleColor(progress),
                  }}
                >
                  {title}
                </Typography>
                {titleSub && (
                  <Typography
                    variant={'body1'}
                    sx={{
                      color: theme.palette.kmColorsCoolGrey.main,
                    }}
                  >
                    {titleSub}
                  </Typography>
                )}
              </Box>
              <Typography
                variant={'body1'}
                sx={{
                  // width: '77px',
                  whiteSpace: 'nowrap',
                  color: theme.palette.kmColorsCoolGrey.main,
                }}
              >
                {expanded ? t('buttons.hide') : t('buttons.reveal')}
              </Typography>
            </Box>
          }
          action={
            <Box
              sx={{
                marginLeft: 'auto',
                color: theme.palette.primary.main,
                transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
                transition: theme.transitions.create('transform', {
                  duration: theme.transitions.duration.shortest,
                }),
              }}
            >
              <ExpandMoreIcon />
            </Box>
          }
        />
      </CardActionArea>
      {(data || sectionData || text) && (
        <Collapse in={expanded} timeout={'auto'} unmountOnExit>
          <CardContent
            sx={{
              background: theme.palette.highlight.main,
              padding: theme.spacing(1, 2, 1, 2),
              '&.MuiCardContent-root:last-child': {
                paddingBottom: theme.spacing(1),
              },
            }}
          >
            {data &&
              Object.entries(data).map(([key, val], index) => (
                <Box
                  key={index}
                  marginTop={index !== 0 ? theme.spacing(1) : theme.spacing(0)}
                >
                  <DataItem value={val.value} title={key} details={val.details} />
                </Box>
              ))}

            {sectionData &&
              Object.entries(sectionData)?.map(([key, val], index) => (
                <Box
                  key={index}
                  marginTop={index !== 0 ? theme.spacing(2) : theme.spacing(0)}
                >
                  <SectionDataItem
                    title={key}
                    color={val.color}
                    values={val.values}
                    details={val.details}
                  />
                </Box>
              ))}

            {textOnly && (
              <Box>
                <Typography
                  variant={'subtitle2'}
                  sx={{
                    color: theme.palette.primary.light,
                    fontWeight: theme.typography.fontWeightRegular,
                  }}
                >
                  {text}
                </Typography>
              </Box>
            )}
            {handleRemove && (
              <Box display={'flex'} justifyContent={'center'}>
                <Box width={'33%'}>
                  <CustomButton
                    fullWidth
                    variant={'contained'}
                    onClick={handleRemove}
                    startIcon={<DeleteIcon fill={'#FFFFFF'} />}
                    style={{
                      color: theme.palette.secondary.light,
                      backgroundColor: theme.palette.error.main,
                    }}
                  >
                    {t('buttons.delete')}
                  </CustomButton>
                </Box>
              </Box>
            )}
            {children}
          </CardContent>
        </Collapse>
      )}
    </Card>
  );
};

export default Collapsible;
