import React, { FC } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { SexType } from '../types/SexType';
import AvatarFemale from './AvatarFemale';
import AvatarMale from './AvatarMale';
import { theme } from '@hdcorner/ui-library';

type Props = {
  sex: SexType;
  selected: boolean;
  onSelect: (bool: boolean) => void;
};
const AvatarSelector: FC<Props> = ({ sex, selected, onSelect }) => {
  const web = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box width={'100%'} height={'100%'} onClick={() => onSelect(!selected)}>
      {sex === SexType.MALE ? (
        <AvatarMale
          width={web ? '155px' : '137px'}
          height={web ? '426px' : '378px'}
          selected={selected ? 'whole_body' : 'default'}
        />
      ) : (
        <AvatarFemale
          width={web ? '182px' : '162px'}
          height={web ? '426px' : '378px'}
          selected={selected ? 'whole_body' : 'default'}
        />
      )}
    </Box>
  );
};

export default AvatarSelector;
