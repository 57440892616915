export enum SexType {
  MALE = 'male',
  FEMALE = 'female',
}

export const sexLabel = (type: SexType) => {
  switch (type) {
    case SexType.MALE:
      return 'userDetails.male';
    case SexType.FEMALE:
      return 'userDetails.female';
  }
};
