import { useMemo } from 'react';

const useType = (paramType: string) => {
  return useMemo<string>(() => {
    return paramType === 'trg'
      ? 'triglycerides'
      : paramType === 'chol'
      ? 'totalChol'
      : paramType;
  }, [paramType]);
};

export default useType;
