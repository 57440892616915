import { Box } from '@mui/material';
import { useEffect } from 'react';
import {
  CapacitorHealthkit,
  OtherData,
  SampleNames,
  SleepData,
} from '@perfood/capacitor-healthkit';
import { isPlatform } from '@ionic/react';
import moment from 'moment';
import {
  useCreateStepsLogMutation,
  useLazyGetTodayStepsLogsQuery,
  useUpdateStepsLogMutation,
} from '../fitness/exercise/queries/stepsQueries';
import { SleepLog, StepsLog } from '@hdcorner/ui-library';
import {
  useCreateSleepLogMutation,
  useLazyGetTodayLastSleepLogQuery,
  useUpdateSleepLogMutation,
} from '../fitness/wellness/queries/sleepQueries';
import { useTranslation } from 'react-i18next';
import useAlert from '../../hooks/useAlert';

const READ_PERMISSIONS = ['activity', 'steps'];

const AppleHealth = () => {
  const { t } = useTranslation();
  const { presentError } = useAlert();
  const isIOS = isPlatform('ios');
  const isCapacitor = isPlatform('capacitor');

  const [createStepsLog] = useCreateStepsLogMutation();
  const [updateStepsLog] = useUpdateStepsLogMutation();
  const [getTodaySteps] = useLazyGetTodayStepsLogsQuery();

  const [createSleepLog] = useCreateSleepLogMutation();
  const [updateSleepLog] = useUpdateSleepLogMutation();
  const [getTodaySleepLogs] = useLazyGetTodayLastSleepLogQuery();

  useEffect(() => {
    if (isIOS && isCapacitor) {
      requestAuthorization();
    }
  }, [isCapacitor, isIOS]);

  const requestAuthorization = async () => {
    try {
      await CapacitorHealthkit.requestAuthorization({
        all: [''],
        write: [''],
        read: READ_PERMISSIONS,
      });
      manageStepsData();
      manageSleepData();
    } catch (error) {
      presentError(t('appleHealth.error'));
      console.error('[HealthKitService] Error getting Authorization:', error);
    }
  };

  const getStepsData = async () => {
    try {
      const queryOptions = {
        limit: 0,
        sampleName: SampleNames.STEP_COUNT,
        endDate: moment().endOf('day').toISOString(),
        startDate: moment().startOf('day').toISOString(),
      };

      return await CapacitorHealthkit.queryHKitSampleType<OtherData>(queryOptions);
    } catch (error) {
      console.error(error);
      presentError(t('appleHealth.error'));
      return undefined;
    }
  };

  const getSleepData = async () => {
    try {
      const queryOptions = {
        limit: 0,
        sampleName: SampleNames.SLEEP_ANALYSIS,
        endDate: moment().endOf('day').toISOString(),
        startDate: moment().startOf('day').toISOString(),
      };
      return await CapacitorHealthkit.queryHKitSampleType<SleepData>(queryOptions);
    } catch (error) {
      console.error(error);
      presentError(t('appleHealth.error'));
      return undefined;
    }
  };

  const manageStepsData = async () => {
    try {
      const data = await getStepsData();
      if (!data) {
        console.log('No steps data retrieved from Apple Health');
        return;
      }

      let totalSteps = 0;
      data.resultData.forEach(item => {
        totalSteps += item.value;
      });

      console.log('Steps data retrieved from Apple Health', totalSteps);
      const steps = (await getTodaySteps({ fromDevice: true }).unwrap()) as any;
      if (steps?.documents?.length === 0) {
        console.log('No steps log found for today, creating one');
        const newStepLog: Omit<StepsLog, '_id' | 'createdAt'> = {
          fromDevice: true,
          steps: totalSteps,
          logDate: moment().toISOString(),
        };
        await createStepsLog({ ...newStepLog }).unwrap();
      } else {
        console.log('Steps log found for today, updating it');
        const stepLog = steps.documents[0];
        await updateStepsLog({
          _id: stepLog._id,
          steps: totalSteps,
        }).unwrap();
      }
    } catch (error) {
      console.error(error);
      presentError(t('appleHealth.errorSteps'));
    }
  };

  const manageSleepData = async () => {
    try {
      const data = await getSleepData();
      if (!data) {
        console.log('No sleep data retrieved from Apple Health');
        return;
      }

      let totalSleep = 0;
      const asleepData = data.resultData.filter(item => item.sleepState === 'Asleep');
      const dataFromApple = asleepData.filter(item =>
        item.sourceBundleId.includes('apple.health'),
      );
      dataFromApple.forEach(item => {
        totalSleep += item.duration;
      });

      console.log('Sleep data retrieved from Apple Health', totalSleep);
      const sleepLogs = (await getTodaySleepLogs({ fromDevice: true }).unwrap()) as any;
      if (sleepLogs?.documents?.length === 0) {
        console.log('No sleep log found for today, creating one');
        const newSleepLog: Omit<SleepLog, '_id'> = {
          fromDevice: true,
          quantity: totalSleep,
          logDate: moment().toISOString(),
        };
        await createSleepLog({ params: { ...newSleepLog } }).unwrap();
      } else {
        console.log('Sleep log found for today, updating it');
        const sleepLog = sleepLogs.documents[0];
        await updateSleepLog({
          _id: sleepLog._id,
          quantity: totalSleep,
        }).unwrap();
      }
    } catch (error) {
      console.error(error);
      presentError(t('appleHealth.errorSleep'));
    }
  };

  return (
    <Box hidden>
      {isIOS && t('appleHealth.isIos')}
      {!isIOS && t('appleHealth.isNotIos')}
    </Box>
  );
};

export default AppleHealth;
