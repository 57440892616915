import api from '../../../redux/api';
import { AuthUser, QuestionnaireAnswer, UserData } from '@hdcorner/ui-library';

const onBoardingQueries = api.injectEndpoints({
  endpoints: build => ({
    setTermsAndConditions: build.mutation<
      AuthUser,
      Pick<AuthUser, 'termsAndConditionsAccepted' | 'privacyPolicyAccepted'>
    >({
      query: ({ termsAndConditionsAccepted, privacyPolicyAccepted }) => ({
        method: 'PUT',
        url: '/database/function/setAuthUser',
        body: { termsAndConditionsAccepted, privacyPolicyAccepted },
      }),
      invalidatesTags: ['AuthUser'],
    }),

    setOnboardingOne: build.mutation<AuthUser, Partial<AuthUser>>({
      query: ({ firstName, lastName, gender, birthDate }) => ({
        method: 'PUT',
        url: '/database/function/setAuthUser',
        body: { firstName, lastName, gender, birthDate, role: 'user' },
      }),
      invalidatesTags: ['AuthUser'],
    }),

    setOnboardingTwo: build.mutation<UserData, Partial<UserData>>({
      query: ({
        phone,
        height,
        weight,
        smoker,
        diabetes,
        hypertension,
        dyslipidemia,
        underMedication,
        socialSecurityNumber,
      }) => ({
        method: 'POST',
        url: '/database/function/createUserData',
        body: {
          phone,
          height,
          weight,
          smoker,
          diabetes,
          hypertension,
          dyslipidemia,
          underMedication,
          socialSecurityNumber,
        },
      }),
      invalidatesTags: ['UserData'],
    }),

    setHealthQuestionnaire: build.mutation<UserData, QuestionnaireAnswer>({
      query: ({ answer, resultType, outcome }) => ({
        method: 'PATCH',
        url: '/database/function/setQuestionnaireAnswer',
        body: {
          answer,
          outcome,
          resultType,
        },
      }),
      invalidatesTags: ['UserData'],
    }),

    setTutorialCompleted: build.mutation<void, void>({
      query: () => ({
        method: 'PATCH',
        url: '/database/function/setTutorialCompleted',
      }),
      invalidatesTags: ['AuthUser'],
    }),
  }),
});

export const {
  useSetOnboardingOneMutation,
  useSetOnboardingTwoMutation,
  useSetTutorialCompletedMutation,
  useSetTermsAndConditionsMutation,
  useSetHealthQuestionnaireMutation,
} = onBoardingQueries;
