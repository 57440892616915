import React, { useMemo, useState } from 'react';
import { ComponentsLayout, DownloadIcon } from '@hdcorner/ui-library';
import { useIonRouter } from '@ionic/react';
import PageLayout from '../../../components/PageLayout';
import SectionSleepData from './components/SectionSleepData';
import SectionWeightData from './components/SectionWeightData';
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter';
import SectionStepsData from '../exercise/components/SectionStepsData';
import ModalInDepthDataDL from '../../../components/ModalInDepthDataDL';
import SectionActivitiesData from '../exercise/components/SectionActivitiesData';
import { useTranslation } from 'react-i18next';

const WellnessInDepthData = () => {
  const router = useIonRouter();
  const { t } = useTranslation();

  const pathname = router.routeInfo.pathname.split('/').slice(-1).toString();

  const [open, setOpen] = useState<boolean>(false);
  const [module, setModule] = useState<'sleep' | 'weight' | 'steps'>('weight');

  const handleDLData = () => {
    const moduleName = pathname as 'sleep' | 'weight' | 'steps';
    setModule(moduleName);
    setOpen(true);
  };

  const handlePanelToShow = useMemo(() => {
    switch (pathname) {
      case 'steps':
        return <SectionStepsData />;
      case 'weight':
        return <SectionWeightData />;
      case 'sleep':
        return <SectionSleepData />;
      case 'activities':
        return <SectionActivitiesData />;
    }
  }, [pathname]);

  const handleHeadingTitle = useMemo(() => {
    switch (pathname) {
      case 'steps':
        return 'headingsTitles.steps';
      case 'weight':
        return 'userDetails.weight';
      case 'sleep':
        return 'fitness.wellness.labels.sleep';
      case 'activities':
        return 'headingsTitles.activities';
      default:
        return '';
    }
  }, [pathname]);

  return (
    <PageLayout
      headerTitle={`${capitalizeFirstLetter(t(handleHeadingTitle))} ${t(
        'headingsTitles.inDepth',
      )}`}
      endButtons={[
        {
          icon: <DownloadIcon width={'18px'} height={'20px'} />,
          onClick: handleDLData,
        },
      ]}
    >
      <ComponentsLayout>{handlePanelToShow}</ComponentsLayout>
      <ModalInDepthDataDL open={open} module={module} dismiss={() => setOpen(false)} />
    </PageLayout>
  );
};

export default WellnessInDepthData;
