import {
  ChatIcon,
  NavHealthProfIcon,
  NavHomeIcon,
  NavProfileIcon,
  SideBar,
  theme,
} from '@hdcorner/ui-library';
import {
  IonContent,
  IonMenu,
  IonRouterOutlet,
  IonSplitPane,
  IonTabBar,
  IonTabButton,
  IonTabs,
  useIonRouter,
} from '@ionic/react';
import { useMediaQuery } from '@mui/material';
import * as React from 'react';
import { useMemo, useRef } from 'react';
import { Redirect, Route } from 'react-router-dom';
import DashboardSidebarIcon from '../assets/icons/DashboardSidebarIcon';
import DataSidebarIcon from '../assets/icons/DataSidebarIcon';
import HealthProfSidebarIcon from '../assets/icons/HealthProfSidebarIcon';
import MyRecordsSidebarIcon from '../assets/icons/MyRecordsSidebarIcon';
import SubscriptionSidebarIcon from '../assets/icons/SubscriptionSidebarIcon';
import FabBottomTabs from '../components/FabBottomTabs';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { hideNavigation } from '../utils/hideNavigation';
import NonProtectedRoutes from './NonProtectedRoutes';
import ProtectedRoutes from './ProtectedRoutes';
import logo from '../features/aboutUs/assets/HDCLogo.png';
import smallLogo from '../assets/illustrations/LogoHeartPNG.png';
import ModalSubsPromotion from '../components/ModalSubsPromotion';
import { setPromotion } from '../redux/slices/appSlice';
import InAppPayments from '../features/subscription/InAppPayments';

const routesWithTabs = [
  '/dashboard/home',
  '/dashboard/chat',
  '/dashboard/profile',
  '/dashboard/healthProfessionals',
];

const showTabs = (path: string) => {
  return routesWithTabs.includes(path);
};

const MenuToMap = [
  {
    link: '/dashboard/home',
    icon: DashboardSidebarIcon,
    label: 'general.labels.dashboard',
  },
  {
    icon: DataSidebarIcon,
    link: '/dashboard/fitness',
    label: 'constants.toggleValues.data.data',
  },
  {
    icon: HealthProfSidebarIcon,
    label: 'headingsTitles.hcpProfs',
    link: '/dashboard/healthProfessionals',
  },
  {
    icon: SubscriptionSidebarIcon,
    link: '/dashboard/subscription',
    label: 'subscription.titles.sub',
  },
  {
    link: '/dashboard/records',
    icon: MyRecordsSidebarIcon,
    label: 'records.labels.myRec',
  },
];

const userDataScreens = [
  'cvd',
  'lipid',
  'diabetes',
  'exercise',
  'nutrition',
  'medication',
  'hypertension',
];

const Routes = () => {
  const router = useIonRouter();
  const dispatch = useAppDispatch();
  const pathname = router.routeInfo.pathname;

  const shrinkMenu = useMediaQuery(theme.breakpoints.down('md'));
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { shrunkSideBar, promotion } = useAppSelector(state => state.layout);

  const tabBarRef = useRef<HTMLIonTabBarElement>(null);

  const {
    data: { user },
    meta: { isAuthenticated },
  } = useAppSelector(state => state.auth);

  const isCurrentScreen = useMemo(
    () => (link: string) => {
      if (pathname === link) {
        return true;
      } else if (link === '/dashboard/fitness') {
        const module = pathname.split('/')?.[2];
        return userDataScreens.includes(module);
      }

      return false;
    },
    [pathname],
  );

  const handleClickRoute = (routeLink: string) => {
    if (routeLink.includes('fitness')) {
      if (!user?.premiumUser) {
        router.push('/dashboard/hypertension');
        return;
      } else {
        router.push('/dashboard/fitness');
        return;
      }
    }

    if (routeLink.includes('healthProfessionals') || routeLink.includes('records')) {
      if (!user?.premiumUser) {
        dispatch(
          setPromotion({
            open: true,
            heading: 'Premium Feature',
            body: 'Subscribe to premium to access this feature',
          }),
        );
        return;
      }
    }

    router.push(routeLink);
  };

  const handleClickDisabledRoute = (event: Event) => {
    if (user?.premiumUser) {
      return;
    }
    event.stopPropagation();
    event.preventDefault();
    dispatch(
      setPromotion({
        open: true,
        heading: 'Premium Feature',
        body: 'Subscribe to premium to access this feature',
      }),
    );
  };

  const dismissPromotion = () => {
    dispatch(
      setPromotion({
        open: false,
        heading: '',
        body: '',
      }),
    );
  };

  return (
    <IonSplitPane
      when={'sm'}
      contentId={'main'}
      className={'ion-split-pane'}
      disabled={smallScreen || hideNavigation(pathname)}
    >
      <IonMenu
        contentId={'main'}
        disabled={smallScreen}
        className={
          shrinkMenu || shrunkSideBar
            ? 'ion-split-pane-menu-shrink'
            : 'ion-split-pane-menu'
        }
      >
        <IonContent>
          <SideBar
            menuOptions={MenuToMap}
            isCurrentScreen={isCurrentScreen}
            handleClickRoute={handleClickRoute}
            shrunkSideBar={shrinkMenu || shrunkSideBar}
            logoBig={
              <img width={'134px'} height={'72px'} src={logo} alt={`HD Corner Logo`} />
            }
            logoSmall={
              <img
                width={'40px'}
                height={'40px'}
                src={smallLogo}
                alt={`HD Corner Logo`}
                style={{ maxWidth: 'none' }}
              />
            }
          />
        </IonContent>
      </IonMenu>
      <div className="ion-page" id="main">
        <IonTabs>
          <IonRouterOutlet animated={smallScreen}>
            <NonProtectedRoutes />
            <Route path={'/dashboard'}>
              {isAuthenticated && user && user.authUser ? (
                <ProtectedRoutes />
              ) : (
                <Redirect to={'/sign-in'} />
              )}
            </Route>
            <Route exact path={'/'}>
              <Redirect to={'/dashboard/home'} />
            </Route>
          </IonRouterOutlet>
          <IonTabBar
            ref={tabBarRef}
            slot="bottom"
            hidden={!showTabs(pathname) || !smallScreen}
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
            }}
          >
            <IonTabButton tab="home" href="/dashboard/home">
              <NavHomeIcon
                color={
                  pathname === '/dashboard/home'
                    ? theme.palette.kmColorsRed.main
                    : theme.palette.primary.main
                }
              />
            </IonTabButton>
            <IonTabButton
              tab="chat"
              onClick={ev => handleClickDisabledRoute(ev)}
              href={!user?.premiumUser ? undefined : '/dashboard/chat'}
            >
              <ChatIcon
                width={'20px'}
                height={'20px'}
                color={
                  pathname === '/dashboard/chat'
                    ? theme.palette.kmColorsRed.main
                    : theme.palette.primary.main
                }
              />
            </IonTabButton>
            <IonTabButton
              tab="healthProfessionals"
              onClick={ev => handleClickDisabledRoute(ev)}
              href={!user?.premiumUser ? undefined : '/dashboard/healthProfessionals'}
            >
              <NavHealthProfIcon
                color={
                  pathname === '/dashboard/healthProfessionals'
                    ? theme.palette.kmColorsRed.main
                    : theme.palette.primary.main
                }
              />
            </IonTabButton>
            <IonTabButton tab="profile" href="/dashboard/profile">
              <NavProfileIcon
                color={
                  pathname === '/dashboard/profile'
                    ? theme.palette.kmColorsRed.main
                    : theme.palette.primary.main
                }
              />
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
        {pathname.includes('/dashboard/home') && <FabBottomTabs />}
      </div>
      <ModalSubsPromotion
        open={promotion.open}
        body={promotion.body}
        setOpen={dismissPromotion}
        heading={promotion.heading}
        onCtaClick={() => {
          router.push('/dashboard/subscription');
          dismissPromotion();
        }}
      />
      {isAuthenticated && user && user.authUser && (
        <InAppPayments userId={user.authUser._id} />
      )}
    </IonSplitPane>
  );
};

export default Routes;
