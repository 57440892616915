import React from 'react';
import { Box } from '@mui/material';
import { useAppSelector } from '../../../redux/hooks';
import ProgramVideos from './components/ProgramVideos';
import PageLayout from '../../../components/PageLayout';
import { ComponentsLayout } from '@hdcorner/ui-library';

const WorkoutProgram = () => {
  const { program } = useAppSelector(state => state.workouts);

  return (
    <PageLayout
      headerTitle={program.name}
      defaultHref={'/dashboard/fitness/workouts/workout-programs'}
    >
      <ComponentsLayout>
        <Box gap={3} display={'flex'} flexDirection={'column'}>
          <ProgramVideos />
        </Box>
      </ComponentsLayout>
    </PageLayout>
  );
};

export default WorkoutProgram;
