import * as React from 'react';
import { SVGProps } from 'react';
import { useTheme } from '@mui/material';

const ChatIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  return (
    <svg
      width={props && props.width ? props.width : '14px'}
      height={props && props.height ? props.height : '14px'}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.5 1.43328e-05C8.96921 -0.000982731 7.45864 0.349463 6.08467 1.02435C4.7107 1.69924 3.51001 2.68054 2.57512 3.89266C1.64022 5.10478 0.99608 6.51535 0.692324 8.01569C0.388569 9.51602 0.43331 11.0661 0.823099 12.5464C1.21289 14.0267 1.93732 15.3978 2.94058 16.5539C3.94383 17.7101 5.19913 18.6205 6.60973 19.215C8.02034 19.8095 9.54861 20.0723 11.0768 19.983C12.6049 19.8937 14.0922 19.4548 15.424 18.7L19.184 19.953C19.2862 19.9853 19.3928 20.0011 19.5 20C19.6583 19.9999 19.8144 19.9622 19.9554 19.8899C20.0963 19.8177 20.2181 19.713 20.3106 19.5845C20.4032 19.456 20.4639 19.3074 20.4878 19.1508C20.5117 18.9943 20.498 18.8343 20.448 18.684L19.2 14.924C20.0643 13.4035 20.5143 11.6827 20.5048 9.93365C20.4952 8.18465 20.0266 6.4688 19.1458 4.95778C18.2649 3.44676 17.0027 2.19353 15.4854 1.32348C13.9682 0.453425 12.249 -0.0029483 10.5 1.43328e-05ZM17.153 15.121L17.919 17.421L15.619 16.655C15.4787 16.6072 15.3294 16.5913 15.1822 16.6086C15.0349 16.6259 14.8934 16.6759 14.768 16.755C13.1001 17.8105 11.1016 18.214 9.15492 17.8881C7.20822 17.5622 5.45007 16.5299 4.21693 14.9887C2.98379 13.4475 2.36229 11.5057 2.47137 9.53494C2.58044 7.56417 3.41243 5.70283 4.80812 4.30715C6.2038 2.91147 8.06514 2.07947 10.0359 1.9704C12.0067 1.86132 13.9485 2.48283 15.4896 3.71596C17.0308 4.9491 18.0631 6.70725 18.389 8.65396C18.7149 10.6007 18.3115 12.5992 17.256 14.267C17.1764 14.3927 17.1258 14.5346 17.108 14.6824C17.0901 14.8301 17.1055 14.98 17.153 15.121Z"
        fill={props && props.color ? props.color : theme.palette.highlight.main}
      />
    </svg>
  );
};

export default ChatIcon;
