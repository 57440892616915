import * as React from 'react';
import { SVGProps } from 'react';
import { useTheme } from '@mui/material';

const AddIconRounded = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();

  return (
    <svg
      {...props}
      width={14}
      height={15}
      fill="none"
      viewBox="0 0 14 15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.00065 6.8335H6.33398V1.50016C6.33398 1.32335 6.40422 1.15378 6.52925 1.02876C6.65427 0.903734 6.82384 0.833496 7.00065 0.833496C7.17746 0.833496 7.34703 0.903734 7.47206 1.02876C7.59708 1.15378 7.66732 1.32335 7.66732 1.50016V6.8335H13.0007C13.1775 6.8335 13.347 6.90373 13.4721 7.02876C13.5971 7.15378 13.6673 7.32335 13.6673 7.50016C13.6673 7.67697 13.5971 7.84654 13.4721 7.97157C13.347 8.09659 13.1775 8.16683 13.0007 8.16683H7.66732V13.5002C7.66732 13.677 7.59708 13.8465 7.47206 13.9716C7.34703 14.0966 7.17746 14.1668 7.00065 14.1668C6.82384 14.1668 6.65427 14.0966 6.52925 13.9716C6.40422 13.8465 6.33398 13.677 6.33398 13.5002V8.16683H1.00065C0.82384 8.16683 0.654271 8.09659 0.529246 7.97157C0.404222 7.84654 0.333984 7.67697 0.333984 7.50016C0.333984 7.32335 0.404222 7.15378 0.529246 7.02876C0.654271 6.90373 0.82384 6.8335 1.00065 6.8335Z"
        fill={
          theme.palette.mode === 'dark'
            ? theme.palette.kmColorsTurquoise.main
            : theme.palette.error.contrastText
        }
      />
    </svg>
  );
};

export default AddIconRounded;
