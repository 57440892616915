import api from '../../../redux/api';
import {
  ApiPagination,
  GetPressureGoal,
  HypertensionLog,
  HypertensionOverall,
  PaginationResult,
  PressureGoal,
} from '@hdcorner/ui-library';

const hypertensionQueries = api
  .enhanceEndpoints({ addTagTypes: ['HypertensionLog', 'PressureGoal'] })
  .injectEndpoints({
    endpoints: build => ({
      addHypertensionLog: build.mutation<HypertensionLog[], Partial<HypertensionLog>>({
        query: ({ afib, systolic, diastolic, pulses, category, logDate }) => ({
          method: 'POST',
          url: '/database/function/addHypertensionLog',
          body: { afib, systolic, diastolic, pulses, category, logDate },
        }),
        invalidatesTags: ['HypertensionLog', 'Dashboard'],
      }),
      getFirstLogOfGoal: build.query<
        PaginationResult<HypertensionLog>,
        { start?: string }
      >({
        query: ({ start }) => ({
          method: 'GET',
          url: `/database/function/getHypertensionLogs`,
          params: {
            start,
            skip: 0,
            limit: 1,
            sort: 'logDate',
          },
        }),
      }),
      getLatestLog: build.query<PaginationResult<HypertensionLog>, void>({
        query: () => ({
          method: 'GET',
          url: `/database/function/getHypertensionLogs`,
          params: { skip: 0, limit: 1, sort: '-logDate' },
        }),
      }),
      getHypertensionLogs: build.query<
        PaginationResult<HypertensionLog>,
        {
          end?: string;
          start?: string;
          category?: string;
          params: ApiPagination;
        }
      >({
        query: ({ params, category, start, end }) => ({
          method: 'GET',
          params: { ...params, category, start, end },
          url: `/database/function/getHypertensionLogs`,
        }),
        serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
          return endpointName;
        },
        merge: (currentCache, newItems, otherArgs) => {
          if (otherArgs.arg.params.skip === 0) {
            currentCache.documents = newItems?.documents;
          } else {
            currentCache?.documents.push(...newItems?.documents);
          }
        },
        forceRefetch: ({ previousArg, currentArg }) => currentArg !== previousArg,
        providesTags: ['HypertensionLog'],
      }),
      getPressureGoal: build.query<GetPressureGoal[], void>({
        query: () => ({
          method: 'GET',
          params: { goalType: 'pressure' },
          url: `/database/function/getUserGoal`,
        }),
        providesTags: ['PressureGoal'],
      }),
      addPressureGoal: build.mutation<void, Partial<PressureGoal>>({
        query: params => ({
          method: 'POST',
          url: '/hdCornerService/user/goal',
          body: { ...params, goalType: 'pressure' },
        }),
        invalidatesTags: ['PressureGoal'],
      }),
      deletePressureGoal: build.mutation<void, { _id: string }>({
        query: ({ _id }) => ({
          method: 'DELETE',
          url: `/database/UserGoal/${_id}`,
        }),
        invalidatesTags: ['PressureGoal'],
      }),
      updatePressureGoal: build.mutation<void, Partial<PressureGoal>>({
        query: ({ _id, ...rest }) => ({
          method: 'PATCH',
          body: { ...rest },
          url: `/hdCornerService/user/goal/${_id}`,
        }),
        invalidatesTags: ['PressureGoal'],
      }),
      getHypertensionOverall: build.query<
        HypertensionOverall,
        { start?: string; end?: string; category?: string }
      >({
        query: ({ category, start, end }) => ({
          method: 'GET',
          params: { category, start, end },
          url: `/hdCornerService/user/hypertension/overall`,
        }),
      }),
      getPressureGraphData: build.query<
        { logs: HypertensionLog[] },
        { start?: string; end?: string; category?: string }
      >({
        query: ({ start, end, category }) => ({
          method: 'GET',
          params: { start, end, category },
          url: `/hdCornerService/user/blood-pressure/graph`,
        }),
      }),
      getPulsesGraphData: build.query<
        { logs: HypertensionLog[] },
        { start?: string; end?: string; category?: string }
      >({
        query: ({ start, end, category }) => ({
          method: 'GET',
          params: { start, end, category },
          url: `/hdCornerService/user/pulses/graph`,
        }),
      }),
    }),
  });

export const {
  useGetHypertensionOverallQuery,
  useAddHypertensionLogMutation,
  useDeletePressureGoalMutation,
  useUpdatePressureGoalMutation,
  useGetPressureGraphDataQuery,
  useGetHypertensionLogsQuery,
  useGetPulsesGraphDataQuery,
  useAddPressureGoalMutation,
  useGetFirstLogOfGoalQuery,
  useGetPressureGoalQuery,
  useGetLatestLogQuery,
} = hypertensionQueries;

export default hypertensionQueries;
