import * as React from 'react';
import { SVGProps } from 'react';

const LipidSQ = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.66667 1.33329C4.66667 1.15648 4.7369 0.986912 4.86193 0.861888C4.98695 0.736864 5.15652 0.666626 5.33333 0.666626H10.6667C10.8435 0.666626 11.013 0.736864 11.1381 0.861888C11.2631 0.986912 11.3333 1.15648 11.3333 1.33329C11.3333 1.5101 11.2631 1.67967 11.1381 1.8047C11.013 1.92972 10.8435 1.99996 10.6667 1.99996H5.33333C5.15652 1.99996 4.98695 1.92972 4.86193 1.8047C4.7369 1.67967 4.66667 1.5101 4.66667 1.33329ZM2 13.3333V5.33329C2 4.80286 2.21071 4.29415 2.58579 3.91908C2.96086 3.54401 3.46957 3.33329 4 3.33329H12C12.5304 3.33329 13.0391 3.54401 13.4142 3.91908C13.7893 4.29415 14 4.80286 14 5.33329V13.3333C14 13.8637 13.7893 14.3724 13.4142 14.7475C13.0391 15.1226 12.5304 15.3333 12 15.3333H4C3.46957 15.3333 2.96086 15.1226 2.58579 14.7475C2.21071 14.3724 2 13.8637 2 13.3333ZM12.6667 12.6666H3.33333V13.3333C3.33333 13.5101 3.40357 13.6797 3.5286 13.8047C3.65362 13.9297 3.82319 14 4 14H12C12.1768 14 12.3464 13.9297 12.4714 13.8047C12.5964 13.6797 12.6667 13.5101 12.6667 13.3333V12.6666ZM3.33333 5.33329V11.3333H12.6667V5.33329C12.6667 5.15648 12.5964 4.98691 12.4714 4.86189C12.3464 4.73686 12.1768 4.66663 12 4.66663H4C3.82319 4.66663 3.65362 4.73686 3.5286 4.86189C3.40357 4.98691 3.33333 5.15648 3.33333 5.33329ZM7.33333 9.99996H8.66667V8.66663H10V7.33329H8.66667V5.99996H7.33333V7.33329H6V8.66663H7.33333V9.99996Z"
      fill={props?.color ?? '#F2F1F3'}
    />
  </svg>
);

export default LipidSQ;
