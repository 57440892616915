import React, { FC, useMemo } from 'react';
import { CustomButton, theme } from '@hdcorner/ui-library';
import { Box, BoxProps, styled, Typography, TypographyProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetProductsQuery } from '../queries/subscriptionsQueries';

const TypographySummary = styled(Typography, {
  shouldForwardProp: propName => propName !== 'web',
})<TypographyProps & { web: boolean }>(({ theme, web }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  marginBottom: web ? theme.spacing(3) : theme.spacing(2),
  fontSize: web ? theme.typography.h1.fontSize : theme.typography.subtitle2.fontSize,
}));

const TypographyTally = styled(Typography, {
  shouldForwardProp: propName => propName !== 'web',
})<TypographyProps & { web: boolean }>(({ theme, web }) => ({
  fontWeight: web
    ? theme.typography.fontWeightMedium
    : theme.typography.fontWeightRegular,
  color: theme.palette.primary.light,
  fontSize: web ? theme.typography.subtitle1.fontSize : theme.typography.body1.fontSize,
}));

const BoxText = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export type SubscriptionTotalProps = {
  web: boolean;
  value: string;
  trial: boolean;
  couponCode?: string;
  handleBuySubscription: () => void;
};

const SubscriptionTotal: FC<SubscriptionTotalProps> = ({
  web,
  value,
  trial,
  couponCode,
  handleBuySubscription,
}) => {
  const { t } = useTranslation();

  const { data: productsRes } = useGetProductsQuery();

  const paymentPlan = useMemo(() => {
    if (productsRes) {
      return productsRes.find(choice => choice._id === value);
    }
  }, [value, productsRes]);

  const findPaymentAmount = useMemo(() => {
    if (paymentPlan) {
      const priceInCents = paymentPlan.value;
      const finalPriceInDollars = priceInCents / 100;
      return finalPriceInDollars.toFixed(2);
    }
    return 0;
  }, [paymentPlan]);

  const findPaymentVat = useMemo(() => {
    if (paymentPlan) {
      const vatRate = paymentPlan.vat;
      return vatRate + '%';
    }
    return '-%';
  }, [paymentPlan]);

  const findPaymentFinalAmount = useMemo(() => {
    if (paymentPlan) {
      const vatRate = paymentPlan.vat;
      const priceInCents = paymentPlan.value;
      const finalPriceInCents = priceInCents + Math.round(priceInCents * (vatRate / 100));
      const finalPriceInDollars = finalPriceInCents / 100;
      return finalPriceInDollars.toFixed();
    }
    return 0;
  }, [paymentPlan]);

  return (
    <Box
      gap={2}
      width={'100%'}
      display={'flex'}
      borderRadius={'8px'}
      flexDirection={'column'}
      boxSizing={'border-box'}
      padding={web ? theme.spacing(3) : theme.spacing(2)}
      bgcolor={web ? theme.palette.secondary.light : theme.palette.highlight.main}
    >
      <Box display={'flex'} justifyContent={'space-between'}>
        <TypographySummary web={web}>
          {t('subscription.labels.orderSummary')}
        </TypographySummary>
        {trial && (
          <TypographySummary web={web}>
            {t('subscription.labels.trial')}
          </TypographySummary>
        )}
      </Box>
      <BoxText>
        <TypographyTally web={web}>{t('subscription.labels.subtotal')}</TypographyTally>
        <TypographyTally
          web={web}
          style={{ textDecoration: trial ? 'line-through' : 'none' }}
        >
          {findPaymentAmount} €
        </TypographyTally>
      </BoxText>
      <BoxText>
        <TypographyTally web={web}>VAT</TypographyTally>
        <TypographyTally
          web={web}
          sx={{
            color: theme.palette.primary.main,
          }}
        >
          {findPaymentVat}
        </TypographyTally>
      </BoxText>

      <BoxText>
        <TypographyTally web={web}>{t('subscription.labels.discount')}</TypographyTally>
        <TypographyTally
          web={web}
          sx={{
            color: theme.palette.success.main,
          }}
        >
          0.00 €
        </TypographyTally>
      </BoxText>

      <Box
        flexGrow={1}
        height={'2px'}
        margin={theme.spacing(2, 0)}
        bgcolor={theme.palette.secondary.main}
      />

      {/*{couponCode && (*/}
      {/*  <>*/}
      {/*    <BoxText>*/}
      {/*      <TypographyTally*/}
      {/*        web={web}*/}
      {/*        sx={{*/}
      {/*          color:*/}
      {/*            data?.value !== 'bi-annually'*/}
      {/*              ? theme.palette.error.main*/}
      {/*              : theme.palette.primary.light,*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        {t('subscription.labels.codeUsed')}*/}
      {/*      </TypographyTally>*/}
      {/*      <TypographyTally*/}
      {/*        web={web}*/}
      {/*        sx={{*/}
      {/*          color:*/}
      {/*            data?.value !== 'bi-annually'*/}
      {/*              ? theme.palette.error.main*/}
      {/*              : theme.palette.primary.light,*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        {couponCode}*/}
      {/*      </TypographyTally>*/}
      {/*    </BoxText>*/}

      {/*    {data?.value !== 'bi-annually' && (*/}
      {/*      <Box gap={1} display={'flex'}>*/}
      {/*        <ExclamationIcon*/}
      {/*          width={'15px'}*/}
      {/*          height={'15px'}*/}
      {/*          color={theme.palette.error.main}*/}
      {/*        />*/}
      {/*        <Typography*/}
      {/*          variant={'body1'}*/}
      {/*          sx={{*/}
      {/*            color: theme.palette.error.main,*/}
      {/*            fontWeight: theme.typography.fontWeightRegular,*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          {t('subscription.text.changePayment')}*/}
      {/*        </Typography>*/}
      {/*      </Box>*/}
      {/*    )}*/}
      {/*  </>*/}
      {/*)}*/}

      <BoxText>
        <Typography
          variant={web ? 'subtitle1' : 'body1'}
          sx={{
            fontWeight: web
              ? theme.typography.fontWeightMedium
              : theme.typography.fontWeightRegular,
            color: web ? theme.palette.primary.light : theme.palette.primary.main,
          }}
        >
          {web ? t('subscription.labels.totalAmount') : t('subscription.labels.total')}
        </Typography>
        <Typography
          variant={web ? 'h1' : 'body1'}
          sx={{
            textDecoration: trial ? 'line-through' : 'none',
            fontWeight: web ? null : theme.typography.fontWeightRegular,
          }}
        >
          {findPaymentFinalAmount} €
        </Typography>
      </BoxText>
      <CustomButton fullWidth variant={'contained'} onClick={handleBuySubscription}>
        {trial && t('subscription.text.freeTrial')}
        {!trial && t('subscription.text.completePurchase')}
      </CustomButton>
    </Box>
  );
};

export default SubscriptionTotal;
