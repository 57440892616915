import React, { FC } from 'react';
import {
  Box,
  styled,
  ToggleButton,
  ToggleButtonProps,
  Typography,
  useTheme,
} from '@mui/material';
import ArrowRight from '../../assets/ArrowRight';

const CustomToggleButton = styled(ToggleButton)<ToggleButtonProps>(({ theme }) => ({
  flexGrow: '1',
  border: 'none',
  height: '35px',
  borderRadius: '8px',
  boxSizing: 'border-box',
  padding: theme.spacing(1, 2),
  margin: theme.spacing(0, 0, 1, 0),
  '&.MuiToggleButton-root': {
    justifyContent: 'flex-start',
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.secondary.dark,
  },
  '&:last-of-type': {
    margin: theme.spacing(0),
  },
}));

type ToggleCardProps = {
  value: string;
  label: string;
  selected: boolean;
  arrowRight?: boolean;
  textTransform: string;
  backgroundColor: string;
  setSelected: (value: string) => void;
  alignText?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
};
const ToggleCard: FC<ToggleCardProps> = ({
  alignText,
  label,
  value,
  selected,
  arrowRight,
  textTransform,
  backgroundColor,
  setSelected,
}) => {
  const theme = useTheme();

  const handleOnChange = () => {
    setSelected(value);
  };

  return (
    <CustomToggleButton
      fullWidth
      value={value}
      selected={selected}
      onChange={handleOnChange}
      sx={{
        textTransform: { textTransform },
        backgroundColor: { backgroundColor },
      }}
    >
      <Box
        flexGrow={1}
        display={'flex'}
        alignItems={'center'}
        textAlign={alignText ? alignText : 'left'}
        justifyContent={alignText === 'center' ? 'center' : 'space-between'}
      >
        <Typography
          variant={'subtitle2'}
          align={alignText ? alignText : 'left'}
          sx={{
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightRegular,
          }}
        >
          {label}
        </Typography>
        <Box marginRight={theme.spacing(0.5)} display={arrowRight ? 'flex' : 'none'}>
          <ArrowRight />
        </Box>
      </Box>
    </CustomToggleButton>
  );
};

export default ToggleCard;
