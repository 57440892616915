import * as React from 'react';
import { SVGProps } from 'react';

const ArticleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width={16}
    height={13}
    fill="none"
    viewBox="0 0 16 13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.914 1.18926C12.7012 0.299541 10.2363 0.265792 8 1.09459C5.76366 0.265792 3.29881 0.299541 1.086 1.18926C0.962188 1.23872 0.856051 1.32418 0.7813 1.43458C0.706549 1.54498 0.666619 1.67526 0.666668 1.80859V11.8333C0.666628 11.9422 0.693287 12.0495 0.744313 12.1458C0.795339 12.242 0.869176 12.3243 0.959365 12.3854C1.04955 12.4465 1.15334 12.4846 1.26166 12.4963C1.36997 12.508 1.4795 12.493 1.58067 12.4526C3.56085 11.6558 5.77249 11.6558 7.75267 12.4526C7.82684 12.4807 7.90536 12.4956 7.98467 12.4966C7.99 12.4966 7.99467 12.4966 8 12.4966C8.00533 12.4966 8.01 12.4966 8.01533 12.4966C8.09464 12.4956 8.17317 12.4807 8.24733 12.4526C10.2275 11.6558 12.4392 11.6558 14.4193 12.4526C14.5205 12.4931 14.63 12.5082 14.7383 12.4966C14.8466 12.4849 14.9504 12.447 15.0407 12.3859C15.1309 12.3248 15.2048 12.2424 15.2558 12.146C15.3068 12.0497 15.3335 11.9423 15.3333 11.8333V1.80859C15.3334 1.67526 15.2935 1.54498 15.2187 1.43458C15.144 1.32418 15.0378 1.23872 14.914 1.18926ZM7.33333 10.9059C5.59242 10.396 3.74206 10.3939 2 10.8999V2.27259C3.72979 1.68542 5.60546 1.68847 7.33333 2.28126V10.9059ZM14 10.8999C12.258 10.3953 10.4086 10.3953 8.66667 10.8999V2.28126C10.3945 1.68847 12.2702 1.68542 14 2.27259V10.8999Z"
      fill={props.color ? props.color : '#F2F1F3'}
    />
  </svg>
);

export default ArticleIcon;
