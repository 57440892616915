import { useState } from 'react';
import ChartSteps from './ChartSteps';
import ModalActivityContent from './ModalActivityContent';
import SectionGoalSteps from './SectionGoalSteps';
import { Box, BoxProps, styled } from '@mui/material';
import SectionActivitiesCal from './SectionActivitiesCal';
import SectionGoalActivities from './SectionGoalActivities';
import SectionStepsData from './SectionStepsData';
import SectionActivitiesData from './SectionActivitiesData';
import { ModalMobile } from '@hdcorner/ui-library';

const BoxMain = styled(Box)<BoxProps>(({ theme }) => ({
  flex: 1,
  height: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  overflow: 'scroll',
  scrollbarWidth: 'none',
  gap: theme.spacing(3),
  msOverflowStyle: 'none',
  '::-webkit-scrollbar': {
    display: 'none',
  },
}));

const BoxColumn = styled(Box)<BoxProps>(({ theme }) => ({
  flex: '1 0 250px',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const WebExercise = () => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <BoxMain>
      <BoxColumn>
        <ChartSteps />
        <SectionStepsData />
        <SectionGoalSteps />
      </BoxColumn>
      <BoxColumn>
        <SectionActivitiesCal />
        <SectionActivitiesData />
        <SectionGoalActivities />
      </BoxColumn>
      <ModalMobile open={open} setOpen={setOpen} width={'398px'}>
        <ModalActivityContent setOpen={setOpen} />
      </ModalMobile>
    </BoxMain>
  );
};

export default WebExercise;
