import React, { FC, useEffect, useState } from 'react';
import { Box, styled, Typography, TypographyProps } from '@mui/material';
import { CustomButton, theme } from '@hdcorner/ui-library';
import AvatarSelector from './AvatarSelector';
import { SexType } from '../types/SexType';
import { useIonRouter } from '@ionic/react';
import {
  useCreateWorkoutSettingsMutation,
  useGetWorkoutSettingsQuery,
  useUpdateWorkoutSettingsMutation,
} from '../queries/workoutSettingsQueries';
import useAlert from '../../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(1),
  fontWeight: theme.typography.fontWeightMedium,
}));

const Subtitle = styled(Typography)<TypographyProps>(() => ({
  width: 350,
  textAlign: 'center',
  color: theme.palette.primary.main,
}));

type Props = {
  onClick?: () => void;
};
const AvatarSelectorComponent: FC<Props> = ({ onClick }) => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { presentSuccess, presentError } = useAlert();

  const [workoutSettingsId, setWorkoutSettingsId] = useState<string | undefined>(
    undefined,
  );
  const [selectedAvatar, setSelectedAvatar] = useState<SexType | undefined>(undefined);

  const { data: avatarRes, error } = useGetWorkoutSettingsQuery();

  const [createAvatar] = useCreateWorkoutSettingsMutation();
  const [updateAvatar] = useUpdateWorkoutSettingsMutation();

  useEffect(() => {
    if (error) {
      presentError(t('errors.fitness.workouts.errorWorkoutSettingsFetch'));
    }
  }, [error]);

  useEffect(() => {
    if (!avatarRes) return;
    const data = avatarRes[0];

    if (data) {
      setWorkoutSettingsId(data._id);
      const avatar = data.avatar;
      if (avatar) {
        setSelectedAvatar(avatar);
      }
    }
  }, [avatarRes]);

  const handleClickSelectAvatar = (selectedAvatar: SexType) => {
    if (!workoutSettingsId) {
      createAvatar({ avatar: selectedAvatar })
        .unwrap()
        .then(() => {
          presentSuccess(t('errors.fitness.workouts.successAvatarSelect'));
          handleNext();
        })
        .catch(() => {
          presentError(t('errors.fitness.workouts.errorAvatarSelect'));
        });
    } else {
      updateAvatar({ avatar: selectedAvatar, _id: workoutSettingsId })
        .unwrap()
        .then(() => {
          presentSuccess(t('errors.fitness.workouts.successAvatarUpdate'));
          handleNext();
        })
        .catch(() => {
          presentError(t('errors.fitness.workouts.errorAvatarUpdate'));
        });
    }
  };

  const handleNext = () => {
    if (onClick) {
      onClick();
    } else {
      router.goBack();
    }
  };

  const onSelectMale = () => setSelectedAvatar(SexType.MALE);
  const onSelectFemale = () => setSelectedAvatar(SexType.FEMALE);

  return (
    <Box
      gap={3}
      flex={1}
      height={'100%'}
      display={'flex'}
      alignItems={'center'}
      flexDirection={'column'}
    >
      <Title variant={'h1'}>{t('fitness.workouts.labels.avatar')}</Title>
      <Box display={'flex'}>
        <AvatarSelector
          sex={SexType.MALE}
          onSelect={onSelectMale}
          selected={selectedAvatar === SexType.MALE}
        />
        <AvatarSelector
          sex={SexType.FEMALE}
          onSelect={onSelectFemale}
          selected={selectedAvatar === SexType.FEMALE}
        />
      </Box>
      <Subtitle variant={'subtitle1'}>{t('fitness.workouts.text.chooseAvatar')}</Subtitle>
      <CustomButton
        variant={'contained'}
        disabled={!selectedAvatar}
        onClick={
          selectedAvatar ? () => handleClickSelectAvatar(selectedAvatar) : undefined
        }
      >
        {t('buttons.selectAv')}
      </CustomButton>
    </Box>
  );
};

export default AvatarSelectorComponent;
