import React, { FC, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { ArrowRight } from '@hdcorner/ui-library';
import { TypographyProps } from '@mui/material/Typography/Typography';
import { Box, BoxProps, Typography, useMediaQuery, useTheme } from '@mui/material';

const CustomBox = styled(Box, {
  shouldForwardProp: propName => propName !== 'web',
})<BoxProps & { web: boolean }>(({ web, theme }) => ({
  display: 'flex',
  borderRadius: '8px',
  boxSizing: 'border-box',
  justifyContent: 'space-between',
  flex: web ? '1 1 177px' : '1 1 0px',
  alignItems: web ? 'flex-start' : 'center',
  backgroundColor: theme.palette.secondary.light,
  cursor: 'pointer',
  '&:last-of-type': {
    margin: theme.spacing(0),
  },
  height: web ? '64px' : 'auto',
  padding: web ? theme.spacing(1) : theme.spacing(1, 2),
}));

const TypographyHeading = styled(Typography, {
  shouldForwardProp: propName => propName !== 'web',
})<TypographyProps & { web: boolean }>(({ web, theme }) => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  textOverflow: 'ellipsis',
  margin: theme.spacing(0),
  maxWidth: web ? '130px' : '230px',
  color: theme.palette.primary.main,
  fontWeight: web
    ? theme.typography.fontWeightRegular
    : theme.typography.fontWeightMedium,
}));

type Props = {
  icon: ReactNode;
  heading: string;
  handleNavClick: () => void;
};
const NavFoodEquivalent: FC<Props> = ({ icon, heading, handleNavClick }) => {
  const theme = useTheme();
  const web = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <CustomBox web={web} onClick={handleNavClick}>
      <Box gap={1} display={'flex'} alignItems={'flex-start'}>
        {icon}
        <TypographyHeading web={web} variant={web ? 'body1' : 'subtitle2'}>
          {heading}
        </TypographyHeading>
      </Box>
      <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} gap={1}>
        <ArrowRight />
      </Box>
    </CustomBox>
  );
};

export default NavFoodEquivalent;
