import * as React from 'react';
import { FC } from 'react';
import { styled } from '@mui/material/styles';
import {
  ToggleButton,
  ToggleButtonProps,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
} from '@mui/material';

const CustomToggleButtonGroup = styled(ToggleButtonGroup, {
  shouldForwardProp: propName => propName !== 'traffic',
})<ToggleButtonGroupProps & { traffic: boolean }>(({ theme, traffic }) => ({
  display: 'flex',
  borderRadius: '4px',
  gap: theme.spacing(1),

  '.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
    borderRadius: '4px',
  },
  '.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
    borderRadius: '4px',
  },
  justifyContent: traffic ? 'space-between' : 'flex-start',
}));

const CustomToggleButton = styled(ToggleButton, {
  shouldForwardProp: propName => propName !== 'traffic' && propName !== 'lang',
})<ToggleButtonProps & { lang: string; traffic: boolean }>(
  ({ lang, theme, traffic }) => ({
    width: 'auto',
    border: 'none',
    height: '40px',
    textWrap: 'nowrap',
    borderRadius: '8px',
    textTransform: lang === 'el' ? 'capitalize' : 'uppercase',
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.kmColorsCoolGrey.main
        : theme.palette.primary.main,
    backgroundColor:
      theme.palette.mode === 'dark' ? '#282828' : theme.palette.secondary.light,

    '&.Mui-disabled': {
      border: 'none',
    },
    '&.Mui-selected': {
      borderRadius: 1,
      color:
        theme.palette.mode === 'dark'
          ? theme.palette.kmColorsTurquoise.main
          : theme.palette.secondary.light,
      background:
        theme.palette.mode === 'dark' ? 'rgba(56, 216, 226, 0.3)' : 'rgb(34, 34, 34)',
    },
    '&.Mui-selected.Mui-selected:hover': {
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(56, 216, 226, 0.3)' : 'rgb(34, 34, 34)',
    },
    '&.Mui-selected .MuiSvgIcon-root': {
      color: theme.palette.secondary.light,
    },
    '&.MuiToggleButton-root': {
      flex: traffic ? '1 1 80px' : 0,
    },
  }),
);

type ToggleButtonCuProps = {
  value: string;
  traffic?: boolean;
  setValue?: (newActive: string) => void;
  headings:
    | string[]
    | { label: string; value: string }[]
    | { label: string; disabled: boolean; value: string }[];
};
const ToggleButtonGroupCu: FC<ToggleButtonCuProps & ToggleButtonProps> = ({
  value,
  traffic,
  setValue,
  headings,
  ...rest
}) => {
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const handleChange = (event: React.MouseEvent<HTMLElement>, active: string) => {
    if (active) {
      setValue!(active as string);
    }
  };

  return (
    <CustomToggleButtonGroup
      exclusive
      value={value}
      color="primary"
      onChange={handleChange}
      traffic={traffic ? traffic : false}
    >
      {headings.map((head, index) => (
        <CustomToggleButton
          {...rest}
          lang={lang}
          key={index}
          traffic={traffic ? traffic : false}
          value={typeof head === 'string' ? head : head.value}
          disabled={
            typeof head !== 'string' && 'disabled' in head ? head.disabled : false
          }
        >
          {typeof head === 'string' ? head : head.label}
        </CustomToggleButton>
      ))}
    </CustomToggleButtonGroup>
  );
};

export default ToggleButtonGroupCu;
