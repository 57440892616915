import * as React from 'react';
import { SVGProps } from 'react';

const ErrorBackgroundIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width={20} height={20} rx={10} fill="#E84044" />
    <path
      d="M5.504 13.67 9.175 10l-3.67-3.67a.583.583 0 0 1 .824-.826L10 9.175l3.67-3.67a.583.583 0 1 1 .826.824L10.825 10l3.67 3.67a.582.582 0 1 1-.824.826L10 10.825l-3.67 3.67a.584.584 0 0 1-.826-.824Z"
      fill="#F2F1F3"
      stroke="#F2F1F3"
    />
  </svg>
);

export default ErrorBackgroundIcon;
