import React, { FC } from 'react';
import { Box, useTheme } from '@mui/material';
import ComponentsLayout from './ComponentsLayout';
import ClickNavCardNutrition from './cards/ClickNavCardNutrition';
import { useTranslation } from 'react-i18next';

type SettingsProps = {
  email: string;
  phone: string;
  handleRouting: (route: string) => void;
};
const SettingsPanelMobile: FC<SettingsProps> = ({ email, phone, handleRouting }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <ComponentsLayout>
      <Box
        boxSizing={'border-box'}
        padding={theme.spacing(0, 1.5)}
        margin={theme.spacing(4.75, 'auto', 0, 'auto')}
      >
        <ClickNavCardNutrition
          noArrow
          profile={true}
          accountSettings={email}
          headings={t('personalDetails.email')}
          onClick={() => handleRouting('change-email')}
        />
        <ClickNavCardNutrition
          noArrow
          profile={true}
          accountSettings={'••••••••••••'}
          headings={t('profile.titles.pass')}
          onClick={() => handleRouting('change-password')}
        />
        <ClickNavCardNutrition
          noArrow
          profile={true}
          accountSettings={phone}
          headings={t('profile.titles.phone')}
          onClick={() => handleRouting('change-phone')}
        />
        <ClickNavCardNutrition
          noArrow
          profile={true}
          headings={t('notifications.titles.notifications')}
          onClick={() => handleRouting('notifications')}
        />
        <ClickNavCardNutrition
          noArrow
          profile={true}
          headings={t('profile.titles.deactivate')}
          onClick={() => handleRouting('deactivate-account')}
        />
      </Box>
    </ComponentsLayout>
  );
};

export default SettingsPanelMobile;
