import React, { FC } from 'react';
import {
  Box,
  BoxProps,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomBox = styled(Box, {
  shouldForwardProp: prop => prop !== 'size',
})<BoxProps & { size: 'mobile' | 'web' }>(({ theme, ...props }) => ({
  flexGrow: '1',
  boxSizing: 'border-box',
  height: props.size === 'mobile' ? 'auto' : '40px',
  borderRadius: props.size === 'mobile' ? '0px' : '8px',
  padding: props.size === 'mobile' ? theme.spacing(0) : theme.spacing(1, 2),
}));

const CustomFormControlLabel = styled(FormControlLabel)<FormControlLabelProps>(
  ({ theme }) => ({
    display: 'flex',
    margin: theme.spacing(0),
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    color: theme.palette.primary.main,
    paddingRight: theme.spacing(0),
    '.MuiFormControlLabel-label': {
      fontSize: theme.typography.subtitle2.fontSize,
      fontWeight: theme.typography.fontWeightRegular,
    },
  }),
);

export const CustomCheckbox = styled(Checkbox)<CheckboxProps>(({ theme }) => ({
  width: '20px',
  borderRadius: '4px',
  margin: theme.spacing(0),
  padding: theme.spacing(0),
  color: theme.palette.kmColorsCoolGrey.main,
  '&.Mui-checked': {
    color: theme.palette.kmColorsRed.main,
  },
  '&.MuiCheckbox-indeterminate': {
    color: theme.palette.kmColorsRed.main,
  },
}));

type CheckBoxesSingleProps = {
  label?: string;
  value?: string;
  checked: boolean;
  disabled?: boolean;
  size?: 'mobile' | 'web';
  setChecked: (checked: boolean) => void;
};
const CheckBoxesSingle: FC<CheckBoxesSingleProps> = ({
  size = 'mobile',
  label,
  value,
  checked,
  setChecked,
  disabled,
}) => {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <CustomBox size={size}>
      <CustomFormControlLabel
        label={label}
        disabled={disabled}
        labelPlacement={'start'}
        control={
          <CustomCheckbox checked={checked} onChange={handleOnChange} value={value} />
        }
      />
    </CustomBox>
  );
};

export default CheckBoxesSingle;
