import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, BoxProps, debounce, styled, useTheme } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import useDebounce from '../../hooks/useDebounce';

const RedArrow = styled(Box)<BoxProps>(({ theme }) => ({
  left: 0,
  right: 0,
  width: 0,
  height: 0,
  zIndex: 10,
  margin: '0 auto',
  position: 'absolute',
  borderLeft: '6px solid transparent',
  borderRight: '6px solid transparent',
  borderTop: `12px solid ${theme.palette.kmColorsRed.main}`,
}));

const NumbersContainer = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexGrow: 1,
  overflowX: 'scroll',
  scrollSnapType: 'x mandatory',
  '::-webkit-scrollbar': {
    width: 0,
    height: 0,
    background: 'transparent',
  },
}));

const Number = styled(Button, {
  shouldForwardProp: prop =>
    prop !== 'isSelected' && prop !== 'isDragging' && prop !== 'numberWidth',
})<ButtonProps & { isSelected: boolean; isDragging: boolean; numberWidth: number }>(
  ({ theme, isSelected, isDragging, numberWidth }) => ({
    display: 'flex',
    flex: 1,
    ...(isSelected ? theme.typography.h1 : theme.typography.caption),
    fontWeight: isSelected
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
    color: isSelected ? theme.palette.primary.main : theme.palette.kmColorsCoolGrey.main,
    padding: 0,
    minWidth: numberWidth,
    width: numberWidth,
    maxWidth: numberWidth,
    borderRadius: 0,
    margin: theme.spacing(0),
    ':hover': {
      backgroundColor: 'transparent',
    },
    scrollSnapAlign: isDragging ? 'none' : 'center',
  }),
);

type SliderWeightType = {
  selected: number;
  minWeight?: number;
  maxWeight?: number;
  setSelected: (value: number) => void;
};
const SliderWeight: FC<SliderWeightType & BoxProps> = ({
  selected,
  setSelected,
  minWeight = 20,
  maxWeight = 200,
  ...containerProps
}) => {
  const theme = useTheme();

  const [selectedNumber, setSelectedNumber] = useState<number>(selected);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [pos, setPos] = useState<{ left: number; x: number }>({ left: 0, x: 0 });

  const debouncedValue = useDebounce(selectedNumber, 300);

  const sliderWeightContainerRef = useRef<HTMLDivElement>(null);

  const calculateWidthAndShow = useMemo(() => {
    const parentWidth = sliderWeightContainerRef.current?.clientWidth ?? 200;
    const numbersToShow = parentWidth > 200 ? 15 : 7;
    const numberWidth = parentWidth / numbersToShow;

    return {
      numberWidth,
      numbersToShow,
    };
  }, [sliderWeightContainerRef.current]);

  const generateWeights = useMemo(() => {
    const arr: number[] = [];

    for (let weight = minWeight; weight <= maxWeight; weight++) {
      arr.push(weight);
    }

    return arr;
  }, [minWeight, maxWeight]);

  const handleEndScroll = useMemo(
    () =>
      debounce(selNum => {
        setSelected(selNum);
      }, 300),
    [],
  );

  const handleScroll = useCallback(() => {
    if (sliderWeightContainerRef.current && !isDragging) {
      const selectedNum =
        Math.round(
          sliderWeightContainerRef.current.scrollLeft / calculateWidthAndShow.numberWidth,
        ) + minWeight;
      setSelectedNumber(selectedNum);
      handleEndScroll(selectedNum);
      return () => handleEndScroll.clear();
    }
  }, [sliderWeightContainerRef.current, isDragging, calculateWidthAndShow.numberWidth]);

  const GeneratePlaceholders = useMemo(() => {
    return Array(Math.floor(calculateWidthAndShow.numbersToShow / 2))
      .fill(1)
      .map((item, index) => (
        <Box
          flex={1}
          key={index}
          height={'100%'}
          display={'flex'}
          width={calculateWidthAndShow.numberWidth}
          minWidth={calculateWidthAndShow.numberWidth}
        />
      ));
  }, [calculateWidthAndShow.numbersToShow, calculateWidthAndShow.numberWidth]);

  useEffect(() => {
    if (debouncedValue) {
      setSelected(debouncedValue);
    }
  }, [debouncedValue]);

  useEffect(() => {
    setSelectedNumber(selected);
    sliderWeightContainerRef.current?.scroll({
      left: (selected - minWeight) * calculateWidthAndShow.numberWidth,
    });
  }, [calculateWidthAndShow.numberWidth, minWeight, selected]);

  const mouseMoveHandler = useCallback(
    (e: any) => {
      if (!isDragging) {
        return;
      }
      // How far the mouse has been moved
      const dx = e.clientX - pos.x;
      // Scroll the element
      if (sliderWeightContainerRef.current) {
        sliderWeightContainerRef.current.scrollLeft = pos.left - dx;
        const selectedNum =
          Math.round(
            sliderWeightContainerRef.current.scrollLeft /
              calculateWidthAndShow.numberWidth,
          ) + minWeight;
        setSelectedNumber(selectedNum);
      }
    },
    [
      pos,
      isDragging,
      sliderWeightContainerRef.current,
      calculateWidthAndShow.numberWidth,
    ],
  );

  const handleSetDraggingToFalse = useMemo(
    () =>
      debounce(() => {
        setIsDragging(false);
      }, 100),
    [],
  );

  const mouseUpHandler = useCallback(() => {
    setIsDragging(false);

    if (sliderWeightContainerRef.current) {
      setSelectedNumber(
        Math.round(
          sliderWeightContainerRef.current?.scrollLeft /
            calculateWidthAndShow.numberWidth,
        ) + minWeight,
      );

      handleSetDraggingToFalse();
      sliderWeightContainerRef.current.style.cursor = 'grab';
      sliderWeightContainerRef.current.style.removeProperty('user-select');
    }
  }, [sliderWeightContainerRef.current, calculateWidthAndShow.numberWidth]);

  const mouseDownHandler = (e: any) => {
    setIsDragging(true);

    if (sliderWeightContainerRef.current) {
      sliderWeightContainerRef.current.style.cursor = 'grabbing';
      sliderWeightContainerRef.current.style.userSelect = 'none';

      setPos({
        // The current scroll
        left: sliderWeightContainerRef.current.scrollLeft,
        // Get the current mouse position
        x: e.clientX,
      });
    }
  };

  const handleClickNumber = useCallback(
    (index: number) => {
      if (isDragging) {
        return;
      }
      if (sliderWeightContainerRef.current && !isDragging)
        sliderWeightContainerRef.current.scroll({
          left: index * calculateWidthAndShow.numberWidth,
        });
    },
    [sliderWeightContainerRef.current, isDragging, calculateWidthAndShow.numberWidth],
  );

  const Numbers = useMemo(() => {
    return generateWeights.map((item, index) => (
      <Number
        key={index}
        isDragging={isDragging}
        isSelected={selectedNumber === item}
        onClick={() => handleClickNumber(index)}
        numberWidth={calculateWidthAndShow.numberWidth}
      >
        {item}
      </Number>
    ));
  }, [
    isDragging,
    selectedNumber,
    generateWeights,
    handleClickNumber,
    calculateWidthAndShow.numberWidth,
  ]);

  return (
    <Box
      flex={1}
      height={60}
      display={'flex'}
      borderRadius={2}
      position={'relative'}
      bgcolor={theme.palette.secondary.main}
      {...containerProps}
    >
      <RedArrow />
      <NumbersContainer
        onScroll={handleScroll}
        onMouseUp={mouseUpHandler}
        onMouseMove={mouseMoveHandler}
        onMouseDown={mouseDownHandler}
        ref={sliderWeightContainerRef}
      >
        <Box display={'flex'} flexGrow={1} justifyContent={'center'}>
          {GeneratePlaceholders}
          {Numbers}
          {GeneratePlaceholders}
        </Box>
      </NumbersContainer>
    </Box>
  );
};

export default SliderWeight;
