import { Storage } from '@ionic/storage';

let storage: Storage;

const getStore = async () => {
  if (!storage) {
    storage = new Storage();
    await storage.create();
  }
  return storage;
};

export const storeDidYouKnow = async () => {
  const store = await getStore();
  const timeNow = new Date().getTime().toString();
  await store.set('didYouKnow', timeNow);
};

export const storeOtherMessages = async () => {
  const store = await getStore();
  const timeNow = new Date().getTime().toString();
  await store.set('otherMessages', timeNow);
};

export const getDidYouKnow = async () => {
  const store = await getStore();
  const timeStamp = await store.get('didYouKnow');
  return timeStamp ? JSON.parse(timeStamp) : undefined;
};

export const getOtherMessages = async () => {
  const store = await getStore();
  const timeStamp = await store.get('otherMessages');
  return timeStamp ? JSON.parse(timeStamp) : undefined;
};
