import React, {
  CSSProperties,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  BoxProps,
  IconButton,
  IconButtonProps,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import moment from 'moment';
import ArrowRight from '../../assets/ArrowRight';
import Button, { ButtonProps } from '@mui/material/Button';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { TypographyProps } from '@mui/material/Typography/Typography';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { useTranslation } from 'react-i18next';

const days = [
  'days.mo',
  'days.tu',
  'days.we',
  'days.th',
  'days.fr',
  'days.sa',
  'days.su',
];

const Container = styled(Box, {
  shouldForwardProp: prop => prop !== 'backgroundColor',
})<BoxProps & { backgroundColor?: CSSProperties['color'] }>(
  ({ theme, backgroundColor }) => ({
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(1, 2),
    background: backgroundColor ? backgroundColor : theme.palette.highlight.main,
  }),
);

const ButtonMain = styled(Button, {
  shouldForwardProp: prop => prop !== 'selectedDate' && prop !== 'otherMonth',
})<ButtonProps & { selectedDate?: boolean; otherMonth?: boolean }>(
  ({ theme, selectedDate, otherMonth }) => ({
    width: 36,
    minWidth: 36,
    maxWidth: 36,
    height: 36,
    minHeight: 36,
    maxHeight: 36,
    display: 'flex',
    lineHeight: 1.5,
    borderRadius: '18px',
    background:
      selectedDate && theme.palette.mode === 'dark'
        ? theme.palette.secondary.main
        : selectedDate
        ? theme.palette.primary.main
        : undefined,
    color: selectedDate
      ? theme.palette.secondary.light
      : otherMonth
      ? theme.palette.kmColorsCoolGrey.main
      : theme.palette.primary.main,
    ':hover': {
      background:
        selectedDate && theme.palette.mode === 'dark'
          ? theme.palette.secondary.main
          : selectedDate
          ? theme.palette.primary.main
          : undefined,
    },
  }),
);

const MonthButton = styled(Button)<ButtonProps>(({ theme, ...props }) => ({
  cursor: !props?.onClick ? 'auto' : undefined,
  ':hover': {
    background: theme.palette.highlight.main,
  },
}));

const CalendarMonthName = styled(Typography)<TypographyProps>(({ theme }) => ({
  textAlign: 'left',
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.kmColorsCoolGrey.main
      : theme.palette.medication.main,
  fontWeight: theme.typography.fontWeightBold,
}));

const DayName = styled(Typography)<TypographyProps>(({ theme }) => ({
  flex: 1,
  align: 'center',
  display: 'flex',
  textAlign: 'center',
  color: theme.palette.kmColorsCoolGrey.main,
  fontWeight: theme.typography.fontWeightMedium,
}));

const CalendarTodayOutlinedIconMain = styled(CalendarTodayOutlinedIcon)<SvgIconProps>(
  ({ theme }) => ({
    width: '14px',
    height: '14px',
    marginRight: theme.spacing(1),
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.kmColorsCoolGrey.main
        : theme.palette.medication.main,
  }),
);

interface MonthNameProps extends ButtonProps {
  openMonthly: boolean;
  firstDayOfTheMonth: moment.Moment;
  setOpenMonthly?: (value: boolean) => void;
}

const MonthName = styled((props: MonthNameProps) => {
  const { firstDayOfTheMonth, setOpenMonthly, openMonthly, ...other } = props;

  const handleSetOpen = () => {
    setOpenMonthly?.(!openMonthly);
  };

  return (
    <MonthButton
      {...other}
      disableTouchRipple
      onClick={setOpenMonthly ? handleSetOpen : undefined}
    >
      <CalendarTodayOutlinedIconMain />
      <CalendarMonthName>
        {moment(firstDayOfTheMonth).format('MMMM')}
        {` '${moment(firstDayOfTheMonth).format('YY')}`}
      </CalendarMonthName>
    </MonthButton>
  );
})(({ theme }) => ({
  display: 'flex',
  alignSelf: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(1.5),
  padding: theme.spacing(0.5, 1),
}));

interface ArrowsPropsType extends IconButtonProps {
  isPrevArrow?: boolean;
  handleClick: () => void;
}

const Arrows = styled((props: ArrowsPropsType) => {
  const theme = useTheme();
  const { isPrevArrow, handleClick, ...other } = props;

  return (
    <IconButton onClick={handleClick} {...other}>
      <ArrowRight
        fill={
          theme.palette.mode === 'dark'
            ? theme.palette.kmColorsCoolGrey.main
            : theme.palette.primary.main
        }
      />
    </IconButton>
  );
})(({ theme, isPrevArrow }) => ({
  marginTop: theme.spacing(1.5),
  transform: isPrevArrow ? 'rotate(180deg)' : undefined,
}));

export const HorizontalCalendarSuccessIndicator = styled(Box)<BoxProps>(({ theme }) => ({
  width: '8px',
  height: '8px',
  borderRadius: '4px',
  background: theme.palette.success.main,
}));

export const HorizontalCalendarErrorIndicator = styled(Box)<BoxProps>(({ theme }) => ({
  width: '16px',
  height: '4px',
  borderRadius: '2px',
  background: theme.palette.error.main,
}));

const generateWeek = (day: Date): Date[] => {
  const dates: Date[] = [];
  for (let i = 1; i < 8; i++) {
    const date = moment(day).add(i, 'day').toDate();
    dates.push(date);
  }
  return dates;
};

type HorizontalContainerProps = {
  openMonthly?: boolean;
  selectFutureDates?: boolean;
  dayNameProps?: TypographyProps;
  innerContainerProps?: BoxProps;
  dayNumberProps?: TypographyProps;
  dayNumberButtonProps?: ButtonProps;
  onMonthChange?: (date: string) => void;
  backgroundColor?: CSSProperties['color'];
  selectedDate?: (Date | undefined)[] | Date;
  setOpenMonthly?: (newState: boolean) => void;
  goalDates?: { date: Date; success: boolean }[];
  handleChange?: (date: (Date | undefined)[] | Date) => void;
};

const HorizontalCalendar: FC<HorizontalContainerProps & BoxProps> = ({
  goalDates,
  selectedDate,
  handleChange,
  dayNameProps,
  onMonthChange,
  dayNumberProps,
  setOpenMonthly,
  backgroundColor,
  innerContainerProps,
  openMonthly = false,
  dayNumberButtonProps,
  selectFutureDates = false,
  ...containerProps
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const isMultipleSelection = Array.isArray(selectedDate);

  const [currentWeek, setCurrentWeek] = useState<number>(0);
  const [currentMonth, setCurrentMonth] = useState<number>(0);

  const firstDayOfTheMonth = useMemo(() => {
    return moment().startOf('month').add(currentMonth, 'month');
  }, [currentMonth, isMultipleSelection]);

  const generateWeeksOfTheMonth = useMemo((): Date[][] => {
    let end = 5;
    let start = 0;
    const dates: Date[] = [];
    const firstDayOfFirstWeekOfMonth = moment(firstDayOfTheMonth)
      .startOf('week')
      .toDate();

    //check if sunday is 01 of month
    if (
      firstDayOfFirstWeekOfMonth.getDay() === 0 &&
      firstDayOfFirstWeekOfMonth.getDate() === 1
    ) {
      start = -1;
      end = 5;
    }

    for (let i = start; i < end; i++) {
      const date = moment(firstDayOfFirstWeekOfMonth).add(i, 'week').toDate();
      dates.push(date);
    }
    const firstDayOfEachWeek = dates;
    if (!firstDayOfEachWeek) return [];
    return firstDayOfEachWeek.map(date => generateWeek(date));
  }, [firstDayOfTheMonth]);

  useEffect(() => {
    if (!onMonthChange) return;
    onMonthChange(firstDayOfTheMonth.format('YYYY-MM'));
  }, [firstDayOfTheMonth]);

  const handleChangeInside = useCallback(
    (newDate: Date) => {
      if (!handleChange) return;
      if (Array.isArray(selectedDate)) {
        if (!selectedDate[0]) {
          handleChange([newDate, undefined]);
          return;
        } else if (!selectedDate[1]) {
          if (selectedDate[0] && moment(newDate).isBefore(selectedDate[0])) {
            handleChange([newDate, undefined]);
          } else if (selectedDate[0] && moment(newDate).isAfter(selectedDate[0])) {
            handleChange([selectedDate[0], newDate]);
          }
          return;
        }

        handleChange([newDate, undefined]);
        return;
      }

      handleChange(newDate);
    },
    [selectedDate, handleChange],
  );

  /**
   * Check dates to be disabled
   */
  const isDateClickable = (date: Date) => {
    const today = moment().startOf('day');
    return moment(date).isSameOrBefore(today);
  };

  const isSelected = (index: number) => {
    if (isMultipleSelection) {
      return (
        selectedDate[0]?.toLocaleDateString() ===
          generateWeeksOfTheMonth[currentWeek][index].toLocaleDateString() ||
        selectedDate[1]?.toLocaleDateString() ===
          generateWeeksOfTheMonth[currentWeek][index].toLocaleDateString()
      );
    } else {
      return (
        selectedDate?.toLocaleDateString() ===
        generateWeeksOfTheMonth[currentWeek][index].toLocaleDateString()
      );
    }
  };

  const otherMonth = (index: number) => {
    const getMonth = moment(firstDayOfTheMonth).toDate().getMonth();

    let generatedMonth;
    if (currentWeek && currentWeek >= 0) {
      generatedMonth = generateWeeksOfTheMonth[currentWeek][index].getMonth();
    } else {
      generatedMonth = generateWeeksOfTheMonth[0][index].getMonth();
    }

    return getMonth !== generatedMonth;
  };

  const generateDaysInWeeklyView = useMemo(() => {
    return days.map((item, index) => {
      const validCurrentWeek = currentWeek ? (currentWeek < 0 ? 0 : currentWeek) : 0;
      const dateToCheck = generateWeeksOfTheMonth[validCurrentWeek][index];
      const clickable = isDateClickable(dateToCheck);

      return (
        <Box
          flex={1}
          display={'flex'}
          alignItems={'center'}
          flexDirection={'column'}
          key={`monthDayName${index}`}
          {...innerContainerProps}
        >
          <Box display={'flex'} alignSelf={'center'}>
            <DayName variant={'subtitle2'} {...dayNameProps}>
              {t(item)}
            </DayName>
          </Box>

          {!openMonthly && (
            <ButtonMain
              otherMonth={otherMonth(index)}
              selectedDate={isSelected(index)}
              sx={{ marginTop: theme.spacing(1.5) }}
              disabled={selectFutureDates ? false : !clickable}
              onClick={() =>
                handleChangeInside(
                  moment(generateWeeksOfTheMonth[currentWeek][index]).toDate(),
                )
              }
              {...dayNumberButtonProps}
            >
              {moment(dateToCheck).format('DD')}
            </ButtonMain>
          )}
        </Box>
      );
    });
  }, [
    currentWeek,
    openMonthly,
    dayNameProps,
    selectFutureDates,
    handleChangeInside,
    innerContainerProps,
    dayNumberButtonProps,
    generateWeeksOfTheMonth,
  ]);

  const handlePreviousClick = () => {
    if (openMonthly) {
      setCurrentMonth(currentMonth - 1);
    } else {
      if (currentWeek > 0) {
        setCurrentWeek(prevState => (prevState - 1 >= 0 ? prevState - 1 : 0));
      } else {
        setCurrentMonth(currentMonth - 1);
        setCurrentWeek(4);
      }
    }
  };

  const handleNextClick = () => {
    if (openMonthly) {
      setCurrentMonth(currentMonth + 1);
    } else {
      if (currentWeek < 4) {
        setCurrentWeek(prevState => (prevState + 1 <= 4 ? prevState + 1 : 4));
      } else {
        setCurrentMonth(currentMonth + 1);
        setCurrentWeek(0);
      }
    }
  };

  const WeekMap = useMemo(() => {
    const handleBorderRadius = (day: Date) => {
      if (isMultipleSelection) {
        return selectedDate[0] && selectedDate[1]
          ? moment(day).get('day') === 0 ||
            selectedDate[1]?.toLocaleDateString() === day.toLocaleDateString()
            ? '0px 24px 24px 0px'
            : moment(day).get('day') === 1 ||
              selectedDate[0]?.toLocaleDateString() === day.toLocaleDateString()
            ? '24px 0px 0px 24px'
            : moment(day).isBetween(selectedDate[0], selectedDate[1])
            ? '0px'
            : '0px 24px 24px 0px'
          : '24px';
      } else {
        return '24px';
      }
    };

    const isSelected = (day: Date) => {
      return isMultipleSelection
        ? selectedDate[0] && selectedDate[1]
          ? moment(day).isBetween(selectedDate[0], selectedDate[1])
            ? true
            : selectedDate[0]?.toLocaleDateString() === day.toLocaleDateString() ||
              selectedDate[1]?.toLocaleDateString() === day.toLocaleDateString()
          : selectedDate[0]?.toLocaleDateString() === day.toLocaleDateString() ||
            selectedDate[1]?.toLocaleDateString() === day.toLocaleDateString()
        : selectedDate?.toLocaleDateString() === day.toLocaleDateString();
    };

    return generateWeeksOfTheMonth.map((week, weekIndex) => (
      <Box
        flex={1}
        display="flex"
        key={`week-${weekIndex}`}
        justifyContent={'space-between'}
      >
        {week.map((day, dayIndex) => {
          const goalDate = goalDates?.find(
            goal =>
              moment(goal.date).format('DD/MM/YYYY') === moment(day).format('DD/MM/YYYY'),
          );
          return (
            <Box
              flex={1}
              display="flex"
              alignItems={'center'}
              key={`day-${dayIndex}`}
              justifyContent={'center'}
              bgcolor={
                isSelected(day) && theme.palette.mode === 'dark'
                  ? theme.palette.secondary.main
                  : isSelected(day)
                  ? theme.palette.primary.main
                  : backgroundColor
                  ? backgroundColor
                  : theme.palette.highlight.main
              }
              position={'relative'}
              marginTop={theme.spacing(1.5)}
              borderRadius={handleBorderRadius(day)}
            >
              <ButtonMain
                selectedDate={isSelected(day)}
                disabled={selectFutureDates ? false : !isDateClickable(day)}
                onClick={() => {
                  handleChangeInside(moment(day).toDate());
                }}
                otherMonth={
                  moment(firstDayOfTheMonth).toDate().getMonth() !== day.getMonth()
                }
              >
                {moment(day).format('DD')}
              </ButtonMain>
              {goalDate && (
                <Box
                  left={0}
                  right={0}
                  bottom={2}
                  height={'8px'}
                  display={'flex'}
                  alignItems={'center'}
                  position={'absolute'}
                  justifyContent={'center'}
                >
                  {goalDate.success ? (
                    <HorizontalCalendarSuccessIndicator />
                  ) : (
                    <HorizontalCalendarErrorIndicator />
                  )}
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    ));
  }, [
    goalDates,
    selectedDate,
    firstDayOfTheMonth,
    isMultipleSelection,
    generateWeeksOfTheMonth,
  ]);

  return (
    <Container {...containerProps} backgroundColor={backgroundColor}>
      <MonthName
        openMonthly={openMonthly}
        setOpenMonthly={setOpenMonthly}
        firstDayOfTheMonth={firstDayOfTheMonth}
      />
      <Box display={'flex'} flex={1} alignItems={'flex-start'}>
        <Arrows handleClick={handlePreviousClick} isPrevArrow />
        <Box display={'flex'} flex={1} flexDirection={'column'}>
          <Box
            flex={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            {generateDaysInWeeklyView}
          </Box>
          {openMonthly && (
            <Box display="flex" flexDirection="column">
              {WeekMap}
            </Box>
          )}
        </Box>
        <Arrows handleClick={handleNextClick} />
      </Box>
    </Container>
  );
};

export default HorizontalCalendar;
