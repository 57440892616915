import React, { FC, ReactNode } from 'react';
import {
  alpha,
  Box,
  ListItemIcon,
  ListItemText,
  ListItemTextProps,
  MenuItem,
  MenuItemProps,
  MenuList,
  Paper,
  PaperProps,
  styled,
  SvgIcon,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { MenuOption } from '../../types/MenuOption';
import AdminAppLogo from '../../assets/AdminAppLogo';
import AdminAppLogoText from '../../assets/AdminAppLogoText';
import { TypographyProps } from '@mui/material/Typography/Typography';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

const PaperMain = styled(Paper)<PaperProps>(({ theme }) => ({
  display: 'flex',
  height: '100%',
  borderRadius: 0,
  alignItems: 'left',
  flexDirection: 'column',
  background:
    theme.palette.mode === 'dark'
      ? theme.palette.secondary.main
      : theme.palette.secondary.light,
}));

const MenuItemMain = styled(MenuItem, {
  shouldForwardProp: prop => prop !== 'isshrinkBar',
})<MenuItemProps & { isshrinkBar?: boolean }>(({ theme, isshrinkBar }) => ({
  height: 40,
  minHeight: 40,
  borderRadius: 8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: theme.spacing(3),
  padding: isshrinkBar ? '12px' : '16px',
  color: theme.palette.primary.main,
  '.MuiListItemIcon-root': {
    minWidth: 18,
    color: theme.palette.primary.main,
  },
  '&.Mui-selected': {
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.kmColorsTurquoise.main
        : theme.palette.kmColorsRed.main,
    background:
      theme.palette.mode === 'dark'
        ? alpha(theme.palette.kmColorsTurquoise.main, 0.3)
        : theme.palette.info.main,
    '.MuiListItemIcon-root': {
      color:
        theme.palette.mode === 'dark'
          ? theme.palette.kmColorsTurquoise.main
          : theme.palette.kmColorsRed.main,
    },
  },
  ':hover': {
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.kmColorsTurquoise.main
        : theme.palette.kmColorsRed.main,
    '.MuiListItemIcon-root': {
      color:
        theme.palette.mode === 'dark'
          ? theme.palette.kmColorsTurquoise.main
          : theme.palette.kmColorsRed.main,
    },
  },
}));

const ListItemTextMain = styled(ListItemText)<ListItemTextProps>(({ theme }) => ({
  flex: 1,
  display: 'flex',
  whiteSpace: 'normal',
  textTransform: 'capitalize',
  marginLeft: theme.spacing(1),
}));

const Copyright = styled(Typography)<TypographyProps>(({ theme }) => ({
  alignSelf: 'center',
  color: theme.palette.primary.light,
  marginBottom: theme.spacing(2),
}));

const MenuText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightBold,
  textTransform: lang === 'el' ? 'capitalize' : 'uppercase',
}));

type SideBarType = {
  logoBig?: ReactNode;
  logoSmall?: ReactNode;
  isAdminApp?: boolean;
  shrunkSideBar: boolean;
  menuOptions: MenuOption[];
  handleClickRoute: (route: string) => void;
  isCurrentScreen: (link: string) => boolean;
};
const SideBar: FC<SideBarType> = ({
  logoBig,
  logoSmall,
  isAdminApp,
  menuOptions,
  shrunkSideBar,
  isCurrentScreen,
  handleClickRoute,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const handleIcon = () => {
    if (isAdminApp) {
      return !shrunkSideBar ? <AdminAppLogoText /> : <AdminAppLogo />;
    }

    if (logoBig && logoSmall) {
      return !shrunkSideBar ? logoBig : logoSmall;
    }
  };

  return (
    <PaperMain sx={{ width: shrunkSideBar ? 60 : 180 }}>
      <Box
        flex={1}
        margin={2}
        display={'flex'}
        alignItems={'center'}
        flexDirection={'column'}
      >
        {handleIcon()}
        <MenuList
          sx={{ padding: 0, mt: 6 }}
          subheader={
            !shrunkSideBar && (
              <MenuText variant={'body1'}>{t('dashboard.sideBar.menu')}</MenuText>
            )
          }
        >
          {menuOptions.map((item, index) => (
            <Tooltip
              placement={'right'}
              key={`tooltip-${index}`}
              title={shrunkSideBar ? t(item.label) : ''}
            >
              <MenuItemMain
                isshrinkBar={shrunkSideBar}
                key={`menuItemMain-${index}`}
                selected={isCurrentScreen(item.link)}
                onClick={() => handleClickRoute(item.link)}
              >
                <ListItemIcon
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <SvgIcon inheritViewBox fill={'inherit'} component={item.icon} />
                </ListItemIcon>
                {!shrunkSideBar && (
                  <ListItemTextMain
                    primaryTypographyProps={{
                      sx: {
                        ...theme.typography.subtitle2,
                        fontWeight: theme.typography.fontWeightMedium,
                      },
                    }}
                  >
                    {t(item.label)}
                  </ListItemTextMain>
                )}
              </MenuItemMain>
            </Tooltip>
          ))}
        </MenuList>
      </Box>
      <Copyright variant={'body2'} textAlign={'center'}>
        {t('karabinis.footer', { year: moment().year() })}
      </Copyright>
    </PaperMain>
  );
};

export default SideBar;
