import * as React from 'react';
import { SVGProps } from 'react';

const DropdownDownArrowDisabled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.35765 7.4498C5.88624 6.91946 5.04549 6.8478 4.47979 7.28975C3.91409 7.73171 3.83765 8.51991 4.30907 9.05026L10.9757 16.5502C11.229 16.8352 11.6043 17 12 17C12.3957 17 12.771 16.8352 13.0243 16.5502L19.6909 9.05026C20.1623 8.51991 20.0859 7.73171 19.5202 7.28975C18.9545 6.8478 18.1138 6.91946 17.6423 7.4498L12 13.7974L6.35765 7.4498Z"
      fill={props?.color ?? '#00000061'}
    />
  </svg>
);

export default DropdownDownArrowDisabled;
