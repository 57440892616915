import api from '../../../../redux/api';
import {
  ApiPagination,
  CustomFood,
  CustomMeal,
  Food,
  Meal,
  MealItem,
  PaginationResult,
} from '@hdcorner/ui-library';

const foodsQueries = api
  .enhanceEndpoints({ addTagTypes: ['CustomMeals'] })
  .injectEndpoints({
    endpoints: build => ({
      getSearchFoods: build.query<
        { foods: Food[]; count: number },
        ApiPagination & { searchText?: string }
      >({
        query: ({ skip, limit, sort, searchText }) => ({
          method: 'GET',
          url: '/hdCornerService/user/foods',
          params: { skip, limit, sort, search: searchText },
        }),
        providesTags: ['FoodData'],
        serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
          return endpointName;
        },
        merge: (currentCache, newItems, otherArgs) => {
          if (otherArgs.arg.skip === 0) {
            currentCache.foods = newItems?.foods;
          } else {
            if (otherArgs.arg.searchText) {
              currentCache.foods = newItems?.foods;
            } else {
              currentCache?.foods.push(...newItems?.foods);
            }
          }
          currentCache.count = newItems?.count;
        },
        forceRefetch: ({ previousArg, currentArg }) => {
          return (
            previousArg?.sort !== currentArg?.sort ||
            previousArg?.skip !== currentArg?.skip ||
            previousArg?.limit !== currentArg?.limit ||
            previousArg?.searchText !== currentArg?.searchText
          );
        },
      }),

      getPopularFoods: build.query<{ foods: Food[] }, { category: string }>({
        query: ({ category }) => ({
          method: 'GET',
          params: { category },
          url: '/hdCornerService/user/foods/popular',
        }),
        providesTags: ['FoodData'],
      }),

      getRecentFoods: build.query<{ foods: Food[] }, void>({
        query: () => ({
          method: 'GET',
          url: '/hdCornerService/user/foods/recent',
        }),
        providesTags: ['FoodData'],
      }),

      getMealsOfDay: build.query<Meal[], { start: string; end: string }>({
        query: ({ start, end }) => ({
          method: 'GET',
          url: '/database/function/getMealsOfDay',
          params: { populate: 'foods.food', start, end },
        }),
        providesTags: ['MealsData'],
      }),

      postCreateCustomFood: build.mutation<void, CustomFood>({
        query: ({ ...rest }) => ({
          body: rest,
          method: 'POST',
          url: '/database/function/createCustomFood',
        }),
        invalidatesTags: ['FoodData'],
      }),

      postMealsLogs: build.mutation<void, Omit<Meal, '_id'>>({
        query: ({ category, logDate, foods }) => ({
          method: 'POST',
          body: { category, logDate, foods },
          url: '/hdCornerService/user/meals/logs',
        }),
        invalidatesTags: ['MealsData'],
      }),

      patchMealsLogs: build.mutation<void, { mealId: string; foods: MealItem[] }>({
        query: ({ mealId, foods }) => ({
          method: 'PATCH',
          body: { foods },
          url: `/hdCornerService/user/meals/logs/${mealId}`,
        }),
        invalidatesTags: ['MealsData'],
      }),

      getCustomMeals: build.query<PaginationResult<CustomMeal>, ApiPagination>({
        query: ({ skip, limit, sort }) => ({
          method: 'GET',
          url: '/database/function/getCustomMeals',
          params: { populate: 'foods.food', skip, limit, sort },
        }),
        providesTags: ['CustomMeals'],
      }),

      createCustomMeal: build.mutation<void, Omit<CustomMeal, '_id' | 'createdAt'>>({
        query: ({ mealName, foods }) => ({
          method: 'POST',
          body: { mealName, foods },
          url: '/database/function/createCustomMeal',
        }),
        invalidatesTags: ['CustomMeals'],
      }),

      editCustomMeal: build.mutation<void, CustomMeal>({
        query: ({ _id, mealName, foods }) => ({
          method: 'PATCH',
          body: { mealName, foods },
          url: `/database/function/editCustomMeal/${_id}`,
        }),
        invalidatesTags: ['CustomMeals'],
      }),
    }),
  });

export const {
  useGetMealsOfDayQuery,
  useGetCustomMealsQuery,
  useGetSearchFoodsQuery,
  useGetRecentFoodsQuery,
  useGetPopularFoodsQuery,
  usePostMealsLogsMutation,
  usePatchMealsLogsMutation,
  useEditCustomMealMutation,
  useLazyGetSearchFoodsQuery,
  useCreateCustomMealMutation,
  usePostCreateCustomFoodMutation,
} = foodsQueries;

export default foodsQueries;
