import { FC } from 'react';
import * as React from 'react';
import { BoxProps, styled, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import ArrowDown from '../assets/ArrowDown';
import MeasurementsIcon from '../assets/Measurements';

const AllMeasurementsContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  borderRadius: '8px',
  alignItems: 'center',
  minWidth: 'max-content',
  backgroundColor: 'white',
  gap: theme.spacing(1),
  padding: theme.spacing(1, 2, 1, 2),
}));

const AllMeasurementsTypography = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  color: theme.palette.primary.main,
}));

const CenterBox = styled(Box)(() => ({
  display: 'flex',
  marginLeft: 'auto',
  alignItems: 'center',
  justifyContent: 'center',
}));

type AllMeasurementsProps = {
  text: string;
  openModal: () => void;
};
export const AllMeasurements: FC<AllMeasurementsProps & BoxProps> = ({
  text,
  openModal,
  ...props
}) => {
  return (
    <AllMeasurementsContainer onClick={openModal} {...props}>
      <MeasurementsIcon width={16} height={16} />
      <AllMeasurementsTypography variant="body1">{text}</AllMeasurementsTypography>
      <CenterBox>
        <ArrowDown />
      </CenterBox>
    </AllMeasurementsContainer>
  );
};

export default AllMeasurements;
