import React, { FC } from 'react';
import { Avatar, Box, IconButton, Typography } from '@mui/material';
import {
  ArrowLeft,
  CustomButton,
  HistoryHourGlassIcon,
  PlanBadge,
  theme,
} from '@hdcorner/ui-library';
import { useTranslation } from 'react-i18next';

type SubsWebToolbarProps = {
  user: string;
  avatar: string;
  premium: boolean;
  backButton: boolean;
  handleGoBack?: () => void;
  handleRedeem?: () => void;
  handleViewHistory?: () => void;
};
const SubsWebToolbar: FC<SubsWebToolbarProps> = ({
  user,
  avatar,
  premium,
  backButton,
  handleGoBack,
  handleRedeem,
  handleViewHistory,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      flexGrow={1}
      display={'flex'}
      maxHeight={'80px'}
      borderRadius={'8px'}
      alignItems={'center'}
      boxSizing={'border-box'}
      padding={theme.spacing(2)}
      marginBottom={theme.spacing(3)}
      justifyContent={'space-between'}
      bgcolor={theme.palette.secondary.light}
    >
      <Box
        flexGrow={1}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'flex-start'}
      >
        <Box display={backButton ? 'block' : 'none'} marginRight={theme.spacing(4)}>
          <IconButton onClick={handleGoBack}>
            <ArrowLeft width={'10px'} height={'16px'} />
          </IconButton>
        </Box>
        <Avatar src={avatar} alt={user} sx={{ width: '48px', height: '48px' }} />
        <Typography
          variant={'subtitle2'}
          sx={{
            margin: theme.spacing(0, 3),
            fontWeight: theme.typography.fontWeightBold,
          }}
        >
          {user}
        </Typography>
        <PlanBadge premium={premium} />
      </Box>

      {/*{handleRedeem && (*/}
      {/*  <CustomButton onClick={handleRedeem} variant={'contained'}>*/}
      {/*    <Box gap={1} display={'flex'} alignItems={'center'}>*/}
      {/*      {t('buttons.redeem')}*/}
      {/*    </Box>*/}
      {/*  </CustomButton>*/}
      {/*)}*/}

      {handleViewHistory && (
        <CustomButton
          variant={'text'}
          onClick={handleViewHistory}
          sx={{ width: '121px', marginLeft: theme.spacing(2) }}
        >
          <Box gap={1} display={'flex'} alignItems={'center'}>
            <HistoryHourGlassIcon />
            <Typography
              variant={'subtitle2'}
              sx={{ fontWeight: theme.typography.fontWeightBold }}
            >
              {t('buttons.history')}
            </Typography>
          </Box>
        </CustomButton>
      )}
    </Box>
  );
};

export default SubsWebToolbar;
