import { Box, BoxProps, Typography, TypographyProps, useTheme } from '@mui/material';
import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import BarChart from './charts/BarChart';
import { useTranslation } from 'react-i18next';

const TotalStepsNumber = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: 54,
  lineHeight: '74px',
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightMedium,
}));

const TotalStepsText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.light,
  marginLeft: theme.spacing(2),
  fontWeight: theme.typography.fontWeightMedium,
}));

type Props = {
  totalSteps: number;
  chartData: { x: Date; y: number }[];
};
const ExerciseChart: FC<Props & BoxProps> = ({ totalSteps, chartData, ...boxProps }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      flex={1}
      display={'flex'}
      borderRadius={'8px'}
      flexDirection={'column'}
      padding={theme.spacing(2)}
      bgcolor={theme.palette.secondary.light}
      {...boxProps}
    >
      <Box display={'flex'} flex={1} alignItems={'center'}>
        <TotalStepsNumber>{totalSteps}</TotalStepsNumber>
        <TotalStepsText variant={'subtitle2'}>{t('headingsTitles.steps')}</TotalStepsText>
      </Box>
      <BarChart chartData={chartData} />
    </Box>
  );
};

export default ExerciseChart;
