import { Box, useMediaQuery, useTheme } from '@mui/material';
import {
  capitalizeFirstLetter,
  CustomButton,
  FeelsEmpty,
  Food,
  foodTitlesString,
  MacrosMetrics,
  MealCollapseCard,
  MealList,
  totalValue,
} from '@hdcorner/ui-library';
import React, { FC, useEffect, useMemo } from 'react';
import { useIonRouter } from '@ionic/react';
import {
  useEditCustomMealMutation,
  useGetCustomMealsQuery,
} from '../queries/foodsQueries';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import useAlert from '../../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';
import { addFoodItem, storeExistingMeal } from '../slices/mealFoodsSlice';
import { ExistingCustomMeal } from '../types/ExistingCustomMeal';

type MealsTabProps = {
  handleUpdateMeal?: () => void;
  handleCreateNewMeal?: () => void;
};

const MealsTab: FC<MealsTabProps> = ({ handleUpdateMeal, handleCreateNewMeal }) => {
  const router = useIonRouter();
  const { presentError, presentSuccess } = useAlert();
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const web = useMediaQuery(theme.breakpoints.up('sm'));
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const { mealCategory } = useAppSelector(state => state.mealFoodsList);

  const { data: customMealsRes, error } = useGetCustomMealsQuery({
    skip: 0,
    limit: 1000,
    sort: ['-createdAt'],
  });

  const [updateCustomMeal] = useEditCustomMealMutation();

  useEffect(() => {
    if (error) {
      presentError(t('errors.fitness.nutrition.errorMealFetch'));
    }
  }, [error]);

  const prepareCustomMeals = useMemo<any>(() => {
    if (!customMealsRes || customMealsRes.documents.length === 0) return [];

    return customMealsRes.documents.map(meal => {
      const wholeMealOriginal = meal;
      const totalCalories = totalValue(meal.foods, 'calories');

      const mealItems = meal.foods.map(item => {
        const food = item.food as Food;
        return {
          food: {
            ...food,
            calculatedCalories: food
              ? Math.round((food.calories / 100) * item.quantity)
              : 0,
          },
          quantity: item.quantity,
        };
      });

      const metrics: MacrosMetrics[] = [
        {
          measure: t('measurements.gr'),
          eaten: Math.round(totalCalories),
          category: t('fitness.calories.titles.calories'),
        },
        {
          measure: t('measurements.gr'),
          category: t('fitness.calories.titles.carbs'),
          eaten: Math.round(totalValue(meal.foods, 'carbs')),
        },
        {
          measure: t('measurements.gr'),
          category: t('fitness.calories.titles.protein'),
          eaten: Math.round(totalValue(meal.foods, 'protein')),
        },
        {
          measure: t('measurements.gr'),
          category: t('fitness.calories.titles.fat'),
          eaten: totalValue(meal.foods, 'fat'),
        },
      ];

      return {
        metrics,
        mealItems,
        logDate: '',
        wholeMealOriginal,
        mealId: meal._id || '',
        totalCalories: totalCalories || 0,
        title: capitalizeFirstLetter(meal.mealName),
        description: foodTitlesString(meal.foods) || '',
        category: '', // custom meal doesn't have category
      };
    });
  }, [customMealsRes, mealCategory]);

  const handleCreateNew = () => {
    if (handleCreateNewMeal) {
      handleCreateNewMeal();
    } else {
      router.push(`/dashboard/fitness/custom-meal/custom-meal`);
    }
  };

  const handleAddMealItems = (data: any) => {
    data.forEach((it: any) => {
      dispatch(
        addFoodItem({
          _id: it.food._id,
          category: mealCategory,
          quantity: it.quantity,
          calories: it.food.calories,
          name: lang === 'en' ? it.food.name.en : it.food.name.en,
        }),
      );
    });
  };

  const handleUpdateExistingMeal = (data: ExistingCustomMeal) => {
    dispatch(storeExistingMeal(data));
    handleAddMealItems(data.foods);
    if (handleUpdateMeal) handleUpdateMeal();
    if (!web) router.push(`/dashboard/fitness/custom-meal/custom-meal`);
  };

  const handleRemoveItemFromMeal = (meal: ExistingCustomMeal, foodId: string) => {
    const removeFood = meal.foods.filter(obj => obj.food._id !== foodId);

    const body = {
      mealName: meal.mealName,
      _id: meal._id || '',
      foods: removeFood.map(food => ({
        food: food.food._id || '',
        quantity: food.quantity || 0,
      })),
    };

    updateCustomMeal(body)
      .unwrap()
      .then(() => presentSuccess('Item removed'))
      .catch(() => presentError('Unable to remove item'));
  };

  return (
    <Box>
      <Box mb={3} gap={1} display={'flex'} flexDirection={'column'}>
        {prepareCustomMeals.map((meal: any) => (
          <MealCollapseCard
            white
            key={meal.mealId}
            title={meal.title}
            metrics={meal.metrics}
            mealItems={meal.mealItems}
            description={meal.description}
            handleAddMealItems={handleAddMealItems}
            totalCal={Math.round(meal.totalCalories)}
            addMore={() => handleUpdateExistingMeal(meal.wholeMealOriginal)}
            handleRemoveItem={foodId =>
              handleRemoveItemFromMeal(meal.wholeMealOriginal, foodId)
            }
          />
        ))}
        {prepareCustomMeals.length === 0 && <FeelsEmpty />}
      </Box>
      <CustomButton
        fullWidth
        color={'primary'}
        variant={'contained'}
        onClick={handleCreateNew}
        children={t('buttons.createNew')}
      />
    </Box>
  );
};

export default MealsTab;
