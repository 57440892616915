import React, { FC } from 'react';
import {
  Avatar,
  AvatarProps,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardContentProps,
  CardProps,
  IconButton,
  IconButtonProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PersonHealthProfIcon from '../assets/PersonHealthProfIcon';
import ClinicalCoachBadgeIcon from '../assets/ClinicalCoachBadgeIcon';

const CardMain = styled(Card)<CardProps>(({ theme }) => ({
  flex: 1,
  height: 72,
  display: 'flex',
  borderRadius: 8,
  boxShadow: theme.shadows[0],
  border: `1px solid ${theme.palette.secondary.main}`,
}));

const CardContentMain = styled(CardContent)<CardContentProps>(({ theme }) => ({
  flex: 1,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0.5),
  '&.MuiCardContent-root:last-child': {
    paddingBottom: theme.spacing(1),
  },
}));

const ProfileName = styled(Typography)<TypographyProps>(({ theme }) => ({
  maxHeight: 40,
  marginBottom: theme.spacing(1),
  color: theme.palette.kmColorsDarkGrey.main,
  fontWeight: theme.typography.fontWeightMedium,
}));

const Speciality = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightBold,
}));

const AvatarMain = styled(Avatar)<AvatarProps>(({ theme }) => ({
  width: 56,
  height: 56,
  borderRadius: 8,
  border: `1px solid ${theme.palette.secondary.main}`,
  margin: theme.spacing(0.5, 1, 0.5, 0.5),
}));

const IconButtonMain = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  margin: theme.spacing(0),
  padding: theme.spacing(0.5),
}));

type HealthProfessionalCardType = {
  data: {
    name: string;
    image: string;
    specialty: string;
    clinicalCoach?: boolean;
  };
  onClick?: () => void;
  cardContentProps?: CardContentProps;
  onRemoveProfessionalClick?: () => void;
};
const HealthProfessionalCard: FC<HealthProfessionalCardType & CardProps> = ({
  data,
  onClick,
  cardContentProps,
  onRemoveProfessionalClick,
  ...cardProps
}) => {
  const MainContent = () => {
    return (
      <CardContentMain {...cardContentProps}>
        <Box display={'flex'} alignItems={'center'}>
          <Box position={'relative'}>
            <AvatarMain src={data.image} />
            {data.clinicalCoach && (
              <Box right={'1px'} bottom={'-5px'} position={'absolute'}>
                <ClinicalCoachBadgeIcon />
              </Box>
            )}
          </Box>
          <Box>
            <ProfileName variant={'subtitle2'}>{data.name}</ProfileName>
            <Speciality variant={'body1'}>{data.specialty}</Speciality>
          </Box>
        </Box>
        <Box>
          {onRemoveProfessionalClick && (
            <IconButtonMain onClick={onRemoveProfessionalClick}>
              <PersonHealthProfIcon width={16} height={16} />
            </IconButtonMain>
          )}
        </Box>
      </CardContentMain>
    );
  };

  return (
    <CardMain raised={false} {...cardProps}>
      {onClick ? (
        <CardActionArea onClick={onClick}>{MainContent()}</CardActionArea>
      ) : (
        <>{MainContent()}</>
      )}
    </CardMain>
  );
};

export default HealthProfessionalCard;
