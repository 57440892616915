import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, BoxProps, styled, useTheme } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import useDebounce from '../../hooks/useDebounce';

const NumbersContainer = styled(Box)<BoxProps>(() => ({
  flexGrow: 1,
  display: 'flex',
  overflowX: 'scroll',
  overflowY: 'hidden',
  scrollSnapType: 'x mandatory',
  '::-webkit-scrollbar': {
    width: 0,
    height: 0,
    background: 'transparent',
  },
}));

interface ButtonMoreProps extends ButtonProps {
  isSelected?: boolean;
  customWidth: number;
}

const Number = styled((props: ButtonMoreProps) => {
  const { isSelected, customWidth, ...other } = props;
  return <Button disableRipple {...other} />;
})(({ theme, isSelected, customWidth }) => ({
  ...(isSelected ? theme.typography.subtitle2 : theme.typography.caption),
  flex: 1,
  padding: 0,
  display: 'flex',
  borderRadius: 0,
  width: customWidth,
  minWidth: customWidth,
  maxWidth: customWidth,
  margin: theme.spacing(0),
  color: isSelected ? theme.palette.primary.main : theme.palette.kmColorsCoolGrey.main,
  fontWeight: isSelected
    ? theme.typography.fontWeightMedium
    : theme.typography.fontWeightRegular,
  ':hover': {
    backgroundColor: 'transparent',
  },
}));

interface LineMoreProps extends BoxProps {
  isSelected?: boolean;
}

const Line = styled((props: LineMoreProps) => {
  const { isSelected, ...other } = props;
  return <Box {...other} />;
})(({ theme, isSelected }) => ({
  width: isSelected ? 2 : 1,
  height: isSelected ? 16 : 10,
  background: isSelected
    ? theme.palette.primary.main
    : theme.palette.kmColorsCoolGrey.main,
  borderRadius: 8,
  marginBottom: 8,
}));

type SliderHeightType = {
  selected: number;
  minHeight?: number;
  maxHeight?: number;
  setSelected: (value: number) => void;
};
const SliderHeight: FC<SliderHeightType> = ({
  selected,
  setSelected,
  minHeight = 100,
  maxHeight = 260,
}) => {
  const theme = useTheme();

  const [selectedNumber, setSelectedNumber] = useState<number>(selected);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [pos, setPos] = useState<{ left: number; x: number }>({ left: 0, x: 0 });

  const debouncedValue = useDebounce(selectedNumber, 300);

  const isFirstRender = useRef<boolean>(true);
  const sliderHeightContainerRef = useRef<HTMLDivElement>(null);

  const calculateWidthAndShow = useMemo(() => {
    const parentWidth = sliderHeightContainerRef.current?.clientWidth ?? 200;
    const numbersToShow = parentWidth > 200 ? 41 : 19;
    const numberWidth = parentWidth / numbersToShow;

    return {
      numberWidth,
      numbersToShow,
    };
  }, [sliderHeightContainerRef.current]);

  useEffect(() => {
    if (debouncedValue) {
      setSelected(debouncedValue);
    }
  }, [debouncedValue]);

  useEffect(() => {
    setSelectedNumber(selected);

    if (sliderHeightContainerRef.current) {
      sliderHeightContainerRef.current.scroll({
        left: (selected - minHeight) * calculateWidthAndShow.numberWidth,
      });
    }
  }, [minHeight, calculateWidthAndShow.numberWidth, selected]);

  const generateHeights = useMemo(() => {
    const arr: number[] = [];

    for (let height = minHeight; height <= maxHeight; height++) {
      arr.push(height);
    }

    return arr;
  }, [minHeight, maxHeight]);

  const handleClickNumber = useCallback(
    (index: number) => {
      if (sliderHeightContainerRef.current && !isDragging) {
        sliderHeightContainerRef.current.scroll({
          left: index * calculateWidthAndShow.numberWidth,
        });
      }
    },
    [sliderHeightContainerRef.current, isDragging, calculateWidthAndShow.numberWidth],
  );

  const Numbers = useMemo(() => {
    const hidden = (item: number) => {
      if (item - 1 === selectedNumber || item + 1 === selectedNumber) return null;
      if (item % 5 === 0 || item === selectedNumber) {
        return item;
      }
    };

    return generateHeights.map((item, index) => (
      <Box
        flex={1}
        key={index}
        display={'flex'}
        alignItems={'center'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        onClick={() => handleClickNumber(index)}
        width={calculateWidthAndShow.numberWidth}
        minWidth={calculateWidthAndShow.numberWidth}
        maxWidth={calculateWidthAndShow.numberWidth}
        sx={{
          scrollSnapAlign: isDragging ? 'none' : 'center',
        }}
      >
        <Number
          isSelected={item === selectedNumber}
          customWidth={calculateWidthAndShow.numberWidth}
        >
          {hidden(item)}
        </Number>
      </Box>
    ));
  }, [
    isDragging,
    selectedNumber,
    generateHeights,
    handleClickNumber,
    calculateWidthAndShow.numberWidth,
  ]);

  const GeneratePlaceholders = useMemo(() => {
    return Array(Math.floor(calculateWidthAndShow.numbersToShow / 2))
      .fill(1)
      .map((item, index) => (
        <Box
          flex={1}
          key={index}
          height={'100%'}
          display={'flex'}
          width={calculateWidthAndShow.numberWidth}
          minWidth={calculateWidthAndShow.numberWidth}
        />
      ));
  }, [calculateWidthAndShow]);

  const GenerateLines = useMemo(() => {
    return (
      <Box flex={1} display={'flex'} alignItems={'flex-end'}>
        {Array(generateHeights[generateHeights?.length - 1] - generateHeights[0] + 1)
          .fill(1)
          ?.map((item, index) => (
            <Box
              flex={1}
              key={index}
              display={'flex'}
              justifyContent={'center'}
              onClick={() => handleClickNumber(index)}
              width={calculateWidthAndShow.numberWidth}
              minWidth={calculateWidthAndShow.numberWidth}
              maxWidth={calculateWidthAndShow.numberWidth}
            >
              <Line />
            </Box>
          ))}
      </Box>
    );
  }, [generateHeights, calculateWidthAndShow.numberWidth, handleClickNumber]);

  const handleScroll = useCallback(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (sliderHeightContainerRef.current && !isDragging) {
      const selectedNum =
        Math.round(
          sliderHeightContainerRef.current.scrollLeft / calculateWidthAndShow.numberWidth,
        ) + minHeight;

      setSelectedNumber(selectedNum);
    }
  }, [sliderHeightContainerRef.current, isDragging, calculateWidthAndShow.numberWidth]);

  const mouseMoveHandler = useCallback(
    (e: any) => {
      if (!isDragging) return;

      // How far the mouse has been moved
      const dx = e.clientX - pos.x;
      // Scroll the element
      if (sliderHeightContainerRef.current) {
        sliderHeightContainerRef.current.scrollLeft = pos.left - dx;
        const selectedNum =
          Math.round(
            sliderHeightContainerRef.current.scrollLeft /
              calculateWidthAndShow.numberWidth,
          ) + minHeight;
        setSelectedNumber(selectedNum);
      }
    },
    [
      pos,
      isDragging,
      sliderHeightContainerRef.current,
      calculateWidthAndShow.numberWidth,
    ],
  );

  const mouseUpHandler = useCallback(() => {
    setIsDragging(false);

    if (sliderHeightContainerRef.current) {
      const selectedNum =
        Math.round(
          sliderHeightContainerRef.current.scrollLeft / calculateWidthAndShow.numberWidth,
        ) + minHeight;
      setSelectedNumber(selectedNum);

      setIsDragging(false);

      sliderHeightContainerRef.current.style.cursor = 'grab';
      sliderHeightContainerRef.current.style.removeProperty('user-select');
    }
  }, [sliderHeightContainerRef.current, calculateWidthAndShow.numberWidth]);

  const mouseDownHandler = (e: any) => {
    setIsDragging(true);

    if (sliderHeightContainerRef.current) {
      sliderHeightContainerRef.current.style.cursor = 'grabbing';
      sliderHeightContainerRef.current.style.userSelect = 'none';

      setPos({
        // The current scroll
        left: sliderHeightContainerRef.current.scrollLeft,
        // Get the current mouse position
        x: e.clientX,
      });
    }
  };

  return (
    <Box
      flex={1}
      height={60}
      borderRadius={2}
      display={'flex'}
      position={'relative'}
      flexDirection={'column'}
      bgcolor={theme.palette.secondary.main}
    >
      <NumbersContainer
        onScroll={handleScroll}
        onMouseUp={mouseUpHandler}
        onMouseDown={mouseDownHandler}
        onMouseMove={mouseMoveHandler}
        ref={sliderHeightContainerRef}
      >
        {GeneratePlaceholders}
        <Box display={'flex'} flex={1} flexDirection={'column'}>
          <Box display={'flex'} flex={1}>
            {Numbers}
          </Box>

          {GenerateLines}
        </Box>
        {GeneratePlaceholders}
      </NumbersContainer>
      <Box
        bottom={0}
        left={'50%'}
        display={'flex'}
        position={'absolute'}
        alignItems={'center'}
        sx={{ transform: 'translateX(-50%)' }}
      >
        <Line isSelected />
      </Box>
    </Box>
  );
};

export default SliderHeight;
