import React, { FC, useMemo } from 'react';
import ExclamationIcon from '../../assets/ExclamationIcon';
import AlertSuccessIcon from '../../assets/AlertSuccessIcon';
import {
  Alert,
  AlertProps,
  Snackbar,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const AlertCustom = styled(Alert, {
  shouldForwardProp: prop => prop !== 'severity',
})<AlertProps & { severity: string }>(({ theme, severity }) => ({
  lineHeight: '22px',
  borderRadius: '8px',
  alignItems: 'center',
  boxSizing: 'border-box',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.secondary.main
      : severity === 'success'
      ? '#EBFBF6'
      : severity === 'error'
      ? theme.palette.info.main
      : severity === 'warning'
      ? '#FFF6D7'
      : theme.palette.secondary.light,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0px 1px 16px rgba(151, 159, 165, 0.16)'
      : '0px 1px 16px rgba(0, 0, 0, 0.16)',
  color: theme.palette.primary.main,
  fontSize: theme.typography.subtitle1.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
  padding: theme.spacing(2, 3),
  '.MuiAlert-message': {
    padding: 0,
  },
}));

type SnackbarAlertProps = {
  open: boolean;
  message: string;
  handleClose: () => void;
  severity: 'success' | 'error' | 'warning' | 'info';
};
const SnackbarAlert: FC<SnackbarAlertProps> = ({
  open,
  message,
  severity,
  handleClose,
}) => {
  const theme = useTheme();
  const web = useMediaQuery(theme.breakpoints.up('md'));

  const handleIcon = useMemo(() => {
    switch (severity) {
      case 'success':
        return <AlertSuccessIcon />;
      case 'error':
        return <ExclamationIcon color={theme.palette.error.main} />;
      case 'info':
        return <ExclamationIcon color={theme.palette.primary.main} />;
      case 'warning':
        return <ExclamationIcon color={theme.palette.warning.main} />;
    }
  }, [theme.palette.error.main, theme.palette.primary.main, theme.palette.warning.main]);

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      autoHideDuration={2000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: web ? 'right' : 'center',
      }}
      sx={{
        '&.MuiSnackbar-root': web ? { top: '90px', right: '7px' } : { top: '55px' },
      }}
    >
      <AlertCustom
        icon={handleIcon}
        severity={severity}
        sx={{
          width: web ? '512px' : '330px',
        }}
      >
        {message}
      </AlertCustom>
    </Snackbar>
  );
};

export default SnackbarAlert;
