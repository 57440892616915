import React, { FC } from 'react';
import { Box, Tab, TabProps, Tabs, TabsProps } from '@mui/material';
import { styled } from '@mui/material/styles';

type TabPanelType = {
  index: number;
  value: number;
  children: React.ReactNode;
};
const TabPanel: FC<TabPanelType> = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`custom-tab-panel-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

const TabMain = styled(Tab, {
  shouldForwardProp: propName => propName !== 'lang',
})<TabProps & { lang: string }>(({ theme, lang }) => ({
  textTransform: lang === 'en' ? 'uppercase' : 'capitalize',
  '&.MuiButtonBase-root': {
    minHeight: 0,
    ...theme.typography.subtitle2,
    padding: theme.spacing(0),
    '&.MuiTab-root': {
      flex: 1,
      minWidth: 40,
      display: 'flex',
      color: theme.palette.primary.main,
      '&.Mui-selected': {
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  },
}));

type TabType = {
  label: string;
  children: React.ReactNode;
  icon?: string | React.ReactElement;
  iconPosition?: 'top' | 'bottom' | 'start' | 'end';
};
type CustomTabsType = {
  value: number;
  tabs: TabType[];
  setValue: (index: number) => void;
};
const CustomTabs: FC<CustomTabsType & TabsProps> = ({
  tabs,
  value,
  setValue,
  ...tabsProps
}) => {
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Tabs
        value={value}
        onChange={handleChange}
        sx={{ minHeight: 0, height: 24 }}
        {...tabsProps}
      >
        {tabs.map((item, index) => (
          <TabMain
            lang={lang}
            value={index}
            icon={item.icon}
            label={item?.label}
            iconPosition={item?.iconPosition}
            key={`tabIndex${index}${item?.label}`}
          />
        ))}
      </Tabs>
      {tabs.map((item, index) => (
        <TabPanel value={value} index={index} key={`tabPanelIndex${index}${item.label}`}>
          {item.children}
        </TabPanel>
      ))}
    </Box>
  );
};

export default CustomTabs;
