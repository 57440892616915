import SearchPanel from './SearchPanel';
import React, { FC } from 'react';
import { cleanSlate, updateAddFoodDetails } from '../slices/mealFoodsSlice';
import {
  useCreateCustomMealMutation,
  useEditCustomMealMutation,
} from '../queries/foodsQueries';
import { useIonRouter } from '@ionic/react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { Food } from '@hdcorner/ui-library';
import useAlert from '../../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

type Props = {
  web?: boolean;
  handleModalPanelWeb?: () => void;
  handleAddItemWeb?: (data: Food) => void;
};
const CustomMealInner: FC<Props> = ({ web, handleAddItemWeb, handleModalPanelWeb }) => {
  const dispatch = useAppDispatch();
  const router = useIonRouter();
  const { presentError, presentSuccess } = useAlert();
  const { t } = useTranslation();
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const { foods, mealCategory, existingCustomMeal } = useAppSelector(
    state => state.mealFoodsList,
  );

  const [createCustomMeal] = useCreateCustomMealMutation();
  const [updateCustomMeal] = useEditCustomMealMutation();

  const handleCreateMeal = (mealName: string) => {
    const body = {
      mealName,
      _id: existingCustomMeal._id !== '' ? existingCustomMeal._id : undefined,
      foods: foods.map(food => ({
        food: food._id || '',
        quantity: food.quantity ? food.quantity : 0,
      })),
    };

    if (existingCustomMeal._id !== '') {
      updateCustomMeal(body)
        .unwrap()
        .then(() => {
          presentSuccess(`${mealName} updated!`);
          dispatch(cleanSlate());
          if (web && handleModalPanelWeb) {
            handleModalPanelWeb();
          }
          if (!web) router.push(`/dashboard/fitness/food-meals/${mealCategory}`);
        })
        .catch(() => {
          presentError(t('errors.fitness.nutrition.errorMealCreate'));
        });
    } else {
      createCustomMeal(body)
        .unwrap()
        .then(() => {
          presentSuccess(`New meal ${mealName} created!`);
          dispatch(cleanSlate());
          if (web && handleModalPanelWeb) {
            handleModalPanelWeb();
          }
          if (!web) router.push(`/dashboard/fitness/food-meals/${mealCategory}`);
        })
        .catch(() => {
          presentError(t('errors.fitness.nutrition.errorMealCreate'));
        });
    }
  };

  const handleAddItem = (data: Food) => {
    if (handleAddItemWeb) {
      handleAddItemWeb(data);
    }

    if (!web) {
      const name = typeof data.name === 'string' ? data.name : data.name[lang];
      const path = name.split(' ').join('-');
      router.push(`/dashboard/fitness/add-food/${path}?customMeal=true`);
      dispatch(updateAddFoodDetails({ ...data }));
    }
  };

  return (
    <SearchPanel
      meal
      handleItemClick={handleAddItem}
      handleCreateMeal={handleCreateMeal}
      label={`${t('fitness.nutrition.labels.foodName')}`}
      placeholder={`${t('fitness.nutrition.placeholders.food')}`}
      button={
        existingCustomMeal._id !== '' ? t('buttons.updateMeal') : t('buttons.createMeal')
      }
    />
  );
};

export default CustomMealInner;
