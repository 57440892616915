import api from '../../../../redux/api';
import moment from 'moment';
import {
  Activity,
  ActivityGoal,
  ActivityLog,
  ApiPagination,
  LogActivity,
  PaginationResult,
} from '@hdcorner/ui-library';

const activitiesQueries = api.injectEndpoints({
  endpoints: build => ({
    getActivityList: build.query<
      PaginationResult<Activity>,
      ApiPagination & { searchText: string }
    >({
      query: ({ skip, limit, sort, searchText }) => ({
        method: 'GET',
        url: '/database/function/searchActivity',
        params: { skip, limit, sort, searchText },
      }),
      providesTags: ['Activity'],
      serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, otherArgs) => {
        if (otherArgs.arg.skip === 0) {
          currentCache.documents = newItems?.documents;
        } else {
          if (otherArgs.arg.searchText) {
            currentCache.documents = newItems?.documents;
          } else {
            currentCache?.documents.push(...newItems?.documents);
          }
        }
        currentCache.count = newItems?.count;
      },
      forceRefetch: ({ previousArg, currentArg }) => {
        return (
          previousArg?.sort !== currentArg?.sort ||
          previousArg?.skip !== currentArg?.skip ||
          previousArg?.limit !== currentArg?.limit ||
          previousArg?.searchText !== currentArg?.searchText
        );
      },
    }),

    getActivityLogs: build.query<
      PaginationResult<ActivityLog>,
      {
        end?: string;
        skip: number;
        limit: number;
        start?: string;
        sort: string[];
      }
    >({
      query: ({ start, end, sort, skip, limit }) => ({
        method: 'GET',
        url: '/database/function/getUserActivities',
        params: {
          end,
          skip,
          sort,
          limit,
          start,
          populate: 'activity',
        },
      }),
      providesTags: ['ActivityLog'],
      serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, otherArgs) => {
        if (otherArgs.arg.skip === 0) {
          currentCache.documents = newItems?.documents;
        } else {
          currentCache?.documents.push(...newItems?.documents);
        }
        currentCache.count = newItems?.count;
      },
      forceRefetch: ({ previousArg, currentArg }) => {
        return (
          previousArg?.end !== currentArg?.end ||
          previousArg?.sort !== currentArg?.sort ||
          previousArg?.skip !== currentArg?.skip ||
          previousArg?.start !== currentArg?.start ||
          previousArg?.limit !== currentArg?.limit
        );
      },
    }),

    getCalendarActivityLogs: build.query<
      PaginationResult<ActivityLog>,
      {
        end?: string;
        skip: number;
        limit: number;
        start?: string;
        sort: string[];
      }
    >({
      query: ({ start, end, sort, skip, limit }) => ({
        method: 'GET',
        url: '/database/function/getUserActivities',
        params: {
          end,
          skip,
          sort,
          limit,
          start,
          populate: 'activity',
        },
      }),
      providesTags: ['ActivityLog'],
    }),

    getTodaysActivityLogs: build.query<PaginationResult<ActivityLog>, void>({
      query: () => ({
        method: 'GET',
        url: '/database/function/getUserActivities',
        params: {
          skip: 0,
          limit: 100,
          populate: 'activity',
          end: moment().endOf('day').toISOString(),
          start: moment().startOf('day').toISOString(),
        },
      }),
      providesTags: ['ActivityLog'],
    }),

    getFirstActivityLog: build.query<PaginationResult<ActivityLog>, { start: string }>({
      query: ({ start }) => ({
        method: 'GET',
        url: '/database/function/getUserActivities',
        params: {
          start,
          skip: 0,
          limit: 1,
          sort: ['logDate'],
          populate: 'activity',
        },
      }),
      providesTags: ['ActivityLog'],
    }),

    getLastActivityLog: build.query<PaginationResult<ActivityLog>, void>({
      query: () => ({
        method: 'GET',
        url: '/database/function/getUserActivities',
        params: {
          skip: 0,
          limit: 100,
          sort: ['-logDate'],
          populate: 'activity',
          start: moment().startOf('day').toISOString(),
        },
      }),
      providesTags: ['ActivityLog'],
    }),

    postCreateActivityLog: build.mutation<void, LogActivity>({
      query: ({ ...rest }) => ({
        body: rest,
        method: 'POST',
        url: '/database/function/createActivityLog',
      }),
      invalidatesTags: ['ActivityLog'],
    }),

    getUserGoalActivity: build.query<ActivityGoal[], void>({
      query: () => ({
        method: 'GET',
        params: { goalType: 'exercise' },
        url: '/database/function/getUserGoal',
      }),
      providesTags: ['ActivityGoal'],
    }),

    postUserGoalActivity: build.mutation<void, ActivityGoal & { userId: string }>({
      query: ({ userId, ...rest }) => ({
        body: rest,
        method: 'POST',
        url: '/hdCornerService/user/goal',
        params: { scope: `Exercise:${userId}` },
      }),
      invalidatesTags: ['ActivityGoal'],
    }),

    patchUserGoalActivity: build.mutation<
      ActivityGoal[],
      ActivityGoal & { userId: string }
    >({
      query: ({ userId, _id, ...rest }) => ({
        body: rest,
        method: 'PATCH',
        url: `/hdCornerService/user/goal/${_id}`,
        params: { scope: `Exercise:${userId}` },
      }),
      invalidatesTags: ['ActivityGoal'],
    }),

    deleteUserGoalActivity: build.mutation<void, { _id: string }>({
      query: ({ _id }) => ({
        method: 'DELETE',
        url: `/database/UserGoal/${_id}`,
      }),
      invalidatesTags: ['ActivityGoal'],
    }),
  }),
});

export const {
  useLazyGetActivityListQuery,
  useGetActivityListQuery,
  useGetActivityLogsQuery,
  useGetLastActivityLogQuery,
  useGetFirstActivityLogQuery,
  useGetUserGoalActivityQuery,
  useGetTodaysActivityLogsQuery,
  useGetCalendarActivityLogsQuery,
  usePostUserGoalActivityMutation,
  usePatchUserGoalActivityMutation,
  usePostCreateActivityLogMutation,
  useDeleteUserGoalActivityMutation,
} = activitiesQueries;

export default activitiesQueries;
