import { IconButton, MobileStepperProps } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';

const MobileStepperMain = styled(MobileStepper)<MobileStepperProps>(({ theme }) => ({
  width: '100%',
  flexGrow: 1,
  background: theme.palette.secondary.light,
  '.MuiMobileStepper-dots': {
    alignItems: 'center',
  },
  '.MuiMobileStepper-dot': {
    width: 4,
    height: 4,
  },
  '.MuiMobileStepper-dotActive': {
    width: 8,
    height: 8,
    backgroundColor: theme.palette.error.main,
  },
  '.MuiIconButton-root': {
    color: theme.palette.primary.main,
  },
}));

type CustomStepperType = {
  activeStep: number;
  totalSteps: number;
  handleNext: () => void;
  handleBack: () => void;
};
const CustomStepper: FC<CustomStepperType> = ({
  activeStep,
  totalSteps,
  handleNext,
  handleBack,
}) => {
  return (
    <MobileStepperMain
      variant="dots"
      steps={totalSteps}
      position="static"
      activeStep={activeStep}
      nextButton={
        <IconButton
          size="small"
          onClick={handleNext}
          disabled={activeStep === totalSteps - 1}
          sx={{ minWidth: '50px' }}
          disableRipple
        >
          {activeStep !== totalSteps - 1 ? <KeyboardArrowRightIcon /> : undefined}
        </IconButton>
      }
      backButton={
        <IconButton
          size="small"
          onClick={handleBack}
          disabled={activeStep === 0}
          sx={{ minWidth: '50px' }}
          disableRipple
        >
          {activeStep !== 0 ? <KeyboardArrowLeftIcon /> : undefined}
        </IconButton>
      }
    />
  );
};

export default CustomStepper;
