import api from '../../../../redux/api';
import { ApiPagination, Video } from '@hdcorner/ui-library';

const videoQueries = api.injectEndpoints({
  endpoints: build => ({
    getSuggestedVideo: build.query<
      { suggestedVideos: Video[] },
      ApiPagination & { suggested: boolean; version: 'en' | 'el' }
    >({
      query: ({ suggested, skip, limit, sort, version }) => ({
        url: '/hdCornerService/user/suggested-video',
        method: 'GET',
        params: {
          skip,
          limit,
          sort,
          version,
          suggested,
          populate: ['attachedVideo', 'thumbnail'],
        },
      }),
    }),
  }),
});

export const { useGetSuggestedVideoQuery } = videoQueries;

export default videoQueries;
