import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = ({ fill = '#F2C72E', ...rest }: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={17}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <rect y={0.5} width={16} height={16} rx={2} fill={fill} />
    <path
      d="M8.57452 9.97581V7.30491C8.57452 7.1475 8.51387 6.99653 8.40591 6.88522C8.29796 6.77391 8.15154 6.71138 7.99887 6.71138C7.8462 6.71138 7.69978 6.77391 7.59183 6.88522C7.48387 6.99653 7.42323 7.1475 7.42323 7.30491V9.97581C7.42323 10.1332 7.48387 10.2842 7.59183 10.3955C7.69978 10.5068 7.8462 10.5693 7.99887 10.5693C8.15154 10.5693 8.29796 10.5068 8.40591 10.3955C8.51387 10.2842 8.57452 10.1332 8.57452 9.97581Z"
      fill="#F2F1F3"
    />
    <path
      d="M7.99887 11.1629C8.11272 11.1629 8.22402 11.1977 8.31868 11.2629C8.41335 11.3281 8.48713 11.4208 8.5307 11.5293C8.57427 11.6377 8.58567 11.7571 8.56346 11.8722C8.54124 11.9873 8.48642 12.0931 8.40591 12.1761C8.32541 12.2591 8.22284 12.3156 8.11117 12.3385C7.99951 12.3614 7.88377 12.3497 7.77858 12.3048C7.6734 12.2598 7.58349 12.1838 7.52024 12.0862C7.45699 11.9885 7.42323 11.8738 7.42323 11.7564C7.42323 11.599 7.48387 11.448 7.59183 11.3367C7.69978 11.2254 7.8462 11.1629 7.99887 11.1629Z"
      fill="#F2F1F3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.66683 13.2246C1.6641 13.3305 1.68892 13.4353 1.73873 13.528C1.78858 13.6207 1.86158 13.6979 1.95015 13.7516C2.03873 13.8054 2.13964 13.8337 2.24242 13.8337H13.7553C13.8583 13.8341 13.9595 13.806 14.0483 13.7524C14.1372 13.6988 14.2105 13.6216 14.2606 13.5289C14.3107 13.4361 14.3357 13.3313 14.3331 13.2251C14.3305 13.119 14.3004 13.0156 14.2458 12.9255L8.48933 3.42903C8.43414 3.34808 8.36087 3.28202 8.27571 3.23641C8.19055 3.1908 8.096 3.16699 8.00003 3.16699C7.90407 3.16699 7.80951 3.1908 7.72435 3.23641C7.63919 3.28202 7.56592 3.34808 7.51073 3.42903L1.75427 12.9255C1.69976 13.0154 1.66956 13.1186 1.66683 13.2246ZM7.99888 4.8636L12.7192 12.6466H3.27858L7.99888 4.8636Z"
      fill="#F2F1F3"
    />
  </svg>
);

export default SvgComponent;
