const calculateCvdRange = (
  cvdRisk: number,
): 'low' | 'warning' | 'high_risk' | 'very_high_risk' => {
  if (cvdRisk < 1) {
    return 'low';
  } else if (cvdRisk >= 1 && cvdRisk < 5) {
    return 'warning';
  } else if (cvdRisk >= 5 && cvdRisk < 10) {
    return 'high_risk';
  } else {
    return 'very_high_risk';
  }
};

export { calculateCvdRange };
