import * as React from 'react';
import { SVGProps } from 'react';

const DeleteIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6665 2.83464H11.3332V0.834635C11.3332 0.657824 11.2629 0.488255 11.1379 0.363231C11.0129 0.238207 10.8433 0.167969 10.6665 0.167969H5.33317C5.15636 0.167969 4.98679 0.238207 4.86177 0.363231C4.73674 0.488255 4.6665 0.657824 4.6665 0.834635V2.83464H1.33317C1.15636 2.83464 0.98679 2.90487 0.861766 3.0299C0.736742 3.15492 0.666504 3.32449 0.666504 3.5013C0.666504 3.67811 0.736742 3.84768 0.861766 3.97271C0.98679 4.09773 1.15636 4.16797 1.33317 4.16797H2.07784L3.3385 14.2506C3.35865 14.4118 3.43697 14.5601 3.55874 14.6677C3.68052 14.7752 3.83738 14.8346 3.99984 14.8346H11.9998C12.1623 14.8346 12.3192 14.7752 12.4409 14.6677C12.5627 14.5601 12.641 14.4118 12.6612 14.2506L13.9218 4.16797H14.6665C14.8433 4.16797 15.0129 4.09773 15.1379 3.97271C15.2629 3.84768 15.3332 3.67811 15.3332 3.5013C15.3332 3.32449 15.2629 3.15492 15.1379 3.0299C15.0129 2.90487 14.8433 2.83464 14.6665 2.83464ZM5.99984 1.5013H9.99984V2.83464H5.99984V1.5013ZM11.4112 13.5013H4.5885L3.42184 4.16797H12.5778L11.4112 13.5013Z"
      fill={props.fill || '#222222'}
    />
  </svg>
);

export default DeleteIcon;
