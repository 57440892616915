import { FC } from 'react';
import * as React from 'react';
import { styled } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow, { TableRowProps } from '@mui/material/TableRow';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableContainer, { TableContainerProps } from '@mui/material/TableContainer';
import { useTranslation } from 'react-i18next';

const CustomTableContainer = styled(TableContainer)<TableContainerProps>(({ theme }) => ({
  flex: 1,
  border: 'none',
  overflow: 'visible',
  borderRadius: '8px',
  boxSizing: 'border-box',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.secondary.main
      : theme.palette.secondary.light,
  padding: theme.spacing(3, 2),
}));

const TableRowHeader = styled(TableRow)<TableRowProps>(({ theme }) => ({
  flex: 1,
  border: 'none',
  boxSizing: 'border-box',
  margin: theme.spacing(0),
  padding: theme.spacing(0),
}));

const TableCellHeadings = styled(TableCell, {
  shouldForwardProp: prop => prop !== 'profApp' && prop !== 'esh',
})<TableCellProps & { profApp: boolean; esh: boolean }>(({ theme, ...props }) => ({
  border: 'none',
  flex: 1,
  width: '25%',
  textAlign: 'center',
  boxSizing: 'border-box',
  textTransform: 'capitalize',
  margin: theme.spacing(0),
  padding: theme.spacing(0),
  color: theme.palette.primary.main,
  fontSize: theme.typography.subtitle2.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  '&:first-of-type': {
    textAlign: 'left',
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.kmColorsCoolGrey.main
        : !props.esh
        ? theme.palette.secondary.dark
        : theme.palette.medication.main,
  },
  '&:last-of-type': {
    color: !props.profApp
      ? theme.palette.diabetes.main
      : theme.palette.kmColorsDarkGrey.main,
  },
}));

const TableCellData = styled(TableCell, {
  shouldForwardProp: prop => prop !== 'profApp',
})<TableCellProps & { profApp: boolean }>(({ theme, ...props }) => ({
  flex: 1,
  width: '25%',
  border: 'none',
  textAlign: 'center',
  boxSizing: 'border-box',
  margin: theme.spacing(0),
  color: theme.palette.primary.light,
  fontSize: theme.typography.subtitle2.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  padding: theme.spacing(3, 0, 0, 0),
  '&:first-of-type': {
    textAlign: 'left',
    color: theme.palette.primary.main,
  },
  '&:last-of-type': {
    color: !props.profApp
      ? theme.palette.diabetes.main
      : theme.palette.kmColorsDarkGrey.main,
  },
}));

type InDepthDataCardProps = {
  rows:
    | {
        name: string;
        min: string | number;
        max: string | number;
        avg: string | number;
      }[]
    | undefined;
  esh?: boolean;
  profApp?: boolean;
  headings: string[];
  altHeading?: string;
  measurement?: string;
};
const InDepthDataCard: FC<InDepthDataCardProps> = ({
  rows,
  esh = false,
  profApp = false,
  headings,
  altHeading,
  measurement,
}) => {
  const { t } = useTranslation();

  return (
    <CustomTableContainer>
      <Table>
        <TableHead>
          <TableRowHeader>
            <TableCellHeadings profApp={profApp} esh={esh}>
              {altHeading
                ? altHeading
                : measurement
                ? t('general.labels.valuesIn', { unit: measurement })
                : ''}
            </TableCellHeadings>
            {headings.map((heading, index) => (
              <TableCellHeadings key={index} profApp={profApp} esh={esh}>
                {heading}
              </TableCellHeadings>
            ))}
          </TableRowHeader>
        </TableHead>
        <TableBody>
          {rows?.map((row: any, index) => {
            const values = Object.values(row);
            return (
              <TableRow key={index}>
                {values.map((value: any, index) => (
                  <TableCellData key={index} profApp={profApp}>
                    {value}
                  </TableCellData>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </CustomTableContainer>
  );
};

export default InDepthDataCard;
