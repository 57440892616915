import { useMemo } from 'react';
import { Hbac1Log } from '../types/Hbac1Log';
import { PaginationResult } from '../types/ApiPagination';
import { BloodGlucoseLog } from '../types/BloodGlucoseLog';

const useCurrentMeasurement = (
  currentMeasurements?: PaginationResult<BloodGlucoseLog | Hbac1Log>,
) => {
  return useMemo(() => {
    if (!currentMeasurements || currentMeasurements.documents.length === 0) {
      return undefined;
    }
    return currentMeasurements.documents[0].measurement;
  }, [currentMeasurements]);
};

export default useCurrentMeasurement;
