import ReactApexChart from 'react-apexcharts';
import React, { FC, useMemo } from 'react';
import { ApexOptions } from 'apexcharts';
import moment from 'moment';

type Props = {
  width?: string | number;
  height?: string | number;
  xAxisAnnotations: XAxisAnnotations[];
  yAxisAnnotations: YAxisAnnotations[];
  chartData?: { name: string; data: any[] }[];
};
const DayScatterChart: FC<Props> = ({
  chartData = [],
  width = '100%',
  height = '170px',
  yAxisAnnotations,
  xAxisAnnotations,
}) => {
  const options = useMemo<ApexOptions>(
    () => ({
      chart: {
        type: 'scatter',
        toolbar: { show: false },
        zoom: { enabled: false },
      },
      annotations: {
        xaxis: xAxisAnnotations,
        yaxis: yAxisAnnotations,
      },
      xaxis: {
        type: 'datetime',
        tooltip: {
          enabled: false,
        },
        min: moment().utc(true).startOf('day').toDate().getTime(),
        max: moment().utc(true).endOf('day').toDate().getTime(),
      },
      tooltip: {
        enabled: true,
        x: {
          show: true,
          format: 'HH:mm',
        },
        y: {
          formatter: (value: number) => `${value} mg/dL`,
          title: {
            formatter: (seriesName: string) => '',
          },
        },
      },
    }),
    [xAxisAnnotations, yAxisAnnotations],
  );

  return (
    <ReactApexChart
      width={width}
      height={height}
      type={'scatter'}
      options={options}
      series={chartData}
    />
  );
};

export default DayScatterChart;
