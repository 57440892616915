import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment/moment';
import { useGetESHQuery } from '../queries/eshQueries';
import { useAppSelector } from '../../../../redux/hooks';
import { findUpdatedAt } from '../../../../utils/dateTimeUtils';
import { Table, TableBody, useMediaQuery } from '@mui/material';
import {
  ESHSortTableHeading,
  ESHSortTableRows,
  HeadCell,
  theme,
} from '@hdcorner/ui-library';
import useAlert from '../../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

type Order = 'asc' | 'desc';

const TableReadings = () => {
  const { t } = useTranslation();
  const { presentError } = useAlert();
  const web = useMediaQuery(theme.breakpoints.up('md'));

  const { customDateFilter } = useAppSelector(state => state.layout);

  const endDate = moment().endOf('day');
  const startDate = moment().subtract(1, 'week').startOf('day');

  const [params, setParams] = useState({
    end: endDate.toISOString(),
    start: startDate.toISOString(),
  });

  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState<Order>('asc');

  const { error, data: eshRes } = useGetESHQuery({
    end: params.end,
    start: params.start,
  });

  useEffect(() => {
    if (error) presentError(t('errors.hypertension.errorESHFetch'));
  }, [error]);

  useEffect(() => {
    if (!customDateFilter) return;
    const endDate = moment(customDateFilter.end).endOf('day');
    const startDate = moment(customDateFilter.start).startOf('day');
    setParams({ start: startDate.toISOString(), end: endDate.toISOString() });
  }, [customDateFilter]);

  const rows = useMemo(() => {
    if (!eshRes || eshRes.logs.length === 0)
      return [
        {
          _id: '0',
          date: '-',
          afib: '-',
          pulses: `- ${t('measurements.bpm')}`,
          systolic: `- ${t('measurements.mmGH')}`,
          diastolic: `- ${t('measurements.mmGH')}`,
        },
      ];
    return eshRes.logs.map(it => {
      const timeStamp = findUpdatedAt(it.logDate);
      return {
        _id: it._id,
        afib: it.afib ? t('userDetails.yes') : '-',
        pulses: `${it.pulses} ${t('measurements.bpm')}`,
        date: t(timeStamp.translation) + timeStamp.time,
        systolic: `${it.systolic} ${t('measurements.mmGH')}`,
        diastolic: `${it.diastolic} ${t('measurements.mmGH')}`,
      };
    });
  }, [eshRes]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createData = (row: any) => {
    return [row.date, row.systolic, row.diastolic, row.pulses, row.afib];
  };

  const headCells: HeadCell[] = [
    { _id: 'dates', label: t('general.labels.dates'), align: 'left' },
    {
      _id: 'systolic',
      align: 'center',
      label: !web ? t('measurements.sys') : t('hypertension.labels.systolic'),
    },
    {
      _id: 'diastolic',
      align: 'center',
      label: !web ? t('measurements.dia') : t('hypertension.labels.diastolic'),
    },
    {
      _id: 'pulses',
      align: 'center',
      label: t('dashboard.dashcards.hypertension.pulses'),
    },
    { _id: 'afib', label: t('measurements.afib'), align: 'center' },
  ];

  return (
    <Table>
      <ESHSortTableHeading
        order={order}
        orderBy={orderBy}
        headCells={headCells}
        onRequestSort={handleRequestSort}
      />
      <TableBody>
        {rows.map((it: any) => (
          <ESHSortTableRows key={it.id} rowCells={createData(it)} alignData />
        ))}
      </TableBody>
    </Table>
  );
};

export default TableReadings;
