import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import {
  ComponentsLayout,
  CustomButton,
  FrequencyItemType,
  theme,
} from '@hdcorner/ui-library';
import moment from 'moment/moment';
import { v4 as uuidv4 } from 'uuid';
import { Modal } from '@mui/material';
import { useIonRouter } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { setFrequency } from './medicationSlice';
import { useAppDispatch } from '../../redux/hooks';
import PageLayout from '../../components/PageLayout';
import TimePickerFields from '../../components/TimePickerFields';
import { hasDuplicateTimes } from '../../utils/hasDuplicateTimes';
import MedicationFrequency from './components/MedicationFrequency';

const MedicationFrequencyPage = () => {
  const dispatch = useAppDispatch();
  const router = useIonRouter();
  const { t } = useTranslation();

  const [frequencyAmount, setFrequencyAmount] = useState<number>(1);
  const [openTimePillPicker, setOpenTimePillPicker] = useState(false);
  const [frequenciesArray, setFrequenciesArray] = useState<FrequencyItemType[]>([]);
  const [selected, setSelected] = useState<FrequencyItemType | undefined>(undefined);

  useEffect(() => {
    const arr = [...frequenciesArray];
    const timesToRender = frequencyAmount;

    if (timesToRender > frequenciesArray.length) {
      for (let i = 1; i <= timesToRender - frequenciesArray.length; i++) {
        arr.push({
          amount: 1,
          uuid: uuidv4(),
          time: moment().format('HH:mm'),
        });
      }
      setFrequenciesArray(arr);
    } else if (timesToRender < frequenciesArray.length) {
      const newArr = arr.slice(0, timesToRender);
      setFrequenciesArray(newArr);
    }
  }, [frequenciesArray, frequencyAmount, setFrequenciesArray]);

  const handleClickAddFrequency = useCallback(() => {
    if (frequenciesArray.length > frequencyAmount) {
      setFrequencyAmount(frequencyAmount + 1);
    }
    setFrequenciesArray([
      ...frequenciesArray,
      {
        amount: 1,
        uuid: uuidv4(),
        time: moment().format('HH:mm'),
      },
    ]);
  }, [frequencyAmount, frequenciesArray]);

  const handleClickCard = useCallback(
    (ind: number) => {
      setSelected(frequenciesArray[ind]);
      setOpenTimePillPicker(true);
    },
    [frequenciesArray],
  );

  const handleSaveData = useCallback(
    (newData: FrequencyItemType) => {
      setOpenTimePillPicker(false);
      if (!selected) return;

      const index = frequenciesArray.findIndex(item => item.uuid === selected.uuid);
      if (index === -1) return;

      const current = frequenciesArray[index];
      const newItem = { ...current, ...newData };
      frequenciesArray.splice(index, 1, newItem as FrequencyItemType);
    },
    [frequenciesArray, selected],
  );

  const handleClickNext = () => {
    dispatch(setFrequency(frequenciesArray));
    router.push('/dashboard/medication-add', 'back', 'pop');
  };

  return (
    <PageLayout
      defaultHref={'/dashboard/medication'}
      headerColor={theme.palette.primary.main}
      headerTitle={t('medication.titles.frequency')}
    >
      <ComponentsLayout display={'flex'} flexDirection={'column'} gap={theme.spacing(2)}>
        <MedicationFrequency
          frequencyAmount={frequencyAmount}
          handleClickCard={handleClickCard}
          frequenciesArray={frequenciesArray}
          setFrequencyAmount={setFrequencyAmount}
          handleClickAddFrequency={handleClickAddFrequency}
        />
        <CustomButton
          fullWidth
          disableElevation
          color={'primary'}
          variant={'contained'}
          onClick={handleClickNext}
          disabled={hasDuplicateTimes(frequenciesArray)}
        >
          {t('buttons.next')}
        </CustomButton>
      </ComponentsLayout>
      <Modal open={openTimePillPicker} onClose={() => setOpenTimePillPicker(false)}>
        <TimePickerFields
          handleSaveData={handleSaveData}
          time={selected ? selected.time : ''}
          amount={selected ? selected.amount : 1}
          label={t('medication.labels.medsAmount')}
        />
      </Modal>
    </PageLayout>
  );
};

export default MedicationFrequencyPage;
