import React, { FC, useState } from 'react';
import { Activity, ArrowLeft, ModalHeading, theme } from '@hdcorner/ui-library';
import { Box, IconButton } from '@mui/material';
import SearchActivityPanel from './SearchActivityPanel';
import AddActivityPanel from './AddActivityPanel';
import { useTranslation } from 'react-i18next';

type Props = {
  dashboardModal?: boolean;
  setOpen: (value: boolean) => void;
};
const ModalActivityContent: FC<Props> = ({ dashboardModal, setOpen }) => {
  const { t } = useTranslation();
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const [screenOne, setScreenOne] = useState<string>('block');
  const [screenTwo, setScreenTwo] = useState({ display: 'none', title: '' });

  const handleAddActivity = (data: Activity) => {
    setScreenOne('none');
    const name = typeof data.name === 'string' ? data.name : data.name[lang];
    setScreenTwo({ display: 'block', title: name });
  };

  const handleAddCustomActivity = () => {
    setScreenOne('none');
    setScreenTwo({
      display: 'block',
      title: t('fitness.exercise.labels.customActivity'),
    });
  };

  const handleActivityAdded = () => {
    setOpen(false);
    setScreenOne('block');
    setScreenTwo({ display: 'none', title: '' });
  };

  const handlePreviousPanel = () => {
    setScreenOne('block');
    setScreenTwo({ display: 'none', title: '' });
  };

  return (
    <Box padding={2} boxSizing={'border-box'} bgcolor={theme.palette.highlight.main}>
      <Box mb={3} position={'relative'}>
        <ModalHeading
          align={'center'}
          showCalendar={'none'}
          title={
            screenOne === 'block'
              ? t('fitness.exercise.labels.selectActivity')
              : screenTwo.title
          }
        />
        <IconButton
          sx={{ padding: 0, position: 'absolute', top: 0, left: 0 }}
          onClick={screenOne === 'block' ? () => setOpen(false) : handlePreviousPanel}
        >
          <ArrowLeft />
        </IconButton>
      </Box>
      <Box display={screenOne}>
        <SearchActivityPanel
          dashboardModal={dashboardModal}
          onActivityClick={handleAddActivity}
          addCustomHandler={handleAddCustomActivity}
        />
      </Box>
      <Box display={screenTwo}>
        <AddActivityPanel
          title={screenTwo.title}
          activityAddedHandler={handleActivityAdded}
        />
      </Box>
    </Box>
  );
};

export default ModalActivityContent;
