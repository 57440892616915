import api from '../../../redux/api';
import {
  ApiPagination,
  AuthUser,
  ClinicalCoachesFound,
  HCPConnection,
  HCPUser,
  HealthProfessionalsData,
  PaginationResult,
} from '@hdcorner/ui-library';

const healthProfessionalsQueries = api.injectEndpoints({
  endpoints: build => ({
    getConnectedHealthProfessionals: build.query<
      PaginationResult<HCPUser>,
      ApiPagination
    >({
      query: ({ skip, limit }) => ({
        url: '/database/function/getConnectedHealthProfessionals',
        method: 'GET',
        params: {
          skip,
          limit,
          populate: [
            'user',
            'healthProfessional',
            'user.profilePicture',
            'healthProfessionalData',
            'healthProfessional.profilePicture',
          ],
        },
      }),
      providesTags: ['HCPUser'],
    }),

    postHCPConnectionCode: build.mutation<
      HCPConnection,
      { code: string; isClinicalCoach: boolean }
    >({
      query: ({ code, isClinicalCoach }) => ({
        method: 'POST',
        body: { code, isClinicalCoach },
        url: '/hdCornerService/user/connect',
      }),
      invalidatesTags: ['HCPUser'],
    }),

    removeHcpUserConnection: build.mutation<void, { hcpId: string }>({
      query: ({ hcpId }) => ({
        method: 'POST',
        body: { hcpId },
        url: `/hdCornerService/user/disconnect`,
      }),
      invalidatesTags: ['HCPUser'],
    }),

    findHcpByCode: build.query<HealthProfessionalsData[], string>({
      query: code => ({
        method: 'GET',
        params: { code },
        url: `/database/function/searchHcpByCode`,
      }),
    }),

    hasClinicalCoach: build.query<HCPUser[], void>({
      query: () => ({
        method: 'GET',
        url: `/database/function/hasClinicalCoach`,
      }),
      providesTags: ['HCPUser'],
    }),

    searchClinicalCoach: build.query<
      {
        count: number;
        results: ClinicalCoachesFound[];
        point: { lat: number; lng: number };
      },
      { postalCode: string }
    >({
      query: ({ postalCode }) => ({
        method: 'GET',
        params: { postalCode, skip: 0, limit: 1000 },
        url: `/hdCornerService/user/search/clinical-coach`,
      }),
    }),

    setSalesGiven: build.mutation<AuthUser, { hcpDataId: string }>({
      query: ({ hcpDataId }) => ({
        method: 'PATCH',
        body: { salesGiven: hcpDataId },
        url: '/database/function/setSalesGiven',
      }),
      invalidatesTags: ['AuthUser'],
    }),
  }),
});

export const {
  useSetSalesGivenMutation,
  useHasClinicalCoachQuery,
  useLazyFindHcpByCodeQuery,
  useSearchClinicalCoachQuery,
  usePostHCPConnectionCodeMutation,
  useRemoveHcpUserConnectionMutation,
  useGetConnectedHealthProfessionalsQuery,
} = healthProfessionalsQueries;

export default healthProfessionalsQueries;
