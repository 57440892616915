const articleCategories = [
  { title: 'constants.articles.benefits', value: 'benefits' },
  { title: 'constants.articles.news', value: 'news' },
  { title: 'constants.articles.exercise', value: 'exercise' },
  { title: 'constants.articles.nutrition', value: 'nutrition' },
  { title: 'constants.articles.hypertension', value: 'hypertension' },
  { title: 'constants.articles.diabetes', value: 'diabetes' },
  { title: 'constants.articles.obesity', value: 'obesity' },
  { title: 'constants.articles.dyslipidemia', value: 'dyslipidemia' },
];

const mealCategories = [
  { label: 'fitness.nutrition.labels.breakfast', value: 'breakfast' },
  { label: 'fitness.nutrition.labels.lunch', value: 'lunch' },
  { label: 'fitness.nutrition.labels.dinner', value: 'dinner' },
  { label: 'fitness.nutrition.labels.snack', value: 'snacks' },
];

export { mealCategories, articleCategories };
