import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  CheckBoxes,
  CustomButton,
  CustomInput,
  DropdownMenu,
  UserData,
} from '@hdcorner/ui-library';
import Box from '@mui/material/Box';
import { diabeticOptions } from '../../../profile/constants';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../../../../utils/useConvertJSON';

const smokerOptions = [
  {
    value: 'true',
    label: 'userDetails.yes',
  },
  {
    value: 'false',
    label: 'userDetails.no',
  },
];

type UserDetailsThreeProps = {
  web?: boolean;
  user?: UserData;
  loading: boolean;
  handleNextPage: (data: Partial<UserData>) => void;
};
const UserDetailsThree: FC<UserDetailsThreeProps> = ({
  user,
  web,
  loading,
  handleNextPage,
}) => {
  const { t } = useTranslation();

  const [amka, setAmka] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [height, setHeight] = useState<string>('');
  const [weight, setWeight] = useState<string>('');
  const [smoker, setSmoker] = useState<string>('false');
  const [diabetic, setDiabetic] = useState<string>('No');
  const [medicated, setMedicated] = useState<boolean>(false);
  const [hypertension, setHypertension] = useState<boolean>(false);
  const [dyslipidemia, setDyslipidemia] = useState<boolean>(false);

  const [heightError, setHeightError] = useState<{
    hasError: boolean;
    errorMessage: string;
  }>({ hasError: false, errorMessage: 'authentication.onboarding.heightRequired' });
  const [weightError, setWeightError] = useState<{
    hasError: boolean;
    errorMessage: string;
  }>({ hasError: false, errorMessage: 'authentication.onboarding.weightRequired' });

  useEffect(() => {
    if (user) {
      setPhone(user.phone || '');
      setAmka(user.socialSecurityNumber || '');
      setSmoker(user.smoker ? 'true' : 'false');
      setMedicated(user.underMedication || false);
      setHypertension(user.hypertension || false);
      setDyslipidemia(user.dyslipidemia || false);
      setDiabetic(user.diabetes ? user.diabetes : 'no');
      setWeight(user.weight ? (user.weight === 0 ? '' : user.weight.toString()) : '');
      setHeight(user.height ? (user.height === 0 ? '' : user.height.toString()) : '');
    }
  }, [user]);

  const handleHeightChange = (newHeight: string) => {
    const cleanedValue = newHeight.replace(/[^0-9,.]/g, '');
    const containsDotOrComma = cleanedValue.includes('.') || cleanedValue.includes(',');
    if (containsDotOrComma) {
      setHeightError({
        hasError: true,
        errorMessage: 'authentication.onboarding.heightInCm',
      });
    } else if (cleanedValue === '') {
      setHeightError({
        hasError: true,
        errorMessage: 'authentication.onboarding.heightRequired',
      });
    } else {
      setHeightError({ hasError: false, errorMessage: '' });
    }
    setHeight(cleanedValue);
  };

  const handleWeightChange = (newWeight: string) => {
    const cleanedValue = newWeight.replace(/[^0-9,.]/g, '');
    const parsedWeight = parseFloat(cleanedValue);
    if (cleanedValue === '') {
      setWeightError({
        hasError: true,
        errorMessage: 'authentication.onboarding.weightRequired',
      });
    } else if (isNaN(parsedWeight)) {
      setWeightError({
        hasError: true,
        errorMessage: 'authentication.onboarding.weightIsValid',
      });
    } else {
      setWeightError({ hasError: false, errorMessage: '' });
    }
    setWeight(cleanedValue);
  };

  const handleSaveData = () => {
    const data: Partial<UserData> = {
      hypertension,
      dyslipidemia,
      phone: phone,
      diabetes: diabetic,
      underMedication: medicated,
      socialSecurityNumber: amka,
      height: parseFloat(height),
      weight: parseFloat(weight),
      smoker: smoker === 'true',
    };
    handleNextPage(data);
  };

  const disableSave = useMemo(() => {
    if (!web) {
      return (
        !smoker ||
        !height ||
        !weight ||
        !diabetic ||
        heightError.hasError ||
        weightError.hasError ||
        loading
      );
    }
    return (
      !phone ||
      !smoker ||
      !height ||
      !weight ||
      !diabetic ||
      heightError.hasError ||
      weightError.hasError ||
      loading
    );
  }, [web, smoker, diabetic, height, weight, phone, loading]);

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      <Box display={'flex'} gap={2} justifyContent={'space-between'}>
        <CustomInput
          fullWidth
          value={phone}
          label={'Phone'}
          placeholder={'-'}
          handleChange={setPhone}
        />
        <CustomInput
          fullWidth
          value={amka}
          placeholder={'-'}
          handleChange={setAmka}
          label={`${t('userDetails.amka')}`}
        />
      </Box>
      <Box display={'flex'} gap={2} justifyContent={'space-between'}>
        <CustomInput
          fullWidth
          value={height}
          error={heightError.hasError}
          handleChange={handleHeightChange}
          suffix={`${t('measurements.cm')}`}
          label={`${t('measurements.heightIn')}`}
          placeholder={`${t('measurements.heightEg')}`}
          helperText={heightError.errorMessage ? `${t(heightError.errorMessage)}` : ''}
        />
        <CustomInput
          fullWidth
          value={weight}
          error={weightError.hasError}
          handleChange={handleWeightChange}
          suffix={`${t('measurements.kg')}`}
          label={`${t('measurements.weightIn')}`}
          placeholder={`${t('measurements.weightEg')}`}
          helperText={weightError.errorMessage ? `${t(weightError.errorMessage)}` : ''}
        />
      </Box>
      <DropdownMenu
        value={smoker}
        setValue={setSmoker}
        label={`${t('userDetails.smoker')}`}
        menuItems={useConvertJSON(smokerOptions)}
      />
      <Box>
        <DropdownMenu
          value={diabetic}
          setValue={setDiabetic}
          label={`${t('userDetails.diabetes')}`}
          menuItems={useConvertJSON(diabeticOptions)}
        />
      </Box>
      <Box display={'flex'} flexDirection={'column'} gap={1} px={2}>
        <CheckBoxes
          checked={hypertension}
          setChecked={setHypertension}
          label={`${t('userDetails.hypertension')}`}
        />
        <CheckBoxes
          checked={dyslipidemia}
          setChecked={setDyslipidemia}
          label={`${t('userDetails.dyslipidemia')}`}
        />
        <CheckBoxes
          checked={medicated}
          setChecked={setMedicated}
          label={`${t('userDetails.medicated')}`}
        />
      </Box>
      <CustomButton
        fullWidth
        variant={'contained'}
        disabled={disableSave}
        onClick={handleSaveData}
      >
        {t('buttons.continue')}
      </CustomButton>
    </Box>
  );
};

export default UserDetailsThree;
