import React, { useState } from 'react';
import { Box } from '@mui/material';
import { ComponentsLayout, CustomTabs, Food } from '@hdcorner/ui-library';
import { useIonRouter } from '@ionic/react';
import PageLayout from '../../../components/PageLayout';
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter';
import FoodsTab from './components/FoodsTab';
import ModalBase from '../components/ModalBase';
import ModalAddFood from './components/ModalAddFood';
import MealsTab from './components/MealsTab';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { updateAddFoodDetails } from './slices/mealFoodsSlice';
import CircleCounter from './components/CircleCounter';
import { useTranslation } from 'react-i18next';
import { mealCategories } from './constants';

const FoodMealsScreen = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const { foods, mealCategory } = useAppSelector(state => state.mealFoodsList);

  const [open, setOpen] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const tabs = [
    {
      label: t('fitness.nutrition.labels.foods'),
      children: <></>,
    },
    {
      label: t('fitness.nutrition.labels.meals'),
      children: <></>,
    },
  ];
  const handleOpenModal = () => {
    if (foods.length !== 0) {
      setOpen(true);
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleAddFood = (data: Food) => {
    const name = typeof data.name === 'string' ? data.name : data.name[lang];
    const path = name.split(' ').join('-');
    router.push(`/dashboard/fitness/add-food/${path}`);

    dispatch(updateAddFoodDetails({ ...data }));
  };

  const translatedCategory = () => {
    const found = mealCategories.find(it => it.value === mealCategory);
    return found ? t(found.label) : '';
  };

  return (
    <PageLayout
      defaultHref={'/dashboard/fitness/nutrition'}
      headerTitle={capitalizeFirstLetter(translatedCategory())}
      endButtons={[
        {
          onClick: handleOpenModal,
          icon: <CircleCounter count={foods.length} />,
        },
      ]}
    >
      <ComponentsLayout>
        <Box gap={2} flex={1} display={'flex'} flexDirection={'column'}>
          <CustomTabs value={selectedTab} setValue={setSelectedTab} tabs={tabs} />
          {selectedTab === 0 ? <FoodsTab handleAddFood={handleAddFood} /> : <MealsTab />}
        </Box>
      </ComponentsLayout>

      <ModalBase open={open} setOpen={setOpen}>
        <ModalAddFood handleCloseModal={handleCloseModal} />
      </ModalBase>
    </PageLayout>
  );
};

export default FoodMealsScreen;
