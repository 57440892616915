import React, { FC, useCallback } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { theme, ToggleCard } from '@hdcorner/ui-library';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';

const Card: FC<{
  web?: boolean;
  title: string;
  isSelected: boolean;
  onClick: () => void;
}> = ({ title, isSelected, onClick, web }) => {
  return (
    <Box
      height={35}
      display={'flex'}
      onClick={onClick}
      alignItems={'center'}
      sx={{ cursor: 'pointer' }}
      paddingX={theme.spacing(2)}
      justifyContent={'space-between'}
      bgcolor={web ? theme.palette.secondary.light : theme.palette.highlight.main}
    >
      <Typography
        variant={'subtitle2'}
        sx={{
          color: theme.palette.primary.main,
          fontWeight: theme.typography.fontWeightRegular,
        }}
      >
        {title}
      </Typography>
      {isSelected && <CheckIcon htmlColor={theme.palette.kmColorsRed.main} />}
    </Box>
  );
};

type Props = {
  selected: string;
  selectedDays: string[];
  setSelected: (str: string) => void;
  setSelectedDays: (days: string[]) => void;
  dates: { label: string; value: string }[];
  datesWeek: { label: string; value: string }[];
};

const MedicationRepeat: FC<Props> = ({
  dates,
  datesWeek,
  selected,
  setSelected,
  selectedDays,
  setSelectedDays,
}) => {
  const { t } = useTranslation();
  const web = useMediaQuery(theme.breakpoints.up('sm'));

  const isSelected = useCallback(
    (item: string) => {
      return !!selectedDays?.includes(item);
    },
    [selectedDays],
  );

  const handleSelected = useCallback(
    (str: string) => {
      const arr = selectedDays ? [...selectedDays] : [];

      if ((str === 'Everyday' || str === 'Custom') && selected !== str) {
        setSelected(str);
        setSelectedDays(datesWeek.map(it => it.value));
      } else if (selected === 'Custom') {
        if (selectedDays.includes(str) && selectedDays.length > 1) {
          const index = selectedDays.findIndex(newDate => newDate === str);
          arr.splice(index, 1);
          setSelectedDays(arr);
        } else if (!selectedDays.includes(str)) {
          arr.push(str);
          setSelectedDays(arr);
        }
      }
    },
    [selectedDays, selected, setSelected, setSelectedDays, datesWeek],
  );
  //FIXME other comp

  return (
    <Box display={'flex'} flexDirection={'column'} gap={'1px'}>
      {dates.map((item, index) => (
        <ToggleCard
          key={index}
          value={item.value}
          label={t(item.label)}
          textTransform={'none'}
          selected={selected === item.value}
          backgroundColor={
            web ? theme.palette.secondary.main : theme.palette.highlight.main
          }
          setSelected={handleSelected}
        />
      ))}
      <Box
        marginY={2}
        width={160}
        height={'1px'}
        display={'flex'}
        alignSelf={'center'}
        bgcolor={theme.palette.secondary.main}
      />
      <Box display={'flex'} flexDirection={'column'} gap={1}>
        {datesWeek.map((item, index) => (
          <Card
            key={index}
            isSelected={isSelected(item.value)}
            onClick={() => handleSelected(item.value)}
            title={t('medication.text.everyItem', { item: t(item.label) })}
          />
        ))}
      </Box>
    </Box>
  );
};

export default MedicationRepeat;
