import { useMemo } from 'react';
import { HCPUser } from '@hdcorner/ui-library';
import { GroupParticipantsDataType } from '@hdcorner/health-professional-app/src/components/ModalCreateChat';

export const usePrepareGroupParticipantsDataUsers = (
  users?: HCPUser[],
): GroupParticipantsDataType[] => {
  return useMemo(
    () =>
      users?.map(user => ({
        id: user.user?._id ?? '',
        name: `${user.user?.firstName} ${user.user?.lastName}`,
        image: user.user?.profilePicture?.url,
      })) ?? [],
    [users],
  );
};

export const usePrepareGroupParticipantsDataHCPs = (
  userId?: string,
  hcpUsers?: HCPUser[],
  specialty?: string,
): GroupParticipantsDataType[] => {
  return useMemo(
    () =>
      hcpUsers
        ?.filter(hcpUser => hcpUser.healthProfessional?._id !== userId)
        ?.filter(
          hcpUser =>
            !specialty || hcpUser.healthProfessionalData?.speciality === specialty,
        )
        ?.map(hcpUser => ({
          id: hcpUser.healthProfessional?._id ?? '',
          name: `${hcpUser.healthProfessional?.firstName} ${hcpUser.healthProfessional?.lastName}`,
          image: hcpUser.healthProfessional?.profilePicture?.url,
        })) ?? [],
    [hcpUsers, specialty, userId],
  );
};
