import api from '../../../../redux/api';
import { Article } from '@hdcorner/ui-library';
import { ArticleParams } from '../types/ArticleParams';

const articleQueries = api.injectEndpoints({
  endpoints: build => ({
    getArticles: build.query<Article[], ArticleParams>({
      query: ({ category, sort, lang }) => ({
        method: 'GET',
        url: '/database/function/getArticles',
        params: { populate: 'image', category, sort, lang },
      }),
      providesTags: ['Article'],
    }),

    getSuggestedArticle: build.query<Article[], ArticleParams>({
      query: ({ suggested, sort, lang }) => ({
        method: 'GET',
        url: '/database/function/getArticles',
        params: { populate: 'image', suggested, sort, lang },
      }),
      providesTags: ['Article'],
    }),

    getArticleById: build.query<Article, { id: string }>({
      query: ({ id }) => ({
        method: 'GET',
        url: `/database/Article/${id}`,
        params: { populate: 'image', id },
      }),
      providesTags: ['Article'],
    }),

    getArticleCategories: build.query<
      { categories: string[] },
      { search?: string; lang: string } | void
    >({
      query: params => ({
        method: 'GET',
        url: `/hdCornerService/user/article/categories`,
        params: {
          lang: params?.lang,
          search: params?.search,
        },
      }),
      providesTags: [],
    }),
  }),
});

export const {
  useGetArticlesQuery,
  useGetArticleByIdQuery,
  useGetSuggestedArticleQuery,
  useGetArticleCategoriesQuery,
} = articleQueries;

export default articleQueries;
