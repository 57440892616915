import {
  ClickNavCardNutrition,
  extractMedicationState,
  HorizontalCalendar,
  Medication,
  Medicine,
  ModalMobile,
  Searchbar,
  useDebounce,
} from '@hdcorner/ui-library';
import React, { FC, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Box, Typography, useTheme } from '@mui/material';
import RemindersDashboard from '../reminders/components/RemindersDashboard';
import {
  useDeleteAllUnTakenMedicationMutation,
  useDeleteSingleMedicationMutation,
  useGetMedicationsQuery,
  useGetSearchMedicinesQuery,
  useRescheduleMedicationMutation,
  useTakeMedicationMutation,
} from './queries/medicationQueries';
import { concatMedicine } from './utils/medicineUtils';
import NavCardMedication from './components/NavCardMedication';
import MedicationDrugModal from './components/MedicationDrugModal';
import MedicationDeleteModal from './components/MedicationDeleteModal';
import RescheduleMedicationModal from './components/RescheduleMedicationModal';
import MedicationInstructionsModal from './components/MedicationInstructionsModal';
import useAlert from '../../hooks/useAlert';
import InfiniteScrollList from '../../components/InfiniteScrollList';
import { useTranslation } from 'react-i18next';

const UserDataMedication: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { presentError, presentSuccess } = useAlert();
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const [search, setSearch] = useState('');
  const [selectedMedication, setSelectedMedication] = useState<Medication | null>(null);
  const [medicineSearchParams, setMedicineSearchParams] = useState({
    skip: 0,
    limit: 10,
  });

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [rescheduleModalOpen, setRescheduleModalOpen] = useState(false);
  const [medicationInstructions, setMedicationInstruction] = useState(false);

  const [rescheduleMedication] = useRescheduleMedicationMutation();
  const [deleteSingleMedication] = useDeleteSingleMedicationMutation();
  const [deleteAllUnTakenMeds] = useDeleteAllUnTakenMedicationMutation();

  const searchText = useDebounce(search, 500);

  const {
    isFetching,
    data: medicinesRes,
    error: errorMedicine,
  } = useGetSearchMedicinesQuery({
    ...medicineSearchParams,
    searchText: searchText,
  });

  const prepareMedicines = useMemo<Medicine[]>(() => {
    if (!medicinesRes) return [];

    return medicinesRes.documents;
  }, [medicinesRes]);

  const [selectedDate, setSelectedDate] = useState<Date>(moment().toDate());

  const [takeMedication] = useTakeMedicationMutation();

  const { data: medicationRes, error: errorMedication } = useGetMedicationsQuery({
    skip: 0,
    limit: 1000,
    endDate: moment(selectedDate).utc(true).endOf('day').toISOString(),
    startDate: moment(selectedDate).utc(true).startOf('day').toISOString(),
  });

  useEffect(() => {
    if (errorMedication || errorMedicine)
      presentError(t('errors.medication.errorFetchMeds'));
  }, [errorMedicine, errorMedication]);

  const medications = useMemo(() => {
    if (!medicationRes) return [];
    return medicationRes.documents;
  }, [medicationRes]);

  const handleChange = (newDate: Date) => {
    setSelectedDate(newDate);
  };

  const onTakeHandler = (medicationId: string) => {
    takeMedication(medicationId);
  };

  const handleDeleteAll = () => {
    if (!selectedMedication) return;
    const id = selectedMedication.medicine._id;
    deleteAllUnTakenMeds({ id: id ? id : '' })
      .unwrap()
      .then(() => {
        setDeleteModalOpen(false);
        setSelectedMedication(null);
        presentSuccess(t('errors.medication.successDelAll'));
      })
      .catch(() => presentError(t('errors.medication.errorDelAll')));
  };

  const handleDeleteEntry = () => {
    if (!selectedMedication) return;
    const medicationId = selectedMedication._id;
    deleteSingleMedication({ medicationId })
      .unwrap()
      .then(() => {
        presentSuccess(t('errors.medication.successDelOne'));
      })
      .catch(() => presentError(t('errors.medication.errorDelOne')));
  };

  const saveReschedule = (rescheduleTo: string) => {
    if (!selectedMedication) return;
    rescheduleMedication({ medicationId: selectedMedication._id, rescheduleTo })
      .unwrap()
      .then(() => {
        setRescheduleModalOpen(false);
        presentSuccess(t('errors.medication.successReschedule'));
      })
      .catch(() => presentError(t('errors.medication.errorReschedule')));
  };

  return (
    <Box display={'flex'} gap={3}>
      <Box display={'flex'} flex={1} flexDirection={'column'} gap={2}>
        {/*Medications Calendar*/}
        <Box>
          <Typography
            variant={'subtitle1'}
            sx={{
              color: theme.palette.primary.main,
              marginBottom: theme.spacing(1),
              fontWeight: theme.typography.fontWeightMedium,
              textTransform: lang === 'el' ? 'capitalize' : 'uppercase',
            }}
          >
            {t('dashboard.dashcards.medication.medications')}
          </Typography>
          <Box
            overflow={'hidden'}
            borderRadius={'8px'}
            bgcolor={theme.palette.secondary.light}
            padding={theme.spacing(2, 1)}
          >
            <HorizontalCalendar
              selectFutureDates
              selectedDate={selectedDate}
              handleChange={date => handleChange(date as Date)}
            />
            {medications.map((medication, index) => {
              return (
                <NavCardMedication
                  key={index}
                  time={medication.time}
                  medicineName={medication.medicine.medicineName}
                  medicationState={extractMedicationState(medication)}
                  onChangeHandler={() => onTakeHandler(medication._id)}
                  onClickHandler={() => setSelectedMedication(medication)}
                  amount={`${medication.amount} (${medication.medicine.medicineType})`}
                  checkLabel={medication.medicine.label ? medication.medicine.label : ''}
                />
              );
            })}
          </Box>
        </Box>
        {/*All Medicines list*/}
        <Box>
          <Typography
            variant={'subtitle1'}
            sx={{
              color: theme.palette.primary.main,
              marginBottom: theme.spacing(1),
              fontWeight: theme.typography.fontWeightMedium,
              textTransform: lang === 'el' ? 'capitalize' : 'uppercase',
            }}
          >
            {t('medication.titles.allMeds')}
          </Typography>
          <Box display={'flex'} flexDirection={'column'} gap={theme.spacing(3)}>
            <Searchbar placeholder={'Search'} value={search} setValue={setSearch} />
            <Box
              sx={{
                borderRadius: '8px',
                backgroundColor: theme.palette.secondary.light,
              }}
            >
              <InfiniteScrollList
                loading={isFetching}
                count={prepareMedicines.length}
                totalCount={medicinesRes?.count}
                skip={medicineSearchParams.skip}
                limit={medicineSearchParams.limit}
                onLoadMore={(skip, limit) => {
                  setMedicineSearchParams({ skip, limit });
                }}
              >
                {prepareMedicines.map(medicine => {
                  return (
                    <Box key={medicine._id}>
                      <ClickNavCardNutrition headings={concatMedicine(medicine)} />
                    </Box>
                  );
                })}
              </InfiniteScrollList>
            </Box>
          </Box>
        </Box>
      </Box>
      {/*Reminders*/}
      <Box flex={1}>
        <RemindersDashboard category={'medication'} />
      </Box>
      <ModalMobile
        open={!!selectedMedication}
        setOpen={() => setSelectedMedication(null)}
      >
        <MedicationDrugModal
          medication={selectedMedication}
          handleDeleteClick={() => setDeleteModalOpen(true)}
          handleCloseModal={() => setSelectedMedication(null)}
          handleRescheduleClick={() => setRescheduleModalOpen(true)}
          handleInstructionsClick={() => setMedicationInstruction(true)}
        />
      </ModalMobile>

      {/*Not wanted for now*/}
      {/*<ModalMobile open={!!selectedMedicine} setOpen={() => setSelectedMedicine(null)}>*/}
      {/*  <MedicineModal medicine={selectedMedicine} />*/}
      {/*</ModalMobile>*/}

      {/*Reschedule Modal*/}
      <ModalMobile
        open={rescheduleModalOpen}
        setOpen={() => setRescheduleModalOpen(false)}
      >
        <RescheduleMedicationModal
          saveChanges={saveReschedule}
          initialDate={selectedMedication?.time}
        />
      </ModalMobile>
      {/*Delete Modal*/}
      <ModalMobile open={deleteModalOpen} setOpen={setDeleteModalOpen}>
        <MedicationDeleteModal
          deleteAll={handleDeleteAll}
          deleteEntry={handleDeleteEntry}
          setModalOpen={setDeleteModalOpen}
        />
      </ModalMobile>
      <ModalMobile open={medicationInstructions} setOpen={setMedicationInstruction}>
        <MedicationInstructionsModal medication={selectedMedication} />
      </ModalMobile>
    </Box>
  );
};

export default UserDataMedication;
