import React, { FC } from 'react';
import {
  Box,
  CircularProgress,
  circularProgressClasses,
  CircularProgressProps,
  LinearProgress,
  LinearProgressProps,
  styled,
  useTheme,
} from '@mui/material';

type ProgressBarType = {
  circular?: boolean;
  progress: number;
  isProgressBarWide?: boolean;
  children?: React.ReactNode;
  linearProgressProps?: LinearProgressProps;
  circularProgressProps?: CircularProgressProps;
};
const LinearProgressMain = styled(LinearProgress, {
  shouldForwardProp: prop => prop !== 'isProgressBarWide' && prop !== 'colorProp',
})<LinearProgressProps & { colorProp: string; isProgressBarWide?: boolean }>(
  ({ theme, colorProp, isProgressBarWide }) => ({
    marginY: 1,
    borderRadius: '24px',
    height: isProgressBarWide ? 16 : 8,
    backgroundColor: theme.palette.secondary.main,
    '.MuiLinearProgress-bar1Determinate': {
      backgroundColor: colorProp,
      borderRadius: '24px',
    },
  }),
);

const CircularProgressMain = styled(CircularProgress)<CircularProgressProps>(() => ({
  position: 'absolute',
  left: 0,
  [`& .${circularProgressClasses.circle}`]: {
    strokeLinecap: 'round',
  },
}));

const CircularProgressWithChildren: FC<
  CircularProgressProps & { children: React.ReactNode }
> = ({ children, ...props }) => {
  return (
    <Box position={'relative'} display={'inline-flex'}>
      <Box position={'relative'}>
        <CircularProgress
          {...props}
          variant="determinate"
          sx={{
            color: theme => theme.palette.secondary.main,
          }}
          value={100}
        />
        <CircularProgressMain variant="determinate" {...props} />
      </Box>
      <Box
        position={'absolute'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        top={0}
        left={0}
        right={0}
        bottom={0}
      >
        {children}
      </Box>
    </Box>
  );
};

const ProgressBar: FC<ProgressBarType> = ({
  circular = false,
  progress,
  isProgressBarWide,
  children,
  circularProgressProps,
  linearProgressProps,
}) => {
  const theme = useTheme();

  const handleColor = () => {
    if (progress <= 0.33) {
      return theme.palette.success.main;
    } else if (progress <= 0.66) {
      return theme.palette.warning.main;
    } else return theme.palette.error.main;
  };

  const handleValue = () => {
    if (progress > 1) {
      return 100;
    } else if (progress < 0) {
      return 0;
    } else return progress * 100;
  };

  return circular ? (
    <CircularProgressWithChildren value={handleValue()} {...circularProgressProps}>
      {children}
    </CircularProgressWithChildren>
  ) : (
    <LinearProgressMain
      value={handleValue()}
      variant={'determinate'}
      colorProp={handleColor()}
      isProgressBarWide={isProgressBarWide}
      {...linearProgressProps}
    />
  );
};

export default ProgressBar;
