import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { SwitchButton, theme } from '@hdcorner/ui-library';

export type NotificationSettingsProps = {
  text: string;
  title: string;
  checked: boolean;
  setChecked: (checked: boolean) => void;
};

const NotificationSettings: FC<NotificationSettingsProps> = ({
  text,
  title,
  checked,
  setChecked,
}) => {
  return (
    <Box display={'flex'} flexDirection={'column'} flexGrow={1}>
      <Box
        flexGrow={1}
        marginBottom={1}
        display={'flex'}
        alignContent={'center'}
        justifyContent={'space-between'}
      >
        <Typography
          color={'primary'}
          variant={'subtitle2'}
          sx={{ fontWeight: theme.typography.fontWeightMedium }}
        >
          {title}
        </Typography>
        <SwitchButton checked={checked} setChecked={setChecked} />
      </Box>

      <Typography variant={'body1'} sx={{ color: theme.palette.primary.light }}>
        {text}
      </Typography>
    </Box>
  );
};

export default NotificationSettings;
