import React, { FC } from 'react';
import TableContainer, { TableContainerProps } from '@mui/material/TableContainer';
import { styled } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell, { TableCellProps } from '@mui/material/TableCell';

const CustomTableContainer = styled(TableContainer)<TableContainerProps>(({ theme }) => ({
  flexGrow: '1',
  border: 'none',
  minWidth: '350px',
  borderRadius: '8px',
  boxSizing: 'border-box',
  padding: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    padding: 0,
  },
  backgroundColor: theme.palette.secondary.light,
}));

const TableCellData = styled(TableCell)<TableCellProps>(({ theme }) => ({
  width: '25%',
  minWidth: '73.5px',
  textAlign: 'center',
  boxSizing: 'border-box',
  fontSize: theme.typography.body1.fontSize,
  fontWeight: theme.typography.body1.fontWeight,
  lineHeight: theme.typography.body1.lineHeight,

  borderBottom: `1px solid ${theme.palette.secondary.main}`,
  padding: theme.spacing(1, 0.5),
  backgroundColor: theme.palette.secondary.light,
  '&:first-of-type': {
    textAlign: 'left',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    padding: theme.spacing(1, 0.5, 1, 1),
  },
  '&:last-of-type': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    padding: theme.spacing(1, 1, 1, 0.5),
  },

  [theme.breakpoints.up('md')]: {
    borderBottom: `4px solid ${theme.palette.secondary.light}`,
    backgroundColor: theme.palette.highlight.main,
    '&:first-of-type': {
      textAlign: 'left',
      borderTopLeftRadius: '8px',
      borderBottomLeftRadius: '8px',
      padding: theme.spacing(1, 0.5, 1, 1),
    },
    '&:last-of-type': {
      borderTopRightRadius: '8px',
      borderBottomRightRadius: '8px',
      padding: theme.spacing(1, 1, 1, 0.5),
    },
  },
}));

type NutritionTableProps = {
  rows: any[];
};
const NutritionTable: FC<NutritionTableProps> = ({ rows }) => {
  return (
    <CustomTableContainer>
      <TableBody>
        {rows.map((row: any, index) => {
          const keys = Object.keys(row);
          return (
            <TableRow
              key={index}
              sx={{
                '&:last-child th, &:last-child td': {
                  borderBottom: 0,
                },
              }}
            >
              {keys.map((key: any, index) => (
                <TableCellData key={index}>{row[key]}</TableCellData>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </CustomTableContainer>
  );
};

export default NutritionTable;
