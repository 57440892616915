import * as React from 'react';
import { SVGProps } from 'react';

const AppleHealthIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    viewBox="0 0 36 32"
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : '36'}
    height={props.height ? props.height : '32'}
    {...props}
  >
    <path
      d="M24.5925 0.0731262C22.4872 0.367451 20.7782 1.15967 18.5389 3.36288C18.4395 3.46067 18.0439 3.62111 17.9057 3.63959C17.8229 3.65065 17.7526 3.65459 17.7306 3.63224C15.357 1.22341 13.5964 0.379145 11.4075 0.0731262C9.4627 -0.198762 7.47707 0.300992 5.79316 1.34805C3.45236 2.80356 1.95964 4.33257 0.893376 6.48614C-0.50072 9.30185 -0.149791 12.6878 1.164 15.5449C4.57793 22.9691 8.98107 26.8151 17.3296 31.707C17.5096 31.8125 17.9442 31.9383 18.1059 31.9831C18.1457 31.9942 18.1878 31.9884 18.2235 31.9677C18.3162 31.9138 18.508 31.8022 18.6704 31.707C27.0189 26.8151 31.4221 22.9691 34.836 15.5449C36.1498 12.6878 36.5007 9.30185 35.1066 6.48614C34.0404 4.33257 32.5476 2.80356 30.2068 1.34805C28.5229 0.300992 26.5373 -0.198762 24.5925 0.0731262Z"
      fill="url(#paint0_linear_7280_79173)"
    />
    <defs>
      <linearGradient
        x1="14.4744"
        y1="0.944295"
        x2="18.8168"
        y2="26.3635"
        id="paint0_linear_7280_79173"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E666A3" />
        <stop offset="0.833333" stopColor="#E44A40" />
        <stop offset="1" stopColor="#E44023" />
      </linearGradient>
    </defs>
  </svg>
);

export default AppleHealthIcon;
