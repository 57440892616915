const FilterOptions = [
  { label: 'constants.diabetes.timing.default', value: 'default' },
  { label: 'constants.diabetes.timing.beforeMeal', value: 'before_meal' },
  { label: 'constants.diabetes.timing.withMeal', value: 'with_meal' },
  { label: 'constants.diabetes.timing.afterMeal', value: 'after_meal' },
  { label: 'constants.diabetes.timing.sports', value: 'sports' },
  { label: 'constants.diabetes.timing.ill', value: 'ill' },
  { label: 'constants.diabetes.timing.special', value: 'special' },
];

export { FilterOptions };
