import {
  CalendarModal,
  Collapsible,
  ComponentsLayout,
  CustomButton,
  FeelsEmpty,
  PDFIcon,
  theme,
} from '@hdcorner/ui-library';
import { Box } from '@mui/material';
import PageLayout from '../../../components/PageLayout';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import useAlert from '../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';
import { useGetTwentyFourHoursQuery, useLazyDownloadPdfReportQuery } from './queries';
import moment from 'moment';
import InfiniteScrollList from '../../../components/InfiniteScrollList';
import InDepthDatePicker from '../../../components/InDepthDatePicker';
import { downloadFile } from '../../../utils/Filesystem';

const TwentyFourHours = () => {
  const { presentError, presentInfo } = useAlert();
  const { t } = useTranslation();

  const [hypertensionParams, setHypertensionParams] = useState({
    skip: 0,
    limit: 20,
  });

  const [endParam, setEndParam] = useState<string | undefined>();
  const [startParam, setStartParam] = useState<string | undefined>();
  const [calendarModalOpen, setCalendarModalOpen] = useState<boolean>(false);

  const [trigger] = useLazyDownloadPdfReportQuery();

  const { error, data, isFetching } = useGetTwentyFourHoursQuery({
    skip: 0,
    limit: 100,
    end: endParam,
    start: startParam,
  });

  useEffect(() => {
    if (error) presentError(t('errors.hypertension.error24hoursFetch'));
  }, [error]);

  const handleDateChange = (dateRange: string[]) => {
    setStartParam(dateRange[0]);
    setEndParam(dateRange[1]);
  };

  const inDepthData = useMemo(() => {
    if (!data?.documents) return [];
    return data.documents.map(res => {
      return {
        _id: res._id,
        title: moment(res.logDate).format('MMMM Do, YYYY'),
        data: {
          'Number of measurements': { value: res.logs.length.toString() },
          'First measurement': {
            value: moment(res.logs[0].logDate).format('DD/MM/YYYY HH:mm'),
          },
          'Last measurement': {
            value: moment(res.logs[res.logs.length - 1].logDate).format(
              'DD/MM/YYYY HH:mm',
            ),
          },
        },
      };
    });
  }, [data]);

  const handlePdfExport = (reportId: string) => {
    if (!reportId) return;
    presentInfo(t('hypertension.notifications.pdf24HoursReportGenerating'));
    trigger(reportId)
      .unwrap()
      .then((res: any) => {
        if (res && res.uploadUrl) {
          return res.uploadUrl;
        } else {
          throw Error(t('errors.generic.error01') as string);
        }
      })
      .then(url => {
        return downloadFile(url, '24hours_report.pdf');
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <PageLayout headerTitle={'24 hours'} defaultHref={'/dashboard/hypertension'}>
      <ComponentsLayout display={'flex'} flexDirection={'column'} gap={theme.spacing(1)}>
        <Box>
          <InDepthDatePicker flex={1} onClick={() => setCalendarModalOpen(true)} />
        </Box>
        <Box>
          <InfiniteScrollList
            loading={isFetching}
            totalCount={data?.count}
            count={data?.documents.length}
            skip={hypertensionParams.skip}
            limit={hypertensionParams.limit}
            onLoadMore={(skip, limit) => setHypertensionParams({ skip, limit })}
          >
            {inDepthData.map((data, index) => (
              <Box key={`${data.title}-${index}`}>
                <Collapsible title={data.title} data={data.data}>
                  <CustomButton
                    fullWidth
                    variant={'contained'}
                    startIcon={<PDFIcon stroke={'#FFFFFF'} />}
                    onClick={() => handlePdfExport(data._id)}
                    style={{
                      backgroundColor: '#D75148',
                      marginTop: theme.spacing(1),
                      color: theme.palette.secondary.light,
                    }}
                  >
                    {t('buttons.pdfDL')}
                  </CustomButton>
                </Collapsible>
              </Box>
            ))}
          </InfiniteScrollList>
          {inDepthData.length === 0 && (
            <Box>
              <FeelsEmpty />
            </Box>
          )}
        </Box>
      </ComponentsLayout>
      <CalendarModal
        open={calendarModalOpen}
        setOpen={setCalendarModalOpen}
        saveDate={date => handleDateChange(date)}
        selectedDate={[startParam || '', endParam || '']}
      />
    </PageLayout>
  );
};

export default TwentyFourHours;
