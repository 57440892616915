import {
  Box,
  BoxProps,
  Card,
  CardActionArea,
  CardActionAreaProps,
  CardContent,
  CardContentProps,
  CardMedia,
  CardProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import { TypographyProps } from '@mui/material/Typography/Typography';
import PlayButtonOutlined from '../../assets/PlayButtonOutlined';
import { useTranslation } from 'react-i18next';

const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

const CardMain = styled(Card, {
  shouldForwardProp: propName => {
    return propName !== 'isWeb';
  },
})<CardProps & { isWeb: boolean }>(({ theme, isWeb }) => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  boxShadow: 'none',
  borderRadius: '8px',
  overflow: 'hidden',
  position: 'relative',
  background: isWeb ? theme.palette.secondary.main : theme.palette.secondary.light,
}));

const CardActionAreaMain = styled(CardActionArea)<CardActionAreaProps>(({ theme }) => ({
  display: 'flex',
  flex: 1,
  position: 'unset',
  padding: theme.spacing(2),
}));

const CardContentMain = styled(CardContent)<CardContentProps>(({ theme }) => ({
  display: 'flex',
  flex: 1,
  position: 'relative',
  height: '100%',
  padding: theme.spacing(0),
  '&.MuiCardContent-root:last-child': {
    paddingBottom: theme.spacing(0),
  },
}));

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontWeight: theme.typography.fontWeightRegular,
  color: theme.palette.primary.main,
}));

const TitleInfo = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.secondary.light,
  fontWeight: theme.typography.fontWeightRegular,
  textTransform: lang === 'el' ? 'capitalize' : 'uppercase',
}));

const BadgeBackground = styled(Box)<BoxProps>(({ theme }) => ({
  top: '4%',
  left: '4%',
  width: 29,
  height: 14,
  opacity: 0.8,
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  justifyContent: 'center',
  background: theme.palette.primary.main,
  transform: 'translateY(-4%) translateX(-4%)',
}));

const BadgeTime = styled(Typography)<TypographyProps>(({ theme }) => ({
  position: 'absolute',
  top: '8%',
  left: '9.5%',
  transform: 'translateY(-8%) translateX(-9.5%)',
  color: theme.palette.secondary.main,
}));

type ExerciseVideoCardType = {
  title: string;
  watched?: boolean;
  onClick: () => void;
  thumbnailUrl: string;
  time?: number | string | undefined;
};
const ExerciseVideoCard: FC<ExerciseVideoCardType & CardProps> = ({
  time,
  title,
  watched,
  onClick,
  thumbnailUrl,
  ...cardProps
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const web = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <CardMain raised={false} isWeb={web} {...cardProps}>
      <CardActionAreaMain onClick={onClick}>
        <CardContentMain>
          <Box
            width={112}
            height={64}
            overflow={'hidden'}
            borderRadius={'2px'}
            position={'relative'}
            sx={{ opacity: watched ? 0.2 : 1 }}
            bgcolor={watched ? theme.palette.primary.main : 'transparent'}
          >
            {thumbnailUrl && (
              <CardMedia
                component="img"
                image={thumbnailUrl}
                sx={{
                  width: 112,
                  height: 64,
                  borderRadius: '2px',
                  filter: watched ? 'brightness(50%)' : 'unset',
                }}
              />
            )}
            {time ? (
              <>
                <BadgeBackground />
                <BadgeTime variant={'caption'}>{time}</BadgeTime>
              </>
            ) : null}
            {watched ? (
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translateY(-50%) translateX(-50%)',
                }}
              >
                <TitleInfo variant={'body1'}>
                  {t('fitness.exercise.labels.watched')}
                </TitleInfo>
              </Box>
            ) : null}
          </Box>
          <Box
            flex={1}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            ml={theme.spacing(2)}
          >
            <Title variant={'subtitle1'}>{title}</Title>
          </Box>
          <Box alignSelf={'center'}>
            <PlayButtonOutlined />
          </Box>
        </CardContentMain>
      </CardActionAreaMain>
    </CardMain>
  );
};

export default ExerciseVideoCard;
