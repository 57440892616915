import React, { FC } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material';

const ButtonMain = styled(Button, { shouldForwardProp: propName => propName !== 'dark' })<
  ButtonProps & { dark: boolean }
>(({ dark, theme }) => ({
  boxShadow: 'none',
  ...theme.typography.subtitle2,
  padding: theme.spacing(2, 2),
  borderRadius: theme.shape.borderRadius,
  fontWeight: theme.typography.fontWeightRegular,
  color: dark ? theme.palette.kmColorsButtonText.main : theme.palette.secondary.main,
  background: dark ? theme.palette.kmColorsTurquoise.main : theme.palette.primary.main,
  '&.MuiButton-contained': {
    maxHeight: 48,
    color: dark ? theme.palette.kmColorsButtonText.main : theme.palette.secondary.main,
    background: dark ? theme.palette.kmColorsTurquoise.main : theme.palette.primary.main,
  },
  '&.MuiButton-containedSecondary': {
    maxHeight: 32,
    ...theme.typography.body1,
    opacity: dark ? '0.8' : 1,
    padding: theme.spacing(1, 2),
    fontWeight: theme.typography.fontWeightRegular,
    color: dark ? theme.palette.kmColorsCoolGrey.main : theme.palette.primary.main,
    background: dark ? theme.palette.secondary.dark : theme.palette.secondary.main,
  },
  '&.MuiButton-text': {
    maxHeight: 32,
    background: 'none',
    ...theme.typography.body1,
    padding: theme.spacing(1, 2),
    fontWeight: theme.typography.fontWeightRegular,
    color: dark ? theme.palette.kmColorsTurquoise.main : theme.palette.primary.main,
  },
  '&.MuiButton-textSecondary': {
    ...theme.typography.body1,
    maxHeight: 32,
    background: 'none',
    color: theme.palette.secondary.light,
    fontWeight: theme.typography.fontWeightRegular,
    padding: theme.spacing(1, 2),
  },
  '&.MuiButton-outlined': {
    border: dark
      ? `2px solid ${theme.palette.kmColorsTurquoise.main}`
      : `1px solid ${theme.palette.primary.main}`,
    color: dark ? theme.palette.kmColorsTurquoise.main : theme.palette.primary.main,
    background: 'transparent',
  },
  '&:disabled': {
    opacity: 0.5,
    color: theme.palette.secondary.main,
    background: theme.palette.primary.light,
  },
}));

const CustomButton: FC<ButtonProps> = ({ children, ...rest }) => {
  const theme = useTheme();

  return (
    <ButtonMain dark={theme.palette.mode === 'dark'} {...rest}>
      {children}
    </ButtonMain>
  );
};

export default CustomButton;
