import * as React from 'react';
import { SVGProps } from 'react';

const NotificationAddIcon = ({ fill = '#222222', ...rest }: SVGProps<SVGSVGElement>) => (
  <svg
    width={21}
    height={23}
    viewBox="0 0 21 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M17.957 17.043L16.25 15.336V15H14.25V15.75C14.2501 16.0152 14.3554 16.2695 14.543 16.457L14.836 16.75H3.664L3.957 16.457C4.14455 16.2695 4.24994 16.0152 4.25 15.75V9.75C4.24705 9.01535 4.40668 8.28915 4.71744 7.62346C5.02819 6.95777 5.48239 6.36908 6.04745 5.89959C6.61252 5.43011 7.27446 5.09146 7.98582 4.90792C8.69717 4.72438 9.44033 4.7005 10.162 4.838L11.75 3.25C11.6977 3.21075 11.6414 3.17722 11.582 3.15C11.1494 3.0014 10.7033 2.89556 10.25 2.834V1.75C10.25 1.48478 10.1446 1.23043 9.95711 1.04289C9.76957 0.855357 9.51522 0.75 9.25 0.75C8.98478 0.75 8.73043 0.855357 8.54289 1.04289C8.35536 1.23043 8.25 1.48478 8.25 1.75V2.83C6.5854 3.07026 5.06295 3.90181 3.96118 5.17253C2.85941 6.44324 2.25197 8.06815 2.25 9.75V15.336L0.543 17.043C0.403191 17.1829 0.307985 17.361 0.269417 17.555C0.23085 17.7489 0.250653 17.95 0.326323 18.1327C0.401993 18.3154 0.530132 18.4715 0.694542 18.5814C0.858952 18.6913 1.05225 18.75 1.25 18.75H17.25C17.4477 18.75 17.641 18.6913 17.8055 18.5814C17.9699 18.4715 18.098 18.3154 18.1737 18.1327C18.2493 17.95 18.2692 17.7489 18.2306 17.555C18.192 17.361 18.0968 17.1829 17.957 17.043Z"
      fill={fill}
    />
    <path
      d="M9.25 22.75C8.71957 22.75 8.21086 22.5393 7.83579 22.1642C7.46071 21.7891 7.25 21.2804 7.25 20.75H11.25C11.25 21.2804 11.0393 21.7891 10.6642 22.1642C10.2891 22.5393 9.78043 22.75 9.25 22.75Z"
      fill={fill}
    />
    <path
      d="M20.25 7.75012C20.25 8.01534 20.1446 8.26969 19.9571 8.45723C19.7696 8.64477 19.5152 8.75012 19.25 8.75012H16.25V11.7501C16.25 12.0153 16.1446 12.2697 15.9571 12.4572C15.7696 12.6448 15.5152 12.7501 15.25 12.7501C14.9848 12.7501 14.7304 12.6448 14.5429 12.4572C14.3554 12.2697 14.25 12.0153 14.25 11.7501V8.75012H11.25C10.9848 8.75012 10.7304 8.64477 10.5429 8.45723C10.3554 8.26969 10.25 8.01534 10.25 7.75012C10.25 7.48491 10.3554 7.23055 10.5429 7.04302C10.7304 6.85548 10.9848 6.75012 11.25 6.75012H14.25V3.75012C14.25 3.48491 14.3554 3.23055 14.5429 3.04302C14.7304 2.85548 14.9848 2.75012 15.25 2.75012C15.5152 2.75012 15.7696 2.85548 15.9571 3.04302C16.1446 3.23055 16.25 3.48491 16.25 3.75012V6.75012H19.25C19.5152 6.75012 19.7696 6.85548 19.9571 7.04302C20.1446 7.23055 20.25 7.48491 20.25 7.75012Z"
      fill={fill}
    />
  </svg>
);

export default NotificationAddIcon;
