export enum Filters {
  ILL = 'ill',
  SPORTS = 'sports',
  DEFAULT = 'default',
  SPECIAL = 'special',
  WITH_MEAL = 'with_meal',
  AFTER_MEAL = 'after_meal',
  BEFORE_MEAL = 'before_meal',
}

export const mealTypeLabel = (type: Filters) => {
  switch (type) {
    case Filters.DEFAULT:
      return 'Default';
    case Filters.BEFORE_MEAL:
      return 'Before Meal';
    case Filters.AFTER_MEAL:
      return 'After Meal';
    case Filters.SPORTS:
      return 'Sports';
    case Filters.ILL:
      return 'ill';
    case Filters.SPECIAL:
      return 'Special';
    case Filters.WITH_MEAL:
      return 'With Meal';
  }
};

export const returnFiltersLabel = (category: string) => {
  switch (category) {
    case 'default':
      return 'Default';
    case 'before_meal':
      return 'Before Meal';
    case 'after_meal':
      return 'After Meal';
    case 'sports':
      return 'Sports';
    case 'ill':
      return 'ill';
    case 'special':
      return 'Special';
    case 'with_meal':
      return 'With Meal';
    default:
      return '';
  }
};
