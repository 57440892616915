import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import {
  ChartNumbersInfo,
  FeelsEmpty,
  GoalActionButtons,
  GoalDetailsCard,
  HypertensionGraphContainer,
  InDepthDataCard,
  LineChart,
  ModalMobile,
  usePreparePressureGraph,
  usePreparePulsesGraph,
} from '@hdcorner/ui-library';
import ModalRemoveGoal from '../../../components/ModalRemoveGoal';
import ModalAddEditGoal from '../../../components/ModalAddEditGoal';
import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import {
  useAddPressureGoalMutation,
  useDeletePressureGoalMutation,
  useGetFirstLogOfGoalQuery,
  useGetHypertensionOverallQuery,
  useGetLatestLogQuery,
  useGetPressureGoalQuery,
  useGetPressureGraphDataQuery,
  useGetPulsesGraphDataQuery,
  useUpdatePressureGoalMutation,
} from '../queries/hypertensionQueries';
import moment from 'moment';
import LogsList from '../LogsList';
import { useAppSelector } from '../../../redux/hooks';
import useTimeframe from '../../../hooks/useTimeframe';
import useAlert from '../../../hooks/useAlert';
import InDepthHeading from '../../fitness/components/InDepthHeading';
import { useTranslation } from 'react-i18next';
import { goalProgressHypertension } from '../../../utils/goalProgress';

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  lineHeight: '24px',
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightMedium,
}));

const GraphDotContainer = styled(Box, {
  shouldForwardProp: propName => propName !== 'justify',
})<BoxProps & { justify: string }>(({ theme, justify }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginTop: theme.spacing(1),
  justifyContent: justify ? justify : 'unset',
}));

const GraphDot = styled('div', {
  shouldForwardProp: prop => prop !== 'customColor',
})<{ customColor?: CSSProperties['color'] }>(({ theme, customColor }) => ({
  height: '8px',
  width: '8px',
  borderRadius: '50%',
  boxSizing: 'border-box',
  backgroundColor: customColor ? customColor : theme.palette.primary.main,
}));

const WebHyperInDepth = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { presentSuccess, presentError } = useAlert();
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const { measurement } = useAppSelector(state => state.hypertension);
  const { dateFilter, customDateFilter } = useAppSelector(state => state.layout);

  const [modalGoalOpen, setModalGoalOpen] = useState<boolean>(false);
  const [modalRemoveGoalOpen, setModalRemoveGoalOpen] = useState(false);

  const timeframe = useTimeframe(
    dateFilter,
    customDateFilter?.start,
    customDateFilter?.end,
  );

  const measurementCategory = useMemo(() => {
    return measurement;
  }, [measurement]);

  const [deleteGoal] = useDeletePressureGoalMutation();
  const [addPressureGoal] = useAddPressureGoalMutation();
  const [updatePressureGoal] = useUpdatePressureGoalMutation();

  const { error: errorPressureGraph, data: pressureGraphData } =
    useGetPressureGraphDataQuery({
      ...timeframe,
    });
  const { error: errorPulsesGraph, data: pulsesGraphData } = useGetPulsesGraphDataQuery({
    ...timeframe,
  });
  const { error: errorLatestLog, data: latestLogRes } = useGetLatestLogQuery();
  const { error: errorPressureGoal, data: pressureGoal } = useGetPressureGoalQuery();
  const { error: errorLatestHypLog, data: latestHypertensionLogs } =
    useGetLatestLogQuery();
  const { error: errorFirstLog, data: firstLogOfGoalRes } = useGetFirstLogOfGoalQuery(
    {
      start:
        pressureGoal && pressureGoal.length > 0 ? pressureGoal[0].startDate : undefined,
    },
    { skip: !pressureGoal || pressureGoal.length === 0 },
  );
  const { error: errorOverall, data: overallData } = useGetHypertensionOverallQuery({
    category: measurementCategory === 'all' ? undefined : measurementCategory,
    ...timeframe,
  });

  useEffect(() => {
    if (errorPulsesGraph || errorPressureGraph)
      presentError(t('errors.hypertension.errorGraphFetch'));
    if (errorFirstLog || errorLatestLog || errorLatestHypLog)
      presentError(t('errors.hypertension.errorLogFetch'));
    if (errorPressureGoal) presentError(t('errors.hypertension.errorGoalFetch'));
    if (errorOverall) presentError(t('errors.hypertension.errorOverallFetch'));
  }, [
    errorOverall,
    errorFirstLog,
    errorLatestLog,
    errorPulsesGraph,
    errorPressureGoal,
    errorLatestHypLog,
    errorPressureGraph,
  ]);

  const prepareGraphDataPulses = usePreparePulsesGraph(pulsesGraphData);
  const prepareGraphDataPressure = usePreparePressureGraph(pressureGraphData);

  const latestHypertensionData = useMemo(() => {
    if (latestHypertensionLogs && latestHypertensionLogs.documents.length > 0) {
      const data = latestHypertensionLogs.documents[0];
      return {
        systolic: data ? data.systolic : '-',
        diastolic: data ? data.diastolic : '-',
      };
    }

    return { systolic: '-', diastolic: '-' };
  }, [latestHypertensionLogs]);

  const systolicOverall = useMemo(() => {
    let systolicPressure = { value: '-', min: '-', max: '-', avg: '-' };

    if (overallData && overallData.systolic) {
      const systolic = overallData.systolic;
      systolicPressure = {
        ...systolicPressure,
        min: systolic.min ? systolic.min.toFixed().toString() : '-',
        max: systolic.max ? systolic.max.toFixed().toString() : '-',
        avg: systolic.avg ? systolic.avg.toFixed().toString() : '-',
      };
    }
    if (latestHypertensionLogs && latestHypertensionLogs.documents.length > 0) {
      const data = latestHypertensionLogs.documents[0];
      systolicPressure = {
        ...systolicPressure,
        value: data && data.systolic ? data.systolic.toFixed().toString() : '-',
      };
    }

    return systolicPressure;
  }, [overallData, latestHypertensionLogs]);

  const diastolicOverall = useMemo(() => {
    let diastolicPressure = { value: '-', min: '-', max: '-', avg: '-' };

    if (overallData && overallData.diastolic) {
      const diastolic = overallData.diastolic;
      diastolicPressure = {
        ...diastolicPressure,
        min: diastolic.min ? diastolic.min.toFixed().toString() : '-',
        max: diastolic.max ? diastolic.max.toFixed().toString() : '-',
        avg: diastolic.avg ? diastolic.avg.toFixed().toString() : '-',
      };
    }
    if (latestHypertensionLogs && latestHypertensionLogs.documents.length > 0) {
      const data = latestHypertensionLogs.documents[0];
      diastolicPressure = {
        ...diastolicPressure,
        value: data && data.diastolic ? data.diastolic.toFixed().toString() : '-',
      };
    }

    return diastolicPressure;
  }, [overallData, latestHypertensionLogs]);

  const pulsesOverall = useMemo(() => {
    let pulsesAll = { value: '-', min: '-', max: '-', avg: '-' };

    if (overallData && overallData.pulses) {
      const pulses = overallData.pulses;
      pulsesAll = {
        ...pulsesAll,
        min: pulses.min ? pulses.min.toFixed().toString() : '-',
        max: pulses.max ? pulses.max.toFixed().toString() : '-',
        avg: pulses.avg ? pulses.avg.toFixed().toString() : '-',
      };
    }
    if (latestHypertensionLogs && latestHypertensionLogs.documents.length > 0) {
      const data = latestHypertensionLogs.documents[0];
      pulsesAll = {
        ...pulsesAll,
        value: data && data.pulses ? data.pulses.toFixed().toString() : '-',
      };
    }

    return pulsesAll;
  }, [overallData, latestHypertensionLogs]);

  const overallDataCard = useMemo(() => {
    return [
      {
        name: t('hypertension.labels.systolic'),
        min: systolicOverall.min,
        max: systolicOverall.max,
        avg: systolicOverall.avg,
      },
      {
        name: t('hypertension.labels.diastolic'),
        min: diastolicOverall.min,
        max: diastolicOverall.max,
        avg: diastolicOverall.avg,
      },
      {
        name: t('dashboard.dashcards.hypertension.pulses'),
        min: pulsesOverall.min,
        max: pulsesOverall.max,
        avg: pulsesOverall.avg,
      },
    ];
  }, [systolicOverall, diastolicOverall, pulsesOverall]);

  const prepareGoalData = useMemo(() => {
    let goalSystolic, goalDiastolic, progress, start, latestMeasurement, startMeasurement;
    if (pressureGoal && pressureGoal.length > 0) {
      const data = pressureGoal[0];
      goalSystolic = data.goal.systolic;
      goalDiastolic = data.goal.diastolic;
      start = moment(data.startDate).format('DD MMM YYYY');
    }

    if (firstLogOfGoalRes && firstLogOfGoalRes.documents.length > 0) {
      const data = firstLogOfGoalRes.documents[0];
      startMeasurement = data.systolic + '/' + data.diastolic;
    }

    if (latestLogRes && latestLogRes.documents.length > 0) {
      const data = latestLogRes.documents[0];
      latestMeasurement = data.systolic + '/' + data.diastolic;
      if (start && !startMeasurement) {
        startMeasurement = latestMeasurement;
      }
    }

    if (latestHypertensionData && pressureGoal && firstLogOfGoalRes) {
      if (
        latestHypertensionData.systolic === '-' ||
        latestHypertensionData.diastolic === '-'
      ) {
        progress = 0;
      } else {
        const goal = pressureGoal[0].goal;
        const latest = latestHypertensionData as { systolic: number; diastolic: number };
        const starting = firstLogOfGoalRes?.documents[0]
          ? firstLogOfGoalRes.documents[0]
          : latest;
        progress = goalProgressHypertension(latest, goal, starting);
      }
    }

    return [
      { label: t('goals.measurement'), value: t('measurements.mmGH') },
      {
        label: t('hypertension.labels.goalSystolic'),
        value: goalSystolic ? goalSystolic : '-',
      },
      {
        label: t('hypertension.labels.goalDiastolic'),
        value: goalDiastolic ? goalDiastolic : '-',
      },
      { label: t('goals.goalStart'), value: start ? start : '-' },
      { label: t('goals.progress'), value: progress ? progress + '%' : '-' },
      {
        label: t('goals.startingMeasurement'),
        value: startMeasurement ? startMeasurement : '- ',
      },
      {
        label: t('goals.latestMeasurement'),
        value: latestMeasurement ? latestMeasurement : '- ',
      },
    ];
  }, [firstLogOfGoalRes, latestLogRes, pressureGoal]);

  const handleSaveClick = async (newGoals: any) => {
    if (pressureGoal && pressureGoal.length > 0) {
      try {
        await updatePressureGoal({
          goalType: 'pressure',
          goal: { ...newGoals },
          _id: pressureGoal[0]._id,
          startDate: moment().toISOString(),
        }).unwrap();
        presentSuccess(t('errors.hypertension.successEditGoal'));
        setModalGoalOpen(false);
      } catch (e) {
        presentError(t('errors.hypertension.errorEditGoal'));
        setModalGoalOpen(false);
      }
    } else {
      try {
        await addPressureGoal({
          goal: newGoals,
          startDate: moment().startOf('day').toISOString(),
        }).unwrap();
        presentSuccess(t('errors.hypertension.successAddGoal'));
        setModalGoalOpen(false);
      } catch (e) {
        presentError(t('errors.hypertension.errorAddGoal'));
        setModalGoalOpen(false);
      }
    }
  };

  const handleRemoveGoal = async () => {
    if (pressureGoal && pressureGoal.length > 0) {
      try {
        await deleteGoal({ _id: pressureGoal[0]._id }).unwrap();
        presentSuccess(t('errors.hypertension.successDeleteGoal'));
        setModalRemoveGoalOpen(false);
      } catch (e) {
        presentError(t('errors.hypertension.errorDeleteGoal'));
        setModalRemoveGoalOpen(false);
      }
    }
  };

  const handleEditPressureGoal = () => {
    setModalGoalOpen(true);
  };

  const handleRemovePressureGoal = () => {
    setModalRemoveGoalOpen(true);
  };

  return (
    <Box>
      <Box>
        <Box display={'flex'} flex={1} gap={theme.spacing(3)}>
          <Box display={'flex'} flexDirection={'column'} flex={1}>
            <Title
              variant={'subtitle1'}
              textTransform={lang === 'el' ? 'capitalize' : 'uppercase'}
            >
              {t('headingsTitles.sysDiaPressure')}
            </Title>
            <Box
              sx={{
                borderRadius: '8px',
                backgroundColor: theme.palette.secondary.light,
                padding: theme.spacing(2, 2, 1, 2),
              }}
            >
              <HypertensionGraphContainer
                gap={3}
                horizontal
                systolicPressure={systolicOverall}
                diastolicPressure={diastolicOverall}
                headers={[t('measurements.sys'), t('measurements.dia')]}
              />
              {prepareGraphDataPressure.length === 0 && <FeelsEmpty />}
              {prepareGraphDataPressure.length > 0 && (
                <LineChart
                  chartData={prepareGraphDataPressure}
                  colors={[theme.palette.medication.main, theme.palette.nutrition.main]}
                />
              )}
            </Box>
          </Box>
          <Box display={'flex'} flexDirection={'column'} flex={1}>
            <Title
              variant={'subtitle1'}
              textTransform={lang === 'el' ? 'capitalize' : 'uppercase'}
            >
              {t('dashboard.dashcards.hypertension.pulses')}
            </Title>
            <Box
              sx={{
                borderRadius: '8px',
                backgroundColor: theme.palette.secondary.light,
                padding: theme.spacing(2, 2, 1, 2),
              }}
            >
              <ChartNumbersInfo
                isHorizontal
                numberOne={pulsesOverall.max}
                numberTwo={pulsesOverall.min}
                numberThree={pulsesOverall.avg}
                header={`${t('measurements.pul')}`}
              />
              <Box
                display={'flex'}
                alignItems={'center'}
                gap={theme.spacing(1)}
                marginTop={theme.spacing(3)}
                marginBottom={theme.spacing(3)}
              >
                <Typography
                  variant={'h1'}
                  color={theme.palette.primary.main}
                  textTransform={lang === 'el' ? 'capitalize' : 'uppercase'}
                >
                  {t('hypertension.labels.latestPul')}
                </Typography>
                <Typography
                  variant={'subtitle1'}
                  color={theme.palette.primary.light}
                  fontWeight={theme.typography.fontWeightMedium}
                >
                  {pulsesOverall.value} {t('measurements.bpm')}
                </Typography>
              </Box>
              <GraphDotContainer justify={'unset'} marginTop={theme.spacing(3)}>
                <GraphDot customColor={theme.palette.lipid.main} />
                <Typography
                  variant={'body1'}
                  color={theme.palette.primary.main}
                  fontWeight={theme.typography.subtitle1.fontWeight}
                >
                  {t('dashboard.dashcards.hypertension.pulses')}
                </Typography>
              </GraphDotContainer>
              {prepareGraphDataPulses.length === 0 && <FeelsEmpty />}
              {prepareGraphDataPulses.length > 0 && (
                <LineChart chartData={prepareGraphDataPulses} />
              )}
            </Box>
          </Box>
        </Box>
        <Box display={'flex'} flex={1} mt={theme.spacing(3)} gap={theme.spacing(3)}>
          <Box display={'flex'} flex={1} flexDirection={'column'} gap={theme.spacing(1)}>
            <InDepthHeading module={'hypertension'} />
            <InDepthDataCard
              profApp
              rows={overallDataCard}
              measurement={`${t('measurements.mmGH')}`}
              headings={[
                `${t('measurements.min')}`,
                `${t('measurements.max')}`,
                `${t('measurements.avg')}`,
              ]}
            />
            <LogsList endParam={timeframe?.end} startParam={timeframe?.start} />
          </Box>
          <Box display={'flex'} flex={1} flexDirection={'column'}>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              marginBottom={theme.spacing(1)}
            >
              <Title variant={'subtitle1'}>{t('headingsTitles.goal')}</Title>
              <GoalActionButtons
                handleEdit={handleEditPressureGoal}
                handleRemove={handleRemovePressureGoal}
                handleAdd={() => setModalGoalOpen(true)}
                type={pressureGoal && pressureGoal.length > 0 ? 'edit' : 'add'}
              />
            </Box>
            <Box
              borderRadius={'8px'}
              padding={theme.spacing(1)}
              bgcolor={theme.palette.secondary.light}
            >
              {prepareGoalData.map(goal => (
                <GoalDetailsCard key={`${goal.label}_${goal.value}`} goal={goal} />
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
      <ModalMobile open={modalRemoveGoalOpen} setOpen={setModalRemoveGoalOpen}>
        <ModalRemoveGoal
          handleRemoveClick={handleRemoveGoal}
          handleCancelClick={() => {
            setModalRemoveGoalOpen(false);
          }}
        />
      </ModalMobile>
      <ModalMobile width={'350px'} open={modalGoalOpen} setOpen={setModalGoalOpen}>
        <ModalAddEditGoal
          goalType={'pressure'}
          goal={prepareGoalData}
          handleSaveClick={handleSaveClick}
          currentMeasurement={latestHypertensionData}
        />
      </ModalMobile>
    </Box>
  );
};

export default WebHyperInDepth;
