import { useEffect, useState } from 'react';
import { useIonRouter } from '@ionic/react';
import { theme } from '@hdcorner/ui-library';
import { Box, useMediaQuery } from '@mui/material';
import PageLayout from '../../components/PageLayout';
import SubsCheckoutWeb from './components/SubsCheckoutWeb';
import { useGetUserDataQuery } from '../profile/queries/personalInfoQueries';
import { useGetAuthUserQuery } from '../authentication/queries/signInQueries';
import useQuery from '../../hooks/useQuery';
import useAlert from '../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

const SubscriptionCheckout = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { presentError } = useAlert();

  const query = useQuery();
  const trial = query.get('trial');

  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const [userDetails, setUserDetails] = useState({
    lastName: '',
    firstName: '',
    premiumUser: false,
    profilePicture: '',
  });

  const { data: authUser, error: errorAuthUser } = useGetAuthUserQuery();
  const { data: userData, error: errorUserData } = useGetUserDataQuery();

  useEffect(() => {
    if (errorAuthUser || errorUserData) presentError(t('errors.profile.errorUserData'));
  }, [errorAuthUser, errorUserData]);

  useEffect(() => {
    if (authUser) {
      const user = authUser[0];

      const newAuthUser = {
        lastName: user.lastName,
        firstName: user.firstName,
        profilePicture: user.profilePicture ? user.profilePicture.url : '',
      };

      setUserDetails({ ...userDetails, ...newAuthUser });
    }
  }, [authUser]);

  useEffect(() => {
    if (userData && userData.length > 0) {
      const user = userData[0];

      setUserDetails(oldData => ({ ...oldData, premiumUser: user.premiumUser || false }));
    }
  }, [userData]);

  const handleCancelUpgrade = () => {
    router.push('/dashboard/subscription', 'back', 'pop');
  };

  return (
    <PageLayout headerTitle={'Checkout'} backgroundWhite>
      <Box hidden={mobile} height={'100%'}>
        <SubsCheckoutWeb
          trial={trial === 'true'}
          premium={userDetails.premiumUser}
          handleGoBack={handleCancelUpgrade}
          avatar={userDetails.profilePicture}
          user={`${userDetails.firstName} ${userDetails.lastName}`}
        />
      </Box>
    </PageLayout>
  );
};

export default SubscriptionCheckout;
