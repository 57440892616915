import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { Box } from '@mui/material';
import CalcOptInner from './CalcOptInner';
import { useIonRouter } from '@ionic/react';
import useAlert from '../../../hooks/useAlert';
import { useAppSelector } from '../../../redux/hooks';
import PageLayout from '../../../components/PageLayout';
import { calculateCalories } from '../utils/caloriesFormula';
import DietCalculatorFirstStep from './DietCalculatorFirstStep';
import { AuthUser, ComponentsLayout, CustomButton } from '@hdcorner/ui-library';
import { usePostCaloriesInDepthMutation } from '../calories/queries/caloriesQueries';
import {
  useAssignDietPlanMutation,
  useCreateDietPlanMutation,
  useEditDietPlanMutation,
  useGetDietPlanQuery,
} from './queries/dietPlanQueries';
import { useTranslation } from 'react-i18next';

const DietCalculatorMobile = () => {
  const router = useIonRouter();
  const { t } = useTranslation();

  const { presentSuccess, presentError } = useAlert();

  const {
    data: { user },
  } = useAppSelector(state => state.auth);

  const [userData, setUserData] = useState({
    age: 0,
    sex: '',
    weight: 0,
    height: 0,
  });
  const [step, setStep] = useState<number>(0);
  const [changePerWeek, setChangePerWeek] = useState<number>(750);
  const [activityLevel, setActivityLevel] =
    useState<'light' | 'moderate' | 'heavy'>('light');
  const [goal, setGoal] =
    useState<'lose_weight' | 'muscle_gain' | 'maintain_weight'>('maintain_weight');
  const [dietType, setDietType] =
    useState<'standard' | 'low_carb' | 'low_fat' | 'high_protein'>('standard');

  const { data: dietPlan, error } = useGetDietPlanQuery();

  const [assignDietPlan] = useAssignDietPlanMutation();
  const [editCalculatedCalories] = useEditDietPlanMutation();
  const [setCalculatedCalories] = useCreateDietPlanMutation();
  const [postCaloriesInDepth] = usePostCaloriesInDepthMutation();

  useEffect(() => {
    if (error) {
      presentError(t('errors.fitness.dietPlan.error01'));
    }
  }, [error]);

  useEffect(() => {
    if (user && user.authUser) {
      const authUser = user.authUser as AuthUser;
      const age = moment().diff(moment(authUser.birthDate), 'years');
      setUserData({
        age: age,
        height: user.height || 0,
        weight: user.weight || 0,
        sex: authUser.gender ? authUser.gender : 'male',
      });
    }
  }, [user]);

  const handleCalculate = () => {
    if (step === 0) {
      setStep(1);
      return;
    }
    const data = {
      goal,
      dietType,
      changePerWeek,
      activityLevel,
      sex: userData.sex,
      age: userData.age,
      weight: userData.weight,
      height: userData.height,
    };

    const calories = calculateCalories(data);

    if (dietPlan && dietPlan.length > 0) {
      editCalculatedCalories({
        calculatedCalories: calories,
        dietType,
      })
        .unwrap()
        .then(() => {
          presentSuccess(t('errors.fitness.dietPlan.successEdit'));
          assignDietPlan();
          postCaloriesInDepth()
            .unwrap()
            .finally(() => {
              setStep(0);
              router.push('/dashboard/fitness');
            });
        })
        .catch(() => {
          presentSuccess(t('errors.fitness.dietPlan.errorEdit'));
        });
    } else {
      setCalculatedCalories({
        calculatedCalories: calories,
        dietType,
      })
        .unwrap()
        .then(async () => {
          presentSuccess(t('errors.fitness.dietPlan.successCalculate'));
          await assignDietPlan()
            .unwrap()
            .catch(() => {
              presentError(t('errors.fitness.dietPlan.errorAdd'));
            });
          await postCaloriesInDepth()
            .unwrap()
            .catch(() => {
              presentError(t('errors.generic.error01'));
            });

          setStep(0);
          router.push('/dashboard/fitness');
        })
        .catch(() => {
          presentError(t('errors.fitness.dietPlan.errorCalculate'));
        });
    }
  };

  return (
    <PageLayout
      defaultHref={'/dashboard/fitness'}
      headerTitle={t('headingsTitles.personalDietCal')}
    >
      <ComponentsLayout>
        {step === 0 && (
          <Box mt={3}>
            <DietCalculatorFirstStep user={userData} setUser={setUserData} />
          </Box>
        )}
        {step === 1 && (
          <CalcOptInner
            goal={goal}
            setGoal={setGoal}
            dietType={dietType}
            setDietType={setDietType}
            changePerWeek={changePerWeek}
            activityLevel={activityLevel}
            setChangePerWeek={setChangePerWeek}
            setActivityLevel={setActivityLevel}
          />
        )}
        <Box mt={3}>
          <CustomButton
            fullWidth
            disableRipple
            disableElevation
            disableFocusRipple
            variant={'contained'}
            onClick={handleCalculate}
          >
            {t('buttons.calculate')}
          </CustomButton>
        </Box>
      </ComponentsLayout>
    </PageLayout>
  );
};

export default DietCalculatorMobile;
