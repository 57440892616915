import React from 'react';
import logo from './assets/HDCLogo.png';
import { AboutUsInner, theme } from '@hdcorner/ui-library';
import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageLayout from '../../components/PageLayout';

const AboutUs = () => {
  const { t } = useTranslation();
  const web = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <PageLayout
      backgroundWhite={!web}
      defaultHref={'/dashboard/profile'}
      headerTitle={t('aboutUs.pageTitle')}
    >
      <AboutUsInner
        logo={<img width={'244px'} height={'128px'} src={logo} alt={`HD Corner Logo`} />}
      />
    </PageLayout>
  );
};

export default AboutUs;
