import React, { useMemo } from 'react';
import { Box, styled, Typography, TypographyProps, useTheme } from '@mui/material';
import {
  CardWithVideo,
  ClickNavCardNutrition,
  ComponentsLayout,
  ConduitFile,
} from '@hdcorner/ui-library';
import { useIonRouter } from '@ionic/react';
import PageLayout from '../../../components/PageLayout';
import { useGetSuggestedVideoQuery } from '../nutrition/queries/videoQueries';
import { useTranslation } from 'react-i18next';

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  color: theme.palette.primary.main,
}));

const FitnessWorkouts = () => {
  const theme = useTheme();
  const router = useIonRouter();
  const { t } = useTranslation();
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const { data: suggestedVideosRes } = useGetSuggestedVideoQuery({
    skip: 0,
    limit: 1,
    version: lang,
    suggested: true,
  });

  const prepareSuggestedVideo = useMemo(() => {
    if (!suggestedVideosRes || suggestedVideosRes.suggestedVideos.length === 0)
      return undefined;
    return suggestedVideosRes.suggestedVideos[0];
  }, [suggestedVideosRes]);

  return (
    <PageLayout
      defaultHref={'/dashboard/fitness'}
      headerTitle={t('dashboard.dashcards.fitness.workouts')}
    >
      <ComponentsLayout>
        <Box display={'flex'} flexDirection={'column'}>
          <Box display={'flex'} flexDirection={'column'} flex={1} mt={theme.spacing(2)}>
            <Box>
              <ClickNavCardNutrition
                body={`${t('buttons.selectAv')}`}
                headings={t('fitness.workouts.labels.avatar')}
                onClick={() => router.push('/dashboard/fitness/workouts/avatar')}
              />
              <ClickNavCardNutrition
                body={`${t('buttons.startWorkout')}`}
                headings={t('dashboard.dashcards.fitness.workouts')}
                onClick={() => router.push('/dashboard/fitness/workouts/workout-type')}
              />
            </Box>
            {prepareSuggestedVideo && (
              <Box
                flex={1}
                display={'flex'}
                flexDirection={'column'}
                mt={theme.spacing(3)}
              >
                <Title variant={'subtitle2'}>
                  {t('fitness.workouts.text.suggestedVid')}
                </Title>
                <Box display="flex" flex={1} mt={1}>
                  <CardWithVideo
                    sx={{
                      height: 200,
                      backgroundSize: 'cover',
                      backgroundImage: `url(${
                        (prepareSuggestedVideo.thumbnail as ConduitFile).url
                      })`,
                    }}
                    url={(prepareSuggestedVideo.attachedVideo as ConduitFile).url}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </ComponentsLayout>
    </PageLayout>
  );
};

export default FitnessWorkouts;
