import React, { FC } from 'react';
import { Box, BoxProps, styled, Typography, useTheme } from '@mui/material';

const CustomBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  borderRadius: '8px',
  alignItems: 'flex-end',
  boxSizing: 'border-box',
  justifyContent: 'space-between',
  margin: theme.spacing(0),
  marginBottom: theme.spacing(1),
  backgroundColor: theme.palette.secondary.light,
  padding: theme.spacing(1, 2),
}));

const CustomInnerBox = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

type InfoCardMedicationProps = {
  heading: string;
  amount: number;
  measurement: string;
};
const InfoCardMedication: FC<InfoCardMedicationProps> = ({
  heading,
  amount,
  measurement,
}) => {
  const theme = useTheme();

  return (
    <CustomBox>
      <Box>
        <Typography
          variant={'subtitle2'}
          sx={{
            fontWeight: theme.typography.fontWeightRegular,
            color: theme.palette.primary.main,
          }}
        >
          {heading}
        </Typography>
      </Box>
      <CustomInnerBox>
        <Typography variant={'body1'} sx={{ color: theme.palette.kmColorsCoolGrey.main }}>
          {amount} {measurement}
        </Typography>
      </CustomInnerBox>
    </CustomBox>
  );
};

export default InfoCardMedication;
