import { useMemo } from 'react';
import moment from 'moment';
import { DiabetesGoal } from '@hdcorner/ui-library';
import { useTranslation } from 'react-i18next';
import { goalProgress } from '../../../utils/goalProgress';

const useBloodGlucoseGoalData = (
  prepareGoalValue: number | null,
  bloodGlucoseGoal: DiabetesGoal[] | undefined,
  prepareStartingMeasurement: number | undefined,
  prepareCurrentMeasurement: number | undefined,
) => {
  const { t } = useTranslation();

  return useMemo(() => {
    const goalValue = bloodGlucoseGoal?.[0];
    let firstValue = prepareStartingMeasurement;
    const currentValue = prepareCurrentMeasurement;

    let progress = 0;
    if (goalValue && firstValue && currentValue) {
      progress = goalProgress(currentValue, goalValue.goal, firstValue);
    }

    if (goalValue && !firstValue) {
      firstValue = currentValue;
    }

    return [
      {
        label: t('headingsTitles.goal'),
        value: prepareGoalValue ? `${prepareGoalValue} ${t('measurements.mgdl')}` : '-',
      },
      {
        label: t('goals.startingDate'),
        value: goalValue ? moment(goalValue.startDate).format('MMM Do, YYYY') : '-',
      },
      {
        label: t('goals.startingMeasurement'),
        value: firstValue ? `${firstValue} ${t('measurements.mgdl')}` : '-',
      },
      {
        label: t('goals.latestMeasurement'),
        value: currentValue ? `${currentValue} ${t('measurements.mgdl')}` : '-',
      },
      {
        label: t('goals.progress'),
        value: progress ? `${progress} %` : '-',
      },
    ];
  }, [
    t,
    bloodGlucoseGoal,
    prepareGoalValue,
    prepareCurrentMeasurement,
    prepareStartingMeasurement,
  ]);
};

export default useBloodGlucoseGoalData;
