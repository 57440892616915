import React, { FC, useState } from 'react';
import { Box, BoxProps, styled, Typography } from '@mui/material';
import EmojisSelect, { IconButtonsType } from './EmojisSelect';
import { TypographyProps } from '@mui/material/Typography/Typography';
import CustomButton from './controls/CustomButton';
import { useTranslation } from 'react-i18next';

const Container = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.secondary.light,
  padding: theme.spacing(4, 2),
}));

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const Subtitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.kmColorsDarkGrey.main,
  fontWeight: theme.typography.fontWeightRegular,
}));

type OverlaysType = {
  username: string;
  buttonTitle?: string;
  onSubmit: (value: string) => void;
};
const OverlayWithEmojis: FC<OverlaysType> = ({ username, buttonTitle, onSubmit }) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState<string>('');

  const handleClickIcon = (item: IconButtonsType) => {
    setSelected(item.value);
  };

  const handleName = () => {
    if (username) {
      return `, ${username}!`;
    }
    return '!';
  };

  const handleSubmit = () => {
    onSubmit(selected);
  };

  return (
    <Container>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <Title variant={'h1'}>
          {t('modals.emojis.hi')}
          {handleName()}
        </Title>
        <Subtitle variant={'subtitle2'}>{t('modals.emojis.howRU')}</Subtitle>
      </Box>
      <Box display={'flex'} flex={1} marginY={3}>
        <EmojisSelect selected={selected} handleClickIcon={handleClickIcon} />
      </Box>
      <CustomButton onClick={handleSubmit} disabled={!selected} variant={'contained'}>
        {buttonTitle ?? t('buttons.submit')}
      </CustomButton>
    </Container>
  );
};

export default OverlayWithEmojis;
