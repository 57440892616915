import { useMemo } from 'react';
import { BloodGlucoseLog, Hbac1Log, PaginationResult } from '@hdcorner/ui-library';

const useCurrentMeasurement = (
  currentMeasurements?: PaginationResult<BloodGlucoseLog | Hbac1Log>,
) => {
  return useMemo(() => {
    if (!currentMeasurements || currentMeasurements.documents.length === 0) {
      return undefined;
    }
    return currentMeasurements.documents[0].measurement;
  }, [currentMeasurements]);
};

export default useCurrentMeasurement;
