import React from 'react';
import { Box, styled, Typography, TypographyProps, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

const TypographyCaption = styled(Typography)<TypographyProps>(({ theme }) => ({
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.background.default
      : theme.palette.secondary.light,
  fontWeight: theme.typography.fontWeightRegular,
  textTransform: lang === 'el' ? 'capitalize' : 'uppercase',
}));

const NewMessageDivider = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      height={'31px'}
      display={'flex'}
      justifyContent={'space-between'}
      marginBottom={theme.spacing(1.25)}
    >
      <Box
        flexGrow={'1'}
        height={'1px'}
        boxSizing={'border-box'}
        bgcolor={
          theme.palette.mode === 'dark'
            ? theme.palette.kmColorsTurquoise.main
            : theme.palette.error.main
        }
        margin={theme.spacing(2, 1)}
      ></Box>
      <Box
        width={'30px'}
        height={'15px'}
        display={'flex'}
        borderRadius={'4px'}
        alignItems={'center'}
        boxSizing={'border-box'}
        justifyContent={'center'}
        bgcolor={
          theme.palette.mode === 'dark'
            ? theme.palette.kmColorsTurquoise.main
            : theme.palette.error.main
        }
        padding={theme.spacing(0.25, 0.75)}
        margin={theme.spacing(1, 1, 1, 0)}
      >
        <TypographyCaption variant={'caption'}>
          {t('general.labels.new')}
        </TypographyCaption>
      </Box>
    </Box>
  );
};

export default NewMessageDivider;
