import { Storage } from '@ionic/storage';
import { StoredWatchedWorkout } from '../types/WatchedExercises';

let storage: Storage;

const getStore = async () => {
  if (!storage) {
    storage = new Storage();
    await storage.create();
  }
  return storage;
};

const getWatchedWorkouts = async (): Promise<StoredWatchedWorkout> => {
  const store = await getStore();
  const existingStoredWorkoutsStr = await store.get('watchedWorkouts');

  return existingStoredWorkoutsStr ? JSON.parse(existingStoredWorkoutsStr) : undefined;
};

const getWatchedExercises = async (workoutId: string): Promise<string[]> => {
  const watchedWorkouts = await getWatchedWorkouts();

  if (!watchedWorkouts) {
    return [];
  }

  const watchedExercises = watchedWorkouts[workoutId];

  if (!watchedExercises) {
    return [];
  }

  return watchedExercises.map(watchedExercise => watchedExercise.exerciseId);
};

const setWatchedExercises = async (
  workoutId: string,
  exerciseIds: string[],
): Promise<void> => {
  const store = await getStore();

  const existingStoredWorkoutsStr = await store.get('watchedWorkouts');
  const existingStoredWorkouts: StoredWatchedWorkout = existingStoredWorkoutsStr
    ? JSON.parse(existingStoredWorkoutsStr)
    : undefined;

  if (!existingStoredWorkouts) {
    const newStoredWorkouts = {
      [workoutId]: exerciseIds.map(exerciseId => ({ exerciseId })),
    };
    return await store.set('watchedWorkouts', JSON.stringify(newStoredWorkouts));
  }

  const watchedExercises = exerciseIds.map(exerciseId => ({ exerciseId }));
  const newStoredWorkouts = {
    ...existingStoredWorkouts,
    [workoutId]: watchedExercises,
  };

  return await store.set('watchedWorkouts', JSON.stringify(newStoredWorkouts));
};

const clearWatchedExercises = async () => {
  const store = await getStore();
  return await store.remove('watchedWorkouts');
};

export { getWatchedExercises, setWatchedExercises, clearWatchedExercises };
