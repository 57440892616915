import * as React from 'react';
import { SVGProps } from 'react';

const NutritionBowl = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.666 4.1665H13.938C13.7073 2.8885 12.7893 2.1665 11.3327 2.1665C10.8033 2.1665 10.116 1.5625 9.56335 1.07784C8.98468 0.566504 8.52668 0.166504 7.99935 0.166504C7.27002 0.166504 6.03735 1.21117 5.06068 2.1665C4.96735 2.20184 2.67068 1.82117 2.09268 4.1665H1.33268C1.15587 4.1665 0.986302 4.23674 0.861278 4.36177C0.736253 4.48679 0.666016 4.65636 0.666016 4.83317V7.49984C0.666016 9.44476 1.43863 11.31 2.8139 12.6853C4.18917 14.0606 6.05443 14.8332 7.99935 14.8332C9.94427 14.8332 11.8095 14.0606 13.1848 12.6853C14.5601 11.31 15.3327 9.44476 15.3327 7.49984V4.83317C15.3327 4.65636 15.2624 4.48679 15.1374 4.36177C15.0124 4.23674 14.8428 4.1665 14.666 4.1665ZM4.66602 3.49984H7.33268C7.50949 3.49984 7.67906 3.4296 7.80409 3.30457C7.92911 3.17955 7.99935 3.00998 7.99935 2.83317C7.99935 2.65636 7.92911 2.48679 7.80409 2.36177C7.67906 2.23674 7.50949 2.1665 7.33268 2.1665H7.05268C7.33291 1.91316 7.64112 1.69263 7.97135 1.50917C8.22342 1.68 8.4615 1.8706 8.68335 2.07917C9.40402 2.71184 10.3007 3.49984 11.3327 3.49984C12.0247 3.49984 12.408 3.69984 12.5707 4.1665H3.48868C3.59302 3.94818 3.76189 3.76716 3.97246 3.64793C4.18302 3.5287 4.42512 3.477 4.66602 3.49984V3.49984ZM1.99935 5.49984H13.9993V6.83317H1.99935V5.49984ZM7.99935 13.4998C6.52432 13.4977 5.10163 12.9529 4.00238 11.9694C2.90314 10.9858 2.20417 9.63222 2.03868 8.1665H13.96C13.7945 9.63222 13.0956 10.9858 11.9963 11.9694C10.8971 12.9529 9.47438 13.4977 7.99935 13.4998Z"
        fill={props?.color ?? '#222222'}
      />
    </svg>
  );
};

export default NutritionBowl;
