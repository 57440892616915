import * as React from 'react';
import { SVGProps } from 'react';

const OkEmojiOutlined = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={30}
    height={30}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 14C11.6569 14 13 12.6569 13 11C13 9.34315 11.6569 8 10 8C8.34315 8 7 9.34315 7 11C7 12.6569 8.34315 14 10 14Z"
      fill="#212121"
    />
    <path
      d="M20 14C21.6569 14 23 12.6569 23 11C23 9.34315 21.6569 8 20 8C18.3431 8 17 9.34315 17 11C17 12.6569 18.3431 14 20 14Z"
      fill="#212121"
    />
    <path
      d="M10 18C9.44771 18 9 18.4477 9 19C9 19.5523 9.44771 20 10 20H20C20.5523 20 21 19.5523 21 19C21 18.4477 20.5523 18 20 18H10Z"
      fill="#212121"
    />
    <path
      d="M3.76342 4.42276C6.313 1.65419 10.0952 0 14.9989 0C19.9026 0 23.6848 1.65419 26.2343 4.42276C28.7715 7.17785 29.9978 10.9378 29.9978 14.9989C29.9978 19.06 28.7715 22.8199 26.2343 25.575C23.6848 28.3436 19.9026 29.9978 14.9989 29.9978C10.0952 29.9978 6.313 28.3436 3.76342 25.575C1.22626 22.8199 0 19.06 0 14.9989C0 10.9378 1.22626 7.17785 3.76342 4.42276ZM5.23462 5.77759C3.10595 8.08911 2 11.3286 2 14.9989C2 18.6691 3.10595 21.9087 5.23462 24.2202C7.35088 26.5182 10.5681 27.9978 14.9989 27.9978C19.4296 27.9978 22.6469 26.5182 24.7631 24.2202C26.8918 21.9087 27.9978 18.6691 27.9978 14.9989C27.9978 11.3286 26.8918 8.08911 24.7631 5.77759C22.6469 3.47956 19.4296 2 14.9989 2C10.5681 2 7.35088 3.47956 5.23462 5.77759Z"
      fill="#212121"
    />
  </svg>
);

export default OkEmojiOutlined;
