import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = ({ fill = '#E84044', ...rest }: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={17}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <rect y={0.5} width={16} height={16} rx={2} fill={fill} />
    <path
      d="M12.8484 3.83304H4.77825C4.43478 3.83085 4.10135 3.94884 3.83571 4.16659C3.57006 4.38433 3.38892 4.68811 3.32364 5.02533L2.69089 8.41942C2.65222 8.62712 2.65985 8.8408 2.71324 9.04521C2.76662 9.24963 2.86445 9.43975 2.99975 9.60202C3.13968 9.76986 3.31491 9.90477 3.51295 9.99713C3.711 10.0895 3.92698 10.137 4.1455 10.1363H6.45881V12.0758C6.45881 13.0751 7.48334 13.5304 8.43514 13.5304C8.56374 13.5304 8.68706 13.4793 8.77799 13.3884C8.86892 13.2975 8.92001 13.1742 8.92001 13.0456C8.92001 12.1209 9.08486 11.1497 9.27105 10.9684L10.6214 9.65147H12.8484C12.977 9.65147 13.1003 9.60039 13.1913 9.50946C13.2822 9.41853 13.3333 9.2952 13.3333 9.1666V4.31791C13.3333 4.18931 13.2822 4.06598 13.1913 3.97505C13.1003 3.88412 12.977 3.83304 12.8484 3.83304ZM9.93921 8.96247L8.59418 10.2716C8.14325 10.7114 8.00651 11.7805 7.96385 12.4943C7.70105 12.4317 7.42613 12.31 7.42613 12.0734V9.64905C7.42613 9.52045 7.37504 9.39713 7.28411 9.30619C7.19318 9.21526 7.06985 9.16418 6.94126 9.16418H4.14307C4.06703 9.16472 3.9918 9.14849 3.92275 9.11664C3.85369 9.08479 3.7925 9.03812 3.74354 8.97993C3.69867 8.92699 3.66623 8.86466 3.64861 8.79754C3.63099 8.73041 3.62863 8.66019 3.64172 8.59203L4.27205 5.19795C4.29702 5.08334 4.3613 4.98108 4.45376 4.90891C4.54621 4.83673 4.66101 4.79918 4.77825 4.80278H9.93921V8.96247ZM12.3636 8.68173H10.9089V4.80278H12.3636V8.68173Z"
      fill="#F2F1F3"
    />
  </svg>
);

export default SvgComponent;
