import * as React from 'react';
import { SVGProps } from 'react';

const ThumbsDown = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.7727 4.47591e-05H3.66744C3.15223 -0.00323437 2.65209 0.173755 2.25362 0.500368C1.85515 0.82698 1.58344 1.28265 1.48553 1.78849L0.536395 6.87962C0.478389 7.19117 0.489834 7.51169 0.569916 7.81831C0.649997 8.12493 0.796742 8.41011 0.999687 8.65351C1.20958 8.90528 1.47242 9.10765 1.76949 9.24619C2.06656 9.38473 2.39053 9.45603 2.71831 9.455H6.18828V12.3642C6.18828 13.8632 7.72507 14.5461 9.15277 14.5461C9.34566 14.5461 9.53066 14.4695 9.66705 14.3331C9.80345 14.1967 9.88007 14.0117 9.88007 13.8188C9.88007 12.4319 10.1274 10.9751 10.4066 10.7031L12.4322 8.7277H15.7727C15.9656 8.7277 16.1506 8.65107 16.287 8.51468C16.4234 8.37828 16.5 8.19329 16.5 8.00039V0.727349C16.5 0.534456 16.4234 0.349463 16.287 0.213067C16.1506 0.0766707 15.9656 4.47591e-05 15.7727 4.47591e-05ZM11.4089 7.6942L9.39133 9.65792C8.71493 10.3176 8.50983 11.9213 8.44583 12.9919C8.05163 12.8981 7.63925 12.7155 7.63925 12.3606V8.72406C7.63925 8.53117 7.56262 8.34618 7.42623 8.20978C7.28983 8.07338 7.10484 7.99676 6.91195 7.99676H2.71467C2.60061 7.99757 2.48776 7.97322 2.38418 7.92545C2.28059 7.87768 2.18881 7.80766 2.11537 7.72038C2.04807 7.64097 1.99941 7.54748 1.97298 7.44679C1.94655 7.3461 1.94301 7.24077 1.96264 7.13854L2.90813 2.04741C2.94558 1.8755 3.04201 1.72211 3.1807 1.61385C3.31938 1.50558 3.49158 1.44926 3.66744 1.45465H11.4089V7.6942ZM15.0454 7.27309H12.8635V1.45465H15.0454V7.27309Z"
        fill="white"
      />
    </svg>
  );
};

export default ThumbsDown;
