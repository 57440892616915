import React, { FC, useState } from 'react';
import { IonDatetime } from '@ionic/react';
import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  CustomButton,
  CustomInput,
  FrequencyItemType,
  theme,
} from '@hdcorner/ui-library';
import { useTranslation } from 'react-i18next';

const CustomBox = styled(Box)<BoxProps>(({ theme }) => ({
  bottom: '0',
  left: '50%',
  width: '100%',
  position: 'absolute',
  boxSizing: 'border-box',
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
  transform: 'translate(-50%, 0)',
  backgroundColor: theme.palette.secondary.light,
  padding: theme.spacing(6.25, 1.5, 1.5, 1.5),
}));

const styles = {
  left: '50%',
  width: '100%',
  fontSize: '23px',
  position: 'relative',
  transform: 'translate(-50%, 0)',
  color: theme.palette.kmColorsCoolGrey.main,
  fontWeight: theme.typography.subtitle1.fontWeight,
};

type TimePickerFieldsProps = {
  time: string;
  label: string;
  amount: number;
  handleSaveData: (newData: FrequencyItemType) => void;
};

const TimePickerFields: FC<TimePickerFieldsProps & BoxProps> = ({
  time,
  label,
  amount,
  handleSaveData,
  ...containerProps
}) => {
  const { t } = useTranslation();

  const [timeValue, setTimeValue] = useState<string>(time);
  const [amountValue, setAmountValue] = useState<string>(amount.toString());

  const onIonChange = (e: any) => {
    setTimeValue(e.detail.value);
  };

  const onSave = () => {
    handleSaveData({ time: timeValue, amount: parseInt(amountValue) });
  };

  return (
    <CustomBox {...containerProps}>
      <CustomInput
        fullWidth
        label={label}
        value={amountValue}
        sx={{ marginBottom: theme.spacing(3) }}
        handleChange={value => setAmountValue(value)}
        placeholder={`${t('medication.labels.pillsAmount')}`}
      />

      <IonDatetime
        style={styles}
        value={timeValue}
        hourCycle={'h23'}
        preferWheel={true}
        presentation="time"
        onIonChange={onIonChange}
      ></IonDatetime>

      <Box padding={theme.spacing(1.5)} boxSizing={'border-box'}>
        <CustomButton
          color={'primary'}
          variant={'contained'}
          children={'Save'}
          onClick={onSave}
          fullWidth
          sx={{
            height: '48px',
          }}
        />
      </Box>
    </CustomBox>
  );
};

export default TimePickerFields;
