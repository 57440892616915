import React, { SVGProps } from 'react';
import { useTheme } from '@mui/material';

const CardiovascularIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();

  return (
    <svg
      width="22"
      height="21"
      fill="none"
      viewBox="0 0 22 21"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 1.00021C13.5177 0.959086 12.0795 1.50668 11 2.52321C9.92045 1.50668 8.48224 0.959086 7 1.00021C6.16045 1.00257 5.33174 1.1899 4.57281 1.54888C3.81387 1.90787 3.14337 2.42968 2.609 3.07721C0.775997 5.27621 0.642997 8.80021 1.518 11.3582C1.526 11.3762 1.532 11.3942 1.54 11.4122C3.147 16.0002 9.963 21.0002 11 21.0002C12.019 21.0002 18.815 16.1102 20.46 11.4122C20.468 11.3942 20.474 11.3762 20.482 11.3582C21.345 8.84121 21.252 5.30921 19.391 3.07721C18.8566 2.42968 18.1861 1.90787 17.4272 1.54888C16.6683 1.1899 15.8395 1.00257 15 1.00021ZM11 18.8182C9.609 17.9142 5.739 15.2002 3.982 12.0002H8C8.16459 12.0002 8.32664 11.9596 8.47178 11.882C8.61692 11.8044 8.74065 11.6921 8.832 11.5552L9.698 10.2552L11.052 14.3152C11.1112 14.4927 11.219 14.6499 11.3632 14.7692C11.5074 14.8884 11.6821 14.9648 11.8675 14.9897C12.0529 15.0146 12.2416 14.987 12.4121 14.91C12.5826 14.833 12.7281 14.7097 12.832 14.5542L14.535 12.0002H18.019C16.261 15.2002 12.391 17.9142 11 18.8182ZM18.785 10.0002H14C13.8354 10.0002 13.6734 10.0408 13.5282 10.1184C13.3831 10.196 13.2593 10.3083 13.168 10.4452L12.302 11.7452L10.948 7.68521C10.8888 7.50775 10.7809 7.35048 10.6368 7.23124C10.4926 7.11201 10.3179 7.0356 10.1325 7.01071C9.94711 6.98581 9.75843 7.01343 9.58793 7.09041C9.41743 7.1674 9.27194 7.29066 9.168 7.44621L7.465 10.0002H3.215C2.815 8.10921 2.903 5.84721 4.146 4.35721C4.49059 3.93271 4.92567 3.59056 5.41946 3.35578C5.91324 3.121 6.45324 2.99952 7 3.00021C10.067 3.00021 9.747 5.10021 10.989 5.11221H11C12.244 5.11221 11.929 3.00021 15 3.00021C15.5471 2.99922 16.0875 3.12056 16.5816 3.35535C17.0758 3.59014 17.5112 3.93244 17.856 4.35721C19.092 5.84221 19.188 8.10021 18.785 10.0002Z"
        fill={
          props.fill
            ? props.fill
            : theme.palette.mode === 'dark'
            ? theme.palette.primary.light
            : theme.palette.secondary.light
        }
      />
    </svg>
  );
};

export default CardiovascularIcon;
