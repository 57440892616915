import { Box, BoxProps, styled, Typography, TypographyProps } from '@mui/material';
import SectionNutrition from './components/SectionNutrition';
import FoodEquivalentsInner from '../components/FoodEquivalentsInner';
import SectionNutritionCal from './components/SectionNutritionCal';
import { theme } from '@hdcorner/ui-library';
import TrafficLightTabsList from './components/TrafficLightTabsList';
import SectionArticles from '../components/SectionArticles';
import ArticleDetailsInner from './components/ArticleDetailsInner';
import ArticlesInner from './components/ArticlesInner';
import { useState } from 'react';
import SectionSuggestedArticle from './components/SectionSuggestedArticle';
import { useTranslation } from 'react-i18next';

const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

const BoxMain = styled(Box)<BoxProps>(({ theme }) => ({
  flex: 1,
  height: '100%',
  overflow: 'scroll',
  scrollbarWidth: 'none',
  flexDirection: 'column',
  msOverflowStyle: 'none',
  gap: theme.spacing(3),
  '::-webkit-scrollbar': {
    display: 'none',
  },
}));

const SubText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.light,
  fontSize: theme.typography.body1.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  textTransform: lang === 'el' ? 'capitalize' : 'uppercase',
}));

const WebNutrition = () => {
  const { t } = useTranslation();
  const [panel, setPanel] = useState<string>('main');

  return (
    <>
      <BoxMain display={panel === 'main' ? 'flex' : 'none'}>
        <Box flex={1} gap={3} display={'flex'} flexWrap={'wrap'}>
          <Box flex={'1 0 47%'}>
            <Box gap={3} display={'flex'} flexDirection={'column'}>
              <SectionNutrition />
              <SectionSuggestedArticle handleGoToArticle={() => setPanel('article')} />
            </Box>
          </Box>
          <Box flex={'1 0 47%'}>
            <SectionNutritionCal />
          </Box>
        </Box>
        <Box gap={1} display={'flex'} flex={1} flexDirection={'column'}>
          <Typography
            variant={'subtitle1'}
            sx={{ fontWeight: theme.typography.fontWeightMedium }}
            textTransform={lang === 'el' ? 'capitalize' : 'uppercase'}
          >
            {t('headingsTitles.tipsTricks')}
          </Typography>
          <SubText>{t('headingsTitles.trafficLights')}</SubText>
          <Box display={'flex'} gap={3}>
            <TrafficLightTabsList />
          </Box>
        </Box>
        <Box gap={3} flex={1} display={'flex'}>
          <Box maxWidth={'50%'} minWidth={'45%'}>
            <FoodEquivalentsInner />
          </Box>
          <Box maxWidth={'50%'} minWidth={'45%'}>
            <SectionArticles handleGoToArticles={() => setPanel('articles')} />
          </Box>
        </Box>
      </BoxMain>

      {/* SCREEN : Articles */}
      <BoxMain display={panel === 'articles' ? 'flex' : 'none'}>
        <ArticlesInner
          handleBack={() => setPanel('main')}
          handleGoToArticle={() => setPanel('article')}
        />
      </BoxMain>

      {/* SCREEN : Article details */}
      <BoxMain display={panel === 'article' ? 'flex' : 'none'}>
        <ArticleDetailsInner handleGoBack={() => setPanel('articles')} />
      </BoxMain>
    </>
  );
};

export default WebNutrition;
