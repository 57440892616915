import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import {
  AddHPCodeModal,
  AuthUser,
  ClinicalCoachesFound,
  ComponentsLayout,
  CustomButton,
  FeelsEmpty,
  HCPUser,
  HealthProfessionalCard,
  HealthProfessionalsData,
  ModalMobile,
  RemoveHPCodeModal,
} from '@hdcorner/ui-library';
import {
  useGetConnectedHealthProfessionalsQuery,
  useHasClinicalCoachQuery,
  useLazyFindHcpByCodeQuery,
  usePostHCPConnectionCodeMutation,
  useRemoveHcpUserConnectionMutation,
  useSetSalesGivenMutation,
} from './queries/healthProfessionalsQueries';
import useAlert from '../../hooks/useAlert';
import ModalFindCC from './components/ModalFindCC';
import PageLayout from '../../components/PageLayout';
import ModalCCPrompt from './components/ModalCCPrompt';
import ModalCCDetails from './components/ModalCCDetails';
import ModalCCDetected from './components/ModalCCDetected';
import ModalSearchForCC from './components/ModalSearchForCC';
import PersonAddIcon from '../../assets/icons/PersonAddIcon';
import HealthProfessionalsTable from './HealthProfessionalsTable';
import ModalCCUnlockPotential from './components/ModalCCUnlockPotential';
import {
  getModalCCReminder,
  removeModalCCReminder,
} from './utils/clinicalCoachPromptStorage';
import { useTranslation } from 'react-i18next';
import { useGetUserSettingsQuery } from '../profile/queries/userSettingsQueries';
import { findSpecialityLabel } from './utils/findSpecialityLabel';
import { useAppSelector } from '../../redux/hooks';

const HealthProfessionals = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { presentError, presentSuccess } = useAlert();
  const web = useMediaQuery(theme.breakpoints.up('md'));

  const {
    data: { user },
  } = useAppSelector(state => state.auth);

  const hcpDataId = useRef<string>('');
  const hcpCodeRef = useRef<string>('');

  const [findCcOpen, setFindCcOpen] = useState<boolean>(false);
  const [searchCcOpen, setSearchCcOpen] = useState<boolean>(false);
  const [ccPromptOpen, setCcPromptOpen] = useState<boolean>(false);
  const [ccUnlockOpen, setCcUnlockOpen] = useState<boolean>(false);
  const [ccDetectedOpen, setCcDetectedOpen] = useState<boolean>(false);
  const [addHpModalOpen, setAddHpModalOpen] = useState<boolean>(false);
  const [removeHpModalOpen, setRemoveHpModalOpen] = useState<string>('');
  const [selectedCc, setSelectedCc] = useState<ClinicalCoachesFound | null>(null);
  const [selectedHealthProfessional, setSelectedHealthProfessional] =
    useState<string>('');

  const { data: userSettings } = useGetUserSettingsQuery();
  const { data: hasCcRes, error: errorHasCC } = useHasClinicalCoachQuery();
  const { data: connectedHPCsRes, error: errorCCRes } =
    useGetConnectedHealthProfessionalsQuery({
      skip: 0,
      limit: 100,
    });

  const [setSalesGiven] = useSetSalesGivenMutation();
  const [findHcpByCode] = useLazyFindHcpByCodeQuery();
  const [addHCP] = usePostHCPConnectionCodeMutation();
  const [removeHcp] = useRemoveHcpUserConnectionMutation();

  useEffect(() => {
    if (errorCCRes) presentError(t('errors.healthProf.errorHcpsFetch'));
    if (errorHasCC) presentError(t('errors.healthProf.clinicalCoach.errorCCFetch'));
  }, [errorHasCC, errorCCRes]);

  /** Check if CC notifications are on/off */
  const settings = useMemo(() => {
    if (!userSettings || userSettings.length === 0) return false;
    return userSettings[0].clinicalCoachNotifications;
  }, [userSettings]);

  /** Check time elapsed since user hit snooze on setting a CC */
  useEffect(() => {
    if (!hasCcRes || !connectedHPCsRes || !settings) return;
    if (hasCcRes.length > 0 || connectedHPCsRes.count > 1) {
      return;
    }
    if (hasCcRes.length === 0) {
      getModalCCReminder().then(reminder => {
        if (!reminder) return;
        const firstTimeStamp = reminder;
        const currentTimeStamp = new Date().getTime();
        const twentyDays = 20 * 24 * 60 * 60 * 1000;
        if (currentTimeStamp - firstTimeStamp >= twentyDays) {
          setCcUnlockOpen(true);
          removeModalCCReminder();
        }
      });
    }
  }, [hasCcRes, connectedHPCsRes]);

  const createHcpAuthUserDetails = (data: AuthUser) => ({
    email: data.email || t('general.labels.nA') || '',
    gender: data.gender || t('general.labels.nA') || '',
    fullName: `${data.firstName} ${data.lastName}`,
    profilePicture: data.profilePicture?.url || '',
  });

  const createHcpUserData = (data: HealthProfessionalsData) => ({
    phone: data ? data.phone : t('general.labels.nA') || '',
    speciality: data
      ? t(findSpecialityLabel(data.speciality))
      : (t('general.labels.nA') as string),
  });

  const hasClinicalCoach = useMemo(() => {
    if (!hasCcRes) return false;
    return hasCcRes.length > 0;
  }, [hasCcRes]);

  const hcpTable = useMemo(() => {
    if (!connectedHPCsRes || connectedHPCsRes.documents.length === 0) return [];

    return connectedHPCsRes.documents.map((data: HCPUser) => ({
      _id: data._id,
      hcpId: data.healthProfessional._id,
      clinicalCoach: data.isClinicalCoach || false,
      ...createHcpUserData(data.healthProfessionalData),
      ...createHcpAuthUserDetails(data.healthProfessional),
    }));
  }, [connectedHPCsRes]);

  const hcpMobileData = useMemo(() => {
    if (!connectedHPCsRes || connectedHPCsRes.documents.length === 0) return [];

    return connectedHPCsRes.documents.map((data: HCPUser) => ({
      _id: data._id as string,
      hcpId: data.healthProfessional._id,
      clinicalCoach: data.isClinicalCoach || false,
      image: data.healthProfessional.profilePicture?.url || '',
      specialty: data.healthProfessionalData.speciality || t('general.labels.nA'),
      name: `${data.healthProfessional.firstName} ${data.healthProfessional.lastName}`,
    }));
  }, [connectedHPCsRes]);

  const handleRemoveProfessional = (hcpId?: string, healthProfName?: string) => {
    if (!hcpId) return;
    if (!healthProfName) return;
    setRemoveHpModalOpen(hcpId);
    setSelectedHealthProfessional(healthProfName);
  };

  const handleDeleteHCP = () => {
    try {
      removeHcp({ hcpId: removeHpModalOpen }).unwrap();
    } catch (e: any) {
      const message = e.data.message;
      if (message) {
        presentError(message);
      } else {
        presentError(t('errors.healthProf.errorHcpRemove'));
      }
    } finally {
      setRemoveHpModalOpen('');
    }
  };

  const handleEnterCode = async (code: string) => {
    try {
      hcpCodeRef.current = code;
      const res = await findHcpByCode(code).unwrap();
      if (!res || res.length === 0) {
        presentError(t('errors.healthProf.clinicalCoach.errorCCCode'));
        return;
      }
      const hcp = res[0];
      hcpDataId.current = hcp._id ? hcp._id : '';

      if (!hasCcRes || hasCcRes.length === 0) {
        const isClinicalCoach = hcp.clinicalCoach;
        if (isClinicalCoach) {
          setAddHpModalOpen(false);
          setCcDetectedOpen(true);
          return;
        }
      }
      await addHCP({
        code: code,
        isClinicalCoach: false,
      }).unwrap();
      await updateSalesGiven();
      setAddHpModalOpen(false);
      if (!hasCcRes || hasCcRes.length === 0) {
        setTimeout(() => {
          setCcPromptOpen(true);
        }, 1500);
      }
    } catch (e: any) {
      const message = e.data.message;
      if (message && message.includes('already exists')) {
        presentError('error.healthProf.alreadyConnected');
      } else {
        presentError(t('errors.generic.error01'));
      }
      setAddHpModalOpen(false);
    }
  };

  const handleAddHealthProfessional = () => {
    setAddHpModalOpen(true);
  };

  const handleAcceptCC = async () => {
    // This adds the hcp and marks it as clinical coach
    try {
      const code = hcpCodeRef.current;
      await addHCP({
        code: code,
        isClinicalCoach: true,
      }).unwrap();
      await updateSalesGiven();
    } catch (e: any) {
      const message = e.data.message;
      if (message) {
        presentError(message);
      } else {
        presentError(t('errors.generic.error01'));
      }
    } finally {
      hcpDataId.current = '';
      hcpCodeRef.current = '';
      setCcDetectedOpen(false);
    }
  };

  const handleDeclineCC = async () => {
    // This just add the HCP and not mark it as clinical coach
    try {
      const code = hcpCodeRef.current;
      await addHCP({
        code: code,
        isClinicalCoach: false,
      }).unwrap();
      await updateSalesGiven();
      setTimeout(() => {
        setCcPromptOpen(true);
      }, 1500);
      presentSuccess(t('errors.healthProf.successHcpAdd'));
    } catch (e: any) {
      const message = e.data.message;
      if (message) {
        presentError(message);
      } else {
        presentError(t('errors.generic.error01'));
      }
    } finally {
      hcpDataId.current = '';
      hcpCodeRef.current = '';
      setCcDetectedOpen(false);
    }
  };

  const handleAddClinicalCoach = async () => {
    if (!selectedCc) return;
    hcpDataId.current = selectedCc._id || '';
    try {
      await addHCP({
        code: selectedCc.code,
        isClinicalCoach: true,
      }).unwrap();
      await updateSalesGiven();
    } catch (e: any) {
      const message = e.data.message;
      if (message) {
        presentError(message);
      } else {
        presentError(t('errors.generic.error01'));
      }
    } finally {
      setSelectedCc(null);
    }
  };

  const updateSalesGiven = async () => {
    try {
      if (!hcpDataId.current) return;
      const existingSalesGiven = user?.authUser.salesGiven;
      if (existingSalesGiven) return;
      await setSalesGiven({ hcpDataId: hcpDataId.current }).unwrap();
    } catch (e: any) {
      presentError(t('errors.generic.error01'));
    } finally {
      hcpDataId.current = '';
    }
  };

  const findCCButton = (
    <Box
      mb={web ? 1 : 0}
      display={'flex'}
      mt={hcpTable.length > 0 && web ? 0 : 1}
      justifyContent={web ? 'flex-end' : 'center'}
    >
      <CustomButton
        variant={'contained'}
        onClick={() => setFindCcOpen(true)}
        style={{
          fontSize: web
            ? theme.typography.subtitle2.fontSize
            : theme.typography.body1.fontSize,
        }}
      >
        {t('buttons.findCC')}
      </CustomButton>
    </Box>
  );

  const decideFabClick = useMemo(() => {
    if (hcpMobileData.length === 0 || hcpTable.length === 0) {
      return;
    }
    return () => setAddHpModalOpen(true);
  }, [hcpMobileData, hcpTable]);

  return (
    <PageLayout
      backgroundWhite={!web}
      fabClick={decideFabClick}
      headerTitle={t('headingsTitles.hcpProfs')}
    >
      <ComponentsLayout height={'100%'} display={'flex'} flexDirection={'column'}>
        <Box>
          {web ? (
            <>
              {!hasClinicalCoach && findCCButton}
              {hcpTable.length > 0 && (
                <HealthProfessionalsTable
                  data={hcpTable}
                  handleRemoveProfessional={handleRemoveProfessional}
                />
              )}
            </>
          ) : (
            <Box display={'flex'} flexDirection={'column'} gap={3}>
              {hcpMobileData.map(data => (
                <HealthProfessionalCard
                  key={data._id || ''}
                  data={{
                    name: data.name,
                    image: data.image,
                    specialty: data.specialty,
                    clinicalCoach: data.clinicalCoach,
                  }}
                  onRemoveProfessionalClick={() =>
                    handleRemoveProfessional(data.hcpId, data.name)
                  }
                />
              ))}
            </Box>
          )}
        </Box>
        <Box
          gap={1}
          flex={1}
          display={'flex'}
          alignItems={'center'}
          flexDirection={'column'}
          justifyContent={'center'}
        >
          {(hcpMobileData.length === 0 || hcpTable.length === 0) && <FeelsEmpty />}
          {(hcpMobileData.length === 0 || hcpTable.length === 0) && (
            <CustomButton
              variant={'contained'}
              sx={{ width: '280px' }}
              onClick={handleAddHealthProfessional}
              startIcon={
                <PersonAddIcon
                  width={14}
                  height={12}
                  fill={theme.palette.secondary.main}
                />
              }
            >
              {t('buttons.addHcp')}
            </CustomButton>
          )}
          {!web && !hasClinicalCoach && findCCButton}
        </Box>
      </ComponentsLayout>
      <ModalMobile open={addHpModalOpen} setOpen={setAddHpModalOpen}>
        <AddHPCodeModal handleEnterCode={handleEnterCode} />
      </ModalMobile>
      <ModalMobile
        open={removeHpModalOpen !== ''}
        setOpen={() => setRemoveHpModalOpen('')}
      >
        <RemoveHPCodeModal
          handleRemove={handleDeleteHCP}
          healthProfessionalName={selectedHealthProfessional}
          handleCancel={() => setRemoveHpModalOpen('')}
        />
      </ModalMobile>
      <ModalCCDetected
        open={ccDetectedOpen}
        handleYes={handleAcceptCC}
        handleNo={handleDeclineCC}
        setOpen={setCcDetectedOpen}
      />
      <ModalCCPrompt
        open={ccPromptOpen}
        setOpen={setCcPromptOpen}
        handleFindOne={() => {
          setCcPromptOpen(false);
          setSearchCcOpen(true);
        }}
        handleEnterCode={() => {
          setCcPromptOpen(false);
          setAddHpModalOpen(true);
        }}
      />
      <ModalCCUnlockPotential
        open={ccUnlockOpen}
        setOpen={setCcUnlockOpen}
        handleAddNow={() => {
          setCcUnlockOpen(false);
          setFindCcOpen(true);
        }}
      />
      <ModalFindCC
        open={findCcOpen}
        setOpen={setFindCcOpen}
        handleFindOne={() => {
          setFindCcOpen(false);
          setSearchCcOpen(true);
        }}
        handleEnterCode={() => {
          setFindCcOpen(false);
          setAddHpModalOpen(true);
        }}
      />
      <ModalSearchForCC
        open={searchCcOpen}
        setOpen={setSearchCcOpen}
        handleSelectCc={selected => {
          setSearchCcOpen(false);
          setSelectedCc(selected);
        }}
        handleBack={() => setSearchCcOpen(false)}
      />
      <ModalCCDetails
        data={{
          phone: selectedCc?.phone || '',
          postCode: selectedCc?.postalCode || '',
          email: selectedCc?.authUser.email || '',
          avatar: selectedCc?.authUser.profilePicture?.url || '',
          speciality: t(findSpecialityLabel(selectedCc?.speciality || '')),
          fullName: `${selectedCc?.authUser.firstName} ${selectedCc?.authUser.lastName}`,
          address:
            `${selectedCc?.address} ${selectedCc?.addressNum}, ${selectedCc?.area}` || '',
        }}
        open={!!selectedCc}
        setOpen={() => {
          setSelectedCc(null);
        }}
        handleBack={() => {
          setSelectedCc(null);
          setSearchCcOpen(true);
        }}
        handleAddClinicalCoach={handleAddClinicalCoach}
      />
    </PageLayout>
  );
};

export default HealthProfessionals;
