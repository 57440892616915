import React, { FC } from 'react';
import {
  CardiovascularIcon,
  CustomButton,
  FileCard,
  theme,
  usePrepareDietPlan,
} from '@hdcorner/ui-library';
import { Box, BoxProps, styled } from '@mui/material';
import SectionHeading from '../components/SectionHeading';
import { useGetDietPlanQuery } from './queries/dietPlanQueries';
import { useTranslation } from 'react-i18next';

const IconBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: 48,
  height: 48,
  borderRadius: 4,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(1),
  backgroundColor: theme.palette.error.main,
}));

type SectionDietPlanProps = {
  title?: boolean;
  handleCalculateClick: () => void;
};

const SectionDietPlan: FC<SectionDietPlanProps> = ({ title, handleCalculateClick }) => {
  const { t } = useTranslation();

  const { data: dietPlanRes } = useGetDietPlanQuery();

  const prepareDietPlan = usePrepareDietPlan(dietPlanRes);

  const handleOpenFile = () => {
    const file = prepareDietPlan?.file;
    if (file) {
      window.open(file, '_blank');
    }
  };

  return (
    <Box gap={1} width={'100%'} display={'flex'} flexDirection={'column'}>
      <Box display={title ? 'block' : 'none'}>
        <SectionHeading hasGoal={false} heading={t('headingsTitles.personalDietCal')} />
      </Box>

      {prepareDietPlan && (
        <FileCard
          onClick={handleOpenFile}
          date={prepareDietPlan.date}
          title={prepareDietPlan.title}
          subtitle={prepareDietPlan.subtitle}
          hasFile={prepareDietPlan.file !== ''}
          sx={{ backgroundColor: theme.palette.secondary.light }}
          icon={
            <IconBox>
              <CardiovascularIcon />
            </IconBox>
          }
        />
      )}
      <CustomButton fullWidth variant={'contained'} onClick={handleCalculateClick}>
        {prepareDietPlan ? t('buttons.recalculate') : t('buttons.calculate')}
      </CustomButton>
    </Box>
  );
};

export default SectionDietPlan;
