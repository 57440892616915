import React, { useState } from 'react';
import PageLayout from '../../../components/PageLayout';
import { ComponentsLayout, DownloadIcon } from '@hdcorner/ui-library';
import CalorieDataInner from '../components/CalorieDataInner';
import ModalInDepthDataDL from '../../../components/ModalInDepthDataDL';
import { useTranslation } from 'react-i18next';

const CalorieData = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  return (
    <PageLayout
      headerTitle={t('headingsTitles.calInDepth')}
      defaultHref={'/dashboard/fitness/calories'}
      endButtons={[
        {
          icon: <DownloadIcon width={'18px'} height={'20px'} />,
          onClick: () => setOpen(true),
        },
      ]}
    >
      <ComponentsLayout>
        <CalorieDataInner />
      </ComponentsLayout>

      <ModalInDepthDataDL
        open={open}
        module={'calories'}
        dismiss={() => setOpen(false)}
      />
    </PageLayout>
  );
};

export default CalorieData;
