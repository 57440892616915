import * as React from 'react';
import { SVGProps } from 'react';

const NutritionSQ = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.6667 4.66663H13.9387C13.708 3.38863 12.79 2.66663 11.3334 2.66663C10.804 2.66663 10.1167 2.06263 9.56402 1.57796C8.98535 1.06663 8.52735 0.666626 8.00002 0.666626C7.27069 0.666626 6.03802 1.71129 5.06135 2.66663C4.96802 2.70196 2.67135 2.32129 2.09335 4.66663H1.33335C1.15654 4.66663 0.986973 4.73686 0.861949 4.86189C0.736925 4.98691 0.666687 5.15648 0.666687 5.33329V7.99996C0.666687 9.94488 1.4393 11.8101 2.81457 13.1854C4.18984 14.5607 6.0551 15.3333 8.00002 15.3333C9.94494 15.3333 11.8102 14.5607 13.1855 13.1854C14.5607 11.8101 15.3334 9.94488 15.3334 7.99996V5.33329C15.3334 5.15648 15.2631 4.98691 15.1381 4.86189C15.0131 4.73686 14.8435 4.66663 14.6667 4.66663ZM4.66669 3.99996H7.33335C7.51016 3.99996 7.67973 3.92972 7.80476 3.8047C7.92978 3.67967 8.00002 3.5101 8.00002 3.33329C8.00002 3.15648 7.92978 2.98691 7.80476 2.86189C7.67973 2.73686 7.51016 2.66663 7.33335 2.66663H7.05335C7.33358 2.41329 7.64179 2.19275 7.97202 2.00929C8.22409 2.18012 8.46217 2.37072 8.68402 2.57929C9.40469 3.21196 10.3014 3.99996 11.3334 3.99996C12.0254 3.99996 12.4087 4.19996 12.5714 4.66663H3.48935C3.5937 4.4483 3.76256 4.26728 3.97313 4.14805C4.18369 4.02882 4.42579 3.97713 4.66669 3.99996ZM2.00002 5.99996H14V7.33329H2.00002V5.99996ZM8.00002 14C6.52499 13.9978 5.1023 13.4531 4.00305 12.4695C2.90381 11.486 2.20485 10.1323 2.03935 8.66663H13.9607C13.7952 10.1323 13.0962 11.486 11.997 12.4695C10.8977 13.4531 9.47505 13.9978 8.00002 14Z"
        fill={props?.color ?? "#F2F1F3"}
      />
    </svg>
  );
};

export default NutritionSQ;
