import React, { FC } from 'react';
import { Box, BoxProps, styled, Typography, TypographyProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CustomBox = styled(Box, {
  shouldForwardProp: propName => {
    return (
      propName !== 'expanded' &&
      propName !== 'noHeading' &&
      propName !== 'customWidth' &&
      propName !== 'customHeight'
    );
  },
})<
  BoxProps & {
    expanded: boolean;
    noHeading: boolean;
    customWidth: string | undefined;
    customHeight: string | undefined;
  }
>(({ theme, ...props }) => ({
  flexGrow: 1,
  display: 'flex',
  textAlign: 'left',
  borderRadius: '8px',
  flexDirection: 'column',
  boxSizing: 'border-box',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.secondary.main
      : theme.palette.secondary.light,
  width: props.customWidth ? props.customWidth : 'none',
  height: props.customHeight ? props.customHeight : '132px',
  padding: props.noHeading ? theme.spacing(6, 2, 2, 2) : theme.spacing(2),
}));

const InnerBox = styled(Box, {
  shouldForwardProp: propName => {
    return propName !== 'expanded';
  },
})<BoxProps & { expanded: boolean }>(({ theme, ...props }) => ({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 0, 2, 0),
  justifyContent: props.expanded ? 'space-between' : 'flex-start',
}));

const TypographyHeading = styled(Typography, {
  shouldForwardProp: propName => {
    return propName !== 'noHeading';
  },
})<TypographyProps & { noHeading: boolean }>(({ theme, ...props }) => ({
  textTransform: 'capitalize',
  padding: theme.spacing(0, 0, 2, 0),
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.kmColorsCoolGrey.main
      : theme.palette.kmColorsDarkGrey.main,
  fontWeight: theme.typography.fontWeightBold,
  display: props.noHeading ? 'none' : 'block',
}));

const TypographyNum = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '28px',
  color: theme.palette.primary.main,
  padding: theme.spacing(0, 0, 0, 1),
  fontWeight: theme.typography.fontWeightMedium,
}));

const TypographyDate = styled(Typography, {
  shouldForwardProp: propName => {
    return propName !== 'expanded';
  },
})<TypographyProps & { expanded: boolean }>(({ theme, ...props }) => ({
  flexGrow: 1,
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.kmColorsCoolGrey.main
      : theme.palette.kmColorsDarkGrey.main,
  fontWeight: theme.typography.fontWeightLight,
  textAlign: props.expanded ? 'right' : 'left',
}));

type HealthProfDashboardCardProps = {
  num: number;
  date: string;
  heading?: string;
  expanded: boolean;
  icon: React.ReactNode;
  noHeading?: boolean | any;
  iconTwo?: React.ReactNode;
  customWidth?: string | undefined;
  customHeight?: string | undefined;
};
const HealthProfDashboardCard: FC<HealthProfDashboardCardProps> = ({
  num,
  date,
  icon,
  iconTwo,
  heading,
  expanded,
  noHeading,
  customWidth,
  customHeight,
}) => {
  const { t } = useTranslation();

  return (
    <CustomBox
      expanded={expanded}
      noHeading={noHeading}
      customWidth={customWidth}
      customHeight={customHeight}
    >
      <TypographyHeading variant={'body1'} noHeading={noHeading}>
        {heading}
      </TypographyHeading>
      <InnerBox expanded={expanded}>
        <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
          {icon}
          <TypographyNum>{num}</TypographyNum>
        </Box>
        <Box display={expanded ? 'block' : 'none'}>{iconTwo}</Box>
      </InnerBox>
      <TypographyDate variant={'body2'} expanded={expanded}>
        {t('general.labels.updated')} {date}
      </TypographyDate>
    </CustomBox>
  );
};

export default HealthProfDashboardCard;
