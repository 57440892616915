import { FC } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { CustomButton, GoalActionButtons, theme } from '@hdcorner/ui-library';
import { styled } from '@mui/material/styles';
import { ButtonProps } from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

const ViewButton = styled(CustomButton, {
  shouldForwardProp: propName => propName !== 'display',
})<ButtonProps & { display: boolean }>(({ theme, display }) => ({
  display: display ? 'block' : 'none',
  color: theme.palette.medication.main,
  fontSize: theme.typography.body1.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  backgroundColor: theme.palette.secondary.main,
}));

type SectionHeadingProps = {
  heading: string;
  hasGoal: boolean;
  viewMore?: () => void;
  handleAdd?: () => void;
  handleEdit?: () => void;
  handleRemove?: () => void;
  displayGoalButtons?: boolean;
};

const SectionHeading: FC<SectionHeadingProps> = ({
  hasGoal,
  heading,
  viewMore,
  handleAdd,
  handleEdit,
  handleRemove,
  displayGoalButtons = false,
}) => {
  const { t } = useTranslation();
  const web = useMediaQuery(theme.breakpoints.up('md'));
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  return (
    <Box
      flexGrow={1}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <Typography
        variant={'subtitle1'}
        sx={{ fontWeight: theme.typography.fontWeightMedium }}
        textTransform={web && lang === 'en' ? 'uppercase' : 'capitalize'}
      >
        {heading}
      </Typography>
      <ViewButton onClick={viewMore} display={web ? false : !!viewMore} variant={'text'}>
        {t('buttons.navigation.viewMore')}
      </ViewButton>
      <Box display={web && displayGoalButtons ? 'flex' : 'none'}>
        <GoalActionButtons
          handleAdd={handleAdd}
          handleEdit={handleEdit}
          handleRemove={handleRemove}
          type={hasGoal ? 'edit' : 'add'}
        />
      </Box>
    </Box>
  );
};

export default SectionHeading;
