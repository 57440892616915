import { FC, ReactNode } from 'react';
import { Box, BoxProps, styled } from '@mui/material';

const IconBox = styled(Box)<BoxProps>(() => ({
  width: '40px',
  height: '40px',
  padding: '8px',
  display: 'flex',
  borderRadius: '8px',
  alignItems: 'center',
  boxSizing: 'border-box',
  justifyContent: 'center',
}));

type Props = {
  color: string;
  children: ReactNode;
};
const SQIconContainer: FC<Props> = ({ color, children }) => {
  return <IconBox bgcolor={color}>{children}</IconBox>;
};

export default SQIconContainer;
