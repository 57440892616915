import React, { FC } from 'react';
import { Fab, FabProps, useTheme, Zoom, ZoomProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import AddIconRounded from '../../assets/AddIconRounded';

const FabMain = styled(Fab)<FabProps>(({ theme }) => ({
  right: 10,
  bottom: 28,
  position: 'absolute',
  boxShadow: theme.shadows[1],
}));

type FloatingActionButtonType = {
  onClick: () => void;
  zoomProps?: ZoomProps;
  icon?: React.ReactNode;
  svgProps?: SvgIconProps;
};
const FloatingActionButton: FC<FloatingActionButtonType & FabProps> = ({
  onClick,
  zoomProps,
  icon,
  svgProps,
  ...fabProps
}) => {
  const theme = useTheme();

  return (
    <Zoom in unmountOnExit {...zoomProps}>
      <FabMain
        {...fabProps}
        size={'large'}
        onClick={onClick}
        color={theme.palette.mode === 'dark' ? 'secondary' : 'primary'}
      >
        {icon ? icon : <AddIconRounded {...svgProps} />}
      </FabMain>
    </Zoom>
  );
};

export default FloatingActionButton;
