import Box from '@mui/material/Box';
import {
  CalendarIcon,
  ClickNavCardNutrition,
  MedicationRequest,
  Medicine,
  theme,
} from '@hdcorner/ui-library';
import * as React from 'react';
import { FC, useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

type Props = {
  medicine?: Medicine;
  medication: MedicationRequest;
  handleShowRepeat: () => void;
  handleShowFrequency: () => void;
  handleShowDuration: () => void;
};
const AddMedicationOverview: FC<Props> = ({
  medicine,
  medication,
  handleShowRepeat,
  handleShowDuration,
  handleShowFrequency,
}) => {
  const { t } = useTranslation();

  const formattedDuration = useMemo(() => {
    if (!medication.start || !medication.end) {
      return 'ex. 01/01 - 01/02';
    }
    return `${moment(medication.start).format('DD/MM')} - ${moment(medication.end).format(
      'DD/MM',
    )}`;
  }, [medication.start, medication.end]);

  const formattedFrequency = useMemo(() => {
    if (!medication.frequency) {
      return t('general.labels.nA');
    }
    if (medication.frequency.length === 0) {
      return t('medication.labels.selectFrequency');
    }
    if (medication.frequency.length === 1) {
      return t('medication.labels.oncePerDay');
    }
    return t('medication.labels.xTimesPerDay', { amount: medication.frequency.length });
  }, [medication.frequency, t]);

  const formattedRepeat = useMemo(() => {
    const days = medication.repeat.length;
    if (days === 0) {
      return t('medication.labels.selectRepeat');
    } else if (days === 1) {
      return t('medication.labels.everyX', { amount: medication.repeat[0] });
    } else if (days >= 7) {
      return t('medication.labels.everyday');
    } else {
      return t('buttons.custom');
    }
  }, [medication.repeat]);

  return (
    <Box>
      <Box marginBottom={theme.spacing(3)}>
        <ClickNavCardNutrition
          body={medicine?.medicineName}
          headings={t('medication.titles.medicine')}
        />
        <ClickNavCardNutrition headings={'Type'} body={medicine?.medicineType} />
        <ClickNavCardNutrition
          body={`${medicine?.content}`}
          headings={t('general.labels.content')}
        />
        <ClickNavCardNutrition headings={'Package'} body={medicine?.package} />
      </Box>

      <ClickNavCardNutrition
        body={formattedRepeat}
        onClick={handleShowRepeat}
        headings={t('medication.titles.repeat')}
      />
      <ClickNavCardNutrition
        body={formattedFrequency}
        onClick={handleShowFrequency}
        headings={t('medication.titles.frequency')}
      />
      <ClickNavCardNutrition
        icon={<CalendarIcon />}
        body={formattedDuration}
        onClick={handleShowDuration}
        headings={t('medication.titles.duration')}
      />
    </Box>
  );
};

export default AddMedicationOverview;
