import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { FC, useCallback } from 'react';
import AvatarBodyPartSelector from './AvatarBodyPartSelector';
import {
  exampleExercise,
  ExerciseBodyPart,
  exerciseBodyPartLabel,
} from '../types/ExerciseBodyPart';
import { useTranslation } from 'react-i18next';

const Title = styled(Typography, {
  shouldForwardProp: propName => propName !== 'noMargin',
})<TypographyProps & { noMargin?: boolean }>(({ theme, noMargin }) => ({
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightMedium,
  marginBottom: noMargin ? 0 : theme.spacing(1),
}));

const Subtitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.light,
}));

const WorkoutsText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  marginLeft: theme.spacing(2),
}));

const Dot = styled(Box)<BoxProps>(({ theme }) => ({
  width: 4,
  height: 4,
  borderRadius: '99px',
  background: theme.palette.primary.main,
}));

type Props = {
  selectedBodyPart: ExerciseBodyPart;
  setSelectedBodyPart: (part: ExerciseBodyPart) => void;
};
const WorkoutType: FC<Props> = ({ selectedBodyPart, setSelectedBodyPart }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const web = useMediaQuery(theme.breakpoints.up('sm'));

  const handleSelectBodyPart = useCallback(
    (part: ExerciseBodyPart) => {
      setSelectedBodyPart(part === selectedBodyPart ? ExerciseBodyPart.WHOLE : part);
    },
    [selectedBodyPart, setSelectedBodyPart],
  );

  return (
    <Box display={'flex'} flex={1} flexDirection={'column'}>
      <Title variant={'h1'} align={web ? 'left' : 'center'}>
        {t('fitness.workouts.text.chooseType')}
      </Title>
      <Subtitle variant={'subtitle2'} align={web ? 'left' : 'center'}>
        {t('fitness.workouts.text.chooseBodyPart')}
      </Subtitle>
      <Box display={'flex'} marginTop={theme.spacing(3)}>
        <Box
          flex={1}
          display={'flex'}
          minWidth={'138px'}
          alignItems={'center'}
          flexDirection={'column'}
          gap={theme.spacing(3)}
          marginRight={theme.spacing(3)}
        >
          <AvatarBodyPartSelector
            selected={selectedBodyPart}
            onSelect={handleSelectBodyPart}
          />
          <Title variant={'subtitle1'} noMargin={false}>
            {t(exerciseBodyPartLabel(selectedBodyPart))}
          </Title>
        </Box>
        <Box display={'flex'} flexDirection={'column'} flex={2}>
          <Title variant={'subtitle1'}>
            {t(exerciseBodyPartLabel(selectedBodyPart))} Workouts
          </Title>
          {exampleExercise(selectedBodyPart).map((item, index) => (
            <Box
              key={index}
              display={'flex'}
              alignItems={'center'}
              marginTop={index !== 0 ? 1 : 0}
            >
              <Dot />
              <WorkoutsText variant={'subtitle1'}>{t(item)}</WorkoutsText>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default WorkoutType;
