import React, { FC, useMemo } from 'react';
import { Box, BoxProps, Typography, useTheme } from '@mui/material';
import ArrowRight from '../../assets/ArrowRight';
import { styled } from '@mui/material/styles';
import ExerciseIcon from '../../assets/ExerciseIcon';
import DiabetesSQ from '../../assets/DiabetesSQ';
import PillIcon from '../../assets/PillIcon';
import NutritionBowl from '../../assets/NutritionBowl';
import { TypographyProps } from '@mui/material/Typography/Typography';
import CVDRiskSQ from '../../assets/CVDRiskSQ';
import HypertensionSQ from '../../assets/HypertensionSQ';
import LipidSQ from '../../assets/LipidSQ';
import WorkoutIcon from '../../assets/WorkoutIcon';
import WeightIcon from '../../assets/WeightIcon';
import ActivityIcon from '../../assets/ActivityIcon';
import SleepIcon from '../../assets/SleepIcon';
import StepsIcon from '../../assets/StepsIcon';

const CustomBox = styled(Box)<BoxProps>(({ theme }) => ({
  flexGrow: '1',
  height: '56px',
  display: 'flex',
  cursor: 'pointer',
  borderRadius: '8px',
  alignItems: 'center',
  boxSizing: 'border-box',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.secondary.light,
  margin: '0 0 8px 0',
  [theme.breakpoints.up('md')]: {
    margin: 0,
  },
  '&:last-of-type': {
    margin: theme.spacing(0),
  },
}));

const TypographyBody = styled(Typography)<TypographyProps & { size: 'mobile' | 'web' }>(
  ({ theme, ...props }) => ({
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    textOverflow: 'ellipsis',
    color: theme.palette.kmColorsCoolGrey.main,
    fontWeight: theme.typography.fontWeightRegular,
    maxWidth: props.size === 'web' ? '350px' : '140px',
    margin: theme.spacing(0, 1),
  }),
);

type ClickNavCardProps = {
  time: string;
  body: string;
  title: string;
  category: string;
  size?: 'mobile' | 'web';
  onClickHandler?: () => void;
};
const ClickNavCardReminders: FC<ClickNavCardProps> = ({
  time,
  body,
  title,
  category,
  size = 'mobile',
  onClickHandler,
}) => {
  const theme = useTheme();

  const pickIcon = useMemo(() => {
    switch (category) {
      case 'exercise':
        return <ExerciseIcon color={theme.palette.exercise.main} />;
      case 'medication':
        return <PillIcon color={theme.palette.medication.main} />;
      case 'nutrition':
        return <NutritionBowl color={theme.palette.nutrition.main} />;
      case 'diabetes':
        return <DiabetesSQ color={theme.palette.diabetes.main} />;
      case 'hypertension':
        return <HypertensionSQ color={theme.palette.hypertension.main} />;
      case 'cvd_risk':
        return <CVDRiskSQ color={theme.palette.cvdRisk.main} />;
      case 'lipid_profile':
        return <LipidSQ color={theme.palette.lipid.main} />;
      case 'workout':
        return <WorkoutIcon />;
      case 'weight':
        return <WeightIcon />;
      case 'activities':
        return <ActivityIcon />;
      case 'sleep':
        return <SleepIcon />;
      case 'steps':
        return <StepsIcon />;
      default:
        return null;
    }
  }, [category]);

  return (
    <CustomBox onClick={onClickHandler}>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        margin={theme.spacing(0)}
      >
        {pickIcon}
        <Typography
          variant={'subtitle2'}
          sx={{
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightMedium,
            margin: theme.spacing(0, 1),
          }}
        >
          {time}
        </Typography>
        <Typography
          variant={'subtitle2'}
          sx={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            color: theme.palette.primary.main,
          }}
        >
          {title}
        </Typography>
        <TypographyBody
          size={size}
          variant={'subtitle2'}
          sx={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {body}
        </TypographyBody>
      </Box>
      <Box>
        <ArrowRight />
      </Box>
    </CustomBox>
  );
};

export default ClickNavCardReminders;
