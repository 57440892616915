import * as React from 'react';
import { SVGProps } from 'react';

const ThumbsUp = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.727304 14.5461H12.8326C13.3478 14.5494 13.8479 14.3724 14.2464 14.0458C14.6449 13.7192 14.9166 13.2635 15.0145 12.7576L15.9636 7.66652C16.0216 7.35496 16.0102 7.03445 15.9301 6.72782C15.85 6.4212 15.7033 6.13602 15.5003 5.89262C15.2904 5.64085 15.0276 5.43849 14.7305 5.29995C14.4334 5.16141 14.1095 5.09011 13.7817 5.09113H10.3117V2.18191C10.3117 0.682939 8.77493 0 7.34723 0C7.15434 0 6.96934 0.0766265 6.83295 0.213023C6.69655 0.349419 6.61993 0.534411 6.61993 0.727304C6.61993 2.11427 6.37264 3.57106 6.09336 3.84308L4.06781 5.81844H0.727304C0.534411 5.81844 0.349419 5.89506 0.213023 6.03146C0.0766265 6.16785 0 6.35285 0 6.54574V13.8188C0 14.0117 0.0766265 14.1967 0.213023 14.3331C0.349419 14.4695 0.534411 14.5461 0.727304 14.5461ZM5.09113 6.85193L7.10867 4.88821C7.78507 4.22855 7.99017 2.62484 8.05417 1.55425C8.44837 1.64807 8.86075 1.83063 8.86075 2.18555V5.82207C8.86075 6.01497 8.93738 6.19996 9.07377 6.33635C9.21017 6.47275 9.39516 6.54938 9.58805 6.54938H13.7853C13.8994 6.54857 14.0122 6.57291 14.1158 6.62068C14.2194 6.66845 14.3112 6.73847 14.3846 6.82575C14.4519 6.90517 14.5006 6.99865 14.527 7.09934C14.5535 7.20003 14.557 7.30536 14.5374 7.4076L13.5919 12.4987C13.5544 12.6706 13.458 12.824 13.3193 12.9323C13.1806 13.0406 13.0084 13.0969 12.8326 13.0915H5.09113V6.85193ZM1.45461 7.27304H3.63652V13.0915H1.45461V7.27304Z"
        fill="white"
      />
    </svg>
  );
};

export default ThumbsUp;
