import React, { FC } from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardActionAreaProps,
  CardMedia,
  CardProps,
  Typography,
  useTheme,
} from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const CardMain = styled(Card)<CardProps>(({ theme }) => ({
  display: 'flex',
  flex: 1,
  height: 146,
  flexDirection: 'column',
  boxShadow: 'none',
  borderRadius: '8px',
  overflow: 'hidden',
  position: 'relative',
  border: `2px solid ${theme.palette.secondary.main}`,
}));

const CardActionAreaMain = styled(CardActionArea)<CardActionAreaProps>(() => ({
  flex: 1,
  display: 'flex',
  position: 'unset',
}));

type AddCardProps = {
  title: string;
  imageSrc: string;
  numberKcal: string;
  recommendedPortion: string;
  cardActionAreaProps?: CardActionAreaProps;
  onClick: () => void;
};
const AddCardBackground: FC<AddCardProps & CardProps> = ({
  title,
  onClick,
  imageSrc,
  numberKcal,
  recommendedPortion,
  cardActionAreaProps,
  ...cardProps
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box display={'flex'} flex={1} flexDirection={'column'} height={'100%'}>
      <CardMain raised={false} {...cardProps}>
        <CardActionAreaMain onClick={() => onClick()} {...cardActionAreaProps}>
          <Box
            sx={{
              zIndex: 30,
              top: '50%',
              left: '50%',
              display: 'flex',
              position: 'absolute',
              alignItems: 'center',
              flexDirection: 'column',
              transform: 'translateY(-50%) translateX(-50%)',
            }}
          >
            <AddCircleOutlineOutlinedIcon
              sx={{ width: 22, height: 22, color: theme.palette.secondary.light }}
            />
            <Typography
              variant={'body1'}
              sx={{
                marginTop: 1,
                textAlign: 'center',
                color: theme.palette.secondary.light,
                fontWeight: theme.typography.fontWeightRegular,
              }}
            >
              Add {title}
            </Typography>
          </Box>
          <CardMedia
            src={imageSrc}
            component={'img'}
            sx={{
              height: 146,
              objectFit: 'cover',
              filter: 'blur(2px) brightness(70%)',
            }}
          />
        </CardActionAreaMain>
      </CardMain>
      <Box marginTop={1}>
        <Typography
          variant={'body1'}
          sx={{
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightRegular,
          }}
        >
          {numberKcal} kcal
        </Typography>
        <Typography
          variant={'caption'}
          sx={{
            color: theme.palette.primary.light,
            fontWeight: theme.typography.fontWeightBold,
          }}
        >
          {t('fitness.nutrition.text.recommended', { portion: recommendedPortion })}
        </Typography>
      </Box>
    </Box>
  );
};

export default AddCardBackground;
