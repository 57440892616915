import React, { FC } from 'react';
import {
  Box,
  BoxProps,
  Card,
  CardContent,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import { theme } from '@hdcorner/ui-library';
import { useTranslation } from 'react-i18next';

const OuterBox = styled(Box)<BoxProps>(() => ({
  flex: 1,
  display: 'flex',
  alignItems: 'flex-end',
  flexDirection: 'column',
}));

const InnerBox = styled(Box)<BoxProps>(() => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
}));

const Text = styled(Typography, {
  shouldForwardProp: propName => propName !== 'alignBottom' && propName !== 'color',
})<TypographyProps & { color?: string; alignBottom?: boolean }>(
  ({ theme, color, alignBottom }) => ({
    flex: 1,
    display: 'flex',
    textTransform: 'capitalize',
    fontWeight: theme.typography.fontWeightRegular,
    alignItems: alignBottom ? 'flex-end' : undefined,
    color: color ? color : theme.palette.primary.main,
  }),
);

type InDepthDataOverallCardType = {
  min: number;
  max: number;
  avg: number;
  type: string;
  measure: string;
};
const InDepthDataOverallCard: FC<InDepthDataOverallCardType> = ({
  min,
  max,
  avg,
  type,
  measure,
}) => {
  const { t } = useTranslation();
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  return (
    <Card
      raised={false}
      sx={{
        height: 110,
        boxShadow: 'none',
        borderRadius: '8px',
        padding: theme.spacing(3, 2),
      }}
    >
      <CardContent
        sx={{
          flex: 1,
          padding: 0,
          height: '100%',
          display: 'flex',
          '&.MuiCardContent-root:last-child': {
            paddingBottom: theme.spacing(0),
          },
        }}
      >
        <Box display={'flex'} flex={1}>
          <OuterBox sx={{ flex: 1.5 }}>
            <InnerBox sx={{ alignItems: 'flex-start' }}>
              <Text
                color={theme.palette.secondary.dark}
                style={{ textTransform: 'lowercase' }}
              >
                Values are in {measure}
              </Text>
              <Text
                alignBottom
                textTransform={lang === 'el' ? 'capitalize' : 'uppercase'}
              >
                {type}
              </Text>
            </InnerBox>
          </OuterBox>
          <OuterBox>
            <InnerBox>
              <Text>{t('measurements.min')}</Text>
              <Text alignBottom color={theme.palette.primary.light}>
                {min.toFixed()}
              </Text>
            </InnerBox>
          </OuterBox>
          <OuterBox>
            <InnerBox>
              <Text>{t('measurements.max')}</Text>
              <Text alignBottom color={theme.palette.primary.light}>
                {max.toFixed()}
              </Text>
            </InnerBox>
          </OuterBox>
          <OuterBox>
            <InnerBox>
              <Text color={theme.palette.lipid.main}>{t('measurements.avg')}.</Text>
              <Text alignBottom color={theme.palette.lipid.main}>
                {avg.toFixed()}
              </Text>
            </InnerBox>
          </OuterBox>
        </Box>
      </CardContent>
    </Card>
  );
};

export default InDepthDataOverallCard;
