import React, { FC } from 'react';
import ChatIcon from '../../assets/ChatIcon';
import { styled } from '@mui/material/styles';
import DeleteUser from '../../assets/DeleteUser';
import { TableCellProps } from '@mui/material/TableCell';
import { CustomCheckbox } from '../controls/CheckBoxesSingle';
import { Avatar, Box, IconButton, TableCell, TableRow, useTheme } from '@mui/material';
import ClinicalCoachBadgeIcon from '../../assets/ClinicalCoachBadgeIcon';

const TableCellData = styled(TableCell, {
  shouldForwardProp: prop => prop !== 'alignData',
})<TableCellProps & { alignData?: boolean }>(({ alignData, theme }) => ({
  flexGrow: '1',
  height: '40px',
  boxSizing: 'border-box',
  color: theme.palette.primary.main,
  fontSize: theme.typography.body1.fontSize,
  fontWeight: theme.typography.body1.fontWeight,
  backgroundColor: theme.palette.secondary.light,
  borderBottomColor: theme.palette.background.default,
  padding: theme.spacing(1.5, 0, 1.5, 3),
  '.Mui-active': {
    color: theme.palette.primary.light,
  },
  '&:first-of-type': {
    width: '52px',
    padding: theme.spacing(1, 1, 1, 2),
  },
  '&:nth-of-type(2)': {
    textAlign: alignData ? 'center' : 'left',
    paddingLeft: theme.spacing(0),
    fontWeight: theme.typography.fontWeightRegular,
  },
  '&:nth-of-type(3)': {
    textAlign: alignData ? 'center' : 'left',
  },
  '&:last-of-type': {
    paddingRight: theme.spacing(2),
  },
}));

type HealthProfTableRowsProps = {
  avatar: string;
  checked?: boolean;
  selected: boolean;
  rowCells: string[];
  onClick: () => void;
  noActions?: boolean;
  alignData?: boolean;
  clinicalCoach?: boolean;
  handleChatClick: () => void;
  handleDeleteClick?: () => void;
};
const HealthProfTableRows: FC<HealthProfTableRowsProps> = ({
  avatar,
  checked,
  onClick,
  rowCells,
  selected,
  noActions,
  alignData,
  clinicalCoach,
  handleChatClick,
  handleDeleteClick,
}) => {
  const theme = useTheme();

  return (
    <TableRow
      hover
      tabIndex={-1}
      role="checkbox"
      selected={checked}
      aria-checked={checked}
      onClick={event => {
        onClick();
        event.stopPropagation();
      }}
      sx={{
        '&:last-child th, &:last-child td': {
          borderBottom: 0,
          '&:first-of-type': {
            borderBottomLeftRadius: '8px',
          },
          '&:last-of-type': {
            borderBottomRightRadius: '8px',
          },
        },
      }}
    >
      <TableCellData align={'center'} alignData={alignData}>
        {selected || checked ? (
          <CustomCheckbox checked={checked} />
        ) : (
          <Box position={'relative'}>
            <Avatar
              alt="Avatar"
              src={avatar}
              sx={{
                width: 24,
                height: 24,
                margin: '0 auto',
              }}
            />
            {clinicalCoach && (
              <Box right={'-5px'} bottom={'-10px'} position={'absolute'}>
                <ClinicalCoachBadgeIcon />
              </Box>
            )}
          </Box>
        )}
      </TableCellData>
      {rowCells.map((cell, index) => (
        <TableCellData align={'center'} key={index} alignData={alignData}>
          {cell}
        </TableCellData>
      ))}
      {!noActions && (
        <TableCellData align={'center'}>
          <IconButton
            onClick={event => {
              handleChatClick();
              event.stopPropagation();
            }}
          >
            <ChatIcon color={theme.palette.primary.main} />
          </IconButton>
          {handleDeleteClick && (
            <IconButton
              onClick={event => {
                handleDeleteClick();
                event.stopPropagation();
              }}
            >
              <DeleteUser color={theme.palette.primary.main} />
            </IconButton>
          )}
        </TableCellData>
      )}
    </TableRow>
  );
};

export default HealthProfTableRows;
