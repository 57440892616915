import moment from 'moment';
import { Box } from '@mui/material';
import React, { FC, useMemo, useState } from 'react';
import { useIonRouter } from '@ionic/react';
import useAlert from '../../../../hooks/useAlert';
import { deleteActivityDetails } from '../slices/activitySlice';
import { CustomButton, CustomInput, theme } from '@hdcorner/ui-library';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  useGetTodaysActivityLogsQuery,
  useGetUserGoalActivityQuery,
  usePostCreateActivityLogMutation,
} from '../queries/activitiesQueries';
import { usePostCaloriesInDepthMutation } from '../../calories/queries/caloriesQueries';
import { calculateLogs } from '../utils/calculateLogs';
import { incrementalProgress } from '../../../../utils/goalProgress';
import { addGoalDetails } from '../../../dashboard/slices/congratulationsSlice';
import { useTranslation } from 'react-i18next';

type Props = {
  title: string;
  activityAddedHandler?: () => void;
};
const AddActivityPanel: FC<Props> = ({ title, activityAddedHandler }) => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { presentSuccess, presentError } = useAlert();

  const { data } = useAppSelector(state => state.auth);
  const { addActivity } = useAppSelector(state => state.activity);

  const [activity, setActivity] = useState({
    cal: 0,
    name: '',
    duration: undefined,
  });

  const { data: getGoalRes } = useGetUserGoalActivityQuery();
  const { data: logsData } = useGetTodaysActivityLogsQuery();

  const [postCalories] = usePostCaloriesInDepthMutation();
  const [postActivityLog] = usePostCreateActivityLogMutation();

  const lowerCaseTitle =
    title.toLowerCase() === 'custom activity' ||
    title.toLowerCase() === 'custom δραστηριότητα'
      ? true
      : false;

  const goalData = useMemo(() => {
    if (!getGoalRes || getGoalRes.length === 0) return 0;
    return getGoalRes[0].goal.duration;
  }, [getGoalRes]);

  const allLogs = useMemo(() => {
    if (!logsData) return 0;
    return calculateLogs(logsData, 'duration');
  }, [logsData]);

  const handleChange = (fieldName: string, value: any) => {
    if (fieldName === 'duration' && !lowerCaseTitle) {
      const weight = data.user?.weight || 0;
      const calories = (value * (addActivity.met * 3.5) * weight) / 200;
      setActivity(oldValue => ({
        ...oldValue,
        cal: calories,
        duration: value,
      }));
    } else {
      setActivity(oldValue => ({
        ...oldValue,
        [fieldName]: value,
      }));
    }
  };

  const handleAddActivity = () => {
    postActivityLog({
      calories: activity.cal,
      custom: lowerCaseTitle,
      logDate: moment().toISOString(),
      duration: activity.duration || 0,
      name: lowerCaseTitle ? activity.name : title,
      activity: lowerCaseTitle ? undefined : addActivity._id,
    })
      .unwrap()
      .then(() => {
        presentSuccess(t('errors.fitness.exercise.successLog'));
      })
      .catch(() => presentError(t('errors.fitness.exercise.errorLog')))
      .finally(() => {
        dispatch(deleteActivityDetails);
        handlePostCalories();
        if (
          getGoalRes &&
          getGoalRes.length > 0 &&
          incrementalProgress(allLogs, getGoalRes[0].goal.duration) === 100
        ) {
          setTimeout(() => {
            dispatch(
              addGoalDetails({
                category: 'activities',
                goal: { value: goalData, measure: 'mins' },
                start: {
                  value: 0,
                  measure: 'mins',
                  time: moment().utc(true).startOf('day').toISOString(),
                },
              }),
            );
          }, 1500);
        }
      });
  };

  const handlePostCalories = () => {
    postCalories()
      .unwrap()
      .catch(() => presentError(t('errors.fitness.calories.errorUpdate')))
      .finally(() => {
        if (activityAddedHandler) {
          activityAddedHandler();
        } else {
          router.push('/dashboard/fitness/exercise', 'back', 'pop', { unmount: true });
        }
      });
  };

  return (
    <>
      <Box gap={2} display={'flex'} flexDirection={'column'} margin={theme.spacing(3, 0)}>
        <Box display={lowerCaseTitle ? 'block' : 'none'}>
          <CustomInput
            fullWidth
            value={activity.name}
            alternativeBackground
            label={`${t('fitness.exercise.labels.name')}`}
            placeholder={`${t('fitness.exercise.placeholders.egRunning')}`}
            handleChange={value => handleChange('name', value)}
          />
        </Box>
        <CustomInput
          fullWidth
          type={'number'}
          alternativeBackground
          value={activity.duration}
          placeholder={`${t('measurements.minEg')}`}
          label={`${t('fitness.exercise.labels.duration')}`}
          handleChange={value => handleChange('duration', value)}
        />
        <CustomInput
          fullWidth
          type={'number'}
          alternativeBackground
          readOnly={!lowerCaseTitle}
          value={Math.round(activity.cal)}
          placeholder={`${t('measurements.calEg')}`}
          label={`${t('fitness.exercise.labels.calories')}`}
          handleChange={value => handleChange('cal', value)}
        />
      </Box>
      <CustomButton
        fullWidth
        color={'primary'}
        variant={'contained'}
        children={t('buttons.add')}
        onClick={handleAddActivity}
        disabled={activity.cal === 0}
      />
    </>
  );
};

export default AddActivityPanel;
