import {
  Collapsible,
  ComponentsLayout,
  CvdRiskLog,
  CvdRiskRanges,
  DownloadIcon,
  FeelsEmpty,
  ModalHeartRiskEU,
  ModalMobile,
  usePrepareInDepthData,
} from '@hdcorner/ui-library';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import InfiniteScrollList from '../../components/InfiniteScrollList';
import PageLayout from '../../components/PageLayout';
import useGetUserData from './hooks/useGetUserData';
import {
  useAddCvdRiskLogMutation,
  useGetCvdRiskLogsQuery,
} from './queries/cvdRiskQueries';
import ModalInDepthDataDL from '../../components/ModalInDepthDataDL';
import useAlert from '../../hooks/useAlert';
import { InfoOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const CvdRiskInDepthData = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { presentSuccess, presentError } = useAlert();

  const [rangesModalOpen, setRangesModalOpen] = useState<boolean>(false);
  const [downloadDataModalOpen, setDownloadDataModalOpen] = useState(false);
  const [heartRiskModalOpen, setHeartRiskModalOpen] = useState<boolean>(false);
  const [cvdRiskParams, setCvdRiskParams] = useState({
    skip: 0,
    limit: 20,
  });

  const useDetails = useGetUserData();

  const [addCvdRiskLog] = useAddCvdRiskLogMutation();

  const {
    data: cvdRiskLogs,
    error: cvdRiskError,
    isFetching: cvdRiskFetching,
  } = useGetCvdRiskLogsQuery({
    ...cvdRiskParams,
  });

  const prepareData = usePrepareInDepthData(cvdRiskLogs);

  useEffect(() => {
    if (cvdRiskError) {
      presentError(t('errors.cvd.fetchLogs'));
    }
  }, [cvdRiskError]);

  const handleAddCvdRisk = async (data: Partial<CvdRiskLog>) => {
    const { logDate, totalChol, sysBloodPressure } = data;

    try {
      await addCvdRiskLog({
        logDate,
        totalChol,
        sysBloodPressure,
      }).unwrap();
      setCvdRiskParams(cvdRiskParams => ({ ...cvdRiskParams, skip: 0 }));
      presentSuccess(t('errors.cvd.logAdded'));
    } catch (e) {
      presentError(t('errors.cvd.errorAddLog'));
    }
  };

  const handleSaveHeartRiskModal = (data: Partial<CvdRiskLog>) => {
    const dataForRequest = {
      logDate: data.logDate,
      totalChol: data.totalChol,
      sysBloodPressure: data.sysBloodPressure,
    };

    handleAddCvdRisk(dataForRequest);
    setHeartRiskModalOpen(false);
  };

  const EmptyData = useMemo(() => {
    return prepareData?.length ? (
      <Typography
        mt={2}
        variant={'body1'}
        fontWeight={theme.typography.fontWeightRegular}
      >
        {t('cvd.text.riskPercentage')}
      </Typography>
    ) : (
      <Box mt={2}>
        <FeelsEmpty />
      </Box>
    );
  }, [prepareData?.length, theme.typography.fontWeightRegular]);

  return (
    <PageLayout
      defaultHref="/dashboard/cvd"
      headerTitle={`${t('headingsTitles.inDepth')}`}
      endButtons={[
        {
          icon: <DownloadIcon />,
          onClick: () => setDownloadDataModalOpen(true),
        },
      ]}
    >
      <ComponentsLayout>
        <Box display={'flex'} alignItems={'center'}>
          <Box>
            <Typography variant={'h1'} fontWeight={theme.typography.fontWeightMedium}>
              {t('headingsTitles.latestUpdates')}
            </Typography>
          </Box>
          <Box>
            <IconButton onClick={() => setRangesModalOpen(true)}>
              <InfoOutlined />
            </IconButton>
          </Box>
        </Box>
        {EmptyData}
        <InfiniteScrollList
          loading={cvdRiskFetching}
          skip={cvdRiskParams.skip}
          count={prepareData.length}
          limit={cvdRiskParams.limit}
          totalCount={cvdRiskLogs?.count}
          onLoadMore={(skip, limit) => setCvdRiskParams({ skip, limit })}
        >
          {prepareData.map((item, index) => {
            const title = parseInt(item.values['Overall Risk'].value);
            return (
              <Box key={index}>
                <Collapsible
                  icon
                  titleSub={'%'}
                  data={item.values}
                  title={title.toString()}
                  progress={item.progress}
                />
              </Box>
            );
          })}
        </InfiniteScrollList>
      </ComponentsLayout>
      <ModalMobile open={heartRiskModalOpen} setOpen={setHeartRiskModalOpen}>
        <ModalHeartRiskEU
          userAge={useDetails.age}
          userGender={useDetails.gender}
          userSmoker={useDetails.smoker || false}
          handleModalSaveClick={handleSaveHeartRiskModal}
        />
      </ModalMobile>
      <ModalInDepthDataDL
        module={'cvd'}
        open={downloadDataModalOpen}
        dismiss={() => setDownloadDataModalOpen(false)}
      />
      <ModalMobile open={rangesModalOpen} setOpen={setRangesModalOpen} height={'auto'}>
        <Box p={2} display={'flex'} flexDirection={'column'} gap={2}>
          <Box>
            <CvdRiskRanges />
          </Box>
          <Box>
            <Typography variant={'body1'} fontWeight={theme.typography.fontWeightRegular}>
              {t('cvd.text.riskRanges')}
            </Typography>
          </Box>
        </Box>
      </ModalMobile>
    </PageLayout>
  );
};

export default CvdRiskInDepthData;
