import * as React from 'react';
import { useEffect, useState } from 'react';
import { ComponentsLayout, Goal, ModalMobile, theme } from '@hdcorner/ui-library';
import ModalRemoveGoal from '../../components/ModalRemoveGoal';
import PageLayout from '../../components/PageLayout';
import {
  useDeleteDiabetesGoalMutation,
  useEditDiabetesGoalMutation,
  useGetBloodGlucoseCurrentMeasurementQuery,
  useGetBloodGlucoseGoalQuery,
  useGetBloodGlucoseStartingMeasurementQuery,
} from './queries/diabetesQueries';
import { useIonRouter } from '@ionic/react';
import useGoalValue from './hooks/useGoalValue';
import useCurrentMeasurement from './hooks/useCurrentMeasurement';
import ModalAddEditGoal from '../../components/ModalAddEditGoal';
import useBloodGlucoseGoalData from './hooks/useBloodGlucoseGoalData';
import useAlert from '../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

const DiabetesGoal = () => {
  const router = useIonRouter();
  const { t } = useTranslation();

  const { presentSuccess, presentError } = useAlert();

  const [startingDate, setStartingDate] = useState<string>('');
  const [openEditGoal, setOpenEditGoal] = useState<boolean>(false);
  const [openRemoveGoal, setOpenRemoveGoal] = useState<boolean>(false);

  const [editGoal] = useEditDiabetesGoalMutation();
  const [deleteGoal] = useDeleteDiabetesGoalMutation();

  const { data: startingMeasurements, error: errorStarting } =
    useGetBloodGlucoseStartingMeasurementQuery(
      { startDate: startingDate },
      { skip: !startingDate },
    );
  const { data: currentMeasurements, error: errorCurrent } =
    useGetBloodGlucoseCurrentMeasurementQuery();
  const { data: bloodGlucoseGoal, error: bloodGlucoseGoalError } =
    useGetBloodGlucoseGoalQuery();

  useEffect(() => {
    if (bloodGlucoseGoalError || errorStarting || errorCurrent) {
      presentError(t('errors.diabetes.errorGlucose.fetchGoal'));
    }
  }, [bloodGlucoseGoalError, errorCurrent, errorStarting]);

  useEffect(() => {
    if (bloodGlucoseGoal && bloodGlucoseGoal.length > 0) {
      const goal = bloodGlucoseGoal[0];
      const start = goal.startDate;
      setStartingDate(start);
    }
  }, [bloodGlucoseGoal]);

  const prepareGoalValue = useGoalValue(bloodGlucoseGoal);
  const prepareCurrentMeasurement = useCurrentMeasurement(currentMeasurements);
  const prepareStartingMeasurement = useCurrentMeasurement(startingMeasurements);

  const prepareData = useBloodGlucoseGoalData(
    prepareGoalValue,
    bloodGlucoseGoal,
    prepareStartingMeasurement,
    prepareCurrentMeasurement,
  );

  const handleClickRemove = () => {
    if (bloodGlucoseGoal?.[0]?._id) {
      deleteGoal({ id: bloodGlucoseGoal?.[0]?._id })
        .unwrap()
        .then(() => {
          setOpenRemoveGoal(false);
          presentSuccess(t('errors.diabetes.successGlucose.goalRemoved'));
          router.push('/dashboard/diabetes', 'back', 'pop');
        })
        .catch(e => {
          console.log(e);
          setOpenRemoveGoal(false);
          presentError(t('errors.diabetes.errorGlucose.removeGoal'));
        });
    }
  };

  const handleRemove = () => {
    setOpenRemoveGoal(true);
  };

  const handleEdit = () => {
    setOpenEditGoal(true);
  };

  const handleSaveClick = (newGoal: string) => {
    if (bloodGlucoseGoal && bloodGlucoseGoal.length > 0) {
      editGoal({
        goal: parseInt(newGoal),
        goalType: 'blood_glucose',
        _id: bloodGlucoseGoal[0]._id,
        startDate: bloodGlucoseGoal[0].createdAt,
      })
        .unwrap()
        .then(() => {
          setOpenEditGoal(false);
          presentSuccess(t('errors.diabetes.successGlucose.goalUpdated'));
        })
        .catch(e => {
          console.log(e);
          setOpenEditGoal(false);
          presentError(t('errors.diabetes.errorGlucose.updateGoal'));
        });
    } else {
      setOpenEditGoal(false);
    }
  };

  return (
    <PageLayout
      headerTitle={'Blood Glucose Goal'}
      defaultHref={'/dashboard/diabetes'}
      headerColor={theme.palette.primary.main}
    >
      <ComponentsLayout display={'flex'} flexDirection={'column'} gap={theme.spacing(2)}>
        <Goal data={prepareData} handleRemove={handleRemove} handleEdit={handleEdit} />
      </ComponentsLayout>
      <ModalMobile open={openRemoveGoal} setOpen={setOpenRemoveGoal}>
        <ModalRemoveGoal
          handleRemoveClick={handleClickRemove}
          handleCancelClick={() => setOpenRemoveGoal(false)}
        />
      </ModalMobile>
      <ModalMobile open={openEditGoal} setOpen={setOpenEditGoal}>
        <ModalAddEditGoal
          goal={prepareGoalValue}
          handleSaveClick={handleSaveClick}
          currentMeasurement={prepareCurrentMeasurement}
          desiredPlaceholder={`126 ${t('measurements.mgdl')}`}
        />
      </ModalMobile>
    </PageLayout>
  );
};

export default DiabetesGoal;
