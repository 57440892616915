import React, { useEffect, useMemo, useState } from 'react';
import { useIonRouter } from '@ionic/react';
import SectionHeading from '../../components/SectionHeading';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import {
  BMIIndicator,
  calculateBMI,
  CalendarModal,
  categoryBMI,
  ChartCard,
  ClickNavCardNutrition,
  dayWeekMonthYearCustom,
  FeelsEmpty,
  LineChart,
  prepareGraphData,
} from '@hdcorner/ui-library';
import {
  useGetUserGoalWeightQuery,
  useGetWeightGraphDataQuery,
} from '../queries/weightQueries';
import ModalSliderWeight from './ModalSliderWeight';
import useTimeframe from '../../../../hooks/useTimeframe';
import { useGetUserDataQuery } from '../../../profile/queries/personalInfoQueries';
import useAlert from '../../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../../../../utils/useConvertJSON';

const ChartWeight = () => {
  const theme = useTheme();
  const router = useIonRouter();
  const { t } = useTranslation();
  const { presentError } = useAlert();

  const web = useMediaQuery(theme.breakpoints.up('md'));

  const [open, setOpen] = useState<boolean>(false);
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
  const [selectedTimeframe, setSelectedTimeframe] = useState<string>('day');
  // Used for updating the params for the query
  const [endParam, setEndParam] = useState<string>('');
  const [startParam, setStartParam] = useState<string>('');

  const timeframe = useTimeframe(selectedTimeframe, endParam, startParam);

  const { data: userData, error: userError } = useGetUserDataQuery();
  const { data: weightGoalRes, error: goalError } = useGetUserGoalWeightQuery();
  const { data: graphData, error: graphError } = useGetWeightGraphDataQuery({
    ...timeframe,
  });

  useEffect(() => {
    if (userError) {
      presentError(t('errors.generic.error01'));
    }
    if (goalError) {
      presentError(t('errors.fitness.wellness.errorWeightGoalFetch'));
    }
    if (graphError) {
      presentError(t('errors.fitness.wellness.errorWeightGraphFetch'));
    }
  }, [userError, goalError, graphError]);

  const weightData = prepareGraphData(graphData, 'weight');

  const goalWeight = useMemo(() => {
    if (!weightGoalRes || weightGoalRes.length === 0) return `- ${t('measurements.kg')}`;

    return `${weightGoalRes[0].goal.measurement} ${t('measurements.kg')}`;
  }, [weightGoalRes]);

  const userHeight = useMemo(() => {
    if (!userData) return 0;
    return userData[0].height || 0;
  }, [userData]);

  const userWeight = useMemo(() => {
    if (!userData) return 0;
    return userData[0].weight || 0;
  }, [userData]);

  const BMI = calculateBMI(userHeight, userWeight);

  const handleClickGoal = () => {
    if (!weightGoalRes || weightGoalRes.length === 0) {
      setOpen(true);
    } else {
      router.push('/dashboard/fitness/wellness/weight/goal');
    }
  };

  const handleDateChange = (dateRange: string[]) => {
    setStartParam(dateRange[0]);
    setEndParam(dateRange[1]);
  };

  return (
    <Box display={'flex'} flex={1} flexDirection={'column'} mt={theme.spacing(2)}>
      <Box mb={web ? 1 : 0} display={web ? 'block' : 'none'}>
        <SectionHeading hasGoal={false} heading={t('userDetails.weight')} />
      </Box>
      <Box>
        <ChartCard
          setOpen={setCalendarOpen}
          value={selectedTimeframe}
          unit={t('measurements.kg')}
          setValue={setSelectedTimeframe}
          headings={useConvertJSON(dayWeekMonthYearCustom).slice(1)}
          amount={userWeight ? userWeight.toFixed(2).toString() : '-'}
          BMIIcon={
            BMI ? (
              <BMIIndicator bmi={BMI.toString()} weight={categoryBMI(Number(BMI))} />
            ) : null
          }
        >
          {!weightData.length || !weightData ? (
            <FeelsEmpty />
          ) : (
            <LineChart height={200} chartData={weightData} colors={['#C41168']} />
          )}
        </ChartCard>
      </Box>
      <Box mt={2} display={web ? 'none' : 'block'}>
        <ClickNavCardNutrition
          headings={t('buttons.navigation.inDepth')}
          onClick={() => router.push('/dashboard/fitness/wellness/in-depth/weight')}
        />
        <ClickNavCardNutrition
          body={goalWeight}
          onClick={handleClickGoal}
          headings={t('buttons.navigation.goal')}
        />
      </Box>
      <ModalSliderWeight log={false} open={open} setOpen={setOpen} />
      <CalendarModal
        open={calendarOpen}
        setOpen={setCalendarOpen}
        saveDate={handleDateChange}
        selectedDate={[startParam, endParam]}
      />
    </Box>
  );
};

export default ChartWeight;
