import { Box, useTheme } from '@mui/material';
import React, { FC, useMemo } from 'react';
import LineChart from './charts/LineChart';
import { ChartDataType } from '../types/ChartDataType';
import RiskIdealPercentCard from './cards/RiskIdealPercentCard';
import FeelsEmpty from './FeelsEmpty';

type CvdRiskChartWithRiskType = {
  chartData: ChartDataType[];
  data: { idealRisk: string; overallRisk: string };
};
const CvdRiskChartWithRisk: FC<CvdRiskChartWithRiskType> = ({ data, chartData }) => {
  const theme = useTheme();

  const riskStateValue = useMemo(() => {
    const score = data.overallRisk.split('%')[0];

    if (Number(score) < 1) {
      return 'ideal';
    }
    if (Number(score) < 10) {
      return 'warning';
    }
    return 'danger';
  }, [data.overallRisk]);

  return (
    <Box
      display={'flex'}
      borderRadius={'8px'}
      flexDirection={'column'}
      padding={theme.spacing(2)}
      marginTop={theme.spacing(1)}
      bgcolor={
        theme.palette.mode === 'dark'
          ? theme.palette.secondary.main
          : theme.palette.secondary.light
      }
    >
      {chartData.length > 0 && (
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <RiskIdealPercentCard
            percent={data.overallRisk}
            riskIdealState={riskStateValue}
          />
        </Box>
      )}
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        marginTop={theme.spacing(3)}
      >
        {chartData.length === 0 && <FeelsEmpty />}
        {chartData.length > 0 && <LineChart chartData={chartData} />}
      </Box>
    </Box>
  );
};

export default CvdRiskChartWithRisk;
