import React, { FC } from 'react';
import { TypographyProps } from '@mui/material/Typography/Typography';
import { Avatar, AvatarProps, Box, BoxProps, styled, Typography } from '@mui/material';
import GroupChatIcon from '../../assets/GroupChatIcon';
import { useTranslation } from 'react-i18next';

const MessageBox = styled(Box, {
  shouldForwardProp: prop =>
    prop !== 'unread' && prop !== 'selected' && prop !== 'web' && prop !== 'collapsed',
})<BoxProps & { unread: boolean; selected: boolean; web: boolean; collapsed: boolean }>(
  ({ theme, ...props }) => ({
    flexGrow: 1,
    display: 'flex',
    boxSizing: 'border-box',
    alignItems: 'space-between',
    width: props.collapsed ? '72px' : undefined,
    padding: !props.unread ? theme.spacing(2) : theme.spacing(2, 2, 2, 1.5),
    backgroundColor: !props.selected
      ? theme.palette.mode === 'dark'
        ? theme.palette.background.default
        : theme.palette.secondary.light
      : theme.palette.mode === 'dark'
      ? theme.palette.kmColorsDarkGrey.main
      : theme.palette.secondary.main,
    borderLeft: !props.unread
      ? 'none'
      : theme.palette.mode === 'dark'
      ? `${theme.spacing(0.5)} solid ${theme.palette.kmColorsTurquoise.main}`
      : `${theme.spacing(0.5)} solid ${theme.palette.error.main}`,
    borderBottom:
      !props.selected && props.web
        ? theme.palette.mode === 'dark'
          ? `1px solid ${theme.palette.kmColorsDarkGrey.main}`
          : `1px solid ${theme.palette.secondary.main}`
        : `1px solid transparent`,
  }),
);

const CustomAvatar = styled(Avatar, {
  shouldForwardProp: prop => prop !== 'web' && prop !== 'collapsed' && prop !== 'group',
})<AvatarProps & { web: boolean; collapsed: boolean; group: boolean }>(
  ({ theme, ...props }) => ({
    borderRadius: '4px',
    boxSizing: 'border-box',
    width: props.web ? '40px' : '48px',
    height: props.web ? '40px' : '48px',
    border:
      theme.palette.mode === 'dark'
        ? `1px solid ${theme.palette.primary.main}`
        : `1px solid ${theme.palette.secondary.main}`,
    marginRight: props.collapsed ? 0 : theme.spacing(1.25),
    backgroundColor: props.group ? theme.palette.error.main : undefined,
  }),
);

const UserTypography = styled(Typography, {
  shouldForwardProp: prop => prop !== 'web' && prop !== 'collapsed',
})<TypographyProps & { web: boolean; collapsed: boolean }>(({ theme, ...props }) => ({
  fontWeight: props.web
    ? theme.typography.fontWeightRegular
    : theme.typography.fontWeightMedium,
  fontSize: props.web
    ? theme.typography.subtitle1.fontSize
    : theme.typography.subtitle2.fontSize,
  display: props.collapsed ? 'none' : 'block',
  marginBottom: props.web ? theme.spacing(0.75) : theme.spacing(0.5),
  color: props.web
    ? theme.palette.mode === 'dark'
      ? theme.palette.kmColorsBgText.main
      : theme.palette.primary.main
    : theme.palette.kmColorsDarkGrey.main,
}));

const PreviewTypography = styled(Typography, {
  shouldForwardProp: prop => prop !== 'web' && prop !== 'collapsed' && prop !== 'unread',
})<TypographyProps & { web: boolean; unread: boolean; collapsed: boolean }>(
  ({ theme, ...props }) => ({
    maxWidth: '200px',
    overflow: 'hidden',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontWeight: props.unread
      ? theme.typography.fontWeightBold
      : props.web
      ? theme.typography.subtitle2.fontWeight
      : theme.typography.fontWeightRegular,
    fontSize: props.web
      ? theme.typography.subtitle2.fontSize
      : theme.typography.body1.fontSize,
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.primary.main
        : props.web || !props.unread
        ? theme.palette.kmColorsCoolGrey.main
        : theme.palette.primary.main,
    display: props.collapsed ? 'none' : 'block',
    marginBottom: props.web && props.unread ? theme.spacing(0.5) : 0,
  }),
);

const DateTypography = styled(Typography, {
  shouldForwardProp: prop => prop !== 'web' && prop !== 'collapsed',
})<TypographyProps & { web: boolean; collapsed: boolean }>(({ theme, ...props }) => ({
  fontWeight: props.web
    ? theme.typography.body1.fontSize
    : theme.typography.fontWeightRegular,
  display: props.collapsed ? 'none' : 'block',
  marginTop: props.web ? 0 : theme.spacing(1),
  color: props.web
    ? theme.palette.mode === 'dark'
      ? theme.palette.kmColorsBgText.main
      : theme.palette.primary.main
    : theme.palette.kmColorsCoolGrey.main,
}));

const MessageTypography = styled(Typography, {
  shouldForwardProp: prop => prop !== 'collapsed' && prop !== 'web' && prop !== 'unread',
})<TypographyProps & { collapsed: boolean; web: boolean; unread: boolean }>(
  ({ collapsed, web, unread, theme }) => ({
    display: collapsed ? 'none' : web && unread ? 'block' : 'none',
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.kmColorsTurquoise.main
        : theme.palette.error.main,
  }),
);

type ChatMessageSendersProps = {
  date: string;
  web: boolean;
  title: string;
  avatar?: string;
  message: string;
  group?: boolean;
  selected?: boolean;
  collapsed?: boolean;
  unreadMessages?: number;
};
const ChatMessageSenders: FC<ChatMessageSendersProps> = ({
  web,
  date,
  title,
  avatar,
  message,
  unreadMessages = 0,
  selected = false,
  collapsed = false,
  group = false,
}) => {
  const { t } = useTranslation();

  return (
    <MessageBox
      web={web}
      selected={selected}
      collapsed={collapsed}
      unread={unreadMessages > 0}
    >
      <Box
        flexGrow={1}
        display={'flex'}
        justifyContent={'flex-start'}
        alignItems={web ? 'flex-start' : 'center'}
      >
        <CustomAvatar
          web={web}
          alt={title}
          group={group}
          collapsed={collapsed}
          src={!group ? avatar : undefined}
        >
          {group ? <GroupChatIcon /> : title.charAt(0).toUpperCase()}
        </CustomAvatar>
        <Box>
          <UserTypography web={web} collapsed={collapsed}>
            {title}
          </UserTypography>
          <PreviewTypography web={web} unread={unreadMessages > 0} collapsed={collapsed}>
            {message}
          </PreviewTypography>
          <MessageTypography
            web={web}
            variant={'body1'}
            collapsed={collapsed}
            unread={unreadMessages > 0}
          >
            {unreadMessages}{' '}
            {unreadMessages > 1 ? t('chat.titles.messages') : t('chat.titles.message')}
          </MessageTypography>
        </Box>
      </Box>
      <DateTypography web={web} variant={'body1'} collapsed={collapsed}>
        {date}
      </DateTypography>
    </MessageBox>
  );
};

export default ChatMessageSenders;
