import api from '../../../../redux/api';
import moment from 'moment';
import { ApiPagination, PaginationResult, SleepLog } from '@hdcorner/ui-library';

const sleepQueries = api.injectEndpoints({
  endpoints: build => ({
    getSleepLogs: build.query<
      PaginationResult<SleepLog>,
      { params: ApiPagination; start?: string; end?: string }
    >({
      query: ({ params, start, end }) => ({
        method: 'GET',
        params: { ...params, start, end },
        url: '/database/function/getSleepLogs',
      }),
      providesTags: ['SleepLog'],
      serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, otherArgs) => {
        if (otherArgs.arg.params.skip === 0) {
          currentCache.documents = newItems?.documents;
        } else {
          currentCache?.documents.push(...newItems?.documents);
        }
        currentCache.count = newItems?.count;
      },
      forceRefetch: ({ previousArg, currentArg }) => {
        return (
          previousArg?.end !== currentArg?.end ||
          previousArg?.start !== currentArg?.start ||
          previousArg?.params.sort !== currentArg?.params.sort ||
          previousArg?.params.skip !== currentArg?.params.skip ||
          previousArg?.params.limit !== currentArg?.params.limit
        );
      },
    }),

    getTodayLastSleepLog: build.query<
      PaginationResult<SleepLog>,
      { fromDevice: boolean } | void
    >({
      query: params => ({
        method: 'GET',
        url: '/database/function/getSleepLogs',
        params: {
          skip: 0,
          limit: 100,
          sort: '-createdAt',
          fromDevice: params?.fromDevice,
          end: moment().endOf('day').toISOString(),
          start: moment().startOf('day').toISOString(),
        },
      }),
      providesTags: ['SleepLog'],
    }),

    createSleepLog: build.mutation<{ result: SleepLog }, { params: Partial<SleepLog> }>({
      query: ({ params }) => ({
        method: 'POST',
        body: { ...params },
        url: '/database/function/createSleepLog',
      }),
      invalidatesTags: ['SleepLog'],
    }),

    updateSleepLog: build.mutation<
      { result: SleepLog },
      { _id: string; quantity: number }
    >({
      query: ({ _id, quantity }) => ({
        method: 'PATCH',
        body: { quantity },
        url: `/database/UserSleepLog/${_id}`,
      }),
      invalidatesTags: ['SleepLog'],
    }),

    getSleepGraphData: build.query<
      { graphData: SleepLog[]; total?: number; avg?: number },
      { timezone: string; start?: string; end?: string; timeframe?: string }
    >({
      query: ({ start, end, timeframe, timezone }) => ({
        method: 'GET',
        url: '/hdCornerService/user/sleep/graph',
        params: { start, end, timeframe, timezone },
      }),
    }),
  }),
});

export const {
  useGetSleepLogsQuery,
  useCreateSleepLogMutation,
  useUpdateSleepLogMutation,
  useGetSleepGraphDataQuery,
  useGetTodayLastSleepLogQuery,
  useLazyGetTodayLastSleepLogQuery,
} = sleepQueries;

export default sleepQueries;
