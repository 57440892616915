export const calculateLogs = (object: any, key: string) => {
  if (object && object.documents && Array.isArray(object.documents)) {
    const total = object.documents.map((it: any) => it[key]);
    return total.reduce((sum: number, current: number) => sum + current, 0);
  }
  return 0;
};

export const calculateActivityLogs = (object: any, key: string) => {
  if (object && Array.isArray(object)) {
    const total = object.map((it: any) => it[key]);
    return total.reduce((sum: number, current: number) => sum + current, 0);
  }
  return 0;
};
