import { Box, styled, Typography, TypographyProps } from '@mui/material';
import { CustomButton, theme, ToggleCard } from '@hdcorner/ui-library';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.main,
}));

type Props = {
  handleLogMeal: (value: string) => void;
};
const SelectMealModal: FC<Props> = ({ handleLogMeal }) => {
  const { t } = useTranslation();

  const [selectedType, setSelectedType] = useState<string>('breakfast');

  const types = [
    { label: t('fitness.nutrition.labels.breakfast'), value: 'breakfast' },
    { label: t('fitness.nutrition.labels.lunch'), value: 'lunch' },
    { label: t('fitness.nutrition.labels.dinner'), value: 'dinner' },
    { label: t('fitness.nutrition.labels.snack'), value: 'snack' },
  ];

  return (
    <Box p={3} display={'flex'} flexDirection={'column'} gap={2}>
      <Title variant={'h1'}>{t('fitness.nutrition.labels.selectMeal')}</Title>
      <Box>
        {types.map(type => (
          <ToggleCard
            value={type.value}
            label={type.label}
            textTransform={'none'}
            setSelected={setSelectedType}
            selected={selectedType === type.value}
            backgroundColor={theme.palette.secondary.light}
          />
        ))}
      </Box>
      <CustomButton
        fullWidth
        color={'primary'}
        variant={'contained'}
        children={t('buttons.logMeal')}
        onClick={() => handleLogMeal(selectedType)}
      />
    </Box>
  );
};

export default SelectMealModal;
