import { Box, IconButton, Typography, useMediaQuery } from '@mui/material';
import {
  BrowseFiles,
  CustomButton,
  CustomDropzoneArea,
  DropdownMenu,
  PhotosAndVideos,
  theme,
} from '@hdcorner/ui-library';
import RemoveIcon from '../../../assets/icons/RemoveIcon';
import { fileCategories } from '../constants';
import React, { FC } from 'react';
import { bytesToSize, decideFileType } from '../utils';
import { useCreateUserRecordMutation } from '../queries/recordsQueries';
import useAlert from '../../../hooks/useAlert';
import { takePhoto } from '../../../utils/CameraSystem';
import { createFiles, pickFiles } from '../../../utils/FilePicker';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../../../utils/useConvertJSON';

type Props = {
  dismissModal: () => void;
};
const UploadFileModal: FC<Props> = ({ dismissModal }) => {
  const { t } = useTranslation();
  const { presentError, presentSuccess } = useAlert();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const [attachedFiles, setAttachedFiles] = React.useState<File[]>([]);
  const [selectedCategory, setSelectedCategory] = React.useState<string>('');

  const [createRecord] = useCreateUserRecordMutation();

  const handleNewFiles = (files: File[]) => {
    setAttachedFiles([...attachedFiles, ...files]);
  };

  const handleRemoveFile = (fileIndex: number) => {
    const newAttachedFiles = attachedFiles.filter((file, index) => index !== fileIndex);
    setAttachedFiles(newAttachedFiles);
  };

  const handleUpload = async () => {
    const filesToUpload = attachedFiles.map(file => {
      return {
        type: file.type,
        fileName: file.name,
        fileSize: file.size,
        category: selectedCategory,
        fileType: decideFileType(file.type),
      };
    });

    const recordPromises = filesToUpload.map(file => {
      return createRecord({
        fileName: file.fileName,
        fileType: file.fileType,
        category: file.category,
        fileSize: file.fileSize,
      }).unwrap();
    });

    let results = await Promise.all(recordPromises).catch(error => {
      dismissModal();
      console.log(error);
      setAttachedFiles([]);
      setSelectedCategory('');
      presentError(t('errors.records.errorUpload'));
    });

    if (!results) {
      presentError(t('errors.records.errorUpload'));
      return;
    }

    const fileDataPromises = results.map((result, index) => {
      return fetch(result.uploadUrl, {
        method: 'PUT',
        body: attachedFiles[index],
        headers: {
          'x-ms-blob-type': 'BlockBlob',
          'Content-Type': attachedFiles[index].type,
        },
      });
    });

    Promise.all(fileDataPromises)
      .then(() => {
        presentSuccess(t('errors.records.successUpload'));
      })
      .catch(error => {
        console.log(error);
        presentError(t('errors.records.errorUpload'));
      })
      .finally(() => {
        dismissModal();
        setAttachedFiles([]);
        setSelectedCategory('');
      });
  };

  const handleMediaClick = async () => {
    const takenPhotoFile = await takePhoto();
    if (!takenPhotoFile) return;
    setAttachedFiles([...attachedFiles, ...[takenPhotoFile]]);
  };

  const handleFileBrowse = async () => {
    const result = await pickFiles();
    const files = createFiles(result);
    setAttachedFiles([...attachedFiles, ...files]);
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={3} padding={3}>
      <Box>
        <Typography variant={'h1'}>{t('buttons.upload')}</Typography>
      </Box>
      {!mobile && (
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <Box>
            <Typography
              variant={'subtitle2'}
              fontWeight={'fontWeightRegular'}
              color={theme.palette.kmColorsCoolGrey.main}
            >
              {t('records.labels.attach')}
            </Typography>
          </Box>
          <Box height={'160px'}>
            <CustomDropzoneArea
              onFilesSelected={handleNewFiles}
              textUp={`${t('records.text.dragDrop')}`}
              textDown={`${t('records.text.browse')}`}
            />
          </Box>
        </Box>
      )}
      {mobile && (
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <Box>
            <CustomButton
              fullWidth
              onClick={handleMediaClick}
              startIcon={<PhotosAndVideos />}
              style={{
                justifyContent: 'flex-start',
                background: theme.palette.secondary.main,
              }}
            >
              <Typography
                variant={'subtitle1'}
                fontWeight={'fontWeightMedium'}
                color={theme.palette.kmColorsDarkGrey.main}
              >
                {t('records.labels.jpgMp3')}
              </Typography>
            </CustomButton>
          </Box>
          <Box>
            <CustomButton
              fullWidth
              onClick={handleFileBrowse}
              startIcon={<BrowseFiles />}
              style={{
                justifyContent: 'flex-start',
                background: theme.palette.secondary.main,
              }}
            >
              <Typography
                variant={'subtitle1'}
                fontWeight={'fontWeightMedium'}
                color={theme.palette.kmColorsDarkGrey.main}
              >
                {t('records.labels.browse')}
              </Typography>
            </CustomButton>
          </Box>
        </Box>
      )}
      <Box>
        <Box>
          <Typography
            variant={'subtitle2'}
            fontWeight={'fontWeightRegular'}
            color={theme.palette.kmColorsCoolGrey.main}
          >
            {t('records.labels.attached')}
          </Typography>
        </Box>
        <Box>
          {attachedFiles.map((file: File, index) => (
            <Box
              my={1}
              px={2}
              py={1}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              key={`${file.name}-${file.lastModified}}`}
              sx={{
                borderRadius: '8px',
                background: '#F2F1F3',
                border: '1px solid #A5A9B1',
              }}
            >
              <Box>
                <Typography>{file.name}</Typography>
              </Box>
              <Box display={'flex'} alignItems={'center'}>
                <Typography>{bytesToSize(file.size)}</Typography>
                <IconButton onClick={() => handleRemoveFile(index)}>
                  <RemoveIcon />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Box>
        <DropdownMenu
          fullWidth
          value={selectedCategory}
          setValue={setSelectedCategory}
          menuItems={useConvertJSON(fileCategories)}
          placeholder={`${t('records.labels.selectCat')}`}
        />
      </Box>
      <Box textAlign={'center'} px={5}>
        <CustomButton
          fullWidth
          variant={'contained'}
          onClick={handleUpload}
          disabled={attachedFiles.length === 0 || selectedCategory === ''}
        >
          {t('buttons.upload')}
        </CustomButton>
      </Box>
    </Box>
  );
};

export default UploadFileModal;
