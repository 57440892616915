import moment from 'moment';

const formatHourTime = (dateTime: string) => {
  const time = dateTime.split('T')[1];
  const hours = time.split(':')[0];
  const minutes = time.split(':')[1];
  const hoursNum = parseInt(hours);

  if (hoursNum === 12) {
    return `${hours}:${minutes} PM`;
  } else if (hoursNum > 12) {
    const newHours = hoursNum - 12;

    if (newHours < 9) {
      return `0${newHours.toString()}:${minutes} PM`;
    } else {
      return `${newHours.toString()}:${minutes} PM`;
    }
  } else {
    return `${hours}:${minutes} AM`;
  }
};

const findUpdatedAt = (updatedAt: string | undefined) => {
  if (!updatedAt) return { translation: '', time: '-' };

  const inputDate = moment(updatedAt);
  const today = moment().startOf('day');
  const yesterday = moment().subtract(1, 'day').startOf('day');

  if (inputDate.isSame(today, 'd')) {
    // If it's today, return the time
    return {
      translation: 'general.labels.todayAt',
      time: ` ${inputDate.utc(false).format('HH:mm')}`,
    };
  } else if (inputDate.isSame(yesterday, 'd')) {
    // If it's yesterday, return 'Yesterday' and the time
    return {
      translation: 'general.labels.yesterdayAt',
      time: ` ${inputDate.utc(false).format('HH:mm')}`,
    };
  } else {
    // Otherwise, return the date and time
    return {
      translation: '',
      time: `${inputDate.utc(false).format('MMM DD, YYYY [at] HH:mm')}`,
    };
  }
};

const convertDateFormat = (date: string) => {
  const splitDateTime = date.split('T');
  const splitDate = splitDateTime[0].split('-');
  const displayDate = splitDate[2] + '/' + splitDate[1] + '/' + splitDate[0];

  return displayDate;
};

const convertTimeFormat = (date: string) => {
  const splitDateTime = date.split('T');
  const splitTime = splitDateTime[1].split(':');
  const displayTime = splitTime[0] + ':' + splitTime[1];

  return displayTime;
};

const secondsToMinutes = (secs: number) => {
  if (typeof secs !== 'number' || secs === undefined) return 0;

  const minutes = Math.floor(secs / 60);
  const remainingSeconds = secs % 60;

  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');

  return `${formattedMinutes}:${formattedSeconds}`;
};

const hoursAndMinutes = (mins: number) => {
  const hours = Math.floor(mins / 60);
  const minutes = mins % 60;

  if (hours > 0) {
    return `${hours}hr ${minutes}mins`;
  } else {
    return `${minutes}mins`;
  }
};

const timeElapsed = (start: string) => {
  const startDate = moment(start);
  const endDate = moment();

  const minsElapsed = endDate.diff(startDate, 'minutes');
  const hoursElapsed = endDate.diff(startDate, 'hours');
  const daysElapsed = endDate.diff(startDate, 'days');
  const weeksElapsed = endDate.diff(startDate, 'weeks');
  const monthsElapsed = endDate.diff(startDate, 'months');

  const output = (num: number, unit: string) => {
    return {
      amount: num,
      unit:
        num === 1
          ? `constants.goals.achievedIn.${unit}`
          : `constants.goals.achievedIn.${unit}s`,
    };
  };

  if (minsElapsed < 60) {
    return output(minsElapsed, 'min');
  } else if (minsElapsed >= 60 && hoursElapsed < 24) {
    return output(hoursElapsed, 'hr');
  } else if (daysElapsed < 7) {
    return output(daysElapsed, 'day');
  } else if (30 > daysElapsed && daysElapsed >= 7) {
    return output(weeksElapsed, 'week');
  } else {
    return output(monthsElapsed, 'month');
  }
};

export {
  timeElapsed,
  findUpdatedAt,
  formatHourTime,
  hoursAndMinutes,
  secondsToMinutes,
  convertDateFormat,
  convertTimeFormat,
};
