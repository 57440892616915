import { fileCategories } from './constants';

const bytesToSize = (bytes: number) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
};

const imageFileTypes = ['jpg', 'png', 'jpeg', 'image/jpeg', 'image/png', 'image/jpg'];

const decideFileType = (fileType: string): 'image' | 'file' => {
  if (fileType && imageFileTypes.includes(fileType)) {
    return 'image';
  }
  return 'file';
};

const extractBase64 = (base64Str: string) => {
  return base64Str.split(';base64,').pop() ?? '';
};

const findCategoryLabel = (category: string) => {
  return (
    fileCategories.find(fileCategory => fileCategory.value === category)?.label ?? ''
  );
};

export { bytesToSize, decideFileType, extractBase64, findCategoryLabel };
