import * as React from 'react';
import { SVGProps } from 'react';
import { useTheme } from '@mui/material';

const MessageBubbleIcon = ({
  fill = useTheme().palette.primary.main,
  ...rest
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={20}
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M11 1.43328e-05C9.46927 -0.000982731 7.9587 0.349463 6.58473 1.02435C5.21077 1.69924 4.01007 2.68054 3.07518 3.89266C2.14028 5.10478 1.49614 6.51535 1.19239 8.01569C0.88863 9.51602 0.933371 11.0661 1.32316 12.5464C1.71295 14.0267 2.43738 15.3978 3.44064 16.5539C4.44389 17.7101 5.69919 18.6205 7.1098 19.215C8.5204 19.8095 10.0487 20.0723 11.5768 19.983C13.105 19.8937 14.5923 19.4548 15.924 18.7L19.684 19.953C19.7862 19.9853 19.8929 20.0011 20 20C20.1584 19.9999 20.3145 19.9622 20.4554 19.8899C20.5964 19.8177 20.7181 19.713 20.8107 19.5845C20.9032 19.456 20.964 19.3074 20.9879 19.1508C21.0117 18.9943 20.9981 18.8343 20.948 18.684L19.7 14.924C20.5644 13.4035 21.0143 11.6827 21.0048 9.93365C20.9953 8.18465 20.5267 6.4688 19.6458 4.95778C18.765 3.44676 17.5028 2.19353 15.9855 1.32348C14.4682 0.453425 12.7491 -0.0029483 11 1.43328e-05ZM17.653 15.121L18.419 17.421L16.119 16.655C15.9787 16.6072 15.8295 16.5913 15.6822 16.6086C15.535 16.6259 15.3935 16.6759 15.268 16.755C13.6002 17.8105 11.6017 18.214 9.65499 17.8881C7.70828 17.5622 5.95013 16.5299 4.71699 14.9887C3.48386 13.4475 2.86235 11.5057 2.97143 9.53494C3.0805 7.56417 3.9125 5.70283 5.30818 4.30715C6.70386 2.91147 8.5652 2.07947 10.536 1.9704C12.5067 1.86132 14.4485 2.48283 15.9897 3.71596C17.5309 4.9491 18.5632 6.70725 18.8891 8.65396C19.215 10.6007 18.8116 12.5992 17.756 14.267C17.6764 14.3927 17.6258 14.5346 17.608 14.6824C17.5902 14.8301 17.6056 14.98 17.653 15.121Z"
      fill={fill}
    />
  </svg>
);

export default MessageBubbleIcon;
