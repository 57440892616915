import * as React from 'react';
import { ComponentsLayout, CustomButton, theme } from '@hdcorner/ui-library';
import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router';
import PageLayout from '../../components/PageLayout';
import { IonImg } from '@ionic/react';
import { useGetMedicineByIdQuery } from './queries/medicationQueries';
import useAlert from '../../hooks/useAlert';
import { useEffect } from 'react';

const MedicationDrugDescription = () => {
  const { presentError } = useAlert();
  const { medicineId } = useParams<{ medicineId: string }>();

  const { error, data: medicine } = useGetMedicineByIdQuery(medicineId);

  useEffect(() => {
    if (error) presentError('An unexpected error occurred fetching medication data');
  }, [error]);

  return (
    <PageLayout
      defaultHref={'/dashboard/medicines'}
      headerTitle={medicine?.medicineName}
      headerColor={theme.palette.primary.main}
    >
      <ComponentsLayout>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          padding={theme.spacing(4, 2)}
        >
          <IonImg src={medicine?.imagePreview as string} />
        </Box>
        <Box marginTop={theme.spacing(2)}>
          <Typography
            variant={'subtitle2'}
            color={theme.palette.primary.main}
            marginBottom={theme.spacing(1)}
            fontWeight={theme.typography.fontWeightRegular}
          >
            Description
          </Typography>
          <CustomButton
            onClick={() => window.open(medicine?.substanceUrl, '_blank')}
            sx={{
              paddingInline: '0px',
              backgroundColor: 'transparent',
              marginTop: theme.spacing(-0.8),
              marginBottom: theme.spacing(0.2),
              '&:hover': { backgroundColor: 'secondary.light' },
              '&:focus': { backgroundColor: 'secondary.light' },
            }}
          >
            <Typography
              variant={'subtitle2'}
              color={theme.palette.medication.main}
              fontWeight={theme.typography.fontWeightRegular}
            >
              {medicine?.substance}
            </Typography>
          </CustomButton>
          <Typography variant={'body1'} color={theme.palette.primary.light}>
            {medicine?.description}
          </Typography>
        </Box>
      </ComponentsLayout>
    </PageLayout>
  );
};

export default MedicationDrugDescription;
