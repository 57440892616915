import { Box, useMediaQuery } from '@mui/material';
import { ComponentsLayout, theme } from '@hdcorner/ui-library';
import HeroImage from '../../../assets/illustrations/HeroImagePNG.png';
import SignUpPanel from './components/SignUpPanel';
import PageLayout from '../../../components/PageLayout';

const SignUp = () => {
  const web = useMediaQuery(theme.breakpoints.up('md'));
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <PageLayout backgroundWhite>
      <Box hidden={mobile} display={'flex'} height={'100%'}>
        <Box
          width={'560px'}
          padding={'16px 0'}
          boxSizing={'border-box'}
          bgcolor={theme.palette.secondary.light}
        >
          <SignUpPanel web />
        </Box>
        <Box
          flexGrow={'1'}
          sx={{
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${HeroImage})`,
          }}
        />
      </Box>
      <Box hidden={web} height={'100%'}>
        <ComponentsLayout height={'100%'}>
          <SignUpPanel web={false} />
        </ComponentsLayout>
      </Box>
    </PageLayout>
  );
};

export default SignUp;
