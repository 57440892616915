import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type DiabetesType = {
  filter: string;
};

const initialState: DiabetesType = {
  filter: 'default',
};

const diabetesSlice = createSlice({
  name: 'diabetes',
  initialState: initialState,
  reducers: {
    updateDiabetesFilter: (state, action: PayloadAction<string>) => {
      state.filter = action.payload;
    },
  },
});

export const { updateDiabetesFilter } = diabetesSlice.actions;

export default diabetesSlice.reducer;
