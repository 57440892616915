import React, { FC } from 'react';
import Modal from '@mui/material/Modal';
import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomBox = styled(Box, {
  shouldForwardProp: prop => prop !== 'transparent',
})<BoxProps & { transparent?: boolean }>(({ theme, transparent }) => ({
  top: '50%',
  left: '50%',
  width: 'auto',
  maxWidth: '90%',
  maxHeight: '90%',
  minWidth: '350px',
  overflow: 'scroll',
  borderRadius: '8px',
  position: 'absolute',
  alignItems: 'center',
  scrollbarWidth: 'none',
  boxSizing: 'border-box',
  transform: 'translate(-50%, -50%)',
  backgroundColor: transparent ? undefined : theme.palette.highlight.main,
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  msOverflowStyle: 'none',
  '::-webkit-scrollbar': {
    display: 'none',
  },
}));

type ModalMobileProps = {
  open: boolean;
  width?: string;
  height?: string;
  handleClose?: () => void;
  children: React.ReactNode;
  transparentBackground?: boolean;
  setOpen: (open: boolean) => void;
};
const ModalMobile: FC<ModalMobileProps> = ({
  open,
  width,
  height,
  setOpen,
  children,
  handleClose,
  transparentBackground = false,
}) => {
  const handleModalClose = () => {
    if (handleClose) handleClose();
    setOpen(false);
  };

  return (
    <Modal open={open} onClose={handleModalClose}>
      <CustomBox
        sx={{ width: width, height: height }}
        transparent={transparentBackground}
      >
        {children}
      </CustomBox>
    </Modal>
  );
};

export default ModalMobile;
