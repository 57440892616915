import SectionHeading from '../../components/SectionHeading';
import { Box } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import WellnessCards from './WellnessCards';
import SleepIcon from '../../../../assets/icons/SleepIcon';
import WeightIcon from '../../../../assets/icons/WeightIcon';
import { useIonRouter } from '@ionic/react';
import { useGetLastWeightLogQuery } from '../queries/weightQueries';
import { useGetUserDataQuery } from '../../../profile/queries/personalInfoQueries';
import { useGetTodayLastSleepLogQuery } from '../queries/sleepQueries';
import { BMIIndicator, calculateBMI, categoryBMI } from '@hdcorner/ui-library';
import useAlert from '../../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

const SectionWellness = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { presentError } = useAlert();

  const { data: userData, error: userError } = useGetUserDataQuery();
  const { data: todayWeightLog, error: weightError } = useGetLastWeightLogQuery();
  const { data: todaySleepLog, error: sleepError } = useGetTodayLastSleepLogQuery();

  useEffect(() => {
    if (userError) {
      presentError(t('errors.generic.error01'));
    }
    if (weightError) {
      presentError(t('errors.fitness.wellness.errorWeightLogFetch'));
    }
    if (sleepError) {
      presentError(t('errors.fitness.wellness.errorSleepLogFetch'));
    }
  }, [userError, weightError, sleepError]);

  const weight = useMemo(() => {
    if (!todayWeightLog || todayWeightLog.documents.length === 0) return '-';
    return todayWeightLog.documents[0].weight.toString();
  }, [todayWeightLog]);

  const sleep = useMemo(() => {
    if (!todaySleepLog || todaySleepLog.documents.length === 0) return '-';
    return todaySleepLog.documents[0].quantity.toFixed(1).toString();
  }, [todaySleepLog]);

  const userHeight = useMemo(() => {
    if (!userData) return 0;
    return userData[0].height || 0;
  }, [userData]);

  const BMI = useMemo(() => {
    if (!todayWeightLog || todayWeightLog.documents.length === 0) return '-';
    return calculateBMI(userHeight, parseFloat(weight));
  }, [todayWeightLog, userHeight, weight]);

  const handleViewMore = () => {
    router.push('/dashboard/fitness/wellness');
  };

  return (
    <Box gap={1} flexGrow={1} width={'100%'} display={'flex'} flexDirection={'column'}>
      <SectionHeading
        hasGoal={false}
        viewMore={handleViewMore}
        heading={t('headingsTitles.wellness')}
      />
      <Box display={'flex'} flexGrow={1} gap={1} flexDirection={'column'}>
        <WellnessCards
          amount={weight}
          color={'#E93C16'}
          icon={<WeightIcon />}
          unit={`${t('measurements.kg')}`}
          title={`${t('fitness.wellness.labels.bodyWeight')}`}
        >
          {BMI && (
            <BMIIndicator
              bmi={BMI}
              weight={BMI === '-' ? categoryBMI(0) : categoryBMI(parseFloat(BMI))}
            />
          )}
        </WellnessCards>
        <WellnessCards
          amount={sleep}
          color={'#7456B6'}
          icon={<SleepIcon />}
          unit={`${t('measurements.hours')}`}
          title={`${t('fitness.wellness.labels.sleepTim')}`}
        />
      </Box>
    </Box>
  );
};

export default SectionWellness;
