import React, { useState } from 'react';
import PageLayout from '../../components/PageLayout';
import { ComponentsLayout } from '@hdcorner/ui-library';
import { useAppSelector } from '../../redux/hooks';
import CircleCounter from './nutrition/components/CircleCounter';
import ModalBase from './components/ModalBase';
import ModalAddFood from './nutrition/components/ModalAddFood';
import CustomMealInner from './nutrition/components/CustomMealInner';
import { useTranslation } from 'react-i18next';

const Fitness = () => {
  const { t } = useTranslation();

  const { foods, mealCategory } = useAppSelector(state => state.mealFoodsList);
  const [open, setOpen] = useState<boolean>(false);

  const handleOpenModal = () => {
    if (foods.length !== 0) {
      setOpen(true);
    }
  };

  return (
    <PageLayout
      defaultHref={`/dashboard/fitness/${mealCategory}`}
      headerTitle={t('fitness.nutrition.labels.customMeal')}
      endButtons={[
        {
          icon: <CircleCounter count={foods.length} />,
          onClick: handleOpenModal,
        },
      ]}
    >
      <ComponentsLayout>
        <CustomMealInner />
      </ComponentsLayout>
      <ModalBase open={open} setOpen={setOpen}>
        <ModalAddFood customMeal handleCloseModal={() => setOpen(false)} />
      </ModalBase>
    </PageLayout>
  );
};

export default Fitness;
