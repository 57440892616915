import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import CustomInput from '../controls/CustomInput';
import ToggleCard from '../cards/ToggleCard';
import CustomButton from '../controls/CustomButton';
import React, { FC, useEffect, useState } from 'react';
import ModalHeading from './ModalHeading';
import { Filters, mealTypeLabel } from '../../types/Filters';
import CalendarModal from './CalendarModal';
import moment from 'moment';
import CustomTabs from '../layout/CustomTabs';
import OcrIcon from '../../assets/OcrIcon';
import { BloodGlucoseLog } from '../../types/BloodGlucoseLog';
import { Hbac1Log } from '../../types/Hbac1Log';
import { useTranslation } from 'react-i18next';

type ModalDiabetesProps = {
  isLoading?: boolean;
  tabView?: boolean;
  isBloodGlucose?: boolean;
  error: { value: boolean; message: string };
  triggerOCR: (cb: (newMeasurement: string) => void) => void;
  handleModalSaveClick: (data: Partial<BloodGlucoseLog & Hbac1Log>) => void;
};
const ModalDiabetes: FC<ModalDiabetesProps> = ({
  isLoading,
  error,
  tabView,
  triggerOCR,
  isBloodGlucose = false,
  handleModalSaveClick,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [date, setDate] = useState<string>(moment().toISOString());
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [measurement, setMeasurement] = useState<string>('');
  const [openPicker, setOpenPicker] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<string>(Filters.DEFAULT);
  const [bloodGlucoseView, setBloodGlucoseView] = useState<boolean>(false);

  useEffect(() => {
    if (tabView) {
      setBloodGlucoseView(true);
    } else {
      setBloodGlucoseView(isBloodGlucose);
    }
  }, [isBloodGlucose, tabView]);

  useEffect(() => {
    if (tabView) {
      if (selectedTab === 0) {
        setBloodGlucoseView(true);
      } else {
        setBloodGlucoseView(false);
      }
    }
  }, [selectedTab, tabView]);

  const handleSaveData = () => {
    const data = {
      logDate: date,
      category: selectedCategory,
      measurement: parseFloat(measurement),
    };
    handleModalSaveClick(data);
  };

  const handleClickOCR = () => {
    triggerOCR(newMeasurement => {
      setMeasurement(newMeasurement);
    });
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2} p={3}>
      {tabView && (
        <Box>
          <CustomTabs
            value={selectedTab}
            setValue={setSelectedTab}
            tabs={[
              {
                label: t('headingsTitles.bloodGluc'),
                children: null,
              },
              {
                label: t('headingsTitles.hba1c'),
                children: null,
              },
            ]}
          />
        </Box>
      )}
      <Box
        sx={{
          filter: isLoading ? 'blur(2px)' : 'none',
          pointerEvents: isLoading ? 'none' : 'auto',
          WebkitFilter: isLoading ? 'blur(2px)' : 'none',
        }}
      >
        <ModalHeading
          align={'left'}
          showCalendar={'flex'}
          color={theme.palette.diabetes.main}
          handleCalendarClick={() => setOpenPicker(true)}
          headingButton={t('constants.toggleValues.dates.customDate')}
          title={
            tabView
              ? ''
              : bloodGlucoseView
              ? t('headingsTitles.bloodGluc')
              : t('headingsTitles.hba1c')
          }
        />
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <CustomInput
              fullWidth
              type={'number'}
              value={measurement}
              error={error.value}
              helperText={error.message}
              handleChange={setMeasurement}
              label={t('general.labels.addMeasurement')}
              suffix={bloodGlucoseView ? t('measurements.mgdl') : '%'}
              placeholder={
                bloodGlucoseView ? `30-600 ${t('measurements.mgdl')}` : '4-20%'
              }
            />
          </Box>
          {bloodGlucoseView && (
            <Box>
              {[
                Filters.DEFAULT,
                Filters.BEFORE_MEAL,
                Filters.WITH_MEAL,
                Filters.AFTER_MEAL,
                Filters.SPORTS,
                Filters.ILL,
                Filters.SPECIAL,
              ].map(value => (
                <ToggleCard
                  key={value}
                  value={value}
                  textTransform={'none'}
                  label={mealTypeLabel(value)}
                  setSelected={setSelectedCategory}
                  selected={selectedCategory === value}
                  backgroundColor={theme.palette.secondary.main}
                />
              ))}
            </Box>
          )}

          <Box display={'flex'} justifyContent={'center'} gap={1}>
            <Box flex={1}>
              <CustomButton
                fullWidth
                color={'primary'}
                variant={'contained'}
                onClick={handleSaveData}
                children={t('buttons.saveData')}
                disabled={!bloodGlucoseView ? !measurement : !measurement}
              />
            </Box>
            <Box>
              <CustomButton variant={'outlined'} onClick={handleClickOCR}>
                <OcrIcon />
              </CustomButton>
            </Box>
          </Box>
        </Box>

        <CalendarModal
          open={openPicker}
          selectedDate={date}
          setOpen={setOpenPicker}
          saveDate={date => setDate(date)}
        />
      </Box>
      {isLoading && (
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{
            gap: 4,
            filter: 'none',
            pointerEvents: 'auto',
            WebkitFilter: 'none',
          }}
        >
          <Typography
            align={'left'}
            variant={'subtitle2'}
            sx={{
              color: theme.palette.text.primary,
            }}
          >
            {t('general.text.preparingData')}
          </Typography>
          <CircularProgress size={20} />
        </Box>
      )}
    </Box>
  );
};

export default ModalDiabetes;
