import * as React from 'react';
import { FC } from 'react';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';
import ArrowDown from '../../assets/ArrowDown';
import { CustomCheckbox } from '../controls/CheckBoxesSingle';
import { styled } from '@mui/material/styles';
import ArrowUp from '../../assets/ArrowUp';
import CustomButton from '../controls/CustomButton';
import { BoxProps, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

type HeadCell = {
  _id: string;
  sort?: string;
  label: string;
  align?: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
};

const TableCellHeadings = styled(TableCell)<TableCellProps>(({ theme }) => ({
  flexGrow: '1',
  boxSizing: 'border-box',
  color: theme.palette.primary.light,
  fontSize: theme.typography.body1.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  backgroundColor: theme.palette.secondary.light,
  borderBottomColor: theme.palette.background.default,
  padding: theme.spacing(1, 0, 1, 2.25),
  '.Mui-active': {
    color: theme.palette.primary.light,
  },
  '&:first-of-type': {
    width: '52px',
    borderTopLeftRadius: '8px',
    padding: theme.spacing(1.25, 1.25, 1.25, 2.25),
  },
  '&:nth-of-type(2)': {
    paddingLeft: theme.spacing(0),
  },
  '&:last-of-type': {
    borderTopRightRadius: '8px',
    paddingRight: theme.spacing(2),
  },
}));

const TableCellSelectedStatus = styled(TableCell)<TableCellProps>(({ theme }) => ({
  flexGrow: '1',
  boxSizing: 'border-box',
  borderTopRightRadius: '8px',
  fontSize: theme.typography.body1.fontSize,
  color: theme.palette.kmColorsCoolGrey.main,
  fontWeight: theme.typography.fontWeightBold,
  backgroundColor: theme.palette.secondary.light,
  padding: theme.spacing(1.25, 0),
  borderBottomColor: theme.palette.background.default,
  '&:last-of-type': {
    paddingLeft: theme.spacing(0),
  },
}));

const IndeterminateCheckBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: '18px',
  height: '18px',
  borderRadius: '2px',
  boxSizing: 'border-box',
  border: `2px solid ${theme.palette.kmColorsCoolGrey.main}`,
}));

const MarginBox = styled(Box)<BoxProps>(({ theme }) => ({
  margin: theme.spacing(0, 0, 0, 0.75),
}));

const SortOrderDesc = () => (
  <MarginBox>
    <ArrowDown />
  </MarginBox>
);

const SortOrderAsc = () => (
  <MarginBox>
    <ArrowUp />
  </MarginBox>
);

type Order = 'asc' | 'desc';

type HealthProfTableHeadingProps = {
  order: Order;
  orderBy: string;
  headCells: HeadCell[];
  totalRowsCount: number;
  onCancelClick: () => void;
  totalRowsSelected: number;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
};
const HealthProfTableHeading: FC<HealthProfTableHeadingProps> = ({
  order,
  orderBy,
  headCells,
  onCancelClick,
  onRequestSort,
  totalRowsCount,
  onSelectAllClick,
  totalRowsSelected,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow sx={{ height: '55px' }}>
        <TableCellHeadings align={'center'}>
          {onSelectAllClick && (
            <CustomCheckbox
              onChange={onSelectAllClick}
              indeterminateIcon={<IndeterminateCheckBox />}
              checked={totalRowsCount > 0 && totalRowsSelected === totalRowsCount}
              indeterminate={totalRowsSelected > 0 && totalRowsSelected < totalRowsCount}
            />
          )}
        </TableCellHeadings>
        {totalRowsSelected > 0 && (
          <TableCellSelectedStatus colSpan={6}>
            {totalRowsSelected} users selected
            <CustomButton
              variant={'text'}
              onClick={onCancelClick}
              sx={{
                color: theme.palette.kmColorsRed.main,
                fontSize: theme.typography.body1.fontSize,
                fontWeight: theme.typography.fontWeightBold,
              }}
            >
              {t('buttons.cancel')}
            </CustomButton>
          </TableCellSelectedStatus>
        )}
        {totalRowsSelected === 0 &&
          headCells.map(headCell => (
            <TableCellHeadings
              key={headCell._id}
              align={headCell.align}
              sortDirection={orderBy === headCell._id ? order : false}
            >
              {!headCell.sort ? (
                headCell.label
              ) : (
                <TableSortLabel
                  active={orderBy === headCell._id}
                  direction={orderBy === headCell._id ? order : 'asc'}
                  onClick={createSortHandler(headCell._id)}
                  IconComponent={
                    orderBy === headCell._id
                      ? order === 'asc'
                        ? SortOrderAsc
                        : SortOrderDesc
                      : SortOrderDesc
                  }
                >
                  {headCell.label}
                  {orderBy === headCell._id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              )}
            </TableCellHeadings>
          ))}
      </TableRow>
    </TableHead>
  );
};

export default HealthProfTableHeading;
