import PageLayout from '../../components/PageLayout';
import * as React from 'react';
import { CSSProperties, useEffect, useState } from 'react';
import {
  AllMeasurements,
  CalendarModal,
  ChartTypeButtons,
  ComponentsLayout,
  dayWeekMonthYearCustom,
  FeelsEmpty,
  LineChart,
  MeasurementsContainer,
  ModalFilters,
  ScatterChart,
  theme,
  ToggleButtons,
  usePreparePressureGraph,
  usePreparePressureScatterXy,
  usePreparePulsesGraph,
} from '@hdcorner/ui-library';
import InDepthDatePicker from '../../components/InDepthDatePicker';
import useCategoryLabel from './hooks/useCategoryLabel';
import { MeasurementOptions } from './constants';
import Box from '@mui/material/Box';
import ToggleHalfRectangleIcon from '../../assets/icons/ToggleHalfRectangleIcon';
import { styled, Typography, TypographyProps } from '@mui/material';
import {
  useGetPressureGraphDataQuery,
  useGetPulsesGraphDataQuery,
} from './queries/hypertensionQueries';
import useTimeframe from '../../hooks/useTimeframe';
import LinearGraphIcon from '../../assets/icons/LinearGraphIcon';
import useAlert from '../../hooks/useAlert';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../../utils/useConvertJSON';

const iconsPressure = [
  { name: 'lineChart', icon: LinearGraphIcon },
  { name: 'scatterChart', icon: ToggleHalfRectangleIcon },
];

const iconsPulses = [{ name: 'lineChart', icon: LinearGraphIcon }];

const GraphContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderRadius: '8px',
  flexDirection: 'column',
  backgroundColor: 'white',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
}));

const GraphTypography = styled(Typography, {
  shouldForwardProp: propName => propName !== 'dotColor',
})<TypographyProps & { dotColor?: CSSProperties['color'] }>(({ dotColor, theme }) => ({
  position: 'relative',
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightRegular,
  '&::before': {
    top: '50%',
    width: '8px',
    height: '8px',
    left: '-16px',
    content: '" "',
    borderRadius: '50%',
    position: 'absolute',
    transform: 'translateY(-50%)',
    backgroundColor: dotColor ? dotColor : '#222222',
  },
}));

const HypertensionGraphDataPage = () => {
  const { presentError } = useAlert();
  const { t } = useTranslation();

  const [selectedCategory, setSelectedCategory] = useState<string>('all');
  const [calendarModalOpen, setCalendarModalOpen] = useState<boolean>(false);
  const [categorySelectModal, setCategorySelectModal] = useState<boolean>(false);

  //for pressure
  const [iconTogglePressure, setIconTogglePressure] = useState<string>(
    iconsPressure[0].name,
  );
  const [dayTogglePressure, setDayTogglePressure] = useState<string>('all');
  //for pulses
  const [dayTogglePulses, setDayTogglePulses] = useState<string>('all');
  const [iconTogglePulses, setIconTogglePulses] = useState<string>(iconsPressure[0].name);

  // Used for updating the params for the query
  const [endParam, setEndParam] = useState<string>('');
  const [startParam, setStartParam] = useState<string>('');

  const timeframePressure = useTimeframe(dayTogglePressure, startParam, endParam);
  const timeframePulses = useTimeframe(dayTogglePulses, startParam, endParam);

  const { data: graphDataPressure, error: errorGraphPressure } =
    useGetPressureGraphDataQuery({
      ...timeframePressure,
    });

  const { data: graphDataPulses, error: errorGraphPulses } = useGetPulsesGraphDataQuery({
    ...timeframePulses,
  });

  useEffect(() => {
    if (errorGraphPulses || errorGraphPressure)
      presentError(t('errors.hypertension.errorGraphFetch'));
  }, [errorGraphPulses, errorGraphPressure]);

  const scatterXy = usePreparePressureScatterXy(graphDataPressure);
  const prepareGraphDataPulses = usePreparePulsesGraph(graphDataPulses);
  const prepareGraphDataPressure = usePreparePressureGraph(graphDataPressure);

  console.log(scatterXy);

  const categoryLabel = useCategoryLabel(selectedCategory);
  const handleDateChange = (dateRange: string[]) => {
    setDayTogglePressure('custom');
    setDayTogglePulses('custom');
    setStartParam(dateRange[0]);
    setEndParam(dateRange[1]);
  };

  const filteredToggle = dayWeekMonthYearCustom.filter(item => item.value !== 'custom');

  return (
    <PageLayout
      defaultHref={'/dashboard/hypertension'}
      headerTitle={t('buttons.navigation.graphData')}
    >
      <ComponentsLayout display={'flex'} flexDirection={'column'} gap={theme.spacing(1)}>
        <MeasurementsContainer>
          <AllMeasurements
            flex={2}
            text={categoryLabel}
            openModal={() => setCategorySelectModal(true)}
          />
          <InDepthDatePicker flex={1} onClick={() => setCalendarModalOpen(true)} />
        </MeasurementsContainer>
        <GraphContainer>
          <Box display={'flex'} justifyContent={'space-between'}>
            <ToggleButtons
              value={dayTogglePressure}
              handleChange={setDayTogglePressure}
              headings={useConvertJSON(filteredToggle)}
            />
            <ChartTypeButtons
              icons={iconsPressure}
              iconValue={iconTogglePressure}
              setIconValue={setIconTogglePressure}
            />
          </Box>
          {prepareGraphDataPressure.length > 0 && iconTogglePressure === 'lineChart' && (
            <LineChart
              height={'250px'}
              chartData={prepareGraphDataPressure}
              colors={[theme.palette.error.main, theme.palette.warning.main]}
            />
          )}
          {prepareGraphDataPressure.length > 0 &&
            iconTogglePressure === 'scatterChart' && (
              <ScatterChart
                height={'250px'}
                chartData={scatterXy}
                axisTitles={{
                  xAxis: t('dashboard.dashcards.hypertension.systolic'),
                  yAxis: t('dashboard.dashcards.hypertension.diastolic'),
                }}
                colorList={[theme.palette.error.main, theme.palette.warning.main]}
              />
            )}
          {prepareGraphDataPressure.length === 0 && <FeelsEmpty />}
          {iconTogglePressure === 'lineChart' && (
            <Box
              display={'flex'}
              alignItems={'center'}
              gap={theme.spacing(3)}
              justifyContent={'center'}
              paddingLeft={theme.spacing(2)}
            >
              <GraphTypography dotColor={theme.palette.warning.main}>
                {t('hypertension.labels.systolic')}
              </GraphTypography>
              <GraphTypography dotColor={theme.palette.error.main}>
                {t('hypertension.labels.diastolic')}
              </GraphTypography>
            </Box>
          )}
        </GraphContainer>
        <GraphContainer>
          <Box display={'flex'} justifyContent={'space-between'}>
            <ToggleButtons
              value={dayTogglePulses}
              handleChange={setDayTogglePulses}
              headings={useConvertJSON(filteredToggle)}
            />
            <ChartTypeButtons
              icons={iconsPulses}
              iconValue={iconTogglePulses}
              setIconValue={setIconTogglePulses}
            />
          </Box>
          {prepareGraphDataPulses.length > 0 && iconTogglePulses === 'lineChart' && (
            <LineChart
              height={'250px'}
              chartData={prepareGraphDataPulses}
              colors={[theme.palette.warning.main]}
            />
          )}
          {prepareGraphDataPulses.length === 0 && <FeelsEmpty />}
          <Box
            display={'flex'}
            alignItems={'center'}
            gap={theme.spacing(3)}
            justifyContent={'center'}
            paddingLeft={theme.spacing(2)}
          >
            <GraphTypography dotColor={theme.palette.warning.main}>
              {t('dashboard.dashcards.hypertension.pulses')}
            </GraphTypography>
          </Box>
        </GraphContainer>
      </ComponentsLayout>
      <ModalFilters
        open={categorySelectModal}
        selected={selectedCategory}
        options={useConvertJSON(MeasurementOptions)}
        dismiss={(newValue: string) => {
          setSelectedCategory(newValue);
          setCategorySelectModal(false);
        }}
      />
      <CalendarModal
        open={calendarModalOpen}
        saveDate={handleDateChange}
        setOpen={setCalendarModalOpen}
        selectedDate={[startParam, endParam]}
      />
    </PageLayout>
  );
};
export default HypertensionGraphDataPage;
