import { styled, Typography, TypographyProps } from '@mui/material';
import { theme } from '@hdcorner/ui-library';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const TypographyHeading = styled(Typography, {
  shouldForwardProp: propName => propName !== 'lang',
})<TypographyProps & { lang: string }>(({ lang }) => ({
  marginBottom: theme.spacing(1),
  color: theme.palette.primary.light,
  fontWeight: theme.typography.fontWeightBold,
  textTransform: lang === 'el' ? 'capitalize' : 'uppercase',
}));

type Props = {
  text: string;
};

const ProfileTypographyHeading: FC<Props> = ({ text }) => {
  const { t } = useTranslation();
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  return (
    <TypographyHeading lang={lang} variant={'subtitle2'}>
      {t(text)}
    </TypographyHeading>
  );
};

export default ProfileTypographyHeading;
