import { useMemo } from 'react';

const useMetric = (
  value: string,
): 'all' | 'lpa' | 'hdl' | 'ldl' | 'triglycerides' | 'totalChol' => {
  return useMemo(() => {
    switch (value.toLowerCase()) {
      case 'all':
        return 'all';
      case 'lpa':
        return 'lpa';
      case 'chol':
        return 'totalChol';
      case 'hdl':
        return 'hdl';
      case 'ldl':
        return 'ldl';
      case 'trg':
        return 'triglycerides';
      default:
        return 'all';
    }
  }, [value]);
};

export default useMetric;
