import React, { useMemo, useRef, useState } from 'react';
import { Box } from '@mui/material';
import BloodGlucoseWebSection from './BloodGlucoseWebSection';
import Hbac1WebSection from './Hbac1WebSection';
import { DiabetesGoal, ModalMobile } from '@hdcorner/ui-library';
import ModalAddEditGoal from '../../components/ModalAddEditGoal';
import {
  useAddDiabetesGoalMutation,
  useDeleteDiabetesGoalMutation,
  useEditDiabetesGoalMutation,
} from './queries/diabetesQueries';
import ModalRemoveGoal from '../../components/ModalRemoveGoal';
import moment from 'moment';
import useAlert from '../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

const DiabetesWeb = () => {
  const { presentSuccess, presentError } = useAlert();
  const { t } = useTranslation();

  const [modalGoalOpen, setModalGoalOpen] = useState<boolean>(false);
  const [openRemoveGoal, setOpenRemoveGoal] = useState<boolean>(false);
  const [goal, setGoal] = useState<DiabetesGoal | undefined>(undefined);
  const [currentMeasurement, setCurrentMeasurement] = useState<number>(0);

  const goalTypeRef = useRef<string>('');

  const [editGoal] = useEditDiabetesGoalMutation();
  const [deleteGoal] = useDeleteDiabetesGoalMutation();
  const [addBloodGlucoseGoal] = useAddDiabetesGoalMutation();

  const goalValue = useMemo(() => {
    if (goal) {
      return goal.goal ? goal.goal : 0;
    }
    return 0;
  }, [goal]);

  const handleAddBloodGlucoseGoal = (current: number | undefined) => {
    goalTypeRef.current = 'blood_glucose';
    if (current) {
      setCurrentMeasurement(current);
    }
    setModalGoalOpen(true);
  };

  const handleEditGoal = (
    goal: DiabetesGoal | undefined,
    current: number | undefined,
  ) => {
    if (goal) {
      setGoal(goal);
      setModalGoalOpen(true);
    }
    if (current) {
      setCurrentMeasurement(current);
    }
  };

  const handleRemoveGoalClick = (goal: DiabetesGoal | undefined) => {
    if (goal) {
      setGoal(goal);
      setOpenRemoveGoal(true);
    }
  };

  const handleAddHbac1Goal = (current: number | undefined) => {
    goalTypeRef.current = 'hbac1';
    if (current) {
      setCurrentMeasurement(current);
    }
    setModalGoalOpen(true);
  };

  const handleRemoveGoal = () => {
    if (goal?._id) {
      deleteGoal({ id: goal?._id })
        .unwrap()
        .then(() => presentSuccess(t('errors.diabetes.successHba1c.goalRemoved')))
        .catch(() => presentError(t('errors.diabetes.errorRemoveGoal')))
        .finally(() => {
          setGoal(undefined);
          setOpenRemoveGoal(false);
        });
    }
  };

  const handleSaveClick = (newGoal: string) => {
    if (goal) {
      editGoal({
        _id: goal._id,
        goal: parseInt(newGoal),
        goalType: goal.goalType,
        startDate: goal.createdAt,
      })
        .unwrap()
        .then(() => presentSuccess(t('errors.diabetes.successHba1c.goalUpdated')))
        .catch(() => presentError(`${t('errors.diabetes.errorUpdateGoal')}`))
        .finally(() => {
          setGoal(undefined);
          setCurrentMeasurement(0);
          setModalGoalOpen(false);
        });
    } else {
      addBloodGlucoseGoal({
        goal: parseInt(newGoal),
        startDate: moment().startOf('day').toISOString(),
        goalType: goalTypeRef.current === 'blood_glucose' ? 'blood_glucose' : 'hbac1',
      })
        .unwrap()
        .then(() => presentSuccess(t('errors.diabetes.successHba1c.goalAdded')))
        .catch(() => presentError(t('errors.diabetes.errorAddGoal')))
        .finally(() => {
          goalTypeRef.current = '';
          setGoal(undefined);
          setModalGoalOpen(false);
          setCurrentMeasurement(0);
        });
    }
  };

  return (
    <>
      <Box display={'flex'} flex={1} flexWrap={'wrap'} gap={3} alignItems={'start'}>
        <BloodGlucoseWebSection
          handleEditBloodGlucoseGoal={handleEditGoal}
          handleRemoveBloodGlucoseGoal={handleRemoveGoalClick}
          handleAddBloodGlucoseGoal={handleAddBloodGlucoseGoal}
        />
        <Hbac1WebSection
          handleEditHbac1Goal={handleEditGoal}
          handleAddHbac1Goal={handleAddHbac1Goal}
          handleRemoveHbac1Goal={handleRemoveGoalClick}
        />
      </Box>
      <ModalMobile open={openRemoveGoal} setOpen={setOpenRemoveGoal}>
        <ModalRemoveGoal
          handleRemoveClick={handleRemoveGoal}
          handleCancelClick={() => setOpenRemoveGoal(false)}
        />
      </ModalMobile>
      <ModalMobile width={'350px'} open={modalGoalOpen} setOpen={setModalGoalOpen}>
        <ModalAddEditGoal
          goal={goalValue}
          handleSaveClick={handleSaveClick}
          currentMeasurement={currentMeasurement}
        />
      </ModalMobile>
    </>
  );
};

export default DiabetesWeb;
