import * as React from 'react';
import { SVGProps } from 'react';

const SubscriptionSidebarIcon = ({
  fill = '#222222',
  ...rest
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M0.536992 8.48807C0.484705 8.67452 0.487677 8.87214 0.545547 9.05693C0.603417 9.24173 0.713696 9.40575 0.862992 9.52907L5.66799 13.4921L4.15299 20.2821C4.10872 20.4799 4.12565 20.6866 4.20153 20.8746C4.27741 21.0627 4.40866 21.2232 4.57787 21.3349C4.74709 21.4466 4.94626 21.5043 5.14899 21.5002C5.35172 21.4961 5.54841 21.4305 5.71299 21.3121L11 17.5091L16.287 21.3091C16.4516 21.4275 16.6483 21.4931 16.851 21.4972C17.0537 21.5013 17.2529 21.4436 17.4221 21.3319C17.5913 21.2202 17.7226 21.0597 17.7985 20.8716C17.8743 20.6836 17.8913 20.4769 17.847 20.2791L16.332 13.4891L21.137 9.52607C21.2864 9.40288 21.3967 9.23899 21.4548 9.05428C21.5128 8.86957 21.5159 8.67199 21.4638 8.48552C21.4117 8.29906 21.3066 8.13172 21.1613 8.00384C21.0159 7.87595 20.8366 7.793 20.645 7.76507L14.828 6.91607L11.9 1.05307C11.8076 0.898092 11.6765 0.769764 11.5196 0.680647C11.3628 0.591529 11.1854 0.544678 11.005 0.544678C10.8246 0.544678 10.6472 0.591529 10.4903 0.680647C10.3334 0.769764 10.2024 0.898092 10.11 1.05307L7.17199 6.91907L1.35499 7.76807C1.16362 7.79612 0.984488 7.87906 0.839302 8.00686C0.694115 8.13465 0.589104 8.3018 0.536992 8.48807ZM7.97799 8.82307C8.13742 8.79983 8.28887 8.73841 8.41946 8.64404C8.55004 8.54967 8.65589 8.42515 8.72799 8.28107L11 3.73607L13.272 8.28107C13.3441 8.42515 13.4499 8.54967 13.5805 8.64404C13.7111 8.73841 13.8626 8.79983 14.022 8.82307L18.122 9.42307L14.586 12.3401C14.4437 12.4576 14.3368 12.6122 14.277 12.7867C14.2171 12.9613 14.2068 13.149 14.247 13.3291L15.323 18.1551L11.584 15.4651C11.4139 15.3427 11.2096 15.2768 11 15.2768C10.7904 15.2768 10.5861 15.3427 10.416 15.4651L6.67699 18.1551L7.75299 13.3291C7.79323 13.149 7.78286 12.9613 7.72303 12.7867C7.6632 12.6122 7.55624 12.4576 7.41399 12.3401L3.87599 9.42107L7.97799 8.82307Z"
      fill={fill}
    />
  </svg>
);

export default SubscriptionSidebarIcon;
