import { FC } from 'react';
import * as React from 'react';
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PlusIconCircular from '../../assets/PlusIconCircular';
import CustomButton from '../controls/CustomButton';
import { useTranslation } from 'react-i18next';

type ConnectedDeviceCardSingleProps = {
  name: string;
  img: React.ReactNode;
  removeItem: () => void;
};

type CardType = {
  name: string;
  img: React.ReactNode;
};

type ConnectedDeviceCardProps = {
  cards: CardType[];
  handeAddItem: () => void;
  handleRemoveItem: () => void;
};

type ConnectedDeviceCardSingleAddProps = {
  addItem: () => void;
};

const CenterCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(5),
  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(7),
    padding: theme.spacing(1, 3, 7, 3),
  },
  padding: theme.spacing(1, 4, 5, 4),
}));

const AddCardActions = styled(CardActions)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: theme.spacing(3),
}));

const GridCard = styled(Card)(({ theme }) => ({
  width: '163px',
  display: 'flex',
  height: '163px',
  alignItems: 'center',
  boxSizing: 'border-box',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: '#F2F1F3',
  boxShadow: '0px 1px 24px rgba(0, 0, 0, 0.08)',
  [theme.breakpoints.up('md')]: {
    height: '200px',
    width: '224px',
  },
}));

const ConnectDeviceSingle = styled(Card)(({ theme }) => ({
  width: '163px',
  display: 'flex',
  height: '163px',
  borderRadius: '8px',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    height: '200px',
    width: '224px',
  },
}));

export const ConnectedDeviceCardSingle: FC<ConnectedDeviceCardSingleProps> = ({
  img,
  name,
  removeItem,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  return (
    <ConnectDeviceSingle>
      <CenterCardContent>
        <Typography
          variant={'body1'}
          textAlign={'center'}
          minWidth={'max-content'}
          textTransform={'capitalize'}
          color={theme.palette.primary.main}
          fontWeight={theme.typography.fontWeightBold}
        >
          {name}
        </Typography>
        {img}
      </CenterCardContent>
      <CardActions sx={{ padding: 0, margin: 0 }}>
        <CustomButton
          fullWidth
          disableElevation
          color={'secondary'}
          onClick={removeItem}
          variant={'contained'}
          sx={{
            height: '32px',
          }}
        >
          <Typography
            variant={'body1'}
            fontWeight={theme.typography.fontWeightBold}
            color={theme.palette.kmColorsCoolGrey.main}
          >
            {t('devices.text.remove')}
          </Typography>
        </CustomButton>
      </CardActions>
    </ConnectDeviceSingle>
  );
};

export const ConnectedDeviceCardSingleAdd: FC<ConnectedDeviceCardSingleAddProps> = ({
  addItem,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <GridCard>
      <AddCardActions>
        <CustomButton
          onClick={addItem}
          disableElevation
          variant={'contained'}
          sx={{
            borderRadius: '99px',
            backgroundColor: 'transparent',
            '&:focus,&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <PlusIconCircular width={'44px'} height={'44px'} />
        </CustomButton>
        <Typography
          variant={'body1'}
          color={theme.palette.primary.main}
          fontWeight={theme.typography.fontWeightBold}
          textTransform={lang === 'el' ? 'capitalize' : 'uppercase'}
        >
          {t('devices.text.add')}
        </Typography>
      </AddCardActions>
    </GridCard>
  );
};

const ConnectedDeviceCard: FC<ConnectedDeviceCardProps> = ({
  cards,
  handeAddItem,
  handleRemoveItem,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Grid container spacing={3} justifyContent={{ xs: 'center', sm: 'flex-start' }}>
      {cards.map((card, index) => (
        <Grid item key={index}>
          <ConnectedDeviceCardSingle
            name={card.name}
            img={card.img}
            removeItem={handleRemoveItem}
          />
        </Grid>
      ))}
      {matches && (
        <Grid item>
          <ConnectedDeviceCardSingleAdd addItem={handeAddItem} />
        </Grid>
      )}
    </Grid>
  );
};

export default ConnectedDeviceCard;
