import { articleCategories } from '../constants';

// Convert the category value to a title
export const convertArticleCategory = (value: string) => {
  if (!value) return '';

  const category = articleCategories.find((it: any) => it.value === value);
  return category?.title;
};

// Convert the category title to a value
export const convertArticleTitle = (value: string) => {
  if (!value) return '';

  const category = articleCategories.find((it: any) => it.title === value);
  return category?.value;
};

export const findArticleIndex = (array: string[], id: string) => {
  return array.findIndex((it: string) => it === id);
};
