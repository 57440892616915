import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { FC, useMemo } from 'react';
import moment from 'moment';
import CalendarIcon from '../../assets/CalendarIcon';
import { useTranslation } from 'react-i18next';

type Props = {
  label?: string;
  selectedDate?: string;
  handleCalendarOpen: () => void;
};
const CustomDateInput: FC<Props> = ({ label, selectedDate, handleCalendarOpen }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const formatDate = useMemo(() => {
    if (!selectedDate) return '';

    const date = moment(selectedDate);
    return date.format('DD/MM/YYYY');
  }, [selectedDate]);

  return (
    <Box display={'flex'} flexDirection={'column'} gap={1}>
      <Box>
        <Typography
          variant={'subtitle2'}
          color={theme.palette.primary.dark}
          fontWeight={theme.typography.fontWeightRegular}
        >
          {label ? label : t('userDetails.dob')}
        </Typography>
      </Box>
      <Box
        px={2}
        py={1}
        display={'flex'}
        borderRadius={2}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{ background: theme.palette.secondary.main }}
      >
        <Box>
          <Typography color={theme.palette.primary.dark} variant={'subtitle2'}>
            {formatDate}
          </Typography>
        </Box>
        <Box>
          <IconButton onClick={handleCalendarOpen}>
            <CalendarIcon width={'20px'} height={'20px'} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomDateInput;
