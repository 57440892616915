import api from '../api';

const queries = api.injectEndpoints({
  endpoints: build => ({
    downloadInDepthData: build.mutation<
      string,
      {
        end?: string;
        module: string;
        start?: string;
        format: 'pdf' | 'excel';
        diabetesCategory?: string;
        hypertensionCategory?: string;
      }
    >({
      query: ({
        end,
        start,
        module,
        format,
        diabetesCategory,
        hypertensionCategory,
      }) => ({
        url: `hdCornerService/user/file/pdf-excel`,
        method: 'POST',
        params: { module, format, start, end, diabetesCategory, hypertensionCategory },
      }),
    }),
    uploadDeviceData: build.mutation<
      string,
      {
        uploadData: any[];
        logType: 'hypertension' | 'blood_glucose';
      }
    >({
      query: ({ logType, uploadData }) => ({
        method: 'POST',
        body: { logType, uploadData },
        url: `hdCornerService/user/upload`,
      }),
    }),
  }),
});

export const { useDownloadInDepthDataMutation, useUploadDeviceDataMutation } = queries;

export default queries;
