import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface categoryDetails {
  title: string;
  value: string;
}

interface articleDetails {
  id: string;
  title: string;
}

const initialState: {
  idArray: string[];
  article: articleDetails;
  category: categoryDetails;
} = {
  idArray: [],
  article: { id: '', title: '' },
  category: { title: '', value: '' },
};

const articlesSlice = createSlice({
  name: 'articleDetails',
  initialState,
  reducers: {
    updateArticleCategory: (state, action: PayloadAction<categoryDetails>) => {
      state.category = action.payload;
    },
    updateArticleDetails: (state, action: PayloadAction<articleDetails>) => {
      state.article = action.payload;
    },
    createArticleList: (state, action: PayloadAction<string[]>) => {
      state.idArray = action.payload;
    },
  },
});

export const { createArticleList, updateArticleCategory, updateArticleDetails } =
  articlesSlice.actions;

export default articlesSlice.reducer;
