import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
  CalendarModal,
  ClickNavCardNutrition,
  HBAC1Chart,
  ModalMobile,
  theme,
  weekMonthYearCustom,
} from '@hdcorner/ui-library';
import { useIonRouter } from '@ionic/react';
import {
  useAddDiabetesGoalMutation,
  useGetHbac1CurrentMeasurementQuery,
  useGetHbac1OverallDataQuery,
  useGetHbacGoalQuery,
  useGetHcac1GraphDataQuery,
} from './queries/diabetesQueries';
import useOverallData from './hooks/useOverallData';
import useCurrentMeasurement from './hooks/useCurrentMeasurement';
import useGoalValue from './hooks/useGoalValue';
import ModalAddEditGoal from '../../components/ModalAddEditGoal';
import moment from 'moment/moment';
import useTimeframe from '../../hooks/useTimeframe';
import usePrepareGraph from './hooks/usePrepareGraph';
import useAlert from '../../hooks/useAlert';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../../utils/useConvertJSON';

const DiabetesTabHBAC1 = () => {
  const router = useIonRouter();
  const { t } = useTranslation();

  const { presentSuccess, presentError } = useAlert();

  const [goalModalOpen, setGoalModalOpen] = useState<boolean>(false);
  const [selectedTimeframe, setSelectedTimeframe] = useState<string>('all');
  const [calendarModalOpen, setCalendarModalOpen] = useState<boolean>(false);

  // Used for updating the params for the query
  const [startParam, setStartParam] = useState<string>('');
  const [endParam, setEndParam] = useState<string>('');

  const timeframe = useTimeframe(selectedTimeframe, startParam, endParam);

  const [addHbac1Goal] = useAddDiabetesGoalMutation();

  const { data: hbac1Goal, error: goalError } = useGetHbacGoalQuery();
  const { data: hbacGraphData, error: graphError } = useGetHcac1GraphDataQuery({
    ...timeframe,
  });
  const { data: hbac1CurrentMeasurement, error: currentError } =
    useGetHbac1CurrentMeasurementQuery();
  const { data: hbac1OverallData, error: overallError } = useGetHbac1OverallDataQuery({
    ...timeframe,
  });

  const prepareGoalValue = useGoalValue(hbac1Goal);
  const prepareGraphData = usePrepareGraph(hbacGraphData);
  const prepareOverallData = useOverallData(hbac1OverallData);
  const prepareCurrentMeasurement = useCurrentMeasurement(hbac1CurrentMeasurement);

  useEffect(() => {
    if (goalError || currentError) {
      presentError(t('errors.diabetes.errorsHba1c.fetchGoal'));
    }

    if (graphError) {
      presentError(t('errors.diabetes.errorsHba1c.fetchGraph'));
    }

    if (overallError) {
      presentError(t('errors.diabetes.errorsHba1c.fetchOverall'));
    }
  }, [goalError, graphError, overallError, currentError]);

  const handleShowInDepthData = () => {
    router.push('/dashboard/diabetes/hbac1-in-depth-data');
  };

  const handleShowGraphData = () => {
    router.push('/dashboard/diabetes/graph-data/hbac1');
  };

  const handleClickGoal = () => {
    if (hbac1Goal && hbac1Goal.length > 0) {
      router.push('/dashboard/diabetes-hbac1-goal');
      return;
    }
    setGoalModalOpen(true);
  };

  const handleSaveClick = (newData: any) => {
    addHbac1Goal({
      goal: newData,
      goalType: 'hbac1',
      startDate: moment().startOf('day').toISOString(),
    })
      .unwrap()
      .then(() => presentSuccess(t('errors.diabetes.successHba1c.goalAdded')))
      .catch(() => presentError(t('errors.diabetes.errorsHba1c.addGoal')))
      .finally(() => setGoalModalOpen(false));
  };

  const onOpenCalendar = () => {
    if (selectedTimeframe === 'custom') {
      setCalendarModalOpen(true);
    }
  };

  const handleDateChange = (dateRange: string[]) => {
    setStartParam(dateRange[0]);
    setEndParam(dateRange[1]);
  };

  return (
    <>
      <Box
        display={'flex'}
        gap={theme.spacing(2)}
        flexDirection={'column'}
        marginTop={theme.spacing(3)}
      >
        <HBAC1Chart
          chartData={prepareGraphData}
          overallData={prepareOverallData}
          handleOpenCalendar={onOpenCalendar}
          selectedTimeframe={selectedTimeframe}
          selectTimeframe={setSelectedTimeframe}
          latestMeasurement={prepareCurrentMeasurement}
          headings={useConvertJSON(weekMonthYearCustom)}
        />
        <Box>
          <ClickNavCardNutrition
            onClick={handleShowInDepthData}
            headings={t('buttons.navigation.inDepth')}
          />
          <ClickNavCardNutrition
            headings={t('buttons.navigation.graphData')}
            onClick={handleShowGraphData}
          />
          <ClickNavCardNutrition
            onClick={handleClickGoal}
            headings={t('headingsTitles.goal')}
            body={
              prepareGoalValue
                ? `${prepareGoalValue} %`
                : `${t('buttons.navigation.addGoal')}`
            }
          />
        </Box>
      </Box>
      <ModalMobile open={goalModalOpen} setOpen={setGoalModalOpen}>
        <ModalAddEditGoal
          desiredPlaceholder={'5.7%'}
          handleSaveClick={handleSaveClick}
          currentMeasurement={prepareCurrentMeasurement}
        />
      </ModalMobile>
      <CalendarModal
        open={calendarModalOpen}
        saveDate={handleDateChange}
        setOpen={setCalendarModalOpen}
        selectedDate={[startParam, endParam]}
      />
    </>
  );
};

export default DiabetesTabHBAC1;
