import api from '../../../redux/api';
import { AuthUser, ChangeEmail, ChangePassword } from '@hdcorner/ui-library';

const accountSettingsQueries = api.injectEndpoints({
  endpoints: build => ({
    changeEmail: build.mutation<ChangeEmail, { newEmail: string }>({
      query: ({ newEmail }) => ({
        method: 'POST',
        body: { newEmail },
        url: '/authentication/local/change-email',
      }),
    }),

    updateChangePassword: build.mutation<
      ChangePassword,
      { oldPassword: string; newPassword: string }
    >({
      query: ({ oldPassword, newPassword }) => ({
        method: 'POST',
        body: { oldPassword, newPassword },
        url: '/authentication/local/change-password',
      }),
      invalidatesTags: ['ChangePassword'],
    }),

    updatePhoneNumber: build.mutation<AuthUser, { phoneNumber: string }>({
      query: ({ phoneNumber }) => ({
        method: 'PUT',
        body: { phone: phoneNumber },
        url: '/database/function/editUserData',
      }),
      invalidatesTags: ['AuthUser', 'UserData'],
    }),

    deleteAuthUser: build.mutation<AuthUser, void>({
      query: () => ({
        method: 'DELETE',
        url: '/authentication/user',
      }),
    }),

    upgradeToken: build.mutation<AuthUser, void>({
      query: () => ({
        method: 'POST',
        url: '/hdCornerService/user/token-upgrade',
      }),
    }),

    deleteUserData: build.mutation<AuthUser, void>({
      query: () => ({
        method: 'DELETE',
        url: '/database/function/deleteUserData',
      }),
    }),
  }),
});

export const {
  useChangeEmailMutation,
  useUpgradeTokenMutation,
  useDeleteAuthUserMutation,
  useDeleteUserDataMutation,
  useUpdatePhoneNumberMutation,
  useUpdateChangePasswordMutation,
} = accountSettingsQueries;

export default accountSettingsQueries;
