import React, { FC } from 'react';
import {
  Avatar,
  AvatarProps,
  Box,
  BoxProps,
  Card,
  CardContent,
  CardContentProps,
  CardProps,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import IDBadge from '../IDBadge';
import VerifiedIcon from '../../assets/VerifiedIcon';
import { useTranslation } from 'react-i18next';

const CardMain = styled(Card, {
  shouldForwardProp: propName => {
    return propName !== 'profApp';
  },
})<CardProps & { profApp: boolean }>(({ theme, ...props }) => ({
  display: 'flex',
  borderRadius: 8,
  alignItems: 'center',
  boxShadow: theme.shadows[0],
  flex: props.profApp ? 'none' : 1,
}));

const CardContentMain = styled(CardContent, {
  shouldForwardProp: propName => {
    return propName !== 'profApp';
  },
})<CardContentProps & { profApp: boolean }>(({ theme, ...props }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: props.profApp ? 'center' : 'flex-start',
  '&.MuiCardContent-root:last-child': {
    paddingBottom: theme.spacing(2),
  },
}));

const PlanBadgeContainer = styled(Box, {
  shouldForwardProp: propName => {
    return propName !== 'isBasicPlan';
  },
})<BoxProps & { isBasicPlan: boolean }>(({ theme, isBasicPlan }) => ({
  borderRadius: '4px',
  backgroundColor: isBasicPlan
    ? theme.palette.secondary.main
    : theme.palette.kmColorsYellow.main,
  padding: theme.spacing(0.5, 2, 0.5, 2),
  marginLeft: theme.spacing(1),
}));

const PlanBadgeText = styled(Typography, {
  shouldForwardProp: propName => {
    return propName !== 'isBasicPlan';
  },
})<TypographyProps & { isBasicPlan: boolean }>(({ theme, isBasicPlan }) => ({
  display: 'flex',
  textTransform: 'capitalize',
  color: isBasicPlan
    ? theme.palette.kmColorsCoolGrey.main
    : theme.palette.kmColorsDarkYellow.main,
  fontWeight: theme.typography.fontWeightRegular,
}));

const ProfileName = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  color: theme.palette.primary.main,
  maxHeight: 40,
}));

const Joined = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  color: theme.palette.kmColorsCoolGrey.main,
}));

const AvatarMain = styled(Avatar)<AvatarProps>(({ theme }) => ({
  borderRadius: 8,
  width: 64,
  height: 64,
  marginRight: theme.spacing(1),
}));

type ProfileDetailsCardType = {
  id?: string;
  name: string;
  image: string;
  plan?: string;
  email?: string;
  joined?: string;
  verified?: boolean;
  iconTextSpace?: boolean;
  profApp?: boolean | any;
  onClick?: () => void;
};
const ProfileDetailsCard: FC<ProfileDetailsCardType & CardProps> = ({
  image,
  name,
  joined,
  plan,
  email,
  iconTextSpace = true,
  profApp,
  verified,
  id,
  onClick,
  ...cardProps
}) => {
  const PlanBadge = () => {
    const { t } = useTranslation();

    const isBasicPlan = plan?.toLowerCase() === 'basic';

    return (
      <PlanBadgeContainer isBasicPlan={isBasicPlan}>
        <PlanBadgeText variant={'caption'} isBasicPlan={isBasicPlan}>
          {plan?.toLowerCase() === 'basic'
            ? t('userDetails.basic')
            : t('userDetails.premium')}
        </PlanBadgeText>
      </PlanBadgeContainer>
    );
  };

  return (
    <CardMain {...cardProps} raised={false} profApp={profApp} onClick={onClick}>
      <CardContentMain profApp={profApp}>
        <AvatarMain src={image} />
        <Box
          display={'flex'}
          flexDirection={'column'}
          flex={profApp ? 'none' : 1}
          marginLeft={iconTextSpace ? 3 : 0}
          alignItems={profApp ? 'flex-start' : 'none'}
        >
          <Box
            flex={1}
            display={'flex'}
            marginBottom={1}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box display={'flex'} gap={1} alignItems={'center'}>
              <ProfileName variant={'subtitle1'}>{name}</ProfileName>
              {profApp && verified && <VerifiedIcon />}
            </Box>
            {plan && !email && PlanBadge()}
          </Box>

          {profApp ? (
            <IDBadge id={id} />
          ) : (
            <Joined variant={'body1'}>{email ? email : `Joined: ${joined}`}</Joined>
          )}
        </Box>
      </CardContentMain>
    </CardMain>
  );
};

export default ProfileDetailsCard;
