import * as React from 'react';
import { SVGProps } from 'react';

const ToggleHalfRectangleIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.48 2C2.2149 2 2 2.2149 2 2.48V13.2941C2 13.684 2.31603 14 2.70588 14H13.52C13.7851 14 14 13.7851 14 13.52C14 13.2549 13.7851 13.04 13.52 13.04H3.29455C3.10978 13.04 2.96 12.8902 2.96 12.7055V2.48C2.96 2.2149 2.7451 2 2.48 2Z"
        fill={props?.color ?? '#222222'}
      />
      <path
        d="M13.28 2.96C13.28 3.22531 13.0651 3.44 12.8 3.44C12.5349 3.44 12.32 3.22531 12.32 2.96C12.32 2.82031 12.3798 2.69469 12.4752 2.60703C12.5606 2.52828 12.6747 2.48 12.8 2.48C13.0651 2.48 13.28 2.69469 13.28 2.96Z"
        fill={props?.color ?? '#222222'}
      />
      <path
        d="M9.44 3.44C9.44 3.70531 9.22508 3.92 8.96 3.92C8.69492 3.92 8.48 3.70531 8.48 3.44C8.48 3.17469 8.69492 2.96 8.96 2.96C9.08961 2.96 9.20715 3.01109 9.29352 3.09453C9.33957 3.13906 9.37672 3.19297 9.40215 3.2525C9.41188 3.27547 9.41984 3.29938 9.42594 3.32422C9.43238 3.35 9.4366 3.37672 9.43871 3.40484L9.44 3.44Z"
        fill={props?.color ?? '#222222'}
      />
      <path
        d="M9.44 6.8C9.44 7.06531 9.22508 7.28 8.96 7.28C8.87961 7.28 8.80391 7.26031 8.73734 7.22516C8.64008 7.17406 8.5625 7.09109 8.51879 6.98984C8.51211 6.97438 8.50625 6.95844 8.50121 6.94203C8.49219 6.91297 8.48598 6.8825 8.4827 6.85156C8.48094 6.83469 8.48 6.81734 8.48 6.8C8.48 6.64953 8.54891 6.51594 8.65684 6.42781C8.73957 6.36031 8.84504 6.32 8.96 6.32C9.22508 6.32 9.44 6.53469 9.44 6.8Z"
        fill={props?.color ?? '#222222'}
      />
      <path
        d="M6.56 8.24C6.56 8.50531 6.34508 8.72 6.08 8.72C5.81492 8.72 5.6 8.50531 5.6 8.24C5.6 7.97469 5.81492 7.76 6.08 7.76C6.19473 7.76 6.29996 7.80031 6.38246 7.86734C6.49074 7.955 6.56 8.08953 6.56 8.24Z"
        fill={props?.color ?? '#222222'}
      />
      <path
        d="M4.16 11.12C4.42508 11.12 4.64 10.9053 4.64 10.64C4.64 10.3747 4.42508 10.16 4.16 10.16C3.89492 10.16 3.68 10.3747 3.68 10.64C3.68 10.9053 3.89492 11.12 4.16 11.12Z"
        fill={props?.color ?? '#222222'}
      />
      <path
        d="M7.10867 11.1148C7.12719 11.1125 7.14547 11.1088 7.16328 11.1041C7.23266 11.0858 7.29594 11.0525 7.34926 11.0075C7.39613 10.9681 7.43527 10.9198 7.46422 10.865C7.49984 10.798 7.52 10.7216 7.52 10.64C7.52 10.3747 7.30508 10.16 7.04 10.16C6.77492 10.16 6.56 10.3747 6.56 10.64C6.56 10.7741 6.61496 10.8955 6.70367 10.9827C6.79027 11.0675 6.90898 11.12 7.04 11.12C7.06332 11.12 7.08617 11.1181 7.10867 11.1148Z"
        fill={props?.color ?? '#222222'}
      />
      <path
        d="M9.92 9.68C10.1851 9.68 10.4 9.46531 10.4 9.2C10.4 8.93469 10.1851 8.72 9.92 8.72C9.65492 8.72 9.44 8.93469 9.44 9.2C9.44 9.46531 9.65492 9.68 9.92 9.68Z"
        fill={props?.color ?? '#222222'}
      />
      <path
        d="M12.32 7.28C12.4501 7.28 12.5681 7.22844 12.6546 7.14406C12.7443 7.05688 12.8 6.935 12.8 6.8C12.8 6.53469 12.5851 6.32 12.32 6.32C12.1807 6.32 12.0552 6.37953 11.9675 6.47422C11.8884 6.56 11.84 6.67438 11.84 6.8C11.84 7.06531 12.0549 7.28 12.32 7.28Z"
        fill={props?.color ?? '#222222'}
      />
      <path
        d="M11.36 4.4C11.6251 4.4 11.84 4.18531 11.84 3.92C11.84 3.65469 11.6251 3.44 11.36 3.44C11.0949 3.44 10.88 3.65469 10.88 3.92C10.88 4.18531 11.0949 4.4 11.36 4.4Z"
        fill={props?.color ?? '#222222'}
      />
      <path
        d="M6.56 5.36C6.82508 5.36 7.04 5.14531 7.04 4.88C7.04 4.61469 6.82508 4.4 6.56 4.4C6.29492 4.4 6.08 4.61469 6.08 4.88C6.08 5.02906 6.14797 5.16266 6.25473 5.25031C6.27512 5.26719 6.29691 5.28266 6.32 5.29578C6.39066 5.33656 6.47258 5.36 6.56 5.36Z"
        fill={props?.color ?? '#222222'}
      />
      <path
        d="M11.742 10.9306C11.6544 11.0459 11.5159 11.12 11.36 11.12C11.0949 11.12 10.88 10.9053 10.88 10.64C10.88 10.3747 11.0949 10.16 11.36 10.16C11.6251 10.16 11.84 10.3747 11.84 10.64C11.84 10.7492 11.8034 10.85 11.742 10.9306Z"
        fill={props?.color ?? '#222222'}
      />
    </svg>
  );
};

export default ToggleHalfRectangleIcon;
