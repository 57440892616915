import React, { FC } from 'react';
import { CustomInput } from '@hdcorner/ui-library';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  paymentDetails: any;
};
const CardPaymentInfo: FC<Props> = ({ paymentDetails }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const web = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      width={'100%'}
      display={'flex'}
      gap={web ? 4 : 3}
      flexDirection={'column'}
      boxSizing={'border-box'}
      padding={web ? theme.spacing(3) : 0}
      bgcolor={web ? theme.palette.secondary.light : 'transparent'}
    >
      <Typography
        variant={web ? 'h1' : 'subtitle2'}
        sx={{
          fontWeight: web
            ? theme.typography.fontWeightBold
            : theme.typography.fontWeightRegular,
        }}
      >
        {t('subscription.titles.payment')}
      </Typography>
      <Box gap={1} flex={1} display={'flex'} flexDirection={'column'}>
        <Box>
          <Box flex={1} minWidth={'45%'}>
            <CustomInput
              fullWidth
              readOnly
              type={'email'}
              value={paymentDetails.email}
              label={`${t('personalDetails.email')}`}
              placeholder={`${t('personalDetails.emailEx')}`}
            />
          </Box>
        </Box>
        <Box display={'flex'} gap={1}>
          <CustomInput
            fullWidth
            readOnly
            value={paymentDetails.firstName}
            label={`${t('userDetails.fName')}`}
            placeholder={`${t('personalDetails.fNameEx')}`}
          />
          <CustomInput
            fullWidth
            readOnly
            value={paymentDetails.lastName}
            label={`${t('userDetails.fName')}`}
            placeholder={`${t('personalDetails.lNameEx')}`}
          />
        </Box>
        <Box>
          <CustomInput
            fullWidth
            readOnly
            placeholder={'-'}
            value={paymentDetails.phone}
            label={`${t('personalDetails.phoneNumber')}`}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CardPaymentInfo;
