import * as React from 'react';
import { SVGProps } from 'react';
import { useTheme } from '@mui/material';

const FeelsEmptyIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();

  return (
    <svg
      width="216"
      height="86"
      fill="none"
      viewBox="0 0 216 86"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M39 73.1332C39 77.0218 42.134 80.1741 46 80.1741H60C56 77.7574 48.8 69.8058 52 57.3333C55.2 44.8608 68.6667 41.4074 75 41.2398C73.6667 35.3442 73.9 20.7953 85.5 9.76478H62C58.134 9.76478 55 12.9171 55 16.8057C55 20.6943 58.134 23.8467 62 23.8467H22C18.134 23.8467 15 26.999 15 30.8876C15 34.7762 18.134 37.9285 22 37.9285H47C50.866 37.9285 54 41.0809 54 44.9695C54 48.8581 50.866 52.0104 47 52.0104H7C3.13401 52.0104 0 55.1627 0 59.0513C0 62.9399 3.13401 66.0923 7 66.0923H46C42.134 66.0923 39 69.2446 39 73.1332Z"
        fill={
          theme.palette.mode === 'dark'
            ? theme.palette.kmColorsCoolGrey.main
            : theme.palette.primary.light
        }
      />
      <path
        d="M192 80.1741C195.866 80.1741 199 77.0218 199 73.1332C199 69.2446 195.866 66.0923 192 66.0923H186C182.134 66.0923 179 62.9399 179 59.0513C179 55.1627 182.134 52.0104 186 52.0104H205C208.866 52.0104 212 48.8581 212 44.9695C212 41.0809 208.866 37.9285 205 37.9285H183C186.866 37.9285 190 34.7762 190 30.8876C190 26.999 186.866 23.8467 183 23.8467H145.5C155.167 25.4533 174.5 33.9977 174.5 55.3216C174.5 68.0365 167.863 75.7804 160.526 80.1741H192Z"
        fill={
          theme.palette.mode === 'dark'
            ? theme.palette.kmColorsCoolGrey.main
            : theme.palette.primary.light
        }
      />
      <path
        d="M209 80.1741C212.866 80.1741 216 77.0218 216 73.1332C216 69.2446 212.866 66.0923 209 66.0923C205.134 66.0923 202 69.2446 202 73.1332C202 77.0218 205.134 80.1741 209 80.1741Z"
        fill={
          theme.palette.mode === 'dark'
            ? theme.palette.kmColorsCoolGrey.main
            : theme.palette.primary.light
        }
      />
      <path
        d="M52.1559 6.03509H40.2099V8.42498H48.6579L39.5059 19.5778V21.8349H52.3759V19.4451H43.0039L52.1559 8.13731V6.03509Z"
        fill={
          theme.palette.mode === 'dark'
            ? theme.palette.kmColorsCoolGrey.main
            : theme.palette.primary.light
        }
      />
      <path
        d="M65.5679 21.4085H56.8799V23.1466H63.0239L56.3679 31.2577V32.8993H65.7279V31.1612H58.9119L65.5679 22.9374V21.4085Z"
        fill={
          theme.palette.mode === 'dark'
            ? theme.palette.kmColorsCoolGrey.main
            : theme.palette.primary.light
        }
      />
      <path
        d="M108.25 2.51462C90.1627 2.51462 75.5 17.263 75.5 35.4561C75.5 37.4587 75.6775 39.4186 76.0173 41.3209L76.3004 42.906L74.7029 42.7976C74.2231 42.7651 73.7386 42.7485 73.25 42.7485C61.7628 42.7485 52.5 51.8949 52.5 63.117C52.5 74.339 61.7628 83.4854 73.25 83.4854C73.9404 83.4854 74.5 84.0483 74.5 84.7427C74.5 85.4371 73.9404 86 73.25 86C60.4366 86 50 75.782 50 63.117C50 50.4519 60.4366 40.2339 73.25 40.2339L73.3175 40.234C73.1081 38.6709 73 37.0759 73 35.4561C73 15.8743 88.782 0 108.25 0C123.446 0 136.392 9.67047 141.345 23.2234C142.139 23.1645 142.941 23.1345 143.75 23.1345C161.265 23.1345 175.5 37.1879 175.5 54.5672C175.5 70.6837 163.256 84.021 147.5 85.8608V86H89.7578C89.0675 86 88.5078 85.4371 88.5078 84.7427C88.5078 84.0483 89.0675 83.4854 89.7578 83.4854H145.927L146.151 83.4674C161.195 82.2599 173 69.7258 173 54.5672C173 38.6157 159.924 25.6491 143.75 25.6491C142.692 25.6491 141.647 25.7046 140.619 25.8128L139.622 25.9176L139.304 24.9626C134.947 11.9129 122.688 2.51462 108.25 2.51462Z"
        fill={
          theme.palette.mode === 'dark'
            ? theme.palette.kmColorsCoolGrey.main
            : theme.palette.primary.light
        }
      />
      <path
        d="M77.0314 84.7427C77.0314 84.0483 77.591 83.4854 78.2814 83.4854H85.246C85.9363 83.4854 86.496 84.0483 86.496 84.7427C86.496 85.4371 85.9363 86 85.246 86H78.2814C77.591 86 77.0314 85.4371 77.0314 84.7427Z"
        fill={
          theme.palette.mode === 'dark'
            ? theme.palette.kmColorsCoolGrey.main
            : theme.palette.primary.light
        }
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M132.94 20.0129C132.94 15.6133 129.359 12.0702 124.97 12.0702C120.582 12.0702 117 15.6133 117 20.0129C117 24.4126 120.582 27.9557 124.97 27.9557C129.359 27.9557 132.94 24.4126 132.94 20.0129ZM124.97 14.5848C128.004 14.5848 130.44 17.0281 130.44 20.0129C130.44 22.9978 128.004 25.4411 124.97 25.4411C121.936 25.4411 119.5 22.9978 119.5 20.0129C119.5 17.0281 121.936 14.5848 124.97 14.5848Z"
        fill={
          theme.palette.mode === 'dark'
            ? theme.palette.kmColorsCoolGrey.main
            : theme.palette.primary.light
        }
      />
      <path
        d="M102 49.0351C102 49.2744 101.775 49.8296 100.641 50.3999C99.5816 50.9325 98.031 51.2982 96.25 51.2982C94.469 51.2982 92.9184 50.9325 91.8593 50.3999C90.7254 49.8296 90.5 49.2744 90.5 49.0351C90.5 48.3407 89.9404 47.7778 89.25 47.7778C88.5596 47.7778 88 48.3407 88 49.0351C88 50.74 89.3416 51.9451 90.7412 52.649C92.2156 53.3905 94.165 53.8129 96.25 53.8129C98.335 53.8129 100.284 53.3905 101.759 52.649C103.158 51.9451 104.5 50.74 104.5 49.0351C104.5 48.3407 103.94 47.7778 103.25 47.7778C102.56 47.7778 102 48.3407 102 49.0351Z"
        fill={
          theme.palette.mode === 'dark'
            ? theme.palette.kmColorsCoolGrey.main
            : theme.palette.primary.light
        }
      />
      <path
        d="M129.641 50.3999C130.775 49.8296 131 49.2744 131 49.0351C131 48.3407 131.56 47.7778 132.25 47.7778C132.94 47.7778 133.5 48.3407 133.5 49.0351C133.5 50.74 132.158 51.9451 130.759 52.649C129.284 53.3905 127.335 53.8129 125.25 53.8129C123.165 53.8129 121.216 53.3905 119.741 52.649C118.342 51.9451 117 50.74 117 49.0351C117 48.3407 117.56 47.7778 118.25 47.7778C118.94 47.7778 119.5 48.3407 119.5 49.0351C119.5 49.2744 119.725 49.8296 120.859 50.3999C121.918 50.9325 123.469 51.2982 125.25 51.2982C127.031 51.2982 128.582 50.9325 129.641 50.3999Z"
        fill={
          theme.palette.mode === 'dark'
            ? theme.palette.kmColorsCoolGrey.main
            : theme.palette.primary.light
        }
      />
      <path
        d="M103.25 63.3684C102.56 63.3684 102 63.9313 102 64.6257C102 65.3201 102.56 65.883 103.25 65.883H117.75C118.44 65.883 119 65.3201 119 64.6257C119 63.9313 118.44 63.3684 117.75 63.3684H103.25Z"
        fill={
          theme.palette.mode === 'dark'
            ? theme.palette.kmColorsCoolGrey.main
            : theme.palette.primary.light
        }
      />
    </svg>
  );
};

export default FeelsEmptyIcon;
