import React, { SVGProps } from 'react';

const CCNameIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="12"
      height="15"
      fill="none"
      viewBox="0 0 12 15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 6.83464C6.65927 6.83464 7.30374 6.63914 7.8519 6.27287C8.40006 5.9066 8.82731 5.386 9.0796 4.77692C9.33189 4.16783 9.3979 3.49761 9.26929 2.851C9.14067 2.2044 8.8232 1.61046 8.35702 1.14428C7.89085 0.678106 7.2969 0.360637 6.6503 0.232019C6.0037 0.103402 5.33348 0.169413 4.72439 0.421705C4.1153 0.673997 3.59471 1.10124 3.22843 1.6494C2.86216 2.19757 2.66667 2.84203 2.66667 3.5013C2.66773 4.38503 3.01925 5.23226 3.64415 5.85716C4.26904 6.48205 5.11627 6.83358 6 6.83464ZM6 1.5013C6.39556 1.5013 6.78224 1.6186 7.11114 1.83836C7.44004 2.05813 7.69638 2.37048 7.84776 2.73594C7.99913 3.10139 8.03874 3.50352 7.96157 3.89148C7.8844 4.27945 7.69392 4.63581 7.41421 4.91552C7.13451 5.19522 6.77814 5.3857 6.39018 5.46287C6.00222 5.54004 5.60009 5.50044 5.23463 5.34906C4.86918 5.19769 4.55682 4.94134 4.33706 4.61244C4.1173 4.28355 4 3.89687 4 3.5013C4 2.97087 4.21071 2.46216 4.58579 2.08709C4.96086 1.71202 5.46957 1.5013 6 1.5013ZM0 14.168C2.37122e-08 12.5767 0.632141 11.0505 1.75736 9.92533C2.88258 8.80011 4.4087 8.16797 6 8.16797C7.5913 8.16797 9.11742 8.80011 10.2426 9.92533C11.3679 11.0505 12 12.5767 12 14.168C12 14.3448 11.9298 14.5144 11.8047 14.6394C11.6797 14.7644 11.5101 14.8346 11.3333 14.8346C11.1565 14.8346 10.987 14.7644 10.8619 14.6394C10.7369 14.5144 10.6667 14.3448 10.6667 14.168C10.6667 12.9303 10.175 11.7433 9.29983 10.8681C8.42466 9.99297 7.23768 9.5013 6 9.5013C4.76232 9.5013 3.57534 9.99297 2.70017 10.8681C1.825 11.7433 1.33333 12.9303 1.33333 14.168C1.33333 14.3448 1.2631 14.5144 1.13807 14.6394C1.01305 14.7644 0.843478 14.8346 0.666667 14.8346C0.489856 14.8346 0.320286 14.7644 0.195262 14.6394C0.0702379 14.5144 0 14.3448 0 14.168Z"
        fill="#222222"
      />
    </svg>
  );
};

export default CCNameIcon;
