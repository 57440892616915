import React, { FC } from 'react';
import {
  Box,
  BoxProps,
  IconButton,
  Input,
  InputAdornment,
  InputProps,
  styled,
  useTheme,
} from '@mui/material';
import SearchIcon from '../../assets/SearchIcon';

const CustomBox = styled(Box)<BoxProps>(({ theme }) => ({
  height: '32px',
  margin: theme.spacing(0),
  padding: theme.spacing(1.25),
}));

const CustomInput = styled(Input, {
  shouldForwardProp: prop => prop !== 'modal',
})<InputProps & { modal?: boolean }>(({ theme, modal }) => ({
  borderRadius: '50px',
  margin: theme.spacing(0),
  padding: theme.spacing(0),
  color: theme.palette.kmColorsCoolGrey.main,
  fontSize: theme.typography.subtitle2.fontSize,
  fontWeight: theme.typography.subtitle2.fontWeight,
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.background.default
      : modal
      ? theme.palette.highlight.main
      : theme.palette.secondary.light,
}));

type SearchBarProps = {
  value?: string;
  modal?: boolean;
  placeholder: string;
  setValue?: (value: string) => void;
};
const SearchBar: FC<SearchBarProps & BoxProps> = ({
  placeholder,
  modal,
  value,
  setValue,
  ...boxProps
}) => {
  const theme = useTheme();

  const onChangeHandler = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const { target } = event;
    setValue?.(target?.value);
  };

  return (
    <CustomBox {...boxProps}>
      <CustomInput
        fullWidth
        modal={modal}
        value={value}
        disableUnderline
        onChange={onChangeHandler}
        startAdornment={
          <InputAdornment position="start">
            <IconButton
              type="submit"
              aria-label="search"
              sx={{
                padding: theme.spacing(0, 0, 0, 1.25),
              }}
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
        placeholder={placeholder}
      />
    </CustomBox>
  );
};

export default SearchBar;
