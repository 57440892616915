import * as React from 'react';
import { SVGProps } from 'react';
import theme from '../theme/theme';

const DownloadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    viewBox={'0 0 24 24'}
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : '24px'}
    height={props.height ? props.height : '24px'}
    {...props}
  >
    <path
      d="M4 20H20C20.2652 20 20.5196 20.1054 20.7071 20.2929C20.8946 20.4804 21 20.7348 21 21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21C3 20.7348 3.10536 20.4804 3.29289 20.2929C3.48043 20.1054 3.73478 20 4 20ZM12 2C11.7348 2 11.4804 2.10536 11.2929 2.29289C11.1054 2.48043 11 2.73478 11 3V14.586L8.707 12.293C8.61475 12.1975 8.50441 12.1213 8.3824 12.0689C8.2604 12.0165 8.12918 11.9889 7.9964 11.9877C7.86362 11.9866 7.73194 12.0119 7.60905 12.0622C7.48615 12.1125 7.3745 12.1867 7.2806 12.2806C7.18671 12.3745 7.11246 12.4861 7.06218 12.609C7.0119 12.7319 6.9866 12.8636 6.98775 12.9964C6.9889 13.1292 7.01649 13.2604 7.0689 13.3824C7.12131 13.5044 7.19749 13.6148 7.293 13.707L11.293 17.707C11.3861 17.7996 11.4965 17.873 11.618 17.923C11.7389 17.9738 11.8688 18 12 18C12.1312 18 12.2611 17.9738 12.382 17.923C12.5035 17.873 12.6139 17.7996 12.707 17.707L16.707 13.707C16.8892 13.5184 16.99 13.2658 16.9877 13.0036C16.9854 12.7414 16.8802 12.4906 16.6948 12.3052C16.5094 12.1198 16.2586 12.0146 15.9964 12.0123C15.7342 12.01 15.4816 12.1108 15.293 12.293L13 14.586V3C13 2.73478 12.8946 2.48043 12.7071 2.29289C12.5196 2.10536 12.2652 2 12 2Z"
      fill={props?.color ?? theme.palette.primary.main}
    />
  </svg>
);

export default DownloadIcon;
