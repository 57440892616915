import React, { FC, useState } from 'react';
import {
  Box,
  BoxProps,
  Card,
  CardContent,
  CardContentProps,
  CardProps,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { TypographyProps } from '@mui/material/Typography/Typography';
import ReactPlayer from 'react-player';
import { ButtonProps } from '@mui/material/Button';
import CustomButton from '../controls/CustomButton';
import { useTranslation } from 'react-i18next';

const CardMain = styled(Card)<CardProps>(() => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  boxShadow: 'none',
  borderRadius: '8px',
  overflow: 'hidden',
  position: 'relative',
}));

const CardContentMain = styled(CardContent)<CardContentProps>(({ theme }) => ({
  display: 'flex',
  flex: 1,
  position: 'relative',
  height: '100%',
  padding: theme.spacing(0),
  '&.MuiCardContent-root:last-child': {
    paddingBottom: theme.spacing(0),
  },
}));

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.primary.main,
}));

const TitleInfo = styled(Typography)<TypographyProps<'span', { component: 'span' }>>(
  ({ theme }) => ({
    color: theme.palette.kmColorsCoolGrey.main,
  }),
);

const Info = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.kmColorsCoolGrey.main,
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  margin: theme.spacing(1, 0),
}));

const Circle = styled(Box)<BoxProps>(({ theme }) => ({
  height: 3,
  width: 3,
  borderRadius: 30,
  background: theme.palette.kmColorsCoolGrey.main,
  margin: theme.spacing(0, 1),
}));

const ButtonMain = styled(CustomButton)<ButtonProps>(({ theme }) => ({
  ...theme.typography.body1,
  fontWeight: theme.typography.fontWeightRegular,
  color: theme.palette.medication.main,
  padding: 0,
  ':hover': { background: theme.palette.secondary.light },
}));

type VideoWatchedCardType = {
  title: string;
  info: string;
  url: string;
  creator: string;
  handleClickViewMore: () => void;
};
const VideoWatchedCard: FC<VideoWatchedCardType> = ({
  title,
  info,
  url,
  creator,
  handleClickViewMore,
}) => {
  const { t } = useTranslation();

  const [playing, setPlaying] = useState<boolean>(false);

  return (
    <CardMain raised={false}>
      <CardContentMain>
        <ReactPlayer
          url={url}
          light
          style={{
            ...(!playing ? { filter: 'blur(0.5px)' } : undefined),
            borderRadius: '4px',
            overflow: 'hidden',
          }}
          playIcon={<></>}
          playing={playing}
          onPlay={() => setPlaying(true)}
          onPause={() => setPlaying(false)}
          onClickPreview={() => setPlaying(true)}
          width={180}
          height={110}
          controls={true}
          config={{
            youtube: {
              playerVars: {
                color: 'red',
                controls: 1,
                modestbranding: 0,
                cc_load_policy: 0,
                fs: 0,
                iv_load_policy: 3,
              },
            },
          }}
        />
        <Box
          display={'flex'}
          flex={1}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'flex-start'}
          ml={2}
        >
          <Title variant={'subtitle2'}>
            {title}
            <Circle />
            <TitleInfo variant={'body1'} component={'span'}>
              {creator}
            </TitleInfo>
          </Title>
          <Info variant={'body1'}>{info}</Info>
          <ButtonMain onClick={handleClickViewMore} variant={'text'} disableTouchRipple>
            {t('buttons.navigation.viewMore')}
          </ButtonMain>
        </Box>
      </CardContentMain>
    </CardMain>
  );
};

export default VideoWatchedCard;
