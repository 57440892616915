import { ModalMobile, OverlayWithEmojis } from '@hdcorner/ui-library';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../../redux/hooks';
import {
  useGetUserMoodQuery,
  useLogUserMoodMutation,
} from '../../profile/queries/personalInfoQueries';
import moment from 'moment';
import useAlert from '../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

type Props = {
  welcomeOpen: boolean;
};

const UserMood: FC<Props> = ({ welcomeOpen }) => {
  const { presentSuccess, presentError } = useAlert();
  const { t } = useTranslation();

  const {
    data: { user },
  } = useAppSelector(state => state.auth);

  const [end, setEnd] = useState<string>('');
  const [start, setStart] = useState<string>('');
  const [moodModalOpen, setMoodModalOpen] = useState<boolean>(false);

  const [logUserMood] = useLogUserMoodMutation();
  const { data: moodData } = useGetUserMoodQuery(
    { start, end },
    { skip: !start || !end },
  );

  useEffect(() => {
    const today = moment();
    const start = today.startOf('day').utc(true).toISOString();
    const end = today.endOf('day').utc(true).toISOString();

    setStart(start);
    setEnd(end);
  }, []);

  useEffect(() => {
    if (!moodData) {
      return;
    }
    if (moodData.length === 0 && !welcomeOpen) {
      setMoodModalOpen(true);
    }
  }, [moodData, welcomeOpen]);

  const getUserName = useMemo(() => {
    if (user) {
      const authUser = user.authUser;
      if (authUser && typeof authUser === 'object') {
        return authUser.firstName + ' ' + authUser.lastName;
      }
    }

    return t('general.labels.nA');
  }, [user]);

  const handleModalSaveClick = (value: string) => {
    if (!value) {
      return;
    }
    logUserMood({ mood: value })
      .unwrap()
      .then(() => {
        presentSuccess(t('errors.dashboard.moodSaved'));
      })
      .catch(() => {
        presentError(t('errors.dashboard.moodError'));
      })
      .finally(() => {
        setMoodModalOpen(false);
      });
  };

  return (
    <ModalMobile width={'330px'} open={moodModalOpen} setOpen={setMoodModalOpen}>
      <OverlayWithEmojis username={getUserName} onSubmit={handleModalSaveClick} />
    </ModalMobile>
  );
};

export default UserMood;
