import { Box, ButtonProps, styled, Typography } from '@mui/material';
import {
  CustomButton,
  DeleteIcon,
  InfoCardMeal,
  ReminderData,
  theme,
} from '@hdcorner/ui-library';
import React, { FC } from 'react';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';

const RemoveButton = styled(CustomButton)<ButtonProps>(({ theme }) => ({
  flex: 1,
  display: 'flex',
  boxShadow: theme.shadows[0],
  color: theme.palette.primary.main,
  background: theme.palette.secondary.light,
  padding: theme.spacing(1.5, 0),
  ':hover': {
    background: theme.palette.secondary.light,
  },
}));

const EditReminderButton = styled(CustomButton)<ButtonProps>(({ theme }) => ({
  flex: 1,
  display: 'flex',
  boxShadow: theme.shadows[0],
  color: theme.palette.secondary.main,
  background: theme.palette.primary.main,
  padding: theme.spacing(1.5, 0),
}));

type ReminderInfoPanelProps = {
  web?: boolean;
  reminderData?: ReminderData;
  handleEdit: () => void;
  handleDelete: () => void;
};
const ReminderInfoPanel: FC<ReminderInfoPanelProps> = ({
  web,
  reminderData,
  handleEdit,
  handleDelete,
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography
        sx={{ display: web ? 'block' : 'none', marginBottom: theme.spacing(2) }}
      >
        {reminderData?.allDay
          ? moment(reminderData.reminderDateTime).format('MMM D') + ', All Day'
          : moment(reminderData?.reminderDateTime).format('MMM D, HH:mm')}
      </Typography>
      <Typography variant={'h1'} mb={3} textTransform={'capitalize'}>
        {reminderData?.category}
      </Typography>
      <InfoCardMeal heading={reminderData?.title || ''} body={reminderData?.info || ''} />
      <Box
        gap={3}
        marginY={3}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <RemoveButton
          variant={'outlined'}
          onClick={handleDelete}
          startIcon={<DeleteIcon />}
        >
          {t('buttons.remove')}
        </RemoveButton>
        <EditReminderButton variant={'contained'} onClick={handleEdit}>
          {t('buttons.editReminder')}
        </EditReminderButton>
      </Box>
    </Box>
  );
};

export default ReminderInfoPanel;
