import { activityLevels } from '@hdcorner/ui-library';

const calculateBMR = (it: any) => {
  let calories = 0;

  if (it.sex.toLowerCase() === 'male') {
    calories = 9.99 * it.weight + 6.25 * it.height - 4.92 * it.age - 161;
  }

  if (it.sex.toLowerCase() === 'female') {
    calories = 9.99 * it.weight + 6.25 * it.height - 4.92 * it.age - 5;
  }

  return calories;
};

const calculateActivityLevel = (data: any, cal: any) => {
  switch (data.activityLevel) {
    case 'light':
      return cal * activityLevels.light.value;
    case 'moderate':
      return cal * activityLevels.moderate.value;
    case 'heavy':
      return cal * activityLevels.heavy.value;
    default:
      return cal;
  }
};

const calculateCalorieGoal = (cal: any, data: any) => {
  switch (data.goal) {
    case 'lose_weight':
      return cal - data.changePerWeek;
    case 'muscle_gain':
      return cal + data.changePerWeek;
    case 'maintain_weight':
      return cal;
    default:
      return cal;
  }
};

const calculateCalories = (formulaData: {
  sex: string;
  age: number;
  changePerWeek: number;
  weight: number;
  height: number;
  activityLevel: 'light' | 'moderate' | 'heavy';
  goal: 'lose_weight' | 'muscle_gain' | 'maintain_weight';
  dietType: 'standard' | 'low_carb' | 'low_fat' | 'high_protein';
}): number => {
  const bmr = calculateBMR(formulaData);
  const activity = calculateActivityLevel(formulaData, bmr);
  const finalTally = calculateCalorieGoal(activity, formulaData);

  return Math.round(finalTally);
};

export { calculateCalories };
