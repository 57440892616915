import * as React from 'react';
import { SVGProps } from 'react';

const DeleteBucketIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={15} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.666 2.834h-3.333v-2a.667.667 0 0 0-.667-.667H5.333a.667.667 0 0 0-.667.667v2H1.333a.667.667 0 0 0 0 1.333h.745l1.26 10.083a.667.667 0 0 0 .662.584h8a.667.667 0 0 0 .661-.584l1.26-10.083h.745a.667.667 0 1 0 0-1.333ZM6 1.5h4v1.334H6V1.5Zm5.411 12H4.59L3.421 4.167h9.156L11.41 13.5Z"
      fill={props.color}
    />
  </svg>
);

export default DeleteBucketIcon;
