import LipidGroupDetails from './LipidGroupDetails';
import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  useGetLipidFirstLogQuery,
  useGetLipidGoalQuery,
  useGetLipidGraphDataQuery,
  useGetLipidLastLogQuery,
  useGetLipidLogsQuery,
  useGetOverallLipidQuery,
} from './queries/lipidQueries';
import moment from 'moment';
import { useAppSelector } from '../../redux/hooks';
import useTimeframe from '../../hooks/useTimeframe';
import { LipidGoal, LipidLog, minMaxAvg, minMaxObj } from '@hdcorner/ui-library';
import { useTranslation } from 'react-i18next';
import { goalProgress } from '../../utils/goalProgress';

type Props = {
  onAddGoalClick: (type: string) => void;
  onEditGoalClick: (goal: LipidGoal) => void;
  onRemoveGoalClick: (goal: LipidGoal) => void;
};
const LdlCholesterolSection: FC<Props> = ({
  onAddGoalClick,
  onEditGoalClick,
  onRemoveGoalClick,
}) => {
  const { t } = useTranslation();

  const [ldlLogsParams, setLdlLogsParams] = useState({
    skip: 0,
    limit: 8,
    sort: ['-logDate'],
  });

  const { dateFilter, customDateFilter } = useAppSelector(state => state.layout);

  const timeframe = useTimeframe(
    dateFilter,
    customDateFilter?.start,
    customDateFilter?.end,
  );

  const { data: lastLog } = useGetLipidLastLogQuery();
  const { data: ldlGoalRes } = useGetLipidGoalQuery({ type: 'ldl' });
  const { data: lipidOverallData } = useGetOverallLipidQuery(timeframe);
  const { data: lipidLogs, isFetching: ldlLogsFetching } = useGetLipidLogsQuery({
    params: { ...ldlLogsParams },
    ...timeframe,
  });
  const { data: chartDataRes } = useGetLipidGraphDataQuery({
    params: { metric: 'ldl', ...timeframe },
  });

  const { data: ldlFirstLog } = useGetLipidFirstLogQuery(
    {
      startingDate:
        ldlGoalRes && ldlGoalRes.length > 0
          ? (ldlGoalRes as LipidGoal[])[0].startDate
          : '',
    },
    { skip: ldlGoalRes ? ldlGoalRes.length === 0 : true },
  );

  const getOverallData = useCallback(() => {
    return minMaxAvg(lipidOverallData, 'ldl');
  }, [lipidOverallData]);

  const goalData = useMemo(() => {
    const ldlGoal = ldlGoalRes ? ldlGoalRes[0] : null;
    const lastLogItem = lastLog ? lastLog.documents[0] : null;
    let ldlFirst = ldlFirstLog ? ldlFirstLog.documents[0] : null;

    let progress = 0;

    if (ldlGoal && ldlFirst && lastLogItem) {
      const startingMeasurement = ldlFirst.ldl || 0;
      const currentMeasurement = lastLogItem.ldl || 0;
      const goalMeasurement = parseFloat(ldlGoal.goal.measurement) || 0;
      progress = goalProgress(currentMeasurement, goalMeasurement, startingMeasurement);
    }

    if (ldlGoal && !ldlFirst) {
      ldlFirst = lastLogItem;
    }

    return {
      progress: progress ? progress + '%' : '-',
      startingMeasurement: ldlFirst ? ldlFirst.ldl : undefined,
      latestMeasurement: lastLogItem ? lastLogItem.ldl : undefined,
      goal: ldlGoal ? parseFloat(ldlGoal.goal.measurement) : undefined,
      startingDate: ldlGoal?.startDate
        ? moment(ldlGoal.startDate).format('MMMM Do, YYYY')
        : undefined,
    };
  }, [ldlFirstLog, ldlGoalRes, lastLog]);

  const chartData = useMemo(() => {
    if (chartDataRes) {
      const logs = chartDataRes.logs;
      if (!logs) return [];

      return logs.map((log: LipidLog) => {
        return {
          date: log.logDate,
          value: log.ldl,
        };
      });
    }
    return [];
  }, [chartDataRes]);

  const overallData = useMemo(() => {
    return minMaxObj('LDL Cholesterol', getOverallData);
  }, [getOverallData]);

  const inDepthData = useMemo(() => {
    if (lipidLogs && lipidLogs.documents.length > 0) {
      return lipidLogs.documents
        .filter(log => log.ldl)
        .map((log: LipidLog) => {
          return {
            title: log.ldl,
            titleSub: t('measurements.mgdl'),
            data: {
              [t('dashboard.dashcards.lipid.ldl')]: { value: `${log.ldl} mg/dl` },
              [t('inDepth.timeOfCalc')]: { value: moment(log.logDate).format('h:mm A') },
              [t('inDepth.dateOfCalc')]: {
                value: moment(log.logDate).format('MMM Do, YYYY'),
              },
            },
          };
        });
    }
    return [];
  }, [lipidLogs]);

  const handleAddGoal = () => {
    onAddGoalClick('ldl');
  };

  const handleEditGoal = () => {
    if (ldlGoalRes) {
      onEditGoalClick(ldlGoalRes[0]);
    }
  };

  const handleRemoveGoal = () => {
    if (ldlGoalRes) {
      onRemoveGoalClick(ldlGoalRes[0]);
    }
  };

  return (
    <LipidGroupDetails
      goalData={goalData}
      chartData={chartData}
      inDepthData={inDepthData}
      overallData={overallData}
      listParams={ldlLogsParams}
      isFetching={ldlLogsFetching}
      handleAddGoal={handleAddGoal}
      totalCount={lipidLogs?.count}
      handleEditGoal={handleEditGoal}
      handleRemoveGoal={handleRemoveGoal}
      count={lipidLogs?.documents.length}
      sectionName={t('lipid.labels.ldlChol')}
      setListParams={params => setLdlLogsParams({ ...ldlLogsParams, ...params })}
    />
  );
};

export default LdlCholesterolSection;
