import { useEffect, useMemo, useState } from 'react';
import {
  useGetFirstLogOfGoalQuery,
  useGetLatestLogQuery,
  useGetPressureGoalQuery,
} from '../../hypertension/queries/hypertensionQueries';
import { goalProgress } from '../../../utils/goalProgress';

const useGetSystolicGoalData = () => {
  const [startingDate, setStartingDate] = useState<string>();

  const { data: pressureGoal } = useGetPressureGoalQuery();

  const { data: current } = useGetLatestLogQuery();
  const { data: starting } = useGetFirstLogOfGoalQuery(
    {
      start: startingDate || '',
    },
    { skip: !startingDate },
  );

  useEffect(() => {
    if (pressureGoal && pressureGoal.length > 0) {
      setStartingDate(pressureGoal[0].startDate);
    }
  }, [pressureGoal]);

  return useMemo(() => {
    if (!pressureGoal || pressureGoal.length === 0) return null;

    let goalSystolic;
    let startSystolic;
    let currentSystolic;
    let progressSystolic = 0;

    if (pressureGoal && pressureGoal.length > 0) {
      goalSystolic = pressureGoal[0].goal.systolic;
    }

    if (starting && starting.documents.length > 0) {
      startSystolic = starting.documents[0].systolic;
    }

    if (current && current.documents.length > 0) {
      currentSystolic = current.documents[0].systolic;
      startSystolic = startSystolic || currentSystolic;
    }

    if (goalSystolic && startSystolic && currentSystolic) {
      progressSystolic = goalProgress(currentSystolic, goalSystolic, startSystolic);
    }

    return { progressSystolic, goalSystolic, startSystolic, currentSystolic };
  }, [pressureGoal, starting, current]);
};

export default useGetSystolicGoalData;
