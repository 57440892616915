import api from '../../../../redux/api';
import moment from 'moment/moment';
import {
  ApiPagination,
  GetStepsLog,
  PaginationResult,
  StepsGoal,
  StepsLog,
} from '@hdcorner/ui-library';

const stepsQueries = api.injectEndpoints({
  endpoints: build => ({
    getStepsLogs: build.query<
      PaginationResult<GetStepsLog>,
      { params: ApiPagination; start?: string; end?: string }
    >({
      query: ({ params, start, end }) => ({
        method: 'GET',
        params: { ...params, start, end },
        url: '/database/function/getStepLogs',
      }),
      providesTags: ['GetStepsLog'],
      serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, otherArgs) => {
        if (otherArgs.arg.params.skip === 0) {
          currentCache.documents = newItems?.documents;
        } else {
          currentCache?.documents.push(...newItems?.documents);
        }
        currentCache.count = newItems?.count;
      },
      forceRefetch: ({ previousArg, currentArg }) => {
        return (
          previousArg?.end !== currentArg?.end ||
          previousArg?.start !== currentArg?.start ||
          previousArg?.params?.sort !== currentArg?.params?.sort ||
          previousArg?.params?.skip !== currentArg?.params?.skip ||
          previousArg?.params?.limit !== currentArg?.params?.limit
        );
      },
    }),

    getLatestStepsLogs: build.query<PaginationResult<GetStepsLog>, void>({
      query: () => ({
        method: 'GET',
        url: '/database/function/getStepLogs',
        params: {
          skip: 0,
          limit: 100,
          sort: '-logDate',
          start: moment().startOf('day').toISOString(),
        },
      }),
      providesTags: ['GetStepsLog'],
    }),

    getTodayStepsLogs: build.query<
      PaginationResult<GetStepsLog>,
      { fromDevice?: boolean } | void
    >({
      query: params => ({
        method: 'GET',
        url: '/database/function/getStepLogs',
        params: {
          skip: 0,
          limit: 100,
          fromDevice: params?.fromDevice,
          end: moment().endOf('day').toISOString(),
          start: moment().startOf('day').toISOString(),
        },
      }),
      providesTags: ['GetStepsLog'],
    }),

    updateStepsLog: build.mutation<void, { _id: string; steps: number }>({
      query: ({ _id, steps }) => ({
        body: { steps },
        method: 'PATCH',
        url: `/database/UserStepsLog/${_id}`,
      }),
      invalidatesTags: ['GetStepsLog'],
    }),

    createStepsLog: build.mutation<void, Omit<StepsLog, '_id' | 'createdAt'>>({
      query: ({ ...rest }) => ({
        body: rest,
        method: 'POST',
        url: '/database/function/createStepsLog',
      }),
      invalidatesTags: ['GetStepsLog'],
    }),

    getUserGoalSteps: build.query<StepsGoal[], void>({
      query: () => ({
        method: 'GET',
        params: { goalType: 'steps' },
        url: '/database/function/getUserGoal',
      }),
      providesTags: ['StepsGoal'],
    }),

    postUserGoalSteps: build.mutation<void, StepsGoal & { userId: string }>({
      query: ({ userId, ...rest }) => ({
        body: rest,
        method: 'POST',
        url: '/hdCornerService/user/goal',
        params: { scope: `Exercise:${userId}` },
      }),
      invalidatesTags: ['StepsGoal'],
    }),

    patchUserGoalSteps: build.mutation<StepsGoal[], StepsGoal & { userId: string }>({
      query: ({ userId, _id, ...rest }) => ({
        body: rest,
        method: 'PATCH',
        url: `/hdCornerService/user/goal/${_id}`,
        params: { scope: `Exercise:${userId}` },
      }),
      invalidatesTags: ['StepsGoal'],
    }),

    deleteUserGoalSteps: build.mutation<void, { _id: string }>({
      query: ({ _id }) => ({
        method: 'DELETE',
        url: `/database/UserGoal/${_id}`,
      }),
      invalidatesTags: ['StepsGoal'],
    }),

    getStepsGraphData: build.query<
      { graphData: any[]; total?: number; avg?: number },
      { timezone: string; start?: string; end?: string; timeframe?: string }
    >({
      query: ({ start, end, timeframe, timezone }) => ({
        method: 'GET',
        url: '/hdCornerService/user/steps/graph',
        params: { start, end, timeframe, timezone },
      }),
      providesTags: ['GetStepsLog'],
    }),
  }),
});

export const {
  useGetStepsLogsQuery,
  useGetUserGoalStepsQuery,
  useUpdateStepsLogMutation,
  useCreateStepsLogMutation,
  useGetStepsGraphDataQuery,
  useGetTodayStepsLogsQuery,
  useGetLatestStepsLogsQuery,
  usePostUserGoalStepsMutation,
  usePatchUserGoalStepsMutation,
  useLazyGetTodayStepsLogsQuery,
  useDeleteUserGoalStepsMutation,
} = stepsQueries;

export default stepsQueries;
