import { AuthState } from './types/AuthState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import signInQueries from './queries/signInQueries';
import {
  removeAccessToken,
  removeRefreshToken,
  storeAccessToken,
  storeRefreshToken,
} from '../../utils/TokenStorage';
import personalInfoQueries from '../profile/queries/personalInfoQueries';
import { AuthUser, QuestionnaireAnswer, UserData } from '@hdcorner/ui-library';

const initialState: AuthState = {
  data: {
    user: null,
    token: null,
    refreshToken: null,
  },
  meta: {
    error: null,
    status: 'idle',
    authResolved: false,
    isAuthenticated: false,
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.meta.authResolved = true;
      state.data.token = action.payload;

      storeAccessToken(action.payload).then(r => console.log('ok'));
    },
    setRefreshToken: (state, action: PayloadAction<string>) => {
      state.data.refreshToken = action.payload;
      storeRefreshToken(action.payload).then(r => console.log('ok'));
    },
    logout: state => {
      state.data.user = null;
      state.data.token = null;
      state.data.refreshToken = null;
      state.meta.isAuthenticated = false;

      removeAccessToken().then(() => console.log('removeAccessToken: ok'));
      removeRefreshToken().then(() => console.log('removeRefreshToken: ok'));

      localStorage.clear();
    },
    setAuthUser: (state, action: PayloadAction<Partial<AuthUser>>) => {
      const user = state.data.user as UserData;
      state.data.user = {
        ...user,
        authUser: { ...(user.authUser as AuthUser), ...action.payload },
      };
    },
    setUserData: (state, action: PayloadAction<Omit<UserData, 'authUser'>>) => {
      const user = state.data.user as UserData;
      state.data.user = { ...user, ...action.payload };
    },
    setQuestionnaireAnswer: (state, action: PayloadAction<QuestionnaireAnswer>) => {
      const user = state.data.user as UserData;
      state.data.user = {
        ...user,
        questionnaireAnswer: action.payload.answer,
        questionnaireOutcome: action.payload.outcome,
        questionnaireResultType: action.payload.resultType,
      };
    },
    setTermsAccepted: state => {
      const user = state.data.user as UserData;
      const authUser = user?.authUser as AuthUser;
      const newUser = {
        ...user,
        authUser: {
          ...authUser,
          privacyPolicyAccepted: true,
          termsAndConditionsAccepted: true,
        },
      };
      state.data.user = { ...newUser };
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(signInQueries.endpoints.signIn.matchFulfilled, (state, { payload }) => {
        state.data.token = payload.accessToken;
        state.data.refreshToken = payload.refreshToken;
        storeAccessToken(payload.accessToken).then(r => console.log('ok'));
        storeRefreshToken(payload.refreshToken).then(r => console.log('ok'));
      })
      .addMatcher(
        signInQueries.endpoints.getAuthUser.matchFulfilled,
        (state, action: PayloadAction<AuthUser[]>) => {
          const { role } = action.payload[0];
          if (role) {
            const isUser = role === 'user';
            if (!isUser) {
              state.data.user = null;
              state.data.token = null;
              state.data.refreshToken = null;
              state.meta.authResolved = false;
              state.meta.isAuthenticated = false;
              removeAccessToken().then(() => console.log('removeAccessToken: ok'));
              removeRefreshToken().then(() => console.log('removeRefreshToken: ok'));
              return;
            }
          }

          if (action.payload.length === 0) {
            state.meta.isAuthenticated = false;
            return;
          }

          const authUser = {
            ...action.payload[0],
          };

          let updatedUser: UserData;
          const user = state.data.user;

          if (user) {
            updatedUser = {
              ...user,
              authUser,
            };
          } else {
            updatedUser = {
              authUser,
              height: 0,
              weight: 0,
              phone: '',
              diabetes: '',
              smoker: false,
              premiumUser: false,
              hypertension: false,
              dyslipidemia: false,
              underMedication: false,
              socialSecurityNumber: '',
              questionnaireAnswer: '',
              questionnaireOutcome: '',
              questionnaireResultType: '',
            };
          }
          state.data.user = updatedUser;
        },
      )
      .addMatcher(
        personalInfoQueries.endpoints.getUserData.matchFulfilled,
        (state, action: PayloadAction<UserData[]>) => {
          const authUser: AuthUser = state.data.user?.authUser as AuthUser;
          if (action.payload.length > 0) {
            state.data.user = { ...action.payload[0], authUser: authUser };
          }
          state.meta.isAuthenticated = true;
        },
      );
  },
});

export const {
  logout,
  setAuthUser,
  setUserData,
  setAccessToken,
  setRefreshToken,
  setTermsAccepted,
  setQuestionnaireAnswer,
} = authSlice.actions;

export default authSlice.reducer;
