import HorizontalCalendar from '../controls/HorizontalCalendar';
import ModalMobile from './ModalMobile';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import CustomButton from '../controls/CustomButton';
import moment from 'moment';
import ToggleButtons from '../layout/ToggleButtons';
import { useTranslation } from 'react-i18next';

/** Headings used in admin - translation not needed */
const headings = [
  { label: 'all data', value: 'all' },
  { label: 'this month', value: 'month' },
  { label: 'this year', value: 'year' },
  { label: 'custom', value: 'custom' },
];

type CalendarModalType = {
  open: boolean;
  admin?: boolean;
  openMonthly?: boolean;
  selectFutureDates?: boolean;
  selectedDate: string[] | string;
  setOpen: (bool: boolean) => void;
  saveDate: (date: string[] | string | any) => void;
};
const CalendarModal: FC<CalendarModalType> = ({
  open,
  setOpen,
  saveDate,
  selectedDate,
  admin = false,
  openMonthly = true,
  selectFutureDates = false,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [toggleValue, setToggleValue] = useState<string>('all');
  // Used for keeping the values selected from calendar modal
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const [startDate, setStartDate] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!admin) return;
    switch (toggleValue) {
      case 'all':
        setEndDate(undefined);
        setStartDate(undefined);
        break;
      case 'month':
        setEndDate(moment().endOf('month').toISOString());
        setStartDate(moment().startOf('month').toISOString());
        break;
      case 'year':
        setEndDate(moment().endOf('year').toISOString());
        setStartDate(moment().startOf('year').toISOString());
        break;
      default:
        break;
    }
  }, [toggleValue]);

  useEffect(() => {
    if (!selectedDate) return;
    if (Array.isArray(selectedDate) && selectedDate.length === 0) return;

    if (Array.isArray(selectedDate)) {
      if (selectedDate[0]) {
        const start = moment(selectedDate[0]).toISOString();
        setStartDate(start);
      }
      if (selectedDate[1]) {
        const end = moment(selectedDate[1]).toISOString();
        setEndDate(end);
      }
    }

    if (typeof selectedDate === 'string') {
      const start = moment(selectedDate).toISOString();
      setStartDate(start);
    }
  }, [selectedDate]);

  const getSelectedDate = useMemo(() => {
    if (typeof selectedDate === 'string') {
      if (startDate) return moment(startDate).toDate();
    }

    const start = startDate ? moment(startDate).toDate() : undefined;
    const end = endDate ? moment(endDate).toDate() : undefined;
    return [start, end];
  }, [startDate, endDate, selectedDate]);

  const handleDateChange = (dateRange: (Date | undefined)[] | Date) => {
    if (!dateRange) return;
    if (Array.isArray(dateRange)) {
      if (dateRange[0]) {
        const currentHour = moment().get('hour');
        const currentMinute = moment().get('minute');
        const date = moment(dateRange[0])
          .set('hour', currentHour)
          .set('minute', currentMinute);
        setStartDate(date.toISOString());
      } else {
        setStartDate(undefined);
      }

      if (dateRange[1]) {
        const currentHour = moment().get('hour');
        const currentMinute = moment().get('minute');
        const date = moment(dateRange[1])
          .set('hour', currentHour)
          .set('minute', currentMinute);
        setEndDate(date.toISOString());
      } else {
        setEndDate(undefined);
      }
      return;
    }

    const currentHour = moment().get('hour');
    const currentMinute = moment().get('minute');
    const date = moment(dateRange).set('hour', currentHour).set('minute', currentMinute);
    setStartDate(date.toISOString());
  };

  const handleChange = (newValue: string) => {
    setToggleValue(newValue);
  };

  const handleSave = () => {
    const end = moment(endDate).toISOString();
    const start = moment(startDate).toISOString();
    if (Array.isArray(selectedDate)) {
      saveDate([start, end]);
    } else {
      saveDate(start);
    }
    setOpen(false);
  };

  return (
    <ModalMobile open={open} setOpen={setOpen}>
      <Box
        p={2}
        gap={2}
        display={'flex'}
        flexDirection={'column'}
        bgcolor={
          theme.palette.mode === 'dark'
            ? theme.palette.secondary.main
            : theme.palette.secondary.light
        }
      >
        {admin && (
          <>
            <Typography
              variant={'subtitle2'}
              sx={{
                color: theme.palette.kmColorsCoolGrey.main,
                fontWeight: theme.typography.fontWeightMedium,
              }}
            >
              Choose a date
            </Typography>
            <ToggleButtons
              size={'medium'}
              value={toggleValue}
              headings={headings}
              handleChange={handleChange}
            />
          </>
        )}
        <Box>
          {(!admin || toggleValue === 'custom') && (
            <HorizontalCalendar
              openMonthly={openMonthly}
              selectedDate={getSelectedDate}
              selectFutureDates={selectFutureDates}
              handleChange={date => handleDateChange(date)}
              sx={{
                background:
                  theme.palette.mode === 'dark'
                    ? theme.palette.background.default
                    : theme.palette.secondary.light,
              }}
            />
          )}
        </Box>
        <Box display={'flex'} justifyContent={'end'} gap={2} alignItems={'center'}>
          <CustomButton onClick={() => setOpen(false)}>Cancel</CustomButton>
          <CustomButton variant={'contained'} onClick={() => handleSave()}>
            {admin ? 'Save' : t('buttons.save')}
          </CustomButton>
        </Box>
      </Box>
    </ModalMobile>
  );
};

export default CalendarModal;
