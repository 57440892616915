import React, { FC, useState } from 'react';
import { Table, TableBody } from '@mui/material';
import {
  HeadCell,
  HealthProfTableHeading,
  HealthProfTableRows,
  HealthProfTableType,
} from '@hdcorner/ui-library';
import { useTranslation } from 'react-i18next';
import { useIonRouter } from '@ionic/react';
import {
  useCreateChatRoomMutation,
  useLazyGetChatRoomByParticipantsQuery,
} from '../chat/queries/chatQueries';
import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import useAlert from '../../hooks/useAlert';

type Order = 'asc' | 'desc';

type HealthProfessionalsTableType = {
  data: HealthProfTableType[];
  handleRemoveProfessional: (hcpId?: string, healthProfName?: string) => void;
};

const HealthProfessionalsTable: FC<HealthProfessionalsTableType> = ({
  data,
  handleRemoveProfessional,
}) => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { presentError } = useAlert();

  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState<Order>('asc');
  const [selected, setSelected] = useState<string[]>([]);

  const userId = useAppSelector(
    (state: RootState) => state.auth.data.user?.authUser?._id,
  );

  const [createChat] = useCreateChatRoomMutation();
  const [getChatRoom] = useLazyGetChatRoomByParticipantsQuery();

  const headCells: HeadCell[] = [
    { _id: 'name', label: t('personalDetails.name'), align: 'left' },
    { _id: 'email', label: t('personalDetails.email'), align: 'left' },
    { _id: 'gender', label: t('personalDetails.gender'), align: 'center' },
    { _id: 'phoneNumber', label: t('personalDetails.phoneNumber'), align: 'center' },
    { _id: 'occupation', label: t('personalDetails.occupation'), align: 'center' },
    { _id: 'actions', label: t('personalDetails.actions'), align: 'center' },
  ];

  const handleSelectAll = () => {
    if (selected.length === data.length) {
      setSelected([]);
    } else {
      setSelected(data?.map(row => row._id));
    }
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createData = (row: any) => {
    return [row.fullName, row.email, row.gender, row.phone, row.speciality];
  };

  const handleClick = (id: string) => {
    const newSelected = [...selected];
    if (selected.includes(id)) {
      const itemIndex = selected.findIndex(selectedId => selectedId === id);
      newSelected.splice(itemIndex, 1);
    } else {
      newSelected.push(id);
    }
    setSelected(newSelected);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const startConversation = async (hcpId: string) => {
    if (!userId) {
      presentError('errors.generic.error01');
      return;
    }
    try {
      const res = await getChatRoom({ participants: userId + '-' + hcpId }).unwrap();
      if (res && res.length > 0) {
        const roomId = res[0]._id;
        router.push(`/dashboard/chat/${roomId}`);
      } else {
        const roomName = `room-${[userId, hcpId].sort().join('-')}`;
        const res = await createChat({ roomName, users: [userId, hcpId] }).unwrap();
        if (res && res.roomId) {
          router.push(`/dashboard/chat/${res.roomId}`);
        }
      }
    } catch (e) {
      console.log(e);
      presentError('errors.generic.error01');
    }
  };

  return (
    <Table>
      <HealthProfTableHeading
        order={order}
        orderBy={orderBy}
        headCells={headCells}
        totalRowsCount={data.length}
        totalRowsSelected={selected.length}
        onSelectAllClick={handleSelectAll}
        onRequestSort={handleRequestSort}
        onCancelClick={() => setSelected([])}
      />
      <TableBody>
        {data.map(row => (
          <HealthProfTableRows
            key={row.fullName}
            rowCells={createData(row)}
            checked={isSelected(row._id)}
            selected={selected.length >= 1}
            avatar={row.profilePicture || ''}
            clinicalCoach={row.clinicalCoach}
            onClick={() => handleClick(row._id)}
            handleChatClick={() => startConversation(row.hcpId)}
            handleDeleteClick={() => handleRemoveProfessional(row.hcpId, row.fullName)}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default HealthProfessionalsTable;
