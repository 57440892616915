import {
  ChatSendMessageIcon,
  ComponentsLayout,
  CustomButton,
  CustomInput,
  DropdownMenu,
  FileRecordCard,
  ModalMobile,
  NoContentCloudIcon,
  theme,
  useDebounce,
  UserRecord,
} from '@hdcorner/ui-library';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import PageLayout from '../../../components/PageLayout';
import { fileCategories } from '../constants';
import SearchIcon from '../../../assets/icons/SearchIcon';
import UploadFileModal from './UploadFileModal';
import {
  useDeleteUserRecordMutation,
  useGetUserRecordsQuery,
  useShareFilesMutation,
} from '../queries/recordsQueries';
import { bytesToSize, findCategoryLabel } from '../utils';
import ViewUserRecord from './ViewUserRecord';
import useAlert from '../../../hooks/useAlert';
import ModalSelectRecordRecipient from './ModalSelectRecordRecipient';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../../../utils/useConvertJSON';

const Records = () => {
  const { t } = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const { presentWarning, presentError, presentSuccess } = useAlert();

  const [search, setSearch] = useState('');
  const [params, setParams] = useState<any>({
    fileType: undefined,
    category: undefined,
    searchText: undefined,
  });
  const [filesToSend, setFilesToSend] = useState<UserRecord[]>([]);
  const [addFileModalState, setAddFileModalState] = useState(false);
  const [selectedFile, setSelectedFile] = useState<UserRecord | null>(null);
  const [openSelectRecipient, setOpenSelectRecipient] = useState<boolean>(false);

  const searchDebounce = useDebounce(search, 500);

  const [shareFile] = useShareFilesMutation();
  const [deleteFile] = useDeleteUserRecordMutation();

  const { data: recordsRes, error } = useGetUserRecordsQuery({ ...params });

  useEffect(() => {
    setParams({
      ...params,
      searchText: searchDebounce === '' ? undefined : searchDebounce,
    });
  }, [searchDebounce]);

  useEffect(() => {
    if (error) {
      presentError(t('errors.records.errorFetch'));
    }
  }, [error]);

  const images = useMemo(() => {
    if (!recordsRes) return [];

    return recordsRes.filter(record => record.fileType === 'image');
  }, [recordsRes]);

  const files = useMemo(() => {
    if (!recordsRes) return [];

    return recordsRes.filter(record => record.fileType === 'file');
  }, [recordsRes]);

  const handleSendRecord = (data: { hcpId?: string; groupChatRoom?: string }) => {
    if (!filesToSend || filesToSend.length === 0) return;

    shareFile({
      hcpId: data.hcpId,
      groupChatRoom: data.groupChatRoom,
      recordIds: filesToSend.map(f => f._id),
    })
      .unwrap()
      .then(() => {
        setFilesToSend([]);
        setOpenSelectRecipient(false);
        if (filesToSend.length === 1) {
          presentSuccess(t('errors.records.successSend'));
        } else {
          presentSuccess(t('errors.records.successSendMultiple'));
        }
      })
      .catch(() => {
        presentError(t('errors.records.errorSend'));
      });
  };

  const handleAddRecords = () => {
    setAddFileModalState(true);
  };

  const handleFileTypeChange = (value: string) => {
    setParams({ ...params, fileType: value === '' ? undefined : value });
  };

  const handleFileCategoryChange = (value: string) => {
    setParams({ ...params, category: value === '' ? undefined : value });
  };

  const handleEditFile = (file: UserRecord) => {
    setSelectedFile(file);
  };

  const handleDeleteFile = (fileId: string) => {
    deleteFile({ recordId: fileId })
      .unwrap()
      .catch(() => {
        presentError(t('errors.records.errorDelete'));
      });
  };

  /** Handle multiple file selection to send - including add/remove */
  const handleSelectFile = (file: UserRecord) => {
    setFilesToSend(prevFiles => {
      const existingIndex = prevFiles.findIndex(f => f._id === file._id);

      if (existingIndex !== -1) {
        const updatedFiles = [...prevFiles];
        updatedFiles.splice(existingIndex, 1);
        return updatedFiles;
      } else {
        return [...prevFiles, { ...file, selected: true }];
      }
    });
  };

  const sendFiles = () => {
    if (filesToSend?.length === 0 || !filesToSend) {
      presentWarning(t('errors.records.warningSelect'));
      return;
    }
    setOpenSelectRecipient(true);
  };

  const endButtons = (
    <Box
      gap={2}
      flex={1}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'flex-end'}
    >
      <CustomButton
        variant={'text'}
        onClick={() => {
          setFilesToSend([]);
        }}
        disabled={filesToSend?.length === 0 || !filesToSend}
        style={{
          fontWeight: theme.typography.fontWeightBold,
          fontSize: theme.typography.subtitle2.fontSize,
        }}
        sx={{
          '&:disabled': {
            backgroundColor: 'transparent',
            color: theme.palette.kmColorsCoolGrey.main,
          },
        }}
      >
        {t('buttons.deselect')}
      </CustomButton>
      {!mobile && (
        <CustomButton variant={'text'} onClick={sendFiles}>
          <Box gap={1} display={'flex'} alignItems={'center'}>
            <Typography
              variant={'subtitle2'}
              color={theme.palette.primary.main}
              sx={{ whiteSpace: 'nowrap', fontWeight: theme.typography.fontWeightBold }}
            >
              {t('buttons.send')}
            </Typography>
            <ChatSendMessageIcon color={theme.palette.primary.main} />
          </Box>
        </CustomButton>
      )}
    </Box>
  );

  const renderFileCard = (file: UserRecord, type: 'image' | 'file') => {
    const isSelected = filesToSend?.some(f => f._id === file._id && f.selected);

    return (
      <FileRecordCard
        type={type}
        key={file._id}
        name={file.fileName}
        selected={isSelected}
        fileSize={bytesToSize(file.fileSize)}
        handleEdit={() => handleEditFile(file)}
        handleSelect={() => handleSelectFile(file)}
        category={t(findCategoryLabel(file.category))}
        handleDelete={() => handleDeleteFile(file._id)}
      />
    );
  };

  const dropdownMenu = (
    <DropdownMenu
      noBorder
      fullWidth={mobile}
      alternativeBackground
      value={params.category ?? ''}
      setValue={handleFileCategoryChange}
      menuItems={useConvertJSON(fileCategories)}
      placeholder={`${t('records.labels.allCat')}`}
    />
  );

  const renderToolbar = () => {
    return (
      <Box>
        <Box
          borderRadius={1}
          display={'flex'}
          gap={mobile ? 1 : 6}
          alignItems={'center'}
          padding={mobile ? 0 : theme.spacing(2, 3)}
          sx={{
            backgroundColor: mobile ? 'none' : theme.palette.secondary.light,
          }}
        >
          <DropdownMenu
            noBorder
            fullWidth={mobile}
            alternativeBackground
            value={params.fileType ?? ''}
            setValue={handleFileTypeChange}
            placeholder={`${t('records.labels.allRec')}`}
            menuItems={[
              { label: t('records.labels.files'), value: 'file' },
              { label: t('records.labels.imgs'), value: 'image' },
            ]}
          />

          {!mobile && (
            <Box
              sx={{
                width: '2px',
                height: '24px',
                background: theme.palette.secondary.dark,
              }}
            />
          )}

          {dropdownMenu}

          {!mobile && (
            <Box
              sx={{
                width: '2px',
                height: '24px',
                background: theme.palette.secondary.dark,
              }}
            />
          )}
          {!mobile && (
            <CustomInput
              value={search}
              handleChange={setSearch}
              placeholder={`${t('records.labels.browse')}`}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton type="submit" aria-label="search">
                    <SearchIcon color={theme.palette.primary.light} />
                  </IconButton>
                </InputAdornment>
              }
            />
          )}

          {!mobile && endButtons}
        </Box>
        {mobile && (
          <>
            <CustomInput
              fullWidth
              value={search}
              alternativeBackground
              handleChange={setSearch}
              placeholder={`${t('records.labels.allCat')}`}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton type="submit" aria-label="search">
                    <SearchIcon color={theme.palette.primary.light} />
                  </IconButton>
                </InputAdornment>
              }
            />
            {endButtons}
          </>
        )}
      </Box>
    );
  };

  return (
    <PageLayout
      fabClick={handleAddRecords}
      defaultHref={'/dashboard/profile'}
      headerTitle={t('records.labels.myRec')}
      endButtons={[
        {
          onClick: sendFiles,
          icon: <ChatSendMessageIcon />,
        },
      ]}
    >
      <ComponentsLayout display={'flex'} flexDirection={'column'} gap={3}>
        {renderToolbar()}
        <Box display={'flex'} gap={4} flexDirection={'column'}>
          {/*Images section*/}
          {params.fileType !== 'file' && (
            <Box display={'flex'} gap={2} flexDirection={'column'}>
              <Box display={'flex'} gap={1}>
                <Typography textTransform={'capitalize'}>
                  {t('records.labels.imgs')}
                </Typography>
                <Typography>(.jpg, .png)</Typography>
              </Box>
              {!mobile && (
                <Box display={'flex'} flexWrap={'wrap'} gap={theme.spacing(1)}>
                  {images.map(file => renderFileCard(file, 'image'))}
                </Box>
              )}
              {mobile && (
                <Grid container spacing={1}>
                  {images.map(file => (
                    <Grid key={file._id} item xs={6}>
                      {renderFileCard(file, 'image')}
                    </Grid>
                  ))}
                </Grid>
              )}
              <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                {images.length === 0 && <NoContentCloudIcon />}
              </Box>
            </Box>
          )}
          {/*Files section*/}
          {params.fileType !== 'image' && (
            <Box display={'flex'} gap={2} flexDirection={'column'}>
              <Box display={'flex'} gap={1}>
                <Typography textTransform={'capitalize'}>
                  {t('records.labels.files')}
                </Typography>
                <Typography>(.pdf, .xls, .csv)</Typography>
              </Box>
              {!mobile && (
                <Box display={'flex'} flexWrap={'wrap'} gap={1}>
                  {files.map(file => renderFileCard(file, 'file'))}
                </Box>
              )}
              {mobile && (
                <Grid container spacing={1}>
                  {files.map(file => (
                    <Grid key={file._id} item xs={6}>
                      {renderFileCard(file, 'file')}
                    </Grid>
                  ))}
                </Grid>
              )}
              <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                {files.length === 0 && <NoContentCloudIcon />}
              </Box>
            </Box>
          )}
        </Box>
      </ComponentsLayout>
      <ModalMobile open={addFileModalState} setOpen={setAddFileModalState}>
        <UploadFileModal dismissModal={() => setAddFileModalState(false)} />
      </ModalMobile>
      <ModalMobile
        open={!!selectedFile}
        setOpen={() => {
          setSelectedFile(null);
        }}
      >
        {selectedFile && <ViewUserRecord selectedFile={selectedFile} />}
      </ModalMobile>
      <ModalSelectRecordRecipient
        open={openSelectRecipient}
        handleSend={handleSendRecord}
        setOpen={setOpenSelectRecipient}
      />
    </PageLayout>
  );
};

export default Records;
