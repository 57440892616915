import {
  customDate,
  DropdownDownArrow,
  DropdownDownArrowDisabled,
  monthYearCustom,
  OptionType,
  ToggleButtonGroupCu,
  ToggleButtons,
} from '@hdcorner/ui-library';
import {
  Box,
  BoxProps,
  FormControl,
  MenuItem,
  Select,
  styled,
  useTheme,
} from '@mui/material';
import React, { FC } from 'react';
import DropdownAllMeasurements from '../../../components/DropdownAllMeasurements';
import { useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../../../utils/useConvertJSON';

const Line = styled(Box)<BoxProps>(({ theme }) => ({
  width: 2,
  height: 32,
  borderRadius: '1px',
  background: theme.palette.secondary.dark,
}));

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(1),
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.subtitle2.fontSize,
  lineHeight: theme.typography.subtitle2.lineHeight,
  '&:first-of-type': {
    marginTop: theme.spacing(2),
  },
  '&:last-child': {
    marginBottom: theme.spacing(2),
  },
}));

const CustomFormControl = styled(FormControl)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '.MuiInputBase-root > div': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  '.MuiInputBase-root > svg': {
    transform: 'translateY(-6px)',
  },
  '.MuiSelect-select.MuiSelect-standard.MuiInputBase-input': {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.subtitle2.fontSize,
    lineHeight: theme.typography.subtitle1.lineHeight,
  },
}));

const CustomBox = styled(Box)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.secondary.main
      : theme.palette.secondary.light,
  display: 'flex',
  borderRadius: '8px',
  alignItems: 'center',
  gap: theme.spacing(3),
  padding: theme.spacing(2, 2),
}));

type UserDataToolbarProps = {
  activeFilter: string;
  options: OptionType[];
  activeSegment?: string;
  activeOption: OptionType;
  disabledDropdown?: boolean;
  changeFilter: (filter: string) => void;
  changeOption: (item: OptionType) => void;
  changeSegment?: (newSegment: string) => void;
  filterOptions?: { label: string; value: string }[];
  segmentOptions?: string[] | { label: string; value: string }[];
};

const UserDataToolbar: FC<UserDataToolbarProps> = ({
  options,
  activeFilter,
  activeOption,
  changeFilter,
  changeOption,
  activeSegment,
  changeSegment,
  disabledDropdown,
  segmentOptions = [],
  filterOptions,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { toggle } = useAppSelector(state => state.hypertension);

  const handleDropdownChange = (event: any) => {
    const newState = options.find(option => option.name === event.target.value);
    changeOption(newState!);
  };

  /** variables defined for toggles to resolve TS errors */
  const convertedDefault = useConvertJSON(monthYearCustom);
  const defaultOptions = filterOptions ? filterOptions : convertedDefault;
  const convertedCustom = useConvertJSON(customDate);

  return (
    <CustomBox>
      <Box display={'flex'} gap={theme.spacing(1)} alignItems={'center'}>
        <CustomFormControl disabled={disabledDropdown}>
          <Select
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
            sx={{
              display: 'flex',
              paddingRight: '8px',
              position: 'relative',
              alignItems: 'center',
            }}
            disableUnderline
            variant={'standard'}
            value={activeOption.name}
            onChange={handleDropdownChange}
            inputProps={{
              IconComponent: disabledDropdown
                ? DropdownDownArrowDisabled
                : DropdownDownArrow,
            }}
          >
            {options.map((option, index) => (
              <CustomMenuItem key={index} value={option.name}>
                <option.icon
                  width={20}
                  height={20}
                  color={disabledDropdown ? '#00000061' : option.color ?? '#222222'}
                />
                {t(option.name)}
              </CustomMenuItem>
            ))}
          </Select>
        </CustomFormControl>
      </Box>
      {defaultOptions && defaultOptions.length > 0 && <Line />}

      {(activeOption.value === 'Hypertension' || activeOption.value === 'Diabetes') && (
        <DropdownAllMeasurements
          currentPage={activeOption.value}
          toolToggle={toggle !== 'indepth'}
          disabledDropdown={disabledDropdown}
        />
      )}

      <Box>
        <ToggleButtons
          buttonBorder
          size={'large'}
          value={activeFilter}
          disabled={disabledDropdown}
          handleChange={changeFilter}
          headings={
            toggle === 'esh' || toggle === '24hrs' ? convertedCustom : defaultOptions
          }
        />
      </Box>

      {segmentOptions && segmentOptions.length > 0 && <Line />}
      <Box>
        <ToggleButtonGroupCu
          setValue={changeSegment}
          headings={segmentOptions}
          value={activeSegment || ''}
        />
      </Box>
    </CustomBox>
  );
};

export default UserDataToolbar;
