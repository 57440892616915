import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import {
  ClickNavCardNutrition,
  ComponentsLayout,
  Medicine,
  Searchbar,
  theme,
  useDebounce,
} from '@hdcorner/ui-library';
import { Box } from '@mui/material';
import { useIonRouter } from '@ionic/react';
import PageLayout from '../../components/PageLayout';
import { useGetSearchMedicinesQuery } from './queries/medicationQueries';
import useQuery from '../../hooks/useQuery';
import { useAppDispatch } from '../../redux/hooks';
import { setMedicine } from './medicationSlice';
import { concatMedicine } from './utils/medicineUtils';
import useAlert from '../../hooks/useAlert';
import InfiniteScrollList from '../../components/InfiniteScrollList';
import { useTranslation } from 'react-i18next';

const MedicationAllMedicines = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const searchParams = useQuery();
  const dispatch = useAppDispatch();
  const { presentError } = useAlert();

  const [search, setSearch] = useState('');
  const [medicineSearchParams, setMedicineSearchParams] = useState({
    skip: 0,
    limit: 20,
  });

  const searchText = useDebounce(search, 400);

  const {
    error,
    isFetching,
    data: medicinesRes,
  } = useGetSearchMedicinesQuery({
    searchText: searchText,
    ...medicineSearchParams,
  });

  useEffect(() => {
    if (error) presentError(t('errors.generic.error01'));
  }, [error]);

  const prepareMedicines = useMemo(() => {
    if (!medicinesRes) return [];

    return medicinesRes.documents;
  }, [medicinesRes]);

  const handleClickMedicine = (medicine: Medicine) => {
    const add = searchParams.get('add');
    if (add === 'true') {
      dispatch(setMedicine(medicine));
      router.push('/dashboard/medication-add');
    } else {
      // For now we don't want onClick navigation to work
      // if (medicine && medicine._id) {
      //   router.push(`/dashboard/medicine/${medicine._id}`);
      // }
    }
  };

  return (
    <PageLayout
      defaultHref={'/dashboard/medication'}
      headerColor={theme.palette.primary.main}
      headerTitle={t('medication.titles.allMeds')}
    >
      <ComponentsLayout>
        <Box display={'flex'} flexDirection={'column'} gap={theme.spacing(3)}>
          <Searchbar placeholder={'Search'} value={search} setValue={setSearch} />
          <Box
            sx={{
              borderRadius: '8px',
              backgroundColor: theme.palette.secondary.light,
            }}
          >
            <InfiniteScrollList
              loading={isFetching}
              count={prepareMedicines.length}
              skip={medicineSearchParams.skip}
              totalCount={medicinesRes?.count}
              limit={medicineSearchParams.limit}
              onLoadMore={(skip, limit) => {
                setMedicineSearchParams({ skip, limit });
              }}
            >
              {prepareMedicines.map(medicine => {
                return (
                  <Box key={medicine._id}>
                    <ClickNavCardNutrition
                      headings={concatMedicine(medicine)}
                      onClick={() => handleClickMedicine(medicine)}
                    />
                  </Box>
                );
              })}
            </InfiniteScrollList>
          </Box>
        </Box>
      </ComponentsLayout>
    </PageLayout>
  );
};

export default MedicationAllMedicines;
