import {
  calculateValues,
  Collapsible,
  ComponentsLayout,
  DownloadIcon,
  FeelsEmpty,
  filterValues,
  LipidLog,
} from '@hdcorner/ui-library';
import { Box, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import InfiniteScrollList from '../../components/InfiniteScrollList';
import ModalInDepthDataDL from '../../components/ModalInDepthDataDL';
import PageLayout from '../../components/PageLayout';
import InDepthDataOverallCard from './components/InDepthDataOverallCard';
import useTitle from './hooks/useTitle';
import useType from './hooks/useType';
import { useGetLipidLogsQuery, useGetOverallLipidQuery } from './queries/lipidQueries';
import useAlert from '../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

const LipidInDepthData = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { presentError } = useAlert();

  const { type: paramType } = useParams<{ type: string }>();
  const type = useType(paramType);
  const title = useTitle(paramType);

  const [downloadDataModalOpen, setDownloadDataModalOpen] = useState(false);
  const [lipidParams, setLipidParams] = useState({
    skip: 0,
    limit: 20,
  });

  const {
    data: lipidLogs,
    error: lipidError,
    isFetching: lipidLogsFetching,
  } = useGetLipidLogsQuery({
    params: { ...lipidParams, sort: ['-logDate'] },
  });
  const { data: lipidOverallData, error: lipidOverallError } = useGetOverallLipidQuery();

  useEffect(() => {
    if (lipidError) {
      presentError(t('errors.lipid.errorFetchLog'));
    }
    if (lipidOverallError) {
      presentError(t('errors.lipid.errorFetchOverall'));
    }
  }, [lipidError, lipidOverallError]);

  const overallData = useMemo(() => {
    if (!lipidOverallData)
      return { max: 0, min: 0, avg: 0, title: type, measure: t('measurements.mgdl') };

    const title = type === 'totalChol' ? t('lipid.labels.totChol') : type;
    const lipidOverallDataKeys = Object.keys(lipidOverallData);
    if (!lipidOverallDataKeys.includes(type)) {
      return {
        title,
        max: 0,
        min: 0,
        avg: 0,
        measure: t('measurements.mgdl'),
      };
    }
    const typeKey = type as keyof typeof lipidOverallData;
    return {
      title,
      measure: t('measurements.mgdl'),
      max: lipidOverallData[typeKey] ? lipidOverallData[typeKey].max : 0,
      min: lipidOverallData[typeKey] ? lipidOverallData[typeKey].min : 0,
      avg: lipidOverallData[typeKey] ? lipidOverallData[typeKey].avg : 0,
    };
  }, [lipidOverallData, type]);

  const prepareData = useMemo(() => {
    if (lipidLogs && lipidLogs.documents.length > 0) {
      return lipidLogs.documents
        .filter(log => filterValues(type, log))
        .map((log: LipidLog) => {
          return {
            title: calculateValues(type, log) + '',
            titleSub: t('measurements.mgdl'),
            data: {
              [title]: {
                value: `${calculateValues(type, log)} ${t('measurements.mgdl')}`,
              },
              [t('inDepth.timeOfCalc')]: { value: moment(log.logDate).format('h:mm A') },
              [t('inDepth.dateOfCalc')]: {
                value: moment(log.logDate).format('MMM Do, YYYY'),
              },
            },
          };
        });
    }
    return [];
  }, [lipidLogs, type]);

  return (
    <PageLayout
      defaultHref={'/dashboard/lipid'}
      headerTitle={`${title} ${t('headingsTitles.inDepth')}`}
      endButtons={[
        {
          icon: <DownloadIcon />,
          onClick: () => setDownloadDataModalOpen(true),
        },
      ]}
    >
      <ComponentsLayout>
        <Box marginBottom={theme.spacing(3)}>
          <InDepthDataOverallCard
            min={overallData.min}
            max={overallData.max}
            avg={overallData.avg}
            type={overallData.title}
            measure={overallData.measure}
          />
        </Box>

        <Typography variant={'h1'} marginBottom={theme.spacing(3)}>
          {t('headingsTitles.latestUpdates')}
        </Typography>
        {prepareData.length > 0 ? (
          <InfiniteScrollList
            skip={lipidParams.skip}
            limit={lipidParams.limit}
            count={prepareData.length}
            loading={lipidLogsFetching}
            totalCount={lipidLogs?.count}
            onLoadMore={(skip, limit) => setLipidParams({ skip, limit })}
          >
            {prepareData.map((item, index) => (
              <Box key={`${index}`}>
                <Collapsible
                  data={item.data}
                  title={item.title}
                  titleSub={item.titleSub}
                />
              </Box>
            ))}
          </InfiniteScrollList>
        ) : (
          <FeelsEmpty />
        )}
      </ComponentsLayout>
      <ModalInDepthDataDL
        module={'lipid'}
        open={downloadDataModalOpen}
        dismiss={() => setDownloadDataModalOpen(false)}
      />
    </PageLayout>
  );
};

export default LipidInDepthData;
