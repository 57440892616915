import PageLayout from '../../../components/PageLayout';
import { ComponentsLayout } from '@hdcorner/ui-library';
import AvatarSelectorComponent from './components/AvatarSelectorComponent';
import { useTranslation } from 'react-i18next';

const FitnessAvatarScreen = () => {
  const { t } = useTranslation();

  return (
    <PageLayout
      defaultHref={'dashboard/fitness/workouts'}
      headerTitle={t('fitness.workouts.labels.avatar')}
    >
      <ComponentsLayout>
        <AvatarSelectorComponent />
      </ComponentsLayout>
    </PageLayout>
  );
};

export default FitnessAvatarScreen;
