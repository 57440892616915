import { Directory, Filesystem } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';

export const downloadFile = async (url: string, filename: string) => {
  return Filesystem.downloadFile({
    url,
    directory: Directory.Cache,
    path: filename,
  }).then(res => {
    if (res.blob) {
      // for web download blob
      const blobUrl = URL.createObjectURL(res.blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = blobUrl;
      downloadLink.download = filename;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      URL.revokeObjectURL(blobUrl);
    } else {
      // for mobile share
      console.log(res.path);
      Share.share({
        url: res.path,
      });
    }
  });
};
