import * as React from 'react';
import { SVGProps } from 'react';

const RedUpArrow = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.29303 20.707C3.10556 20.5195 3.00024 20.2652 3.00024 20C3.00024 19.7348 3.10556 19.4805 3.29303 19.293L17.586 5H12C11.7348 5 11.4805 4.89464 11.2929 4.70711C11.1054 4.51957 11 4.26522 11 4C11 3.73478 11.1054 3.48043 11.2929 3.29289C11.4805 3.10536 11.7348 3 12 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8947 3.48043 21 3.73478 21 4V12C21 12.2652 20.8947 12.5196 20.7071 12.7071C20.5196 12.8946 20.2652 13 20 13C19.7348 13 19.4805 12.8946 19.2929 12.7071C19.1054 12.5196 19 12.2652 19 12V6.414L4.70703 20.707C4.5195 20.8945 4.26519 20.9998 4.00003 20.9998C3.73487 20.9998 3.48056 20.8945 3.29303 20.707Z"
        fill={props.fill || '#FF0000'}
      />
    </svg>
  );
};

export default RedUpArrow;
