import React, { FC } from 'react';
import { Box, BoxProps, styled, Typography, TypographyProps } from '@mui/material';

const CustomBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  borderRadius: '8px',
  boxSizing: 'border-box',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  margin: theme.spacing(0),
  marginBottom: theme.spacing(1),
  backgroundColor: theme.palette.secondary.light,
  padding: theme.spacing(1, 2),
}));

const CustomTypographyHeading = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  paddingRight: theme.spacing(2),
  fontWeight: theme.typography.fontWeightRegular,
}));

const CustomTypographyData = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  color: theme.palette.kmColorsCoolGrey.main,
  textAlign: 'left',
}));

type InfoCardMealProps = {
  body: string;
  heading: string;
};
const InfoCardMeal: FC<InfoCardMealProps> = ({ heading, body }) => {
  return (
    <CustomBox>
      <CustomTypographyHeading variant={'subtitle2'}>{heading}</CustomTypographyHeading>
      <CustomTypographyData variant={'subtitle2'}>{body}</CustomTypographyData>
    </CustomBox>
  );
};

export default InfoCardMeal;
