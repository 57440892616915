import React, { FC } from 'react';
import { alpha, LinearProgress, LinearProgressProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const LinearProgressMain = styled(LinearProgress)<LinearProgressProps>(({ theme }) => ({
  height: 16,
  background: alpha(theme.palette.primary.main, 0.16),
  '.MuiLinearProgress-bar1Determinate': {
    background: theme.palette.kmColorsRed.main,
  },
}));

type BarStepperType = LinearProgressProps;
const BarStepper: FC<BarStepperType> = linearProgressProps => {
  return <LinearProgressMain variant="determinate" {...linearProgressProps} />;
};

export default BarStepper;
