import moment from 'moment';

export const prepareGraphData = (graphData: any[] | undefined, metric: string): any[] => {
  if (!graphData) return [];

  const data = graphData.map(it => {
    const hasDate = 'date' in it;
    const hasMetric = metric in it;
    const hasCount = 'count' in it;
    const hasLogDate = 'logDate' in it;

    if (hasDate && hasCount) {
      if (!it.count) return null;
      return {
        x: moment(it.date).toISOString(),
        y: it.count.toFixed(1),
      };
    }

    if (hasMetric) {
      if (!it[metric]) return null;

      if (hasLogDate) {
        return {
          x: moment(it.logDate).toISOString(),
          y: it[metric].toFixed(1),
        };
      } else {
        return {
          x: moment(it.createdAt).toISOString(),
          y: it[metric].toFixed(1),
        };
      }
    }
  });

  return data.filter(it => it);
};

export const calculateAxisTotal = (object: any) => {
  if (object && Array.isArray(object)) {
    const total = object.map((it: any) => parseFloat(it.y));
    return total.reduce((sum: number, current: number) => sum + current, 0);
  }
  return 0;
};
