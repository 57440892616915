import * as React from 'react';
import { SVGProps } from 'react';

const HdCornerHeartLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    viewBox={'0 0 40 40'}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <g filter="url(#b)">
        <path
          fill="#EE1D23"
          fillRule="evenodd"
          d="M28.726 6.453a8.752 8.752 0 0 1 5.964 10.845c-1.147 3.95-2.39 6.534-4.803 10.632l-3.514 5.967-6.614-2.047c-4.06-1.256-6.595-2.408-10.57-4.81a8.752 8.752 0 0 1 8.845-15.101 8.752 8.752 0 0 1 10.692-5.486Z"
          clipRule="evenodd"
        />
      </g>
      <g filter="url(#c)" opacity={0.3}>
        <path
          fill="#FFF8F8"
          d="M18.168 11.375a5.418 5.418 0 1 1-10.152 3.79 5.418 5.418 0 0 1 10.152-3.79Z"
        />
      </g>
    </g>
    <defs>
      <filter
        id="b"
        width={37.672}
        height={35.387}
        x={2.43}
        y={4.078}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={1.266} dy={1.772} />
        <feGaussianBlur stdDeviation={1.899} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.176471 0 0 0 0 0.447059 0 0 0 0.3 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_3219_10405" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_3219_10405" result="shape" />
      </filter>
      <filter
        id="c"
        width={20.964}
        height={20.964}
        x={2.61}
        y={2.788}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur result="effect1_foregroundBlur_3219_10405" stdDeviation={2.531} />
      </filter>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default HdCornerHeartLogo;
