import React, { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import AvatarMale from './AvatarMale';
import AvatarFemale from './AvatarFemale';
import { SexType } from '../types/SexType';
import { ExerciseBodyPart } from '../types/ExerciseBodyPart';
import { useGetWorkoutSettingsQuery } from '../queries/workoutSettingsQueries';
import useAlert from '../../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

type Props = {
  selected: ExerciseBodyPart;
  onSelect: (selected: ExerciseBodyPart) => void;
};
const AvatarBodyPartSelector: FC<Props> = ({ selected, onSelect }) => {
  const { t } = useTranslation();
  const { presentError } = useAlert();

  const [sex, setSex] = useState<string>('female');

  const { data: avatarRes, error } = useGetWorkoutSettingsQuery();

  useEffect(() => {
    if (error) {
      presentError(t('errors.fitness.workouts.errorWorkoutSettingsFetch'));
    }
  }, [error]);

  useEffect(() => {
    setSex(avatarRes?.[0]?.avatar || '');
  }, [avatarRes]);

  return (
    <Box position={'relative'} height={'100%'}>
      <Box position={'relative'} width={'fit-content'}>
        <Box
          left={0}
          top={'15%'}
          width={'30%'}
          height={'38%'}
          position={'absolute'}
          sx={{ cursor: 'pointer' }}
          onClick={() => onSelect(ExerciseBodyPart.ARMS)}
        />
        {/*TORSO*/}
        <Box
          top={'15%'}
          left={'30%'}
          width={'40%'}
          height={'30%'}
          position={'absolute'}
          sx={{ cursor: 'pointer' }}
          onClick={() => onSelect(ExerciseBodyPart.TORSO)}
        />
        {/*ARM : RIGHT*/}
        <Box
          top={'15%'}
          left={'70%'}
          width={'30%'}
          height={'38%'}
          position={'absolute'}
          sx={{ cursor: 'pointer' }}
          onClick={() => onSelect(ExerciseBodyPart.ARMS)}
        />
        {/*LEGS*/}
        <Box
          top={'45%'}
          left={'29%'}
          width={'42%'}
          height={'54%'}
          position={'absolute'}
          sx={{ cursor: 'pointer' }}
          onClick={() => onSelect(ExerciseBodyPart.LEGS)}
        />
        {sex === SexType.MALE ? (
          <AvatarMale selected={selected} height={'328px'} width={'119px'} />
        ) : (
          <AvatarFemale selected={selected} height={'328px'} width={'138px'} />
        )}
      </Box>
    </Box>
  );
};

export default AvatarBodyPartSelector;
