import NotificationComponent from '../NotificationComponent';
import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import {
  alpha,
  Box,
  BoxProps,
  List,
  ListItem,
  ListItemProps,
  ListProps,
} from '@mui/material';
import { Notification } from '../../types/Notification';

const ListMain = styled(List)<ListProps>(({ theme }) => ({
  mt: 1,
  gap: 1,
  width: '100%',
  height: '100%',
  maxHeight: 212,
  display: 'flex',
  overflow: 'auto',
  flexDirection: 'column',
  '::-webkit-scrollbar': { width: 4, height: 60 },
  '::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: 8,
    backgroundColor: theme.palette.secondary.dark,
  },
}));

const ListItemMain = styled(ListItem, {
  shouldForwardProp: prop =>
    prop !== 'isUnread' && prop !== 'isLastItem' && prop !== 'isNotificationDialog',
})<
  ListItemProps & {
    isUnread?: boolean;
    isLastItem: boolean;
    isNotificationDialog: boolean;
  }
>(({ theme, isUnread, isLastItem, isNotificationDialog }) => ({
  padding: theme.spacing(0),
  margin: theme.spacing(0),
  background:
    isUnread || !isNotificationDialog
      ? theme.palette.highlight.main
      : theme.palette.mode === 'dark'
      ? alpha(theme.palette.highlight.main, 0.5)
      : theme.palette.secondary.light,
  boxShadow: isLastItem
    ? undefined
    : `inset 0px -1px 0px ${theme.palette.secondary.main}`,
}));

const Line = styled(Box)<BoxProps>(({ theme }) => ({
  left: 0,
  width: 4,
  minHeight: 12,
  height: '100%',
  position: 'absolute',
  background:
    theme.palette.mode === 'dark'
      ? theme.palette.kmColorsTurquoise.main
      : theme.palette.kmColorsRed.main,
}));

type NotificationsListType = {
  data: Notification[];
  centerElements?: boolean;
  isNotificationDialog?: boolean;
  onNotificationClick: (id: string) => void;
};
const NotificationsList: FC<NotificationsListType & ListProps> = ({
  data = [],
  onNotificationClick,
  centerElements = false,
  isNotificationDialog = true,
  ...listProps
}) => {
  return (
    <ListMain disablePadding {...listProps}>
      {data.map((val: Notification, index) => (
        <ListItemMain
          key={val._id}
          isUnread={!val.read}
          isLastItem={index === data.length - 1}
          isNotificationDialog={isNotificationDialog}
        >
          {isNotificationDialog && !val.read ? <Line /> : null}
          <NotificationComponent
            data={val}
            centerElements={centerElements}
            onNotificationClick={onNotificationClick}
            isNotificationDialog={isNotificationDialog}
          />
        </ListItemMain>
      ))}
    </ListMain>
  );
};

export default NotificationsList;
