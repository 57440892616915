import * as React from 'react';
import { SVGProps } from 'react';

const CalendarIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props && props.width ? props.width : '14'}
      height={props && props.height ? props.height : '15'}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.00016 14.1668H13.0002C13.177 14.1668 13.3465 14.0966 13.4716 13.9716C13.5966 13.8465 13.6668 13.677 13.6668 13.5002V3.50016C13.6668 3.32335 13.5966 3.15378 13.4716 3.02876C13.3465 2.90373 13.177 2.8335 13.0002 2.8335H10.3335V1.50016C10.3335 1.32335 10.2633 1.15378 10.1382 1.02876C10.0132 0.903734 9.84364 0.833496 9.66683 0.833496C9.49002 0.833496 9.32045 0.903734 9.19543 1.02876C9.0704 1.15378 9.00016 1.32335 9.00016 1.50016V2.8335H5.00016V1.50016C5.00016 1.32335 4.92992 1.15378 4.8049 1.02876C4.67988 0.903734 4.51031 0.833496 4.3335 0.833496C4.15669 0.833496 3.98712 0.903734 3.86209 1.02876C3.73707 1.15378 3.66683 1.32335 3.66683 1.50016V2.8335H1.00016C0.823352 2.8335 0.653782 2.90373 0.528758 3.02876C0.403734 3.15378 0.333496 3.32335 0.333496 3.50016V13.5002C0.333496 13.677 0.403734 13.8465 0.528758 13.9716C0.653782 14.0966 0.823352 14.1668 1.00016 14.1668ZM1.66683 4.16683H12.3335V6.16683H1.66683V4.16683ZM1.66683 7.50016H12.3335V12.8335H1.66683V7.50016Z"
        fill={props && props.color ? props.color : '#222222'}
      />
    </svg>
  );
};

export default CalendarIcon;
