import * as React from 'react';
import { SVGProps } from 'react';
import { useTheme } from '@mui/material';

const NavHealthProfIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  return (
    <svg
      width={props && props.width ? props.width : 23}
      height={props && props.height ? props.height : 22}
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.5 10V8C7.5 7.73478 7.60536 7.48043 7.7929 7.29289C7.98043 7.10536 8.23479 7 8.5 7C8.76522 7 9.01958 7.10536 9.20711 7.29289C9.39465 7.48043 9.5 7.73478 9.5 8V10C9.5 10.2652 9.39465 10.5196 9.20711 10.7071C9.01958 10.8946 8.76522 11 8.5 11C8.23479 11 7.98043 10.8946 7.7929 10.7071C7.60536 10.5196 7.5 10.2652 7.5 10ZM14.5 11C14.7652 11 15.0196 10.8946 15.2071 10.7071C15.3946 10.5196 15.5 10.2652 15.5 10V8C15.5 7.73478 15.3946 7.48043 15.2071 7.29289C15.0196 7.10536 14.7652 7 14.5 7C14.2348 7 13.9804 7.10536 13.7929 7.29289C13.6054 7.48043 13.5 7.73478 13.5 8V10C13.5 10.2652 13.6054 10.5196 13.7929 10.7071C13.9804 10.8946 14.2348 11 14.5 11ZM22.5 11C22.5 13.1756 21.8549 15.3023 20.6462 17.1113C19.4375 18.9202 17.7195 20.3301 15.7095 21.1627C13.6995 21.9952 11.4878 22.2131 9.35401 21.7886C7.22022 21.3642 5.26021 20.3166 3.72183 18.7782C2.18345 17.2398 1.1358 15.2798 0.711367 13.146C0.286929 11.0122 0.504766 8.80047 1.33733 6.79048C2.16989 4.78049 3.57979 3.06253 5.38873 1.85383C7.19767 0.645139 9.32441 0 11.5 0C14.4143 0.0100158 17.2064 1.17216 19.2671 3.23289C21.3278 5.29362 22.49 8.0857 22.5 11ZM2.52801 10.451L7.013 13.142L9.249 12.015C9.94731 11.6647 10.7178 11.4823 11.499 11.4823C12.2802 11.4823 13.0507 11.6647 13.749 12.015L15.985 13.142L20.47 10.451C20.3334 8.16411 19.3286 6.01571 17.661 4.4448C15.9935 2.87389 13.789 1.99906 11.498 1.99906C9.20704 1.99906 7.00254 2.87389 5.33497 4.4448C3.6674 6.01571 2.66264 8.16411 2.52601 10.451H2.52801ZM20.3 12.888L18.1 14.207L19.575 14.95C19.9007 14.2951 20.1442 13.6026 20.3 12.888ZM18.5 16.647L12.851 13.8C12.432 13.5898 11.9698 13.4803 11.501 13.4803C11.0322 13.4803 10.57 13.5898 10.151 13.8L4.5 16.647C5.34125 17.6936 6.40659 18.5382 7.61754 19.1185C8.82848 19.6988 10.1542 20.0001 11.497 20.0001C12.8398 20.0001 14.1655 19.6988 15.3765 19.1185C16.5874 18.5382 17.6528 17.6936 18.494 16.647H18.5ZM3.42601 14.95L4.9 14.207L2.70001 12.888C2.85615 13.6026 3.1 14.2952 3.42601 14.95Z"
        fill={props && props.color ? props.color : theme.palette.primary.main}
      />
    </svg>
  );
};

export default NavHealthProfIcon;
