import { useEffect, useMemo, useState } from 'react';
import { goalProgress } from '../../../utils/goalProgress';
import {
  useGetFirstWeightLogQuery,
  useGetLastWeightLogQuery,
  useGetUserGoalWeightQuery,
} from '../../fitness/wellness/queries/weightQueries';

const useGetWeightGoalData = () => {
  const [startingDate, setStartingDate] = useState<string>();

  const { data: goal } = useGetUserGoalWeightQuery();
  const { data: current } = useGetLastWeightLogQuery();
  const { data: start } = useGetFirstWeightLogQuery(
    {
      start: startingDate || '',
    },
    { skip: !startingDate },
  );

  useEffect(() => {
    if (goal && goal.length > 0) {
      setStartingDate(goal[0].startDate);
    }
  }, [goal]);

  return useMemo(() => {
    if (!goal || goal.length === 0) return null;

    let goalValue;
    let startValue;
    let currentValue;
    let progress = 0;

    if (goal && goal.length > 0) {
      goalValue = goal[0].goal.measurement;
    }

    if (start && start.documents.length > 0) {
      startValue = start.documents[0].weight;
    }

    if (current && current.documents.length > 0) {
      currentValue = current.documents[0].weight;
      startValue = startValue || currentValue;
    }

    if (goalValue && startValue && currentValue) {
      progress = goalProgress(currentValue, goalValue, startValue);
    }

    return { progress, goalValue, startValue, currentValue };
  }, [current, start, goal]);
};

export default useGetWeightGoalData;
