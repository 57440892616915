import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useGetArticlesQuery } from '../queries/articlesQueries';
import {
  Box,
  IconButton,
  styled,
  Typography,
  TypographyProps,
  useMediaQuery,
} from '@mui/material';
import { ArrowLeft, ArticleCard, ConduitFile, theme } from '@hdcorner/ui-library';
import React, { FC, useEffect, useMemo } from 'react';
import { createArticleList, updateArticleDetails } from '../slices/articlesSlice';
import { useIonRouter } from '@ionic/react';
import { convertArticleCategory } from '../utilities/articleUtils';
import useAlert from '../../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

const Title = styled(Typography, { shouldForwardProp: propName => propName !== 'web' })<
  TypographyProps & { web: boolean; lang: 'en' | 'el' }
>(({ web, theme, lang }) => ({
  fontSize: web
    ? theme.typography.subtitle1.fontSize
    : theme.typography.subtitle2.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
  textTransform: web && lang === 'en' ? 'uppercase' : 'capitalize',
  color: web ? theme.palette.primary.main : theme.palette.primary.light,
}));

type Props = {
  handleBack?: () => void;
  handleGoToArticle?: () => void;
};
const ArticlesInner: FC<Props> = ({ handleBack, handleGoToArticle }) => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { presentError } = useAlert();
  const web = useMediaQuery(theme.breakpoints.up('md'));
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const { category } = useAppSelector(state => state.articles);

  const { data: articlesRes, error } = useGetArticlesQuery({
    lang: lang,
    sort: ['-createdAt'],
    category: category.value,
  });

  useEffect(() => {
    if (error) {
      presentError(t('errors.fitness.nutrition.errorArticleFetch'));
    }
  }, [error]);

  const articleIds = useMemo(() => {
    if (!articlesRes || articlesRes.length === 0) return [];
    return articlesRes.map(it => it._id);
  }, [articlesRes]);

  const allArticles = useMemo(() => {
    if (!articlesRes || articlesRes.length === 0) return [];

    return articlesRes.map(it => ({
      id: it._id || '',
      title: it.title || '',
      author: it.author || '',
      imageSrc: (it.image as ConduitFile)?.url || '',
      category: convertArticleCategory(it.category) || '',
    }));
  }, [articlesRes]);

  const handleViewArticle = (data: { id: string; title: string }) => {
    dispatch(updateArticleDetails({ id: data.id, title: data.title }));
    dispatch(createArticleList(articleIds as string[]));
    if (!web) {
      router.push(`/dashboard/fitness/nutrition/articles/${data.id}`);
    }
    if (handleGoToArticle) {
      handleGoToArticle();
    }
  };

  const handleGoBack = () => {
    if (handleBack) {
      handleBack();
    }
  };

  return (
    <Box>
      <Box gap={1} mb={web ? 3 : 1} display={'flex'}>
        {web && (
          <IconButton sx={{ padding: 0 }} onClick={handleGoBack}>
            <ArrowLeft />
          </IconButton>
        )}
        <Title web={web} lang={lang}>
          {web
            ? `${category.title} ${t('headingsTitles.articles')}`
            : t('headingsTitles.latestArticle')}
        </Title>
      </Box>
      <Box
        flex={1}
        display={'flex'}
        gap={web ? 3 : 1}
        flexWrap={web ? 'wrap' : 'nowrap'}
        flexDirection={web ? 'row' : 'column'}
      >
        {allArticles.map(it => (
          <ArticleCard
            key={it.id}
            title={it.title}
            author={it.author}
            imageSrc={it.imageSrc}
            category={it.category}
            onClick={() => handleViewArticle(it)}
            sx={{ width: web ? '300px' : '100%' }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ArticlesInner;
