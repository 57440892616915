import * as React from 'react';
import { SVGProps } from 'react';

const MedicationHeaderIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.11699 22.0001C10.0513 22.0013 10.9766 21.8181 11.8399 21.461C12.7032 21.1039 13.4876 20.5799 14.148 19.9191L19.916 14.1521C21.2511 12.8174 22.0013 11.007 22.0016 9.11915C22.0019 7.23132 21.2522 5.42069 19.9175 4.08559C18.5828 2.75049 16.7724 2.00028 14.8846 2C12.9967 1.99972 11.1861 2.74939 9.85099 4.08409L4.08399 9.85109C3.08733 10.8459 2.40833 12.1142 2.133 13.4952C1.85767 14.8762 1.9984 16.3079 2.53736 17.6088C3.07632 18.9098 3.98926 20.0216 5.16056 20.8033C6.33185 21.585 7.7088 22.0015 9.11699 22.0001ZM11.265 5.50009C11.7364 5.00887 12.3011 4.61666 12.926 4.34645C13.5509 4.07623 14.2235 3.93344 14.9043 3.92644C15.5851 3.91944 16.2604 4.04837 16.8908 4.30567C17.5211 4.56298 18.0938 4.94349 18.5752 5.42491C19.0566 5.90633 19.4371 6.47898 19.6944 7.10932C19.9517 7.73965 20.0806 8.415 20.0736 9.0958C20.0666 9.77659 19.9238 10.4491 19.6536 11.0741C19.3834 11.699 18.9912 12.2637 18.5 12.7351L16.323 14.9111L9.08899 7.67509L11.265 5.50009ZM5.49999 11.2651L7.67499 9.08909L14.911 16.3251L12.734 18.5001C11.7703 19.4388 10.4757 19.9601 9.13047 19.9513C7.78522 19.9424 6.49761 19.404 5.54642 18.4527C4.59522 17.5014 4.05702 16.2137 4.04833 14.8684C4.03964 13.5232 4.56117 12.2286 5.49999 11.2651Z"
      fill={props?.color ?? '#222222'}
    />
  </svg>
);

export default MedicationHeaderIcon;
