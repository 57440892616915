import React, { FC, ReactElement, useRef } from 'react';
import { Box, IconButton, styled } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

const CustomCarousel = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  height: '350px',
  overflow: 'hidden',
  touchAction: 'pan-y',
}));

const CustomNavigation = styled('div')(() => ({
  zIndex: 1,
  top: '90%',
  position: 'absolute',
  transform: 'translateY(-50%)',
}));

const Carousel: FC<{
  activeIndex: number;
  handleChangeElement: (activeElement: number) => void;
  children: ReactElement[];
}> = ({ children, activeIndex, handleChangeElement }) => {
  // const [touchStartX, setTouchStartX] = useState<number | null>(null);

  const carouselRef = useRef<HTMLDivElement>(null);

  const handlePrevClick = () => {
    handleChangeElement(activeIndex === 0 ? children.length - 1 : activeIndex - 1);
  };

  const handleNextClick = () => {
    handleChangeElement(activeIndex === children.length - 1 ? 0 : activeIndex + 1);
  };

  // Need fixes for swipe to work
  // const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
  //   setTouchStartX(event.touches[0].clientX);
  // };
  // const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
  //   const touchCurrentX = event.touches[0].clientX;
  //   const touchDelta = touchCurrentX - (touchStartX || 0);
  //
  //   if (carouselRef.current) {
  //     carouselRef.current.style.transform = `translateX(calc(-${
  //       activeIndex * 100
  //     }% + ${touchDelta}px))`;
  //   }
  // };
  // const handleTouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
  //   const touchEndX = event.changedTouches[0].clientX;
  //   const touchDelta = touchEndX - (touchStartX || 0);
  //
  //   if (touchDelta > 100) {
  //     handlePrevClick();
  //   } else if (touchDelta < -100) {
  //     handleNextClick();
  //   }
  //
  //   if (carouselRef.current) {
  //     carouselRef.current.style.transform = `translateX(-${activeIndex * 100}%)`;
  //   }
  // };

  return (
    <CustomCarousel ref={carouselRef}>
      {children.map((node, index) => (
        <Box
          key={index}
          sx={
            activeIndex === index
              ? {
                  width: '100%',
                  height: '100%',
                  display: 'block',
                  position: 'absolute',
                  transition: 'transform 0.5s ease-in-out',
                }
              : {
                  width: '100%',
                  height: '100%',
                  display: 'block',
                  position: 'absolute',
                  transition: 'transform 0.5s ease-in-out',
                }
          }
          style={{
            transform: `translateX(${
              index === activeIndex ? 0 : (index - activeIndex) * 100
            }%)`,
          }}
        >
          {node}
        </Box>
      ))}
      {children.length > 1 && (
        <CustomNavigation>
          <Box>
            <IconButton onClick={handlePrevClick}>
              <ChevronLeft />
            </IconButton>
            <IconButton onClick={handleNextClick}>
              <ChevronRight />
            </IconButton>
          </Box>
        </CustomNavigation>
      )}
    </CustomCarousel>
  );
};

export default Carousel;
