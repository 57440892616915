import * as React from 'react';
import { FC } from 'react';
import { styled, useTheme } from '@mui/material';
import ToggleButtonGroup, {
  ToggleButtonGroupProps,
} from '@mui/material/ToggleButtonGroup';
import ToggleButton, { ToggleButtonProps } from '@mui/material/ToggleButton';
import ChartIconsType from '../../types/ChartIconsType';

const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

const CustomToggleButton = styled(ToggleButton, {
  shouldForwardProp: prop => prop !== 'rotate',
})<
  ToggleButtonProps & {
    rotate?: boolean;
  }
>(({ theme, size, rotate }) => ({
  width: size == 'large' ? 60 : 28,
  height: size == 'large' ? 40 : 20,
  fontWeight: theme.typography.fontWeightBold,
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.kmColorsCoolGrey.main
      : theme.palette.primary.main,
  borderRadius: size == 'large' ? '8px' : '4px',
  padding: 0,
  border: 'none',
  '&.Mui-selected, &.Mui-selected:hover': {
    color: theme.palette.secondary.light,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.kmColorsCoolGrey.main
        : theme.palette.primary.main,
    opacity: '1',
    textTransform: lang === 'el' ? 'capitalize' : 'uppercase',
  },
  '& > svg': {
    transform: rotate ? 'rotate(90deg)' : 'rotate(0)',
  },
}));

const CustomToggleButtongroup = styled(ToggleButtonGroup, {
  shouldForwardProp: prop => prop !== 'gap',
})<ToggleButtonGroupProps & { gap?: string }>(({ theme, gap }) => ({
  display: 'flex',
  gap: gap ? gap : theme.spacing(1),
  '.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
    borderRadius: '4px',
  },
  '.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
    borderRadius: '4px',
  },
}));

type ChartTypeButtonsType = {
  gap?: string;
  size?: 'large';
  iconValue: string;
  icons: ChartIconsType[];
  setIconValue: (icon: string) => void;
};
const ChartTypeButtons: FC<ChartTypeButtonsType> = ({
  icons,
  gap,
  size,
  iconValue,
  setIconValue,
}) => {
  const theme = useTheme();

  const handleIconButtonChange = (
    event: React.MouseEvent<HTMLElement>,
    active: string,
  ) => {
    if (active) {
      setIconValue(active as string);
    }
  };

  return (
    <CustomToggleButtongroup
      gap={gap}
      exclusive
      size={size}
      color="primary"
      value={iconValue}
      aria-label="Platform"
      onChange={handleIconButtonChange}
    >
      {icons.map((Icon, index) => (
        <CustomToggleButton key={index} value={Icon.name} rotate={Icon.rotate}>
          <Icon.icon
            width={12}
            height={12}
            color={
              theme.palette.mode === 'dark'
                ? iconValue === Icon.name
                  ? theme.palette.secondary.main
                  : theme.palette.kmColorsCoolGrey.main
                : iconValue === Icon.name
                ? theme.palette.secondary.light
                : theme.palette.primary.main
            }
          />
        </CustomToggleButton>
      ))}
    </CustomToggleButtongroup>
  );
};

export default ChartTypeButtons;
