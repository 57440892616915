import React, { FC } from 'react';
import { Avatar, Box, Typography, useTheme } from '@mui/material';
import HdCornerHeartLogo from '../../assets/HdCornerHeartLogo';

type ChatMessageCardWebProps = {
  date: string;
  name: string;
  avatar?: string;
  message: string;
  support?: boolean;
};
const ChatMessageCardWeb: FC<ChatMessageCardWebProps> = ({
  date,
  name,
  avatar,
  message,
  support = false,
}) => {
  const theme = useTheme();

  return (
    <Box
      display={'flex'}
      boxSizing={'border-box'}
      alignItems={'flex-start'}
      bgcolor={
        theme.palette.mode === 'dark'
          ? theme.palette.background.default
          : theme.palette.secondary.light
      }
      padding={theme.spacing(3, 1)}
    >
      <Avatar
        alt={name}
        src={avatar}
        sx={{
          width: 36,
          height: 36,
          bgcolor: support ? 'transparent' : undefined,
        }}
      >
        {support ? (
          <HdCornerHeartLogo width={36} height={36} />
        ) : (
          name.charAt(0).toUpperCase()
        )}
      </Avatar>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'stretch'}
        marginLeft={theme.spacing(1.25)}
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'flex-start'}
          marginBottom={theme.spacing(0.5)}
        >
          <Typography
            color={'primary'}
            variant={'subtitle1'}
            sx={{
              fontWeight: theme.typography.fontWeightMedium,
              marginRight: theme.spacing(0.5),
            }}
          >
            {name}
          </Typography>
          <Typography
            variant={'subtitle2'}
            sx={{
              color:
                theme.palette.mode === 'dark'
                  ? theme.palette.kmColorsCoolGrey.main
                  : theme.palette.primary.light,
            }}
          >
            {date}
          </Typography>
        </Box>
        <Typography align={'left'} color={'primary'} variant={'subtitle1'}>
          {message}
        </Typography>
      </Box>
    </Box>
  );
};

export default ChatMessageCardWeb;
