import React, { FC, useEffect, useMemo, useRef } from 'react';
import {
  Box,
  BoxProps,
  Card,
  CardActionAreaProps,
  CardContent,
  CardContentProps,
  CardProps,
  styled,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import goalAnimation from '../../../assets/lotties/hd-goal-anim.json';
import { useTranslation } from 'react-i18next';

const CardMain = styled(Card)<CardProps>(({ theme }) => ({
  height: 292,
  display: 'flex',
  borderRadius: 8,
  background: theme.palette.secondary.light,
  boxShadow: 'none',
}));

const CardContentMain = styled(CardContent)<CardContentProps>(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
}));

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightMedium,
}));

const Container = styled(Box)<BoxProps>(() => ({
  flexShrink: 1,
  display: 'flex',
  alignItems: 'center',
}));

const InnerContainer = styled(Box)<TypographyProps>(() => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
}));

const Text = styled(Typography, {
  shouldForwardProp: prop => prop !== 'customColor',
})<TypographyProps & { customColor?: string }>(({ theme, customColor }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  color: customColor ? customColor : theme.palette.primary.main,
}));

type GoalCardType = {
  title: string;
  hidden?: boolean;
  progress?: number;
  goalValue?: string | number;
  startValue?: string | number;
  currentValue?: string | number;
  cardContentProps?: CardContentProps;
  cardActionAreaProps?: CardActionAreaProps;
};

const GoalCard: FC<GoalCardType & CardProps> = ({
  title,
  hidden,
  goalValue,
  startValue,
  currentValue,
  progress = 0,
  cardContentProps,
  cardActionAreaProps,
  ...cardProps
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const lottieRef = useRef<LottieRefCurrentProps>(null);

  useEffect(() => {
    if (hidden) {
      lottieRef?.current?.goToAndStop(0, true);
      return;
    }

    const percentage = progress / 100;

    if (percentage <= 0) {
      lottieRef?.current?.playSegments([0, 1], true);
    } else if (percentage < 0.5) {
      lottieRef?.current?.playSegments([0, 120], true);
    } else if (percentage < 1) {
      lottieRef?.current?.playSegments([0, 190], true);
    } else {
      lottieRef?.current?.playSegments([0, 260], true);
    }
  }, [progress, hidden]);

  const goalMeasurement = useMemo(() => {
    switch (title) {
      case t('dashboard.goalCards.hba1c'):
        return '%';
      case t('dashboard.goalCards.cvd'):
        return '%';
      case t('dashboard.goalCards.systolic'):
        return t('measurements.mmGH');
      case t('dashboard.goalCards.diastolic'):
        return t('measurements.mmGH');
      case t('dashboard.goalCards.weight'):
        return t('measurements.kg');
      case t('dashboard.goalCards.exercise'):
        return t('measurements.min');
      default:
        return t('measurements.mgdl');
    }
  }, [t, title]);

  return (
    <CardMain {...cardProps}>
      <CardContentMain {...cardContentProps}>
        <Box display={'flex'} alignItems={'center'}>
          <Title textAlign={'center'} variant={'subtitle2'}>
            {title}
          </Title>
        </Box>
        <Box
          flexGrow={1}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Lottie
            loop={false}
            width={'100%'}
            height={'100%'}
            autoplay={false}
            lottieRef={lottieRef}
            animationData={goalAnimation}
          />
        </Box>
        <Container>
          <InnerContainer>
            <Text variant={'body1'}>{t('goals.start')}</Text>
            <Text variant={'body1'} customColor={theme.palette.warning.main}>
              {`${startValue ?? '-'} ${goalMeasurement}`}
            </Text>
          </InnerContainer>
          <InnerContainer>
            <Text variant={'body1'}>{t('buttons.navigation.goal')}</Text>
            <Text variant={'body1'} customColor={theme.palette.success.main}>
              {`${goalValue ?? '-'}  ${goalMeasurement}`}
            </Text>
          </InnerContainer>
          <InnerContainer>
            <Text variant={'body1'}>{t('goals.current')}</Text>
            <Text variant={'body1'} customColor={theme.palette.primary.light}>
              {`${currentValue ?? '-'} ${goalMeasurement}`}
            </Text>
          </InnerContainer>
          <InnerContainer>
            <Text variant={'body1'}>{t('goals.progress')}</Text>
            <Text variant={'body1'} customColor={theme.palette.medication.main}>
              {`${progress} %`}
            </Text>
          </InnerContainer>
        </Container>
      </CardContentMain>
    </CardMain>
  );
};

export default GoalCard;
