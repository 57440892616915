import { CustomButton, CustomInput, CvdRiskRanges } from '@hdcorner/ui-library';
import { Box, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

type ModalAddEditProps = {
  goal?: any;
  currentMeasurement?: any;
  desiredPlaceholder?: string;
  goalType?: 'cvd' | 'lipid' | 'hba1c' | 'weight' | 'pressure' | 'blood_glucose';
  handleSaveClick: (newGoals: any) => void;
};
const ModalAddEditGoal: FC<ModalAddEditProps> = ({
  goal,
  goalType,
  handleSaveClick,
  currentMeasurement,
  desiredPlaceholder,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [goalLocal, setGoalLocal] = useState<string>('');

  // State for goal type pressure
  const [systolic, setSystolic] = useState<string>('');
  const [diastolic, setDiastolic] = useState<string>('');

  useEffect(() => {
    if (goalType === 'pressure') {
      setSystolic(goal?.systolic ?? '');
      setDiastolic(goal?.diastolic ?? '');
    } else {
      setGoalLocal(goal ?? '');
    }
  }, [goalType, goal, currentMeasurement]);

  const handleSaveGoal = () => {
    if (goalType === 'pressure') {
      const newGoal = {
        systolic,
        diastolic,
      };
      handleSaveClick(newGoal);
      return;
    }
    handleSaveClick(goalLocal);
  };

  const renderMeasurementUnit = useMemo(() => {
    if (goalType === 'cvd') return '%';
    if (goalType === 'hba1c') return '%';
    if (goalType === 'pressure') return t('measurements.mmGH');
    if (goalType === 'weight') return t('measurements.kg');

    return t('measurements.mgdl');
  }, [goalType]);

  const renderCurrentMeasurementPlaceholder = useMemo(() => {
    if (goalType === 'cvd') return '%';
    if (goalType === 'hba1c') return '5.7%';
    if (goalType === 'pressure') return `- ${t('measurements.mmGH')}`;
    if (goalType === 'weight') return `- ${t('measurements.kg')}`;

    return `- ${t('measurements.mgdl')}`;
  }, [goalType]);

  const renderPressureInputs = useMemo(() => {
    if (goalType !== 'pressure') return null;

    return (
      <>
        <CustomInput
          fullWidth
          type={'number'}
          value={systolic}
          handleChange={setSystolic}
          suffix={`${t('measurements.mmGH')}`}
          label={`${t('hypertension.labels.goalSystolic')}`}
          placeholder={`${t('hypertension.labels.sysMmHgEx')}`}
        />
        <CustomInput
          fullWidth
          type={'number'}
          value={diastolic}
          handleChange={setDiastolic}
          suffix={`${t('measurements.mmGH')}`}
          label={`${t('hypertension.labels.goalDiastolic')}`}
          placeholder={`${t('hypertension.labels.diaMmHgEx')}`}
        />
        <>
          <CustomInput
            readOnly
            fullWidth
            type={'number'}
            value={currentMeasurement.systolic}
            suffix={`${t('measurements.mmGH')}`}
            placeholder={`- ${t('measurements.mmGH')}`}
            label={`${t('hypertension.labels.currentSysMeasurement')}`}
          />
          <CustomInput
            readOnly
            fullWidth
            type={'number'}
            suffix={`${t('measurements.mmGH')}`}
            value={currentMeasurement.diastolic}
            placeholder={`- ${t('measurements.mmGH')}`}
            label={`${t('hypertension.labels.currentDiaMeasurement')}`}
          />
        </>
      </>
    );
  }, [goalType, systolic, diastolic, currentMeasurement]);

  const renderStandardInputs = useMemo(() => {
    if (goalType === 'pressure') return null;

    return (
      <>
        <CustomInput
          fullWidth
          type={'number'}
          value={goalLocal}
          handleChange={setGoalLocal}
          label={`${t('goals.goal')}`}
          suffix={renderMeasurementUnit}
          placeholder={desiredPlaceholder}
        />
        <CustomInput
          readOnly
          fullWidth
          type={'number'}
          value={currentMeasurement}
          suffix={renderMeasurementUnit}
          label={`${t('goals.currentMeasurement')}`}
          placeholder={renderCurrentMeasurementPlaceholder}
        />
      </>
    );
  }, [
    goalType,
    goalLocal,
    desiredPlaceholder,
    currentMeasurement,
    renderMeasurementUnit,
    renderCurrentMeasurementPlaceholder,
  ]);

  const disabledValue = useMemo(() => {
    if (goalType === 'pressure') {
      return !systolic || !diastolic;
    }
    return !goalLocal;
  }, [goalType, systolic, diastolic, goalLocal]);

  return (
    <Box
      padding={3}
      display={'flex'}
      flexDirection={'column'}
      gap={theme.spacing(3)}
      justifyContent={'space-between'}
    >
      <Typography
        variant={'subtitle1'}
        color={theme.palette.primary.main}
        marginBottom={theme.spacing(1)}
        fontWeight={theme.typography.fontWeightMedium}
      >
        {!goal ? t('headingsTitles.goal') : t('buttons.editGoal')}
      </Typography>
      <Box display={'flex'} flexDirection={'column'} gap={theme.spacing(6)}>
        {renderPressureInputs}
        {renderStandardInputs}
      </Box>
      {/*CVD risk ranges, visible only for CVD goals*/}
      {goalType === 'cvd' && <CvdRiskRanges />}
      <CustomButton
        fullWidth
        disableElevation
        color={'primary'}
        variant={'contained'}
        onClick={handleSaveGoal}
        disabled={disabledValue}
        sx={{ padding: theme.spacing(2, 1) }}
      >
        {t('buttons.save')}
      </CustomButton>
    </Box>
  );
};
export default ModalAddEditGoal;
