import React, { FC, useEffect, useMemo, useState } from 'react';
import PlanGift from './PlanGift';
import { Box } from '@mui/material';
import SubsWebToolbar from './SubsWebToolbar';
import CardPaymentInfo from './CardPaymentInfo';
import SubscriptionTotal from './SubscriptionTotal';
import SubscriptionPlanDetails from './SubscriptionPlanDetails';
import { RadioGroup, RadioSingle, theme } from '@hdcorner/ui-library';
import {
  useCreatePaymentMutation,
  useGetProductsQuery,
  useLazyGetTransactionQuery,
} from '../queries/subscriptionsQueries';
import { useGetAuthUserQuery } from '../../authentication/queries/signInQueries';
import {
  useGetUserDataQuery,
  useLazyGetUserDataQuery,
} from '../../profile/queries/personalInfoQueries';
import useAlert from '../../../hooks/useAlert';
import { useIonRouter } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { Product } from '../types/SubscriptionHistory';

interface PaymentDetails {
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
}

type Props = {
  user: string;
  avatar: string;
  trial: boolean;
  premium: boolean;
  handleGoBack?: () => void;
};
const SubsCheckoutWeb: FC<Props> = ({ user, avatar, trial, premium, handleGoBack }) => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { presentError, presentSuccess } = useAlert();

  const [product, setProduct] = useState<string>('');
  const [paymentDetails, setPaymentDetails] = useState<PaymentDetails>({
    email: '',
    phone: '',
    lastName: '',
    firstName: '',
  });

  const { data: paymentPlans } = useGetProductsQuery();

  const [getUserData] = useLazyGetUserDataQuery();
  const [createPayment] = useCreatePaymentMutation();
  const [getTransaction] = useLazyGetTransactionQuery();

  const { data: authUserRes } = useGetAuthUserQuery();
  const { data: userDataRes } = useGetUserDataQuery();

  useEffect(() => {
    if (!trial) {
      const biAnnual = paymentPlans?.find(plan => plan.name === 'APP-HD-024');
      if (biAnnual) {
        setProduct(biAnnual._id);
      }
      return;
    }
    const annual = paymentPlans?.find(plan => plan.name === 'APP-HD-012');
    if (annual) {
      setProduct(annual._id);
    }
  }, [paymentPlans, trial]);

  useEffect(() => {
    if (userDataRes && authUserRes) {
      setPaymentDetails({
        email: authUserRes[0]?.email || '',
        phone: userDataRes[0]?.phone || '',
        lastName: authUserRes[0]?.lastName || '',
        firstName: authUserRes[0]?.firstName || '',
      });
    }
  }, [authUserRes, userDataRes]);

  const showGift = useMemo(() => {
    const findBiAnnual = paymentPlans?.find(plan => plan.name === 'APP-HD-024');
    return product === findBiAnnual?._id;
  }, [paymentPlans, product]);

  const annualPlanId = useMemo(() => {
    const annualPlan = paymentPlans?.find(plan => plan.name === 'APP-HD-012');
    return annualPlan?._id;
  }, [paymentPlans]);

  const handlePlanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProduct((event.target as HTMLInputElement).value);
  };

  const handleBuySubscription = async () => {
    try {
      const res = await createPayment({
        paymentOptions: {
          isTrial: trial,
        },
        billingDetails: {
          email: paymentDetails.email,
          phone: paymentDetails.phone,
          lastName: paymentDetails.lastName,
          firstName: paymentDetails.firstName,
        },
        productId: product,
      }).unwrap();

      const vivaWindow = window.open(res.url, '_blank');
      // check if the window is closed every 300ms
      const interval = setInterval(async () => {
        if (vivaWindow?.closed) {
          clearInterval(interval);
          const transactionRes = await getTransaction({
            transactionId: res.transaction,
          }).unwrap();

          if (transactionRes.status === 'success') {
            presentSuccess(t('errors.subscription.successPayment'));
            await getUserData();
            router.push('/dashboard/subscription', 'back', 'pop');
          } else {
            presentError(t('errors.subscription.errorPayment'));
          }
        }
      }, 300);
    } catch (err) {
      console.log(err);
      presentError(t('errors.subscription.errorPayment'));
    }
  };

  const getLabels = (product: Product) => {
    const name = product.name;
    switch (name) {
      case 'APP-HD-001':
        return t('constants.subscription.plans.mon');
      case 'APP-HD-012':
        return t('constants.subscription.plans.annua');
      case 'APP-HD-024':
        return t('constants.subscription.plans.biAnnual');
      default:
        return '-';
    }
  };

  return (
    <Box
      flexGrow={1}
      height={'100%'}
      display={'flex'}
      flexDirection={'column'}
      boxSizing={'border-box'}
      padding={theme.spacing(3)}
      bgcolor={theme.palette.background.default}
    >
      <SubsWebToolbar
        backButton
        user={user}
        avatar={avatar}
        premium={premium}
        handleGoBack={handleGoBack}
      />
      <Box gap={3} display={'flex'} flexGrow={1}>
        <Box
          gap={3}
          flexGrow={1}
          minWidth={'60%'}
          display={'flex'}
          flexDirection={'column'}
        >
          <SubscriptionPlanDetails
            web
            trial={trial}
            value={product}
            products={paymentPlans}
          />
          {showGift && <PlanGift />}
          <Box
            flex={1}
            display={'flex'}
            maxHeight={'68px'}
            borderRadius={'8px'}
            alignItems={'center'}
            boxSizing={'border-box'}
            bgcolor={theme.palette.secondary.light}
            padding={theme.spacing(1.25, 3)}
          >
            <RadioGroup
              row
              value={product}
              name={'Payment plan'}
              onChange={handlePlanChange}
              sx={{ flex: 1, display: 'flex' }}
            >
              {paymentPlans?.map(choice => (
                <Box gap={2} key={choice.value}>
                  <RadioSingle
                    bold
                    value={choice._id}
                    label={'Pay ' + getLabels(choice)}
                    disabled={trial && choice._id !== annualPlanId}
                  />
                </Box>
              ))}
            </RadioGroup>
          </Box>
          <CardPaymentInfo paymentDetails={paymentDetails} />
        </Box>
        <Box
          width={'460px'}
          sx={{
            [theme.breakpoints.down('lg')]: {
              flexGrown: 1,
              minWidth: '334px',
            },
          }}
        >
          <SubscriptionTotal
            web
            trial={trial}
            value={product}
            handleBuySubscription={handleBuySubscription}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SubsCheckoutWeb;
