import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardContentProps,
  CardMedia,
  CardMediaProps,
  CardProps,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import React, { FC } from 'react';
import CardBackgroundBig from '../../assets/CardBackgroundBig';
import CustomButton from '../controls/CustomButton';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const CardMain = styled(Card)<CardProps>(() => ({
  flex: 1,
  width: 167,
  minHeight: 212,
  display: 'flex',
  boxShadow: 'none',
  cursor: 'pointer',
  overflow: 'hidden',
  borderRadius: '20px',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

type DashboardCardBigType = {
  title: string;
  time?: string;
  color?: string;
  onClick: () => void;
  twoColumns?: boolean;
  icon: React.ReactNode;
  cardMediaProps?: CardMediaProps;
  cardContentProps?: CardContentProps;
  data: { label: string; info: string }[];
};
const DashboardCardBig: FC<DashboardCardBigType & CardProps> = ({
  time,
  icon,
  data,
  color,
  title,
  onClick,
  twoColumns,
  cardMediaProps,
  cardContentProps,
  ...cardProps
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const Background = () => {
    return (
      <Box width={'100%'} height={'100%'} sx={{ position: 'absolute', top: 0, right: 0 }}>
        <CardBackgroundBig fill={color} width={'100%'} height={'100%'} />
      </Box>
    );
  };

  return (
    <CardMain raised={false} {...cardProps} onClick={onClick}>
      <CardMedia component={Background} {...cardMediaProps} />
      <CardContent
        {...cardContentProps}
        sx={{ position: 'relative', padding: '16px 8px 0px' }}
      >
        <Box display={'flex'} alignItems={'center'}>
          {icon}
          <Typography
            variant={'subtitle1'}
            ml={theme.spacing(1)}
            color={theme.palette.highlight.main}
            fontWeight={theme.typography.fontWeightMedium}
          >
            {title}
          </Typography>
        </Box>
        {time && (
          <Typography variant={'body2'} color={theme.palette.highlight.main}>
            {t('general.labels.updated')} {time}
          </Typography>
        )}
        <Grid
          container
          spacing={1}
          marginY={1}
          textOverflow={'ellipsis'}
          justifyContent={'space-between'}
        >
          {data.map((item, index) => {
            return (
              <Grid item xs={twoColumns ? 6 : 12} key={`cardLabelLabelInfo${index}`}>
                <Typography
                  noWrap={true}
                  variant={'subtitle2'}
                  sx={{ textTransform: 'capitalize' }}
                  color={theme.palette.highlight.main}
                  fontWeight={theme.typography.fontWeightBold}
                >
                  {item.label}
                </Typography>
                <Typography
                  noWrap={true}
                  variant={'subtitle2'}
                  textOverflow={'ellipsis'}
                  color={theme.palette.highlight.main}
                >
                  {item.info}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
      <CardActions sx={{ padding: 0, margin: theme.spacing(2, 0) }}>
        <CustomButton fullWidth size={'small'} color={'secondary'}>
          {t('buttons.navigation.viewMore')}
        </CustomButton>
      </CardActions>
    </CardMain>
  );
};

export default DashboardCardBig;
