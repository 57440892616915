import { CvdRiskLog, ModalHeartRiskEU } from '@hdcorner/ui-library';
import { Box } from '@mui/material';
import useGetUserData from '../hooks/useGetUserData';
import {
  useAddCvdRiskLogMutation,
  useGetCvdCurrentMeasurementQuery,
  useGetCvdRiskGoalQuery,
  useGetCvdStartingMeasurementQuery,
} from '../queries/cvdRiskQueries';
import { FC, useMemo } from 'react';
import useAlert from '../../../hooks/useAlert';
import { useAppDispatch } from '../../../redux/hooks';
import { goalProgress } from '../../../utils/goalProgress';
import { addGoalDetails } from '../../dashboard/slices/congratulationsSlice';
import { useTranslation } from 'react-i18next';

type Props = {
  dismiss: () => void;
};
const HeartRiskModalWeb: FC<Props> = ({ dismiss }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const useDetails = useGetUserData();
  const { presentSuccess, presentError } = useAlert();

  const [addCvdRiskLog] = useAddCvdRiskLogMutation();

  const { data: cvdRiskGoal } = useGetCvdRiskGoalQuery();
  const { data: cvdCurrent } = useGetCvdCurrentMeasurementQuery();

  const goalData = useMemo(() => {
    if (!cvdRiskGoal || cvdRiskGoal.length === 0) {
      return { goal: 0, date: '' };
    }
    return { goal: cvdRiskGoal[0].goal, date: cvdRiskGoal[0].startDate };
  }, [cvdRiskGoal]);

  const { data: cvdStarting } = useGetCvdStartingMeasurementQuery(
    {
      startingDate: goalData.date,
    },
    { skip: !goalData.date },
  );

  const startLog = useMemo(() => {
    if (!cvdStarting || cvdStarting.logs.length === 0) return 0;
    return cvdStarting.logs[0].score;
  }, [cvdStarting]);

  const currentLog = useMemo(() => {
    if (!cvdCurrent || cvdCurrent.logs.length === 0) return 0;
    return cvdCurrent.logs[0].score;
  }, [cvdCurrent]);

  const handleAddCvdRisk = (data: Partial<CvdRiskLog>) => {
    const { logDate, totalChol, sysBloodPressure } = data;

    addCvdRiskLog({
      logDate,
      totalChol,
      sysBloodPressure,
    })
      .unwrap()
      .then(() => presentSuccess(t('errors.measurements.saved')))
      .catch(() => presentError(t('errors.generic.error01')))
      .finally(() => {
        dismiss();
        if (
          goalProgress(currentLog, goalData.goal, startLog) === 100 &&
          goalData.date !== ''
        ) {
          setTimeout(() => {
            dispatch(
              addGoalDetails({
                category: 'cvd',
                goal: { value: goalData.goal, measure: '%' },
                start: { value: startLog, measure: '%', time: goalData.date },
              }),
            );
          }, 750);
        }
      });
  };

  const handleSaveHeartRiskModal = (data: Partial<CvdRiskLog>) => {
    const dataForRequest = {
      logDate: data.logDate,
      totalChol: data.totalChol,
      sysBloodPressure: data.sysBloodPressure,
    };
    handleAddCvdRisk(dataForRequest);
  };

  return (
    <Box>
      <ModalHeartRiskEU
        userAge={useDetails.age}
        userGender={useDetails.gender}
        userSmoker={useDetails.smoker || false}
        handleModalSaveClick={handleSaveHeartRiskModal}
      />
    </Box>
  );
};

export default HeartRiskModalWeb;
