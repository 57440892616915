import React, { FC, useState } from 'react';
import {
  Box,
  BoxProps,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardHeaderProps,
  CardProps,
  Collapse,
  CollapseProps,
  styled,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import FoodItems from './FoodItems';
import { Food, MealItem } from '../../types/Food';
import SmallMacros from '../charts/SmallMacros';
import CustomButton from '../controls/CustomButton';
import { MacrosMetrics } from '../../types/MacrosMetrics';
import PlusCircleIcon from '../../assets/PlusCircleIcon';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';

const CardMain = styled(Card)<CardProps>(() => ({
  flexGrow: 1,
  padding: 0,
  display: 'flex',
  boxShadow: 'none',
  borderRadius: '8px',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const CardHeaderMain = styled(CardHeader, {
  shouldForwardProp: propName => propName !== 'white',
})<CardHeaderProps & { white: boolean }>(({ theme, white }) => ({
  height: '35px',
  boxSizing: 'border-box',
  padding: theme.spacing(1, 2),
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.background.default
      : white
      ? theme.palette.secondary.light
      : '#FBFBFB',
}));

const BoxHeader = styled(Box)<BoxProps>(() => ({
  flexGrow: 1,
  height: '35px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const CollapseStyle = styled(Collapse, {
  shouldForwardProp: propName => propName !== 'white',
})<CollapseProps & { white: boolean }>(({ theme, white }) => ({
  width: '100%',
  borderTop: white ? 'none' : `1px solid ${theme.palette.secondary.dark}`,
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.background.default : '#FBFBFB',
}));

const BoxInner = styled(Box)<BoxProps>(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  boxSizing: 'border-box',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const BoxSecondary = styled(Box)<BoxProps>(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
  justifyContent: 'space-between',
}));

const TextTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  maxWidth: '95px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  textTransform: 'capitalize',
  color: theme.palette.primary.main,
  marginRight: theme.spacing(1),
  fontSize: theme.typography.subtitle2.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
}));

const TextSecondary = styled(Typography)<TypographyProps>(({ theme }) => ({
  maxWidth: '90px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontSize: theme.typography.body1.fontSize,
  color: theme.palette.kmColorsCoolGrey.main,
  fontWeight: theme.typography.body1.fontWeight,
}));

type MealCollapseCardProps = {
  title: string;
  admin?: boolean;
  white?: boolean;
  totalCal: number;
  description: string;
  addMore?: () => void;
  mealItems: MealItem[];
  metrics: MacrosMetrics[];
  handleRemoveItem: (foodId: string) => void;
  handleAddMealItems?: (data: MealItem[]) => void;
};

const MealCollapseCard: FC<MealCollapseCardProps> = ({
  title,
  admin = false,
  addMore,
  metrics,
  totalCal,
  mealItems,
  description,
  white = false,
  handleRemoveItem,
  handleAddMealItems,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const renderMeasure = (metric: MacrosMetrics) => {
    if (metric.max) {
      return `${metric.eaten} / ${metric.max} ${metric.measure}`;
    }
    return `${metric.eaten} ${metric.measure}`;
  };

  const getName = (food: Food) => {
    return typeof food.name === 'string' ? food.name : food.name[lang];
  };

  return (
    <CardMain>
      <CardActionArea onClick={() => setExpanded(!expanded)}>
        <CardHeaderMain
          white={white}
          title={
            <BoxHeader>
              <Box display={'flex'}>
                <TextTitle>{title}</TextTitle>
                <TextSecondary>{description}</TextSecondary>
              </Box>
              <Box display={'flex'} alignItems={'center'}>
                <TextSecondary>{`${totalCal} kcal`}</TextSecondary>
                {!admin && white && (
                  <Box sx={{ padding: 0, marginLeft: 1 }}>
                    <PlusCircleIcon
                      onClick={event => {
                        event.stopPropagation();
                        handleAddMealItems?.(mealItems);
                      }}
                    />
                  </Box>
                )}
              </Box>
            </BoxHeader>
          }
          action={
            <Box
              sx={{
                marginLeft: 'auto',
                color: theme.palette.primary.main,
                padding: theme.spacing(0.5),
                transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
                transition: theme.transitions.create('transform', {
                  duration: theme.transitions.duration.shortest,
                }),
              }}
              onClick={handleExpandClick}
            >
              {!white && <ExpandMoreIcon />}
            </Box>
          }
        ></CardHeaderMain>
      </CardActionArea>
      <CollapseStyle in={expanded} timeout="auto" white={white} unmountOnExit>
        <CardContent>
          <BoxInner>
            {mealItems.map(item => (
              <FoodItems
                admin={admin}
                quantity={item.quantity}
                key={(item.food as Food)._id}
                name={getName(item.food as Food)}
                calories={(item.food as Food).calculatedCalories}
                handleAddItem={() => handleRemoveItem((item.food as Food)._id as string)}
              />
            ))}
            <BoxSecondary>
              {metrics.map((metric, index) => (
                <Box display={'flex'} flex={1} key={`${metric.category}_${index}`}>
                  <SmallMacros
                    max={metric.max}
                    value={metric.eaten}
                    title={metric.category}
                    measure={renderMeasure(metric)}
                  />
                </Box>
              ))}
            </BoxSecondary>
            {!admin && (
              <CustomButton
                fullWidth
                color={'primary'}
                variant={'contained'}
                children={t('buttons.addMore')}
                onClick={addMore ? addMore : undefined}
              />
            )}
          </BoxInner>
        </CardContent>
      </CollapseStyle>
    </CardMain>
  );
};

export default MealCollapseCard;
