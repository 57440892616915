import { MealList } from '../hooks/usePrepareMealList';
import { Food, Meal, MealItem } from '../types/Food';
import { capitalizeFirstLetter } from './capitalizeFirstLetter';

export const findMeal = (meals: Meal[] | undefined, mealCategory: string) => {
  if (!meals || meals.length === 0) {
    return undefined;
  }
  return meals.find(meal => meal.category === mealCategory);
};

export const totalValue = (
  mealItems: MealItem[] | undefined,
  macro: 'calories' | 'fat' | 'carbs' | 'protein',
) => {
  if (!mealItems || mealItems.length === 0) {
    return 0;
  }
  const macros = mealItems.map((food: MealItem) => (food.food as Food)[macro]);
  const quantity = mealItems.map(it => it.quantity);
  const calculation = macros.map((it: number, index: number) => {
    return Math.round((it / 100) * quantity[index]);
  });

  return calculation.reduce((sum: number, current: number) => sum + current, 0);
};

export const foodTitlesString = (mealItems: MealItem[]) => {
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';
  const names = mealItems.map((mealItem: MealItem) => {
    const food = mealItem.food as Food;
    return typeof food.name === 'string' ? food.name : food.name[lang];
  });
  const namesCapitalised = names.map((name: string) => {
    return capitalizeFirstLetter(name);
  });
  return namesCapitalised.join(', ');
};

export const findFoodById = (meals: MealList[], value: string) => {
  if (!meals || meals.length === 0) {
    return undefined;
  }

  return meals.find(meal => {
    if (!meal.mealItems || !Array.isArray(meal.mealItems)) {
      return false;
    }

    const foundFood = meal.mealItems.find(item => (item.food as Food)._id === value);
    return foundFood !== undefined;
  });
};
