import * as React from 'react';
import { SVGProps } from 'react';

const TrophyIcon = ({ fill = '#222222', ...rest }: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M21 2H18V1C18 0.734784 17.8946 0.48043 17.7071 0.292893C17.5196 0.105357 17.2652 0 17 0H5C4.73478 0 4.48043 0.105357 4.29289 0.292893C4.10536 0.48043 4 0.734784 4 1V2H1C0.734784 2 0.48043 2.10536 0.292893 2.29289C0.105357 2.48043 0 2.73478 0 3V5C0.00362544 6.19749 0.437419 7.3538 1.22231 8.2582C2.00721 9.16259 3.09095 9.75484 4.276 9.927C4.64939 11.2213 5.38786 12.3806 6.40301 13.2661C7.41816 14.1516 8.66698 14.7258 10 14.92V17H6C5.79004 16.9999 5.58538 17.066 5.41505 17.1888C5.24472 17.3115 5.11735 17.4848 5.051 17.684L4.051 20.684C4.00093 20.8343 3.9873 20.9944 4.01123 21.151C4.03517 21.3077 4.09598 21.4564 4.18866 21.5849C4.28134 21.7134 4.40323 21.8181 4.54429 21.8903C4.68536 21.9624 4.84155 22 5 22H17C17.1584 21.9999 17.3144 21.9621 17.4554 21.8899C17.5963 21.8177 17.7181 21.713 17.8106 21.5845C17.9032 21.456 17.9639 21.3074 17.9878 21.1508C18.0117 20.9942 17.998 20.8343 17.948 20.684L16.948 17.684C16.8817 17.485 16.7545 17.3118 16.5844 17.1891C16.4142 17.0663 16.2098 17.0002 16 17H12V14.92C13.333 14.7258 14.5818 14.1516 15.597 13.2661C16.6121 12.3806 17.3506 11.2213 17.724 9.927C18.9091 9.75484 19.9928 9.16259 20.7777 8.2582C21.5626 7.3538 21.9964 6.19749 22 5V3C22 2.73478 21.8946 2.48043 21.7071 2.29289C21.5196 2.10536 21.2652 2 21 2ZM4 7.829C3.41549 7.62142 2.90951 7.2381 2.55144 6.73161C2.19338 6.22513 2.00076 5.62027 2 5V4H4V7.829ZM15.279 19L15.612 20H6.387L6.721 19H15.279ZM16 8C16 9.32608 15.4732 10.5979 14.5355 11.5355C13.5979 12.4732 12.3261 13 11 13C9.67392 13 8.40215 12.4732 7.46447 11.5355C6.52678 10.5979 6 9.32608 6 8V2H16V8ZM20 5C19.9992 5.62027 19.8066 6.22513 19.4486 6.73161C19.0905 7.2381 18.5845 7.62142 18 7.829V4H20V5ZM9.667 7.667L8 6.292L10 6L11 4L12 6L14 6.292L12.333 7.667L12.854 10L11 8.667L9.146 10L9.667 7.667Z"
      fill={fill}
    />
  </svg>
);

export default TrophyIcon;
