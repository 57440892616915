import { styled } from '@mui/material/styles';
import { ListItem, ListItemProps } from '@mui/material';

const ListItemMain = styled(ListItem)<ListItemProps>(({ theme }) => ({
  padding: theme.spacing(0),
  '&:first-of-type': {
    marginTop: theme.spacing(0),
  },
  '&:not(:first-of-type)': {
    marginTop: theme.spacing(1),
  },
}));

export default ListItemMain;
