import React from 'react';
import { ComponentsLayout } from '@hdcorner/ui-library';
import PageLayout from '../../../components/PageLayout';
import SectionGoalSteps from './components/SectionGoalSteps';
import { useTranslation } from 'react-i18next';

const FitnessGoalSteps = () => {
  const { t } = useTranslation();

  return (
    <PageLayout
      headerTitle={t('headingsTitles.stepsGoal')}
      defaultHref={'/dashboard/fitness/exercise'}
    >
      <ComponentsLayout>
        <SectionGoalSteps />
      </ComponentsLayout>
    </PageLayout>
  );
};
export default FitnessGoalSteps;
