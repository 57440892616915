import React, { Dispatch, FC, ReactNode, SetStateAction, useEffect } from 'react';
import {
  Box,
  BoxProps,
  Card,
  CardProps,
  styled,
  Typography,
  TypographyProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ToggleButtons from '../layout/ToggleButtons';

const CardMain = styled(Card, {
  shouldForwardProp: prop => prop !== 'admin',
})<CardProps & { admin: boolean }>(({ admin, theme }) => ({
  display: 'flex',
  boxShadow: 'none',
  overflow: 'hidden',
  borderRadius: '8px',
  position: 'relative',
  boxSizing: 'border-box',
  flexDirection: 'column',
  padding: theme.spacing(2, 2),
  backgroundColor: admin ? theme.palette.secondary.main : theme.palette.secondary.light,
}));

const BoxHeading = styled(Box, {
  shouldForwardProp: prop => prop !== 'admin',
})<BoxProps & { admin: boolean }>(({ admin, theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  paddingX: theme.spacing(2),
  justifyContent: admin ? 'flex-end' : 'space-between',
}));

const TypographyValue = styled(Typography, {
  shouldForwardProp: prop => prop !== 'admin',
})<TypographyProps & { admin: boolean }>(({ admin, theme }) => ({
  fontSize: 54,
  lineHeight: '74px',
  fontWeight: theme.typography.fontWeightMedium,
  color: admin ? theme.palette.kmColorsCoolGrey.main : theme.palette.primary.main,
}));

const TypographyTitle = styled(Typography, {
  shouldForwardProp: prop => prop !== 'admin',
})<TypographyProps & { admin: boolean }>(({ admin, theme }) => ({
  fontSize: '32px',
  marginLeft: theme.spacing(2),
  fontWeight: theme.typography.fontWeightRegular,
  color: admin ? theme.palette.kmColorsDarkGrey.main : theme.palette.primary.light,
}));

type ChartCardProps = {
  unit: string;
  value?: string;
  setValue?: any;
  admin?: boolean;
  height?: string;
  children: ReactNode;
  amount: string | number;
  BMIIcon?: ReactNode | undefined;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  headings: { label: string; value: string }[];
};
const ChartCard: FC<ChartCardProps> = ({
  unit,
  admin = false,
  height,
  amount,
  BMIIcon,
  setOpen,
  value,
  setValue,
  headings,
  children,
}) => {
  const theme = useTheme();
  const web = useMediaQuery(theme.breakpoints.up('sm'));

  const handleChange = (newValue: string) => {
    if (setOpen && newValue === 'custom') {
      setOpen(true);
    }
    setValue(newValue);
  };

  useEffect(() => {
    if (setOpen) setOpen(value === 'custom');
  }, [value]);

  return (
    <CardMain admin={admin} sx={{ height: height ? height : web ? '365px' : 'auto' }}>
      {!admin && (
        <BoxHeading admin={admin}>
          {!admin && value && (
            <ToggleButtons
              value={value}
              headings={headings}
              handleChange={handleChange}
              color={theme.palette.exercise.main}
            />
          )}
          {BMIIcon && BMIIcon}
        </BoxHeading>
      )}
      <Box display={'flex'} flexDirection={'column'}>
        <Box flex={1} display={'flex'} alignItems={'center'}>
          <TypographyValue admin={admin}>{amount}</TypographyValue>
          <TypographyTitle admin={admin} variant={'subtitle2'}>
            {unit}
          </TypographyTitle>
        </Box>
        {children}
      </Box>
    </CardMain>
  );
};

export default ChartCard;
