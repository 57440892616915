import api from '../../../redux/api';
import {
  ApiPagination,
  ApiResult,
  CvdRiskGoal,
  CvdRiskLog,
  PaginationResult,
} from '@hdcorner/ui-library';

const cvdRiskQueries = api
  .enhanceEndpoints({ addTagTypes: ['CvdRiskLog', 'CvdRiskGoal'] })
  .injectEndpoints({
    endpoints: build => ({
      addCvdRiskLog: build.mutation<ApiResult<CvdRiskLog[]>, Partial<CvdRiskLog>>({
        query: ({ totalChol, sysBloodPressure, logDate }) => ({
          method: 'POST',
          url: '/database/function/addCvdRiskLog',
          body: {
            logDate,
            totalChol,
            sysBloodPressure,
          },
        }),
        invalidatesTags: ['CvdRiskLog', 'Dashboard'],
      }),

      getCvdRiskLogs: build.query<
        PaginationResult<CvdRiskLog>,
        ApiPagination & { start?: string; end?: string }
      >({
        query: ({ skip = 0, limit, start, end }) => {
          return {
            method: 'GET',
            url: `/hdCornerService/user/cvd-risk/in-depth`,
            params: { skip, limit, sort: ['-logDate'], start, end },
          };
        },
        serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
          return endpointName;
        },
        merge: (currentCache, newItems, otherArgs) => {
          if (otherArgs.arg.skip === 0) {
            currentCache.logs = newItems?.logs;
          } else {
            currentCache?.logs.push(...newItems?.logs);
          }
        },
        forceRefetch: ({ previousArg, currentArg }) => {
          return currentArg !== previousArg;
        },
        providesTags: ['CvdRiskLog'],
      }),

      getCvdStartingMeasurement: build.query<
        PaginationResult<CvdRiskLog>,
        { startingDate?: string }
      >({
        query: ({ startingDate }) => ({
          method: 'GET',
          url: `/hdCornerService/user/cvd-risk/in-depth`,
          params: { skip: 0, limit: 1, startingDate, sort: 'logDate' },
        }),
        providesTags: ['CvdRiskLog'],
      }),

      getCvdCurrentMeasurement: build.query<PaginationResult<CvdRiskLog>, void>({
        query: () => ({
          method: 'GET',
          url: `/hdCornerService/user/cvd-risk/in-depth`,
          params: { skip: 0, limit: 1, sort: '-logDate' },
        }),
        providesTags: ['CvdRiskLog'],
      }),

      getCvdRiskGoal: build.query<CvdRiskGoal[], void>({
        query: () => ({
          method: 'GET',
          params: { goalType: 'cvd_risk' },
          url: `/database/function/getUserGoal`,
        }),
        providesTags: ['CvdRiskGoal'],
      }),

      addCvdRiskGoal: build.mutation<CvdRiskGoal, Partial<CvdRiskGoal>>({
        query: ({ goal, goalType, startDate }) => ({
          method: 'POST',
          url: `/hdCornerService/user/goal`,
          body: { goal, goalType, startDate },
        }),
        invalidatesTags: ['CvdRiskGoal'],
      }),

      editCvdRiskGoal: build.mutation<CvdRiskGoal[], Partial<CvdRiskGoal>>({
        query: ({ _id, goal, startDate }) => ({
          method: 'PATCH',
          body: { goal, startDate },
          url: `/hdCornerService/user/goal/${_id}`,
        }),
        invalidatesTags: ['CvdRiskGoal'],
      }),

      deleteCvdRiskGoal: build.mutation<void, { _id: string }>({
        query: ({ _id }) => ({
          method: 'DELETE',
          url: `/database/UserGoal/${_id}`,
        }),
        invalidatesTags: ['CvdRiskGoal'],
      }),

      getCvdGraphData: build.query<
        { logs: CvdRiskLog[] },
        { start: string; end: string } | void
      >({
        query: params => ({
          method: 'GET',
          params: { ...params },
          url: `/hdCornerService/user/cvd-risk/graph`,
        }),
        providesTags: ['CvdRiskLog'],
      }),
    }),
  });

export const {
  useGetCvdRiskLogsQuery,
  useGetCvdRiskGoalQuery,
  useGetCvdGraphDataQuery,
  useAddCvdRiskLogMutation,
  useAddCvdRiskGoalMutation,
  useEditCvdRiskGoalMutation,
  useDeleteCvdRiskGoalMutation,
  useGetCvdCurrentMeasurementQuery,
  useGetCvdStartingMeasurementQuery,
} = cvdRiskQueries;

export default cvdRiskQueries;
