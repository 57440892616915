import * as React from 'react';
import { SVGProps } from 'react';
import { lighten } from '@mui/material';
import { useTheme } from '@mui/material';

const CardBackgroundBig = ({
  fill = useTheme().palette.hypertension.main,
  ...rest
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={166}
    height={212}
    fill="none"
    viewBox={'0 0 166 212'}
    preserveAspectRatio={'none'}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <rect width={166} height={212} fill={fill} />
    <path
      d="M150 212c8.837 0 16-7.163 16-16V16c0-8.837-7.163-16-16-16H26.5C63.667 22 121.6 95.2 140 212h10Z"
      fill={lighten(fill, 0.1)}
    />
  </svg>
);

export default CardBackgroundBig;
