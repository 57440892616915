import React, { FC } from 'react';
import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
  useMediaQuery,
} from '@mui/material';
import { CustomSlider, theme } from '@hdcorner/ui-library';
import { useTranslation } from 'react-i18next';

const BoxMain = styled(Box)<BoxProps & { web: boolean }>(({ web, theme }) => ({
  flexGrow: 1,
  borderRadius: '8px',
  boxSizing: 'border-box',
  padding: theme.spacing(2, 1, 0.25, 1),
  backgroundColor: web ? theme.palette.highlight.main : theme.palette.secondary.light,
}));

const TitleText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(1),
  fontSize: theme.typography.subtitle2.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
}));

type SliderSectionProps = {
  grams: number;
  title: string;
  onChange: (value: number | number[]) => void;
};
const SliderSection: FC<SliderSectionProps> = ({ grams, title, onChange }) => {
  const { t } = useTranslation();
  const web = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box width={'100%'} display={'flex'} flexDirection={'column'}>
      <TitleText>{t('fitness.calories.text.grPerWeek', { gainLose: title })}</TitleText>
      <BoxMain web={web}>
        <CustomSlider
          min={500}
          max={1000}
          selected={grams}
          valueLabelDisplay={'on'}
          setSliderValue={onChange}
          valueLabelText={`${t('measurements.gr')}`}
          getAriaValueText={value => `${value}${t('measurements.gr')}`}
          marks={[
            { value: 500, label: `500${t('measurements.gr')}` },
            { value: 1000, label: `1000${t('measurements.gr')}` },
          ]}
        />
      </BoxMain>
    </Box>
  );
};

export default SliderSection;
