import { useIonRouter } from '@ionic/react';
import UserDetailsOne from './UserDetailsOne';
import OnBoardingHeading from './OnBoardingHeading';
import UserDetailsTwo from './UserDetailsTwo';
import React, { useEffect, useState } from 'react';
import UserDetailsThree from './UserDetailsThree';
import { AuthUser, BarStepper, ComponentsLayout, UserData } from '@hdcorner/ui-library';
import { Box } from '@mui/material';
import {
  useSetOnboardingOneMutation,
  useSetOnboardingTwoMutation,
} from '../../queries/onBoardingQueries';
import useQuery from '../../../../hooks/useQuery';
import { useAppDispatch } from '../../../../redux/hooks';
import { setAuthUser, setUserData } from '../../authSlice';
import useAlert from '../../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';
import {
  useGetUserDataQuery,
  useUpdateUserDataMutation,
} from '../../../profile/queries/personalInfoQueries';

const OnBoardingPanelMobile = () => {
  const dispatch = useAppDispatch();
  const { presentError } = useAlert();
  const router = useIonRouter();
  const searchParams = useQuery();
  const { t } = useTranslation();

  const [onboardingPtOne, { isLoading: onboardingOneLoading }] =
    useSetOnboardingOneMutation();
  const [onboardingPtTwo, { isLoading: onBoardingTwoLoading }] =
    useSetOnboardingTwoMutation();
  const [updateUserData, { isLoading: updateUserDataLoading }] =
    useUpdateUserDataMutation();

  const { data: userDataRes } = useGetUserDataQuery();

  const [count, setCount] = useState<number>(1);

  useEffect(() => {
    if (searchParams.get('incomplete')) {
      setCount(3);
    }
  }, [searchParams]);

  const handleNextPage = (data: Partial<AuthUser & UserData>) => {
    if (count === 3) {
      sendUserDataTwo(data);
    } else {
      sendUserDataOne(data);
    }
  };

  const sendUserDataOne = async (data: Partial<AuthUser>) => {
    try {
      await onboardingPtOne({
        gender: data.gender,
        lastName: data.lastName,
        firstName: data.firstName,
        birthDate: data.birthDate,
      }).unwrap();

      dispatch(
        setAuthUser({
          gender: data.gender,
          lastName: data.lastName,
          firstName: data.firstName,
          birthDate: data.birthDate,
        }),
      );

      setCount(count + 1);
    } catch (e) {
      console.log(e);
      presentError(t('authentication.onboarding.error1'));
    }
  };

  const sendUserDataTwo = async (data: Partial<UserData>) => {
    try {
      const body: Partial<UserData> = {
        phone: data.phone,
        smoker: data.smoker,
        height: data.height,
        weight: data.weight,
        diabetes: data.diabetes,
        hypertension: data.hypertension,
        dyslipidemia: data.dyslipidemia,
        underMedication: data.underMedication,
        socialSecurityNumber: data.socialSecurityNumber,
      };
      if (userDataRes?.[0]) {
        await updateUserData({
          ...userDataRes[0],
          ...body,
        }).unwrap();
      } else {
        await onboardingPtTwo(body).unwrap();
      }
      dispatch(setUserData(body));
      router.push('/dashboard/questionnaire');
      setCount(1);
    } catch (e) {
      console.log(e);
      presentError(t('authentication.onboarding.error1'));
    }
  };

  return (
    <>
      {count === 1 ? (
        <BarStepper value={25} />
      ) : count === 2 ? (
        <BarStepper value={50} />
      ) : (
        <BarStepper value={75} />
      )}

      <ComponentsLayout>
        <Box maxWidth={'350px'} margin={'0 auto'}>
          <OnBoardingHeading web={false} />
          {count === 1 && (
            <UserDetailsOne
              handleNextPage={handleNextPage}
              loading={onboardingOneLoading}
            />
          )}
          {count === 2 && (
            <UserDetailsTwo
              loading={onBoardingTwoLoading}
              handleNextPage={handleNextPage}
            />
          )}
          {count === 3 && (
            <UserDetailsThree
              user={userDataRes?.[0]}
              handleNextPage={handleNextPage}
              loading={onBoardingTwoLoading || updateUserDataLoading}
            />
          )}
        </Box>
      </ComponentsLayout>
    </>
  );
};

export default OnBoardingPanelMobile;
