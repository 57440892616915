import React, { FC, useMemo } from 'react';
import { LipidLog, ModalCholesterolData } from '@hdcorner/ui-library';
import { Box } from '@mui/material';
import {
  useAddLipidLogMutation,
  useGetLipidFirstLogQuery,
  useGetLipidGoalQuery,
  useGetLipidLastLogQuery,
} from '../queries/lipidQueries';
import useAlert from '../../../hooks/useAlert';
import { useAppDispatch } from '../../../redux/hooks';
import { goalProgress } from '../../../utils/goalProgress';
import { addGoalDetails } from '../../dashboard/slices/congratulationsSlice';
import { useTranslation } from 'react-i18next';

type Props = {
  setUsersDataModalOpen: (bool: boolean) => void;
};
const LipidModalWeb: FC<Props> = ({ setUsersDataModalOpen }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { presentSuccess, presentError } = useAlert();

  const [addLipidLog] = useAddLipidLogMutation();

  const { data: lpaGoal } = useGetLipidGoalQuery({ type: 'lpa' });
  const { data: hdlGoal } = useGetLipidGoalQuery({ type: 'hdl' });
  const { data: ldlGoal } = useGetLipidGoalQuery({ type: 'ldl' });
  const { data: trgGoal } = useGetLipidGoalQuery({ type: 'trg' });
  const { data: cholGoal } = useGetLipidGoalQuery({ type: 'chol' });

  const lpaGoalData = useMemo(() => {
    if (!lpaGoal || lpaGoal.length === 0) return { log: 0, date: '' };
    return { log: Number(lpaGoal[0].goal.measurement), date: lpaGoal[0].startDate };
  }, [lpaGoal]);

  const hdlGoalData = useMemo(() => {
    if (!hdlGoal || hdlGoal.length === 0) return { log: 0, date: '' };
    return { log: Number(hdlGoal[0].goal.measurement), date: hdlGoal[0].startDate };
  }, [hdlGoal]);

  const ldlGoalData = useMemo(() => {
    if (!ldlGoal || ldlGoal.length === 0) return { log: 0, date: '' };
    return { log: Number(ldlGoal[0].goal.measurement), date: ldlGoal[0].startDate };
  }, [ldlGoal]);

  const trgGoalData = useMemo(() => {
    if (!trgGoal || trgGoal.length === 0) return { log: 0, date: '' };
    return { log: Number(trgGoal[0].goal.measurement), date: trgGoal[0].startDate };
  }, [trgGoal]);

  const cholGoalData = useMemo(() => {
    if (!cholGoal || cholGoal.length === 0) return { log: 0, date: '' };
    return { log: Number(cholGoal[0].goal.measurement), date: cholGoal[0].startDate };
  }, [cholGoal]);

  const { data: lipidCurrent } = useGetLipidLastLogQuery();
  const { data: lipidStarting } = useGetLipidFirstLogQuery(
    {
      startingDate: cholGoalData.date,
    },
    { skip: !cholGoalData.date },
  );

  const startData = useMemo(() => {
    const data =
      !lipidStarting || lipidStarting.documents.length === 0
        ? undefined
        : lipidStarting.documents[0];
    return {
      lpa: data ? Number(data.lpa) : 0,
      hdl: data ? Number(data.hdl) : 0,
      ldl: data ? Number(data.ldl) : 0,
      chol: data ? Number(data.totalChol) : 0,
      trg: data ? Number(data.triglycerides) : 0,
    };
  }, [lipidStarting]);

  const currentData = useMemo(() => {
    const data =
      !lipidCurrent || lipidCurrent.documents.length === 0
        ? undefined
        : lipidCurrent.documents[0];
    return {
      lpa: data ? Number(data.lpa) : 0,
      hdl: data ? Number(data.hdl) : 0,
      ldl: data ? Number(data.ldl) : 0,
      chol: data ? Number(data.totalChol) : 0,
      trg: data ? Number(data.triglycerides) : 0,
    };
  }, [lipidCurrent]);

  const handleAddLipid = async (data: Omit<LipidLog, '_id'>) => {
    let { totalChol, triglycerides, hdl, ldl, lpa, logDate } = data;

    try {
      await addLipidLog({ totalChol, triglycerides, hdl, ldl, lpa, logDate })
        .unwrap()
        .then(() => {
          presentSuccess(t('errors.lipid.successLogAdd'));
          const goalDetailsArray: Array<{
            type: keyof typeof currentData;
            data: number;
            goalData: {
              log: number;
              date: string;
            };
            startData: {
              chol: number;
              trg: number;
              hdl: number;
              ldl: number;
              lpa: number;
            };
          }> = [
            { type: 'chol', data: currentData.chol, goalData: cholGoalData, startData },
            { type: 'trg', data: currentData.trg, goalData: trgGoalData, startData },
            { type: 'hdl', data: currentData.hdl, goalData: hdlGoalData, startData },
            { type: 'ldl', data: currentData.ldl, goalData: ldlGoalData, startData },
            { type: 'lpa', data: currentData.lpa, goalData: lpaGoalData, startData },
          ];

          goalDetailsArray.forEach(({ type, data, goalData, startData }) => {
            if (
              goalProgress(data, goalData.log, startData[type]) === 100 &&
              goalData.date !== ''
            ) {
              setTimeout(() => {
                dispatch(
                  addGoalDetails({
                    category: 'lipid_profile',
                    goal: {
                      value: goalData.log,
                      measure: `mg/dL - ${type.toUpperCase()}`,
                    },
                    start: {
                      value: startData[type],
                      measure: `mg/dL - ${type.toUpperCase()}`,
                      time: goalData.date,
                    },
                  }),
                );
              }, 1500);
            }
          });
        });
    } catch (e) {
      presentError(t('errors.lipid.errorLogAdd'));
    }
  };

  const handleSaveLipidModal = async (data: Omit<LipidLog, '_id'>) => {
    await handleAddLipid(data);
    setUsersDataModalOpen(false);
  };

  return (
    <Box>
      <ModalCholesterolData handleModalSaveClick={handleSaveLipidModal} />
    </Box>
  );
};

export default LipidModalWeb;
