import * as React from 'react';
import { SVGProps } from 'react';
const NotGoodEmoji = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
      fill="url(#paint0_radial_31_1584)"
    />
    <path
      d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
      fill="url(#paint1_radial_31_1584)"
    />
    <path
      d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
      fill="url(#paint2_radial_31_1584)"
      fillOpacity={0.6}
    />
    <path
      d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
      fill="url(#paint3_radial_31_1584)"
    />
    <path
      d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
      fill="url(#paint4_radial_31_1584)"
    />
    <path
      d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
      fill="url(#paint5_radial_31_1584)"
    />
    <path
      d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
      fill="url(#paint6_radial_31_1584)"
    />
    <circle cx={9.01705} cy={15.4205} r={4.6733} fill="url(#paint7_radial_31_1584)" />
    <circle cx={19.2443} cy={15.9432} r={4.24431} fill="url(#paint8_radial_31_1584)" />
    <path
      d="M10.4191 18.4119C12.742 18.4119 14.6251 16.5289 14.6251 14.206C14.6251 11.8831 12.742 10 10.4191 10C8.09621 10 6.21313 11.8831 6.21313 14.206C6.21313 16.5289 8.09621 18.4119 10.4191 18.4119Z"
      fill="white"
    />
    <path
      d="M21.5683 18.4886C23.9123 18.4886 25.8126 16.5884 25.8126 14.2443C25.8126 11.9002 23.9123 10 21.5683 10C19.2242 10 17.324 11.9002 17.324 14.2443C17.324 16.5884 19.2242 18.4886 21.5683 18.4886Z"
      fill="white"
    />
    <path
      d="M13.5 14.5C13.5 15.8807 12.3807 17 11 17C9.61929 17 8.5 15.8807 8.5 14.5C8.5 13.1193 9.61929 12 11 12C12.3807 12 13.5 13.1193 13.5 14.5Z"
      fill="url(#paint9_linear_31_1584)"
    />
    <path
      d="M23.5 14.5C23.5 15.8807 22.3807 17 21 17C19.6193 17 18.5 15.8807 18.5 14.5C18.5 13.1193 19.6193 12 21 12C22.3807 12 23.5 13.1193 23.5 14.5Z"
      fill="url(#paint10_linear_31_1584)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.2455 24.6997C17.897 22.3683 14.0992 22.3765 11.7584 24.7258C11.3686 25.117 10.7355 25.1182 10.3442 24.7284C9.953 24.3386 9.95184 23.7054 10.3417 23.3142C13.4609 20.1835 18.5231 20.1716 21.6546 23.2803C22.0465 23.6694 22.0488 24.3025 21.6597 24.6945C21.2706 25.0864 20.6375 25.0888 20.2455 24.6997Z"
      fill="url(#paint11_linear_31_1584)"
    />
    <defs>
      <radialGradient
        id="paint0_radial_31_1584"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(25.5 9) rotate(131.878) scale(38.9487)"
      >
        <stop stopColor="#FFF478" />
        <stop offset={0.474827} stopColor="#FFB02E" />
        <stop offset={1} stopColor="#F70A8D" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_31_1584"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16 12.5) rotate(101.31) scale(17.8466 22.8581)"
      >
        <stop offset={0.787821} stopColor="#F59639" stopOpacity={0} />
        <stop offset={0.972509} stopColor="#FF7DCE" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_31_1584"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18 14) rotate(135) scale(41.0122)"
      >
        <stop offset={0.314853} stopOpacity={0} />
        <stop offset={1} />
      </radialGradient>
      <radialGradient
        id="paint3_radial_31_1584"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16 17) rotate(77.692) scale(28.1469)"
      >
        <stop offset={0.507903} stopColor="#7D6133" stopOpacity={0} />
        <stop offset={1} stopColor="#715B32" />
      </radialGradient>
      <radialGradient
        id="paint4_radial_31_1584"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16.5 16.5) rotate(55.7131) scale(13.3135 9.65032)"
      >
        <stop stopColor="#FFB849" />
        <stop offset={1} stopColor="#FFB847" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="paint5_radial_31_1584"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20.5 18) rotate(9.86581) scale(11.6726)"
      >
        <stop stopColor="#FFA64B" />
        <stop offset={0.900412} stopColor="#FFAE46" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="paint6_radial_31_1584"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17.5 15) rotate(43.9708) scale(59.0529)"
      >
        <stop offset={0.185425} stopOpacity={0} />
        <stop offset={1} stopOpacity={0.4} />
      </radialGradient>
      <radialGradient
        id="paint7_radial_31_1584"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(12.6518 11.7857) rotate(135) scale(9.10579 4.71285)"
      >
        <stop stopColor="#392108" />
        <stop offset={1} stopColor="#C87928" stopOpacity={0} />
      </radialGradient>
      <radialGradient
        id="paint8_radial_31_1584"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(22.5454 12.642) rotate(135) scale(7.66968 4.32966)"
      >
        <stop stopColor="#392108" />
        <stop offset={1} stopColor="#C87928" stopOpacity={0} />
      </radialGradient>
      <linearGradient
        id="paint9_linear_31_1584"
        x1={25.5833}
        y1={11.1667}
        x2={24.75}
        y2={17}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#553B3E" />
        <stop offset={1} stopColor="#3D2432" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_31_1584"
        x1={25.5833}
        y1={11.1667}
        x2={24.75}
        y2={17}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#553B3E" />
        <stop offset={1} stopColor="#3D2432" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_31_1584"
        x1={16}
        y1={20.9262}
        x2={16}
        y2={22.9887}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4F3C43" />
        <stop offset={1} stopColor="#512756" />
      </linearGradient>
    </defs>
  </svg>
);
export default NotGoodEmoji;
