import LipidGroupDetails from './LipidGroupDetails';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  useGetLipidFirstLogQuery,
  useGetLipidGoalQuery,
  useGetLipidGraphDataQuery,
  useGetLipidLastLogQuery,
  useGetLipidLogsQuery,
  useGetOverallLipidQuery,
} from './queries/lipidQueries';
import moment from 'moment/moment';
import { useAppSelector } from '../../redux/hooks';
import useTimeframe from '../../hooks/useTimeframe';
import { LipidGoal, LipidLog, minMaxAvg, minMaxObj } from '@hdcorner/ui-library';
import useAlert from '../../hooks/useAlert';
import { useTranslation } from 'react-i18next';
import { goalProgress } from '../../utils/goalProgress';

type Props = {
  onAddGoalClick: (type: string) => void;
  onEditGoalClick: (goal: LipidGoal) => void;
  onRemoveGoalClick: (goal: LipidGoal) => void;
};
const TriglyceridesSection: FC<Props> = ({
  onAddGoalClick,
  onEditGoalClick,
  onRemoveGoalClick,
}) => {
  const { t } = useTranslation();
  const { presentError } = useAlert();

  const [trgLogsParams, setTrgLogsParams] = useState({
    skip: 0,
    limit: 8,
    sort: ['-logDate'],
  });

  const { dateFilter, customDateFilter } = useAppSelector(state => state.layout);

  const timeframe = useTimeframe(
    dateFilter,
    customDateFilter?.start,
    customDateFilter?.end,
  );

  const { data: lastLog, error: errorLastLog } = useGetLipidLastLogQuery();
  const { data: trgGoalRes, error: errorTrgGoal } = useGetLipidGoalQuery({ type: 'trg' });
  const { data: lipidOverallData, error: errorOverallLip } =
    useGetOverallLipidQuery(timeframe);
  const {
    data: lipidLogs,
    error: errorLipLog,
    isFetching: trgLogsFetching,
  } = useGetLipidLogsQuery({
    params: { ...trgLogsParams },
    ...timeframe,
  });
  const { data: chartDataRes, error: errorGraphData } = useGetLipidGraphDataQuery({
    params: { metric: 'triglycerides', ...timeframe },
  });

  const { data: trgFirstLog, error: errorTrgFirstLog } = useGetLipidFirstLogQuery(
    {
      startingDate:
        trgGoalRes && trgGoalRes.length > 0
          ? (trgGoalRes as LipidGoal[])[0].startDate
          : '',
    },
    { skip: trgGoalRes ? trgGoalRes.length === 0 : true },
  );

  useEffect(() => {
    if (errorLipLog || errorLastLog || errorTrgFirstLog)
      presentError(t('errors.lipid.errorFetchLog'));
    if (errorGraphData) presentError(t('errors.lipid.errorFetchGraph'));
    if (errorOverallLip) presentError(t('errors.lipid.errorFetchOverall'));
  }, [
    errorLipLog,
    errorLastLog,
    errorTrgGoal,
    errorGraphData,
    errorOverallLip,
    errorTrgFirstLog,
  ]);

  const getOverallData = useCallback(() => {
    return minMaxAvg(lipidOverallData, 'triglycerides');
  }, [lipidOverallData]);

  const goalData = useMemo(() => {
    const trgGoal = trgGoalRes ? trgGoalRes[0] : null;
    const lastLogItem = lastLog ? lastLog.documents[0] : null;
    let trgFirst = trgFirstLog ? trgFirstLog.documents[0] : null;

    let progress = 0;

    if (trgGoal && trgFirst && lastLogItem) {
      const startingMeasurement = trgFirst.triglycerides || 0;
      const currentMeasurement = lastLogItem.triglycerides || 0;
      const goalMeasurement = parseFloat(trgGoal.goal.measurement) || 0;
      progress = goalProgress(currentMeasurement, goalMeasurement, startingMeasurement);
    }

    if (trgGoal && !trgFirst) {
      trgFirst = lastLogItem;
    }

    return {
      progress: progress ? progress + '%' : '-',
      goal: trgGoal ? parseFloat(trgGoal.goal.measurement) : undefined,
      startingMeasurement: trgFirst ? trgFirst.triglycerides : undefined,
      latestMeasurement: lastLogItem ? lastLogItem.triglycerides : undefined,
      startingDate: trgGoal?.startDate
        ? moment(trgGoal.startDate).format('MMMM Do, YYYY')
        : undefined,
    };
  }, [trgFirstLog, trgGoalRes, lastLog]);

  const chartData = useMemo(() => {
    if (chartDataRes) {
      const logs = chartDataRes.logs;
      if (!logs) return [];

      return logs.map((log: LipidLog) => {
        return {
          date: log.logDate,
          value: log.triglycerides,
        };
      });
    }
    return [];
  }, [chartDataRes]);

  const overallData = useMemo(() => {
    return minMaxObj('Triglycerides', getOverallData);
  }, [getOverallData]);

  const inDepthData = useMemo(() => {
    if (lipidLogs && lipidLogs.documents.length > 0) {
      return lipidLogs.documents
        .filter(log => log.triglycerides)
        .map((log: LipidLog) => {
          return {
            title: log.triglycerides,
            titleSub: t('measurements.mgdl'),
            data: {
              [t('lipid.labels.tryg')]: {
                value: `${log.triglycerides} ${t('measurements.mgdl')}`,
              },
              [t('inDepth.timeOfCalc')]: { value: moment(log.logDate).format('h:mm A') },
              [t('inDepth.dateOfCalc')]: {
                value: moment(log.logDate).format('MMM Do, YYYY'),
              },
            },
          };
        });
    }
    return [];
  }, [lipidLogs]);

  const handleAddGoal = () => {
    onAddGoalClick('trg');
  };

  const handleEditGoal = () => {
    if (trgGoalRes) {
      onEditGoalClick(trgGoalRes[0]);
    }
  };

  const handleRemoveGoal = () => {
    if (trgGoalRes) {
      onRemoveGoalClick(trgGoalRes[0]);
    }
  };

  return (
    <LipidGroupDetails
      goalData={goalData}
      chartData={chartData}
      inDepthData={inDepthData}
      overallData={overallData}
      listParams={trgLogsParams}
      isFetching={trgLogsFetching}
      handleAddGoal={handleAddGoal}
      totalCount={lipidLogs?.count}
      handleEditGoal={handleEditGoal}
      handleRemoveGoal={handleRemoveGoal}
      count={lipidLogs?.documents.length}
      sectionName={t('lipid.labels.tryg')}
      setListParams={params => setTrgLogsParams({ ...trgLogsParams, ...params })}
    />
  );
};

export default TriglyceridesSection;
