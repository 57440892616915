import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { FC, useEffect, useState } from 'react';
import { setRepeat } from '../medicationSlice';
import { CustomButton } from '@hdcorner/ui-library';
import { Box } from '@mui/material';
import MedicationRepeat from './MedicationRepeat';
import { useTranslation } from 'react-i18next';
import { dates, datesWeek } from '../constants';

type Props = {
  handleRepeatSelect: () => void;
};
const MedicationRepeatModal: FC<Props> = ({ handleRepeatSelect }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [selectedRepeat, setSelectedRepeat] = useState<string>('Everyday');

  const { repeat } = useAppSelector(state => state.medication);

  useEffect(() => {
    setSelectedDays(datesWeek.map(it => it.value));
  }, [datesWeek]);

  useEffect(() => {
    if (repeat) {
      setSelectedDays(repeat);

      if (repeat.length === 7) {
        setSelectedRepeat('Everyday');
      } else {
        setSelectedRepeat('Custom');
      }
    }
  }, [repeat]);

  const handleShowRepeatSelect = () => {
    dispatch(setRepeat(selectedDays));
    handleRepeatSelect();
  };

  return (
    <Box
      p={3}
      gap={3}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
    >
      <MedicationRepeat
        dates={dates}
        datesWeek={datesWeek}
        selected={selectedRepeat}
        selectedDays={selectedDays}
        setSelected={setSelectedRepeat}
        setSelectedDays={setSelectedDays}
      />
      <CustomButton
        fullWidth
        disableElevation
        color={'primary'}
        variant={'contained'}
        onClick={handleShowRepeatSelect}
      >
        {t('buttons.save')}
      </CustomButton>
    </Box>
  );
};

export default MedicationRepeatModal;
