import * as React from 'react';
import { FC, useEffect, useMemo, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import {
  ClickNavCardNutrition,
  CustomButton,
  CustomInput,
  Medication,
  MedicineStock,
  SwitchButton,
} from '@hdcorner/ui-library';
import BannerReschedule from './BannerReschedule';
import moment from 'moment/moment';
import ActionButtonsSmall from './ActionButtonsSmall';
import MedicationHeaderIcon from '../../../assets/icons/MedicationHeaderIcon';
import {
  useCreateMedicineStockMutation,
  useEditMedicineStockMutation,
  useGetMedicineStockQuery,
  useTakeMedicationMutation,
} from '../queries/medicationQueries';
import useAlert from '../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

type MedicationDrugModalProps = {
  handleCloseModal: () => void;
  medication: Medication | null;
  handleDeleteClick: () => void;
  handleRescheduleClick: () => void;
  handleInstructionsClick: () => void;
};

const MedicationDrugModal: FC<MedicationDrugModalProps> = ({
  medication,
  handleCloseModal,
  handleDeleteClick,
  handleRescheduleClick,
  handleInstructionsClick,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { presentError, presentSuccess } = useAlert();

  const [stockAmount, setStockAmount] = useState('');
  const [stockThreshold, setStockThreshold] = useState('');

  const [refill, setRefill] = useState(false);

  const [takeMedication] = useTakeMedicationMutation();
  const [editMedicineStock] = useEditMedicineStockMutation();
  const [createMedicineStock] = useCreateMedicineStockMutation();

  const { data: medicineStockRes } = useGetMedicineStockQuery(
    { medicineId: medication?.medicine._id || '' },
    {
      skip: !medication || !medication.medicine._id,
    },
  );

  const medicineStock = useMemo<MedicineStock | null>(() => {
    if (!medicineStockRes || medicineStockRes.length === 0) return null;
    return medicineStockRes[0];
  }, [medicineStockRes]);

  useEffect(() => {
    if (medicineStock) {
      setStockAmount(medicineStock.amount ? medicineStock.amount.toString() : '');
      setStockThreshold(
        medicineStock.threshold ? medicineStock.threshold.toString() : '',
      );

      if (medicineStock.threshold) {
        setRefill(true);
      }
    }
  }, [medicineStock]);

  const updateStock = () => {
    if (medicineStock) {
      editMedicineStock({
        ...medicineStock,
        amount: parseInt(stockAmount),
        threshold: refill ? parseInt(stockThreshold) : undefined,
      })
        .unwrap()
        .then(() => presentSuccess(t('errors.medication.successEditMedStock')))
        .catch(() => presentError(t('errors.medication.errorEditMedStock')))
        .finally(() => handleCloseModal());
    } else {
      if (!medication) return;
      createMedicineStock({
        amount: parseInt(stockAmount),
        medicine: medication.medicine._id,
        threshold: refill ? parseInt(stockThreshold) : undefined,
      })
        .unwrap()
        .then(() => {
          presentSuccess(t('errors.medication.successAddMedStock'));
        })
        .catch(() => presentError(t('errors.medication.errorAddStock')))
        .finally(() => handleCloseModal());
    }
  };

  const handleTakenClick = () => {
    if (!medication) return;
    takeMedication(medication._id);
  };

  const rescheduleBanner = useMemo(() => {
    if (!medication) return null;

    if (medication.rescheduledTo) {
      return (
        <BannerReschedule
          time={t('general.labels.to')}
          date={moment(medication.rescheduledTo).format('DD/MM/YYYY')}
        />
      );
    }

    if (medication.rescheduledFrom) {
      return (
        <BannerReschedule
          time={t('general.labels.from')}
          date={moment(medication.rescheduledFrom).format('DD/MM/YYYY')}
        />
      );
    }
  }, [medication]);

  const renderActionButtons = useMemo(() => {
    if (!medication) return null;

    return (
      <ActionButtonsSmall
        taken={medication.taken}
        onClickTake={handleTakenClick}
        rescheduledTo={!!medication.rescheduledTo}
        rescheduledFrom={!!medication.rescheduledFrom}
        onClickReschedule={() => handleRescheduleClick()}
      />
    );
  }, [medication, handleRescheduleClick, handleTakenClick]);

  return (
    <Box display={'flex'} flexDirection={'column'} gap={3} padding={3}>
      {medication?.rescheduledFrom && (
        <BannerReschedule
          time={t('general.labels.from')}
          date={moment(medication.rescheduledFrom).format('DD/MM/YYYY')}
        />
      )}
      {rescheduleBanner}
      {renderActionButtons}
      {medication?.medicine.substanceUrl && (
        <Box
          px={2}
          py={1}
          borderRadius={1}
          sx={{
            backgroundColor: 'white',
            paddingInline: theme.spacing(2),
          }}
        >
          <MedicationHeaderIcon
            width={'16px'}
            height={'16px'}
            color={theme.palette.medication.main}
          />
          <Typography
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
            variant={'subtitle2'}
            color={theme.palette.medication.main}
            fontWeight={theme.typography.fontWeightRegular}
          >
            {medication.medicine.substanceUrl}
          </Typography>
        </Box>
      )}
      <Box>
        <Typography
          variant={'subtitle2'}
          marginBottom={theme.spacing(1)}
          color={theme.palette.primary.main}
          fontWeight={theme.typography.fontWeightRegular}
        >
          {t('general.labels.info')}
        </Typography>
        <ClickNavCardNutrition
          headings={t('general.labels.content')}
          body={medication ? medication.medicine.content : `${t('general.labels.nA')}`}
        />
        <ClickNavCardNutrition
          headings={t('general.labels.type')}
          body={
            medication ? medication.medicine.medicineType : `${t('general.labels.nA')}`
          }
        />
        <ClickNavCardNutrition
          headings={t('buttons.navigation.package')}
          body={medication ? medication.medicine.package : `${t('general.labels.nA')}`}
        />
        <ClickNavCardNutrition
          onClick={handleInstructionsClick}
          headings={t('buttons.navigation.medInstructions')}
        />
      </Box>
      <Box>
        <Typography
          variant={'subtitle2'}
          color={theme.palette.primary.main}
          marginBottom={theme.spacing(1)}
          fontWeight={theme.typography.fontWeightRegular}
        >
          {t('medication.labels.medicineStock')}
        </Typography>
        <Box display={'flex'} flexDirection={'column'} gap={theme.spacing(1)}>
          <CustomInput
            fullWidth
            type={'number'}
            value={stockAmount}
            readOnly={medication?.taken}
            handleChange={setStockAmount}
            placeholder={`${t('medication.labels.enterAmount')}`}
          />
          <Box
            sx={{
              height: '35px',
              display: 'flex',
              borderRadius: '8px',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingInline: theme.spacing(2),
              backgroundColor: theme.palette.secondary.light,
            }}
          >
            <Typography
              variant={'subtitle2'}
              color={theme.palette.primary.main}
              fontWeight={theme.typography.fontWeightRegular}
            >
              {t('medication.labels.refillAmount')}
            </Typography>
            <SwitchButton
              checked={refill}
              setChecked={setRefill}
              disabled={medication?.taken}
            />
          </Box>
          {refill && (
            <Box>
              <Typography
                variant={'subtitle2'}
                marginBottom={theme.spacing(1)}
                color={theme.palette.primary.main}
                fontWeight={theme.typography.fontWeightRegular}
              >
                {t('medication.labels.reminderThreshold')}
              </Typography>
              <CustomInput
                fullWidth
                type={'number'}
                value={stockThreshold}
                readOnly={medication?.taken}
                handleChange={setStockThreshold}
                placeholder={`${t('medication.labels.lowerThresh')}`}
              />
            </Box>
          )}
        </Box>
      </Box>

      <Box display={'flex'} gap={theme.spacing(3)}>
        <CustomButton
          fullWidth
          disableElevation
          color={'primary'}
          variant={'contained'}
          onClick={updateStock}
          disabled={medication?.taken}
        >
          {t('buttons.update')}
        </CustomButton>
        <CustomButton
          fullWidth
          color={'error'}
          disableElevation
          variant={'contained'}
          disabled={medication?.taken}
          onClick={() => handleDeleteClick()}
        >
          {t('buttons.delete')}
        </CustomButton>
      </Box>
    </Box>
  );
};

export default MedicationDrugModal;
