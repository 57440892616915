import React, { FC } from 'react';
import { Box, BoxProps, Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckSuccessBackgroundIcon from '../../assets/CheckSuccessBackgroundIcon';
import ErrorBackgroundIcon from '../../assets/ErrorBackgroundIcon';

const Container = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const BenefitContainer = styled(Box, {
  shouldForwardProp: prop => {
    return prop !== 'isFirst';
  },
})<BoxProps & { isFirst: boolean }>(({ theme, isFirst }) => ({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  marginTop: isFirst ? theme.spacing(0) : theme.spacing(2),
}));

const Benefit = styled(Typography)<TypographyProps>(({ theme }) => ({
  display: 'flex',
  flex: 1,
  fontWeight: theme.typography.fontWeightRegular,
  color: theme.palette.kmColorsCoolGrey.main,
  marginLeft: theme.spacing(1),
}));

type SubscriptionPlanBenefitsType = {
  selectedPlan: string;
  benefits: { benefit: string; plan: string[] }[];
};
const SubscriptionPlanBenefits: FC<SubscriptionPlanBenefitsType> = ({
  selectedPlan,
  benefits,
}) => {
  return (
    <Container>
      {benefits.map((item, index) => (
        <BenefitContainer key={index} isFirst={index === 0}>
          {item.plan.includes(selectedPlan) ? (
            <CheckSuccessBackgroundIcon />
          ) : (
            <ErrorBackgroundIcon />
          )}
          <Benefit variant={'body1'}>{item.benefit}</Benefit>
        </BenefitContainer>
      ))}
    </Container>
  );
};

export default SubscriptionPlanBenefits;
