import moment from 'moment/moment';
import useAlert from '../../hooks/useAlert';
import { Box } from '@mui/material';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Collapsible, FeelsEmpty } from '@hdcorner/ui-library';
import InfiniteScrollList from '../../components/InfiniteScrollList';
import { useGetHypertensionLogsQuery } from './queries/hypertensionQueries';
import { useTranslation } from 'react-i18next';

type Props = {
  endParam?: string;
  startParam?: string;
  selectedCategory?: string;
};
const LogsList: FC<Props> = ({ startParam, endParam, selectedCategory = 'all' }) => {
  const { presentError } = useAlert();
  const { t } = useTranslation();

  const [hypertensionParams, setHypertensionParams] = useState({
    skip: 0,
    limit: 20,
  });

  const {
    error,
    data: hypertensionLogs,
    isFetching: hypertensionIsFetching,
  } = useGetHypertensionLogsQuery({
    end: endParam ? endParam : undefined,
    start: startParam ? startParam : undefined,
    params: { ...hypertensionParams, sort: ['-logDate'] },
    category: selectedCategory === 'all' ? undefined : selectedCategory,
  });

  useEffect(() => {
    if (error) presentError(t('errors.hypertension.errorLogFetch'));
  }, [error]);

  const inDepthData = useMemo(() => {
    if (!hypertensionLogs) return [];

    return hypertensionLogs.documents.map(log => {
      return {
        titleSub: t('measurements.kcal'),
        title: moment(log.logDate).format('MMMM Do, YYYY'),
        data: {
          [t('hypertension.labels.sysMmGH')]: { value: log.systolic.toString() },
          [t('hypertension.labels.diaMmGh')]: { value: log.diastolic.toString() },
          [t('dashboard.dashcards.hypertension.pulses')]: {
            value: log.pulses.toString(),
          },
          [t('goals.measurement')]: { value: log.category || '-' },
          [t('measurements.afib')]: { value: log.afib ? t('userDetails.yes') : '-' },
          [t('inDepth.timeOfCalc')]: { value: moment(log.logDate).format('h:mm A') },
          [t('inDepth.dateOfCalc')]: {
            value: moment(log.logDate).format('MMMM Do, YYYY'),
          },
        },
      };
    });
  }, [hypertensionLogs]);

  return (
    <Box>
      <InfiniteScrollList
        skip={hypertensionParams.skip}
        loading={hypertensionIsFetching}
        limit={hypertensionParams.limit}
        totalCount={hypertensionLogs?.count}
        count={hypertensionLogs?.documents.length}
        onLoadMore={(skip, limit) => setHypertensionParams({ skip, limit })}
      >
        {inDepthData.map((data, index) => (
          <Box key={`${data.title}-${index}`}>
            <Collapsible title={data.title} data={data.data} />
          </Box>
        ))}
      </InfiniteScrollList>
      {inDepthData.length === 0 && (
        <Box>
          <FeelsEmpty />
        </Box>
      )}
    </Box>
  );
};

export default LogsList;
