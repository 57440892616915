import React, { useEffect, useMemo, useRef, useState } from 'react';
import { LanguagePanelMobile, useDebounce } from '@hdcorner/ui-library';
import PageLayout from '../../components/PageLayout';
import {
  useGetUserSettingsQuery,
  useUpdateLanguageSettingsMutation,
} from './queries/userSettingsQueries';
import useAlert from '../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

const languages = [
  { label: 'constants.languages.en', value: 'en' },
  { label: 'constants.languages.gr', value: 'el' },
];

const Language = () => {
  const { presentError } = useAlert();
  const { t, i18n } = useTranslation();

  const isFirstRef = useRef<boolean>(true);
  const [language, setLanguage] = useState<string>('en');

  const debounceLanguage = useDebounce(language, 200);

  const { error, data: userSettings } = useGetUserSettingsQuery();
  const [updateUserSettings] = useUpdateLanguageSettingsMutation();

  useEffect(() => {
    if (error) presentError(t('errors.profile.errorUserData'));
  }, [error]);

  const handleLanguageChange = (newValue: string) => {
    if (newValue === 'en') {
      i18n.changeLanguage('en');
    }
    if (newValue === 'el') {
      i18n.changeLanguage('el');
    }
    setLanguage(newValue);
  };

  useEffect(() => {
    if (userSettings && userSettings.length > 0) {
      const data = userSettings[0];
      if (data) {
        setLanguage(data.selectedLang || 'en');
      }
      const timeout = setTimeout(() => {
        isFirstRef.current = false;
        clearTimeout(timeout);
      }, 600);
    }
  }, [userSettings]);

  useEffect(() => {
    if (isFirstRef.current) {
      return;
    }

    updateUserSettings({
      selectedLang: language,
    })
      .unwrap()
      .catch(() => presentError(t('errors.profile.errorLangRegion')));
  }, [debounceLanguage]);

  const getLanguages = useMemo(() => {
    return languages.map(lang => ({
      ...lang,
      label: t(lang.label),
    }));
  }, [t]);

  return (
    <PageLayout
      backgroundWhite
      defaultHref={'/dashboard/profile'}
      headerTitle={t('profile.titles.language')}
    >
      <LanguagePanelMobile
        languages={getLanguages}
        selectedLanguage={language}
        setLanguage={handleLanguageChange}
      />
    </PageLayout>
  );
};

export default Language;
