import { FC } from 'react';
import {
  BoxProps,
  styled,
  Typography,
  TypographyProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import { CustomButton, ModalMobile } from '@hdcorner/ui-library';
import { useTranslation } from 'react-i18next';

const MainBox = styled(Box, {
  shouldForwardProp: propName => propName !== 'web',
})<BoxProps & { web: boolean }>(({ web, theme }) => ({
  display: 'flex',
  borderRadius: '8px',
  gap: theme.spacing(3),
  boxSizing: 'border-box',
  flexDirection: 'column',
  padding: theme.spacing(3),
  width: web ? '351px' : 'none',
}));

const Text = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.kmColorsCoolGrey.main,
  fontSize: theme.typography.subtitle2.fontSize,
  fontWeight: theme.typography.subtitle2.fontWeight,
}));

type Props = {
  open: boolean;
  handleAddNow: () => void;
  setOpen: (open: boolean) => void;
};

const ModalCCUnlockPotential: FC<Props> = ({ open, setOpen, handleAddNow }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const web = useMediaQuery(theme.breakpoints.up('md'));

  const handleRemindMeLater = () => {
    setOpen(false);
  };

  return (
    <ModalMobile open={open} setOpen={setOpen}>
      <MainBox web={web}>
        <Typography
          variant={'subtitle2'}
          sx={{ fontWeight: theme.typography.fontWeightBold }}
        >
          {t('healthProf.clinicalCoach.text.unlockFull')}
        </Typography>

        <Box gap={1} display={'flex'} flexDirection={'column'}>
          <Text>{t('healthProf.clinicalCoach.text.helloThere')}</Text>
          <Text>{t('healthProf.clinicalCoach.text.weveNoticed')}</Text>
          <Text>{t('healthProf.clinicalCoach.text.getPersonalised')}</Text>
        </Box>

        <Box gap={2} display={'flex'}>
          <CustomButton fullWidth variant={'contained'} onClick={handleAddNow}>
            {t('buttons.addNow')}
          </CustomButton>

          <CustomButton
            fullWidth
            variant={'contained'}
            onClick={handleRemindMeLater}
            style={{ backgroundColor: theme.palette.kmColorsCoolGrey.main }}
          >
            {t('buttons.remindLater')}
          </CustomButton>
        </Box>
      </MainBox>
    </ModalMobile>
  );
};

export default ModalCCUnlockPotential;
