import React, { FC, ReactNode, useCallback, useMemo } from 'react';
import { Avatar, Box, BoxProps, Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Accept, FileRejection, useDropzone } from 'react-dropzone';

const DropzoneAreaContainer = styled(Box)<BoxProps>(({ theme }) => ({
  padding: theme.spacing(2, 1),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(3),
  background: `linear-gradient(to right, ${
    theme.palette.mode === 'dark'
      ? theme.palette.kmColorsDarkGrey.main
      : theme.palette.secondary.dark
  } 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(${
    theme.palette.mode === 'dark'
      ? theme.palette.kmColorsDarkGrey.main
      : theme.palette.secondary.dark
  } 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(to right, ${
    theme.palette.mode === 'dark'
      ? theme.palette.kmColorsDarkGrey.main
      : theme.palette.secondary.dark
  } 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(${
    theme.palette.mode === 'dark'
      ? theme.palette.kmColorsDarkGrey.main
      : theme.palette.secondary.dark
  } 50%, rgba(255, 255, 255, 0) 0%)`,
  backgroundPosition: 'top, right, bottom, left',
  backgroundRepeat: 'repeat-x, repeat-y',
  backgroundSize: '18px 1px, 1px 18px',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.secondary.main
      : theme.palette.highlight.main,
  borderRadius: '8px',
  cursor: 'pointer',
}));

const TextUpDown = styled(Typography)<TypographyProps>(({ theme }) => ({
  textAlign: 'center',
  fontWeight: theme.typography.fontWeightRegular,
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.kmColorsBgText.main
      : theme.palette.primary.main,
}));

type CustomDropzoneAreaProps = {
  img?: string;
  textUp: string;
  textDown?: string;
  maxFiles?: number;
  maxFileSize?: number;
  children?: ReactNode;
  acceptedFiles?: Accept;
  onFilesSelected: (files: File[], base64Files: string[]) => void;
};
const CustomDropzoneArea: FC<CustomDropzoneAreaProps> = ({
  img,
  textUp,
  textDown,
  children,
  maxFiles,
  maxFileSize,
  acceptedFiles,
  onFilesSelected,
}) => {
  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (acceptedFiles.length > 0) {
        const promises: Promise<string>[] = acceptedFiles.map(file => {
          return new Promise<string>(resolve => {
            const reader = new FileReader();
            reader.onload = () => {
              const base64String = reader.result as string;
              resolve(base64String);
            };
            reader.readAsDataURL(file);
          });
        });

        Promise.all(promises).then(base64Results => {
          onFilesSelected(acceptedFiles, base64Results);
        });
      } else {
        console.error('Invalid file(s) rejected:', rejectedFiles);
      }
    },
    [onFilesSelected],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles,
    maxSize: maxFileSize,
    accept: acceptedFiles,
  });

  const renderAvatar = useMemo(() => {
    if (img === '') {
      return (
        <Avatar
          variant={'square'}
          sx={{ width: '80px', height: '80px', cursor: 'pointer', borderRadius: '8px' }}
        ></Avatar>
      );
    }
    if (img) {
      return (
        <Avatar
          src={img}
          variant={'square'}
          sx={{ width: '80px', height: '80px', cursor: 'pointer', borderRadius: '8px' }}
        ></Avatar>
      );
    }
    return null;
  }, [img]);

  return (
    <DropzoneAreaContainer {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? <span>Drop the files here ...</span> : <></>}
      {renderAvatar}
      <Box display={'flex'} flexDirection={'column'} gap={1} alignItems={'center'}>
        <TextUpDown variant="subtitle2">
          {textUp} <br /> {textDown}
        </TextUpDown>
        <Box>{children}</Box>
      </Box>
    </DropzoneAreaContainer>
  );
};

export default CustomDropzoneArea;
