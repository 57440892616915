import React, { FC } from 'react';
import { Box, BoxProps, styled, Typography } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography/Typography';
import Button, { ButtonProps } from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

const Container = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(1, 0),
}));

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const Text = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  color: theme.palette.kmColorsDarkGrey.main,
  margin: theme.spacing(2, 0),
}));

const ButtonMain = styled(Button)<ButtonProps>(({ theme }) => ({
  ...theme.typography.body1,
  fontWeight: theme.typography.fontWeightRegular,
  borderRadius: 8,
  background: theme.palette.primary.main,
  padding: theme.spacing(1, 3),
  color: theme.palette.secondary.main,
  '&:hover': {
    background: theme.palette.primary.main,
  },
}));

type ProgressModalType = {
  content: string;
  onClick: () => void;
};
const ProgressModal: FC<ProgressModalType> = ({ content, onClick }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Title variant={'h1'}>{t('goals.yourProgress')}</Title>
      <Text variant={'subtitle2'}>{content}</Text>
      <ButtonMain onClick={onClick} disableRipple disableElevation>
        {t('buttons.continue')}
      </ButtonMain>
    </Container>
  );
};

export default ProgressModal;
