import React, { FC, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import ChartNumbersInfo from './tables/ChartNumbersInfo';
import LipidChartCard from './cards/LipidChartCard';
import { ChartDataType } from '../types/ChartDataType';

type Props = {
  chartData: ChartDataType[];
  headings: { label: string; value: string }[];
  inDepthData: { latestMeasurement?: number; min: number; max: number; avg: number };
  goalData?: {
    goal?: number;
    latestMeasurement?: number;
    startingMeasurement?: number;
  };
};
const LipidChart: FC<Props> = ({ headings, inDepthData, chartData, goalData }) => {
  const theme = useTheme();

  const [toggleButtonsValue, setToggleButtonsValue] = useState<string>('all');

  const handleChange = (newValue: string) => {
    setToggleButtonsValue(newValue);
  };

  return (
    <Box
      sx={{
        borderRadius: '8px',
        padding: theme.spacing(2),
        background:
          theme.palette.mode === 'dark'
            ? theme.palette.secondary.main
            : theme.palette.secondary.light,
      }}
    >
      <ChartNumbersInfo
        isHorizontal
        numberOne={inDepthData.max}
        numberTwo={inDepthData.min}
        numberThree={inDepthData.avg}
      />
      <LipidChartCard
        goalData={goalData}
        headings={headings}
        chartData={chartData}
        handleChangeOption={handleChange}
        title={inDepthData.latestMeasurement}
        toggleButtonsValue={toggleButtonsValue}
      />
    </Box>
  );
};

export default LipidChart;
