import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  CalendarModal,
  CustomButton,
  CustomInput,
  ModalHeading,
  ModalMobile,
  theme,
} from '@hdcorner/ui-library';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { Box, useMediaQuery } from '@mui/material';
import {
  useCreateStepsLogMutation,
  useGetTodayStepsLogsQuery,
  useGetUserGoalStepsQuery,
  usePatchUserGoalStepsMutation,
  usePostUserGoalStepsMutation,
} from '../queries/stepsQueries';
import moment from 'moment';
import useAlert from '../../../../hooks/useAlert';
import { calculateLogs } from '../utils/calculateLogs';
import { addGoalDetails } from '../../../dashboard/slices/congratulationsSlice';
import { incrementalProgress } from '../../../../utils/goalProgress';
import { useTranslation } from 'react-i18next';

type Props = {
  log: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
};
const ModalStepsGoal: FC<Props> = ({ log, open, setOpen }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { presentSuccess, presentError } = useAlert();
  const web = useMediaQuery(theme.breakpoints.up('md'));

  const {
    data: { user },
  } = useAppSelector(state => state.auth);

  const [steps, setSteps] = useState<string>('');
  const [openPicker, setOpenPicker] = useState<boolean>(false);
  const [date, setDate] = useState<string>(moment().utc(true).toISOString());

  const { data: logsData } = useGetTodayStepsLogsQuery();
  const { data: getGoalRes, error } = useGetUserGoalStepsQuery();

  const [postStepsLog] = useCreateStepsLogMutation();
  const [postStepsGoal] = usePostUserGoalStepsMutation();
  const [patchStepsGoal] = usePatchUserGoalStepsMutation();

  useEffect(() => {
    if (error) {
      presentError(t('errors.fitness.exercise.errorStepsFetchGoal'));
    }
  }, [error]);

  const goalData = useMemo(() => {
    if (!getGoalRes || getGoalRes.length === 0) return { steps: 0, date: '' };
    return { steps: getGoalRes[0].goal.steps, date: getGoalRes[0].startDate };
  }, [getGoalRes]);

  const allLogs = useMemo(() => {
    if (!logsData) return 0;
    return calculateLogs(logsData, 'steps');
  }, [logsData]);

  useEffect(() => {
    setSteps(log ? '' : goalData.toString());
  }, [goalData, log]);

  const handleAddGoal = () => {
    postStepsGoal({
      goal: {
        steps: parseInt(steps),
      },
      goalType: 'steps',
      userId: user ? user.authUser._id : '',
      startDate: moment().utc(true).startOf('day').toISOString(),
    })
      .unwrap()
      .then(() => {
        setSteps('');
        setOpen(false);
        presentSuccess(t('errors.fitness.exercise.successStepsAddGoal'));
      })
      .catch(() => {
        presentError(t('errors.fitness.exercise.errorStepsAddGoal'));
      });
  };

  const handleEditGoal = () => {
    if (!getGoalRes || getGoalRes.length === 0) return;

    patchStepsGoal({
      goalType: 'steps',
      _id: getGoalRes[0]._id,
      goal: { steps: parseInt(steps) },
      startDate: getGoalRes[0].startDate,
      userId: user ? user.authUser._id : '',
    })
      .unwrap()
      .then(() => {
        setSteps('');
        setOpen(false);
        presentSuccess(t('errors.fitness.exercise.successStepsEditGoal'));
      })
      .catch(() => {
        presentError(t('errors.fitness.exercise.errorStepsEditGoal'));
      });
  };

  const handleAddStepsLog = () => {
    postStepsLog({
      logDate: date,
      fromDevice: false,
      steps: parseInt(steps),
    })
      .unwrap()
      .then(() => {
        setSteps('');
        setOpen(false);
        presentSuccess(t('errors.fitness.exercise.successStepsAddLog'));
        if (
          getGoalRes &&
          goalData.date !== '' &&
          incrementalProgress(allLogs, getGoalRes[0].goal.steps) === 100
        ) {
          setTimeout(() => {
            dispatch(
              addGoalDetails({
                category: 'steps',
                goal: { value: goalData.steps, measure: 'steps' },
                start: {
                  value: 0,
                  measure: 'steps',
                  time: moment().utc(true).startOf('day').toISOString(),
                },
              }),
            );
          }, 1500);
        }
      })
      .catch(() => {
        presentError(t('errors.fitness.exercise.errorStepsAddLog'));
      });
  };

  return (
    <ModalMobile open={open} setOpen={setOpen}>
      <Box
        gap={3}
        padding={2}
        width={'100%'}
        display={'flex'}
        boxSizing={'border-box'}
        flexDirection={'column'}
      >
        <ModalHeading
          align={'left'}
          color={theme.palette.diabetes.main}
          showCalendar={!log ? 'none' : 'flex'}
          headingButton={web ? undefined : 'custom date'}
          title={
            log
              ? t('fitness.exercise.labels.addStepsLog')
              : !getGoalRes || getGoalRes.length === 0
              ? t('fitness.exercise.labels.addStepsGoal')
              : t('fitness.exercise.labels.editStepsGoal')
          }
          handleCalendarClick={() => setOpenPicker(true)}
        />
        <CustomInput
          fullWidth
          value={steps}
          type={'number'}
          label={`${t('headingsTitles.steps')}`}
          handleChange={value => setSteps(value)}
          placeholder={`${t('fitness.exercise.placeholders.egSteps')}`}
        />
        <CustomButton
          fullWidth
          color={'primary'}
          variant={'contained'}
          children={t('buttons.save')}
          disabled={steps === '' || parseInt(steps) === 0}
          onClick={
            log
              ? handleAddStepsLog
              : !getGoalRes || getGoalRes.length === 0
              ? handleAddGoal
              : handleEditGoal
          }
        />
      </Box>
      <CalendarModal
        open={openPicker}
        selectedDate={date}
        setOpen={setOpenPicker}
        saveDate={date => setDate(date)}
      />
    </ModalMobile>
  );
};

export default ModalStepsGoal;
