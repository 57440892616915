import React, { FC } from 'react';
import { Avatar, Box, BoxProps, styled, Typography, useTheme } from '@mui/material';
import HdCornerHeartLogo from '../../assets/HdCornerHeartLogo';
import { useTranslation } from 'react-i18next';

const OutterBox = styled(Box, {
  shouldForwardProp: prop => prop !== 'currentUser',
})<BoxProps & { currentUser: true | false }>(props => ({
  flexDirection: props.currentUser ? 'row' : 'row-reverse',
}));

const MessageBox = styled(Box, {
  shouldForwardProp: prop => prop !== 'currentUser',
})<BoxProps & { currentUser: true | false }>(({ theme, ...props }) => ({
  margin: props.currentUser ? theme.spacing(0, 2, 0, 0) : theme.spacing(0, 0, 0, 2),
}));

type ChatMessageCardProps = {
  name: string;
  date: string;
  message: string;
  avatar?: string;
  support?: boolean;
  currentUser?: boolean;
};
const ChatMessageCard: FC<ChatMessageCardProps> = ({
  name,
  currentUser = false,
  date,
  message,
  avatar,
  support = false,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <OutterBox
      display={'flex'}
      minWidth={'343px'}
      boxSizing={'border-box'}
      currentUser={currentUser}
      alignItems={'flex-start'}
      justifyContent={'space-between'}
      margin={theme.spacing(0, 0, 1.25, 0)}
    >
      <MessageBox
        bgcolor={
          theme.palette.mode === 'dark'
            ? theme.palette.background.default
            : theme.palette.secondary.light
        }
        flexGrow={'1'}
        minWidth={'295px'}
        borderRadius={'8px'}
        boxSizing={'border-box'}
        currentUser={currentUser}
        padding={theme.spacing(1)}
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'flex-start'}
          margin={theme.spacing(0, 0, 0.5, 0)}
        >
          <Typography
            color={'primary'}
            variant={'subtitle1'}
            sx={{
              textTransform: 'capitalize',
              fontWeight: theme.typography.fontWeightMedium,
              margin: theme.spacing(0, 1, 0, 0),
            }}
          >
            {currentUser ? t('general.labels.me') : name}
          </Typography>
          <Typography
            align={'left'}
            variant={'subtitle2'}
            sx={{
              color:
                theme.palette.mode === 'dark'
                  ? theme.palette.kmColorsCoolGrey.main
                  : theme.palette.kmColorsDarkGrey.main,
            }}
          >
            {date}
          </Typography>
        </Box>
        <Typography align={'left'} variant={'subtitle2'} color={'primary'}>
          {message}
        </Typography>
      </MessageBox>
      <Avatar
        alt={name}
        src={avatar}
        sx={{
          width: 32,
          height: 32,
          borderRadius: 0.5,
          bgcolor: support ? 'transparent' : undefined,
        }}
      >
        {support ? (
          <HdCornerHeartLogo width={32} height={32} />
        ) : (
          name.charAt(0).toUpperCase()
        )}
      </Avatar>
    </OutterBox>
  );
};

export default ChatMessageCard;
