import {
  useGetBloodGlucoseCurrentMeasurementQuery,
  useGetBloodGlucoseGoalQuery,
  useGetBloodGlucoseStartingMeasurementQuery,
} from '../../diabetes/queries/diabetesQueries';
import { useEffect, useMemo, useState } from 'react';
import { goalProgress } from '../../../utils/goalProgress';

const useGetBloodGlucoseGoalData = () => {
  const [startingDate, setStartingDate] = useState<string>();

  const { data: bloodGlucoseGoal } = useGetBloodGlucoseGoalQuery();

  const { data: bloodGlucoseCurrent } = useGetBloodGlucoseCurrentMeasurementQuery();
  const { data: bloodGlucoseStarting } = useGetBloodGlucoseStartingMeasurementQuery(
    {
      startDate: startingDate || '',
    },
    { skip: !startingDate },
  );

  useEffect(() => {
    if (bloodGlucoseGoal && bloodGlucoseGoal.length > 0) {
      setStartingDate(bloodGlucoseGoal[0].startDate);
    }
  }, [bloodGlucoseGoal]);

  return useMemo(() => {
    if (!bloodGlucoseGoal || bloodGlucoseGoal.length === 0) return null;

    let goalValue;
    let startValue;
    let currentValue;
    let progress = 0;

    if (bloodGlucoseGoal && bloodGlucoseGoal.length > 0) {
      goalValue = bloodGlucoseGoal[0].goal;
    }

    if (bloodGlucoseStarting && bloodGlucoseStarting.documents.length > 0) {
      startValue = bloodGlucoseStarting.documents[0].measurement;
    }

    if (bloodGlucoseCurrent && bloodGlucoseCurrent.documents.length > 0) {
      currentValue = bloodGlucoseCurrent.documents[0].measurement;
      startValue = startValue || currentValue;
    }

    if (goalValue && startValue && currentValue) {
      progress = goalProgress(currentValue, goalValue, startValue);
    }

    return { progress, goalValue, startValue, currentValue };
  }, [bloodGlucoseCurrent, bloodGlucoseGoal, bloodGlucoseStarting]);
};

export default useGetBloodGlucoseGoalData;
