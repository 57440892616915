const calculateTotal = (array: any, value: string) => {
  if (!array || array.length === 0) return 0;
  const values = array.map((it: any) => it[value]);
  if (value === 'duration') {
    const totalSeconds = values.reduce(
      (sum: number, current: number) => sum + current,
      0,
    );
    return Math.round(totalSeconds / 60);
  } else {
    return values.reduce((sum: number, current: number) => sum + current, 0);
  }
};

export { calculateTotal };
