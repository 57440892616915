import { Redirect, Route } from 'react-router-dom';
import React, { useMemo } from 'react';
import { useMediaQuery } from '@mui/material';
import { AuthUser, theme } from '@hdcorner/ui-library';
import UsersData from '../features/users/UsersData';
import Hypertension from '../features/hypertension/Hypertension';
import HypertensionInDepthData from '../features/hypertension/InDepthData';
import HypertensionGoal from '../features/hypertension/HypertensionGoal';
import Medications from '../features/Medication/Medications';
import MedicationAllMedicines from '../features/Medication/MedicationAllMedicines';
import AddMedication from '../features/Medication/AddMedication';
import MedicationDrug from '../features/Medication/MedicationDrug';
import MedicationInstructions from '../features/Medication/MedicationInstructions';
import MedicationDrugDescription from '../features/Medication/MedicationDrugDescription';
import Diabetes from '../features/diabetes/Diabetes';
import DiabetesHbac1Goal from '../features/diabetes/DiabetesHbac1Goal';
import DiabetesGraphDataPage from '../features/diabetes/DiabetesGraphDataPage';
import BloodGlucoseInDepthData from '../features/diabetes/BloodGlucoseInDepthData';
import OnBoarding from '../features/authentication/onBoarding/OnBoarding';
import OnBoardingQuestionnaire from '../features/authentication/onBoarding/OnBoardingQuestionnaire';
import Dashboard from '../features/dashboard/Dashboard';
import Chat from '../features/chat/Chat';
import ChatMessage from '../features/chat/ChatMessage';
import HealthProfessionals from '../features/healthProfessionals/HealthProfessionals';
import Profile from '../features/profile/Profile';
import AccountSettings from '../features/profile/AccountSettings';
import AccountSettingsEmail from '../features/profile/AccountSettingsEmail';
import AccountSettingsPassword from '../features/profile/AccountSettingsPassword';
import AccountSettingsPhone from '../features/profile/AccountSettingsPhone';
import AccountNotifications from '../features/profile/AccountNotifications';
import AccountSettingsDeactivate from '../features/profile/AccountSettingsDeactivate';
import PersonalInfo from '../features/profile/PersonalInfo';
import Subscription from '../features/subscription/Subscription';
import SubscriptionCheckout from '../features/subscription/SubscriptionCheckout';
import Records from '../features/records/components/Records';
import RecordsSend from '../features/profile/RecordsSend';
import Language from '../features/profile/Language';
import Reminders from '../features/reminders/Reminders';
import ReminderInfo from '../features/reminders/ReminderInfo';
import CvdRisk from '../features/cvdRisk/CvdRisk';
import CvdRiskInDepthData from '../features/cvdRisk/CvdRiskInDepthData';
import CvdRiskGoalScreen from '../features/cvdRisk/CvdRiskGoalScreen';
import Lipid from '../features/lipid/Lipid';
import LipidInDepthData from '../features/lipid/LipidInDepthData';
import LipidGoalScreen from '../features/lipid/LipidGoalScreen';
import FoodEquivalents from '../features/fitness/FoodEquivalents';
import { IonRouterOutlet } from '@ionic/react';
import MedicationRepeatPage from '../features/Medication/MedicationRepeatPage';
import MedicationFrequencyPage from '../features/Medication/MedicationFrequencyPage';
import TermsAndConditionsPage from '../features/authentication/signUp/TermsAndConditionsPage';
import Hbac1InDepthData from '../features/diabetes/Hbac1InDepthData';
import DiabetesBloodGlucoseGoal from '../features/diabetes/DiabetesBloodGlucoseGoal';
import NutritionFitness from '../features/fitness/nutrition/NutritionFitness';
import FoodEquivalentsTable from '../features/fitness/FoodEquivalentsTable';
import TipsTricks from '../features/fitness/nutrition/TipsTricks';
import TrafficLightFitness from '../features/fitness/TrafficLightFitness';
import DietCalculatorInitialPage from '../features/fitness/diet-plan/DietCalculatorInitialPage';
import DietCalculatorMobile from '../features/fitness/diet-plan/DietCalculatorMobile';
import FoodMealsScreen from '../features/fitness/nutrition/FoodMealsScreen';
import Wellness from '../features/fitness/wellness/Wellness';
import DevicesFitness from '../features/fitness/DevicesFitness';
import Fitness from '../features/fitness/Fitness';
import AddFood from '../features/fitness/nutrition/AddFood';
import CustomMeal from '../features/fitness/CustomMeal';
import DailyCalories from '../features/fitness/calories/DailyCalories';
import CalorieData from '../features/fitness/calories/CalorieData';
import WellnessInDepthData from '../features/fitness/wellness/WellnessInDepthData';
import ExerciseFitness from '../features/fitness/exercise/ExerciseFitness';
import SearchActivity from '../features/fitness/exercise/SearchActivity';
import AddActivity from '../features/fitness/exercise/AddActivity';
import FitnessWorkouts from '../features/fitness/workouts/FitnessWorkouts';
import FitnessAvatarScreen from '../features/fitness/workouts/FitnessAvatarScreen';
import FitnessWorkoutType from '../features/fitness/workouts/FitnessWorkoutType';
import FitnessWorkoutProgram from '../features/fitness/workouts/FitnessWorkoutProgram';
import FitnessWorkoutPrograms from '../features/fitness/workouts/FitnessWorkoutPrograms';
import { isNil } from 'lodash';
import { useAppSelector } from '../redux/hooks';
import FitnessGoalSteps from '../features/fitness/exercise/FitnessGoalSteps';
import FitnessGoalActivity from '../features/fitness/exercise/FitnessGoalActivity';
import FitnessGoalWeight from '../features/fitness/wellness/FitnessGoalWeight';
import Esh from '../features/hypertension/esh/Esh';
import TwentyFourHours from '../features/hypertension/24hours/TwentyFourHours';
import Articles from '../features/fitness/nutrition/Articles';
import ArticleDetails from '../features/fitness/nutrition/ArticleDetails';
import AboutUs from '../features/aboutUs/AboutUs';
import WorkoutCongratulations from '../features/fitness/workouts/components/WorkoutCongratulations';
import SubscriptionHistoryList from '../features/subscription/SubscriptionHistoryList';
import Notifications from '../features/notifications/Notifications';
import HypertensionGraphDataPage from '../features/hypertension/HypertensionGraphDataPage';
import LipidGraphDataPage from '../features/lipid/LipidGraphDataPage';

const ProtectedRoutes = () => {
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const {
    data: { user },
  } = useAppSelector(state => state.auth);

  const shouldRedirect = useMemo(() => {
    if (isNil(user) || isNil(user.authUser)) {
      return true;
    }

    if (!isNil(user) && !isNil(user.authUser)) {
      const authUser: AuthUser = user.authUser as AuthUser;
      if (!authUser.privacyPolicyAccepted || !authUser.termsAndConditionsAccepted) {
        return true;
      }

      if (
        !authUser.firstName ||
        !authUser.lastName ||
        !authUser.birthDate ||
        !authUser.gender
      ) {
        return true;
      }

      if (!user.weight || !user.height) {
        return true;
      }

      if (
        !user.questionnaireAnswer ||
        !user.questionnaireOutcome ||
        !user.questionnaireResultType
      ) {
        return true;
      }
    }

    return false;
  }, [user]);

  const decideRedirect = useMemo(() => {
    if (user && user.authUser) {
      const authUser: AuthUser = user.authUser as AuthUser;

      if (!authUser.privacyPolicyAccepted || !authUser.termsAndConditionsAccepted) {
        return <Redirect to={'/dashboard/terms-and-conditions'} />;
      }

      if (
        !authUser.lastName ||
        !authUser.firstName ||
        !authUser.birthDate ||
        !authUser.gender
      ) {
        return <Redirect to={'/dashboard/onboarding'} />;
      }

      if (!user.weight || !user.height) {
        return <Redirect to={'/dashboard/onboarding?incomplete=true'} />;
      }

      if (
        !user.questionnaireAnswer ||
        !user.questionnaireOutcome ||
        !user.questionnaireResultType
      ) {
        return <Redirect to={'/dashboard/questionnaire'} />;
      }
    } else {
      return <Redirect to={'/sign-in'} />;
    }
  }, [user]);

  const renderRoutes = () => {
    return (
      <IonRouterOutlet animated={smallScreen}>
        <Route exact path="/dashboard/terms-and-conditions">
          <TermsAndConditionsPage />
        </Route>
        <Route exact path="/dashboard/onboarding">
          <OnBoarding />
        </Route>
        <Route exact path="/dashboard/questionnaire">
          <OnBoardingQuestionnaire />
        </Route>
        <Route exact path="/dashboard/home">
          {!shouldRedirect ? <Dashboard /> : decideRedirect}
        </Route>
        <Route exact path="/dashboard/about-us">
          <AboutUs />
        </Route>
        <Route exact path="/dashboard/user-data">
          {!smallScreen ? <UsersData /> : <Redirect to="/dashboard/home" />}
        </Route>
        {/* Hypertension paths */}
        <Route exact path="/dashboard/hypertension">
          {!smallScreen ? <UsersData /> : <Hypertension />}
        </Route>
        <Route exact path="/dashboard/hypertension-in-depth-data">
          {!smallScreen ? (
            <Redirect to="/dashboard/hypertension" />
          ) : (
            <HypertensionInDepthData />
          )}
        </Route>
        <Route exact path="/dashboard/hypertension/graph-data">
          {!smallScreen ? (
            <Redirect to="/dashboard/hypertension" />
          ) : (
            <HypertensionGraphDataPage />
          )}
        </Route>
        <Route exact path="/dashboard/hypertension-goal">
          {!smallScreen ? (
            <Redirect to="/dashboard/hypertension" />
          ) : (
            <HypertensionGoal />
          )}
        </Route>
        <Route exact path="/dashboard/hypertension/esh">
          {!smallScreen ? <Redirect to="/dashboard/hypertension" /> : <Esh />}
        </Route>
        <Route exact path="/dashboard/hypertension/24hours">
          {!smallScreen ? <Redirect to="/dashboard/hypertension" /> : <TwentyFourHours />}
        </Route>
        {/*  --------------------- */}
        {/*  Medication paths*/}
        <Route exact path="/dashboard/medication">
          {!smallScreen ? <UsersData /> : <Medications />}
        </Route>
        <Route exact path="/dashboard/medicines">
          {!smallScreen ? (
            <Redirect to="/dashboard/medication" />
          ) : (
            <MedicationAllMedicines />
          )}
        </Route>
        <Route exact path="/dashboard/medication-add">
          {!smallScreen ? <Redirect to="/dashboard/medication" /> : <AddMedication />}
        </Route>
        <Route exact path="/dashboard/medication-repeat">
          {!smallScreen ? (
            <Redirect to="/dashboard/medication" />
          ) : (
            <MedicationRepeatPage />
          )}
        </Route>
        <Route exact path="/dashboard/medication/:medicationId">
          {!smallScreen ? <Redirect to="/dashboard/medication" /> : <MedicationDrug />}
        </Route>
        <Route exact path="/dashboard/medication-instructions/:medicationId">
          {!smallScreen ? (
            <Redirect to="/dashboard/medication" />
          ) : (
            <MedicationInstructions />
          )}
        </Route>
        <Route exact path="/dashboard/medication-frequency">
          {!smallScreen ? (
            <Redirect to="/dashboard/medication" />
          ) : (
            <MedicationFrequencyPage />
          )}
        </Route>
        <Route exact path="/dashboard/medicine/:medicineId">
          {!smallScreen ? (
            <Redirect to="/dashboard/medication" />
          ) : (
            <MedicationDrugDescription />
          )}
        </Route>
        {/*  ---------------------*/}
        {/*  Diabetes paths*/}
        <Route exact path="/dashboard/diabetes">
          {!smallScreen ? <UsersData /> : <Diabetes />}
        </Route>
        <Route exact path="/dashboard/diabetes-hbac1-goal">
          {!smallScreen ? <Redirect to="/dashboard/diabetes" /> : <DiabetesHbac1Goal />}
        </Route>
        <Route exact path="/dashboard/diabetes-blood-glucose-goal">
          {!smallScreen ? (
            <Redirect to="/dashboard/diabetes" />
          ) : (
            <DiabetesBloodGlucoseGoal />
          )}
        </Route>
        <Route exact path="/dashboard/diabetes/graph-data/:type">
          {!smallScreen ? (
            <Redirect to="/dashboard/diabetes" />
          ) : (
            <DiabetesGraphDataPage />
          )}
        </Route>
        <Route exact path="/dashboard/diabetes/blood-glucose-in-depth-data">
          {!smallScreen ? (
            <Redirect to="/dashboard/diabetes" />
          ) : (
            <BloodGlucoseInDepthData />
          )}
        </Route>
        <Route exact path="/dashboard/diabetes/hbac1-in-depth-data">
          {!smallScreen ? <Redirect to="/dashboard/diabetes" /> : <Hbac1InDepthData />}
        </Route>
        {/*  ---------------------*/}
        <Route exact path="/dashboard/chat" component={Chat} />
        <Route
          exact
          path="/dashboard/chat/:chatId"
          render={props =>
            !smallScreen ? <Chat {...props} /> : <ChatMessage {...props} />
          }
        />
        <Route exact path="/dashboard/healthProfessionals">
          <HealthProfessionals />
        </Route>
        {/*  Profile paths*/}
        <Route exact path="/dashboard/profile">
          <Profile />
        </Route>
        <Route exact path="/dashboard/profile-settings">
          {!smallScreen ? <Profile /> : <AccountSettings />}
        </Route>
        <Route exact path="/dashboard/change-email">
          {!smallScreen ? <Profile /> : <AccountSettingsEmail />}
        </Route>
        <Route exact path="/dashboard/change-password">
          {!smallScreen ? <Profile /> : <AccountSettingsPassword />}
        </Route>
        <Route exact path="/dashboard/change-phone">
          {!smallScreen ? <Profile /> : <AccountSettingsPhone />}
        </Route>
        <Route exact path="/dashboard/notifications">
          {!smallScreen ? <Profile /> : <AccountNotifications />}
        </Route>
        <Route exact path="/dashboard/deactivate-account">
          {!smallScreen ? <Profile /> : <AccountSettingsDeactivate />}
        </Route>
        <Route exact path="/dashboard/personal-info">
          {!smallScreen ? <Profile /> : <PersonalInfo />}
        </Route>
        <Route exact path="/dashboard/subscription">
          <Subscription />
        </Route>
        <Route exact path="/dashboard/subscription/history">
          <SubscriptionHistoryList />
        </Route>
        <Route exact path="/dashboard/subscription/checkout">
          <SubscriptionCheckout />
        </Route>
        <Route exact path="/dashboard/records">
          <Records />
        </Route>
        <Route exact path="/dashboard/records-send">
          <RecordsSend />
        </Route>
        {/*------------------*/}
        <Route exact path="/dashboard/language">
          {!smallScreen ? <Profile /> : <Language />}
        </Route>
        <Route exact path="/dashboard/notification-alerts">
          {!smallScreen ? <Redirect to="/dashboard/home" /> : <Notifications />}
        </Route>
        <Route exact path="/dashboard/reminders">
          <Reminders />
        </Route>
        <Route
          exact
          path="/dashboard/reminderInfo/:reminderId"
          render={props => <ReminderInfo {...props} />}
        />
        <Route exact path="/dashboard/cvd">
          {!smallScreen ? <UsersData /> : <CvdRisk />}
        </Route>
        <Route exact path="/dashboard/cvd-in-depth-data">
          {!smallScreen ? <Redirect to="/dashboard/cvd" /> : <CvdRiskInDepthData />}
        </Route>
        <Route exact path="/dashboard/cvd-risk-goal">
          {!smallScreen ? <Redirect to="/dashboard/cvd" /> : <CvdRiskGoalScreen />}
        </Route>
        <Route exact path="/dashboard/lipid">
          {!smallScreen ? <UsersData /> : <Lipid />}
        </Route>
        <Route exact path="/dashboard/lipid-in-depth-data/:type">
          {!smallScreen ? <Redirect to="/dashboard/lipid" /> : <LipidInDepthData />}
        </Route>
        <Route exact path="/dashboard/lipid/graph-data/:type">
          {!smallScreen ? <Redirect to="/dashboard/lipid" /> : <LipidGraphDataPage />}
        </Route>
        <Route exact path="/dashboard/lipid-goal/:type">
          {!smallScreen ? <Redirect to="/dashboard/lipid" /> : <LipidGoalScreen />}
        </Route>
        <Route exact path="/dashboard/fitness">
          {!smallScreen ? <UsersData /> : <Fitness />}
        </Route>
        <Route exact path="/dashboard/fitness/nutrition">
          {!smallScreen ? <UsersData /> : <NutritionFitness />}
        </Route>
        <Route exact path="/dashboard/fitness/food-meals/:id">
          {!smallScreen ? <UsersData /> : <FoodMealsScreen />}
        </Route>
        <Route exact path="/dashboard/fitness/add-food/:id">
          {!smallScreen ? <UsersData /> : <AddFood />}
        </Route>
        <Route exact path="/dashboard/fitness/custom-meal/:id">
          {!smallScreen ? <UsersData /> : <CustomMeal />}
        </Route>
        <Route exact path="/dashboard/fitness/exercise">
          {!smallScreen ? <UsersData /> : <ExerciseFitness />}
        </Route>
        <Route exact path="/dashboard/fitness/workouts">
          {!smallScreen ? <UsersData /> : <FitnessWorkouts />}
        </Route>
        <Route exact path="/dashboard/fitness/workouts/avatar">
          {!smallScreen ? <UsersData /> : <FitnessAvatarScreen />}
        </Route>
        <Route exact path="/dashboard/fitness/workouts/workout-type">
          {!smallScreen ? <UsersData /> : <FitnessWorkoutType />}
        </Route>
        <Route exact path="/dashboard/fitness/workouts/workout-programs">
          {!smallScreen ? <UsersData /> : <FitnessWorkoutPrograms />}
        </Route>
        <Route exact path="/dashboard/fitness/workouts/workout-programs/:id">
          {!smallScreen ? <UsersData /> : <FitnessWorkoutProgram />}
        </Route>
        <Route exact path="/dashboard/fitness/workouts/congratulations">
          {!smallScreen ? <UsersData /> : <WorkoutCongratulations />}
        </Route>
        <Route exact path="/dashboard/fitness/activities">
          {!smallScreen ? <UsersData /> : <SearchActivity />}
        </Route>
        <Route exact path="/dashboard/fitness/activities/:id">
          {!smallScreen ? <UsersData /> : <AddActivity />}
        </Route>
        <Route exact path="/dashboard/fitness/exercise/steps/goal">
          {!smallScreen ? <UsersData /> : <FitnessGoalSteps />}
        </Route>
        <Route exact path="/dashboard/fitness/exercise/activity/goal">
          {!smallScreen ? <UsersData /> : <FitnessGoalActivity />}
        </Route>
        <Route exact path="/dashboard/fitness/exercise/in-depth/:id">
          {!smallScreen ? <UsersData /> : <WellnessInDepthData />}
        </Route>
        <Route exact path="/dashboard/fitness/wellness">
          {!smallScreen ? <UsersData /> : <Wellness />}
        </Route>
        <Route exact path="/dashboard/fitness/wellness/weight/goal">
          {!smallScreen ? <UsersData /> : <FitnessGoalWeight />}
        </Route>
        <Route exact path="/dashboard/fitness/wellness/in-depth/:id">
          {!smallScreen ? <UsersData /> : <WellnessInDepthData />}
        </Route>
        <Route exact path="/dashboard/fitness/devices">
          {!smallScreen ? <UsersData /> : <DevicesFitness />}
        </Route>
        <Route exact path="/dashboard/fitness/calories">
          {!smallScreen ? <UsersData /> : <DailyCalories />}
        </Route>
        <Route exact path="/dashboard/fitness/calories/in-depth-data">
          {!smallScreen ? <UsersData /> : <CalorieData />}
        </Route>
        <Route exact path="/dashboard/fitness/nutrition/articles">
          {!smallScreen ? <UsersData /> : <Articles />}
        </Route>
        <Route exact path="/dashboard/fitness/nutrition/articles/:id">
          {!smallScreen ? <UsersData /> : <ArticleDetails />}
        </Route>
        <Route exact path="/dashboard/fitness/nutrition/food-equivalents">
          {!smallScreen ? <UsersData /> : <FoodEquivalents />}
        </Route>
        <Route exact path="/dashboard/fitness/nutrition/food-equivalents/:id">
          {!smallScreen ? <UsersData /> : <FoodEquivalentsTable />}
        </Route>
        <Route exact path={'/dashboard/fitness/nutrition/tips-tricks'}>
          {!smallScreen ? <UsersData /> : <TipsTricks />}
        </Route>
        <Route
          exact
          path="/dashboard/fitness/nutrition/traffic-light/tipstricks"
          render={() => {
            return !smallScreen ? <UsersData /> : <TrafficLightFitness />;
          }}
        />
        <Route exact path="/dashboard/fitness/nutrition/diet-plans">
          {!smallScreen ? <UsersData /> : <DietCalculatorInitialPage />}
        </Route>
        <Route exact path="/dashboard/fitness/nutrition/diet-plans/calculator">
          {!smallScreen ? <UsersData /> : <DietCalculatorMobile />}
        </Route>
      </IonRouterOutlet>
    );
  };

  return renderRoutes();
};

export default ProtectedRoutes;
