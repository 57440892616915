import { ArticleCard, ConduitFile, theme } from '@hdcorner/ui-library';
import React, { FC, useEffect, useMemo } from 'react';
import {
  createArticleList,
  updateArticleCategory,
  updateArticleDetails,
} from '../slices/articlesSlice';
import { convertArticleCategory, convertArticleTitle } from '../utilities/articleUtils';
import { useGetSuggestedArticleQuery } from '../queries/articlesQueries';
import { useAppDispatch } from '../../../../redux/hooks';
import { useIonRouter } from '@ionic/react';
import { Box, styled, Typography, TypographyProps, useMediaQuery } from '@mui/material';
import useAlert from '../../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

const Title = styled(Typography, { shouldForwardProp: propName => propName !== 'web' })<
  TypographyProps & { web: boolean }
>(({ web, theme }) => ({
  color: theme.palette.primary.light,
  marginBottom: theme.spacing(1),
  fontWeight: theme.typography.fontWeightMedium,
  textTransform: web && lang === 'en' ? 'uppercase' : 'capitalize',
}));

type Props = {
  handleGoToArticle?: () => void;
};
const SectionSuggestedArticle: FC<Props> = ({ handleGoToArticle }) => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { presentError } = useAlert();
  const web = useMediaQuery(theme.breakpoints.up('md'));
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const { data: suggestedArticle, error } = useGetSuggestedArticleQuery({
    lang: lang,
    suggested: true,
    sort: ['-createdAt'],
  });

  useEffect(() => {
    if (error) {
      presentError(t('errors.fitness.nutrition.errorArticleFetch'));
    }
  }, [error]);

  const article = useMemo(() => {
    if (!suggestedArticle || suggestedArticle.length === 0) return;

    const article = suggestedArticle[0];

    if (!article) return;

    return {
      avatarSrc: '',
      id: article._id || '',
      title: article.title || '',
      author: article.author || '',
      imageSrc: (article.image as ConduitFile)?.url || '',
      category: convertArticleCategory(article.category) || '',
    };
  }, [suggestedArticle]);

  const handleSuggestedArticle = (data: {
    id: string;
    title: string;
    category: string;
  }) => {
    dispatch(
      updateArticleCategory({
        value: convertArticleTitle(data.category) || '',
        title: data.category,
      }),
    );
    dispatch(updateArticleDetails({ id: data.id, title: data.title }));
    dispatch(createArticleList([data.id]));
    if (handleGoToArticle) {
      handleGoToArticle();
    }

    if (!web) {
      router.push(`/dashboard/fitness/nutrition/articles/${data.id}`);
    }
  };

  return (
    <>
      {article && (
        <Box>
          <Title web={web} variant={'subtitle2'}>
            {t('headingsTitles.suggestedArticle')}
          </Title>
          <ArticleCard
            key={article.id}
            title={article.title}
            author={article.author}
            imageSrc={article.imageSrc}
            category={article.category}
            avatarSrc={article.avatarSrc}
            onClick={() => handleSuggestedArticle(article)}
          />
        </Box>
      )}
    </>
  );
};

export default SectionSuggestedArticle;
