import { theme } from '@hdcorner/ui-library';
import { Box } from '@mui/material';
import Lottie from 'lottie-react';
import animationData from '../../../assets/lotties/authloading.json';
import { FC } from 'react';

export type LoadingPageProps = {
  web: boolean;
};
const LoadingPage: FC<LoadingPageProps> = ({ web }) => {
  return (
    <Box
      flexGrow={1}
      height={'100%'}
      display={'flex'}
      alignItems={'center'}
      alignContent={'center'}
      justifyContent={'center'}
      width={web ? '350px' : '100%'}
      bgcolor={theme.palette.secondary.light}
    >
      <Lottie
        animationData={animationData}
        width={web ? '180px' : '128px'}
        height={web ? '180px' : '128px'}
      />
    </Box>
  );
};

export default LoadingPage;
