import React, { FC, ReactElement } from 'react';
import ArrowRight from '../../assets/ArrowRight';
import { styled } from '@mui/material/styles';
import { Box, BoxProps, Typography, useTheme } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography/Typography';

const CustomBox = styled(Box, {
  shouldForwardProp: propName => {
    return propName !== 'profile' && propName !== 'bgColorModal';
  },
})<BoxProps & { profile: boolean; bgColorModal: boolean }>(({ theme, ...props }) => ({
  flexGrow: '1',
  display: 'flex',
  borderRadius: '8px',
  alignItems: 'center',
  boxSizing: 'border-box',
  justifyContent: 'space-between',
  backgroundColor: props.bgColorModal
    ? theme.palette.highlight.main
    : theme.palette.secondary.light,
  cursor: 'pointer',
  '&:last-of-type': {
    margin: theme.spacing(0),
  },
  height: props.profile ? '24px' : '35px',
  padding: props.profile ? '0' : theme.spacing(1, 2),
  margin: props.profile ? theme.spacing(0, 0, 3.5, 0) : theme.spacing(0, 0, 1, 0),
}));

const TypographyHeading = styled(Typography, {
  shouldForwardProp: propName => {
    return propName !== 'textColor';
  },
})<TypographyProps & { textColor: string | undefined }>(({ theme, textColor }) => ({
  boxSizing: 'border-box',
  margin: theme.spacing(0),
  fontWeight: theme.typography.fontWeightMedium,
  color: textColor && textColor ? textColor : theme.palette.primary.main,
}));

const TypographyPreview = styled(Typography, {
  shouldForwardProp: (propName: PropertyKey) => {
    return propName !== 'profile' && propName !== 'textColor';
  },
})<TypographyProps & { profile: boolean; textColor: string | undefined }>(
  ({ theme, profile, textColor }) => ({
    width: '178px',
    boxSizing: 'border-box',
    display: profile ? 'flex' : 'none',
    fontWeight: theme.typography.fontWeightMedium,
    margin: theme.spacing(0, 4, 0, 0),
    color: textColor ? textColor : theme.palette.kmColorsCoolGrey.main,
  }),
);

const TypographyBody = styled(Typography, {
  shouldForwardProp: propName => {
    return propName !== 'textColor' && propName !== 'noArrow' && propName !== 'lang';
  },
})<TypographyProps & { lang: string; textColor: string | undefined; noArrow?: boolean }>(
  ({ lang, theme, textColor }) => ({
    boxSizing: 'border-box',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    textOverflow: 'ellipsis',
    maxWidth: lang === 'el' ? '200px' : 'none',
    color: textColor ? textColor : theme.palette.kmColorsCoolGrey.main,
  }),
);

type ClickNavCardNutritionProps = {
  body?: string;
  headings: string;
  noArrow?: boolean;
  icon?: ReactElement;
  profile?: boolean | any;
  accountSettings?: string;
  bodyProps?: TypographyProps;
  bgColorModal?: boolean | any;
  textColor?: string | undefined;
  headingProps?: TypographyProps;
};
const ClickNavCardNutrition: FC<ClickNavCardNutritionProps & BoxProps> = ({
  body,
  profile,
  headings,
  textColor,
  bgColorModal,
  accountSettings,
  noArrow,
  bodyProps,
  headingProps,
  icon,
  ...boxProps
}) => {
  const theme = useTheme();
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  return (
    <CustomBox {...boxProps} profile={profile} bgColorModal={bgColorModal}>
      <Box display={'flex'} alignItems={'center'}>
        <TypographyHeading
          {...headingProps}
          variant={'subtitle2'}
          textColor={textColor}
          textTransform={'capitalize'}
        >
          {headings}
        </TypographyHeading>
      </Box>

      <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} gap={1}>
        <TypographyPreview variant={'subtitle2'} profile={profile} textColor={textColor}>
          {accountSettings}
        </TypographyPreview>

        <TypographyBody
          {...bodyProps}
          lang={lang}
          variant={'body1'}
          noArrow={noArrow}
          textColor={textColor}
        >
          {body}
        </TypographyBody>
        {noArrow && (
          <ArrowRight fill={textColor ? textColor : theme.palette.primary.main} />
        )}
        {icon && <Box margin={theme.spacing(0, 1)}>{icon}</Box>}
      </Box>
    </CustomBox>
  );
};

export default ClickNavCardNutrition;
