import React, { useMemo, useState } from 'react';
import { LipidGoal, ModalMobile, ToggleButtonGroupCu } from '@hdcorner/ui-library';
import ModalRemoveGoal from '../../components/ModalRemoveGoal';
import { Box, styled } from '@mui/material';
import HdlCholesterolSection from './HdlCholesterolSection';
import TriglyceridesSection from './TriglyceridesSection';
import LdlCholesterolSection from './LdlCholesterolSection';
import LpaSection from './LpaSection';
import TotalCholesterolSection from './TotalCholesterolSection';
import {
  useAddLipidGoalMutation,
  useDeleteLipidGoalMutation,
  useEditLipidGoalMutation,
  useGetLipidLastLogQuery,
} from './queries/lipidQueries';
import ModalAddEditGoal from '../../components/ModalAddEditGoal';
import moment from 'moment';
import useAlert from '../../hooks/useAlert';
import AllMeasurementsSection from './components/AllMeasurementsSection';
import useMetric from './hooks/useMetric';
import { useTranslation } from 'react-i18next';

const CustomBox = styled(Box)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.secondary.main
      : theme.palette.secondary.light,
  display: 'flex',
  borderRadius: '8px',
  alignItems: 'center',
  gap: theme.spacing(3),
  justifyContent: 'space-between',
  padding: theme.spacing(2),
}));

const headings = ['all', 'chol', 'ldl', 'hdl', 'trg', 'lpa'];

const UserDataLipid = () => {
  const { t } = useTranslation();
  const { presentSuccess, presentError } = useAlert();

  const [value, setValue] = useState<string>('all');

  const [goal, setGoal] = useState<LipidGoal>();
  const [goalType, setGoalType] = useState<string>('');
  const [modalGoalOpen, setModalGoalOpen] = useState<boolean>(false);
  const [modalRemoveGoalOpen, setModalRemoveGoalOpen] = useState(false);

  const [addLipidGoal] = useAddLipidGoalMutation();
  const [editLipidGoal] = useEditLipidGoalMutation();
  const [deleteLipidGoal] = useDeleteLipidGoalMutation();

  const metric = useMetric(value);
  const { data: lastLog } = useGetLipidLastLogQuery();

  const getCurrentMeasurement = useMemo(() => {
    switch (metric) {
      case 'totalChol':
        return lastLog?.documents[0]?.totalChol;
      case 'hdl':
        return lastLog?.documents[0]?.hdl;
      case 'ldl':
        return lastLog?.documents[0]?.ldl;
      case 'triglycerides':
        return lastLog?.documents[0]?.triglycerides;
      case 'lpa':
        return lastLog?.documents[0]?.lpa;
      default:
        return 0;
    }
  }, [lastLog, metric]);

  const handleSaveGoal = (newGoalValue: string) => {
    if (goal) {
      editLipidGoal({
        _id: goal._id,
        goalType: goalType,
        startDate: goal.createdAt,
        goal: { measurement: newGoalValue },
      })
        .unwrap()
        .then(() => {
          presentSuccess(t('errors.lipid.successGoalEdit'));
        })
        .catch(() => {
          presentError(t('errors.lipid.errorGoalEdit'));
        });
    } else {
      addLipidGoal({
        goalType: goalType,
        goal: { measurement: newGoalValue },
        startDate: moment().startOf('day').toISOString(),
      })
        .unwrap()
        .then(() => presentSuccess(t('errors.lipid.successGoalAdd')))
        .catch(() => presentError(t('errors.lipid.errorGoalAdd')))
        .finally(() => setModalGoalOpen(false));
    }
  };

  const handleClickRemove = async () => {
    if (goal) {
      await deleteLipidGoal({ _id: goal._id })
        .unwrap()
        .then(() => presentSuccess(t('errors.lipid.successGoalDelete')))
        .catch(e => presentError(t('errors.lipid.errorGoalDelete')))
        .finally(() => {
          setGoal(undefined);
          setModalRemoveGoalOpen(false);
        });
    }
  };

  const handleAddClick = (goalType: string) => {
    setGoalType(goalType);
    setModalGoalOpen(true);
  };

  const renderDesiredGoalPlaceholder = useMemo(() => {
    switch (goalType) {
      case 'chol':
        return `200 ${t('measurements.mgdl')}`;
      case 'hdl':
        return `60 ${t('measurements.mgdl')}`;
      case 'ldl':
        return `100 ${t('measurements.mgdl')}`;
      case 'trg':
        return `150 ${t('measurements.mgdl')}`;
      case 'lpa':
        return `14 ${t('measurements.mgdl')}`;
      default:
        return '';
    }
  }, [goalType]);

  const handleEditClick = (goal: LipidGoal) => {
    setGoal(goal);
    setGoalType(goal.goalType);
    setModalGoalOpen(true);
  };

  const handleRemoveGoal = (goal: LipidGoal) => {
    setGoal(goal);
    setModalRemoveGoalOpen(true);
  };

  return (
    <>
      <Box height={'74px'} position={'relative'}>
        <CustomBox>
          <ToggleButtonGroupCu value={value} headings={headings} setValue={setValue} />
        </CustomBox>
      </Box>

      {value === 'all' && <AllMeasurementsSection />}

      {value === 'chol' && (
        <TotalCholesterolSection
          onAddGoalClick={handleAddClick}
          onEditGoalClick={handleEditClick}
          onRemoveGoalClick={handleRemoveGoal}
        />
      )}
      {value === 'hdl' && (
        <HdlCholesterolSection
          onAddGoalClick={handleAddClick}
          onEditGoalClick={handleEditClick}
          onRemoveGoalClick={handleRemoveGoal}
        />
      )}

      {value === 'ldl' && (
        <LdlCholesterolSection
          onAddGoalClick={handleAddClick}
          onEditGoalClick={handleEditClick}
          onRemoveGoalClick={handleRemoveGoal}
        />
      )}

      {value === 'trg' && (
        <TriglyceridesSection
          onAddGoalClick={handleAddClick}
          onEditGoalClick={handleEditClick}
          onRemoveGoalClick={handleRemoveGoal}
        />
      )}

      {value === 'lpa' && (
        <LpaSection
          onAddGoalClick={handleAddClick}
          onEditGoalClick={handleEditClick}
          onRemoveGoalClick={handleRemoveGoal}
        />
      )}

      <ModalMobile open={modalRemoveGoalOpen} setOpen={setModalRemoveGoalOpen}>
        <ModalRemoveGoal
          handleRemoveClick={handleClickRemove}
          handleCancelClick={() => setModalRemoveGoalOpen(false)}
        />
      </ModalMobile>
      <ModalMobile open={modalGoalOpen} setOpen={setModalGoalOpen}>
        <ModalAddEditGoal
          goal={goal}
          handleSaveClick={handleSaveGoal}
          currentMeasurement={getCurrentMeasurement}
          desiredPlaceholder={renderDesiredGoalPlaceholder}
        />
      </ModalMobile>
    </>
  );
};

export default UserDataLipid;
