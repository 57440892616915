import React, { FC, useEffect, useMemo, useState } from 'react';
import { Box, BoxProps, styled, Typography } from '@mui/material';
import {
  CustomButton,
  CustomInput,
  Food,
  FoodItems,
  PlusIconCircular,
  theme,
  useDebounce,
} from '@hdcorner/ui-library';
import {
  useGetPopularFoodsQuery,
  useGetRecentFoodsQuery,
  useLazyGetSearchFoodsQuery,
} from '../queries/foodsQueries';
import { useAppSelector } from '../../../../redux/hooks';
import useAlert from '../../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';
import InfiniteScrollList from '../../../../components/InfiniteScrollList';

const BoxMain = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.highlight.main,
  '::-webkit-scrollbar': {
    width: '10px',
  },
  '::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.background.default,
    borderRadius: '10px',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '10px',
  },
}));

type SearchPanelProps = {
  meal: boolean;
  label: string;
  button: string;
  placeholder: string;
  handleItemClick: (value: Food) => void;
  handleCreateMeal: (value: string) => void;
};
const SearchPanel: FC<SearchPanelProps> = ({
  meal,
  label,
  button,
  placeholder,
  handleItemClick,
  handleCreateMeal,
}) => {
  const { t } = useTranslation();
  const { presentError } = useAlert();
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const { mealCategory, existingCustomMeal } = useAppSelector(
    state => state.mealFoodsList,
  );

  const [search, setSearch] = useState<string>('');
  const searchText = useDebounce(search, 500);
  const [mealName, setMealName] = useState<string>(existingCustomMeal.mealName);
  const [foodParams, setFoodParams] = useState({
    skip: 0,
    limit: 20,
  });

  const { data: recentRes, error: errorRecent } = useGetRecentFoodsQuery();
  const { data: popularRes, error: errorPopular } = useGetPopularFoodsQuery({
    category: mealCategory ? mealCategory : '',
  });
  const [searchFoods, { data: foodsRes, error: errorFoods, isFetching }] =
    useLazyGetSearchFoodsQuery();

  useEffect(() => {
    searchFoods({
      skip: foodParams.skip,
      limit: foodParams.limit,
      searchText: searchText || undefined,
    });
  }, [foodParams.limit, foodParams.skip]);

  useEffect(() => {
    searchFoods({
      skip: 0,
      limit: 20,
      searchText: searchText || undefined,
    });
  }, [searchText]);

  useEffect(() => {
    if (errorRecent) {
      presentError(t('errors.fitness.nutrition.errorFoodRecentFetch'));
    }
    if (errorPopular) {
      presentError(t('errors.fitness.nutrition.errorFoodPopFetch'));
    }
    if (errorFoods) {
      presentError(t('errors.fitness.nutrition.errorFoodFetch'));
    }
  }, [errorRecent, errorPopular, errorFoods]);

  const foodsData = useMemo<Food[]>(() => {
    if (!foodsRes || foodsRes.foods.length === 0) return [];
    return foodsRes.foods;
  }, [foodsRes]);

  const popularFoods = useMemo<Food[]>(() => {
    if (!popularRes || popularRes.foods.length === 0) return [];
    return popularRes.foods;
  }, [popularRes]);

  const recentFoods = useMemo(() => {
    if (!recentRes || recentRes.foods.length === 0) return [];
    return recentRes.foods;
  }, [recentRes]);

  return (
    <>
      <Box
        gap={1}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        margin={theme.spacing(0, 0, 6, 0)}
      >
        <Box display={meal ? 'block' : 'none'}>
          <CustomInput
            fullWidth
            value={mealName}
            alternativeBackground
            placeholder={placeholder}
            handleChange={setMealName}
            label={`${t('fitness.nutrition.labels.mealName')}`}
          />
        </Box>
        <CustomInput
          fullWidth
          label={label}
          value={search}
          alternativeBackground
          handleChange={setSearch}
          placeholder={placeholder}
        />
        <CustomButton
          fullWidth
          children={button}
          color={'primary'}
          variant={'contained'}
          disabled={meal && mealName === ''}
          onClick={() => handleCreateMeal(mealName)}
        />
        {!searchText && popularFoods && popularFoods.length > 0 && (
          <Box gap={1} display={'flex'} flexDirection={'column'}>
            <Typography
              variant={'subtitle2'}
              sx={{ fontWeight: theme.typography.fontWeightRegular }}
            >
              {t('general.labels.popular')}
            </Typography>
            <BoxMain>
              {popularFoods.map(food => (
                <FoodItems
                  key={food._id}
                  calories={food.calories}
                  quantity={food.quantity}
                  handleAddItem={() => handleItemClick(food)}
                  icon={<PlusIconCircular width={'14px'} height={'14px'} />}
                  name={typeof food.name === 'string' ? food.name : food.name[lang]}
                />
              ))}
            </BoxMain>
          </Box>
        )}
        {!searchText && recentFoods && recentFoods.length > 0 && (
          <Box gap={1} display={'flex'} flexDirection={'column'}>
            <Typography
              variant={'subtitle2'}
              sx={{ fontWeight: theme.typography.fontWeightRegular }}
            >
              {t('general.labels.recent')}
            </Typography>
            <BoxMain>
              {recentFoods.map(food => (
                <FoodItems
                  key={food._id}
                  quantity={food.quantity}
                  calories={food.calories}
                  handleAddItem={() => handleItemClick(food)}
                  icon={<PlusIconCircular width={'14px'} height={'14px'} />}
                  name={typeof food.name === 'string' ? food.name : food.name[lang]}
                />
              ))}
            </BoxMain>
          </Box>
        )}
        <Box gap={1} display={'flex'} flexDirection={'column'} hidden={!searchText}>
          <Typography
            variant={'subtitle2'}
            sx={{ fontWeight: theme.typography.fontWeightRegular }}
          >
            {searchText
              ? t('general.labels.searchRes')
              : t('fitness.nutrition.labels.allFood')}
          </Typography>
          <InfiniteScrollList
            loading={isFetching}
            skip={foodParams.skip}
            limit={foodParams.limit}
            count={foodsData.length}
            totalCount={foodsRes?.count}
            onLoadMore={(skip, limit) => setFoodParams({ ...foodParams, skip, limit })}
          >
            {foodsData.map(food => (
              <Box key={food._id}>
                <FoodItems
                  key={food._id}
                  calories={food.calories}
                  handleAddItem={() => handleItemClick(food)}
                  icon={<PlusIconCircular width={'14px'} height={'14px'} />}
                  name={typeof food.name === 'string' ? food.name : food.name[lang]}
                />
              </Box>
            ))}
          </InfiniteScrollList>
        </Box>
      </Box>
    </>
  );
};

export default SearchPanel;
