import React, { FC } from 'react';
import { Box, BoxProps, styled, Typography, useTheme } from '@mui/material';

const MainBox = styled(Box)<BoxProps>(({ theme }) => ({
  flexGrow: '1',
  borderRadius: '8px',
  boxSizing: 'border-box',
  backgroundColor: theme.palette.secondary.light,
  padding: theme.spacing(1, 2),
  margin: theme.spacing(0, 0, 1, 0),
  '&:last-of-type': {
    margin: theme.spacing(0),
  },
}));

const InnerBox = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
}));

type GoalDetailsCardProps = {
  goal: { label: string; value: string };
};
const GoalDetailsCard: FC<GoalDetailsCardProps> = ({ goal }) => {
  const theme = useTheme();

  return (
    <MainBox>
      <InnerBox>
        <Typography
          variant={'subtitle2'}
          sx={{
            color: theme.palette.primary.main,
            fontWight: theme.typography.fontWeightRegular,
          }}
        >
          {goal.label}
        </Typography>
        <Typography
          variant={'body1'}
          sx={{
            color: theme.palette.kmColorsCoolGrey.main,
          }}
        >
          {goal.value}
        </Typography>
      </InnerBox>
    </MainBox>
  );
};

export default GoalDetailsCard;
