import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
};

const detectMob = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some(toMatchItem => {
    return navigator.userAgent.match(toMatchItem);
  });
};

let messaging: any;
let firebaseApp: any;

if (!detectMob()) {
  firebaseApp = initializeApp(firebaseConfig);
  messaging = getMessaging();
}

export const requestWebFirebaseToken = async () => {
  if (!firebaseApp || !messaging) {
    return;
  }
  try {
    return await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    });
  } catch (error) {
    console.log(error);
  }
};

export const onMessageListener = () => {
  if (!firebaseApp || !messaging) {
    return;
  }
  return new Promise(resolve => {
    onMessage(messaging, payload => {
      resolve(payload);
    });
  });
};
