import * as React from 'react';
import { SVGProps } from 'react';

const HypertensionSQ = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="15"
      height="12"
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.6667 5.3333C14.6667 5.51011 14.5965 5.67968 14.4715 5.80471C14.3464 5.92973 14.1769 5.99997 14.0001 5.99997H12.4394L10.6127 10.2626C10.5569 10.3877 10.4645 10.493 10.3476 10.5644C10.2307 10.6358 10.0948 10.6701 9.95806 10.6626C9.82041 10.6541 9.68879 10.6031 9.58133 10.5167C9.47387 10.4302 9.39586 10.3126 9.35806 10.18L7.37606 3.2433L5.31339 11.4946C5.27918 11.6328 5.20164 11.7564 5.09212 11.8473C4.98259 11.9383 4.84684 11.9918 4.70473 12H4.66673C4.53101 12.0001 4.39849 11.9588 4.28686 11.8816C4.17524 11.8044 4.08983 11.695 4.04206 11.568L2.20473 6.66664H0.666728C0.489917 6.66664 0.320347 6.5964 0.195323 6.47137C0.0702989 6.34635 6.10352e-05 6.17678 6.10352e-05 5.99997C6.10352e-05 5.82316 0.0702989 5.65359 0.195323 5.52857C0.320347 5.40354 0.489917 5.3333 0.666728 5.3333H2.66673C2.80244 5.33317 2.93497 5.37446 3.04659 5.45165C3.15822 5.52885 3.24362 5.63827 3.29139 5.7653L4.53939 9.09464L6.68673 0.505304C6.72249 0.363104 6.80413 0.236659 6.91901 0.145548C7.0339 0.0544368 7.17561 0.00374181 7.32222 0.00131228C7.46883 -0.00111725 7.61214 0.0448543 7.72998 0.132109C7.84782 0.219363 7.93361 0.343033 7.97406 0.48397L10.1261 8.0173L11.3874 5.07397C11.4383 4.95345 11.5235 4.85057 11.6325 4.77814C11.7414 4.70571 11.8692 4.66693 12.0001 4.66664H14.0001C14.1769 4.66664 14.3464 4.73688 14.4715 4.8619C14.5965 4.98692 14.6667 5.15649 14.6667 5.3333Z"
        fill={props?.color ?? '#F2F1F3'}
      />
    </svg>
  );
};

export default HypertensionSQ;
