import React, { SVGProps } from 'react';
import { useTheme } from '@mui/material';

const XCloseIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();

  return (
    <svg
      {...props}
      width="12"
      height="11"
      fill="none"
      viewBox="0 0 12 11"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7071 2.20708L7.41408 5.50008L10.7071 8.79308C10.8026 8.88533 10.8788 8.99567 10.9312 9.11768C10.9836 9.23968 11.0112 9.3709 11.0123 9.50368C11.0135 9.63646 10.9882 9.76814 10.9379 9.89103C10.8876 10.0139 10.8134 10.1256 10.7195 10.2195C10.6256 10.3134 10.5139 10.3876 10.391 10.4379C10.2681 10.4882 10.1365 10.5135 10.0037 10.5123C9.8709 10.5112 9.73968 10.4836 9.61768 10.4312C9.49567 10.3788 9.38533 10.3026 9.29308 10.2071L6.00008 6.91408L2.70708 10.2071C2.61483 10.3026 2.50449 10.3788 2.38249 10.4312C2.26048 10.4836 2.12926 10.5112 1.99648 10.5123C1.8637 10.5135 1.73202 10.4882 1.60913 10.4379C1.48623 10.3876 1.37458 10.3134 1.28069 10.2195C1.18679 10.1256 1.11254 10.0139 1.06226 9.89103C1.01198 9.76814 0.986677 9.63646 0.987831 9.50368C0.988985 9.3709 1.01657 9.23968 1.06898 9.11768C1.12139 8.99567 1.19757 8.88533 1.29308 8.79308L4.58608 5.50008L1.29308 2.20708C1.19757 2.11483 1.12139 2.00449 1.06898 1.88249C1.01657 1.76048 0.988985 1.62926 0.987831 1.49648C0.986677 1.3637 1.01198 1.23202 1.06226 1.10913C1.11254 0.986231 1.18679 0.874579 1.28069 0.780686C1.37458 0.686793 1.48623 0.61254 1.60913 0.562259C1.73202 0.511978 1.8637 0.486677 1.99648 0.487831C2.12926 0.488985 2.26048 0.516571 2.38249 0.56898C2.50449 0.621389 2.61483 0.697571 2.70708 0.793081L6.00008 4.08608L9.29308 0.793081C9.38533 0.697571 9.49567 0.621389 9.61768 0.56898C9.73968 0.516571 9.8709 0.488985 10.0037 0.487831C10.1365 0.486677 10.2681 0.511978 10.391 0.562259C10.5139 0.61254 10.6256 0.686793 10.7195 0.780686C10.8134 0.874579 10.8876 0.986231 10.9379 1.10913C10.9882 1.23202 11.0135 1.3637 11.0123 1.49648C11.0112 1.62926 10.9836 1.76048 10.9312 1.88249C10.8788 2.00449 10.8026 2.11483 10.7071 2.20708Z"
        fill={props.color ? props.color : theme.palette.kmColorsCoolGrey.main}
      />
    </svg>
  );
};

export default XCloseIcon;
