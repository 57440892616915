import { Box } from '@mui/material';
import React, { FC } from 'react';
import RemoveEditButtonsContainer from './components/RemoveEditButtonsContainer';
import GoalDetailsCard from '../components/cards/GoalDetailsCard';

type Type = {
  data: {
    label: string;
    value: string;
    marginBottom?: boolean;
  }[];
  handleEdit?: () => void;
  handleRemove?: () => void;
};

const Goal: FC<Type> = ({ data, handleRemove, handleEdit }) => {
  return (
    <Box>
      {data.map((item, index) => (
        <Box key={`${item}${index}`} mb={item.marginBottom ? 3 : 1}>
          <GoalDetailsCard goal={item} />
        </Box>
      ))}
      {handleRemove && handleEdit && (
        <RemoveEditButtonsContainer onRemove={handleRemove} onEdit={handleEdit} />
      )}
    </Box>
  );
};

export default Goal;
