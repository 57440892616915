import { LipidLog } from '../types/LipidLog';

const filterValues = (paramType: string, log: LipidLog) => {
  if (paramType === 'trg' || paramType === 'triglycerides')
    return log.triglycerides !== undefined;
  if (paramType === 'chol' || paramType === 'totalChol')
    return log.totalChol !== undefined;
  if (paramType === 'ldl') return log.ldl !== undefined;
  if (paramType === 'hdl') return log.hdl !== undefined;
  if (paramType === 'lpa') return log.lpa !== undefined;
};

const calculateValues = (paramType: string, log: LipidLog) => {
  if (paramType === 'trg') return log.triglycerides || 0;
  if (paramType === 'chol') return log.totalChol || 0;
  if (paramType === 'ldl') return log.ldl || 0;
  if (paramType === 'hdl') return log.hdl || 0;
  if (paramType === 'lpa') return log.lpa || 0;
  return 0;
};

export { filterValues, calculateValues };
