import React, { FC } from 'react';
import RedUpArrow from '../../assets/RedUpArrow';
import GreenDownArrow from '../../assets/GreenDownArrow';
import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import StableTrend from '../../assets/StableTrend';
import { useTranslation } from 'react-i18next';

const CustomBox = styled(Box, {
  shouldForwardProp: propName => {
    return propName !== 'boxSize';
  },
})<BoxProps & { boxSize: 'tallNarrow' | 'shortWide' }>(({ theme, ...props }) => ({
  flex: 1,
  minWidth: 291,
  borderRadius: '8px',
  boxSizing: 'border-box',
  padding: theme.spacing(1),
  margin: theme.spacing(0, 2, 0, 0),
  minHeight: props.boxSize === 'tallNarrow' ? '184px' : '136px',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.secondary.main
      : theme.palette.secondary.light,
  '&:last-of-type': {
    margin: theme.spacing(0),
  },
}));

const InnerBox = styled(Box)<BoxProps>(({ theme }) => ({
  padding: theme.spacing(1),
  display: 'flex',
  borderRadius: '8px',
  alignItems: 'center',
  boxSizing: 'border-box',
  justifyContent: 'space-between',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.highlight.main
      : theme.palette.secondary.main,
  margin: theme.spacing(1, 0, 0, 0),
}));

const CardTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  margin: theme.spacing(0, 2, 0, 0),
  '&:last-of-type': {
    margin: theme.spacing(0),
  },
}));

type CardType = {
  label: string;
  value: string;
  direction?: 'ascending' | 'descending' | 'stable';
};

type MyDataCardProps = {
  time: string;
  heading: string;
  cards: CardType[];
  icon: React.ReactNode;
  boxSize: 'tallNarrow' | 'shortWide';
};
const MyDataCard: FC<MyDataCardProps & BoxProps> = ({
  icon,
  time,
  cards,
  heading,
  boxSize,
  ...containerProps
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const renderDirectionIcon = (card: CardType) => {
    if (!card || !card.direction) {
      return;
    }

    switch (card.direction) {
      case 'ascending':
        return <RedUpArrow />;
      case 'descending':
        return <GreenDownArrow />;
      case 'stable':
        return <StableTrend />;
      default:
        return;
    }
  };

  return (
    <CustomBox boxSize={boxSize} {...containerProps}>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          {icon}
          <Typography
            variant={'body1'}
            sx={{
              marginLeft: theme.spacing(1),
              color:
                theme.palette.mode === 'dark'
                  ? theme.palette.kmColorsCoolGrey.main
                  : theme.palette.primary.main,
              fontWeight: theme.typography.fontWeightBold,
            }}
          >
            {heading}
          </Typography>
        </Box>
        <Box>
          {time && (
            <Typography
              variant={'body2'}
              sx={{
                color:
                  theme.palette.mode === 'dark'
                    ? theme.palette.kmColorsCoolGrey.main
                    : theme.palette.primary.main,
              }}
            >
              {t('general.labels.updated')} {time}
            </Typography>
          )}
        </Box>
      </Box>
      {cards.map((card, index) => {
        return (
          <InnerBox key={index}>
            <Box
              sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
            >
              <CardTypography
                variant={'subtitle2'}
                sx={{ textTransform: 'capitalize', minInlineSize: 'fit-content' }}
              >
                {card.label}
              </CardTypography>
              <CardTypography
                variant={'subtitle1'}
                sx={{
                  minInlineSize: 'auto',
                  fontWeight: theme.typography.fontWeightMedium,
                }}
              >
                {card.value}
              </CardTypography>
            </Box>
            {renderDirectionIcon(card)}
          </InnerBox>
        );
      })}
    </CustomBox>
  );
};

export default MyDataCard;
