import React, { FC, useState } from 'react';
import { Box } from '@mui/material';
import CustomInput from './controls/CustomInput';
import ComponentsLayout from './ComponentsLayout';
import CustomButton from './controls/CustomButton';
import { useTranslation } from 'react-i18next';

type PhoneProps = {
  handleNext: (newPhone: string) => void;
};
const SettingsPhonePanelWeb: FC<PhoneProps> = ({ handleNext }) => {
  const { t } = useTranslation();

  const [phone, setPhone] = useState<string>('');

  const handleClickNext = () => {
    handleNext(phone);
  };

  return (
    <ComponentsLayout>
      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <Box>
          <CustomInput
            fullWidth
            value={phone}
            type={'number'}
            handleChange={setPhone}
            label={t('profile.text.enterPhone')}
          />
        </Box>
        <Box width={'288px'} margin={'0 auto'}>
          <CustomButton
            fullWidth
            variant={'contained'}
            disabled={phone === ''}
            onClick={handleClickNext}
          >
            {t('buttons.changePhone')}
          </CustomButton>
        </Box>
      </Box>
    </ComponentsLayout>
  );
};

export default SettingsPhonePanelWeb;
