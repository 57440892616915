import * as React from 'react';
import { SVGProps } from 'react';

const CVDRiskSQ = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.57455 9.47544V6.80454C8.57455 6.64713 8.5139 6.49616 8.40595 6.38485C8.29799 6.27354 8.15157 6.21101 7.9989 6.21101C7.84623 6.21101 7.69981 6.27354 7.59186 6.38485C7.4839 6.49616 7.42326 6.64713 7.42326 6.80454V9.47544C7.42326 9.63286 7.4839 9.78382 7.59186 9.89513C7.69981 10.0064 7.84623 10.069 7.9989 10.069C8.15157 10.069 8.29799 10.0064 8.40595 9.89513C8.5139 9.78382 8.57455 9.63286 8.57455 9.47544Z"
      fill={props?.color ?? '#F2F1F3'}
    />
    <path
      d="M7.9989 10.6625C8.11275 10.6625 8.22405 10.6973 8.31871 10.7625C8.41338 10.8278 8.48716 10.9204 8.53073 11.0289C8.5743 11.1374 8.5857 11.2567 8.56349 11.3718C8.54128 11.487 8.48645 11.5927 8.40595 11.6757C8.32544 11.7587 8.22287 11.8153 8.11121 11.8382C7.99954 11.8611 7.8838 11.8493 7.77861 11.8044C7.67343 11.7595 7.58352 11.6834 7.52027 11.5858C7.45702 11.4882 7.42326 11.3734 7.42326 11.256C7.42326 11.0986 7.4839 10.9477 7.59186 10.8363C7.69981 10.725 7.84623 10.6625 7.9989 10.6625Z"
      fill={props?.color ?? '#F2F1F3'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.66686 12.7242C1.66413 12.8302 1.68895 12.9349 1.73876 13.0276C1.78861 13.1203 1.86161 13.1975 1.95018 13.2513C2.03876 13.305 2.13967 13.3333 2.24245 13.3333H13.7554C13.8583 13.3337 13.9595 13.3057 14.0484 13.2521C14.1372 13.1985 14.2105 13.1213 14.2606 13.0285C14.3107 12.9358 14.3357 12.8309 14.3331 12.7248C14.3305 12.6187 14.3004 12.5152 14.2458 12.4252L8.48936 2.92866C8.43417 2.84772 8.36091 2.78165 8.27574 2.73604C8.19058 2.69043 8.09603 2.66663 8.00006 2.66663C7.9041 2.66663 7.80954 2.69043 7.72438 2.73604C7.63922 2.78165 7.56595 2.84772 7.51076 2.92866L1.7543 12.4252C1.69979 12.515 1.66959 12.6183 1.66686 12.7242ZM7.99891 4.36323L12.7192 12.1462H3.27861L7.99891 4.36323Z"
      fill={props?.color ?? '#F2F1F3'}
    />
  </svg>
);

export default CVDRiskSQ;
