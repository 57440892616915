import { useMemo } from 'react';
import { HypertensionLog } from '../types/HypertensionLog';
import { useTranslation } from 'react-i18next';

const usePreparePressureScatterXy = (graphData?: { logs: HypertensionLog[] }) => {
  const { t } = useTranslation();

  return useMemo(() => {
    if (!graphData || graphData.logs.length === 0) return [];
    const data = graphData.logs.map(item => {
      return [item.systolic, item.diastolic];
    });
    return [{ name: t('hypertension.labels.pressure'), data }];
  }, [graphData]);
};

export default usePreparePressureScatterXy;
