import api from '../../../redux/api';
import { AuthUser, UserData } from '@hdcorner/ui-library';

const personalInfoQueries = api.injectEndpoints({
  endpoints: build => ({
    getUserData: build.query<UserData[], void>({
      query: () => ({
        url: '/database/function/getUserData',
        method: 'GET',
      }),
      providesTags: ['UserData'],
    }),

    updateAuthUser: build.mutation<AuthUser, Partial<AuthUser>>({
      query: ({ firstName, lastName, gender, birthDate }) => ({
        url: '/database/function/setAuthUser',
        method: 'PUT',
        body: { firstName, lastName, gender, birthDate },
      }),
      invalidatesTags: ['AuthUser'],
    }),

    updateUserData: build.mutation<UserData, Partial<UserData>>({
      query: ({
        height,
        weight,
        smoker,
        diabetes,
        hypertension,
        dyslipidemia,
        underMedication,
        socialSecurityNumber,
      }) => ({
        url: '/database/function/editUserData',
        method: 'PUT',
        body: {
          height,
          weight,
          smoker,
          diabetes,
          hypertension,
          dyslipidemia,
          underMedication,
          socialSecurityNumber,
        },
      }),
      invalidatesTags: ['UserData'],
    }),

    setUserProfilePicture: build.mutation<void, { profilePicture: string }>({
      query: ({ profilePicture }) => ({
        method: 'PUT',
        body: { profilePicture },
        url: '/database/function/setAuthUser',
      }),
      invalidatesTags: ['UserData', 'AuthUser'],
    }),

    logUserMood: build.mutation<void, { mood: string }>({
      query: ({ mood }) => ({
        url: '/database/function/logUserMood',
        method: 'POST',
        body: { mood },
      }),
    }),

    getUserMood: build.query<number[], { start: string; end: string }>({
      query: ({ start, end }) => ({
        url: '/database/function/getUserMood',
        method: 'GET',
        params: { start, end },
      }),
    }),
  }),
});

export const {
  useGetUserDataQuery,
  useGetUserMoodQuery,
  useLogUserMoodMutation,
  useLazyGetUserDataQuery,
  useUpdateUserDataMutation,
  useUpdateAuthUserMutation,
  useSetUserProfilePictureMutation,
} = personalInfoQueries;

export default personalInfoQueries;
