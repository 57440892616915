import * as React from 'react';
import { SVGProps } from 'react';

const AlarmIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.8046 11.529L10.6666 10.391V10.167H9.33331V10.667C9.33335 10.8438 9.40361 11.0133 9.52865 11.1383L9.72398 11.3337H2.27598L2.47131 11.1383C2.59635 11.0133 2.66661 10.8438 2.66665 10.667V6.66699C2.66468 6.17723 2.7711 5.69309 2.97827 5.2493C3.18544 4.8055 3.48824 4.41304 3.86495 4.10005C4.24166 3.78706 4.68295 3.5613 5.15719 3.43894C5.63143 3.31658 6.12687 3.30066 6.60798 3.39233L7.66665 2.33366C7.63181 2.30749 7.59426 2.28514 7.55465 2.26699C7.26624 2.16793 6.96882 2.09737 6.66665 2.05633V1.33366C6.66665 1.15685 6.59641 0.987279 6.47139 0.862254C6.34636 0.73723 6.17679 0.666992 5.99998 0.666992C5.82317 0.666992 5.6536 0.73723 5.52858 0.862254C5.40355 0.987279 5.33331 1.15685 5.33331 1.33366V2.05366C4.22358 2.21383 3.20862 2.7682 2.4741 3.61534C1.73958 4.46248 1.33463 5.54576 1.33331 6.66699V10.391L0.195313 11.529C0.102107 11.6222 0.0386361 11.741 0.0129244 11.8703C-0.0127873 11.9996 0.000414704 12.1336 0.0508614 12.2554C0.101308 12.3772 0.186734 12.4813 0.296341 12.5546C0.405948 12.6278 0.534813 12.667 0.666647 12.667H11.3333C11.4651 12.667 11.594 12.6278 11.7036 12.5546C11.8132 12.4813 11.8987 12.3772 11.9491 12.2554C11.9995 12.1336 12.0127 11.9996 11.987 11.8703C11.9613 11.741 11.8979 11.6222 11.8046 11.529Z"
        fill="#F2F1F3"
      />
      <path
        d="M5.99998 15.3337C5.64636 15.3337 5.30722 15.1932 5.05717 14.9431C4.80712 14.6931 4.66665 14.3539 4.66665 14.0003H7.33331C7.33331 14.3539 7.19284 14.6931 6.94279 14.9431C6.69274 15.1932 6.3536 15.3337 5.99998 15.3337Z"
        fill="#F2F1F3"
      />
      <path
        d="M13.3333 5.33374C13.3333 5.51055 13.2631 5.68012 13.1381 5.80514C13.013 5.93017 12.8435 6.00041 12.6666 6.00041H10.6666V8.00041C10.6666 8.17722 10.5964 8.34679 10.4714 8.47181C10.3464 8.59684 10.1768 8.66707 9.99998 8.66707C9.82317 8.66707 9.6536 8.59684 9.52857 8.47181C9.40355 8.34679 9.33331 8.17722 9.33331 8.00041V6.00041H7.33331C7.1565 6.00041 6.98693 5.93017 6.86191 5.80514C6.73688 5.68012 6.66665 5.51055 6.66665 5.33374C6.66665 5.15693 6.73688 4.98736 6.86191 4.86234C6.98693 4.73731 7.1565 4.66707 7.33331 4.66707H9.33331V2.66707C9.33331 2.49026 9.40355 2.32069 9.52857 2.19567C9.6536 2.07064 9.82317 2.00041 9.99998 2.00041C10.1768 2.00041 10.3464 2.07064 10.4714 2.19567C10.5964 2.32069 10.6666 2.49026 10.6666 2.66707V4.66707H12.6666C12.8435 4.66707 13.013 4.73731 13.1381 4.86234C13.2631 4.98736 13.3333 5.15693 13.3333 5.33374Z"
        fill="#F2F1F3"
      />
    </svg>
  );
};

export default AlarmIcon;
