import { FC } from 'react';
import { Box, BoxProps, styled, Typography, TypographyProps } from '@mui/material';
import SubsWebToolbar from './SubsWebToolbar';
import { theme } from '@hdcorner/ui-library';
import SubsPlanCard from './SubsPlanCard';
import SubscriptionIcon from './SubscriptionIcon';
import { useTranslation } from 'react-i18next';

const TypographyHeading = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '64px',
  lineHeight: '87px',
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(1),
  fontWeight: theme.typography.fontWeightBold,
}));

const PlansBox = styled(Box)<BoxProps>(({ theme }) => ({
  borderTopLeftRadius: '20px',
  borderBottomLeftRadius: '20px',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    margin: '0 auto',
  },
}));

export type SubscriptionPanelWebProps = {
  user: string;
  avatar: string;
  premium: boolean;
  planBasic: string;
  planPremium: string;
  handleRedeem: () => void;
  handleTryPremium: () => void;
  handlePlanUpgrade: () => void;
  handleViewHistory: () => void;
  handlePlanDowngrade: () => void;
  benefits: { benefit: string; plan: string[] }[];
};

const SubscriptionPanelWeb: FC<SubscriptionPanelWebProps> = ({
  user,
  avatar,
  premium,
  benefits,
  planBasic,
  planPremium,
  handleViewHistory,
  handleRedeem,
  handlePlanUpgrade,
  handleTryPremium,
  handlePlanDowngrade,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      flexGrow={1}
      height={'100%'}
      display={'flex'}
      flexDirection={'column'}
      boxSizing={'border-box'}
      padding={theme.spacing(3)}
      bgcolor={theme.palette.background.default}
      sx={{
        [theme.breakpoints.down('lg')]: {
          height: 'auto',
        },
      }}
    >
      <SubsWebToolbar
        user={user}
        avatar={avatar}
        premium={premium}
        backButton={false}
        handleRedeem={handleRedeem}
        handleViewHistory={handleViewHistory}
      />
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        sx={{
          [theme.breakpoints.down('lg')]: {
            height: 'auto',
            flexDirection: 'column',
            alignItems: 'flex-start',
          },
        }}
      >
        <Box
          width={'508px'}
          display={'flex'}
          flexDirection={'column'}
          marginRight={theme.spacing(3)}
          sx={{
            [theme.breakpoints.down('lg')]: {
              marginRight: 0,
              marginBottom: 3,
            },
          }}
        >
          <TypographyHeading>Choose what suits you best</TypographyHeading>
          <Typography
            variant={'h1'}
            sx={{ color: theme.palette.primary.light, marginBottom: theme.spacing(8) }}
          >
            {t('subscription.text.redeemCode')}
          </Typography>
          <SubscriptionIcon width={'440px'} height={'312px'} />
        </Box>
        <PlansBox display={'flex'} gap={3} flexWrap={'wrap'} justifyContent={'center'}>
          <SubsPlanCard
            premium={false}
            plan={planBasic}
            benefits={benefits}
            handlePlanDowngrade={handlePlanDowngrade}
          />
          <SubsPlanCard
            premium
            plan={planPremium}
            benefits={benefits}
            tryPremium={handleTryPremium}
            handlePlanUpgrade={handlePlanUpgrade}
          />
        </PlansBox>
      </Box>
    </Box>
  );
};

export default SubscriptionPanelWeb;
