import api from '../../../../redux/api';
import { ESHType } from '@hdcorner/ui-library';

const eshQueries = api.injectEndpoints({
  endpoints: build => ({
    getESH: build.query<ESHType, { start: string; end: string }>({
      query: ({ start, end }) => ({
        method: 'GET',
        url: '/hdCornerService/user/esh',
        params: { start, end, populate: 'logs' },
      }),
      providesTags: ['HypertensionLog'],
    }),
  }),
});

export const { useGetESHQuery } = eshQueries;

export default eshQueries;
