import * as React from 'react';
import { SVGProps } from 'react';

const ArrowDown = ({ fill = '#979FA5', ...rest }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="9"
      height="6"
      viewBox="0 0 9 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M1.51208 0.7249C1.27637 0.459728 0.855995 0.4239 0.573145 0.644877C0.290295 0.865854 0.252079 1.25996 0.487787 1.52513L3.82111 5.27512C3.94777 5.41761 4.1354 5.5 4.33325 5.5C4.5311 5.5 4.71874 5.41761 4.8454 5.27512L8.17872 1.52513C8.41442 1.25996 8.37621 0.865854 8.09336 0.644877C7.81051 0.4239 7.39013 0.459728 7.15443 0.7249L4.33325 3.89872L1.51208 0.7249Z"
        fill={fill}
      />
    </svg>
  );
};

export default ArrowDown;
