import {
  AuthUser,
  CheckBoxes,
  ComponentsLayout,
  CustomDateInput,
  CustomInput,
  DropdownMenu,
  SaveIcon,
  theme,
  UserData,
  UserParticipantImage,
} from '@hdcorner/ui-library';
import { Box } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import DatePicker from '../../components/DatePicker';
import PageLayout from '../../components/PageLayout';
import { useGetAuthUserQuery } from '../authentication/queries/signInQueries';
import {
  useGetUserDataQuery,
  useSetUserProfilePictureMutation,
  useUpdateAuthUserMutation,
  useUpdateUserDataMutation,
} from './queries/personalInfoQueries';
import useAlert from '../../hooks/useAlert';
import _ from 'lodash';
import { diabeticOptions } from './constants';
import {
  useReUploadFileMutation,
  useUploadFileMutation,
} from '../records/queries/recordsQueries';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { useCreateWeightLogMutation } from '../fitness/wellness/queries/weightQueries';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../../utils/useConvertJSON';

const PersonalInfo = () => {
  const { t } = useTranslation();
  const { presentError, presentSuccess } = useAlert();

  const [open, setOpen] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<Partial<AuthUser & UserData>>({
    height: 0,
    weight: 0,
    gender: '',
    diabetes: '',
    lastName: '',
    birthDate: '',
    firstName: '',
    smoker: false,
    hypertension: false,
    dyslipidemia: false,
    underMedication: false,
    socialSecurityNumber: '',
    profilePicture: undefined,
  });

  const [upload] = useUploadFileMutation();
  const [reUpload] = useReUploadFileMutation();
  const [logWeight] = useCreateWeightLogMutation();
  const [setProfilePicture] = useSetUserProfilePictureMutation();

  const [
    updateAuthUser,
    { isLoading: updateAuthUserLoading, error: updateAuthUserError },
  ] = useUpdateAuthUserMutation();
  const [
    updateUserData,
    { isLoading: updateUserDataLoading, error: updateUserDataError },
  ] = useUpdateUserDataMutation();

  const { data: userData, error: userDataError } = useGetUserDataQuery();
  const { data: authUserData, error: authUserError } = useGetAuthUserQuery();

  useEffect(() => {
    if (updateAuthUserError || updateUserDataError || authUserError || userDataError) {
      presentError(t('errors.profile.errorUserData'));
    }
  }, [
    authUserError,
    userDataError,
    updateAuthUserError,
    updateUserDataError,
    updateAuthUserLoading,
    updateUserDataLoading,
  ]);

  useEffect(() => {
    let newAuthUser: Partial<AuthUser>, newUser: Partial<UserData>;

    if (authUserData && authUserData.length > 0) {
      const authUser = authUserData[0];
      const date = moment(authUser.birthDate).format('YYYY-MM-DD');

      newAuthUser = {
        birthDate: date,
        gender: authUser.gender,
        lastName: authUser.lastName,
        firstName: authUser.firstName,
        profilePicture: authUser.profilePicture,
      };

      setUserDetails(oldValue => ({ ...oldValue, ...newAuthUser }));
    }

    if (userData && userData.length > 0) {
      const user = userData[0];

      newUser = {
        height: user.height,
        weight: user.weight,
        smoker: user.smoker,
        hypertension: user.hypertension,
        diabetes: user.diabetes || 'no',
        dyslipidemia: user.dyslipidemia,
        underMedication: user.underMedication,
        socialSecurityNumber: user.socialSecurityNumber,
      };

      setUserDetails(oldValue => ({ ...oldValue, ...newUser }));
    }
  }, [authUserData, userData]);

  useEffect(() => {
    if (updateAuthUserError || updateUserDataError) {
      presentError(t('errors.profile.errorUserDataUpdate'));
    }
  }, [updateAuthUserError, updateUserDataError]);

  const stateHasChanged = useMemo(() => {
    if (userData && authUserData) {
      const allUserDataInitial: Partial<AuthUser & UserData> = {
        smoker: userData[0].smoker,
        height: userData[0].height,
        weight: userData[0].weight,
        diabetes: userData[0].diabetes || 'no',
        hypertension: userData[0].hypertension,
        dyslipidemia: userData[0].dyslipidemia,
        underMedication: userData[0].underMedication,
        socialSecurityNumber: userData[0].socialSecurityNumber,

        gender: authUserData[0].gender,
        lastName: authUserData[0].lastName,
        firstName: authUserData[0].firstName,
        birthDate: moment(authUserData[0].birthDate).format('DD-MM-YYYY'),
      };

      return !_.isEqual(allUserDataInitial, userDetails);
    }

    return false;
  }, [userDetails, userData, authUserData]);

  const handleChange = (fieldName: keyof Partial<AuthUser & UserData>, value: any) => {
    setUserDetails(oldValue => ({
      ...oldValue,
      [fieldName]: value,
    }));
  };

  const handleSave = async () => {
    try {
      await updateAuthUser({
        gender: userDetails.gender,
        lastName: userDetails.lastName,
        firstName: userDetails.firstName,
        birthDate: userDetails.birthDate,
      }).unwrap();

      await updateUserData({
        height: userDetails.height,
        weight: userDetails.weight,
        smoker: userDetails.smoker,
        diabetes: userDetails.diabetes,
        dyslipidemia: userDetails.dyslipidemia,
        hypertension: userDetails.hypertension,
        underMedication: userDetails.underMedication,
        socialSecurityNumber: userDetails.socialSecurityNumber,
      }).unwrap();

      const initialUserWeight = userData && userData[0].weight;
      const currentUserWeight = userDetails.weight;

      if (currentUserWeight && initialUserWeight !== currentUserWeight) {
        const logDate = moment().toISOString();
        await logWeight({ measurement: currentUserWeight, logDate }).unwrap();
      }

      presentSuccess('Changes saved successfully');
    } catch (e) {
      presentError('An error occurred while saving your data');
    }
  };

  const handleChangeImage = async (files: File[]) => {
    const file = files[0];
    const fileName = `${uuidv4()}-${file.name}`;
    const existingProfileImage = userDetails?.authUser?.profilePicture;

    try {
      if (existingProfileImage) {
        const res = await reUpload({
          name: fileName,
          size: file.size,
          _id: existingProfileImage._id,
        }).unwrap();
        const url = res.url;
        const conduitFile = res.file;
        await uploadBlob(url, file);
        await setProfilePicture({
          profilePicture: conduitFile._id,
        }).unwrap();
      } else {
        const res = await upload({
          name: fileName,
          size: file.size,
        }).unwrap();
        const conduitFile = res.file;
        const url = res.url;
        await uploadBlob(url, file);
        await setProfilePicture({
          profilePicture: conduitFile._id,
        }).unwrap();
      }
    } catch (e) {
      console.log(e);
      presentError(t('profile.errorUploadingProfile'));
    }
  };

  const uploadBlob = async (url: string, file: File) => {
    return await fetch(url, {
      method: 'PUT',
      body: file,
      headers: {
        'x-ms-blob-type': 'BlockBlob',
        'Content-Type': file.type,
      },
    });
  };

  const handleSaveDate = () => {
    setOpen(false);
    setUserDetails(oldValue => ({ ...oldValue, birthDate: userDetails.birthDate }));
  };

  return (
    <PageLayout
      backgroundWhite
      defaultHref="/dashboard/profile"
      headerTitle={t('profile.titles.personalInfo')}
      endButtons={[
        {
          onClick: handleSave,
          disabled: !stateHasChanged,
          icon: <SaveIcon color={theme.palette.kmColorsRed.main} />,
        },
      ]}
    >
      <ComponentsLayout>
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <Box display={'flex'} justifyContent={'center'}>
            <UserParticipantImage
              maxFiles={1}
              onFilesSelected={handleChangeImage}
              photo={userDetails.profilePicture?.url || ''}
              acceptedFiles={{ 'image/*': ['.png', '.jpg', '.jpeg'] }}
            />
          </Box>

          <Box>
            <CustomInput
              fullWidth
              placeholder={'-'}
              label={`${t('userDetails.amka')}`}
              value={userDetails.socialSecurityNumber}
              handleChange={value => handleChange('socialSecurityNumber', value)}
            />
          </Box>

          <Box>
            <CustomInput
              fullWidth
              value={userDetails.firstName}
              label={`${t('userDetails.fName')}`}
              handleChange={value => handleChange('firstName', value)}
            />
          </Box>

          <Box>
            <CustomInput
              fullWidth
              value={userDetails.lastName}
              label={`${t('userDetails.lName')}`}
              handleChange={value => handleChange('lastName', value)}
            />
          </Box>

          <Box>
            <CustomDateInput
              selectedDate={userDetails.birthDate}
              handleCalendarOpen={() => setOpen(true)}
            />
          </Box>

          <Box>
            <DropdownMenu
              placeholder={'-'}
              label={`${t('userDetails.sex')}`}
              value={userDetails.gender || '-'}
              menuItems={[
                { label: t('userDetails.male'), value: 'male' },
                { label: t('userDetails.female'), value: 'female' },
              ]}
              setValue={value => handleChange('gender', value)}
            />
          </Box>

          <Box flex={1} display={'flex'} justifyContent={'space-between'} gap={2}>
            <Box
              width={'48%'}
              display={'flex'}
              flexDirection={'column'}
              boxSizing={'border-box'}
            >
              <CustomInput
                fullWidth
                type={'number'}
                value={userDetails.height}
                suffix={`${t('measurements.cm')}`}
                label={`${t('measurements.heightIn')}`}
                placeholder={`${t('measurements.heightEg')}`}
                handleChange={value => handleChange('height', value)}
              />
            </Box>

            <Box
              width={'48%'}
              display={'flex'}
              flexDirection={'column'}
              boxSizing={'border-box'}
            >
              <CustomInput
                fullWidth
                type={'number'}
                value={userDetails.weight}
                suffix={`${t('measurements.kg')}`}
                label={`${t('measurements.weightIn')}`}
                placeholder={`${t('measurements.weightEg')}`}
                handleChange={value => handleChange('weight', value)}
              />
            </Box>
          </Box>

          <Box>
            <DropdownMenu
              placeholder={'-'}
              label={`${t('userDetails.smoker')}`}
              value={userDetails.smoker ? 'yes' : 'no'}
              menuItems={[
                { label: t('userDetails.no'), value: 'no' },
                { label: t('userDetails.yes'), value: 'yes' },
              ]}
              setValue={value => handleChange('smoker', value === 'yes')}
            />
          </Box>
          <Box>
            <DropdownMenu
              placeholder={'-'}
              value={userDetails.diabetes || '-'}
              label={`${t('userDetails.diabetes')}`}
              menuItems={useConvertJSON(diabeticOptions)}
              setValue={value => handleChange('diabetes', value)}
            />
          </Box>

          <Box px={2}>
            <CheckBoxes
              label={`${t('userDetails.hypertension')}`}
              checked={userDetails.hypertension || false}
              setChecked={value => handleChange('hypertension', value)}
            />
            <CheckBoxes
              label={`${t('userDetails.dyslipidemia')}`}
              checked={userDetails.dyslipidemia || false}
              setChecked={value => handleChange('dyslipidemia', value)}
            />
            <CheckBoxes
              label={`${t('userDetails.medicated')}`}
              checked={userDetails.underMedication || false}
              setChecked={value => handleChange('underMedication', value)}
            />
          </Box>
        </Box>
      </ComponentsLayout>
      <DatePicker
        open={open}
        setOpen={setOpen}
        handleSaveDate={handleSaveDate}
        selectedDate={userDetails.birthDate}
        setSelectedDate={value => handleChange('birthDate', value)}
      />
    </PageLayout>
  );
};

export default PersonalInfo;
