import * as React from 'react';
import { SVGProps } from 'react';

const FantasticEmojiOutlined = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={30}
    height={30}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 14C11.6569 14 13 12.6569 13 11C13 9.34315 11.6569 8 10 8C8.34315 8 7 9.34315 7 11C7 12.6569 8.34315 14 10 14Z"
      fill="#212121"
    />
    <path
      d="M20 14C21.6569 14 23 12.6569 23 11C23 9.34315 21.6569 8 20 8C18.3431 8 17 9.34315 17 11C17 12.6569 18.3431 14 20 14Z"
      fill="#212121"
    />
    <path
      d="M10.8001 17.4001C10.4687 16.9582 9.84189 16.8687 9.40006 17.2001C8.95823 17.5314 8.86869 18.1582 9.20006 18.6001C9.89069 19.5209 11.8883 21.0001 15.0001 21.0001C18.1118 21.0001 20.1094 19.5209 20.8001 18.6001C21.1314 18.1582 21.0419 17.5314 20.6001 17.2001C20.1582 16.8687 19.5314 16.9582 19.2001 17.4001C18.8907 17.8125 17.4883 19.0001 15.0001 19.0001C12.5118 19.0001 11.1094 17.8125 10.8001 17.4001Z"
      fill="#212121"
    />
    <path
      d="M3.76342 4.42276C6.313 1.65419 10.0952 0 14.9989 0C19.9026 0 23.6848 1.65419 26.2343 4.42276C28.7715 7.17785 29.9978 10.9378 29.9978 14.9989C29.9978 19.06 28.7715 22.8199 26.2343 25.575C23.6848 28.3436 19.9026 29.9978 14.9989 29.9978C10.0952 29.9978 6.313 28.3436 3.76342 25.575C1.22626 22.8199 0 19.06 0 14.9989C0 10.9378 1.22626 7.17785 3.76342 4.42276ZM5.23462 5.77759C3.10595 8.08911 2 11.3286 2 14.9989C2 18.6691 3.10595 21.9087 5.23462 24.2202C7.35088 26.5182 10.5681 27.9978 14.9989 27.9978C19.4296 27.9978 22.6469 26.5182 24.7631 24.2202C26.8918 21.9087 27.9978 18.6691 27.9978 14.9989C27.9978 11.3286 26.8918 8.08911 24.7631 5.77759C22.6469 3.47956 19.4296 2 14.9989 2C10.5681 2 7.35088 3.47956 5.23462 5.77759Z"
      fill="#212121"
    />
  </svg>
);

export default FantasticEmojiOutlined;
