import * as React from 'react';
import { SVGProps } from 'react';

const ArrowLeft = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.5502 2.35765C10.0805 1.88624 10.1522 1.04549 9.71025 0.479786C9.26829 -0.0859146 8.48009 -0.162346 7.94975 0.30907L0.449768 6.97571C0.164778 7.22903 0 7.6043 0 8C0 8.3957 0.164778 8.77097 0.449768 9.02429L7.94975 15.6909C8.48009 16.1623 9.26829 16.0859 9.71025 15.5202C10.1522 14.9545 10.0805 14.1138 9.5502 13.6423L3.20255 8L9.5502 2.35765Z"
        fill={props?.color ? props.color : '#222222'}
      />
    </svg>
  );
};

export default ArrowLeft;
