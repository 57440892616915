import React, { FC } from 'react';
import { visuallyHidden } from '@mui/utils';
import {
  Box,
  BoxProps,
  styled,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
  TableSortLabel,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ArrowUp from '../../assets/ArrowUp';
import ArrowDown from '../../assets/ArrowDown';

const TableCellHeadings = styled(TableCell)<TableCellProps & { web: boolean }>(
  ({ web, theme }) => ({
    width: '20%',
    flexGrow: '1',
    height: '32px',
    boxSizing: 'border-box',
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.kmColorsCoolGrey.main
        : theme.palette.primary.light,
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.secondary.main
        : theme.palette.secondary.light,
    borderBottomColor: theme.palette.background.default,
    padding: web ? theme.spacing(0, 2) : theme.spacing(0, 0.5),
    '.Mui-active': {
      color: theme.palette.primary.light,
    },
    '&:first-of-type': {
      borderTopLeftRadius: '8px',
      padding: web ? theme.spacing(0, 2) : theme.spacing(0, 0.5, 0, 1),
    },
    '&:last-of-type': {
      borderTopRightRadius: '8px',
      padding: web ? theme.spacing(0, 2) : theme.spacing(0, 1, 0, 0.5),
    },
  }),
);

const MarginBox = styled(Box)<BoxProps>(({ theme }) => ({
  margin: theme.spacing(0, 0, 0, 0.75),
}));

const SortOrderDesc = () => (
  <MarginBox>
    <ArrowDown />
  </MarginBox>
);

const SortOrderAsc = () => (
  <MarginBox>
    <ArrowUp />
  </MarginBox>
);

type Order = 'asc' | 'desc';

type HealthProfTableHeadingProps = {
  order?: Order;
  orderBy?: string;
  headCells: {
    _id: string;
    label: string;
    align?: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
  }[];
  onRequestSort?: (event: React.MouseEvent<unknown>, property: string) => void;
};
const ESHSortTableHeading: FC<HealthProfTableHeadingProps> = ({
  order,
  orderBy,
  headCells,
  onRequestSort,
}) => {
  const theme = useTheme();
  const web = useMediaQuery(theme.breakpoints.up('sm'));

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    if (onRequestSort) onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCellHeadings
            web={web}
            key={headCell._id}
            align={headCell.align}
            sortDirection={orderBy === headCell._id ? order : false}
          >
            {onRequestSort ? (
              <TableSortLabel
                active={orderBy === headCell._id}
                direction={orderBy === headCell._id ? order : 'asc'}
                onClick={createSortHandler(headCell._id)}
                IconComponent={
                  orderBy === headCell._id
                    ? order === 'asc'
                      ? SortOrderAsc
                      : SortOrderDesc
                    : SortOrderDesc
                }
              >
                {headCell.label}
                {orderBy === headCell._id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCellHeadings>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default ESHSortTableHeading;
