import {
  ModalDeactivateAccount,
  SettingsDeactivatePanelMobile,
} from '@hdcorner/ui-library';
import { useIonRouter } from '@ionic/react';
import { useState } from 'react';
import useAlert from '../../hooks/useAlert';
import PageLayout from '../../components/PageLayout';
import {
  useDeleteAuthUserMutation,
  useDeleteUserDataMutation,
  useUpgradeTokenMutation,
} from './queries/accountSettingsQueries';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../redux/hooks';
import { logout } from '../authentication/authSlice';

const AccountSettingsDeactivate = () => {
  const dispatch = useAppDispatch();
  const { presentError } = useAlert();
  const router = useIonRouter();
  const { t } = useTranslation();

  const [open, setOpen] = useState<boolean>(false);

  const [deleteUser] = useDeleteAuthUserMutation();
  const [upgradeToken] = useUpgradeTokenMutation();
  const [deleteUserData] = useDeleteUserDataMutation();

  const handleContinue = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleDeactivate = async () => {
    try {
      await upgradeToken();
      await deleteUserData();
      await deleteUser();
      setOpen(false);
      dispatch(logout());
      router.push('/', 'root', 'replace');
    } catch (e) {
      console.log(e);
      presentError(t('errors.profile.errorDeactivate'));
    }
  };

  return (
    <PageLayout
      backgroundWhite
      defaultHref={'/dashboard/profile-settings'}
      headerTitle={t('profile.titles.deactivate')}
    >
      <SettingsDeactivatePanelMobile handleContinue={handleContinue} />
      <ModalDeactivateAccount
        open={open}
        setOpen={setOpen}
        handleCancel={handleCancel}
        handleDeactivate={handleDeactivate}
      />
    </PageLayout>
  );
};

export default AccountSettingsDeactivate;
