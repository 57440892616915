import * as React from 'react';
import { SVGProps } from 'react';

const LanguageIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="32" height="32" rx="4" fill="#F2F1F3" />
    <g clipPath="url(#clip0_4574_5370)">
      <path
        d="M16 8.66602C14.5496 8.66602 13.1318 9.09611 11.9258 9.9019C10.7199 10.7077 9.77995 11.853 9.22491 13.193C8.66986 14.533 8.52464 16.0075 8.8076 17.43C9.09056 18.8525 9.78899 20.1592 10.8146 21.1848C11.8402 22.2104 13.1468 22.9088 14.5694 23.1918C15.9919 23.4747 17.4664 23.3295 18.8064 22.7745C20.1464 22.2194 21.2917 21.2795 22.0975 20.0735C22.9033 18.8676 23.3334 17.4497 23.3334 15.9993C23.3311 14.0551 22.5577 12.1912 21.1829 10.8164C19.8082 9.44166 17.9442 8.66831 16 8.66602V8.66602ZM10.0394 16.666H12.018C12.0568 18.2869 12.4688 19.877 13.222 21.3127C12.3523 20.8571 11.6074 20.1953 11.0526 19.3853C10.4978 18.5753 10.1499 17.6416 10.0394 16.666V16.666ZM12.018 15.3327H10.0394C10.1499 14.3571 10.4978 13.4234 11.0526 12.6134C11.6074 11.8034 12.3523 11.1416 13.222 10.686C12.4688 12.1217 12.0568 13.7118 12.018 15.3327V15.3327ZM15.3334 21.7767C14.3714 21.1527 13.4834 19.2473 13.3507 16.666H15.3334V21.7767ZM15.3334 15.3327H13.3507C13.4834 12.7513 14.3714 10.846 15.3334 10.222V15.3327ZM16.6667 21.7767V16.666H18.6494C18.5167 19.2473 17.6287 21.1527 16.6667 21.7767ZM16.6667 15.3327V10.222C17.6287 10.846 18.5167 12.7513 18.6494 15.3327H16.6667ZM18.778 21.3127C19.5313 19.877 19.9433 18.2869 19.982 16.666H21.9607C21.8502 17.6416 21.5023 18.5753 20.9474 19.3853C20.3926 20.1953 19.6478 20.8571 18.778 21.3127V21.3127ZM19.982 15.3327C19.9433 13.7118 19.5313 12.1217 18.778 10.686C19.6478 11.1416 20.3926 11.8034 20.9474 12.6134C21.5023 13.4234 21.8502 14.3571 21.9607 15.3327H19.982Z"
        fill="#4A4F55"
      />
    </g>
    <defs>
      <clipPath id="clip0_4574_5370">
        <rect width="16" height="16" fill="white" transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
);

export default LanguageIcon;
