import React, { FC } from 'react';
import { Box, styled, Switch, SwitchProps } from '@mui/material';

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color:
        theme.palette.mode === 'dark'
          ? theme.palette.kmColorsTurquoise.main
          : theme.palette.secondary.light,
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor:
          theme.palette.mode === 'dark'
            ? theme.palette.background.default
            : theme.palette.kmColorsRed.main,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: 12,
    height: 12,
    borderRadius: 6,
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    borderRadius: 16 / 2,
    boxSizing: 'border-box',
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.secondary.dark
        : theme.palette.kmColorsCoolGrey.main,
  },
}));

type Props = {
  checked?: boolean;
  setChecked: (checked: boolean) => void;
};
const SwitchButton: FC<Props & SwitchProps> = ({ checked, setChecked, ...rest }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  return (
    <Box>
      <CustomSwitch {...rest} checked={checked} onChange={handleChange} />
    </Box>
  );
};

export default SwitchButton;
