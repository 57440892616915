import { Box, BoxProps, styled, Typography, TypographyProps } from '@mui/material';
import React, { FC } from 'react';

const OuterBox = styled(Box, {
  shouldForwardProp: prop => prop !== 'noAlignment',
})<BoxProps & { noAlignment?: boolean }>(({ noAlignment }) => ({
  ...(!noAlignment
    ? {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
      }
    : null),
}));

const InnerBox = styled(Box, {
  shouldForwardProp: prop => prop !== 'noAlignment',
})<BoxProps & { noAlignment?: boolean }>(({ noAlignment }) => ({
  ...(!noAlignment
    ? {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
      }
    : null),
}));

const Title = styled(Typography, {
  shouldForwardProp: prop => prop !== 'avgColor',
})<TypographyProps & { avgColor?: string }>(({ theme, avgColor }) => ({
  display: 'flex',
  alignItems: 'center',
  fontWeight: theme.typography.fontWeightRegular,
  color: avgColor ? avgColor : theme.palette.kmColorsDarkGrey.main,
}));

const Number = styled(Typography, {
  shouldForwardProp: prop => prop !== 'avgColor',
})<TypographyProps<'span', { component: 'span' }> & { avgColor?: string }>(
  ({ theme, avgColor }) => ({
    fontWeight: theme.typography.fontWeightBold,
    color: avgColor ? avgColor : theme.palette.kmColorsDarkGrey.main,
    marginLeft: theme.spacing(0.5),
  }),
);

type MinMaxAvgChartCompType = {
  isHorizontal?: boolean;
  titleOne?: string;
  titleTwo?: string;
  titleThree?: string;
  numberOne: number | string;
  numberTwo: number | string;
  numberThree: number | string;
  spacing?: number;
  avgColor?: string;
  header?: string;
};
const ChartNumbersInfo: FC<MinMaxAvgChartCompType & BoxProps> = ({
  isHorizontal = false,
  titleOne = 'MAX',
  titleTwo = 'MIN',
  titleThree = 'AVG',
  numberOne,
  numberTwo,
  numberThree,
  spacing = 3,
  avgColor,
  header,
  ...boxProps
}) => {
  return (
    <Box
      display={'flex'}
      gap={isHorizontal ? spacing : 0}
      flexDirection={isHorizontal ? 'row' : 'column'}
      {...boxProps}
    >
      {header && (
        <OuterBox noAlignment={isHorizontal}>
          <InnerBox>
            <Title variant={isHorizontal ? 'body1' : 'caption'}>{header}</Title>
          </InnerBox>
        </OuterBox>
      )}
      <OuterBox noAlignment={isHorizontal}>
        <InnerBox noAlignment={isHorizontal}>
          <Title variant={isHorizontal ? 'body1' : 'caption'}>
            {titleOne}{' '}
            <Number variant={isHorizontal ? 'subtitle2' : 'body1'} component={'span'}>
              {numberOne}
            </Number>
          </Title>
        </InnerBox>
      </OuterBox>
      <OuterBox noAlignment={isHorizontal}>
        <InnerBox noAlignment={isHorizontal}>
          <Title variant={isHorizontal ? 'body1' : 'caption'}>
            {titleTwo}{' '}
            <Number variant={isHorizontal ? 'subtitle2' : 'body1'} component={'span'}>
              {numberTwo}
            </Number>
          </Title>
        </InnerBox>
      </OuterBox>
      <OuterBox noAlignment={isHorizontal}>
        <InnerBox noAlignment={isHorizontal}>
          <Title variant={isHorizontal ? 'body1' : 'caption'} avgColor={avgColor}>
            {titleThree}{' '}
            <Number
              component={'span'}
              avgColor={avgColor}
              variant={isHorizontal ? 'subtitle2' : 'body1'}
            >
              {numberThree}
            </Number>
          </Title>
        </InnerBox>
      </OuterBox>
    </Box>
  );
};

export default ChartNumbersInfo;
