import * as React from 'react';
import { SVGProps } from 'react';

const PersonHealthProfIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={13} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.333 13h5.334a.667.667 0 1 0 0-1.333h-4.62a4.674 4.674 0 0 1 4.62-4 3.363 3.363 0 1 0-2.252-.892 6.007 6.007 0 0 0-3.748 5.558.667.667 0 0 0 .666.667ZM6.667 2.333a2 2 0 1 1 0 4 2 2 0 0 1 0-4Zm8.666 7.334a.667.667 0 0 1-.666.666H10A.667.667 0 0 1 10 9h4.667a.667.667 0 0 1 .666.667Z"
      fill="#222"
    />
  </svg>
);

export default PersonHealthProfIcon;
