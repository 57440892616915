import { alpha, Box, Card, CardProps, styled, Typography, useTheme } from '@mui/material';
import React, { FC, useMemo } from 'react';
import LineChart from '../charts/LineChart';
import ToggleButtonsChart from '../charts/ToggleButtonsChart';
import { ChartDataType } from '../../types/ChartDataType';
import FeelsEmpty from '../FeelsEmpty';
import { useTranslation } from 'react-i18next';

const CardMain = styled(Card)<CardProps>(({ theme }) => ({
  flex: 1,
  display: 'flex',
  boxShadow: 'none',
  overflow: 'hidden',
  borderRadius: '8px',
  position: 'relative',
  flexDirection: 'column',
  padding: theme.spacing(2, 2.5),
}));

const GoalsStartEnd: FC<{ goalStart: boolean; goalEnd: boolean }> = ({
  goalEnd,
  goalStart,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const data = useMemo(() => {
    const data = [];

    if (goalStart) {
      data.push({
        label: t('goals.goalStart'),
        color: alpha(theme.palette.error.main, 0.45),
      });
    }

    if (goalEnd) {
      data.push({
        label: t('goals.goalEnd'),
        color: alpha(theme.palette.success.main, 0.45),
      });
    }

    return data;
  }, [goalEnd, goalStart]);

  return useMemo(() => {
    if (data.length === 0) return null;

    return (
      <Box display={'flex'} alignItems={'center'} marginTop={theme.spacing(3)}>
        {data.map((item, index) => (
          <Box
            key={index}
            display={'flex'}
            alignItems={'center'}
            marginRight={theme.spacing(1)}
          >
            <Box
              width={8}
              height={8}
              bgcolor={item.color}
              borderRadius={'99px'}
              marginRight={theme.spacing(1)}
            />
            <Typography
              variant={'body1'}
              sx={{
                color: theme.palette.primary.main,
                fontWeight: theme.typography.fontWeightRegular,
              }}
            >
              {item.label}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  }, [data]);
};

type LipidChartCardType = {
  title?: number;
  toggleButtonsValue: string;
  chartData?: ChartDataType[];
  headings: { label: string; value: string }[];
  values?: { label: string; value: number | string }[];
  goalData?: {
    goal?: number;
    startingMeasurement?: number;
  };
  handleChangeOption: (newValue: string) => void;
};
const LipidChartCard: FC<LipidChartCardType> = ({
  title,
  values,
  headings,
  goalData,
  chartData,
  toggleButtonsValue,
  handleChangeOption,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const yAnnotations = useMemo(() => {
    const annotations = [];

    if (goalData?.goal) {
      annotations.push({
        solid: true,
        fillColor: alpha(theme.palette.success.main, 0.45),
        from: goalData.goal,
      });

      if (goalData?.startingMeasurement) {
        annotations.push({
          solid: true,
          fillColor: alpha(theme.palette.error.main, 0.45),
          from: goalData.startingMeasurement,
        });
      }
    }

    return annotations;
  }, [goalData]);

  const isEmpty = useMemo(() => {
    if (!chartData || chartData.length === 0) return true;

    if (chartData.length > 0) {
      if ('data' in chartData[0]) {
        return chartData.every((item: any) => item.data.length === 0);
      }

      return false;
    }

    return false;
  }, [chartData]);

  return (
    <CardMain raised={false}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(1) }}>
        {values && (
          <ToggleButtonsChart
            values={values}
            headings={headings}
            toggleButtonsValue={toggleButtonsValue}
            handleChangeOption={handleChangeOption}
          />
        )}
        <Box display={'flex'} flexDirection={'column'} flex={1}>
          <GoalsStartEnd
            goalEnd={!!goalData?.goal}
            goalStart={!!goalData?.startingMeasurement}
          />
          {title && (
            <Box
              gap={1}
              display={'flex'}
              alignItems={'center'}
              marginTop={theme.spacing(3)}
            >
              <Typography
                variant={'subtitle1'}
                fontWeight={'fontWeightRegular'}
                color={theme.palette.primary.light}
              >
                {t('goals.latest')}
              </Typography>
              <Typography variant={'h1'} fontWeight={'fontWeightMedium'}>
                {title}
              </Typography>
              <Typography variant={'subtitle1'} fontWeight={'fontWeightRegular'}>
                {'mg/dL'}
              </Typography>
            </Box>
          )}
        </Box>
        {isEmpty && <FeelsEmpty />}
        {!isEmpty && (
          <LineChart
            showLegend={!title}
            chartData={chartData || []}
            yAnnotations={yAnnotations}
          />
        )}
      </Box>
    </CardMain>
  );
};

export default LipidChartCard;
