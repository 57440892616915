import React, { FC } from 'react';
import { Box } from '@mui/material';
import { CustomButton, CustomInput } from '@hdcorner/ui-library';

export type AccountInfoFieldsProps = {
  type: string;
  value: string;
  button: string;
  heading: string;
  onClick: () => void;
};

const AccountInfoFields: FC<AccountInfoFieldsProps> = ({
  type,
  value,
  button,
  heading,
  onClick,
}) => {
  return (
    <Box display={'flex'} alignItems={'end'} gap={3} justifyContent={'start'}>
      <Box flex={1}>
        <CustomInput readOnly fullWidth type={type} value={value} label={heading} />
      </Box>
      <Box>
        <CustomButton
          fullWidth
          onClick={onClick}
          variant={'contained'}
          sx={{ width: 180 }}
        >
          {button}
        </CustomButton>
      </Box>
    </Box>
  );
};

export default AccountInfoFields;
