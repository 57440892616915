import { Box, useTheme } from '@mui/material';
import { FC } from 'react';
import AccountSQIcon from '../assets/AccountSQIcon';
import LanguageIcon from '../assets/LaunguageIcon';
import LogoutSQIcon from '../assets/LogoutSQIcon';
import ProfileSQIcon from '../assets/ProfileSQIcon';
import RecordsSQIcon from '../assets/RecordsSQIcon';
import ComponentsLayout from './ComponentsLayout';
import ClickNavCardProfile from './cards/ClickNavCardProfile';
import ProfileDetailsCard from './cards/ProfileDetailsCard';
import AboutUsSQIcon from '../assets/AboutUsSQIcon';
import { SubscriptionSQIcon } from '../assets/SubscriptionSQIcon';
import QrCodeIcon from '../assets/QrCodeIcon';
import { useTranslation } from 'react-i18next';

type MobileProfileProps = {
  id?: string;
  healthProf: boolean;
  data: {
    name: string;
    image: string;
    plan?: string;
    joined: string;
    verified?: boolean;
  };
  handlePageRouting: (route: string) => void;
};
const MobileProfile: FC<MobileProfileProps> = ({
  id,
  data,
  healthProf,
  handlePageRouting,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const userAppMenu = (
    <>
      <ClickNavCardProfile
        icon={<SubscriptionSQIcon />}
        headings={t('subscription.titles.sub')}
        onClickHandler={() => handlePageRouting('subscription')}
      />

      <ClickNavCardProfile
        icon={<RecordsSQIcon />}
        headings={t('records.labels.myRec')}
        onClickHandler={() => handlePageRouting('records')}
      />
    </>
  );

  return (
    <ComponentsLayout>
      <Box minWidth={'308px'} margin={theme.spacing(0, 'auto')}>
        <ProfileDetailsCard
          id={id}
          plan={data.plan}
          name={data.name}
          image={data.image}
          joined={data.joined}
          profApp={healthProf}
          verified={data.verified}
        />
      </Box>

      <ClickNavCardProfile
        icon={<AccountSQIcon />}
        headings={t('headingsTitles.accSettings')}
        onClickHandler={() => handlePageRouting('profile-settings')}
      />

      <ClickNavCardProfile
        icon={<ProfileSQIcon />}
        headings={t('profile.titles.personalInfo')}
        onClickHandler={() => handlePageRouting('personal-info')}
      />

      {!healthProf && userAppMenu}

      {healthProf && (
        <ClickNavCardProfile
          icon={<QrCodeIcon />}
          headings={t('profile.titles.codes')}
          onClickHandler={() => handlePageRouting('codes-activity')}
        />
      )}

      {/*{!healthProf && (*/}
      {/*  <ClickNavCardProfile*/}
      {/*    headings={'Units'}*/}
      {/*    icon={<UnitsSQIcon />}*/}
      {/*    onClickHandler={() => handlePageRouting('units')}*/}
      {/*  />*/}
      {/*)}*/}

      <ClickNavCardProfile
        icon={<LanguageIcon />}
        headings={t('profile.titles.language')}
        onClickHandler={() => handlePageRouting('language')}
      />

      <ClickNavCardProfile
        icon={<AboutUsSQIcon />}
        headings={t('aboutUs.pageTitle')}
        onClickHandler={() => handlePageRouting('about-us')}
      />

      <ClickNavCardProfile
        arrowDisplay
        icon={<LogoutSQIcon />}
        headings={t('profile.titles.logout')}
        onClickHandler={() => handlePageRouting('sign-in')}
      />
    </ComponentsLayout>
  );
};

export default MobileProfile;
