import { useIonRouter } from '@ionic/react';
import { Box, useMediaQuery } from '@mui/material';
import {
  ComponentsLayout,
  BarStepper,
  theme,
  QuestionnaireAnswer,
} from '@hdcorner/ui-library';
import React, { useState } from 'react';
import Questions0of4 from './components/Questions0of4';
import QuestionnaireHeading from './components/QuestionnaireHeading';
import QuestionnaireEndButtons from './components/QuestionnaireEndButtons';
import HeroImage from '../../../assets/illustrations/HeroImagePNG.png';
import LogoHeartPNG from '../../../assets/illustrations/LogoHeartPNG.png';
import PageLayout from '../../../components/PageLayout';
import { useSetHealthQuestionnaireMutation } from '../queries/onBoardingQueries';
import QuestionnaireMultipleChoice from './components/QuestionnaireMultipleChoice';
import { useAppDispatch } from '../../../redux/hooks';
import { setQuestionnaireAnswer } from '../authSlice';
import thinking from './components/thinkingicon.png';
import useAlert from '../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

const OnBoardingQuestionnaire = () => {
  const router = useIonRouter();
  const dispatch = useAppDispatch();

  const { presentError } = useAlert();

  const { t } = useTranslation();

  const stepOne: QuestionnaireAnswer[] = [
    {
      value: '1',
      outcome: 'dominant',
      answer: t('authentication.onboarding.domAnswer'),
      resultType: t('authentication.onboarding.domResultType'),
    },
    {
      value: '2',
      outcome: 'communicative',
      answer: t('authentication.onboarding.comAnswer'),
      resultType: t('authentication.onboarding.comResultType'),
    },
    {
      value: '3',
      outcome: 'stable',
      answer: t('authentication.onboarding.stableAnswer'),
      resultType: t('authentication.onboarding.stablemResultType'),
    },
    {
      value: '4',
      outcome: 'cautious',
      answer: t('authentication.onboarding.cautiousAnswer'),
      resultType: t('authentication.onboarding.cautiousResultType'),
    },
  ];

  const web = useMediaQuery(theme.breakpoints.up('md'));
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const [count, setCount] = useState(0);

  const [questionnaire] = useSetHealthQuestionnaireMutation();

  const sendUserQuestionnaire = async (value: QuestionnaireAnswer) => {
    try {
      await questionnaire({
        answer: value.answer,
        outcome: value.outcome,
        resultType: value.resultType,
      }).unwrap();
      dispatch(setQuestionnaireAnswer(value));
      router.push('/dashboard/home');
      setCount(0);
    } catch (e) {
      console.log(e);
      setCount(0);
      presentError(t('authentication.onboarding.error2'));
    }
  };

  const incrementCount = (value: QuestionnaireAnswer) => {
    if (count === 0) {
      setCount(count + 1);
    } else {
      sendUserQuestionnaire(value);
    }
  };

  const decrementCount = () => {
    setCount(count - 1);
  };

  return (
    <PageLayout backgroundWhite>
      <Box hidden={mobile} height={'100%'} display={'flex'}>
        <Box
          display={'flex'}
          maxWidth={'560px'}
          overflow={'scroll'}
          textAlign={'center'}
          flexDirection={'column'}
          boxSizing={'border-box'}
          alignContent={'space-between'}
          justifyContent={'space-between'}
          bgcolor={theme.palette.secondary.light}
          padding={theme.spacing(1, 13)}
          sx={{
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            '::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          <Box>
            <Box>
              <img
                width={'120px'}
                height={'120px'}
                src={LogoHeartPNG}
                alt={'company logo'}
              />
            </Box>
            <QuestionnaireHeading web count={count} />
            {count === 0 && <Questions0of4 incrementCount={incrementCount} web />}
            {count === 1 && (
              <QuestionnaireMultipleChoice
                choices={stepOne}
                handleNextPage={incrementCount}
                heading={t('authentication.onboarding.requestQuestionnaire')}
              />
            )}
            <img src={thinking} width={200} height={200} alt={'thinking icon'} />
          </Box>
          <Box>
            <QuestionnaireEndButtons web count={count} decrementCount={decrementCount} />
          </Box>
        </Box>
        <Box
          flexGrow={'1'}
          sx={{
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${HeroImage})`,
          }}
        ></Box>
      </Box>
      <Box hidden={web}>
        <BarStepper value={100} />
        <ComponentsLayout height={'100%'}>
          <Box
            maxWidth={'350px'}
            textAlign={'center'}
            margin={theme.spacing(4.25, 'auto', 0, 'auto')}
          >
            <QuestionnaireHeading
              web={false}
              count={count}
              decrementCount={decrementCount}
            />
            {count === 0 && <Questions0of4 incrementCount={incrementCount} web={false} />}
            {count === 1 && (
              <QuestionnaireMultipleChoice
                choices={stepOne}
                handleNextPage={incrementCount}
                heading={t('authentication.onboarding.requestQuestionnaire')}
              />
            )}
            <Box height={'100%'}>
              <img src={thinking} width={200} height={200} alt={'thinking icon'} />
              <QuestionnaireEndButtons
                web={false}
                count={count}
                decrementCount={decrementCount}
              />
            </Box>
          </Box>
        </ComponentsLayout>
      </Box>
    </PageLayout>
  );
};

export default OnBoardingQuestionnaire;
