import React, { FC } from 'react';
import { Box } from '@mui/material';
import { ModalMobile, SettingsPhonePanelWeb } from '@hdcorner/ui-library';
import { useUpdatePhoneNumberMutation } from '../queries/accountSettingsQueries';
import useAlert from '../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

export type ModalChangePhoneProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const ModalChangePhone: FC<ModalChangePhoneProps> = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const { presentSuccess, presentError } = useAlert();

  const [updatePhoneNumber] = useUpdatePhoneNumberMutation();

  const handleNext = (newPhone: string) => {
    updatePhoneNumber({
      phoneNumber: newPhone,
    })
      .then(() => {
        presentSuccess(t('errors.profile.successUpdatePhone'));
        setOpen(false);
      })
      .catch(() => presentError(t('errors.profile.errorUpdatePhone')));
  };

  return (
    <ModalMobile open={open} height={'auto'} setOpen={setOpen} width={'512px'}>
      <Box padding={2} boxSizing={'border-box'}>
        <SettingsPhonePanelWeb handleNext={handleNext} />
      </Box>
    </ModalMobile>
  );
};

export default ModalChangePhone;
