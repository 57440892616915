import * as React from 'react';
import { FC } from 'react';
import { Box, BoxProps, styled, Typography, useTheme } from '@mui/material';
import SwitchButton from '../controls/SwitchButton';

const CustomBox = styled(Box, {
  shouldForwardProp: prop => prop !== 'whiteBackground',
})<BoxProps & { whiteBackground?: boolean }>(({ theme, whiteBackground }) => ({
  display: 'flex',
  borderRadius: '8px',
  alignItems: 'center',
  boxSizing: 'border-box',
  gap: theme.spacing(2),
  justifyContent: 'space-between',
  padding: theme.spacing(1, 2),
  background: whiteBackground ? theme.palette.secondary.light : 'none',
  backgroundColor: whiteBackground ? theme.palette.secondary.light : 'none',
}));

type SwitchButtonProps = {
  label: string;
  checked: boolean;
  whiteBackground?: boolean;
  setChecked: (checked: boolean) => void;
};
const SwitchCard: FC<SwitchButtonProps> = ({
  label,
  checked,
  setChecked,
  whiteBackground,
}) => {
  const theme = useTheme();

  return (
    <CustomBox whiteBackground={whiteBackground}>
      <Typography
        variant={'subtitle2'}
        sx={{
          color: theme.palette.primary.main,
          fontWeight: theme.typography.fontWeightRegular,
        }}
      >
        {label}
      </Typography>
      <SwitchButton checked={checked} setChecked={setChecked} />
    </CustomBox>
  );
};

export default SwitchCard;
