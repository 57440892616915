import * as React from 'react';
import { SVGProps } from 'react';

const ColumnsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 4C10 3.44772 10.4477 3 11 3H13C13.5523 3 14 3.44772 14 4V20C14 20.5523 13.5523 21 13 21H11C10.4477 21 10 20.5523 10 20V4Z"
      fill={props?.color ?? '#222222'}
    />
    <path
      d="M17 10C17 9.44771 17.4477 9 18 9H20C20.5523 9 21 9.44772 21 10V20C21 20.5523 20.5523 21 20 21H18C17.4477 21 17 20.5523 17 20V10Z"
      fill={props?.color ?? '#222222'}
    />
    <path
      d="M4 11C3.44772 11 3 11.4477 3 12V20C3 20.5523 3.44772 21 4 21H6C6.55228 21 7 20.5523 7 20V12C7 11.4477 6.55228 11 6 11H4Z"
      fill={props?.color ?? '#222222'}
    />
  </svg>
);

export default ColumnsIcon;
