import { FC, useMemo, useState } from 'react';
import { CustomButton, CustomInput, ModalMobile, theme } from '@hdcorner/ui-library';
import { useAppSelector } from '../../../../redux/hooks';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import {
  useGetUserGoalActivityQuery,
  usePatchUserGoalActivityMutation,
  usePostUserGoalActivityMutation,
} from '../queries/activitiesQueries';
import useAlert from '../../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};
const ModalActivityGoal: FC<Props> = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const { presentSuccess, presentError } = useAlert();

  const {
    data: { user },
  } = useAppSelector(state => state.auth);

  const { data: getGoalRes } = useGetUserGoalActivityQuery();

  const [postActivityGoal] = usePostUserGoalActivityMutation();
  const [patchActivityGoal] = usePatchUserGoalActivityMutation();

  const goalData = useMemo(() => {
    if (!getGoalRes || getGoalRes.length === 0) return 0;

    return getGoalRes[0].goal.duration;
  }, [getGoalRes]);

  const [goal, setGoal] = useState({
    duration: goalData,
  });

  const handleChange = (fieldName: string, value: any) => {
    setGoal(oldValue => ({
      ...oldValue,
      [fieldName]: value,
    }));
  };

  const handleAddGoal = () => {
    postActivityGoal({
      goal: {
        duration: goal.duration || 0,
      },
      goalType: 'exercise',
      userId: user ? user.authUser._id : '',
      startDate: moment().startOf('day').toISOString(),
    })
      .unwrap()
      .then(() => {
        setOpen(false);
        presentSuccess(t('errors.fitness.exercise.successActivityAddGoal'));
      })
      .catch(() => {
        presentError(t('errors.fitness.exercise.errorActivityAddGoal'));
      });
  };

  const handleEditGoal = () => {
    if (!getGoalRes || getGoalRes.length === 0) return;

    patchActivityGoal({
      goalType: 'exercise',
      _id: getGoalRes[0]._id,
      goal: { duration: goal.duration },
      startDate: getGoalRes[0].startDate,
      userId: user ? user.authUser._id : '',
    })
      .unwrap()
      .then(() => {
        setOpen(false);
        presentSuccess(t('errors.fitness.exercise.successActivityEditGoal'));
      })
      .catch(() => {
        presentError(t('errors.fitness.exercise.errorActivityEditGoal'));
      });
  };

  return (
    <ModalMobile open={open} setOpen={setOpen}>
      <Box
        gap={3}
        width={'100%'}
        display={'flex'}
        padding={2}
        boxSizing={'border-box'}
        flexDirection={'column'}
      >
        <Typography
          variant={'subtitle1'}
          sx={{
            fontWeight: theme.typography.fontWeightMedium,
          }}
        >
          {!getGoalRes || getGoalRes.length === 0
            ? t('fitness.exercise.labels.addGoal')
            : t('fitness.exercise.labels.editGoal')}
        </Typography>
        <CustomInput
          fullWidth
          type={'number'}
          value={goal.duration}
          placeholder={`${t('measurements.minEg')}`}
          label={`${t('fitness.exercise.labels.duration')}`}
          handleChange={value => handleChange('duration', value)}
        />
        <CustomButton
          fullWidth
          color={'primary'}
          variant={'contained'}
          children={t('buttons.save')}
          disabled={goal.duration === undefined}
          onClick={
            !getGoalRes || getGoalRes.length === 0 ? handleAddGoal : handleEditGoal
          }
        />
      </Box>
    </ModalMobile>
  );
};

export default ModalActivityGoal;
