import api from '../../../redux/api';
import { Popup } from '@hdcorner/ui-library';

const popupQueries = api.injectEndpoints({
  endpoints: build => ({
    getPopup: build.query<
      { messages: Popup[] },
      { lang: string; category: string; personality?: string }
    >({
      query: ({ lang, category, personality }) => ({
        url: '/hdCornerService/user/info-message',
        method: 'GET',
        params: {
          lang,
          category,
          personality,
          skip: 0,
          limit: 100,
          sort: ['-createdAt'],
        },
      }),
    }),
  }),
});

export const { useLazyGetPopupQuery } = popupQueries;

export default popupQueries;
