import React, { FC, useState } from 'react';
import {
  AppBar,
  AppBarProps,
  Avatar,
  AvatarProps,
  Badge,
  BadgeProps,
  Box,
  IconButton,
  IconButtonProps,
  SvgIcon,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import LogoutIcon from '../../assets/LogoutIcon';
import PersonIcon from '../../assets/PersonIcon';
import { alpha, styled } from '@mui/material/styles';
import { Notification } from '../../types/Notification';
import NotificationsDialog from '../NotificationsDialog';
import DropdownTrigger from '../controls/DropdownTrigger';
import ExclamationIcon from '../../assets/ExclamationIcon';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MessageBubbleIcon from '../../assets/MessageBubbleIcon';
import AppBarExpandedIcon from '../../assets/AppBarExpandedIcon';
import AppBarExpandMoreIcon from '../../assets/AppBarExpandMoreIcon';
import { TypographyProps } from '@mui/material/Typography/Typography';
import AppBarExpandedDarkIcon from '../../assets/AppBarExpandedDarkIcon';
import AppBarExpandMoreDarkIcon from '../../assets/AppBarExpandMoreDarkIcon';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import VerifiedIcon from '../../assets/VerifiedIcon';
import { useTranslation } from 'react-i18next';

const AppBarMain = styled(AppBar)<AppBarProps>(({ theme }) => ({
  justifyContent: 'center',
  height: 80,
  padding: theme.spacing(0, 3),
  borderLeft: `1px solid ${theme.palette.secondary.main}`,
  background:
    theme.palette.mode === 'dark'
      ? theme.palette.secondary.main
      : theme.palette.secondary.light,
}));

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  lineHeight: '24px',
  color: theme.palette.primary.main,
  marginLeft: theme.spacing(2),
  fontWeight: theme.typography.fontWeightBold,
}));

const AvatarMain = styled(Avatar)<AvatarProps>(() => ({
  width: 24,
  border: 0,
  height: 24,
  cursor: 'pointer',
}));

const IconButtonMain = styled(IconButton, {
  shouldForwardProp: prop => prop !== 'isSelected',
})<IconButtonProps & { isSelected: boolean }>(({ theme, isSelected }) => ({
  borderRadius: '8px',
  background: isSelected
    ? theme.palette.mode === 'dark'
      ? alpha(theme.palette.kmColorsTurquoise.main, 0.3)
      : theme.palette.info.main
    : undefined,
  padding: theme.spacing(1),
  marginRight: theme.spacing(1),
  color: isSelected
    ? theme.palette.mode === 'dark'
      ? theme.palette.kmColorsTurquoise.main
      : theme.palette.kmColorsRed.main
    : theme.palette.primary.main,
  ':hover': {
    color:
      theme.palette.mode === 'dark'
        ? theme.palette.kmColorsTurquoise.main
        : theme.palette.kmColorsRed.main,
  },
}));

const BadgeMain = styled(Badge)<BadgeProps>(({ theme }) => ({
  '.MuiBadge-badge': {
    margin: theme.spacing(0.15, 0.25, 0, 0),
    border: `1px solid ${
      theme.palette.mode === 'dark'
        ? theme.palette.secondary.main
        : theme.palette.secondary.light
    }`,
    height: 10,
    width: 10,
    borderRadius: 30,
    background:
      theme.palette.mode === 'dark'
        ? theme.palette.kmColorsTurquoise.main
        : theme.palette.kmColorsRed.main,
  },
}));

type HeaderBarType = {
  hcpApp?: boolean;
  currentScreen: string;
  shrunkSideBar: boolean;
  unreadMessages: number;
  unreadNotifications: number;
  notifications?: Notification[];
  user: { username: string; avatarSrc: string; verified?: boolean };
  handleShrinkBar: () => void;
  handleClickLogout: () => void;
  handleClickAboutUs?: () => void;
  handleClickMessages: () => void;
  handleClickAccountSettings: () => void;
  onNotificationClick: (id: string) => void;
};
const HeaderBar: FC<HeaderBarType> = ({
  user,
  hcpApp,
  notifications,
  shrunkSideBar,
  currentScreen,
  handleShrinkBar,
  handleClickLogout,
  handleClickAboutUs,
  unreadNotifications,
  unreadMessages,
  onNotificationClick,
  handleClickMessages,
  handleClickAccountSettings,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [notificationsOpen, setNotificationsOpen] = useState<boolean>(false);

  const handleClickNotifications = () => {
    setNotificationsOpen(!notificationsOpen);
  };

  const handleIcon = () => {
    return shrunkSideBar ? (
      theme.palette.mode === 'dark' ? (
        <AppBarExpandedDarkIcon />
      ) : (
        <AppBarExpandedIcon />
      )
    ) : theme.palette.mode === 'dark' ? (
      <AppBarExpandMoreDarkIcon />
    ) : (
      <AppBarExpandMoreIcon />
    );
  };

  const aboutUsObj = {
    text: t('aboutUs.pageTitle'),
    icon: (
      <ExclamationIcon
        color={
          theme.palette.mode === 'dark'
            ? theme.palette.primary.light
            : theme.palette.primary.main
        }
        strokeWidth={2}
      />
    ),
    onClick: handleClickAboutUs ? handleClickAboutUs : () => undefined,
  };

  const optionsArray = [
    {
      text: t('profile.titles.profileSettings'),
      icon: (
        <PersonIcon
          fill={
            theme.palette.mode === 'dark'
              ? theme.palette.primary.light
              : theme.palette.primary.main
          }
          strokeWidth={2}
        />
      ),
      onClick: handleClickAccountSettings,
    },
    {
      text: t('profile.titles.logout'),
      icon: (
        <LogoutIcon
          width={24}
          fill={
            theme.palette.mode === 'dark'
              ? theme.palette.primary.light
              : theme.palette.primary.main
          }
        />
      ),
      onClick: handleClickLogout,
    },
  ];

  const allOptionsArray = [
    ...optionsArray.slice(0, 1),
    aboutUsObj,
    ...optionsArray.slice(1),
  ];

  return (
    <AppBarMain position={'static'}>
      <Toolbar disableGutters>
        <Box display={'flex'} flex={1} alignItems={'center'}>
          <Box display={'flex'} flex={1} justifyContent={'flex-start'}>
            <IconButton sx={{ padding: 0 }} onClick={() => handleShrinkBar()}>
              {handleIcon()}
            </IconButton>
            <Box display={'flex'} gap={1} alignItems={'center'}>
              <Title fontSize={24}>
                {currentScreen
                  ? currentScreen
                  : ` ${t('dashboard.banner.welcome')}, ${user?.username}!`}
              </Title>
              {hcpApp && user.verified && <VerifiedIcon />}
            </Box>
          </Box>
          <Box
            flex={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-end'}
          >
            <IconButtonMain
              size="large"
              disableRipple
              isSelected={notificationsOpen}
              onClick={handleClickNotifications}
            >
              <BadgeMain
                color={'error'}
                variant={'dot'}
                overlap={'circular'}
                invisible={unreadNotifications === 0}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <NotificationsNoneOutlinedIcon htmlColor={theme.palette.primary.main} />
              </BadgeMain>
            </IconButtonMain>
            <IconButtonMain
              disableRipple
              size={'large'}
              onClick={handleClickMessages}
              isSelected={currentScreen === 'Chat'}
            >
              <BadgeMain
                color={'error'}
                variant={'dot'}
                overlap={'circular'}
                invisible={unreadMessages === 0}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <SvgIcon
                  width={24}
                  height={24}
                  inheritViewBox
                  fill={'inherit'}
                  component={MessageBubbleIcon}
                />
              </BadgeMain>
            </IconButtonMain>
            <DropdownTrigger
              menuProps={{ sx: { top: theme.spacing(3) } }}
              startIcon={<AvatarMain src={user?.avatarSrc} />}
              buttonProps={{
                sx: {
                  width: 60,
                  minWidth: 60,
                  borderRadius: 2,
                  background:
                    currentScreen === 'Profile Settings'
                      ? theme.palette.mode === 'dark'
                        ? alpha(theme.palette.kmColorsTurquoise.main, 0.3)
                        : theme.palette.info.main
                      : undefined,
                  '.MuiButton-startIcon': {
                    border: `1px solid ${
                      currentScreen === 'Profile Settings'
                        ? theme.palette.mode === 'dark'
                          ? theme.palette.kmColorsTurquoise.main
                          : theme.palette.kmColorsRed.main
                        : theme.palette.mode === 'dark'
                        ? theme.palette.secondary.main
                        : theme.palette.secondary.light
                    }`,
                    borderRadius: 80,
                    margin: theme.spacing(0),
                  },
                  '.MuiButton-endIcon': {
                    width: 12,
                    margin: theme.spacing(0),
                    color:
                      currentScreen === 'Profile Settings'
                        ? theme.palette.mode === 'dark'
                          ? theme.palette.kmColorsTurquoise.main
                          : theme.palette.kmColorsRed.main
                        : undefined,
                  },
                  ':hover': {
                    background:
                      theme.palette.mode === 'dark'
                        ? alpha(theme.palette.kmColorsTurquoise.main, 0.3)
                        : theme.palette.info.main,
                    '.MuiButton-startIcon': {
                      border: `1px solid ${
                        theme.palette.mode === 'dark'
                          ? theme.palette.kmColorsTurquoise.main
                          : theme.palette.kmColorsRed.main
                      }`,
                      borderRadius: 80,
                    },
                    '.MuiButton-endIcon': {
                      color:
                        theme.palette.mode === 'dark'
                          ? theme.palette.kmColorsTurquoise.main
                          : theme.palette.kmColorsRed.main,
                    },
                  },
                },
              }}
              icon={<ExpandMoreIcon width={10} height={6} />}
              options={handleClickAboutUs ? allOptionsArray : optionsArray}
            />
          </Box>
          {notifications && (
            <NotificationsDialog
              open={notificationsOpen}
              notifications={notifications}
              setOpen={setNotificationsOpen}
              onNotificationClick={onNotificationClick}
            />
          )}
        </Box>
      </Toolbar>
    </AppBarMain>
  );
};

export default HeaderBar;
