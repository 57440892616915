import moment from 'moment/moment';
import { useIonRouter } from '@ionic/react';
import React, { useEffect, useMemo, useState } from 'react';
import SectionHeading from '../../components/SectionHeading';
import { Box, BoxProps, styled, useMediaQuery } from '@mui/material';
import ModalRemoveGoal from '../../../../components/ModalRemoveGoal';
import GoalModalButtons from '../../../../components/GoalModalButtons';
import { GoalDetailsCard, ModalMobile, theme } from '@hdcorner/ui-library';
import {
  useDeleteUserGoalActivityMutation,
  useGetTodaysActivityLogsQuery,
  useGetUserGoalActivityQuery,
} from '../queries/activitiesQueries';
import ModalActivityGoal from './ModalActivityGoal';
import { calculateLogs } from '../utils/calculateLogs';
import useAlert from '../../../../hooks/useAlert';
import { incrementalProgress } from '../../../../utils/goalProgress';
import { useTranslation } from 'react-i18next';

const BoxInner = styled(Box, { shouldForwardProp: propName => propName !== 'web' })<
  BoxProps & { web: boolean }
>(({ web, theme }) => ({
  display: 'flex',
  borderRadius: '8px',
  flexDirection: 'column',
  boxSizing: 'border-box',
  gap: web ? 0 : theme.spacing(1),
  padding: theme.spacing(web ? 1 : 0),
  backgroundColor: web ? theme.palette.secondary.light : 'transparent',
}));

const SectionGoalActivities = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { presentError, presentSuccess } = useAlert();
  const web = useMediaQuery(theme.breakpoints.up('md'));

  const [open, setOpen] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);

  const [deleteActivityGoal] = useDeleteUserGoalActivityMutation();

  const { data: getGoalRes, error: errorGoal } = useGetUserGoalActivityQuery();
  const { data: logsData, error: errorLogs } = useGetTodaysActivityLogsQuery();

  useEffect(() => {
    if (errorGoal) {
      presentError(
        t('errors.fitness.exercise.errorActivityFetchLog.errorActivityFetchGoal'),
      );
    }
    if (errorLogs) {
      presentError(t('errors.fitness.exercise.errorActivityFetchLog'));
    }
  }, [errorGoal, errorLogs]);

  const lastLog = useMemo(() => {
    if (!logsData || logsData.documents.length === 0) return '-';
    return logsData.documents[0].duration.toString();
  }, [logsData]);

  const allLogs = useMemo(() => {
    if (!logsData) return 0;
    return calculateLogs(logsData, 'duration');
  }, [logsData]);

  const goalData = useMemo(() => {
    if (!getGoalRes || getGoalRes.length === 0)
      return [
        {
          label: t('headingsTitles.goal'),
          value: `- ${t('measurements.minutes')}`,
        },
        {
          label: t('goals.start'),
          value: '-',
        },
        {
          label: t('goals.latestMeasurement'),
          value: `- ${t('measurements.minutes')}`,
        },
        {
          label: t('goals.progress'),
          value: `-`,
        },
      ];

    const progress = incrementalProgress(allLogs, getGoalRes[0].goal.duration);

    return [
      {
        label: t('headingsTitles.goal'),
        value: `${getGoalRes[0].goal.duration} ${t('measurements.minutes')}`,
      },
      {
        label: t('goals.start'),
        value: moment(getGoalRes[0].startDate).format('MMMM Do, YYYY'),
      },
      {
        label: t('goals.latestMeasurement'),
        value: `${lastLog} ${t('measurements.minutes')}`,
      },
      {
        label: t('goals.progress'),
        value: `${progress}%`,
      },
    ];
  }, [allLogs, getGoalRes, lastLog]);

  const handleRemoveGoal = () => {
    setOpenDelete(true);
  };

  const handleAddEditGoal = () => {
    setOpen(true);
  };

  const handleDeleteGoal = () => {
    if (!getGoalRes || getGoalRes.length === 0) return;
    const goal = getGoalRes[0];
    if (getGoalRes) {
      deleteActivityGoal({ _id: goal._id ? goal._id : '' })
        .unwrap()
        .then(() => {
          setOpenDelete(false);
          router.push('/dashboard/fitness/exercise', 'back', 'pop');
          presentSuccess(t('errors.fitness.exercise.successActivityDeleteGoal'));
        })
        .catch(e => {
          console.log(e);
          setOpenDelete(false);
          presentError(t('errors.fitness.exercise.errorActivityDeleteGoal'));
        });
    }
  };

  return (
    <Box gap={1} display={'flex'} width={'100%'} flexDirection={'column'}>
      <Box display={web ? 'block' : 'none'}>
        <SectionHeading
          displayGoalButtons
          handleAdd={handleAddEditGoal}
          handleEdit={handleAddEditGoal}
          handleRemove={handleRemoveGoal}
          heading={t('headingsTitles.goal')}
          hasGoal={!!(getGoalRes && getGoalRes.length > 0)}
        />
      </Box>
      <BoxInner web={web}>
        {goalData.map((it, index) => (
          <Box key={`${it}${index}`}>
            <GoalDetailsCard goal={it} />
          </Box>
        ))}
        <Box
          mt={2}
          flex={1}
          width={'100%'}
          display={web ? 'none' : 'flex'}
          justifyContent={'space-between'}
        >
          <GoalModalButtons
            handleFirst={handleRemoveGoal}
            handleSecond={handleAddEditGoal}
          />
        </Box>
      </BoxInner>
      <ModalActivityGoal open={open} setOpen={setOpen} />
      <ModalMobile open={openDelete} setOpen={setOpenDelete}>
        <ModalRemoveGoal
          handleRemoveClick={handleDeleteGoal}
          handleCancelClick={() => setOpenDelete(false)}
          heading={`${t('fitness.exercise.labels.activity')}`}
        />
      </ModalMobile>
    </Box>
  );
};

export default SectionGoalActivities;
