import {
  ChatRoomType,
  HCPUser,
  PaginationResult,
  UploadResponse,
  UserRecord,
} from '@hdcorner/ui-library';
import api from '../../../redux/api';

const recordsQueries = api
  .enhanceEndpoints({ addTagTypes: ['Records'] })
  .injectEndpoints({
    endpoints: build => ({
      createUserRecord: build.mutation<
        { uploadUrl: string },
        Omit<UserRecord, '_id' | 'createdAt' | 'file'>
      >({
        query: body => ({
          method: 'POST',
          url: '/hdCornerService/user/record',
          body: { ...body, folder: 'hdcorner' },
        }),
        invalidatesTags: ['Records'],
      }),
      deleteUserRecord: build.mutation<void, { recordId: string }>({
        query: ({ recordId }) => ({
          method: 'DELETE',
          url: '/hdCornerService/user/record/' + recordId,
        }),
        invalidatesTags: ['Records'],
      }),
      getUserRecords: build.query<
        UserRecord[],
        { fileType?: string; category?: string; searchText?: string } | void
      >({
        query: params => ({
          method: 'GET',
          params: { ...params, populate: 'file' },
          url: '/database/function/getUserRecords',
        }),
        providesTags: ['Records'],
      }),
      uploadFile: build.mutation<UploadResponse, { name: string; size: number }>({
        query: ({ name, size }) => ({
          method: 'POST',
          url: '/storage/upload',
          body: { name, size, container: 'hdcorner', isPublic: true },
        }),
      }),
      reUploadFile: build.mutation<
        UploadResponse,
        { _id: string; name: string; size: number }
      >({
        query: ({ _id, name, size }) => ({
          method: 'PATCH',
          body: { name, size },
          url: '/storage/upload/' + _id,
        }),
      }),
      shareFiles: build.mutation<
        void,
        { recordIds: string[]; hcpId?: string; groupChatRoom?: string }
      >({
        query: ({ recordIds, hcpId, groupChatRoom }) => ({
          method: 'PATCH',
          url: '/hdCornerService/user/record/share',
          params: { recordIds, hcpId, groupChatRoom },
        }),
      }),
      getFileUrl: build.query<{ result: string }, { fileId: string }>({
        query: ({ fileId }) => ({
          method: 'GET',
          url: '/storage/getFileUrl/' + fileId,
        }),
      }),
      getHealthProfessionals: build.query<
        { _id: string; profilePicture: string; name: string }[],
        void
      >({
        query: () => ({
          method: 'GET',
          url: '/database/function/getConnectedHealthProfessionals',
          params: {
            skip: 0,
            limit: 1000,
            populate: [
              'user',
              'healthProfessional',
              'user.profilePicture',
              'healthProfessionalData',
              'healthProfessional.profilePicture',
            ],
          },
        }),
        transformResponse(baseQueryReturnValue: PaginationResult<HCPUser>): any {
          return baseQueryReturnValue.documents.map(connected => {
            return {
              _id: connected.healthProfessional._id,
              profilePicture: connected.healthProfessional.profilePicture?.url,
              name:
                connected.healthProfessional.firstName +
                ' ' +
                connected.healthProfessional.lastName,
              clinicalCode: connected.healthProfessionalData.clinicalCoach,
            };
          });
        },
      }),
      getGroupChats: build.query<{ _id: string; participants: string }[], void>({
        query: () => ({
          method: 'GET',
          url: '/hdCornerService/user/chat/rooms',
          params: {
            skip: 0,
            limit: 1000,
            roomType: 'mixed',
          },
        }),
        transformResponse(baseQueryReturnValue: {
          rooms: ChatRoomType[];
          count: number;
        }): Promise<any> | any {
          return baseQueryReturnValue.rooms
            .filter(room => room?.participants && room.participants.length > 1)
            .map(room => {
              return {
                _id: room._id,
                participants: room.participants
                  ?.map(participant => {
                    return participant.firstName + ' ' + participant.lastName;
                  })
                  .join(', '),
              };
            });
        },
      }),
    }),
  });

export const {
  useGetFileUrlQuery,
  useGetGroupChatsQuery,
  useUploadFileMutation,
  useShareFilesMutation,
  useGetUserRecordsQuery,
  useReUploadFileMutation,
  useCreateUserRecordMutation,
  useDeleteUserRecordMutation,
  useGetHealthProfessionalsQuery,
} = recordsQueries;

export default recordsQueries;
