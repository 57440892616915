import { Dispatch, FC, SetStateAction } from 'react';
import { Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { CustomButton } from '@hdcorner/ui-library';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

type MedicationDeleteModalProps = {
  deleteAll: () => void;
  deleteEntry: () => void;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
};

const MedicationDeleteModal: FC<MedicationDeleteModalProps> = ({
  setModalOpen,
  deleteAll,
  deleteEntry,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const handleDeleteAll = () => {
    deleteAll();
    setModalOpen(false);
  };

  const handleDeleteEntry = () => {
    deleteEntry();
    setModalOpen(false);
  };

  return (
    <Box display={'flex'} padding={2} flexDirection={'column'} gap={theme.spacing(3)}>
      <Typography
        variant={'subtitle1'}
        color={theme.palette.primary.main}
        fontWeight={theme.typography.fontWeightMedium}
      >
        {t('medication.labels.deleteMeds')}
      </Typography>
      <Box display={'flex'} flexDirection={'column'} gap={theme.spacing(1)}>
        <Typography
          variant={'body1'}
          color={theme.palette.primary.main}
          fontWeight={theme.typography.fontWeightRegular}
        >
          {t('buttons.deleteEntry')}
        </Typography>
        <Typography
          variant={'body1'}
          color={theme.palette.primary.light}
          fontWeight={theme.typography.subtitle1.fontWeight}
        >
          {t('medication.labels.deleteSpecificEntry')}
        </Typography>
        <Typography
          variant={'body1'}
          color={theme.palette.primary.main}
          fontWeight={theme.typography.fontWeightRegular}
        >
          {t('buttons.deleteAll')}
        </Typography>
        <Typography
          variant={'body1'}
          color={theme.palette.primary.light}
          fontWeight={theme.typography.subtitle1.fontWeight}
        >
          {t('medication.labels.deleteAll4Specific')}
        </Typography>
      </Box>
      <Box display={'flex'} gap={theme.spacing(3)}>
        <CustomButton
          fullWidth
          disableElevation
          variant={'contained'}
          onClick={handleDeleteAll}
          sx={{
            height: '48px',
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.dark,
            '&:hover,&:focus': {
              backgroundColor: theme.palette.secondary.dark,
            },
          }}
        >
          {t('buttons.deleteAll')}
        </CustomButton>
        <CustomButton
          fullWidth
          color={'error'}
          disableElevation
          variant={'contained'}
          onClick={handleDeleteEntry}
          sx={{
            height: '48px',
            '&:hover,&:focus': {
              backgroundColor: theme.palette.error.main,
            },
          }}
        >
          {t('buttons.deleteEntry')}
        </CustomButton>
      </Box>
    </Box>
  );
};

export default MedicationDeleteModal;
