import { Box, Divider, DividerProps, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { FC, useState } from 'react';
import CheckBoxesSingle from '../controls/CheckBoxesSingle';
import CustomButton from '../controls/CustomButton';
import CustomInput from '../controls/CustomInput';
import ModalHeading from './ModalHeading';
import CalendarModal from './CalendarModal';
import moment from 'moment';
import { CvdRiskLog } from '../../types/CvdRiskLog';
import { useTranslation } from 'react-i18next';

const DividerMain = styled(Divider)<DividerProps>(({ theme }) => ({
  borderColor: theme.palette.primary.main,
}));

type ModalHeartRiskProps = {
  userAge: number;
  userGender: string;
  userSmoker: boolean;
  handleModalSaveClick: (data: Partial<CvdRiskLog>) => void;
};
const ModalHeartRisk: FC<ModalHeartRiskProps> = ({
  userAge,
  userSmoker,
  userGender,
  handleModalSaveClick,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [gender, setGender] = useState<string>(userGender);
  const [sys, setSys] = useState<string>('');
  const [smoker, setSmoker] = useState<boolean>(userSmoker);
  const [age, setAge] = useState<string>(userAge.toString());
  const [totalChol, setTotalChol] = useState<string>('');
  const [logDate, setLogDate] = useState<string>(moment().toISOString());
  const [openPicker, setOpenPicker] = useState<boolean>(false);
  const [errorSystolic, setErrorSystolic] = useState({ value: false, message: '' });
  const [errorTotalChol, setErrorTotalChol] = useState({ value: false, message: '' });

  const handleCheckInputs = () => {
    const systolicValue = !(parseInt(sys) > 250 || parseInt(sys) < 80);
    const totalCholValue = !(parseInt(totalChol) > 320 || parseInt(totalChol) < 130);

    if (!totalCholValue) {
      setErrorTotalChol({
        value: true,
        message: t('general.text.validInput', { value: '130-320' }),
      });
    } else {
      setErrorTotalChol({ value: false, message: '' });
    }

    if (!systolicValue) {
      setErrorSystolic({
        value: true,
        message: t('general.text.validInput', { value: '80-250' }),
      });
    } else {
      setErrorSystolic({ value: false, message: '' });
    }

    if (systolicValue && totalCholValue) {
      handleSaveData();
    }
  };

  const handleSaveData = () => {
    const data: Partial<CvdRiskLog> = {
      logDate,
      totalChol: parseFloat(totalChol),
      sysBloodPressure: parseFloat(sys),
    };
    handleModalSaveClick(data);
  };

  const handleCalendarClick = () => {
    setOpenPicker(true);
  };

  return (
    <Box gap={3} display={'flex'} flexDirection={'column'} padding={2}>
      <ModalHeading
        align={'left'}
        showCalendar={'flex'}
        title={t('cvd.titles.heartRisk')}
        color={theme.palette.cvdRisk.main}
        handleCalendarClick={handleCalendarClick}
        headingButton={`${t('constants.toggleValues.dates.customDate')}`}
      />
      <Box gap={3} display={'flex'} justifyContent={'space-between'}>
        <CustomInput
          readOnly
          fullWidth
          value={age}
          handleChange={setAge}
          placeholder={'40-79'}
          label={`${t('userDetails.age')}`}
        />
        <CustomInput
          readOnly
          fullWidth
          value={gender}
          handleChange={setGender}
          label={`${t('userDetails.sex')}`}
          placeholder={`${t('userDetails.female')}`}
        />
      </Box>

      <CheckBoxesSingle
        disabled
        size={'mobile'}
        checked={smoker}
        setChecked={setSmoker}
        label={`${t('userDetails.smoker')}`}
      />

      <DividerMain variant={'middle'} />

      <Box gap={3} display={'flex'} justifyContent={'space-between'}>
        <CustomInput
          value={sys}
          type={'number'}
          handleChange={setSys}
          placeholder={'80-250'}
          error={errorSystolic.value}
          helperText={errorSystolic.message}
          suffix={`${t('measurements.mmGH')}`}
          label={`${t('hypertension.labels.sysPressure')}`}
        />

        <CustomInput
          type={'number'}
          value={totalChol}
          placeholder={'130-320'}
          handleChange={setTotalChol}
          error={errorTotalChol.value}
          helperText={errorTotalChol.message}
          suffix={`${t('measurements.mgdl')}`}
          label={`${t('lipid.labels.totalChol')}`}
        />
      </Box>

      <CustomButton
        fullWidth
        color={'primary'}
        variant={'contained'}
        onClick={handleCheckInputs}
        children={t('buttons.save')}
        disabled={totalChol === '' || sys === ''}
      />

      <CalendarModal
        open={openPicker}
        selectedDate={logDate}
        setOpen={setOpenPicker}
        saveDate={logDate => setLogDate(logDate)}
      />
    </Box>
  );
};

export default ModalHeartRisk;
