export enum ExerciseBodyPart {
  ARMS = 'arms',
  LEGS = 'legs',
  TORSO = 'torso',
  WHOLE = 'whole_body',
}

export const exerciseBodyPartLabel = (part: ExerciseBodyPart) => {
  switch (part) {
    case ExerciseBodyPart.TORSO:
      return 'constants.workouts.torso';
    case ExerciseBodyPart.LEGS:
      return 'constants.workouts.legs';
    case ExerciseBodyPart.ARMS:
      return 'constants.workouts.arms';
    case ExerciseBodyPart.WHOLE:
      return 'constants.workouts.wholeBody';
  }
};

export const exampleExercise = (bodyPart: ExerciseBodyPart) => {
  switch (bodyPart) {
    case ExerciseBodyPart.WHOLE:
      return [
        'constants.workouts.exercises.whole01',
        'constants.workouts.exercises.whole02',
        'constants.workouts.exercises.whole03',
        'constants.workouts.exercises.whole04',
      ];
    case ExerciseBodyPart.ARMS:
      return [
        'constants.workouts.exercises.arms01',
        'constants.workouts.exercises.arms02',
        'constants.workouts.exercises.arms03',
        'constants.workouts.exercises.arms04',
      ];
    case ExerciseBodyPart.TORSO:
      return [
        'constants.workouts.exercises.torso01',
        'constants.workouts.exercises.torso02',
        'constants.workouts.exercises.torso03',
        'constants.workouts.exercises.torso04',
      ];
    case ExerciseBodyPart.LEGS:
      return [
        'constants.workouts.exercises.legs01',
        'constants.workouts.exercises.legs02',
        'constants.workouts.exercises.legs03',
        'constants.workouts.exercises.legs04',
      ];
  }
};
