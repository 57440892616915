import * as React from 'react';
import { useMemo, useState } from 'react';
import {
  ComponentsLayout,
  CustomButton,
  HorizontalCalendar,
  MedicationRequest,
  ModalMobile,
  theme,
} from '@hdcorner/ui-library';
import { useIonRouter } from '@ionic/react';
import moment from 'moment/moment';
import PageLayout from '../../components/PageLayout';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useCreateNewMedicationMutation } from './queries/medicationQueries';
import { clear } from './medicationSlice';
import { extractRepeat } from './utils/extractRepeat';
import AddMedicationOverview from './components/AddMedicationOverview';
import useAlert from '../../hooks/useAlert';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const AddMedication = () => {
  const { presentError } = useAlert();
  const dispatch = useAppDispatch();
  const router = useIonRouter();
  const { t } = useTranslation();

  const { medicine, frequency, repeat } = useAppSelector(state => state.medication);

  const [createMedication, { isLoading }] = useCreateNewMedicationMutation();

  const [open, setOpen] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>(moment().toDate());
  const [endDate, setEndDate] = useState<Date>(moment().add(1, 'days').toDate());

  const medication = useMemo(() => {
    return {
      medicine: '',
      repeat: extractRepeat(repeat),
      end: moment(endDate).endOf('day').toISOString(),
      start: moment(startDate).startOf('day').toISOString(),
      frequency: frequency.map(f => {
        const hours = f.time.split(':')[0];
        const minutes = f.time.split(':')[1];
        const time = moment()
          .hours(Number(hours))
          .minutes(Number(minutes))
          .seconds(0)
          .milliseconds(0);
        return {
          amount: f.amount,
          time: time.toISOString(),
        };
      }),
    };
  }, [repeat, endDate, startDate, frequency]);

  const handleShowFrequency = () => {
    router.push('/dashboard/medication-frequency');
  };

  const handleShowDuration = () => {
    setOpen(true);
  };

  const handleMedicationNext = async () => {
    if (!medicine) {
      presentError(t('errors.medication.errorPleaseSelect'));
      return;
    }
    const medication: MedicationRequest = {
      repeat: extractRepeat(repeat),
      medicine: medicine._id ? medicine._id : '',
      end: moment(endDate).endOf('day').toISOString(),
      start: moment(startDate).startOf('day').toISOString(),
      frequency: frequency.map(f => {
        const hours = f.time.split(':')[0];
        const minutes = f.time.split(':')[1];
        const time = moment()
          .hours(Number(hours))
          .minutes(Number(minutes))
          .seconds(0)
          .milliseconds(0);
        return {
          amount: f.amount,
          time: time.toISOString(),
        };
      }),
    };

    try {
      await createMedication(medication).unwrap();
      dispatch(clear());
      router.push('/dashboard/medication', 'back', 'pop');
    } catch (err) {
      console.log(err);
      presentError(t('errors.medication.errorAddMed'));
    }
  };

  const handleShowRepeat = () => {
    router.push('/dashboard/medication-repeat');
  };

  const handleChange = (dateRange: Date[]) => {
    setStartDate(dateRange[0]);
    setEndDate(dateRange[1]);
  };

  return (
    <PageLayout
      defaultHref={'/dashboard/medication'}
      headerColor={theme.palette.primary.main}
      headerTitle={t('medication.titles.addMeds')}
    >
      <ComponentsLayout>
        <Box display={'flex'} flexDirection={'column'} gap={theme.spacing(2)}>
          <AddMedicationOverview
            medicine={medicine}
            medication={medication}
            handleShowRepeat={handleShowRepeat}
            handleShowDuration={handleShowDuration}
            handleShowFrequency={handleShowFrequency}
          />
          <CustomButton
            fullWidth
            disableElevation
            color={'primary'}
            disabled={
              !endDate ||
              isLoading ||
              !medicine ||
              !startDate ||
              !repeat?.length ||
              !frequency?.length
            }
            variant={'contained'}
            onClick={handleMedicationNext}
          >
            Create Medication
          </CustomButton>
          {isLoading && (
            <Box
              display={'flex'}
              alignItems={'center'}
              flexDirection={'column'}
              justifyContent={'center'}
            >
              <Typography variant={'subtitle2'}>
                {t('medication.text.creatingMeds')}
              </Typography>
              <CircularProgress />
            </Box>
          )}
        </Box>
      </ComponentsLayout>
      <ModalMobile open={open} setOpen={setOpen}>
        <HorizontalCalendar
          openMonthly
          selectFutureDates
          selectedDate={[startDate, endDate]}
          handleChange={date => handleChange(date as Date[])}
        />
      </ModalMobile>
    </PageLayout>
  );
};

export default AddMedication;
