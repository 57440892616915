import React, { FC, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { CrownSQIcon, PlanBadge, theme } from '@hdcorner/ui-library';
import { useTranslation } from 'react-i18next';
import { Product } from '../types/SubscriptionHistory';

type SubscriptionPlanDetailsProps = {
  web: boolean;
  value: string;
  trial: boolean;
  products?: Product[];
};
const SubscriptionPlanDetails: FC<SubscriptionPlanDetailsProps> = ({
  web,
  trial,
  value,
  products: paymentPlans,
}) => {
  const { t } = useTranslation();

  const paymentPlan = useMemo(() => {
    if (paymentPlans) {
      return paymentPlans.find(choice => choice._id === value);
    }
  }, [value, paymentPlans]);

  const findPaymentAmount = useMemo(() => {
    if (paymentPlan) {
      const vatRate = paymentPlan.vat;
      const priceInCents = paymentPlan.value;
      const finalPriceInCents = priceInCents + Math.round(priceInCents * (vatRate / 100));
      const finalPriceInDollars = finalPriceInCents / 100;
      return finalPriceInDollars.toFixed();
    }
    return 0;
  }, [paymentPlan]);

  const findPaymentLabel = useMemo(() => {
    if (paymentPlan) {
      const name = paymentPlan.name;
      switch (name) {
        case 'APP-HD-001':
          return t('constants.subscription.plans.mon');
        case 'APP-HD-012':
          return t('constants.subscription.plans.annua');
        case 'APP-HD-024':
          return t('constants.subscription.plans.biAnnual');
        default:
          return '-';
      }
    }
  }, [paymentPlan]);

  return (
    <Box
      flexGrow={1}
      boxSizing={'border-box'}
      maxHeight={web ? '68px' : 'none'}
      borderRadius={web ? '8px' : 'none'}
      bgcolor={web ? theme.palette.secondary.light : 'transparent'}
      padding={web ? theme.spacing(1.25, 3) : 0}
    >
      <Box
        flexGrow={1}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={web ? 'center' : 'flex-start'}
        flexDirection={web ? 'row-reverse' : 'row'}
      >
        <Box textAlign={web ? 'right' : 'left'}>
          <Typography variant={'h1'}>
            {findPaymentAmount}€ / {findPaymentLabel}
          </Typography>
          <Typography variant={'caption'}>
            {t('subscription.text.billedAs', {
              timeFrame: findPaymentLabel,
            })}
          </Typography>
        </Box>

        {web ? (
          <Box display={'flex'} alignItems={'center'}>
            <CrownSQIcon
              width={'40px'}
              height={'40px'}
              color={theme.palette.warning.main}
            />
            <Typography variant={'h1'} sx={{ marginLeft: theme.spacing(1) }}>
              {t('subscription.text.premiumSubTrial', {
                trial: trial ? t('subscription.labels.trial') : '',
              })}
            </Typography>
          </Box>
        ) : (
          <PlanBadge premium />
        )}
      </Box>
    </Box>
  );
};

export default SubscriptionPlanDetails;
