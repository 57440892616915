import * as React from 'react';
import { useEffect, useState } from 'react';
import { ComponentsLayout, Goal, ModalMobile, theme } from '@hdcorner/ui-library';
import ModalRemoveGoal from '../../components/ModalRemoveGoal';
import PageLayout from '../../components/PageLayout';
import {
  useDeleteDiabetesGoalMutation,
  useEditDiabetesGoalMutation,
  useGetHbac1CurrentMeasurementQuery,
  useGetHbac1StartingMeasurementQuery,
  useGetHbacGoalQuery,
} from './queries/diabetesQueries';
import { useIonRouter } from '@ionic/react';
import useGoalValue from './hooks/useGoalValue';
import useCurrentMeasurement from './hooks/useCurrentMeasurement';
import ModalAddEditGoal from '../../components/ModalAddEditGoal';
import useHbac1GoalData from './hooks/useHbac1GoalData';
import useAlert from '../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

const DiabetesHbac1Goal = () => {
  const router = useIonRouter();
  const { t } = useTranslation();

  const { presentSuccess, presentError } = useAlert();

  const [startingDate, setStartingDate] = useState<string>('');
  const [openEditGoal, setOpenEditGoal] = useState<boolean>(false);
  const [openRemoveGoal, setOpenRemoveGoal] = useState<boolean>(false);

  const [editGoal] = useEditDiabetesGoalMutation();
  const [deleteGoal] = useDeleteDiabetesGoalMutation();

  const { data: hbac1Goal, error: hbac1GoalError } = useGetHbacGoalQuery();

  const { data: startingMeasurements, error: startingError } =
    useGetHbac1StartingMeasurementQuery(
      { startDate: startingDate },
      { skip: !startingDate },
    );
  const { data: currentMeasurements, error: currentError } =
    useGetHbac1CurrentMeasurementQuery();

  const prepareGoalValue = useGoalValue(hbac1Goal);
  const prepareCurrentMeasurement = useCurrentMeasurement(currentMeasurements);
  const prepareStartingMeasurement = useCurrentMeasurement(startingMeasurements);
  const prepareData = useHbac1GoalData(
    prepareGoalValue,
    hbac1Goal,
    prepareStartingMeasurement,
    prepareCurrentMeasurement,
  );

  useEffect(() => {
    if (hbac1GoalError || startingError || currentError) {
      presentError('An error occurred while fetching HbA1c goal data');
    }
  }, [currentError, hbac1GoalError, startingError]);

  useEffect(() => {
    if (hbac1Goal && hbac1Goal.length > 0) {
      const goal = hbac1Goal[0];
      const start = goal.startDate;
      setStartingDate(start);
    }
  }, [hbac1Goal]);

  const handleClickRemove = () => {
    if (hbac1Goal?.[0]?._id) {
      deleteGoal({ id: hbac1Goal?.[0]?._id })
        .unwrap()
        .then(() => {
          presentSuccess('Goal removed');
          setOpenRemoveGoal(false);
          router.push('/dashboard/diabetes');
        })
        .catch(e => {
          setOpenRemoveGoal(false);
          presentError('Something gone wrong');
        });
    }
  };

  const handleRemove = () => {
    setOpenRemoveGoal(true);
  };

  const handleEdit = () => {
    setOpenEditGoal(true);
  };

  const handleSaveClick = (newGoal: string) => {
    if (hbac1Goal && hbac1Goal.length > 0) {
      editGoal({
        goalType: 'hbac1',
        _id: hbac1Goal[0]._id,
        goal: parseInt(newGoal),
        startDate: hbac1Goal[0].createdAt,
      })
        .unwrap()
        .then(() => {
          presentSuccess(t('errors.diabetes.successHba1c.updateGoal'));
          setOpenEditGoal(false);
        })
        .catch(e => {
          presentError(t('errors.diabetes.errorsHba1c.updateGoal'));
          setOpenEditGoal(false);
        });
    } else {
      setOpenEditGoal(false);
    }
  };

  return (
    <PageLayout
      headerTitle={t('headingsTitles.hba1cGoal')}
      headerColor={theme.palette.primary.main}
    >
      <ComponentsLayout display={'flex'} flexDirection={'column'} gap={theme.spacing(2)}>
        <Goal data={prepareData} handleRemove={handleRemove} handleEdit={handleEdit} />
      </ComponentsLayout>
      <ModalMobile open={openRemoveGoal} setOpen={setOpenRemoveGoal}>
        <ModalRemoveGoal
          handleRemoveClick={handleClickRemove}
          handleCancelClick={() => setOpenRemoveGoal(false)}
        />
      </ModalMobile>
      <ModalMobile open={openEditGoal} setOpen={setOpenEditGoal}>
        <ModalAddEditGoal
          goalType={'hba1c'}
          goal={prepareGoalValue}
          desiredPlaceholder={'5.7%'}
          handleSaveClick={handleSaveClick}
          currentMeasurement={prepareCurrentMeasurement}
        />
      </ModalMobile>
    </PageLayout>
  );
};

export default DiabetesHbac1Goal;
