import { Box } from '@mui/material';
import React, { FC, useState } from 'react';
import CustomInput from '../controls/CustomInput';
import CustomButton from '../controls/CustomButton';
import { useTranslation } from 'react-i18next';

type SignUpFormProps = {
  loading: boolean;
  onInputClick?: () => void;
  handleSignUp: (data: { email: string; password: string }) => void;
};
const SignUpForm: FC<SignUpFormProps> = ({ loading, onInputClick, handleSignUp }) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState<string>('');
  const [password1, setPassword1] = useState<string>('');
  const [password2, setPassword2] = useState<string>('');
  const [passMatch, setPassMatch] = useState({ value: false, message: '' });

  const handleFormSaveClick = () => {
    const password = password1;
    const data = { email, password };
    if (password1 !== password2) {
      setPassMatch({
        value: true,
        message: t('authentication.signUp.text.passMatch'),
      });
    } else {
      setPassMatch({
        value: false,
        message: '',
      });
      handleSignUp(data);
    }
  };

  //check for valid email and password
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const disabledSignUp = !email || !password1 || !password2 || !emailRegex.test(email);

  return (
    <Box
      gap={3}
      display={'flex'}
      margin={'0 auto'}
      maxWidth={'350px'}
      flexDirection={'column'}
    >
      <CustomInput
        required
        fullWidth
        value={email}
        type={'email'}
        onClick={onInputClick}
        handleChange={setEmail}
        label={t('personalDetails.email')}
      />
      <CustomInput
        required
        fullWidth
        passwordStrength
        type={'password'}
        value={password1}
        handleChange={setPassword1}
        label={t('authentication.forgotReset.labels.newPass')}
      />
      <CustomInput
        required
        fullWidth
        value={password2}
        type={'password'}
        error={passMatch.value}
        handleChange={setPassword2}
        helperText={passMatch.message}
        label={t('authentication.forgotReset.labels.reenterPass')}
      />
      <CustomButton
        fullWidth
        variant={'contained'}
        onClick={handleFormSaveClick}
        disabled={disabledSignUp || loading}
      >
        {t('buttons.signUp')}
      </CustomButton>
    </Box>
  );
};

export default SignUpForm;
