const subscriptionBenefits = [
  {
    benefit: 'constants.subscription.benefits.ben1',
    plan: ['premium', 'basic'],
  },
  {
    benefit: 'constants.subscription.benefits.ben2',
    plan: ['premium'],
  },
  {
    benefit: 'constants.subscription.benefits.ben3',
    plan: ['premium'],
  },
];

const paymentPlans = [
  {
    number: 8,
    amount: '8.00',
    value: '655a4e2d6f477563e3ca4b04',
    productId: '655a4e2d6f477563e3ca4b04',
    label: 'constants.subscription.plans.mon',
  },
  {
    number: 50,
    amount: '50.00',
    value: '655a4dda6f477563e3ca4afe',
    productId: '655a4dda6f477563e3ca4afe',
    label: 'constants.subscription.plans.annua',
  },
  {
    number: 100,
    amount: '100.00',
    value: '655a4e086f477563e3ca4b01',
    productId: '655a4e086f477563e3ca4b01',
    label: 'constants.subscription.plans.biAnnual',
  },
];

export { subscriptionBenefits };
