import React, { FC, useState } from 'react';
import { Box } from '@mui/material';
import ComponentsLayout from './ComponentsLayout';
import CustomInput from './controls/CustomInput';
import CustomButton from './controls/CustomButton';
import { useTranslation } from 'react-i18next';

type PasswordProps = {
  handleNext: (currentPassword: string, newPassword: string) => void;
};
const SettingsPassPanelMobile: FC<PasswordProps> = ({ handleNext }) => {
  const { t } = useTranslation();

  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');

  const passwordValidation = () => {
    if (!password1 || !password2 || !currentPassword) {
      return false;
    }
    return password1 === password2;
  };

  const handleClickNext = () => {
    handleNext(currentPassword, password1);
  };

  return (
    <ComponentsLayout>
      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <CustomInput
          fullWidth
          type={'password'}
          value={currentPassword}
          handleChange={setCurrentPassword}
          label={t('profile.text.enterCurrentPass')}
        />
        <CustomInput
          fullWidth
          type={'password'}
          value={password1}
          handleChange={setPassword1}
          label={t('profile.text.enterNewPass')}
        />
        <CustomInput
          fullWidth
          type={'password'}
          value={password2}
          handleChange={setPassword2}
          label={t('profile.text.reEnterNewPass')}
        />
        <Box width={'288px'} margin={'0 auto'}>
          <CustomButton
            fullWidth
            variant={'contained'}
            onClick={handleClickNext}
            disabled={!passwordValidation()}
          >
            {t('buttons.changePass')}
          </CustomButton>
        </Box>
      </Box>
    </ComponentsLayout>
  );
};

export default SettingsPassPanelMobile;
