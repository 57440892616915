import { CongratulationsDataType } from '../../../types/CongratulationsDataType';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: {
  openModal: boolean;
  goalMet: CongratulationsDataType;
} = {
  openModal: false,
  goalMet: {
    category: '',
    goal: { value: 0, measure: '' },
    start: { value: 0, measure: '', time: '' },
  },
};

const congratulationsSlice = createSlice({
  name: 'goalMet',
  initialState,
  reducers: {
    addGoalDetails: (state, action: PayloadAction<CongratulationsDataType>) => {
      state.openModal = true;
      state.goalMet = action.payload;
    },
    deleteGoalDetails: state => {
      state.openModal = false;
      state.goalMet = {
        category: '',
        goal: { value: 0, measure: '' },
        start: { value: 0, measure: '', time: '' },
      };
    },
  },
});
export const { addGoalDetails, deleteGoalDetails } = congratulationsSlice.actions;

export default congratulationsSlice.reducer;
