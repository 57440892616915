import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useCategoryLabel = (selectedCategory: string): string => {
  const { t } = useTranslation();

  return useMemo<string>(() => {
    if (selectedCategory === 'morning') {
      return t('constants.hypertension.measurements.morning');
    } else if (selectedCategory === 'afternoon') {
      return t('constants.hypertension.measurements.afternoon');
    }
    return t('constants.hypertension.measurements.all');
  }, [selectedCategory]);
};

export default useCategoryLabel;
