import { FrequencyItemType } from '@hdcorner/ui-library';

export const hasDuplicateTimes = (freqArray: FrequencyItemType[]) => {
  const encounteredTimes = new Set();
  return freqArray
    .map((obj: any) => {
      if (encounteredTimes.has(obj.time)) {
        return true;
      } else {
        encounteredTimes.add(obj.time);
      }
      return false;
    })
    .includes(true);
};
