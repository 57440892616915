import { Box, Typography } from '@mui/material';

const TermsAndConditionsGR = () => {
  return (
    <Box>
      <Box>
        <Typography variant={'h1'}>ΟΡΟΙ ΧΡΗΣΗΣ</Typography>
        <Typography variant={'body1'} paragraph>
          Οι παρόντες όροι χρήσης διέπουν τη σχέση μεταξύ εσάς των χρηστών της Εφαρμογής
          και της εταιρίας «ΚΑΡΑΜΠΙΝΗΣ MEDICAL Α.Ε. ΠΑΡΑΓΩΓΗΣ ΕΜΠΟΡΙΑΣ ΙΑΤΡΟΤΕΧΝΟΛΟΓΙΚΩΝ
          ΠΡΟΪΟΝΤΩΝ ΚΑΙ ΠΑΡΟΧΗΣ ΥΠΗΡΕΣΙΩΝ», που εδρεύει στην Παιανία Αττικής, επί της
          Λεωφ. Λαυρίου, αριθ. 151, με Α.Φ.Μ.: 099052507 – Δ.Ο.Υ.: ΦΑΕ Αθηνών- ΑΡ. ΓΕΜΗ
          87183102000 , τηλ. 2106645751. («εμείς», « εμάς», «μας» ή «Εταιρεία») σχετικά με
          τη χρήση από μέρους σας των υπηρεσιών της Εφαρμογής HDCORNER -web ή mobile app
          (η «Εφαρμογή» ή «Υπηρεσία» ή «HDCORNER»), και όλων των διαθέσιμων πληροφοριών,
          κειμένου, γραφικών, λογισμικού και υπηρεσιών για τη χρήση εφαρμογής (το
          «Περιεχόμενο»).
        </Typography>
        <Typography variant={'body1'} paragraph>
          Αυτοί οι Όροι αποτελούν την νομικά δεσμευτική σύμβαση μεταξύ εσάς και της
          Εταιρείας για την χρήση της Εφαρμογής. Για το λόγο αυτό, ΠΑΡΑΚΑΛΟΥΜΕ ΔΙΑΒΑΣΤΕ
          ΠΡΟΣΕΚΤΙΚΑ ΤΟΥΣ ΟΡΟΥΣ ΠΡΙΝ ΧΡΗΣΙΜΟΠΟΙΗΣΕΤΕ ΤΗΝ ΥΠΗΡΕΣΙΑ..
        </Typography>
        <Typography variant={'body1'} paragraph>
          Η Εταιρεία διατηρεί το δικαίωμα να μεταβάλει ή να τροποποιεί τους εφαρμοστέους
          όρους και προϋποθέσεις για τη χρήση του Διαδικτυακού Τόπου και της Εφαρμογής
          κατά την ελεύθερη κρίση της και σε οποιαδήποτε χρονική στιγμή, λαμβάνοντας
          πάντοτε υπόψη τις προϋποθέσεις που τάσσονται σχετικά από την κείμενη νομοθεσία.
        </Typography>
        <Typography variant={'subtitle1'} paragraph>
          ΑΠΟΠΟΙΗΣΕΙΣ
        </Typography>
        <Typography variant={'body1'} paragraph>
          ΟΙ ΥΠΗΡΕΣΙΕΣ ΚΑΙ ΤΑ ΠΕΡΙΕΧΟΜΕΝΑ ΠΑΡΕΧΟΝΤΑΙ "ΩΣ ΕΧΕΙ", ΧΩΡΙΣ ΚΑΝΕΝΟΣ ΕΙΔΟΥΣ
          ΕΓΓΥΗΣΗΣ.
        </Typography>
        <Typography variant={'body1'} paragraph>
          Η ΕΤΑΙΡΕΙΑ ΔΕΝ ΠΑΡΕΧΕΙ ΚΑΝΕΝΟΣ ΕΙΔΟΥΣ ΙΑΤΡΙΚΗ ΣΥΜΒΟΥΛΗ, , ΣΧΕΤΙΚΑ ΜΕ ΤΗΝ ,
          ΠΡΟΛΗΨΗ, ΔΙΑΓΝΩΣΗ Ή ΘΕΡΑΠΕΙΑ ΑΣΘΕΝΕΙΑΣ Η ΚΑΤΑΣΤΑΣΗΣ ΥΓΕΙΑΣ.
        </Typography>
        <Typography variant={'body1'} paragraph>
          Η ΥΠΗΡΕΣΙΑ ΔΕΝ ΑΝΤΙΚΑΘΙΣΤΑ ΤΟΥΣ ΕΠΑΓΓΕΛΜΑΤΙΕΣ ΥΓΕΙΑΣ ΑΛΛΑ ΠΡΟΟΡΙΖΕΤΑΙ ΜΟΝΟ ΩΣ
          ΕΡΓΑΛΕΙΟ, ΠΟΥ ΜΠΟΡΕΙ ΝΑ ΕΙΝΑΙ ΧΡΗΣΙΜΟ ΓΙΑ ΤΗΝ ΕΠΙΤΕΥΞΗ ΤΩΝ ΣΤΟΧΩΝ ΣΑΣ ΚΑΙ ΤΗΣ
          ΣΥΝΟΛΙΚΗΣ ΥΓΕΙΑΣ ΚΑΙ ΕΥΕΞΙΑΣ .
        </Typography>
        <Typography variant={'body1'} paragraph>
          ΔΕΝ ΕΠΙΤΡΕΠΕΤΑΙ ΝΑ ΧΡΗΣΙΜΟΠΟΙΗΣΕΤΕ ΤΗΝ ΥΠΗΡΕΣΙΑ ΣΕ ΑΝΤΙΚΑΤΑΣΤΑΣΗ ΙΑΤΡΙΚΩΝ
          ΣΥΜΒΟΥΛΩΝ Ή ΕΑΝ ΤΟ ΚΑΝΕΤΕ ΜΠΟΡΕΙ ΑΥΤΟ ΝΑ ΕΝΕΧΕΙ ΚΙΝΔΥΝΟ ΓΙΑ ΤΗΝ ΥΓΕΙΑ ΣΑΣ.
          ΑΝΑΓΝΩΡΙΖΕΤΕ ΟΤΙ ΑΝΑΛΑΜΒΑΝΕΤΕ ΤΗΝ ΠΛΗΡΗ ΕΥΘΥΝΗ ΓΙΑ ΤΗΝ ΥΓΕΙΑ, ΤΗ ΖΩΗ ΚΑΙ ΤΗΝ
          ΕΥΖΩΙΑ ΣΑΣ .
        </Typography>
        <Typography variant={'body1'} paragraph>
          ΤΟ ΠΕΡΙΕΧΟΜΕΝΟ ΠΟΥ ΠΑΡΕΧΕΤΑΙ ΜΕΣΩ ΤΗΣ ΥΠΗΡΕΣΙΑΣ, ΕΙΤΕ ΠΑΡΕΧΕΤΑΙ ΑΠΟ ΕΜΑΣ ΕΙΤΕ
          ΤΡΙΤΑ ΜΕΡΗ (ΑΚΟΜΑ ΚΑΙ ΑΝ ΕΙΝΑΙ ΕΠΑΓΓΕΛΜΑΤΙΕΣ ΥΓΕΙΑΣ) ΔΕΝ ΠΡΟΟΡΙΖΕΤΑΙ ΚΑΙ ΔΕΝ
          ΠΡΕΠΕΙ ΝΑ ΧΡΗΣΙΜΟΠΟΙΕΙΤΑΙ ΑΝΤΙ ΓΙΑ ΤΗΝ ΕΠΙΣΚΕΨΗ, ΚΛΗΣΗ Ή ΔΙΑΒΟΥΛΕΥΣΗ ΜΕ ΤΟΝ
          ΓΙΑΤΡΟ ΣΑΣ Ή ΑΛΛΟΥΣ ΕΠΑΓΓΕΛΜΑΤΙΕΣ ΥΓΕΙΑΣ.
        </Typography>
        <Typography variant={'body1'} paragraph>
          ΕΑΝ ΕΧΕΤΕ ΟΠΟΙΑΔΗΠΟΤΕ ΕΡΩΤΗΣΗ ΣΧΕΤΙΚΑ ΜΕ ΤΗΝ ΥΓΕΙΑ ΣΑΣ, ΚΑΛΕΣΤΕ Ή ΕΠΙΣΚΕΦΘΕΙΤΕ
          ΑΜΕΣΩΣ ΤΟΝ ΓΙΑΤΡΟ ΣΑΣ Ή ΑΛΛΟ ΕΠΑΓΓΕΛΜΑΤΙΑ ΥΓΕΙΑΣ. ΕΑΝ ΥΠΑΡΧΕΙ ΕΠΕΙΓΟΥΣΑ
          ΠΕΡΙΠΤΩΣΗ, ΚΑΛΕΣΤΕ ΑΜΕΣΩΣ ΤΟΝ ΓΙΑΤΡΟ ΣΑΣ Ή ΤΙΣ ΤΟΠΙΚΕΣ ΥΠΗΡΕΣΙΕΣ ΕΚΤΑΚΤΗΣ
          ΑΝΑΓΚΗΣ .
        </Typography>
        <Typography variant={'body1'} paragraph>
          ΑΝΑΓΝΩΡΙΖΕΤΕ ΟΤΙ Η ΔΙΑΤΡΟΦΗ ΣΑΣ ΚΑΙ ΟΙ ΔΡΑΣΤΗΡΙΟΤΗΤΕΣ ΑΣΚΗΣΗΣ ΕΝΕΧΟΥΝ ΚΙΝΔΥΝΟ
          ΣΩΜΑΤΙΚΟΥ ΤΡΑΥΜΑΤΙΣΜΟΥ Ή ΘΑΝΑΤΟΥ ΚΑΙ ΟΤΙ ΑΝΑΛΑΜΒΑΝΕΤΕ ΑΥΤΟΥΣ ΤΟΥΣ ΚΙΝΔΥΝΟΥΣ ΠΡΙΝ
          ΧΡΗΣΙΜΟΠΟΙΗΣΕΤΕ ΤΗΝ ΥΠΗΡΕΣΙΑ ΚΑΙ ΣΥΜΦΩΝΕΙΤΕ ΝΑ ΑΠΑΛΛΑΞΕΤΕ ΚΑΙ ΑΠΑΛΛΑΣΕΤΕ ΤΗΝ
          ΕΤΑΙΡΕΙΑ ΑΠΟ ΟΠΟΙΑΔΗΠΟΤΕ ΣΥΝΕΠΕΙΑ, ΓΝΩΣΤΗ Ή ΑΓΝΩΣΤΗ, ΠΟΥ ΜΠΟΡΕΙ ΝΑ ΠΡΟΚΥΨΕΙ ΑΠΟ
          ΤΗ ΧΡΗΣΗ ΤΗΣ ΥΠΗΡΕΣΙΑΣ.
        </Typography>
        <Typography variant={'body1'} paragraph>
          Η ΕΤΑΙΡΕΙΑ ΔΕΝ ΑΝΑΛΑΜΒΑΝΕΙ ΚΑΜΙΑ ΕΥΘΥΝΗ ΓΙΑ ΤΥΧΟΝ ΑΝΑΚΡΙΒΕΙΑ ΣΧΕΤΙΚΑ ΜΕ ΣΥΝΤΑΓΕΣ
          ΤΡΟΦΙΜΩΝ, ΣΥΜΒΟΥΛΕΣ ΑΣΚΗΣΕΙΣ Ή ΑΛΛΟ ΠΕΡΙΕΧΟΜΕΝΟ ΤΗΣ ΥΠΗΡΕΣΙΑΣ. ΠΡΕΠΕΙ ΝΑ
          ΔΙΑΒΑΣΕΤΕ ΠΡΟΣΕΚΤΙΚΑ ΟΛΕΣ ΤΙΣ ΠΛΗΡΟΦΟΡΙΕΣ ΠΟΥ ΠΑΡΕΧΟΝΤΑΙ ΑΠΟ ΤΟΥΣ ΚΑΤΑΣΚΕΥΑΣΤΕΣ
          ΤΩΝ ΔΙΑΤΡΟΦΙΚΩΝ ΠΡΟΪΟΝΤΩΝ, ΕΙΤΕ ΣΤΟ ΔΙΑΔΙΚΤΥΟ ΕΙΤΕ ΣΤΗΝ ΠΡΑΓΜΑΤΙΚΗ ΣΥΣΚΕΥΑΣΙΑ
          ΚΑΙ ΤΙΣ ΕΤΙΚΕΤΕΣ ΤΟΥ ΠΡΟΪΟΝΤΟΣ, ΣΥΜΠΕΡΙΛΑΜΒΑΝΟΜΕΝΟΥ ΠΕΡΙΕΧΟΜΕΝΟΥ ΘΡΕΠΤΙΚΩΝ
          ΣΥΣΤΑΤΙΚΩΝ , ΠΡΙΝ ΧΡΗΣΙΜΟΠΟΙΗΣΕΤΕ Ή ΚΑΤΑΝΑΛΩΣΕΤΕ ΕΝΑ ΠΡΟΪΟΝ. ΓΙΑ ΠΡΟΣΘΕΤΕΣ
          ΠΛΗΡΟΦΟΡΙΕΣ ΣΧΕΤΙΚΑ ΜΕ ΕΝΑ ΔΙΑΤΡΟΦΙΚΟ ΠΡΟΪΟΝ, ΕΠΙΚΟΙΝΩΝΗΣΤΕ ΑΠΕΥΘΕΙΑΣ ΜΕ ΤΟΝ
          ΠΑΡΑΓΩΓΟ.
        </Typography>
        <Typography variant={'body1'} paragraph>
          Η ΕΤΑΙΡΕΙΑ ΔΕΝ ΑΝΑΛΑΜΒΑΝΕΙ ΚΑΜΙΑ ΕΥΘΥΝΗ ΓΙΑ ΤΗΝ ΚΑΤΑΛΛΗΛΟΤΗΤΑ ΤΗΣ ΥΠΗΡΕΣΙΑΣ ΣΕ
          ΣΑΣ ΚΑΙ ΤΟ ΕΠΙΠΕΔΟ ΤΗΣ ΕΠΙΤΥΧΙΑΣ. ΤΑ ΑΠΟΤΕΛΕΣΜΑΤΑ ΜΠΟΡΕΙ ΝΑ ΔΙΑΦΕΡΟΥΝ ΑΝΑ ΑΤΟΜΟ.
          Ειδικότερα, η Εταιρία δεν παρέχει και αρνείται ρητά οποιαδήποτε εγγύηση ότι: (i)
          η Υπηρεσία θα ικανοποιήσει τις απαιτήσεις σας, (ii) η Υπηρεσία θα είναι
          αδιάκοπη, έγκαιρη, ασφαλής ή χωρίς σφάλματα, (iii) τα αποτελέσματα που ενδέχεται
          να προκύψουν από τη χρήση της Υπηρεσίας, συμπεριλαμβανομένων των δεδομένων, θα
          είναι ακριβή ή αξιόπιστα, (iv) η ποιότητα οποιωνδήποτε δεδομένων ή υπηρεσιών που
          διατίθενται στην Υπηρεσία θα ανταποκρίνεται στις προσδοκίες σας και (v) τυχόν
          σφάλματα στην υπηρεσία θα διορθωθούν. Οποιοδήποτε υλικό αποκτάται μέσω της
          χρήσης της Υπηρεσίας γίνεται με δική σας κρίση και ευθύνη και θα είστε
          αποκλειστικά υπεύθυνοι για οποιαδήποτε ζημία σε σύστημα υπολογιστή ή φορητή
          συσκευή ή απώλεια δεδομένων που προκύπτει από τη χρήση οποιουδήποτε τέτοιου
          υλικού. Δεν μπορούμε να εγγυηθούμε και δεν υποσχόμαστε συγκεκριμένα αποτελέσματα
          από τη χρήση της Εφαρμογής ή/και της Υπηρεσίας. Συμφωνείτε επίσης να αναλάβετε
          τους κινδύνους διακοπής της Υπηρεσίας για οποιουσδήποτε τεχνικούς λόγους.
        </Typography>
        <Typography variant={'body1'} paragraph>
          ΟΙ ΜΑΡΤΥΡΙΕΣ ΚΑΙ ΤΑ ΠΑΡΑΔΕΙΓΜΑΤΑ ΠΟΥ ΜΠΟΡΕΙ ΝΑ ΠΑΡΕΧΟΝΤΑΙ ΣΤΗΝ ΥΠΗΡΕΣΙΑ ΜΠΟΡΕΙ
          ΝΑ ΜΗΝ ΙΣΧΥΟΥΝ ΓΙΑ ΟΛΑ ΤΑ ΑΤΟΜΑ ΚΑΙ ΔΕΝ ΕΓΓΥΩΝΤΑΙ ΤΑ ΑΠΟΤΕΛΕΣΜΑΤΑ ΔΕΝ ΥΠΑΡΧΕΙ
          ΔΙΑΒΕΒΑΙΩΣΗ ΟΤΙ ΠΑΡΑΔΕΙΓΜΑΤΑ ΠΡΟΗΓΟΥΜΕΝΩΝ ΑΠΟΤΕΛΕΣΜΑΤΩΝ ΑΣΚΗΣΕΩΝ ΚΑΙ ΔΙΑΤΡΟΦΗΣ
          ΜΠΟΡΟΥΝ ΝΑ ΕΧΟΥΝ ΑΠΟΤΕΛΕΣΜΑΤΑ ΣΤΟ ΜΕΛΛΟΝ. ΔΕΝ ΜΠΟΡΟΥΜΕ ΝΑ ΕΓΓΥΗΘΟΥΜΕ ΤΑ
          ΜΕΛΛΟΝΤΙΚΑ ΑΠΟΤΕΛΕΣΜΑΤΑ ΚΑΙ/Η ΤΗΝ ΕΠΙΤΥΧΙΑ ΣΑΣ ΟΥΤΕ ΜΠΟΡΟΥΜΕ ΝΑ ΕΓΓΥΗΘΟΥΜΕ ΟΤΙ
          ΘΑ ΔΙΑΤΗΡΗΣΕΤΕ ΤΑ ΑΠΟΤΕΛΕΣΜΑΤΑ ΕΑΝ ΔΕΝ ΣΥΝΕΧΙΣΕΤΕ ΝΑ ΑΚΟΛΟΥΘΕΙΤΕ ΤΑ ΠΡΟΓΡΑΜΜΑΤΑ
          ΜΑΣ.
        </Typography>
        <Typography variant={'body1'} paragraph>
          Η ΥΠΗΡΕΣΙΑ ΕΝΔΕΧΕΤΑΙ ΝΑ ΜΗΝ ΕΙΝΑΙ ΚΑΤΑΛΛΗΛΗ ΓΙΑ ΟΛΑ ΤΑ ΠΡΟΣΩΠΑ.ΤΑ ΑΠΟΤΕΛΕΣΜΑΤΑ
          ΣΧΕΤΙΚΑ ΜΕ ΤΗΝ ΥΓΕΙΑ ΤΗΝ ΑΣΚΗΣΗ ΚΑΙ ΤΗΝ ΔΙΑΤΡΟΦΗ ΚΑΘΕ ΑΤΟΜΟΥ ΕΞΑΡΤΑΤΑΙ ΑΠΟ ΤΟ
          ΙΣΤΟΡΙΚΟ ΤΟΥ, ΤΗΝ ΑΦΟΣΙΩΣΗ, ΤΗΝ ΕΠΙΘΥΜΙΑ ΚΑΙ ΤΑ ΚΙΝΗΤΡΑ ΤΟΥ. ΟΠΩΣ ΜΕ ΟΠΟΙΟΔΗΠΟΤΕ
          ΠΡΟΓΡΑΜΜΑ Ή ΥΠΗΡΕΣΙΑ ΣΧΕΤΙΚΑ ΜΕ ΤΗΝ ΥΓΕΙΑ, ΤΑ ΑΠΟΤΕΛΕΣΜΑΤΑ ΣΑΣ ΕΝΔΕΧΕΤΑΙ ΝΑ
          ΔΙΑΦΕΡΟΥΝ ΚΑΙ ΘΑ ΒΑΣΙΣΘΟΥΝ ΣΕ ΠΟΛΛΕΣ ΜΕΤΑΒΛΗΤΕΣ, ΣΥΜΠΕΡΙΛΑΜΒΑΝΟΜΕΝΩΝ ΑΛΛΑ ΟΧΙ
          ΠΕΡΙΟΡΙΣΤΙΚΑ ΤΗΣ ΑΤΟΜΙΚΗΣ ΙΚΑΝΟΤΗΤΑΣ, ΚΑΙ ΤΟ ΕΠΙΠΕΔΟ ΤΗΣ ΣΥΝΕΠΕΙΑΣ. Η ΧΡΗΣΗ ΤΗΣ
          ΥΠΗΡΕΣΙΑΣ ΠΡΕΠΕΙ ΝΑ ΒΑΣΙΖΕΤΑΙ ΣΤΗ ΔΙΚΗ ΣΑΣ ΔΕΟΥΣΑ ΕΠΙΜΕΛΕΙΑ ΚΑΙ ΣΥΜΦΩΝΕΙΤΕ ΟΤΙ Η
          ΕΤΑΙΡΕΙΑ ΔΕΝ ΦΕΡΕΙ ΕΥΘΥΝΗ ΓΙΑ ΟΠΟΙΑΔΗΠΟΤΕ ΕΠΙΤΥΧΙΑ Ή ΑΠΟΤΥΧΙΑ ΤΗΣ ΥΓΕΙΑΣ ΚΑΙ ΤΗΣ
          ΦΥΣΙΚΗΣ ΣΑΣ ΚΑΤΑΣΤΑΣΗΣ .
        </Typography>
        <Typography variant={'body1'} paragraph>
          ΕΠΙΠΛΕΟΝ ΟΛΩΝ ΤΩΝ ΑΛΛΩΝ ΠΕΡΙΟΡΙΣΜΩΝ ΚΑΙ ΑΠΟΠΟΙΗΣΕΩΝ ΣΕ ΑΥΤΟΥΣ ΟΡΟΥΣ, Η ΕΤΑΙΡΕΙΑ
          ΑΠΟΠΟΙΕΙΤΑΙ ΟΠΟΙΑΔΗΠΟΤΕ ΕΥΘΥΝΗ ΓΙΑ ΖΗΜΙΑ ΣΕ ΣΥΝΔΕΣΗ ΜΕ ΤΟ ΠΕΡΙΕΧΟΜΕΝΟ ΠΟΥ
          ΠΑΡΕΧΕΤΑΙ ΣΤΗΝ ΥΠΗΡΕΣΙΑ. ΣΑΣ ΕΝΘΑΡΡΥΝΟΥΜΕ ΝΑ ΣΥΜΒΟΥΛΕΥΤΕΙΤΕ ΤΟΝ ΓΙΑΤΡΟ ΣΑΣ ΚΑΙ
          ΑΛΛΟΥΣ ΣΧΕΤΙΚΟΥΣ ΕΠΑΓΓΕΛΜΑΤΙΕΣ ΣΧΕΤΙΚΑ ΜΕ ΤΙΣ ΠΛΗΡΟΦΟΡΙΕΣ ΠΟΥ ΠΕΡΙΕΧΟΝΤΑΙ ΣΤΗΝ
          ΥΠΗΡΕΣΙΑ Ή ΠΟΥ ΕΧΕΤΕ ΠΡΟΣΒΑΣΗ ΜΕΣΩ ΤΗΣ ΥΠΗΡΕΣΙΑΣ.
        </Typography>
        <ol>
          <li>
            <Typography variant={'subtitle1'}>Εγγραφή στην υπηρεσία</Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            Η εγγραφή στην υπηρεσία HD CORNER γίνεται παρέχοντας τα κάτωθι προσωπικά σας
            στοιχεία : ονοματεπώνυμο, ημερομηνία γέννησης, φύλο και ηλεκτρονική διεύθυνση.
            Επιπλέον θα κληθείτε να σχηματίσετε και κωδικό πρόσβασης (password), τo οποίo
            θα χρησιμοποιείτε μαζί με το email σας εφεξής για την σύνδεσή σας στην
            υπηρεσία. Έχετε τη δυνατότητα να αλλάξετε το password στην Καρτέλα «Προφίλ»
          </Typography>
          <Typography variant={'body1'} paragraph>
            Αποτελεί αποκλειστικά δική σας ευθύνη να ελέγχετε τη γνωστοποίηση και την
            χρήση του κωδικού πρόσβασης και να επιτρέπετε, να ελέγχετε και να
            διαχειρίζεστε την πρόσβαση και χρήση του λογαριασμού και του κωδικού σας στην
            υπηρεσία hdcorner Με την χρήση των υπηρεσιών της εφαρμογής εγγυάσθε ότι:
          </Typography>
          <ul>
            <li>
              <Typography variant={'body1'}>
                Δε θα παρέχετε λανθασμένες πληροφορίες
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                Δε θα δημιουργούνται περισσότεροι από έναν λογαριασμοί.
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                Αν ανασταλεί ή διαγραφεί λογαριασμός από το διαχειριστή δε θα δημιουργηθεί
                άλλος.
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                Δε θα κοινοποιήσετε σε άλλους τον κωδικό πρόσβασης και δε θα δώσετε τα
                στοιχεία πρόσβασης σε άλλους.
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                Η χρήση της Υπηρεσίας από εσάς δεν θα παραβιάζει κανέναν ισχύοντα νόμο ή
                κανονισμό ή αυτούς τους Όρους.
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>Δεν είστε κάτω των 18 ετών</Typography>
            </li>
          </ul>
          <Typography variant={'body1'} paragraph>
            Η Εταιρεία διατηρεί το δικαίωμα να αναστείλει ή να τερματίσει το Προφίλ σας ή
            την πρόσβασή σας στην Υπηρεσία, με ή χωρίς ειδοποίηση προς εσάς, σε περίπτωση
            που παραβιάσετε αυτούς τους Όρους. Είστε υπεύθυνοι για τη διατήρηση της
            εμπιστευτικότητας των πληροφοριών σύνδεσης του Προφίλ σας και είστε πλήρως
            υπεύθυνοι για όλες τις δραστηριότητες που πραγματοποιούνται στο πλαίσιο του
            Προφίλ σας. Συμφωνείτε να ειδοποιήσετε αμέσως την Εταιρεία για οποιαδήποτε μη
            εξουσιοδοτημένη χρήση ή ύποπτη μη εξουσιοδοτημένη χρήση του Προφίλ σας ή
            οποιαδήποτε άλλη παραβίαση ασφάλειας. Η Εταιρεία δεν μπορεί και δεν θα φέρει
            ευθύνη για οποιαδήποτε απώλεια ή ζημία προκύψει από τη μη συμμόρφωσή σας με
            τις παραπάνω απαιτήσεις.
          </Typography>
          <Typography variant={'body1'} paragraph>
            Με την είσοδό σας στην υπηρεσία, καλείστε να επιλέξετε μεταξύ του βασικού
            προφίλ και του προφίλ premium. Επιλέγοντας το βασικό προφίλ θα έχετε τη
            δυνατότητα:
          </Typography>
          <ul>
            <li>
              <Typography variant={'body1'}>
                Να ανεβάζετε μετρήσεις σακχάρου είτε χειροκίνητα για τις συσκευές μέτρησης
                σακχάρου της Microlife GlucoTeq και της ΒΙΟΝΙΜΕ GM550 & GM700s
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                Να ανεβάζετε μετρήσεις αρτηριακής πίεσης είτε χειροκίνητα είτε μέσω
                σύνδεσης με USB για τις συσκευές πιεσομέτρων Microlife
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                Να ανεβάζετε μετρήσεις HbA1c, χοληστερίνης, τριγλικεριδίων, HDL, Lpa & LDL
                χειροκίνητα
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                Πρόσβαση στα αυτοματοποιημένα report των μετρήσεών σας
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                Θα μπορείτε επίσης να θέτετε reminders ( πχ. για λήψη φαρμάκων, μέτρηση
                σακχάρου κλπ.)
              </Typography>
            </li>
          </ul>
          <Typography variant={'body1'} paragraph>
            Επιλέγοντας το προφίλ premium εκτός από τα προαναφερόμενα θα έχετε τη
            δυνατότητα πρόσβασης σε όλα τα module του HD Corner τα οποία διακρίνονται ως
            κάτωθι:
          </Typography>
          <ul>
            <li>
              <Typography variant={'body1'}>
                Ανέβασμα των μετρήσεων σακχάρου από οποιαδήποτε συσκευή μέτρησης σακχάρου
                μέσω της κάμερας του κινητού σας
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                Ανέβασμα των μετρήσεων αρτηριακής πίεσης από οποιαδήποτε συσκευή μέτρησης
                αρτηριακής πίεσης μέσω της κάμερας του κινητού σας.
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                Δυνατότητα download των αυτοματοποιημένων report των μετρήσεών σας σε
                excel ή pdf.
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                Πρόσβαση στον Υπολογισμός κινδύνου θανατηφόρου καρδιαγγειακού επεισοδίου
                Hellenic Score II.
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                Πρόσβαση στην υπηρεσία υπενθύμισης φαρμάκου.
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                Πρόσβαση στον υπολογισμό θερμίδων με βάση το προφίλ σας, διαιτολόγια,
                ισοδύναμα τροφών και στην υπηρεσία καταγραφής λήψης και καύσης θερμίδων
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                Πρόσβαση σε βίντεο ασκήσεων και συμβουλές εκτέλεσης των προγραμμάτων
                άσκησης με βάση το προφίλ σας.
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                Δυνατότητα αποθήκευσης αρχείων ιατρικών εξετάσεων (cloud).
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                Δυνατότητα προσθήκης επαγγελματία υγείας (γιατρό ή φαρμακοποιό), ο οποίος
                θα μπορεί να εξετάζει το περιεχόμενο που ανεβάζετε και να σας δίνει
                οδηγίες και συμβουλές μέσω συνομιλίας (chat).
              </Typography>
            </li>
          </ul>
          <Typography variant={'body1'}>
            Η επιλογή premium παρέχεται με συνδρομή (μηνιαία ή ετήσια) την οποία μπορείτε
            να εξοφλήσετε μέσω πιστωτικής/χρεωστικής κάρτας από την Web εφαρμογή του HD
            Corner.
          </Typography>
          <Typography variant={'body1'}>
            Σε κάθε επιλογή προφίλ (βασικό ή premium) δύνασθε προαιρετικά να σχηματίσετε
            το προφίλ σας εισάγοντας τις διατροφικές σας συνήθειες, πληροφορίες για την
            σωματική σας κατάσταση και υγεία, ώστε να σας προσφέρουμε το κατά το δυνατόν
            εξατομικευμένο περιεχόμενο.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>Διαγραφή από την Υπηρεσία</Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            Ο χρήστης έχει τη δυνατότητα να διαγραφεί από την υπηρεσία (unsubscribe)
            επιλέγοντας την αντίστοιχη επιλογή στην καρτέλα προφίλ. Επιλέγοντας να
            διαγραφείτε συμφωνείτε ότι το προφίλ σας θα διαγραφεί και όλα τα δεδομένα που
            έχετε καταχωρήσει θα καταστραφούν σύμφωνα με τη νομοθεσία περί προσωπικών
            δεδομένων.
          </Typography>
          <Typography variant={'body1'} paragraph>
            Διατηρούμε το δικαίωμα να περιορίσουμε την πρόσβαση στο Διαδικτυακό Τόπο ή την
            Εφαρμογή ή/και να διαγράψουμε έναν λογαριασμό, σε περίπτωση που κρίνουμε ως
            απαραίτητο, όπως:
          </Typography>
          <ul>
            <li>
              <Typography variant={'body1'}>
                Κάποιος άλλος εκτός από το Χρήστη κάνει χρήση του λογαριασμού του, ή
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                Ο Χρήστης έχει εμπλακεί σε οποιαδήποτε δραστηριότητα ή συμπεριφορά που
                παραβιάζει τον νόμο, τους παρόντες Όρους και τις πολιτικές μας ή
                εμπλέκεται σε δραστηριότητα ή συμπεριφορά που θεωρούμε κατά την απόλυτη
                κρίση μας ότι συνιστά κατάχρηση των υπηρεσιών μας.
              </Typography>
            </li>
          </ul>
          <li>
            <Typography variant={'subtitle1'}>Χρήση Περιεχομένου</Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            Το Περιεχόμενο του HD Corner συμπεριλαμβάνει φωτογραφίες, εικόνες, γραφικά,
            βίντεο, ήχο, δεδομένα, κείμενα, μουσική, φυσική κατάσταση, σχόλια, λογισμικά,
            εργασίες συγγραφέων οποιουδήποτε είδους και άλλες πληροφορίες, περιεχόμενο και
            άλλο υλικό που δημοσιεύεται, παράγεται, διατίθεται ή μέσω των Υπηρεσιών
            (συλλογικά αναφέρονται ως "Περιεχόμενο"). Το Περιεχόμενο, οι Υπηρεσίες
            προστατεύονται από πνευματικά δικαιώματα, εμπορικά σήματα και διπλώματα
            ευρεσιτεχνίας, . Διατηρούμε όλα τα δικαιώματα που δεν αναφέρονται ρητά στους
            παρόντες Όρους. Συμφωνείτε να μη διαγράψετε, αλλάξετε ή αποκρύψετε τυχόν
            ειδοποίηση πνευματικών δικαιωμάτων, εμπορικών σημάτων, σημάτων υπηρεσιών ή
            άλλων δικαιωμάτων ιδιοκτησίας που ενσωματώνονται ή συνοδεύουν τις Υπηρεσίες.
            Τα λογότυπά μας και οποιοδήποτε άλλο εμπορικό σήμα του HD Corner που μπορεί να
            εμφανιστεί στις Υπηρεσίες, και το Περιεχόμενο της εφαρμογής , όπως π.χ. η
            γενική εμφάνιση των Υπηρεσιών, συμπεριλαμβανομένων των επικεφαλίδων των
            σελίδων, των γραφικών, των εικόνων και των κειμένων, δεν επιτρέπεται να
            αντιγράφονται , να μιμούνται ή να χρησιμοποιούνται με οποιονδήποτε τρόπο, στο
            σύνολό ή μέρος αυτών, χωρίς προηγούμενη γραπτή εξουσιοδότηση. Άλλα εμπορικά
            σήματα, ονόματα προϊόντων και υπηρεσιών και ονόματα εταιρειών ή λογότυπα που
            έχουν αναφερθεί στις Υπηρεσίες αποτελούν ιδιοκτησία των αντίστοιχων ιδιοκτητών
            και δεν μπορούν να αντιγράφονται, να μιμούνται ή να χρησιμοποιούνται, στο
            σύνολο ή μέρος αυτών, χωρίς την άδεια του κατόχου του εμπορικού σήματος που
            είναι αντίστοιχα κατοχυρωμένη.
          </Typography>
          <Typography variant={'body1'} paragraph>
            Επισημαίνεται ότι η χρήση της εφαρμογής και του περιεχομένου της , γίνεται
            αποκλειστικά με δική σας ευθύνη. Η Εταιρία δεν φέρει καμία ευθύνη για τυχόν
            καθυστερήσεις, αποτυχίες, διακοπές, ή φθορές οποιονδήποτε δεδομένων ή
            πληροφοριών σχετίζονται με την εφαρμογή. Η Εταιρία καμία περίπτωση δεν
            εγγυάται ακόμη, την έλλειψη «ιών» ή άλλου επιζήμιου κώδικα είτε πρόκειται για
            την εφαρμογή, είτε για κάποιο άλλο site ή server μέσω των οποίων λαμβάνετε το
            περιεχόμενο της.
          </Typography>
          <Typography variant={'body1'} paragraph>
            Με την επιφύλαξη των παρόντων Όρων, η Εταιρεία σας χορηγεί μια μη
            μεταβιβάσιμη, μη αποκλειστική άδεια (χωρίς δικαίωμα υποάδειας) για (i) χρήση
            της Υπηρεσίας αποκλειστικά για προσωπικούς, μη εμπορικούς σκοπούς και (β)
            εγκατάσταση και χρήση της Εφαρμογής, αποκλειστικά στη δική σας φορητή συσκευή
            χειρός (π.χ. iPhone, Android κ.λπ. κατά περίπτωση) ή προσωπικό υπολογιστή και
            αποκλειστικά για προσωπικούς, μη εμπορικούς σκοπούς.
          </Typography>
          <Typography variant={'body1'} paragraph>
            Συμφωνείτε, δηλώνετε και εγγυάστε, ότι η χρήση της Υπηρεσίας ή οποιουδήποτε
            τμήματος αυτής, θα είναι σύμφωνη με την προαναφερόμενη άδεια, τις συμβάσεις
            και τους περιορισμούς και δεν θα παραβιάζει ούτε θα παραβιάζει τα δικαιώματα
            οποιουδήποτε άλλου μέρους ούτε θα παραβιάζει οποιαδήποτε σύμβαση ή νομική
            υποχρέωση σε οποιαδήποτε άλλα μέρη. Επιπλέον, συμφωνείτε ότι θα συμμορφώνεστε
            με όλους τους ισχύοντες νόμους, και κανονισμούς που σχετίζονται με την
            Υπηρεσία ή τη χρήση της και θα είστε αποκλειστικά υπεύθυνοι για τις δικές σας
            ατομικές παραβιάσεις οποιωνδήποτε τέτοιων νόμων.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>
              Αλλαγή Πληροφοριών και Υπηρεσιών Ιστοσελίδας
            </Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            Μπορούμε να αλλάξουμε όλες τις πληροφορίες που παρέχονται στην Υπηρεσία (είτε
            σε δωρεάν είτε επί πληρωμή λειτουργίες) κατά την απόλυτη διακριτική μας
            ευχέρεια χωρίς προειδοποίηση. Μπορούμε ανά πάσα στιγμή να τροποποιήσουμε ή να
            διακόψουμε, προσωρινά ή μόνιμα, την Υπηρεσία (ή οποιοδήποτε μέρος αυτής) κατά
            τη διακριτική μας ευχέρεια με ή χωρίς προειδοποίηση. Συμφωνείτε ότι δεν θα
            φέρουμε ευθύνη έναντι εσάς ή οποιουδήποτε τρίτου για οποιαδήποτε τροποποίηση,
            αναστολή ή διακοπή της Υπηρεσίας.
          </Typography>

          <Typography variant={'body1'} paragraph>
            Δηλώσεις και Περιορισμοί της χρήσης
          </Typography>
          <Typography variant={'body1'} paragraph>
            Χρησιμοποιώντας την Υπηρεσία, δηλώνετε και εγγυάστε ότι: (α) έχετε τη
            δικαιοπρακτική ικανότητα και συμφωνείτε να συμμορφωθείτε με αυτούς τους Όρους·
            (β) δεν είστε κάτω των 18 ετών· (γ) δεν θα έχετε πρόσβαση στην Υπηρεσία μέσω
            αυτοματοποιημένων ή μη ανθρώπινων μέσων, είτε μέσω robot ή σεναρίου ή άλλου
            τρόπου. (δ) δεν θα χρησιμοποιήσετε την Υπηρεσία για οποιονδήποτε παράνομο ή μη
            εξουσιοδοτημένο σκοπό. (ε) η χρήση της Υπηρεσίας από εσάς δεν θα παραβιάζει
            κανέναν ισχύοντα νόμο ή κανονισμό.
          </Typography>

          <Typography variant={'body1'} paragraph>
            Εάν παρέχετε οποιαδήποτε πληροφορία που είναι αναληθής, ανακριβής, μη τρέχουσα
            ή ελλιπής, έχουμε το δικαίωμα να αρνηθούμε οποιαδήποτε και όλη την τρέχουσα ή
            μελλοντική χρήση της Υπηρεσίας (ή οποιοδήποτε τμήμα της).
          </Typography>
          <Typography variant={'body1'} paragraph>
            Δεν επιτρέπεται η πρόσβαση ή η χρήση της Υπηρεσίας για οποιονδήποτε άλλο σκοπό
            εκτός από αυτόν για τον οποίο διαθέτουμε την Υπηρεσία. Η Υπηρεσία δεν μπορεί
            να χρησιμοποιηθεί εμπορικό σκοπό εκτός εάν έχετε εξουσιοδοτηθεί ειδικά από
            εμάς.
          </Typography>
          <Typography variant={'body1'} paragraph>
            Ο χρήστης της Υπηρεσίας, συμφωνεί να ΜΗΝ (α) ανακτά συστηματικά δεδομένα ή
            άλλο περιεχόμενο από την Υπηρεσία για τη δημιουργία ή τη σύνταξη, άμεσα ή
            έμμεσα, συλλογής, συλλογής, βάσης δεδομένων ή καταλόγου χωρίς γραπτή άδεια από
            εμάς· (β) κάνει οποιαδήποτε μη εξουσιοδοτημένη χρήση της Υπηρεσίας. (γ) κάνει
            οποιαδήποτε τροποποίηση, προσαρμογή, βελτίωση, βελτίωση, μετάφραση ή παράγωγο
            έργο από την Υπηρεσία. (δ) χρησιμοποιεί την Υπηρεσία για οποιαδήποτε
            προσπάθεια δημιουργίας εσόδων, εμπορική επιχείρηση ή άλλο σκοπό για τον οποίο
            δεν έχει σχεδιαστεί ή προοριστεί· (ε) κάνει την Υπηρεσία διαθέσιμη μέσω
            δικτύου ή άλλου περιβάλλοντος που επιτρέπει την πρόσβαση ή τη χρήση από πολλές
            συσκευές ή χρήστες ταυτόχρονα· (στ) χρησιμοποιεί την Υπηρεσία για τη
            δημιουργία ενός προϊόντος, μιας υπηρεσίας ή λογισμικού που είναι, άμεσα ή
            έμμεσα, ανταγωνιστικό με ή με οποιονδήποτε τρόπο υποκατάστατο της Υπηρεσίας·
            (ζ) κάνει χρήση οποιασδήποτε ιδιόκτητης πληροφορίας ή άλλης πνευματικής
            ιδιοκτησίας μας για το σχεδιασμό, την ανάπτυξη, την κατασκευή, την αδειοδότηση
            ή τη διανομή οποιωνδήποτε εφαρμογών, αξεσουάρ ή συσκευών για χρήση με την
            Υπηρεσία. (η) παρακάμψει, να απενεργοποιήσει ή να παρέμβει με άλλο τρόπο σε
            λειτουργίες της Υπηρεσίας που σχετίζονται με την ασφάλεια. (i) εμπλέκεται σε
            μη εξουσιοδοτημένη πλαισίωση ή σύνδεση με την Υπηρεσία· (ι) παρεμβαίνει,
            διακόπτει ή δημιουργεί αδικαιολόγητη επιβάρυνση στην Υπηρεσία ή στα δίκτυα ή
            τις υπηρεσίες που είναι συνδεδεμένες στην Υπηρεσία. (ια) αποκρυπτογραφήσει,
            απομεταγλωττίσει, αποσυναρμολογήσει ή αναστρέψει οποιοδήποτε λογισμικό που
            περιλαμβάνει ή με οποιονδήποτε τρόπο αποτελεί μέρος της Υπηρεσίας. (ιβ) κάνει
            προσπάθεια παράκαμψης οποιωνδήποτε μέτρων της Υπηρεσίας που αποσκοπούν στην
            αποτροπή ή τον περιορισμό της πρόσβασης στην Υπηρεσία ή σε οποιοδήποτε τμήμα
            της Υπηρεσίας. (ιγ) ανεβάζει ή να διανέμει με οποιονδήποτε τρόπο αρχεία που
            περιέχουν ιούς, worms, trojans, κατεστραμμένα αρχεία ή οποιοδήποτε άλλο
            παρόμοιο λογισμικό ή προγράμματα που μπορεί να βλάψουν τη λειτουργία του
            υπολογιστή άλλου· (ιδ) κάνει χρήση, εκκίνηση, ανάπτυξη ή διανομή οποιουδήποτε
            αυτοματοποιημένου συστήματος, συμπεριλαμβανομένου χωρίς περιορισμό,
            οποιουδήποτε spider, robot, cheat utility, scraper ή offline reader που έχει
            πρόσβαση στην Υπηρεσία ή χρησιμοποιεί ή εκκινεί οποιοδήποτε μη εξουσιοδοτημένο
            σενάριο ή άλλο λογισμικό. (ιε) κάνει χρήση της Υπηρεσίας για αποστολή
            αυτοματοποιημένων ερωτημάτων σε οποιονδήποτε ιστότοπο ή για αποστολή
            ανεπιθύμητων εμπορικών μηνυμάτων ηλεκτρονικού ταχυδρομείου· (ιστ) υποτιμούν,
            αμαυρώνουν ή βλάπτουν με άλλο τρόπο, κατά τη γνώμη μας, εμάς ή/και την
            Υπηρεσία. (ιζ) κάνει χρήση της Υπηρεσίας με τρόπο που δεν συνάδει με τυχόν
            ισχύοντες νόμους ή κανονισμούς. ή (ιη) παραβιάζει με οποιονδήποτε τρόπο αυτούς
            τους Όρους.
          </Typography>
          <Typography variant={'body1'} paragraph>
            Είστε αποκλειστικά υπεύθυνοι για την απόκτηση του εξοπλισμού και των
            τηλεπικοινωνιακών υπηρεσιών που είναι απαραίτητες για την πρόσβαση στην
            Υπηρεσία, καθώς και για όλες τις χρεώσεις που σχετίζονται με αυτές (όπως
            υπολογιστικές συσκευές και Πάροχος υπηρεσιών Διαδικτύου και χρεώσεις χρόνου
            ομιλίας).
          </Typography>
          <Typography variant={'body1'} paragraph>
            Η πρόσβασή σας και η χρήση της Υπηρεσίας γίνεται με δική σας ευθύνη. Η
            Εταιρεία δεν θα έχει καμία ευθύνη για οποιαδήποτε βλάβη στο υπολογιστικό σας
            σύστημα, απώλεια δεδομένων ή άλλη βλάβη σε εσάς ή σε οποιοδήποτε τρίτο μέρος,
            συμπεριλαμβανομένης, χωρίς περιορισμό, οποιασδήποτε σωματικής βλάβης, που
            τυχόν προκύψει από την πρόσβασή σας στην Υπηρεσία ή τη χρήση της ή βασιστείτε
            σε οποιαδήποτε πληροφορία ή συμβουλή.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>Συνδρομή και Πληρωμή</Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            Ορισμένες δυνατότητες της Υπηρεσίας προσφέρονται με συνδρομή έναντι χρέωσης.
            Μπορείτε να αγοράσετε μια συνδρομή απευθείας από την Εταιρεία μέσω της WEB
            πλατφόρμας της Εφαρμογής. Η αγορά της συνδρομής μέσω της WEB πλατφόρμας της
            Εφαρμογής γίνεται , με ηλεκτρονική πληρωμή με υπηρεσίες πληρωμής από τρίτους
            παρόχους και πληρωμές με χρεωστική ή πιστωτική κάρτα , σύμφωνα με τις
            υπηρεσίες που είναι διαθέσιμες στο ειδικό πεδίο της Εφαρμογής . Η διευθέτηση
            των πληρωμών με κάρτα πραγματοποιείται σύμφωνα με την επιλογή του χρήστη μέσω
            των εξουσιοδοτημένων υπηρεσιών.
          </Typography>
          <Typography variant={'body1'} paragraph>
            Στο μέγιστο βαθμό που επιτρέπεται από την ισχύουσα νομοθεσία, ενδέχεται να
            αλλάξουμε τις χρεώσεις αγοράς ανά πάσα στιγμή. Θα σας ειδοποιήσουμε εύλογα για
            οποιεσδήποτε τέτοιες αλλαγές στις τιμές δημοσιεύοντας τις νέες τιμές μέσω της
            Εφαρμογής ή/και στέλνοντάς σας μια ειδοποίηση μέσω ηλεκτρονικού ταχυδρομείου ή
            με άλλο εμφανή τρόπο. Εάν δεν επιθυμείτε να πληρώσετε τα νέα τέλη, μπορείτε να
            ακυρώσετε την ισχύουσα συνδρομή πριν τεθεί σε ισχύ η αλλαγή ή/και να απέχετε
            από την προπληρωμή για πρόσβαση στην Υπηρεσία.
          </Typography>
          <Typography variant={'body1'} paragraph>
            Εξουσιοδοτείτε εμάς να χρεώνουμε τις ισχύουσες χρεώσεις στην κάρτα πληρωμής
            που υποβάλλετε. Με την εγγραφή σας σε ορισμένες συνδρομές, συμφωνείτε ότι η
            συνδρομή σας μπορεί να ανανεωθεί αυτόματα. Εάν δεν ακυρώσετε τη συνδρομή σας,
            εξουσιοδοτείτε εμάς να σας χρεώνουμε για τη διάρκεια της ανανέωσης. Η περίοδος
            αυτόματης ανανέωσης θα είναι ίδια με την αρχική περίοδο συνδρομής σας, εκτός
            εάν ενημερωθείτε διαφορετικά στην Υπηρεσία.
          </Typography>
          <Typography variant={'body1'} paragraph>
            Ενδέχεται να προσφέρουμε μια περίοδο δοκιμαστικής συνδρομής για την Υπηρεσία.
            Η δοκιμαστική έκδοση σάς παρέχει πρόσβαση στην Υπηρεσία για ένα χρονικό
            διάστημα, με λεπτομέρειες που καθορίζονται κατά την εγγραφή σας στην προσφορά.
            Εκτός εάν ακυρώσετε πριν από το τέλος της δωρεάν δοκιμής ή εκτός εάν ορίζεται
            διαφορετικά, η πρόσβασή σας στην Υπηρεσία θα συνεχιστεί και θα χρεωθείτε με
            τις ισχύουσες χρεώσεις για την Υπηρεσία. Είναι τελικά δική σας ευθύνη να
            γνωρίζετε πότε θα τελειώσει η δωρεάν δοκιμή. Διατηρούμε το δικαίωμα, κατά την
            απόλυτη διακριτική μας ευχέρεια, να τροποποιήσουμε ή να τερματίσουμε
            οποιαδήποτε δωρεάν δοκιμαστική προσφορά, την πρόσβασή σας στην Υπηρεσία κατά
            τη διάρκεια της δωρεάν δοκιμής ή οποιονδήποτε από αυτούς τους όρους χωρίς
            προειδοποίηση και χωρίς καμία ευθύνη. Διατηρούμε το δικαίωμα να περιορίσουμε
            τη δυνατότητά σας να επωφεληθείτε από πολλαπλές δωρεάν δοκιμές. Η Υπηρεσία και
            τα δικαιώματά σας να τη χρησιμοποιήσετε λήγουν στο τέλος της περιόδου πληρωμής
            της συνδρομής σας. Διατηρούμε το δικαίωμα να απενεργοποιήσουμε ή να
            τερματίσουμε την πρόσβασή σας στην Υπηρεσία (και μπορεί να το κάνουμε χωρίς
            προειδοποίηση)αν δεν έχει καταβληθεί το σχετικό τέλος συνδρομής.
          </Typography>
          <Typography variant={'body1'} paragraph>
            Συμφωνείτε ότι η πληρωμή της συνδρομής είναι οριστική, ότι η Εταιρεία δεν θα
            επιστρέψει χρήματα για καμία συναλλαγή αφού πραγματοποιηθεί και ότι η συνδρομή
            δεν μπορεί να ακυρωθεί. Όταν πραγματοποιείτε την πληρωμή, αναγνωρίζετε και
            συμφωνείτε ότι όλες οι συνδρομές δεν επιστρέφονται ή ανταλλάσσονται.
            Ανεξάρτητα από οτιδήποτε αντίθετο στα παραπάνω, η Εταιρεία θα παρέχει
            επιστροφές χρημάτων ή/και ακυρώσεις συνδρομών σε περιπτώσεις και στον βαθμό
            που απαιτείται από τις υποχρεωτικές διατάξεις της ισχύουσας νομοθεσίας. Η
            Εταιρεία μπορεί επίσης να παρέχει επιστροφές χρημάτων κατά την κρίση της και
            με την επιφύλαξη των πολιτικών μας που ενδέχεται να δημοσιεύονται κατά
            καιρούς.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>
              Υπαναχώρηση/Ακύρωση Ενεργοποιημένων Συνδρομών:
            </Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            Ο χρήστης των Υπηρεσιών αναγνωρίζει , συγκατατίθεται και επιβεβαιώνει ότι θα
            απωλέσει το δικαίωμα υπαναχώρησης/ ακύρωσης, μόλις η σύμβαση αγοράς των
            υπηρεσιών της Εφαρμογής εκτελεστεί πλήρως και ενημερωθεί ότι ενεργοποιήθηκε η
            συνδρομητική υπηρεσία του HDCorner.
          </Typography>
          <Typography variant={'body1'} paragraph>
            Με την ενεργοποίηση παροχής των συνδρομητικών υπηρεσιών δεν ισχύει το δικαίωμα
            υπαναχώρησης για την παροχή των υπηρεσιών σύμφωνα με όσα προβλέπονται στα
            άρθρα 3ε έως 3ια του Νόμου 2251/1994 – ΦΕΚ 191/Α/16-11-1994 για την Προστασία
            των καταναλωτών, όπως συμπληρώθηκε με την Κ.Υ.Α. Ζ1-891/2013 – ΦΕΚ
            2144/Β/30-8-2013 για τις εξ αποστάσεως και εκτός εμπορικού καταστήματος
            συμβάσεις. Οι συνδρομητικές παρεχόμενες υπηρεσίες υπάγονται στις εξαιρέσεις
            του άρθρου 3ιβ του παραπάνω νόμου.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>Προσωπικά Δεδομένα.</Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            Το HD Corner σέβεται την ιδιωτικότητά σας. Για να λάβετε πληροφορίες για την
            πολιτική προστασίας των δεδομένων μας, συμβουλευτείτε την Πολιτική Απορρήτου
            που αναφέρεται κατωτέρω ,Με την πρόσβαση ή χρήση των Υπηρεσιών, αποδέχεστε πως
            το HD Corner μπορεί να συλλέξει και να χρησιμοποιήσει τις πληροφορίες που μας
            παρέχετε σύμφωνα με την Πολιτική Απορρήτου.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>Περιορισμός ευθύνης</Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            Η ΕΤΑΙΡΙΑ ΚΑΙ ΟΙ ΟΝΤΟΤΗΤΕΣ ΠΟΥ ΕΜΠΛΕΚΟΝΤΑΙ ΜΕ ΤΗ ΔΗΜΙΟΥΡΓΙΑ, ΤΗΝ ΠΑΡΑΓΩΓΗ Η
            ΤΗΝ ΠΑΡΟΧΗ ΥΠΗΡΕΣΙΩΝ ΔΕΝ ΕΥΘΥΝΟΝΤΑΙ ΓΙΑ ΤΥΧΟΝ ΑΚΟΥΣΙΕΣ, ΕΙΔΙΚΕΣ Η ΕΠΑΚΟΛΟΥΘΕΣ
            ΖΗΜΙΕΣ, ΣΥΜΠΕΡΙΛΑΜΒΑΝΟΜΕΝΗΣ ΤΗΣ ΑΠΩΛΕΙΑΣ ΚΕΡΔΩΝ, ΑΠΩΛΕΙΑΣ ΔΕΔΟΜΕΝΩΝ , ΔΙΑΚΟΠΗΣ
            ΤΗΣ ΥΠΗΡΕΣΙΑΣ, ΖΗΜΙΑΣ ΣΤΟ ΗΛΕΚΤΡΟΝΙΚΟ ΥΛΙΚΟ, Ή ΑΔΥΝΑΜΙΑΣ ΤΟΥ ΣΥΣΤΗΜΑΤΟΣ Ή
            ΔΑΠΑΝΕΣ ΓΙΑ ΤΗΝ ΑΝΤΙΚΑΤΑΣΤΑΣΗ ΤΩΝ ΥΠΗΡΕΣΙΩΝ ΠΟΥ ΠΡΟΚΥΠΤΟΥΝ ΑΠΟ Ή ΣΕ ΣΧΕΣΗ ΜΕ
            ΤΟΥΣ ΌΡΟΥΣ Η ΑΠΟ ΤΗ ΧΡΗΣΗ Η ΤΗΝ ΑΔΥΝΑΜΙΑ ΧΡΗΣΗΣ ΤΩΝ ΥΠΗΡΕΣΙΩΝ, ΠΟΥ ΒΑΣΙΖΟΝΤΑΙ
            ΣΕ ΟΠΟΙΑΔΉΠΟΤΕ ΕΓΓΥΗΣΗ, ΣΥΜΒΑΣΗ, ΑΔΙΚΟΠΡΑΞΙΑ (ΣΥΜΠΕΡΙΛΑΜΒΑΝΟΜΕΝΗΣ ΤΗΣ
            ΑΜΕΛΕΙΑΣ), ΔΙΑΤΑΞΕΙΣ ΕΥΘΥΝΗΣ ΠΡΟΪΟΝΤΟΣ Ή ΔΙΑΦΗΜΙΣΕΩΝ ΤΡΙΤΩΝ ΜΕΡΩΝ, Ή
            ΟΠΟΙΑΔΗΠΟΤΕ ΆΛΛΗ ΝΟΜΙΚΗ ΔΙΑΤΑΞΗ, ΑΚΟΜΑ ΚΑΙ ΑΝ ΈΧΟΥΝ ΕΝΗΜΕΡΩΘΕΙ ΓΙΑ ΤΗΝ
            ΠΙΘΑΝΟΤΗΤΑ ΑΥΤΩΝ ΤΩΝ ΖΗΜΙΩΝ.
          </Typography>
          <Typography variant={'body1'} paragraph>
            ΚΑΤΑ ΤΟ ΜΕΤΡΟ ΠΟΥ ΕΠΙΤΡΕΠΕΤΑΙ ΑΠΟ ΤΟΝ ΝΟΜΟ, ΣΕ ΚΑΜΙΑ ΠΕΡΙΠΤΩΣΗ Η ΣΥΝΟΛΙΚΗ
            ΕΥΘΥΝΗ ΤΗΣ ΕΦΑΡΜΟΓΗΣ ΠΟΥ ΠΡΟΚΥΠΤΕΙ ΑΠΟ Ή ΣΕ ΣΧΕΣΗ ΜΕ ΤΟΥΣ ΠΑΡΟΝΤΕΣ ΌΡΟΥΣ Ή ΑΠΟ
            ΤΗ ΧΡΗΣΗ Ή ΤΗΝ ΑΔΥΝΑΜΙΑ ΧΡΗΣΗΣ ΤΩΝ ΥΠΗΡΕΣΙΩΝ ΔΕΝ ΘΑ ΥΠΕΡΒΑΙΝΕΙ ΤΗΝ ΑΞΙΑ ΤΗΣ
            ΣΥΝΔΡΟΜΗΣ ΠΟΥ ΈΧΕΤΕ ΠΛΗΡΩΣΕΙ ΤΟΥΣ ΤΕΛΕΥΤΑΙΟΥΣ ΔΩΔΕΚΑ ΜΗΝΕΣ.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>
              Ενημερωτικά δελτία (news letters)
            </Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            Με την αποδοχή των όρων χρήσης της Εφαρμογής και την εγγραφή του χρήστη στις
            Υπηρεσίες του HDCORNER, θα μπορούν να αποστέλλονται σε αυτόν ενημερωτικά
            δελτία (newsletters) στην διεύθυνση ηλεκτρονικού ταχυδρομείου που έχει δηλώσει
            κατά την εγγραφή του .Σε περίπτωση που ο χρήστης δεν επιθυμεί στο μέλλον την
            αποστολή ενημερωτικών δελτίων - ειδήσεων του HD CORNER , μπορεί να προβεί
            άμεσα στην διαγραφή της ηλεκτρονικής διεύθυνσης ταχυδρομείου του από την λίστα
            διευθύνσεων αποδεκτών τέτοιων μηνυμάτων, χρησιμοποιώντας τον ειδικό σύνδεσμο
            διαγραφής (unsubscribe) που υπάρχει σε κάθε τέτοιο ενημερωτικό δελτίο ( ν.
            3741/2006 αρ. 11 παρ. 3 και 4).
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>Δικαιοδοσία</Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            Οι παρόντες Όροι Χρήσης διέπονται από το Ελληνικό δίκαιο. Ο χρήστης αποδέχεται
            ρητά ότι την αποκλειστική δικαιοδοσία και αρμοδιότητα για οποιαδήποτε διαφορά
            προκύψει με την χρήση της Εφαρμογής την έχουν τα Δικαστήρια της Αθήνας. Αν
            οποιοσδήποτε από τους ανωτέρω όρους αποδειχθεί άκυρος, αυτό δεν επηρεάζει την
            εγκυρότητα και ισχύ των υπολοίπων όρων οι οποίοι παραμένουν σε πλήρη ισχύ.
          </Typography>
          <Typography variant={'body1'} paragraph>
            Η ΧΡΗΣΗ ΤΗΣ ΕΦΑΡΜΟΓΗΣ , ΟΙ ΥΠΗΡΕΣΙΕΣ ΚΑΙ ΤΟ ΠΕΡΙΕΧΟΜΕΝΟ ΤΗΣ ΥΠΟΚΕΙΝΤΑΙ ΣΤΟΥΣ
            ΑΝΩΤΕΡΩ ΌΡΟΥΣ ΧΡΗΣΗΣ. ΑΝ Ο ΧΡΗΣΤΗΣ – ΕΠΙΣΚΕΠΤΗΣ ΘΕΩΡΕΙ ΤΟΥΣ ΟΡΟΥΣ ΑΥΤΟΥΣ ΜΗ
            ΑΠΟΔΕΚΤΟΥΣ, ΠΑΡΑΚΑΛΕΙΤΑΙ ΝΑ ΜΗΝ ΚΑΤΕΒΑΣΕΙ ΤΗΝ ΕΦΑΡΜΟΓΗ Η ΝΑ ΚΑΝΕΙ ΧΡΗΣΗ ΤΩΝ
            ΥΠΗΡΕΣΙΩΝ ΤΗΣ .
          </Typography>
          <Typography variant={'subtitle1'} paragraph>
            Επικοινωνία
          </Typography>
          <Typography variant={'body1'} paragraph>
            Εάν θέλετε να επικοινωνήσετε μαζί μας σχετικά τους παρόντες Όρους χρήσης ή
            έχετε οποιαδήποτε άλλη απορία σχετικά με την παροχή των Υπηρεσιών μπορείτε να
            στείλετε μήνυμα στο info@hdcorner.com.
          </Typography>
          <Typography variant={'body1'} paragraph>
            Τελευταία ημερομηνία ενημέρωσης 08/01/2024
          </Typography>
        </ol>
      </Box>
      <Box>
        <Typography variant={'h1'} paragraph>
          ΠΟΛΙΤΙΚΗ ΠΡΟΣΤΑΣΙΑΣ ΠΡΟΣΩΠΙΚΩΝ ΔΕΔΟΜΕΝΩΝ
        </Typography>
        <Typography variant={'body1'} paragraph>
          Η παρούσα Πολιτική Προστασίας προσωπικών Δεδομένων αφορά την επεξεργασία των
          προσωπικών δεδομένων σας κατά την χρήση της Εφαρμογής HDCORNER ( εφεξής η
          «Εφαρμογή»)
        </Typography>
        <ol>
          <li>
            <Typography variant={'subtitle1'}>
              Υπεύθυνος Επεξεργασίας – Στοιχεία επικοινωνίας
            </Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            «ΚΑΡΑΜΠΙΝΗΣ MEDICAL Α.Ε. ΠΑΡΑΓΩΓΗΣ ΕΜΠΟΡΙΑΣ ΙΑΤΡΟΤΕΧΝΟΛΟΓΙΚΩΝ ΠΡΟΪΟΝΤΩΝ ΚΑΙ
            ΠΑΡΟΧΗΣ ΥΠΗΡΕΣΙΩΝ», που εδρεύει στην Παιανία Αττικής, επί της Λεωφ. Λαυρίου,
            αριθ. 151, με Α.Φ.Μ.: 099052507 – Δ.Ο.Υ.: ΦΑΕ Αθηνών- ΑΡ. ΓΕΜΗ 87183102000 ,
            τηλ. 2106645751 ( κατωτέρω η «Εταιρία»)
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>
              Ποια προσωπικά μου δεδομένα συλλέγονται και πώς;
            </Typography>
          </li>
          <Typography variant={'body1'}>
            Κατά την εγγραφή σας στην Εφαρμογή (sign up) παρέχετε τα κάτωθι προσωπικά σας
            δεδομένα:
          </Typography>
          <ul>
            <li>
              <Typography variant={'body1'}>Ονοματεπώνυμο</Typography>
            </li>
            <li>
              <Typography variant={'body1'}>Ημερομηνία γέννησης</Typography>
            </li>
            <li>
              <Typography variant={'body1'}>Φύλο</Typography>
            </li>
            <li>
              <Typography variant={'body1'}>Στοιχεία επικοινωνίας</Typography>
            </li>
          </ul>
          <Typography variant={'body1'}>
            Κατά την εγγραφή σας στην Εφαρμογή (sign up) ως επαγγελματίας υγείας παρέχετε
            τα κάτωθι προσωπικά σας δεδομένα:
          </Typography>
          <ul>
            <li>
              <Typography variant={'body1'}>ονοματεπώνυμο,</Typography>
            </li>
            <li>
              <Typography variant={'body1'}>ειδικότητα,</Typography>
            </li>
            <li>
              <Typography variant={'body1'}>ΑΦΜ,</Typography>
            </li>
            <li>
              <Typography variant={'body1'}>διεύθυνση, Αριθμός, ΤΚ, περιοχή,</Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                τηλέφωνο επικοινωνίας και ηλεκτρονική διεύθυνση
              </Typography>
            </li>
          </ul>
          <Typography variant={'body1'}>
            Κατά τη χρήση των υπηρεσιών της Εφαρμογής ως χρήστης έχετε την δυνατότητα να
            εισάγετε τα κάτωθι δεδομένα:
          </Typography>
          <ul>
            <li>
              <Typography variant={'body1'}>
                Δεδομένα υγείας (π.χ. μετρήσεις διαβήτη, αρτηριακή πίεση, διαγνωστικές
                εξετάσεις κλπ.) που εισάγετε στην πλατφόρμα αν το επιθυμείτε
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                Στοιχεία που αφορούν αθλητικές δραστηριότητες, διατροφικές συνήθειες
              </Typography>
            </li>
            <li>
              <Typography variant={'body1'}>
                Τα στοιχεία της πιστωτική σας κάρτας ( αριθμός, όνομα κατόχου, κωδικός
                ασφαλείας) που μας παρέχετε κατά την πληρωμή της υπηρεσίας δεν συλλέγονται
                από την Εφαρμογή, αλλά παρέχονται απευθείας στον εξουσιοδοτημένο πάροχο
                προς τον οποίο γίνεται ανακατεύθυνση κατά την διαδικασία πληρωμής .
              </Typography>
            </li>
          </ul>
          <li>
            <Typography variant={'subtitle1'}>
              Για ποιους σκοπούς γίνεται η επεξεργασία και ποια η νομική της βάση;
            </Typography>
          </li>
          <Typography variant={'body1'}>
            Η επεξεργασία των δεδομένων σας γίνεται για να σας παρέχουμε την πρόσβαση στις
            υπηρεσίες του HDCORNER. Η νομική βάση για αυτήν την επεξεργασία είναι η
            εκτέλεση σύμβασης (α. 6 παρ. 2 β’ ΓΚΠΔ).
          </Typography>
          <Typography variant={'body1'}>
            Για τα δεδομένα υγείας που μας παρέχετε η νομική βάση είναι η εκτέλεση
            σύμβασης και η α. 9 παρ.1 περ. η’
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>
              Ποιοι είναι οι αποδέκτες των προσωπικών μου δεδομένων;
            </Typography>
          </li>
          <Typography variant={'body1'}>
            Πρόσβαση στα προσωπικά σας δεδομένα έχει ο πάροχος της πλατφόρμας που ενεργεί
            ως εκτελών την επεξεργασία καθώς και ο γιατρός ή φαρμακοποιός ή άλλος
            επαγγελματίας υγείας που εσείς θα επιλέξετε να δώσετε πρόσβαση στα δεδομένα
            σας . O επαγγελματίας υγείας θα μπορεί να βλέπει τα δεδομένα που καταχωρείτε
            στην πλατφόρμα, μόνο εφόσον εσείς οι ίδιοι του δώσετε πρόσβαση.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>
              Πόσο χρόνο διατηρούνται τα δεδομένα μου;
            </Typography>
          </li>
          <Typography variant={'body1'}>
            Τα δεδομένα σας θα τηρηθούν αυστηρώς για όσο χρονικό διάστημα είστε
            εγγεγραμμένοι στην υπηρεσία. Σε περίπτωση διαγραφής σας από την υπηρεσία θα
            διαγράφονται αμέσως. Θα διαγράφονται επίσης σε περίπτωση που μας υποβάλλετε
            αίτημα διαγραφής.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>Cookies</Typography>
          </li>
          <Typography variant={'body1'}>
            Η εφαρμογή δεν χρησιμοποιεί cookies όταν ο χρήστης εισέρχεται σε αυτήν, είτε
            μέσω του κινητού τηλεφώνου είτε μέσω της web πλατφόρμας.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>
              Πως προστατεύονται τα δεδομένα μου;
            </Typography>
          </li>
          <Typography variant={'body1'}>
            Τα δεδομένα σας τηρούνται σε εξωτερικό server με όλα τα απαραίτητα μέτρα
            ασφαλείας. Ειδικά για τα δεδομένα υγείας που καταχωρείτε στην πλατφόρμα, αυτά
            υπόκεινται σε κρυπτογράφηση και ψευδωνυμοποίηση, ως εκ τούτου δεν μπορεί
            κανένας αποδέκτης να τα συσχετίσει με το πρόσωπό σας, εκτός από τον
            επαγγελματία υγείας στον οποίο εσείς δίνετε πρόσβαση.
          </Typography>
          <li>
            <Typography variant={'subtitle1'}>Ποια είναι τα δικαιώματά μου;</Typography>
          </li>
          <li>
            <Typography variant={'body1'}>
              Ως υποκείμενο των δεδομένων έχετε τα ακόλουθα δικαιώματα:
            </Typography>
          </li>
          <table>
            <tbody style={{ verticalAlign: 'top' }}>
              <tr>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>Δικαίωμα ενημέρωσης</Typography>
                </td>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>
                    Έχετε το δικαίωμα να ενημερώνεστε για τη συλλογή, τον τρόπο
                    επεξεργασίας και φύλαξης των προσωπικών σας δεδομένων
                  </Typography>
                </td>
              </tr>
              <tr>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>Δικαίωμα πρόσβαση</Typography>
                </td>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>
                    Έχετε το δικαίωμα να λαμβάνετε την επιβεβαίωση για το κατά πόσον ή όχι
                    τα δεδομένα σας υφίστανται επεξεργασία και, εάν συμβαίνει τούτο, έχετε
                    το δικαίωμα πρόσβασης στα δεδομένα σας σε σύντομη, κατανοητή, διαφανή
                    και εύκολα προσβάσιμη μορφή .
                  </Typography>
                </td>
              </tr>
              <tr>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>Δικαίωμα διόρθωσης</Typography>
                </td>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>
                    Μπορείτε να ζητήσετε τη διόρθωση ανακριβών ή ελλιπών προσωπικών
                    δεδομένων που σας αφορούν.
                  </Typography>
                </td>
              </tr>
              <tr>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>Δικαίωμα διαγραφής</Typography>
                </td>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>
                    Έχετε το δικαίωμα να ζητήσετε τη διαγραφή των δεδομένων που σας
                    αφορούν, αν κρίνετε ότι αυτά τηρούνται με μη σύννομο τρόπο και η
                    Εταιρεία υποχρεούται να προβεί στη διαγραφή εκτός αν υπάρχει νόμιμος
                    λόγος διατήρησής τους.
                  </Typography>
                </td>
              </tr>
              <tr>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>
                    Δικαίωμα στη φορητότητα των δεδομένων
                  </Typography>
                </td>
                <td style={{ paddingBottom: '10px' }}>
                  <Typography variant={'body1'}>
                    Έχετε το δικαίωμα να λαμβάνετε τα δεδομένα προσωπικού χαρακτήρα που
                    σας αφορούν και τα οποία έχει παράσχει στην Εταιρεία σε κοινώς
                    χρησιμοποιούμενο και αναγνώσιμο από μηχανήματα μορφότυπο, καθώς και το
                    δικαίωμα να ζητάτε τη διαβίβαση των λόγω δεδομένων σε άλλον υπεύθυνο
                    επεξεργασίας, υπό τις προϋποθέσεις που ορίζει ο νόμος.
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
          <Typography variant={'body1'} paragraph>
            Θα απαντήσουμε στο αίτημά σας χωρίς καθυστέρηση και σε κάθε περίπτωση εντός
            μηνός από την παραλαβή του. Η εν λόγω προθεσμία παρατείνεται για δύο ακόμα
            μήνες, εφόσον είναι απαραίτητο, λαμβάνοντας υπόψη την πολυπλοκότητα του
            αιτήματος και το πλήθος των αιτημάτων. Θα σας ενημερώσουμε για την εν λόγω
            παράταση εντός μηνός από την παραλαβή του αιτήματος, καθώς και για τους λόγους
            της καθυστέρησης.
          </Typography>
          <Typography variant={'body1'} paragraph>
            Η εταιρεία έχει σε κάθε περίπτωση δικαίωμα να αρνηθεί την ικανοποίηση του
            αιτήματός σας για περιορισμό της επεξεργασίας ή διαγραφή των προσωπικών σας
            δεδομένων, εάν η επεξεργασία αυτή είναι απαραίτητη για τη θεμελίωση, άσκηση ή
            υποστήριξη νόμιμων δικαιωμάτων της ή την εκπλήρωση υποχρεώσεών της.
          </Typography>
          <li>
            <Typography variant={'subtitle1'} paragraph>
              Πως μπορείτε να ασκήσετε τα δικαιώματά σας:
            </Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            Για να ασκήσετε τα ανωτέρω δικαιώματά σας μπορείτε να επικοινωνείτε με τον
            Υπεύθυνο Προστασίας Δεδομένων στην ηλεκτρονική διεύθυνση
            dataprotection@karabinismedical.gr είτε τηλεφωνικώς στο 210-6645751 είτε
            ταχυδρομικώς στη διεύθυνση εταιρεία ΚΑΡΑΜΠΙΝΗΣ MEDICAL ΑΕ, Λεωφ. Λαυρίου 151,
            19005 Παιανία -Υπόψη Υπευθύνου Προστασίας Δεδομένων.
          </Typography>
          <li>
            <Typography variant={'subtitle1'} paragraph>
              Αρχή Προστασίας Δεδομένων Προσωπικού Χαρακτήρα
            </Typography>
          </li>
          <Typography variant={'body1'} paragraph>
            Έχετε δικαίωμα να υποβάλλετε καταγγελία στην Αρχή Προστασίας Δεδομένων
            Προσωπικού Χαρακτήρα (www.dpa.gr), η οποία είναι η αρμόδια εποπτική αρχή για
            την προστασία των θεμελιωδών δικαιωμάτων και ελευθεριών των φυσικών προσώπων
            έναντι της επεξεργασίας των προσωπικών τους δεδομένων του, εφόσον θεωρείτε ότι
            προσβάλλονται τα δικαιώματά σας με οποιοδήποτε τρόπο
          </Typography>
          <Typography variant={'body1'} paragraph>
            Τελευταία ημερομηνία ενημέρωσης 08/01/2024
          </Typography>
        </ol>
      </Box>
    </Box>
  );
};

export default TermsAndConditionsGR;
