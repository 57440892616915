import React, { FC } from 'react';
import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ToggleButtons from '../layout/ToggleButtons';

const Container = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const Text = styled(Typography, {
  shouldForwardProp: propName => propName !== 'label' && propName !== 'lipidColor',
})<TypographyProps & { label?: boolean; lipidColor?: boolean }>(
  ({ theme, lipidColor, label }) => ({
    textTransform: 'capitalize',
    color: lipidColor ? theme.palette.lipid.main : theme.palette.primary.main,
    marginRight: label ? theme.spacing(1) : 0,
    fontWeight: label
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightBold,
  }),
);

type ToggleButtonsChartType = {
  toggleButtonsValue: string;
  headings: { label: string; value: string }[];
  handleChangeOption: (newValue: string) => void;
  values: { label: string; value: number | string }[];
};
const ToggleButtonsChart: FC<ToggleButtonsChartType> = ({
  values,
  headings,
  toggleButtonsValue,
  handleChangeOption,
}) => {
  const theme = useTheme();
  const web = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Container>
      <Box display={web ? 'none' : 'flex'}>
        <ToggleButtons
          size={'small'}
          headings={headings}
          value={toggleButtonsValue}
          color={theme.palette.lipid.main}
          handleChange={handleChangeOption}
        />
      </Box>
      <Box
        display={'flex'}
        alignItems={'flex-end'}
        flexWrap={web ? 'wrap' : 'nowrap'}
        flexDirection={web ? 'row' : 'column'}
      >
        {values.map((button, index) => (
          <Box key={index} display={'flex'} mr={web ? 3 : 0} alignItems={'center'}>
            <Text
              label
              variant={web ? 'body1' : 'caption'}
              lipidColor={button.label === 'Avg'}
              color={web ? 'kmColorsDarkGrey' : 'primary'}
            >
              {button.label}
            </Text>
            <Text
              lipidColor={button.label === 'Avg'}
              variant={web ? 'subtitle2' : 'body1'}
              color={web ? 'kmColorsDarkGrey' : 'primary'}
            >
              {button.value}
            </Text>
          </Box>
        ))}
      </Box>
    </Container>
  );
};

export default ToggleButtonsChart;
