import PageLayout from '../../../components/PageLayout';
import { ComponentsLayout } from '@hdcorner/ui-library';
import React from 'react';
import { useAppSelector } from '../../../redux/hooks';
import ArticleDetailsInner from './components/ArticleDetailsInner';

const Articles = () => {
  const { article } = useAppSelector(state => state.articles);

  return (
    <PageLayout
      headerTitle={article.title}
      defaultHref={'/dashboard/fitness/nutrition/articles'}
    >
      <ComponentsLayout>
        <ArticleDetailsInner />
      </ComponentsLayout>
    </PageLayout>
  );
};

export default Articles;
