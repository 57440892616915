import * as React from 'react';
import { SVGProps } from 'react';

const WeightIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5022 8.19226C12.6871 7.7624 12.9039 7.33254 12.9931 7.2314C13.1206 7.07968 13.1907 7.04808 13.4393 7.04808C14.0258 7.04808 14.1979 7.53483 13.8601 8.24916C13.7708 8.4388 13.6433 8.72327 13.5796 8.8813C13.5318 8.99981 13.4302 9.23563 13.3286 9.47147L13.229 9.7031C12.6737 10.9732 12.6743 10.9738 12.7733 11.084L12.7764 11.0875C12.9485 11.2771 13.0823 11.6817 13.0887 12.042C13.0951 13.0092 12.1771 13.7678 11.24 13.5718C11.0424 13.5339 10.7683 13.4201 10.6344 13.3316C10.3284 13.1167 10.0352 12.6805 9.97781 12.3518C9.92681 12.0484 9.98419 11.5743 10.1053 11.3467C10.3093 10.9547 10.9085 10.5249 11.2464 10.5249C11.4759 10.5249 11.546 10.4237 11.8711 9.66517C11.973 9.43214 12.1184 9.09107 12.2581 8.7635L12.2585 8.76244C12.3459 8.55745 12.4311 8.35756 12.5022 8.19226Z"
        fill={props?.color ?? '#E93C16'}
      />
      <path
        d="M7.5 7.5C7.77614 7.5 8 7.27614 8 7C8 6.72386 7.77614 6.5 7.5 6.5C7.22386 6.5 7 6.72386 7 7C7 7.27614 7.22386 7.5 7.5 7.5Z"
        fill={props?.color ?? '#E93C16'}
      />
      <path
        d="M10 5C10 5.27614 9.77614 5.5 9.5 5.5C9.22386 5.5 9 5.27614 9 5C9 4.72386 9.22386 4.5 9.5 4.5C9.77614 4.5 10 4.72386 10 5Z"
        fill={props?.color ?? '#E93C16'}
      />
      <path
        d="M16.5 7.5C16.7761 7.5 17 7.27614 17 7C17 6.72386 16.7761 6.5 16.5 6.5C16.2239 6.5 16 6.72386 16 7C16 7.27614 16.2239 7.5 16.5 7.5Z"
        fill={props?.color ?? '#E93C16'}
      />
      <path
        d="M15 5C15 5.27614 14.7761 5.5 14.5 5.5C14.2239 5.5 14 5.27614 14 5C14 4.72386 14.2239 4.5 14.5 4.5C14.7761 4.5 15 4.72386 15 5Z"
        fill={props?.color ?? '#E93C16'}
      />
      <path
        d="M12 4.8999C12.2761 4.8999 12.5 4.67604 12.5 4.3999C12.5 4.12376 12.2761 3.8999 12 3.8999C11.7239 3.8999 11.5 4.12376 11.5 4.3999C11.5 4.67604 11.7239 4.8999 12 4.8999Z"
        fill={props?.color ?? '#E93C16'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C14.5154 2 16.7272 3.2865 17.9998 5.23077H19.1429C20.7208 5.23077 22 6.49643 22 8.05769V20.1731C22 21.7343 20.7208 23 19.1429 23H4.85714C3.27919 23 2 21.7343 2 20.1731V8.05769C2 6.49643 3.27919 5.23077 4.85714 5.23077H6.00024C7.27283 3.2865 9.48457 2 12 2ZM12 14.5192C15.0432 14.5192 17.5102 12.0783 17.5102 9.06731C17.5102 6.05629 15.0432 3.61538 12 3.61538C8.9568 3.61538 6.4898 6.05629 6.4898 9.06731C6.4898 12.0783 8.9568 14.5192 12 14.5192ZM12 16.1346C15.9449 16.1346 19.1429 12.9705 19.1429 9.06731C19.1429 8.29137 19.0165 7.54463 18.783 6.84615H19.1429C19.8191 6.84615 20.3673 7.38858 20.3673 8.05769V20.1731C20.3673 20.8422 19.8191 21.3846 19.1429 21.3846H4.85714C4.18088 21.3846 3.63265 20.8422 3.63265 20.1731V8.05769C3.63265 7.38858 4.18088 6.84615 4.85714 6.84615H5.21704C4.98353 7.54463 4.85714 8.29137 4.85714 9.06731C4.85714 12.9705 8.05511 16.1346 12 16.1346Z"
        fill={props?.color ?? '#E93C16'}
      />
    </svg>
  );
};

export default WeightIcon;
