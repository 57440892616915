import * as React from 'react';
import { SVGProps } from 'react';
import { Box, BoxProps, styled } from '@mui/material';

const IconBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: '40px',
  height: '40px',
  borderRadius: '8px',
  padding: '8px',
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#8C55E7',
}));
const ReportsSQ = (props: SVGProps<SVGSVGElement>) => {
  return (
    <IconBox>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M21 1H3C2.73478 1 2.48043 1.10536 2.29289 1.29289C2.10536 1.48043 2 1.73478 2 2V22C2 22.2652 2.10536 22.5196 2.29289 22.7071C2.48043 22.8946 2.73478 23 3 23H17C17.1315 22.9995 17.2616 22.9733 17.383 22.923C17.5045 22.8727 17.615 22.799 17.708 22.706L21.708 18.706C21.8843 18.5117 21.9875 18.2621 22 18V2C22 1.73478 21.8946 1.48043 21.7071 1.29289C21.5196 1.10536 21.2652 1 21 1ZM16 18V21H4V3H20V17H17C16.7348 17 16.4804 17.1054 16.2929 17.2929C16.1054 17.4804 16 17.7348 16 18ZM17 10C17 10.2652 16.8946 10.5196 16.7071 10.7071C16.5196 10.8946 16.2652 11 16 11H8C7.73478 11 7.48043 10.8946 7.29289 10.7071C7.10536 10.5196 7 10.2652 7 10C7 9.73478 7.10536 9.48043 7.29289 9.29289C7.48043 9.10536 7.73478 9 8 9H16C16.2652 9 16.5196 9.10536 16.7071 9.29289C16.8946 9.48043 17 9.73478 17 10ZM13 14C13 14.2652 12.8946 14.5196 12.7071 14.7071C12.5196 14.8946 12.2652 15 12 15H8C7.73478 15 7.48043 14.8946 7.29289 14.7071C7.10536 14.5196 7 14.2652 7 14C7 13.7348 7.10536 13.4804 7.29289 13.2929C7.48043 13.1054 7.73478 13 8 13H12C12.2652 13 12.5196 13.1054 12.7071 13.2929C12.8946 13.4804 13 13.7348 13 14Z"
          fill="#FBFBFB"
        />
      </svg>
    </IconBox>
  );
};

export default ReportsSQ;
