import React from 'react';
import PageLayout from '../../../components/PageLayout';
import { ComponentsLayout } from '@hdcorner/ui-library';
import SearchActivityPanel from './components/SearchActivityPanel';
import { useTranslation } from 'react-i18next';

const SearchActivity = () => {
  const { t } = useTranslation();

  return (
    <PageLayout
      defaultHref={'/dashboard/fitness/exercise'}
      headerTitle={t('fitness.exercise.labels.activity')}
    >
      <ComponentsLayout>
        <SearchActivityPanel />
      </ComponentsLayout>
    </PageLayout>
  );
};

export default SearchActivity;
