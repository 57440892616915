const customDate = [
  { label: 'constants.toggleValues.dates.customDate', value: 'custom' },
];

const monthYearCustom = [
  { label: 'constants.toggleValues.dates.all', value: 'all' },
  { label: 'constants.toggleValues.dates.month', value: 'month' },
  { label: 'constants.toggleValues.dates.year', value: 'year' },
  { label: 'constants.toggleValues.dates.custom', value: 'custom' },
];

const weekMonthYearCustom = [
  { label: 'constants.toggleValues.dates.all', value: 'all' },
  { label: 'constants.toggleValues.dates.week', value: 'week' },
  { label: 'constants.toggleValues.dates.month', value: 'month' },
  { label: 'constants.toggleValues.dates.year', value: 'year' },
  { label: 'constants.toggleValues.dates.custom', value: 'custom' },
];

const dayWeekMonthYearCustom = [
  { label: 'constants.toggleValues.dates.all', value: 'all' },
  { label: 'constants.toggleValues.dates.day', value: 'day' },
  { label: 'constants.toggleValues.dates.week', value: 'week' },
  { label: 'constants.toggleValues.dates.month', value: 'month' },
  { label: 'constants.toggleValues.dates.year', value: 'year' },
  { label: 'constants.toggleValues.dates.custom', value: 'custom' },
];

/** This Month - This Year  🤦🏻*/
const indepthDataTime = [
  { label: 'constants.toggleValues.dates.allData', value: 'all' },
  { label: 'constants.toggleValues.dates.thisMonth', value: 'month' },
  { label: 'constants.toggleValues.dates.thisYear', value: 'year' },
  { label: 'constants.toggleValues.dates.custom', value: 'custom' },
];

const fitnessHeadings = [
  { label: 'general.labels.overview', value: 'overview' },
  { label: 'fitness.calories.titles.calories', value: 'calories' },
  { label: 'headingsTitles.nutrition', value: 'nutrition' },
  { label: 'headingsTitles.exercise', value: 'exercise' },
  { label: 'headingsTitles.wellness', value: 'wellness' },
  { label: 'dashboard.dashcards.fitness.workouts', value: 'workouts' },
];

const hypertensionHeadings = [
  { label: 'hypertension.titles.inDepth', value: 'indepth', disabled: false },
  { label: 'hypertension.titles.eshMode', value: 'esh', disabled: false },
  { label: 'hypertension.titles.24hrs', value: '24hrs', disabled: false },
];

const dataGraphs = [
  { label: 'constants.toggleValues.data.data', value: 'data' },
  { label: 'constants.toggleValues.data.graphs', value: 'graphs' },
];

const userHCPHeadings = [
  { label: 'constants.toggleValues.data.data', value: 'data' },
  { label: 'general.labels.userInfo', value: 'user info' },
  { label: 'records.titles.records', value: 'records' },
  { label: 'devices.labels.devices', value: 'devices' },
];

const whenTakenDiabetes = [
  { label: 'constants.diabetes.timing.default', value: 'default' },
  { label: 'constants.diabetes.timing.beforeMeal', value: 'before_meal' },
  { label: 'constants.diabetes.timing.withMeal', value: 'with_meal' },
  { label: 'constants.diabetes.timing.afterMeal', value: 'after_meal' },
  { label: 'constants.diabetes.timing.sports', value: 'sports' },
  { label: 'constants.diabetes.timing.ill', value: 'ill' },
  { label: 'constants.diabetes.timing.special', value: 'special' },
];

export {
  dataGraphs,
  customDate,
  indepthDataTime,
  fitnessHeadings,
  monthYearCustom,
  userHCPHeadings,
  whenTakenDiabetes,
  weekMonthYearCustom,
  hypertensionHeadings,
  dayWeekMonthYearCustom,
};
