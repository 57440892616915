import ModalMobile from './ModalMobile';
import ModalHeading from './ModalHeading';
import React, { FC, useState } from 'react';
import ToggleCard from '../cards/ToggleCard';
import CustomButton from '../controls/CustomButton';
import { Box, BoxProps, styled, useTheme } from '@mui/material';
import ClickNavCardNutrition from '../cards/ClickNavCardNutrition';
import { useTranslation } from 'react-i18next';

const chatReasons = [
  { label: 'headingsTitles.nutrition', value: 'Nutrition' },
  { label: 'dashboard.dashcards.medication.title', value: 'Medication' },
  { label: 'dashboard.dashcards.diabetes.title', value: 'Diabetes' },
  { label: 'dashboard.dashcards.hypertension.title', value: 'Hypertension' },
  { label: 'headingsTitles.exercise', value: 'Exercise' },
  { label: 'dashboard.dashcards.cvd.title', value: 'CVD' },
  { label: 'dashboard.dashcards.lipid.title', value: 'Lipid Profile' },
  { label: 'constants.fileTypes.other', value: 'Other' },
];

const OuterContainer = styled(Box)<BoxProps>(({ theme }) => ({
  flex: 1,
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const LeftContainer = styled(Box)<BoxProps>(({ theme }) => ({
  width: 260,
  maxWidth: 260,
  height: '100%',
  display: 'flex',
  boxSizing: 'border-box',
  flexDirection: 'column',
  justifyContent: 'start',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  padding: theme.spacing(3),
  background: theme.palette.secondary.main,
}));

const RightContainer = styled(Box)<BoxProps>(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  boxSizing: 'border-box',
  flexDirection: 'column',
  padding: 0,
  width: 'auto',
  maxWidth: 'auto',
  [theme.breakpoints.up('md')]: {
    width: 398,
    maxWidth: 398,
    padding: theme.spacing(3),
  },
  background: theme.palette.secondary.light,
}));

type ModalRequestInfoProps = {
  web: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
  handleModalSaveClick: (data: { selected: string } | any) => void;
};
const ModalRequestInfo: FC<ModalRequestInfoProps> = ({
  web,
  open,
  setOpen,
  handleModalSaveClick,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [selected, setSelected] = useState<string>('');

  const handleChange = (newValue: string) => {
    setSelected(newValue);
  };

  const handleSaveData = () => {
    const data = { selected };
    handleModalSaveClick(data);
  };

  return (
    <ModalMobile height={web ? '480px' : 'auto'} open={open} setOpen={setOpen}>
      <OuterContainer>
        <LeftContainer>
          <ModalHeading
            align={'left'}
            showCalendar={'none'}
            title={t('chat.labels.messageReq')}
          />
          <Box height={35}>
            <ClickNavCardNutrition
              headings={t('general.labels.requestInfo')}
              textColor={theme.palette.kmColorsRed.main}
              body={t('dashboard.dashcards.medication.title')}
            />
          </Box>
          <Box
            justifyContent={'center'}
            display={web ? 'flex' : 'none'}
            marginTop={theme.spacing(3)}
          >
            <CustomButton
              fullWidth
              color={'primary'}
              variant={'contained'}
              onClick={handleSaveData}
              disabled={selected === ''}
              children={t('general.labels.choose')}
              sx={{
                height: '48px',
                opacity: '0.24',
                '&:hover': {
                  opacity: '1',
                },
              }}
            />
          </Box>
        </LeftContainer>

        <RightContainer>
          <Box display={web ? 'none' : 'block'}>
            <ModalHeading
              align={'left'}
              showCalendar={'none'}
              title={t('general.labels.requestInfo')}
            />
          </Box>
          <Box>
            {chatReasons.map((it, index) => (
              <ToggleCard
                key={index}
                value={it.value}
                label={t(it.label)}
                textTransform={'none'}
                setSelected={handleChange}
                selected={selected === it.value}
                backgroundColor={theme.palette.secondary.main}
              />
            ))}
          </Box>

          <Box
            justifyContent={'center'}
            marginTop={theme.spacing(3)}
            display={web ? 'none' : 'flex'}
          >
            <CustomButton
              fullWidth
              color={'primary'}
              variant={'contained'}
              onClick={handleSaveData}
              disabled={selected === ''}
              children={t('buttons.contained')}
              sx={{
                height: '48px',
                opacity: '0.24',
                '&:hover': {
                  opacity: '1',
                },
              }}
            />
          </Box>
        </RightContainer>
      </OuterContainer>
    </ModalMobile>
  );
};

export default ModalRequestInfo;
