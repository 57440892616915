import SectionHeading from '../../components/SectionHeading';
import { Box } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import WellnessCards from '../../wellness/components/WellnessCards';
import { ExerciseIcon, theme } from '@hdcorner/ui-library';
import ActivitiesIcon from '../../../../assets/icons/ActivitiesIcon';
import { useIonRouter } from '@ionic/react';
import { useGetTodayStepsLogsQuery } from '../queries/stepsQueries';
import { useGetTodaysActivityLogsQuery } from '../queries/activitiesQueries';
import { calculateActivityLogs } from '../utils/calculateLogs';
import useAlert from '../../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

const SectionExercise = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { presentError } = useAlert();

  const { data: stepsLogs, error: stepsError } = useGetTodayStepsLogsQuery();
  const { data: activityLogs, error: activityError } = useGetTodaysActivityLogsQuery();

  useEffect(() => {
    if (stepsError) {
      presentError(t('errors.fitness.exercise.errorStepsFetchLogs'));
    }
    if (activityError) {
      presentError(t('errors.fitness.exercise.errorActivityFetchLog'));
    }
  }, [stepsError, activityError]);

  const activity = useMemo(() => {
    if (!activityLogs || activityLogs.documents.length === 0) return '-';
    return calculateActivityLogs(activityLogs.documents, 'duration').toString();
  }, [activityLogs]);

  const steps = useMemo(() => {
    if (!stepsLogs || stepsLogs.documents.length === 0) return '-';
    return calculateActivityLogs(stepsLogs.documents, 'steps').toString();
  }, [stepsLogs]);

  const handleViewMore = () => {
    router.push('/dashboard/fitness/exercise');
  };

  return (
    <Box gap={1} flexGrow={1} width={'100%'} display={'flex'} flexDirection={'column'}>
      <SectionHeading
        hasGoal={false}
        viewMore={handleViewMore}
        heading={t('headingsTitles.exercise')}
      />
      <Box display={'flex'} flexGrow={1} gap={1} flexDirection={'column'}>
        <WellnessCards
          amount={steps}
          color={'#AD22A8'}
          title={t('headingsTitles.steps')}
          unit={`${t('headingsTitles.steps')}`}
          icon={<ExerciseIcon color={'#AD22A8'} width={'22px'} height={'21px'} />}
        />
        <WellnessCards
          amount={activity}
          icon={<ActivitiesIcon />}
          color={theme.palette.warning.main}
          unit={`${t('measurements.minutes')}`}
          title={t('headingsTitles.activities')}
        />
      </Box>
    </Box>
  );
};

export default SectionExercise;
