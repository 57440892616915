import * as React from 'react';
import { SVGProps } from 'react';
const HealthDataSQ = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.66669 0.333349C8.67852 0.305935 7.71972 0.670999 7.00002 1.34868C6.28032 0.670999 5.32152 0.305935 4.33335 0.333349C3.77365 0.334922 3.22118 0.459812 2.71522 0.699135C2.20927 0.938457 1.76227 1.28633 1.40602 1.71802C0.184019 3.18402 0.0953519 5.53335 0.678685 7.23868C0.684019 7.25068 0.688019 7.26268 0.693352 7.27468C1.76469 10.3333 6.30869 13.6667 7.00002 13.6667C7.67935 13.6667 12.21 10.4067 13.3067 7.27468C13.312 7.26268 13.316 7.25068 13.3214 7.23868C13.8967 5.56068 13.8347 3.20602 12.594 1.71802C12.2378 1.28633 11.7908 0.938457 11.2848 0.699135C10.7789 0.459812 10.2264 0.334922 9.66669 0.333349ZM7.00002 12.212C6.07269 11.6093 3.49269 9.80002 2.32135 7.66668H5.00002C5.10975 7.6667 5.21778 7.63963 5.31454 7.58788C5.4113 7.53613 5.49379 7.46129 5.55469 7.37002L6.13202 6.50335L7.03469 9.21002C7.07417 9.32832 7.14605 9.43317 7.24215 9.51266C7.33826 9.59215 7.45473 9.64308 7.57834 9.65968C7.70195 9.67628 7.82773 9.65787 7.9414 9.60655C8.05507 9.55522 8.15206 9.47304 8.22135 9.36935L9.35669 7.66668H11.6794C10.5074 9.80002 7.92735 11.6093 7.00002 12.212ZM12.19 6.33335H9.00002C8.89029 6.33333 8.78225 6.3604 8.6855 6.41215C8.58874 6.4639 8.50625 6.53874 8.44535 6.63002L7.86802 7.49668L6.96535 4.79002C6.92587 4.67171 6.85399 4.56686 6.75788 4.48737C6.66178 4.40788 6.54531 4.35695 6.4217 4.34035C6.29809 4.32375 6.17231 4.34216 6.05864 4.39349C5.94497 4.44481 5.84798 4.52699 5.77869 4.63068L4.64335 6.33335H1.81002C1.54335 5.07268 1.60202 3.56468 2.43069 2.57135C2.66041 2.28835 2.95047 2.06025 3.27966 1.90373C3.60885 1.74721 3.96885 1.66622 4.33335 1.66668C6.37802 1.66668 6.16469 3.06668 6.99269 3.07468H7.00002C7.82935 3.07468 7.61935 1.66668 9.66669 1.66668C10.0314 1.66602 10.3917 1.74692 10.7211 1.90345C11.0505 2.05997 11.3408 2.28817 11.5707 2.57135C12.3947 3.56135 12.4587 5.06668 12.19 6.33335Z"
      fill={props.fill ? props.fill : '#F2F1F3'}
    />
  </svg>
);
export default HealthDataSQ;
