import PageLayout from '../../../components/PageLayout';
import { ComponentsLayout } from '@hdcorner/ui-library';
import ViewChooseProgram from './components/ViewChooseProgram';
import { useTranslation } from 'react-i18next';

const WorkoutPrograms = () => {
  const { t } = useTranslation();

  return (
    <PageLayout
      headerTitle={t('headingsTitles.workoutPrograms')}
      defaultHref={'/dashboard/fitness/workouts/workout-type'}
    >
      <ComponentsLayout>
        <ViewChooseProgram />
      </ComponentsLayout>
    </PageLayout>
  );
};

export default WorkoutPrograms;
