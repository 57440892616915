import Chart from 'react-apexcharts';
import { FC, useMemo } from 'react';
import { ApexOptions } from 'apexcharts';
import { useTheme } from '@mui/material';
import { ChartDataType } from '../../types/ChartDataType';
import moment from 'moment/moment';

export type LineChartProps = {
  colors?: string[];
  showGrid?: boolean;
  showTime?: boolean;
  showLegend?: boolean;
  width?: string | number;
  height?: string | number;
  chartData: ChartDataType[];
  legendPosition?: 'top' | 'bottom';
  xAnnotations?: { value: number | string; color: string; solid?: boolean }[];
  yAnnotations?: {
    from: number | string;
    to?: number | string;
    fillColor: string;
    solid?: boolean;
  }[];
};

const LineChart: FC<LineChartProps> = ({
  colors,
  showTime,
  chartData,
  xAnnotations,
  yAnnotations,
  width = '100%',
  showGrid = true,
  height = '170px',
  showLegend = false,
  legendPosition = 'top',
}) => {
  const theme = useTheme();

  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const getXAnnotations = useMemo<XAxisAnnotations[]>(() => {
    if (!xAnnotations) return [];

    return xAnnotations?.map(annotation => ({
      borderWidth: 1,
      x: annotation.value,
      borderColor: annotation.color,
      strokeDashArray: annotation.solid ? 0 : 3,
    }));
  }, [xAnnotations]);

  const getYAnnotations = useMemo<YAxisAnnotations[]>(() => {
    if (!yAnnotations) return [];

    return yAnnotations?.map(annotation => ({
      borderWidth: 1,
      y: annotation.from,
      y2: annotation.to,
      borderColor: annotation.fillColor,
      strokeDashArray: annotation.solid ? 0 : 3,
    }));
  }, [yAnnotations]);

  const options = useMemo<ApexOptions>(
    () => ({
      chart: {
        type: 'line',
        zoom: { enabled: false },
        toolbar: { show: false },
        fontFamily: theme.typography.fontFamily,
        defaultLocale: lang,
        locales: [
          {
            name: 'en',
            options: {
              months: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
              ],
              shortMonths: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
              ],
              days: [
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
              ],
              shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            },
          },
          {
            name: 'el',
            options: {
              months: [
                'Ιανουάριος',
                'Φεβρουάριος',
                'Μάρτιος',
                'Απρίλιος',
                'Μάιος',
                'Ιούνιος',
                'Ιούλιος',
                'Αύγουστος',
                'Σεπτέμβριος',
                'Οκτώβριος',
                'Νοέμβριος',
                'Δεκέμβριος',
              ],
              shortMonths: [
                'Ιαν',
                'Φεβ',
                'Μάρ',
                'Απρ',
                'Μάι',
                'Ιούν',
                'Ιούλ',
                'Αύγ',
                'Σεπ',
                'Οκτ',
                'Νοέ',
                'Δεκ',
              ],
              days: [
                'Κυριακή',
                'Δευτέρα',
                'Τρίτη',
                'Τετάρτη',
                'Πέμπτη',
                'Παρασκευή',
                'Σάββατο',
              ],
              shortDays: ['Κυρ', 'Δευ', 'Τρί', 'Τετ', 'Πέμ', 'Παρ', 'Σάβ'],
            },
          },
        ],
      },
      colors: colors,
      annotations: {
        xaxis: getXAnnotations,
        yaxis: getYAnnotations,
      },
      dataLabels: {
        style: {
          colors: colors,
        },
      },
      legend: {
        show: showLegend,
        position: legendPosition,
        horizontalAlign: legendPosition === 'top' ? 'left' : 'center',
        fontWeight: 600,
        fontSize: '12px',
        fontFamily: theme.typography.fontFamily,
        offsetX: -32,
        markers: {
          width: 8,
          height: 8,
        },
        itemMargin: {
          vertical: 0,
        },
      },
      stroke: {
        width: 2,
        show: true,
        lineCap: 'round',
      },
      grid: {
        borderColor: theme.palette.primary.light,
        show: showGrid,
        padding: {
          right: 21,
          top: 0,
          bottom: 0,
          left: 0,
        },
      },
      xaxis: {
        type: 'datetime',
        labels: {
          formatter: (value: string) => {
            return [
              moment(value).format('DD/MM/YYYY'),
              showTime ? moment(value).format('HH:mm') : '',
            ];
          },
          style: {
            fontWeight: 600,
            fontSize: '12px',
            colors: '#A5A9B1',
            fontFamily: theme.typography.fontFamily,
          },
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          offsetX: -13,
          offsetY: -7,
          style: {
            fontWeight: 600,
            fontSize: '12px',
            colors: '#A5A9B1',
            fontFamily: theme.typography.fontFamily,
          },
        },
      },
      tooltip: {
        enabled: false,
      },
    }),
    [showLegend, getXAnnotations, getYAnnotations, colors, legendPosition, theme, lang],
  );

  const series = useMemo<ApexAxisChartSeries>(() => {
    if (chartData.length === 0) {
      return [{ data: [] }];
    }

    if ('name' in chartData[0]) {
      const data = chartData as {
        name: string;
        data: { x: Date | string; y: number }[];
      }[];

      return data.map(rec => ({
        name: rec.name,
        data: rec.data.map(data => ({
          x: data.x,
          y: data.y,
        })),
      }));
    }

    return [
      {
        data: (chartData as { x: Date | string; y: number }[]).map(data => ({
          x: data.x,
          y: data.y,
        })),
      },
    ];
  }, [chartData]);

  return <Chart series={series} options={options} height={height} width={width} />;
};

export default LineChart;
