import { FC, useState } from 'react';
import { useIonRouter } from '@ionic/react';
import { CustomButton, SignUpForm, theme } from '@hdcorner/ui-library';
import { useSignUpMutation } from '../../queries/signUpQueries';
import { Box, styled, Typography, TypographyProps } from '@mui/material';
import LogoHeartPNG from '../../../../assets/illustrations/LogoHeartPNG.png';
import { useSignInMutation } from '../../queries/signInQueries';
import LoadingPage from '../../components/LoadingPage';
import { useCreateUserSettingsMutation } from '../../../profile/queries/userSettingsQueries';
import useAlert from '../../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import SignInSocial from '../../signIn/components/SignInSocial';

const TypographyText = styled(Typography, {
  shouldForwardProp: propName => propName !== 'web',
})<TypographyProps & { web: boolean }>(props => ({
  color: theme.palette.primary.light,
  display: props.web ? 'block' : 'none',
  marginBottom: theme.spacing(6.25),
}));

const TypographyCopyright = styled(Typography)<TypographyProps>(() => ({
  display: 'block',
  color: theme.palette.primary.light,
  marginTop: theme.spacing(3),
  fontWeight: theme.typography.fontWeightRegular,
}));

type SignUpPanelProps = {
  web: boolean;
};
const SignUpPanel: FC<SignUpPanelProps> = ({ web }) => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { presentError } = useAlert();

  const [hasLoaded, setHasLoaded] = useState<boolean>(false);

  const [userSettings] = useCreateUserSettingsMutation();
  const [login, { isLoading: isLoginLoading }] = useSignInMutation();
  const [signup, { isLoading: isSignUpLoading }] = useSignUpMutation();

  const handleSignIn = () => {
    router.push('/sign-in', 'back', 'pop');
  };

  const handleSignUp = async (data: { email: string; password: string }) => {
    try {
      setHasLoaded(true);
      await signup(data).unwrap();
      await login(data).unwrap();

      await userSettings({
        selectedLang: 'en',
        unitsSystem: 'metric',
        cvdRiskCalculator: 'eu',
        selectedRegion: 'europe',
        generalNotifications: true,
        knowledgeNotifications: true,
        otherMessagesNotifications: true,
        clinicalCoachNotifications: true,
      }).unwrap();

      setTimeout(() => {
        setHasLoaded(false);
        router.push('/dashboard/terms-and-conditions');
      }, 1000);
    } catch (e: any) {
      console.log(e);
      if ('data' in e) {
        if (e.data.message === 'User already exists') {
          presentError(t('authentication.signUp.error.userExists'));
        }
      } else {
        presentError(t('authentication.signUp.error.errorCreateAcc'));
      }
      setHasLoaded(false);
    }
  };

  return (
    <>
      {hasLoaded ? (
        <LoadingPage web={web} />
      ) : (
        <Box
          height={'100%'}
          display={'flex'}
          overflow={'scroll'}
          flexDirection={'column'}
          justifyContent={'space-between'}
          bgcolor={theme.palette.secondary.light}
          sx={{
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            '::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          <Box>
            <Box textAlign={'center'}>
              <img
                src={LogoHeartPNG}
                alt={'company logo'}
                width={web ? '80px' : '56px'}
                height={web ? '86px' : '54px'}
              />
              <Typography
                variant={web ? 'subtitle1' : 'h1'}
                sx={{
                  fontWeight: theme.typography.fontWeightMedium,
                  margin: web ? theme.spacing(2, 0, 3, 0) : theme.spacing(3, 0),
                }}
              >
                {t('buttons.signUp')}
              </Typography>

              <Box
                display={'block'}
                marginBottom={web ? theme.spacing(4) : theme.spacing(7.4)}
              >
                <SignInSocial />
              </Box>

              <TypographyText variant={'subtitle2'} web={web}>
                {t('authentication.signUp.text.enterEmail')}
              </TypographyText>
            </Box>
            <SignUpForm
              handleSignUp={handleSignUp}
              loading={isLoginLoading || isSignUpLoading}
            />
          </Box>

          <Box
            display={'flex'}
            textAlign={'center'}
            flexDirection={'column'}
            marginTop={theme.spacing(3)}
          >
            <CustomButton
              onClick={handleSignIn}
              variant={web ? 'contained' : 'text'}
              sx={{
                width: '100px',
                height: '32px',
                margin: '0 auto',
                boxShadow: 'none',
                color: theme.palette.primary.main,
                fontSize: theme.typography.body1.fontSize,
                backgroundColor: web ? theme.palette.secondary.main : 'none',
              }}
            >
              {t('buttons.signIn')}
            </CustomButton>
            <TypographyCopyright variant={'body1'}>
              {t('karabinis.footer', { year: moment().year() })}
            </TypographyCopyright>
          </Box>
        </Box>
      )}
    </>
  );
};

export default SignUpPanel;
