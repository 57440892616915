import React, { FC, useMemo } from 'react';
import {
  Avatar,
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import GroupChatIcon from '../../assets/GroupChatIcon';
import DropdownTrigger from '../controls/DropdownTrigger';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import HdCornerHeartLogo from '../../assets/HdCornerHeartLogo';
import { useTranslation } from 'react-i18next';

const BoxHeader = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  boxSizing: 'border-box',
  justifyContent: 'space-between',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.background.default
      : theme.palette.secondary.light,
  borderBottom:
    theme.palette.mode === 'dark'
      ? `1px solid ${theme.palette.kmColorsDarkGrey.main}`
      : `1px solid ${theme.palette.secondary.main}`,
  padding: theme.spacing(1, 2),
}));

const TypographyUser = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.main,
}));

type ChatHeaderWebProps = {
  title: string;
  avatar?: string;
  group?: boolean;
  admin?: boolean;
  support?: boolean;
  handleLeaveChat?: () => void;
  handleResolveIssue?: () => void;
  handleOpenMediaPanel: () => void;
};
const ChatHeaderWeb: FC<ChatHeaderWebProps> = ({
  title,
  avatar,
  group = false,
  admin = false,
  support = false,
  handleLeaveChat,
  handleOpenMediaPanel,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const options = useMemo(() => {
    if (!handleLeaveChat) return [];
    return support
      ? [
          {
            isDelete: false,
            icon: <DoneAllIcon />,
            onClick: handleLeaveChat,
            text: t('chat.buttons.resolve'),
          },
        ]
      : [
          {
            isDelete: false,
            icon: <ExitToAppIcon />,
            onClick: handleLeaveChat,
            text: t('chat.buttons.leave'),
          },
        ];
  }, [handleLeaveChat, support]);

  return (
    <BoxHeader>
      <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
        <Avatar
          alt={title}
          src={(!admin && group) || (!admin && support) ? undefined : avatar}
          sx={{
            width: '40px',
            height: '40px',
            bgcolor:
              !admin && group
                ? theme.palette.error.main
                : !admin && support
                ? 'transparent'
                : undefined,
          }}
        >
          {!admin && group ? (
            <GroupChatIcon />
          ) : !admin && support ? (
            <HdCornerHeartLogo />
          ) : (
            title.charAt(0).toUpperCase()
          )}
        </Avatar>
        <Box marginX={0.5} />
        <TypographyUser variant={'subtitle1'}>{title}</TypographyUser>
      </Box>
      <Box
        flexGrow={1}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'flex-end'}
      >
        {/*{!support ? (*/}
        {/*  <IconButton onClick={handleOpenMediaPanel}>*/}
        {/*    <PictureIcon color={theme.palette.primary.main} />*/}
        {/*  </IconButton>*/}
        {/*) : null}*/}
        <DropdownTrigger options={options} />
      </Box>
    </BoxHeader>
  );
};

export default ChatHeaderWeb;
