import React from 'react';
import { Box, styled, Typography, TypographyProps } from '@mui/material';
import { ClickNavCardNutrition, ComponentsLayout } from '@hdcorner/ui-library';
import { useIonRouter } from '@ionic/react';
import PageLayout from '../../../components/PageLayout';
import SectionArticles from '../components/SectionArticles';
import { useTranslation } from 'react-i18next';

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.light,
  marginBottom: theme.spacing(1),
  fontWeight: theme.typography.fontWeightMedium,
}));

const TipsTricks = () => {
  const router = useIonRouter();
  const { t } = useTranslation();

  return (
    <PageLayout
      headerTitle={t('headingsTitles.tipsTricks')}
      defaultHref={'/dashboard/fitness/nutrition'}
    >
      <ComponentsLayout>
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <Box>
            <Title variant={'subtitle2'}>{t('headingsTitles.trafficLights')}</Title>
            <ClickNavCardNutrition
              noArrow
              body={`${t('buttons.navigation.readMore')}`}
              headings={t('fitness.nutrition.text.seeTips')}
              onClick={() =>
                router.push(`/dashboard/fitness/nutrition/traffic-light/tipstricks`)
              }
            />
          </Box>
          <SectionArticles />
        </Box>
      </ComponentsLayout>
    </PageLayout>
  );
};

export default TipsTricks;
