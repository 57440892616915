import * as React from 'react';
import { SVGProps } from 'react';

const PillIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.6169 20.0001C8.55116 20.0013 9.47647 19.8181 10.3398 19.461C11.2031 19.1039 11.9875 18.5799 12.6479 17.9191L18.4159 12.1521C19.751 10.8174 20.5012 9.00699 20.5015 7.11915C20.5018 5.23132 19.7521 3.42069 18.4174 2.08559C17.0827 0.750491 15.2723 0.000281398 13.3845 7.91464e-08C11.4966 -0.00028124 9.686 0.749389 8.3509 2.08409L2.5839 7.85109C1.58724 8.84592 0.908238 10.1142 0.632909 11.4952C0.35758 12.8762 0.498308 14.3079 1.03727 15.6088C1.57623 16.9098 2.48917 18.0216 3.66046 18.8033C4.83176 19.585 6.2087 20.0015 7.6169 20.0001ZM9.7649 3.50009C10.2363 3.00887 10.801 2.61666 11.4259 2.34645C12.0508 2.07623 12.7234 1.93344 13.4042 1.92644C14.085 1.91944 14.7603 2.04837 15.3907 2.30567C16.021 2.56298 16.5937 2.94349 17.0751 3.42491C17.5565 3.90633 17.937 4.47898 18.1943 5.10932C18.4516 5.73965 18.5806 6.415 18.5735 7.0958C18.5665 7.77659 18.4238 8.44914 18.1535 9.07406C17.8833 9.69897 17.4911 10.2637 16.9999 10.7351L14.8229 12.9111L7.5889 5.67509L9.7649 3.50009ZM3.9999 9.26509L6.1749 7.08909L13.4109 14.3251L11.2339 16.5001C10.2702 17.4388 8.97563 17.9601 7.63038 17.9513C6.28513 17.9424 4.99751 17.404 4.04632 16.4527C3.09513 15.5014 2.55693 14.2137 2.54824 12.8684C2.53955 11.5232 3.06108 10.2286 3.9999 9.26509Z"
        fill={props?.color ?? '#4780EF'}
      />
    </svg>
  );
};

export default PillIcon;
