import { Box, BoxProps, styled } from '@mui/material';
import { theme, useDebounce } from '@hdcorner/ui-library';
import React, { useEffect, useRef, useState } from 'react';
import NotificationSettings from './NotificationSettings';
import {
  useGetUserSettingsQuery,
  useUpdateNotificationsSettingsMutation,
} from '../queries/userSettingsQueries';
import { useTranslation } from 'react-i18next';
import ProfileTypographyHeading from './ProfileTypographyHeading';

const BoxWrap = styled(Box)<BoxProps>(() => ({
  flex: '1 0 48%',
  maxWidth: '50%',
}));

const WebNotifications = () => {
  const { t } = useTranslation();

  const isFirstRef = useRef<boolean>(true);

  const [general, setGeneral] = useState<boolean>(true);
  const [messages, setMessages] = useState<boolean>(true);
  const [knowledge, setKnowledge] = useState<boolean>(true);
  const [clinicalCoach, setClinicalCoach] = useState<boolean>(true);

  const { data: userSettings } = useGetUserSettingsQuery();
  const [updateUserSettings] = useUpdateNotificationsSettingsMutation();

  const debounceGeneral = useDebounce(general, 300);
  const debounceMessages = useDebounce(messages, 300);
  const debounceKnowledge = useDebounce(knowledge, 300);
  const debounceClinicalCoach = useDebounce(clinicalCoach, 300);

  useEffect(() => {
    if (userSettings && userSettings.length > 0) {
      const data = userSettings[0];

      const newUserSettings = {
        general: data.generalNotifications,
        knowledge: data.knowledgeNotifications,
        messages: data.otherMessagesNotifications,
        clinicalCoach: data.clinicalCoachNotifications,
      };

      setGeneral(newUserSettings.general);
      setMessages(newUserSettings.messages);
      setKnowledge(newUserSettings.knowledge);
      setClinicalCoach(newUserSettings.clinicalCoach);

      const timeout = setTimeout(() => {
        isFirstRef.current = false;
        clearTimeout(timeout);
      }, 700);
    }
  }, [userSettings]);

  useEffect(() => {
    if (isFirstRef.current) {
      return;
    }
    updateUserSettings({
      generalNotifications: general,
      knowledgeNotifications: knowledge,
      otherMessagesNotifications: messages,
      clinicalCoachNotifications: clinicalCoach,
    });
  }, [debounceKnowledge, debounceGeneral, debounceMessages, debounceClinicalCoach]);

  return (
    <>
      <ProfileTypographyHeading text={'notifications.titles.notifications'} />
      <Box
        gap={3}
        flexGrow={1}
        display={'flex'}
        flexWrap={'wrap'}
        borderRadius={'8px'}
        boxSizing={'border-box'}
        padding={theme.spacing(3)}
        bgcolor={theme.palette.highlight.main}
      >
        <BoxWrap>
          <NotificationSettings
            checked={general}
            setChecked={setGeneral}
            text={t('notifications.text.genNots')}
            title={t('notifications.titles.genNots')}
          />
        </BoxWrap>
        <BoxWrap>
          <NotificationSettings
            checked={knowledge}
            setChecked={setKnowledge}
            text={t('notifications.text.didYou')}
            title={t('notifications.titles.didYou')}
          />
        </BoxWrap>
        <BoxWrap>
          <NotificationSettings
            checked={messages}
            setChecked={setMessages}
            text={t('notifications.text.other')}
            title={t('notifications.titles.other')}
          />
        </BoxWrap>
        <BoxWrap>
          <NotificationSettings
            checked={clinicalCoach}
            setChecked={setClinicalCoach}
            text={t('notifications.text.cc')}
            title={t('notifications.titles.cc')}
          />
        </BoxWrap>
      </Box>
    </>
  );
};

export default WebNotifications;
