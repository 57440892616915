import React, { FC, useState } from 'react';
import { Box, Typography } from '@mui/material';
import {
  CustomButton,
  QuestionnaireAnswer,
  RadioGroup,
  RadioSingle,
  theme,
} from '@hdcorner/ui-library';
import { useTranslation } from 'react-i18next';

export type QuestionnaireMultipleChoiceProps = {
  heading: string;
  choices: QuestionnaireAnswer[];
  handleNextPage: (value: QuestionnaireAnswer) => void;
};

const QuestionnaireMultipleChoice: FC<QuestionnaireMultipleChoiceProps> = ({
  heading,
  choices,
  handleNextPage,
}) => {
  const { t } = useTranslation();

  const [value, setValue] = useState<string>('');

  const handleSaveData = () => {
    if (value) {
      const found = choices.find(choice => choice.value === value);
      if (found) {
        handleNextPage(found);
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  return (
    <Box padding={theme.spacing(0, 1.5)} boxSizing={'border-box'}>
      <Typography
        variant={'subtitle1'}
        sx={{
          color: theme.palette.primary.main,
          fontWeight: theme.typography.fontWeightRegular,
          margin: theme.spacing(1.5, 0, 3, 0),
        }}
      >
        {heading}
      </Typography>
      <RadioGroup
        row={false}
        value={value}
        onChange={handleChange}
        name={'multiple choice questionnaire'}
      >
        {choices.map(choice => (
          <Box key={choice.value}>
            <RadioSingle value={choice.value} label={choice.answer} />
            <Box marginY={theme.spacing(1.5)} />
          </Box>
        ))}
      </RadioGroup>

      <Box margin={theme.spacing(1.5, 0, 3, 0)}>
        <CustomButton
          fullWidth
          variant={'contained'}
          disabled={value === ''}
          onClick={handleSaveData}
        >
          {t('buttons.next')}
        </CustomButton>
      </Box>
    </Box>
  );
};

export default QuestionnaireMultipleChoice;
