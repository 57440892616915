import * as React from 'react';
import { SVGProps } from 'react';
import { useTheme } from '@mui/material';

const FEOtherCarbIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.666697 8.00092H1.44336L2.67736 14.7869C2.70531 14.9405 2.78628 15.0795 2.90617 15.1795C3.02605 15.2795 3.17723 15.3343 3.33336 15.3343H8.6667C8.82271 15.3341 8.97374 15.2793 9.09348 15.1793C9.21323 15.0793 9.2941 14.9404 9.32203 14.7869L10.556 8.00092H11.3334C11.5102 8.00092 11.6797 7.93068 11.8048 7.80566C11.9298 7.68064 12 7.51107 12 7.33426C12 7.15744 11.9298 6.98788 11.8048 6.86285C11.6797 6.73783 11.5102 6.66759 11.3334 6.66759H10.6134C10.4687 5.67229 10.0063 4.75038 9.29509 4.0392C8.58391 3.32802 7.66199 2.86563 6.6667 2.72092V2.27692L7.13803 1.80559C7.25947 1.67985 7.32667 1.51145 7.32515 1.33665C7.32363 1.16186 7.25352 0.994649 7.12991 0.871043C7.0063 0.747438 6.8391 0.677325 6.6643 0.675806C6.4895 0.674288 6.3211 0.741484 6.19536 0.862922C5.6867 1.42959 5.33336 1.56359 5.33336 2.00092V2.72092C4.33807 2.86563 3.41616 3.32802 2.70498 4.0392C1.9938 4.75038 1.53141 5.67229 1.3867 6.66759H0.666697C0.489886 6.66759 0.320317 6.73783 0.195293 6.86285C0.0702684 6.98788 3.05176e-05 7.15744 3.05176e-05 7.33426C3.05176e-05 7.51107 0.0702684 7.68064 0.195293 7.80566C0.320317 7.93068 0.489886 8.00092 0.666697 8.00092ZM8.11003 14.0009H3.88936L2.80003 8.00092H9.20003L8.11003 14.0009ZM6.00003 4.00092C6.7682 4.00177 7.51258 4.26748 8.10765 4.75325C8.70273 5.23902 9.11208 5.91514 9.2667 6.66759H2.73336C2.88798 5.91514 3.29734 5.23902 3.89241 4.75325C4.48748 4.26748 5.23186 4.00177 6.00003 4.00092ZM4.6667 10.6676C4.6667 10.4039 4.7449 10.1461 4.8914 9.92683C5.03791 9.70756 5.24615 9.53667 5.48979 9.43575C5.73342 9.33483 6.00151 9.30843 6.26015 9.35988C6.51879 9.41132 6.75637 9.53831 6.94284 9.72478C7.12931 9.91125 7.2563 10.1488 7.30774 10.4075C7.35919 10.6661 7.33279 10.9342 7.23187 11.1778C7.13095 11.4215 6.96006 11.6297 6.74079 11.7762C6.52153 11.9227 6.26374 12.0009 6.00003 12.0009C5.64641 12.0009 5.30727 11.8604 5.05722 11.6104C4.80717 11.3603 4.6667 11.0212 4.6667 10.6676Z"
        fill={
          theme.palette.mode === 'dark'
            ? theme.palette.kmColorsCoolGrey.main
            : theme.palette.primary.main
        }
      />
    </svg>
  );
};

export default FEOtherCarbIcon;
