import { useEffect, useMemo, useState } from 'react';
import { goalProgress } from '../../../utils/goalProgress';

import {
  useGetFirstActivityLogQuery,
  useGetLastActivityLogQuery,
  useGetUserGoalActivityQuery,
} from '../../fitness/exercise/queries/activitiesQueries';

const useGetExerciseGoalData = () => {
  const [startingDate, setStartingDate] = useState<string>();

  const { data: goal } = useGetUserGoalActivityQuery();
  const { data: current } = useGetLastActivityLogQuery();
  const { data: start } = useGetFirstActivityLogQuery(
    {
      start: startingDate || '',
    },
    { skip: !startingDate },
  );

  useEffect(() => {
    if (goal && goal.length > 0) {
      setStartingDate(goal[0].startDate);
    }
  }, [goal]);

  return useMemo(() => {
    if (!goal || goal.length === 0) return null;

    let goalValue;
    let startValue;
    let currentValue;
    let progress = 0;

    if (goal && goal.length > 0) {
      goalValue = goal[0].goal.duration;
    }

    if (start && start.documents.length > 0) {
      startValue = start.documents[0].duration;
    }

    if (current && current.documents.length > 0) {
      currentValue = current.documents[0].duration;
      startValue = startValue || currentValue;
    }

    if (goalValue && startValue && currentValue) {
      progress = goalProgress(currentValue, goalValue, startValue);
    }

    return { progress, goalValue, startValue, currentValue };
  }, [current, start, goal]);
};

export default useGetExerciseGoalData;
