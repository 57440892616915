import { Exercise, Workout } from '../types/Workout';

export const workoutExercises = (workouts: Workout[]): Exercise[] => {
  if (!workouts || workouts.length === 0) return [];

  const result = workouts.map((obj: Workout) =>
    obj.exercises.map((exercise: Exercise) => ({
      ...exercise,
      watched: false,
    })),
  );

  return [...result.flat()];
};

export const findProgramByName = (
  workouts: Workout[],
  programName: string,
): Workout[] => {
  const program = workouts.find((workout: Workout) => workout.name === programName);

  if (!program) return [];

  return [program];
};
