import { Box, useTheme } from '@mui/material';
import CustomInput from '../controls/CustomInput';
import CustomButton from '../controls/CustomButton';
import ModalMobile from './ModalMobile';
import React, { FC, useEffect, useState } from 'react';
import ModalHeading from './ModalHeading';
import { useTranslation } from 'react-i18next';

type ModalRedeemCodeProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleModalSaveClick: (data: { code: string }) => void;
  errorMessage: 'invalid' | 'default' | 'generic' | 'plan';
};
const ModalRedeemCode: FC<ModalRedeemCodeProps> = ({
  open,
  setOpen,
  errorMessage,
  handleModalSaveClick,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [code, setCode] = useState<string>('');
  const [error, setError] = useState({ value: false, message: '' });

  useEffect(() => {
    switch (errorMessage) {
      case 'default':
        return setError({ value: false, message: '' });
      case 'invalid':
        return setError({ value: true, message: t('subscription.prompts.invalid') });
      case 'generic':
        return setError({ value: true, message: t('subscription.prompts.tryAgain') });
      case 'plan':
        return setError({ value: true, message: t('subscription.prompts.biAnnual') });
    }
  }, [errorMessage]);

  const handleSaveData = () => {
    const data = { code };
    handleModalSaveClick(data);
    setCode('');
  };

  return (
    <ModalMobile open={open} setOpen={setOpen} width={'348px'}>
      <Box padding={2} boxSizing={'border-box'}>
        <ModalHeading title={t('buttons.redeem')} showCalendar={'none'} align={'left'} />
        <Box margin={theme.spacing(4, 0, 3, 0)}>
          <CustomInput
            fullWidth
            value={code}
            error={error.value}
            handleChange={setCode}
            helperText={error.message}
            label={t('subscription.labels.subCode')}
            placeholder={t('subscription.labels.code')}
          />
        </Box>
        <Box display={'flex'} justifyContent={'center'}>
          <CustomButton
            fullWidth
            color={'primary'}
            variant={'contained'}
            onClick={handleSaveData}
            children={t('buttons.redeem')}
          />
        </Box>
      </Box>
    </ModalMobile>
  );
};

export default ModalRedeemCode;
