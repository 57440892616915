import React, { FC } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

type PlanBadgeProps = {
  premium: boolean;
};
const PlanBadge: FC<PlanBadgeProps> = ({ premium }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  return (
    <Box
      width={'100px'}
      height={'27px'}
      borderRadius={'4px'}
      boxSizing={'border-box'}
      padding={theme.spacing(0.5, 2)}
      bgcolor={premium ? theme.palette.kmColorsYellow.main : '#F2F2F2'}
    >
      <Typography
        align={'center'}
        variant={'subtitle2'}
        sx={{
          color: premium
            ? theme.palette.kmColorsDarkYellow.main
            : theme.palette.kmColorsCoolGrey.main,
          fontWeight: theme.typography.fontWeightMedium,
          textTransform: lang === 'el' ? 'capitalize' : 'uppercase',
        }}
      >
        {premium ? t('userDetails.premium') : t('userDetails.basic')}
      </Typography>
    </Box>
  );
};

export default PlanBadge;
