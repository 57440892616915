import { useMemo, useState } from 'react';
import moment from 'moment/moment';

const useTimeframe = (
  timeframe: string,
  start?: string,
  end?: string,
): { start: string; end: string } | undefined => {
  const [currentStart, setCurrentStart] = useState<string>('');
  const [currentEnd, setCurrentEnd] = useState<string>('');

  return useMemo(() => {
    let localCurrentStart, localCurrentEnd;

    if (timeframe === 'all') {
      return undefined;
    }
    if (timeframe === 'day') {
      localCurrentStart = moment().utc(true).startOf('day').toISOString();
      localCurrentEnd = moment().utc(true).endOf('day').toISOString();
    }
    if (timeframe === 'week') {
      localCurrentStart = moment().utc(true).startOf('week').toISOString();
      localCurrentEnd = moment().utc(true).endOf('week').toISOString();
    }
    if (timeframe === 'month') {
      localCurrentStart = moment().utc(true).startOf('month').toISOString();
      localCurrentEnd = moment().utc(true).endOf('month').toISOString();
    }
    if (timeframe === 'year') {
      localCurrentStart = moment().utc(true).startOf('year').toISOString();
      localCurrentEnd = moment().utc(true).endOf('year').toISOString();
    }
    if (timeframe === 'custom' || timeframe === 'custom date') {
      if (start && end) {
        localCurrentStart = moment(start).startOf('day').utc(true).toISOString();
        localCurrentEnd = moment(end).endOf('day').utc(true).toISOString();
      }

      if (start && !end) {
        localCurrentStart = moment(start).startOf('day').utc(true).toISOString();
        localCurrentEnd = moment(start).endOf('day').utc(true).toISOString();
      }
    }

    if (localCurrentStart && localCurrentEnd) {
      setCurrentStart(localCurrentStart);
      setCurrentEnd(localCurrentEnd);
      return { start: localCurrentStart, end: localCurrentEnd };
    }

    if (currentStart && currentEnd) {
      return { start: currentStart, end: currentEnd };
    }
    return undefined;
  }, [timeframe, currentStart, currentEnd, start, end]);
};

export default useTimeframe;
