import * as React from 'react';
import { SVGProps } from 'react';

const DiabetesHeaderIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.208 23.0001C14.6452 22.9997 16.0543 22.602 17.2795 21.8507C18.5047 21.0994 19.4982 20.0239 20.1502 18.7432C20.8023 17.4624 21.0874 16.0262 20.974 14.5935C20.8607 13.1608 20.3533 11.7874 19.508 10.6251L12.808 1.41206C12.7107 1.29106 12.5875 1.19342 12.4475 1.12633C12.3075 1.05924 12.1543 1.02441 11.999 1.02441C11.8437 1.02441 11.6905 1.05924 11.5505 1.12633C11.4105 1.19342 11.2873 1.29106 11.19 1.41206L4.49 10.6251C3.64459 11.7875 3.13719 13.1611 3.02393 14.594C2.91066 16.0269 3.19594 17.4632 3.84821 18.744C4.50048 20.0249 5.49432 21.1003 6.7198 21.8515C7.94528 22.6026 9.35464 23.0001 10.792 23.0001H13.208ZM5 15.2081C4.99707 13.9832 5.38486 12.7894 6.107 11.8001L12 3.70006L17.893 11.8001C18.5218 12.6642 18.8993 13.6854 18.9837 14.7508C19.068 15.8161 18.856 16.8841 18.3711 17.8364C17.8861 18.7887 17.1471 19.5883 16.2359 20.1467C15.3246 20.705 14.2767 21.0004 13.208 21.0001H10.792C9.25652 20.9979 7.78453 20.387 6.69878 19.3013C5.61302 18.2155 5.00212 16.7435 5 15.2081ZM11.245 19.2501C10.2936 19.251 9.35999 18.9928 8.54429 18.5032C7.72858 18.0136 7.06162 17.3111 6.615 16.4711C6.55075 16.3551 6.51021 16.2274 6.49574 16.0956C6.48128 15.9638 6.49318 15.8304 6.53075 15.7032C6.56832 15.576 6.63081 15.4576 6.71459 15.3548C6.79836 15.252 6.90175 15.1669 7.01872 15.1044C7.1357 15.042 7.26393 15.0034 7.39596 14.9909C7.52799 14.9785 7.66117 14.9924 7.78776 15.032C7.91435 15.0715 8.03181 15.1358 8.13331 15.2211C8.23482 15.3065 8.31833 15.4111 8.379 15.5291C8.65532 16.0492 9.06813 16.4842 9.57307 16.7875C10.078 17.0907 10.656 17.2506 11.245 17.2501C11.5102 17.2501 11.7646 17.3554 11.9521 17.5429C12.1396 17.7305 12.245 17.9848 12.245 18.2501C12.245 18.5153 12.1396 18.7696 11.9521 18.9572C11.7646 19.1447 11.5102 19.2501 11.245 19.2501Z"
      fill={props?.color ?? '#222222'}
    />
  </svg>
);

export default DiabetesHeaderIcon;
