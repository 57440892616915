import { Medication } from '../types/Medication';
import { MedicationStateEnum } from '../types/MedicationStateEnum';

const extractMedicationState = (medication: Medication): MedicationStateEnum => {
  if (medication.taken) return MedicationStateEnum.taken;

  if (medication.rescheduled && medication.rescheduledTo)
    return MedicationStateEnum.rescheduledTo;

  if (medication.rescheduled && medication.rescheduledFrom)
    return MedicationStateEnum.rescheduledFrom;

  return MedicationStateEnum.notTaken;
};

export default extractMedicationState;
