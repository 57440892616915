import React, { FC } from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

type CircularChartType = {
  left: number;
  total: number;
};
const CircularChart: FC<CircularChartType> = ({ left, total }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const options: ApexOptions = {
    chart: {
      type: 'radialBar',
      toolbar: {
        show: false,
      },
    },
    grid: {
      show: true,
      padding: {
        top: -20,
        left: -20,
        right: -20,
        bottom: -20,
      },
    },
    plotOptions: {
      radialBar: {
        endAngle: 4,
        startAngle: -356,
        hollow: {
          margin: 0,
          size: '70%',
          imageOffsetX: 0,
          imageOffsetY: 0,
          image: undefined,
          position: 'front',
          background: theme.palette.mode === 'dark' ? '#222222' : '#fff',
        },
        track: {
          margin: 0,
          strokeWidth: '100%',
          background:
            theme.palette.mode === 'dark'
              ? theme.palette.kmColorsDarkGrey.main
              : theme.palette.secondary.main,
        },
        dataLabels: {
          show: true,
          name: {
            offsetY: 0,
            show: true,
            fontSize: '20px',
            fontWeight: 700,
            color:
              theme.palette.mode === 'dark'
                ? theme.palette.kmColorsCoolGrey.main
                : theme.palette.primary.main,
          },
          value: {
            offsetY: 0,
            formatter: function () {
              return t('fitness.calories.titles.circularChart');
            },
            color:
              theme.palette.mode === 'dark'
                ? theme.palette.kmColorsCoolGrey.main
                : theme.palette.primary.light,
            fontSize: '14px',
            fontWeight: 600,
            show: true,
          },
        },
      },
    },
    fill: {
      type: 'gradient',
      colors: ['#5FD071'],
      gradient: {
        opacityTo: 1,
        shade: 'dark',
        opacityFrom: 1,
        stops: [0, 100],
        type: 'vertical',
        shadeIntensity: 1,
        gradientToColors: ['#5FB5D0'],
      },
    },
    stroke: {
      lineCap: 'round',
    },
    labels: [left.toString()],
    states: {
      normal: {
        filter: {
          type: 'none',
        },
      },
      hover: {
        filter: {
          type: 'none',
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
        },
      },
    },
  };

  return (
    <Chart
      width={'100%'}
      height={'100%'}
      options={options}
      type={'radialBar'}
      series={[Math.floor((left / total) * 100)]}
    />
  );
};

export default CircularChart;
