import React, { FC } from 'react';
import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';

const CustomBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 0, 1, 0),
}));

const CustomBoxTwo = styled(Box)<BoxProps>(() => ({
  display: 'flex',
}));

const CustomTypographyHeading = styled(Typography)<TypographyProps>(({ theme }) => ({
  padding: theme.spacing(0, 0.5, 0, 0),
  fontWeight: theme.typography.fontWeightRegular,
  color: theme.palette.primary.main,
}));

type SMDetailsCardChildProps = {
  heading: string;
  weight?: string;
  time: string;
};
const SMDetailsCardChild: FC<SMDetailsCardChildProps> = ({ heading, weight, time }) => {
  const theme = useTheme();

  return (
    <CustomBox>
      <CustomBoxTwo>
        <CustomTypographyHeading variant={'subtitle2'}>{heading}</CustomTypographyHeading>
        <Typography
          variant={'subtitle2'}
          sx={{
            fontWeight: theme.typography.fontWeightRegular,
            color: theme.palette.kmColorsCoolGrey.main,
          }}
        >
          {weight}
        </Typography>
      </CustomBoxTwo>
      <Box>
        <Typography
          variant={'subtitle2'}
          sx={{ color: theme.palette.kmColorsCoolGrey.main }}
        >
          {time}
        </Typography>
      </Box>
    </CustomBox>
  );
};

export default SMDetailsCardChild;
