import moment from 'moment';
import api from '../../../redux/api';
import { Trends } from '../Trends';
import { Medication, PaginationResult, UserRecord } from '@hdcorner/ui-library';

const dashboardQueries = api.injectEndpoints({
  endpoints: build => ({
    getMedicationsValues: build.query<PaginationResult<Medication>, void>({
      query: () => ({
        url: `/database/function/getMedications`,
        method: 'GET',
        params: {
          skip: 0,
          limit: 1000,
          sort: '-updatedAt',
          endDate: moment().utc(true).endOf('day').toISOString(),
          startDate: moment().utc(true).startOf('day').toISOString(),
        },
      }),
      providesTags: ['Dashboard', 'MedicationDashboard'],
    }),
    getRecordsValues: build.query<PaginationResult<UserRecord>, void>({
      query: () => ({
        method: 'GET',
        url: `/database/function/getPaginatedRecords`,
        params: {
          skip: 0,
          limit: 1,
          sort: '-updatedAt',
        },
      }),
      providesTags: ['Dashboard'],
    }),
    getTrendValues: build.query<Trends, void>({
      query: () => ({
        method: 'GET',
        url: `/hdCornerService/user/trends`,
      }),
      providesTags: ['Dashboard'],
    }),
  }),
});

export const {
  useGetTrendValuesQuery,
  useGetRecordsValuesQuery,
  useGetMedicationsValuesQuery,
} = dashboardQueries;

export default dashboardQueries;
