const RemoveIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.138 5.80635L8.94264 8.00168L11.138 10.197C11.2016 10.2585 11.2524 10.3321 11.2874 10.4134C11.3223 10.4947 11.3407 10.5822 11.3415 10.6707C11.3422 10.7593 11.3254 10.8471 11.2919 10.929C11.2583 11.0109 11.2088 11.0854 11.1462 11.1479C11.0836 11.2105 11.0092 11.26 10.9273 11.2936C10.8453 11.3271 10.7576 11.344 10.669 11.3432C10.5805 11.3424 10.493 11.324 10.4117 11.2891C10.3304 11.2541 10.2568 11.2034 10.1953 11.1397L7.99997 8.94435L5.80464 11.1397C5.74314 11.2034 5.66958 11.2541 5.58824 11.2891C5.50691 11.324 5.41943 11.3424 5.33091 11.3432C5.24239 11.344 5.1546 11.3271 5.07267 11.2936C4.99074 11.26 4.9163 11.2105 4.85371 11.1479C4.79111 11.0854 4.74161 11.0109 4.70809 10.929C4.67457 10.8471 4.6577 10.7593 4.65847 10.6707C4.65924 10.5822 4.67763 10.4947 4.71257 10.4134C4.74751 10.3321 4.7983 10.2585 4.86197 10.197L7.05731 8.00168L4.86197 5.80635C4.7983 5.74485 4.74751 5.67129 4.71257 5.58995C4.67763 5.50862 4.65924 5.42114 4.65847 5.33262C4.6577 5.2441 4.67457 5.15631 4.70809 5.07438C4.74161 4.99245 4.79111 4.91801 4.85371 4.85542C4.9163 4.79282 4.99074 4.74332 5.07267 4.7098C5.1546 4.67628 5.24239 4.65941 5.33091 4.66018C5.41943 4.66095 5.50691 4.67934 5.58824 4.71428C5.66958 4.74922 5.74314 4.80001 5.80464 4.86368L7.99997 7.05902L10.1953 4.86368C10.2568 4.80001 10.3304 4.74922 10.4117 4.71428C10.493 4.67934 10.5805 4.66095 10.669 4.66018C10.7576 4.65941 10.8453 4.67628 10.9273 4.7098C11.0092 4.74332 11.0836 4.79282 11.1462 4.85542C11.2088 4.91801 11.2583 4.99245 11.2919 5.07438C11.3254 5.15631 11.3422 5.2441 11.3415 5.33262C11.3407 5.42114 11.3223 5.50862 11.2874 5.58995C11.2524 5.67129 11.2016 5.74485 11.138 5.80635Z"
        fill="#222222"
      />
    </svg>
  );
};

export default RemoveIcon;
