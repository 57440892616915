import * as React from 'react';
import { SVGProps } from 'react';

const ChatSendMessageIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="14"
    fill="none"
    viewBox="0 0 16 14"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.4995 6.211L1.08212 0.0554181C0.922568 -0.00946653 0.747667 -0.0176047 0.583437 0.032215C0.419206 0.0820346 0.274421 0.187149 0.17062 0.331923C0.0668186 0.476696 0.00954779 0.653392 0.0073274 0.835725C0.005107 1.01806 0.0580556 1.19628 0.158296 1.34389L4.12603 6.99474L0.158295 12.6456C0.0546291 12.7934 -0.000955276 12.9733 1.24245e-05 13.158C0.000980125 13.3427 0.0584472 13.5219 0.163656 13.6685C0.268865 13.815 0.416047 13.9208 0.582773 13.9698C0.749499 14.0187 0.926627 14.008 1.08717 13.9395L15.5017 7.78088C15.6488 7.71802 15.7749 7.60953 15.8637 7.46941C15.9525 7.32928 16 7.16395 16 6.99474C16 6.82553 15.9525 6.6602 15.8637 6.52008C15.7749 6.37996 15.6488 6.27147 15.5017 6.2086L15.4995 6.211ZM3.12093 11.2475L5.74945 7.50486C5.85223 7.35835 5.90779 7.18021 5.90779 6.99714C5.90779 6.81406 5.85223 6.63592 5.74945 6.48941L3.12037 2.74613L13.075 6.99714L3.12093 11.2475Z"
      fill={props.color ? props.color : '#979FA5'}
    />
  </svg>
);

export default ChatSendMessageIcon;
