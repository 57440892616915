import api from '../../../../redux/api';
import { Workout } from '../types/Workout';

const workoutQueries = api.injectEndpoints({
  endpoints: build => ({
    getWorkoutById: build.query<Workout[], { workoutId: string }>({
      query: ({ workoutId }) => ({
        method: 'GET',
        url: `/database/function/getWorkoutById`,
        params: {
          workoutId,
          populate: [
            'exercises',
            'exercises.thumbnail.en',
            'exercises.thumbnail.el',
            'exercises.attachedVideo.el',
            'exercises.attachedVideo.en',
          ],
        },
      }),
      providesTags: ['Workout'],
    }),

    getWarmUpWorkouts: build.query<Workout[], { avatar: string; bodyPart: string }>({
      query: ({ avatar, bodyPart }) => ({
        method: 'GET',
        url: '/database/function/getWorkouts',
        params: {
          avatar,
          bodyPart,
          workoutType: 'warm_up',
          populate: [
            'exercises',
            'exercises.thumbnail.en',
            'exercises.thumbnail.el',
            'exercises.attachedVideo.el',
            'exercises.attachedVideo.en',
          ],
        },
      }),
      providesTags: ['Workout'],
    }),

    getMainWorkouts: build.query<Workout[], { avatar: string; bodyPart: string }>({
      query: ({ avatar, bodyPart }) => ({
        method: 'GET',
        url: '/database/function/getWorkouts',
        params: {
          avatar,
          bodyPart,
          workoutType: 'main',
          populate: [
            'exercises',
            'exercises.thumbnail.en',
            'exercises.thumbnail.el',
            'exercises.attachedVideo.el',
            'exercises.attachedVideo.en',
          ],
        },
      }),
      providesTags: ['Workout'],
    }),

    getRecoveryWorkouts: build.query<Workout[], { avatar: string; bodyPart: string }>({
      query: ({ avatar, bodyPart }) => ({
        method: 'GET',
        url: '/database/function/getWorkouts',
        params: {
          avatar,
          bodyPart,
          workoutType: 'recovery',
          populate: [
            'exercises',
            'exercises.thumbnail.en',
            'exercises.thumbnail.el',
            'exercises.attachedVideo.el',
            'exercises.attachedVideo.en',
          ],
        },
      }),
      providesTags: ['Workout'],
    }),
  }),
});

export const {
  useGetWorkoutByIdQuery,
  useGetMainWorkoutsQuery,
  useGetWarmUpWorkoutsQuery,
  useGetRecoveryWorkoutsQuery,
} = workoutQueries;

export default workoutQueries;
