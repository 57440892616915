import * as React from 'react';
import { SVGProps } from 'react';

const FacebookRoundIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="27"
    height="27"
    fill="none"
    viewBox="0 0 27 27"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 13.402C0 20.036 4.8006 25.5308 11.1347 26.67L11.2155 26.6036C11.2108 26.6027 11.2061 26.6019 11.2014 26.601V17.1526H7.86768V13.4H11.2014V10.4516C11.2014 7.10107 13.335 5.22479 16.4021 5.22479C17.3355 5.22479 18.4023 5.35881 19.3358 5.49283V8.91035H17.6022C16.002 8.91035 15.602 9.71447 15.602 10.7866V13.4H19.1358L18.5357 17.1526H15.602V26.601C15.5607 26.6085 15.5194 26.6159 15.4781 26.623L15.5353 26.67C21.8694 25.5308 26.67 20.036 26.67 13.402C26.67 6.03091 20.6693 0 13.335 0C6.00075 0 0 6.03091 0 13.402Z"
      fill="url(#paint0_linear_12171_61419)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_12171_61419"
        x1="13.3357"
        y1="25.8719"
        x2="13.3357"
        y2="-0.00493715"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0062E0" />
        <stop offset="1" stopColor="#19AFFF" />
      </linearGradient>
    </defs>
  </svg>
);

export default FacebookRoundIcon;
