import React, { FC, useEffect, useMemo, useState } from 'react';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import {
  CalendarModal,
  CustomButton,
  CustomInput,
  ModalHeading,
  ModalMobile,
  OcrIcon,
} from '@hdcorner/ui-library';
import {
  useCreateWeightLogMutation,
  useGetFirstWeightLogQuery,
  useGetUserGoalWeightQuery,
  usePatchUserGoalWeightMutation,
  usePostUserGoalWeightMutation,
} from '../queries/weightQueries';
import moment from 'moment/moment';
import useAlert from '../../../../hooks/useAlert';
import { useUpdateUserDataMutation } from '../../../profile/queries/personalInfoQueries';
import { goalProgress } from '../../../../utils/goalProgress';
import { useAppDispatch } from '../../../../redux/hooks';
import { addGoalDetails } from '../../../dashboard/slices/congratulationsSlice';
import { useTranslation } from 'react-i18next';

type ModalSliderWeightProps = {
  log: boolean;
  open: boolean;
  isLoading?: boolean;
  setOpen: (open: boolean) => void;
  triggerOCR?: (cb: (newMeasurements: { weight: string }) => void) => void;
};

const ModalSliderWeight: FC<ModalSliderWeightProps> = ({
  log,
  open,
  setOpen,
  triggerOCR,
  isLoading = false,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { presentError, presentSuccess } = useAlert();

  const [date, setDate] = useState<string>(moment().toISOString());
  const [openPicker, setOpenPicker] = useState<boolean>(false);
  const [selectedWeight, setSelectedWeight] = useState<string>('');

  const [updateUserData] = useUpdateUserDataMutation();
  const [postWeightLog] = useCreateWeightLogMutation();
  const [postWeightGoal] = usePostUserGoalWeightMutation();
  const [patchWeightGoal] = usePatchUserGoalWeightMutation();

  const { data: getGoalRes, error } = useGetUserGoalWeightQuery();
  const { data: firstLog } = useGetFirstWeightLogQuery({
    start: getGoalRes && getGoalRes.length > 0 ? getGoalRes[0].startDate : undefined,
  });

  useEffect(() => {
    if (error) {
      presentError(t('errors.fitness.wellness.errorWeightGoalFetch'));
    }
  }, [error]);

  const goalData = useMemo(() => {
    if (!getGoalRes || getGoalRes.length === 0) return { weight: 0, date: '' };
    return { weight: getGoalRes[0].goal.measurement, date: getGoalRes[0].startDate };
  }, [getGoalRes]);

  useEffect(() => {
    if (log) {
      setSelectedWeight('');
    } else {
      setSelectedWeight(goalData.weight.toString());
    }
  }, [goalData, log]);

  const handleSaveWeightLog = async () => {
    const startWeight =
      firstLog && firstLog.documents.length > 0 ? firstLog.documents[0].weight : 0;

    try {
      await postWeightLog({
        logDate: date,
        measurement: parseFloat(selectedWeight),
      }).unwrap();
      setSelectedWeight('');
      presentSuccess(t('errors.fitness.wellness.successWeightLogAdd'));

      // if weight log is not the current date, update the user's weight
      if (moment(date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
        await updateUserData({ weight: parseFloat(selectedWeight) }).unwrap();
      }

      if (
        goalData.date !== '' &&
        goalProgress(parseFloat(selectedWeight), goalData.weight, startWeight) === 100
      ) {
        dispatch(
          addGoalDetails({
            category: 'weight',
            goal: { value: goalData.weight, measure: 'kg' },
            start: { value: startWeight, measure: 'kg', time: goalData.date },
          }),
        );
      }
    } catch (e) {
      presentError(t('errors.fitness.wellness.errorWeightLogAdd'));
    } finally {
      setOpen(false);
    }
  };

  const handleAddGoal = () => {
    postWeightGoal({
      goalType: 'weight',
      startDate: moment().startOf('day').toISOString(),
      goal: {
        measurement: parseFloat(selectedWeight),
      },
    })
      .unwrap()
      .then(() => {
        setSelectedWeight('');
        presentSuccess(t('errors.fitness.wellness.successWeightGoalAdd'));
      })
      .catch(() => {
        presentError(t('errors.fitness.wellness.errorWeightGoalAdd'));
      })
      .finally(() => {
        setOpen(false);
      });
  };

  const handleEditGoal = () => {
    if (!getGoalRes || getGoalRes.length === 0) {
      presentError(t('errors.generic.errorGoal'));
      return;
    }

    patchWeightGoal({
      goalType: 'weight',
      _id: getGoalRes[0]._id,
      startDate: getGoalRes[0].startDate,
      goal: { measurement: parseFloat(selectedWeight) },
    })
      .unwrap()
      .then(() => {
        setSelectedWeight('');
        presentSuccess(t('errors.fitness.wellness.successWeightGoalEdit'));
      })
      .catch(() => {
        presentError(t('errors.fitness.wellness.errorWeightGoalEdit'));
      })
      .finally(() => {
        setOpen(false);
      });
  };

  const handleClickOCR = () => {
    if (triggerOCR) {
      triggerOCR(newMeasurements => {
        setSelectedWeight(newMeasurements.weight);
      });
    }
  };

  return (
    <ModalMobile open={open} setOpen={setOpen} width={'400px'}>
      <Box display={'flex'} flexDirection={'column'} width={'100%'} gap={2} p={2}>
        <Box
          sx={{
            filter: isLoading ? 'blur(2px)' : 'none',
            pointerEvents: isLoading ? 'none' : 'auto',
            WebkitFilter: isLoading ? 'blur(2px)' : 'none',
          }}
        >
          <ModalHeading
            align={'left'}
            showCalendar={log ? 'flex' : 'none'}
            title={
              log
                ? t('fitness.wellness.labels.addWeightLog')
                : !getGoalRes || getGoalRes.length === 0
                ? t('fitness.wellness.labels.addWeightGoal')
                : t('fitness.wellness.labels.editWeightGoal')
            }
            headingButton={log ? `${t('buttons.customDate')}` : ''}
            handleCalendarClick={log ? () => setOpenPicker(true) : undefined}
          />
          <Box mb={3}>
            <CustomInput
              fullWidth
              type={'number'}
              value={selectedWeight}
              handleChange={setSelectedWeight}
              label={`${t('measurements.weightIn')}`}
              placeholder={`${t('fitness.wellness.labels.enterWeight')}`}
            />
          </Box>
          <Box
            gap={1}
            display={'flex'}
            justifyContent={'center'}
            marginTop={theme.spacing(3)}
          >
            <Box flex={1}>
              <CustomButton
                fullWidth
                variant={'contained'}
                children={t('buttons.save')}
                onClick={
                  log
                    ? handleSaveWeightLog
                    : !getGoalRes || getGoalRes.length === 0
                    ? handleAddGoal
                    : handleEditGoal
                }
              />
            </Box>
            {triggerOCR && (
              <Box>
                <CustomButton variant={'outlined'} onClick={handleClickOCR}>
                  <OcrIcon />
                </CustomButton>
              </Box>
            )}
          </Box>
        </Box>
        {isLoading && (
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{
              gap: 4,
              filter: 'none',
              pointerEvents: 'auto',
              WebkitFilter: 'none',
            }}
          >
            <Typography
              align={'left'}
              variant={'subtitle2'}
              sx={{
                color: theme.palette.text.primary,
              }}
            >
              {t('general.text.preparingData')}
            </Typography>
            <CircularProgress size={20} />
          </Box>
        )}
      </Box>
      <CalendarModal
        open={openPicker}
        selectedDate={date}
        setOpen={setOpenPicker}
        saveDate={date => setDate(date)}
      />
    </ModalMobile>
  );
};

export default ModalSliderWeight;
