import WebAccountInfo from './WebAccountInfo';
import WebGeneralInfo from './WebGeneralInfo';
import { AuthUser, theme, WebProfilePic } from '@hdcorner/ui-library';
import { Box, BoxProps, styled, Typography } from '@mui/material';
import WebNotifications from './WebNotifications';
import WebLanguage from './WebLanguage';
import WebDeactivatePanel from './WebDeactivatePanel';
import { v4 as uuidv4 } from 'uuid';
import {
  useReUploadFileMutation,
  useUploadFileMutation,
} from '../../records/queries/recordsQueries';
import { useSetUserProfilePictureMutation } from '../queries/personalInfoQueries';
import { useAppSelector } from '../../../redux/hooks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useAlert from '../../../hooks/useAlert';

const BoxContainer = styled(Box)<BoxProps>(({ theme }) => ({
  borderRadius: '8px',
  boxSizing: 'border-box',
  padding: theme.spacing(3),
  backgroundColor: theme.palette.secondary.light,
}));

const WebAccountSettingsContainer = () => {
  const { t } = useTranslation();
  const { presentError } = useAlert();

  const {
    data: { user },
  } = useAppSelector(state => state.auth);

  const [upload] = useUploadFileMutation();
  const [reUpload] = useReUploadFileMutation();
  const [setProfilePicture] = useSetUserProfilePictureMutation();

  const handleDrop = async (files: File[]) => {
    const file = files[0];
    const fileName = `${uuidv4()}-${file.name}`;
    const existingProfileImage = user?.authUser?.profilePicture;

    try {
      if (existingProfileImage) {
        const res = await reUpload({
          name: fileName,
          size: file.size,
          _id: existingProfileImage._id,
        }).unwrap();
        const url = res.url;
        const conduitFile = res.file;
        await uploadBlob(url, file);
        await setProfilePicture({
          profilePicture: conduitFile._id,
        }).unwrap();
      } else {
        const res = await upload({
          name: fileName,
          size: file.size,
        }).unwrap();
        const conduitFile = res.file;
        const url = res.url;
        await uploadBlob(url, file);
        await setProfilePicture({
          profilePicture: conduitFile._id,
        }).unwrap();
      }
    } catch (e) {
      console.log(e);
      presentError(t('profile.errorUploadingProfile'));
    }
  };

  const getProfilePicture = useMemo(() => {
    if (user && user.authUser) {
      const authUser: AuthUser = user.authUser as AuthUser;
      return authUser.profilePicture?.url || '';
    }
    return '';
  }, [user]);

  const uploadBlob = async (url: string, file: File) => {
    return await fetch(url, {
      method: 'PUT',
      body: file,
      headers: {
        'x-ms-blob-type': 'BlockBlob',
        'Content-Type': file.type,
      },
    });
  };

  return (
    <BoxContainer>
      <Typography variant={'h1'}>{t('profile.titles.myInfo')}</Typography>
      <Box
        display={'flex'}
        marginTop={theme.spacing(3)}
        sx={{
          [theme.breakpoints.down('md')]: {
            height: 'auto',
            display: 'block',
          },
        }}
      >
        <Box
          width={'33%'}
          sx={{
            [theme.breakpoints.down('md')]: {
              width: '100%',
              marginBottom: theme.spacing(3),
            },
          }}
        >
          <WebProfilePic
            img={getProfilePicture}
            handleDrop={handleDrop}
            textUp={t('profile.text.image')}
            textDown={t('records.labels.maxSize')}
          />
        </Box>
        <Box marginX={theme.spacing(1.5)} />
        <WebAccountInfo />
      </Box>
      <Box marginY={theme.spacing(1.5)} />
      <WebGeneralInfo />
      {/*<Box marginY={theme.spacing(1.5)} />*/}
      {/*<WebUnits />*/}
      <Box marginY={theme.spacing(1.5)} />
      <WebNotifications />
      <Box marginY={theme.spacing(1.5)} />
      <WebLanguage />
      <Box marginY={theme.spacing(1.5)} />
      <WebDeactivatePanel />
    </BoxContainer>
  );
};

export default WebAccountSettingsContainer;
