import React from 'react';
import { useAppSelector } from '../../../redux/hooks';
import ArticlesInner from './components/ArticlesInner';
import PageLayout from '../../../components/PageLayout';
import { ComponentsLayout } from '@hdcorner/ui-library';

const Articles = () => {
  const { category } = useAppSelector(state => state.articles);

  return (
    <PageLayout
      headerTitle={category.title || 'Articles'}
      defaultHref={'/dashboard/fitness/nutrition/tips-tricks'}
    >
      <ComponentsLayout>
        <ArticlesInner />
      </ComponentsLayout>
    </PageLayout>
  );
};

export default Articles;
