import React, { FC } from 'react';
import { Box, BoxProps, styled, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

const OutterBox = styled(Box)<BoxProps>(() => ({
  flexDirection: 'row',
}));

const MessageBox = styled(Box)<BoxProps>(({ theme }) => ({
  margin: theme.spacing(0, 0, 1.25, 0),
}));

type SupportChatMessageCardProps = {
  web?: boolean;
  message: string;
};
const SupportChatMessageCard: FC<SupportChatMessageCardProps> = ({ message, web }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <OutterBox
      display={'flex'}
      alignItems={'center'}
      boxSizing={'border-box'}
      justifyContent={'center'}
    >
      <MessageBox
        bgcolor={
          theme.palette.mode === 'dark'
            ? theme.palette.background.default
            : theme.palette.secondary.light
        }
        padding={theme.spacing(1)}
        boxSizing={'border-box'}
        borderRadius={'8px'}
        flexGrow={'1'}
      >
        <Typography
          variant={web ? 'body1' : 'subtitle1'}
          align={'center'}
          sx={{
            fontWeight: theme.typography.fontWeightMedium,
            color:
              theme.palette.mode === 'dark'
                ? theme.palette.kmColorsTurquoise.main
                : theme.palette.error.main,
          }}
        >
          {t('chat.titles.supportMessage')}
        </Typography>
        <Typography
          align={'center'}
          color={'primary'}
          variant={web ? 'subtitle1' : 'subtitle2'}
        >
          {message}
        </Typography>
      </MessageBox>
    </OutterBox>
  );
};

export default SupportChatMessageCard;
