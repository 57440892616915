import api from '../../../redux/api';
import {
  ApiPagination,
  LipidGoal,
  LipidLog,
  LipidOverall,
  PaginationResult,
} from '@hdcorner/ui-library';

const lipidQueries = api
  .enhanceEndpoints({ addTagTypes: ['LipidLog', 'LipidGoal'] })
  .injectEndpoints({
    endpoints: build => ({
      addLipidLog: build.mutation<LipidLog[], Omit<LipidLog, '_id'>>({
        query: ({ totalChol, triglycerides, hdl, ldl, lpa, logDate }) => ({
          method: 'POST',
          url: '/database/function/addLipidLog',
          body: { totalChol, triglycerides, hdl, ldl, lpa, logDate },
        }),
        invalidatesTags: ['LipidLog', 'Dashboard'],
      }),
      getLipidLogs: build.query<
        PaginationResult<LipidLog>,
        { params: ApiPagination; start?: string; end?: string }
      >({
        query: ({ params, start, end }) => ({
          method: 'GET',
          url: `/database/function/getLipidLogs`,
          params: {
            ...params,
            endingDate: end,
            startingDate: start,
          },
        }),
        serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
          return endpointName;
        },
        merge: (currentCache, newItems, otherArgs) => {
          if (otherArgs.arg.params.skip === 0) {
            currentCache.documents = newItems?.documents;
          } else {
            currentCache?.documents.push(...newItems?.documents);
          }
        },
        forceRefetch: ({ previousArg, currentArg }) => {
          return currentArg !== previousArg;
        },
        providesTags: ['LipidLog'],
      }),
      getLipidFirstLog: build.query<PaginationResult<LipidLog>, { startingDate: string }>(
        {
          query: ({ startingDate }) => ({
            method: 'GET',
            url: `/database/function/getLipidLogs`,
            params: {
              skip: 0,
              limit: 1,
              startingDate,
              sort: 'logDate',
            },
          }),
          providesTags: ['LipidLog'],
        },
      ),
      getLipidLastLog: build.query<PaginationResult<LipidLog>, void>({
        query: () => ({
          method: 'GET',
          url: `/database/function/getLipidLogs`,
          params: { skip: 0, limit: 1, sort: '-logDate' },
        }),
        providesTags: ['LipidLog'],
      }),
      getLipidGoal: build.query<
        LipidGoal[],
        { type: 'lpa' | 'hdl' | 'ldl' | 'trg' | 'chol' }
      >({
        query: ({ type }) => ({
          method: 'GET',
          params: { goalType: type },
          url: `/database/function/getUserGoal`,
        }),
        providesTags: ['LipidGoal'],
      }),
      addLipidGoal: build.mutation<LipidGoal[], Partial<LipidGoal>>({
        query: ({ goal, goalType, startDate }) => ({
          method: 'POST',
          url: `/hdCornerService/user/goal`,
          body: { goal, goalType, startDate },
        }),
        invalidatesTags: ['LipidGoal'],
      }),
      editLipidGoal: build.mutation<LipidGoal[], Partial<LipidGoal>>({
        query: ({ _id, goal, goalType, startDate }) => ({
          method: 'PATCH',
          body: { goal, goalType, startDate },
          url: `/hdCornerService/user/goal/${_id}`,
        }),
        invalidatesTags: ['LipidGoal'],
      }),
      deleteLipidGoal: build.mutation<void, { _id: string }>({
        query: ({ _id }) => ({
          method: 'DELETE',
          url: `/database/UserGoal/${_id}`,
        }),
        invalidatesTags: ['LipidGoal'],
      }),
      getOverallLipid: build.query<LipidOverall, { start: string; end: string } | void>({
        query: params => ({
          method: 'GET',
          params: { ...params },
          url: `/hdCornerService/user/lipid/overall`,
        }),
        providesTags: ['LipidLog'],
      }),
      getLipidGraphData: build.query<
        { logs: LipidLog[] },
        { params: { metric: string | undefined; start?: string; end?: string } }
      >({
        query: ({ params }) => ({
          method: 'GET',
          params: { ...params },
          url: `/hdCornerService/user/lipid/graph`,
        }),
        providesTags: ['LipidLog'],
      }),
    }),
  });

export const {
  useGetLipidLogsQuery,
  useGetLipidGoalQuery,
  useAddLipidLogMutation,
  useGetLipidLastLogQuery,
  useAddLipidGoalMutation,
  useGetOverallLipidQuery,
  useEditLipidGoalMutation,
  useGetLipidFirstLogQuery,
  useGetLipidGraphDataQuery,
  useDeleteLipidGoalMutation,
} = lipidQueries;

export default lipidQueries;
