import { Storage } from '@ionic/storage';

let storage: Storage;

const getStore = async () => {
  if (!storage) {
    storage = new Storage();
    await storage.create();
  }
  return storage;
};

export const storeModalCCReminder = async () => {
  const store = await getStore();
  const timeNow = new Date().getTime().toString();
  await store.set('remindLater', timeNow);
};

export const getModalCCReminder = async () => {
  const store = await getStore();
  const firstTimeStamp = await store.get('remindLater');
  return firstTimeStamp ? JSON.parse(firstTimeStamp) : undefined;
};

export const removeModalCCReminder = async () => {
  const store = await getStore();
  await store.remove('remindLater');
};
