const extractRepeat = (repeat: string[]) => {
  if (repeat.length === 7) {
    return [0, 1, 2, 3, 4, 5, 6];
  }
  return repeat.map(day => {
    switch (day) {
      case 'Sunday':
        return 0;
      case 'Monday':
        return 1;
      case 'Tuesday':
        return 2;
      case 'Wednesday':
        return 3;
      case 'Thursday':
        return 4;
      case 'Friday':
        return 5;
      case 'Saturday':
        return 6;
      default:
        return -1;
    }
  });
};

const extractRepeatString = (repeat: number[]) => {
  if (repeat.length === 7) {
    return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  }
  return repeat.map(day => {
    switch (day) {
      case 0:
        return 'Sunday';
      case 1:
        return 'Monday';
      case 2:
        return 'Tuesday';
      case 3:
        return 'Wednesday';
      case 4:
        return 'Thursday';
      case 5:
        return 'Friday';
      case 6:
        return 'Saturday';
      default:
        return '';
    }
  });
};

export { extractRepeat, extractRepeatString };
