import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  CustomButton,
  DropdownMenu,
  GroupParticipantCard,
  ModalHeading,
  ModalMobile,
} from '@hdcorner/ui-library';
import { Box } from '@mui/material';
import CloseIcon from '../assets/icons/CloseIcon';
import { hcpSpecialties } from '../constants';
import { useTranslation } from 'react-i18next';

export type ModalCreateChatProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleCreate: (users: string[]) => void;
  healthProfs?: GroupParticipantsDataType[];
  onSelectedSpeciality?: (speciality: string) => void;
};

export type GroupParticipantsDataType = { id: string; image?: string; name: string };

const ModalCreateChat: FC<ModalCreateChatProps> = ({
  open,
  setOpen,
  handleCreate,
  healthProfs,
  onSelectedSpeciality,
}) => {
  const { t } = useTranslation();
  const [selectedHPs, setSelectedHPs] = useState<string[]>([]);
  const [speciality, setSpeciality] = useState<string>('');

  useEffect(() => {
    if (!open) {
      setSelectedHPs([]);
      setSpeciality('');
    }
  }, [open]);

  useEffect(() => {
    onSelectedSpeciality?.(speciality);
  }, [onSelectedSpeciality, speciality]);

  const handleSelected = useCallback(
    (healthProfID: string) => {
      const selectedArray = [...selectedHPs];
      if (!selectedHPs.includes(healthProfID)) {
        selectedArray.push(healthProfID);
      } else {
        selectedArray.splice(selectedArray.indexOf(healthProfID), 1);
      }
      setSelectedHPs(selectedArray);
    },
    [selectedHPs],
  );

  const handleButtonClick = useCallback(() => {
    handleCreate(selectedHPs);
  }, [handleCreate, selectedHPs]);

  const handleDisable = useMemo(() => {
    return selectedHPs.length === 0;
  }, [selectedHPs]);

  return (
    <ModalMobile open={open} setOpen={setOpen} width={'348px'}>
      <Box padding={2} boxSizing={'border-box'}>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <ModalHeading
            align={'left'}
            showCalendar={'flex'}
            alternateIcon={<CloseIcon />}
            title={t('healthProf.hcp.titles.chooseHCPs')}
            handleCalendarClick={() => setOpen(false)}
          />
        </Box>

        <DropdownMenu
          fullWidth
          value={speciality}
          setValue={setSpeciality}
          menuItems={hcpSpecialties}
          placeholder={`${t('inDepth.all')}`}
        />

        <Box marginY={1.5} />
        {healthProfs?.map((part, index) => (
          <Box key={`keyHealthProf${index}`} marginTop={index === 0 ? 0 : 1}>
            <GroupParticipantCard
              healthProfKey={part.id}
              healthProfName={part.name}
              healthProfImage={part.image}
              handleSelected={handleSelected}
              checked={selectedHPs.includes(part.id)}
            />
          </Box>
        ))}

        <Box marginY={1.5} />
        <CustomButton
          fullWidth
          color={'primary'}
          onClick={handleButtonClick}
          variant={'contained'}
          disabled={handleDisable}
        >
          {t('buttons.startChat')}
        </CustomButton>
      </Box>
    </ModalMobile>
  );
};

export default ModalCreateChat;
