import { useIonRouter } from '@ionic/react';
import SearchPanel from './SearchPanel';
import { FC } from 'react';
import { Food } from '@hdcorner/ui-library';
import { useTranslation } from 'react-i18next';

type Props = {
  handleAddCustomFood?: () => void;
  handleAddFood: (data: Food) => void;
};
const FoodsTab: FC<Props> = ({ handleAddFood, handleAddCustomFood }) => {
  const router = useIonRouter();
  const { t } = useTranslation();

  const handleAddCustom = () => {
    if (handleAddCustomFood) {
      handleAddCustomFood();
    } else {
      router.push(`/dashboard/fitness/add-food/custom-food?customFood=true`);
    }
  };

  return (
    <SearchPanel
      meal={false}
      button={t('buttons.addCustom')}
      handleItemClick={handleAddFood}
      handleCreateMeal={handleAddCustom}
      label={`${t('fitness.nutrition.labels.foodName')}`}
      placeholder={`${t('fitness.nutrition.placeholders.food')}`}
    />
  );
};

export default FoodsTab;
