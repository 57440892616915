import React, { FC, useState } from 'react';
import { Box, styled, Typography, TypographyProps, useTheme } from '@mui/material';
import moment from 'moment';
import NoContentCloudIcon from '../../assets/NoContentCloudIcon';
import HorizontalCalendar from '../../components/controls/HorizontalCalendar';
import GeneralList from '../../components/GeneralList';
import ClickNavCardReminders from '../../components/cards/ClickNavCardReminders';
import { ReminderData } from '../../types/ReminderData';
import { useTranslation } from 'react-i18next';

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(1),
  fontWeight: theme.typography.fontWeightMedium,
}));

type RemindersBoxType = {
  web: boolean;
  heading?: string;
  reminders: ReminderData[];
  monthlyCalendarOpen: boolean;
  handleClickReminder?: (_id: string) => void;
  setMonthlyCalendarOpen?: (bool: boolean) => void;
  handleSendData: (data: { startDate: string; endDate: string }) => void;
};

const RemindersBox: FC<RemindersBoxType> = ({
  web,
  heading,
  reminders,
  handleSendData,
  monthlyCalendarOpen,
  handleClickReminder,
  setMonthlyCalendarOpen,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const [selectedDate, setSelectedDate] = useState<Date>(moment().utc(true).toDate());

  const handleChangeDate = (date: Date) => {
    setSelectedDate(date);

    const newDate = moment(date).utc(true);

    const endDate = newDate.endOf('day').toISOString();
    const startDate = newDate.startOf('day').toISOString();

    const data = { endDate, startDate };

    handleSendData(data);
  };

  return (
    <Box flex={1} hidden={!web} height={'100%'} display={'flex'} flexDirection={'column'}>
      <Title
        variant={'subtitle1'}
        textTransform={lang === 'el' ? 'capitalize' : 'uppercase'}
      >
        {heading ? heading : t('general.labels.cal')}
      </Title>
      <Box
        flexGrow={1}
        overflow={'scroll'}
        borderRadius={'8px'}
        boxSizing={'border-box'}
        padding={theme.spacing(1)}
        bgcolor={theme.palette.secondary.light}
        sx={{
          scrollbarWidth: 'none',
          '::-webkit-scrollbar': {
            display: 'none',
          },
          msOverflowStyle: 'none',
        }}
      >
        <HorizontalCalendar
          sx={{ flex: 1 }}
          selectFutureDates
          selectedDate={selectedDate}
          openMonthly={monthlyCalendarOpen}
          setOpenMonthly={setMonthlyCalendarOpen}
          handleChange={(date: any) => handleChangeDate(date)}
        />
        <GeneralList
          listMaxHeight={650}
          sx={{ marginTop: theme.spacing(1) }}
          listProps={{ sx: { padding: theme.spacing(0) } }}
        >
          {reminders && reminders.length > 0 ? (
            <>
              {reminders.map(reminder => (
                <ClickNavCardReminders
                  key={reminder._id}
                  body={reminder.info}
                  title={reminder.title}
                  category={reminder.category}
                  onClickHandler={() => handleClickReminder?.(reminder._id)}
                  time={
                    reminder.allDay
                      ? t('general.labels.allDay')
                      : moment(reminder.reminderDateTime).utc(true).format('h:mm A')
                  }
                />
              ))}
            </>
          ) : (
            <Box textAlign={'center'}>
              <NoContentCloudIcon />
            </Box>
          )}
        </GeneralList>
      </Box>
    </Box>
  );
};

export default RemindersBox;
