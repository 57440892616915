import React, { FC, useEffect, useState } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { CustomButton, theme } from '@hdcorner/ui-library';
import WorkoutType from './WorkoutType';
import { updateTargetBodyPart } from '../slices/workoutSlice';
import { useAppDispatch } from '../../../../redux/hooks';
import { useIonRouter } from '@ionic/react';
import { ExerciseBodyPart } from '../types/ExerciseBodyPart';
import { useTranslation } from 'react-i18next';

type Props = {
  onWebClick?: () => void;
};
const TargetWorkout: FC<Props> = ({ onWebClick }) => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const web = useMediaQuery(theme.breakpoints.up('md'));
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const [selectedBodyPart, setSelectedBodyPart] = useState<ExerciseBodyPart>(
    ExerciseBodyPart.WHOLE,
  );

  const handleButtonClick = () => {
    if (web && onWebClick) {
      onWebClick();
    } else {
      router.push('/dashboard/fitness/workouts/workout-programs');
    }
  };

  useEffect(() => {
    dispatch(updateTargetBodyPart(selectedBodyPart));
  }, [selectedBodyPart]);

  return (
    <Box flex={1}>
      {web && (
        <Typography
          variant={'h1'}
          textTransform={lang === 'el' ? 'capitalize' : 'uppercase'}
          sx={{
            marginBottom: theme.spacing(3),
            color: theme.palette.primary.main,
          }}
        >
          {t('fitness.workouts.labels.workout')}
        </Typography>
      )}
      <WorkoutType
        selectedBodyPart={selectedBodyPart}
        setSelectedBodyPart={setSelectedBodyPart}
      />

      <CustomButton fullWidth variant={'contained'} onClick={handleButtonClick}>
        {web ? t('buttons.changeAva') : t('buttons.viewWorkout')}
      </CustomButton>
    </Box>
  );
};

export default TargetWorkout;
