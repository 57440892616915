import React, { useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import useAlert from '../../hooks/useAlert';
import PageLayout from '../../components/PageLayout';
import { ComponentsLayout, FeelsEmpty, NotificationCard } from '@hdcorner/ui-library';
import {
  useGetNotificationsQuery,
  useReadNotificationMutation,
} from './queries/notificationQueries';
import { useTranslation } from 'react-i18next';

const Notifications = () => {
  const { t } = useTranslation();
  const { presentError } = useAlert();

  const [readNotification] = useReadNotificationMutation();

  const { error, data: notificationRes } = useGetNotificationsQuery({
    skip: 0,
    limit: 1000,
    read: false,
  });

  useEffect(() => {
    if (error) presentError(t('errors.notifications.errorFetch'));
  }, [error]);

  const notifications = useMemo(() => {
    if (!notificationRes || notificationRes.notifications.length === 0) return [];

    return notificationRes.notifications;
  }, [notificationRes]);

  const handleMarkAsRead = (id: string) => {
    readNotification({ id: id })
      .unwrap()
      .catch(() => {
        presentError(t('errors.notifications.errorRead'));
      });
  };

  return (
    <PageLayout
      defaultHref={'/dashboard/home'}
      headerTitle={t('notifications.titles.notifications')}
    >
      <ComponentsLayout>
        <Box gap={1} display={'flex'} flexDirection={'column'}>
          {notifications.map(notification => (
            <NotificationCard
              data={notification}
              key={notification._id}
              onNotificationClick={handleMarkAsRead}
            />
          ))}
          {notifications.length === 0 && <FeelsEmpty />}
        </Box>
      </ComponentsLayout>
    </PageLayout>
  );
};

export default Notifications;
