import { useMemo } from 'react';
import { HypertensionLog } from '../types/HypertensionLog';
import { useTranslation } from 'react-i18next';

const usePreparePulsesGraph = (graphData?: { logs: HypertensionLog[] }) => {
  const { t } = useTranslation();

  return useMemo<{ name: 'pulses'; data: { x: string; y: number }[] }[]>(() => {
    if (!graphData || graphData.logs.length === 0) return [];

    const data: { name: 'pulses'; data: { x: string; y: number }[] }[] = [
      { name: t('dashboard.dashcards.hypertension.pulses'), data: [] },
    ];

    graphData.logs.forEach(item => {
      data[0].data.push({
        y: item.pulses,
        x: item.logDate,
      });
    });

    return data;
  }, [graphData]);
};

export default usePreparePulsesGraph;
