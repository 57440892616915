import React, { FC } from 'react';
import { Box, BoxProps, Typography, useTheme } from '@mui/material';
import ArrowRight from '../../assets/ArrowRight';
import { styled } from '@mui/material/styles';

const CustomBox = styled(Box)<BoxProps>(({ theme }) => ({
  height: '40px',
  display: 'flex',
  cursor: 'pointer',
  minWidth: '350px',
  borderRadius: '8px',
  alignItems: 'center',
  boxSizing: 'border-box',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.secondary.light,
  padding: theme.spacing(0.5, 1),
  margin: theme.spacing(0, 0, 3, 0),
  '&:last-of-type': {
    margin: theme.spacing(0),
  },
}));

type ClickNavCardProfileProps = {
  headings: string;
  arrowDisplay?: boolean;
  icon?: React.ReactNode;
  onClickHandler?: () => void;
};
const ClickNavCardProfile: FC<ClickNavCardProfileProps> = ({
  icon,
  headings,
  arrowDisplay,
  onClickHandler,
}) => {
  const theme = useTheme();

  return (
    <CustomBox onClick={onClickHandler}>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        margin={theme.spacing(0)}
      >
        {icon}
        <Typography
          variant={'subtitle2'}
          sx={{
            boxSizing: 'border-box',
            textTransform: 'capitalize',
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightMedium,
            margin: theme.spacing(0, 0, 0, 3),
          }}
        >
          {headings}
        </Typography>
      </Box>
      {arrowDisplay ? null : (
        <Box>
          <ArrowRight />
        </Box>
      )}
    </CustomBox>
  );
};

export default ClickNavCardProfile;
