import * as React from 'react';
import { SVGProps } from 'react';

const TooltipChatIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={22} height={21} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11 .5a10 10 0 1 0 4.924 18.7l3.76 1.253c.102.032.209.048.316.047a.999.999 0 0 0 .948-1.316l-1.248-3.76A9.987 9.987 0 0 0 11 .5Zm6.653 15.121.766 2.3-2.3-.766a.995.995 0 0 0-.85.1 8.02 8.02 0 1 1 2.487-2.488 1 1 0 0 0-.103.854Z"
      fill="#EE1D23"
    />
  </svg>
);

export default TooltipChatIcon;
