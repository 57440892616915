import React, { FC } from 'react';
import SignInInputFields from '../SignInInputFields';
import CustomButton from '../controls/CustomButton';
import { Box, styled, Typography, TypographyProps, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const TypographyHeading = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  margin: theme.spacing(3, 0),
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.kmColorsBgText.main
      : theme.palette.primary.main,
}));

const TypographyText = styled(Typography)<TypographyProps>(({ theme }) => ({
  marginBottom: theme.spacing(6.25),
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.kmColorsBgText.main
      : theme.palette.primary.light,
}));

const TypographyCopyright = styled(Typography)<TypographyProps>(({ theme }) => ({
  display: 'block',
  marginTop: theme.spacing(3),
  fontWeight: theme.typography.fontWeightRegular,
  color:
    theme.palette.mode === 'dark'
      ? theme.palette.kmColorsBgText.main
      : theme.palette.primary.light,
}));

type SignInPanelGlobalProps = {
  web: boolean;
  email: string;
  social?: boolean;
  password: string;
  healthProf?: boolean;
  handleSignIn: () => void;
  handleSignUp?: () => void;
  onInputClick?: () => void;
  logoComponent: React.ReactNode;
  socialComponent?: React.ReactNode;
  handleForgotPassword?: () => void;
  setEmail: (email: string) => void;
  setSocial?: (social: boolean) => void;
  setPassword: (password: string) => void;
};
const SignInPanelGlobal: FC<SignInPanelGlobalProps> = ({
  web,
  email,
  social,
  setEmail,
  password,
  healthProf,
  setPassword,
  handleSignIn,
  handleSignUp,
  onInputClick,
  logoComponent,
  socialComponent,
  handleForgotPassword,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      height={'100%'}
      display={'flex'}
      margin={'0 auto'}
      overflow={'scroll'}
      flexDirection={'column'}
      width={web ? '350px' : 'auto'}
      justifyContent={'space-between'}
      maxWidth={web ? 'none' : '350px'}
      sx={{
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <Box>
        <Box>
          <Box textAlign={'center'}>
            {logoComponent}
            <TypographyHeading variant={web ? 'subtitle1' : 'h1'}>
              {healthProf || web || social
                ? t('buttons.signIn')
                : t('buttons.signInSocial')}
            </TypographyHeading>
          </Box>
          <Box
            display={(!web && social) || healthProf ? 'none' : 'block'}
            marginBottom={web ? theme.spacing(4) : theme.spacing(7.4)}
          >
            {socialComponent}
          </Box>
          {web && (
            <TypographyText variant={'subtitle2'} textAlign={'center'}>
              {t('authentication.signIn.text.enterEmail')}
            </TypographyText>
          )}
          <SignInInputFields
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            onInputClick={onInputClick}
            handleSignIn={handleSignIn}
            handleForgotPassword={handleForgotPassword}
          />
        </Box>
      </Box>
      <Box display={'flex'} flexDirection={'column'} textAlign={'center'}>
        {!healthProf && (
          <Box>
            <CustomButton
              onClick={handleSignUp}
              variant={web ? 'contained' : 'text'}
              color={web ? 'secondary' : 'primary'}
            >
              {t('buttons.signUp')}
            </CustomButton>
          </Box>
        )}
        <TypographyCopyright variant={'body1'}>
          {t('karabinis.footer', { year: moment().year() })}
        </TypographyCopyright>
      </Box>
    </Box>
  );
};

export default SignInPanelGlobal;
