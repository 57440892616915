import React, { FC } from 'react';
import CustomDropzoneArea from './layout/CustomDropzoneArea';
import { Box, styled, Typography, TypographyProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

const TypographyHeading = styled(Typography, {
  shouldForwardProp: propName => propName !== 'lang',
})<TypographyProps & { lang: string }>(({ theme, lang }) => ({
  marginBottom: theme.spacing(1),
  color: theme.palette.primary.light,
  fontWeight: theme.typography.fontWeightBold,
  textTransform: lang === 'el' ? 'capitalize' : 'uppercase',
}));

type WebProfilePicProps = {
  img: string;
  textUp: string;
  textDown: string;
  handleDrop: (files: File[], base64Files?: string[]) => void;
};

const WebProfilePic: FC<WebProfilePicProps> = ({ img, textUp, textDown, handleDrop }) => {
  const { t } = useTranslation();
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  return (
    <Box display={'flex'} flex={1} height={'100%'} flexDirection={'column'}>
      <TypographyHeading lang={lang} variant={'subtitle2'}>
        {t('profile.titles.profilePic')}
      </TypographyHeading>
      <CustomDropzoneArea
        img={img}
        maxFiles={1}
        textUp={textUp}
        textDown={textDown}
        onFilesSelected={handleDrop}
        acceptedFiles={{ 'image/*': ['.png', '.jpg', '.jpeg'] }}
      />
    </Box>
  );
};

export default WebProfilePic;
