import * as React from 'react';
import { SVGProps } from 'react';

const MedicationSQ = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={17}
    height={16}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.41135 14.6666C7.03419 14.6674 7.65106 14.5453 8.22661 14.3072C8.80216 14.0692 9.32506 13.7199 9.76535 13.2793L13.6107 9.43465C14.5007 8.54484 15.0009 7.33791 15.0011 6.07935C15.0013 4.8208 14.5015 3.61371 13.6117 2.72365C12.7219 1.83358 11.5149 1.33344 10.2564 1.33325C8.99783 1.33306 7.79075 1.83284 6.90068 2.72265L3.05602 6.56731C2.39157 7.23053 1.93891 8.07602 1.75535 8.9967C1.5718 9.91738 1.66562 10.8718 2.02493 11.7391C2.38423 12.6065 2.99286 13.3476 3.77372 13.8688C4.55459 14.3899 5.47255 14.6676 6.41135 14.6666V14.6666ZM7.84335 3.66665C8.15763 3.33917 8.5341 3.0777 8.95071 2.89755C9.36731 2.71741 9.81568 2.62221 10.2695 2.61755C10.7234 2.61288 11.1736 2.69883 11.5939 2.87037C12.0141 3.0419 12.3959 3.29558 12.7168 3.61652C13.0378 3.93747 13.2914 4.31924 13.463 4.73946C13.6345 5.15969 13.7204 5.60992 13.7158 6.06378C13.7111 6.51765 13.6159 6.96602 13.4358 7.38262C13.2556 7.79923 12.9942 8.1757 12.6667 8.48998L11.2153 9.94065L6.39268 5.11665L7.84335 3.66665ZM4.00002 7.50998L5.45002 6.05931L10.274 10.8833L8.82268 12.3333C8.18024 12.9591 7.31717 13.3067 6.42034 13.3008C5.5235 13.2948 4.66509 12.9359 4.03096 12.3017C3.39684 11.6675 3.03803 10.809 3.03224 9.91219C3.02645 9.01536 3.37413 8.15233 4.00002 7.50998Z"
      fill={props?.color ?? "#F2F1F3"}
    />
  </svg>
);

export default MedicationSQ;
