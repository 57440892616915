import React, { FC } from 'react';
import { Box, BoxProps, Typography } from '@mui/material';
import CustomStepper from '../navigation/CustomStepper';
import { styled } from '@mui/material/styles';
import { TypographyProps } from '@mui/material/Typography/Typography';
import CustomButton from '../controls/CustomButton';
import { ButtonProps } from '@mui/material/Button';
import TooltipHomeIcon from '../../assets/TooltipHomeIcon';
import TooltipDataIcon from '../../assets/TooltipDataIcon';
import TooltipReminderIcon from '../../assets/TooltipReminderIcon';
import TooltipHealthProfessionalsIcon from '../../assets/TooltipHealthProfessionalsIcon';
import TooltipChatIcon from '../../assets/TooltipChatIcon';
import HealthDataSQ from '../../assets/HealthDataSQ';
import ExerciseSQ from '../../assets/ExerciseSQ';
import { useTranslation } from 'react-i18next';

const Container = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  borderRadius: 8,
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(2),
  background: theme.palette.secondary.light,
}));

const IconTitleContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(3),
}));

const IconContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: theme.spacing(1),
}));

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const Subtitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  minHeight: 147,
  textAlign: 'center',
  color: theme.palette.kmColorsDarkGrey.main,
  fontWeight: theme.typography.fontWeightRegular,
}));

const ButtonMain = styled(CustomButton, {
  shouldForwardProp: prop => prop !== 'lastStep',
})<ButtonProps & { lastStep: boolean }>(({ theme, lastStep }) => ({
  ...theme.typography.body1,
  marginBottom: theme.spacing(0.5),
  fontWeight: theme.typography.fontWeightRegular,
  color: lastStep ? theme.palette.secondary.main : theme.palette.kmColorsCoolGrey.main,
}));

type CardDataType = {
  title: string;
  subtitle: string;
  icon?: React.ReactNode;
};
export type TooltipOverlayType = {
  activeStep: number;
  onSkip: () => void;
  onContinue: () => void;
  setActiveStep: (prevActiveStep: number) => void;
};

const TooltipOverlay: FC<TooltipOverlayType> = ({
  onSkip,
  activeStep,
  onContinue,
  setActiveStep,
}) => {
  const { t } = useTranslation();

  const totalSteps = cardData?.length;
  const lastStep = totalSteps - 1 === activeStep;

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <Container>
      <IconTitleContainer>
        {cardData[activeStep]?.icon ? (
          <IconContainer>{cardData[activeStep]?.icon}</IconContainer>
        ) : null}
        <Title variant={'h1'}>{t(cardData[activeStep]?.title)}</Title>
      </IconTitleContainer>
      <Subtitle variant={'subtitle2'}>{t(cardData[activeStep]?.subtitle)}</Subtitle>
      <CustomStepper
        activeStep={activeStep}
        totalSteps={totalSteps}
        handleNext={handleNext}
        handleBack={handleBack}
      />
      <ButtonMain
        disableRipple
        disableElevation
        disableFocusRipple
        lastStep={lastStep}
        variant={lastStep ? 'contained' : 'text'}
        onClick={lastStep ? onContinue : onSkip}
      >
        {lastStep ? t('buttons.continue') : t('buttons.skip')}
      </ButtonMain>
    </Container>
  );
};

export default TooltipOverlay;

const cardData: CardDataType[] = [
  {
    title: 'authentication.onboarding.greeting',
    subtitle: 'modals.welcome.mess01',
  },
  {
    icon: <TooltipHomeIcon />,
    title: 'homePage.title',
    subtitle: 'modals.welcome.mess02',
  },
  {
    icon: <TooltipDataIcon />,
    title: 'constants.toggleValues.data.data',
    subtitle: 'modals.welcome.mess03',
  },
  {
    icon: <ExerciseSQ color={'#EE1D23'} width={22} height={21} />,
    title: 'Fitness',
    subtitle: 'modals.welcome.mess04',
  },
  {
    icon: <TooltipReminderIcon />,
    title: 'dashboard.dashcards.fitness.title',
    subtitle: 'modals.welcome.mess05',
  },
  {
    icon: <TooltipHealthProfessionalsIcon />,
    title: 'healthProf.hcp.labels.hps',
    subtitle: 'modals.welcome.mess06',
  },
  {
    icon: <TooltipChatIcon />,
    title: 'chat.titles.chat',
    subtitle: 'modals.welcome.mess07',
  },
  {
    icon: <HealthDataSQ fill={'#EE1D23'} width={22} height={21} />,
    title: 'headingsTitles.healthLib',
    subtitle: 'modals.welcome.mess08',
  },
];
