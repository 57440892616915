import api from '../../../redux/api';
import {
  ApiPagination,
  Medication,
  MedicationRequest,
  Medicine,
  MedicineStock,
  PaginationResult,
} from '@hdcorner/ui-library';

const medicationQueries = api
  .enhanceEndpoints({ addTagTypes: ['Medication', 'MedicineSearch', 'MedicineStock'] })
  .injectEndpoints({
    endpoints: build => ({
      getSearchMedicines: build.query<
        PaginationResult<Medicine>,
        ApiPagination & { searchText: string }
      >({
        query: ({ skip, limit, sort, searchText }) => ({
          method: 'GET',
          url: `/database/function/searchMedicines`,
          params: { skip, limit, sort, searchText },
        }),
        providesTags: ['MedicineSearch'],
        serializeQueryArgs: ({ queryArgs, endpointDefinition, endpointName }) => {
          return endpointName;
        },
        merge: (currentCache, newItems, otherArgs) => {
          if (otherArgs.arg.skip === 0) {
            currentCache.documents = newItems?.documents;
          } else {
            currentCache?.documents.push(...newItems?.documents);
          }
        },
        forceRefetch: ({ previousArg, currentArg }) => {
          return currentArg !== previousArg;
        },
      }),

      getMedicineById: build.query<Medicine, string>({
        query: id => `/database/Medicine/${id}`,
      }),

      getMedications: build.query<
        PaginationResult<Medication>,
        ApiPagination & { startDate: string; endDate: string }
      >({
        query: ({ skip, limit, startDate, endDate }) => ({
          url: `/database/function/getMedications`,
          method: 'GET',
          params: {
            skip,
            limit,
            startDate,
            endDate,
            sort: 'time',
            populate: 'medicine',
          },
        }),
        providesTags: ['Medication'],
      }),

      createNewMedication: build.mutation<Medication, MedicationRequest>({
        query: ({ ...medication }) => ({
          method: 'POST',
          body: medication,
          url: `/hdCornerService/user/medications`,
        }),
        invalidatesTags: ['Medication', 'MedicationDashboard'],
      }),

      takeMedication: build.mutation<Medication, string>({
        query: medicationId => ({
          method: 'PATCH',
          params: { id: medicationId },
          url: `/hdCornerService/user/medications/take`,
        }),
        invalidatesTags: ['Medication', 'MedicationDashboard', 'MedicineStock'],
      }),

      getMedicationById: build.query<Medication[], { medicationId: string }>({
        query: ({ medicationId }) => ({
          method: 'GET',
          params: { populate: 'medicine' },
          url: `/database/function/getMedicationById/${medicationId}`,
        }),
      }),

      getMedicineStock: build.query<MedicineStock[], { medicineId: string }>({
        query: ({ medicineId }) => ({
          method: 'GET',
          cache: 'no-cache',
          params: { medicineId },
          url: `/database/function/getMedicineStock`,
        }),
        providesTags: ['MedicineStock'],
      }),

      createMedicineStock: build.mutation<MedicineStock, Omit<MedicineStock, '_id'>>({
        query: ({ amount, threshold, medicine, instructions, instructionsCategory }) => ({
          method: 'POST',
          url: `/database/function/createMedicineStock`,
          body: {
            amount,
            medicine,
            threshold,
            instructions,
            instructionsCategory,
          },
        }),
        invalidatesTags: ['MedicineStock'],
      }),

      editMedicineStock: build.mutation<MedicineStock, MedicineStock>({
        query: ({
          _id,
          amount,
          authUser,
          medicine,
          threshold,
          instructions,
          instructionsCategory,
        }) => ({
          method: 'PUT',
          url: `/database/MedicineStock/${_id}`,
          body: {
            amount,
            authUser,
            medicine,
            threshold,
            instructions,
            instructionsCategory,
          },
        }),
        invalidatesTags: ['MedicineStock'],
      }),

      // Deletes all instances of a medication except doses that have already been taken
      deleteAllUnTakenMedication: build.mutation<void, { id: string }>({
        query: ({ id }) => ({
          params: { id },
          method: 'DELETE',
          url: '/hdCornerService/user/medication',
        }),
        invalidatesTags: ['Medication', 'MedicationDashboard'],
      }),

      deleteSingleMedication: build.mutation<number, { medicationId: string }>({
        query: ({ medicationId }) => ({
          method: 'DELETE',
          params: { medicationId },
          url: `/database/function/deleteSingleMedication`,
        }),
        invalidatesTags: ['Medication', 'MedicationDashboard'],
      }),

      rescheduleMedication: build.mutation<
        Medication,
        { medicationId: string; rescheduleTo: string }
      >({
        query: ({ medicationId, rescheduleTo }) => ({
          method: 'PATCH',
          params: { id: medicationId },
          body: { date: rescheduleTo },
          url: `/hdCornerService/user/medications/reschedule`,
        }),
        invalidatesTags: ['Medication', 'MedicationDashboard'],
      }),
    }),
  });

export const {
  useDeleteAllUnTakenMedicationMutation,
  useDeleteSingleMedicationMutation,
  useRescheduleMedicationMutation,
  useCreateMedicineStockMutation,
  useCreateNewMedicationMutation,
  useEditMedicineStockMutation,
  useGetSearchMedicinesQuery,
  useGetMedicationByIdQuery,
  useTakeMedicationMutation,
  useGetMedicineStockQuery,
  useGetMedicineByIdQuery,
  useGetMedicationsQuery,
} = medicationQueries;

export default medicationQueries;
