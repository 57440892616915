import { useMemo } from 'react';
import { DiabetesGoal } from '@hdcorner/ui-library';

const useGoalValue = (goals?: DiabetesGoal[]) => {
  return useMemo(() => {
    if (!goals || goals.length === 0) {
      return null;
    }
    return goals[0].goal;
  }, [goals]);
};

export default useGoalValue;
