import Box from '@mui/material/Box';
import * as React from 'react';
import { styled, Typography } from '@mui/material';
import ToggleButtons from './layout/ToggleButtons';
import { Dispatch, FC, SetStateAction, useEffect } from 'react';

const ToggleButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderRadius: '8px',
  backgroundColor: 'white',
  justifyContent: 'space-between',
  padding: theme.spacing(1.5, 2, 1.5, 3),
}));

const TextBox = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  gap: theme.spacing(1),
  justifyContent: 'space-between',
}));

const AverageTypography = styled(Typography)(({ theme }) => ({
  display: 'flex',
  textAlign: 'left',
  alignItems: 'center',
  justifyContent: 'flex-end',
  textTransform: 'capitalize',
  gap: theme.spacing(0.5),
  fontWeight: theme.typography.fontWeightRegular,
}));

const AverageValueTypography = styled(Typography)(({ theme }) => ({
  textAlign: 'right',
  display: 'inline-block',
  fontWeight: theme.typography.fontWeightBold,
}));

type ToggleDataType = {
  name: string;
  value: string;
};

type ToggleButtonsProps = {
  textColor?: string;
  customColor?: string;
  data: ToggleDataType[];
  toggleButtonsValue: string;
  setToggleButtonsValue: any;
  setOpen: Dispatch<SetStateAction<boolean>>;
  headings: { label: string; value: string }[];
};
const ToggleButtonsComp: FC<ToggleButtonsProps> = ({
  data,
  setOpen,
  textColor,
  headings,
  customColor,
  toggleButtonsValue,
  setToggleButtonsValue,
}) => {
  const handleChange = (newValue: string) => {
    if (newValue === 'custom') {
      setOpen(true);
    }
    setToggleButtonsValue(newValue);
  };

  useEffect(() => {
    setOpen(toggleButtonsValue === 'custom');
  }, [toggleButtonsValue]);

  return (
    <ToggleButtonsContainer>
      <ToggleButtons
        size={'small'}
        headings={headings}
        value={toggleButtonsValue}
        handleChange={handleChange}
        color={customColor ? customColor : '#222222'}
      />
      <Box>
        <TextBox>
          <AverageTypography variant="caption">{data[0].name}</AverageTypography>
          <AverageValueTypography variant="body1">{data[0].value}</AverageValueTypography>
        </TextBox>
        <TextBox>
          <AverageTypography variant="caption">{data[1].name}</AverageTypography>
          <AverageValueTypography variant="body1">{data[1].value}</AverageValueTypography>
        </TextBox>
        <TextBox>
          <AverageTypography variant="caption" color={textColor ? textColor : '#22222'}>
            {data[2].name}
          </AverageTypography>
          <AverageValueTypography
            variant="body1"
            color={textColor ? textColor : '#222222'}
          >
            {data[2].value}
          </AverageValueTypography>
        </TextBox>
      </Box>
    </ToggleButtonsContainer>
  );
};

export default ToggleButtonsComp;
