import React, { FC, ReactNode } from 'react';
import {
  Box,
  BoxProps,
  Card,
  CardProps,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import NutritionBowl from '../../assets/NutritionBowl';

const CustomCard = styled(Card)<CardProps>(({ theme }) => ({
  padding: theme.spacing(2, 2, 1, 2),
  boxSizing: 'border-box',
  borderRadius: '8px',
  backgroundColor: theme.palette.highlight.main,
  minWidth: '316px',
  '&.MuiCard-root': {
    boxShadow: 'none',
  },
}));

const CustomBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: theme.spacing(0, 0, 3, 0),
}));

const CustomTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
  padding: theme.spacing(0, 0, 0, 1),
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightBold,
}));

type SMDetailsCardProps = {
  heading: string;
  altIcon?: ReactNode;
  children: ReactNode;
};
const SMDetailsCard: FC<SMDetailsCardProps & CardProps> = ({
  altIcon,
  heading,
  children,
  ...cardProps
}) => {
  return (
    <CustomCard {...cardProps}>
      <CustomBox>
        {altIcon ? altIcon : <NutritionBowl />}
        <CustomTypography variant={'subtitle2'}>{heading}</CustomTypography>
      </CustomBox>
      {children}
    </CustomCard>
  );
};

export default SMDetailsCard;
