import * as React from 'react';
import { SVGProps } from 'react';

const LipidIcon = ({ fill = '#222222', ...rest }: SVGProps<SVGSVGElement>) => (
  <svg width={18} height={22} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M4 1a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1ZM0 19V7a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3Zm16-1H2v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-1ZM2 7v9h14V7a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1Zm6 7h2v-2h2v-2h-2V8H8v2H6v2h2v2Z"
      fill={fill}
    />
  </svg>
);

export default LipidIcon;
