import React from 'react';
import { Box } from '@mui/material';
import PageLayout from '../../components/PageLayout';
import SectionGraph from './calories/components/SectionGraph';
import SectionDietPlan from './diet-plan/SectionDietPlan';
import SectionWellness from './wellness/components/SectionWellness';
import SectionExercise from './exercise/components/SectionExercise';
import SectionNutrition from './nutrition/components/SectionNutrition';
import { ComponentsLayout, FitnessHeartIcon, theme } from '@hdcorner/ui-library';
import { useIonRouter } from '@ionic/react';
import FABFitness from './components/FABFitness';
import SectionWorkouts from './workouts/components/SectionWorkouts';
import { useTranslation } from 'react-i18next';

const Fitness = () => {
  const router = useIonRouter();
  const { t } = useTranslation();

  return (
    <PageLayout
      defaultHref={'/dashboard/home'}
      headerColor={theme.palette.exercise.main}
      headerTitle={t('dashboard.dashcards.fitness.title')}
      headerIcon={<FitnessHeartIcon fill={theme.palette.exercise.main} />}
    >
      <ComponentsLayout>
        <Box gap={3} flex={1} display={'flex'} flexDirection={'column'}>
          <SectionGraph toggle={false} heading />
          <SectionDietPlan
            title
            handleCalculateClick={() => {
              router.push('/dashboard/fitness/nutrition/diet-plans');
            }}
          />
          <SectionNutrition />
          <SectionWellness />
          <SectionExercise />
          <SectionWorkouts />
        </Box>
      </ComponentsLayout>
      <FABFitness />
    </PageLayout>
  );
};

export default Fitness;
