import React, { FC } from 'react';
import { FormLabel, RadioGroup as MuiRadioGroup, RadioGroupProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomRadioGroup = styled(MuiRadioGroup)<RadioGroupProps>(() => ({
  textAlign: 'left',
  justifyContent: 'space-between',
}));

type Props = {
  value?: any;
  name?: string;
  row?: boolean;
  label?: string;
  children?: React.ReactNode;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};
const RadioGroup: FC<Props & RadioGroupProps> = ({
  row,
  name,
  value,
  label,
  onChange,
  children,
  ...rest
}) => {
  return (
    <>
      {label && <FormLabel>{label}</FormLabel>}
      <CustomRadioGroup {...rest} row={row} name={name} value={value} onChange={onChange}>
        {children}
      </CustomRadioGroup>
    </>
  );
};

export default RadioGroup;
