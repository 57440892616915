import * as React from 'react';
import { ComponentsLayout, ExclamationIcon, theme } from '@hdcorner/ui-library';
import PageLayout from '../../../components/PageLayout';
import TableAverages from './components/TableAverages';
import { useState } from 'react';
import EshInfo from './components/EshInfo';
import TableReadings from './components/TableReadings';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Esh = () => {
  const { t } = useTranslation();

  const [display, setDisplay] = useState<boolean>(false);

  return (
    <PageLayout
      defaultHref={'/dashboard/hypertension'}
      headerTitle={t('hypertension.titles.esh')}
      endButtons={[
        {
          icon: <ExclamationIcon color={theme.palette.primary.main} />,
          onClick: () => setDisplay(display => !display),
        },
      ]}
    >
      <ComponentsLayout disablePaddingBottom marginBottom={theme.spacing(1)}>
        <TableAverages />
        <Box mt={1}>
          <TableReadings />
        </Box>
      </ComponentsLayout>
      <EshInfo top={8} right={8} display={display} />
    </PageLayout>
  );
};

export default Esh;
