import { Box, Typography, useTheme } from '@mui/material';
import {
  Collapsible,
  DiabetesBloodGlucoseChart,
  DiabetesGoal,
  FeelsEmpty,
  GoalActionButtons,
  GoalDetailsCard,
  InDepthDataCard,
  returnFiltersLabel,
  useCurrentMeasurement,
  useOverallData,
  usePrepareGraph,
  weekMonthYearCustom,
} from '@hdcorner/ui-library';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import moment from 'moment/moment';
import useTimeframe from '../../hooks/useTimeframe';
import {
  useGetBloodGlucoseCurrentMeasurementQuery,
  useGetBloodGlucoseGoalQuery,
  useGetBloodGlucoseGraphDataQuery,
  useGetBloodGlucoseLogsQuery,
  useGetBloodGlucoseOverallQuery,
  useGetBloodGlucoseStartingMeasurementQuery,
} from './queries/diabetesQueries';
import useGoalValue from './hooks/useGoalValue';
import useBloodGlucoseGoalData from './hooks/useBloodGlucoseGoalData';
import useAlert from '../../hooks/useAlert';
import InDepthHeading from '../fitness/components/InDepthHeading';
import InfiniteScrollList from '../../components/InfiniteScrollList';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../../utils/useConvertJSON';

type Props = {
  handleAddBloodGlucoseGoal: (current: number | undefined) => void;
  handleRemoveBloodGlucoseGoal: (goal: DiabetesGoal | undefined) => void;
  handleEditBloodGlucoseGoal: (
    goal: DiabetesGoal | undefined,
    current: number | undefined,
  ) => void;
};
const BloodGlucoseWebSection: FC<Props> = ({
  handleAddBloodGlucoseGoal,
  handleEditBloodGlucoseGoal,
  handleRemoveBloodGlucoseGoal,
}) => {
  const theme = useTheme();
  const { presentError } = useAlert();
  const { t } = useTranslation();
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const { filter } = useAppSelector(state => state.diabetes);
  const { dateFilter, customDateFilter } = useAppSelector(state => state.layout);

  const [startingDate, setStartingDate] = useState<string>('');
  const [bloodGlucoseParams, setBloodGlucoseParams] = useState({
    skip: 0,
    limit: 20,
  });

  const timeframe = useTimeframe(
    dateFilter,
    customDateFilter?.start,
    customDateFilter?.end,
  );

  const diabetesFilter = useMemo(() => {
    return filter;
  }, [filter]);

  const { data: graphDataRes } = useGetBloodGlucoseGraphDataQuery({
    ...timeframe,
  });
  const { data: overallData } = useGetBloodGlucoseOverallQuery({
    category: diabetesFilter === 'default' ? undefined : diabetesFilter,
    ...timeframe,
  });
  const { data: bloodGlucoseGoal, error: bloodGlucoseGoalError } =
    useGetBloodGlucoseGoalQuery();
  const { data: currentMeasurements } = useGetBloodGlucoseCurrentMeasurementQuery();
  const { data: bloodGlucoseLogs, isFetching: bloodGlucoseFetching } =
    useGetBloodGlucoseLogsQuery({
      params: {
        ...bloodGlucoseParams,
        ...timeframe,
      },
    });
  const { data: startingMeasurements } = useGetBloodGlucoseStartingMeasurementQuery(
    { startDate: startingDate },
    { skip: !startingDate },
  );

  const graphData = usePrepareGraph(graphDataRes);
  const prepareOverallData = useOverallData(overallData);
  const prepareGoalValue = useGoalValue(bloodGlucoseGoal);
  const prepareCurrentMeasurement = useCurrentMeasurement(currentMeasurements);
  const prepareStartingMeasurement = useCurrentMeasurement(startingMeasurements);
  const prepareGoalValues = useBloodGlucoseGoalData(
    prepareGoalValue,
    bloodGlucoseGoal,
    prepareStartingMeasurement,
    prepareCurrentMeasurement,
  );

  useEffect(() => {
    if (bloodGlucoseGoal && bloodGlucoseGoal.length > 0) {
      const goal = bloodGlucoseGoal[0];
      const start = goal.startDate;
      setStartingDate(start);
    }
  }, [bloodGlucoseGoal]);

  useEffect(() => {
    if (bloodGlucoseGoalError) {
      presentError(t('errors.diabetes.errorGlucose.fetchGlucose'));
    }
  }, [bloodGlucoseGoalError]);

  const prepareOverallDataValues = useMemo(() => {
    if (!overallData)
      return [
        {
          name: t('inDepth.all'),
          min: t('general.labels.nA'),
          max: t('general.labels.nA'),
          avg: t('general.labels.nA'),
        },
      ];
    const data = overallData;
    return [
      {
        name: t('inDepth.all'),
        min: data.min ? data.min.toFixed() : t('general.labels.nA'),
        max: data.max ? data.max.toFixed() : t('general.labels.nA'),
        avg: data.avg ? data.avg.toFixed() : t('general.labels.nA'),
      },
    ];
  }, [overallData]);

  const prepareInDepthData = useMemo(() => {
    if (!bloodGlucoseLogs) return [];

    return bloodGlucoseLogs.documents.map(item => {
      return {
        title: item.measurement.toString(),
        titleSub: t('measurements.mgdl'),
        values: {
          [t('headingsTitles.bloodGluc')]: {
            value: `${item.measurement} ${t('measurements.mgdl')}`,
          },
          Filters: { value: returnFiltersLabel(item.category) },
          [t('inDepth.timeOfCalc')]: {
            value: moment(item.logDate).format('h:mm A'),
          },
          [t('inDepth.dateOfCalc')]: {
            value: moment(item.logDate).format('MMM Do, YYYY'),
          },
        },
      };
    });
  }, [bloodGlucoseLogs]);

  return (
    <Box display={'flex'} flexDirection={'column'} flex={1} gap={3}>
      <Box display={'flex'} flexDirection={'column'} flex={1}>
        <Typography
          variant={'subtitle1'}
          sx={{
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightMedium,
            textTransform: lang === 'el' ? 'capitalize' : 'uppercase',
          }}
        >
          {t('headingsTitles.bloodGluc')}
        </Typography>
        <DiabetesBloodGlucoseChart
          chartData={graphData}
          overallData={prepareOverallData}
          latestMeasurement={prepareCurrentMeasurement}
          headings={useConvertJSON(weekMonthYearCustom)}
        />
      </Box>
      <Box display={'flex'} flexDirection={'column'} gap={1}>
        <InDepthHeading module={'blood_glucose'} />
        <InDepthDataCard
          rows={prepareOverallDataValues}
          headings={[
            `${t('measurements.min')}`,
            `${t('measurements.max')}`,
            `${t('measurements.avg')}`,
          ]}
          measurement={`${t('measurements.mgdl')}`}
        />
        <InfiniteScrollList
          loading={bloodGlucoseFetching}
          skip={bloodGlucoseParams.skip}
          limit={bloodGlucoseParams.limit}
          totalCount={bloodGlucoseLogs?.count}
          count={bloodGlucoseLogs?.documents.length}
          onLoadMore={(skip, limit) => {
            setBloodGlucoseParams({ skip, limit });
          }}
        >
          {prepareInDepthData.map((data, index) => (
            <Box key={index}>
              <Collapsible
                title={data.title}
                data={data.values}
                titleSub={data.titleSub}
              />
            </Box>
          ))}
        </InfiniteScrollList>
        {prepareInDepthData.length === 0 && <FeelsEmpty />}
        <Box
          flex={1}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          marginTop={theme.spacing(3)}
          marginBottom={theme.spacing(1)}
        >
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontWeight: theme.typography.fontWeightMedium,
              textTransform: lang === 'el' ? 'capitalize' : 'uppercase',
            }}
            variant={'subtitle1'}
          >
            {t('headingsTitles.goal')}
          </Typography>
          <GoalActionButtons
            handleAdd={() =>
              handleAddBloodGlucoseGoal(
                prepareCurrentMeasurement ? prepareCurrentMeasurement : undefined,
              )
            }
            handleEdit={() =>
              handleEditBloodGlucoseGoal(
                bloodGlucoseGoal ? bloodGlucoseGoal[0] : undefined,
                prepareCurrentMeasurement ? prepareCurrentMeasurement : undefined,
              )
            }
            handleRemove={() =>
              handleRemoveBloodGlucoseGoal(
                bloodGlucoseGoal ? bloodGlucoseGoal[0] : undefined,
              )
            }
            type={bloodGlucoseGoal && bloodGlucoseGoal.length > 0 ? 'edit' : 'add'}
          />
        </Box>
        <Box
          borderRadius={'8px'}
          padding={theme.spacing(1)}
          bgcolor={theme.palette.secondary.light}
        >
          {prepareGoalValues.map((item, index) => (
            <Box key={`${item}${index}`} mt={index === 1 ? 2 : 0}>
              <GoalDetailsCard goal={item} />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default BloodGlucoseWebSection;
