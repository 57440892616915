import moment from 'moment';
import { useMemo } from 'react';
import { Hbac1Log } from '../types/Hbac1Log';
import { BloodGlucoseLog } from '../types/BloodGlucoseLog';

const usePrepareGraph = (data?: { logs: BloodGlucoseLog[] | Hbac1Log[] }) => {
  return useMemo<{ x: string; y: number }[]>(() => {
    if (!data) return [];

    return data.logs.map(item => ({
      x: moment(item.logDate).toISOString(),
      y: item.measurement,
    }));
  }, [data]);
};

export default usePrepareGraph;
