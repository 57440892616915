import { Box, Typography } from '@mui/material';
import {
  ComponentsLayout,
  CustomButton,
  SubscriptionPlanBenefits,
  SubscriptionPlanDetails,
  theme,
} from '@hdcorner/ui-library';
import React, { FC, useEffect, useMemo, useState } from 'react';
import SubscriptionIcon from './SubscriptionIcon';
import { Purchases, PurchasesStoreProduct } from '@revenuecat/purchases-capacitor';
import { Capacitor } from '@capacitor/core';
import { useGetProductsQuery, useGetSubscriptionsQuery } from '../queries/subscriptionsQueries';
import { useAppDispatch } from '../../../redux/hooks';
import api from '../../../redux/api';
import { IonBackdrop, IonSpinner } from '@ionic/react';
import useAlert from '../../../hooks/useAlert';

type AvailableProduct = {
  _id: string;
  name: string;
  price?: number;
  isAnnual: boolean;
  isMonthly: boolean;
  identifier?: string;
  productDescription: string;
};

type SubscriptionPanelMobileProps = {
  isPremium: boolean;
  benefits: { benefit: string; plan: string[] }[];
};
const SubscriptionPanelMobile: FC<SubscriptionPanelMobileProps> = ({
                                                                     isPremium,
                                                                     benefits,
                                                                   }) => {
  const dispatch = useAppDispatch();
  const { presentError } = useAlert();

  const [showLoading, setShowLoading] = useState(false);
  const [isConfigured, setIsConfigured] = useState(false);
  const [purchaseProduct, setPurchaseProduct] = useState<PurchasesStoreProduct>();
  const [selectedPlan, setSelectedPlan] = useState<'basic' | 'premium'>('basic');
  const [availableProducts, setAvailableProducts] = useState<AvailableProduct[]>([]);
  const [purchaseProducts, setPurchaseProducts] = useState<PurchasesStoreProduct[]>([]);

  const { data: paymentPlans } = useGetProductsQuery();
  const { data: subscriptionsRes } = useGetSubscriptionsQuery();

  useEffect(() => {
    (async () => {
      if (Capacitor.getPlatform() === 'web') {
        console.log('InAppPayments is not available on web');
        return;
      }
      const result = await Purchases.isConfigured().catch(e => {
        console.log(e);
      });
      if (result) setIsConfigured(result.isConfigured);
    })();
  }, []);

  useEffect(() => {
    if (!isConfigured) return;
    if (!paymentPlans) return;
    const productIds = paymentPlans.map(p => p._id);
    if (!productIds || productIds.length === 0) return;
    getProducts(productIds);
  }, [isConfigured, paymentPlans]);

  const getProducts = async (productIds: string[]) => {
    const googleProducts = productIds.map(p => {
      return `${p}:${p}`;
    });
    let result: { products: PurchasesStoreProduct[] } | void = undefined;
    if (Capacitor.getPlatform() === 'ios') {
      result = await Purchases.getProducts({
        productIdentifiers: productIds,
      }).catch(e => {
        console.log(e);
      });
    } else if (Capacitor.getPlatform() === 'android') {
      result = await Purchases.getProducts({
        productIdentifiers: [...productIds],
      }).catch(e => {
        console.log(e);
      });
    }


    if (result && result.products) {
      setPurchaseProducts(result.products);

      let products: AvailableProduct[] = [];
      paymentPlans?.forEach(p => {
        const product = (result as {
          products: PurchasesStoreProduct[]
        }).products.find((product: PurchasesStoreProduct) => {
          return product.identifier.includes(p._id);
        });
        if (!product) return;
        const newProd: AvailableProduct = {
          _id: p._id,
          name: p.name,
          price: product?.price,
          identifier: product?.identifier,
          productDescription: p.productDescription,
          isAnnual: p.productDescription.toLowerCase().includes('annual'),
          isMonthly: p.productDescription.toLowerCase().includes('monthly'),
        };
        products.push(newProd);
      });

      setAvailableProducts(products);
    }
  };

  const awaitInvalidation = async () => {
    // invalidate tags each 1 second for 5 seconds
    // to make sure the user data is updated
    // after the purchase
    // present loading while doing this
    return new Promise(resolve => {
      for (let i = 0; i < 6; i++) {
        const timeout = setTimeout(() => {
          dispatch(api.util.invalidateTags(['AuthUser', 'UserData']));
          clearTimeout(timeout);
        }, i * 1200);
      }
      resolve(true);
    });
  };

  const handlePurchase = async () => {
    if (!purchaseProduct) return;

    const purchase = await Purchases.purchaseStoreProduct({
      product: purchaseProduct,
    }).catch(e => {
      console.log(e);
      presentError('An error occurred while trying to purchase the product');
    });

    if (purchase) {
      setShowLoading(true);
      await awaitInvalidation();
      setShowLoading(false);
    }
  };

  const handleSelectPlan = (identifier?: string) => {
    setSelectedPlan('premium');
    const p = purchaseProducts.find(p => p.identifier === identifier);
    setPurchaseProduct(p);
  };

  const startPlanText = useMemo(() => {
    const activeSubscription = subscriptionsRes?.find(it => it.status === 'active');
    if (isPremium) {
      const isActivePlan = purchaseProduct?.identifier.includes(
        activeSubscription?._id || '',
      );
      if (isActivePlan) return 'Your current plan';
      return 'Already premium';
    }

    const selectedPlan = availableProducts.find(
      p => p.identifier === purchaseProduct?.identifier,
    );
    if (selectedPlan) {
      return selectedPlan.isAnnual ? 'Start your annual plan' : 'Start your monthly plan';
    }
  }, [availableProducts, isPremium, purchaseProduct]);

  return (
    <ComponentsLayout>
      <Box margin={theme.spacing(4.75, 'auto', 3, 'auto')} width={'250px'}>
        <SubscriptionIcon />
      </Box>
      <Box>
        <SubscriptionPlanDetails
          isBasic={true}
          data={{ price: '0€' }}
          handleSelected={() => {
            setSelectedPlan('basic');
            setPurchaseProduct(undefined);
          }}
          selected={selectedPlan === 'basic'}
        />
        {availableProducts.map(product => (
          <React.Fragment key={product.identifier}>
            <Box marginY={1} />
            <SubscriptionPlanDetails
              isBasic={false}
              selected={product.identifier === purchaseProduct?.identifier}
              data={{
                price: product.price + '',
                isAnnual: product.isAnnual,
                isMonthly: product.isMonthly,
                description: product.productDescription,
              }}
              handleSelected={() => {
                handleSelectPlan(product.identifier);
              }}
            />
          </React.Fragment>
        ))}
      </Box>
      <Box margin={theme.spacing(3.25, 0, 3, 0)}>
        <SubscriptionPlanBenefits selectedPlan={selectedPlan} benefits={benefits} />
      </Box>
      <Box>
        <CustomButton
          fullWidth
          variant={'contained'}
          onClick={handlePurchase}
          disabled={selectedPlan === 'basic' || isPremium}
        >
          {startPlanText}
        </CustomButton>
      </Box>
      {showLoading && (
        <>
          <IonBackdrop
            style={{ background: theme.palette.kmColorsBg.main, opacity: 0.7 }}
          />
          <Box
            zIndex={2}
            top={'50%'}
            left={'50%'}
            width={'395px'}
            position={'absolute'}
            style={{ transform: 'translate(-50%, -50%)' }}
          >
            <Box
              p={2}
              gap={2}
              display={'flex'}
              bgcolor={'#fff'}
              borderRadius={1}
              flexDirection={'column'}
              boxShadow={'0px 4px 4px rgba(0, 0, 0, 0.25)'}
            >
              <Box
                display={'flex'}
                textAlign={'center'}
                flexDirection={'column'}
                justifyContent={'center'}
              >
                <Typography variant={'subtitle1'}>
                  Wait while we verify your purchase, please don't close the app or leave
                  this page
                </Typography>
              </Box>
              <Box textAlign={'center'}>
                <IonSpinner name='bubbles'></IonSpinner>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </ComponentsLayout>
  );
};

export default SubscriptionPanelMobile;
