import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import EditPencil from '../../assets/EditPencil';
import {
  Box,
  BoxProps,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const MainBox = styled(Box, {
  shouldForwardProp: propName => propName !== 'web',
})<BoxProps & { web: boolean }>(({ web, theme }) => ({
  display: 'flex',
  borderRadius: '8px',
  boxSizing: 'border-box',
  flexDirection: 'column',
  flex: web ? '1 0 49%' : 1,
  maxWidth: web ? '50%' : 'none',
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.secondary.main}`,
  backgroundColor:
    theme.palette.mode === 'dark' ? '#282828' : theme.palette.secondary.light,
}));

type Props = {
  text: string;
  date?: string;
  category: string;
  handleEditField?: () => void;
};
const InfoCards: FC<Props> = ({ text, date, category, handleEditField }) => {
  const theme = useTheme();
  const web = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <MainBox web={web}>
      <Box mb={date ? 1 : 0} flex={1} display={'flex'} justifyContent={'space-between'}>
        <Typography
          variant={'subtitle1'}
          sx={{
            color:
              theme.palette.mode === 'dark'
                ? theme.palette.kmColorsCoolGrey.main
                : theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightMedium,
          }}
        >
          {category}
        </Typography>
        <Box gap={1} display={'flex'} alignItems={'center'}>
          <Typography
            variant={'subtitle1'}
            sx={{
              color: theme.palette.primary.light,
              fontWeight: theme.typography.fontWeightRegular,
            }}
          >
            {text}
          </Typography>
          {handleEditField && category.toLowerCase() === 'clinical coach' && (
            <IconButton onClick={handleEditField} sx={{ padding: 0 }}>
              <EditPencil
                width={'14px'}
                height={'14px'}
                color={
                  theme.palette.mode === 'dark'
                    ? theme.palette.kmColorsCoolGrey.main
                    : theme.palette.primary.light
                }
              />
            </IconButton>
          )}
        </Box>
      </Box>
      {date && (
        <Typography
          variant={'body1'}
          sx={{
            color:
              theme.palette.mode === 'dark'
                ? theme.palette.kmColorsCoolGrey.main
                : theme.palette.primary.light,
          }}
        >
          {date}
        </Typography>
      )}
    </MainBox>
  );
};

export default InfoCards;
