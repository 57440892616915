import { SVGProps } from 'react';

const ImagePreviewIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="44"
      height="36"
      fill="none"
      viewBox="0 0 44 36"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M42 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V34C0 34.5304 0.210714 35.0391 0.585786 35.4142C0.960859 35.7893 1.46957 36 2 36H42C42.5304 36 43.0391 35.7893 43.4142 35.4142C43.7893 35.0391 44 34.5304 44 34V2C44 1.46957 43.7893 0.960859 43.4142 0.585786C43.0391 0.210714 42.5304 0 42 0ZM40 32H4V30.938L14.066 22.538L16.666 24.492L14.586 26.586C14.2217 26.9632 14.0201 27.4684 14.0247 27.9928C14.0292 28.5172 14.2395 29.0188 14.6104 29.3896C14.9812 29.7605 15.4828 29.9708 16.0072 29.9753C16.5316 29.9799 17.0368 29.7783 17.414 29.414L29.784 17.044L40 30.666V32ZM40 24L31.6 12.8C31.4281 12.5708 31.2089 12.3812 30.9573 12.2441C30.7057 12.107 30.4276 12.0256 30.1418 12.0053C29.856 11.985 29.5692 12.0263 29.3008 12.1265C29.0324 12.2267 28.7886 12.3834 28.586 12.586L19.526 21.646L15.2 18.4C14.84 18.1297 14.3993 17.9888 13.9492 18C13.4992 18.0113 13.0661 18.1741 12.72 18.462L4 25.73V4H40V24ZM8 12C8 11.2089 8.2346 10.4355 8.67412 9.77772C9.11365 9.11992 9.73836 8.60723 10.4693 8.30448C11.2002 8.00173 12.0044 7.92252 12.7804 8.07686C13.5563 8.2312 14.269 8.61216 14.8284 9.17157C15.3878 9.73098 15.7688 10.4437 15.9231 11.2196C16.0775 11.9956 15.9983 12.7998 15.6955 13.5307C15.3928 14.2616 14.8801 14.8864 14.2223 15.3259C13.5645 15.7654 12.7911 16 12 16C10.9391 16 9.92172 15.5786 9.17157 14.8284C8.42143 14.0783 8 13.0609 8 12Z"
        fill={props.color ? props.color : '#4780EF'}
      />
    </svg>
  );
};

export default ImagePreviewIcon;
