import { useEffect, useMemo, useState } from 'react';
import {
  useGetLipidFirstLogQuery,
  useGetLipidGoalQuery,
  useGetLipidLastLogQuery,
} from '../../lipid/queries/lipidQueries';
import { goalProgress } from '../../../utils/goalProgress';

const useGetTrgGoalData = () => {
  const [startingDate, setStartingDate] = useState<string>();

  const { data: trgGoal } = useGetLipidGoalQuery({ type: 'trg' });

  const { data: bloodGlucoseCurrent } = useGetLipidLastLogQuery();
  const { data: bloodGlucoseStarting } = useGetLipidFirstLogQuery(
    {
      startingDate: startingDate || '',
    },
    { skip: !startingDate },
  );

  useEffect(() => {
    if (trgGoal && trgGoal.length > 0) {
      setStartingDate(trgGoal[0].startDate);
    }
  }, [trgGoal]);

  return useMemo(() => {
    if (!trgGoal || trgGoal.length === 0) return null;

    let goalValue;
    let startValue;
    let currentValue;
    let progress = 0;

    if (trgGoal && trgGoal.length > 0) {
      goalValue = parseInt(trgGoal[0].goal.measurement);
    }

    if (bloodGlucoseStarting && bloodGlucoseStarting.documents.length > 0) {
      startValue = bloodGlucoseStarting.documents[0].triglycerides;
    }

    if (bloodGlucoseCurrent && bloodGlucoseCurrent.documents.length > 0) {
      currentValue = bloodGlucoseCurrent.documents[0].triglycerides;
      startValue = startValue || currentValue;
    }

    if (goalValue && startValue && currentValue) {
      progress = goalProgress(currentValue, goalValue, startValue);
    }

    return { progress, goalValue, startValue, currentValue };
  }, [trgGoal, bloodGlucoseStarting, bloodGlucoseCurrent]);
};

export default useGetTrgGoalData;
