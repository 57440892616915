import { Box, BoxProps, styled } from '@mui/material';

const IconBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: '32px',
  height: '32px',
  padding: '4px',
  display: 'flex',
  borderRadius: '4px',
  alignItems: 'center',
  boxSizing: 'border-box',
  justifyContent: 'center',
  backgroundColor: theme.palette.secondary.main,
}));

const QrCodeIcon = () => {
  return (
    <IconBox>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.0002 1.3335H10.0002C9.82335 1.3335 9.65378 1.40373 9.52876 1.52876C9.40373 1.65378 9.3335 1.82335 9.3335 2.00016V6.00016C9.3335 6.17697 9.40373 6.34654 9.52876 6.47157C9.65378 6.59659 9.82335 6.66683 10.0002 6.66683H10.6668V8.00016H12.0002V6.66683H13.3335V8.00016H14.6668V2.00016C14.6668 1.82335 14.5966 1.65378 14.4716 1.52876C14.3465 1.40373 14.177 1.3335 14.0002 1.3335ZM12.0002 5.3335H10.6668V2.66683H13.3335V5.3335H12.0002ZM2.00016 6.66683H6.00016C6.17697 6.66683 6.34654 6.59659 6.47157 6.47157C6.59659 6.34654 6.66683 6.17697 6.66683 6.00016V2.00016C6.66683 1.82335 6.59659 1.65378 6.47157 1.52876C6.34654 1.40373 6.17697 1.3335 6.00016 1.3335H2.00016C1.82335 1.3335 1.65378 1.40373 1.52876 1.52876C1.40373 1.65378 1.3335 1.82335 1.3335 2.00016V6.00016C1.3335 6.17697 1.40373 6.34654 1.52876 6.47157C1.65378 6.59659 1.82335 6.66683 2.00016 6.66683ZM2.66683 2.66683H5.3335V5.3335H2.66683V2.66683ZM3.3335 10.6668V12.0002H2.00016V10.6668H3.3335ZM2.00016 13.3335H3.3335V14.6668H2.00016V13.3335ZM4.66683 12.0002V13.3335H3.3335V12.0002H4.66683ZM4.66683 10.6668H3.3335V9.3335H4.66683V8.00016H6.00016V10.6668H4.66683ZM3.3335 8.00016V9.3335H2.00016V8.00016H3.3335ZM9.3335 10.0002V10.6668H8.66683V9.3335H7.3335V12.0002H9.3335V14.0002C9.3335 14.177 9.40373 14.3465 9.52876 14.4716C9.65378 14.5966 9.82335 14.6668 10.0002 14.6668H14.0002C14.177 14.6668 14.3465 14.5966 14.4716 14.4716C14.5966 14.3465 14.6668 14.177 14.6668 14.0002V10.0002C14.6668 9.82335 14.5966 9.65378 14.4716 9.52876C14.3465 9.40373 14.177 9.3335 14.0002 9.3335H10.6668V8.00016H9.3335V10.0002ZM13.3335 10.6668V13.3335H10.6668V10.6668H13.3335ZM6.00016 12.0002H7.3335V13.3335H8.00016V14.6668H4.66683V13.3335H6.00016V12.0002ZM8.66683 4.00016H7.3335V2.66683H8.66683V4.00016ZM7.3335 5.3335H8.66683V8.00016H7.3335V5.3335ZM3.3335 3.3335H4.66683V4.66683H3.3335V3.3335ZM11.3335 3.3335H12.6668V4.66683H11.3335V3.3335ZM12.6668 12.6668H11.3335V11.3335H12.6668V12.6668Z"
          fill="#4A4F55"
        />
      </svg>
    </IconBox>
  );
};

export default QrCodeIcon;
