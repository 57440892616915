import React, { FC, ReactNode } from 'react';
import Modal from '@mui/material/Modal';
import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomBox = styled(Box)<BoxProps>(({ theme }) => ({
  bottom: '0',
  left: '50%',
  width: '100%',
  height: '500px',
  overflow: 'scroll',
  position: 'absolute',
  alignItems: 'center',
  boxSizing: 'border-box',
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
  transform: 'translate(-50%, 0)',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.secondary.light,
}));

type ModalBaseProps = {
  open: boolean;
  children: ReactNode;
  setOpen: (value: boolean) => void;
};

const ModalBase: FC<ModalBaseProps> = ({ open, setOpen, children }) => {
  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <CustomBox>{children}</CustomBox>
    </Modal>
  );
};

export default ModalBase;
