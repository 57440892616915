import { useEffect, useMemo, useState } from 'react';
import {
  useGetLipidFirstLogQuery,
  useGetLipidGoalQuery,
  useGetLipidLastLogQuery,
} from '../../lipid/queries/lipidQueries';
import { goalProgress } from '../../../utils/goalProgress';

const useGetLdlGoalData = () => {
  const [startingDate, setStartingDate] = useState<string>();

  const { data: ldlGoal } = useGetLipidGoalQuery({ type: 'ldl' });

  const { data: lipidCurrent } = useGetLipidLastLogQuery();
  const { data: lipidStarting } = useGetLipidFirstLogQuery(
    {
      startingDate: startingDate || '',
    },
    { skip: !startingDate },
  );

  useEffect(() => {
    if (ldlGoal && ldlGoal.length > 0) {
      setStartingDate(ldlGoal[0].startDate);
    }
  }, [ldlGoal]);

  return useMemo(() => {
    if (!ldlGoal || ldlGoal.length === 0) return null;

    let goalValue;
    let startValue;
    let currentValue;
    let progress = 0;

    if (ldlGoal && ldlGoal.length > 0) {
      goalValue = parseInt(ldlGoal[0].goal.measurement);
    }

    if (lipidStarting && lipidStarting.documents.length > 0) {
      startValue = lipidStarting.documents[0].ldl;
    }

    if (lipidCurrent && lipidCurrent.documents.length > 0) {
      currentValue = lipidCurrent.documents[0].ldl;
      startValue = startValue || currentValue;
    }

    if (goalValue && startValue && currentValue) {
      progress = goalProgress(currentValue, goalValue, startValue);
    }

    return { progress, goalValue, startValue, currentValue };
  }, [ldlGoal, lipidCurrent, lipidStarting]);
};

export default useGetLdlGoalData;
