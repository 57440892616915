import React, { FC, useEffect, useMemo, useState } from 'react';
import { Box, Button, CircularProgress, Typography, useMediaQuery } from '@mui/material';
import moment from 'moment';
import {
  CustomButton,
  HorizontalCalendar,
  MedicationRequest,
  theme,
} from '@hdcorner/ui-library';
import MedicinesList from './MedicinesList';
import MedicationFrequencyModal from './MedicationFrequencyModal';
import MedicationRepeatModal from './MedicationRepeatModal';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useCreateNewMedicationMutation } from '../queries/medicationQueries';
import { extractRepeat } from '../utils/extractRepeat';
import { clear } from '../medicationSlice';
import AddMedicationOverview from './AddMedicationOverview';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import useAlert from '../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

type Props = {
  onSaveSuccess: () => void;
};
const AddMedicationModalWeb: FC<Props> = ({ onSaveSuccess }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { presentSuccess, presentError } = useAlert();
  const web = useMediaQuery(theme.breakpoints.up('sm'));

  const [step, setStep] = useState<number>(1);
  const [startDate, setStartDate] = useState<Date>(moment().toDate());
  const [endDate, setEndDate] = useState<Date>(moment().add(1, 'days').toDate());

  const { medicine, frequency, repeat } = useAppSelector(state => state.medication);

  const [createMedication, { isLoading }] = useCreateNewMedicationMutation();

  useEffect(() => {
    return () => {
      dispatch(clear());
    };
  }, []);

  const Title = useMemo(() => {
    const title = t('medication.titles.addMeds');
    switch (step) {
      case 1:
        return title + `- ${t('medication.titles.selectMeds')}`;
      case 2:
        return title + `- ${t('medication.titles.medOverview')}`;
      case 3:
        return title + `- ${t('medication.titles.repeat')}`;
      case 4:
        return title + `- ${t('medication.titles.frequency')}`;
      case 5:
        return title + `- ${t('medication.titles.duration')}`;
      default:
        return '';
    }
  }, [step]);

  const medication = useMemo(() => {
    return {
      medicine: '',
      repeat: extractRepeat(repeat),
      end: moment(endDate).endOf('day').toISOString(),
      start: moment(startDate).startOf('day').toISOString(),
      frequency: frequency.map(f => {
        const hours = f.time.split(':')[0];
        const minutes = f.time.split(':')[1];
        const time = moment()
          .hours(Number(hours))
          .minutes(Number(minutes))
          .seconds(0)
          .milliseconds(0);
        return {
          amount: f.amount,
          time: time.toISOString(),
        };
      }),
    };
  }, [repeat, endDate, startDate, frequency]);

  const backClick = () => {
    if (step === 2) {
      setStep(1);
    }
    if (step > 2) setStep(2);
  };

  const handleChangeDate = (dateRange: Date[]) => {
    setStartDate(dateRange[0]);
    setEndDate(dateRange[1]);
  };

  const handleSave = () => {
    if (step === 5) {
      setStep(2);
      return;
    }

    if (!medicine) {
      presentError(t('errors.medication.errorPleaseSelect'));
      return;
    }
    const medication: MedicationRequest = {
      repeat: extractRepeat(repeat),
      medicine: medicine._id ? medicine._id : '',
      end: moment(endDate).endOf('day').toISOString(),
      start: moment(startDate).startOf('day').toISOString(),
      frequency: frequency.map(f => {
        const hours = f.time.split(':')[0];
        const minutes = f.time.split(':')[1];
        const time = moment()
          .hours(Number(hours))
          .minutes(Number(minutes))
          .seconds(0)
          .milliseconds(0);
        return {
          amount: f.amount,
          time: time.toISOString(),
        };
      }),
    };

    createMedication(medication)
      .unwrap()
      .then(() => {
        presentSuccess(t('errors.medication.successAddMed'));
        dispatch(clear());
        onSaveSuccess();
      })
      .catch(err => {
        console.error(err);
        presentError(t('errors.medication.errorAddMed'));
      });
  };

  const handleMedicineSelect = () => {
    setStep(2);
  };

  const handleShowRepeat = () => {
    setStep(3);
  };

  const handleShowDuration = () => {
    setStep(5);
  };

  const handleShowFrequency = () => {
    setStep(4);
  };

  const RenderSection = useMemo(() => {
    switch (step) {
      case 1:
        return <MedicinesList handleMedicineSelect={handleMedicineSelect} />;
      case 2:
        return (
          <AddMedicationOverview
            medicine={medicine}
            medication={medication}
            handleShowRepeat={handleShowRepeat}
            handleShowDuration={handleShowDuration}
            handleShowFrequency={handleShowFrequency}
          />
        );
      case 3:
        return <MedicationRepeatModal handleRepeatSelect={() => setStep(2)} />;
      case 4:
        return <MedicationFrequencyModal handleFrequencySelect={() => setStep(2)} />;
      case 5:
        return (
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            <HorizontalCalendar
              openMonthly
              selectFutureDates
              selectedDate={[startDate, endDate]}
              handleChange={date => handleChangeDate(date as Date[])}
            />
            <CustomButton onClick={() => setStep(2)} variant={'contained'}>
              {t('buttons.next')}
            </CustomButton>
          </Box>
        );
    }
    return;
  }, [step, medicine, medication, startDate, endDate]);

  return (
    <Box
      p={3}
      gap={2}
      height={'100%'}
      display={'flex'}
      flexDirection={'column'}
      minWidth={web ? 500 : 'none'}
      justifyContent={'space-between'}
    >
      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <Box display={'flex'} alignItems={'center'}>
          {step > 1 && (
            <Button onClick={backClick}>
              <ArrowBackIosIcon />
            </Button>
          )}
          <Typography variant={'h1'} sx={{ color: theme.palette.primary.main }}>
            {Title}
          </Typography>
        </Box>
        {RenderSection}
      </Box>

      {step === 2 && (
        <Box display={'flex'} justifyContent={'center'} flexDirection={'column'} gap={2}>
          <CustomButton
            onClick={handleSave}
            variant={'contained'}
            disabled={
              !endDate ||
              isLoading ||
              !medicine ||
              !startDate ||
              !repeat?.length ||
              !frequency?.length
            }
          >
            {t('buttons.createMedication')}
          </CustomButton>
          {isLoading && (
            <Box
              display={'flex'}
              alignItems={'center'}
              flexDirection={'column'}
              justifyContent={'center'}
            >
              <Typography variant={'subtitle2'}>
                {t('medication.text.creatingMeds')}
              </Typography>
              <CircularProgress />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default AddMedicationModalWeb;
