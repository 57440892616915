import * as React from 'react';
import { SVGProps } from 'react';

const TakeIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.3337 4.5L6.00033 11.8333L2.66699 8.5"
        stroke={props?.color ?? '#5FD071'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TakeIcon;
