import * as React from 'react';
import { SVGProps } from 'react';

const FacebookIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={36}
    height={36}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={36} height={36} rx={8} fill="#4267B2" />
    <path
      d="M16.4601 28V20.9631H13.9161V18.0533H16.4631V15.8382C16.3492 14.7886 16.7062 13.7425 17.4374 12.9837C18.1685 12.2249 19.1979 11.8319 20.2464 11.9115C20.999 11.9237 21.7497 11.991 22.4925 12.1128V14.5887H21.225C20.7887 14.5313 20.35 14.676 20.0326 14.982C19.7151 15.2881 19.5533 15.7223 19.5926 16.1624V18.0533H22.3712L21.9269 20.9641H19.5926V28H23.9891C26.2042 28 28 26.2079 28 23.9972V12.0028C28 9.7921 26.2042 8 23.9891 8H12.0109C9.79576 8 8 9.79211 8 12.0028V23.9972C8 26.2079 9.79575 28 12.0109 28H16.4601Z"
      fill="white"
    />
  </svg>
);

export default FacebookIcon;
