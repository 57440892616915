import React, { FC, ReactNode } from 'react';
import {
  Box,
  MenuItem,
  MenuItemProps,
  Select,
  SelectChangeEvent,
  SelectProps,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DropdownDownArrowDisabled from '../../assets/DropdownDownArrowDisabled';
import DropdownDownArrow from '../../assets/DropdownDownArrow';

const StyledSelect = styled(Select, {
  shouldForwardProp: propName =>
    propName !== 'alternativeBackground' && propName !== 'noBorder',
})<SelectProps & { alternativeBackground?: boolean; noBorder?: boolean }>(
  ({ theme, alternativeBackground, noBorder }) => ({
    '&.MuiInputBase-root': {
      borderRadius: theme.shape.borderRadius,
      background: alternativeBackground
        ? theme.palette.secondary.light
        : theme.palette.secondary.main,
      padding: theme.spacing(1, 2),
      border: !noBorder ? `1px ${theme.palette.secondary.main} solid` : 'none',
      ...theme.typography.subtitle2,
    },
    '&.MuiInputBase-root > svg': {
      transform: 'translate(-15px,-5px)',
    },

    '&.MuiSelect-select': {
      padding: 0,
      background: 'transparent',
    },
  }),
);

const CustomMenuItem = styled(MenuItem)<MenuItemProps>(({ theme }) => ({
  borderRadius: theme.spacing(1),
  background: theme.palette.secondary.main,
  padding: theme.spacing(1),
  color: theme.palette.primary.main,
  ...theme.typography.subtitle2,
  '&.Mui-selected': {
    background: theme.palette.secondary.dark,
  },
}));

type DropdownMenuType = {
  value: string;
  label?: string;
  noBorder?: boolean;
  startIcon?: ReactNode;
  alternativeBackground?: boolean;
  menuItems: { label: string; value: string }[] | string[];
  setValue: (value: string) => void;
};

const CustomSelect: FC<DropdownMenuType & SelectProps> = ({
  value,
  label,
  setValue,
  startIcon,
  menuItems,
  disabled,
  noBorder,
  alternativeBackground,
  ...selectProps
}) => {
  const theme = useTheme();

  const handleDropdownChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as string;
    setValue(value);
  };

  const findRenderedValue = (value: string) => {
    const isObject = typeof menuItems[0] === 'object';
    if (!isObject) {
      return value;
    }

    const foundItem = (menuItems as { label: string; value: string }[]).find(
      item => item.value === value,
    );
    if (!foundItem) {
      return value;
    }

    return foundItem.label;
  };

  return (
    <Box
      gap={1}
      display={'flex'}
      flexDirection={'column'}
      width={selectProps.fullWidth ? '100%' : 'inherit'}
    >
      {label && (
        <Typography
          variant={'subtitle2'}
          fontWeight={'fontWeightBold'}
          sx={{
            color:
              theme.palette.mode === 'dark'
                ? theme.palette.kmColorsCoolGrey.main
                : theme.palette.primary.main,
          }}
        >
          {label}
        </Typography>
      )}
      <Box>
        <StyledSelect
          fullWidth
          displayEmpty
          value={value}
          {...selectProps}
          disableUnderline
          noBorder={noBorder}
          variant={'standard'}
          onChange={handleDropdownChange}
          alternativeBackground={alternativeBackground}
          renderValue={(selected: any) => {
            if (!selected || selected === '' || selected.length === 0) {
              return (
                <Box
                  gap={1}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'flex-start'}
                >
                  {startIcon}
                  <Typography variant={'subtitle2'} fontWeight={'fontWeightBold'}>
                    {selectProps.placeholder}
                  </Typography>
                </Box>
              );
            }

            return (
              <Box
                gap={1}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'flex-start'}
              >
                {startIcon}
                {findRenderedValue(selected)}
              </Box>
            );
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            sx: {
              '.MuiMenu-list': {
                padding: theme.spacing(1),
                borderRadius: theme.spacing(1),
                background: theme.palette.secondary.main,
              },
              '.MuiMenu-paper': {
                transform: 'translateY(15px) !important',
              },
            },
          }}
          inputProps={{
            IconComponent: disabled ? DropdownDownArrowDisabled : DropdownDownArrow,
          }}
        >
          {selectProps.placeholder && (
            <CustomMenuItem value={''}>{selectProps.placeholder}</CustomMenuItem>
          )}
          {menuItems.map((option, index) => (
            <CustomMenuItem
              key={index}
              value={typeof option === 'object' ? option.value : option}
            >
              {typeof option === 'object' ? option.label : option}
            </CustomMenuItem>
          ))}
        </StyledSelect>
      </Box>
    </Box>
  );
};

export default CustomSelect;
