import * as React from 'react';
import { SVGProps } from 'react';
import { Box, BoxProps, styled } from '@mui/material';

const IconBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: '32px',
  height: '32px',
  borderRadius: '4px',
  padding: '4px',
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.secondary.main,
}));

const ProfileSQIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <IconBox>
      <svg
        width="12"
        height="16"
        viewBox="0 0 12 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M6 7.33268C6.65927 7.33268 7.30374 7.13719 7.8519 6.77092C8.40006 6.40464 8.82731 5.88405 9.0796 5.27496C9.33189 4.66588 9.3979 3.99565 9.26929 3.34905C9.14067 2.70245 8.8232 2.1085 8.35702 1.64233C7.89085 1.17615 7.2969 0.858684 6.6503 0.730066C6.0037 0.601449 5.33348 0.66746 4.72439 0.919752C4.1153 1.17204 3.59471 1.59929 3.22843 2.14745C2.86216 2.69561 2.66667 3.34008 2.66667 3.99935C2.66773 4.88308 3.01925 5.73031 3.64415 6.3552C4.26904 6.9801 5.11627 7.33162 6 7.33268ZM6 1.99935C6.39556 1.99935 6.78224 2.11665 7.11114 2.33641C7.44004 2.55617 7.69638 2.86853 7.84776 3.23398C7.99913 3.59944 8.03874 4.00157 7.96157 4.38953C7.8844 4.77749 7.69392 5.13386 7.41421 5.41356C7.13451 5.69327 6.77814 5.88375 6.39018 5.96092C6.00222 6.03809 5.60009 5.99849 5.23463 5.84711C4.86918 5.69573 4.55682 5.43939 4.33706 5.11049C4.1173 4.78159 4 4.39491 4 3.99935C4 3.46892 4.21071 2.96021 4.58579 2.58514C4.96086 2.21006 5.46957 1.99935 6 1.99935ZM0.666667 15.3327H11.3333C11.5101 15.3327 11.6797 15.2624 11.8047 15.1374C11.9298 15.0124 12 14.8428 12 14.666C12 13.0747 11.3679 11.5486 10.2426 10.4234C9.11742 9.29816 7.5913 8.66602 6 8.66602C4.4087 8.66602 2.88258 9.29816 1.75736 10.4234C0.632141 11.5486 2.37122e-08 13.0747 0 14.666C0 14.8428 0.0702379 15.0124 0.195262 15.1374C0.320286 15.2624 0.489856 15.3327 0.666667 15.3327ZM6 9.99935C7.12184 10.0007 8.20576 10.4056 9.05383 11.14C9.90191 11.8743 10.4576 12.8892 10.6193 13.9994H1.38067C1.54244 12.8892 2.09809 11.8743 2.94617 11.14C3.79424 10.4056 4.87816 10.0007 6 9.99935Z"
          fill="#4A4F55"
        />
      </svg>
    </IconBox>
  );
};

export default ProfileSQIcon;
