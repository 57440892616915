import React, { FC, useEffect, useState } from 'react';
import { AuthUser, CustomButton } from '@hdcorner/ui-library';
import { Box, BoxProps, ButtonProps, styled, Typography } from '@mui/material';
import { useAppSelector } from '../../../redux/hooks';
import moment from 'moment';
import {
  useAssignDietPlanMutation,
  useCreateDietPlanMutation,
  useEditDietPlanMutation,
  useGetDietPlanQuery,
} from './queries/dietPlanQueries';
import { calculateCalories } from '../utils/caloriesFormula';
import DietCalculatorFirstStep from './DietCalculatorFirstStep';
import DietCalculatorSecondStep from './DietCalculatorSecondStep';
import { usePostCaloriesInDepthMutation } from '../calories/queries/caloriesQueries';
import useAlert from '../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

const BoxContainer = styled(Box, { shouldForwardProp: propName => propName !== 'inner' })<
  BoxProps & { inner: boolean }
>(({ theme, inner }) => ({
  width: '100%',
  display: 'flex',
  borderRadius: '8px',
  boxSizing: 'border-box',
  flexDirection: 'column',
  gap: theme.spacing(3),
  padding: theme.spacing(3),
  background: inner ? theme.palette.highlight.main : theme.palette.secondary.light,
}));

const Button = styled(CustomButton)<ButtonProps>(({ theme }) => ({
  ...theme.typography.subtitle2,
  height: 48,
  width: '240px',
  boxShadow: theme.shadows[0],
  color: theme.palette.secondary.main,
  background: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightRegular,
  padding: theme.spacing(1.5, 0),
}));

type Props = {
  handleCompletion: () => void;
};
const DietCalculatorWeb: FC<Props> = ({ handleCompletion }) => {
  const { presentError } = useAlert();
  const { t } = useTranslation();
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const {
    data: { user },
  } = useAppSelector(state => state.auth);

  const { data: dietPlan } = useGetDietPlanQuery();

  const [assignDietPlan] = useAssignDietPlanMutation();
  const [editCalculatedCalories] = useEditDietPlanMutation();
  const [setCalculatedCalories] = useCreateDietPlanMutation();
  const [postCaloriesInDepth] = usePostCaloriesInDepthMutation();

  const [userData, setUserData] = useState({
    sex: '',
    age: 0,
    weight: 0,
    height: 0,
  });

  const [changePerWeek, setChangePerWeek] = useState<number>(750);

  const [activityLevel, setActivityLevel] = useState<'light' | 'moderate' | 'heavy'>(
    'light',
  );
  const [goal, setGoal] = useState<'lose_weight' | 'muscle_gain' | 'maintain_weight'>(
    'maintain_weight',
  );
  const [dietType, setDietType] = useState<
    'standard' | 'low_carb' | 'low_fat' | 'high_protein'
  >('standard');

  useEffect(() => {
    if (user && user.authUser) {
      const authUser = user.authUser as AuthUser;
      const age = moment().diff(moment(authUser.birthDate), 'years');
      setUserData({
        age: age,
        height: user.height || 0,
        weight: user.weight || 0,
        sex: authUser.gender ? authUser.gender : 'male',
      });
    }
  }, [user]);

  const handleCalculate = async () => {
    const data = {
      goal,
      dietType,
      changePerWeek,
      activityLevel,
      sex: userData.sex,
      age: userData.age,
      weight: userData.weight,
      height: userData.height,
    };

    const calories = calculateCalories(data);

    let reqPromise;
    if (dietPlan && dietPlan.length > 0) {
      reqPromise = editCalculatedCalories({
        calculatedCalories: calories,
        dietType,
      });
    } else {
      reqPromise = setCalculatedCalories({
        calculatedCalories: calories,
        dietType,
      });
    }

    try {
      await reqPromise.unwrap();
      await assignDietPlan().unwrap();
      await postCaloriesInDepth().unwrap();
      handleCompletion();
    } catch (e) {
      console.log(e);
      presentError(t('errors.fitness.dietPlan.errorAdd'));
    }
  };

  return (
    <BoxContainer inner={false}>
      <Box
        flex={1}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Typography
          variant={'h1'}
          textTransform={lang === 'el' ? 'capitalize' : 'uppercase'}
        >
          {t('headingsTitles.personalDietCal')}
        </Typography>
        <Button
          fullWidth
          disableRipple
          disableElevation
          disableFocusRipple
          variant={'contained'}
          onClick={handleCalculate}
        >
          {t('buttons.calculate')}
        </Button>
      </Box>
      <BoxContainer inner>
        <DietCalculatorFirstStep user={userData} setUser={setUserData} />
      </BoxContainer>
      <BoxContainer inner>
        <DietCalculatorSecondStep
          goal={goal}
          setGoal={setGoal}
          dietType={dietType}
          setDietType={setDietType}
          activityLevel={activityLevel}
          changePerWeek={changePerWeek}
          setChangePerWeek={setChangePerWeek}
          setActivityLevel={setActivityLevel}
        />
      </BoxContainer>
    </BoxContainer>
  );
};

export default DietCalculatorWeb;
