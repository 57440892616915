import PageLayout from '../../../components/PageLayout';
import { ClickNavCardNutrition, ComponentsLayout } from '@hdcorner/ui-library';
import SectionGraph from './components/SectionGraph';
import { useIonRouter } from '@ionic/react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const DailyCalories = () => {
  const { t } = useTranslation();
  const router = useIonRouter();

  const handleNextPage = () => {
    router.push('/dashboard/fitness/calories/in-depth-data');
  };

  return (
    <PageLayout
      defaultHref={'/dashboard/fitness'}
      headerTitle={t('headingsTitles.dailyCal')}
    >
      <ComponentsLayout>
        <SectionGraph toggle heading={false} />
        <Box mb={3}></Box>
        <ClickNavCardNutrition
          onClick={handleNextPage}
          headings={t('buttons.navigation.inDepth')}
        />
      </ComponentsLayout>
    </PageLayout>
  );
};

export default DailyCalories;
