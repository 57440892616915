import * as React from 'react';
import { SVGProps } from 'react';

const TooltipDataIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={22} height={19} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19 .5H3a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h16a3 3 0 0 0 3-3v-12a3 3 0 0 0-3-3Zm1 15a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-12a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v12Zm-12-6v4a1 1 0 1 1-2 0v-4a1 1 0 0 1 2 0Zm4-2v6a1 1 0 0 1-2 0v-6a1 1 0 0 1 2 0Zm4-2v8a1 1 0 0 1-2 0v-8a1 1 0 0 1 2 0Z"
      fill="#EE1D23"
    />
  </svg>
);

export default TooltipDataIcon;
