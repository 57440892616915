import {
  CalendarModal,
  ClickNavCardNutrition,
  ComponentsLayout,
  CvdRiskLog,
  ModalHeartRiskEU,
  ModalMobile,
} from '@hdcorner/ui-library';
import { useIonRouter } from '@ionic/react';
import { Box, useTheme } from '@mui/material';
import moment from 'moment/moment';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import CvdRiskIcon from '../../assets/icons/CvdRiskIcon';
import ModalAddEditGoal from '../../components/ModalAddEditGoal';
import PageLayout from '../../components/PageLayout';
import { useAppDispatch } from '../../redux/hooks';
import CvdRiskChartCard from './components/CvdRiskChartCard';
import useGetUserData from './hooks/useGetUserData';
import {
  useAddCvdRiskGoalMutation,
  useAddCvdRiskLogMutation,
  useGetCvdCurrentMeasurementQuery,
  useGetCvdGraphDataQuery,
  useGetCvdRiskGoalQuery,
  useGetCvdStartingMeasurementQuery,
} from './queries/cvdRiskQueries';
import useTimeframe from '../../hooks/useTimeframe';
import useIdealData from './hooks/useIdealData';
import usePrepareGraphData from './hooks/usePrepareGraphData';
import useAlert from '../../hooks/useAlert';
import { goalProgress } from '../../utils/goalProgress';
import { addGoalDetails } from '../dashboard/slices/congratulationsSlice';
import { useTranslation } from 'react-i18next';

const CvdRisk: FC = () => {
  const theme = useTheme();
  const router = useIonRouter();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { presentSuccess, presentError } = useAlert();

  const [openEditGoal, setOpenEditGoal] = useState<boolean>(false);
  const [addLogModalOpen, setAddLogModalOpen] = useState<boolean>(false);
  const [selectedTimeframe, setSelectedTimeframe] = useState<string>('all');
  const [calendarModalOpen, setCalendarModalOpen] = useState<boolean>(false);

  // Used for updating the params for the query
  const [endParam, setEndParam] = useState<string>('');
  const [startParam, setStartParam] = useState<string>('');

  const useDetails = useGetUserData();
  const timeframe = useTimeframe(selectedTimeframe, startParam, endParam);

  const [addCvdRiskLog] = useAddCvdRiskLogMutation();
  const [addCvdRiskGoal] = useAddCvdRiskGoalMutation();

  const { data: currentMeasurement } = useGetCvdCurrentMeasurementQuery();
  const { data: cvdRiskGoal, error: cvdRiskGoalError } = useGetCvdRiskGoalQuery();
  const { data: graphData, error: errorGraphData } = useGetCvdGraphDataQuery(timeframe);

  const idealData = useIdealData(currentMeasurement);
  const prepareGraphData = usePrepareGraphData(graphData);

  const goalData = useMemo(() => {
    if (!cvdRiskGoal || cvdRiskGoal.length === 0) {
      return { goal: 0, date: '' };
    }
    return { goal: cvdRiskGoal[0].goal, date: cvdRiskGoal[0].startDate };
  }, [cvdRiskGoal]);

  const { data: cvdStarting } = useGetCvdStartingMeasurementQuery(
    {
      startingDate: goalData.date,
    },
    { skip: !goalData.date },
  );

  const startLog = useMemo(() => {
    if (!cvdStarting || cvdStarting.logs.length === 0) return 0;
    return cvdStarting.logs[0].score;
  }, [cvdStarting]);

  const currentLog = useMemo(() => {
    if (!currentMeasurement || currentMeasurement.logs.length === 0) return 0;
    return currentMeasurement.logs[0].score;
  }, [currentMeasurement]);

  useEffect(() => {
    if (errorGraphData) {
      const { status, data } = errorGraphData as any;
      if (status === 500) {
        presentError(data.message);
      } else {
        presentError(t('errors.cvd.fetchGraph'));
      }
    }
  }, [errorGraphData]);

  useEffect(() => {
    if (cvdRiskGoalError) {
      presentError(t('errors.cvd.fetchGoal'));
    }
  }, [cvdRiskGoalError, dispatch]);

  const handleAddCvdRisk = async (data: Partial<CvdRiskLog>) => {
    const { logDate, totalChol, sysBloodPressure } = data;

    try {
      await addCvdRiskLog({
        logDate,
        totalChol,
        sysBloodPressure,
      })
        .unwrap()
        .then(() => {
          presentSuccess(t('errors.cvd.logAdded'));
          if (
            goalProgress(currentLog, goalData.goal, startLog) === 100 &&
            goalData.date !== ''
          ) {
            setTimeout(() => {
              dispatch(
                addGoalDetails({
                  category: 'cvd',
                  goal: { value: goalData.goal, measure: '%' },
                  start: { value: startLog, measure: '%', time: goalData.date },
                }),
              );
            }, 1500);
          }
        });
    } catch (e) {
      presentError(t('errors.generic.error01'));
    }
  };

  const handleSaveHeartRiskModal = (data: Partial<CvdRiskLog>) => {
    const dataForRequest = {
      logDate: data.logDate,
      totalChol: data.totalChol,
      sysBloodPressure: data.sysBloodPressure,
    };
    handleAddCvdRisk(dataForRequest);
    setAddLogModalOpen(false);
  };

  const handleClickGoal = useCallback(() => {
    if (cvdRiskGoal && cvdRiskGoal.length > 0) {
      router.push('/dashboard/cvd-risk-goal');
      return;
    }
    setOpenEditGoal(true);
  }, [cvdRiskGoal, router]);

  const handleSaveClick = async (newGoal: string) => {
    if (newGoal) {
      try {
        await addCvdRiskGoal({
          goalType: 'cvd_risk',
          goal: parseFloat(newGoal),
          startDate: moment().startOf('day').toISOString(),
        }).unwrap();
        presentSuccess(t('errors.cvd.goalAdded'));
      } catch (e) {
        presentError(t('errors.cvd.errorAddGoal'));
      }
    }
    setOpenEditGoal(false);
  };

  const handleDateChange = (dateRange: string[]) => {
    setStartParam(dateRange[0]);
    setEndParam(dateRange[1]);
  };

  return (
    <PageLayout
      defaultHref={'/dashboard/home'}
      headerTitle={`${t('cvd.titles.riskCalc')}`}
      headerColor={theme.palette.cvdRisk.main}
      fabClick={() => setAddLogModalOpen(true)}
      headerIcon={<CvdRiskIcon fill={theme.palette.cvdRisk.main} />}
    >
      <ComponentsLayout>
        <CvdRiskChartCard
          data={idealData}
          chartData={prepareGraphData}
          selectedToggle={selectedTimeframe}
          setSelectedToggle={setSelectedTimeframe}
          openCalendar={() => setCalendarModalOpen(true)}
        />
        <Box mt={3}>
          <ClickNavCardNutrition
            headings={`${t('buttons.navigation.inDepth')}`}
            onClick={() => router.push('/dashboard/cvd-in-depth-data')}
          />
          <ClickNavCardNutrition
            headings={`${t('buttons.navigation.goal')}`}
            onClick={handleClickGoal}
            body={
              cvdRiskGoal?.[0] && cvdRiskGoal?.[0]?.goal ? `${cvdRiskGoal[0].goal} %` : ''
            }
          />
        </Box>
      </ComponentsLayout>

      <ModalMobile open={addLogModalOpen} setOpen={setAddLogModalOpen}>
        <ModalHeartRiskEU
          userAge={useDetails.age}
          userGender={useDetails.gender}
          userSmoker={useDetails.smoker || false}
          handleModalSaveClick={handleSaveHeartRiskModal}
        />
      </ModalMobile>
      <ModalMobile open={openEditGoal} setOpen={setOpenEditGoal}>
        <ModalAddEditGoal
          goalType={'cvd'}
          desiredPlaceholder={'1%'}
          handleSaveClick={handleSaveClick}
        />
      </ModalMobile>
      <CalendarModal
        open={calendarModalOpen}
        saveDate={handleDateChange}
        setOpen={setCalendarModalOpen}
        selectedDate={[startParam, endParam]}
      />
    </PageLayout>
  );
};

export default CvdRisk;
