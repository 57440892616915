import {
  Box,
  BoxProps,
  IconButton,
  styled,
  Table,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import moment from 'moment';
import { useIonRouter } from '@ionic/react';
import { ArrowLeft, FeelsEmpty } from '@hdcorner/ui-library';
import PageLayout from '../../components/PageLayout';
import { useTranslation } from 'react-i18next';
import { useGetBillingHistoryQuery } from './queries/subscriptionsQueries';
import useAlert from '../../hooks/useAlert';
import { Product } from './types/SubscriptionHistory';

const NavBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  borderRadius: '8px',
  boxSizing: 'border-box',
  gap: theme.spacing(3),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.secondary.light,
}));

const MobBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  borderRadius: '8px',
  boxSizing: 'border-box',
  flexDirection: 'column',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.secondary.light,
  border: `1px solid ${theme.palette.highlight.main}`,
}));

const TableCellData = styled(TableCell, {
  shouldForwardProp: prop => prop !== 'heading' && prop !== 'web',
})<TableCellProps & { web: boolean; heading?: boolean }>(({ web, theme, heading }) => ({
  flexGrow: '1',
  textAlign: 'center',
  boxSizing: 'border-box',
  textOverflow: 'ellipsis',
  height: web ? '72px' : '32',
  overflow: web ? 'hidden' : 'none',
  color: theme.palette.primary.main,
  whiteSpace: web ? 'nowrap' : 'normal',
  fontSize: heading
    ? theme.typography.subtitle1.fontSize
    : theme.typography.subtitle2.fontSize,
  fontWeight: heading
    ? theme.typography.fontWeightMedium
    : theme.typography.subtitle1.fontWeight,
  backgroundColor: theme.palette.secondary.light,
  borderBottom: web ? `1px solid ${theme.palette.secondary.dark}` : 'none',
  '&:first-of-type': {
    textAlign: 'left',
  },
  padding: web ? theme.spacing(1, 2) : theme.spacing(0, 1, 3, 0),
}));

const headings = [
  'subscription.labels.subType',
  'subscription.labels.paymentType',
  'subscription.labels.codeUsed',
  'subscription.labels.date',
  'subscription.labels.totalCost',
];

const SubscriptionHistoryList = () => {
  const theme = useTheme();
  const router = useIonRouter();
  const { t } = useTranslation();
  const { presentError } = useAlert();
  const web = useMediaQuery(theme.breakpoints.up('md'));
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const { error, data: billingHistory } = useGetBillingHistoryQuery({
    skip: 0,
    limit: 100,
  });

  useEffect(() => {
    if (error) {
      if ((error as any)?.data?.message === 'Customer does not exist') {
        return;
      }
      presentError(t('errors.subscription.errorFetchBilling'));
    }
  }, [error]);

  const billingData = useMemo(() => {
    if (!billingHistory || billingHistory.transactions.length === 0) return [];
    return billingHistory.transactions.map(data => {
      const product = data.products ? data.products[0] : null;
      const actualProduct = product ? (product.product as Product) : null;
      return {
        code: '-',
        createdAt: data.createdAt,
        payment: data.cardId ? 'Card' : '-',
        total: `${data.priceWithVat / 100}€` || '-€',
        subscription: actualProduct ? actualProduct.productDescription : '-',
      };
    });
  }, [billingHistory]);

  const createData = (row: any) => {
    if (web) {
      return [
        row.subscription,
        row.payment,
        row.code,
        moment(row.createdAt).format('DD MMMM, YYYY'),
        row.total,
      ];
    } else {
      return [
        row.subscription,
        row.payment,
        moment(row.createdAt).format('DD/MM/YYYY'),
        row.total,
      ];
    }
  };

  const handleBack = () => {
    router.push('/dashboard/subscription', 'back', 'pop');
  };

  return (
    <PageLayout
      backgroundWhite={!web}
      defaultHref={'/dashboard/subscription'}
      headerTitle={t('subscription.titles.billing')}
    >
      <Box
        gap={3}
        display={'flex'}
        flexDirection={'column'}
        boxSizing={'border-box'}
        padding={theme.spacing(3)}
      >
        {web && (
          <NavBox>
            <IconButton sx={{ padding: 0 }} onClick={handleBack}>
              <ArrowLeft />
            </IconButton>
            <Typography
              variant={'subtitle2'}
              sx={{ fontWeight: theme.typography.fontWeightBold }}
            >
              {t('subscription.titles.billing')}
            </Typography>
          </NavBox>
        )}
        <Typography
          variant={web ? 'h1' : 'subtitle1'}
          sx={{
            fontWeight: web
              ? theme.typography.fontWeightBold
              : theme.typography.fontWeightRegular,
          }}
        >
          {t('subscription.labels.latest')}
        </Typography>
        {billingData.length > 0 && web && (
          <Table
            sx={{
              '&:last-child th, &:last-child td': {
                '&:first-of-type': {
                  borderTopLeftRadius: '8px',
                },
                '&:last-of-type': {
                  borderTopRightRadius: '8px',
                },
              },
            }}
          >
            <TableHead>
              <TableRow>
                {headings.map((data, index) => (
                  <TableCellData heading key={index} web={web}>
                    {t(data)}
                  </TableCellData>
                ))}
              </TableRow>
            </TableHead>
            {billingData.map(row => (
              <TableRow
                sx={{
                  '&:last-child th, &:last-child td': {
                    borderBottom: 0,
                    '&:first-of-type': {
                      borderBottomLeftRadius: '8px',
                    },
                    '&:last-of-type': {
                      borderBottomRightRadius: '8px',
                    },
                  },
                }}
              >
                {createData(row).map((cell, index) => (
                  <TableCellData web={web} key={index}>
                    {cell}
                  </TableCellData>
                ))}
              </TableRow>
            ))}
          </Table>
        )}
        {billingData.length > 0 && !web && (
          <MobBox>
            <Table>
              {billingData.map(row => (
                <>
                  <Typography
                    sx={{
                      whiteSpace: 'nowrap',
                      color: theme.palette.primary.light,
                      marginBottom: theme.spacing(2),
                      fontWeight: theme.typography.fontWeightBold,
                      textTransform: lang === 'el' ? 'capitalize' : 'uppercase',
                    }}
                  >
                    {moment(row.createdAt).format('MMMM YYYY')}
                  </Typography>
                  <TableRow>
                    {createData(row).map((cell, index) => (
                      <TableCellData
                        web={web}
                        key={index}
                        sx={{
                          fontSize: theme.typography.body1.fontSize,
                          fontWeight: theme.typography.fontWeightRegular,
                        }}
                      >
                        {cell}
                      </TableCellData>
                    ))}
                  </TableRow>
                </>
              ))}
            </Table>
          </MobBox>
        )}
        {!billingData || (billingData.length === 0 && <FeelsEmpty />)}
      </Box>
    </PageLayout>
  );
};

export default SubscriptionHistoryList;
