const trafficLightSections = [
  { label: 'constants.trafficLights.fishMeat', value: 'fish_meats' },
  { label: 'constants.trafficLights.dairy', value: 'dairy_products' },
  { label: 'constants.trafficLights.eggs', value: 'eggs' },
  { label: 'constants.trafficLights.fruitVeg', value: 'fruits_vegetables' },
  { label: 'constants.trafficLights.breadGrains', value: 'bread_grains_legumes_cereals' },
  { label: 'constants.trafficLights.drinks', value: 'drinks' },
  { label: 'constants.trafficLights.fats', value: 'fats_oils' },
  { label: 'constants.trafficLights.misc', value: 'miscellaneous' },
];

export { trafficLightSections };
