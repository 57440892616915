import React, { FC } from 'react';
import {
  Box,
  BoxProps,
  Card,
  CardActionArea,
  CardActionAreaProps,
  CardContent,
  CardContentProps,
  CardProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

interface CardMainMoreProps extends CardProps {
  selected: boolean;
}
const CardMain = styled((props: CardMainMoreProps) => {
  const { selected, ...other } = props;
  return <Card {...other} raised={false} />;
})(({ theme, selected }) => ({
  flex: 1,
  height: 72,
  borderRadius: 8,
  display: 'flex',
  overflow: 'hidden',
  position: 'relative',
  flexDirection: 'column',
  boxShadow: theme.shadows[0],
  background: selected ? theme.palette.primary.main : undefined,
  border: !selected
    ? `1px solid ${theme.palette.secondary.dark}`
    : `1px solid transparent`,
}));

const CardActionAreaMain = styled(CardActionArea)<CardActionAreaProps>(() => ({
  height: '100%',
}));

const CardContentMain = styled(CardContent)<CardContentProps>(({ theme }) => ({
  flex: 1,
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0),
  '&.MuiCardContent-root:last-child': {
    paddingBottom: theme.spacing(1),
  },
}));

const InsideCard = styled(Box)<BoxProps>(({ theme }) => ({
  flex: 1,
  borderRadius: 8,
  display: 'flex',
  overflow: 'hidden',
  backgroundColor: theme.palette.secondary.light,
  margin: theme.spacing(0.25, 0.25, 0.25, 0.75),
}));

const OuterContainer = styled(Box)<BoxProps>(({ theme }) => ({
  flex: 1,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(1.5),
}));

const InnerContainer = styled(Box)<BoxProps>(() => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const PlanTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  textTransform: 'capitalize',
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightMedium,
}));

const PlanSubtitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.kmColorsCoolGrey.main,
  fontWeight: theme.typography.fontWeightBold,
}));

const PricePremium = styled(Typography)<TypographyProps>(({ theme }) => ({
  display: 'flex',
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightMedium,
}));

const PricePremiumPer = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  margin: theme.spacing(0.3, 0, 0, 0.3),
}));

type SubscriptionPlanDetailsType = {
  isBasic?: boolean;
  selected: boolean;
  cardContentProps?: CardContentProps;
  cardActionAreaProps?: CardActionAreaProps;
  handleSelected: (plan: 'basic' | 'premium') => void;
  data: { price: string; description?: string; isMonthly?: boolean; isAnnual?: boolean };
};
const SubscriptionPlanDetails: FC<SubscriptionPlanDetailsType & CardProps> = ({
  data,
  isBasic,
  selected,
  handleSelected,
  cardContentProps,
  cardActionAreaProps,
  ...cardProps
}) => {
  const { t } = useTranslation();

  return (
    <CardMain selected={selected} {...cardProps}>
      <CardActionAreaMain
        onClick={() => handleSelected(isBasic ? 'basic' : 'premium')}
        {...cardActionAreaProps}
      >
        <CardContentMain {...cardContentProps}>
          <InsideCard>
            <OuterContainer>
              <InnerContainer>
                <PlanTitle variant={'subtitle1'}>
                  {data.description
                    ? data.description
                    : isBasic
                    ? t('userDetails.basic')
                    : t('userDetails.premium')}
                </PlanTitle>
                <PlanSubtitle variant={'caption'}>
                  {isBasic
                    ? t('subscription.text.basicFeatures')
                    : t('subscription.labels.recurring')}
                </PlanSubtitle>
              </InnerContainer>
              <PricePremium variant={'h1'} alignSelf={'center'}>
                {isBasic ? data?.price : `${data.price}€`}
                {!isBasic && (
                  <PricePremiumPer variant={'caption'}>
                    {data.isMonthly && `/ ${t('constants.subscription.plans.mon')}`}
                    {data.isAnnual && `/ ${t('constants.subscription.plans.annua')}`}
                  </PricePremiumPer>
                )}
              </PricePremium>
            </OuterContainer>
          </InsideCard>
        </CardContentMain>
      </CardActionAreaMain>
    </CardMain>
  );
};

export default SubscriptionPlanDetails;
