import Box from '@mui/material/Box';
import * as React from 'react';
import { FC, useEffect, useMemo, useState } from 'react';
import {
  AllMeasurements,
  CalendarModal,
  ClickNavCardNutrition,
  CustomButton,
  FeelsEmpty,
  LineChart,
  ModalFilters,
  ToggleButtonsComp,
  usePreparePulsesGraph,
  weekMonthYearCustom,
} from '@hdcorner/ui-library';
import { useIonRouter } from '@ionic/react';
import { Typography, useTheme } from '@mui/material';
import GraphDots from './components/GraphDots';
import {
  useGetHypertensionOverallQuery,
  useGetLatestLogQuery,
  useGetPulsesGraphDataQuery,
} from './queries/hypertensionQueries';
import useTimeframe from '../../hooks/useTimeframe';
import usePrepareOverallData from './hooks/usePrepareOverallData';
import useCategoryLabel from './hooks/useCategoryLabel';
import { MeasurementOptions } from './constants';
import useAlert from '../../hooks/useAlert';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../../utils/useConvertJSON';

const HypertensionPulses: FC<unknown> = () => {
  const theme = useTheme();
  const router = useIonRouter();
  const { t } = useTranslation();
  const lang: 'en' | 'el' = (localStorage.getItem('i18nextLng') as 'en' | 'el') || 'en';

  const { presentError, presentInfo } = useAlert();

  const [selectedCategory, setSelectedCategory] = useState<string>('all');
  const [selectedTimeframe, setSelectedTimeframe] = useState<string>('all');
  const [calendarModalOpen, setCalendarModalOpen] = useState<boolean>(false);
  const [categorySelectModal, setCategorySelectModal] = useState<boolean>(false);

  // Used for updating the params for the query
  const [startParam, setStartParam] = useState<string>('');
  const [endParam, setEndParam] = useState<string>('');

  const timeframe = useTimeframe(selectedTimeframe, startParam, endParam);

  const { data: overallData, error: errorOverall } = useGetHypertensionOverallQuery({
    category: selectedCategory === 'all' ? undefined : selectedCategory,
    ...timeframe,
  });

  const { data: graphData, error: errorGraph } = useGetPulsesGraphDataQuery({
    ...timeframe,
  });

  const { data: hypertensionLatestLog, error: hypertensionError } =
    useGetLatestLogQuery();

  const prepareOverallData = usePrepareOverallData(overallData);
  const categoryLabel = useCategoryLabel(selectedCategory);

  const prepareGraphData = usePreparePulsesGraph(graphData);

  useEffect(() => {
    if (hypertensionError || errorOverall || errorGraph) {
      presentError(t('errors.generic.error01'));
    }
  }, [hypertensionError, errorOverall, errorGraph]);

  const hypertensionData = useMemo(() => {
    if (hypertensionLatestLog && hypertensionLatestLog.documents.length > 0) {
      return {
        pulses: hypertensionLatestLog.documents[0].pulses,
      };
    }
    return { pulses: '-' };
  }, [hypertensionLatestLog]);

  const handleDateChange = (dateRange: string[]) => {
    setStartParam(dateRange[0]);
    setEndParam(dateRange[1]);
  };

  const handleShowInDepthData = () => {
    router.push('/dashboard/hypertension-in-depth-data');
  };

  const handleShowGraphData = () => {
    router.push(`/dashboard/hypertension/graph-data`);
  };

  const renderDots = useMemo(() => {
    let dots: any[] = [];
    if (prepareGraphData.length > 0) {
      dots = [
        {
          color: theme.palette.error.main,
          text: t('dashboard.dashcards.hypertension.pulses'),
        },
      ];
    }
    return dots;
  }, [prepareGraphData]);

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      gap={theme.spacing(1)}
      marginTop={theme.spacing(3)}
    >
      <AllMeasurements
        text={categoryLabel}
        openModal={() => setCategorySelectModal(true)}
      />
      <ToggleButtonsComp
        data={prepareOverallData}
        setOpen={setCalendarModalOpen}
        toggleButtonsValue={selectedTimeframe}
        setToggleButtonsValue={setSelectedTimeframe}
        customColor={theme.palette.hypertension.main}
        headings={useConvertJSON(weekMonthYearCustom)}
      />
      <Box
        sx={{
          display: 'flex',
          borderRadius: '8px',
          flexDirection: 'column',
          backgroundColor: 'white',
          gap: theme.spacing(2),
          padding: theme.spacing(2),
        }}
      >
        <Box display={'flex'} alignItems={'center'} gap={theme.spacing(1)}>
          <Typography
            variant={'h1'}
            color={theme.palette.primary.main}
            textTransform={lang === 'el' ? 'capitalize' : 'uppercase'}
          >
            {t('hypertension.labels.latestPul')}
          </Typography>
          <Typography
            variant={'subtitle1'}
            color={theme.palette.primary.light}
            fontWeight={theme.typography.fontWeightMedium}
          >
            {hypertensionData.pulses} {t('measurements.bpm')}
          </Typography>
        </Box>
        <GraphDots justify={'flex-start'} dots={renderDots} />
        {prepareGraphData.length === 0 && <FeelsEmpty />}
        {prepareGraphData.length > 0 && (
          <LineChart colors={[theme.palette.error.main]} chartData={prepareGraphData} />
        )}
      </Box>
      <Box marginTop={2}>
        <ClickNavCardNutrition
          onClick={handleShowInDepthData}
          headings={t('buttons.navigation.inDepth')}
        />
        <ClickNavCardNutrition
          headings={t('hypertension.titles.esh')}
          onClick={() => router.push('/dashboard/hypertension/esh')}
        />
        <ClickNavCardNutrition
          headings={t('hypertension.titles.24Hours')}
          onClick={() => router.push('/dashboard/hypertension/24hours')}
        />
        <ClickNavCardNutrition
          onClick={handleShowGraphData}
          headings={t('buttons.navigation.graphData')}
        />
      </Box>
      <ModalFilters
        open={categorySelectModal}
        selected={selectedCategory}
        options={useConvertJSON(MeasurementOptions)}
        dismiss={newValue => {
          setSelectedCategory(newValue);
          setCategorySelectModal(false);
        }}
      />
      <CalendarModal
        open={calendarModalOpen}
        saveDate={handleDateChange}
        setOpen={setCalendarModalOpen}
        selectedDate={[startParam, endParam]}
      />
    </Box>
  );
};

export default HypertensionPulses;
