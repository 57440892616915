import React, { useEffect, useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import {
  AllMeasurements,
  Collapsible,
  ComponentsLayout,
  DownloadIcon,
  InDepthDataCard,
  ModalFilters,
  returnFiltersLabel,
  theme,
} from '@hdcorner/ui-library';
import PageLayout from '../../components/PageLayout';
import {
  useGetBloodGlucoseLogsQuery,
  useGetBloodGlucoseOverallQuery,
} from './queries/diabetesQueries';
import moment from 'moment/moment';
import { FilterOptions } from './constants';
import useFilterLabel from './hooks/useFilterLabel';
import ModalInDepthDataDL from '../../components/ModalInDepthDataDL';
import useAlert from '../../hooks/useAlert';
import InfiniteScrollList from '../../components/InfiniteScrollList';
import { useTranslation } from 'react-i18next';
import { useConvertJSON } from '../../utils/useConvertJSON';

const BloodGlucoseInDepthData = () => {
  const { t } = useTranslation();
  const { presentError } = useAlert();

  const [filter, setFilter] = useState<string>('default');
  const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
  const [downloadDataModalOpen, setDownloadDataModalOpen] = useState(false);
  const [bloodGlucoseParams, setBloodGlucoseParams] = useState({
    skip: 0,
    limit: 20,
  });

  const filterLabel = useFilterLabel(filter);

  const {
    isLoading,
    data: bloodGlucoseLogs,
    error: bloodGlucoseError,
  } = useGetBloodGlucoseLogsQuery({
    params: {
      ...bloodGlucoseParams,
      category: filter === 'default' ? undefined : filter,
    },
  });
  const { data: overallData } = useGetBloodGlucoseOverallQuery({
    category: filter === 'default' ? undefined : filter,
  });

  useEffect(() => {
    if (bloodGlucoseError) {
      presentError(t('error.diabetes.fetchGlucose'));
    }
  }, [bloodGlucoseError]);

  const prepareData = useMemo(() => {
    if (!bloodGlucoseLogs) return [];

    return bloodGlucoseLogs.documents.map(item => {
      return {
        title: item.measurement.toString(),
        titleSub: t('measurements.mgdl'),
        values: {
          [t('headingsTitles.bloodGluc')]: {
            value: `${item.measurement} ${t('measurements.mgdl')}`,
          },
          Filters: { value: returnFiltersLabel(item.category) },
          [t('inDepth.timeOfCalc')]: {
            value: moment(item.logDate).format('h:mm A'),
          },
          [t('inDepth.dateOfCalc')]: {
            value: moment(item.logDate).format('MMM Do, YYYY'),
          },
        },
      };
    });
  }, [bloodGlucoseLogs]);

  const prepareOverallData = useMemo(() => {
    if (!overallData)
      return [
        {
          name: filterLabel,
          min: t('general.labels.nA'),
          max: t('general.labels.nA'),
          avg: t('general.labels.nA'),
        },
      ];
    const data = overallData;
    return [
      {
        name: filterLabel,
        min: data.min ? data.min.toFixed() : t('general.labels.nA'),
        max: data.max ? data.max.toFixed() : t('general.labels.nA'),
        avg: data.avg ? data.avg.toFixed() : t('general.labels.nA'),
      },
    ];
  }, [filterLabel, overallData]);

  return (
    <PageLayout
      headerTitle={t('headingsTitles.inDepth')}
      defaultHref={'/dashboard/diabetes'}
      headerColor={theme.palette.primary.main}
      endButtons={[
        {
          icon: <DownloadIcon />,
          onClick: () => setDownloadDataModalOpen(true),
        },
      ]}
    >
      <ComponentsLayout display={'flex'} flexDirection={'column'} gap={theme.spacing(2)}>
        <AllMeasurements text={filterLabel} openModal={() => setFilterModalOpen(true)} />
        <InDepthDataCard
          rows={prepareOverallData}
          headings={[
            `${t('measurements.min')}`,
            `${t('measurements.max')}`,
            `${t('measurements.avg')}`,
          ]}
          measurement={`${t('measurements.mgdl')}`}
        />
        <Box>
          <Typography
            variant="h1"
            color={theme.palette.primary.main}
            marginBottom={theme.spacing(2)}
            fontWeight={theme.typography.fontWeightRegular}
          >
            {t('headingsTitles.latestUpdates')}
          </Typography>
          <InfiniteScrollList
            loading={isLoading}
            skip={bloodGlucoseParams.skip}
            limit={bloodGlucoseParams.limit}
            totalCount={bloodGlucoseLogs?.count || 0}
            count={bloodGlucoseLogs?.documents.length || 0}
            onLoadMore={(skip, limit) => {
              setBloodGlucoseParams({ skip, limit });
            }}
          >
            {prepareData.map((data, index) => (
              <Box key={index}>
                <Collapsible
                  title={data.title}
                  data={data.values}
                  titleSub={data.titleSub}
                />
              </Box>
            ))}
          </InfiniteScrollList>
        </Box>
      </ComponentsLayout>

      <ModalFilters
        selected={filter}
        open={filterModalOpen}
        options={useConvertJSON(FilterOptions)}
        dismiss={newValue => {
          setFilter(newValue);
          setFilterModalOpen(false);
        }}
      />
      <ModalInDepthDataDL
        module={'blood_glucose'}
        open={downloadDataModalOpen}
        dismiss={() => setDownloadDataModalOpen(false)}
      />
    </PageLayout>
  );
};

export default BloodGlucoseInDepthData;
