import * as React from 'react';
import { FC, useEffect, useMemo, useState } from 'react';
import {
  CustomButton,
  CustomInput,
  Medication,
  MedicineStock,
  theme,
  ToggleCard,
} from '@hdcorner/ui-library';
import { Box, Typography } from '@mui/material';
import {
  useCreateMedicineStockMutation,
  useEditMedicineStockMutation,
  useGetMedicineStockQuery,
} from '../queries/medicationQueries';
import { instructionCategories } from '../constants';
import { useTranslation } from 'react-i18next';

type MedicationInstructionsModalProps = {
  medication: Medication | null;
};
const MedicationInstructionsModal: FC<MedicationInstructionsModalProps> = ({
  medication,
}) => {
  const { t } = useTranslation();

  const [instructions, setInstructions] = useState<string>('');
  const [instructionsCategory, setInstructionsCategory] = useState<
    'before_meal' | 'after_meal' | 'with_meal' | 'no_instructions'
  >('no_instructions');

  const [editMedicineStock] = useEditMedicineStockMutation();
  const [createMedicineStock] = useCreateMedicineStockMutation();

  const { data: medicineStockRes } = useGetMedicineStockQuery(
    { medicineId: medication?.medicine._id || '' },
    {
      skip: !medication || !medication.medicine._id,
    },
  );

  const medicineStock = useMemo<MedicineStock | null>(() => {
    if (!medicineStockRes || medicineStockRes.length === 0) return null;
    return medicineStockRes[0];
  }, [medicineStockRes]);

  useEffect(() => {
    if (medicineStock) {
      if (medicineStock.instructionsCategory === 'no_instructions') {
        setInstructionsCategory('no_instructions');
        setInstructions('');
      } else {
        setInstructionsCategory(medicineStock.instructionsCategory || 'no_instructions');
        if (medicineStock.instructions) {
          setInstructions(medicineStock.instructions);
        } else {
          setInstructions('');
        }
      }
    }
  }, [medicineStock]);

  const updateInstructions = () => {
    if (medicineStock) {
      editMedicineStock({
        ...medicineStock,
        instructions,
        instructionsCategory,
      });
    } else {
      if (!medication) return;
      createMedicineStock({
        instructions,
        instructionsCategory,
        medicine: medication.medicine._id,
      });
    }
  };

  const handleChangeCategory = (value: string) => {
    const castedValue = value as
      | 'before_meal'
      | 'after_meal'
      | 'with_meal'
      | 'no_instructions';
    setInstructionsCategory(castedValue);
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={3} padding={3}>
      <Box>
        {instructionCategories.map((category, index) => (
          <ToggleCard
            key={index}
            value={category.value}
            textTransform={'none'}
            label={t(category.label)}
            setSelected={handleChangeCategory}
            backgroundColor={theme.palette.secondary.light}
            selected={instructionsCategory === category.value}
          />
        ))}
      </Box>
      <Box marginTop={theme.spacing(3)}>
        <Typography
          variant={'subtitle2'}
          marginBottom={theme.spacing(1)}
          color={theme.palette.primary.main}
          fontWeight={theme.typography.fontWeightRegular}
        >
          {t('medication.text.customInstructions')}
        </Typography>
        <CustomInput
          fullWidth
          value={instructions}
          handleChange={setInstructions}
          placeholder={`${t('medication.text.enterCustom')}`}
        />
      </Box>
      <CustomButton
        fullWidth
        disableElevation
        color={'primary'}
        variant={'contained'}
        onClick={updateInstructions}
        sx={{
          height: '48px',
          marginTop: theme.spacing(3),
        }}
      >
        {t('buttons.update')}
      </CustomButton>
    </Box>
  );
};

export default MedicationInstructionsModal;
