import { useDebounce } from '@hdcorner/ui-library';
import { Box } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import PageLayout from '../../components/PageLayout';
import NotificationSettings from './components/NotificationSettings';
import {
  useGetUserSettingsQuery,
  useUpdateNotificationsSettingsMutation,
} from './queries/userSettingsQueries';
import { useTranslation } from 'react-i18next';

const AccountNotifications = () => {
  const { t } = useTranslation();

  const isFirstRef = useRef<boolean>(true);

  const [general, setGeneral] = useState<boolean>(true);
  const [messages, setMessages] = useState<boolean>(true);
  const [knowledge, setKnowledge] = useState<boolean>(true);
  const [clinicalCoach, setClinicalCoach] = useState<boolean>(true);

  const { data: userSettings } = useGetUserSettingsQuery();
  const [updateUserSettings] = useUpdateNotificationsSettingsMutation();

  const debounceGeneral = useDebounce(general, 300);
  const debounceMessages = useDebounce(messages, 300);
  const debounceKnowledge = useDebounce(knowledge, 300);
  const debounceClinicalCoach = useDebounce(clinicalCoach, 300);

  useEffect(() => {
    if (userSettings) {
      const data = userSettings[0];

      const newUserSettings = {
        general: data.generalNotifications,
        knowledge: data.knowledgeNotifications,
        messages: data.otherMessagesNotifications,
        clinicalCoach: data.clinicalCoachNotifications,
      };

      setGeneral(newUserSettings.general);
      setMessages(newUserSettings.messages);
      setKnowledge(newUserSettings.knowledge);
      setClinicalCoach(newUserSettings.clinicalCoach);

      const timeout = setTimeout(() => {
        isFirstRef.current = false;
        clearTimeout(timeout);
      }, 700);
    }
  }, [userSettings]);

  useEffect(() => {
    if (isFirstRef.current) {
      return;
    }

    updateUserSettings({
      generalNotifications: general,
      knowledgeNotifications: knowledge,
      otherMessagesNotifications: messages,
      clinicalCoachNotifications: clinicalCoach,
    });
  }, [debounceKnowledge, debounceGeneral, debounceMessages, debounceClinicalCoach]);

  return (
    <PageLayout
      backgroundWhite
      defaultHref={'/dashboard/profile'}
      headerTitle={t('headingsTitles.accSettings')}
    >
      <Box
        gap={3}
        padding={3}
        display={'flex'}
        flexDirection={'column'}
        boxSizing={'border-box'}
      >
        <NotificationSettings
          checked={general}
          setChecked={setGeneral}
          text={t('notifications.text.genNots')}
          title={t('notifications.titles.genNots')}
        />
        <NotificationSettings
          checked={knowledge}
          setChecked={setKnowledge}
          text={t('notifications.text.didYou')}
          title={t('notifications.titles.didYou')}
        />
        <NotificationSettings
          checked={messages}
          setChecked={setMessages}
          text={t('notifications.text.other')}
          title={t('notifications.titles.other')}
        />
        <NotificationSettings
          checked={clinicalCoach}
          text={t('notifications.text.cc')}
          setChecked={setClinicalCoach}
          title={t('notifications.titles.cc')}
        />
      </Box>
    </PageLayout>
  );
};

export default AccountNotifications;
