import { SettingsEmailPanelMobile } from '@hdcorner/ui-library';
import { useIonRouter } from '@ionic/react';
import React from 'react';
import PageLayout from '../../components/PageLayout';
import useAlert from '../../hooks/useAlert';
import { useTranslation } from 'react-i18next';
import { useChangeEmailMutation } from './queries/accountSettingsQueries';
import { useSignInMutation } from '../authentication/queries/signInQueries';
import { useAppSelector } from '../../redux/hooks';

const AccountSettingsEmail = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { presentError } = useAlert();

  const {
    data: { user },
  } = useAppSelector(state => state.auth);

  const [login] = useSignInMutation();
  const [updateEmail] = useChangeEmailMutation();

  const changeEmail = async (newEmail: string, password: string) => {
    if (!user || !user.authUser || !user.authUser.email) {
      presentError(t('errors.profile.errorUpdateEmail'));
      return;
    }
    try {
      await login({ email: user.authUser.email, password: password }).unwrap();
      await updateEmail({ newEmail: newEmail }).unwrap();

      router.push('/dashboard/profile-settings', 'back', 'pop');
    } catch (e) {
      console.log(e);
      presentError(t('errors.profile.errorUpdateEmail'));
    }
  };

  return (
    <PageLayout
      backgroundWhite
      headerTitle={t('personalDetails.email')}
      defaultHref={'/dashboard/profile-settings'}
    >
      <SettingsEmailPanelMobile handleChangeEmail={changeEmail} />
    </PageLayout>
  );
};

export default AccountSettingsEmail;
