const routesWithoutHeaderAndSide = [
  '/sign-in',
  '/sign-up',
  '/reset-password',
  '/forgot-password',
  '/dashboard/onboarding',
  '/dashboard/questionnaire',
  '/dashboard/terms-and-conditions',
];

export const hideNavigation = (path: string): boolean => {
  const resetPasswordRegex = /\/reset-password\/.*/;

  if (resetPasswordRegex.test(path)) {
    return true;
  }

  return routesWithoutHeaderAndSide.includes(path);
};
