import api from '../../../redux/api';
import { UserSettings } from '../types/UserSettings';

const userSettingsQueries = api.injectEndpoints({
  endpoints: build => ({
    createUserSettings: build.mutation<
      UserSettings,
      Omit<UserSettings, '_id' | 'createdAt'>
    >({
      query: ({
        unitsSystem,
        selectedLang,
        selectedRegion,
        cvdRiskCalculator,
        generalNotifications,
        knowledgeNotifications,
        otherMessagesNotifications,
        clinicalCoachNotifications,
      }) => ({
        url: '/database/function/createUserSettings',
        method: 'POST',
        body: {
          unitsSystem,
          selectedLang,
          selectedRegion,
          cvdRiskCalculator,
          generalNotifications,
          knowledgeNotifications,
          otherMessagesNotifications,
          clinicalCoachNotifications,
        },
      }),
      invalidatesTags: ['UserSettings'],
    }),

    getUserSettings: build.query<UserSettings[], void>({
      query: () => ({
        method: 'GET',
        url: '/database/function/getUserSettings',
      }),
      providesTags: ['UserSettings'],
    }),

    updateLanguageSettings: build.mutation<UserSettings[], { selectedLang: string }>({
      query: ({ selectedLang }) => ({
        method: 'PUT',
        body: { selectedLang },
        url: '/database/function/editUserSettings',
      }),
      invalidatesTags: ['UserSettings'],
    }),

    updateUnitsSettings: build.mutation<
      UserSettings,
      { unitsSystem: string; cvdRiskCalculator: string }
    >({
      query: ({ unitsSystem, cvdRiskCalculator }) => ({
        url: '/database/function/editUserSettings',
        method: 'PUT',
        body: { unitsSystem, cvdRiskCalculator },
      }),
      invalidatesTags: ['UserSettings'],
    }),

    updateNotificationsSettings: build.mutation<
      UserSettings,
      {
        generalNotifications: boolean;
        knowledgeNotifications: boolean;
        otherMessagesNotifications: boolean;
        clinicalCoachNotifications: boolean;
      }
    >({
      query: ({
        generalNotifications,
        knowledgeNotifications,
        otherMessagesNotifications,
        clinicalCoachNotifications,
      }) => ({
        url: '/database/function/editUserSettings',
        method: 'PUT',
        body: {
          generalNotifications,
          knowledgeNotifications,
          otherMessagesNotifications,
          clinicalCoachNotifications,
        },
      }),
      invalidatesTags: ['UserSettings'],
    }),
  }),
});

export const {
  useGetUserSettingsQuery,
  useLazyGetUserSettingsQuery,
  useCreateUserSettingsMutation,
  useUpdateUnitsSettingsMutation,
  useUpdateLanguageSettingsMutation,
  useUpdateNotificationsSettingsMutation,
} = userSettingsQueries;

export default userSettingsQueries;
