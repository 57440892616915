import React, { FC, useState } from 'react';
import { useIonRouter } from '@ionic/react';
import { ResetPasswordPanelGlobal } from '@hdcorner/ui-library';
import LogoHeartPNG from '../../../../assets/illustrations/LogoHeartPNG.png';
import { useResetPasswordMutation } from '../../queries/forgotPasswordQueries';
import useAlert from '../../../../hooks/useAlert';
import { useTranslation } from 'react-i18next';

type ResetPasswordPanelProps = {
  web: boolean;
  resetToken?: string;
};
const ResetPasswordPanel: FC<ResetPasswordPanelProps> = ({ web, resetToken }) => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { presentError } = useAlert();

  const [resetPassword] = useResetPasswordMutation();

  const [count, setCount] = useState(0);
  const [password1, setPassword1] = useState<string>('');
  const [password2, setPassword2] = useState<string>('');

  const handleContinue = () => {
    setCount(count + 1);
  };

  const handleResetPassword = async () => {
    if (!resetToken) {
      presentError(t('authentication.forgotReset.error.errorReset'));
      return;
    }

    try {
      await resetPassword({
        password: password1,
        passwordResetToken: resetToken,
      }).unwrap();
      setCount(count + 1);
    } catch (e: any) {
      console.log(e);
      if (e.status === 403) {
        presentError(e.data.message);
        return;
      }
      presentError(t('authentication.forgotReset.error.errorReset'));
    }
  };

  const handleGoToSignIn = () => {
    router.push('/sign-in');
  };

  const handleCancelButton = () => {
    setCount(0);
    router.push('/sign-in');
  };

  return (
    <ResetPasswordPanelGlobal
      web={web}
      count={count}
      password1={password1}
      password2={password2}
      handleContinue={handleContinue}
      handleGoToSignIn={handleGoToSignIn}
      handleCancelButton={handleCancelButton}
      logoContainer={
        <img
          src={LogoHeartPNG}
          alt={'company logo'}
          width={web ? '134px' : '54px'}
          height={web ? '136px' : '56px'}
        />
      }
      handleResetPassword={handleResetPassword}
      setPassword1={setPassword1}
      setPassword2={setPassword2}
    />
  );
};

export default ResetPasswordPanel;
