import {
  ExclamationIcon,
  ModalMobile,
  QuotesIcon,
  XCloseIcon,
} from '@hdcorner/ui-library';
import { FC } from 'react';
import { Box, BoxProps, IconButton, styled, Typography, useTheme } from '@mui/material';
import {
  storeDidYouKnow,
  storeOtherMessages,
} from '../features/dashboard/utils/popupStorage';

const BoxMain = styled(Box)<BoxProps>(({ theme }) => ({
  width: '348px',
  display: 'flex',
  borderRadius: '8px',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.secondary.light,
}));

const BoxInner = styled(Box)<BoxProps>(({ theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  justifyContent: 'space-between',
}));

type Props = {
  text: string;
  open: boolean;
  category: string;
  setOpen: (open: boolean) => void;
};

const ModalPopUp: FC<Props> = ({ text, open, setOpen, category }) => {
  const theme = useTheme();

  const black = theme.palette.primary.dark;

  const handleClose = () => {
    if (category === 'did_you_know') {
      storeDidYouKnow();
    } else {
      storeOtherMessages();
    }
    setOpen(false);
  };

  return (
    <ModalMobile open={open} setOpen={setOpen} handleClose={handleClose}>
      <BoxMain>
        <BoxInner>
          <Box gap={1} display={'flex'} alignItems={'center'}>
            {category === 'did_you_know' ? (
              <ExclamationIcon color={black} />
            ) : (
              <QuotesIcon color={black} />
            )}
            <Typography
              variant={'subtitle1'}
              sx={{ fontWeight: theme.typography.fontWeightMedium }}
            >
              {category === 'did_you_know' ? 'Did you know?' : 'Other messages'}
            </Typography>
          </Box>

          <IconButton onClick={handleClose} sx={{ padding: 0 }}>
            <XCloseIcon color={black} />
          </IconButton>
        </BoxInner>

        <Typography variant={'body1'} sx={{ color: theme.palette.kmColorsCoolGrey.main }}>
          {text}
        </Typography>
      </BoxMain>
    </ModalMobile>
  );
};

export default ModalPopUp;
